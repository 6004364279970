/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-message-delivered': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.1 12.6c.3-.15.45-.45.45-.6 0-.3-.15-.6-.45-.6L2.55 1.2c-.3-.15-.6 0-.9.15-.15.15-.3.6-.15.9l2.55 6.9c.15.45.6.6.9.45.45-.15.6-.6.45-1.05l-1.95-5.1L21.15 12l-17.7 8.55 2.85-7.8h5.85c.45 0 .75-.3.75-.75s-.3-.75-.75-.75h-6.3c-.3 0-.6.15-.75.45L1.5 21.75c-.15.3 0 .6.15.75.3.15.6.3.9.15L23.1 12.6z"/>'
  }
})
