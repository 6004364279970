/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calendar-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 21h7.5v3h-9A1.5 1.5 0 010 22.5v-18A1.5 1.5 0 011.5 3h3V0h3v3h3V0h3v3h3V0h3v3h3A1.5 1.5 0 0124 4.5v9h-3v-6H3V21zm12.44 2.56a1.5 1.5 0 002.12 0l6-6-2.12-2.12-4.94 4.939-1.94-1.94-2.12 2.122 3 3z"/>'
  }
})
