/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-eye-hide-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.4 4.5L21.45.45c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-21 21c-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45-.6-.6-.6-1.5 0-2.1L4.2 17.7c-1.5-1.35-2.85-2.85-3.6-4.05-.75-.9-.75-2.25 0-3.3C2.4 7.65 6.6 3 12 3c1.95 0 3.75.6 5.4 1.5zM7.5 12c0 .75.15 1.35.45 1.95l6-6c-.6-.3-1.2-.45-1.95-.45-2.55 0-4.5 1.95-4.5 4.5zm2.1 8.7L21.9 8.4c.263.328.526.628.764.9.304.347.567.647.736.9.75 1.05.75 2.4 0 3.45-1.8 2.7-6 7.35-11.4 7.35-.75 0-1.65-.15-2.4-.3z"/>'
  }
})
