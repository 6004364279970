/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-my-apps-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 14h-8v8h8v-8zM10 14H2v8h8v-8zM10 2H2v8h8V2zM22 2h-8v8h8V2z"/>'
  }
})
