/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-dots': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.5 17.8c.2.9.3 1.7.5 2.7.1 1.4-.7 2.2-2.1 2.4-1 .2-2.2 0-2.8-.3-2.2-1.2-3.4-4.1-4-9.3-.5-5 .3-9 2.3-11.1C4.9.4 7.3.9 8.6 2c.9.8.8 2 0 6-.1.6-.5.8-1.6 1h-.1c-.6.2-.8.3-.8.3-.4 1.3-.3 3.8.2 5.4 0 .2.1.2.7.3h.1c.7 0 1.1.2 1.5.6.4.5.7 1.3.9 2.2zm-1.8 3.9c.8-.1 1.1-.4 1-1-.1-1.1-.2-1.7-.4-2.5s-.5-1.4-.7-1.8c-.1-.1-.1-.1-.5-.1H7h-.1c-1.1-.1-1.6-.3-1.9-1.2-.6-1.9-.7-4.6-.2-6.1.2-.8.4-.8 1.8-1.1.3-.1.5-.1.6-.2.9-3.6.9-4.5.6-4.7-1-.9-2.5-1-3.4 0-1.7 1.8-2.5 5.6-2 10.1C3 18 4 20.5 5.7 21.5c.4.2 1.3.3 2 .2zM20.6 2.1c1.9 2.1 2.8 6.2 2.3 11.1-.6 5.3-1.8 8.1-4 9.3-.6.3-1.8.5-2.8.3-1.4-.2-2.2-1-2.1-2.4.1-1.1.2-1.8.5-2.7.2-.9.5-1.7 1-2.2.3-.5.8-.6 1.5-.6h.1c.6 0 .6-.1.7-.2.5-1.6.6-4.1.2-5.4 0 0-.2-.1-.8-.3h-.1c-1.1-.2-1.5-.4-1.6-1-.8-4-.9-5.2 0-6 1.2-1.1 3.6-1.6 5.1.1zm1 11c.5-4.6-.3-8.3-1.9-10.1-.9-1-2.4-.9-3.4 0-.3.3-.3 1.1.4 4.7.1 0 .3.1.6.2 1.4.3 1.6.4 1.8 1.1.5 1.6.4 4.2-.2 6.1-.3.9-.8 1.1-1.9 1.2h-.1-.1c-.4 0-.5 0-.5.1-.3.4-.5 1-.7 1.8s-.3 1.4-.4 2.5c-.1.6.2.9 1 1 .7.1 1.6 0 2-.2 1.8-1 2.8-3.5 3.4-8.4zM15 13c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm-6 0c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm3 0c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"/>'
  }
})
