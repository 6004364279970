/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-small-video-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 0h-21C.6 0 0 .6 0 1.5v21c0 .9.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5v-21c0-.9-.6-1.5-1.5-1.5zm-6.6 13.2l-6 4.5c-.9.75-2.4 0-2.4-1.2v-9a1.5 1.5 0 012.4-1.2l6 4.5c.75.6.75 1.8 0 2.4z"/>'
  }
})
