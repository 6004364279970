/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.76 12.766h3.191C23.951 5.745 18.206 0 11.185 0v3.192c5.266 0 9.574 4.308 9.574 9.574zm-6.384 0h3.192c0-3.51-2.873-6.383-6.383-6.383v3.192a3.201 3.201 0 013.191 3.191zm4.15 3.99c1.117.797 1.915 1.276 2.234 1.436.08.08.16.15.24.22.239.209.478.418.717.897.16.319.32.798.16 1.117l-.479 1.117c-.319.957-1.117 1.596-2.074 2.075-.958.478-2.075.478-3.192.159-1.117-.16-2.234-.638-3.51-1.277-1.277-.638-2.395-1.436-3.512-2.234s-2.074-1.595-2.872-2.393C5.44 17.075 4.642 16.117 3.844 15c-.786-.917-1.357-1.942-1.89-2.898-.115-.208-.23-.413-.344-.613C.972 10.213.493 9.096.174 7.98c-.32-1.117-.16-2.235.16-3.192.478-.957 1.276-1.755 2.234-2.075l1.117-.478c.319 0 .798 0 1.117.16.355.118.534.324.734.553.069.08.14.162.223.244.16.32.639 1.117 1.436 2.234l.958 1.915c.16.639.16 1.277 0 1.756-.16.638-.479 1.436-1.117 1.755v.32c0 .478.32.957.957 1.755.639.797 1.117 1.276 1.596 1.595.32.32.798.798 1.596 1.437.798.638 1.277.798 1.755.957h.32c.319-.479.957-.957 1.755-1.117.957-.32 2.234.16 3.51.958z"/>'
  }
})
