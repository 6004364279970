/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-inbox': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.5 0h-13C.7 0 0 .7 0 1.5v13c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-13c0-.8-.7-1.5-1.5-1.5zm.5 14.5c0 .3-.2.5-.5.5h-13c-.3 0-.5-.2-.5-.5V10h4v2.5c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5V10h4v4.5zM15 9h-4.5c-.3 0-.5.2-.5.5V12H6V9.5c0-.3-.2-.5-.5-.5H1V1.5c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5V9z"/>'
  }
})
