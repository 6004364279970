/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mobile-devices': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.228 3.13c1.252 0 2.192 1.044 2.192 2.192v16.487c0 1.252-1.044 2.191-2.192 2.191h-8.243c-1.252 0-2.191-1.044-2.191-2.191v-.835H3.74c-1.252 0-2.191-1.044-2.191-2.191V2.19C1.55.94 2.594 0 3.741 0h8.244c1.252 0 2.191 1.043 2.191 2.191v.835l6.052.104zm0 19.514a.823.823 0 00.835-.835v-2.922H11.046v2.922c0 .521.417.834.835.834h8.347zM3.741 19.616h6.053v-3.86H2.907v2.921c0 .522.417.94.834.94zM9.794 14.4V5.322v-.313H2.907v9.495l6.887-.104zM3.74 1.357a.823.823 0 00-.834.834v1.461h7.721c.313-.417.835-.522 1.357-.522h.835v-.939a.823.823 0 00-.835-.834H3.74zM11.15 17.53h10.017V8.035H11.15v9.495zm9.913-12.208a.823.823 0 00-.835-.835h-8.243a.823.823 0 00-.835.835v1.356h10.017c-.104 0-.104-1.356-.104-1.356zm-5.009 14.295c.627 0 1.148.627 1.148 1.253 0 .626-.522 1.147-1.148 1.147-.626 0-1.147-.626-1.147-1.252.104-.626.626-1.148 1.147-1.148zm-8.139-3.13c.626 0 1.148.522 1.148 1.148 0 .626-.522 1.148-1.148 1.148a1.158 1.158 0 01-1.148-1.148c-.104-.626.418-1.148 1.148-1.148z"/>'
  }
})
