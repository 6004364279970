/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-type': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5 0a.75.75 0 000 1.5 3.75 3.75 0 013.75 3.75V15H7.5a.75.75 0 000 1.5h3.75v2.25A3.75 3.75 0 017.5 22.5a.75.75 0 000 1.5 5.25 5.25 0 004.5-2.546A5.246 5.246 0 0016.5 24a.75.75 0 000-1.5 3.75 3.75 0 01-3.75-3.75V16.5h3.75a.75.75 0 000-1.5h-3.75V5.25A3.75 3.75 0 0116.5 1.5a.75.75 0 000-1.5A5.25 5.25 0 0012 2.546 5.247 5.247 0 007.5 0z"/>'
  }
})
