var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.hide,
          expression: "hide",
        },
      ],
      staticClass: "Vlt-dropdown unselectable",
      class: {
        "Vlt-dropdown--expanded": _vm.isExpanded,
        "Vlt-dropdown--full-width": _vm.fullWidth,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "toggle-container",
          class: { "default-cursor": _vm.defaultCursor },
          attrs: { "data-cy": "toggle-container" },
          on: { click: _vm.toggle },
        },
        [
          _vm._t("toggle-button", [
            _c(
              "div",
              {
                staticClass: "Vlt-dropdown__trigger Vlt-dropdown__trigger--btn",
              },
              [
                _c(
                  "v-button",
                  { staticClass: "toggle-button", attrs: { secondary: "" } },
                  [
                    _c("span", { staticClass: "Vlt-dropdown__selection" }, [
                      _vm._v(_vm._s(_vm.label)),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ],
        2
      ),
      _c(
        "div",
        {
          staticClass: "Vlt-dropdown__panel",
          class: { "open-to-the-left": _vm.openToTheLeft },
          style: { "min-width": _vm.minWidth + "px" },
          on: {
            mouseleave: function ($event) {
              _vm.closeOnMouseLeave && _vm.hide()
            },
          },
        },
        [
          _vm.isExpanded
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "observe-keyboard",
                      rawName: "v-observe-keyboard.prevent.l2.esc",
                      value: _vm.hide,
                      expression: "hide",
                      modifiers: { prevent: true, l2: true, esc: true },
                    },
                  ],
                  staticClass: "Vlt-dropdown__panel__content",
                },
                _vm._l(_vm.items, function (item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      staticClass: "Vlt-dropdown__link",
                      attrs: { "data-cy": "dropdown-option" },
                      on: {
                        click: function ($event) {
                          return _vm.onSelection(item)
                        },
                      },
                    },
                    [
                      _vm._t(
                        "item",
                        [
                          _c(
                            "span",
                            { staticClass: "Vlt-dropdown__label" },
                            [
                              item.icon
                                ? _c("v-icon", {
                                    staticClass: "link-icon",
                                    attrs: { iconName: item.icon },
                                  })
                                : _vm._e(),
                              _c("span", [_vm._v(_vm._s(item.label))]),
                              item.checked
                                ? _c("v-icon", {
                                    staticClass: "checked-icon",
                                    attrs: {
                                      "data-cy": "vdropdown-checked-icon",
                                      iconName: "Vlt-icon-check-bold",
                                    },
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        { item: item }
                      ),
                    ],
                    2
                  )
                }),
                0
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }