/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-code-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M8.55 22.35c.15.15.3.15.45.15.6 0 1.2-.45 1.5-1.05l6-18c.3-.9-.15-1.65-.9-1.95-.9-.3-1.65.15-1.95.9l-6 18c-.3.9.15 1.65.9 1.95zm-3.6-4.8l-4.5-4.5c-.6-.6-.6-1.5 0-2.1l4.5-4.5 2.1 2.1L3.6 12l3.45 3.45-2.1 2.1zm14.1 0l-2.1-2.1L20.4 12l-3.45-3.45 2.1-2.1 4.5 4.5c.6.6.6 1.5 0 2.1l-4.5 4.5z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
