export default {
  SET_IS_WHITEBOARD_ACTIVE: (state, isActive) => {
    state.isWhiteboardActive = isActive;
  },

  SET_WHITEBOARD_OWNER_PARTICIPANT_ID: (
    state,
    whiteboardOwnerParticipantId
  ) => {
    state.whiteboardOwnerParticipantId = whiteboardOwnerParticipantId;
  },

  SET_WHITEBOARD_ID: (state, sessionId) => {
    state.whiteboardId = sessionId;
  },

  SET_WHITEBOARD_TOKEN: (state, token) => {
    state.token = token;
  },

  SET_WHITEBOARD_LARGE_SESSION_MODAL_VISIBLE: (state, isVisible) => {
    state.isWhiteboardLargeSessionModalVisible = isVisible;
  }
};
