/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-checkbox': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1 1.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v13a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-13zM1.5 0A1.5 1.5 0 000 1.5v13A1.5 1.5 0 001.5 16h13a1.5 1.5 0 001.5-1.5v-13A1.5 1.5 0 0014.5 0h-13zm10.854 5.354a.5.5 0 00-.708-.708L7 9.293 4.854 7.146a.5.5 0 10-.708.708l2.5 2.5a.5.5 0 00.708 0l5-5z"/>'
  }
})
