/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-message-read': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.85 12c0-.6-.3-1.05-.9-1.35l-20.1-9.6c-.6-.15-1.2-.15-1.65.3C.75 1.8.6 2.4.75 3l2.4 7.35L13.95 12l-10.8 1.8L.75 21c-.15.6 0 1.2.3 1.5.45.45 1.05.45 1.65.3l20.1-9.6c.75-.15 1.05-.6 1.05-1.2z"/>'
  }
})
