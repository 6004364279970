/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-message-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 0h21A1.5 1.5 0 0124 1.5v15a1.5 1.5 0 01-1.5 1.5H15l-9 6v-6H1.5A1.5 1.5 0 010 16.5v-15A1.5 1.5 0 011.5 0z"/>'
  }
})
