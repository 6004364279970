<template>
  <div class="mute-indication-popper">
    <div class="popper-content-container">
      <div>{{ $t('mute_indication.text') }}</div>
      <div>{{ $t('mute_indication.suggested_action') }}</div>
      <v-icon
        class="close-mute-indication"
        iconName="Vlt-icon-cross"
        @click="closeMuteIndication"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  name: 'MuteIndication',

  computed: {
    ...mapState('loudnessDetector', ['muteIndication']),
    ...mapState(['isMicEnabled']),
    ...mapGetters(['hasMicrophoneAccess']),

    micEnabled() {
      return this.hasMicrophoneAccess && this.isMicEnabled;
    }
  },

  watch: {
    micEnabled(micEnabled) {
      if (micEnabled) {
        this.$emit('close');
      }
    }
  },

  methods: {
    ...mapActions('loudnessDetector', ['turnMuteIndicationOffClicked']),

    closeMuteIndication() {
      this.turnMuteIndicationOffClicked();
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.mute-indication-popper {
  font-weight: 600;
  min-width: 190px;
  text-align: center;
  color: white;
}

.mute-indication-popper .popper-content-container {
  display: flex;
  flex-direction: column;
}

.close-mute-indication {
  position: absolute;
  width: 10px;
  height: 10px;
  top: 7px;
  right: 8px;
}
</style>
