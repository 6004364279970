<template>
  <div
    v-show="showInviteDisabledIndication"
    class="invites-disabled-indication"
    data-cy="invites-disabled-indication"
  >
    <vwc-icon type="info-line" size="small" />
    <div class="message-container">
      <div v-if="isLocked && isSessionOwner">
        <span>{{
          $t('invites_disabled_indication.locked_meeting_owner_start')
        }}</span>
        <span
          class="text-button"
          data-cy="unlock-text-button"
          @click="onUnlockMeeting"
        >
          {{ $t('invites_disabled_indication.unlock_meeting_button') }}
        </span>
        <span>{{
          $t('invites_disabled_indication.locked_meeting_owner_end')
        }}</span>
      </div>
      <div v-else>
        <span>{{ text }}</span>
        <span
          class="text-button"
          data-cy="text-button"
          @click="showInviteDisabledIndication = false"
        >
          {{ $t('invites_disabled_indication.dismiss_button') }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'InvitesDisabledIndication',

  data() {
    return {
      showInviteDisabledIndication: true
    };
  },

  computed: {
    ...mapState(['isLocked']),
    ...mapGetters(['isSessionOwner', 'isWaitingRoomEnabled']),

    text() {
      if (this.isWaitingRoomEnabled && !this.isSessionOwner) {
        return this.$t('invites_disabled_indication.waiting_room');
      } else if (this.isLocked) {
        return this.$t('invites_disabled_indication.locked_meeting_not_owner');
      }
      return '';
    }
  },

  methods: {
    ...mapActions(['setLockMeeting']),

    onUnlockMeeting() {
      this.setLockMeeting(false);
    }
  }
};
</script>

<style scoped>
.invites-disabled-indication {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-top: 12px;
  align-items: flex-start;
}

vwc-icon {
  flex-shrink: 0;
}

.message-container {
  margin-left: 8px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
}

.text-button {
  color: var(--btn-cta-color-white-bg);
  cursor: pointer;
  margin-left: 1px;
  text-decoration-line: underline;
}
</style>
