/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-collapse': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 8.5A.5.5 0 01.5 8h15a.5.5 0 010 1H.5a.5.5 0 01-.5-.5z"/><path pid="1" d="M4.354 4.646a.5.5 0 010 .708L1.207 8.5l3.147 3.146a.5.5 0 01-.708.708l-3.5-3.5a.5.5 0 010-.708l3.5-3.5a.5.5 0 01.708 0zM7 2.5a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5zM7 14.5a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5z"/>'
  }
})
