/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-xls-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.5 9h9v13.5c0 .9-.6 1.5-1.5 1.5H3c-.9 0-1.5-.6-1.5-1.5v-21C1.5.6 2.1 0 3 0h10.5v9zm5.134 5.71a.506.506 0 01-.132.15.281.281 0 01-.174.049.452.452 0 01-.206-.058 7.757 7.757 0 00-.247-.136 1.85 1.85 0 00-.314-.132 1.222 1.222 0 00-.404-.062c-.261 0-.457.056-.586.17a.565.565 0 00-.19.449c0 .12.039.221.116.301.077.08.177.148.301.206.127.058.27.112.429.161.162.047.327.1.495.161.168.058.331.127.49.206.163.08.306.182.43.305.126.124.228.276.305.454.077.176.116.39.116.64 0 .277-.048.537-.145.78a1.833 1.833 0 01-.42.634c-.182.18-.408.32-.677.425-.267.102-.57.153-.912.153a2.96 2.96 0 01-.573-.058 3.537 3.537 0 01-.561-.16 3.394 3.394 0 01-.511-.252 2.171 2.171 0 01-.417-.322l.412-.652a.4.4 0 01.128-.115.339.339 0 01.178-.05.46.46 0 01.247.079l.285.173c.107.063.23.12.367.173.137.052.3.078.487.078.253 0 .45-.055.59-.165.14-.112.21-.29.21-.532a.5.5 0 00-.116-.342.889.889 0 00-.305-.219 2.717 2.717 0 00-.425-.152c-.16-.044-.323-.092-.49-.145a3.977 3.977 0 01-.491-.198 1.639 1.639 0 01-.43-.309 1.496 1.496 0 01-.3-.479 1.916 1.916 0 01-.116-.709 1.703 1.703 0 01.536-1.233 1.94 1.94 0 01.648-.4c.255-.102.548-.153.878-.153.185 0 .363.015.537.045.176.028.342.07.499.128.157.055.302.123.437.202.137.077.26.165.367.264l-.346.648zm-9.59 4.79h1.395l-1.927-3.151 1.886-2.86H9.06a.298.298 0 00-.16.042.438.438 0 00-.12.132l-1.048 1.799-.062.103a1.174 1.174 0 00-.046.103L6.43 13.643a.29.29 0 00-.115-.116.4.4 0 00-.198-.037H4.72l1.86 2.916L4.648 19.5h1.304c.082 0 .15-.023.202-.07a.886.886 0 00.136-.153l1.122-1.947a.833.833 0 00.074-.156l1.205 2.103c.033.06.077.113.132.157a.357.357 0 00.222.066zm5.556 0v-1.114h-2.227V13.49h-1.394v6.01H14.6z"/><path pid="1" d="M16.5 6V0l6 6h-6z"/>'
  }
})
