var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "card",
      staticClass: "card",
      class: {
        collapsed:
          _vm.cardVisibilityState === _vm.CARD_VISIBILITY_STATE.COLLAPSED,
        "partially-extended":
          _vm.cardVisibilityState ===
          _vm.CARD_VISIBILITY_STATE.PARTIALLY_EXTENDED,
        "fully-extended":
          _vm.cardVisibilityState === _vm.CARD_VISIBILITY_STATE.FULLY_EXTENDED,
        transition: _vm.isTransitioning,
        hidden:
          !_vm.isTransitioning &&
          _vm.cardVisibilityState === _vm.CARD_VISIBILITY_STATE.COLLAPSED,
      },
      attrs: { "data-cy": "mobile-card" },
      on: {
        transitionend: function ($event) {
          _vm.isTransitioning = false
        },
      },
    },
    [
      _vm.extendable
        ? _c(
            "div",
            {
              staticClass: "extender-container",
              attrs: { "data-cy": "extender-button" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.toggleCardExtended($event)
                },
              },
            },
            [
              _c("v-icon", {
                staticClass: "extender",
                attrs: {
                  iconName:
                    _vm.cardVisibilityState ===
                    _vm.CARD_VISIBILITY_STATE.FULLY_EXTENDED
                      ? "Vlt-icon-down-full"
                      : "Vlt-icon-up-full",
                },
              }),
              _vm.cardVisibilityState !==
              _vm.CARD_VISIBILITY_STATE.FULLY_EXTENDED
                ? _c("ChatNotification", {
                    staticClass: "overlaping-notification",
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        _vm._g({ staticClass: "card-content-container" }, _vm.$listeners),
        [
          _c(
            "div",
            { staticClass: "action-buttons-container" },
            [
              _c("ControlBarToggleButton", {
                attrs: {
                  id: "toggle-mute-audio-btn",
                  "data-cy": "toggle-mute-audio-btn",
                  disabled: _vm.isInitializingPublisher,
                  transparent:
                    _vm.isInitializingPublisher || !_vm.hasMicrophoneAccess,
                  active: _vm.micEnabled,
                  iconName: _vm.micEnabled
                    ? "Vlt-icon-microphone-full"
                    : "Vlt-icon-microphone-mute-full",
                },
                on: {
                  click: function () {
                    return _vm.toggleMic(_vm.TOGGLE_MIC_SOURCES.MOBILE_WEB_CARD)
                  },
                },
              }),
              _c("ControlBarToggleButton", {
                attrs: {
                  id: "toggle-mute-video-btn",
                  "data-cy": "toggle-mute-video-btn",
                  disabled: _vm.isInitializingPublisher,
                  transparent:
                    _vm.isInitializingPublisher || !_vm.hasCameraAccess,
                  active: _vm.videoEnabled,
                  iconName: _vm.videoEnabled
                    ? "Vlt-icon-video-negative"
                    : "Vlt-icon-video-off-full",
                },
                on: {
                  click: function () {
                    return _vm.toggleVideo(
                      _vm.TOGGLE_CAMERA_SOURCES.MOBILE_WEB_CARD
                    )
                  },
                },
              }),
              _c("ControlBarButton", {
                staticClass: "flip-btn",
                attrs: {
                  id: "flip-camera-btn",
                  "data-cy": "flip-camera-btn",
                  iconName: "Vlt-icon-camera-switch-full",
                  disabled:
                    _vm.isInitializingPublisher ||
                    !_vm.videoEnabled ||
                    !_vm.hasMultipleCameraDevices,
                },
                on: { click: _vm.flipCamera },
              }),
              _c("ControlBarButton", {
                attrs: {
                  id: "open-leave-meeting-modal-btn",
                  "data-cy": "open-leave-meeting-modal-btn",
                  iconName: "Vlt-icon-cross-full",
                  destructive: "",
                },
                on: { click: _vm.showLeaveMeetingDialog },
              }),
            ],
            1
          ),
          _vm.extendable
            ? _c(
                "div",
                { ref: "meetingInfo", staticClass: "meeting-info" },
                [
                  _vm.shouldShowAudioOptions
                    ? _c(
                        "MobileSelect",
                        {
                          staticClass: "select-audio-source",
                          attrs: {
                            options: _vm.microphoneDeviceOptions,
                            "data-cy": "select-audio-source",
                          },
                          model: {
                            value: _vm.currentMicrophoneId,
                            callback: function ($$v) {
                              _vm.currentMicrophoneId = $$v
                            },
                            expression: "currentMicrophoneId",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "selected-microphone" },
                            [
                              _c("v-icon", {
                                attrs: { iconName: "Vlt-icon-audio-min-full" },
                              }),
                              _c("span", { staticClass: "text-ellipsis" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "mobile_card.selected_audio_source",
                                      {
                                        currentMicrophoneLabel:
                                          _vm.currentMicrophoneLabel,
                                      }
                                    )
                                  )
                                ),
                              ]),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.isChatFeatureEnabled
                    ? _c(
                        "div",
                        {
                          staticClass: "meeting-chat-container",
                          attrs: { "data-cy": "meeting-chat-container" },
                          on: { click: _vm.chatClicked },
                        },
                        [
                          _c("v-icon", {
                            staticClass: "chat-icon md",
                            attrs: { "icon-name": "Vlt-icon-chat-full" },
                          }),
                          _c("div", { staticClass: "meeting-chat-title" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("mobile_card.meeting_chat_title")
                                ) +
                                " "
                            ),
                          ]),
                          _vm.unreadMessagesCount !== 0
                            ? _c("ChatNotification", {
                                staticClass: "chat-notification-badge",
                              })
                            : _c("v-icon", {
                                staticClass: "chat-arrow-icn md",
                                attrs: { "icon-name": "Vlt-icon-right-full" },
                              }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "meeting-details-container Vlt-grey-light" },
                    [
                      _c("div", { staticClass: "label" }, [
                        _c("span", { staticClass: "field-name" }, [
                          _vm._v(_vm._s(_vm.$t("mobile_card.meeting_id_text"))),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "detail",
                            attrs: { "data-cy": "meeting-id-value" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.copyRoomPinCodeToClipboard($event)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.roomPinCode))]
                        ),
                      ]),
                      _c("div", { staticClass: "label" }, [
                        _c("span", { staticClass: "field-name" }, [
                          _vm._v(
                            _vm._s(_vm.$t("mobile_card.meeting_link_text"))
                          ),
                        ]),
                        _c(
                          "span",
                          {
                            staticClass: "detail meeting-link text-ellipsis",
                            attrs: { "data-cy": "meeting-url-value" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.copyRoomUrlToClipboard($event)
                              },
                            },
                          },
                          [_vm._v(_vm._s(_vm.roomUrl))]
                        ),
                      ]),
                    ]
                  ),
                  _c("MobileDialInCountries", {
                    staticClass: "dial-in-container",
                  }),
                  _c(
                    "div",
                    { staticClass: "bottom-buttons-container" },
                    [
                      _c("vwc-button", {
                        staticClass: "copy-details-button",
                        attrs: {
                          "data-cy": "copy-details-button",
                          label: _vm.$t("mobile_card.copy_details_button"),
                          layout: "filled",
                          connotation: "cta",
                        },
                        on: { click: _vm.copyDetails },
                      }),
                      _c("vwc-button", {
                        staticClass: "share-button",
                        attrs: {
                          label: _vm.$t("mobile_card.share_button"),
                          layout: "filled",
                        },
                        on: { click: _vm.shareDetails },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }