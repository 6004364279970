/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-vue': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.78 2H0l12 19.932L24 2H14.848L12 6.576 9.153 2H4.78z"/>'
  }
})
