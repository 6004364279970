/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-link-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M14.167 10.247a1.5 1.5 0 001.893-.186l7.5-7.5A1.5 1.5 0 0021.44.44l-7.5 7.5a1.5 1.5 0 00.227 2.307zM3.732 13.903l-2.283 2.285-.577-1.347C.785 14.619-.72 10.596 1.94 7.939l6.045-6.045c2.665-2.66 6.68-1.158 6.85-1.092l1.4.6-2.284 2.285-.244-.105-.006-.002c-.129-.043-2.368-.792-3.594.434l-6.045 6.045c-1.276 1.277-.421 3.629-.412 3.652l.082.192zM.667 23.747a1.499 1.499 0 001.893-.186l7.5-7.5a1.5 1.5 0 00-2.12-2.121l-7.5 7.5a1.5 1.5 0 00.227 2.307zm7.145-1.195l2.285-2.283.192.082c.024.01 2.376.864 3.652-.412l6.045-6.045c1.226-1.227.477-3.466.434-3.595l-.002-.005-.105-.245 2.285-2.284.6 1.4c.066.169 1.569 4.19-1.093 6.85l-6.044 6.045c-2.657 2.66-6.68 1.155-6.9 1.068l-1.349-.576z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
