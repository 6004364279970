/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-table-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 2.8V1.4H0v1.4h7.4v5.1H0v1.5h7.4v5.1H0V16h7.4v5.1H0v1.4h24v-1.4h-7.5v-5.2H24v-1.4h-7.5V9.4H24V7.9h-7.5V2.8H24zm-8.8 18.3H8.8V16h6.3v5.1zm0-6.5H8.8V9.5h6.3v5.1zm0-6.7H8.8V2.8h6.3v5.1z"/>'
  }
})
