/* eslint-disable */
require('./audience-icon')
require('./camera-permissions')
require('./conference-icon-full')
require('./dominant-icon')
require('./gallery-icon')
require('./leave-meeting-icon')
require('./meeting-illustration')
require('./microphone-permissions')
require('./raise-hand')
require('./spin-the-bottle-full')
require('./spin-the-bottle')
require('./video-whiteboard')
require('./Vlt-icon-add-full')
require('./Vlt-icon-media-exit-fullscreen')
require('./Vlt-icon-media-fullscreen')
require('./Vlt-icon-wifi-full')
require('./vonage-page-logo')
require('./whiteboard-icon')
