export default {
  SET_IS_REACTIONS_COUNTER_MODAL_OPEN: (state, isReactionsCounterModalOpen) => {
    state.isReactionsCounterModalOpen = isReactionsCounterModalOpen;
  },

  SET_TOTAL_COUNT: (state, totalCount) => {
    state.totalCount = totalCount;
  },

  INCREASE_REACTION_COUNTER: (state, reactionId) => {
    const reactionToUpdateIndex = state.reactionsCounts.findIndex(
      (reaction) => reaction.id === reactionId
    );
    const reactionToUpdate = state.reactionsCounts[reactionToUpdateIndex];
    reactionToUpdate.count++;
    state.reactionsCounts.splice(reactionToUpdateIndex, 1, reactionToUpdate);
  },

  RESET_REACTIONS_COUNTERS: (state) => {
    state.reactionsCounts.forEach((element) => {
      element.count = 0;
    });
  },

  SET_ALL_REACTIONS_COUNTERS: (state, reactionsCounts) => {
    state.reactionsCounts = reactionsCounts;
  }
};
