/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-slack': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.836 5.018c-1.418 0-2.509-1.09-2.509-2.509C6.327 1.091 7.418 0 8.837 0c1.418 0 2.508 1.09 2.508 2.51v2.508H8.836zM2.51 17.673c1.418 0 2.51-1.091 2.51-2.51v-2.508h-2.51C1.091 12.655 0 13.745 0 15.164c0 1.418 1.09 2.509 2.51 2.509zm6.327-5.018c-1.418 0-2.509 1.09-2.509 2.509v6.327c0 1.418 1.091 2.509 2.51 2.509 1.418 0 2.508-1.09 2.508-2.51v-6.326c0-1.418-1.09-2.51-2.509-2.51zm2.51-3.819c0-1.418-1.091-2.509-2.51-2.509H2.51C1.091 6.327 0 7.418 0 8.837c0 1.418 1.09 2.508 2.51 2.508h6.326c1.418 0 2.51-1.09 2.51-2.509zm7.636 0c0-1.418 1.09-2.509 2.509-2.509 1.418 0 2.509 1.091 2.509 2.51 0 1.418-1.091 2.508-2.51 2.508h-2.508V8.836zm-3.818 2.51c1.418 0 2.509-1.091 2.509-2.51V2.51c0-1.418-1.091-2.509-2.51-2.509-1.418 0-2.508 1.09-2.508 2.51v6.326c0 1.418 1.09 2.51 2.509 2.51zm0 7.636c1.418 0 2.509 1.09 2.509 2.509 0 1.418-1.091 2.509-2.51 2.509-1.418 0-2.508-1.091-2.508-2.51v-2.508h2.509zm-2.51-3.818c0 1.418 1.091 2.509 2.51 2.509h6.327c1.418 0 2.509-1.091 2.509-2.51 0-1.418-1.091-2.508-2.51-2.508h-6.326c-1.419 0-2.51 1.09-2.51 2.509z"/>'
  }
})
