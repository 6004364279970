var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      attrs: {
        heading: _vm.$t("select_language_modal.title"),
        "icon-name": "globe-line",
        close: _vm.hide,
        "min-width": 376,
        "primary-action": _vm.$t("select_language_modal.primary_action_button"),
        "disable-primary-button": _vm.disablePrimaryAction,
        "data-cy": "select-language-modal",
      },
      on: { applied: _vm.setLocale },
    },
    [
      _c(
        "vwc-list",
        { attrs: { slot: "content" }, slot: "content" },
        _vm._l(_vm.locales, function (locale) {
          return _c(
            "vwc-radio-list-item",
            {
              key: locale.id,
              attrs: { selected: locale.value === _vm.$i18n.locale },
              on: {
                click: function ($event) {
                  return _vm.setSelectedLocale(locale.value)
                },
              },
            },
            [
              _c("vwc-icon", {
                staticClass: "icon",
                attrs: { type: locale.icon },
              }),
              _c("span", [_vm._v(" " + _vm._s(locale.label))]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }