/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cart': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 .5C0 .2.2 0 .5 0h3c.3 0 .5.2.5.4L5 9h8.2l1.7-5H6.5c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h9c.2 0 .3.1.4.2.1.1.1.3.1.5l-2 6c-.1.2-.3.3-.5.3h-9c-.3 0-.5-.2-.5-.4L3.1 1H.5C.2 1 0 .8 0 .5zM5 13c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zm-2 1c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zm9 0c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1-1-.4-1-1zm1-2c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2z"/>'
  }
})
