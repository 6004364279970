import {
  MIN_SECONDS_BEFORE_CREATING_NEW_BLOCK,
  CAPTION_MESSAGE_TYPE
} from './consts';
import { formatTime } from '@/helpers/global-helpers';

export function shouldCreateNewCaptionsBlock(lastCaption, newCaption) {
  const isSystemMessage =
    newCaption.messageType === CAPTION_MESSAGE_TYPE.SYSTEM ||
    (lastCaption && lastCaption.messageType === CAPTION_MESSAGE_TYPE.SYSTEM);
  const isSameBlock =
    lastCaption &&
    lastCaption.participantId === newCaption.participantId &&
    lastCaption.updatedTime >=
      newCaption.creationTime - MIN_SECONDS_BEFORE_CREATING_NEW_BLOCK;
  return isSystemMessage || !isSameBlock;
}

export function createCaptionBlock(caption) {
  return {
    participantId: caption.participantId,
    participantDisplayName: caption.participantDisplayName,
    creationTime: caption.creationTime,
    updatedTime: caption.updatedTime,
    formattedTime: caption.formattedTime,
    type: caption.messageType,
    hideHeader: caption.hideHeader,
    textStyle: caption.textStyle,
    texts: [{ text: caption.text, isComplete: caption.isComplete }]
  };
}

export function formatTranscriptionTime(time) {
  return formatTime(time, {
    hourCycle: 'h23',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  });
}

export function formatTranscriptionBlocksText(blocks) {
  return blocks.reduce((formattedText, block) => {
    const fullText = block.texts.map((text) => text.text).join(' ');
    if (block.type === CAPTION_MESSAGE_TYPE.SYSTEM) {
      formattedText += `[${fullText}]\n\n`;
    } else {
      formattedText += `${block.participantDisplayName} (${block.formattedTime}):\n`;
      formattedText += `${fullText}\n\n`;
    }
    return formattedText;
  }, '');
}
