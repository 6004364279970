/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-car': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.05 3.45c.15-.3.45-.45.75-.45h14.4c.3 0 .6.15.75.45l2.25 7.05H1.8l2.25-7.05zM0 11.1c0 .15 0 .15 0 0v10.65c0 .45.3.75.75.75h6c.45 0 .75-.3.75-.75V19.5h9v2.25c0 .45.3.75.75.75h6c.45 0 .75-.3.75-.75v-10.8L21.3 3c-.3-.9-1.2-1.5-2.1-1.5H4.8c-.9 0-1.8.6-2.1 1.5L0 11.1zm18 8.4h4.5V21H18v-1.5zm4.5-1.5h-21v-6h21v6zm-21 1.5H6V21H1.5v-1.5zM6 15c0 .9-.6 1.5-1.5 1.5S3 15.9 3 15s.6-1.5 1.5-1.5S6 14.1 6 15zm13.5 1.5c.9 0 1.5-.6 1.5-1.5s-.6-1.5-1.5-1.5-1.5.6-1.5 1.5.6 1.5 1.5 1.5z"/>'
  }
})
