/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-inbox-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 12l6-6h-4.5V0h-3v6H6l6 6z"/><path pid="1" d="M22.5 0H18v3h3v12H3V3h3V0H1.5C.6 0 0 .6 0 1.5v21c0 .9.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5v-21c0-.9-.6-1.5-1.5-1.5zM3 21v-3h18v3H3z"/>'
  }
})
