/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-save-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 0c.45 0 .75.15 1.05.45l6 6c.3.3.45.6.45 1.05v15c0 .9-.6 1.5-1.5 1.5h-21C.6 24 0 23.4 0 22.5v-21C0 .6.6 0 1.5 0h3v4.5C4.5 5.4 5.1 6 6 6h7.5c.9 0 1.5-.6 1.5-1.5V0h1.5zm-12 21h15v-4.5c0-.9-.6-1.5-1.5-1.5H6c-.9 0-1.5.6-1.5 1.5V21zm6-21h3v4.5h-3V0z"/>'
  }
})
