/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-telephone-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.44 0a.952.952 0 00-.67.291.962.962 0 00-.27.682V22.08c0 1.06.854 1.919 1.91 1.919h3.817c1.055 0 1.91-.859 1.91-1.919V4.811c0-1.06-.855-1.92-1.91-1.92H3.41V.974A.964.964 0 002.813.07.95.95 0 002.44 0zm8.606 3.851V6.73h8.59v8.919h-8.59v2.594H12v1.92h-.954v2.878h9.545c1.055 0 1.909-.86 1.909-1.92V5.772c0-1.061-.854-1.92-1.91-1.92h-9.544zm2.863 14.392h1.91v1.92h-1.91v-1.92zm3.818 0h1.91v1.92h-1.91v-1.92z"/>'
  }
})
