<template>
  <div
    v-observe-keyboard.prevent.cmd.shift.a="() => toggleMicViaKeyboard()"
    v-observe-keyboard.prevent.cmd.shift.v="() => toggleVideoViaKeyboard()"
    v-observe-keyboard.prevent.cmd.shift.s="
      () => toggleShareScreenClickViaKeyboard()
    "
    v-observe-keyboard.prevent.cmd.shift.r="() => toggleRecordingViaKeyboard()"
    v-observe-keyboard.prevent.cmd.shift.m="() => muteAllViaKeyboard()"
    v-observe-keyboard.prevent.cmd.shift.e="
      () => copyMeetingDetailsViaKeyboard()
    "
    v-observe-keyboard.prevent.cmd.shift.l="() => switchLayoutsViaKeyboard()"
    v-observe-keyboard.prevent.cmd.shift.h="() => toggleHandViaKeyboard()"
    v-observe-keyboard.prevent.cmd.b="() => toggleBeRightBackViaKeyboard()"
    v-observe-keyboard.prevent.cmd.i="() => toggleInfoSideBarViaKeyboard()"
    v-observe-keyboard.prevent.cmd.o="() => toggleChatSideBarViaKeyboard()"
    v-observe-keyboard.prevent.cmd.u="
      () => toggleParticipantsSideBarViaKeyboard()
    "
    class="meeting-buttons unselectable"
    :class="{
      'floating-bottom-bar': floating,
      'hidden-bottom-bar': !show
    }"
  >
    <div class="dock left-dock">
      <div class="mute-btn-container">
        <ToggleAudioButtonPopovers>
          <MeetingActionMenuButton
            class="mic-button"
            :class="{
              'mic-button-enabled': micEnabled,
              'big-button': isMicButtonBig || isNoAudioMode
            }"
            data-cy="mic-button"
            :label="
              isNoAudioMode
                ? $t('meeting_actions.enable_audio')
                : micEnabled
                ? $t('meeting_actions.mute')
                : $t('meeting_actions.unmute')
            "
            :icon="
              isNoAudioMode
                ? 'audio-mute-solid'
                : micEnabled
                ? 'microphone-2-solid'
                : 'mic-mute-solid'
            "
            :disabled="isInitializingPublisher"
            :hover-tooltip="toggleMicBtnTooltip"
            :split="hasMicrophoneAccess"
            :disable-split="
              !hasMicrophoneAccess || isInitializingPublisher || isNoAudioMode
            "
            :flip-menu="true"
            :lists="micMenu"
            @click="
              () =>
                isNoAudioMode
                  ? setIsTurnOnAudioDialogVisible(true)
                  : toggleMic(TOGGLE_MIC_SOURCES.BOTTOM_BAR)
            "
            @speakerDeviceClicked="speakerDeviceClicked"
            @micDeviceClicked="micDeviceClicked"
          >
            <MicVolumeIndication
              v-if="!isInitializingPublisher && micEnabled"
              class="volume-indication"
              :style="micIndicationStyle"
              :volume="micVolume"
          /></MeetingActionMenuButton>
        </ToggleAudioButtonPopovers>
      </div>

      <div class="mute-video-button-container">
        <div
          v-if="showPreviewVideo"
          class="video-preview-container"
          :style="hasCameraAccess ? { 'margin-right': '49px' } : ''"
        >
          <PreviewVideo class="video-preview" :delay="500" />
        </div>
        <MeetingActionMenuButton
          class="camera-button"
          data-cy="camera-button"
          :label="
            videoEnabled
              ? $t('meeting_actions.stop_video')
              : $t('meeting_actions.start_video')
          "
          :icon="videoEnabled ? 'video-solid' : 'video-off-solid'"
          :disabled="isInitializingPublisher"
          :hover-tooltip="toggleVideoBtnTooltip"
          :split="hasCameraAccess"
          :disable-split="isInitializingPublisher || !hasCameraAccess"
          :flip-menu="true"
          :lists="cameraMenu"
          @hover="cameraButtonHovered = true"
          @unhover="cameraButtonHovered = false"
          @click="() => toggleVideo(TOGGLE_CAMERA_SOURCES.BOTTOM_BAR)"
          @blur="blurBackgroundClicked"
          @virtualBackground="virtualBackgroundClicked"
          @cameraDeviceClicked="cameraDeviceClicked"
        />
      </div>
    </div>

    <div class="dock right-dock">
      <MeetingActionMenuButton
        v-if="showRecordingButton"
        class="recording-button"
        data-cy="recording-button"
        :label="
          isUserRecording
            ? $t('meeting_actions.stop_recording')
            : $t('meeting_actions.record')
        "
        :icon="isUserRecording ? 'stop-solid' : 'rec-solid'"
        :loading="isLoadingRecording"
        :active-alert="isUserRecording"
        @click="toggleRecording"
      />

      <MeetingActionMenuButton
        v-if="showCaptionsButton"
        class="captions-button"
        data-cy="captions-button"
        :label="
          isCaptionsEnabled
            ? $t('meeting_actions.hide_captions')
            : $t('meeting_actions.show_captions')
        "
        icon="closed-captioning-solid"
        :loading="isLoadingCaptions"
        :active-info="isCaptionsEnabled"
        @click="toggleCaptions"
      />

      <MeetingActionMenuButton
        v-if="showShareScreenButton"
        class="share-screen-button"
        data-cy="share-screen-button"
        :label="
          isScreenShared
            ? $t('meeting_actions.stop_sharing')
            : $t('meeting_actions.share_screen')
        "
        icon="screen-share-solid"
        :active-info="isScreenShared"
        :disabled="!hasScreenshareAccess"
        :loading="isInitializingScreenshare"
        @click="onScreenshareClick"
      />

      <MeetingActionCustomMenuButton
        v-if="!$screen.sm && !$screen.xs"
        ref="reactionsMenu"
        class="reactions-menu"
        data-cy="reactions-button"
        :title="$t('meeting_actions.reactions_title')"
        iconName="emoji-solid"
      >
        <div class="reactions-container">
          <div
            v-for="reaction in reactions"
            :key="reaction.value"
            class="reaction-icon"
            @click="sendReactionToAll(reaction)"
          >
            <img :src="reaction.path" :alt="reaction.value" />
          </div>
        </div>

        <div v-if="exclusiveExperimentalMode && isSessionOwner">
          <div class="separator" />
          <vwc-list-item
            v-tooltip="{
              content: reactionsCounterTooltip,
              placement: 'top',
              classes: 'reactions-counter-tooltip'
            }"
            graphic="icon"
            hasMeta
            @click="toggleReactionsCounter"
          >
            <vwc-icon slot="graphic" type="happy-line" size="small" />
            {{ $t('meeting_actions.reactions_counter') }}
            <vwc-icon
              v-if="isReactionsCounterModalOpen"
              slot="meta"
              data-cy="layout-selected-icon"
              type="check-line"
              size="small"
            />
          </vwc-list-item>
        </div>

        <div class="separator" />
        <vwc-button
          :label="
            isMyParticipantRaisingHand
              ? $t('meeting_actions.lower_hand')
              : $t('meeting_actions.raise_hand')
          "
          class="raise-hand-btn"
          layout="filled"
          :icon="isMyParticipantRaisingHand ? 'hand-off-line' : 'hand-line'"
          @click="toggleRaiseHand"
        />
      </MeetingActionCustomMenuButton>

      <MeetingActionMenuButton
        v-if="showPrivacyButton"
        id="privacy-button"
        data-cy="privacy-button"
        :label="$t('meeting_actions.privacy')"
        icon="shield-solid"
        :lists="[{ items: privacyMenuItems }]"
        @lockMeeting="toggleMeetingLock"
        @toggleWaitingRoom="toggleWaitingRoom"
      />

      <MeetingActionMenuButton
        v-if="!$screen.xs"
        data-cy="more-button"
        :label="$t('meeting_actions.more')"
        icon="more-vertical-solid"
        :loading="isInitializingWhiteboard"
        :lists="moreMenuLists"
        @whiteboard="toggleWhiteboard"
        @watchTogether="watchTogetherClicked"
        @roundTable="roundTableClicked"
        @lockMeeting="toggleMeetingLock"
        @shareScreen="toggleScreenshare"
        @record="toggleRecording"
        @captions="toggleCaptions"
        @transcript="liveTranscriptionClicked"
        @settings="openSettings"
        @toggleWaitingRoom="toggleWaitingRoom"
        @reportIssue="reportIssue"
        @qna="toggleQna"
      />

      <vwc-button
        data-cy="end-meeting-button"
        :label="endMeetingButtonText"
        layout="filled"
        connotation="alert"
        @click="showEndMeetingDialog"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ToggleAudioButtonPopovers from '@/components/popovers/toggleAudioButton/ToggleAudioButtonPopovers.vue';
import {
  isScreenshareStream,
  reactionOptions
} from '@/helpers/meeting-helpers';
import MeetingActionCustomMenuButton from './MeetingActionCustomMenuButton.vue';
import MeetingActionMenuButton from './MeetingActionMenuButton.vue';
import PreviewVideo from '@/components/PreviewVideo.vue';
import MicVolumeIndication from '@/components/MicVolumeIndication.vue';
import {
  ANALYTICS,
  ANALYTICS_SOURCE,
  SETTING_ANALYTIC_TYPE,
  VIRTUAL_BACKGROUND_TYPE,
  SIDEBARS,
  KEYBOARD_SHORTCUTS,
  LAYOUT_MODE_TYPES,
  SUPPORTED_LOCALES,
  TOGGLE_MIC_SOURCES,
  TOGGLE_CAMERA_SOURCES
} from '@/consts/global-consts';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import {
  isElectron,
  isHijackScreenShareSupported
} from '@/helpers/global-helpers';
import MeetingManager from '@/services/meeting-manager-service';
import analytics from '@/services/analytics-service';
import { getAvailableDevices } from '@/helpers/devices-utils';
import { clipboardService } from '@/services/clipboard-service';
import i18n from '@/i18n';

export default {
  name: 'MeetingActions',

  components: {
    ToggleAudioButtonPopovers,
    PreviewVideo,
    MeetingActionMenuButton,
    MeetingActionCustomMenuButton,
    MicVolumeIndication
  },

  props: {
    show: {
      type: Boolean,
      default: true
    },
    floating: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      cameraButtonHovered: false,
      isInitializingWhiteboard: false,
      TOGGLE_MIC_SOURCES: TOGGLE_MIC_SOURCES,
      TOGGLE_CAMERA_SOURCES: TOGGLE_CAMERA_SOURCES
    };
  },

  computed: {
    ...mapState([
      'isNoAudioMode',
      'isMicEnabled',
      'isVideoEnabled',
      'microphoneDevices',
      'cameraDevices',
      'speakerDevices',
      'selectedMicrophoneId',
      'selectedCameraId',
      'selectedSpeakerId',
      'streams',
      'hasCameraPermissions',
      'hasMicrophonePermissions',
      'hasCameraDevices',
      'hasMicrophoneDevices',
      'isLocked',
      'roomDetails',
      'participantsVolumes',
      'myParticipantId',
      'virtualBackground',
      'shouldToggleBlurHaveFocusAnimation',
      'activeSidebar',
      'isEndMeetingDialogVisible',
      'initialJoinConfig'
    ]),
    ...mapState('loudnessDetector', ['muteIndication']),
    ...mapState('standup', ['isStandupOn']),
    ...mapState('raiseHand', ['raisedHands']),
    ...mapState('watchTogether/ongoing', ['isWatchTogetherActive']),
    ...mapState('whiteboard', ['isWhiteboardActive']),
    ...mapState('recordings', ['isLoadingRecording']),
    ...mapState('captions', [
      'isCaptionsEnabled',
      'isLoadingCaptions',
      'isLiveTranscriptionEnabled'
    ]),
    ...mapState('qna', ['isQnaSessionActive']),
    ...mapState('reactionsCounter', ['isReactionsCounterModalOpen']),
    ...mapState('layout', ['isSidebarCollapsed', 'layoutMode']),
    ...mapGetters([
      'isSessionOwner',
      'hasCameraAccess',
      'hasMicrophoneAccess',
      'isInitializingPublisher',
      'showToggleAudioButtonPopper',
      'isScreenShared',
      'isInitializingScreenshare',
      'isGuest',
      'isWaitingRoomEnabled',
      'isVirtualBackgroundSupported',
      'isWhiteboardFeatureEnabled',
      'isInitializingVirtualBackground',
      'isBlurBackgroundEnabled',
      'isCaptionsFeatureAvailable',
      'participantDisplayName',
      'displayNameOfParticipantSharingScreen',
      'detailsStringToCopy',
      'activeParticipants'
    ]),
    ...mapGetters('screenshare', ['hasScreenshareAccess']),
    ...mapGetters('recordings', [
      'isRecording',
      'isUserAllowedToRecord',
      'isNonOwnerAllowedToRecord',
      'isUserRecording'
    ]),
    ...mapGetters('waitingRoom', ['isWaitingRoomEmpty']),
    ...mapGetters('qna', ['isQnaSessionOwner']),
    ...mapGetters('settings', ['exclusiveExperimentalMode']),
    ...mapGetters('raiseHand', ['isMyParticipantRaisingHand']),
    ...mapState('beRightBack', ['isBeRightBackOn']),

    actionItems() {
      return {
        LOCK: {
          id: 'lockMeeting',
          action: 'lockMeeting',
          label: this.$t('meeting_actions.lock_meeting'),
          iconName: 'lock-line',
          type: 'interactive'
        },
        WAITING_ROOM: {
          id: 'toggleWaitingRoom',
          action: 'toggleWaitingRoom',
          label: this.$t('meeting_actions.enable_waiting_room'),
          iconName: 'user-clock-line',
          type: 'interactive'
        },
        WHITEBOARD: {
          id: 'whiteboard',
          action: 'whiteboard',
          label: this.$t('meeting_actions.whiteboard'),
          iconName: 'video-whiteboard-line'
        },
        WATCH_TOGETHER: {
          id: 'watchTogether',
          action: 'watchTogether',
          label: this.$t('meeting_actions.watch_together'),
          iconName: 'file-video-2-line'
        },
        ROUND_TABLE: {
          id: 'roundTable',
          action: 'roundTable',
          label: this.$t('meeting_actions.roundtable'),
          iconName: 'user-sync-line'
        },
        SHARE_SCREEN: {
          id: 'shareScreen',
          action: 'shareScreen',
          label: this.$t('meeting_actions.share_screen'),
          iconName: 'screen-share-solid'
        },
        RECORD: {
          id: 'record',
          action: 'record',
          label: this.$t('meeting_actions.record'),
          iconName: 'rec-solid'
        },
        BLUR: {
          id: 'blur',
          action: 'blur',
          label: this.$t('meeting_actions.blur_my_background'),
          iconName: 'blur-line',
          type: 'interactive'
        },
        VIRTUAL_BACKGROUND: {
          id: 'virtualBackground',
          action: 'virtualBackground',
          label: this.$t('meeting_actions.select_virtual_background'),
          iconName: 'texture-line'
        },
        SETTINGS: {
          id: 'settings',
          action: 'settings',
          label: this.$t('meeting_actions.settings'),
          iconName: 'gear-line'
        },
        LIVE_CAPTIONS: {
          id: 'captions',
          action: 'captions',
          label: this.$t('meeting_actions.live_captions'),
          iconName: 'closed-captioning-line'
        },
        LIVE_TRANSCRIPTION: {
          id: 'transcript',
          action: 'transcript',
          label: this.$t('meeting_actions.live_transcription'),
          iconName: 'voicemail-transcript-line',
          disabled: this.isLiveTranscriptionEnabled
        },
        LEAVE_FEEDBACK: {
          id: 'leave-feedback',
          action: 'reportIssue',
          label: this.$t('meeting_actions.leave_feedback'),
          iconName: 'feedback-line'
        },
        QUESTIONS_AND_ANSWERS: {
          id: 'questions-and-answers',
          action: 'qna',
          label: this.$t('meeting_actions.qna'),
          iconName: 'group-8-line',
          disabled: this.isQnAButtonDisabled
        }
      };
    },

    isParticipantMutedVideo() {
      return !this.participantVideoStream?.hasVideo;
    },

    isParticipantMutedAudio() {
      return !this.participantVideoStream?.hasAudio;
    },

    participantVideoStream() {
      return this.participantStreams.find(
        (stream) => !isScreenshareStream(stream)
      );
    },

    participantStreams() {
      return this.streams.filter(
        (stream) => stream.participantId === this.myParticipantId
      );
    },

    participantScreenStream() {
      return this.participantStreams.find((stream) =>
        isScreenshareStream(stream)
      );
    },

    reactionsCounterTooltip() {
      return this.$t(
        'reactions_counter_modal.reactions_counter_owner_tool_tip'
      );
    },

    isMicButtonBig() {
      const languagesWithLongMicTexts = [
        SUPPORTED_LOCALES.ITALIAN,
        SUPPORTED_LOCALES.HEBREW,
        SUPPORTED_LOCALES.FRENCH,
        SUPPORTED_LOCALES.GERMAN,
        SUPPORTED_LOCALES.CHINESE_MAINLAND,
        SUPPORTED_LOCALES.CHINESE_TAIWAN
      ];
      return languagesWithLongMicTexts.includes(this.$i18n.locale);
    },

    micIndicationStyle() {
      const bottom = isElectron() ? 50 : 51;
      const left = this.isMicButtonBig ? 35 : 29.5;
      return { bottom: `${bottom}px`, left: `${left}%` };
    },

    micEnabled() {
      return this.hasMicrophoneAccess && this.isMicEnabled;
    },

    videoEnabled() {
      return this.hasCameraAccess && this.isVideoEnabled;
    },

    toggleMicBtnTooltip() {
      // Don't show this tooltip if we show the mute indication
      if (this.muteIndication) {
        return null;
      }

      if (!this.hasMicrophoneDevices) {
        return this.$t('meeting_actions.no_connected_microphone');
      } else if (!this.hasMicrophoneAccess) {
        return this.$t('meeting_actions.missing_microphone_permissions');
      }

      return null;
    },

    toggleVideoBtnTooltip() {
      if (!this.hasCameraDevices) {
        return this.$t('meeting_actions.no_connected_camera');
      } else if (!this.hasCameraAccess) {
        return this.$t('meeting_actions.missing_camera_permissions');
      }
      return null;
    },

    endMeetingButtonText() {
      return this.isSessionOwner
        ? this.$t('meeting_actions.end_meeting')
        : this.$t('meeting_actions.leave_meeting');
    },

    reactions() {
      return Object.values(reactionOptions());
    },

    showRecordingButton() {
      return (
        this.isUserAllowedToRecord &&
        (this.$screen.xl || this.$screen.lg || this.$screen.xxl)
      );
    },

    showPrivacyButton() {
      return this.isSessionOwner && (this.$screen.xl || this.$screen.xxl);
    },

    showCaptionsButton() {
      return (
        this.$screen.xxl &&
        this.isCaptionsFeatureAvailable &&
        this.exclusiveExperimentalMode
      );
    },

    showShareScreenButton() {
      return this.$screen.lg || this.$screen.xl || this.$screen.xxl;
    },

    showPreviewVideo() {
      return (
        this.cameraButtonHovered && this.hasCameraAccess && !this.isVideoEnabled
      );
    },

    screenSizes() {
      return {
        mediumOrLarger: !this.$screen.xs && !this.$screen.sm,
        mediumOrSmaller:
          !this.$screen.lg && !this.$screen.xl && !this.$screen.xxl,
        largeOrSmaller: !this.$screen.xl && !this.$screen.xxl,
        extraLargeOrSmaller: !this.$screen.xxl
      };
    },

    isQnAButtonDisabled() {
      return !this.isQnaSessionOwner && this.isQnaSessionActive;
    },

    canToggleWaitingRoom() {
      return this.roomDetails.type !== 'group';
    },

    // TODO: Maybe should create a specific module for the menu
    moreMenuLists() {
      // Add privacy to "More" menu if user is session owner + screen size is large or smaller
      const addPrivacyMenu =
        this.screenSizes.largeOrSmaller && this.isSessionOwner;

      const lists = [];
      lists.push({ items: this.appsMenuItems });
      lists.push({ items: this.capabilitiesMenuItems });
      if (addPrivacyMenu) {
        lists.push({ items: this.privacyMenuItems });
      }
      lists.push({ items: this.generalMenuItems });
      return lists.filter((list) => list.items.length > 0);
    },

    appsMenuItems() {
      const items = [];
      // Add Whiteboard if whiteboard feature is available + screen size is medium or larger
      const addWhiteboard =
        this.screenSizes.mediumOrLarger && this.isWhiteboardFeatureEnabled;
      // Add Watch Together if screen size is medium or larger
      const addWatchTogether = this.screenSizes.mediumOrLarger;
      // Add Round Table if screen size is medium or larger
      const addRoundTable = this.screenSizes.mediumOrLarger;
      // Add Q&A if screen size is medium or larger and experimental mode enabled
      const addQnA =
        this.screenSizes.mediumOrLarger && this.exclusiveExperimentalMode;

      if (addWhiteboard) {
        items.push({
          ...this.actionItems.WHITEBOARD,
          active: this.isWhiteboardActive
        });
      }
      if (addWatchTogether) {
        items.push({
          ...this.actionItems.WATCH_TOGETHER,
          active: this.isWatchTogetherActive
        });
      }
      if (addRoundTable) {
        items.push({
          ...this.actionItems.ROUND_TABLE,
          active: this.isStandupOn
        });
      }
      if (addQnA) {
        items.push({
          ...this.actionItems.QUESTIONS_AND_ANSWERS,
          active: this.isQnaSessionActive
        });
      }

      return items;
    },

    capabilitiesMenuItems() {
      const capabilitiesItems = [];

      // Add Captions if screen size between medium and extra large
      // and experimental mode enabled and captions feature is available
      const addCaptions =
        this.screenSizes.mediumOrLarger &&
        this.screenSizes.extraLargeOrSmaller &&
        this.exclusiveExperimentalMode &&
        this.isCaptionsFeatureAvailable;
      // Add Live transcription if screen size is medium or larger
      // and experimental mode enabled and captions feature is available
      const addLiveTranscription =
        this.screenSizes.mediumOrLarger &&
        this.exclusiveExperimentalMode &&
        this.isCaptionsFeatureAvailable;
      // Add Recording if user is allowed to record + screen size is medium or smaller
      const addRecording =
        this.screenSizes.mediumOrSmaller && this.isUserAllowedToRecord;
      // Add Share Screen if screen size is medium or smaller
      const addShareScreen = this.screenSizes.mediumOrSmaller;

      if (addCaptions) {
        capabilitiesItems.push({
          ...this.actionItems.LIVE_CAPTIONS,
          active: this.isCaptionsEnabled
        });
      }

      if (addLiveTranscription) {
        capabilitiesItems.push({
          ...this.actionItems.LIVE_TRANSCRIPTION,
          active: this.isLiveTranscriptionEnabled
        });
      }

      if (addRecording) {
        capabilitiesItems.push({
          ...this.actionItems.RECORD,
          iconName: 'rec-line',
          active: this.isUserRecording
        });
      }

      if (addShareScreen) {
        capabilitiesItems.push({
          ...this.actionItems.SHARE_SCREEN,
          iconName: 'screen-share-line',
          active: this.isScreenShared
        });
      }

      return capabilitiesItems;
    },

    privacyMenuItems() {
      const privacyMenuItems = [
        { ...this.actionItems.LOCK, active: this.isLocked }
      ];

      if (this.canToggleWaitingRoom) {
        // Add waiting room as the first item
        privacyMenuItems.unshift({
          ...this.actionItems.WAITING_ROOM,
          active: this.isWaitingRoomEnabled
        });
      }

      return privacyMenuItems;
    },

    generalMenuItems() {
      const generalItems = [
        {
          ...this.actionItems.LEAVE_FEEDBACK
        },
        {
          ...this.actionItems.SETTINGS
        }
      ];
      return generalItems;
    },

    cameraMenu() {
      const lists = [];

      if (!this.hasCameraAccess) {
        return lists;
      }

      const cameraDevicesItems = getAvailableDevices(
        this.cameraDevices,
        this.selectedCameraId
      );
      lists.push({
        label: this.$t('meeting_actions.camera_devices_title'),
        items: cameraDevicesItems,
        defaultAction: 'cameraDeviceClicked'
      });

      if (this.isVirtualBackgroundSupported) {
        const items = [];
        items.push({
          ...this.actionItems.BLUR,
          active: this.isBlurBackgroundEnabled,
          disabled:
            this.isInitializingPublisher ||
            this.isInitializingVirtualBackground ||
            this.initialJoinConfig.backgroundDisabled,
          shouldHaveFocusAnimation: this.shouldToggleBlurHaveFocusAnimation,
          animationFinishedAction: () =>
            this.setShouldToggleBlurHaveFocusAnimation(false)
        });

        items.push({
          ...this.actionItems.VIRTUAL_BACKGROUND,
          disabled:
            this.isInitializingPublisher ||
            this.initialJoinConfig.backgroundDisabled
        });
        lists.push({ items });
      }

      return lists;
    },

    micMenu() {
      const lists = [];

      if (!this.hasMicrophoneAccess) {
        return lists;
      }

      const speakerDevicesItems = getAvailableDevices(
        this.speakerDevices,
        this.selectedSpeakerId
      );
      lists.push({
        label: this.$t('meeting_actions.speaker_devices_title'),
        items: speakerDevicesItems,
        defaultAction: 'speakerDeviceClicked'
      });

      const micDevicesItems = getAvailableDevices(
        this.microphoneDevices,
        this.selectedMicrophoneId
      );
      lists.push({
        label: this.$t('meeting_actions.mic_devices_title'),
        items: micDevicesItems,
        defaultAction: 'micDeviceClicked'
      });

      return lists;
    },

    micVolume() {
      return this.participantsVolumes[this.myParticipantId];
    }
  },

  mounted() {
    window.Appcues?.on('step_interacted', (event) => {
      // blur background Appcues
      if (
        event.stepId === 'b103fbd0-71b3-4cf8-96e1-636fe2cbe259' &&
        event.interaction.destination === 'end'
      ) {
        document
          .getElementsByClassName('camera-button')[0]
          .getElementsByClassName('split')[0]
          .click();
        this.setShouldToggleBlurHaveFocusAnimation(true);
      }
      // virtual background
      if (
        event.stepId === '5017ce77-bb19-4c51-8b07-8438b7e6ff36' &&
        event.interaction.destination === 'end'
      ) {
        this.virtualBackgroundClicked();
      }
    });
  },

  methods: {
    ...mapActions([
      'toggleMic',
      'toggleVideo',
      'setIsTurnOnAudioDialogVisible',
      'setIsEndMeetingDialogVisible',
      'setInfoDialog',
      'setLockMeeting',
      'setConfirmationDialog',
      'setIsLockMeetingDialogVisible',
      'stopScreenshare',
      'addFlashMessage',
      'selectVirtualBackground',
      'saveVirtualBackground',
      'setIsVirtualBackgroundModalVisible',
      'setShowReportIssueModal',
      'setIsHijackScreenshareDialogVisible',
      'setShouldToggleBlurHaveFocusAnimation',
      'setIsSettingsModalVisible',
      'selectSpeakerDevice',
      'selectMicrophoneDevice',
      'selectCameraDevice',
      'forceMuteSession',
      'setActiveSidebar'
    ]),
    ...mapActions('screenshare', [
      'toggleScreenshare',
      'showMissingScreenshareAccess'
    ]),
    ...mapActions('recordings', ['startRecording', 'stopRecording']),
    ...mapActions('reactions', ['sendReaction']),
    ...mapActions('raiseHand', ['toggleMyHand']),
    ...mapActions('standup', ['startStandup', 'focusStandup']),
    ...mapActions('qna', ['toggleQna']),
    ...mapActions('watchTogether/modal', ['openWatchTogetherModal']),
    ...mapActions('whiteboard', [
      'startWhiteboard',
      'emitStopWhiteboardEvent',
      'pinWhiteboard'
    ]),
    ...mapActions('captions', ['toggleCaptions', 'enableLiveTranscription']),
    ...mapActions('waitingRoom', {
      _toggleWaitingRoom: 'toggleWaitingRoom',
      setIsDisableWaitingRoomModalVisible: 'setIsDisableWaitingRoomModalVisible'
    }),
    ...mapActions('settings', ['sendSettingsAnalytics']),
    ...mapActions('reactionsCounter', [
      'setIsReactionsCounterModalOpen',
      'sendStartReactionsCounterSignal',
      'sendStopReactionsCounterSignal'
    ]),
    ...mapActions('beRightBack', ['toggleBeRightBackStatus']),
    ...mapActions('raiseHand', ['toggleMyHand']),
    ...mapActions('layout', {
      _toggleSidebar: 'toggleSidebar',
      setLayoutMode: 'setLayoutMode'
    }),

    sendKeyboardShortcutAnalytic(type) {
      analytics.trackEvent(ANALYTICS.KEYBOARD_SHORTCUTS, {
        Type: type
      });
    },

    switchLayoutsViaKeyboard() {
      // Switch between speaker mode to gallery mode
      if (this.layoutMode === LAYOUT_MODE_TYPES.GRID) {
        this.setLayoutMode({ layoutMode: LAYOUT_MODE_TYPES.SPEAKER });
      } else if (this.layoutMode === LAYOUT_MODE_TYPES.SPEAKER) {
        this.setLayoutMode({ layoutMode: LAYOUT_MODE_TYPES.GRID });
      } else {
        return;
      }
      this.sendKeyboardShortcutAnalytic(KEYBOARD_SHORTCUTS.SWITCH_LAYOUTS);
    },

    copyMeetingDetailsViaKeyboard() {
      clipboardService.copy(this.detailsStringToCopy);

      analytics.trackEvent(ANALYTICS.INFO_COPIED, {
        Source: 'Keyboard shortcuts',
        'Num of Participants': this.activeParticipants.length,
        'Info copied': 'Details'
      });

      this.sendKeyboardShortcutAnalytic(KEYBOARD_SHORTCUTS.COPY_MEETINGS_INFO);

      this.addFlashMessage({
        time: 4000,
        type: 'good',
        text: i18n.t(
          'empty_video_screen.meeting_info_has_been_copied_flash_message'
        )
      });
    },

    toggleMicViaKeyboard() {
      if (!this.isInitializingPublisher) {
        this.toggleMic(this.TOGGLE_MIC_SOURCES.KEYBOARD_SHORTCUT);
      }
      this.sendKeyboardShortcutAnalytic(KEYBOARD_SHORTCUTS.TOGGLE_MICROPHONE);
    },

    toggleVideoViaKeyboard() {
      if (!this.isInitializingPublisher) {
        this.toggleVideo(this.TOGGLE_CAMERA_SOURCES.KEYBOARD_SHORTCUT);
      }
      this.sendKeyboardShortcutAnalytic(KEYBOARD_SHORTCUTS.TOGGLE_CAMERA);
    },

    toggleShareScreenClickViaKeyboard() {
      if (!this.isInitializingScreenshare) {
        this.onScreenshareClick();
      }
      this.sendKeyboardShortcutAnalytic(KEYBOARD_SHORTCUTS.TOGGLE_SHARESCREEN);
    },

    toggleBeRightBackViaKeyboard() {
      this.toggleBeRightBackStatus();
      if (this.isBeRightBackOn) {
        analytics.trackEvent(ANALYTICS.BE_RIGHT_BACK_STATUS);
        if (!this.isParticipantMutedAudio) {
          this.toggleMic();
        }
        if (!this.isParticipantMutedVideo) {
          this.toggleVideo();
        }
      } else {
        analytics.trackEvent(ANALYTICS.I_AM_BACK_STATUS);
      }
      this.sendKeyboardShortcutAnalytic(
        KEYBOARD_SHORTCUTS.TOGGLE_BE_RIGHT_BACK
      );
    },

    toggleHandViaKeyboard() {
      this.toggleMyHand();
      this.sendKeyboardShortcutAnalytic(KEYBOARD_SHORTCUTS.TOGGLE_RAISE_HAND);
    },

    muteAllViaKeyboard() {
      if (!this.isSessionOwner) {
        return;
      }
      this.forceMuteSession();
      analytics.trackEvent(ANALYTICS.OWNER_MUTE, {
        Type: 'All'
      });
      this.sendKeyboardShortcutAnalytic(KEYBOARD_SHORTCUTS.HOST_MUTE_ALL);
    },

    toggleInfoSideBarViaKeyboard() {
      this.toggleSidebar(SIDEBARS.INFO);
      this.sendKeyboardShortcutAnalytic(KEYBOARD_SHORTCUTS.TOGGLE_INFO_TAB);
    },

    toggleChatSideBarViaKeyboard() {
      this.toggleSidebar(SIDEBARS.CHAT);
      this.sendKeyboardShortcutAnalytic(KEYBOARD_SHORTCUTS.TOGGLE_CHAT_TAB);
    },

    toggleParticipantsSideBarViaKeyboard() {
      this.toggleSidebar(SIDEBARS.PARTICIPANTS);
      this.sendKeyboardShortcutAnalytic(
        KEYBOARD_SHORTCUTS.TOGGLE_PARTICIPANTS_TAB
      );
    },

    toggleSidebar(sidebar) {
      if (this.isSidebarCollapsed || this.activeSidebar === sidebar) {
        this._toggleSidebar();
      }
      this.setActiveSidebar(sidebar);
    },

    toggleReactionsCounter() {
      this.setIsReactionsCounterModalOpen(!this.isReactionsCounterModalOpen);

      analytics.trackEvent(ANALYTICS.REACTIONS_COUNTER, {
        Action: this.isReactionsCounterModalOpen ? 'Open' : 'Close'
      });

      if (this.isReactionsCounterModalOpen) {
        this.sendStartReactionsCounterSignal({});
      } else {
        this.sendStopReactionsCounterSignal();
      }
      this.closeReactionsMenu();
    },

    reportIssue() {
      this.setShowReportIssueModal(true);
    },

    onScreenshareClick() {
      if (this.hasScreenshareAccess) {
        this.shareScreen();
      } else {
        this.showMissingScreenshareAccess();
      }
    },

    showEndMeetingDialog() {
      this.setIsEndMeetingDialogVisible(true);
    },

    shareScreen() {
      if (!this.isScreenShared) {
        const screenStream = this.streams.find((stream) =>
          isScreenshareStream(stream)
        );
        if (screenStream) {
          if (isHijackScreenShareSupported()) {
            return this.setIsHijackScreenshareDialogVisible(true);
          } else {
            return this.addFlashMessage({
              time: 7000,
              type: 'shoutout',
              title: this.$t(
                'meeting_actions.other_participant_sharing_screen_safari_browser',
                {
                  participantDisplayName: this
                    .displayNameOfParticipantSharingScreen
                }
              ),
              button: {
                text: this.$t('meeting_actions.got_it'),
                actions: {
                  name: 'removeSelf'
                }
              }
            });
          }
        }
      }
      this.toggleScreenshare();
      logger.log('share-screen-button-clicked', LOG_CATEGORIES.UI_INTERACTION);
    },

    async toggleRecording() {
      if (this.isRecording) {
        if (this.roomDetails.isAutoRecorded) {
          return this.setInfoDialog({
            title: this.$t('meeting_actions.recording'),
            text: this.$t('meeting_actions.cannot_stop_recording')
          });
        }
        await this.stopRecording();
      } else if (this.isWhiteboardActive) {
        // Show alert for whiteboard being excluded from the session recording
        this.setConfirmationDialog({
          title: this.$t('meeting_actions.recording_whiteboard'),
          text: this.$t('meeting_actions.whiteboard_not_included_in_recording'),
          okButtonText: this.$t('meeting_actions.start_recording'),
          actionName: 'recordings/startRecording'
        });
      } else if (this.isUserAllowedToRecord) {
        await this.startRecording();
      }
      logger.log('recording-button-clicked', LOG_CATEGORIES.UI_INTERACTION);
    },

    async toggleRecordingViaKeyboard() {
      if (this.isGuest) {
        return;
      }
      await this.toggleRecording();
      this.sendKeyboardShortcutAnalytic(KEYBOARD_SHORTCUTS.TOGGLE_RECORDINGS);
    },

    async toggleMeetingLock() {
      if (!this.isLocked) {
        this.setIsLockMeetingDialogVisible(true);
      } else {
        await this.setLockMeeting(false);
      }
    },

    sendReactionToAll(reaction) {
      this.sendReaction(reaction.value);
      this.closeReactionsMenu();
    },

    watchTogetherClicked() {
      this.openWatchTogetherModal();
    },

    liveTranscriptionClicked() {
      this.enableLiveTranscription();
    },

    roundTableClicked() {
      if (!this.isStandupOn) {
        this.startStandup();
      } else {
        this.focusStandup();
      }
    },

    async toggleWhiteboard() {
      if (this.isWhiteboardActive) {
        // Pin the whiteboard to make sure that the user will see the Stop Whiteboard modal
        this.pinWhiteboard();
        this.emitStopWhiteboardEvent();
      } else if (this.isRecording) {
        this.setConfirmationDialog({
          title: this.$t('meeting_actions.recording_whiteboard'),
          text: this.$t('meeting_actions.whiteboard_not_included_in_recording'),
          okButtonText: this.$t('meeting_actions.start_whiteboard'),
          actionName: 'whiteboard/startWhiteboard'
        });
      } else {
        this.isInitializingWhiteboard = true;
        try {
          await this.startWhiteboard();
        } finally {
          this.isInitializingWhiteboard = false;
        }
      }
    },

    toggleRaiseHand() {
      this.toggleMyHand();
      this.closeReactionsMenu();
    },

    closeReactionsMenu() {
      if (this.$refs.reactionsMenu) {
        this.$refs.reactionsMenu.close();
      }
    },

    async blurBackgroundClicked() {
      try {
        const virtualBackground = {
          type: this.isBlurBackgroundEnabled
            ? VIRTUAL_BACKGROUND_TYPE.NONE
            : VIRTUAL_BACKGROUND_TYPE.BLUR,
          background: null
        };
        await this.selectVirtualBackground({
          publisher: MeetingManager.publisher,
          currentVirtualBackground: this.virtualBackground,
          newVirtualBackground: virtualBackground
        });
        this.saveVirtualBackground({
          source: ANALYTICS_SOURCE.BOTTOM_BAR,
          virtualBackground: virtualBackground
        });
      } catch (error) {
        logger.error(
          'Bottom Bar - failed to select virtual background',
          LOG_CATEGORIES.TOKBOX,
          error
        );
      }
    },

    virtualBackgroundClicked() {
      this.setIsVirtualBackgroundModalVisible(true);
    },

    cameraDeviceClicked(cameraId) {
      if (this.selectedCameraId !== cameraId) {
        this.selectCameraDevice(cameraId);
        this.sendSettingsAnalytics({
          setting: SETTING_ANALYTIC_TYPE.CAMERA,
          source: ANALYTICS_SOURCE.BOTTOM_BAR
        });
      }
    },

    speakerDeviceClicked(speakerId) {
      if (this.selectedSpeakerId !== speakerId) {
        this.selectSpeakerDevice(speakerId);
        this.sendSettingsAnalytics({
          setting: SETTING_ANALYTIC_TYPE.SPEAKER,
          source: ANALYTICS_SOURCE.BOTTOM_BAR
        });
      }
    },

    micDeviceClicked(micId) {
      if (this.selectedMicrophoneId !== micId) {
        this.selectMicrophoneDevice(micId);
        this.sendSettingsAnalytics({
          setting: SETTING_ANALYTIC_TYPE.MICROPHONE,
          source: ANALYTICS_SOURCE.BOTTOM_BAR
        });
      }
    },

    toggleWaitingRoom() {
      if (this.isWaitingRoomEnabled && !this.isWaitingRoomEmpty) {
        this.setIsDisableWaitingRoomModalVisible(true);
        return;
      }

      this._toggleWaitingRoom();
    },

    openSettings() {
      this.setIsSettingsModalVisible(true);
    }
  }
};
</script>

<!--Using global style to override the v-tooltip css-->
<style>
.tooltip.reactions-counter-tooltip {
  z-index: 200001;
  max-width: 220px;
  text-align: center;
}
</style>

<style scoped>
.mute-btn-container {
  position: relative;
}

.mute-video-button-container {
  position: relative;
}

.video-preview {
  position: absolute;
  bottom: 100%;
  height: 248px;
  width: 283px;
  z-index: 90;
  overflow: hidden;
  box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px,
    rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
}

.video-preview-container {
  display: flex;
  justify-content: center;
}

.meeting-buttons {
  display: flex;
  z-index: 2;
  background: #0d0d0d;
}

.floating-bottom-bar {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1738;
}

.floating-bottom-bar.hidden-bottom-bar {
  transform: translateY(100%);
}

.dock {
  display: flex;
  flex-grow: 1;
}

.dock > *:not(:first-child) {
  margin-left: 10px;
}

.left-dock {
  justify-content: flex-start;
  align-items: center;
  margin-left: 10px;
}

.right-dock {
  justify-content: flex-end;
  align-items: center;
  margin-right: 10px;
}

.reactions-container {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 2px;
  width: 220px;
}

.reaction-icon {
  width: 38px;
  height: 38px;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 5px;
  margin-top: 8px;
}

.reaction-icon:hover {
  background: rgba(255, 255, 255, 0.2);
}

.separator {
  margin-top: 13px;
  margin-bottom: 10px;
  top: 151px;
  border: 1px solid #4d4d4d;
  width: 99%;
  align-self: center;
}

.share-screen-button >>> vwc-button {
  min-width: 124px;
}

.captions-button >>> vwc-button {
  min-width: 134px;
}

.camera-button >>> vwc-button {
  min-width: 109px;
}

.camera-button >>> vwc-list-item {
  min-width: 291px;
}

.mic-button.big-button >>> vwc-button {
  min-width: 135px;
  max-width: 135px;
}

.mic-button:not(.big-button) >>> vwc-button {
  max-width: 86px;
  min-width: 86px;
}

.volume-indication {
  cursor: pointer;
  width: 11px;
}

@media only screen and (max-height: 600px) {
  .camera-button >>> vwc-list-item,
  .mic-button >>> vwc-list-item {
    min-width: 220px;
    max-width: 220px;
  }
}
</style>
