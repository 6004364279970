import HostIFrameCommunication from '@/helpers/embedded/host-iframe-communication';
import { HOST_INBOUND_EVENTS } from './consts';

let hostCommunication;

export default {
  init: ({ dispatch }) => {
    hostCommunication = new HostIFrameCommunication();
    dispatch('registerHostInboundEvents');
  },

  registerHostInboundEvents: ({ dispatch }) => {
    hostCommunication.on(HOST_INBOUND_EVENTS.SET_AUDIO_STATE, (event) => {
      dispatch('handleEventSetAudioState', event.eventData);
    });
    hostCommunication.on(HOST_INBOUND_EVENTS.SET_VIDEO_STATE, (event) => {
      dispatch('handleEventSetVideoState', event.eventData);
    });
  },

  emitEventToHost: (_, { eventName, eventData }) => {
    hostCommunication?.emit(eventName, { event: eventName, ...eventData });
  },

  handleEventAudioState({ rootState, dispatch }, { value }) {
    if (rootState.isMicEnabled !== value) {
      dispatch('toggleMic', null, { root: true });
    }
  },

  handleEventSetVideoState({ rootState, dispatch }, { value }) {
    if (rootState.isVideoEnabled !== value) {
      dispatch('toggleVideo', null, { root: true });
    }
  }
};
