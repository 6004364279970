/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-vonage': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M0 1.7h4.74l4.539 9.984L7.03 16.93a.147.147 0 01-.268.001L0 1.7zm10.939 18.016C11.912 18.195 19.177 1.7 19.177 1.7H24s-7.142 16.233-7.996 17.796c-.751 1.375-2.107 2.931-3.988 2.931h-4.34a.026.026 0 01-.004-.052c1.386-.216 2.136-.891 3.267-2.66z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
