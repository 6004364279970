import videoMetadataService from '@/services/watch-together/video-metadata-service';
import VideoSiteTypeEnum from '@/services/watch-together/video-site-type-enum';
import {
  VideoUrlValidationError,
  VIDEO_URL_ERROR_REASON
} from '@/services/watch-together/video-url-validation-error';
import i18n from '@/i18n';

export default {
  openWatchTogetherModal: ({ commit, getters, dispatch, rootState }) => {
    // Prevent sharing a new video while another participant is already sharing a video
    if (getters.isAnotherParticipantAlreadySharing) {
      dispatch('showAnotherParticipantAlreadySharingDialog');
      return;
    }

    if (rootState.watchTogether.ongoing.isWatchTogetherActive) {
      dispatch('watchTogether/ongoing/stopWatchTogether', null, { root: true });
      return;
    }

    commit('SET_SHOW_VIDEO_SYNC_OVERLAY', true);
  },

  closeWatchTogetherModal: ({ commit }) => {
    commit('SET_SHOW_VIDEO_SYNC_OVERLAY', false);
    commit('RESET_VIDEO_SYNC_STATE');
  },

  updateVideoUrl: async ({ commit }, videoUrl) => {
    commit('SET_IS_LOADING_VIDEO_METADATA', true);
    commit('SET_VALIDATION_ERROR_REASON', null);
    commit('UPDATE_VIDEO_METADATA', null);
    commit('SET_IS_VIDEO_LINK_VALID', false);

    let videoMetadata;
    try {
      videoMetadata = await videoMetadataService.getVideoMetadata(videoUrl);
    } catch (error) {
      if (!(error instanceof VideoUrlValidationError)) {
        throw error;
      }

      if (!videoUrl) {
        return;
      }

      commit('SET_VALIDATION_ERROR_REASON', error.errorReason);
      return;
    } finally {
      commit('SET_IS_LOADING_VIDEO_METADATA', false);
    }

    // TODO: Remove this when support is implemented for other platforms
    if (videoMetadata?.videoSiteType !== VideoSiteTypeEnum.YouTube) {
      commit(
        'SET_VALIDATION_ERROR_REASON',
        VIDEO_URL_ERROR_REASON.UNSUPPORTED_SITE
      );
      return;
    }

    commit('UPDATE_VIDEO_METADATA', videoMetadata);
    commit('SET_IS_VIDEO_LINK_VALID', true);
  },

  showAnotherParticipantAlreadySharingDialog: ({ dispatch, rootGetters }) => {
    const watchTogetherOwnerName =
      rootGetters['watchTogether/ongoing/watchTogetherOwnerName'];

    // Prevent sharing a new video while another participant is already sharing a video
    dispatch(
      'setInfoDialog',
      {
        title: i18n.t('watch_together_modal_actions.title'),
        text: i18n.t(
          'watch_together_modal_actions.another_one_already_share_text',
          { watchTogetherOwnerName }
        )
      },
      { root: true }
    );
  }
};
