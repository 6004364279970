/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-bounce': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2 1h5v1H3v3.5H2V1zM8.1 9.008c3.328 0 5.143.908 5.95 1.412.706.504 1.109 1.412 1.008 2.017-.1.604-.302 1.008-.605 1.21h-.202c-.605 0-1.915-.404-2.42-.606-.604-.302-.705-.907-.705-1.21 0-.302.1-.504-.202-.705-.605-.303-1.412-.404-2.823-.404-1.412 0-2.218.101-2.723.505-.403.302-.201.403-.302.705 0 .303-.1.807-.706 1.21-.504.303-1.815.605-2.42.605H1.75c-.303-.201-.605-.605-.706-1.21-.101-.605.302-1.613 1.008-2.016.908-.605 2.722-1.513 6.05-1.513zM8.1 8c-3.427 0-5.444.907-6.553 1.613C.539 10.32-.167 11.63.034 12.638c.202 1.21.706 1.714 1.11 1.916.201.1.403.202.705.202.807 0 2.32-.404 2.924-.807 1.21-.504 1.311-1.512 1.311-1.815v-.202c.404-.1 1.009-.201 2.017-.201s1.613.1 1.916.201v.101c0 .303.201 1.412 1.21 1.916.604.302 2.117.807 2.924.807.302 0 .504-.101.705-.202.404-.202.908-.706 1.11-1.916.201-1.109-.505-2.42-1.513-3.025C13.445 8.907 11.428 8 8.101 8z"/><path pid="1" d="M7.981 5.344l4.685-4.216.669.744-5.316 4.783L2.688 2.39l.624-.78 4.669 3.734z"/>'
  }
})
