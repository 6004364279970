<template>
  <Dialog
    :heading="$t('select_language_modal.title')"
    icon-name="globe-line"
    :close="hide"
    :min-width="376"
    :primary-action="$t('select_language_modal.primary_action_button')"
    :disable-primary-button="disablePrimaryAction"
    data-cy="select-language-modal"
    @applied="setLocale"
  >
    <vwc-list slot="content">
      <vwc-radio-list-item
        v-for="locale in locales"
        :key="locale.id"
        :selected="locale.value === $i18n.locale"
        @click="setSelectedLocale(locale.value)"
      >
        <vwc-icon class="icon" :type="locale.icon" />
        <span> {{ locale.label }}</span>
      </vwc-radio-list-item>
    </vwc-list>
  </Dialog>
</template>

<script>
import { mapActions } from 'vuex';
import Dialog from '@/components/Dialog';
import { ANALYTICS, SUPPORTED_LOCALES } from '@/consts/global-consts';
import analytics from '@/services/analytics-service';
import { localStorageService } from '@/services/storage-service';

const locales = {
  [SUPPORTED_LOCALES.ENGLISH]: {
    label: 'English',
    icon: 'flag-united-states',
    value: SUPPORTED_LOCALES.ENGLISH
  },
  [SUPPORTED_LOCALES.HEBREW]: {
    label: 'עברית',
    icon: 'flag-israel',
    value: SUPPORTED_LOCALES.HEBREW
  },
  [SUPPORTED_LOCALES.SPANISH]: {
    label: 'Español',
    icon: 'flag-spain',
    value: SUPPORTED_LOCALES.SPANISH
  },
  [SUPPORTED_LOCALES.PORTUGUESE]: {
    label: 'Português',
    icon: 'flag-brazil',
    value: SUPPORTED_LOCALES.PORTUGUESE
  },
  [SUPPORTED_LOCALES.ITALIAN]: {
    label: 'Italiano',
    icon: 'flag-italy',
    value: SUPPORTED_LOCALES.ITALIAN
  },
  [SUPPORTED_LOCALES.CATALAN]: {
    label: 'Catalan',
    icon: 'flag-spain',
    value: SUPPORTED_LOCALES.CATALAN
  },
  [SUPPORTED_LOCALES.FRENCH]: {
    label: 'French',
    icon: 'flag-france',
    value: SUPPORTED_LOCALES.FRENCH
  },
  [SUPPORTED_LOCALES.GERMAN]: {
    label: 'German',
    icon: 'flag-germany',
    value: SUPPORTED_LOCALES.GERMAN
  },
  [SUPPORTED_LOCALES.ARABIC]: {
    label: 'Arabic',
    icon: 'flag-united-arab-emirates',
    value: SUPPORTED_LOCALES.ARABIC
  },
  [SUPPORTED_LOCALES.CHINESE_MAINLAND]: {
    label: 'Chinese (Mainland)',
    icon: 'flag-china',
    value: SUPPORTED_LOCALES.CHINESE_MAINLAND
  },
  [SUPPORTED_LOCALES.CHINESE_TAIWAN]: {
    label: 'Chinese (Taiwan)',
    icon: 'flag-taiwan',
    value: SUPPORTED_LOCALES.CHINESE_TAIWAN
  }
};

export default {
  name: 'SelectLanguageModal',

  components: { Dialog },

  data() {
    return {
      locales,
      selectedLocale: this.$i18n.locale
    };
  },

  computed: {
    disablePrimaryAction() {
      return this.selectedLocale === this.$i18n.locale;
    }
  },

  methods: {
    ...mapActions(['setShowSelectLanguageModal']),

    hide() {
      this.setShowSelectLanguageModal(false);
    },

    setLocale() {
      this.$i18n.locale = this.selectedLocale;
      localStorageService.setItem('lastSelectedLocale', this.selectedLocale);
    },

    setSelectedLocale(selectedLocale) {
      this.selectedLocale = selectedLocale;
      analytics.trackEvent(ANALYTICS.LOCALIZATION, {
        Locale: selectedLocale
      });
    }
  }
};
</script>

<style scoped>
.icon {
  margin-right: 16px;
}

vwc-list {
  --mdc-list-vertical-padding: 0;
}
</style>
