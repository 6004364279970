/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-packet': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.5 3.3l-.3-.3-7-3h-.4l-7 3s-.1 0-.1.1l-.1.1c-.1.1-.1.2-.1.3v9c0 .2.1.4.3.5l7 3h.4l7-3c.2-.1.3-.3.3-.5v-9-.2zm-8 11.4l-6-2.6V4.3l6 2.6v7.8zM8 6L2.3 3.5 8 1l5.7 2.5L8 6zm6.5 6.2l-6 2.6v-8l2.5-1v2.7c0 .3.2.5.5.5s.5-.2.5-.5v-3-.2l2.5-1.1v8z"/>'
  }
})
