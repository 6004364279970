<template>
  <div
    ref="card"
    class="card"
    data-cy="mobile-card"
    :class="{
      collapsed: cardVisibilityState === CARD_VISIBILITY_STATE.COLLAPSED,
      'partially-extended':
        cardVisibilityState === CARD_VISIBILITY_STATE.PARTIALLY_EXTENDED,
      'fully-extended':
        cardVisibilityState === CARD_VISIBILITY_STATE.FULLY_EXTENDED,
      transition: isTransitioning,
      hidden:
        !isTransitioning &&
        cardVisibilityState === CARD_VISIBILITY_STATE.COLLAPSED
    }"
    @transitionend="isTransitioning = false"
  >
    <div
      v-if="extendable"
      class="extender-container"
      data-cy="extender-button"
      @click.stop="toggleCardExtended"
    >
      <v-icon
        :iconName="
          cardVisibilityState === CARD_VISIBILITY_STATE.FULLY_EXTENDED
            ? 'Vlt-icon-down-full'
            : 'Vlt-icon-up-full'
        "
        class="extender"
      />
      <ChatNotification
        v-if="cardVisibilityState !== CARD_VISIBILITY_STATE.FULLY_EXTENDED"
        class="overlaping-notification"
      />
    </div>

    <div class="card-content-container" v-on="$listeners">
      <div class="action-buttons-container">
        <ControlBarToggleButton
          id="toggle-mute-audio-btn"
          data-cy="toggle-mute-audio-btn"
          :disabled="isInitializingPublisher"
          :transparent="isInitializingPublisher || !hasMicrophoneAccess"
          :active="micEnabled"
          :iconName="
            micEnabled
              ? 'Vlt-icon-microphone-full'
              : 'Vlt-icon-microphone-mute-full'
          "
          @click="() => toggleMic(TOGGLE_MIC_SOURCES.MOBILE_WEB_CARD)"
        />

        <ControlBarToggleButton
          id="toggle-mute-video-btn"
          data-cy="toggle-mute-video-btn"
          :disabled="isInitializingPublisher"
          :transparent="isInitializingPublisher || !hasCameraAccess"
          :active="videoEnabled"
          :iconName="
            videoEnabled ? 'Vlt-icon-video-negative' : 'Vlt-icon-video-off-full'
          "
          @click="() => toggleVideo(TOGGLE_CAMERA_SOURCES.MOBILE_WEB_CARD)"
        />

        <ControlBarButton
          id="flip-camera-btn"
          data-cy="flip-camera-btn"
          iconName="Vlt-icon-camera-switch-full"
          :disabled="
            isInitializingPublisher ||
            !videoEnabled ||
            !hasMultipleCameraDevices
          "
          class="flip-btn"
          @click="flipCamera"
        />

        <ControlBarButton
          id="open-leave-meeting-modal-btn"
          data-cy="open-leave-meeting-modal-btn"
          iconName="Vlt-icon-cross-full"
          destructive
          @click="showLeaveMeetingDialog"
        />
      </div>

      <div v-if="extendable" ref="meetingInfo" class="meeting-info">
        <MobileSelect
          v-if="shouldShowAudioOptions"
          v-model="currentMicrophoneId"
          :options="microphoneDeviceOptions"
          class="select-audio-source"
          data-cy="select-audio-source"
        >
          <div class="selected-microphone">
            <v-icon iconName="Vlt-icon-audio-min-full" />
            <span class="text-ellipsis">{{
              $t('mobile_card.selected_audio_source', {
                currentMicrophoneLabel
              })
            }}</span>
          </div>
        </MobileSelect>

        <div
          v-if="isChatFeatureEnabled"
          data-cy="meeting-chat-container"
          class="meeting-chat-container"
          @click="chatClicked"
        >
          <v-icon icon-name="Vlt-icon-chat-full" class="chat-icon md" />
          <div class="meeting-chat-title">
            {{ $t('mobile_card.meeting_chat_title') }}
          </div>
          <ChatNotification
            v-if="unreadMessagesCount !== 0"
            class="chat-notification-badge"
          />
          <v-icon
            v-else
            icon-name="Vlt-icon-right-full"
            class="chat-arrow-icn md"
          />
        </div>

        <div class="meeting-details-container Vlt-grey-light">
          <div class="label">
            <span class="field-name">{{
              $t('mobile_card.meeting_id_text')
            }}</span>
            <span
              class="detail"
              data-cy="meeting-id-value"
              @click.prevent="copyRoomPinCodeToClipboard"
              >{{ roomPinCode }}</span
            >
          </div>
          <div class="label">
            <span class="field-name">{{
              $t('mobile_card.meeting_link_text')
            }}</span>
            <span
              class="detail meeting-link text-ellipsis"
              data-cy="meeting-url-value"
              @click.prevent="copyRoomUrlToClipboard"
              >{{ roomUrl }}</span
            >
          </div>
        </div>

        <MobileDialInCountries class="dial-in-container" />

        <div class="bottom-buttons-container">
          <vwc-button
            class="copy-details-button"
            data-cy="copy-details-button"
            :label="$t('mobile_card.copy_details_button')"
            layout="filled"
            connotation="cta"
            @click="copyDetails"
          />
          <vwc-button
            class="share-button"
            :label="$t('mobile_card.share_button')"
            layout="filled"
            @click="shareDetails"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ControlBarButton from '@/components/ControlBarButton';
import ControlBarToggleButton from '@/components/ControlBarToggleButton';
import MobileDialInCountries from './MobileDialInCountries';
import MobileSelect from './MobileSelect';
import { clipboardService } from '@/services/clipboard-service';
import { isAudioSourceSelectionSupported } from '@/helpers/mobile-utils';
import {
  SHORT_TOAST_DURATION,
  CARD_VISIBILITY_STATE,
  TOAST_TYPE
} from '@/consts/mobile-consts';
import analytics from '@/services/analytics-service';
import ChatNotification from '@/components/ChatNotification';
import {
  ANALYTICS,
  TOGGLE_CAMERA_SOURCES,
  TOGGLE_MIC_SOURCES
} from '@/consts/global-consts';

export default {
  name: 'MobileCard',

  components: {
    ChatNotification,
    ControlBarButton,
    ControlBarToggleButton,
    MobileDialInCountries,
    MobileSelect
  },

  props: {
    collapsed: {
      type: Boolean,
      default: false
    },
    extendable: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      CARD_VISIBILITY_STATE,
      cardVisibilityState: CARD_VISIBILITY_STATE.COLLAPSED,
      isTransitioning: false,
      isAudioSourceSelectionSupported,
      TOGGLE_CAMERA_SOURCES: TOGGLE_CAMERA_SOURCES,
      TOGGLE_MIC_SOURCES: TOGGLE_MIC_SOURCES
    };
  },

  computed: {
    ...mapState([
      'isMicEnabled',
      'isVideoEnabled',
      'hasCameraPermissions',
      'hasMicrophonePermissions',
      'hasMicrophoneDevices',
      'hasCameraDevices',
      'cameraDevices',
      'microphoneDevices',
      'selectedMicrophoneId',
      'selectedDialInNumbers'
    ]),
    ...mapState('messaging', ['unreadMessagesCount']),
    ...mapGetters([
      'hasCameraAccess',
      'hasMicrophoneAccess',
      'isInitializingPublisher',
      'showToggleAudioButtonPopper',
      'roomPinCode',
      'roomUrl',
      'detailsStringToCopy',
      'isChatFeatureEnabled'
    ]),

    micEnabled() {
      return this.hasMicrophoneAccess && this.isMicEnabled;
    },

    videoEnabled() {
      return this.hasCameraAccess && this.isVideoEnabled;
    },

    hasMultipleCameraDevices() {
      return this.hasCameraDevices && this.cameraDevices.length > 1;
    },

    hasMultipleMicrophoneDevices() {
      return this.hasMicrophoneDevices && this.microphoneDevices.length > 1;
    },

    currentMicrophoneId: {
      get: function () {
        return this.selectedMicrophoneId;
      },
      set: function (value) {
        this.selectMicrophoneDevice(value);
      }
    },

    currentMicrophoneLabel() {
      const selectedMicrophoneDevice = this.microphoneDevices.find(
        (device) => device.deviceId === this.selectedMicrophoneId
      );
      if (!selectedMicrophoneDevice) {
        return undefined;
      }

      return selectedMicrophoneDevice.label;
    },

    microphoneDeviceOptions() {
      return this.microphoneDevices.map((microphoneDevice) => {
        return {
          key: microphoneDevice.deviceId,
          value: microphoneDevice.deviceId,
          label: microphoneDevice.label
        };
      });
    },

    shouldShowAudioOptions() {
      return (
        this.hasMultipleMicrophoneDevices &&
        this.isAudioSourceSelectionSupported &&
        !this.isInitializingPublisher &&
        this.hasMicrophonePermissions
      );
    }
  },

  watch: {
    collapsed: {
      handler: function () {
        if (this.collapsed) {
          this.transitionToVisibilityState(CARD_VISIBILITY_STATE.COLLAPSED);
        } else {
          this.transitionToVisibilityState(
            CARD_VISIBILITY_STATE.PARTIALLY_EXTENDED
          );
        }
      },
      immediate: true
    },

    extendable() {
      if (this.cardVisibilityState === CARD_VISIBILITY_STATE.FULLY_EXTENDED) {
        this.cardVisibilityState = CARD_VISIBILITY_STATE.PARTIALLY_EXTENDED;
      }

      if (this.extendable) {
        this.$nextTick(this.updateMeetingInfoHeight);
      }
    },

    shouldShowAudioOptions() {
      // Meeting info height may change when audio source selection becomes visible (i.e. the device suddenly has multiple microphones)
      this.$nextTick(this.updateMeetingInfoHeight);
    },

    cardVisibilityState: {
      handler: function () {
        this.$emit('visibility-state-changed', this.cardVisibilityState);
      },
      immediate: true
    }
  },

  mounted() {
    this.updateMeetingInfoHeight();
  },

  methods: {
    ...mapActions([
      'toggleMic',
      'toggleVideo',
      'leaveSession',
      'selectMicrophoneDevice',
      'setIsEndMeetingDialogVisible'
    ]),
    ...mapActions('mobile', ['flipCamera', 'displayToast', 'openMobileChat']),

    updateMeetingInfoHeight() {
      // Calculate the height of the meeting info section
      const meetingInfoElement = this.$refs.meetingInfo;
      const meetingInfoStyle = getComputedStyle(meetingInfoElement);
      const meetingInfoVerticalMargin =
        parseInt(meetingInfoStyle.marginTop) +
        parseInt(meetingInfoStyle.marginBottom);
      const meetingInfoHeight =
        meetingInfoElement.offsetHeight + meetingInfoVerticalMargin;
      // Store it as a CSS variable so that we can hide that part when the card is visible but not extended
      this.$refs.card.style.setProperty(
        '--meeting-info-height',
        `${meetingInfoHeight}px`
      );
    },

    toggleCardExtended() {
      if (
        this.cardVisibilityState === CARD_VISIBILITY_STATE.PARTIALLY_EXTENDED
      ) {
        this.transitionToVisibilityState(CARD_VISIBILITY_STATE.FULLY_EXTENDED);
      } else {
        this.transitionToVisibilityState(
          CARD_VISIBILITY_STATE.PARTIALLY_EXTENDED
        );
      }
    },

    transitionToVisibilityState(state) {
      this.isTransitioning = true;
      this.cardVisibilityState = state;
    },

    copyToClipboard(contents) {
      clipboardService.copy(`${contents}`);
    },

    copyRoomPinCodeToClipboard() {
      this.copyToClipboard(this.roomPinCode);

      this.displayToast({
        message: this.$t('mobile_card.pin_code_copied_message'),
        time: SHORT_TOAST_DURATION,
        type: TOAST_TYPE.GOOD
      });
    },

    copyRoomUrlToClipboard() {
      this.copyToClipboard(this.roomUrl);

      this.displayToast({
        message: this.$t('mobile_card.url_copied_message'),
        time: SHORT_TOAST_DURATION,
        type: TOAST_TYPE.GOOD
      });
    },

    copyDetails() {
      this.copyToClipboard(this.detailsStringToCopy);

      this.displayToast({
        message: this.$t('mobile_card.details_copied_message'),
        time: SHORT_TOAST_DURATION,
        type: TOAST_TYPE.GOOD
      });
    },

    shareDetails() {
      navigator.share({ text: this.detailsStringToCopy });
      analytics.trackEvent(ANALYTICS.INFO_SHARED);
    },

    showLeaveMeetingDialog() {
      this.setIsEndMeetingDialogVisible(true);
    },

    chatClicked() {
      this.toggleCardExtended();
      this.openMobileChat();
    }
  }
};
</script>

<style scoped>
.card,
.card-content-container,
.extender-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.card-content-container,
.extender-container {
  background: rgba(51, 51, 51, 0.5);
  border: 1px solid #43474f;
}

.card-content-container {
  flex: 1;
}

.extender-container {
  border-radius: 6px;
}

.portrait .card-content-container {
  border-radius: 12px 12px 0 0;
}

.landscape-right .card-content-container {
  border-radius: 0 12px 12px 0;
  border-left: 0;
}

.landscape-left .card-content-container {
  border-radius: 12px 0 0 12px;
  border-right: 0;
}

.card.transition,
.card.transition .extender-container,
.card.transition .card-content-container {
  transition: transform 0.3s, background 0.3s;
}

.card.hidden {
  opacity: 0;
}

.portrait .card.collapsed {
  transform: translate(0, 100%);
}

.portrait .card.partially-extended {
  transform: translate(0, var(--meeting-info-height));
}

.portrait .card.fully-extended {
  transform: translate(0, 0);
}

.portrait .card.fully-extended .extender-container,
.portrait .card.fully-extended .card-content-container {
  background: rgba(51, 51, 51, 1);
}

.landscape-left .card.collapsed {
  transform: translate(100%, 0);
}

.landscape-right .card.collapsed {
  transform: translate(-100%, 0);
}

.landscape-left .card.partially-extended,
.landscape-right .card.partially-extended {
  transform: translate(0, 0);
}

.extender-container {
  width: 52px;
  height: 24px;
  margin: auto;
  margin-bottom: 16px;
  align-items: center;
}

.extender {
  width: 16px;
  height: 16px;
  fill: #fff;
}

.overlaping-notification {
  position: absolute;
  transform: translate(24px, -10px);
}

.action-buttons-container {
  display: flex;
  justify-content: center;
}

.portrait .action-buttons-container {
  flex-direction: row;
  padding: 20px 0;
}

.landscape-left .action-buttons-container,
.landscape-right .action-buttons-container {
  flex-direction: column;
  margin: 0 16px;
}

.portrait .action-buttons-container > :not(:last-child) {
  margin-right: 24px;
}

.landscape-left .action-buttons-container > :not(:last-child),
.landscape-right .action-buttons-container > :not(:last-child) {
  margin-bottom: 16px;
}

.meeting-info {
  margin: 8px 16px 0 16px;
}

.select-audio-source {
  margin-bottom: 16px;
}

.meeting-chat-container {
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  padding: 12px 0;
  margin-bottom: 16px;
  border-top: 1px solid #666666;
  border-bottom: 1px solid #666666;
}

.meeting-chat-title {
  margin-left: 12px;
  font-weight: 600;
  font-size: 16px;
  line-height: 23px;
}

.chat-arrow-icn {
  margin-left: auto;
}

.chat-notification-badge {
  margin-left: auto;
}

.meeting-details-container {
  margin-bottom: 24px;
}

.meeting-info .label {
  display: flex;
  font-size: 18px;
  line-height: 24px;
  color: #e6e6e6;
  margin: 8px 0;
}

.meeting-info .label > :not(:last-child) {
  margin-right: 4px;
}

.meeting-info .label .field-name {
  flex-shrink: 0;
}

.meeting-info .label .detail {
  font-weight: 600;
}

.meeting-info .bottom-buttons-container {
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  height: 40px;
  margin: 24px 0 8px 0;
}

.meeting-info .bottom-buttons-container > * {
  width: 100%;
}

.meeting-info .bottom-buttons-container > :not(:last-child) {
  margin-right: 8px;
}

.selected-microphone {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.selected-microphone .v-icon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.flip-btn {
  background: transparent;
}

vwc-button.share-button {
  --vvd-color-primary: #4d4d4d;
  --vvd-color-on-primary: var(--btn-primary-color-white-bg-text-color);
}

vwc-button.copy-details-button {
  --vvd-color-cta: var(--btn-cta-color-black-bg);
  --vvd-color-on-primary: var(--btn-primary-color-white-bg-text-color);
  --vvd-color-on-cta: var(--btn-primary-color-white-bg-text-color);
}
</style>
