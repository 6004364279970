/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-phone-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.151 8.512c4.991 0 7.714 1.362 8.924 2.118 1.058.756 1.663 2.117 1.512 3.025-.151.907-.454 1.512-.907 1.815h-.303c-.907 0-2.873-.605-3.63-.908-1.058-.454-1.058-1.21-1.21-1.663 0-.454.152-.757-.302-1.06-.756-.604-1.966-.755-4.084-.755-2.117 0-3.327.15-4.083.756-.605.454-.303.605-.303 1.059-.151.453-.151 1.21-1.059 1.814-.756.454-2.873.908-3.63.908h-.302c-.605-.303-.907-.908-1.059-1.815-.15-.907.454-2.42 1.513-3.025 1.21-.907 3.932-2.269 8.923-2.269zm0-1.512C7.01 7 3.984 8.361 2.321 9.42.807 10.479-.252 12.445.05 13.957c.303 1.815 1.06 2.571 1.664 2.874.303.151.605.302 1.06.302 1.21 0 3.478-.605 4.385-1.21 1.815-.756 1.966-2.268 2.118-2.722v-.303c.453-.15 1.36-.302 2.873-.302 1.513 0 2.42.151 2.874.303v.15c0 .455.302 2.118 1.815 2.874.907.454 3.176 1.21 4.386 1.21.454 0 .756-.15 1.059-.302.605-.303 1.36-1.059 1.663-2.874.303-1.664-.756-3.63-2.268-4.537C20.318 8.36 17.142 7 12.15 7z"/>'
  }
})
