/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-phone': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.79 1.655c.496 0 .827.331.992.497C6.28 2.814 8.1 5.462 8.1 6.62c0 1.158-.496 1.82-1.49 2.482 0 0 .332 1.987 3.146 4.8 2.482 2.814 4.8 3.31 4.8 3.31s.496-1.323 1.82-1.489h.497c1.159 0 2.317.662 4.634 2.483.828.662-.165 3.641-2.648 4.138h-.497c-2.648 0-7.117-2.483-10.427-5.959-3.145-3.145-5.462-6.62-6.124-10.262-.662-3.641 2.152-4.138 2.648-4.303.166-.166.166-.166.331-.166zM4.79 0c-.166 0-.497 0-.663.166C.982.993-.507 3.31.155 6.456.817 9.93 2.968 13.571 6.61 17.544 10.086 21.186 15.05 24 18.196 24h.662c2.317-.331 3.807-2.152 4.303-3.972.332-1.325 0-2.483-.827-3.145-2.483-1.986-3.973-2.814-5.628-2.814h-.827c-.993.165-1.655.662-2.152 1.159-.497-.331-1.655-.993-2.814-2.318-1.324-1.324-1.986-2.482-2.317-3.144.662-.663 1.324-1.656 1.159-3.145 0-1.49-1.49-3.807-2.649-5.462C6.776.662 5.948 0 4.79 0z"/>'
  }
})
