import { ANALYTICS } from '@/consts/global-consts';
import analytics from '@/services/analytics-service';

const eventsForAnalytics = {
  'use-tool': 'Use Tool',
  undo: 'Whiteboard Undo',
  redo: 'Whiteboard Redo',
  'stop-whiteboard': 'Stop Whiteboard',
  'clear-whiteboard': 'Clear Whiteboard',
  'lock-whiteboard': 'Lock Whiteboard',
  'download-whiteboard': 'Download Whiteboard'
};
const toolsForAnalytics = ['PEN', 'SHAPE', 'ERASER', 'TEXT', 'LINE', 'ARROW'];

export default {
  sendTelemetryAnalytic(telemetry) {
    if (this.shouldWriteTelemetryAsAnlytic(telemetry)) {
      if (telemetry.name === 'use-tool') {
        analytics.trackEvent(ANALYTICS.WHITEBOARD_TOOLBAR, {
          'Tool Type': telemetry.data.type
        });
      } else {
        analytics.trackEvent(
          `Meetings - ${eventsForAnalytics[telemetry.name]}`
        );
      }
    }
  },

  shouldWriteTelemetryAsAnlytic(telemetry) {
    if (Object.keys(eventsForAnalytics).includes(telemetry.name)) {
      if (
        telemetry.name === 'use-tool' &&
        !toolsForAnalytics.includes(telemetry.data.type)
      ) {
        return false;
      }
      return true;
    }
  }
};
