<template>
  <div class="questions-container">
    <div
      v-if="!isQnaSessionActive && isLoadingSession"
      class="loading-container"
    >
      <vwc-circular-progress indeterminate />
    </div>
    <div
      v-else-if="!isQnaSessionActive && !isLoadingSession"
      class="empty-state-container"
    >
      <vwc-empty-state
        :heading="$t('qna_sidebar.empty_state_title')"
        :body="$t('qna_sidebar.empty_state_message')"
      >
        <img
          slot="graphic"
          data-cy="empty-qna-img"
          src="/illustrations/empty-state/empty-qna.svg"
        />
      </vwc-empty-state>
    </div>
    <div v-else class="qna-container">
      <div class="header-container">
        <div class="header-title">{{ $t('qna_sidebar.title') }}</div>
        <vwc-badge
          class="num-of-questions-badge"
          connotation="primary"
          shape="pill"
          layout="soft"
          :text="questions.length"
        />
      </div>
      <div class="started-by">
        {{ startedBy }}
      </div>
      <div
        class="questions-cards-container"
        :class="{
          empty: isQnaSessionEmpty
        }"
      >
        <div v-if="isQnaSessionEmpty">
          <vwc-empty-state
            :heading="$t('qna_sidebar.empty_state_title')"
            :body="$t('qna_sidebar.empty_state_message')"
          >
            <img
              slot="graphic"
              data-cy="empty-qna-img"
              src="/illustrations/empty-state/empty-qna.svg"
            />
          </vwc-empty-state>
        </div>
        <div
          v-for="question in questions"
          v-else
          :key="question.questionId"
          class="question-card"
        >
          <Question
            :question="question"
            :participant="participantObj(question.userId)"
          />
        </div>
      </div>
      <div class="text-field">
        <VTextfield
          v-model="newQuestionText"
          dense
          :placeholder="$t('qna_sidebar.question_textfield_placeholder')"
          :maxLength="512"
          :actionSlotFunction="sendQuestion"
          :isActionDisabled="isSendButtonDisabled"
          @submit="sendQuestion"
        >
        </VTextfield>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import Question from './qna/Question.vue';

export default {
  name: 'QuestionsAndAnswers',

  components: {
    Question
  },

  data() {
    return { newQuestionText: '' };
  },

  computed: {
    ...mapState('qna', [
      'questions',
      'isQnaSessionActive',
      'isLoadingSession',
      'qnaSessionOwnerId'
    ]),
    ...mapState(['myParticipantId']),
    ...mapGetters(['participants']),
    ...mapGetters('qna', ['isQnaSessionOwner']),

    isQnaSessionEmpty() {
      return this.questions.length === 0;
    },

    allParticipants() {
      return Object.values(this.participants);
    },

    startedBy() {
      return this.$t('qna_sidebar.started_by_label', {
        name: this.participantDisplayName(this.qnaSessionOwnerId)
      });
    },

    isSendButtonDisabled() {
      if (this.newQuestionText.length === 0) {
        return true;
      }
      return !/\S/.test(this.newQuestionText);
    }
  },

  methods: {
    ...mapActions('qna', {
      _sendQuestion: 'sendQuestion'
    }),

    participantDisplayName(participantId) {
      const participant = this.participantObj(participantId);
      if (participant === undefined) {
        return participantId;
      }
      return participant.displayName;
    },

    participantObj(participantId) {
      return this.allParticipants.find(
        (participant) => participant.participantId === participantId
      );
    },

    sendQuestion() {
      if (this.isSendButtonDisabled) return;

      this._sendQuestion(this.newQuestionText);
      this.newQuestionText = '';
    }
  }
};
</script>

<style scoped>
.questions-container {
  display: flex;
  position: relative;
  height: 100%;
  overflow-y: hidden;
  padding: 0 20px;
}

.empty-state-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.qna-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: stretch;
  height: 100%;
  overflow-y: hidden;
}

.questions-cards-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
  padding-bottom: 5px;
}

.questions-cards-container.empty {
  justify-content: center;
}

.text-field {
  padding-top: 10px;
  padding-bottom: 5px;
}

.header-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.header-container {
  width: 95%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 4px;
}

.question-card {
  margin-top: 10px;
  width: 100%;
  padding: 6px;
}

.started-by {
  color: #4f4e4e;
  margin-bottom: 10px;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
</style>
