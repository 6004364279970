var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dial-in-info", attrs: { "data-cy": "dial-in-info" } },
    [
      _c("span", { staticClass: "select-countries-label" }, [
        _vm._v(_vm._s(_vm.$t("dial_in_info.select_countries_label"))),
      ]),
      _c(
        "Dropdown",
        {
          attrs: { open: _vm.isDropdownExpanded, options: _vm.dialInOptions },
          on: { close: _vm.closeDropdown, apply: _vm.onApplyDropdown },
        },
        [
          _c(
            "InputTag",
            {
              attrs: { slot: "toggleDropdown", tags: _vm.tags },
              on: { remove: _vm.removeSelectedNumber },
              slot: "toggleDropdown",
            },
            [
              _c(
                "div",
                {
                  staticClass: "input-text",
                  attrs: { slot: "input-text", "data-cy": "input-text" },
                  on: { click: _vm.toggleDropdown },
                  slot: "input-text",
                },
                [_vm._v(" " + _vm._s(_vm.$t("dial_in_info.input_text")) + " ")]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }