var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _vm.isBranded
        ? _c(
            "div",
            { staticClass: "content-div" },
            [
              _c("vwc-icon", {
                staticClass: "icon",
                attrs: { type: "puzzled-line", size: "large" },
              }),
              _c("h2", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$t("unsupported_browser_screen.title"))),
              ]),
              _c("h4", { staticClass: "description" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("unsupported_browser_screen.formatted_description")
                    ) +
                    " "
                ),
              ]),
            ],
            1
          )
        : _c("div", { staticClass: "content-div" }, [
            _c(
              "div",
              { staticClass: "form" },
              [
                _c("vwc-icon", { attrs: { type: "video-conference-full" } }),
                _c(
                  "div",
                  { staticClass: "text" },
                  [
                    _c("h2", { staticClass: "title" }, [
                      _vm._v(
                        _vm._s(_vm.$t("unsupported_browser_screen.title"))
                      ),
                    ]),
                    _c("div", { staticClass: "description" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("unsupported_browser_screen.description")
                          ) +
                          " "
                      ),
                    ]),
                    _c("div", [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("unsupported_browser_screen.description2")
                          ) +
                          " "
                      ),
                    ]),
                    _c("vwc-button", {
                      staticClass: "download-btn",
                      attrs: {
                        label: _vm.$t(
                          "unsupported_browser_screen.download_button"
                        ),
                        layout: "filled",
                        connotation: "cta",
                      },
                      on: { click: _vm.downloadVBC },
                    }),
                    _c("div", { staticClass: "open-vbc-description" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "unsupported_browser_screen.open_vbc_description"
                            )
                          ) +
                          " "
                      ),
                      _c(
                        "span",
                        {
                          staticClass: "open-vbc-link Vlt-purple-dark",
                          on: { click: _vm.openVBCApp },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.$t("unsupported_browser_screen.here_text")
                            )
                          ),
                        ]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t(
                              "unsupported_browser_screen.join_the_meeting_text"
                            )
                          ) +
                          " "
                      ),
                    ]),
                    _c("iframe", {
                      ref: "preAlphaMeetingsIframe",
                      staticClass: "hidden-frames",
                      attrs: { src: _vm.vbcAppPreAlphaMeetingLink },
                    }),
                    _c("iframe", {
                      ref: "alphaMeetingsIframe",
                      staticClass: "hidden-frames",
                      attrs: { src: _vm.vbcAppAlphaMeetingLink },
                    }),
                    _c("iframe", {
                      ref: "meetingsIframe",
                      staticClass: "hidden-frames",
                      attrs: { src: _vm.vbcAppMeetingLink },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
      !_vm.isBranded
        ? _c("v-icon", {
            staticClass: "vonage-banner",
            attrs: { iconName: "vonage-page-logo" },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }