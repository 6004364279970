<template>
  <vwc-dialog
    v-observe-keyboard.l2.enter="confirmAction"
    data-cy="confirmation-dialog"
    class="vvd-scheme-alternate dialog"
    :open="confirmationDialog"
    :heading="confirmationDialog.title ? confirmationDialog.title : ''"
    :escapeKeyAction="hide"
    @close="(confirm) => (confirm ? confirmAction() : hide())"
    @click.stop
  >
    <div class="content">
      <div class="text">{{ confirmationDialog.text }}</div>
    </div>

    <vwc-button
      slot="primaryAction"
      data-cy="confirm-btn"
      :label="confirmationDialog.okButtonText"
      layout="filled"
      connotation="primary"
      @click="confirmAction"
    />

    <vwc-button
      slot="secondaryAction"
      data-cy="cancel-btn"
      :label="$t('confirmation_dialog_modal.cancel_button')"
      layout="outlined"
      @click="hide"
    />
  </vwc-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ConfirmationDialogModal',

  computed: {
    ...mapState(['confirmationDialog'])
  },

  methods: {
    ...mapActions(['setConfirmationDialog']),

    confirmAction() {
      this.$store.dispatch(
        this.confirmationDialog.actionName,
        this.confirmationDialog.actionData
      );
      this.hide();
    },

    hide() {
      this.setConfirmationDialog(null);
    }
  }
};
</script>
<style scoped>
.content {
  margin-top: 8px;
}

vwc-dialog {
  --mdc-dialog-z-index: 100000;
}
</style>
