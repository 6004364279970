/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-user-sync': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11.821 2.871A5 5 0 009.001 2 .5.5 0 019 1a6 6 0 015.59 3.818.5.5 0 11-.93.364A5 5 0 0011.82 2.87zM1.93 11.194a.5.5 0 01.678.2 5 5 0 003.436 2.514.5.5 0 11-.19.982 6 6 0 01-4.123-3.018.5.5 0 01.2-.678zM12.706 8.3c-.675 0-1.176.512-1.176 1.09 0 .576.502 1.089 1.176 1.089.675 0 1.177-.512 1.177-1.09 0-.576-.502-1.089-1.177-1.089zM10.53 9.39c0-1.18 1-2.09 2.176-2.09 1.177 0 2.177.91 2.177 2.09 0 1.178-1 2.089-2.177 2.089-1.176 0-2.176-.91-2.176-2.09zm2.314 3.961c-.95 0-1.661.359-2.03.636a.783.783 0 00-.314.631V15H15v-.382a.787.787 0 00-.319-.63c-.396-.3-.913-.637-1.837-.637zm-2.63-.163c.51-.385 1.428-.837 2.63-.837 1.229 0 1.956.474 2.439.837.46.347.717.878.717 1.43v.617a.772.772 0 01-.78.765h-4.946a.768.768 0 01-.774-.765v-.617c0-.549.25-1.081.713-1.43zM3.206 1c-.675 0-1.176.512-1.176 1.09 0 .576.502 1.089 1.176 1.089.675 0 1.177-.513 1.177-1.09C4.383 1.512 3.88 1 3.206 1zM1.03 2.09C1.03.91 2.03 0 3.206 0c1.177 0 2.177.91 2.177 2.09 0 1.178-1 2.089-2.177 2.089-1.176 0-2.176-.91-2.176-2.09zm2.314 3.96c-.95 0-1.661.36-2.03.637A.783.783 0 001 7.318V7.7h4.5v-.382a.787.787 0 00-.319-.63v-.001c-.396-.298-.913-.636-1.837-.636zm-2.63-.162c.51-.385 1.428-.837 2.63-.837 1.229 0 1.956.474 2.439.837.46.347.717.878.717 1.43v.617a.772.772 0 01-.78.765H.775A.768.768 0 010 7.935v-.617c0-.549.25-1.081.713-1.43z"/>'
  }
})
