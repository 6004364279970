/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-happy-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm3 7.5c.9 0 1.5.6 1.5 1.5s-.6 1.5-1.5 1.5-1.5-.6-1.5-1.5.6-1.5 1.5-1.5zm-6 0c.9 0 1.5.6 1.5 1.5s-.6 1.5-1.5 1.5S7.5 9.9 7.5 9 8.1 7.5 9 7.5zm10.2 6.3c-.75 3.45-3.75 5.7-7.2 5.7s-6.45-2.25-7.2-5.55c-.3-.9.15-1.65 1.05-1.95.75-.15 1.65.3 1.8 1.2.45 1.95 2.25 3.3 4.35 3.3 2.1 0 3.9-1.35 4.35-3.3.15-.9 1.05-1.35 1.8-1.2.9.3 1.35 1.05 1.05 1.8z"/>'
  }
})
