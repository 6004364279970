/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-audio-max': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.706 2.047a.75.75 0 10-1.07 1.05c4.819 4.907 4.819 12.899 0 17.804a.75.75 0 001.07 1.051c5.392-5.489 5.392-14.416 0-19.905zM2.183 9.167h2.741l4.489-3.885v13.436l-4.489-3.885H2.183a.703.703 0 01-.683-.722V9.889c0-.425.33-.723.683-.723zm0-1.5h2.182l5.72-4.951a.5.5 0 01.827.378v17.812a.5.5 0 01-.827.378l-5.72-4.95H2.183C.977 16.333 0 15.338 0 14.11V9.889C0 8.66.977 7.666 2.183 7.666zm12.35.665a.75.75 0 00-1.07 1.05c1.41 1.436 1.41 3.802 0 5.237a.75.75 0 101.07 1.05c1.983-2.018 1.983-5.319 0-7.337zm1.562-3.152a.75.75 0 011.06.01c3.687 3.753 3.688 9.867 0 13.62a.75.75 0 11-1.07-1.051c3.114-3.17 3.114-8.348 0-11.519a.75.75 0 01.01-1.06z"/>'
  }
})
