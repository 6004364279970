/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-video-off': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1 3.5c0-.3.2-.5.5-.5h7.2l.9-1H1.5C.7 2 0 2.7 0 3.5v9l1-1.1V3.5zM15.8 4.1c-.2-.1-.3-.1-.5-.1L11 5.8V5l3.4-3.6c.2-.2.2-.5 0-.7s-.5-.2-.7 0L.1 15.2c-.2.2-.2.5 0 .7.2.2.5.2.7 0L2.6 14h6.9c.8 0 1.5-.7 1.5-1.5v-2.3l4.3 1.7c.2.1.3 0 .5-.1.1-.1.2-.2.2-.4v-7c0-.1-.1-.2-.2-.3zM10 6.5v6c0 .3-.2.5-.5.5h-6L10 6v.5zm5 4.3l-4-1.6V6.8l4-1.6v5.6z"/>'
  }
})
