<template>
  <span class="status-badge" :class="currentStatus.cls">
    <vwc-icon :type="currentStatus.icon"></vwc-icon>
  </span>
</template>

<script>
const STATUSES = {
  paused: {
    icon: 'pause-solid',
    cls: 'paused-status'
  },
  active: {
    icon: 'play-solid',
    cls: 'active-status'
  }
};
export default {
  name: 'StatusBadge',

  props: {
    status: {
      type: String,
      default: 'active'
    }
  },

  data() {
    return {
      statuses: STATUSES
    };
  },

  computed: {
    currentStatus() {
      return this.statuses[this.status] || this.statuses.active;
    }
  }
};
</script>

<style scoped>
.status-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 12px;
  height: 12px;
  padding: 3px;
  border-radius: 50%;
}

.paused-status {
  background: #e61d1d;
}

.active-status {
  background: #1c8731;
}
</style>
