import NoSleep from 'nosleep.js';
import { isMobileDevice, isAppleMobileDevice } from '@/helpers/global-helpers';
const noSleep = new NoSleep();

/**
 * Prevents mobile browsers from auto-dimming/locking the screen.
 * On some devices NoSleep will play an invisible video to fool the browser.
 * Must be called in a flow that involves user input, such as a tap
 */
export function preventMobileLock() {
  if (!isMobileDevice) {
    return;
  }

  if (noSleep.noSleepVideo) {
    // Change the title attribute which affects mobile sticky media notifications
    noSleep.noSleepVideo.setAttribute('title', 'Vonage Meetings');
  }

  noSleep.enable();
}

export function stopPreventingMobileLock() {
  if (!isMobileDevice) {
    return;
  }

  noSleep.disable();

  // If NoSleep uses a video then the disable() method only pauses it, so here we unload
  // the video thoroughly in order to make the sticky media notification go away
  if (noSleep.noSleepVideo) {
    noSleep.noSleepVideo.pause();
    noSleep.noSleepVideo.src = '';
    while (noSleep.noSleepVideo.firstChild) {
      noSleep.noSleepVideo.removeChild(noSleep.noSleepVideo.lastChild);
    }
    noSleep.noSleepVideo.load();
  }
}

/**
 * Checks if a mobile browser's camera device is a back camera.
 * Although this type of check may seem unreliable, popular mobile browsers are consistent
 * with the camera device labels.
 * @param {*} cameraLabel Label of the camera device
 */
export function isBackCamera(cameraLabel) {
  return /back|rear|environment/i.test(cameraLabel);
}

export const isAudioSourceSelectionSupported = (() => {
  return !isAppleMobileDevice;
})();
