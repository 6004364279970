<template>
  <div class="relative-position">
    <div
      class="Vlt-composite text-area-wrapper"
      :class="{
        'Vlt-form__element--big': label,
        'Vlt-form__element--error': !state
      }"
    >
      <div v-if="prependIcon" class="Vlt-composite__prepend">
        <v-button
          id="prepend-btn"
          :iconName="prependIcon"
          :disabled="disabled"
          class="Vlt-btn--icon prepend-icon"
          @click="$emit('prependIconClick')"
        />
      </div>
      <div class="Vlt-textarea Vlt-input v-text-area">
        <textarea
          ref="textarea"
          v-model="inputText"
          class="textarea"
          :class="{
            'no-left-border-radius': prependIcon,
            'no-right-border-radius': submitButton
          }"
          rows="1"
          cols="50"
          :maxlength="maxLength"
          :disabled="disabled"
          :placeholder="placeholder"
          @input="updateText"
          @keydown.enter.exact.prevent
          @keyup.enter.exact="submitText"
        />
      </div>
      <div v-if="submitButton" class="Vlt-composite__append">
        <button class="Vlt-btn Vlt-btn--icon submit-button" @click="submitText">
          <v-icon
            class="submit-icon"
            :class="{
              'Vlt-teal': inputText.length > 0 && !isMobileWebMode,
              submittable: inputText.length > 0
            }"
            :iconName="
              inputText.length > 0 ? 'Vlt-icon-send-negative' : 'Vlt-icon-send'
            "
          />
        </button>
      </div>
      <v-icon
        iconName="Vlt-icon-emoji"
        class="icon-happy icon-happy-send-message"
        @click="showPicker"
      />
    </div>
    <div
      v-if="showEmojisPicker"
      v-on-clickaway="hidePicker"
      v-observe-keyboard.prevent.l2.esc="hidePicker"
    >
      <VPicker v-once class="emojis-picker" @emojiClick="onEmojiClick" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VPicker from '@/components/VPicker';
import { mixin as clickaway } from 'vue-clickaway';
export default {
  name: 'VInput',

  components: {
    VPicker
  },

  mixins: [clickaway],
  model: {
    prop: 'parentText',
    event: 'textChanged'
  },

  props: {
    parentText: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    iconName: {
      type: String,
      default: ''
    },
    submitButton: {
      type: Boolean,
      default: false
    },
    prependIcon: {
      type: String,
      default: ''
    },
    state: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: String,
      default: '1000'
    }
  },

  data() {
    return {
      showEmojisPicker: false
    };
  },

  computed: {
    ...mapGetters(['isMobileWebMode']),

    inputText: {
      // Needed for two way binding with parent
      get() {
        return this.parentText;
      },
      set(value) {
        this.$emit('textChanged', value);
      }
    }
  },

  watch: {
    text(newVal) {
      this.$emit('textChanged', newVal);
    }
  },

  methods: {
    submitText() {
      if (this.inputText) {
        this.$emit('submit', this.inputText);
        this.hidePicker();
      }
    },

    updateText(event) {
      this.inputText = event.target.value;
    },

    onEmojiClick(emoji) {
      const inputEl = this.$refs.textarea;
      const caretPositionStart = inputEl.selectionStart;
      const caretPositionEnd = inputEl.selectionEnd;
      // Checks the emoji won't make the message exceed the length limit
      if (
        emoji &&
        this.inputText.length -
          (caretPositionEnd - caretPositionStart) +
          emoji.length <=
          999
      ) {
        // Insert emoji in the right place according to user's selection with the caret
        this.inputText =
          this.inputText.slice(0, caretPositionStart) +
          emoji +
          this.inputText.slice(caretPositionEnd);
        this.$nextTick(() => {
          inputEl.focus();
          inputEl.selectionStart = caretPositionStart + emoji.length;
          inputEl.selectionEnd = caretPositionStart + emoji.length;
        });
      }
    },

    hidePicker() {
      this.showEmojisPicker = false;
    },

    showPicker() {
      if (!this.disabled) {
        this.showEmojisPicker = true;
      }
    }
  }
};
</script>

<style scoped>
.text-area-wrapper {
  height: 40px;
  border: 1px solid #131415;
  border-radius: 6px;
  overflow: hidden;
}

.mobile .text-area-wrapper {
  margin: 0px;
  height: 44px;
}

textarea::placeholder {
  color: #919eab;
}
.v-text-area textarea {
  resize: none;
  height: 100%;
  border: 0;
  font-size: 14px;
  line-height: 21px;
}
.v-text-area textarea.no-left-border-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.v-text-area textarea.no-right-border-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.emojis-picker {
  position: absolute;
  right: 0px;
  bottom: 43px;
  width: 310px !important;
  z-index: 2;
}
.icon-happy >>> .svg-icon {
  width: 16px;
  height: 16px;
  fill: #9b9b9b;
}
.icon-happy-send-message {
  position: absolute;
  right: 10px;
  bottom: 10px;
  width: 18px;
  height: 18px;
  fill: #616266;
}
.textarea {
  padding-right: 28px;
}

.mobile .textarea {
  height: 44px;
  background: black;
  color: white;
  padding: 11.5px 0px 11.5px 0px;
  margin-left: 8px;
}

.text-area-wrapper .Vlt-composite__prepend #prepend-btn {
  display: flex;
  height: 100%;
  min-height: 0;
  background-color: #f8fafc;
  border: 0;
  border-right: 1px solid #131415;
}

.text-area-wrapper .Vlt-composite__prepend #prepend-btn:hover {
  background-color: #efefef;
}

.text-area-wrapper .Vlt-composite__prepend .prepend-icon >>> svg.v-icon {
  fill: #616266;
  height: 21px;
  width: 21px;
}

.mobile .text-area-wrapper .Vlt-composite__prepend #prepend-btn {
  background: black;
  border: none;
  padding: 10px 0px 10px 16px;
  height: 44px;
  cursor: unset;
}

.mobile #prepend-btn >>> svg.v-icon {
  fill: white;
  width: 24px;
  height: 24px;
}

.relative-position {
  position: relative;
}

.mobile .submit-button {
  background: black !important;
  border: none;
  cursor: unset;
}

.mobile
  .Vlt-composite.text-area-wrapper
  div.Vlt-composite__append
  button.Vlt-btn.Vlt-btn--icon.submit-button
  .submit-icon {
  fill: #757575;
}

.mobile
  .Vlt-composite.text-area-wrapper
  div.Vlt-composite__append
  button.Vlt-btn.submit-button
  .submit-icon.submittable {
  fill: var(--switch-primary-checked-color);
}
</style>
