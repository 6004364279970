/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-3': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 5c1.4 0 2.5-1.1 2.5-2.5S9.4 0 8 0 5.5 1.1 5.5 2.5 6.6 5 8 5zm0-4c.8 0 1.5.7 1.5 1.5S8.8 4 8 4s-1.5-.7-1.5-1.5S7.2 1 8 1zM13 6c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0-3c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM12 8.5c0-.7-.3-1.3-.7-1.8-.5-.4-1.1-.7-1.8-.7h-3c-.7 0-1.3.3-1.8.7-.4.5-.7 1.1-.7 1.8v3c0 .3.2.5.5.5H5v3.5c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5V12h.5c.3 0 .5-.2.5-.5v-3zM11 11h-.5c-.3 0-.5.2-.5.5V15H6v-3.5c0-.3-.2-.5-.5-.5H5V8.5c0-.4.2-.8.4-1.1S6.1 7 6.5 7h3c.4 0 .8.2 1.1.4.2.3.4.7.4 1.1V11zM15.6 7.4c-.3-.2-.7-.4-1.1-.4h-1c-.3 0-.5.2-.5.5s.2.5.5.5h1c.1 0 .3.1.4.1 0 .1.1.3.1.4V11h-.5c-.3 0-.5.2-.5.5V14h-1.5c-.3 0-.5.2-.5.5s.2.5.5.5h2c.3 0 .5-.2.5-.5V12h.5c.3 0 .5-.2.5-.5v-3c0-.4-.2-.8-.4-1.1zM3 6c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm0-3c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM3.5 14H2v-2.5c0-.3-.2-.5-.5-.5H1V8.5c0-.1.1-.3.1-.4.1 0 .3-.1.4-.1h1c.3 0 .5-.2.5-.5S2.8 7 2.5 7h-1c-.4 0-.8.2-1.1.4S0 8.1 0 8.5v3c0 .3.2.5.5.5H1v2.5c0 .3.2.5.5.5h2c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"/>'
  }
})
