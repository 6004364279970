/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-chevron-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M.8 5.1c-.3-.2-.6-.1-.7.1-.2.3-.1.6.1.7l7.5 5c.2.1.4.1.6 0l7.5-5c.2-.2.3-.5.1-.7-.2-.2-.5-.3-.7-.1L8 9.9.8 5.1z"/>'
  }
})
