/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-light-bulb-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0c4.95 0 9 4.05 9 9 0 3.3-1.8 6.3-4.5 7.8v2.7h-9v-2.7C4.8 15.3 3 12.3 3 9c0-4.95 4.05-9 9-9zM16.5 21c0 1.65-1.35 3-3 3h-3c-1.65 0-3-1.35-3-3h9z"/>'
  }
})
