/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-card-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 3h-3c-.9 0-1.5.6-1.5 1.5S17.4 6 16.5 6 15 5.4 15 4.5 14.4 3 13.5 3h-3C9.6 3 9 3.6 9 4.5S8.4 6 7.5 6 6 5.4 6 4.5 5.4 3 4.5 3h-3C.6 3 0 3.6 0 4.5v15c0 .9.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5v-15c0-.9-.6-1.5-1.5-1.5zm-12 13.5h-6v-6h6v6zm9 0h-6V15h6v1.5zm0-4.5h-6v-1.5h6V12z"/>'
  }
})
