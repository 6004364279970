/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-bounce': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.558 6.46a.748.748 0 00-.058.29v9a.75.75 0 001.5 0V8.56l9.22 9.22a.75.75 0 001.06 0l9.75-9.75a.75.75 0 00-1.06-1.06l-9.22 9.22L4.06 7.5h7.19a.75.75 0 000-1.5h-9-.002a.747.747 0 00-.69.46z"/>'
  }
})
