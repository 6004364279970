<template>
  <Dialog
    data-cy="upload-attachments-modal"
    class="dialog"
    :heading="
      $tc('upload_attachments_modal.title', files.length, {
        filesCount: files.length
      })
    "
    icon-name="upload-line"
    :close="hide"
    :min-width="minWidth"
    :primary-action="$t('upload_attachments_modal.send_button')"
    :secondary-action="$t('upload_attachments_modal.cancel_button')"
    :disablePrimaryButton="hasError"
    primaryButtonIcon="message-sent-solid"
    @applied="sendAttachments"
    @click.stop
  >
    <div slot="content" class="content">
      <div v-if="errorMessage" class="error-message">
        {{ errorMessage }}
      </div>
      <div class="attachment-rows">
        <div
          v-for="(attachment, index) in attachments"
          :key="attachment.name"
          class="attachment-wrapper"
        >
          <AttachmentBox
            :attachment="attachment"
            :isUnsupported="attachment.isUnsupported"
          />
          <v-icon
            iconName="Vlt-icon-bin"
            class="remove-button Vlt-red"
            @click.stop="removeAttachment(index)"
          />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import numeral from 'numeraljs';
import {
  getFileIcon,
  validateFiles,
  isFileUnsupported
} from '@/helpers/attachments-utils';
import {
  UPLOAD_ERRORS_TYPES,
  MAX_SIZE_OF_FILES_TO_UPLOAD,
  MAX_NUMBER_OF_FILES_TO_UPLOAD
} from '@/consts/messaging';
import AttachmentBox from '@/components/rows/message-rows/AttachmentBox';
import Dialog from '@/components/Dialog';
import { mapGetters } from 'vuex';

export default {
  name: 'UploadAttachmentsModal',

  components: {
    AttachmentBox,
    Dialog
  },

  props: {
    files: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      filesValidation: null,
      formattedMaxSize: numeral(MAX_SIZE_OF_FILES_TO_UPLOAD).format('0.0 b')
    };
  },

  computed: {
    ...mapGetters(['isMobileWebMode']),

    minWidth() {
      return this.isMobileWebMode ? 330 : 389;
    },

    attachments() {
      return this.files.map((file) => {
        return {
          name: file.name,
          size: numeral(file.size).format('0.0 b'),
          icon: getFileIcon(file),
          isUnsupported: isFileUnsupported(file)
        };
      });
    },

    errorMessage() {
      const currentError = this.filesValidation.error;
      if (currentError) {
        switch (currentError.name) {
          case UPLOAD_ERRORS_TYPES.TYPE_NOT_SUPPORTED:
            return this.$t('upload_attachments_modal.type_not_supported_error');
          case UPLOAD_ERRORS_TYPES.MAX_NUMBER_OF_FILES_EXCEEDED:
            return this.$t(
              'upload_attachments_modal.max_number_of_files_error',
              {
                maxNumberOfFiles: MAX_NUMBER_OF_FILES_TO_UPLOAD,
                currentErrorDataCount: currentError.data.count
              }
            );
          case UPLOAD_ERRORS_TYPES.TOTAL_SIZE_EXCEEDED:
            return this.$t(
              'upload_attachments_modal.total_size_exceeded_error',
              {
                formattedMaxSize: this.formattedMaxSize,
                currentErrorDataSize: numeral(currentError.data.size).format(
                  '0.0 b'
                )
              }
            );
        }
      }
      return '';
    },

    hasUnsupportedFiles() {
      return !!this.attachments.find((attachment) => attachment.isUnsupported);
    },

    hasError() {
      return this.errorMessage !== '' || this.hasUnsupportedFiles;
    }
  },

  beforeMount() {
    this.filesValidation = validateFiles(this.files);
  },

  methods: {
    sendAttachments() {
      this.$emit('send', this.files);
      this.$emit('hide');
    },

    hide() {
      this.$emit('hide');
    },

    removeAttachment(index) {
      this.$emit('removeFile', index);
      this.filesValidation = validateFiles(this.files);
    }
  }
};
</script>

<style scoped>
.attachment-wrapper {
  position: relative;
}
.attachment-wrapper >>> .file-info {
  padding-right: 30px;
}
.remove-button {
  position: absolute;
  right: 12px;
  bottom: 30px;
  width: 18px;
  height: 18px;
}

.attachment-rows {
  max-height: 284px;
  overflow-y: auto;
  margin-bottom: -13px;
}
.attachment-rows::-webkit-scrollbar {
  width: 7px;
}
.attachment-rows::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 20px;
}
.error-message {
  display: flex;
  justify-content: center;
  font-size: 13px;
  font-weight: 700;
  color: #f25a6b;
  margin-bottom: 7px;
}
</style>
