/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-microphone': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.5 1A3.5 3.5 0 005 4.5v2a3.5 3.5 0 007 0v-2A3.5 3.5 0 008.5 1zM4 4.5a4.5 4.5 0 019 0v2a4.5 4.5 0 01-9 0v-2zM1.5 6a.5.5 0 01.5.5 6.5 6.5 0 1013 0 .5.5 0 011 0 7.5 7.5 0 01-7 7.484V15.5a.5.5 0 01-1 0v-1.516A7.5 7.5 0 011 6.5a.5.5 0 01.5-.5z"/>'
  }
})
