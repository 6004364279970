<template>
  <div
    class="rate-this-meeting"
    data-cy="rate-this-meeting"
    :class="{
      mobile: isMobileWebMode,
      'vvd-scheme-alternate': !isMobileWebMode
    }"
  >
    <!--   Thumbs screen   -->
    <div
      v-if="!showFinalThankYouMessage && !showNegativeDetailsSection"
      class="card main-card"
    >
      <h2 class="rate-title" data-cy="rate-this-meeting-title">
        {{ $t('rate_this_meeting.rate_title') }}
      </h2>
      <p
        v-if="!isMobileWebMode"
        class="rate-description"
        data-cy="rate-this-meeting-description"
      >
        {{ $t('rate_this_meeting.rate_description') }}
      </p>
      <div class="thumbs-section">
        <vwc-icon-button
          class="thumb-btn"
          data-cy="thumbs-up"
          icon="thumbs-up-solid"
          :layout="isMobileWebMode ? 'filled' : 'outlined'"
          :enlarged="!isMobileWebMode"
          @click="setPositiveRating"
        />
        <vwc-icon-button
          class="thumb-btn"
          data-cy="thumbs-down"
          icon="thumbs-down-solid"
          :layout="isMobileWebMode ? 'filled' : 'outlined'"
          :enlarged="!isMobileWebMode"
          @click="setNegativeRating"
        />
      </div>
    </div>
    <!--   Thanks you screen   -->
    <div
      v-else-if="showFinalThankYouMessage"
      class="card main-card thank-you"
      data-cy="rate-this-meeting-thank-you"
    >
      <vwc-icon
        v-if="isBranded"
        class="thank-you-icon"
        type="waving-solid"
        connotation="cta"
        size="large"
      />
      <img
        v-else
        class="thank-you-icon"
        :src="
          isMobileWebMode
            ? '/img-celebration-mobile.svg'
            : '/img-celebration.svg'
        "
      />
      <h2 class="thank-you-title" data-cy="rate-this-meeting-thank-you-title">
        {{ $t('rate_this_meeting.thank_you_title') }}
      </h2>
      <h1
        class="thank-you-description"
        data-cy="rate-this-meeting-thank-you-description"
      >
        {{ thankYouDescription }}
      </h1>
    </div>
    <!--   Details screen   -->
    <div
      v-else
      class="card choices-step"
      data-cy="rate-this-meeting-choices-window"
    >
      <div v-if="!isMobileWebMode" class="choices-icons">
        <v-icon class="choices-icon" iconName="Vlt-icon-device-cloud" />
      </div>
      <h4 class="negative-title" data-cy="rate-this-meeting-neg-title">
        {{ negativeTitle }}
      </h4>
      <!--   Details Textarea screen   -->
      <div v-if="showDetailsSection" class="negative-description">
        <label data-cy="rate-this-meeting-neg-description" class="Vlt-label">
          {{ negativeDescription }}
        </label>
        <div class="Vlt-textarea" data-cy="rate-this-meeting-textarea">
          <textarea
            v-model="descriptionIssue"
            class="details-textarea"
            rows="7"
            maxlength="10000"
          ></textarea>
        </div>
      </div>
      <!--   Details checkbox screen   -->
      <v-checkbox
        v-for="(option, index) in options"
        v-else
        :key="index"
        v-model="option.checked"
        :inverted="isMobileWebMode"
        class="reason"
        :data-cy="`rate-this-meeting-option-${index}`"
      >
        {{ option.label }}
      </v-checkbox>
      <div data-cy="rate-buttons" class="rate-buttons">
        <vwc-button
          v-if="showDetailsSection"
          class="rate-button"
          data-cy="back-button"
          :label="$t('rate_this_meeting.back_button')"
          layout="outlined"
          @click="back"
        />
        <vwc-button
          v-else
          class="rate-button"
          data-cy="cancel-button"
          :label="$t('rate_this_meeting.cancel_button')"
          layout="outlined"
          @click="cancelRating"
        />
        <vwc-button
          class="rate-button submit-button"
          data-cy="submit-button"
          :label="$t('rate_this_meeting.submit_button')"
          :connotation="isMobileWebMode ? 'primary' : 'cta'"
          layout="filled"
          :disabled="!isAnyOptionSelected && options.length"
          @click="submit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

const ANALYTICS_DATA = {
  up: 'Good',
  down: 'Bad',
  cancelReason: 'Cancel'
};

export default {
  name: 'RateThisMeeting',

  data() {
    return {
      showNegativeDetailsSection: false,
      showFinalThankYouMessage: false,
      showDetailsSection: false,
      descriptionIssue: '',
      options: [
        {
          value: 'I couldn’t hear everyone',
          label: this.$t('rate_this_meeting.couldnt_hear_everyone_label'),
          checked: false
        },
        {
          value: 'Not everyone could hear me',
          label: this.$t('rate_this_meeting.not_everyone_hear_me_label'),
          checked: false
        },
        {
          value: 'Video Quality',
          label: this.$t('rate_this_meeting.video_quality_label'),
          checked: false
        },
        {
          value: 'Audio Quality',
          label: this.$t('rate_this_meeting.audio_quality_label'),
          checked: false
        },
        {
          value: 'Dropped',
          label: this.$t('rate_this_meeting.dropped_label'),
          checked: false
        },
        {
          value: 'Other',
          label: this.$t('rate_this_meeting.other_label'),
          checked: false
        }
      ]
    };
  },

  computed: {
    ...mapState(['isBranded']),
    ...mapGetters(['isMobileWebMode']),

    isAnyOptionSelected() {
      return this.options.some((option) => option.checked);
    },

    isOtherSelected() {
      return this.options.some(
        (option) => option.value === 'Other' && option.checked
      );
    },

    negativeTitle() {
      return this.showDetailsSection
        ? this.$t('rate_this_meeting.negative_title_with_details')
        : this.$t('rate_this_meeting.negative_title');
    },

    negativeDescription() {
      return this.isBranded
        ? this.$t('rate_this_meeting.negative_description_branded_title')
        : this.$t('rate_this_meeting.negative_description_title');
    },

    thankYouDescription() {
      return this.isBranded
        ? this.$t('rate_this_meeting.thank_you_branded_description')
        : this.$t('rate_this_meeting.thank_you_description');
    }
  },

  methods: {
    ...mapActions(['reportRating']),

    setNegativeRating() {
      this.showNegativeDetailsSection = true;
      this.$emit('rateMeetingClicked');
    },

    setPositiveRating() {
      this.showDetailsSection = false;
      this.showFinalThankYouMessage = true;
      this.report(ANALYTICS_DATA.up);
      this.$emit('rateMeetingClicked');
    },

    cancelRating() {
      this.showFinalThankYouMessage = true;
      this.report(ANALYTICS_DATA.down, ANALYTICS_DATA.cancelReason);
    },

    back() {
      this.showDetailsSection = false;
    },

    submit() {
      if (
        this.isOtherSelected &&
        !this.showDetailsSection &&
        !this.showFinalThankYouMessage
      ) {
        this.showDetailsSection = true;
      } else {
        this.showFinalThankYouMessage = true;
        this.report(ANALYTICS_DATA.down);
      }
    },

    report(action, reason) {
      let reasons = reason ? [reason] : [];
      let summary = '';
      if (this.isAnyOptionSelected) {
        const selectedReasons = this.options.filter((option) => option.checked);
        reasons = selectedReasons.map((option) => option.value);
        summary = selectedReasons.map((option) => option.label).join('. ');
      }
      this.reportRating({
        action,
        reasons,
        descriptionIssue: this.descriptionIssue,
        summary
      });
      this.$emit('finishRateMeeting');
    }
  }
};
</script>

<style scoped>
.rate-this-meeting {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-color: white;
  background-size: 80% 50%;
  background-repeat: no-repeat;
  background-position: top;
  background-image: url('/img-clouds-2.svg');
  text-align: center;
}

.branded .rate-this-meeting {
  background-image: unset;
}

.rate-this-meeting.mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-color: black;
  background-image: unset;
  text-align: center;
}

.card.main-card.thank-you {
  width: 460px;
  padding: 16px 48px 48px 48px;
}

.rate-this-meeting.mobile .card.main-card.thank-you {
  padding: 16px 48px 0 48px;
}

.card.choices-step {
  padding: 24px;
  width: 460px;
  max-height: 500px;
}

.rate-this-meeting.mobile .card.choices-step {
  width: unset;
  padding: unset;
}

.rate-title {
  font-size: 26px;
  line-height: 36px;
  margin-bottom: 8px;
  letter-spacing: unset;
}

.rate-this-meeting.mobile .rate-title {
  font-size: 16px;
  line-height: 24px;
  font-weight: unset;
  color: white;
}

.rate-description {
  font-size: 16px;
  line-height: 24px;
  margin-top: 5px;
  margin-left: 1px;
}

.negative-title {
  font-size: 20px;
  line-height: 125%;
  letter-spacing: unset;
  margin-top: 16px;
  margin-bottom: 8px;
  text-align: left;
}

.rate-this-meeting.mobile .negative-title {
  color: white;
  text-align: center;
}

.negative-description {
  margin-top: 12px;
}

.negative-description > label {
  font-size: 16px;
  width: 420px;
  line-height: 23px;
}

.rate-this-meeting.mobile .negative-description > label {
  width: 290px;
  font-size: 14px;
  text-align: center;
  line-height: 23px;
  padding-left: 30px;
  color: white;
}

.details-textarea {
  resize: none;
  border-radius: 6px;
}

.rate-this-meeting.mobile .details-textarea {
  background-color: black;
  border: 1px solid white;
  margin-top: 24px;
  height: 100px;
  color: white;
}

.choices-icon {
  width: 40px;
  height: 40px;
}

.rate-this-meeting.mobile .choices-icon {
  border: 2px solid white;
}

.thumbs-section {
  margin-top: 13px;
}

vwc-icon-button.thumb-btn {
  margin: 10px;
}

.reason {
  margin-top: 26px;
  font-size: 14px;
  line-height: 21px;
}

.reason:not(:first-child) {
  margin-top: 16px;
}

.reason > label {
  margin-bottom: 0 !important;
}

.rate-this-meeting.mobile .reason {
  color: white;
  margin-top: 20px;
}

.rate-buttons {
  text-align: left;
  padding-top: 32px;
}

.rate-this-meeting.mobile .rate-buttons {
  text-align: center;
  padding-top: 40px;
}

.rate-button {
  width: 82px;
  height: 40px;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  transition: none !important;
  -webkit-transition: none !important;
}

.submit-button {
  margin-left: 8px;
}

.rate-this-meeting.mobile .rate-button {
  width: 110px;
}

.choices-icons {
  display: flex;
  justify-content: space-between;
}

.thank-you-title {
  font-size: 32px;
  line-height: 44px;
  letter-spacing: unset;
  margin-top: 24px;
  margin-bottom: 8px;
}

.rate-this-meeting.mobile .thank-you-title {
  font-size: 26px;
  line-height: 36px;
  color: white;
}

.thank-you-description {
  font-size: 20px;
  line-height: 28px;
  letter-spacing: unset;
  margin-top: 5px;
  margin-left: 1px;
  white-space: pre-line;
}

.rate-this-meeting.mobile .thank-you-description {
  font-size: 14px;
  line-height: 23px;
  font-weight: unset;
  color: white;
  padding-right: 80px;
  padding-left: 80px;
}

.branded .vvd-scheme-alternate {
  --vvd-color-cta: var(--btn-cta-color-white-bg);
  --vvd-color-on-cta: var(--btn-cta-color-white-bg-text-color);
}

.branded .thank-you-icon {
  --vvd-icon-size: 64px;
  margin-right: 13px;
}
</style>
