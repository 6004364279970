var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showOpenMeetingScreen
    ? _c(
        "div",
        {
          staticClass: "join-from-link-screen",
          attrs: { "data-cy": "join-from-link-screen" },
        },
        [
          !_vm.isBranded && !_vm.domainsConfigurations.isCsp
            ? _c("div", { staticClass: "vonage-logo" }, [
                _c("img", { attrs: { src: "/vonage-logo-with-text.svg" } }),
              ])
            : _vm._e(),
          _vm.isLocaleSwitcherEnabled
            ? _c("LocaleSwitcher", { staticClass: "locale-switcher" })
            : _vm._e(),
          _c("div", { staticClass: "container" }, [
            _c("div", { staticClass: "image-title-container" }, [
              _vm.isBranded
                ? _c(
                    "div",
                    { staticClass: "branded-icon" },
                    [
                      _c("vwc-icon", {
                        staticClass: "icon",
                        attrs: { type: "video-conference-line" },
                      }),
                    ],
                    1
                  )
                : _c("img", {
                    staticClass: "image",
                    attrs: { src: _vm.joinMeetingFromLinkScreenImgPath },
                  }),
              _c(
                "div",
                { staticClass: "title", attrs: { "data-cy": "title" } },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("join_meeting_from_link_screen.title")) +
                      " "
                  ),
                ]
              ),
            ]),
            _c(
              "div",
              { staticClass: "checkbox-buttons-container" },
              [
                _c(
                  "vwc-formfield",
                  {
                    staticClass: "checkbox-text",
                    attrs: { label: _vm.checkBoxLabel },
                  },
                  [
                    _c("vwc-checkbox", {
                      attrs: { checked: _vm.rememberChoiceCheckbox },
                      on: { change: _vm.rememberChoiceCheckboxChanged },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "buttons-container" },
                  [
                    _c("vwc-3-button", {
                      staticClass: "continue-button vvd-root",
                      attrs: {
                        label: _vm.$t(
                          "join_meeting_from_link_screen.continue_button"
                        ),
                        appearance: "outlined",
                        connotation: "primary",
                        disabled: _vm.rememberChoiceCheckbox,
                      },
                      on: {
                        click: function () {
                          return _vm.confirmOpenMeeting(false)
                        },
                      },
                    }),
                    _c("vwc-3-button", {
                      staticClass: "confirm-button vvd-root",
                      attrs: {
                        label: _vm.$t(
                          "join_meeting_from_link_screen.confirmation_button"
                        ),
                        appearance: "filled",
                        connotation: "cta",
                        icon: _vm.isBranded ? "" : "vonage-solid",
                      },
                      on: {
                        click: function () {
                          return _vm.confirmOpenMeeting(true)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _c("div", [_c("LoadingScreen")], 1)
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }