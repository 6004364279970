var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mute-indication-popper" }, [
    _c(
      "div",
      { staticClass: "popper-content-container" },
      [
        _c("div", [_vm._v(_vm._s(_vm.$t("mute_indication.text")))]),
        _c("div", [_vm._v(_vm._s(_vm.$t("mute_indication.suggested_action")))]),
        _c("v-icon", {
          staticClass: "close-mute-indication",
          attrs: { iconName: "Vlt-icon-cross" },
          on: { click: _vm.closeMuteIndication },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }