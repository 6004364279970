/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-microphone-mute': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3.1 10.1C2.4 9 2 7.8 2 6.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5c0 1.6.5 3.1 1.4 4.3l.7-.7zM15.5 6c-.3 0-.5.2-.5.5 0 3.6-2.9 6.5-6.5 6.5-1.4 0-2.6-.4-3.7-1.1l1.5-1.5c.7.4 1.4.6 2.2.6C11 11 13 9 13 6.5v-2c0-.2 0-.5-.1-.7L15.8.9c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0L.6 14.6c-.2.2-.2.5 0 .7.2.2.5.2.7 0l2.8-2.8c1.1.9 2.5 1.4 3.9 1.5v1.5c0 .3.2.5.5.5s.5-.2.5-.5V14c3.9-.3 7-3.5 7-7.5 0-.3-.2-.5-.5-.5zm-3.5.5c0 1.9-1.6 3.5-3.5 3.5-.5 0-1-.1-1.5-.3l5-5v1.8zM5.3 7.9C5.1 7.4 5 7 5 6.5v-2C5 2.6 6.6 1 8.5 1c1 0 1.9.4 2.5 1.1l.7-.7C10.9.5 9.8 0 8.5 0 6 0 4 2 4 4.5v2c0 .8.2 1.5.5 2.1l.8-.7z"/>'
  }
})
