var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "reactions-container" },
    [
      _c(
        "transition-group",
        { attrs: { name: "screenReactions" } },
        _vm._l(_vm.screenReactions, function (reaction) {
          return _c("img", {
            key: reaction.id,
            staticClass: "reaction",
            style: reaction.style,
            attrs: { src: _vm.iconsTypes[reaction.type].path },
          })
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }