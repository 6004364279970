<template>
  <div
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 2000,
      throttleOptions: {
        leading: 'visible'
      }
    }"
    class="stream"
  >
    <!-- Using v-show because content still need to be on the DOM, e.g. for allowing sound even when a stream's video is muted -->
    <div v-show="canShowVideo" class="video-container">
      <div
        ref="video"
        :class="{
          [stream.type]: true,
          'fitmode-contain': fitMode === 'contain',
          'fitmode-cover': fitMode === 'cover'
        }"
        class="video-element"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {
  STREAM_TYPE,
  MUTE_DEVICES_UNPUBLISH_DELAY,
  PUBLISHER_STATE,
  LAYOUT_MODE_TYPES
} from '@/consts/global-consts';
import { isScreenshareStream } from '@/helpers/meeting-helpers';
import MeetingManager from '@/services/meeting-manager-service';
import throttle from 'lodash.throttle';

export default {
  name: 'VideoStream',

  props: {
    stream: {
      type: Object,
      required: true
    },
    preferred: {
      type: Object,
      required: true
    },
    isMainStream: {
      type: Boolean,
      default: false
    },
    subscriberStatus: {
      type: String,
      default: ''
    },
    isPageVisible: {
      type: Boolean,
      default: true
    },
    preferredFitMode: {
      type: String,
      default: 'contain'
    },
    canShowVideo: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      unpublishTimeoutId: '',
      // Call the function at most every 1500ms to prevent spamming tokbox
      setPreferredResolutionThrottled: throttle(
        this.setPreferredResolution,
        1500,
        { leading: false }
      ),
      setPreferredFrameRateThrottled: throttle(
        this.setPreferredFrameRate,
        2500,
        { leading: false }
      ),
      isVisible: true
    };
  },

  computed: {
    ...mapState([
      'isVideoEnabled',
      'isMicEnabled',
      'isNoAudioMode',
      'optimizePublishers',
      'publisherState',
      'pinnedStreamId',
      'minimizedMode',
      'unsubscribedVideoStreamIds'
    ]),
    ...mapGetters([
      'participants',
      'dominantSpeakerStreamId',
      'isMobileWebMode',
      'screenshareStreamId',
      'isCaptionsFeatureAvailable'
    ]),

    isScreenshare() {
      return isScreenshareStream(this.stream);
    },

    streamParticipant() {
      return this.stream ? this.participants[this.stream.participantId] : null;
    },

    hasVideo() {
      return (
        this.stream &&
        this.stream.hasVideo &&
        (!this.stream.isPublisher || this.isVideoEnabled)
      );
    },

    hasAudio() {
      return (
        this.stream &&
        this.stream.hasAudio &&
        (!this.stream.isPublisher || this.isMicEnabled)
      );
    },

    shouldPublish() {
      // Determine if we should publish or unpublish a stream depends on the publishers optimization.
      return (
        this.stream.isPublisher &&
        this.publisherState !== PUBLISHER_STATE.FAILED &&
        (this.isVideoEnabled || this.isMicEnabled || !this.optimizePublishers)
      );
    },

    shouldRepublish() {
      // Determine if we should try to republish a stream after a publish failure.
      return (
        this.stream.isPublisher &&
        this.publisherState === PUBLISHER_STATE.FAILED &&
        (this.isVideoEnabled || this.isMicEnabled)
      );
    },

    isRealStream() {
      return this.stream.type !== STREAM_TYPE.AVATAR;
    },

    shouldSubscribe() {
      return !this.stream.isPublisher && this.isRealStream;
    },

    isUnsubscribedToVideo() {
      return this.unsubscribedVideoStreamIds[this.stream?.streamId];
    },

    fitMode() {
      // Never allow a publisher or main stream to be cut off
      if (this.stream.isPublisher || this.isMainStream) {
        return 'contain';
      }

      return this.preferredFitMode;
    }
  },

  watch: {
    isPageVisible() {
      this.refreshDisableVideo();
    },

    async shouldPublish(shouldPublish) {
      if (shouldPublish) {
        if (this.unpublishTimeoutId) {
          clearTimeout(this.unpublishTimeoutId);
          this.unpublishTimeoutId = '';
        } else {
          await this.publishStream({
            element: this.$refs.video,
            options: this.getStreamOptions(true)
          });
        }
      } else {
        this.unpublishTimeoutId = setTimeout(() => {
          this.unpublishStream();
          this.unpublishTimeoutId = '';
        }, MUTE_DEVICES_UNPUBLISH_DELAY);
      }
    },

    async shouldRepublish(shouldRepublish) {
      if (shouldRepublish) {
        await this.publishStream({
          element: this.$refs.video,
          options: this.getStreamOptions(true)
        });
      }
    },

    shouldSubscribe(newVal) {
      if (newVal) {
        this.dispatchSubscribeStream();
      }
    },

    preferred() {
      this.optimizeSubscriberVideo();
    },

    isUnsubscribedToVideo() {
      this.refreshDisableVideo();
    }
  },

  mounted() {
    this.optimizeSubscriberVideo();

    if (this.stream.isPublisher) {
      if (this.shouldPublish) {
        this.publishStream({
          element: this.$refs.video,
          options: this.getStreamOptions(true)
        });
      }
    } else if (this.shouldSubscribe) {
      this.dispatchSubscribeStream();
    }
  },

  methods: {
    ...mapActions(['publishStream', 'unpublishStream', 'subscribeStream']),

    getStreamOptions(isPublisherStream = false) {
      const streamOptions = {
        // Generally, we don't want to show the controls but due to a bug in share-screen
        // we need to change the default background that displays on the stream until the video is enabled
        // and tokbok allows changing it only if showControls is true
        showControls: this.isScreenshare,
        insertMode: 'append',
        fitMode: 'contain',
        width: '100%',
        height: '100%'
      };
      if (isPublisherStream) {
        // more options for main publisher
        streamOptions.publishCaptions =
          this.isCaptionsFeatureAvailable && !this.isScreenshare;
      } else {
        // more options for subscriber
        streamOptions.subscribeToAudio = !this.isNoAudioMode;
      }
      return streamOptions;
    },

    visibilityChanged(isVisible) {
      this.isVisible = isVisible;
      this.refreshDisableVideo();
    },

    setPreferredResolution(subscriber, resolution) {
      if (MeetingManager.isSubscriberStream(this.stream.streamId)) {
        subscriber.setPreferredResolution(resolution);
      }
    },

    setPreferredFrameRate(subscriber, frameRate) {
      if (MeetingManager.isSubscriberStream(this.stream.streamId)) {
        subscriber.setPreferredFrameRate(frameRate);
      }
    },

    optimizeSubscriberVideo() {
      if (!this.preferred) {
        return;
      }

      const subscriber = MeetingManager.getSubscriberByStreamId(
        this.stream.streamId
      );
      if (subscriber) {
        this.setPreferredResolutionThrottled(subscriber, this.preferred.size);
        this.setPreferredFrameRateThrottled(
          subscriber,
          this.preferred.frameRate
        );
      }
    },

    async dispatchSubscribeStream() {
      try {
        await this.subscribeStream({
          streamId: this.stream.streamId,
          element: this.$refs.video,
          options: this.getStreamOptions()
        });
      } catch (e) {
        // We have infinite retries on subscribe creation.
      }
      this.optimizeSubscriberVideo();
      this.refreshDisableVideo();
    },

    // Insepect the situation, and decide should we show or hide the video, the logic is:
    // If page is not visible - hide the stream.
    // If we are watching pinned or screenshare stream and not in grid mode - hide the stream.
    async refreshDisableVideo() {
      const subscriber = MeetingManager.getSubscriberByStreamId(
        this.stream.streamId
      );
      if (subscriber) {
        if (this.isUnsubscribedToVideo) {
          subscriber.subscribeToVideo(false);
          return;
        }
        // If the stream itself is visible
        if (this.isVisible) {
          // Show or hide based on the page visibility
          subscriber.subscribeToVideo(this.isPageVisible);

          // If the stream is not visible and we are not in grid mode and there is a
          // screenshare in progress (not necessarily ours) or a pinned stream, hide the stream
        } else if (
          this.layoutMode !== LAYOUT_MODE_TYPES.GRID &&
          (this.screenshareStreamId === this.dominantSpeakerStreamId ||
            this.pinnedStreamId)
        ) {
          subscriber.subscribeToVideo(false);
        }
      }
    }
  }
};
</script>

<style scoped>
.stream {
  height: 100%;
  width: 100%;
}

.video-container,
.video-element {
  height: 100%;
  border-radius: 8px;
  transform: translateZ(0);
}

.video-element {
  width: 100%;
}

.video-element.fitmode-contain >>> video {
  object-fit: contain;
}

.video-element.fitmode-cover >>> video {
  object-fit: cover;
}

.mobile .video-element >>> video {
  background: black;
}

:not(.mobile) .video-element >>> video {
  background: #0d0d0d;
}

.stream .video-container {
  overflow: hidden;
  z-index: 9;
  border-radius: 8px;
}

.mobile .participant:not(.main-participant) .video-container {
  border-radius: 8px;
}
</style>
