<template>
  <v-modal
    class="modal"
    noFooter
    dismiss
    dismissEsc
    dismissClickOutside
    confirmEnter
    @close="dismiss"
  >
    <template slot="content">
      <div class="modal-container">
        <div class="illustration">
          <img src="/whiteboard-large-session.png" />
        </div>
        <div class="text-container">
          <div class="title">
            {{ $t('large_whiteboard_session_modal.title') }}
          </div>
          <div class="text">
            {{ $t('large_whiteboard_session_modal.text') }}
          </div>

          <div class="buttons-container">
            <vwc-button
              :label="$t('large_whiteboard_session_modal.ok_button')"
              layout="filled"
              @click="dismiss"
            />
          </div>
        </div>
      </div>
    </template>
  </v-modal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  methods: {
    ...mapActions('whiteboard', ['dismissLargeWhiteboardSessionModal']),

    dismiss() {
      this.dismissLargeWhiteboardSessionModal();
    }
  }
};
</script>

<style scoped>
.modal >>> .Vlt-modal__panel {
  width: 500px;
  padding: 24px;
}

.modal >>> .Vlt-modal__content {
  padding-bottom: 0;
}

.modal-container {
  display: flex;
  flex-direction: row;
}

.illustration {
  width: 117px;
  margin-right: 24px;
}

.text-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.text {
  flex: 1;
  max-width: 300px;
}

.buttons-container {
  margin-top: 16px;
}

.buttons-container > .Vlt-btn {
  margin: 0;
}
</style>
