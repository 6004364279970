/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-document-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.5 1.5h12c.9 0 1.5.6 1.5 1.5v15c0 2.55-1.95 4.5-4.5 4.5H8.1c.6-.9.9-1.95.9-3V3c0-.9.6-1.5 1.5-1.5zM0 12h6v7.5c0 1.8-1.65 3.3-3.45 3C1.05 22.2 0 20.85 0 19.35V12z"/>'
  }
})
