var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isLoadingRoomDetails
    ? _c(
        "div",
        { staticClass: "mobile-guest-screen-container Vlt-bg-white" },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              _vm.isBranded
                ? _c("div", { staticClass: "brand-logo-container" }, [
                    _vm.brandImageColored
                      ? _c("img", {
                          staticClass: "brand-logo",
                          attrs: { src: _vm.brandImageColored },
                        })
                      : _c("div", { staticClass: "brand-text" }, [
                          _vm._v(_vm._s(_vm.brandText)),
                        ]),
                  ])
                : _c("v-icon", {
                    staticClass: "logo",
                    attrs: { iconName: "vonage-logo" },
                  }),
            ],
            1
          ),
          _c(
            "h2",
            { staticClass: "main-title-container" },
            [
              _c("PlaceholderLoadingSkeleton", {
                staticClass: "main-title-skeleton",
                attrs: { loading: "" },
              }),
            ],
            1
          ),
          _vm.isVBCRoom
            ? _c(
                "h3",
                { staticClass: "join-via-app-header" },
                [
                  _c("PlaceholderLoadingSkeleton", {
                    staticClass: "join-via-app-header-skeleton",
                    attrs: { loading: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isVBCRoom
            ? _c("div", { staticClass: "join-via-app-text Vlt-black" }, [
                _c(
                  "div",
                  [
                    _c("PlaceholderLoadingSkeleton", {
                      staticClass: "join-via-app-text-skeleton",
                      attrs: { loading: "" },
                    }),
                    _c("PlaceholderLoadingSkeleton", {
                      staticClass: "join-via-app-text-skeleton",
                      attrs: { loading: "" },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm.isVBCRoom
            ? _c("PlaceholderLoadingSkeleton", {
                staticClass: "action-button-skeleton",
                attrs: { loading: "" },
              })
            : _vm._e(),
          _vm.isVBCRoom
            ? _c("div", { staticClass: "separator" }, [
                _c("hr", { staticClass: "hr" }),
                _c("span", { staticClass: "separator-span" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "mobile_join_meeting_from_link_screen.or_separator"
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "other-option-skeleton-container" },
            [
              _c("PlaceholderLoadingSkeleton", {
                staticClass: "other-option-title-skeleton",
                attrs: { loading: "" },
              }),
              _c("PlaceholderLoadingSkeleton", {
                staticClass: "other-option-description-skeleton",
                attrs: { loading: "" },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _c("div", { staticClass: "mobile-guest-screen-container Vlt-bg-white" }, [
        _c(
          "div",
          { staticClass: "header" },
          [
            _vm.isBranded
              ? _c("div", { staticClass: "brand-logo-container" }, [
                  _vm.brandImageColored
                    ? _c("img", {
                        staticClass: "brand-logo",
                        attrs: { src: _vm.brandImageColored },
                      })
                    : _c("div", { staticClass: "brand-text" }, [
                        _vm._v(_vm._s(_vm.brandText)),
                      ]),
                ])
              : _c("v-icon", {
                  staticClass: "logo",
                  attrs: { iconName: "vonage-logo" },
                }),
          ],
          1
        ),
        _c("h2", { staticClass: "main-title-container Vlt-black" }, [
          _vm._v(" " + _vm._s(_vm.roomDisplayName) + " "),
        ]),
        _vm.shouldShowBrowserNotSupportedSection
          ? _c("div", [
              _c(
                "h3",
                { staticClass: "browser-not-supported-header Vlt-black" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "mobile_join_meeting_from_link_screen.browser_not_supported_header"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c("p", { staticClass: "browser-not-supported-text Vlt-black" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "mobile_join_meeting_from_link_screen.browser_not_supported_text"
                      )
                    ) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "separator" }, [
                _c("hr", { staticClass: "hr" }),
                _c("span", { staticClass: "separator-span" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(
                        "mobile_join_meeting_from_link_screen.or_separator"
                      )
                    )
                  ),
                ]),
              ]),
            ])
          : _vm._e(),
        _vm.isVBCRoom
          ? _c(
              "div",
              [
                _c(
                  "h3",
                  {
                    staticClass: "join-via-app-header Vlt-black",
                    class: {
                      "border-top": !_vm.shouldShowBrowserNotSupportedSection,
                    },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "mobile_join_meeting_from_link_screen.join_via_app_header"
                          )
                        ) +
                        " "
                    ),
                  ]
                ),
                _c("p", { staticClass: "join-via-app-text Vlt-black" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "mobile_join_meeting_from_link_screen.join_via_app_text"
                        )
                      ) +
                      " "
                  ),
                ]),
                _c("vwc-button", {
                  staticClass: "action-button",
                  attrs: {
                    label: _vm.$t(
                      "mobile_join_meeting_from_link_screen.download_app_button"
                    ),
                    layout: "filled",
                    connotation: "cta",
                    fullwidth: "",
                    enlarged: "",
                  },
                  on: { click: _vm.downloadApp },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.shouldShowBrowserNotSupportedSection || _vm.isVBCRoom
          ? _c("div", { staticClass: "separator" }, [
              _c("hr", { staticClass: "hr" }),
              _vm.isVBCRoom
                ? _c("span", { staticClass: "separator-span" }, [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "mobile_join_meeting_from_link_screen.or_separator"
                        )
                      )
                    ),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _c("div", { staticClass: "other-option-container" }, [
          !_vm.isMobileWebSupported
            ? _c(
                "div",
                { staticClass: "other-option-container" },
                [
                  _c("h3", { staticClass: "join-via-dial-header Vlt-black" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "mobile_join_meeting_from_link_screen.join_via_dial_header"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c("p", { staticClass: "join-via-dial-text Vlt-black" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "mobile_join_meeting_from_link_screen.join_via_dial_text"
                          )
                        ) +
                        " "
                    ),
                  ]),
                  _c(
                    "VSelect",
                    {
                      staticClass: "join-via-dial-select",
                      attrs: {
                        label: _vm.$t(
                          "mobile_join_meeting_from_link_screen.join_via_dial_select"
                        ),
                      },
                      model: {
                        value: _vm.selectedCountryNumber,
                        callback: function ($$v) {
                          _vm.selectedCountryNumber = $$v
                        },
                        expression: "selectedCountryNumber",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "default" },
                        _vm._l(_vm.dialInNumbers, function (entry, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: {
                                value: entry.number,
                                selected:
                                  entry.number === _vm.selectedCountryNumber,
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(entry.display_name) +
                                  " - " +
                                  _vm._s(_vm.formatNumber(entry.number)) +
                                  " "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ],
                    2
                  ),
                  _c("vwc-button", {
                    staticClass: "action-button",
                    attrs: {
                      label: _vm.$t(
                        "mobile_join_meeting_from_link_screen.dial_button",
                        {
                          selectedCountryNumber: _vm.formatNumber(
                            _vm.selectedCountryNumber
                          ),
                        }
                      ),
                      layout: "outlined",
                      fullwidth: "",
                      enlarged: "",
                    },
                    on: { click: _vm.dial },
                  }),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "other-option-container" },
                [
                  _c("vwc-button", {
                    staticClass: "action-button",
                    attrs: {
                      label: _vm.$t(
                        "mobile_join_meeting_from_link_screen.continue_button"
                      ),
                      layout: "filled",
                      fullwidth: "",
                      enlarged: "",
                    },
                    on: { click: _vm.continueInBrowser },
                  }),
                ],
                1
              ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }