/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-method-post': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 7.998v7.508h1.33v-2.587h1.759c1.576 0 2.476-1.115 2.476-2.55 0-1.387-.853-2.37-2.33-2.37H0zm1.33 1.257h1.686c.759 0 1.199.445 1.199 1.167 0 .764-.492 1.251-1.283 1.251H1.33V9.255zM9.272 9.778c-1.701 0-2.78 1.141-2.78 2.906 0 1.764 1.079 2.906 2.78 2.906 1.697 0 2.775-1.142 2.775-2.906 0-1.764-1.078-2.906-2.775-2.906zm0 1.1c.948 0 1.487.675 1.487 1.806 0 1.136-.54 1.806-1.487 1.806-.947 0-1.492-.67-1.492-1.806 0-1.131.545-1.806 1.492-1.806zM18.256 11.265c-.33-.9-1.235-1.492-2.617-1.492-1.461 0-2.45.654-2.45 1.796 0 1.314 1.303 1.476 2.413 1.607.796.094 1.487.178 1.487.686 0 .43-.508.691-1.178.691-.717 0-1.356-.288-1.613-.916l-1.188.377c.398 1.026 1.497 1.576 2.77 1.576 1.46 0 2.497-.733 2.497-1.89 0-1.31-1.309-1.44-2.408-1.555-.801-.084-1.498-.157-1.498-.686 0-.403.42-.644 1.11-.644.78 0 1.288.298 1.503.822l1.172-.372zM23.796 9.878h-1.87V8.176l-1.292.335v1.367h-1.425v1.146h1.425v2.765c0 1.157.602 1.8 1.979 1.8.45 0 .947-.073 1.387-.183l-.1-1.078a4.081 4.081 0 01-.9.115c-1.073 0-1.073-.66-1.073-.937v-2.482h1.869V9.878z"/>'
  }
})
