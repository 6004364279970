var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-container", on: { click: _vm.imageClicked } },
    [
      _c(
        "div",
        {
          staticClass: "image-wrapper Vlt-btn-on-hover",
          class: {
            "image-uploading": _vm.isUploading,
            "image-wrapper-not-sent": _vm.sendFailed,
          },
          on: {
            mouseenter: function ($event) {
              _vm.hover = true
            },
            mouseleave: function ($event) {
              _vm.hover = false
            },
          },
        },
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.isUploading && !_vm.sendFailed
              ? _c("div", { staticClass: "thumbnail-text text-ellipsis" }, [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("image_row.uploading_image_text", {
                          percentsUploaded: _vm.percentsUploaded,
                        })
                      ) +
                      " "
                  ),
                ])
              : _vm._e(),
            _vm.sendFailed || _vm.downloadFailed
              ? _c("div", { staticClass: "thumbnail-text text-ellipsis" }, [
                  _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
                ])
              : _vm._e(),
          ]),
          _vm.isUploading
            ? _c("v-spinner", {
                staticClass: "spinner",
                attrs: { white: _vm.isMobileWebMode },
              })
            : _vm._e(),
          _vm.showActionFailedButtons
            ? _c(
                "div",
                { staticClass: "action-failed-buttons" },
                [
                  _c("vwc-button", {
                    attrs: {
                      label: _vm.uploadCancelled
                        ? _vm.$t("image_row.upload_again_label")
                        : _vm.$t("image_row.try_again_label"),
                      layout: "filled",
                      dense: "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.tryAgain($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isUploading
            ? _c(
                "div",
                { staticClass: "uploading-action-buttons" },
                [
                  _c("vwc-button", {
                    attrs: {
                      label: _vm.$t("image_row.cancel_button"),
                      layout: "filled",
                      dense: "",
                    },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.cancelUpload($event)
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("img", {
            staticClass: "image-thumbnail",
            attrs: { src: _vm.image.src },
          }),
          _vm.showDownloadButton
            ? _c(
                "vwc-icon-button",
                {
                  staticClass: "download-button vvd-scheme-main",
                  attrs: {
                    icon: "download-line",
                    layout: "filled",
                    disabled: _vm.isDownloading,
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.downloadFile()
                    },
                  },
                },
                [
                  _vm.isDownloading
                    ? _c("vwc-circular-progress", {
                        attrs: { indeterminate: "", density: "-6" },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }