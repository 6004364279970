<template>
  <div data-cy="flash-messages" class="flash-messages-container">
    <VFlashMessage
      v-for="message in flashMessages"
      :key="message.id"
      :options="message"
    >
      <template slot="text">
        {{ message.text }}
      </template>
    </VFlashMessage>
  </div>
</template>
<script>
import VFlashMessage from '@/components/volta/VFlashMessage.vue';
import { mapState } from 'vuex';

export default {
  components: {
    VFlashMessage
  },
  computed: {
    ...mapState(['flashMessages'])
  }
};
</script>

<style scoped>
.flash-messages-container {
  position: absolute;
  display: inline-flex;
  flex-direction: column;
  margin: 40px 15px 15px 15px;
  width: 410px;
  max-width: 100%;
  z-index: 10000;
}

@media only screen and (max-width: 600px) {
  .flash-messages-container {
    margin-left: unset;
    margin-right: unset;
  }
}
</style>
