/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-more-negative': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<circle pid="0" cx="8" cy="8" r="2"/><circle pid="1" cx="2" cy="8" r="2"/><circle pid="2" cx="14" cy="8" r="2"/>'
  }
})
