<template>
  <div
    v-tooltip.top-start="{
      content: hoverTooltip,
      classes: 'meeting-action-menu-btn-tooltip'
    }"
    v-on-clickaway="closeMenu"
    class="meeting-action-container"
    :class="{
      'button-with-focused-element': hasFocusedElement
    }"
  >
    <vwc-action-group shape="rounded" layout="ghost" tight>
      <vwc-button
        :id="!split ? label : ''"
        data-cy="meeting-action-button"
        :label="label"
        :layout="!split && isMenuOpen ? 'filled' : 'text'"
        :class="{ clicked: !split && isMenuOpen, privacy: label === 'Privacy' }"
        shape="rounded"
        stacked
        :disabled="disabled"
        onmouseup="this.blur()"
        @mouseover="$emit('hover')"
        @mouseleave="$emit('unhover')"
        @click="!split && hasMenu ? toggleMenu() : $emit('click')"
      >
        <vwc-circular-progress
          v-if="loading"
          slot="icon"
          class="loading"
          indeterminate
          density="-6"
        />
        <vwc-icon
          v-else
          slot="icon"
          class="icon"
          data-cy="action-button-icon"
          :class="{ disabled: disabled }"
          :connotation="iconConnotation"
          :type="icon"
        />
      </vwc-button>
      <span v-show="split" role="separator" class="separator" />
      <vwc-icon-button
        v-show="split"
        :id="split ? label : ''"
        icon="chevron-up-solid"
        :layout="isMenuOpen ? 'filled' : 'text'"
        class="split"
        :class="{ clicked: isMenuOpen }"
        shape="rounded"
        dense
        :disabled="disableSplit"
        onmouseup="this.blur()"
        @click="toggleMenu()"
      />
    </vwc-action-group>
    <vwc-popup
      :anchor="label"
      class="menu"
      :corner="flipMenu ? 'top-start' : 'top-end'"
      :open="isMenuOpen"
      @closed="closeMenu"
    >
      <div class="popup-container">
        <vwc-list v-for="(list, index) in lists" :key="index">
          <div v-if="list.label" class="list-label">{{ list.label }}</div>
          <vwc-list-item
            v-for="item in list.items"
            :key="item.id"
            :graphic="item.iconName ? 'icon' : ''"
            hasMeta
            :twoline="item.subtitle"
            class="list-item"
            :class="{
              interactive: item.type === 'interactive',
              'focus-animation': item.shouldHaveFocusAnimation,
              'glow-button': hasFocusedElement
            }"
            :disabled="item.disabled"
            @animationend="focusAnimationEnded(item)"
            @animationcancel="focusAnimationEnded(item)"
            @click="menuItemClicked(item, list.defaultAction)"
          >
            <span class="item-content">{{ item.label }}</span>
            <span v-if="item.subtitle" slot="secondary">{{
              item.subtitle
            }}</span>
            <vwc-icon
              v-if="item.iconName"
              slot="graphic"
              :type="item.iconName"
              size="small"
            />
            <vwc-switch
              v-if="item.type === 'interactive'"
              slot="meta"
              connotation="cta"
              :disabled="item.disabled"
              :checked="item.active"
              @click.prevent
            />
            <vwc-icon
              v-else-if="item.active"
              slot="meta"
              class="meta-icon"
              type="check-line"
              size="small"
            />
            <portal
              v-if="isFocusAnimationInProgress(item) || hasFocusedElement"
              to="overlay-bg-placeholder"
            >
              <transition name="overlay-bg-animation">
                <div class="overlay-bg" @click="stopFocusAnimation"></div>
              </transition>
            </portal>
          </vwc-list-item>
          <li
            v-if="index !== lists.length - 1"
            class="lists-divider"
            role="divider"
          ></li>
        </vwc-list>
      </div>
    </vwc-popup>

    <slot></slot>
  </div>
</template>

<script type="module">
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'MeetingActionMenuButton',

  mixins: [clickaway],

  props: {
    label: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    activeAlert: {
      type: Boolean,
      default: false
    },
    activeInfo: {
      type: Boolean,
      default: false
    },
    lists: {
      type: Array,
      default: () => []
    },
    hoverTooltip: {
      type: String,
      default: ''
    },
    split: {
      type: Boolean,
      default: false
    },
    disableSplit: {
      type: Boolean,
      default: false
    },
    flipMenu: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isMenuOpen: false,
      isMouseOver: false,
      hasFocusedElement: false
    };
  },

  computed: {
    isFocusAnimationInProgress() {
      return (item) => {
        this.hasFocusedElement =
          this.hasFocusedElement ||
          (!!item.shouldHaveFocusAnimation && this.isMenuOpen);
        return false;
      };
    },

    hasMenu() {
      return this.lists.length !== 0;
    },

    iconConnotation() {
      if (this.activeAlert && !this.disabled) {
        return 'alert';
      }
      if (this.activeInfo) {
        return 'cta';
      }
      return 'primary';
    }
  },

  watch: {
    isMenuOpen(newVal, oldVal) {
      if (oldVal && !newVal && this.hasFocusedElement) {
        this.stopFocusAnimation();
      }
    }
  },

  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },

    menuItemClicked(item, defaultAction) {
      if (item.action) {
        this.$emit(`${item.action}`);
      } else {
        this.$emit(`${defaultAction}`, item.id);
      }

      if (item.type !== 'interactive') {
        this.toggleMenu();
      }
    },

    closeMenu() {
      this.isMenuOpen = false;
    },

    focusAnimationEnded(item) {
      item.animationFinishedAction();
    },

    stopFocusAnimation() {
      this.hasFocusedElement = false;
    }
  }
};
</script>

<!--Using global style to override the v-tooltip css-->
<style>
.meeting-action-menu-btn-tooltip .tooltip-inner {
  background: rgba(24, 25, 27, 0.98);
  border: 1px solid #4d4d4d;
  border-radius: 6px;
  padding: 6px 16px;
  white-space: nowrap;
  width: auto;
  z-index: 100;
  text-align: center;
  box-shadow: none;
  color: white;
  font-weight: 600;
  top: -35px;
}
</style>

<style scoped>
.meeting-action-container {
  position: relative;
  z-index: 80;
}

.loading {
  margin-bottom: 15px;
  margin-right: 15px;
}

.list-item {
  min-width: 220px;
}

vwc-button {
  padding: 8px 0;
}

vwc-icon-button {
  padding: 16px 0;
}

vwc-button.clicked,
vwc-icon-button.clicked {
  --vvd-color-primary: #2a2a2a;
  --vvd-color-on-primary: white;
}

vwc-icon.icon {
  width: 20px;
  height: 20px;
  --vvd-color-primary: white;
}

vwc-icon.icon.disabled {
  --vvd-color-primary: #757575;
}

.separator {
  align-self: center;
  height: 40px;
  margin: 0 4px;
}

.list-label {
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
  color: #929292;
  padding: 8px 16px 5px 16px;
}

.lists-divider {
  border-top: 1px solid #4d4d4d;
  margin: 12px;
}

.popup-container {
  padding: 8px 0;
  max-height: 70vh;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: min-content;
}

vwc-list {
  --mdc-list-vertical-padding: 0px;
}

vwc-list-item.list-item.interactive {
  --mdc-list-item-meta-size: 36px;
  min-width: 230px;
}

vwc-list-item.list-item {
  --mdc-list-item-meta-size: 20px;
}

vwc-list-item.list-item .meta-icon {
  flex-shrink: 0;
  min-width: 20px;
}

vwc-list-item:after {
  border-bottom: unset;
}

.item-content {
  margin-right: 12px;
}

vwc-switch {
  padding: 8px 0;
  width: 36px;
  height: 20px;
}

.focus-animation {
  animation: highlight-background 3s linear forwards;
  animation-iteration-count: 1;
}

@keyframes highlight-background {
  30%,
  60% {
    background: #871eff;
  }

  100% {
    background: none;
  }
}

.overlay-bg {
  position: absolute;
  z-index: 9000;
  height: 100%;
  width: 100%;
  background: black;
  opacity: 70%;
}

.button-with-focused-element {
  z-index: 9999;
}

.overlay-bg-animation-leave-active {
  transition: opacity 0.5s;
}
.overlay-bg-animation-leave-to {
  opacity: 0;
}
</style>
