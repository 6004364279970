/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-method-get': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.325 12.546h1.872v.144c0 .91-.525 1.816-1.695 1.816-1.342 0-1.828-1.181-1.828-2.81 0-1.728.53-2.888 1.922-2.888.922 0 1.452.503 1.568 1.414l1.39-.243C8.29 8.372 7.286 7.5 5.619 7.5c-2.33 0-3.368 1.7-3.368 4.223 0 2.397.944 4.058 2.932 4.058 1.087 0 1.805-.513 2.114-1.303V15.6H8.6v-4.295H5.325v1.242zM15.837 13.093v-.812c0-1.695-1.088-2.722-2.771-2.722-1.822 0-2.998 1.204-2.998 3.103 0 1.85 1.104 3.026 2.97 3.026 1.253 0 2.164-.536 2.7-1.436l-1.16-.48c-.309.475-.784.767-1.49.767-.978 0-1.59-.58-1.651-1.446h4.4zm-1.37-1.01h-3.025c.11-.856.707-1.38 1.601-1.38.84 0 1.425.457 1.425 1.319v.06zM21.535 9.664h-1.971V7.87l-1.364.353v1.441h-1.502v1.21H18.2v2.914c0 1.22.635 1.9 2.087 1.9.475 0 1-.078 1.463-.194l-.105-1.137a4.305 4.305 0 01-.95.122c-1.131 0-1.131-.696-1.131-.989v-2.617h1.97V9.664z"/>'
  }
})
