/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-backspace-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.35 3H7.95L0 12l7.95 9h14.4c.9 0 1.65-.75 1.65-1.65V4.65c0-.9-.75-1.65-1.65-1.65zm-3.6 11.55c.45.45.45 1.2 0 1.65-.45.45-1.2.45-1.65 0l-2.4-2.7-2.7 2.55c-.45.45-1.05.45-1.5 0-.45-.45-.45-1.05 0-1.5L13.05 12 10.5 9.3c-.45-.45-.45-1.05 0-1.5.45-.45 1.2-.45 1.65 0l2.55 2.55 2.55-2.55c.45-.45 1.2-.45 1.65 0 .45.45.45 1.2 0 1.65L16.35 12l2.4 2.55z"/>'
  }
})
