/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-sticker': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.5 12C1.5 6.201 6.201 1.5 12 1.5c3.863 0 7.241 2.086 9.065 5.197A12.039 12.039 0 0018.4 6.4c-6.627 0-12 5.373-12 12 0 .916.102 1.808.297 2.665A10.496 10.496 0 011.5 12zm5.839 11.061C3.027 21.241 0 16.974 0 12 0 5.373 5.373 0 12 0c4.974 0 9.242 3.027 11.061 7.34h.002v.001c0 .002 0 .004.002.006L7.344 23.064l-.004-.002-.001.002-.001-.003zm.56-4.66c0-5.8 4.702-10.5 10.5-10.5.624 0 1.233.053 1.825.157L8.057 20.224A10.576 10.576 0 017.9 18.4z"/>'
  }
})
