/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-3-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 0H3c-.9 0-1.5.6-1.5 1.5v21c0 .9.6 1.5 1.5 1.5h18c.9 0 1.5-.6 1.5-1.5v-21C22.5.6 21.9 0 21 0zm-9 19.5H6v-3h6v3zm6-6H6v-3h12v3zm0-6H6v-3h12v3z"/>'
  }
})
