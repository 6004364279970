<template>
  <PreJoinTemplate
    v-observe-keyboard.l2.enter="enterMeeting"
    :subtitle="subtitle"
    :title="title"
    :useLightGrayBackground="true"
    :showTitleLoader="
      isInWaitingRoom && hasFinishedArtificalTransitionToWaitingRoom
    "
    :showLocaleSwitcher="true"
  >
    <template slot="main-content">
      <div class="controls-container">
        <div class="video-mirror">
          <div v-show="showVideo" class="video-container">
            <div ref="video" class="video" />
          </div>
          <ConfirmationDialogModal v-if="confirmationDialog" />
          <InfoModal v-if="infoDialog" class="infoModal" />
          <PermissionsModal v-if="showPermissionModal && canUseMicrophone" />
          <SettingsModal v-if="isSettingsModalVisible" />
          <NoVideoParticipantTile
            v-show="!showVideo"
            :participantType="myParticipant.type"
            :participantProfilePicture="myParticipant.profilePicture"
            :displayName="myParticipant.displayName"
            avatar-size="lg"
          />
        </div>

        <div class="controls">
          <div class="device-options-container">
            <MeetingActionMenuButton
              data-cy="toggle-mic"
              class="toggle-device mic-button"
              :class="{ 'no-audio': !joinWithComputerAudio }"
              :label="microphoneToggleText"
              :icon="
                !joinWithComputerAudio
                  ? 'audio-mute-solid'
                  : microphoneEnabled && canUseMicrophone
                  ? 'microphone-2-solid'
                  : 'mic-mute-solid'
              "
              :disabled="isMicDisabled"
              :split="canUseMicrophone"
              :disable-split="isMicDisabled || !joinWithComputerAudio"
              :flip-menu="true"
              :lists="micMenu"
              @click="
                !joinWithComputerAudio
                  ? toggleJoinWithComputerAudio()
                  : toggleMicrophone()
              "
              @speakerDeviceClicked="speakerDeviceClicked"
              @micDeviceClicked="micDeviceClicked"
              @noAudioMode="toggleJoinWithComputerAudio"
            />

            <MeetingActionMenuButton
              class="toggle-device camera-button"
              data-cy="toggle-camera"
              :label="cameraToggleText"
              :icon="
                canUseCamera && cameraEnabled
                  ? 'video-solid'
                  : 'video-off-solid'
              "
              :disabled="isCameraDisabled"
              :split="canUseCamera"
              :disable-split="isCameraDisabled"
              :flip-menu="true"
              :lists="cameraMenu"
              @click="toggleCamera"
              @blur="blurBackgroundClicked"
              @virtualBackground="toggleVirtualBackground"
              @cameraDeviceClicked="cameraDeviceClicked"
            />
          </div>

          <div class="toggle-device">
            <vwc-button
              data-cy="toggle-sidebar"
              :label="$t('pre_join.settings_button')"
              layout="text"
              shape="rounded"
              stacked
              onmouseup="this.blur()"
              icon="gear-solid"
              @click="openSettings"
            />
          </div>
        </div>
      </div>
    </template>

    <template slot="footer-button">
      <vwc-button
        v-if="isInInitialPrejoinState"
        :label="$t('pre_join.enter_button')"
        class="enter-button"
        data-cy="enter-button"
        layout="filled"
        connotation="cta"
        :disabled="isJoining"
        @click="enterMeeting"
      >
        <vwc-circular-progress
          v-if="isJoining"
          class="join-meeting-loading-spinner"
          indeterminate
          density="-7"
        />
      </vwc-button>
      <vwc-button
        v-else
        :label="$t('pre_join.cancel_waiting_room_button')"
        class="enter-button"
        data-cy="cancel-waiting-room-participation-button"
        layout="outlined"
        outlined
        connotation="primary"
        :disabled="isCancellingWaitingRoomParticipation"
        @click="cancelWaitingRoomParticipation"
      >
        <vwc-circular-progress
          v-if="isCancellingWaitingRoomParticipation"
          class="join-meeting-loading-spinner"
          indeterminate
          density="-7"
        />
      </vwc-button>
    </template>

    <template slot="sidebar">
      <div class="sidebar-container">
        <Sidebar
          class="sidebar-wrapper"
          icon="texture-line"
          :title="$t('virtual_background_modal.title')"
        >
          <template slot="sidebar-content">
            <div class="virtual-background-container">
              <VirtualBackgroundSelector
                :selected-background="virtualBackground"
                :disabled="isInitializingVirtualBackground"
                @selectBackground="selectBackground"
              />
            </div>
          </template>
        </Sidebar>
      </div>
    </template>
  </PreJoinTemplate>
</template>

<script>
import NoVideoParticipantTile from './NoVideoParticipantTile';
import Sidebar from './Sidebar';
import PreJoinTemplate from '@/components/PreJoinTemplate';
import { mapActions, mapGetters, mapState } from 'vuex';
import analytics from '@/services/analytics-service';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import InfoModal from '@/components/modals/InfoModal.vue';
import PermissionsModal from '@/components/modals/PermissionsModal.vue';
import {
  ANALYTICS,
  ANALYTICS_SOURCE,
  OT_ERROR,
  SETTING_ANALYTIC_TYPE,
  VIRTUAL_BACKGROUND_TYPE,
  WAITING_ROOM_ARTIFICIAL_TRANSITION_TIME_IN_MILLISECONDS,
  JOIN_APPROVAL_LEVEL
} from '@/consts/global-consts';
import MeetingActionMenuButton from '@/components/MeetingActionMenuButton';
import VirtualBackgroundSelector from '@/components/VirtualBackgroundSelector';
import SettingsModal from '@/components/modals/SettingsModal';
import ConfirmationDialogModal from '@/components/modals/ConfirmationDialogModal';
import { getAvailableDevices } from '@/helpers/devices-utils';

let publisher = null;
let mediaStream = null;

export default {
  name: 'PreJoin',
  components: {
    VirtualBackgroundSelector,
    PreJoinTemplate,
    Sidebar,
    NoVideoParticipantTile,
    InfoModal,
    PermissionsModal,
    MeetingActionMenuButton,
    SettingsModal,
    ConfirmationDialogModal
  },

  data() {
    return {
      // Todo: remove after full release of Prejoin (and change in settings/state.js 'cameraEnabled' default to true)
      cameraEnabled:
        this.$store.state.settings.initialSettings.cameraEnabled !== undefined
          ? this.$store.state.settings.initialSettings.cameraEnabled
          : true,
      microphoneEnabled: this.$store.state.settings.initialSettings
        .microphoneEnabled,
      hasOperatingSystemPermissions: true,
      showPermissionModal: false,
      isJoining: false,
      isCancellingWaitingRoomParticipation: false,
      isInitializingPublisher: true
    };
  },

  computed: {
    ...mapState([
      'myParticipantId',
      'isNoAudioMode',
      'microphoneDevices',
      'cameraDevices',
      'speakerDevices',
      'selectedMicrophoneId',
      'selectedCameraId',
      'selectedSpeakerId',
      'infoDialog',
      'userInfo',
      'initialJoinConfig',
      'virtualBackground',
      'roomDetails',
      'isSettingsModalVisible',
      'confirmationDialog'
    ]),
    ...mapState('layout', ['isSidebarCollapsed']),
    ...mapState('waitingRoom', [
      'isInWaitingRoom',
      'hasFinishedArtificalTransitionToWaitingRoom'
    ]),
    ...mapState('settings', ['shouldShowPreJoinScreen', 'initialSettings']),
    ...mapGetters([
      'roomDisplayName',
      'participants',
      'hasCameraAccess',
      'hasMicrophoneAccess',
      'myParticipantContactInfo',
      'userDisplayName',
      'isGuest',
      'isVirtualBackgroundSupported',
      'isInitializingVirtualBackground',
      'isBlurBackgroundEnabled'
    ]),

    isInInitialPrejoinState() {
      return (
        !this.isInWaitingRoom &&
        !this.hasFinishedArtificalTransitionToWaitingRoom
      );
    },

    subtitle() {
      if (this.isInInitialPrejoinState) {
        return this.$t('pre_join.subtitle', {
          name: this.userInfo.firstName
        });
      }
      return '';
    },

    title() {
      if (this.isInInitialPrejoinState) {
        return this.$t('pre_join.title', {
          roomDisplayName: this.roomDisplayName
        });
      } else if (
        this.isInWaitingRoom &&
        this.hasFinishedArtificalTransitionToWaitingRoom
      ) {
        return this.$t('pre_join.waiting_room_on_title_step2');
      }
      return this.$t('pre_join.waiting_room_on_title_step1');
    },

    cameraToggleText() {
      return this.cameraEnabled
        ? this.$t('pre_join.camera_enabled_text')
        : this.$t('pre_join.camera_disabled_text');
    },

    microphoneToggleText() {
      if (!this.joinWithComputerAudio) {
        return this.$t('pre_join.no_audio_microphone_text');
      }

      return this.microphoneEnabled
        ? this.$t('pre_join.microphone_enabled_text')
        : this.$t('pre_join.microphone_disabled_text');
    },

    myParticipant() {
      if (!this.isGuest) {
        return this.myParticipantContactInfo
          ? { ...this.myParticipantContactInfo, type: 'Application' }
          : { displayName: this.userDisplayName, type: 'Application' };
      } else {
        return { type: 'Guest' };
      }
    },

    didMicAndCameraSettingsChanged() {
      return (
        this.cameraEnabled !== this.$store.state.settings.cameraEnabled ||
        this.microphoneEnabled !== this.$store.state.settings.microphoneEnabled
      );
    },

    canUseCamera() {
      return (
        this.hasCameraAccess &&
        this.hasOperatingSystemPermissions &&
        this.selectedCameraId !== ''
      );
    },

    canUseMicrophone() {
      return (
        this.hasMicrophoneAccess &&
        this.hasOperatingSystemPermissions &&
        this.selectedMicrophoneId !== ''
      );
    },

    showVideo() {
      return this.canUseCamera && this.cameraEnabled;
    },

    isMicDisabled() {
      return (
        !this.canUseMicrophone || this.isInitializingPublisher || this.isJoining
      );
    },

    isCameraDisabled() {
      return (
        !this.canUseCamera || this.isInitializingPublisher || this.isJoining
      );
    },

    cameraMenu() {
      const lists = [];

      if (!this.canUseCamera) {
        return lists;
      }

      const cameraDevicesItems = getAvailableDevices(
        this.cameraDevices,
        this.selectedCameraId
      );
      lists.push({
        label: this.$t('meeting_actions.camera_devices_title'),
        items: cameraDevicesItems,
        defaultAction: 'cameraDeviceClicked'
      });

      if (this.isVirtualBackgroundSupported) {
        const items = [];
        items.push({
          id: 'blur',
          action: 'blur',
          label: this.$t('pre_join.blur_menu_item_label'),
          iconName: 'blur-line',
          type: 'interactive',
          active: this.isBlurBackgroundEnabled,
          subtitle: !this.cameraEnabled
            ? this.$t('pre_join.camera_menu_item_disabled')
            : null,
          disabled:
            this.isInitializingPublisher ||
            !this.cameraEnabled ||
            this.isInitializingVirtualBackground ||
            !this.isSidebarCollapsed ||
            this.initialJoinConfig.backgroundDisabled
        });

        items.push({
          id: 'virtualBackground',
          action: 'virtualBackground',
          label: this.$t('pre_join.virtual_background_menu_item_label'),
          iconName: 'texture-line',
          subtitle: !this.cameraEnabled
            ? this.$t('pre_join.camera_menu_item_disabled')
            : null,
          disabled:
            this.isInitializingPublisher ||
            !this.cameraEnabled ||
            this.initialJoinConfig.backgroundDisabled
        });
        lists.push({ items });
      }
      return lists;
    },

    micMenu() {
      const lists = [];

      if (!this.canUseMicrophone) {
        return lists;
      }

      const speakerDevicesItems = getAvailableDevices(
        this.speakerDevices,
        this.selectedSpeakerId
      );
      lists.push({
        label: this.$t('meeting_actions.speaker_devices_title'),
        items: speakerDevicesItems,
        defaultAction: 'speakerDeviceClicked'
      });

      const micDevicesItems = getAvailableDevices(
        this.microphoneDevices,
        this.selectedMicrophoneId
      );
      lists.push({
        label: this.$t('meeting_actions.mic_devices_title'),
        items: micDevicesItems,
        defaultAction: 'micDeviceClicked'
      });

      lists.push({
        items: [
          {
            id: 'noAudioMode',
            action: 'noAudioMode',
            label: this.$t('pre_join.computer_audio_label'),
            iconName: 'headset-line',
            active: this.joinWithComputerAudio
          }
        ]
      });

      return lists;
    },

    joinWithComputerAudio: {
      get() {
        return !this.isNoAudioMode;
      },
      set(newValue) {
        this.$store.commit('SET_NO_AUDIO_MODE', !newValue);
      }
    }
  },

  watch: {
    selectedCameraId: function () {
      if (this.canUseCamera && publisher) {
        publisher.setVideoSource(this.selectedCameraId);
      }
    }
  },

  async beforeMount() {
    if (this.isVirtualBackgroundSupported) {
      if (this.initialJoinConfig.blurOn) {
        await this.saveSettings({
          initialSettings: {
            virtualBackground: {
              type: VIRTUAL_BACKGROUND_TYPE.BLUR,
              background: null
            }
          }
        });
      }
      this.setVirtualBackground(this.initialSettings.virtualBackground);
    }
  },

  async mounted() {
    let firefoxTimer = 0;
    if (!window.isFirefox) {
      if (navigator.permissions && navigator.permissions.query) {
        const micPermissionState = await navigator.permissions.query({
          name: 'microphone'
        });
        if (micPermissionState?.state === 'prompt') {
          this.showPermissionModal = true;
        }
        // navigator.permissions.query is not supported for safari with version less than 16
        // we use navigator.mediaDevices.getUserMedia instead
      } else {
        try {
          mediaStream = await navigator.mediaDevices.getUserMedia({
            audio: true,
            video: true
          });
        } catch (err) {
          // if we reach this catch means that either the browser does not support webrtc or that the user didn't grant permissions
          this.showPermissionModal = true;
        }
      }
    } else {
      firefoxTimer = setTimeout(() => (this.showPermissionModal = true), 500);
    }

    await this.askForMicAndCameraPermissions();

    if (
      this.roomDetails.joinApprovalLevel ===
        JOIN_APPROVAL_LEVEL.EXPLICIT_APPROVAL &&
      this.roomDetails.roomOwner === this.myParticipantId
    ) {
      this.askForNotificationPermissions();
    }

    this.showPermissionModal = false;
    if (this.canUseMicrophone) {
      clearTimeout(firefoxTimer);
    } else {
      this.showNoPermissionInfoDialog();
    }
  },

  beforeDestroy() {
    this.destroyPublisher();
    // Stop the entire media stream - this fixes the camera indication bug in "Thank You" screen we had in some safari versions
    mediaStream?.getTracks().forEach((track) => {
      track.stop();
    });
  },

  methods: {
    ...mapActions([
      'joinMeeting',
      'setSelectedDevices',
      'showMissingDeviceAccess',
      'updateDevicesPermissions',
      'setInfoDialog',
      'createPublisher',
      'selectVirtualBackground',
      'saveVirtualBackground',
      'setVirtualBackground',
      'setIsSettingsModalVisible',
      'selectSpeakerDevice',
      'selectMicrophoneDevice',
      'selectCameraDevice',
      'setActionInProgress'
    ]),
    ...mapActions('layout', { _toggleSidebar: 'toggleSidebar' }),
    ...mapActions('settings', ['saveSettings', 'sendSettingsAnalytics']),
    ...mapActions('waitingRoom', {
      sendCancelWaitingRoomParticipationRequest:
        'cancelWaitingRoomParticipation',
      sendJoinerFinishAnalytic: 'sendJoinerFinishAnalytic',
      setHasFinishedArtificialTransitionToWaitingRoom:
        'setHasFinishedArtificialTransitionToWaitingRoom'
    }),

    toggleJoinWithComputerAudio() {
      this.joinWithComputerAudio = !this.joinWithComputerAudio;
    },

    async askForMicAndCameraPermissions() {
      try {
        publisher = await this.createPublisher({
          element: this.$refs.video,
          options: {
            insertMode: 'append',
            fitMode: 'contain',
            width: '100%',
            height: '100%',
            publishVideo: this.canUseCamera && this.cameraEnabled,
            style: {
              buttonDisplayMode: 'off',
              backgroundImageURI: ''
            }
          }
        });

        // If the component has been destroyed before the publisher was created, we want to destroy the publisher
        if (this._isDestroyed) {
          this.destroyPublisher();
          return;
        }

        if (publisher) {
          publisher.setStyle('backgroundImageURI', null);
        }
        this.isInitializingPublisher = false;
      } catch (error) {
        logger.error(
          'PreJoin Screen - failed to access camera or microphone',
          LOG_CATEGORIES.DEVICES,
          error
        );
        if (error.name === OT_ERROR.USER_MEDIA_ACCESS_DENIED) {
          this.hasOperatingSystemPermissions = false;
        }
      } finally {
        await this.updateDevicesPermissions();
        // we initialize the selected devices after we receive permissions from the user (createPublisher asks the user
        // for permissions) - to make sure we get full devices information
        await this.setSelectedDevices();
      }
    },

    askForNotificationPermissions() {
      if (window.Notification && Notification.permission === 'default') {
        Notification.requestPermission();
      }
    },

    toggleCamera() {
      if (this.cameraEnabled && !this.isSidebarCollapsed) {
        this._toggleSidebar();
      }
      this.cameraEnabled = this.canUseCamera && !this.cameraEnabled;
      publisher.publishVideo(this.cameraEnabled);

      analytics.trackEvent(ANALYTICS.CAMERA_SETTINGS_UPDATED, {
        Source: 'PreJoin screen',
        State: !this.cameraEnabled ? 'On' : 'Off'
      });

      this.updateMicAndCameraSettings();
    },

    toggleMicrophone() {
      this.microphoneEnabled = !this.microphoneEnabled;

      analytics.trackEvent(ANALYTICS.MICROPHONE_SETTINGS_UPDATED, {
        Source: 'PreJoin screen',
        State: this.microphoneEnabled ? 'On' : 'Off'
      });

      this.updateMicAndCameraSettings();
    },

    cameraDeviceClicked(cameraId) {
      if (this.selectedCameraId !== cameraId) {
        this.selectCameraDevice(cameraId);
        this.sendSettingsAnalytics({
          setting: SETTING_ANALYTIC_TYPE.CAMERA,
          source: ANALYTICS_SOURCE.BOTTOM_BAR
        });
      }
    },

    speakerDeviceClicked(speakerId) {
      if (this.selectedSpeakerId !== speakerId) {
        this.selectSpeakerDevice(speakerId);
        this.sendSettingsAnalytics({
          setting: SETTING_ANALYTIC_TYPE.SPEAKER,
          source: ANALYTICS_SOURCE.BOTTOM_BAR
        });
      }
    },

    micDeviceClicked(micId) {
      if (this.selectedMicrophoneId !== micId) {
        this.selectMicrophoneDevice(micId);
        this.sendSettingsAnalytics({
          setting: SETTING_ANALYTIC_TYPE.MICROPHONE,
          source: ANALYTICS_SOURCE.BOTTOM_BAR
        });
      }
    },

    async enterMeeting() {
      if (this.isJoining || !this.isInInitialPrejoinState) {
        return;
      }

      await this.updateMicAndCameraSettings();

      analytics.trackEvent(ANALYTICS.PREJOIN, {
        Audio: this.microphoneEnabled,
        Video: this.cameraEnabled,
        'No Audio': this.isNoAudioMode,
        ...((!this.canUseCamera || !this.canUseMicrophone) && {
          'No Permissions': true
        })
      });

      this.isJoining = true;
      try {
        await this.joinMeeting();
      } finally {
        this.isJoining = false;
        setTimeout(
          () =>
            this.setHasFinishedArtificialTransitionToWaitingRoom({
              hasFinishedArtificalTransitionToWaitingRoom: true
            }),
          WAITING_ROOM_ARTIFICIAL_TRANSITION_TIME_IN_MILLISECONDS
        );
      }
    },

    openSettings() {
      this.setIsSettingsModalVisible(true);
    },

    toggleVirtualBackground() {
      this._toggleSidebar();
    },

    async blurBackgroundClicked() {
      const virtualBackground = {
        type: this.isBlurBackgroundEnabled
          ? VIRTUAL_BACKGROUND_TYPE.NONE
          : VIRTUAL_BACKGROUND_TYPE.BLUR,
        background: null
      };
      await this.selectBackground(virtualBackground);
    },

    async selectBackground(selectedBackground) {
      try {
        await this.selectVirtualBackground({
          publisher: publisher,
          currentVirtualBackground: this.virtualBackground,
          newVirtualBackground: selectedBackground
        });
        this.saveVirtualBackground({
          source: ANALYTICS_SOURCE.PRE_JOIN,
          virtualBackground: selectedBackground
        });
      } catch (error) {
        logger.error(
          'PreJoin Screen - failed to select virtual background',
          LOG_CATEGORIES.TOKBOX,
          error
        );
      }
    },

    destroyPublisher() {
      if (publisher) {
        if (this.isInitializingVirtualBackground) {
          this.setActionInProgress({
            name: 'virtualBackground',
            inProgress: false
          });
        }
        publisher.destroy();
        publisher = null;
      }
    },

    async updateMicAndCameraSettings() {
      if (this.didMicAndCameraSettingsChanged) {
        try {
          await this.saveSettings({
            initialSettings: {
              cameraEnabled: this.cameraEnabled,
              microphoneEnabled: this.microphoneEnabled
            }
          });
        } catch (error) {
          logger.error(
            'PreJoin Screen - failed to save new settings to customization service',
            LOG_CATEGORIES.DEVICES,
            error
          );
        }
      }
    },

    showNoPermissionInfoDialog() {
      return this.setInfoDialog({
        title: this.$t('pre_join.no_permission_info_dialog_title'),
        text: this.$t('pre_join.no_permission_info_dialog_text')
      });
    },

    async cancelWaitingRoomParticipation() {
      this.isCancellingWaitingRoomParticipation = true;
      try {
        await this.sendCancelWaitingRoomParticipationRequest();
        this.sendJoinerFinishAnalytic({ status: 'Canceled request' });
      } catch (error) {
        logger.error(
          'cancel-waiting-room-participation',
          LOG_CATEGORIES.WAITING_ROOM,
          {
            message:
              'Unexpected error when trying to cancel waiting room participation. User will see normal cancellation flow anyway',
            error
          }
        );
      } finally {
        this.isCancellingWaitingRoomParticipation = false;
      }
    }
  }
};
</script>

<style scoped>
.title {
  margin-top: 10px;
  text-align: center;
}

.controls-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.video-mirror {
  flex: 1;
  position: relative;
  min-width: 300px;
  min-height: 225px;
}

.video-mirror >>> video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  border-radius: 6px;
  object-fit: cover;
  height: 100%;
  transform: scale(-1, 1);
  transform-origin: 50% 50%;
}

.video-container,
.video {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.video-container >>> * {
  height: 100%;
  width: 100%;
  border-radius: 8px;
}

.toggle-device {
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  --mdc-button-horizontal-padding: 0;
}

.toggle-device > * {
  width: 100%;
}

.mic-button,
camera-button {
  margin-right: 24px;
}

.camera-button >>> vwc-button {
  min-width: 109px;
}

.camera-button >>> vwc-list-item {
  min-width: 291px;
}

.mic-button:not(.no-audio) >>> vwc-button {
  min-width: 92px;
}

.toggle-device.disabled p,
.toggle-device.disabled >>> svg {
  color: #9b9da3 !important;
  fill: #9b9da3 !important;
}

.enter-button {
  width: 100%;
}

.sidebar-container {
  height: 100%;
}

/* Make the sidebar float on small/medium screens */
@media only screen and (max-width: 900px) {
  .sidebar-container {
    position: relative;
  }

  .sidebar-wrapper {
    position: absolute;
    z-index: 100;
    left: -360px;
  }
}

.sidebar-wrapper {
  transition: transform 0.5s, opacity 0.5s, width 0.5s;
  user-select: none;
}

.sidebar-wrapper >>> .sidebar {
  z-index: inherit;
}

.virtual-background-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  margin-top: 3px;
  overflow-y: auto;
  overflow-x: hidden;
}

.infoModal >>> .Vlt-modal__panel {
  width: 465px;
  padding-top: 20px;
  padding-bottom: 30px;
}

.infoModal >>> .pre-formatted {
  white-space: pre;
  margin-top: -12px;
}

.infoModal >>> .Vlt-modal__content {
  padding-bottom: 12px;
}

.infoModal >>> .title {
  margin-left: -5px;
  letter-spacing: normal;
  margin-bottom: -2px;
}

.join-meeting-loading-spinner {
  margin-left: 8px;
}

.controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-height: 68px;
  margin-top: 16px;
}

.device-options-container {
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 660px) {
  .camera-button >>> vwc-list-item,
  .mic-button >>> vwc-list-item {
    min-width: 250px;
    max-width: 250px;
  }
}
</style>
