/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-bin-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 0c.9 0 1.5.6 1.5 1.5v3h6v3H0v-3h6v-3C6 .6 6.6 0 7.5 0h9zM9 4.5h6V3H9v1.5zM3 21V9h18v12c0 1.65-1.35 3-3 3H6c-1.65 0-3-1.35-3-3z"/>'
  }
})
