/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-infinity-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.75 18c-2.71 0-4.86-1.573-6.75-3.232C10.11 16.427 7.96 18 5.25 18A5.256 5.256 0 010 12.75 5.256 5.256 0 015.25 7.5c2.71 0 4.86 1.573 6.75 3.232C13.89 9.073 16.04 7.5 18.75 7.5A5.256 5.256 0 0124 12.75 5.256 5.256 0 0118.75 18zm-4.498-5.25C15.71 14.007 17.154 15 18.75 15c1.24 0 2.25-1.01 2.25-2.25s-1.01-2.25-2.25-2.25c-1.595 0-3.039.993-4.498 2.25zM5.25 10.5C4.01 10.5 3 11.51 3 12.75S4.01 15 5.25 15c1.595 0 3.039-.993 4.498-2.25C8.29 11.493 6.845 10.5 5.25 10.5z"/>'
  }
})
