/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'conference-icon-full': {
    width: 47,
    height: 47,
    viewBox: '0 0 47 47',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 3.615A3.614 3.614 0 013.615 0h39.77A3.614 3.614 0 0147 3.615v28.923a3.614 3.614 0 01-3.615 3.616H3.615A3.614 3.614 0 010 32.538V3.616zm38.188 40.447c0-1.762-1.175-2.937-2.938-2.937h-23.5c-1.762 0-2.938 1.175-2.938 2.938C8.813 45.825 9.989 47 11.75 47h23.5c1.763 0 2.938-1.175 2.938-2.938zM18.494 10.88a5.005 5.005 0 1110.01 0 5.005 5.005 0 01-10.01 0zm-2.439 11.268a8.758 8.758 0 016.193-2.565h2.502a8.758 8.758 0 018.759 8.758c0 .691-.56 1.252-1.252 1.252H14.742a1.25 1.25 0 01-1.251-1.252c0-2.322.923-4.55 2.565-6.193z" _fill="#131415"/>'
  }
})
