/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-user-admin-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M10.5 13.5c-3.3 0-6-2.7-6-6V6c0-3.3 2.7-6 6-6s6 2.7 6 6v1.5c0 3.3-2.7 6-6 6zm-3 3C3.3 16.5 0 19.8 0 24h13.5v-7.5h-6zm14.458.27l1.966 3.214c.101.165.101.367 0 .532l-1.966 3.214a.581.581 0 01-.497.27h-3.922a.581.581 0 01-.497-.27l-1.966-3.214a.504.504 0 010-.532l1.966-3.214a.581.581 0 01.497-.27h3.922a.58.58 0 01.497.27zm-4.178 3.48c0 .888.77 1.607 1.72 1.607s1.72-.72 1.72-1.607c0-.888-.77-1.607-1.72-1.607s-1.72.72-1.72 1.607z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
