var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.l2.esc",
          value: _vm.onClose,
          expression: "onClose",
          modifiers: { prevent: true, l2: true, esc: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.l2.enter",
          value: _vm.onConfirm,
          expression: "onConfirm",
          modifiers: { l2: true, enter: true },
        },
      ],
      staticClass: "Vlt-modal Vlt-modal_visible Vlt-black vvd-scheme-alternate",
      class: {
        "Vlt-modal--large": _vm.largeModal,
      },
      attrs: { "data-cy": "v-modal" },
      on: {
        click: function ($event) {
          _vm.dismissClickOutside && _vm.$emit("close", false)
        },
      },
    },
    [
      _c(
        "div",
        _vm._g(
          { staticClass: "Vlt-modal__panel" },
          { click: _vm.stopPropagation }
        ),
        [
          _vm.title
            ? _c(
                "div",
                { staticClass: "Vlt-modal__header unselectable" },
                [
                  _vm.titleIcon
                    ? _c("v-icon", {
                        staticClass: "title-icon",
                        attrs: {
                          iconName: _vm.titleIcon,
                          "data-cy": "modal-title-icon",
                        },
                      })
                    : _vm._e(),
                  _c(
                    "h2",
                    {
                      staticClass: "title",
                      attrs: { "data-cy": "modal-title" },
                    },
                    [_vm._v(" " + _vm._s(_vm.title) + " ")]
                  ),
                  _vm.dismiss
                    ? _c("div", [
                        _c("div", {
                          staticClass: "Vlt-modal__dismiss",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close", false)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "Vlt-modal__content",
              attrs: { "data-cy": "modal-content" },
            },
            [_vm._t("content")],
            2
          ),
          _vm.$slots.footerButtons
            ? _c(
                "div",
                { staticClass: "Vlt-modal__footer" },
                [_vm._t("footerButtons")],
                2
              )
            : !_vm.noFooter &&
              !_vm.footerButtons &&
              (_vm.ok || _vm.cancel || _vm.$slots.extraButtons)
            ? _c(
                "div",
                { staticClass: "Vlt-modal__footer" },
                [
                  _vm.cancel
                    ? _c(
                        "v-button",
                        {
                          staticClass: "Vlt-modal__cancel modal-button",
                          attrs: {
                            app: "",
                            sm: "",
                            secondary: "",
                            outline: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close", false)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.cancel))]
                      )
                    : _vm._e(),
                  _vm.ok
                    ? _c(
                        "v-button",
                        {
                          staticClass:
                            "Vlt-modal__confirm modal-button ok-button",
                          attrs: {
                            disabled: _vm.disableOk,
                            app: "",
                            sm: "",
                            secondary: "",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close", true)
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.ok))]
                      )
                    : _vm._e(),
                  _vm._t("extraButtons"),
                ],
                2
              )
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }