/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-gear': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.5 0H.5C.2 0 0 .2 0 .5v15c0 .3.2.5.5.5h6c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H1V1h12v5.5c0 .3.2.5.5.5s.5-.2.5-.5v-6c0-.3-.2-.5-.5-.5z"/><path pid="1" d="M10.5 3h-7c-.3 0-.5.2-.5.5s.2.5.5.5h7c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM7.5 6h-4c-.3 0-.5.2-.5.5s.2.5.5.5h4c.3 0 .5-.2.5-.5S7.8 6 7.5 6zM5.5 9h-2c-.3 0-.5.2-.5.5s.2.5.5.5h2c.3 0 .5-.2.5-.5S5.8 9 5.5 9zM5.5 12h-2c-.3 0-.5.2-.5.5s.2.5.5.5h2c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM15.9 12.7v-1.3h-1.4c-.1-.2-.2-.5-.3-.7l1-1-.9-.9-1 1c-.2-.1-.4-.2-.7-.3V8h-1.3v1.4c-.2.1-.5.2-.7.3l-1-1-1 1 1 1c-.1.2-.2.4-.3.7H7.9v1.3h1.4c.1.2.2.5.3.7l-1 1 .9.9 1-1c.2.1.4.2.7.3V16h1.3v-1.4c.2-.1.5-.2.7-.3l1 1 .9-.9-1-1c.1-.2.2-.4.3-.7h1.5zm-3 .3c-.2.2-.6.4-.9.4-.4 0-.7-.1-.9-.4-.2-.2-.4-.6-.4-.9 0-.4.1-.7.4-.9.2-.2.6-.4.9-.4.4 0 .7.1.9.4.2.2.4.6.4.9 0 .3-.2.6-.4.9z"/>'
  }
})
