/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-filter-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 0h-21C.6 0 0 .6 0 1.5v3c0 .45.15.9.6 1.2L9 12.75v9.75c0 .6.3 1.2.9 1.35.15.15.45.15.6.15.45 0 .75-.15 1.05-.45l3-3c.3-.3.45-.6.45-1.05v-6.75l8.4-7.05c.45-.3.6-.75.6-1.2v-3c0-.9-.6-1.5-1.5-1.5z"/>'
  }
})
