/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-6-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.296 15.324l-3.835-.958a.75.75 0 01-.554-.582l-.287-1.445a4.5 4.5 0 002.63-4.089V6.189A4.626 4.626 0 0015.888 1.5a4.5 4.5 0 00-3.933 2.09A6.31 6.31 0 0113.5 7.691a6.358 6.358 0 00.876 3.685 2.25 2.25 0 01-.801 3.15l.206.06a3.765 3.765 0 012.719 3.61v3.553c-.003.256-.05.51-.138.75h6.888a.75.75 0 00.75-.75v-4.244a2.25 2.25 0 00-1.704-2.182z"/><path pid="1" d="M13.368 16.033l-3.236-.924a.75.75 0 01-.506-.487l-.255-.783a7.423 7.423 0 003.45-.6.748.748 0 00.287-1.068A7.849 7.849 0 0112 7.696 4.64 4.64 0 007.678 3 4.5 4.5 0 003 7.5a8.06 8.06 0 01-1.104 4.672.75.75 0 00.29 1.067 7.423 7.423 0 003.45.6l-.256.783a.75.75 0 01-.506.487l-3.236.924A2.25 2.25 0 000 18.196v3.554a.75.75 0 00.75.75h13.5a.75.75 0 00.75-.75v-3.554a2.25 2.25 0 00-1.632-2.163z"/>'
  }
})
