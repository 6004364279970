/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-shield': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0l.45.45c2.85 2.7 6.6 4.5 10.5 4.95l.6.15v.6h-.75.75v.6c0 .3 0 .9-.15 1.5-.15 1.35-.6 3-1.2 4.95-1.35 3.75-4.35 8.25-10.05 10.5l-.15.15-.3-.15C6 21.45 3.15 16.95 1.65 13.2.9 11.25.6 9.6.45 8.25.3 7.65.3 7.05.3 6.75v-.6h.75H.3V5.4l.6-.15C4.8 4.8 8.55 3 11.4.3L12 0zM1.8 6.75c0 .3 0 .75.15 1.2.15 1.2.45 2.85 1.2 4.65 1.35 3.45 3.9 7.35 8.85 9.6 5.1-2.1 7.65-6 8.85-9.6.6-1.8 1.05-3.45 1.2-4.65 0-.45.15-.9.15-1.2-3.75-.6-7.35-2.25-10.2-4.8-2.85 2.55-6.45 4.2-10.2 4.8z"/>'
  }
})
