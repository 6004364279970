/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cross-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.486.514c-.686-.685-1.715-.685-2.4 0L12 9.6 2.914.514C2.23-.17 1.2-.17.514.514c-.685.686-.685 1.715 0 2.4L9.6 12 .514 21.086c-.685.685-.685 1.714 0 2.4.343.343.686.514 1.2.514.515 0 .857-.171 1.2-.514L12 14.4l9.086 9.086c.343.343.857.514 1.2.514.343 0 .857-.171 1.2-.514.685-.686.685-1.715 0-2.4L14.4 12l9.086-9.086c.685-.685.685-1.714 0-2.4z"/>'
  }
})
