var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "switch", on: { click: _vm.onClick } },
    [
      _vm._t("default"),
      _c(
        "div",
        {
          staticClass: "Vlt-switch",
          class: {
            "Vlt-switch--unchecked-red": _vm.uncheckedRed,
            "Vlt-switch--red": _vm.red,
            "Vlt-switch--green": _vm.green,
            "purple-switch": _vm.purple,
            "Vlt-switch--small": _vm.sm,
          },
        },
        [
          _c("label", [
            _c("input", {
              attrs: { type: "checkbox", disabled: _vm.disabled },
              domProps: { checked: _vm.checked },
            }),
            _c("span", {
              staticClass: "Vlt-switch__slider",
              class: { disabled: _vm.disabled },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                },
              },
            }),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }