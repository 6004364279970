/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-separator-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 1.5V6A1.5 1.5 0 003 7.5h18A1.5 1.5 0 0022.5 6V1.5A1.5 1.5 0 0021 0H3a1.5 1.5 0 00-1.5 1.5zm0 16.5v4.5A1.5 1.5 0 003 24h18a1.5 1.5 0 001.5-1.5V18a1.5 1.5 0 00-1.5-1.5H3A1.5 1.5 0 001.5 18zm0-4.5h21a1.5 1.5 0 000-3h-21a1.5 1.5 0 000 3z"/>'
  }
})
