/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-move-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M.5 0a.5.5 0 00-.5.5v2a.5.5 0 001 0V1h1.5a.5.5 0 000-1h-2zm12 0a.5.5 0 01.5.5v13.793l2.146-2.147a.5.5 0 01.708.708l-3 3a.5.5 0 01-.708 0l-3-3a.5.5 0 01.708-.708L12 14.293V.5a.5.5 0 01.5-.5zM.5 4a.5.5 0 01.5.5V6h1.5a.5.5 0 010 1h-2a.5.5 0 01-.5-.5v-2A.5.5 0 01.5 4zm6.5.5a.5.5 0 00-1 0V6H4.5a.5.5 0 000 1h2a.5.5 0 00.5-.5v-2zm-3-4a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 01-1 0V1H4.5A.5.5 0 014 .5zM.5 9a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-6zm.5 6v-5h5v5H1z"/>'
  }
})
