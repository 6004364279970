/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-devices-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 22.5h6v-6H3V3h13.5v4.5h3V3a3 3 0 00-3-3H3a3 3 0 00-3 3v16.5a3 3 0 003 3z"/><path pid="1" d="M13.5 10.5h9A1.5 1.5 0 0124 12v10.5a1.5 1.5 0 01-1.5 1.5h-9a1.5 1.5 0 01-1.5-1.5V12a1.5 1.5 0 011.5-1.5zM15 21h6v-7.5h-6V21z"/>'
  }
})
