/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-gradle-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M22.699 4.301a3.777 3.777 0 00-5.301-.102c-.097.102-.097.199-.097.3 0 .103 0 .2.097.302l.5.5c.102.097.3.097.5 0 .403-.3.801-.403 1.302-.403 1.199 0 2.198 1 2.198 2.204 0 .597-.198 1.098-.596 1.5-3 3-7.102-5.403-16.2-1.101-.602.3-.903 1-.602 1.602v.096L6 11.9c.3.602 1.102.8 1.699.5L8.398 12c.8-.5 1.5-1 2.204-1.602.097-.097.398-.097.5 0 .199.102.199.3.097.5l-.097.102c-.704.602-1.5 1.2-2.3 1.699l-.7.3c-.3.2-.602.301-1 .301-.704 0-1.403-.403-1.704-1L3.899 9.7c-2.796 2-4.596 5.903-3.597 10.8 0 .2.2.301.399.301h1.698c.204 0 .301-.102.403-.3C3 19.101 4.2 18.198 5.602 18.397c1.098.102 2 1 2.097 2.102 0 .2.2.301.403.301H9.7c.199 0 .3-.102.403-.3.198-1.399 1.398-2.302 2.796-2.103 1.101.102 2 1 2.102 2.102 0 .2.199.301.398.301H17c.199 0 .398-.199.398-.403 0-2.295.705-4.898 2.404-6.198 5.898-4.597 4.3-8.5 2.898-9.898h-.001zM16.5 11.2l-1.2-.597c0-.403.301-.704.7-.704.398 0 .699.3.699.704.102.296 0 .5-.199.597z" _fill="#02303A"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
