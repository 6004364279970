var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vwc-icon-button", {
        directives: [
          {
            name: "tooltip",
            rawName: "v-tooltip.bottom-end",
            value: _vm.tooltip,
            expression: "tooltip",
            modifiers: { "bottom-end": true },
          },
        ],
        class: _vm.showThankYouScreen ? "vvd-scheme-alternate" : "",
        attrs: {
          "data-cy": "locale-switcher-button",
          icon: "globe-solid",
          dense: "",
        },
        on: { click: _vm.openSelectLanguageModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }