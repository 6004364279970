import OT from '@opentok/client';

function tokbox() {
  if (window.Cypress && window.OTMock) {
    return window.OTMock;
  }
  return OT;
}

export function initSession(apiKey, sessionId) {
  return tokbox().initSession(apiKey, sessionId);
}

export function initPublisher(element, options) {
  return new Promise((resolve, reject) => {
    const publisher = tokbox().initPublisher(element, options, (error) => {
      if (error) {
        return reject(error);
      } else {
        return resolve(publisher);
      }
    });
  });
}

export function reportIssue() {
  return new Promise((resolve, reject) => {
    tokbox().reportIssue(async (error, reportId) => {
      if (error) {
        return reject(error);
      }
      return resolve(reportId);
    });
  });
}

export function getSubscribers() {
  return tokbox().subscribers;
}

export function isScreenshareSupported() {
  return new Promise((resolve) => {
    tokbox().checkScreenSharingCapability((response) => {
      return resolve(!(response.extensionRegistered === false));
    });
  });
}

export function setAudioOutputDevice(deviceId) {
  return tokbox().setAudioOutputDevice(deviceId);
}

export function hasVirtualBackgroundSupport() {
  return tokbox().hasMediaProcessorSupport();
}
