<template>
  <div class="Vlt-form__element">
    <div class="Vlt-composite">
      <div class="Vlt-composite__prepend Vlt-composite__prepend--icon">
        <v-icon class="input-icon" iconName="Vlt-icon-search" />
      </div>

      <div class="Vlt-input">
        <input
          v-model="filterText"
          type="text"
          :placeholder="searchPlaceHolder"
          @focus="focusUpdate"
          @focusout="focusoutUpdate"
        />
      </div>

      <div
        v-if="filterText !== ''"
        class="Vlt-composite__append Vlt-composite__append--icon"
      >
        <v-icon
          class="input-icon clear-icon"
          iconName="Vlt-icon-cross-circle"
          @click="filterText = ''"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSearch',

  props: {
    filter: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default() {
        return this.$t('v_search.placeholder');
      }
    },
    focusPlaceHolder: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      searchPlaceHolder: this.$t('v_search.placeholder'),
      filterText: ''
    };
  },

  watch: {
    filterText() {
      this.$emit('update:filter', this.filterText);
    }
  },

  mounted() {
    this.filterText = this.filter;
  },

  methods: {
    focusUpdate() {
      if (this.focusPlaceHolder) {
        this.searchPlaceHolder = this.focusPlaceHolder;
      }
    },

    focusoutUpdate() {
      this.searchPlaceHolder = this.placeholder;
    }
  }
};
</script>

<style scoped>
.v-search {
  position: relative;
}

.input-icon {
  position: absolute;
  top: 8px;
  left: 10px;
}

.clear-icon {
  left: auto;
  right: 10px;
}

.input-icon >>> .svg-icon {
  width: 20px;
  height: 20px;
  fill: #9b9b9b;
}

input {
  padding-left: 40px;
  padding-right: 40px;
}

.Vlt-form__element * {
  font-size: 15px;
}
</style>
