<template>
  <div class="container">
    <div class="illustration-container" :class="{ branded: isBranded }">
      <vwc-icon
        v-if="isBranded"
        size="large"
        class="branded-illustration-icon"
        :type="brandedIconType"
      />
      <img v-else :src="illustrationPath" />
    </div>
    <vwc-text tight font-face="subtitle-2" class="title" v-html="title" />
    <vwc-text tight font-face="body-1" class="message" v-html="message" />

    <vwc-button
      v-if="isInSessionExpiredState"
      data-cy="rejoin-waiting-room-button"
      class="rejoin-button"
      :label="$t('mobile_waiting_room_joiner_screen.rejoin_button')"
      connotation="primary"
      layout="filled"
      @click="rejoinWaitingRoom"
    />
    <vwc-button
      v-else-if="canCancel"
      class="cancel-button"
      :label="$t('mobile_waiting_room_joiner_screen.cancel_button')"
      data-cy="cancel-waiting-room-participation-button"
      layout="filled"
      connotation="primary"
      :disabled="isCancellingWaitingRoomParticipation"
      @click="cancelWaitingRoomParticipation"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  WAITING_ROOM_ILLUSTRATION_PATHS,
  WAITING_ROOM_MESSAGE_ENUM
} from '@/store/waitingRoom/consts';

export default {
  data() {
    return { isCancellingWaitingRoomParticipation: false };
  },

  computed: {
    ...mapState(['isBranded', 'globalMessage']),
    ...mapState('waitingRoom', ['isInWaitingRoom', 'isOwnerInMeeting']),
    ...mapGetters('waitingRoom', ['mobileMessages']),

    isInSessionExpiredState() {
      return (
        this.globalMessage?.reason ===
        WAITING_ROOM_MESSAGE_ENUM.KEEPALIVE_EXPIRED
      );
    },

    isWaitingRoomGlobalMessage() {
      const waitingRoomEnumValues = Object.values(WAITING_ROOM_MESSAGE_ENUM);
      const globalMessageType = this.globalMessage?.reason;
      return waitingRoomEnumValues.includes(globalMessageType);
    },

    canCancel() {
      return !this.isWaitingRoomGlobalMessage;
    },

    illustrationPath() {
      if (this.isOwnerInMeeting) {
        return WAITING_ROOM_ILLUSTRATION_PATHS.PLEASE_WAIT;
      } else {
        return WAITING_ROOM_ILLUSTRATION_PATHS.WAIT_FOR_OWNER;
      }
    },

    title() {
      if (this.isOwnerInMeeting) {
        return this.$t(
          'mobile_waiting_room_joiner_screen.owner_in_meeting_title'
        );
      } else {
        return this.$t(
          'mobile_waiting_room_joiner_screen.owner_not_in_meeting_title'
        );
      }
    },

    message() {
      if (this.isInWaitingRoom) {
        return this.$t(
          'mobile_waiting_room_joiner_screen.in_waiting_room_message'
        );
      }

      return '';
    },

    brandedIconType() {
      return 'profile-line';
    }
  },

  methods: {
    ...mapActions('waitingRoom', {
      sendCancelWaitingRoomParticipationRequest:
        'cancelWaitingRoomParticipation',
      sendJoinerFinishAnalytic: 'sendJoinerFinishAnalytic',
      rejoinWaitingRoom: 'joinWaitingRoom'
    }),

    async cancelWaitingRoomParticipation() {
      this.isCancellingWaitingRoomParticipation = true;
      try {
        await this.sendCancelWaitingRoomParticipationRequest();
        this.sendJoinerFinishAnalytic({ status: 'Canceled request' });
      } finally {
        this.isCancellingWaitingRoomParticipation = false;
      }
    }
  }
};
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  height: 100%;
  background: #0d0d0d;
}

.illustration-container {
  width: 120px;
  max-height: 120px;
}

.illustration-container.branded {
  display: flex;
  place-items: center;
  place-content: center;
  background-color: #333333;
  color: white;
  border-radius: 50%;
  height: 120px;
}

vwc-icon.branded-illustration-icon {
  width: 64px;
  height: 64px;
}

.title,
.message {
  text-align: center;
  max-width: 95vw;
}

.title {
  margin-top: 32px;
  line-height: 28px;
}

.message {
  max-width: 300px;
  margin-top: 8px;
  line-height: 24px;
}

.cancel-button,
.rejoin-button {
  margin-top: 24px;
}

.rejoin-button {
  min-width: 160px;
}
</style>
