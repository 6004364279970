/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-print': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3.5 4c.3 0 .5-.2.5-.5V1h8v2.5c0 .3.2.5.5.5s.5-.2.5-.5v-3c0-.3-.2-.5-.5-.5h-9c-.3 0-.5.2-.5.5v3c0 .3.2.5.5.5zM15 6c-.7-.7-1.5-1-2.5-1h-9c-.9 0-1.8.4-2.5 1S0 7.6 0 8.5v4c0 .3.2.5.5.5H3v2.5c0 .3.2.5.5.5h9c.3 0 .5-.2.5-.5V13h2.5c.3 0 .5-.2.5-.5v-4c0-.9-.4-1.8-1-2.5zm-3 6.5V15H4v-5h8v2.5zm3-.5h-2V9.5c0-.3-.2-.5-.5-.5h-9c-.3 0-.5.2-.5.5V12H1V8.5c0-.7.3-1.3.7-1.8.5-.4 1.1-.7 1.8-.7h9c.7 0 1.3.3 1.8.7.4.5.7 1.1.7 1.8V12z"/>'
  }
})
