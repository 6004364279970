/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-check': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 16.1L3.8 11c-.4-.4-1.1-.4-1.5 0-.4.4-.4 1 0 1.4L9 19 21.7 6.7c.4-.4.4-1 0-1.4-.4-.4-1.1-.4-1.5 0L9 16.1z"/>'
  }
})
