/* eslint-disable */
require('./Vlt-icon-add-group-full')
require('./Vlt-icon-add-group')
require('./Vlt-icon-add-user-full')
require('./Vlt-icon-add-user')
require('./Vlt-icon-address-book-full')
require('./Vlt-icon-address-book')
require('./Vlt-icon-ai-2-full')
require('./Vlt-icon-ai-2')
require('./Vlt-icon-ai-3-full')
require('./Vlt-icon-ai-3')
require('./Vlt-icon-ai-4')
require('./Vlt-icon-ai-full')
require('./Vlt-icon-ai')
require('./Vlt-icon-alarm-full')
require('./Vlt-icon-alarm')
require('./Vlt-icon-alert-negative')
require('./Vlt-icon-alert')
require('./Vlt-icon-ambulance-full')
require('./Vlt-icon-ambulance')
require('./Vlt-icon-android')
require('./Vlt-icon-app-full')
require('./Vlt-icon-app-plus-full')
require('./Vlt-icon-app-plus')
require('./Vlt-icon-app')
require('./Vlt-icon-apple')
require('./Vlt-icon-arrow-inbound-full')
require('./Vlt-icon-arrow-inbound')
require('./Vlt-icon-arrow-left-full')
require('./Vlt-icon-arrow-left')
require('./Vlt-icon-arrow-outbound-full')
require('./Vlt-icon-arrow-outbound')
require('./Vlt-icon-arrow-right-full')
require('./Vlt-icon-arrow-right')
require('./Vlt-icon-arrow-thin-down-full')
require('./Vlt-icon-arrow-thin-down')
require('./Vlt-icon-arrow-thin-left-full')
require('./Vlt-icon-arrow-thin-left')
require('./Vlt-icon-arrow-thin-right-full')
require('./Vlt-icon-arrow-thin-right')
require('./Vlt-icon-arrow-thin-up-full')
require('./Vlt-icon-arrow-thin-up')
require('./Vlt-icon-asterisk')
require('./Vlt-icon-atoms-full')
require('./Vlt-icon-atoms')
require('./Vlt-icon-attachment-full')
require('./Vlt-icon-attachment')
require('./Vlt-icon-audio-max-full')
require('./Vlt-icon-audio-max')
require('./Vlt-icon-audio-mid-full')
require('./Vlt-icon-audio-mid')
require('./Vlt-icon-audio-min-full')
require('./Vlt-icon-audio-min')
require('./Vlt-icon-audio-mute-full')
require('./Vlt-icon-audio-mute')
require('./Vlt-icon-audio-off-2-full')
require('./Vlt-icon-audio-off-2')
require('./Vlt-icon-audio-off-full')
require('./Vlt-icon-audio-off')
require('./Vlt-icon-backspace-full')
require('./Vlt-icon-backspace')
require('./Vlt-icon-bar-chart-2')
require('./Vlt-icon-bar-chart-full')
require('./Vlt-icon-bar-chart')
require('./Vlt-icon-bin-full')
require('./Vlt-icon-bin')
require('./Vlt-icon-block-full')
require('./Vlt-icon-block')
require('./Vlt-icon-blocks')
require('./Vlt-icon-bookmark-full')
require('./Vlt-icon-bookmark')
require('./Vlt-icon-books-full')
require('./Vlt-icon-books')
require('./Vlt-icon-bottom-tabs-full')
require('./Vlt-icon-bottom-tabs')
require('./Vlt-icon-bounce-full')
require('./Vlt-icon-bounce')
require('./Vlt-icon-brain-full')
require('./Vlt-icon-brain')
require('./Vlt-icon-broadcast-full')
require('./Vlt-icon-broadcast')
require('./Vlt-icon-calculator-full')
require('./Vlt-icon-calculator')
require('./Vlt-icon-calendar-2-full')
require('./Vlt-icon-calendar-2')
require('./Vlt-icon-calendar-3-full')
require('./Vlt-icon-calendar-3')
require('./Vlt-icon-calendar-add-full')
require('./Vlt-icon-calendar-add')
require('./Vlt-icon-calendar-full')
require('./Vlt-icon-calendar')
require('./Vlt-icon-call-2')
require('./Vlt-icon-call-block-full')
require('./Vlt-icon-call-block')
require('./Vlt-icon-call-bounce-full')
require('./Vlt-icon-call-bounce')
require('./Vlt-icon-call-check-full')
require('./Vlt-icon-call-check')
require('./Vlt-icon-call-disabled')
require('./Vlt-icon-call-dots')
require('./Vlt-icon-call-flip-full')
require('./Vlt-icon-call-flip')
require('./Vlt-icon-call-forward-full')
require('./Vlt-icon-call-forward')
require('./Vlt-icon-call-inbound-full')
require('./Vlt-icon-call-inbound')
require('./Vlt-icon-call-outbound-full')
require('./Vlt-icon-call-outbound')
require('./Vlt-icon-call-park-full')
require('./Vlt-icon-call-park')
require('./Vlt-icon-call-pause-full')
require('./Vlt-icon-call-pause')
require('./Vlt-icon-call-pending-full')
require('./Vlt-icon-call-pending')
require('./Vlt-icon-call-transfer-full')
require('./Vlt-icon-call-transfer')
require('./Vlt-icon-call')
require('./Vlt-icon-calls-disabled-full')
require('./Vlt-icon-calls-disabled')
require('./Vlt-icon-camera-full')
require('./Vlt-icon-camera-switch-full')
require('./Vlt-icon-camera-switch')
require('./Vlt-icon-camera')
require('./Vlt-icon-car-full')
require('./Vlt-icon-car')
require('./Vlt-icon-card-full')
require('./Vlt-icon-card')
require('./Vlt-icon-cart-full-glyph')
require('./Vlt-icon-cart-full')
require('./Vlt-icon-cart-glyph')
require('./Vlt-icon-cart')
require('./Vlt-icon-chart-full')
require('./Vlt-icon-chart')
require('./Vlt-icon-chat-2-full')
require('./Vlt-icon-chat-2')
require('./Vlt-icon-chat-full')
require('./Vlt-icon-chat')
require('./Vlt-icon-check-bold')
require('./Vlt-icon-check-circle-negative')
require('./Vlt-icon-check-circle')
require('./Vlt-icon-check-double-bold')
require('./Vlt-icon-check-double')
require('./Vlt-icon-check')
require('./Vlt-icon-checkbox-checked-full')
require('./Vlt-icon-checkbox-negative')
require('./Vlt-icon-checkbox-unchecked-full')
require('./Vlt-icon-checkbox-unchecked')
require('./Vlt-icon-checkbox')
require('./Vlt-icon-chevron-down')
require('./Vlt-icon-chevron-left')
require('./Vlt-icon-chevron-right')
require('./Vlt-icon-chevron-up')
require('./Vlt-icon-circle-down-full')
require('./Vlt-icon-circle-down')
require('./Vlt-icon-circle-nested')
require('./Vlt-icon-click-to-call-full')
require('./Vlt-icon-click-to-call')
require('./Vlt-icon-clipboard-add-full')
require('./Vlt-icon-clipboard-add')
require('./Vlt-icon-clock-full')
require('./Vlt-icon-clock')
require('./Vlt-icon-cloud-full')
require('./Vlt-icon-cloud-upload-full')
require('./Vlt-icon-cloud-upload')
require('./Vlt-icon-cloud')
require('./Vlt-icon-code-full')
require('./Vlt-icon-code-snippet-full')
require('./Vlt-icon-code-snippet')
require('./Vlt-icon-code')
require('./Vlt-icon-collapse-full')
require('./Vlt-icon-collapse')
require('./Vlt-icon-compose-full')
require('./Vlt-icon-compose')
require('./Vlt-icon-copy-2-full')
require('./Vlt-icon-copy-2')
require('./Vlt-icon-copy-full')
require('./Vlt-icon-copy')
require('./Vlt-icon-credit-card-full')
require('./Vlt-icon-credit-card')
require('./Vlt-icon-crop-fill-full')
require('./Vlt-icon-crop-fill')
require('./Vlt-icon-cross-bold')
require('./Vlt-icon-cross-circle-negative')
require('./Vlt-icon-cross-circle')
require('./Vlt-icon-cross-full')
require('./Vlt-icon-cross')
require('./Vlt-icon-cursor-2')
require('./Vlt-icon-cursor-full')
require('./Vlt-icon-cursor')
require('./Vlt-icon-density-high')
require('./Vlt-icon-density-medium')
require('./Vlt-icon-design-tools-full')
require('./Vlt-icon-design-tools')
require('./Vlt-icon-device-cloud')
require('./Vlt-icon-devices-full')
require('./Vlt-icon-devices')
require('./Vlt-icon-document-full')
require('./Vlt-icon-document')
require('./Vlt-icon-dollar-star')
require('./Vlt-icon-down-full')
require('./Vlt-icon-down')
require('./Vlt-icon-download-full')
require('./Vlt-icon-download')
require('./Vlt-icon-drag-full')
require('./Vlt-icon-drag')
require('./Vlt-icon-duplicate-full')
require('./Vlt-icon-duplicate')
require('./Vlt-icon-edit-full')
require('./Vlt-icon-edit')
require('./Vlt-icon-emoji-full')
require('./Vlt-icon-emoji')
require('./Vlt-icon-enlarge-screen-2-full')
require('./Vlt-icon-enlarge-screen-2')
require('./Vlt-icon-enlarge-screen-full')
require('./Vlt-icon-enlarge-screen')
require('./Vlt-icon-enlarge')
require('./Vlt-icon-enter-full')
require('./Vlt-icon-enter')
require('./Vlt-icon-envelope-full')
require('./Vlt-icon-envelope')
require('./Vlt-icon-error-star')
require('./Vlt-icon-error')
require('./Vlt-icon-exit-full')
require('./Vlt-icon-exit')
require('./Vlt-icon-expand-2')
require('./Vlt-icon-expand-full-1')
require('./Vlt-icon-expand-full')
require('./Vlt-icon-expand')
require('./Vlt-icon-eye-hide-full')
require('./Vlt-icon-eye-hide')
require('./Vlt-icon-eye-negative')
require('./Vlt-icon-eye')
require('./Vlt-icon-facebook')
require('./Vlt-icon-fax-2-full')
require('./Vlt-icon-fax-2')
require('./Vlt-icon-fax-add-full')
require('./Vlt-icon-fax-add')
require('./Vlt-icon-fax-failed-full')
require('./Vlt-icon-fax-failed')
require('./Vlt-icon-fax-full')
require('./Vlt-icon-fax-inbound-full')
require('./Vlt-icon-fax-inbound')
require('./Vlt-icon-fax-outbound-full')
require('./Vlt-icon-fax-outbound')
require('./Vlt-icon-fax-pending-full')
require('./Vlt-icon-fax-pending')
require('./Vlt-icon-fax-scheduled-full')
require('./Vlt-icon-fax-scheduled')
require('./Vlt-icon-fax')
require('./Vlt-icon-file-2-full')
require('./Vlt-icon-file-2')
require('./Vlt-icon-file-3-full')
require('./Vlt-icon-file-3')
require('./Vlt-icon-file-add-full')
require('./Vlt-icon-file-add')
require('./Vlt-icon-file-audio-full')
require('./Vlt-icon-file-audio')
require('./Vlt-icon-file-csv-full')
require('./Vlt-icon-file-doc-full')
require('./Vlt-icon-file-doc')
require('./Vlt-icon-file-dollar-full')
require('./Vlt-icon-file-dollar')
require('./Vlt-icon-file-edit-full')
require('./Vlt-icon-file-edit')
require('./Vlt-icon-file-gear-full')
require('./Vlt-icon-file-gear')
require('./Vlt-icon-file-paragraph-full')
require('./Vlt-icon-file-paragraph')
require('./Vlt-icon-file-pdf-full')
require('./Vlt-icon-file-pdf')
require('./Vlt-icon-file-plus-full')
require('./Vlt-icon-file-plus')
require('./Vlt-icon-file-ppt-full')
require('./Vlt-icon-file-ppt')
require('./Vlt-icon-file-search-full')
require('./Vlt-icon-file-search')
require('./Vlt-icon-file-video-full')
require('./Vlt-icon-file-video')
require('./Vlt-icon-file-voicemail')
require('./Vlt-icon-file-xls-full')
require('./Vlt-icon-file-xls')
require('./Vlt-icon-file-zip-full')
require('./Vlt-icon-file-zip')
require('./Vlt-icon-file')
require('./Vlt-icon-files-full')
require('./Vlt-icon-files')
require('./Vlt-icon-filter-full')
require('./Vlt-icon-filter')
require('./Vlt-icon-flash-2-full')
require('./Vlt-icon-flash-2')
require('./Vlt-icon-flash-full')
require('./Vlt-icon-flash')
require('./Vlt-icon-flow-full')
require('./Vlt-icon-flow')
require('./Vlt-icon-fork')
require('./Vlt-icon-gallery')
require('./Vlt-icon-gear-full')
require('./Vlt-icon-gear')
require('./Vlt-icon-github')
require('./Vlt-icon-grid-two')
require('./Vlt-icon-grid')
require('./Vlt-icon-group-2-full')
require('./Vlt-icon-group-2')
require('./Vlt-icon-group-3-full')
require('./Vlt-icon-group-3')
require('./Vlt-icon-group-4-full')
require('./Vlt-icon-group-4')
require('./Vlt-icon-group-5-full')
require('./Vlt-icon-group-5')
require('./Vlt-icon-group-6-full')
require('./Vlt-icon-group-6')
require('./Vlt-icon-group-7-full')
require('./Vlt-icon-group-7')
require('./Vlt-icon-group-8-full')
require('./Vlt-icon-group-8')
require('./Vlt-icon-group-9-full')
require('./Vlt-icon-group-9')
require('./Vlt-icon-group-add')
require('./Vlt-icon-group-full')
require('./Vlt-icon-group')
require('./Vlt-icon-happy-full')
require('./Vlt-icon-happy')
require('./Vlt-icon-hash-2')
require('./Vlt-icon-hash')
require('./Vlt-icon-headset-full')
require('./Vlt-icon-headset')
require('./Vlt-icon-hearing-full')
require('./Vlt-icon-hearing')
require('./Vlt-icon-heart-full')
require('./Vlt-icon-heart')
require('./Vlt-icon-help-negative')
require('./Vlt-icon-help')
require('./Vlt-icon-history-full')
require('./Vlt-icon-history')
require('./Vlt-icon-home-full')
require('./Vlt-icon-home-user-full')
require('./Vlt-icon-home-user')
require('./Vlt-icon-home')
require('./Vlt-icon-hospital-full')
require('./Vlt-icon-hospital')
require('./Vlt-icon-hoteling-full')
require('./Vlt-icon-hoteling')
require('./Vlt-icon-id-full')
require('./Vlt-icon-id')
require('./Vlt-icon-image-full')
require('./Vlt-icon-image')
require('./Vlt-icon-important')
require('./Vlt-icon-inbox-2-full')
require('./Vlt-icon-inbox-2')
require('./Vlt-icon-inbox-3-full')
require('./Vlt-icon-inbox-3')
require('./Vlt-icon-inbox-full')
require('./Vlt-icon-inbox')
require('./Vlt-icon-increase-full')
require('./Vlt-icon-increase')
require('./Vlt-icon-infinity-full')
require('./Vlt-icon-infinity')
require('./Vlt-icon-info-negative')
require('./Vlt-icon-info')
require('./Vlt-icon-js')
require('./Vlt-icon-key-full')
require('./Vlt-icon-key')
require('./Vlt-icon-keypad-line-full')
require('./Vlt-icon-keypad-line')
require('./Vlt-icon-keypad-solid')
require('./Vlt-icon-keypad')
require('./Vlt-icon-laptop-full')
require('./Vlt-icon-laptop')
require('./Vlt-icon-leave-full')
require('./Vlt-icon-leave')
require('./Vlt-icon-left-full')
require('./Vlt-icon-left')
require('./Vlt-icon-less-detailed-list-full')
require('./Vlt-icon-less-detailed-list')
require('./Vlt-icon-light-bulb-full')
require('./Vlt-icon-light-bulb')
require('./Vlt-icon-lighthouse')
require('./Vlt-icon-link-full')
require('./Vlt-icon-link')
require('./Vlt-icon-list-2')
require('./Vlt-icon-list-numbers')
require('./Vlt-icon-list')
require('./Vlt-icon-lock-full')
require('./Vlt-icon-lock')
require('./Vlt-icon-magic-wand-full')
require('./Vlt-icon-magic-wand')
require('./Vlt-icon-mailbox-full')
require('./Vlt-icon-mailbox')
require('./Vlt-icon-maximise-full')
require('./Vlt-icon-maximise')
require('./Vlt-icon-meeting-full')
require('./Vlt-icon-meeting')
require('./Vlt-icon-megaphone-negative')
require('./Vlt-icon-megaphone')
require('./Vlt-icon-menu-full')
require('./Vlt-icon-menu')
require('./Vlt-icon-merge-2-full')
require('./Vlt-icon-merge-2')
require('./Vlt-icon-merge-full')
require('./Vlt-icon-merge')
require('./Vlt-icon-message-check')
require('./Vlt-icon-message-delivered')
require('./Vlt-icon-message-full')
require('./Vlt-icon-message-read')
require('./Vlt-icon-message')
require('./Vlt-icon-method-get')
require('./Vlt-icon-method-post')
require('./Vlt-icon-microphone-2-full')
require('./Vlt-icon-microphone-2')
require('./Vlt-icon-microphone-full')
require('./Vlt-icon-microphone-mute-full')
require('./Vlt-icon-microphone-mute')
require('./Vlt-icon-microphone-off')
require('./Vlt-icon-microphone')
require('./Vlt-icon-mind-map-full')
require('./Vlt-icon-mind-map')
require('./Vlt-icon-minimize-2-full')
require('./Vlt-icon-minimize-2')
require('./Vlt-icon-minimize-full')
require('./Vlt-icon-minimize')
require('./Vlt-icon-minus-bold')
require('./Vlt-icon-minus')
require('./Vlt-icon-mobile-2-full')
require('./Vlt-icon-mobile-2')
require('./Vlt-icon-mobile-arrow-right-full')
require('./Vlt-icon-mobile-arrow-right')
require('./Vlt-icon-mobile-devices-full')
require('./Vlt-icon-mobile-devices')
require('./Vlt-icon-mobile-full')
require('./Vlt-icon-mobile')
require('./Vlt-icon-mockup-full')
require('./Vlt-icon-mockup')
require('./Vlt-icon-modal-full')
require('./Vlt-icon-modal')
require('./Vlt-icon-more-detailed-list-full')
require('./Vlt-icon-more-detailed-list')
require('./Vlt-icon-more-full')
require('./Vlt-icon-more-negative')
require('./Vlt-icon-more-v-negative')
require('./Vlt-icon-more-v')
require('./Vlt-icon-more')
require('./Vlt-icon-move-down')
require('./Vlt-icon-my-apps-full')
require('./Vlt-icon-my-apps')
require('./Vlt-icon-note-full')
require('./Vlt-icon-note')
require('./Vlt-icon-notes-full')
require('./Vlt-icon-notes')
require('./Vlt-icon-notification-full')
require('./Vlt-icon-notification-off-full')
require('./Vlt-icon-notification-off')
require('./Vlt-icon-notification')
require('./Vlt-icon-open-full')
require('./Vlt-icon-open')
require('./Vlt-icon-packet-2-full')
require('./Vlt-icon-packet-2')
require('./Vlt-icon-packet-full')
require('./Vlt-icon-packet')
require('./Vlt-icon-palette-full')
require('./Vlt-icon-palette')
require('./Vlt-icon-park')
require('./Vlt-icon-pause-2')
require('./Vlt-icon-pause')
require('./Vlt-icon-pentool-full')
require('./Vlt-icon-pentool')
require('./Vlt-icon-phone-down-full')
require('./Vlt-icon-phone-down')
require('./Vlt-icon-phone-full')
require('./Vlt-icon-phone-garland-full')
require('./Vlt-icon-phone-garland')
require('./Vlt-icon-phone-message-full')
require('./Vlt-icon-phone-message')
require('./Vlt-icon-phone-number-full')
require('./Vlt-icon-phone-number')
require('./Vlt-icon-phone-plus-full')
require('./Vlt-icon-phone-plus')
require('./Vlt-icon-phone-tick')
require('./Vlt-icon-phone')
require('./Vlt-icon-pie-chart-full')
require('./Vlt-icon-pie-chart')
require('./Vlt-icon-pin-2-full')
require('./Vlt-icon-pin-2')
require('./Vlt-icon-pin-full')
require('./Vlt-icon-pin')
require('./Vlt-icon-play-2')
require('./Vlt-icon-play-3-full')
require('./Vlt-icon-play-3')
require('./Vlt-icon-play')
require('./Vlt-icon-plug-full')
require('./Vlt-icon-plug')
require('./Vlt-icon-plus-bold')
require('./Vlt-icon-plus')
require('./Vlt-icon-preferences-full')
require('./Vlt-icon-preferences')
require('./Vlt-icon-print-full')
require('./Vlt-icon-print')
require('./Vlt-icon-profile-full')
require('./Vlt-icon-profile')
require('./Vlt-icon-puzzle-full')
require('./Vlt-icon-puzzle')
require('./Vlt-icon-puzzled-full')
require('./Vlt-icon-puzzled')
require('./Vlt-icon-queue-full')
require('./Vlt-icon-queue')
require('./Vlt-icon-quit-full')
require('./Vlt-icon-quit')
require('./Vlt-icon-rec-full')
require('./Vlt-icon-rec')
require('./Vlt-icon-receptionist-2-full')
require('./Vlt-icon-receptionist-2')
require('./Vlt-icon-receptionist-full')
require('./Vlt-icon-receptionist')
require('./Vlt-icon-record')
require('./Vlt-icon-refresh-dollar')
require('./Vlt-icon-refresh-full')
require('./Vlt-icon-refresh')
require('./Vlt-icon-reload-full')
require('./Vlt-icon-reload')
require('./Vlt-icon-remove-full')
require('./Vlt-icon-remove')
require('./Vlt-icon-reorder-full')
require('./Vlt-icon-reorder')
require('./Vlt-icon-reply-full')
require('./Vlt-icon-reply')
require('./Vlt-icon-report-full')
require('./Vlt-icon-report')
require('./Vlt-icon-right-full')
require('./Vlt-icon-right')
require('./Vlt-icon-rocket-full')
require('./Vlt-icon-rocket')
require('./Vlt-icon-rows')
require('./Vlt-icon-rss')
require('./Vlt-icon-salesforce-full')
require('./Vlt-icon-salesforce')
require('./Vlt-icon-sand-clock-full')
require('./Vlt-icon-sand-clock')
require('./Vlt-icon-save-full')
require('./Vlt-icon-save')
require('./Vlt-icon-screen-share-full')
require('./Vlt-icon-screen-share')
require('./Vlt-icon-search-full')
require('./Vlt-icon-search')
require('./Vlt-icon-send-negative')
require('./Vlt-icon-send')
require('./Vlt-icon-separator-full')
require('./Vlt-icon-separator')
require('./Vlt-icon-share-1-full')
require('./Vlt-icon-share-1')
require('./Vlt-icon-share-2-full')
require('./Vlt-icon-share-2')
require('./Vlt-icon-shield-full')
require('./Vlt-icon-shield')
require('./Vlt-icon-shout-full')
require('./Vlt-icon-shout')
require('./Vlt-icon-sidebar-full')
require('./Vlt-icon-sidebar')
require('./Vlt-icon-sidenav-only')
require('./Vlt-icon-small-file')
require('./Vlt-icon-small-image')
require('./Vlt-icon-small-video-full')
require('./Vlt-icon-small-video')
require('./Vlt-icon-smile-full')
require('./Vlt-icon-smile')
require('./Vlt-icon-sms-full')
require('./Vlt-icon-sms')
require('./Vlt-icon-snooze-full')
require('./Vlt-icon-snooze')
require('./Vlt-icon-sort-full')
require('./Vlt-icon-sort')
require('./Vlt-icon-squares')
require('./Vlt-icon-stack-full')
require('./Vlt-icon-stack')
require('./Vlt-icon-star-full')
require('./Vlt-icon-star')
require('./Vlt-icon-sticker-full')
require('./Vlt-icon-sticker')
require('./Vlt-icon-stop-full')
require('./Vlt-icon-stop')
require('./Vlt-icon-stopwatch-full')
require('./Vlt-icon-stopwatch')
require('./Vlt-icon-store-full')
require('./Vlt-icon-store')
require('./Vlt-icon-stream-full')
require('./Vlt-icon-stream')
require('./Vlt-icon-surprised-full')
require('./Vlt-icon-surprised')
require('./Vlt-icon-switch-full')
require('./Vlt-icon-switch')
require('./Vlt-icon-table-1')
require('./Vlt-icon-table-2')
require('./Vlt-icon-table-full')
require('./Vlt-icon-tabs')
require('./Vlt-icon-tag-add-full')
require('./Vlt-icon-tag-add')
require('./Vlt-icon-tag-full')
require('./Vlt-icon-tag-search')
require('./Vlt-icon-tag')
require('./Vlt-icon-telephone-2-full')
require('./Vlt-icon-telephone-2')
require('./Vlt-icon-telephone-3-full')
require('./Vlt-icon-telephone-3')
require('./Vlt-icon-telephone-full')
require('./Vlt-icon-telephone')
require('./Vlt-icon-thumbs-down-full')
require('./Vlt-icon-thumbs-down')
require('./Vlt-icon-thumbs-up-full')
require('./Vlt-icon-thumbs-up')
require('./Vlt-icon-toggle-full')
require('./Vlt-icon-toggle')
require('./Vlt-icon-tool-circle')
require('./Vlt-icon-tool-full')
require('./Vlt-icon-tool')
require('./Vlt-icon-tooltip-full')
require('./Vlt-icon-tooltip')
require('./Vlt-icon-transfer-full')
require('./Vlt-icon-transfer')
require('./Vlt-icon-type-full')
require('./Vlt-icon-type')
require('./Vlt-icon-umbrella-full')
require('./Vlt-icon-umbrella')
require('./Vlt-icon-unhappy-full')
require('./Vlt-icon-unhappy')
require('./Vlt-icon-unlock-full')
require('./Vlt-icon-unlock')
require('./Vlt-icon-up-full')
require('./Vlt-icon-up')
require('./Vlt-icon-upload-full')
require('./Vlt-icon-upload')
require('./Vlt-icon-user-add')
require('./Vlt-icon-user-admin-full')
require('./Vlt-icon-user-admin')
require('./Vlt-icon-user-full')
require('./Vlt-icon-user-lock-full')
require('./Vlt-icon-user-lock')
require('./Vlt-icon-user-small')
require('./Vlt-icon-user-sync-2-full')
require('./Vlt-icon-user-sync-2')
require('./Vlt-icon-user-sync-full')
require('./Vlt-icon-user-sync')
require('./Vlt-icon-user')
require('./Vlt-icon-van-full')
require('./Vlt-icon-van')
require('./Vlt-icon-video-active-full')
require('./Vlt-icon-video-active')
require('./Vlt-icon-video-conference-full')
require('./Vlt-icon-video-conference')
require('./Vlt-icon-video-negative-active')
require('./Vlt-icon-video-negative')
require('./Vlt-icon-video-off-full')
require('./Vlt-icon-video-off')
require('./Vlt-icon-video')
require('./Vlt-icon-virtual-voicemail-full')
require('./Vlt-icon-virtual-voicemail')
require('./Vlt-icon-voicemail-2-full')
require('./Vlt-icon-voicemail-2')
require('./Vlt-icon-voicemail-full')
require('./Vlt-icon-voicemail-transcript-full')
require('./Vlt-icon-voicemail-transcript')
require('./Vlt-icon-voicemail')
require('./Vlt-icon-volume-decrease-full')
require('./Vlt-icon-volume-decrease')
require('./Vlt-icon-volume-full')
require('./Vlt-icon-volume-increase-full')
require('./Vlt-icon-volume-increase')
require('./Vlt-icon-volume-off-2-full')
require('./Vlt-icon-volume-off-2')
require('./Vlt-icon-volume-off-full')
require('./Vlt-icon-volume-off')
require('./Vlt-icon-volume-on-2-full')
require('./Vlt-icon-volume-on-2')
require('./Vlt-icon-volume')
require('./Vlt-icon-vonage-2')
require('./Vlt-icon-vonage')
require('./Vlt-icon-wallet-full')
require('./Vlt-icon-wallet')
require('./Vlt-icon-whisper-full')
require('./Vlt-icon-whisper')
require('./Vlt-icon-wifi-full')
require('./Vlt-icon-wifi')
require('./Vlt-icon-world-full')
require('./Vlt-icon-world-hand')
require('./Vlt-icon-world')
