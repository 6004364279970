/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-list-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4 2a2 2 0 11-4 0 2 2 0 014 0zm2-1h10v2H6V1zm-4 9a2 2 0 100-4 2 2 0 000 4zm4-3h10v2H6V7zm-4 9a2 2 0 100-4 2 2 0 000 4zm4-3h10v2H6v-2z"/>'
  }
})
