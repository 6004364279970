<template>
  <div class="notifications">
    <div class="notifications-title unselectable">
      {{ $t('notifications_settings.title') }}
    </div>
    <div class="notification-row" data-cy="play-sound-when-join-leave">
      {{ $t('notifications_settings.participants_join_or_leave_text') }}
      <vwc-switch
        v-tooltip="joinOrLeaveNotificationTooltip"
        connotation="cta"
        :disabled="disableJoinOrLeaveSound"
        :checked="isJoinOrLeaveSoundNotificationsEnabled"
        @change="
          isJoinOrLeaveSoundNotificationsEnabled = !isJoinOrLeaveSoundNotificationsEnabled
        "
      />
    </div>

    <div
      v-if="isSessionOwner"
      class="notification-row"
      data-cy="play-sound-when-you-have-guests-in-the-waiting-room"
    >
      {{ $t('notifications_settings.guests_in_waiting_room_text') }}
      <vwc-switch
        v-tooltip="joinWaitingRoomNotificationTooltip"
        connotation="cta"
        :disabled="disableWaitingRoomSoundNotification"
        :checked="isWaitingRoomSoundNotificationsEnabled"
        @change="
          isWaitingRoomSoundNotificationsEnabled = !isWaitingRoomSoundNotificationsEnabled
        "
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import analytics from '@/services/analytics-service';
import { ANALYTICS } from '@/consts/global-consts';

export default {
  name: 'NotificationsSettings',

  computed: {
    ...mapState('settings', [
      'playSoundOnJoinOrLeave',
      'playSoundOnWaitingRoomJoin'
    ]),
    ...mapGetters(['activeParticipants', 'isSessionOwner']),
    ...mapGetters('settings', [
      'disableJoinOrLeaveSound',
      'disableWaitingRoomSoundNotification'
    ]),

    joinOrLeaveNotificationTooltip() {
      if (!this.disableJoinOrLeaveSound) {
        return;
      }
      return {
        placement: 'left',
        classes: 'notification-settings-tooltip',
        hideOnTargetClick: true,
        content: this.$t(
          'notifications_settings.participants_join_or_leave_tooltip'
        )
      };
    },

    joinWaitingRoomNotificationTooltip() {
      if (!this.disableWaitingRoomSoundNotification) {
        return;
      }
      return {
        placement: 'left',
        classes: 'notification-settings-tooltip',
        hideOnTargetClick: true,
        content: this.$t(
          'notifications_settings.guests_in_waiting_room_tooltip'
        )
      };
    },

    isJoinOrLeaveSoundNotificationsEnabled: {
      get() {
        return this.playSoundOnJoinOrLeave;
      },
      set(value) {
        this.saveSettings({ playSoundOnJoinOrLeave: value });

        analytics.trackEvent(ANALYTICS.PARTICIPANT_SOUND_INDICATION, {
          Enabled: value,
          'Num of Participants': this.activeParticipants.length
        });
      }
    },

    isWaitingRoomSoundNotificationsEnabled: {
      get() {
        return this.playSoundOnWaitingRoomJoin;
      },
      set(value) {
        this.saveSettings({ playSoundOnWaitingRoomJoin: value });

        analytics.trackEvent(ANALYTICS.WAITING_ROOM_SOUND_INDICATION, {
          Enabled: value
        });
      }
    }
  },

  methods: {
    ...mapActions('settings', ['saveSettings'])
  }
};
</script>

<!--Using global style to override the v-tooltip css-->
<style>
.tooltip.notification-settings-tooltip {
  z-index: 100001;
  max-width: 250px;
}
</style>

<style scoped>
.notifications-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 10px;
}

.notification-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
}
</style>
