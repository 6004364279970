/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-thin-left': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M5.854 3.354a.5.5 0 10-.708-.708l-5 5a.5.5 0 000 .708l5 5a.5.5 0 00.708-.708L1.707 8.5H15.5a.5.5 0 000-1H1.707l4.147-4.146z"/>'
  }
})
