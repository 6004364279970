/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-leave': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M12 12.75a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5h-10.5a.75.75 0 01-.75-.75z"/><path pid="1" d="M18.22 7.72a.75.75 0 011.06 0l4.5 4.5a.75.75 0 010 1.06l-4.5 4.5a.75.75 0 11-1.06-1.06l3.97-3.97-3.97-3.97a.75.75 0 010-1.06zM.463.057A.748.748 0 01.75 0h12a.75.75 0 01.75.75v6a.75.75 0 01-1.5 0V1.5H2.56l6.22 6.22c.141.14.22.331.22.53v15a.75.75 0 01-1.219.586l-7.5-6A.75.75 0 010 17.25V.75A.748.748 0 01.463.057zM1.5 2.56V16.89l6 4.8V8.56l-6-6z"/></g><defs><clipPath id="clip0"><path pid="2" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
