var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "dialog",
      attrs: {
        heading: _vm.$t("virtual_background_modal.title"),
        subtitle: _vm.$t("virtual_background_modal.subtitle"),
        "icon-name": "texture-line",
        "min-width": _vm.minWidth,
        "primary-action": _vm.$t("virtual_background_modal.ok_button"),
        "secondary-action": _vm.$t("virtual_background_modal.cancel_button"),
        "disable-primary-button":
          _vm.isInitializingVirtualBackground || !_vm.isVideoLoaded,
        "loading-primary-button":
          _vm.isInitializingVirtualBackground || !_vm.isVideoLoaded,
        close: _vm.closeModal,
      },
      on: { applied: _vm.applyVirtualBackground },
    },
    [
      _c(
        "div",
        {
          staticClass: "dialog-container",
          attrs: { slot: "content" },
          slot: "content",
        },
        [
          _c("div", { staticClass: "preview-container" }, [
            _c("div", { ref: "video", staticClass: "video" }),
          ]),
          _c("VirtualBackgroundSelector", {
            staticClass: "select-background-container",
            attrs: {
              "selected-background": _vm.selectedBackground,
              disabled:
                _vm.isInitializingVirtualBackground || !_vm.isVideoLoaded,
            },
            on: { selectBackground: _vm.selectBackground },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }