export const SHORT_TOAST_DURATION = 2000;
export const LONG_TOAST_DURATION = 4000;
export const MESSAGE_BANNER_DURATION = 5000;
export const LONG_DURATION = 600000;

export const CARD_VISIBILITY_STATE = Object.freeze({
  COLLAPSED: 'collapsed',
  PARTIALLY_EXTENDED: 'partially-extended',
  FULLY_EXTENDED: 'fully-extended'
});

export const TOAST_TYPE = Object.freeze({
  CRITICAL: 'critical',
  WARNING: 'warning',
  GOOD: 'good',
  INFO: 'info'
});
