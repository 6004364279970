/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-fax-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7 20.5V19h17v1.5c0 1.93-1.57 3.5-3.5 3.5h-15C3.57 24 2 22.43 2 20.5V1a1 1 0 011-1h17a1 1 0 011 1v16h-2V2H4v18.5a1.5 1.5 0 001.46 1.496C5.806 21.947 7 21.693 7 20.5zM7 5h9v2H7V5zm9 4H7v2h9V9zm-9 4h9v2H7v-2z"/>'
  }
})
