/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-camera': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.048 2.329l2.89 4.421H22.5v15h-21v-15h4.561l2.891-4.421.013-.01a.76.76 0 01.097-.065l.008-.004h5.86l.008.004c.026.015.06.036.097.065l.013.01zM18.75 5.25l-2.55-3.9c-.3-.3-.75-.6-1.2-.6H9c-.45 0-.9.3-1.2.6l-2.55 3.9H1.5c-.9 0-1.5.6-1.5 1.5v15c0 .9.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5v-15c0-.9-.6-1.5-1.5-1.5h-3.75zM16.5 13.5a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm1.5 0a6 6 0 11-12 0 6 6 0 0112 0z"/>'
  }
})
