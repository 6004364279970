/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-packet-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 0h21c.9 0 1.5.6 1.5 1.5v3c0 .9-.6 1.5-1.5 1.5h-21C.6 6 0 5.4 0 4.5v-3C0 .6.6 0 1.5 0zM3 7.5h18v15c0 .9-.6 1.5-1.5 1.5h-15c-.9 0-1.5-.6-1.5-1.5v-15zm4.5 9h9V12h-9v4.5z"/>'
  }
})
