import { getAttachmentMessageData } from './attachments-utils';

const MESSAGE_TYPE = Object.freeze({
  GROUP_SMS: 'GRP_SMS',
  GROUP_MMS: 'GRP_MMS',
  SMS: 'SMS',
  BI_SMS: 'SHR_SMS'
});

export async function createNewFakeMessage(
  conversationId,
  messageId,
  from,
  body,
  participantId,
  attachment
) {
  const now = Date.now();
  let fakeMessage = {
    conversationId,
    messageId,
    fromDisplayName: from,
    toDisplayName: conversationId,
    messageType: attachment ? 'GRP_MMS' : 'GRP_SMS',
    sendType: 'ON_NET',
    body,
    lastModifyTime: now,
    creationTime: now,
    sendTime: now,
    trackId: messageId,
    direction: 'OUTBOUND',
    participantId: participantId,
    uiStatus: {
      new: false,
      deliveredConfirmations: {},
      readConfirmations: {},
      sentStatus: 'PENDING'
    }
  };

  if (attachment) {
    const attachmentData = await getAttachmentMessageData(attachment);
    fakeMessage = {
      ...fakeMessage,
      ...attachmentData
    };
  }

  return fakeMessage;
}

export function createNewRealMessage({
  to,
  messageId,
  from,
  body,
  participantId,
  attachment,
  fromDelegate,
  isOffNet = false
}) {
  const realMessage = {
    body,
    trackId: messageId,
    fromDisplayName: from,
    toDisplayName: to,
    participantId: participantId,
    messageType: attachment ? MESSAGE_TYPE.GROUP_MMS : MESSAGE_TYPE.GROUP_SMS
  };
  if (isOffNet) {
    if (fromDelegate) {
      realMessage.messageType = MESSAGE_TYPE.BI_SMS;
      realMessage.fromDelegate = fromDelegate;
    } else {
      realMessage.messageType = MESSAGE_TYPE.SMS;
    }
  }
  return realMessage;
}
