<template>
  <div
    class="Vlt-spinner"
    :class="{
      'Vlt-spinner--white': white,
      'Vlt-spinner--small': size === 'sm',
      'Vlt-spinner--smaller': size === 'xs'
    }"
  ></div>
</template>

<script>
export default {
  name: 'VSpinner',

  props: {
    white: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'lg',
      validator(size) {
        return ['xs', 'sm', 'lg'].indexOf(size) >= 0;
      }
    }
  }
};
</script>
