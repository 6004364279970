<template>
  <Dialog
    :heading="$t('settings_modal.title')"
    :min-width="600"
    hide-actions
    icon-name="gear-line"
    :close="closeModal"
    data-cy="settings-modal"
    :class="{ branded: isBranded }"
  >
    <div slot="content" class="settings-modal-content unselectable">
      <div class="side-nav">
        <vwc-list class="nav-list">
          <vwc-list-item
            v-for="setting in settings"
            :key="setting.id"
            graphic="icon"
            shape="rounded"
            connotation="primary"
            class="setting"
            data-cy="setting-category"
            :class="{ selected: selectedSetting === setting.id }"
            @click="selectedSetting = setting.id"
          >
            <vwc-icon
              slot="graphic"
              class="setting-icon"
              :type="setting.icon"
              :class="{ selected: selectedSetting === setting.id }"
            />
            <div v-if="!$screen.sm && !$screen.xs">{{ setting.label }}</div>
          </vwc-list-item>
        </vwc-list>
        <div
          class="app-version"
          data-cy="app-version"
          @click="clickOnAppVersion"
        >
          {{ appVersion }}
        </div>
      </div>
      <div class="content-container">
        <AudioSettings v-if="isAudioSettings" class="content" />
        <CameraSettings v-if="isCameraSettings" class="content" />
        <NotificationsSettings v-if="isNotificationsSettings" class="content" />
        <AdvancedSettings v-if="isAdvancedSettings" class="content" />
      </div>
    </div>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog';
import { mapActions, mapGetters, mapState } from 'vuex';
import analytics from '@/services/analytics-service';
import { ANALYTICS, ANALYTICS_SOURCE } from '@/consts/global-consts';
import NotificationsSettings from '@/components/NotificationsSettings';
import AudioSettings from '@/components/AudioSettings';
import CameraSettings from '@/components/CameraSettings';
import AdvancedSettings from '@/components/AdvancedSettings';

const SETTING_TYPE = Object.freeze({
  AUDIO: 'audio',
  CAMERA: 'camera',
  NOTIFICATIONS: 'notifications',
  ADVANCED: 'advanced'
});

export default {
  name: 'SettingsModal',

  components: {
    AdvancedSettings,
    CameraSettings,
    AudioSettings,
    NotificationsSettings,
    Dialog
  },

  data() {
    return {
      showAlphaIndication: window.isAlpha,
      numberOfClicksOnAppVersion: 0,
      clicksOnAppVersionTimer: null,
      minNumOfClicksToEnableExperimentalMode: 5,
      selectedSetting: SETTING_TYPE.AUDIO
    };
  },

  computed: {
    ...mapState(['isBranded']),
    ...mapGetters('settings', [
      'allowExclusiveExperimentalMode',
      'exclusiveExperimentalMode',
      'isOpenWebRTCInternalsFunctionalityAvailable'
    ]),

    isAudioSettings() {
      return this.selectedSetting === SETTING_TYPE.AUDIO;
    },

    isCameraSettings() {
      return this.selectedSetting === SETTING_TYPE.CAMERA;
    },

    isNotificationsSettings() {
      return this.selectedSetting === SETTING_TYPE.NOTIFICATIONS;
    },

    isAdvancedSettings() {
      return this.selectedSetting === SETTING_TYPE.ADVANCED;
    },

    settings() {
      const settings = {
        [SETTING_TYPE.AUDIO]: {
          id: 'audio',
          label: this.$t('settings_modal.audio_label'),
          icon: 'audio-max-line'
        },
        [SETTING_TYPE.CAMERA]: {
          id: 'camera',
          label: this.$t('settings_modal.camera_label'),
          icon: 'video-line'
        },
        [SETTING_TYPE.NOTIFICATIONS]: {
          id: 'notifications',
          label: this.$t('settings_modal.notifications_label'),
          icon: 'notification-on-line'
        }
      };
      if (
        this.isOpenWebRTCInternalsFunctionalityAvailable ||
        this.exclusiveExperimentalMode
      ) {
        settings[SETTING_TYPE.ADVANCED] = {
          id: 'advanced',
          label: this.$t('settings_modal.advanced_label'),
          icon: 'flash-line'
        };
      }
      return settings;
    },

    appVersion() {
      const fullAppVersion = process.env.VUE_APP_VERSION;
      const gitRevision = process.env.VUE_APP_GIT_REVISION;

      return this.showAlphaIndication
        ? `Alpha Version ${fullAppVersion.split('-')[0]}`
        : `${fullAppVersion} - ${gitRevision}`;
    }
  },

  methods: {
    ...mapActions(['setIsSettingsModalVisible', 'openWebRTCInternals']),
    ...mapActions('settings', ['toggleExclusiveExperimentalMode']),

    closeModal() {
      this.setIsSettingsModalVisible(false);
    },

    toggleExclusiveExperimentalModeClicked() {
      this.toggleExclusiveExperimentalMode();
      analytics.trackEvent(ANALYTICS.EXCLUSIVE_EXPERIMENTAL_MODE, {
        Source: ANALYTICS_SOURCE.SETTINGS_MODAL,
        State: this.exclusiveExperimentalMode ? 'On' : 'Off'
      });
    },

    clickOnAppVersion() {
      this.numberOfClicksOnAppVersion++;
      if (this.numberOfClicksOnAppVersion === 1) {
        this.clicksOnAppVersionTimer = setTimeout(() => {
          if (
            this.numberOfClicksOnAppVersion >=
            this.minNumOfClicksToEnableExperimentalMode
          ) {
            if (this.allowExclusiveExperimentalMode) {
              this.toggleExclusiveExperimentalModeClicked();
            }
          }
          clearTimeout(this.clicksOnAppVersionTimer);
          this.numberOfClicksOnAppVersion = 0;
        }, 1500);
      }
    }
  }
};
</script>
<style scoped>
.settings-modal-content {
  border-top: 1px solid #cccccc;
  width: 100%;
  height: 370px;
  display: flex;
  flex-direction: row;
}

.side-nav {
  display: flex;
  flex-direction: column;
  padding: 8px 24px 0 0;
  align-items: center;
  justify-content: space-between;
  border-right: 1px solid #cccccc;
}

.nav-list {
  flex: 1;
}

vwc-list-item.setting {
  min-width: 190px;
}

vwc-icon.setting-icon:not(.selected) {
  color: black;
}

vwc-list-item.setting.selected,
vwc-icon.setting-icon.selected {
  background-color: black;
  color: white;
  font-weight: 600;
}

.app-version {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  color: #666666;
}

.content-container {
  padding: 20px 0 0 24px;
  width: 338px;
  height: 100%;
}

.content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-height: 500px) {
  .settings-modal-content {
    height: 60vh;
  }
}

@media only screen and (max-width: 850px) {
  vwc-list-item.setting {
    min-width: 139px;
  }

  vwc-dialog {
    --mdc-dialog-min-width: 450px !important;
    --mdc-dialog-max-width: unset !important;
  }
}

@media only screen and (max-width: 600px) {
  vwc-list-item.setting {
    min-width: unset;
    --vvd-list-item-graphic-margin: 0;
  }

  vwc-dialog {
    --mdc-dialog-min-width: unset !important;
    --mdc-dialog-max-width: unset !important;
  }

  .side-nav {
    width: 64px;
  }
}
</style>
