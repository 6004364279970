<template>
  <div class="text-wrapper" @click="$emit('copyToClipboard')">
    <Linkify
      :halfOpacity="message.sentStatus === 'PENDING'"
      :content="message.body"
      :isRTL="isRTL"
      :isElectron="isElectron"
    />
    <div
      v-if="isNotSent"
      class="message-not-sent-div"
      @click.stop="$emit('resend')"
    >
      <v-icon
        iconName="Vlt-icon-refresh"
        class="retry-icon"
        :class="{ 'spinning-icon': message.sentStatus === 'RESENDING' }"
      />
      <span class="message-not-sent"
        >{{ $t('text_message_row.message_not_sent_text') }}
      </span>
    </div>
  </div>
</template>

<script>
import Linkify from '@/components/Linkify';
import { isRTLLanguage } from '@/helpers/global-helpers';
import { isElectron } from '@/helpers/global-helpers';

export default {
  name: 'TextMessageRow',

  components: {
    Linkify
  },

  props: {
    message: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isElectron: isElectron()
    };
  },

  computed: {
    isRTL() {
      if (this.message?.body) {
        return isRTLLanguage(this.message.body);
      }
      return false;
    },

    isNotSent() {
      const sentStatus = this.message.sentStatus;
      return sentStatus === 'FAILED' || sentStatus === 'RESENDING';
    }
  }
};
</script>

<style scoped>
.text-wrapper {
  width: 100%;
}
.message-not-sent {
  font-size: 12px;
  font-weight: bold;
  color: #f25a6b;
  margin-left: 4px;
}
.retry-icon.svg-icon {
  width: 17px;
  height: 17px;
  fill: #f25a6b;
}
.message-not-sent-div {
  margin-top: 5px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.retry-icon.svg-icon.spinning-icon {
  animation: spin 1s infinite linear;
}
</style>
