/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-fax-pending': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 2.2v1.5h3v18h11.6l6.2-6.2.2-.2V3.8h3V2.2H0zm15 17v-3.4h3.4L15 19.2zm4.5-5h-6v6h-9V3.8h15v10.4z"/><path pid="1" d="M6.7 11.6c-.4 0-.8.3-.8.8s.3.8.8.8h6.1c.4 0 .8-.3.8-.8s-.3-.8-.8-.8h-.2c0-.8-.3-1.7-.8-2.3.5-.6.7-1.4.8-2.2h.2c.4 0 .8-.3.8-.8 0-.4-.3-.8-.8-.8H6.7c-.4 0-.7.3-.7.7 0 .4.3.8.7.8h.2c.1.8.3 1.6.8 2.2-.5.6-.8 1.5-.8 2.3h-.2zm2.5-3c-.4-.2-.7-.8-.8-1.6h2.7c-.1.8-.4 1.4-.8 1.6-.2.1-.4.4-.4.6 0 .3.1.5.4.6.4.2.7.8.8 1.7H8.4c.1-.8.4-1.4.8-1.7.2-.1.4-.4.4-.6 0-.2-.2-.5-.4-.6z"/>'
  }
})
