/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'form-element-dropdown': {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M3.383 7.965a.625.625 0 01.873-.137L10 12.005l5.743-4.177a.625.625 0 01.736 1.01l-6.112 4.445a.625.625 0 01-.735 0l-6.11-4.444a.625.625 0 01-.139-.874z"/>'
  }
})
