/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-block': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.748 5.96c0-3.222 2.738-5.96 6.12-5.96 3.222 0 6.121 2.577 6.121 5.96a6.118 6.118 0 01-6.12 6.12c-3.383 0-6.121-2.899-6.121-6.12zm6.12-4.51c-2.577 0-4.51 2.094-4.51 4.51 0 .966.322 1.933.806 2.577l6.282-6.282c-.806-.483-1.611-.805-2.578-.805zm-2.577 8.214c.806.484 1.611.806 2.577.806a4.33 4.33 0 004.35-4.35c0-.966-.323-1.932-.806-2.576l-6.12 6.12zM5.627 2.9c-.161-.16-.483-.483-.966-.483h-.323l-.112.035c-.707.214-3.222.974-2.465 4.153.645 3.544 3.06 6.926 5.96 9.987 3.221 3.382 7.57 5.798 10.148 5.798h.483c2.416-.483 3.382-3.382 2.577-4.027-2.255-1.771-3.382-2.416-4.51-2.416h-.483c-1.289.161-1.772 1.45-1.772 1.45s-2.255-.483-4.671-3.06c-2.738-2.739-3.06-4.672-3.06-4.672.966-.644 1.449-1.288 1.449-2.416 0-1.127-1.772-3.704-2.255-4.349zM4.017.805h.644c.966 0 1.771.484 2.255 1.128 1.127 1.61 2.577 3.705 2.577 5.154.16 1.45-.483 2.416-1.128 3.06.322.806.967 1.772 2.255 3.061.987 1.128 1.973 1.762 2.528 2.119.08.05.15.096.21.136.484-.483 1.128-.966 2.095-1.127h.805c1.61 0 3.06.805 5.476 2.738.806.644 1.128 1.772.806 3.06-.483 1.772-1.933 3.544-4.188 3.866h-.645c-3.06 0-7.892-2.738-11.275-6.282C2.89 13.852.795 10.308.15 6.926c-.644-3.06.806-5.315 3.866-6.12z"/>'
  }
})
