<template>
  <vwc-card :class="{ uploading: question.isUploading }">
    <div slot="media" class="name-wrapper">
      <Avatar
        size="sm"
        :displayName="participantDisplayName"
        class="avatar"
        :image="participantProfilePicture"
      />
      <div class="name-and-time-container">
        <div class="name">
          {{ participantDisplayName }}
        </div>
        <div class="creationTime">
          {{ formattedTime(question.createdAt) }}
        </div>
      </div>
      <vwc-icon-button
        v-if="isQnaSessionOwner && !question.isResolved"
        icon="check-solid"
        dense
        connotation="success"
        class="check-icon"
        @click="resolveQuestion(question.questionId)"
      />
      <div
        v-else-if="!isQnaSessionOwner && !question.isResolved"
        class="upvote-button-container"
      >
        <vwc-icon-button-toggle
          connotation="cta"
          class="vote-button"
          onicon="thumbs-up-solid"
          officon="thumbs-up-line"
          dense
          :on="isUpvotedByMe"
          @click="upvote"
        />

        <span class="number-of-likes">
          {{ questionNumberOfLikes }}
        </span>
      </div>
      <vwc-icon-button
        v-if="isQnaSessionOwner || isMyQuestion"
        connotation="primary"
        icon="delete-line"
        dense
        class="delete-icon"
        @click="deleteQuestion(question.questionId)"
      />
      <!-- TODO: Add owner menu someday -->
      <!-- <vwc-icon-button-toggle
        v-if="isQnaSessionOwner || isMyQuestion"
        onicon="more-vertical-solid"
        officon="more-vertical-solid"
        dense
      ></vwc-icon-button-toggle> -->
    </div>
    <div slot="main" class="card-main-slot-container">
      <p
        class="question-text"
        :class="{
          resolved: question.isResolved
        }"
      >
        {{ question.questionText }}
      </p>
      <div class="badges-container">
        <vwc-badge
          v-if="isQnaSessionOwner"
          class="likes-badge"
          connotation="cta"
          icon="thumbs-up-solid"
          shape="pill"
          :text="questionNumberOfLikes"
        />
        <vwc-badge
          v-if="question.isResolved"
          class="answered-badge"
          connotation="success"
          icon="check-solid"
          shape="pill"
          layout="soft"
          :text="$t('qna_sidebar.answered_badge')"
        />
      </div>
    </div>
  </vwc-card>
</template>

<script>
import { formatTime } from '@/helpers/global-helpers';
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  props: {
    participant: {
      type: Object,
      required: true
    },
    question: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState(['myParticipantId']),
    ...mapGetters('qna', ['isQnaSessionOwner']),

    isUpvotedByMe() {
      return this.question.upvotes
        .map((vote) => vote.userId)
        .includes(this.myParticipantId);
    },

    isMyQuestion() {
      return this.question.userId === this.myParticipantId;
    },

    participantDisplayName() {
      if (this.participant === undefined) {
        return 'Unknown';
      }
      return this.participant.displayName;
    },

    participantProfilePicture() {
      if (this.participant === undefined) {
        return '';
      }
      return this.participant.profilePicture;
    },

    questionNumberOfLikes() {
      if (this.question.upvotes.length === 0) {
        return 0;
      }
      return this.question.upvotes.length;
    }
  },

  methods: {
    ...mapActions('qna', [
      'upvoteQuestion',
      'cancelUpvoteQuestion',
      'resolveQuestion',
      'deleteQuestion'
    ]),

    upvote() {
      if (this.isUpvotedByMe) {
        this.cancelUpvoteQuestion(this.question.questionId);
      } else {
        this.upvoteQuestion(this.question.questionId);
      }
    },

    formattedTime(createdAt) {
      return formatTime(createdAt);
    }
  }
};
</script>

<style scoped>
.name-wrapper {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.name-and-time-container {
  width: 230px;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.avatar {
  margin-left: 12px;
  min-width: 36px;
  min-height: 36px;
}

.name {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: black;
}

.creationTime {
  font-size: 12px;
  color: #666666;
  line-height: 26px;
}

.check-icon {
  margin-right: 10px;
}

.question-text {
  padding-right: 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-top: -10px;
}

.question-text.resolved {
  color: #666666;
  text-decoration: line-through;
}

.card-main-slot-container {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
}

.vote-button {
  margin-right: 6px;
}

.upvote-button-container {
  margin-right: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.number-of-likes {
  font-weight: bold;
  font-size: 12px;
}

.answered-badge {
  margin-left: 4px;
}

.delete-icon {
  margin-right: 12px;
}

.uploading {
  opacity: 0.35;
}
</style>
