/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-pin-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M12 0C6.15 0 1.5 4.65 1.5 10.5c0 2.85 1.05 5.55 3.15 7.5.15.15 6.15 5.55 6.3 5.7.6.45 1.5.45 1.95 0 .15-.15 6.3-5.55 6.3-5.7 2.1-1.95 3.15-4.65 3.15-7.5C22.5 4.65 17.85 0 12 0zm0 13.5c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
