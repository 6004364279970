/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-camera-switch': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.048 2.329l2.89 4.421H22.5v15h-21v-15h4.561l2.891-4.421.013-.01a.759.759 0 01.097-.065l.008-.004h5.86l.008.004c.026.015.06.036.097.065l.013.01zM18.75 5.25l-2.55-3.9c-.3-.3-.75-.6-1.2-.6H9c-.45 0-.9.3-1.2.6l-2.55 3.9H1.5c-.9 0-1.5.6-1.5 1.5v15c0 .9.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5v-15c0-.9-.6-1.5-1.5-1.5h-3.75zM8.357 7.575a.75.75 0 011.06 0l1.876 1.875 1.28 1.28h-3.31a3 3 0 100 6 .75.75 0 110 1.5 4.5 4.5 0 01-.302-8.99l-.604-.604a.75.75 0 010-1.06zm7.286 12.31a.75.75 0 01-1.061 0l-1.875-1.874-1.28-1.28h3.31a3 3 0 000-6 .75.75 0 010-1.5 4.5 4.5 0 01.301 8.99l.604.604a.75.75 0 010 1.06z"/>'
  }
})
