import axios from 'axios';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';

const publicApiGw = axios.create({
  baseURL: process.env.VUE_APP_VBC_PUBLIC_API_SERVER,
  timeout: 10000
});
export default publicApiGw;

export function setDefaultErrorHandler(fn) {
  publicApiGw.interceptors.response.use(null, fn);
}

setDefaultErrorHandler(async (error) => {
  const { message, name, status } = error.response?.data;
  const { url, method, data } = error.response?.config;
  const formattedError = {
    name,
    status,
    message,
    url,
    method,
    data
  };

  logger.error('public-api-error', LOG_CATEGORIES.API_CALL, formattedError);
  throw error;
});

export function setDefaultSuccessHandler(fn) {
  publicApiGw.interceptors.response.use(fn);
}

setDefaultSuccessHandler((response) => response.data);
