import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';

const SOUNDS_DATA_URIS = Object.freeze({
  PARTICIPANT_JOINED: require('@/assets/sounds/join-notification-sound.mp3'),
  PARTICIPANT_LEFT: require('@/assets/sounds/left-notification-sound.mp3'),
  STANDUP_TURN_REACHED: require('@/assets/sounds/standup-turn-notification-sound.mp3'),
  SHORT_EXCITED_CLAPPING: require('@/assets/sounds/short-excited-clapping.mp3'),
  SHORT_EXCITED_CLAPPING_SCREAMING: require('@/assets/sounds/short-excited-clapping-screaming.mp3'),
  SHORT_NORMAL_CLAPPING: require('@/assets/sounds/short-normal-clapping.mp3'),
  LONG_NORMAL_CLAPPING: require('@/assets/sounds/long-normal-clapping.mp3'),
  LONG_RYTHMIC_CLAPPING: require('@/assets/sounds/long-rythmic-clapping.mp3'),
  MEETING_IS_BEING_RECORDED: require('@/assets/sounds/meeting-is-being-recorded.mp3'),
  MEETING_IS_NO_LONGER_BEING_RECORDED: require('@/assets/sounds/meeting-is-no-longer-being-recorded.mp3'),
  WAITING_ROOM_NOTIFICATION: require('@/assets/sounds/waiting-room-notification.mp3')
});

const cachedSoundsElements = {};

class SoundsPlayer {
  constructor() {
    this._isMuted = false;
    this._soundTypes = Object.freeze({
      participantJoined: 'PARTICIPANT_JOINED',
      participantLeft: 'PARTICIPANT_LEFT',
      standupTurnReached: 'STANDUP_TURN_REACHED',
      meetingIsBeingRecorded: 'MEETING_IS_BEING_RECORDED',
      meetingIsNoLongerBeingRecorded: 'MEETING_IS_NO_LONGER_BEING_RECORDED',
      shortNormalClapping: 'SHORT_NORMAL_CLAPPING',
      shortExcitedClapping: 'SHORT_EXCITED_CLAPPING',
      shortExcitedClappingScreaming: 'SHORT_EXCITED_CLAPPING_SCREAMING',
      longNormalClapping: 'LONG_NORMAL_CLAPPING',
      longRythmicClapping: 'LONG_RYTHMIC_CLAPPING',
      waitingRoomNotification: 'WAITING_ROOM_NOTIFICATION'
    });
  }

  muteAllSounds() {
    this._isMuted = true;
  }

  unmuteAllSounds() {
    this._isMuted = false;
  }

  playParticipantJoinedSound(speakerId) {
    this._playSound(this._soundTypes.participantJoined, speakerId);
  }

  playParticipantLeftSound(speakerId) {
    this._playSound(this._soundTypes.participantLeft, speakerId);
  }

  playStandupTurnReachedSound(speakerId) {
    this._playSound(this._soundTypes.standupTurnReached, speakerId);
  }

  playMeetingRecordingIndicationSound(isRecorded, speakerId) {
    const soundType = isRecorded
      ? this._soundTypes.meetingIsBeingRecorded
      : this._soundTypes.meetingIsNoLongerBeingRecorded;
    this._playSound(soundType, speakerId);
  }

  playShortExcitedClappingScreaming(speakerId) {
    this._playSound(
      this._soundTypes.shortExcitedClappingScreaming,
      speakerId,
      0.6
    );
  }

  playShortExcitedClapping(speakerId) {
    this._playSound(this._soundTypes.shortExcitedClapping, speakerId, 0.6);
  }

  playShortNormalClapping(speakerId) {
    this._playSound(this._soundTypes.shortNormalClapping, speakerId, 0.6);
  }

  playLongNormalClapping(speakerId) {
    this._playSound(this._soundTypes.longNormalClapping, speakerId, 0.6);
  }

  playLongRythmicClapping(speakerId) {
    this._playSound(this._soundTypes.longRythmicClapping, speakerId, 0.6);
  }

  playWaitingRoomNotification(speakerId) {
    this._playSound(this._soundTypes.waitingRoomNotification, speakerId);
  }

  _playSound(soundType, speakerId = 'default', volume = 1) {
    if (this._isMuted) {
      console.log(
        `The sounds player is muted, so it will not play the sound: ${soundType}`
      );
      return;
    }

    const soundUri = SOUNDS_DATA_URIS[soundType];
    if (!soundUri) {
      return;
    }
    if (!cachedSoundsElements[soundType]) {
      cachedSoundsElements[soundType] = new Audio(soundUri);
    }

    if (speakerId) {
      try {
        cachedSoundsElements[soundType].setSinkId(speakerId);
      } catch (e) {
        logger.error('setSinkId', LOG_CATEGORIES.CLIENT_LOGIC, {
          err: e.toString()
        });
      }
    }

    cachedSoundsElements[soundType].volume = volume;
    cachedSoundsElements[soundType].play();
  }
}

export default new SoundsPlayer();
