export const config = {
  environments: {
    dev: [
      {
        host: 'localhost:9090',
        desktop_app_port: 19805
      },
      {
        host: process.env.VUE_APP_PRE_ALPHA_HOSTNAME,
        desktop_app_port: 19804
      }
    ],
    prod: [
      {
        host: process.env.VUE_APP_ALPHA_HOSTNAME,
        desktop_app_port: 19803
      },
      {
        host: process.env.VUE_APP_PRODUCTION_HOSTNAME,
        desktop_app_port: 19802
      }
    ]
  }
};
