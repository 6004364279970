/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-small-video': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 1h18a3 3 0 013 3v16a3 3 0 01-3 3H3a3 3 0 01-3-3V4a3 3 0 013-3zm0 1.7A1.3 1.3 0 001.7 4v16A1.3 1.3 0 003 21.3h18a1.3 1.3 0 001.3-1.3V4A1.3 1.3 0 0021 2.7H3zm13.73 7.539L9.948 6.587A2 2 0 007 8.348v7.303a2 2 0 002.948 1.762l6.782-3.652a2 2 0 000-3.522zM9 8.049a.3.3 0 01.142.035l6.782 3.652a.3.3 0 010 .528l-6.782 3.652a.3.3 0 01-.442-.264V8.348a.3.3 0 01.3-.3z"/>'
  }
})
