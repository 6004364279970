<template>
  <div class="issue-message" data-cy="reconnecting-banner">
    <div class="container">
      <vwc-circular-progress
        class="loading-spinner vvd-scheme-alternate"
        indeterminate
        density="-8"
      />
      <div class="text">{{ $t('app_screen.network_issue_message') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReconnectingBanner'
};
</script>

<style scoped>
.issue-message {
  display: flex;
  align-self: center;
  align-items: center;
  position: absolute;
  top: 80px;
  z-index: 100;
}

.text {
  color: black;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 10px;
}
.loading-spinner {
  margin-left: 12px;
}

.container {
  display: flex;
  align-items: center;
  min-width: 185px;
  min-height: 36px;
  background-color: white;
  border-radius: 6px;
}

.mobile .issue-message {
  z-index: 99999;
  align-self: center;
  align-items: center;
}
</style>
