/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-open': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.854.146a.5.5 0 010 .708l-8 8a.5.5 0 11-.708-.708l8-8a.5.5 0 01.708 0z"/><path pid="1" d="M8 .5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v7a.5.5 0 01-1 0V1H8.5A.5.5 0 018 .5zM1.5 4a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h10a.5.5 0 00.5-.5v-5a.5.5 0 011 0v5a1.5 1.5 0 01-1.5 1.5h-10A1.5 1.5 0 010 14.5v-10A1.5 1.5 0 011.5 3h5a.5.5 0 010 1h-5z"/>'
  }
})
