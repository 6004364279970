/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-inbox-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6 .5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V5h1.5a.5.5 0 01.376.83l-3.5 4a.5.5 0 01-.752 0l-3.5-4A.5.5 0 014.5 5H6V.5zM7 1v4.5a.5.5 0 01-.5.5h-.898L8 8.74 10.398 6H9.5a.5.5 0 01-.5-.5V1H7z"/><path pid="1" d="M1.5 1a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5h-2a.5.5 0 010-1h2A1.5 1.5 0 0116 1.5v13a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 14.5v-13A1.5 1.5 0 011.5 0h2a.5.5 0 010 1h-2z"/><path pid="2" d="M0 12.5a.5.5 0 01.5-.5h15a.5.5 0 010 1H.5a.5.5 0 01-.5-.5z"/>'
  }
})
