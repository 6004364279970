/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-megaphone-negative': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.9.2c.4-.1.7-.2 1.1-.2 2.9 0 5 3.9 5 9s-2.1 9-5 9c-.4 0-.7-.1-1-.2L2.7 13c-.8-.3-1.6-.8-2-1.5C.2 10.7-.1 9.9 0 9c-.1-.9.2-1.7.7-2.5.5-.7 1.2-1.2 2-1.5L17.9.2zM16 9c0 4 1.6 7 3 7s3-3 3-7-1.6-7-3-7-3 3-3 7zm4 0c0-.7-.3-1.4-.8-2-.5-.5-1.2-.9-1.9-1-.2 1-.3 2-.3 3s.1 2 .3 3c.7-.1 1.4-.4 1.9-1s.8-1.3.8-2zm-8.3 8.9l-5.8-1.8 2.6 6.5c.1.3.3.5.5.8.2.2.5.4.8.5.3.1.6.2.9.2.3 0 .6-.1.9-.2s.5-.3.8-.5c.2-.2.4-.5.5-.8.1-.3.2-.6.2-.9 0-.3-.1-.6-.2-.9l-1.2-2.9z"/>'
  }
})
