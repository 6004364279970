/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-thin-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.5 0a.5.5 0 01.5.5v13.793l4.146-4.147a.5.5 0 01.708.708l-5 5a.5.5 0 01-.708 0l-5-5a.5.5 0 01.708-.708L8 14.293V.5a.5.5 0 01.5-.5z"/>'
  }
})
