/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vonage-wordmark': {
    width: 913,
    height: 200,
    viewBox: '0 0 913 200',
    data: '<path pid="0" _fill="#131415" d="M45.34 0H0l64.68 146.958c.495 1.123 2.092 1.112 2.57-.016l21.513-50.605L45.34 0zM183.45 0s-69.494 159.156-78.802 173.833c-10.819 17.063-17.989 23.576-31.257 25.663a.253.253 0 00-.214.25c0 .14.114.254.254.254h41.524c17.988 0 30.96-15.021 38.15-28.286C161.274 156.637 229.59 0 229.59 0h-46.14zM365.053 127.643a.27.27 0 01-.491 0l-34.473-75.437H310.72s35.529 80.048 39.078 86.03c3.468 5.844 7.665 10.663 15.009 10.663s11.54-4.819 15.009-10.664c3.549-5.98 39.078-86.029 39.078-86.029h-19.368l-34.474 75.437zM470.187 134.2c-18.733 0-31.001-12.2-31.001-34.2 0-22 12.268-34.2 31.001-34.2 18.666 0 30.999 12.2 30.999 34.2 0 22-12.333 34.2-30.999 34.2m0-84.2c-29.333 0-48.2 19-48.2 50s18.867 50 48.2 50c29.266 0 48.2-19 48.2-50s-18.934-50-48.2-50M617.483 52.2v95.6H597.63l-46.28-69.893V147.8h-16.933V52.2h19.919l46.28 70.36V52.2zM662.866 108.6l16.677-39.045a.27.27 0 01.496 0l16.677 39.045h-33.85zm16.925-57.493c-6.974 0-11.236 5.291-14.046 10.582-2.879 5.422-37.315 86.111-37.315 86.111h17.694l9.887-23.149h47.56l9.887 23.15h17.693s-34.435-80.69-37.314-86.112c-2.81-5.29-7.073-10.582-14.046-10.582zM779.016 110.934h30.289c-.142 14.854-14.008 23.267-28.705 23.267-18.432 0-30.502-12.202-30.502-34.201 0-23.753 11.174-34.34 31.536-34.34 12.747 0 23.345 5.213 25.576 17.226h17.562C821.692 61.854 802.322 50 780.6 50c-28.862 0-47.425 19-47.425 50s18.563 49.629 47.425 49.629c12.169 0 24.442-6.525 28.764-13.417l-.056 11.588h16.2V96.304h-46.492v14.63zM912.59 68.2v-16h-68.732v95.6h68.733v-16h-51.8v-25.133h47.76v-16h-47.76V68.198z"/>'
  }
})
