var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "VDropdown",
    {
      staticClass: "layout-toggle-container",
      attrs: {
        items: _vm.layoutDropdownItems,
        closeOnMouseLeave: "",
        defaultCursor: "",
        minWidth: "186",
      },
      on: {
        change: function (layout) {
          return _vm.setLayoutMode({
            layoutMode: layout.value,
            layoutToSave: _vm.lastSavedLayout,
          })
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "action-button-container",
          attrs: { slot: "toggle-button", app: "", sm: "", white: "" },
          slot: "toggle-button",
        },
        [
          _vm.layouts[_vm.layoutMode]
            ? _c("span", { staticClass: "layout-name" }, [
                _vm._v(_vm._s(_vm.layouts[_vm.layoutMode].label)),
              ])
            : _vm._e(),
          _c("v-icon", {
            staticClass: "expand-dropdown-arrow-icon Vlt-black",
            attrs: { iconName: "Vlt-icon-down-full" },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }