<template>
  <div
    data-cy="participant-row"
    class="participant-row"
    :class="{ 'my-participant-row': isMyParticipantRow }"
    @mouseleave="hideVolumeBarSlider"
    @mouseenter="showVolumeBarSlider"
  >
    <div class="participant-info">
      <Avatar
        v-if="participant.type === 'Pstn'"
        key="1"
        size="sm"
        :iconName="'call-solid'"
      />
      <Avatar
        v-else-if="participant.type === 'Guest'"
        key="2"
        size="sm"
        :isBeRightBackOn="isBeRightBackOnStatus"
        :iconName="'user-solid'"
      />
      <Avatar
        v-else
        key="3"
        size="sm"
        :displayName="participant.displayName"
        :image="participant.profilePicture"
        :isBeRightBackOn="isBeRightBackOnStatus"
      />
      <div class="participant-data">
        <div class="participant-name-row">
          <div class="participant-name text-ellipsis">
            {{ participantDisplayName }}
          </div>
        </div>
        <div
          v-if="!isActiveParticipant"
          class="participant-state"
          :class="{
            'Vlt-green': participant.state === PARTICIPANT_STATE_TYPES.INVITED,
            'Vlt-grey-dark':
              participant.state === PARTICIPANT_STATE_TYPES.LEFT ||
              participant.state === PARTICIPANT_STATE_TYPES.KICKED,
            'Vlt-red': participant.state === PARTICIPANT_STATE_TYPES.DECLINED
          }"
        >
          {{ participantStateText }}
        </div>
        <div
          v-if="
            isBeRightBackOnStatus &&
            owner !== participant.participantId &&
            !isMyParticipantRow
          "
          class="participant-state Vlt-grey-darker"
        >
          {{ $t('participant_row.be_right_back_text') }}
        </div>
        <div class="participant-state Vlt-grey-darker">
          <span
            v-if="owner === participant.participantId"
            class="participant-state Vlt-grey-darker"
          >
            <span v-if="isMyParticipantRow"
              >{{ $t('participant_row.is_me_text') }},
            </span>
            <span>{{ $t('participant_row.is_owner_text') }} </span>
            <span v-if="isBeRightBackOnStatus" class="Vlt-grey-darker">
              · {{ $t('participant_row.be_right_back_text') }}
            </span>
          </span>
          <span
            v-else-if="isMyParticipantRow"
            class="participant-state Vlt-grey-darker"
          >
            {{ $t('participant_row.is_me_text') }}
            <span v-if="isBeRightBackOnStatus" class="Vlt-grey-darker">
              · {{ $t('participant_row.be_right_back_text') }}
            </span>
          </span>

          <span v-if="isRecordingOwner" class="Vlt-red recording">{{
            $t('participant_row.is_recording_owner_text')
          }}</span>
        </div>
      </div>
      <div v-if="isActiveParticipant" class="info-icons">
        <vwc-icon
          v-if="participantVideoStream && participantVideoStream.badQuality"
          type="wifi-line"
          connotation="alert"
          size="small"
        />
        <vwc-icon
          v-if="isParticipantSharingScreen"
          class="participant-row-icon share-screen"
          type="screen-share-line"
          connotation="primary"
          size="small"
        />
        <div
          v-if="isParticipantRaisingHand"
          v-tooltip.top="raisedHandTooltip"
          :class="{ 'removable-hand': isMyParticipantRow || isSessionOwner }"
          @click="toggleRaiseHand(participant.participantId)"
        >
          <img class="raise-hand" src="/raise_hand_img.svg" />
        </div>
        <vwc-icon
          v-if="participant.type === 'Pstn'"
          class="participant-row-icon"
          type="call-line"
          connotation="primary"
          size="small"
        />
        <vwc-icon-button
          v-else-if="isMyParticipantRow"
          :icon="isParticipantMutedVideo ? 'video-off-solid' : 'video-solid'"
          connotation="primary"
          class="participant-row-icon camera-btn"
          layout="ghost"
          dense
          :disabled="isInitializingPublisher"
          @click="() => toggleVideo(TOGGLE_CAMERA_SOURCES.PARTICIPANTS_TAB)"
        />
        <div
          v-observe-visibility="{
            callback: microphoneVisibilityChanged,
            throttle: 1000,
            throttleOptions: {
              leading: 'visible'
            }
          }"
          class="mic-wrapper"
        >
          <MicVolumeIndication
            v-if="!isParticipantMutedAudio && isMicrophoneVisible"
            class="volume-indication"
            :volume="micVolume"
            :maxHeight="10"
            :color="micIndicationFillColor"
          />
          <vwc-icon
            v-if="!isMyParticipantRow"
            :type="
              isParticipantMutedAudio ? 'mic-mute-line' : 'microphone-line'
            "
            connotation="primary"
            size="small"
            class="mic-icon"
          />
          <vwc-icon-button
            v-else
            :icon="
              isParticipantMutedAudio ? 'mic-mute-solid' : 'microphone-2-solid'
            "
            connotation="primary"
            layout="ghost"
            dense
            class="participant-row-icon mic-btn"
            :disabled="isInitializingPublisher || isNoAudioMode"
            @click="() => toggleMic(TOGGLE_MIC_SOURCES.PARTICIPANTS_TAB)"
          />
        </div>

        <MoreOptionsMenu
          :more-options-lists="moreOptionsMenuLists"
          @optionSelected="dropdownOptionSelected"
        />
      </div>
    </div>
    <div
      v-if="
        false &&
        exclusiveExperimentalMode &&
        !isMyParticipantRow &&
        isActiveParticipant &&
        !isParticipantMutedAudio
      "
      :class="volumeBarClass"
    >
      <VueSlider
        v-model="participantSubscriberAudioVolume"
        tooltip="hover"
        dotSize="12"
        class="volume-bar"
        :use-keyboard="false"
        :marks="{ '0': '0', '50': '50', '100': '100' }"
        @change="setParticipantSubscriberAudioVolume"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { formatPhoneDisplayInternational } from '@/helpers/global-helpers';
import { isScreenshareStream } from '@/helpers/meeting-helpers';
import {
  PARTICIPANT_STATE_TYPES,
  ANALYTICS,
  TOGGLE_MIC_SOURCES,
  TOGGLE_CAMERA_SOURCES
} from '@/consts/global-consts';
import analytics from '@/services/analytics-service';

import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/material.css';
import debounce from 'lodash.debounce';
import MicVolumeIndication from '@/components/MicVolumeIndication';
import { extractFirstName } from '@/helpers/string-utils';
import MoreOptionsMenu from '@/components/MoreOptionsMenu';

const volumeBarVisibilityStates = {
  OFF: 'off',
  CLOSING: 'closing',
  ON: 'on'
};

export default {
  name: 'ParticipantRow',

  components: {
    MoreOptionsMenu,
    VueSlider,
    MicVolumeIndication
  },

  props: {
    participant: {
      type: Object,
      required: true
    },
    micVolume: {
      type: Number,
      default: 0
    },
    lowConnectivity: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      PARTICIPANT_STATE_TYPES,
      isRowHovered: false,
      isMicrophoneVisible: true,
      participantSubscriberAudioVolume: 100,
      volumeBarVisibilityState: volumeBarVisibilityStates.OFF,
      debounceShowAudioSlider: undefined,
      debounceAudioSliderAnalytic: undefined,
      TOGGLE_MIC_SOURCES: TOGGLE_MIC_SOURCES,
      TOGGLE_CAMERA_SOURCES: TOGGLE_CAMERA_SOURCES
    };
  },

  computed: {
    ...mapState([
      'streams',
      'contacts',
      'myParticipantId',
      'owner',
      'keepParticipantOptionsMenuShown',
      'isNoAudioMode',
      'unsubscribedVideoStreamIds',
      'isBranded',
      'pinnedStreamId'
    ]),
    ...mapState('beRightBack', ['isBeRightBackOn', 'beRightBackStatuses']),
    ...mapState('recordings', ['recordingOwner']),
    ...mapState('whitelabel', ['cssVariables']),
    ...mapState('raiseHand', ['raisedHands']),
    ...mapGetters([
      'hasMicrophoneAccess',
      'hasCameraAccess',
      'isInitializingPublisher',
      'isSessionOwner',
      'activeParticipants',
      'isMuteAllEnabled',
      'isScreenShared',
      'participantDisplayName'
    ]),
    ...mapGetters('recordings', ['isRecording']),
    ...mapGetters('settings', ['exclusiveExperimentalMode']),

    moreOptionsItemsTypes() {
      return {
        ForceMute: {
          id: 'forcemute',
          label: this.$t('participant_row.force_mute_label'),
          iconName: 'mic-mute-line'
        },
        KickOut: {
          id: 'kickout',
          label: this.$t('participant_row.kickout_label'),
          iconName: 'remove-user-line',
          isCritical: true
        },
        ToggleParticipantVideo: {
          id: 'togglevideo',
          iconName: this.isUnsubscribedToVideo ? 'video-line' : 'video-off-line'
        },
        BeRightBackStatus: { id: 'BRBStatus', iconName: 'walk-line' },
        PinParticipant: {
          id: 'pinparticipant',
          iconName: 'pin-2-line',
          label: this.$t('participant_row.pin_participant_label')
        }
      };
    },

    raisedHandTooltip() {
      if (this.isMyParticipantRow || this.isSessionOwner) {
        return {
          hideOnTargetClick: true,
          content: this.$t('raise_hand_button.tooltip')
        };
      }
      return null;
    },

    isActiveParticipant() {
      return this.participant.state === PARTICIPANT_STATE_TYPES.JOINED;
    },

    isParticipantMutedVideo() {
      return !this.participantVideoStream?.hasVideo;
    },

    isParticipantMutedAudio() {
      return !this.participantVideoStream?.hasAudio;
    },

    isParticipantSharingScreen() {
      if (this.isMyParticipantRow) {
        return this.isScreenShared;
      }
      return this.participantScreenStream?.hasVideo;
    },

    isMyParticipantRow() {
      return this.myParticipantId === this.participant.participantId;
    },

    isParticipantRaisingHand() {
      return this.participant.participantId in this.raisedHands;
    },

    participantVideoStream() {
      return this.participantStreams.find(
        (stream) => !isScreenshareStream(stream)
      );
    },

    participantScreenStream() {
      return this.participantStreams.find((stream) =>
        isScreenshareStream(stream)
      );
    },
    volumeBarClass() {
      if (this.volumeBarVisibilityState === volumeBarVisibilityStates.ON) {
        return 'participant-volume';
      } else if (
        this.volumeBarVisibilityState === volumeBarVisibilityStates.CLOSING
      ) {
        return 'faded-participant-volume';
      } else {
        return 'hidden-participant-volume';
      }
    },
    participantStreams() {
      return this.streams.filter(
        (stream) => stream.participantId === this.participant.participantId
      );
    },

    participantDisplayName() {
      if (this.participant.type === 'Pstn') {
        // search phone nubmer in contacts
        const contact = this.contacts.find(
          (contact) => contact.extensionNumber === this.participant.displayName
        );
        if (contact) {
          return contact.displayName;
        }

        // return the formatted number
        return formatPhoneDisplayInternational(this.participant.displayName);
      }
      return this.participant.displayName;
    },

    participantStateText() {
      if (
        this.participant.state === PARTICIPANT_STATE_TYPES.KICKED ||
        this.participant.state === PARTICIPANT_STATE_TYPES.ABOUT_TO_BE_KICKED
      ) {
        return this.$t('participant_state_types.removed');
      }
      return this.translateParticipantState(this.participant.state);
    },

    isParticipantPinned() {
      return this.participantVideoStream?.streamId === this.pinnedStreamId;
    },

    moreOptionsMenuLists() {
      const moreOptionsMenuItems = [];
      const criticalMenuItems = [];

      const pinOrUnpin = this.isParticipantPinned
        ? this.$t('participant_row.unpin_text')
        : this.$t('participant_row.pin_text');
      moreOptionsMenuItems.push({
        ...this.moreOptionsItemsTypes.PinParticipant,
        iconName: this.isParticipantPinned ? 'pin-2-off-line' : 'pin-2-line',
        label: this.isMyParticipantRow
          ? this.$t('participant_row.my_video_text', { pinOrUnpin })
          : this.$t('participant_row.video_text', { pinOrUnpin })
      });

      if (
        this.isSessionOwner &&
        !this.isParticipantMutedAudio &&
        this.isMuteAllEnabled &&
        !this.isMyParticipantRow
      ) {
        const firstName = extractFirstName(this.participant.displayName);
        // Mute option will be the first one in the list
        moreOptionsMenuItems.unshift({
          ...this.moreOptionsItemsTypes.ForceMute,
          label: this.$t('participant_row.mute_participant_label', {
            firstName
          })
        });
      }
      if (
        this.exclusiveExperimentalMode &&
        !this.isParticipantMutedVideo &&
        !this.isMyParticipantRow
      ) {
        moreOptionsMenuItems.push({
          ...this.moreOptionsItemsTypes.ToggleParticipantVideo,
          label: this.isUnsubscribedToVideo
            ? this.$t('participant_row.show_video_label')
            : this.$t('participant_row.stop_video_label')
        });
      }

      if (this.isMyParticipantRow) {
        moreOptionsMenuItems.push({
          ...this.moreOptionsItemsTypes.BeRightBackStatus,
          label: this.isBeRightBackOn
            ? this.$t('participant_row.im_back_label')
            : this.$t('participant_row.be_right_back_text')
        });
      }

      if (!this.isMyParticipantRow && this.isSessionOwner) {
        criticalMenuItems.push(this.moreOptionsItemsTypes.KickOut);
      }

      const menuLists = [moreOptionsMenuItems];
      if (criticalMenuItems.length > 0) {
        menuLists.push(criticalMenuItems);
      }

      return menuLists;
    },

    isUnsubscribedToVideo() {
      return this.unsubscribedVideoStreamIds[
        this.participantVideoStream?.streamId
      ];
    },

    isBeRightBackOnStatus() {
      const connectionId = this.participantStreams[0]?.connectionId;
      return connectionId in this.beRightBackStatuses;
    },

    isRecordingOwner() {
      return (
        this.isRecording &&
        this.participant.participantId === this.recordingOwner
      );
    },

    micIndicationFillColor() {
      return this.isMyParticipantRow ? '#30a849' : '#666';
    }
  },

  mounted() {
    this.debounceShowAudioSlider = debounce(() => {
      this.showAudioLevel();
    }, 200);

    this.debounceAudioSliderAnalytic = debounce((level) => {
      this.didChangeParticipantVolume(level);
    }, 1000);
  },

  methods: {
    ...mapActions([
      'toggleMic',
      'toggleVideo',
      'setParticipantToRemove',
      'forceMuteParticipant',
      'setSubscriberAudioVolume',
      'toggleUnsubscribedVideoStream',
      'pinVideoStream'
    ]),
    ...mapActions('beRightBack', ['toggleBeRightBackStatus']),
    ...mapActions('raiseHand', ['setIsRaisingHand']),

    translateParticipantState(participantState) {
      switch (participantState) {
        case PARTICIPANT_STATE_TYPES.JOINED:
          return this.$t('participant_state_types.joined');
        case PARTICIPANT_STATE_TYPES.JOINING:
          return this.$t('participant_state_types.joining');
        case PARTICIPANT_STATE_TYPES.LEFT:
          return this.$t('participant_state_types.left');
        case PARTICIPANT_STATE_TYPES.KICKED:
          return this.$t('participant_state_types.kicked');
        case PARTICIPANT_STATE_TYPES.ABOUT_TO_BE_KICKED:
          return this.$t('participant_state_types.about_to_be_kicked');
        case PARTICIPANT_STATE_TYPES.DECLINED:
          return this.$t('participant_state_types.declined');
        case PARTICIPANT_STATE_TYPES.INVITED:
          return this.$t('participant_state_types.invited');
        default:
          return '';
      }
    },

    async dropdownOptionSelected(selected) {
      if (selected.id === this.moreOptionsItemsTypes.KickOut.id) {
        this.setParticipantToRemove({
          participantId: this.participant.participantId,
          participantDisplayName: this.participantDisplayName
        });

        analytics.trackEvent(ANALYTICS.KICKED_OUT_PARTICIPANT, {
          Source: 'Participant Row',
          'Num of Participants': this.activeParticipants.length
        });
      } else if (selected.id === this.moreOptionsItemsTypes.ForceMute.id) {
        this.forceMuteParticipant({
          streamId: this.participantVideoStream?.streamId,
          participantDisplayName: this.participantDisplayName
        });

        analytics.trackEvent(ANALYTICS.OWNER_MUTE, {
          Type: 'Participant'
        });
      } else if (
        selected.id === this.moreOptionsItemsTypes.ToggleParticipantVideo.id &&
        this.participantVideoStream?.streamId
      ) {
        await this.toggleUnsubscribedVideoStream(
          this.participantVideoStream.streamId
        );
        if (this.isUnsubscribedToVideo) {
          analytics.trackEvent(ANALYTICS.TURN_OFF_INCOMING_VIDEO);
        }
      } else if (
        selected.id === this.moreOptionsItemsTypes.BeRightBackStatus.id
      ) {
        this.toggleBeRightBackStatus();

        if (this.isBeRightBackOn) {
          analytics.trackEvent(ANALYTICS.BE_RIGHT_BACK_STATUS);

          if (!this.isParticipantMutedAudio) {
            this.toggleMic();
          }
          if (!this.isParticipantMutedVideo) {
            this.toggleVideo();
          }
        } else {
          analytics.trackEvent(ANALYTICS.I_AM_BACK_STATUS);
        }
      } else if (selected.id === this.moreOptionsItemsTypes.PinParticipant.id) {
        if (this.isParticipantPinned) {
          this.pinVideoStream(null); // unpin
        } else {
          this.pinVideoStream(this.participantVideoStream?.streamId);
        }
      }
    },

    hideVolumeBarSlider() {
      this.volumeBarVisibilityState = volumeBarVisibilityStates.CLOSING;
    },

    showVolumeBarSlider() {
      this.debounceShowAudioSlider();
    },
    showAudioLevel() {
      if (this.isRowHovered) {
        this.volumeBarVisibilityState = volumeBarVisibilityStates.ON;
      }
    },
    didChangeParticipantVolume(level) {
      if (level === this.participantSubscriberAudioVolume) {
        analytics.trackEvent(ANALYTICS.VOLUME_BAR);
      }
    },
    microphoneVisibilityChanged(isMicrophoneVisible) {
      this.isMicrophoneVisible = isMicrophoneVisible;
    },

    setParticipantSubscriberAudioVolume(level) {
      const streamId = this.participantVideoStream?.streamId;
      if (streamId) {
        this.setSubscriberAudioVolume({ streamId, level });
        this.debounceAudioSliderAnalytic(level);
      }
    },

    toggleRaiseHand(participantId) {
      if (!this.isMyParticipantRow && !this.isSessionOwner) {
        return;
      }
      this.setIsRaisingHand({
        isRaisingHand: false,
        participantId
      });
    }
  }
};
</script>

<style scoped>
.participant-row {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 16px 0 8px 0;
}

.participant-row.my-participant-row {
  padding: 0 0 8px 0;
}

.participant-info {
  display: flex;
  align-items: center;
  display: -ms-flexbox;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1;
  width: 100%;
  min-width: 0;
}

.participant-volume {
  display: flex;
  min-width: 0;
  flex-direction: column;
  width: 94%;
  -moz-animation: flickerAnimation 0.3s linear;
  -o-animation: flickerAnimation 0.3s linear;
  animation: flickerAnimation 0.3s linear;
}
.faded-participant-volume {
  height: 0;
  opacity: 0;
  width: 94%;
  animation: flickerDoneAnimation 0.3s linear;
  -moz-animation: flickerDoneAnimation 0.3s linear;
  -o-animation: flickerDoneAnimation 0.3s linear;
}
@keyframes flickerDoneAnimation {
  0% {
    height: 18px;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}

@keyframes flickerAnimation {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 18px;
    opacity: 1;
  }
}
@-o-keyframes flickerAnimation {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 18px;
    opacity: 1;
  }
}
@-moz-keyframes flickerAnimation {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 18px;
    opacity: 1;
  }
}
@-webkit-keyframes flickerAnimation {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 18px;
    opacity: 1;
  }
}

.participant-data {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 10px;
  min-width: 0;
}

.participant-name-row {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
}

.participant-data .participant-name {
  font-size: 14px;
  color: #2c2d30;
  width: 100%;
}

.my-participant-row .participant-data .participant-name {
  font-weight: 600;
}

.participant-data .more-options-menu {
  margin-left: 7px;
}

.participant-data .more-options-menu >>> .v-icon {
  margin-top: 0;
  width: 20px;
  height: 20px;
}

.hidden-participant-volume {
  height: 0;
  opacity: 0;
}

.participant-data .participant-state {
  font-size: 12px;
}

.info-icons {
  display: flex;
  align-items: center;
}

.info-icons >>> vwc-icon {
  margin: 8px;
}

vwc-icon.participant-row-icon,
vwc-icon-button.participant-row-icon {
  --vvd-color-cta: var(--participant-row-icon-color);
  --vvd-color-cta-70: var(--participant-row-icon-color);
}

.participant-row:not(.my-participant-row)
  .info-icons
  > :not(.more-menu-container) {
  --vvd-color-primary: #666;
}

.subscriber-reconnecting-icon {
  fill: #ffc100;
  outline: none;
}

.mic-wrapper {
  position: relative;
  height: 32px;
  width: 32px;
  contain: strict;
}

.mic-wrapper .mic-icon {
  margin: 8px;
}

.recording {
  left: 2px;
}

.participant-row >>> .vue-slider-mark-label {
  margin-top: 0px;
}

.volume-bar {
  margin-top: 10px;
  margin-bottom: 10px;
}

.volume-indication {
  left: 11.5px;
  width: 9px;
  bottom: 13.5px;
}

.raise-hand {
  height: 18px;
  width: 18px;
  padding-top: 3px;
  padding-right: 3px;
}

.removable-hand {
  cursor: pointer;
  height: 24px;
  width: 24px;
  border-radius: 6px;
  padding: 4px;
  text-align: center;
}

.removable-hand .raise-hand {
  padding-top: 3px;
  padding-left: 5px;
}

.removable-hand:hover {
  background-color: #f5f5f5;
}

.more-menu-container {
  position: relative;
}

.lists-divider {
  border-top: 1px solid #ccc;
  margin: 10px 12px;
}
</style>
