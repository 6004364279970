/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-paragraph': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.25 0a.75.75 0 00-.75.75v22.5c0 .414.336.75.75.75h19.5a.75.75 0 00.75-.75V.75a.75.75 0 00-.75-.75H2.25zM3 22.5v-21h18v21H3zm11.25-18a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3zm-.75 5.25a.75.75 0 01.75-.75h3a.75.75 0 010 1.5h-3a.75.75 0 01-.75-.75zM6.75 13.5a.75.75 0 000 1.5h10.5a.75.75 0 000-1.5H6.75zM6 18.75a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM6.75 4.5a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75h-4.5zM7.5 9V6h3v3h-3z"/>'
  }
})
