/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-voicemail-transcript': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.879 2.379A3 3 0 013 1.5h18a3 3 0 013 3v12a3 3 0 01-3 3h-4.899l-3.525 4.23a.75.75 0 01-1.152 0L7.899 19.5H3a3 3 0 01-3-3v-12a3 3 0 01.879-2.121zM3 3a1.5 1.5 0 00-1.5 1.5v12A1.5 1.5 0 003 18h5.25a.75.75 0 01.576.27L12 22.078l3.174-3.808a.75.75 0 01.576-.27H21a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0021 3H3zm2.25 4.5a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5a.75.75 0 01.75-.75zm5.25-.75a.75.75 0 00-1.5 0v7.5a.75.75 0 001.5 0v-7.5zM14.25 9a.75.75 0 01.75.75v1.5a.75.75 0 01-1.5 0v-1.5a.75.75 0 01.75-.75zm5.25-.75a.75.75 0 00-1.5 0v4.5a.75.75 0 001.5 0v-4.5z"/>'
  }
})
