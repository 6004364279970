/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-drupal-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.364 4.473c-1.31-.764-2.51-1.091-3.71-1.855C12.892 2.182 11.91.982 11.037 0c-.218 1.636-.654 2.29-1.309 2.727-1.2.873-1.963 1.2-3.054 1.746C5.8 4.909 1 7.636 1 13.636S6.236 24 12.018 24c5.782 0 10.8-4.036 10.8-10.146 0-6.109-4.69-8.945-5.454-9.381zm.109 17.345c-.11.11-1.2.873-2.51.982-1.308.11-3.054.218-4.145-.764-.218-.109-.109-.436 0-.436.11-.11.218-.11.328-.11h.218c.436.328 1.2.655 2.727.655s2.618-.436 3.054-.763c.219-.11.328 0 .328.109.109 0 .218.218 0 .327zm-4.146-2.073c.219-.218.655-.654 1.091-.763.437-.11.655-.11.982-.11.436 0 .764 0 1.09.219.328.218.437.654.546.873.11.218 0 .327-.218.436-.218.11-.218 0-.327-.218-.218-.327-.327-.655-1.2-.655s-1.091.328-1.527.655c-.437.327-.546.436-.655.218-.109-.218-.109-.436.218-.654zm6.327.11c-.872-.11-2.618-2.619-3.709-2.728-1.418 0-4.472 2.837-6.763 2.837-1.418 0-1.855-.218-2.4-.546-.764-.436-1.091-1.2-1.091-2.29 0-1.855 1.745-3.492 4.036-3.492 2.837 0 4.8 2.728 6.218 2.619 1.2 0 3.491-2.291 4.691-2.291 1.2 0 1.528 1.2 1.528 1.963 0 .764-.219 1.964-.764 2.837-.764.872-1.09 1.2-1.745 1.09z" _fill="#0073BA"/>'
  }
})
