/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M5.5 8C7.4 8 9 6.4 9 4.5S7.4 1 5.5 1 2 2.6 2 4.5 3.6 8 5.5 8zm0-6C6.9 2 8 3.1 8 4.5S6.9 7 5.5 7 3 5.9 3 4.5 4.1 2 5.5 2zM12.5 7C13.9 7 15 5.9 15 4.5S13.9 2 12.5 2 10 3.1 10 4.5 11.1 7 12.5 7zm0-4c.8 0 1.5.7 1.5 1.5S13.3 6 12.5 6 11 5.3 11 4.5 11.7 3 12.5 3zM5.5 9c-1.5 0-2.9.6-3.9 1.6S0 13 0 14.5c0 .1.1.3.1.4.1 0 .3.1.4.1h10c.1 0 .3-.1.4-.1s.1-.2.1-.4c0-1.5-.6-2.9-1.6-3.9S7 9 5.5 9zM1 14c.1-1 .6-2 1.3-2.7.8-.8 2-1.3 3.2-1.3s2.3.5 3.2 1.3C9.4 12 9.9 13 10 14H1zM15 9c-.7-.7-1.5-1-2.5-1-.7 0-1.4.2-1.9.6-.2.2-.3.5-.1.7.2.2.5.3.7.1.3-.3.8-.4 1.3-.4.7 0 1.3.3 1.8.7.5.5.7 1.1.7 1.8v.5h-2.5c-.3 0-.5.2-.5.5s.2.5.5.5h3c.3 0 .5-.2.5-.5v-1c0-.9-.4-1.8-1-2.5z"/>'
  }
})
