<template>
  <div
    class="volume-container"
    @mouseenter="isMouseOver = true"
    @mouseleave="isMouseOver = false"
  >
    <div class="playback-control" @click="$emit('toggleMute')">
      <v-icon :iconName="volumeIcon" />
    </div>

    <transition name="expand">
      <WatchTogetherSlider
        v-show="isMouseOver || dragging"
        primaryColor="white"
        class="slider"
        collapsible
        :expandedWidth="50"
        :thumbPadding="3"
        :thumbSize="12"
        :barHeight="3"
        :max="100"
        :value="volume"
        @dragstart="sliderDragStarted"
        @dragging="volumeSliderChangedThrottled"
        @dragend="sliderDragEnded"
      />
    </transition>
  </div>
</template>

<script>
import WatchTogetherSlider from './WatchTogetherSlider';
import throttle from 'lodash.throttle';

export default {
  name: 'WatchTogetherVolumeSlider',

  components: {
    WatchTogetherSlider
  },

  props: {
    volume: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      isMouseOver: false,
      dragging: false,
      currentVolume: 0, // represents the exact value of the volume that appears on the slider's UI
      volumeSliderChangedThrottled: throttle(this.volumeSliderChanged, 200, {
        leading: false
      })
    };
  },

  computed: {
    volumeIcon() {
      if (this.currentVolume === 0) {
        return 'Vlt-icon-audio-mute-full';
      } else if (this.currentVolume > 0 && this.currentVolume < 50) {
        return 'Vlt-icon-audio-min-full';
      } else {
        return 'Vlt-icon-audio-mid-full';
      }
    }
  },

  watch: {
    volume() {
      if (this.dragging) {
        return;
      }

      // Track the volume prop
      this.currentVolume = this.volume;
    }
  },

  methods: {
    sliderDragStarted() {
      this.dragging = true;
    },

    sliderDragEnded(volume) {
      this.dragging = false;
      this.currentVolume = volume;
      this.$emit('volumeChanged', volume);
    },

    volumeSliderChanged(volume) {
      this.currentVolume = volume;
      this.$emit('volumeChanged', volume);
    }
  }
};
</script>

<style scoped>
.volume-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.slider {
  width: 50px;
  margin-left: 5px;
}

.expand-enter-active {
  transition: width 0.1s ease-out;
}

.expand-leave-active {
  transition: width 0.2s ease-in;
}

.expand-enter,
.expand-leave-to {
  width: 0;
}
</style>
