/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-magic-wand-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 0l1.5 3L3 6l3-1.5L9 6 7.5 3 9 0 6 1.5 3 0zm15 6l3-1.5L24 6l-1.5-3L24 0l-3 1.5L18 0l1.5 3L18 6zm0 15l1.5-3-1.5-3 3 1.5 3-1.5-1.5 3 1.5 3-3-1.5-3 1.5zM13.409 7.408l-3.97 3.97 3.182 3.182 3.97-3.97-3.182-3.182zM0 20.818l8.38-8.38 3.181 3.183L3.183 24 .001 20.818z"/>'
  }
})
