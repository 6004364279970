/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-bookmark': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4 0c-.7 0-1.3.3-1.8.7-.4.5-.7 1.1-.7 1.8v13c0 .2.1.3.2.4.1.1.3.1.5 0L8 13.1l5.8 2.9c.2.1.3.1.5 0s.2-.3.2-.4v-13c0-.7-.3-1.3-.7-1.8C13.3.3 12.7 0 12 0H4zM2.9 1.4c.3-.2.7-.4 1.1-.4h8c.4 0 .8.2 1.1.4.3.3.4.7.4 1.1v12.2l-5.3-2.6c-.1-.1-.3-.1-.4 0l-5.3 2.6V2.5c0-.4.2-.8.4-1.1z"/>'
  }
})
