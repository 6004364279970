/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-open-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 0h9v9l-3.44-3.44-9.406 9.407-2.121-2.121 9.406-9.407L15 0zM0 22.5A1.5 1.5 0 001.5 24h18a1.5 1.5 0 001.5-1.5v-9h-3V21H3V6h7.5V3h-9A1.5 1.5 0 000 4.5v18z"/>'
  }
})
