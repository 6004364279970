/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cursor-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.7 16.7l4.4 4.4c.3.3.7.3.9 0l2.8-2.9c.2-.3.2-.7 0-.9l-4.4-4.4 4.9-3.5c.4-.3.3-1-.2-1.1l-18-5.8c-.5-.2-1 .3-.8.8l5.8 18c.2.5.8.6 1.1.2l3.5-4.8z"/>'
  }
})
