import { compareVersions, isElectron } from '@/helpers/global-helpers';

export const versionSteps = []
  .filter((tour) => {
    if (tour.nativeVersion) {
      return (
        isElectron() &&
        compareVersions(window.Electron.version, tour.nativeVersion) >= 0
      );
    }
    return true;
  })
  .sort((tour1, tour2) => compareVersions(tour1.version, tour2.version));
