import { KEYBOARD_CODES, MODIFIERS } from './keycodes';
import { OS_TYPES } from '@/consts/global-consts';
import { OS, isContentEditable } from '@/helpers/global-helpers';

const isWindows = OS.name === OS_TYPES.WINDOWS;

// takes a dictionary of keys and converts it into an array of keycodes.
// example: { enter: true, esc: true, nobutton: true } => [enter, esc]
function keysListify(keysDict) {
  return Object.keys(keysDict)
    .map((modifier) => KEYBOARD_CODES[modifier]) // cast modifier from name to keycode
    .filter((modifier) => modifier); // filter undefined values from the list
}

// takes a dictionary of keys and returns a set of modifiers.
function getModifiers(keysDict) {
  return MODIFIERS.reduce((modifiers, modifier) => {
    keysDict[modifier] && modifiers.add(modifier);
    return modifiers;
  }, new Set());
}

/*
**value:
handler function
**modifiers:
l2: for layer 2 modals (while l2 is bound, ignores l1 layer handlers)
stop: dont propagate if an input is highlighted
*/
export default {
  bind(el, binding) {
    const keysList = keysListify(binding.modifiers);
    const modifierKeys = getModifiers(binding.modifiers);

    // return if no keys were passed
    if (!keysList.length) {
      return;
    }

    let addListener = false;
    if (!el._keyboardHandlers) {
      el._keyboardHandlers = {};
      document._l2Counter = 0;
      addListener = true;
    }

    keysList.forEach((keyCode) => {
      el._keyboardHandlers[keyCode] = {
        execute: binding.value,
        modifiers: modifierKeys
      };
    });

    if (binding.modifiers.l2) {
      document._l2Counter++;
    }

    if (addListener) {
      // add keydown listener if it is the first added listener of the element
      el._keyboardHandlerDict = (e) => {
        //  the notext modifier checks if the element is a content editable field. If it is, the handler is not executed.
        if (binding.modifiers.notext) {
          if (isContentEditable()) return;
        }

        // stops propagation if a modal is opened
        if (document._l2Counter && !binding.modifiers.l2) {
          return;
        }

        if (el._keyboardHandlers[e.keyCode]) {
          // validate correct modifier keys
          const modifiers = el._keyboardHandlers[e.keyCode].modifiers;
          if (
            (isWindows
              ? modifiers.has('cmd') !== e.ctrlKey
              : modifiers.has('cmd') !== e.metaKey) ||
            modifiers.has('shift') !== e.shiftKey
          ) {
            return;
          }

          el._keyboardHandlers[e.keyCode].execute(e.keyCode);
          if (modifiers.has('prevent')) {
            // if the prevent modifier is present, we call e.preventDefault() to prevent the default behavior of the event -
            // for an example - behavior that was defined by the browser.
            e.preventDefault();
            e.stopPropagation();
          }
        }
      };
      // if there are no remaining key listeners defined for the element, it removes the keydown event listener.
      window.addEventListener('keydown', el._keyboardHandlerDict);
    }
  },

  unbind(el, binding) {
    // delete the key handlers for each keycode from the _keyboardHandlers object.
    keysListify(binding.modifiers).forEach((keyCode) => {
      delete el._keyboardHandlers[keyCode];
    });

    if (binding.modifiers.l2) {
      document._l2Counter--;
    }

    // remove keydown listener if no key listeners remain
    if (!Object.keys(el._keyboardHandlers).length) {
      window.removeEventListener('keydown', el._keyboardHandlerDict);
    }
  }
};
