/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-error': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 18c-.9 0-1.5-.6-1.5-1.5S11.1 15 12 15s1.5.6 1.5 1.5S12.9 18 12 18zm1.5-4.5h-3V6h3v7.5z"/>'
  }
})
