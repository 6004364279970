/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-volume': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M12 1.5c0-.2-.1-.4-.3-.4-.2-.1-.4-.1-.5 0L5.3 5H.5c-.3 0-.5.2-.5.5v6c0 .3.2.5.5.5h4.8l5.9 3.9c.2.1.4.1.5 0 .2-.1.3-.3.3-.4v-14zM5.8 5.9L11 2.4v12.1L5.8 11H1V6h4.5c.1 0 .2 0 .3-.1zm8.5-1.3c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7.8.8 1.3 2 1.3 3.2 0 1.2-.5 2.3-1.3 3.1-.2.2-.2.5 0 .7s.5.2.7 0c1-1 1.6-2.4 1.6-3.9.1-1.4-.5-2.8-1.6-3.8z"/>'
  }
})
