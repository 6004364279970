/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mailbox': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.75 0A.75.75 0 003 .75V7.5a.75.75 0 001.5 0v-6h15v6a.75.75 0 001.5 0V.75a.75.75 0 00-.75-.75H3.75zM1.103 9.088A.75.75 0 000 9.75v12A2.25 2.25 0 002.25 24h19.5A2.25 2.25 0 0024 21.75v-12a.75.75 0 00-1.103-.662L12 14.9 1.103 9.088zM1.5 21.75V11l10.147 5.412a.75.75 0 00.706 0L22.5 11v10.75a.75.75 0 01-.75.75H2.25a.75.75 0 01-.75-.75zm6-16.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM8.25 9a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5h-7.5z"/>'
  }
})
