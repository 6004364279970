/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-separator': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1 1.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-4zM2 2v3h12V2H2zM0 8.5A.5.5 0 01.5 8h15a.5.5 0 010 1H.5a.5.5 0 01-.5-.5zM1.5 11a.5.5 0 00-.5.5v4a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-4a.5.5 0 00-.5-.5h-13zm.5 4v-3h12v3H2z"/>'
  }
})
