/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-clipboard-add': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M5.5 0a.5.5 0 00-.5.5v2a.5.5 0 00.5.5h6a.5.5 0 00.5-.5v-2a.5.5 0 00-.5-.5h-6zM11 2H6V1h5v1zm-8.854.146A.5.5 0 012.5 2h1a.5.5 0 000-1h-1A1.5 1.5 0 001 2.5v12A1.5 1.5 0 002.5 16h12a1.5 1.5 0 001.5-1.5v-12A1.5 1.5 0 0014.5 1h-1a.5.5 0 000 1h1a.5.5 0 01.5.5v12a.5.5 0 01-.5.5h-12a.5.5 0 01-.5-.5v-12a.5.5 0 01.146-.354zM8.5 6a.5.5 0 01.5.5V9h2.5a.5.5 0 010 1H9v2.5a.5.5 0 01-1 0V10H5.5a.5.5 0 010-1H8V6.5a.5.5 0 01.5-.5z"/>'
  }
})
