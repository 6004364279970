/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-enlarge-screen': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.5 2.25a.75.75 0 01.75-.75h19.5a.75.75 0 01.75.75v19.5a.75.75 0 01-.75.75H10.5v-8.25a.75.75 0 00-.75-.75H1.5V2.25zm-1.5 12v-12A2.25 2.25 0 012.25 0h19.5A2.25 2.25 0 0124 2.25v19.5A2.25 2.25 0 0121.75 24H2.25A2.25 2.25 0 010 21.75v-7.5zm9 8.25H2.25a.75.75 0 01-.75-.75V15H9v7.5zM12.75 6a.75.75 0 010-1.5h6a.748.748 0 01.75.75v6a.75.75 0 01-1.5 0V7.06l-4.72 4.72a.75.75 0 11-1.06-1.06L16.94 6h-4.19z"/>'
  }
})
