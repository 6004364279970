/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-transfer-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 7.5c-7.8 0-12 5.7-12 15 2.25-3.6 4.05-6 12-6v6L24 12 12 1.5v6z"/>'
  }
})
