var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CounterBadge", {
    staticClass: "chat-notification",
    attrs: { count: _vm.unreadMessagesCount },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }