/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-chevron-up': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.3 5.1c-.2-.1-.4-.1-.6 0l-7.5 5c-.2.2-.3.5-.1.7.2.2.5.3.7.1L8 6.1l7.2 4.8c.2.2.5.1.7-.1.2-.2.1-.5-.1-.7l-7.5-5z"/>'
  }
})
