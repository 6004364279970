import chroma from 'chroma-js';

export default {
  cssVariables: (state) => {
    return state.cssVariables;
  },

  mainColorBright: (state) => {
    return chroma(state.mainColor).brighten();
  },

  mainColorDark: (state) => {
    return chroma(state.mainColor).darken();
  }
};
