/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-check-double-bold': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M4.94 19.06A1.5 1.5 0 006 19.5h.15a1.5 1.5 0 001.098-.667l9-13.5a1.5 1.5 0 10-2.496-1.665L5.767 15.647 2.56 12.44a1.501 1.501 0 10-2.12 2.12l4.5 4.5zM22.791 3.03a1.5 1.5 0 01.958 2.304l-9 13.5a1.5 1.5 0 01-1.1.66c-.052.007-.1.007-.15.007a1.5 1.5 0 01-1.06-.435l-2.124-2.124 1.696-2.55 1.256 1.256 7.984-11.98a1.5 1.5 0 011.54-.638z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
