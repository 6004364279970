/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-enlarge-screen-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 0h21A1.5 1.5 0 0124 1.5v21a1.5 1.5 0 01-1.5 1.5h-21A1.5 1.5 0 010 22.5v-21A1.5 1.5 0 011.5 0zM9 21h12V3H3v12h4.5A1.5 1.5 0 019 16.5V21zm1.5-15H18v7.5l-2.69-2.69-3.75 3.75-2.12-2.12 3.75-3.75L10.5 6z"/>'
  }
})
