/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-python-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M23.6 9.122c-.439-1.649-1.208-2.968-2.857-2.968h-2.088v2.528c0 1.978-1.758 3.517-3.626 3.517H9.094c-1.538 0-2.857 1.428-2.857 3.077v5.495c0 1.538 1.319 2.527 2.857 2.967 1.868.55 3.517.66 5.715 0 1.429-.44 2.857-1.319 2.857-2.967v-2.198h-5.715v-.77h8.682c1.649 0 2.308-1.099 2.858-2.857.66-1.868.66-3.517.11-5.824zm-8.352 10.99c.55 0 1.1.439 1.1 1.098 0 .66-.44 1.1-1.1 1.1-.55 0-1.099-.44-1.099-1.1 0-.659.55-1.099 1.1-1.099z" _fill="#FFD845"/><path pid="1" d="M14.919.22C13.929.11 12.83 0 11.84 0c-.989 0-1.868.11-2.747.22-2.418.44-2.747 1.319-2.747 2.967v2.088h5.714v.77H4.04c-1.649 0-3.187.988-3.627 2.967-.55 2.198-.55 3.516 0 5.824.44 1.759 1.429 2.858 3.077 2.858h1.978v-2.528c0-1.868 1.649-3.517 3.627-3.517h5.825c1.648 0 2.857-1.319 2.857-2.967V3.187c0-1.539-1.319-2.747-2.857-2.967zM8.764 3.956c-.55 0-1.099-.44-1.099-1.099s.44-1.099 1.1-1.099c.659 0 1.098.44 1.098 1.1 0 .659-.55 1.098-1.099 1.098z" _fill="#3979B0"/></g><defs><clipPath id="clip0"><path pid="2" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
