/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-profile': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 15c-1.7 0-3.3-.6-4.6-1.7l1.9-.9c.5-.2.9-.4 1.2-.8.2-.2.3-.5.4-.7.3 0 .7.1 1.1.1.4 0 .8-.1 1.1-.2.1.3.2.5.4.7.3.4.7.6 1.1.9l1.9.9C11.3 14.4 9.7 15 8 15zM5 7c0-1.7 1.3-3 3-3s3 1.3 3 3-1.3 3-3 3c-.5 0-.9-.1-1.3-.3C5.7 9.2 5 8.2 5 7zm8.3 5.6l-2.2-1.1c-.4-.2-.7-.4-.8-.6-.1-.1-.2-.3-.2-.5 1.2-.7 2-2 2-3.4 0-2.2-1.8-4-4-4C5.8 3 4 4.8 4 7c0 1.5.8 2.7 2 3.4 0 .2-.1.4-.3.5-.2.2-.4.4-.7.5h-.1l-2.2 1.1C1.6 11.3 1 9.7 1 8c0-3.9 3.1-7 7-7s7 3.1 7 7c0 1.7-.6 3.3-1.7 4.6z"/>'
  }
})
