var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contacts-panel" },
    [
      _c(
        "div",
        { staticClass: "input-container" },
        [
          _c("InputTag", {
            ref: "contactsInputTag",
            attrs: {
              tags: _vm.tags,
              focus: "",
              placeholder: _vm.$t("contacts.input_tag_placeholder"),
            },
            on: { remove: _vm.removeTag, filter: _vm.filter },
          }),
          _c("vwc-icon-button", {
            staticClass: "refresh-contacts-icon",
            attrs: { icon: "refresh-solid" },
            on: { click: _vm.reloadContacts },
          }),
        ],
        1
      ),
      _vm.filteredContacts && _vm.filteredContacts.length > 0
        ? _c("ContactsList", {
            staticClass: "contacts-list",
            attrs: { contacts: _vm.filteredContacts },
            on: { contactClick: _vm.toggleContactToInvite },
          })
        : _vm.reloadInProgress
        ? _c("vwc-circular-progress", {
            staticClass: "loading",
            attrs: { connotation: "primary", indeterminate: "" },
          })
        : _c(
            "div",
            { staticClass: "empty-contacts-wrapper" },
            [
              _c(
                "vwc-empty-state",
                { attrs: { heading: _vm.$t("contacts.empty_contacts_text") } },
                [
                  _c("img", {
                    attrs: {
                      slot: "graphic",
                      src: "/illustrations/empty-state/empty-search.svg",
                    },
                    slot: "graphic",
                  }),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }