/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-phone-number': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.717 3.429l-.156.935c0 .155-.156.467-.312.623-.156.156-.311.156-.623.156h-.78L19.38 7.48h1.247c.156 0 .467 0 .467.155v.624l-.155.623h-1.87l-.624 3.429h-1.247s-.155 0-.155-.156c-.156-.156-.156-.156-.156-.312v-.311l.467-2.65H15.64l-.468 2.65c0 .311-.155.467-.311.623-.156.156-.468.156-.624.156h-.935l.624-3.585h-1.091c-.156 0-.312 0-.468-.156v-.623l.156-.623h1.559l.467-2.338h-1.87l.156-.935c.078-.078.117-.156.156-.234a.814.814 0 01.155-.234c.156-.155.312-.155.624-.155h.935l.623-2.65c0-.311.156-.467.312-.623.156-.156.467-.156.623-.156h.935l-.623 3.429h1.714L18.912 0h.935c.155 0 .467 0 .467.156.156.156.156.312.156.467l-.467 2.806h1.714zm-3.74 1.558h-1.715l-.467 2.338h1.714l.468-2.338zM6.444 3.584c-.156-.155-.467-.467-.935-.467h-.311l-.11.034c-.683.207-3.116.942-2.384 4.018.623 3.428 2.961 6.701 5.766 9.662 3.117 3.273 7.325 5.61 9.818 5.61h.468c2.338-.467 3.273-3.272 2.494-3.895-2.182-1.715-3.273-2.338-4.364-2.338h-.468c-1.246.156-1.714 1.402-1.714 1.402s-2.182-.467-4.52-2.96c-2.649-2.65-2.96-4.52-2.96-4.52.935-.624 1.402-1.247 1.402-2.338 0-1.09-1.714-3.584-2.182-4.208zM4.886 1.558h.623c.935 0 1.715.468 2.182 1.091 1.091 1.559 2.494 3.585 2.494 4.987.155 1.403-.468 2.338-1.091 2.961.311.78.935 1.715 2.181 2.961.955 1.091 1.91 1.705 2.447 2.05l.203.132c.467-.467 1.09-.935 2.026-1.09h.779c1.558 0 2.961.779 5.299 2.649.779.623 1.09 1.714.779 2.96-.468 1.715-1.87 3.43-4.052 3.741h-.623c-2.961 0-7.637-2.65-10.91-6.078-3.428-3.74-5.454-7.169-6.077-10.441-.624-2.962.779-5.143 3.74-5.923z"/>'
  }
})
