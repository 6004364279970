/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-download-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.5.5V11h3.6L12 17l-5.1-6h3.6V.5h3zM1.5 23h21c.9 0 1.5-.6 1.5-1.5V8c0-.9-.6-1.5-1.5-1.5H18v3h3V20H3V9.5h3v-3H1.5C.6 6.5 0 7.1 0 8v13.5c0 .9.6 1.5 1.5 1.5z"/>'
  }
})
