<template>
  <div
    data-cy="transcription-row"
    class="transcription-row"
    :class="{ 'system-message': isSystemMessageBlock }"
  >
    <div v-if="!transcript.hideHeader" class="transcript-header">
      <span class="participant-name">{{
        transcript.participantDisplayName
      }}</span>
      <span class="transcript-time">{{ transcript.formattedTime }}</span>
    </div>
    <div class="transcript-text" :style="transcript.textStyle">
      <span
        v-for="(sentence, index) in transcript.texts"
        :key="index"
        :class="{ 'in-progress': !sentence.isComplete }"
      >
        {{ sentence.text }}
      </span>
    </div>
  </div>
</template>

<script>
import { CAPTION_MESSAGE_TYPE } from '@/store/captions/consts';

export default {
  name: 'TranscriptionBlockRow',

  props: {
    transcript: {
      type: Object,
      required: true
    }
  },

  computed: {
    isSystemMessageBlock() {
      return this.transcript.type === CAPTION_MESSAGE_TYPE.SYSTEM;
    }
  }
};
</script>

<style scoped>
.transcription-row.system-message {
  display: flex;
  justify-content: center;
}
.transcript-header {
  display: flex;
  font-size: 12px;
  margin-bottom: 4px;
}

.transcript-header .participant-name {
  font-weight: bold;
  color: #000;
}

.transcript-header .transcript-time {
  margin-left: 8px;
  color: #666;
}

.transcript-text {
  font-size: 14px;
}

.transcript-text .in-progress {
  color: #888888;
}
</style>
