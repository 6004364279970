<template>
  <Dialog
    data-cy="lock-meeting-modal"
    class="dialog"
    :heading="$t('lock_meeting_modal.title')"
    icon-name="lock-line"
    :close="hide"
    :min-width="minWidth"
    :primary-action="$t('lock_meeting_modal.ok_button')"
    :open="isLockMeetingDialogVisible"
    isSmallModal
    @applied="lockMeetingAndHideModal"
    @click.stop
  >
    <div slot="content" class="content">
      <div class="text">
        {{ $t('lock_meeting_modal.text') }}
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Dialog from '@/components/Dialog';

export default {
  name: 'LockMeetingModal',

  components: { Dialog },

  computed: {
    ...mapGetters(['isMobileWebMode']),
    ...mapState(['isLockMeetingDialogVisible']),

    minWidth() {
      return this.isMobileWebMode ? 330 : 500;
    }
  },

  methods: {
    ...mapActions(['setIsLockMeetingDialogVisible', 'setLockMeeting']),

    hide() {
      this.setIsLockMeetingDialogVisible(false);
    },

    lockMeeting() {
      this.setLockMeeting(true);
    },

    lockMeetingAndHideModal() {
      this.lockMeeting();
      this.hide();
    }
  }
};
</script>

<style scoped></style>
