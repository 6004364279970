/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-audio-off-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.912 3.094a.5.5 0 00-.827-.378l-5.72 4.95H2.183C.977 7.667 0 8.662 0 9.89v4.222c0 1.228.977 2.222 2.183 2.222h2.182l5.72 4.951a.5.5 0 00.827-.378V3.094z"/>'
  }
})
