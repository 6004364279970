/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-sort-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 5.777L10.414.564A2.04 2.04 0 0111.828 0c.53 0 1.039.203 1.414.564l5.414 5.213L15.828 8.5l-4-3.852-4 3.852L5 5.777zm2.828 9.394l4 3.852 4-3.852 2.828 2.723-5.414 5.214a2.04 2.04 0 01-1.414.563 2.04 2.04 0 01-1.414-.563L5 17.894l2.828-2.723z"/>'
  }
})
