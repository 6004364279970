/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-edit': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.9 4.1l-4-4c-.2-.2-.5-.2-.7 0l-10 10c-.1.1-.2.2-.2.3l-1 5c0 .2 0 .3.1.5.2.1.3.1.5.1l5-1c.1 0 .2-.1.3-.1l10-10c.1-.2.1-.6 0-.8zM5.3 14l-4.1.8.8-4.1 7-7L12.3 7l-7 7zM13 6.3L9.7 3l1.8-1.8 3.3 3.3L13 6.3z"/>'
  }
})
