/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-audio-mid-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.912 3.094a.5.5 0 00-.827-.378l-5.72 4.95H2.183C.977 7.666 0 8.661 0 9.89v4.222c0 1.228.977 2.222 2.183 2.222h2.182l5.72 4.95a.5.5 0 00.827-.377V3.094zm2.386 5.05a1 1 0 011.414.012c2.078 2.116 2.078 5.573 0 7.689a1 1 0 01-1.427-1.402c1.314-1.337 1.314-3.548 0-4.885a1 1 0 01.013-1.414zm4.036-3.13a1 1 0 00-1.427 1.401c3.018 3.074 3.018 8.096 0 11.168a1 1 0 101.426 1.402c3.783-3.85 3.783-10.12 0-13.971z"/>'
  }
})
