/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-react': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.3 8.3c-.4-.2-.9-.3-1.3-.5.1-.5.2-.9.3-1.4.4-2.6 0-4.4-1.3-5.1-.4-.2-.8-.3-1.2-.3-1.3 0-3 1-4.8 2.7C10.3 2 8.6 1 7.2 1c-.4 0-.8.1-1.2.3-1.2.7-1.7 2.6-1.3 5.2.1.4.2.9.3 1.3-.4.2-.9.3-1.3.5C1.3 9.2 0 10.5 0 12s1.3 2.8 3.7 3.7c.4.2.9.3 1.3.5-.1.5-.2.9-.3 1.4-.4 2.6 0 4.4 1.3 5.1.4.2.8.3 1.2.3 1.4 0 3.1-1 4.8-2.7 1.7 1.7 3.4 2.7 4.8 2.7.5 0 .9-.1 1.2-.3 1.2-.7 1.7-2.6 1.3-5.1-.1-.5-.2-.9-.3-1.4.5-.1.9-.3 1.3-.5 2.4-.9 3.7-2.2 3.7-3.7s-1.3-2.8-3.7-3.7zm-3.5-6.2c.2 0 .5.1.7.2.8.5 1.1 1.9.7 4-.1.4-.2.8-.3 1.3-1-.2-2.1-.4-3.2-.5-.7-.9-1.3-1.8-2-2.6 1.5-1.4 2.9-2.4 4.1-2.4zM7.7 14.5c.2.4.5.8.7 1.2-.7-.1-1.4-.2-2.1-.4.2-.6.4-1.3.7-2 .3.4.5.8.7 1.2zM6.4 8.7c.6-.2 1.3-.3 2-.4-.2.4-.4.7-.7 1.2-.2.4-.4.8-.7 1.2-.2-.7-.4-1.4-.6-2zM7.7 12c.3-.6.7-1.3 1-2 .3-.6.8-1.2 1.2-1.9.7-.1 1.4-.1 2.1-.1.7 0 1.5 0 2.1.1.4.6.8 1.3 1.2 1.9.4.6.7 1.3 1 2-.3.6-.7 1.3-1 2-.3.6-.7 1.3-1.2 1.9-.7.1-1.4.1-2.1.1-.7 0-1.5 0-2.1-.1-.5-.7-.9-1.3-1.2-1.9-.4-.7-.7-1.4-1-2zm8.6 2.5c.2-.4.4-.8.7-1.2.3.7.5 1.4.7 2-.7.2-1.3.3-2.1.4.2-.4.4-.7.7-1.2zm0-5l-.7-1.2c.7.1 1.4.2 2.1.4-.2.6-.4 1.3-.7 2-.3-.4-.5-.8-.7-1.2zm-3-2.5h-2.6c.4-.6.9-1.1 1.3-1.6.5.5.9 1 1.3 1.6zM6.5 2.3c.2-.1.5-.2.7-.2 1.2 0 2.6 1 4 2.4-.7.7-1.3 1.6-2 2.6-1.1.1-2.1.3-3.1.5-.1-.5-.2-.9-.3-1.3-.3-2.1 0-3.5.7-4zM5.3 15.1c-2.6-.8-4.2-2-4.2-3.1 0-.9 1.1-1.9 3-2.6.4-.2.8-.3 1.2-.4.3 1 .7 2 1.2 3.1-.5 1-.9 2-1.2 3zm1.9 6.8c-.2 0-.5-.1-.7-.2-.8-.5-1.1-1.9-.7-4 .1-.4.2-.8.3-1.3 1 .2 2.1.4 3.2.5.7.9 1.3 1.8 2 2.6-1.5 1.4-2.9 2.4-4.1 2.4zm3.5-4.9h2.6c-.4.6-.9 1.1-1.3 1.6-.5-.5-.9-1-1.3-1.6zm6.8 4.7c-.2.1-.4.2-.7.2-1.2 0-2.6-1-4-2.4.7-.7 1.3-1.6 2-2.6 1.1-.1 2.2-.3 3.2-.5.1.5.2.9.3 1.3.2 2.1-.1 3.5-.8 4zm2.4-7.1c-.4.2-.8.3-1.2.4-.3-1-.7-2-1.2-3.1.5-1 .9-2.1 1.2-3.1 2.6.8 4.2 2 4.2 3.1 0 1-1.1 2-3 2.7z"/><path pid="1" d="M12 14.2a2.2 2.2 0 100-4.4 2.2 2.2 0 000 4.4z"/>'
  }
})
