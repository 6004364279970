/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-salesforce': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11.9 3.4c-.4 0-.8.1-1.1.2-.6-.7-1.4-1-2.3-1-.6 0-1.2.2-1.8.6 0 0-.1 0-.2.1-.1-.1-.1-.2-.2-.2-.6-.6-1.4-1-2.2-1C2.4 2.1 1 3.6 1 5.3c0 .4.1.8.2 1.1C.4 7 0 7.9 0 8.9c0 1.8 1.4 3.2 3.1 3.2h.2c.5 1.1 1.6 1.7 2.8 1.7 1 0 2-.5 2.5-1.4 1.1.3 2.4-.1 3.1-1.1h.3c2.2 0 3.9-1.8 3.9-4 0-2.1-1.8-3.9-4-3.9zm0 7.1h-.7l-.3.4c-.5.6-1.3.9-2.1.7l-.7-.2-.4.6c-.4.6-1 .9-1.7.9-.8 0-1.5-.5-1.9-1.2l-.3-.6h-.7H3c-1 0-2-.9-2-2.2 0-.7.3-1.3.8-1.7l.6-.5-.3-.6C2 5.8 2 5.6 2 5.3c0-1.2 1-2.2 2.1-2.2.6 0 1.2.3 1.6.8l.7.8.8-.7c.3-.3.8-.5 1.3-.5.6 0 1.1.3 1.5.7l.4.5.6-.2c.3-.1.6-.1.8-.1 1.6 0 2.9 1.3 2.9 3 .2 1.7-1.2 3.1-2.8 3.1z"/><path pid="1" d="M8.9 5.9L6.6 8.3 5.5 7.2c-.2-.2-.5-.2-.7 0-.2.2-.2.4 0 .6l1.7 1.8 2.9-3c.2-.2.2-.4 0-.6-.1-.2-.4-.2-.5-.1z"/>'
  }
})
