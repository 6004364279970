/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-link': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.77 2.341a.786.786 0 00-1.111-1.11l-7.857 7.856a.786.786 0 101.11 1.111l7.858-7.857zm-7.12 1.75a.786.786 0 00.658-1.422c-.073-.047-.53-.183-.749-.245a8.289 8.289 0 00-1.548-.244c-1.223-.072-2.865.136-4.137 1.408L3.588 9.873c-1.272 1.272-1.479 2.914-1.407 4.137a8.286 8.286 0 00.378 2.012c.02.062.041.125.064.186l.002.003a.786.786 0 001.466-.564 6.718 6.718 0 01-.342-1.729c-.058-1.003.127-2.11.95-2.934L10.985 4.7c.823-.823 1.93-1.009 2.934-.95a6.718 6.718 0 011.708.334l.02.007v.001h.002zm6.17 5.899a8.29 8.29 0 00-.244-1.549c-.038-.177-.136-.567-.22-.701-.173-.372-.451-.559-.995-.402-.387.157-.609.471-.451 1.016 0 0 .102.316.143.477.081.317.169.757.198 1.25.059 1.004-.127 2.112-.95 2.935L13.017 19.3c-.823.823-1.931 1.009-2.934.95a6.72 6.72 0 01-1.708-.334l-.02-.007h-.001l-.001-.001a.786.786 0 00-1.014.452c-.244.635.664 1.102 1.104 1.215.386.099.929.207 1.548.244 1.223.072 2.865-.136 4.137-1.407l6.286-6.286c1.272-1.272 1.479-2.914 1.407-4.137zm-11.622 3.812a.786.786 0 010 1.11L2.341 22.77a.786.786 0 01-1.11-1.111l7.856-7.857a.786.786 0 011.111 0z"/>'
  }
})
