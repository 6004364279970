/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-left-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 16.5H13.5v6L0 12 13.5 1.5v6H24v9z"/>'
  }
})
