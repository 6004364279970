/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'callout-warning': {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M11.1 1.49a1.25 1.25 0 00-2.2 0L.15 17.74a1.25 1.25 0 001.1 1.843h17.5a1.25 1.25 0 001.1-1.843L11.1 1.49zM9.29 3.401L10 2.083l.71 1.318 7.367 13.682.673 1.25H1.25l.673-1.25L9.29 3.401zM10 7.083a.625.625 0 00-.625.625v3.75a.625.625 0 101.25 0v-3.75A.625.625 0 0010 7.083zm1.25 8.125c0-.75-.5-1.25-1.25-1.25s-1.25.5-1.25 1.25.5 1.25 1.25 1.25 1.25-.5 1.25-1.25z"/>'
  }
})
