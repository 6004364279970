var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Vlt-form__element Vlt-form__element--big",
      class: { "Vlt-form__element--error": _vm.errorMessage },
    },
    [
      _c("div", { staticClass: "Vlt-select" }, [
        _c(
          "select",
          {
            staticClass: "select",
            class: { "with-label": !!_vm.label },
            attrs: { disabled: _vm.disabled },
            domProps: { value: _vm.value },
            on: { input: _vm.emitValue },
          },
          [
            _vm._t(
              "default",
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: item.value } },
                  [_vm._v(" " + _vm._s(item.label || item.value) + " ")]
                )
              })
            ),
          ],
          2
        ),
        _c("label", { staticClass: "label" }, [_vm._v(_vm._s(_vm.label))]),
      ]),
      _vm.errorMessage
        ? _c("div", { staticClass: "error-container" }, [
            _vm.errorMessage && _vm.errorMessage !== " "
              ? _c(
                  "small",
                  {
                    staticClass: "Vlt-form__element__error error-small-message",
                  },
                  [_vm._v(_vm._s(_vm.errorMessage) + " ")]
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }