/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-sugarcrm': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.1 4.188c0 .215.967.698 4.886 2.255 3.33 1.289 5.155 1.933 5.692 1.987.483 0 2.147-.537 5.584-1.933 3.973-1.611 4.885-2.04 4.885-2.31C22.147 3.706 12.86 0 11.624 0 10.389 0 1.1 3.705 1.1 4.188zm4.135 17.88C2.175 20.831.51 20.08.295 19.811c-.322-.376-.322-.805-.268-7.41C.08 6.39.134 5.37.349 5.209c.322-.16 9.879 3.598 10.416 4.188l.376.376-.054 7.033v7.088l-.59.053c-.43 0-2.095-.59-5.262-1.879zm7.087 1.77c-.054-.053-.107-3.22-.107-7.033 0-5.852.053-6.98.268-7.302.322-.43 9.718-4.241 10.309-4.188l.376.054.053 7.034c.054 6.496 0 7.033-.268 7.41-.322.375-9.61 4.187-10.201 4.187a3.162 3.162 0 00-.15-.033c-.14-.027-.24-.047-.28-.128z"/>'
  }
})
