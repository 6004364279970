// the default values of the settings should indicate it's real type
// we use those types to cast the settings received from vbc-customization from string to it's real type
import { VIRTUAL_BACKGROUND_TYPE } from '@/consts/global-consts';

export default function getInitialState() {
  return {
    playSoundOnJoinOrLeave: true,
    playSoundOnWaitingRoomJoin: true,
    initialSettings: {
      microphoneEnabled: true,
      cameraEnabled: undefined,
      selectedMicrophoneId: '',
      selectedCameraId: '',
      selectedSpeakerId: '',
      virtualBackground: {
        type: VIRTUAL_BACKGROUND_TYPE.NONE,
        background: ''
      }
    },
    shouldShowPreJoinScreen: true,
    isWaitingRoomEnabled: false,
    exclusiveExperimentalMode: false
  };
}
