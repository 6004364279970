/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-fax-outbound': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 2.2v1.5h3v18h11.6l6.2-6.2.2-.2V3.8h3V2.2H0zm15 17v-3.4h3.4L15 19.2zm4.5-5h-6v6h-9V3.8h15v10.4z"/><path pid="1" d="M8 13.2l4.8-4.8v2.8h1.4V6H9v1.5h2.6L7 12.1z"/>'
  }
})
