/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-notification-off-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M23.7 19.05c-.3.3-.6.45-1.05.45L6.15 2.85C7.05 1.65 8.4.75 9.9.3c1.5-.45 3.15-.3 4.65.15 1.35.45 2.7 1.5 3.6 2.7.9 1.2 1.35 2.85 1.35 4.35V12c0 .786.229 1.457.486 2.214l.114.336c.45.75.9 1.35 1.65 1.95h.75c.45 0 .75.15 1.05.45.3.3.45.6.45 1.05.15.45 0 .75-.3 1.05zM9 21h6c0 .75-.3 1.5-.9 2.1-.6.6-1.35.9-2.1.9s-1.5-.3-2.1-.9c-.6-.6-.9-1.35-.9-2.1zM4.5 7.65V12c0 .786-.229 1.457-.486 2.214l-.114.336c-.45.75-.9 1.5-1.65 1.95H1.5c-.45 0-.75.15-1.05.45-.3.3-.45.6-.45 1.05 0 .45.15.75.45 1.05.3.3.6.45 1.05.45h14.85L4.5 7.65zm17.7 15.6h.3c.15 0 .3 0 .45-.15.15 0 .3-.15.3-.3v-.45c0-.15 0-.3-.15-.45L2.1.9a1.16 1.16 0 01-.3-.075C1.687.788 1.575.75 1.5.75c-.075 0-.188.037-.3.075A1.16 1.16 0 01.9.9c0 .075-.037.187-.075.3-.037.113-.075.225-.075.3 0 .075.037.188.075.3.038.113.075.225.075.3l21 21c.075 0 .113.037.15.075.037.037.075.075.15.075z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
