/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-rec': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22.5 12c0 5.799-4.701 10.5-10.5 10.5S1.5 17.799 1.5 12 6.201 1.5 12 1.5 22.5 6.201 22.5 12zm1.5 0c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12zm-12 4.5a4.5 4.5 0 110-9 4.5 4.5 0 010 9zM6 12a6 6 0 1112 0 6 6 0 01-12 0z"/>'
  }
})
