/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mobile-devices-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.4 0H12c1.324 0 2.4 1.065 2.4 2.375V5h7.2C22.924 5 24 6.065 24 7.375v14.25C24 22.935 22.924 24 21.6 24H12c-1.324 0-2.4-1.065-2.4-2.375V19H2.4C1.076 19 0 17.935 0 16.625V2.375C0 1.065 1.076 0 2.4 0zm7.2 11.875v-4.5C9.6 6.065 10.676 5 12 5V2.375H2.4v9.5h7.2zm-2.4 4.75c-.662 0-1.2-.532-1.2-1.188 0-.655.538-1.187 1.2-1.187.662 0 1.2.532 1.2 1.188 0 .655-.538 1.187-1.2 1.187zm8.4 3.813c0 .655.538 1.187 1.2 1.187.662 0 1.2-.532 1.2-1.188 0-.655-.538-1.187-1.2-1.187-.662 0-1.2.532-1.2 1.188zM12 16.875h9.6v-9.5H12v9.5z"/>'
  }
})
