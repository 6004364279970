/* eslint-disable */
require('./volta-logo')
require('./Vonage-api--white')
require('./Vonage-api-changelog--white')
require('./Vonage-api-changelog')
require('./Vonage-api-dashboard-vert--white')
require('./Vonage-api-dashboard-vert')
require('./Vonage-api-developer--white')
require('./Vonage-api-developer')
require('./Vonage-api-support--white')
require('./Vonage-api-support')
require('./Vonage-api')
require('./Vonage-apis--white')
require('./Vonage-apis')
require('./Vonage-developer--white')
require('./Vonage-developer')
require('./Vonage-lettermark--white')
require('./Vonage-lettermark')
require('./vonage-logo--white')
require('./vonage-logo-collapsed--white')
require('./Vonage-logo-collapsed')
require('./vonage-logo')
require('./Vonage-nexmo--white')
require('./Vonage-nexmo-vert--white')
require('./Vonage-nexmo-vert')
require('./Vonage-nexmo')
require('./Vonage-tokbox--white')
require('./Vonage-tokbox-vert--white')
require('./Vonage-tokbox-vert')
require('./Vonage-tokbox')
require('./Vonage-video-api--white')
require('./Vonage-video-api-stacked--white')
require('./Vonage-video-api-stacked')
require('./Vonage-video-api')
require('./Vonage-wordmark--white')
require('./Vonage-wordmark')
