/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-store-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 1.5v3L24 9H0l3-4.5v-3A1.5 1.5 0 014.5 0h15A1.5 1.5 0 0121 1.5zm-18 21V12h18v10.5a1.5 1.5 0 01-1.5 1.5H15v-6H9v6H4.5A1.5 1.5 0 013 22.5z"/>'
  }
})
