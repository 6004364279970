<template>
  <div
    ref="sidebarContainer"
    class="sidebar-container Vlt-black vvd-scheme-alternate"
    :class="{
      'sidebar-collapsed': isSidebarCollapsed,
      'sidebar-completely-collapsed': isSidebarCompletelyCollapsed,
      'round-corners': roundCorners
    }"
  >
    <div class="sidebar Vlt-bg-white">
      <slot name="sidebar-header">
        <div class="header">
          <div class="title-container">
            <vwc-icon :type="icon ? icon : sidebars[activeSidebar].icon" />
            <span data-cy="sidebar-title">{{
              title ? title : sidebars[activeSidebar].title
            }}</span>
          </div>
          <vwc-icon
            class="dismiss-button"
            type="close-solid"
            size="small"
            @click="toggleSidebar"
          />
        </div>
      </slot>
      <slot name="sidebar-content">
        <Participants v-if="isParticipantsSidebar" />
        <MeetingInfo v-else-if="isMeetingInfoSidebar" />
        <QuestionsAndAnswers v-else-if="isQnA" />
        <LiveTranscription v-else-if="isLiveTranscriptionSidebar" />
        <ChatComponent v-else-if="isChatSidebar" />
      </slot>
    </div>
  </div>
</template>

<script>
import Participants from '@/components/Participants.vue';
import ChatComponent from '@/components/chat/ChatComponent';
import QuestionsAndAnswers from '@/components/QuestionsAndAnswers.vue';
import LiveTranscription from '@/components/LiveTranscription.vue';
import { SIDEBARS } from '@/consts/global-consts';
import { mapState, mapGetters, mapActions } from 'vuex';
import MeetingInfo from '@/components/MeetingInfo';

export default {
  name: 'Sidebar',

  components: {
    MeetingInfo,
    ChatComponent,
    Participants,
    QuestionsAndAnswers,
    LiveTranscription
  },

  props: {
    roundCorners: {
      type: Boolean,
      default: true
    },
    title: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    }
  },

  computed: {
    ...mapState(['activeSidebar']),
    ...mapState('layout', ['isSidebarCollapsed']),
    ...mapGetters(['isGuest']),
    ...mapGetters('settings', ['exclusiveExperimentalMode']),
    ...mapGetters('layout', ['toggleSidebarInProgress']),

    sidebars() {
      return {
        [SIDEBARS.PARTICIPANTS]: {
          title: this.$t('sidebar.participants_title'),
          icon: 'group-line'
        },
        [SIDEBARS.CHAT]: {
          title: this.$t('sidebar.chat_title'),
          icon: 'chat-line'
        },
        [SIDEBARS.INFO]: {
          title: this.$t('sidebar.meeting_info_title'),
          icon: 'info-line'
        },
        [SIDEBARS.QUESTIONS_AND_ANSWERS]: {
          title: this.$t('sidebar.qna_title'),
          icon: 'group-8-line'
        },
        [SIDEBARS.LIVE_TRANSCRIPTION]: {
          title: this.$t('sidebar.live_transcription_title'),
          icon: 'voicemail-transcript-line'
        }
      };
    },

    isParticipantsSidebar() {
      return this.activeSidebar === SIDEBARS.PARTICIPANTS;
    },

    isChatSidebar() {
      return this.activeSidebar === SIDEBARS.CHAT;
    },

    isMeetingInfoSidebar() {
      return this.activeSidebar === SIDEBARS.INFO;
    },

    isQnA() {
      return this.activeSidebar === SIDEBARS.QUESTIONS_AND_ANSWERS;
    },

    isLiveTranscriptionSidebar() {
      return this.activeSidebar === SIDEBARS.LIVE_TRANSCRIPTION;
    },

    isSidebarCompletelyCollapsed() {
      return this.isSidebarCollapsed && !this.toggleSidebarInProgress;
    }
  },

  mounted() {
    const container = this.$refs.sidebarContainer;
    container.addEventListener('transitionend', this.onTransitionChange);
  },

  destroyed() {
    const container = this.$refs.sidebarContainer;
    if (container) {
      container.removeEventListener('transitionend', this.onTransitionChange);
    }
  },

  methods: {
    ...mapActions(['removeInvitedUser', 'toggleSidebarAnimation']),
    ...mapActions('layout', ['toggleSidebar']),

    onTransitionChange(event) {
      if (event.propertyName === 'transform') {
        this.toggleSidebarAnimation(false);
      }
    }
  }
};
</script>

<style scoped>
.sidebar-container {
  opacity: 1;
  height: 100%;
  transition: transform 0.5s, opacity 0.5s;
}

.sidebar {
  width: 345px;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;
  overflow: hidden;
}

.sidebar-container.round-corners:not(.sidebar-completely-collapsed) {
  padding: 16px;
}

.round-corners .sidebar {
  border-radius: 8px;
}

.sidebar-completely-collapsed,
.sidebar-completely-collapsed .sidebar {
  width: 0;
}

.sidebar-collapsed {
  transform: translateX(360px);
  opacity: 0;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
}

.title-container {
  display: flex;
  align-items: center;
}

.header span {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-left: 13px;
}

.dismiss-button {
  margin-left: 16px;
  align-self: flex-start;
  cursor: pointer;
}

.dismiss-button:hover {
  color: #666666;
}

.branded .vvd-scheme-alternate {
  --vvd-color-cta: var(--btn-cta-color-white-bg);
  --vvd-color-on-cta: var(--btn-cta-color-white-bg-text-color);
}
</style>
