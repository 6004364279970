/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-notification': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6 12.9c.3 0 .5.2.5.5 0 .9.7 1.6 1.5 1.6s1.5-.7 1.5-1.5c0-.3.2-.5.5-.5s.5.2.5.5c0 1.4-1.1 2.6-2.5 2.6s-2.5-1.2-2.5-2.6c0-.4.2-.6.5-.6zM15.5 11.4H15c-.4 0-1-.2-1.4-.5-.4-.3-.6-.7-.6-1V5.2C13 2.3 10.8 0 8 0S3 2.3 3 5.2v4.6c0 .9-.7 1.5-1.5 1.5h-1c-.3 0-.5.2-.5.5s.2.5.5.5h15c.3 0 .5-.2.5-.5 0-.2-.2-.4-.5-.4zm-12 0c.3-.4.5-1 .5-1.5V5.2C4 2.9 5.8 1 8 1s4 1.8 4 4.1v4.6c0 .6.3 1.1.7 1.5H3.5z"/>'
  }
})
