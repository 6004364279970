import { vbcApiGw } from '@/apis/vbc-gw';

export function readUserInfo() {
  return vbcApiGw.get('/ucaas-api/api/ucaas/v2/user', {
    'axios-retry': { retries: 3 }
  });
}

export function readContacts(externalId, lastModified = 0) {
  return vbcApiGw.get(`/contacts/3/addressbooks/${externalId}`, {
    'axios-retry': { retries: 3 },
    params: {
      lastModified
    }
  });
}
