/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-salesforce-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.898 5.107c-.575 0-1.145.088-1.697.26a4.623 4.623 0 00-3.426-1.548c-1.123 0-2.189.41-3.024 1.146A4.613 4.613 0 006.135 3.2c-2.565 0-4.653 2.14-4.653 4.768 0 .575.104 1.15.304 1.688A4.762 4.762 0 000 13.406c0 2.63 2.087 4.768 4.653 4.768.078 0 .155-.003.232-.008.787 1.58 2.395 2.61 4.144 2.61a4.622 4.622 0 003.806-2.027c1.71.431 3.537-.214 4.65-1.576.137.011.274.018.413.018 3.248 0 5.893-2.71 5.893-6.04 0-3.333-2.645-6.044-5.893-6.044zM13.29 8.913l-3.459 3.56-1.639-1.684a.65.65 0 10-.931.905l2.57 2.644 4.391-4.519a.65.65 0 10-.932-.906z"/>'
  }
})
