/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-tag-add': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.505.195a.75.75 0 00-1.01 0l-8.25 7.5A.75.75 0 000 8.25v13.5A2.25 2.25 0 002.25 24h7.5a.75.75 0 000-1.5h-7.5a.75.75 0 01-.75-.75V8.582L9 1.764l7.745 7.041a.75.75 0 001.01-1.11l-8.25-7.5zM12 17.25a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm5.25-6.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zm.75 3.75a.75.75 0 00-1.5 0v2.25h-2.25a.75.75 0 000 1.5h2.25v2.25a.75.75 0 001.5 0V18h2.25a.75.75 0 000-1.5H18v-2.25z"/>'
  }
})
