/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'leave-meeting-icon': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.805 6.196L13.138 3.53A.667.667 0 0012 4v2H9.333a.667.667 0 000 1.334H12v2a.668.668 0 001.138.471l2.667-2.666a.666.666 0 000-.943zM10 8.666a.666.666 0 00-.667.667V12a.667.667 0 01-.666.667h-2v-10c0-.57-.363-1.078-.908-1.268l-.198-.066h3.106c.368 0 .666.3.666.667v2a.666.666 0 101.334 0V2c0-1.103-.898-2-2-2H1.5c-.025 0-.047.011-.071.015C1.397.012 1.366 0 1.333 0 .598 0 0 .598 0 1.333v12c0 .57.363 1.078.908 1.267l4.012 1.338c.136.042.271.062.413.062.736 0 1.334-.598 1.334-1.334V14h2c1.102 0 2-.898 2-2V9.333A.666.666 0 0010 8.667z"/>'
  }
})
