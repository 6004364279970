/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-doc': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.7 15.3c0-1.9-.9-3.1-2.6-3.1H.8v6.3H3c1.8 0 2.7-1.3 2.7-3.2zm-3.8 2.1v-4.2h1c1.1 0 1.6.7 1.6 2.1 0 1.4-.5 2.1-1.6 2.1h-1zM12 15.3c0-2-.8-3.3-2.6-3.3s-2.7 1.3-2.7 3.3c0 2 .8 3.3 2.7 3.3 1.7 0 2.6-1.2 2.6-3.3zm-4.2 0c0-1.4.5-2.3 1.5-2.3 1.1 0 1.5.9 1.5 2.3s-.5 2.3-1.5 2.3c-1.1 0-1.5-.9-1.5-2.3zM15.7 13.1c.8 0 1.2.4 1.3 1.2l1.1-.2c-.2-1.3-1.1-2-2.4-2-1.9 0-2.8 1.3-2.8 3.3 0 2 .9 3.3 2.7 3.3 1.4 0 2.3-.8 2.5-2.2l-1.1-.2c-.1.8-.6 1.3-1.4 1.3-1.2 0-1.6-1-1.6-2.3 0-1.3.5-2.2 1.7-2.2z"/><path pid="1" d="M22.3 7.7L14.8.2c-.2-.1-.4-.2-.6-.2h-12c-.4 0-.7.3-.7.8V10H3V1.5h10.5v6.8c0 .4.3.8.8.8H21v13.5H3V21H1.5v2.2c0 .4.3.8.8.8h19.5c.4 0 .8-.3.8-.8v-15c-.1-.1-.2-.3-.3-.5zM15 7.5V2.6l4.9 4.9H15z"/>'
  }
})
