import debounce from 'lodash.debounce';
import * as vbcApiGw from '@/apis/vbc-gw';
import * as roomServiceApi from '@/apis/room-service-api';
import * as roomServicePublic from '@/apis/room-service-public-api';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import * as utils from '@/helpers/meeting-helpers';
import analyticsService from '@/services/analytics-service';
import {
  ANALYTICS,
  SIDEBARS,
  JOIN_APPROVAL_LEVEL,
  WAITING_ROOM_PARTICIPANT_STATES
} from '@/consts/global-consts';
import { runWithMinimumDelay } from '@/helpers/time-utils';
import { isElectron } from '@/helpers/global-helpers';
import store from '@/store';
import {
  WAITING_ROOM_MESSAGE_ENUM,
  UNDO_WINDOW_DURATION,
  WAITING_ROOM_ILLUSTRATION_PATHS
} from '@/store/waitingRoom/consts';
import { sessionStorageService } from '@/services/storage-service';
import i18n from '@/i18n';

const OWNER_POLLING_INTERVAL_MILLISECONDS = 25 * 1000;
const KEEPALIVE_INTERVAL_MILLISECONDS = 7 * 1000;
const MAX_CONSEQUENT_KEEPALIVE_ERROR_COUNT = 3; // allow up to 3 errors in a row
const MINIMUM_DELAY_FOR_OWNER_ACTION_MILLISECONDS = 700; // at least 0.7 seconds of loading for owner actions

let ownerPollingIntervalId = null;
let keepAliveIntervalId = null;

export default {
  init: ({ rootGetters, dispatch }) => {
    if (rootGetters.isSessionOwner) {
      if (rootGetters.isWaitingRoomEnabled) {
        dispatch('initOwner');
      }

      analyticsService.trackEvent(ANALYTICS.WAITING_ROOM_GENERAL_ACTIVATION, {
        Status: rootGetters.isWaitingRoomEnabled ? 'On' : 'Off'
      });
    }
  },

  initOwner: ({ dispatch }) => {
    logger.log('waiting-room-owner-init', LOG_CATEGORIES.WAITING_ROOM, {
      message: 'Initializing waiting room owner logic'
    });
    dispatch('fetchWaitingRoomParticipants');

    // Init polling interval
    ownerPollingIntervalId = setInterval(() => {
      dispatch('fetchWaitingRoomParticipants');
    }, OWNER_POLLING_INTERVAL_MILLISECONDS);

    // Watch the store to show/hide the Native waiting room owner modal
    store.watch(
      (storeState, storeGetters) =>
        !storeGetters['waitingRoom/isWaitingRoomEmpty'] &&
        storeState.minimizedMode,
      () => {
        dispatch('refreshElectronModalParticipants');
      }
    );
    store.watch(
      (storeState) => storeState.waitingRoom.waitingParticipants,
      () => {
        dispatch('refreshElectronModalParticipants');
      }
    );
    store.watch(
      (storeState) => storeState.minimizedMode,
      () => {
        dispatch('refreshElectronModalParticipants');
      }
    );
  },

  joinWaitingRoom: debounce(
    async ({ dispatch, rootGetters, commit }) => {
      commit('SET_GLOBAL_MESSAGE', null, { root: true });
      try {
        if (rootGetters.isGuest) {
          await dispatch('joinWaitingRoomAsGuest');
        } else {
          await dispatch('joinWaitingRoomAsAppUser');
        }
      } catch (err) {
        logger.warning(
          'waiting-room-join-failed',
          LOG_CATEGORIES.WAITING_ROOM,
          { err }
        );
        return;
      }

      await dispatch('handleWaitingRoomJoined');
    },
    // 1s debounce should be just enough to prevent a user from spamming the join button
    1000,
    {
      leading: true,
      trailing: false
    }
  ),

  joinWaitingRoomAsAppUser: async ({ rootState, dispatch }) => {
    const roomName = rootState.roomDetails.name;

    try {
      await roomServiceApi.joinWaitingRoom(
        roomName,
        vbcApiGw.getCredentials().externalId
      );
    } catch (err) {
      dispatch(
        'handleRoomServiceError',
        { meetingError: utils.getSessionErrorType(err) },
        { root: true }
      );
      throw err;
    }
  },

  joinWaitingRoomAsGuest: async ({ rootState, commit, dispatch }) => {
    const displayName =
      rootState.userInfo.loginName || rootState.initialJoinConfig.displayName;

    let joinWaitingRoomResponse;
    try {
      joinWaitingRoomResponse = await roomServicePublic.joinWaitingRoom(
        rootState.roomDetails.roomToken,
        displayName
      );
    } catch (err) {
      dispatch(
        'handleRoomServiceError',
        { meetingError: utils.getSessionErrorType(err) },
        { root: true }
      );
      throw err;
    }
    const {
      accountId,
      guestId,
      participantToken,
      vbcAccessToken,
      isOwnerInMeeting
    } = joinWaitingRoomResponse;

    commit('SET_IS_OWNER_IN_MEETING', isOwnerInMeeting);

    // Update the user info in the main state (crucial for registering to the bus)
    dispatch(
      'setGuestUserInfo',
      {
        accountId,
        guestId,
        displayName,
        participantToken
      },
      { root: true }
    );
    vbcApiGw.init({
      accessToken: vbcAccessToken,
      externalId: guestId,
      extension: displayName
    });
  },

  handleWaitingRoomJoined: ({ rootState, commit, dispatch }) => {
    dispatch(
      'initBus',
      { domain: rootState.roomDetails.domain },
      { root: true }
    );

    dispatch('initKeepAliveInterval');
    dispatch('showWaitingRoomWaitingScreen');

    commit('SET_JOINED_WAITING_ROOM_TIMESTAMP', Date.now());
  },

  showWaitingRoomWaitingScreen: ({ rootGetters, commit, dispatch }) => {
    commit('SET_IS_IN_WAITING_ROOM', true);

    if (rootGetters.isMobileWebMode) {
      dispatch(
        'replaceRoute',
        {
          name: 'MobileWaitingRoomJoinerScreen'
        },
        {
          root: true
        }
      );
    } else {
      commit('SET_SHOW_PRE_JOIN_SCREEN', true, { root: true });
      commit('SET_IS_LOADING_PRE_ENTRANCE_DATA', false, { root: true });
      commit('SET_IS_LOADING_PRE_INIT_DATA', false, { root: true });
      commit('SET_SHOW_ENTRANCE_SCREEN', false, { root: true });
      commit('SET_IS_SESSION_INITIALIZED', false, { root: true });
      commit('SET_IS_JOINING_A_SESSION', false, { root: true });
    }
  },

  initKeepAliveInterval: async ({
    state,
    getters,
    rootGetters,
    commit,
    dispatch
  }) => {
    clearInterval(keepAliveIntervalId);
    commit('SET_LAST_KEEPALIVE_RESPONSE_TIMESTAMP', Date.now());

    keepAliveIntervalId = setInterval(async () => {
      // This might happen when users switch to another app and the browser pauses the interval
      if (rootGetters.isMobileWebMode && getters.didKeepaliveExpire) {
        logger.warning(
          'waiting-room-keepalive-expired',
          LOG_CATEGORIES.WAITING_ROOM,
          {
            message:
              '(Mobile Web) waiting room keepalive expired, probably because the user switched to another app for too long'
          }
        );
        dispatch('showGlobalMessage', {
          title: {
            i18nKey:
              'waiting_room_consts.mobile_messages_keepalive_expired_title'
          },
          message: {
            i18nKey:
              'waiting_room_consts.mobile_messages_keepalive_expired_text'
          },
          reason: WAITING_ROOM_MESSAGE_ENUM.KEEPALIVE_EXPIRED,
          image: WAITING_ROOM_ILLUSTRATION_PATHS.SESSION_EXPIRED,
          brandedIcon: 'puzzled-line',
          showVonageLogo: false
        });

        return;
      }

      try {
        await dispatch('sendKeepAlive');
        commit('SET_KEEPALIVE_ERROR_COUNTER', 0);
      } catch (error) {
        if (
          state.keepAliveErrorCounter > MAX_CONSEQUENT_KEEPALIVE_ERROR_COUNT
        ) {
          dispatch('handleTooManyConsequentKeepAliveErrors');
          return;
        }

        commit('SET_KEEPALIVE_ERROR_COUNTER', state.keepAliveErrorCounter + 1);
        logger.warning('waiting-room-keepalive', LOG_CATEGORIES.WAITING_ROOM, {
          message: 'Keepalive error counter incremented',
          keepAliveErrorCounter: state.keepAliveErrorCounter
        });
      }
    }, KEEPALIVE_INTERVAL_MILLISECONDS);
  },

  sendKeepAlive: async ({ rootState, rootGetters, commit, dispatch }) => {
    try {
      const {
        isOwnerInMeeting,
        expiresIn
      } = await roomServiceApi.sendWaitingRoomKeepAlive(
        rootState.roomDetails.name,
        vbcApiGw.getCredentials().externalId,
        {
          isExtended: rootGetters.isMobileWebMode
        }
      );
      commit('SET_IS_OWNER_IN_MEETING', isOwnerInMeeting);
      commit('SET_LAST_KEEPALIVE_RESPONSE_TIMESTAMP', Date.now());
      commit('SET_LAST_KEEPALIVE_EXPIRES_IN', expiresIn);
    } catch (err) {
      const expectedHttpErrorCodes = [400, 404];
      if (!expectedHttpErrorCodes.includes(err.response.status)) {
        throw err;
      }

      // Keepalives result in a 404 when the user is no longer in the waiting room
      if (err.response.status === 404) {
        logger.log('waiting-room-keepalive', LOG_CATEGORIES.WAITING_ROOM, {
          message:
            'Keepalive resulted in 404. Assuming the meeting session has ended',
          response: err.response
        });

        dispatch('handleMeetingEnded');
      }
      // Keepalives result in a 400 when the user has been approved/denied and it serves us as a fallback for the bus
      else if (err.response.status === 400) {
        if (!err.response.data.data) {
          logger.warning(
            'waiting-room-keepalive',
            LOG_CATEGORIES.WAITING_ROOM,
            {
              message:
                'Keepalive resulted in 400 but the payload was not in the expected { data: ... } format',
              response: err.response
            }
          );

          throw err;
        }

        const { state } = err.response.data.data;
        if (state === 'admitted') {
          dispatch('handleAdmitted');
        } else if (state === 'denied') {
          dispatch('handleDenied');
        } else {
          throw err;
        }
      }
    }
  },

  handleTooManyConsequentKeepAliveErrors: ({ rootGetters, dispatch }) => {
    logger.error('waiting-room-keepalive', LOG_CATEGORIES.WAITING_ROOM, {
      message:
        'Too many consequent keepalive errors. Sending the user to error screen'
    });

    dispatch('showGlobalMessage', {
      title: rootGetters.isMobileWebMode
        ? {
            i18nKey:
              'waiting_room_consts.mobile_messages_technical_difficulties_title'
          }
        : { i18nKey: '' },
      message: rootGetters.isMobileWebMode
        ? {
            i18nKey: ''
          }
        : {
            i18nKey:
              'waiting_room_consts.desktop_messages_technical_difficulties'
          },
      reason: WAITING_ROOM_MESSAGE_ENUM.TECHNICAL_DIFFICULTIES,
      image: WAITING_ROOM_ILLUSTRATION_PATHS.WALK_OUT_THE_DOOR,
      brandedIcon: 'unhappy-line',
      showVonageLogo: false
    });
  },

  handleAdmitted: debounce(
    async ({ state, getters, rootGetters, commit, dispatch }) => {
      if (!state.isInWaitingRoom) {
        return;
      }

      // Force guests to go through the full join-as-guest flow in case they had arrived at the waiting room following a refresh
      sessionStorageService.removeItem('guest_init_done');
      dispatch('stopKeepaliveInterval');

      // Mobile browsers do not handle the join-meeting flow well whilst in background so we wait for the user to return
      if (rootGetters.isMobileWebMode && !rootGetters['isInForeground']) {
        const unwatch = store.watch(
          (state, getters) => getters['isInForeground'],
          () => {
            unwatch();

            // If the user spent too much time in the background, their approval is no longer relevant and they should rejoin
            if (getters.didKeepaliveExpire) {
              logger.warning(
                'waiting-room-keepalive-expired',
                LOG_CATEGORIES.WAITING_ROOM,
                {
                  message:
                    '(Mobile Web) after approval, waiting room keepalive expired, probably because the user switched to another app for too long'
                }
              );
              dispatch('showGlobalMessage', {
                title: {
                  i18nKey:
                    'waiting_room_consts.mobile_messages_keepalive_expired_title'
                },
                message: {
                  i18nKey:
                    'waiting_room_consts.mobile_messages_keepalive_expired_text'
                },
                reason: WAITING_ROOM_MESSAGE_ENUM.KEEPALIVE_EXPIRED,
                image: WAITING_ROOM_ILLUSTRATION_PATHS.SESSION_EXPIRED,
                brandedIcon: 'puzzled-line',
                showVonageLogo: false
              });
            } else {
              dispatch('handleAdmitted');
            }
          }
        );
        return;
      }

      commit('SET_IS_IN_WAITING_ROOM', false);
      dispatch('sendJoinerFinishAnalytic', { status: 'Approved' });

      if (rootGetters.isMobileWebMode) {
        await dispatch('replaceRoute', { name: 'MobileHome' }, { root: true });
      }
      dispatch('joinMeeting', null, { root: true });
    },
    // 500ms debounce should be just enough to prevent race conditions of handling admitted state from both bus and keepalive
    500,
    {
      leading: true,
      trailing: false
    }
  ),

  handleDenied: ({ state, rootGetters, dispatch }) => {
    if (!state.isInWaitingRoom) {
      return;
    }

    dispatch('sendJoinerFinishAnalytic', { status: 'Denied' });

    dispatch('showGlobalMessage', {
      title: rootGetters.isMobileWebMode
        ? { i18nKey: 'waiting_room_consts.mobile_messages_denied_title' }
        : { i18nKey: 'waiting_room_consts.desktop_messages_denied_title' },
      message: rootGetters.isMobileWebMode
        ? {
            i18nKey: 'waiting_room_consts.mobile_messages_denied_text'
          }
        : {
            i18nKey: 'waiting_room_consts.desktop_messages_denied_text'
          },
      reason: WAITING_ROOM_MESSAGE_ENUM.DENIED,
      image: WAITING_ROOM_ILLUSTRATION_PATHS.BYE_IMG,
      brandedIcon: 'puzzled-line',
      showVonageLogo: false
    });
  },

  handleMeetingEnded: async ({ state, rootState, rootGetters, dispatch }) => {
    if (!state.isInWaitingRoom) {
      return;
    }

    if (rootGetters.isMobileWebMode) {
      dispatch('showGlobalMessage', {
        title: {
          i18nKey: 'waiting_room_consts.mobile_messages_meeting_ended_title'
        },

        message: rootState.isBranded
          ? {
              i18nKey:
                'waiting_room_consts.mobile_messages_meeting_ended_text_branded'
            }
          : {
              i18nKey: 'waiting_room_consts.mobile_messages_meeting_ended_text'
            },
        reason: WAITING_ROOM_MESSAGE_ENUM.MEETING_HAS_ENDED,
        image: WAITING_ROOM_ILLUSTRATION_PATHS.MEETING_ENDED,
        brandedIcon: 'unhappy-line',
        showVonageLogo: false
      });
    } else {
      dispatch('showGlobalMessage', {
        title: {
          i18nKey: 'waiting_room_consts.desktop_messages_meeting_ended_title'
        },
        message: rootState.isBranded
          ? {
              i18nKey:
                'waiting_room_consts.desktop_messages_meeting_ended_text_branded'
            }
          : {
              i18nKey: 'waiting_room_consts.desktop_messages_meeting_ended_text'
            },
        reason: WAITING_ROOM_MESSAGE_ENUM.MEETING_HAS_ENDED,
        image: WAITING_ROOM_ILLUSTRATION_PATHS.MEETING_ENDED,
        brandedIcon: 'unhappy-line',
        showVonageLogo: false
      });
    }
  },

  // Fetch the list of waiting participants (only works for the owner of the room)
  fetchWaitingRoomParticipants: async ({ rootState, commit }) => {
    const waitingRoom = await roomServiceApi.getWaitingRoomParticipants(
      rootState.roomDetails.name,
      vbcApiGw.getCredentials().externalId
    );
    const participants = waitingRoom.waitingParticipants
      // Map each object from the response to an object
      .map((participant) => ({
        ...participant,
        participantId: participant.participant_id,
        state: participant.state,
        type: participant.type || 'Guest',
        displayName: participant.display_name
      }))
      .filter((participant) => participant.state === 'pending');
    commit('SET_WAITING_PARTICIPANTS', participants);
  },

  // Approve or deny a waiting participant
  updateWaitingRoomParticipantStatus: async (
    { state, rootState, commit, dispatch },
    { participantId, isApproval, source, isUndoAllowed }
  ) => {
    const shouldEnterUndoableState = !isApproval && isUndoAllowed;

    commit('UPDATE_PARTICIPANT', {
      participantId: participantId,
      isApproveInProgress: isApproval,
      isDenyInProgress: !isApproval,
      isInUndoableState: shouldEnterUndoableState
    });

    // When denying, we enter an "undoable" state during which users can regret their decision
    if (shouldEnterUndoableState) {
      const participant = state.waitingParticipants.find(
        (p) => p.participantId === participantId
      );

      clearTimeout(participant.denyTimer);
      participant.denyTimer = setTimeout(
        () => {
          dispatch('updateWaitingRoomParticipantStatus', {
            participantId,
            isApproval,
            source,
            isUndoAllowed: false
          });
        },
        // We add a little bit of extra time to make sure the user can use the Undo button for the entirety of its duration
        UNDO_WINDOW_DURATION + 500
      );
      return;
    }

    try {
      await runWithMinimumDelay(async () => {
        await roomServiceApi.updateWaitingRoomParticipant(
          rootState.roomDetails.name,
          participantId,
          isApproval,
          vbcApiGw.getCredentials().externalId
        );
      }, MINIMUM_DELAY_FOR_OWNER_ACTION_MILLISECONDS);
      dispatch('fetchWaitingRoomParticipants');

      commit('UPDATE_PARTICIPANT', {
        participantId: participantId,
        state: isApproval
          ? WAITING_ROOM_PARTICIPANT_STATES.APPROVED
          : WAITING_ROOM_PARTICIPANT_STATES.DENIED
      });

      analyticsService.trackEvent(ANALYTICS.WAITING_ROOM_RESPONSES, {
        Status: isApproval ? 'Approve' : 'Deny',
        Source: source
      });
    } finally {
      commit('UPDATE_PARTICIPANT', {
        participantId,
        isApproveInProgress: false,
        isDenyInProgress: false
      });
    }
  },

  approveAllWaitingRoomParticipants: async ({
    rootState,
    state,
    commit,
    dispatch
  }) => {
    const allWaitingParticipantIds = state.waitingParticipants
      .filter(
        (waitingParticipant) => !waitingParticipant.isApproveOrDenyInProgress
      )
      .map((waitingParticipant) => waitingParticipant.participantId);

    let amountOfApprovedParticipants = 0;

    commit('SET_IS_APPROVE_ALL_IN_PROGRESS', true);

    try {
      await runWithMinimumDelay(async () => {
        await roomServiceApi.updateWaitingRoomParticipants(
          rootState.roomDetails.name,
          allWaitingParticipantIds,
          true,
          vbcApiGw.getCredentials().externalId
        );
      }, MINIMUM_DELAY_FOR_OWNER_ACTION_MILLISECONDS);
      amountOfApprovedParticipants = allWaitingParticipantIds.length;
    } catch (error) {
      if (error.response.status === 400) {
        const failedParticipantIds =
          error.response.data.data.failedParticipantIds;
        logger.error(
          'approve-all-waiting-room-participants',
          LOG_CATEGORIES.WAITING_ROOM,
          {
            error,
            allWaitingParticipantIds,
            failedParticipantIds,
            message: 'Failed to approve one or more waiting room participants'
          }
        );
        amountOfApprovedParticipants =
          allWaitingParticipantIds.length - failedParticipantIds.length;
      } else {
        logger.error(
          'approve-all-waiting-room-participants',
          LOG_CATEGORIES.WAITING_ROOM,
          {
            allWaitingParticipantIds,
            error,
            message: 'Failed to approve all waiting room participants '
          }
        );

        amountOfApprovedParticipants = 0;
        throw error;
      }
    } finally {
      await dispatch('fetchWaitingRoomParticipants');
      commit('SET_IS_APPROVE_ALL_IN_PROGRESS', false);

      if (amountOfApprovedParticipants === 0) {
        dispatch(
          'addFlashMessage',
          {
            type: 'critical',
            time: 5000,
            title: i18n.t('waiting_room_actions.title'),
            text: i18n.t('waiting_room_actions.approve_all_error_text')
          },
          { root: true }
        );
      } else {
        dispatch(
          'addFlashMessage',
          {
            type: 'good',
            time: 5000,
            title: i18n.t('waiting_room_actions.title'),
            text: i18n.tc(
              'waiting_room_actions.approve_all_text',
              amountOfApprovedParticipants,
              {
                amountOfApprovedParticipants
              }
            )
          },
          { root: true }
        );
      }
    }
  },

  handleParticipantListChanged: ({ dispatch }) => {
    dispatch('fetchWaitingRoomParticipants');
  },

  stopKeepaliveInterval: () => {
    clearInterval(keepAliveIntervalId);
    keepAliveIntervalId = null;
  },

  stopOwnerPollingInterval: () => {
    clearInterval(ownerPollingIntervalId);
    ownerPollingIntervalId = null;
  },

  clearIntervals: ({ state, dispatch }, { force } = { force: false }) => {
    if (!force && state.isInWaitingRoom) {
      return;
    }

    dispatch('stopOwnerPollingInterval');
    dispatch('stopKeepaliveInterval');
  },

  cancelWaitingRoomParticipation: async ({
    rootState,
    rootGetters,
    dispatch
  }) => {
    dispatch('clearIntervals', { force: true });

    // NOTE: Even if the cancellation http request fails, we still want to take the user to
    // the "cancelled" screen, and eitherway the user will no longer appear on the owner's
    // screen after a short time thanks to our keepalive mechanism
    try {
      await roomServiceApi.cancelWaitingRoomParticipation(
        rootState.roomDetails.name,
        vbcApiGw.getCredentials().externalId
      );
    } catch (error) {
      logger.error(
        'cancel-waiting-room-participation',
        LOG_CATEGORIES.WAITING_ROOM,
        {
          message:
            'Unexpected error when trying to cancel waiting room participation. User will see normal cancellation flow anyway',
          error
        }
      );
    }

    dispatch('showGlobalMessage', {
      title: rootGetters.isMobileWebMode
        ? { i18nKey: 'waiting_room_consts.mobile_messages_canceled_title' }
        : { i18nKey: '' },
      message: rootGetters.isMobileWebMode
        ? { i18nKey: '' }
        : {
            i18nKey: 'waiting_room_consts.desktop_messages_canceled'
          },
      reason: WAITING_ROOM_MESSAGE_ENUM.CANCELED,
      image: WAITING_ROOM_ILLUSTRATION_PATHS.BYE_IMG,
      brandedIcon: 'puzzled-line',
      showVonageLogo: false
    });
  },

  showGlobalMessage: async ({ dispatch, commit }, globalMessage) => {
    dispatch('stopKeepaliveInterval');
    commit('SET_IS_IN_WAITING_ROOM', false);
    dispatch('showGlobalMessage', globalMessage, { root: true });
  },

  sendJoinerFinishAnalytic: ({ state }, { status }) => {
    const secondsSinceJoined = Math.max(
      0,
      Math.ceil((Date.now() - state.joinedWaitingRoomTimestamp) / 1000)
    );

    analyticsService.trackEvent(ANALYTICS.WAITING_ROOM_JOINER_FINISH, {
      Status: status,
      Duration: secondsSinceJoined
    });
  },

  setHasFinishedArtificialTransitionToWaitingRoom: (
    { commit },
    { hasFinishedArtificalTransitionToWaitingRoom }
  ) => {
    commit(
      'SET_HAS_FINISHED_ARTIFICIAL_TRANSITION_TO_WAITING_ROOM',
      hasFinishedArtificalTransitionToWaitingRoom
    );
  },

  /**
   * Toggles the waiting room persistently: the new state is also applied to the main room and
   * the customizations service to affect future rooms
   */
  toggleWaitingRoom: async (
    { state, rootState, rootGetters, dispatch },
    { shouldApproveAllPendingParticipants = false } = {}
  ) => {
    const oldJoinApprovalLevel = rootState.sessionJoinApprovalLevel;
    const newJoinApprovalLevel = rootGetters.isWaitingRoomEnabled
      ? JOIN_APPROVAL_LEVEL.NONE
      : JOIN_APPROVAL_LEVEL.EXPLICIT_APPROVAL;

    if (
      shouldApproveAllPendingParticipants &&
      state.waitingParticipants.length > 0 &&
      newJoinApprovalLevel === JOIN_APPROVAL_LEVEL.NONE
    ) {
      await dispatch('approveAllWaitingRoomParticipants');
    }

    try {
      await dispatch(
        'updateSessionJoinApprovalLevel',
        {
          sessionId: rootState.sessionId,
          joinApprovalLevel: newJoinApprovalLevel
        },
        { root: true }
      );

      if (newJoinApprovalLevel === JOIN_APPROVAL_LEVEL.NONE) {
        dispatch('stopOwnerPollingInterval');
      } else {
        dispatch('initOwner');
      }

      dispatch(
        'addFlashMessage',
        {
          type: 'shoutout',
          text: rootGetters.isWaitingRoomEnabled
            ? i18n.t('waiting_room_actions.enabled_text')
            : i18n.t('waiting_room_actions.disabled_text')
        },
        { root: true }
      );
      analyticsService.trackEvent(ANALYTICS.WAITING_ROOM_SESSION_ACTIVATION, {
        Status: rootGetters.isWaitingRoomEnabled ? 'On' : 'Off'
      });
    } catch (error) {
      logger.error('toggle-waiting-room', LOG_CATEGORIES.WAITING_ROOM, {
        message: 'Unexpected error when trying to toggle waiting room.',
        oldJoinApprovalLevel,
        newJoinApprovalLevel,
        error
      });
      dispatch(
        'addFlashMessage',
        {
          type: 'critical',
          time: 5000,
          title: i18n.t('waiting_room_actions.title'),
          text:
            newJoinApprovalLevel === JOIN_APPROVAL_LEVEL.NONE
              ? i18n.t('waiting_room_actions.disable_waiting_room_error_text')
              : i18n.t('waiting_room_actions.enable_waiting_room_error_text')
        },
        { root: true }
      );
    }
  },

  setIsDisableWaitingRoomModalVisible: ({ commit }, isVisible) => {
    commit('SET_IS_DISABLE_WAITING_ROOM_MODAL_VISIBLE', isVisible);
  },

  viewWaitingRoom: ({ dispatch }, { source }) => {
    dispatch('layout/toggleSidebar', { shouldCollapse: false }, { root: true });
    dispatch('setActiveSidebar', SIDEBARS.PARTICIPANTS, { root: true });

    analyticsService.trackEvent(ANALYTICS.WAITING_ROOM_RESPONSES, {
      Status: 'View room',
      Source: source
    });
  },

  refreshElectronModalParticipants: ({ state, rootState }) => {
    if (!isElectron() || !rootState.minimizedMode) {
      return;
    }

    if (window.Electron.notifyWaitingRoomParticipantsUpdate) {
      window.Electron.notifyWaitingRoomParticipantsUpdate(
        state.waitingParticipants
      );
    }
  },

  undoDeny: async ({ state, commit }, { participantId }) => {
    const participant = state.waitingParticipants.find(
      (participant) => participant.participantId === participantId
    );
    clearTimeout(participant.denyTimer);

    commit('UPDATE_PARTICIPANT', {
      participantId: participantId,
      isDenyInProgress: false,
      isInUndoableState: false
    });
  }
};
