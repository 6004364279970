var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "div",
        {
          staticClass: "illustration-container",
          class: { branded: _vm.isBranded },
        },
        [
          _vm.isBranded
            ? _c("vwc-icon", {
                staticClass: "branded-illustration-icon",
                attrs: { size: "large", type: _vm.brandedIconType },
              })
            : _c("img", { attrs: { src: _vm.illustrationPath } }),
        ],
        1
      ),
      _c("vwc-text", {
        staticClass: "title",
        attrs: { tight: "", "font-face": "subtitle-2" },
        domProps: { innerHTML: _vm._s(_vm.title) },
      }),
      _c("vwc-text", {
        staticClass: "message",
        attrs: { tight: "", "font-face": "body-1" },
        domProps: { innerHTML: _vm._s(_vm.message) },
      }),
      _vm.isInSessionExpiredState
        ? _c("vwc-button", {
            staticClass: "rejoin-button",
            attrs: {
              "data-cy": "rejoin-waiting-room-button",
              label: _vm.$t("mobile_waiting_room_joiner_screen.rejoin_button"),
              connotation: "primary",
              layout: "filled",
            },
            on: { click: _vm.rejoinWaitingRoom },
          })
        : _vm.canCancel
        ? _c("vwc-button", {
            staticClass: "cancel-button",
            attrs: {
              label: _vm.$t("mobile_waiting_room_joiner_screen.cancel_button"),
              "data-cy": "cancel-waiting-room-participation-button",
              layout: "filled",
              connotation: "primary",
              disabled: _vm.isCancellingWaitingRoomParticipation,
            },
            on: { click: _vm.cancelWaitingRoomParticipation },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }