var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "fade" } }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showPreviewVideo,
            expression: "showPreviewVideo",
          },
        ],
        staticClass: "video-preview",
        attrs: { "data-cy": "preview-video-window" },
      },
      [
        _c("div", { staticClass: "video-container" }, [
          _c("div", { staticClass: "preview-text" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(" " + _vm._s(_vm.$t("preview_video.title")) + " - "),
              _c("span", { staticClass: "sub-title" }, [
                _vm._v(_vm._s(_vm.$t("preview_video.subtitle"))),
              ]),
            ]),
          ]),
          _c("div", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isVideoLoaded,
                expression: "isVideoLoaded",
              },
            ],
            ref: "video",
            staticClass: "video",
          }),
          !_vm.isVideoLoaded
            ? _c(
                "div",
                { staticClass: "loading-video" },
                [
                  _c("vwc-circular-progress", {
                    attrs: { indeterminate: "", density: "2.5" },
                  }),
                  _c("div", { staticClass: "loading-text" }, [
                    _vm._v(_vm._s(_vm.$t("preview_video.loading_text"))),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }