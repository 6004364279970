/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-video': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.35 7.65l-7.5-7.5c-.15 0-.45-.15-.6-.15h-12C1.8 0 1.5.3 1.5.75v22.5c0 .45.3.75.75.75h19.5c.45 0 .75-.3.75-.75v-15c0-.15-.15-.45-.15-.6zM15 2.55l4.95 4.95H15V2.55zm6 19.95H3v-21h10.5v6.75c0 .45.3.75.75.75H21v13.5z"/><path pid="1" d="M9.046 18.498l5.84-3.998-5.803-3.973a.158.158 0 00-.076-.027H9v7.985c.014.006.027.01.04.012l.006.001zm-.1-7.984zm.964-1.239l6.025 4.125c.753.55.753 1.65 0 2.2L9.91 19.725c-.904.687-2.41 0-2.41-1.1v-8.25c0-1.1 1.356-1.787 2.41-1.1z"/>'
  }
})
