var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Vlt-composite Vlt-composite--appendedicon input-container",
      class: {
        "Vlt-form__element--big": _vm.label,
        "Vlt-form__element--error": _vm.errorState,
      },
    },
    [
      _vm.searchIcon
        ? _c(
            "div",
            {
              staticClass:
                "Vlt-composite__prepend Vlt-composite__prepend--icon",
            },
            [_c("v-icon", { attrs: { iconName: "Vlt-icon-search" } })],
            1
          )
        : _vm.iconName
        ? _c(
            "div",
            {
              staticClass:
                "Vlt-composite__prepend Vlt-composite__prepend--icon",
            },
            [
              _c("v-icon", {
                staticClass: "prepend-icon",
                attrs: { iconName: _vm.iconName },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "Vlt-input v-input" }, [
        _vm.inputType === "checkbox"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputText,
                  expression: "inputText",
                },
                {
                  name: "focus",
                  rawName: "v-focus",
                  value: _vm.focus,
                  expression: "focus",
                },
              ],
              staticClass: "input",
              attrs: {
                id: "v-input-" + _vm._uid,
                pattern: _vm.pattern,
                inputmode: _vm.inputMode,
                maxlength: _vm.maxLength,
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
                autocomplete: "off",
                type: "checkbox",
              },
              domProps: {
                checked: Array.isArray(_vm.inputText)
                  ? _vm._i(_vm.inputText, null) > -1
                  : _vm.inputText,
              },
              on: {
                input: _vm.updateText,
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.submitText($event)
                },
                change: function ($event) {
                  var $$a = _vm.inputText,
                    $$el = $event.target,
                    $$c = $$el.checked ? true : false
                  if (Array.isArray($$a)) {
                    var $$v = null,
                      $$i = _vm._i($$a, $$v)
                    if ($$el.checked) {
                      $$i < 0 && (_vm.inputText = $$a.concat([$$v]))
                    } else {
                      $$i > -1 &&
                        (_vm.inputText = $$a
                          .slice(0, $$i)
                          .concat($$a.slice($$i + 1)))
                    }
                  } else {
                    _vm.inputText = $$c
                  }
                },
              },
            })
          : _vm.inputType === "radio"
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputText,
                  expression: "inputText",
                },
                {
                  name: "focus",
                  rawName: "v-focus",
                  value: _vm.focus,
                  expression: "focus",
                },
              ],
              staticClass: "input",
              attrs: {
                id: "v-input-" + _vm._uid,
                pattern: _vm.pattern,
                inputmode: _vm.inputMode,
                maxlength: _vm.maxLength,
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
                autocomplete: "off",
                type: "radio",
              },
              domProps: { checked: _vm._q(_vm.inputText, null) },
              on: {
                input: _vm.updateText,
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.submitText($event)
                },
                change: function ($event) {
                  _vm.inputText = null
                },
              },
            })
          : _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.inputText,
                  expression: "inputText",
                },
                {
                  name: "focus",
                  rawName: "v-focus",
                  value: _vm.focus,
                  expression: "focus",
                },
              ],
              staticClass: "input",
              attrs: {
                id: "v-input-" + _vm._uid,
                pattern: _vm.pattern,
                inputmode: _vm.inputMode,
                maxlength: _vm.maxLength,
                placeholder: _vm.placeholder,
                disabled: _vm.disabled,
                autocomplete: "off",
                type: _vm.inputType,
              },
              domProps: { value: _vm.inputText },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.inputText = $event.target.value
                  },
                  _vm.updateText,
                ],
                keyup: function ($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  ) {
                    return null
                  }
                  return _vm.submitText($event)
                },
              },
            }),
        _vm.label
          ? _c(
              "label",
              {
                staticClass: "unselectable",
                attrs: { for: "v-input-" + _vm._uid },
              },
              [_vm._v(_vm._s(_vm.label))]
            )
          : _vm._e(),
      ]),
      _vm.submitButton
        ? _c("div", { staticClass: "Vlt-composite__append" }, [
            _c(
              "button",
              {
                staticClass: "Vlt-btn Vlt-btn--icon",
                on: { click: _vm.submitText },
              },
              [
                _c("v-icon", {
                  class: { "Vlt-teal": _vm.inputText.length > 0 },
                  attrs: {
                    iconName:
                      _vm.inputText.length > 0
                        ? "Vlt-icon-send-negative"
                        : "Vlt-icon-send",
                  },
                }),
              ],
              1
            ),
          ])
        : _vm._e(),
      _vm.errorMessage || _vm.preservePlaceForError
        ? _c(
            "div",
            {
              staticClass: "error-container",
              attrs: { "data-cy": "input-error-container" },
            },
            [
              _vm.errorMessage
                ? _c(
                    "small",
                    {
                      staticClass:
                        "Vlt-form__element__error error-small-message",
                      style: _vm.errorStyle,
                    },
                    [_vm._v(_vm._s(_vm.errorMessage))]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }