<template>
  <div ref="layoutsButtonContainer" class="layouts-button-container">
    <vwc-button
      data-cy="layout-picker"
      :label="layouts[layoutMode].label"
      layout="ghost"
      :icon="layouts[layoutMode].selectedIcon"
      class="top-bar-button"
      dense
      @click="toggleLayoutMenu"
    >
      <vwc-icon
        slot="trailingIcon"
        :type="isLayoutMenuOpen ? 'chevron-up-solid' : 'chevron-down-solid'"
      ></vwc-icon>
    </vwc-button>

    <vwc-menu
      ref="layoutsMenu"
      class="menu"
      menuCorner="START"
      corner="BOTTOM_LEFT"
      :open="isLayoutMenuOpen"
      @closed="isLayoutMenuOpen = false"
    >
      <vwc-list-item
        v-for="layout in layoutOptions"
        :key="layout.value"
        graphic="icon"
        hasMeta
        class="list-item"
        data-cy="layout-option"
        @click="setLayoutMode({ layoutMode: layout.value })"
      >
        <span> {{ layout.label }}</span>
        <vwc-icon slot="graphic" :type="layout.icon" size="small" />
        <vwc-icon
          v-if="layout.checked"
          slot="meta"
          data-cy="layout-selected-icon"
          type="check-line"
          size="small"
        />
      </vwc-list-item>
    </vwc-menu>
  </div>
</template>

<script type="module">
import { mapState, mapActions } from 'vuex';
import { LAYOUT_MODE_TYPES } from '@/consts/global-consts';

export default {
  name: 'TopBarLayoutsButton',

  data() {
    return {
      isLayoutMenuOpen: false
    };
  },

  computed: {
    ...mapState('layout', ['layoutMode']),

    layouts() {
      return {
        [LAYOUT_MODE_TYPES.DOMINANT]: {
          label: this.$t('top_bar_layouts.dominant_label'),
          icon: 'layout-1-line',
          selectedIcon: 'layout-1-solid',
          value: LAYOUT_MODE_TYPES.DOMINANT
        },
        [LAYOUT_MODE_TYPES.SPEAKER]: {
          label: this.$t('top_bar_layouts.speaker_label'),
          icon: 'layout-2-line',
          selectedIcon: 'layout-2-solid',
          value: LAYOUT_MODE_TYPES.SPEAKER
        },
        [LAYOUT_MODE_TYPES.GRID]: {
          label: this.$t('top_bar_layouts.grid_label'),
          icon: 'apps-line',
          selectedIcon: 'apps-solid',
          value: LAYOUT_MODE_TYPES.GRID
        }
      };
    },

    layoutOptions() {
      return Object.values(this.layouts).map((layout) => ({
        ...layout,
        checked: this.layoutMode === layout.value
      }));
    }
  },

  mounted() {
    this.$refs.layoutsMenu.anchor = this.$refs.layoutsButtonContainer;
  },

  methods: {
    ...mapActions('layout', ['setLayoutMode']),
    toggleLayoutMenu() {
      this.isLayoutMenuOpen = !this.isLayoutMenuOpen;
    }
  }
};
</script>

<style scoped>
.layouts-button-container {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.top-bar-button {
  margin: 0 5px;
}

.list-item {
  width: 200px;
}
</style>
