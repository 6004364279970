/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-phone-garland': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 12c0-.3-.1-.6-.3-.8l-1.9-1.8s.7-3.1.6-3.4c-.2-.3-.4-.5-.7-.5l-2.5-.6-.6-2.5c-.1-.4-.3-.6-.6-.8-.3-.2-.6-.2-.9-.1l-2.5.7L12.8.3c-.2-.2-.5-.3-.8-.3s-.6.1-.8.3L9.4 2.2l-2.5-.7c-.3-.1-.6 0-.9.1-.3.2-.5.4-.5.7l-.7 2.5-2.5.7c-.3 0-.5.2-.7.5-.1.3-.2.6-.1.9l.7 2.5-1.9 1.8c-.2.2-.3.5-.3.8s.1.6.3.8l1.9 1.8-.7 2.5c-.1.3 0 .6.1.9.2.3.4.5.7.5l2.5.6.6 2.5c.1.4.3.6.6.8.3.2.6.2.9.1l2.5-.7 1.8 1.9c.2.2.5.3.8.3s.6-.1.8-.3l1.8-1.9 2.5.7c.3.1.6 0 .9-.1.3-.2.5-.4.5-.7l.6-2.5 2.5-.6c.3-.1.5-.3.7-.5.2-.3.2-.6.1-.9l-.7-2.5 1.9-1.8c.3-.3.4-.6.4-.9zm-3.9 2.2l.8 3-3 .8-.8 3-3-.8-2.1 2.1-2.2-2.2-3 .8-.8-3-3-.8.8-3L1.7 12l2.2-2.2-.9-3L6 6l.8-3 3 .8L12 1.7l2.2 2.2 3-.8.8 3 2.9.7c0 .2-.1.4-.1.7-.1.4-.2.8-.2 1.1 0 .1-.1.3-.1.3V9l-.2.8 2.2 2.1-2.4 2.3z"/><path pid="1" d="M16.3 12.8c-.1-.1-.4-.3-.8-.5-.7-.4-1.5-.7-2.3-.5-.1 0-.3.1-.4.2-.2-.1-.3-.3-.4-.3l-.3-.3c.1-.1.1-.3.2-.4.2-.8-.1-1.6-.5-2.3-.3-.4-.5-.7-.5-.8-.2-.3-.5-.6-.9-.8-.5-.4-.9-.4-1.4-.3h-.1l-.4.2c-.7.3-1.2.8-1.5 1.4-.3.6-.3 1.3-.1 2 .1.5.3 1.1.6 1.6s.6 1 .9 1.4c.3.5.7.9 1 1.2.3.3.7.7 1.2 1 .5.3.9.6 1.4.9.5.3 1.1.5 1.6.6.7.2 1.4.1 2-.1.6-.3 1.2-.8 1.4-1.5l.2-.4V15c.1-.4.1-.8-.1-1.2-.2-.5-.5-.8-.8-1zm-.6 1.8l-.1.4c-.1.3-.3.5-.6.7-.3.1-.6.1-1 0s-.8-.2-1.2-.5c-.4-.2-.8-.5-1.2-.8-.4-.3-.7-.6-1-.9-.3-.3-.6-.6-.9-1-.3-.4-.6-.8-.8-1.2-.2-.4-.4-.8-.5-1.2-.1-.4-.1-.8 0-1 .1-.4.3-.6.6-.7l.4-.1h.3c.1 0 .2.1.2.2.1.1.2.4.5.8.4.6.4.9.3 1.1-.1.2-.1.3-.2.4l-.3.4.3.5c.1.1.2.2.3.4.2.3.4.5.5.6.1.1.4.3.6.5.1.1.2.2.4.3l.5.3.4-.3c.1-.1.2-.1.4-.2s.5 0 1.1.3c.2.1.4.3.5.3.1 0 .1.1.2.1l.2.2c.2.2.2.3.1.4z"/>'
  }
})
