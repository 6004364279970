import { getIdFromUrl } from 'vue-youtube';
import { parse as parseDuration } from 'tinyduration';
import axios from 'axios';
import VideoMetadata from '@/services/watch-together/video-metadata';
import VideoSiteTypeEnum from '@/services/watch-together/video-site-type-enum';
import {
  VideoUrlValidationError,
  VIDEO_URL_ERROR_REASON
} from '@/services/watch-together/video-url-validation-error';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';

// TODO: Move this to backend to prevent leaking the API key
const YOUTUBE_API_KEY = 'AIzaSyB99fLlojMJgG2e0ixfRqkh0TD1GISgE0E';

/**
 * Parses the duration string to integer of seconds
 * @param {*} duration Duration string, as returned by YouTube data API v3
 * @returns Total seconds integer
 */
function parseYoutubeVideoDuration(duration) {
  const parsedDuration = {
    years: 0,
    months: 0,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
    ...parseDuration(duration)
  };

  return (
    parsedDuration.years * 31536000 +
    parsedDuration.months * 2628288 +
    parsedDuration.days * 86400 +
    parsedDuration.hours * 3600 +
    parsedDuration.minutes * 60 +
    parsedDuration.seconds
  );
}

export function getYoutubeIdByUrl(url) {
  return getIdFromUrl(url);
}

export async function getYoutubeVideoMetadata(url) {
  const videoId = getYoutubeIdByUrl(url);

  let videoMetadataResponse;
  try {
    videoMetadataResponse = await axios.get(
      `https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails,status&id=${videoId}&part=contentDetails&key=${YOUTUBE_API_KEY}`
    );
  } catch (error) {
    if (error.response && error.response.status === 403) {
      logger.error('watch-together-youtube-quota', LOG_CATEGORIES.API_CALL, {
        message: 'YouTube 10k video metadata requests quota exceed',
        error
      });
    }
  }

  if (
    !videoMetadataResponse ||
    !videoMetadataResponse.data ||
    !videoMetadataResponse.data.items ||
    videoMetadataResponse.data.items.length === 0
  ) {
    throw new VideoUrlValidationError(url, VIDEO_URL_ERROR_REASON.UNKNOWN);
  }

  const rawMetadata = videoMetadataResponse.data.items[0];

  if (rawMetadata.snippet.liveBroadcastContent === 'live') {
    throw new VideoUrlValidationError(url, VIDEO_URL_ERROR_REASON.LIVE_VIDEO);
  }

  if (!rawMetadata.status.embeddable) {
    throw new VideoUrlValidationError(
      url,
      VIDEO_URL_ERROR_REASON.VIDEO_NOT_EMBEDDABLE
    );
  }

  const videoTitle = rawMetadata.snippet.title;
  const author = rawMetadata.snippet.channelTitle;
  const thumbnailUrl = rawMetadata.snippet.thumbnails.medium.url;
  const duration = parseYoutubeVideoDuration(
    rawMetadata.contentDetails.duration
  );

  return new VideoMetadata(
    VideoSiteTypeEnum.YouTube,
    videoId,
    videoTitle,
    duration,
    thumbnailUrl,
    author
  );
}
