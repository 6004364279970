/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-headset': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3.05 3.05A7 7 0 0115 8v4.5a2.5 2.5 0 01-2.5 2.5H11v-5h2.5a.5.5 0 000-1h-3a.5.5 0 00-.5.5v6a.5.5 0 00.5.5h2a3.5 3.5 0 003.5-3.5V8A8 8 0 000 8v4.5A3.5 3.5 0 003.5 16h2a.5.5 0 00.5-.5v-6a.5.5 0 00-.5-.5h-3a.5.5 0 000 1H5v5H3.5A2.5 2.5 0 011 12.5V8a7 7 0 012.05-4.95z"/>'
  }
})
