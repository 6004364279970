/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-fax-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.5 0h15v4.5h-15V0zM1.5 7.5h21c.9 0 1.5.6 1.5 1.5v9c0 .9-.6 1.5-1.5 1.5h-3v3c0 .9-.6 1.5-1.5 1.5H6c-.9 0-1.5-.6-1.5-1.5v-3h-3C.6 19.5 0 18.9 0 18V9c0-.9.6-1.5 1.5-1.5zm6 13.5h9v-7.5h-9V21z"/>'
  }
})
