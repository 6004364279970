/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-leave-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 3H5.1l4.95 4.95c.3.3.45.6.45 1.05v13.5c0 .6-.3 1.2-.9 1.35-.15.15-.45.15-.6.15-.45 0-.75-.15-1.05-.45l-7.5-7.5c-.3-.3-.45-.6-.45-1.05V1.5C0 .6.6 0 1.5 0h12c.9 0 1.5.6 1.5 1.5V6h-3V3zM3 14.4l4.5 4.5V9.6L3 5.1v9.3zm15-9l5.55 5.55c.6.6.6 1.5 0 2.1L18 18.6l-2.1-2.1 3-3H12v-3h6.9l-3-3L18 5.4z"/>'
  }
})
