/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-quit-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.5 0h3v10.5h-3V0zM6.046 20.648a10.5 10.5 0 0011.907 0A10.5 10.5 0 0019 4.175l-2 2.235A7.5 7.5 0 117 6.41L5 4.175a10.5 10.5 0 001.046 16.473z"/>'
  }
})
