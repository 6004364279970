/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-video-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 0h18a1 1 0 011 1v22a1 1 0 01-1 1H3a1 1 0 01-1-1V1a1 1 0 011-1zm7.708 16.987l5.021-3.937c.628-.525.628-1.575 0-2.1l-5.02-3.937c-.88-.656-2.009 0-2.009 1.05v7.874c0 1.05 1.255 1.707 2.008 1.05z"/>'
  }
})
