/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-clio-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 12c0 6.595 5.405 12 12 12s12-5.405 12-12c0-2.808-.98-5.4-2.614-7.452.735-1.026 1.524-2.129 2.366-3.308L20.8 3.867C18.603 1.493 15.466 0 12 0 5.405 0 0 5.405 0 12zm19.34-6.834a4.205 4.205 0 00-3.224-1.497H7.884A4.211 4.211 0 003.67 7.884v8.232c0 1.14.447 2.181 1.24 2.975a4.248 4.248 0 002.975 1.24h8.232a4.211 4.211 0 004.215-4.215V7.884c0-.524-.096-1.025-.27-1.487-3.627 5.057-5.67 7.869-6.127 8.479-.893 1.19-2.43 1.587-3.322 0-.455-.795-1.227-2.254-2.339-4.356-.344-.652-.722-1.365-1.133-2.14l4.91 3.273 7.29-6.487z" _fill="#0276BB"/>'
  }
})
