/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-down-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.563 8.329L12 13.579l-6.562-5.25a1.5 1.5 0 00-1.875 2.343l7.5 6a1.5 1.5 0 001.875 0l7.5-6a1.5 1.5 0 10-1.875-2.343z"/>'
  }
})
