/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calls-disabled': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.766 1.366A.8.8 0 0022.634.234l-22.4 22.4a.8.8 0 001.132 1.132l6.156-6.156c3.324 3.28 7.82 5.75 10.719 5.75h.64c2.24-.32 3.68-2.08 4.16-3.84.32-1.28 0-2.4-.8-3.04-2.4-1.92-3.84-2.72-5.44-2.72H16c-.96.16-1.6.64-2.08 1.12-.06-.04-.13-.085-.21-.135a11.767 11.767 0 01-1.88-1.445L23.766 1.366zm-12.98 12.978L8.722 16.41c3.152 3.144 7.225 5.35 9.68 5.35h.48c2.4-.48 3.36-3.36 2.56-4-2.24-1.76-3.36-2.4-4.48-2.4h-.48c-1.28.16-1.76 1.44-1.76 1.44s-1.819-.39-3.934-2.456zM8.962 9.6c.074.148.165.322.277.518l-1.121 1.12C7.187 9.836 7.04 8.96 7.04 8.96c.96-.64 1.44-1.28 1.44-2.4 0-1.12-1.76-3.68-2.24-4.32-.16-.16-.48-.48-.96-.48-.16 0-.16 0-.32.16-.036.012-.083.026-.14.042-.723.206-3.013.858-2.42 4.118.46 2.536 1.752 4.99 3.575 7.3l-1.138 1.137C2.64 11.668 1.293 8.987.801 6.4.16 3.36 1.6 1.12 4.64.32c.16-.16.48-.16.64-.16C6.4.16 7.2.8 7.52 1.28c1.12 1.6 2.56 3.84 2.56 5.28.16 1.44-.48 2.4-1.12 3.04z"/>'
  }
})
