var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vwc-dialog",
    {
      staticClass: "vvd-scheme-alternate modal",
      attrs: { hideActions: "", connotation: "cta", open: true },
    },
    [
      _c(
        "div",
        { staticClass: "modal-content" },
        [
          _c("div", {
            staticClass: "title pre-formatted",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _c("div", {
            staticClass: "pre-formatted",
            domProps: { innerHTML: _vm._s(_vm.content) },
          }),
          _c("vwc-linear-progress", {
            staticClass: "linear-progress",
            attrs: {
              indeterminate: "",
              connotation: _vm.linearProgressConnotation,
              progress: 0.5,
              buffer: 1,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }