/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-google': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.285.655h-.002A11.867 11.867 0 001.36 6.58l-.04-.03C.776 7.53.45 8.514.232 9.605c-.545 2.62-.11 5.348 1.088 7.75l.046-.035-.046.035a9.066 9.066 0 002.054 2.82 11.992 11.992 0 005.782 3.4 13.27 13.27 0 006.203.11 12.203 12.203 0 001.662-.52 10.032 10.032 0 003.139-1.82c.07-.056.137-.113.205-.17 1.524-1.31 2.503-3.056 3.047-5.021.054-.204.103-.41.148-.616.52-1.92.579-3.918.179-5.824H12.203v4.803h6.622c-.243 1.48-1.103 2.75-2.378 3.601l.05.038c-.851.528-1.704.947-2.662 1.054a6.674 6.674 0 01-2.938 0 7.68 7.68 0 01-2.72-1.2c-1.307-.983-2.286-2.184-2.83-3.712l-.086.065.086-.065a6.95 6.95 0 01-.03-4.607l.03.023a6.146 6.146 0 011.85-2.838c1.85-1.855 4.57-2.62 7.183-1.855.659.22 1.268.54 1.829.924.195.148.383.312.565.495l.04-.04c.06.049.119.098.177.149.381-.382.762-.737 1.143-1.092.381-.354.762-.71 1.143-1.091l1.197-1.2c-.776-.779-1.717-1.39-2.707-1.875a14.81 14.81 0 00-1.429-.636c-2.503-.873-5.441-.873-8.053 0z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
