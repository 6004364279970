/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-fax-failed': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 2.2v1.5h3v18h11.6l6.2-6.2.2-.2V3.8h3V2.2H0zm15 17v-3.4h3.4L15 19.2zm4.5-5h-6v6h-9V3.8h15v10.4z"/><path pid="1" d="M10.5 13.9c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5S6 7 6 9.4s2 4.5 4.5 4.5zm3-4.5c0 1.7-1.3 3-3 3-.6 0-1.2-.2-1.7-.6L13.2 8c.2.5.3.9.3 1.4zm-3-3c.6 0 1.2.2 1.6.5l-4.4 3.8c-.2-.4-.3-.8-.3-1.3.1-1.6 1.4-3 3.1-3z"/>'
  }
})
