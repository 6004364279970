/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-rocket-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M24 0c-4.5.3-8.25 1.65-11.1 3.6C9.75 2.1 6 2.7 3.45 5.4c-.45.45-.9 1.05-1.35 1.65l3.75 3.75c-1.05 1.8-1.65 3-1.65 3l6.15 6.15s1.2-.45 3-1.65l3.75 3.75c.6-.3 1.2-.75 1.65-1.35 2.55-2.55 3.15-6.45 1.8-9.6C22.35 8.1 23.7 4.5 24 0zm-7 9a2 2 0 11-4 0 2 2 0 014 0zM5.1 18.9c-1.2-1.2-3-1.2-4.2 0C-.3 19.95 0 24 0 24s3.9.3 5.1-.9c1.2-1.2 1.2-3 0-4.2z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
