var render = function () {
  var _obj
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-visibility",
          rawName: "v-observe-visibility",
          value: {
            callback: _vm.visibilityChanged,
            throttle: 2000,
            throttleOptions: {
              leading: "visible",
            },
          },
          expression:
            "{\n    callback: visibilityChanged,\n    throttle: 2000,\n    throttleOptions: {\n      leading: 'visible'\n    }\n  }",
        },
      ],
      staticClass: "stream",
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.canShowVideo,
              expression: "canShowVideo",
            },
          ],
          staticClass: "video-container",
        },
        [
          _c("div", {
            ref: "video",
            staticClass: "video-element",
            class:
              ((_obj = {}),
              (_obj[_vm.stream.type] = true),
              (_obj["fitmode-contain"] = _vm.fitMode === "contain"),
              (_obj["fitmode-cover"] = _vm.fitMode === "cover"),
              _obj),
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }