/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-tool-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 24c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12c0 1.923.452 3.74 1.257 5.352l6.762-5.008-.054-.265c-.105-.506-.196-.946-.196-1.478A5.585 5.585 0 0113.385 5c.748 0 1.497.124 2.121.498l-2.995 2.987 2.995 2.987 3.12-2.987c.25.623.374 1.37.374 2.116a5.585 5.585 0 01-5.615 5.601c-.624 0-1.248-.124-1.872-.249l-5.002 6.72A11.948 11.948 0 0012 24z"/>'
  }
})
