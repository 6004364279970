/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-search-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M23.56 19.94l-4.06-4.061A10.424 10.424 0 0021 10.5C21 4.71 16.288 0 10.5 0S0 4.71 0 10.5 4.712 21 10.5 21c1.968 0 3.804-.553 5.379-1.5l4.06 4.06a1.496 1.496 0 002.122 0l1.5-1.5a1.498 1.498 0 000-2.12zM10.5 18a7.5 7.5 0 11.002-15.002A7.5 7.5 0 0110.5 18z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
