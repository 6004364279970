import Vue from 'vue';
export default {
  SET_IS_BE_RIGHT_BACK_ON: (state, isBeRightBackOn) =>
    (state.isBeRightBackOn = isBeRightBackOn),

  ADD_BE_RIGHT_BACK_STATUS: (state, connectionId) => {
    Vue.set(state.beRightBackStatuses, connectionId, {});
  },

  REMOVE_BE_RIGHT_BACK_STATUS: (state, connectionId) => {
    Vue.delete(state.beRightBackStatuses, connectionId);
  }
};
