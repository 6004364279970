/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-copy-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.75 0A.75.75 0 000 .75v16.5c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75V.75a.75.75 0 00-.75-.75H.75zm.75 16.5v-15h15v15h-15zM20.25 6a.75.75 0 000 1.5h2.25v15h-15v-2.25a.75.75 0 00-1.5 0v3c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75V6.75a.75.75 0 00-.75-.75h-3z"/>'
  }
})
