var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      on: {
        drop: function ($event) {
          $event.preventDefault()
        },
        dragenter: function ($event) {
          $event.preventDefault()
        },
        dragleave: function ($event) {
          $event.preventDefault()
        },
        dragover: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "main-app",
          class: [_vm.minimizedMode ? "Vlt-bg-white" : "black-background"],
        },
        [
          _c(
            "div",
            {
              staticClass: "main-screen Vlt-white",
              class: {
                "grid-view": _vm.layoutMode === _vm.LAYOUT_MODE_TYPES.GRID,
                "speaker-view":
                  _vm.layoutMode === _vm.LAYOUT_MODE_TYPES.SPEAKER,
                "dominant-view":
                  _vm.layoutMode === _vm.LAYOUT_MODE_TYPES.DOMINANT,
                "minimized-mode": _vm.minimizedMode,
              },
            },
            [
              _c("portal-target", {
                attrs: { name: "overlay-bg-placeholder" },
              }),
              _vm.isWatchTogetherModalShown
                ? _c("WatchTogetherModal")
                : _vm._e(),
              _vm.isVirtualBackgroundModalVisible
                ? _c("VirtualBackgroundModal")
                : _vm._e(),
              _c(
                "div",
                { staticClass: "screen-container" },
                [
                  _c(
                    "div",
                    { staticClass: "screen-wrapper" },
                    [
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.hasSessionEverConnected
                          ? _c("div", { staticClass: "main-area-container" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "videos-wrapper",
                                  class: {
                                    "no-cursor": !_vm.showFullscreenElements,
                                    "sidebar-collapsed": _vm.isSidebarCollapsed,
                                  },
                                  on: { mousemove: _vm.updateIsMouseIdle },
                                },
                                [
                                  _vm.isReactionsCounterModalOpen
                                    ? _c("ReactionsCounterModal", {
                                        staticClass: "reactions-counter-modal",
                                      })
                                    : _vm._e(),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "waiting-room-owner-modal-container",
                                      class: {
                                        "waiting-room-empty":
                                          _vm.isWaitingRoomEmpty,
                                      },
                                    },
                                    [
                                      _c(
                                        "transition",
                                        {
                                          attrs: {
                                            name:
                                              "waiting-room-modal-fade-and-fly",
                                          },
                                        },
                                        [
                                          !_vm.minimizedMode &&
                                          _vm.isWaitingRoomOwnerModalShown
                                            ? _c("WaitingRoomOwnerModal", {
                                                staticClass:
                                                  "waiting-room-owner-modal",
                                                attrs: {
                                                  waitingParticipants:
                                                    _vm.waitingParticipants,
                                                  isApproveAllInProgress:
                                                    _vm.isApproveAllInProgress,
                                                  undoWindowDuration:
                                                    _vm.UNDO_WINDOW_DURATION,
                                                  viewRoomButtonLabel: _vm.$t(
                                                    "app_screen.show_waiting_room_button"
                                                  ),
                                                  approveAllButtonLabel: _vm.$t(
                                                    "app_screen.waiting_room_approve_all_button"
                                                  ),
                                                  singleWaitingStringFormat: _vm.$t(
                                                    "app_screen.waiting_room_single_participant_waiting_msg_format"
                                                  ),
                                                  pluralWaitingStringFormat: _vm.$t(
                                                    "app_screen.waiting_room_plural_participants_waiting_msg_format"
                                                  ),
                                                },
                                                on: {
                                                  viewRoomClicked:
                                                    _vm.viewWaitingRoom,
                                                  approveAllClicked:
                                                    _vm.approveAllWaitingParticipants,
                                                  updateParticipantClicked:
                                                    _vm.updateWaitingParticipant,
                                                  undoDenyClicked: _vm.undoDeny,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  !_vm.minimizedMode
                                    ? _c(
                                        "div",
                                        [
                                          !_vm.isOnline
                                            ? _c("NoConnectionBanner")
                                            : _vm._e(),
                                          _vm.isOldAccount
                                            ? _c("OldAccountBanner")
                                            : _vm._e(),
                                          _vm.showSubscriberStatsWidget
                                            ? _c("SubscriberStats")
                                            : _vm._e(),
                                          _vm.showReportIssueModal
                                            ? _c("ReportIssueModal")
                                            : _vm._e(),
                                          _vm.showSelectLanguageModal
                                            ? _c("SelectLanguageModal")
                                            : _vm._e(),
                                          _vm.isEndMeetingDialogVisible
                                            ? _c("EndMeetingModal", {
                                                attrs: {
                                                  shouldCloseWindow: "",
                                                  showCancelButton: "",
                                                },
                                              })
                                            : _vm._e(),
                                          _vm.isTurnOnAudioDialogVisible
                                            ? _c("TurnOnAudioModal")
                                            : _vm._e(),
                                          _vm.confirmationDialog
                                            ? _c("ConfirmationDialogModal")
                                            : _vm._e(),
                                          _vm.isLockMeetingDialogVisible
                                            ? _c("LockMeetingModal")
                                            : _vm._e(),
                                          _vm.participantToRemove
                                            ? _c("RemoveParticipantModal")
                                            : _vm._e(),
                                          _vm.isHijackScreenshareDialogVisible
                                            ? _c("HijackScreenshareModal")
                                            : _vm._e(),
                                          _vm.isMuteAllParticipantsModalVisible
                                            ? _c("MuteAllParticipantsModal")
                                            : _vm._e(),
                                          _vm.screenshareSources.length
                                            ? _c(
                                                "ScreenshareSourcesPickerModal"
                                              )
                                            : _vm._e(),
                                          _vm.errorDialog
                                            ? _c("ErrorModal", {
                                                attrs: { showCancelButton: "" },
                                              })
                                            : _vm._e(),
                                          _vm.infoDialog
                                            ? _c("InfoModal")
                                            : _vm._e(),
                                          !_vm.isGuest ? _c("Tour") : _vm._e(),
                                          _vm.isWhiteboardLargeSessionModalVisible
                                            ? _c("LargeWhiteboardSessionModal")
                                            : _vm._e(),
                                          _vm.isDisableWaitingRoomModalVisible
                                            ? _c("DisableWaitingRoomModal")
                                            : _vm._e(),
                                          _vm.isInviteParticipantsDialogVisible
                                            ? _c("InviteParticipantsModal")
                                            : _vm._e(),
                                          _vm.isSettingsModalVisible
                                            ? _c("SettingsModal")
                                            : _vm._e(),
                                          _vm.layoutMode !==
                                            _vm.LAYOUT_MODE_TYPES.GRID ||
                                          !_vm.showVideoStreams
                                            ? _c(
                                                "div",
                                                { staticClass: "reactions" },
                                                [_c("ScreenReactions")],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "layout-picker Vlt-bg-white",
                                        },
                                        [_c("LayoutPicker")],
                                        1
                                      ),
                                  _vm.isNetworkIssues && !_vm.minimizedMode
                                    ? _c("ReconnectingBanner")
                                    : _vm._e(),
                                  _vm.shouldShowDeviceDisabledBanner
                                    ? _c("DisabledDeviceBanner", {
                                        attrs: {
                                          isMicDisabled:
                                            _vm.isMicrophonePausedSafari,
                                          isVideoDisabled:
                                            _vm.isVideoPausedSafari,
                                        },
                                      })
                                    : _vm._e(),
                                  _c("ParticipantsTileList", {
                                    attrs: {
                                      showOnlyMainStream: !_vm.showFullscreenElements,
                                      updateStreamSizesWrapper:
                                        _vm.updateStreamSizesWrapper,
                                    },
                                  }),
                                  _c(
                                    "transition",
                                    {
                                      attrs: { name: "captions-fade-and-fly" },
                                    },
                                    [
                                      _vm.showCaptions
                                        ? _c(
                                            "div",
                                            { staticClass: "captions-wrapper" },
                                            [
                                              _c("CaptionsView", {
                                                class: {
                                                  "bottom-bar-transition":
                                                    _vm.isFloatingBottomBar,
                                                },
                                                attrs: {
                                                  "offset-y":
                                                    _vm.captionsOffsetY,
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _vm._e(),
                      ]),
                      !_vm.minimizedMode ? _c("Sidebar") : _vm._e(),
                    ],
                    1
                  ),
                  !_vm.minimizedMode && _vm.hasSessionEverConnected
                    ? _c("MeetingActions", {
                        ref: "meetingActions",
                        class: {
                          "bottom-bar-transition": _vm.isFloatingBottomBar,
                        },
                        attrs: {
                          floating: _vm.isFloatingBottomBar,
                          show: _vm.showBottomBar,
                        },
                        nativeOn: {
                          mouseenter: function ($event) {
                            _vm.hoveringActionButtons = true
                          },
                          mouseleave: function ($event) {
                            _vm.hoveringActionButtons = false
                          },
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }