/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-curl-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M12 1H0l2.069 17.69L12 23.759l9.931-5.07L24 1H12zm8.69 11.069c0 .207 0 .31-.207.414l-6.62 3.207-.518.207-.104-.621v-1.552l.207-.103 4.655-2.07-4.344-1.965-1.862 3.724-.931 2.07-.207.413-.414-.207-6.828-3.103c-.207-.104-.31-.207-.31-.414v-1.138c0-.207.103-.31.31-.414l6.931-3.207.621-.31v2.276l-.31.103L6 11.45l4.448 1.965 1.655-3.207 1.242-2.586.207-.414.414.207 6.62 3.103c.104.104.104.207.104.414v1.138z" _fill="#0B3754"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
