/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'callout-tip': {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M0 10C0 4.5 4.5 0 10 0s10 4.5 10 10-4.5 10-10 10S0 15.5 0 10zm1.25 0A8.696 8.696 0 0010 18.75 8.696 8.696 0 0018.75 10 8.696 8.696 0 0010 1.25 8.696 8.696 0 001.25 10zM10 5c-.75 0-1.25.5-1.25 1.25S9.25 7.5 10 7.5s1.25-.5 1.25-1.25S10.75 5 10 5zm0 4.375c-.375 0-.625.25-.625.625v4.375c0 .375.25.625.625.625s.625-.25.625-.625V10c0-.375-.25-.625-.625-.625z"/>'
  }
})
