/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-block': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM1 8c0-3.9 3.1-7 7-7 1.8 0 3.4.6 4.6 1.7l-9.9 9.9C1.6 11.4 1 9.8 1 8zm7 7c-1.8 0-3.4-.6-4.6-1.7l9.9-9.9C14.4 4.6 15 6.2 15 8c0 3.9-3.1 7-7 7z"/>'
  }
})
