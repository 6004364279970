import MeetingManager from '@/services/meeting-manager-service';
import {
  ANALYTICS,
  LARGE_WHITEBOARD_SESSION_THRESHOLD
} from '@/consts/global-consts';
import { PARTICIPANT_STATE_TYPES } from '@/consts/global-consts';
import ChildIFrameCommunication from '@/helpers/embedded/child-iframe-communication';
import analytics from '@/services/analytics-service';
import * as vbcGw from '@/apis/vbc-gw';
import * as roomService from '@/apis/room-service-api';
import whiteboardAnalytics from './whiteboard-analytics'; // Take telemetries and send relevant analytics
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import debounce from 'lodash.debounce';
import i18n from '@/i18n';

const EVENT_TYPES = {
  STOP_WHITEBOARD: 'stop-whiteboard',
  NUMBER_OF_PARTICIPANTS: 'number-of-participants',
  TELEMETRY: 'telemetry',
  CLICKED_ANYWHERE: 'clicked-anywhere',
  LOADED: 'whiteboard-loaded'
};

let whiteboardIframe = null;
let clickedAnywhereEventListeners = [];
let whiteboardLoadedEventListeners = [];

export default {
  init: ({ dispatch }) => {
    whiteboardIframe = new ChildIFrameCommunication(
      'whiteboard-iframe',
      process.env.VUE_APP_EMBEDDABLE_WHITEBOARD_URL
    );
    dispatch('registerWhiteboardIFrameEvents');
  },

  registerWhiteboardIFrameEvents: ({ dispatch }) => {
    whiteboardIframe.onMessage((event) => {
      const whiteboardEvent = event.data;
      switch (whiteboardEvent.name) {
        case EVENT_TYPES.STOP_WHITEBOARD:
          dispatch('stopWhiteboard');
          break;
        case EVENT_TYPES.TELEMETRY:
          dispatch('handleWhiteboardTelemetry', whiteboardEvent.data);
          break;
        case EVENT_TYPES.CLICKED_ANYWHERE:
          dispatch('emitClickedAnywhereEvent');
          break;
        case EVENT_TYPES.LOADED:
          dispatch('emitWhiteboardLoadedEvent');
          break;
      }
    });
  },

  activateIncomingWhiteboard: (
    { commit, dispatch },
    { whiteboardId, token }
  ) => {
    commit('SET_WHITEBOARD_ID', whiteboardId);
    commit('SET_WHITEBOARD_TOKEN', token);
    commit('SET_IS_WHITEBOARD_ACTIVE', true);
    dispatch('pinWhiteboard');
  },

  startWhiteboard: async ({ rootState, rootGetters, commit, dispatch }) => {
    // Basic large session handling - start the board locked in large meetings
    const isLargeWhiteboardSession =
      rootGetters.activeParticipants.length >=
      LARGE_WHITEBOARD_SESSION_THRESHOLD;
    let newWhiteboard;
    try {
      newWhiteboard = await roomService.createWhiteboardSession(
        vbcGw.getCredentials().externalId,
        rootState.sessionId,
        {
          boardName: `${rootGetters.myParticipant.displayName}'s whiteboard`,
          isLocked: isLargeWhiteboardSession
        }
      );
    } catch (err) {
      logger.error('start-whiteboard', LOG_CATEGORIES.WHITEBOARD, {
        err,
        sessionId: rootState.sessionId
      });
      dispatch(
        'addFlashMessage',
        {
          type: 'critical',
          text: i18n.t('whiteboard_actions.start_whiteboard_error_text')
        },
        { root: true }
      );
      return;
    }
    const { id, token } = newWhiteboard;

    logger.log('start-whiteboard', LOG_CATEGORIES.WHITEBOARD, {
      whiteboardId: id,
      isLargeWhiteboardSession
    });

    commit('SET_WHITEBOARD_OWNER_PARTICIPANT_ID', rootState.myParticipantId);
    dispatch('activateIncomingWhiteboard', { whiteboardId: id, token: token });

    if (isLargeWhiteboardSession) {
      // TODO: Make this modal appear for real
      commit('SET_WHITEBOARD_LARGE_SESSION_MODAL_VISIBLE', true);
    }

    analytics.trackEvent(ANALYTICS.WHITEBOARD_SHARED, {
      Owner: rootGetters.isSessionOwner ? 'Yes' : 'No',
      'Join Type': rootGetters.isGuest ? 'Guest' : 'Application',
      'Num of Participants': rootGetters.activeParticipants.length
    });
  },

  stopWhiteboard: ({ state, getters, dispatch }) => {
    if (!getters.isWhiteboardOwner) {
      return;
    }

    logger.log('stop-whiteboard', LOG_CATEGORIES.WHITEBOARD, {
      whiteboardId: state.whiteboardId
    });

    dispatch('clearState');
  },

  emitStopWhiteboardEvent: ({ getters, dispatch }) => {
    if (getters.isWhiteboardOwner) {
      whiteboardIframe.sendMessage({ name: EVENT_TYPES.STOP_WHITEBOARD });
    } else {
      // When a user clicks the Whiteboard app button and they don't own the active whiteboard,
      // they probably mean to start their own whiteboard. This dialog informs them that they cannot do that
      dispatch(
        'setInfoDialog',
        {
          title: i18n.t('whiteboard_actions.another_one_already_share_title', {
            whiteboardOwnerName: getters.whiteboardOwnerName
          }),
          text: i18n.t('whiteboard_actions.another_one_already_share_text')
        },
        { root: true }
      );
    }
  },

  handleParticipantStateUpdate: ({ dispatch, state, rootGetters }, event) => {
    if (!state.isWhiteboardActive) {
      return;
    }

    if (event.state === PARTICIPANT_STATE_TYPES.JOINED) {
      whiteboardIframe.sendMessage({
        name: EVENT_TYPES.NUMBER_OF_PARTICIPANTS,
        data: { numberOfParticipants: rootGetters.activeParticipants.length }
      });
    } else if (
      [PARTICIPANT_STATE_TYPES.LEFT, PARTICIPANT_STATE_TYPES.KICKED].includes(
        event.state
      )
    ) {
      dispatch('_handleParticipantLeft', {
        participantId: event.participantId
      });
      whiteboardIframe.sendMessage({
        name: EVENT_TYPES.NUMBER_OF_PARTICIPANTS,
        data: { numberOfParticipants: rootGetters.activeParticipants.length }
      });
    }
  },

  _handleParticipantLeft: ({ state, dispatch }, { participantId }) => {
    // We are only interested in detecting the owner leaving
    if (
      !state.whiteboardOwnerParticipantId ||
      participantId !== state.whiteboardOwnerParticipantId
    ) {
      return;
    }

    dispatch('clearState');
  },

  clearState: ({ getters, commit, dispatch }) => {
    commit('SET_IS_WHITEBOARD_ACTIVE', false);
    commit('SET_WHITEBOARD_OWNER_PARTICIPANT_ID', null);
    commit('SET_WHITEBOARD_ID', null);
    commit('SET_WHITEBOARD_TOKEN', null);
    clickedAnywhereEventListeners = [];
    whiteboardLoadedEventListeners = [];

    if (getters.isWhiteboardPinned) {
      dispatch('pinVideoStream', '', { root: true });
    }
  },

  dismissLargeWhiteboardSessionModal: ({ commit }) => {
    commit('SET_WHITEBOARD_LARGE_SESSION_MODAL_VISIBLE', false);
  },

  handleWhiteboardTelemetry: (_, telemetry) => {
    whiteboardAnalytics.sendTelemetryAnalytic(telemetry);
  },

  pinWhiteboard: ({ dispatch }) => {
    dispatch('pinVideoStream', 'whiteboard', { root: true });
  },

  unpinWhiteboard: ({ dispatch }) => {
    dispatch('pinVideoStream', '', { root: true });
  },

  registerClickedAnywhereListener: (_, callback) => {
    clickedAnywhereEventListeners.push(callback);
  },

  registerWhiteboardLoadedListener: (_, callback) => {
    whiteboardLoadedEventListeners.push(callback);
  },

  emitClickedAnywhereEvent: () => {
    clickedAnywhereEventListeners.forEach(async (listener) => {
      listener();
    });
  },

  emitWhiteboardLoadedEvent: () => {
    whiteboardLoadedEventListeners.forEach(async (listener) => {
      listener();
    });
  },

  // This action is called for every change in the session, so we debounce it
  handleSessionUpdated: debounce(async ({ state, dispatch }, session) => {
    if (
      !session.active_whiteboard ||
      state.whiteboardId === session.active_whiteboard.id
    ) {
      return;
    }

    // Only join whiteboards when the meeting session is already connected
    MeetingManager.onSessionConnected(async () => {
      const whiteboardId = session.active_whiteboard.id;
      const token = await roomService.obtainWhiteboardToken(
        whiteboardId,
        vbcGw.getCredentials().externalId
      );
      dispatch('activateIncomingWhiteboard', { whiteboardId, token });
    });
  }, 1000)
};
