/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-small-file': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 9h-9V0H3c-.9 0-1.5.6-1.5 1.5v21c0 .9.6 1.5 1.5 1.5h18c.9 0 1.5-.6 1.5-1.5V9zm-6-3V0l6 6h-6z"/>'
  }
})
