// MESSAGING
export const COLORS = [
  '#4bc899',
  '#91cfac',
  '#bfb37c',
  '#90a7e2',
  '#73d1fa',
  '#dda3a3',
  '#f495bf',
  '#6c9ed5',
  '#f8c064',
  '#e6808a',
  '#61d6d6',
  '#f7924a',
  '#c792d4',
  '#d49292',
  '#5ab0e9',
  '#a992d4',
  '#b4c58a',
  '#c8824b',
  '#cfa6b8',
  '#d492bb',
  '#66bbb4',
  '#bf9c7c',
  '#84c7c7',
  '#f57769',
  '#a3c8dd',
  '#a8cbc0',
  '#f58f69',
  '#ff8c8c',
  '#faa97a',
  '#4cc2d2',
  '#7398bb'
];

export const DARK_MODE_COLORS = [
  '#0276D5',
  '#9941FF',
  '#1C8731',
  '#BE5702',
  '#D34204',
  '#D6219C',
  '#E61D1D',
  '#65BAFF'
];

// ATTACHMENTS
export const UNSUPPORTED_ATTACHMENTS_FILES = ['exe'];

export const ATTACHMENT_TYPE = {
  IMAGE: 0,
  DOCUMENT: 2,
  NOT_SUPPORTED: -1
};

export const SUPPORTED_IMAGES_TYPES = [
  'jpeg',
  'jpg',
  'gif',
  'png',
  'apng',
  'bmp'
];

export const UPLOAD_ERRORS_TYPES = {
  TYPE_NOT_SUPPORTED: 'typeNotSupported',
  MAX_NUMBER_OF_FILES_EXCEEDED: 'maxNumberOfFiles',
  TOTAL_SIZE_EXCEEDED: 'totalSizeExceeded'
};

export const MAX_NUMBER_OF_FILES_TO_UPLOAD = 50;

export const MAX_SIZE_OF_FILES_TO_UPLOAD = 1024 * 1024 * 1024;

export const MESSAGE_TYPE = {
  TEXT: 'text-message',
  IMAGE: 'image',
  VIDEO: 'video',
  DOCUMENT: 'document'
};

export const MEDIA_TYPE = {
  IMAGE: 'image',
  VIDEO: 'video',
  FILE: 'file'
};

export const MESSAGES_POLLING_INTERVAL_IN_MILLISECONDS = 30 * 1000;

export const MESSAGES_PAGE_SIZE = 200;
