<template>
  <popper
    ref="popper"
    trigger="focus"
    class="audio-button-popper"
    :visible-arrow="false"
    :disabled="!showToggleAudioButtonPopper"
    :forceShow="showToggleAudioButtonPopper"
    :options="{ placement: 'top-start' }"
  >
    <div
      v-show="showToggleAudioButtonPopper"
      class="popper"
      :class="popperClass"
    >
      <WatchTogetherMuteIndication
        v-if="showWatchTogetherMuteIndication"
        @close="closeWatchTogetherMuteIndication"
      />
      <MuteIndication v-else-if="muteIndication" @close="closePopper" />
    </div>
    <slot slot="reference"></slot>
  </popper>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import MuteIndication from '@/components/popovers/toggleAudioButton/MuteIndication.vue';
import WatchTogetherMuteIndication from '@/components/popovers/toggleAudioButton/WatchTogetherMuteIndication.vue';

export default {
  name: 'ToggleAudioButtonPopovers',

  components: {
    MuteIndication,
    WatchTogetherMuteIndication
  },

  computed: {
    ...mapState('loudnessDetector', ['muteIndication']),
    ...mapState('watchTogether/ongoing', ['showWatchTogetherMuteIndication']),
    ...mapGetters(['showToggleAudioButtonPopper']),

    popperClass() {
      if (this.showWatchTogetherMuteIndication) {
        return 'large-audio-button-announcement';
      } else if (this.muteIndication) {
        return 'mute-indication';
      }
      return '';
    }
  },

  methods: {
    ...mapActions('watchTogether/ongoing', [
      'setShowWatchTogetherMuteIndication'
    ]),

    closePopper() {
      this.$refs.popper.doClose();
    },

    closeWatchTogetherMuteIndication() {
      this.setShowWatchTogetherMuteIndication(false);
      this.closePopper();
    }
  }
};
</script>

<style scoped>
.audio-button-popper {
  position: relative;
}

.popper {
  background: rgba(24, 25, 27, 0.98);
  border: 1px solid #43474f;
  border-radius: 6px;
  padding: 6px 16px;
  white-space: nowrap;
  width: auto;
  z-index: 100;
  text-align: center;
  box-shadow: none;
  color: white;
  font-weight: 600;
}
</style>
