/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-blogger': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.4 9.3c-.45-.15-2.7 0-3.3-.45-.45-.45-.45-2.85-1.35-4.65C17.7 1.95 15.15 0 12.9 0H7.65C3.45 0 0 3.45 0 7.65v8.85C0 20.7 3.45 24 7.65 24h8.7c4.2 0 7.5-3.45 7.65-7.5v-6.15s0-.75-.6-1.05zM7.5 6H12c.9 0 1.5.6 1.5 1.5S12.9 9 12 9H7.5C6.6 9 6 8.4 6 7.5S6.6 6 7.5 6zm9 12h-9c-.9 0-1.5-.6-1.5-1.5S6.6 15 7.5 15h9c.9 0 1.5.6 1.5 1.5s-.6 1.5-1.5 1.5z"/>'
  }
})
