/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-files-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 0h15c.9 0 1.5.6 1.5 1.5v18h-3V3H6V0zM3 6h12c.9 0 1.5.6 1.5 1.5v15c0 .9-.6 1.5-1.5 1.5H3c-.9 0-1.5-.6-1.5-1.5v-15C1.5 6.6 2.1 6 3 6z"/>'
  }
})
