/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-copy': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.9 4.1l-4-4c-.1 0-.3-.1-.4-.1h-5c-.3 0-.5.2-.5.5V2h1V1h4v3.5c0 .3.2.5.5.5H15v7h-4v1h4.5c.3 0 .5-.2.5-.5v-8c0-.1-.1-.3-.1-.4zM12 4V1.7L14.3 4H12z"/><path pid="1" d="M5.9 3.1c-.1 0-.3-.1-.4-.1h-5c-.3 0-.5.2-.5.5v12c0 .3.2.5.5.5h9c.3 0 .5-.2.5-.5v-8c0-.1-.1-.3-.1-.4l-4-4zM6 4.7L8.3 7H6V4.7zM9 15H1V4h4v3.5c0 .3.2.5.5.5H9v7z"/>'
  }
})
