/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'dominant-icon': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4 17a1 1 0 01-1-1V4a1 1 0 011-1h12a1 1 0 011 1v12a1 1 0 01-1 1H4zM0 1a1 1 0 011-1h18a1 1 0 011 1v18a1 1 0 01-1 1H1a1 1 0 01-1-1V1zm6 4a1 1 0 00-1 1v8a1 1 0 001 1h8a1 1 0 001-1V6a1 1 0 00-1-1H6z" _fill="#131415"/>'
  }
})
