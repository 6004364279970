/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-laptop': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2.146 3.146A.5.5 0 012.5 3h11a.5.5 0 01.5.5v6a.5.5 0 001 0v-6A1.5 1.5 0 0013.5 2h-11A1.5 1.5 0 001 3.5v6a.5.5 0 001 0v-6a.5.5 0 01.146-.354zM.5 11a.5.5 0 00-.5.5A2.5 2.5 0 002.5 14h11a2.5 2.5 0 002.5-2.5.5.5 0 00-.5-.5H.5zm.94 1.56a1.5 1.5 0 01-.354-.56h13.828a1.5 1.5 0 01-1.414 1h-11a1.5 1.5 0 01-1.06-.44z"/>'
  }
})
