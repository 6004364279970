/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-world': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.3 21.7c.8-1 1.4-2.3 1.9-3.8h3.2c-1.3 1.7-3.1 3.1-5.1 3.8zM3.7 17.9h3.2c.5 1.5 1.1 2.8 1.9 3.8-2.1-.7-3.9-2.1-5.1-3.8zm-1-10.1h3.8c-.3 1.4-.4 2.8-.4 4.2 0 1.6.2 3.2.5 4.6H2.9c-.7-1.4-1.1-3-1.1-4.6 0-1.5.3-2.9.9-4.2zm6-5.5c-.8 1-1.5 2.5-2 4.2H3.4c1.2-1.9 3.1-3.4 5.3-4.2zm11.9 4.2h-3.4c-.5-1.7-1.2-3.2-2-4.2 2.3.8 4.2 2.3 5.4 4.2zm-3.1 1.3h3.8c.6 1.3.9 2.7.9 4.2 0 1.6-.4 3.2-1.1 4.6h-3.6c.3-1.5.5-3 .5-4.6-.1-1.4-.2-2.8-.5-4.2zm-4.8 8.8V7.8h3.6c.3 1.3.4 2.7.4 4.2 0 1.6-.2 3.2-.5 4.6h-3.5zm0 5.5v-4.2h3.1c-.8 2.2-1.9 3.8-3.1 4.2zm-1.3-4.2v4.2c-1.3-.4-2.4-2-3.1-4.2h3.1zm0-16v4.6H8.1c.7-2.4 1.9-4.2 3.3-4.6zm1.3 0c1.3.4 2.5 2.2 3.2 4.6h-3.2V1.9zM7.4 12c0-1.5.2-2.9.4-4.2h3.6v8.7H7.9c-.4-1.4-.5-3-.5-4.5zM12 .5C5.7.5.5 5.7.5 12S5.7 23.5 12 23.5 23.5 18.3 23.5 12 18.3.5 12 .5z"/>'
  }
})
