/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-user': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M5.167 4a2.833 2.833 0 115.666 0 2.833 2.833 0 01-5.667 0zM8 .167a3.833 3.833 0 100 7.666A3.833 3.833 0 008 .167zM8 9.5c-2.133 0-3.747.667-4.836 1.561C2.094 11.94 1.5 13.07 1.5 14v1.333a.5.5 0 00.5.5h12a.5.5 0 00.5-.5V14c0-.927-.594-2.057-1.664-2.936C11.748 10.169 10.133 9.5 8 9.5zM2.5 14c0-.543.381-1.413 1.298-2.166C4.697 11.096 6.082 10.5 8 10.5c1.917 0 3.303.598 4.201 1.336.918.754 1.299 1.625 1.299 2.164v.833h-11V14z"/>'
  }
})
