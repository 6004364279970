/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-tool': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.25 0C13.5 0 10.5 3 10.5 6.75c0 .75.15 1.35.3 2.1L.3 16.65c-.15.15-.3.3-.3.6 0 .15 0 .45.15.6l6 6c.15.15.45.15.6.15.15 0 .45-.15.6-.3l7.8-10.5c.75.15 1.35.3 2.1.3 3.75 0 6.75-3 6.75-6.75 0-1.05-.3-1.95-.6-2.7-.15-.15-.3-.45-.6-.45s-.45 0-.6.15l-2.7 2.7-1.95-1.95 2.7-2.7c.15-.15.3-.45.15-.6 0-.3-.15-.45-.45-.6-.75-.3-1.65-.6-2.7-.6zM12 6.75c0-2.85 2.4-5.25 5.25-5.25.3 0 .75 0 1.05.15l-2.4 2.4c-.15.15-.15.3-.15.6s.15.45.15.6l3 3c.3.3.75.3 1.05 0l2.4-2.4c.15.15.15.6.15.9 0 2.85-2.4 5.25-5.25 5.25-.75 0-1.35-.15-2.1-.45-.3-.15-.75 0-.9.3L6.6 22.05l-4.8-4.8L12 9.6c.3-.15.45-.6.3-.9-.15-.6-.3-1.2-.3-1.95z"/>'
  }
})
