/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-thin-right-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 10.5h13.5V3L24 12l-10.5 9v-7.5H0v-3z"/>'
  }
})
