/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vonage-logo-collapsed': {
    width: 27,
    height: 24,
    viewBox: '0 0 27 24',
    data: '<path pid="0" d="M5.332 0l5.107 11.56-2.53 6.073a.163.163 0 01-.302.002L0 0h5.332zm16.242 0H27s-8.034 18.796-8.995 20.606C17.16 22.197 15.635 24 13.52 24H8.636a.03.03 0 01-.03-.03.03.03 0 01.025-.03c1.56-.25 2.403-1.032 3.676-3.08C13.4 19.099 21.574 0 21.574 0z" _fill="#131414" fill-rule="evenodd"/>'
  }
})
