/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-expand-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.9 9l4.05-4.05L12 0h12v12l-4.95-4.95L15 11.1 12.9 9zM9 12.9l2.1 2.1-4.05 4.05L12 24H0V12l4.95 4.95L9 12.9z"/>'
  }
})
