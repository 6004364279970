/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-chat-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 0h-15A1.5 1.5 0 000 1.5V12a1.5 1.5 0 001.5 1.5h3V18L9 13.5h7.5A1.5 1.5 0 0018 12V1.5A1.5 1.5 0 0016.5 0zM21 6h1.5A1.5 1.5 0 0124 7.5V18a1.5 1.5 0 01-1.5 1.5h-3V24L15 19.5H7.5l2.742-3H19.5A1.5 1.5 0 0021 15V6z"/>'
  }
})
