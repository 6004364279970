/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-add-user': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4 4a3 3 0 116 0 3 3 0 01-6 0zm3-4a4 4 0 100 8 4 4 0 000-8zm-.5 9A6.5 6.5 0 000 15.5a.5.5 0 00.5.5h9a.5.5 0 000-1H1.023A5.5 5.5 0 016.5 10h1a5.48 5.48 0 012.194.458.5.5 0 00.4-.916A6.48 6.48 0 007.5 9h-1zm6 0a.5.5 0 01.5.5V12h2.5a.5.5 0 010 1H13v2.5a.5.5 0 01-1 0V13H9.5a.5.5 0 010-1H12V9.5a.5.5 0 01.5-.5z"/>'
  }
})
