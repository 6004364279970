/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-thumbs-up': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 8c0-.282.188-.47.47-.47h2.824c.282 0 .47.188.47.47v7.53c0 .282-.188.47-.47.47H.471C.188 16 0 15.812 0 15.53V8zm.941.47v6.589h1.883V8.47H.94zM12.141 15.53H5.647c-.282 0-.47-.189-.47-.471s.188-.47.47-.47h6.494c.283 0 .659-.095.847-.283.283-.188.47-.47.47-.753l1.036-4.706v-.659c0-.188-.188-.376-.282-.564-.094-.189-.283-.283-.47-.377-.19-.094-.377-.094-.566-.094H9.412c-.283-.094-.47-.282-.47-.565V2.824c0-.377-.19-.753-.377-1.036-.189-.188-.471-.282-.753-.376L6.118 6.777c-.095.188-.377.376-.66.282-.187-.094-.376-.377-.282-.659L7.06.753a.518.518 0 01.47-.282c.66 0 1.224.282 1.694.659.471.376.66 1.035.66 1.694v3.294h3.293c.377 0 .66.094 1.036.188.282.188.564.376.847.659.188.282.376.565.47.941.095.376.095.659 0 1.035l-1.035 4.706c-.094.565-.376 1.035-.847 1.318-.47.376-.941.565-1.506.565z"/>'
  }
})
