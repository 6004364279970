var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      class: { branded: _vm.isBranded },
      attrs: {
        heading: _vm.$t("settings_modal.title"),
        "min-width": 600,
        "hide-actions": "",
        "icon-name": "gear-line",
        close: _vm.closeModal,
        "data-cy": "settings-modal",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "settings-modal-content unselectable",
          attrs: { slot: "content" },
          slot: "content",
        },
        [
          _c(
            "div",
            { staticClass: "side-nav" },
            [
              _c(
                "vwc-list",
                { staticClass: "nav-list" },
                _vm._l(_vm.settings, function (setting) {
                  return _c(
                    "vwc-list-item",
                    {
                      key: setting.id,
                      staticClass: "setting",
                      class: { selected: _vm.selectedSetting === setting.id },
                      attrs: {
                        graphic: "icon",
                        shape: "rounded",
                        connotation: "primary",
                        "data-cy": "setting-category",
                      },
                      on: {
                        click: function ($event) {
                          _vm.selectedSetting = setting.id
                        },
                      },
                    },
                    [
                      _c("vwc-icon", {
                        staticClass: "setting-icon",
                        class: { selected: _vm.selectedSetting === setting.id },
                        attrs: { slot: "graphic", type: setting.icon },
                        slot: "graphic",
                      }),
                      !_vm.$screen.sm && !_vm.$screen.xs
                        ? _c("div", [_vm._v(_vm._s(setting.label))])
                        : _vm._e(),
                    ],
                    1
                  )
                }),
                1
              ),
              _c(
                "div",
                {
                  staticClass: "app-version",
                  attrs: { "data-cy": "app-version" },
                  on: { click: _vm.clickOnAppVersion },
                },
                [_vm._v(" " + _vm._s(_vm.appVersion) + " ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "content-container" },
            [
              _vm.isAudioSettings
                ? _c("AudioSettings", { staticClass: "content" })
                : _vm._e(),
              _vm.isCameraSettings
                ? _c("CameraSettings", { staticClass: "content" })
                : _vm._e(),
              _vm.isNotificationsSettings
                ? _c("NotificationsSettings", { staticClass: "content" })
                : _vm._e(),
              _vm.isAdvancedSettings
                ? _c("AdvancedSettings", { staticClass: "content" })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }