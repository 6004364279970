/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-meeting-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M3 3v7.5a1.5 1.5 0 01-3 0v-9A1.5 1.5 0 011.5 0h6a1.5 1.5 0 110 3H3zM23.56.44A1.5 1.5 0 0124 1.5v13.509a4.663 4.663 0 01-1.74 3.45l-4.62 3.945a5.965 5.965 0 01-3.912 1.448 6.025 6.025 0 01-2.064-.38 3 3 0 01-3.813-.351l-5.379-5.379A3 3 0 116.716 13.5l5.377 5.378c.496.499.8 1.158.855 1.86a2.981 2.981 0 002.736-.611l.207-.177-2.167-2.163a.75.75 0 111.062-1.062l2.25 2.25 1.282-1.097-2.349-2.347a.749.749 0 01.244-1.225.752.752 0 01.818.163l2.432 2.433.849-.725a1.8 1.8 0 00.103-2.641l-6.29-6.29-4.03 3.136a3.9 3.9 0 01-5.155-.322 1.5 1.5 0 010-2.12l5.962-5.963A6.705 6.705 0 0115.675 0H22.5a1.5 1.5 0 011.06.44z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
