var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "unselectable" }, [
    _vm.loaderTypeSpinner
      ? _c(
          "div",
          { staticClass: "preload-spinner-wrapper" },
          [
            _c("vwc-circular-progress", {
              attrs: {
                density: "8",
                indeterminate: "",
                connotation: "primary",
              },
            }),
            _c("div", { staticClass: "loading-text" }, [
              _vm._v(_vm._s(_vm.loaderText)),
            ]),
          ],
          1
        )
      : _c(
          "div",
          { staticClass: "meetings-loader-wrapper" },
          [
            _c("MeetingLoader"),
            _c("div", { staticClass: "meetings-loader-text unselectable" }, [
              _vm._v(_vm._s(_vm.loaderText)),
            ]),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }