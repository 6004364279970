/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-bottom-tabs-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 21V3a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 3v18a1.5 1.5 0 001.5 1.5h21A1.5 1.5 0 0024 21zm-3-1.5H3v-15h18v15zM4.5 18v-4.5h15V18h-15z"/>'
  }
})
