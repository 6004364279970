/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-enter': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.75 3a.75.75 0 00-.75.75v4.5a.75.75 0 01-1.5 0v-4.5A2.25 2.25 0 019.75 1.5h12A2.25 2.25 0 0124 3.75v18A2.25 2.25 0 0121.75 24h-12a2.25 2.25 0 01-2.25-2.25v-4.5a.75.75 0 011.5 0v4.5c0 .414.336.75.75.75h12a.75.75 0 00.75-.75v-18a.75.75 0 00-.75-.75h-12zM0 12.75A.75.75 0 01.75 12h14.69l-3.22-3.22a.75.75 0 011.06-1.06l4.5 4.5a.748.748 0 010 1.06l-4.5 4.5a.75.75 0 11-1.06-1.06l3.22-3.22H.75a.75.75 0 01-.75-.75z"/>'
  }
})
