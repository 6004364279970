/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-credit-card': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.5 2h-13C.7 2 0 2.7 0 3.5v9c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-9c0-.8-.7-1.5-1.5-1.5zm.5 10.5c0 .3-.2.5-.5.5h-13c-.3 0-.5-.2-.5-.5V7h14v5.5zM15 6H1V3.5c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5V6z"/><path pid="1" d="M11.5 11h1c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-1c-.3 0-.5.2-.5.5s.2.5.5.5zM3.5 11h3c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-3c-.3 0-.5.2-.5.5s.2.5.5.5z"/>'
  }
})
