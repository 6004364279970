/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calendar-3-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 3h3A1.5 1.5 0 0124 4.5v18a1.5 1.5 0 01-1.5 1.5h-21A1.5 1.5 0 010 22.5v-18A1.5 1.5 0 011.5 3h3V0h3v3h3V0h3v3h3V0h3v3zM3 21h18V7.5H3V21zm7.5-4.5h6v-3h-3v-3h-3v6z"/>'
  }
})
