/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-alarm-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.75 0c1.65 0 3.15.6 4.2 1.5-4.8.45-8.7 3.75-10.35 8.1C.3 8.7 0 7.8 0 6.75 0 3 3 0 6.75 0zm10.5 0c-1.65 0-3.15.6-4.2 1.5 4.8.45 8.85 3.6 10.35 8.1.3-.9.6-1.8.6-2.85C24 3 21 0 17.25 0zm2.1 18.75c1.05-1.5 1.65-3.3 1.65-5.25 0-4.95-4.05-9-9-9s-9 4.05-9 9c0 1.95.6 3.75 1.65 5.25l-2.7 2.7c-.6.6-.6 1.5 0 2.1.3.3.6.45 1.05.45.45 0 .75-.15 1.05-.45l2.7-2.7c1.5 1.05 3.3 1.65 5.25 1.65 1.95 0 3.75-.6 5.25-1.65l2.7 2.7c.3.3.75.45 1.05.45.3 0 .75-.15 1.05-.45.6-.6.6-1.5 0-2.1l-2.7-2.7zM10.5 15V9h3v3h3v3h-6z"/>'
  }
})
