/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-density-high': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.2 6H2.8c-.5 0-.8-.3-.8-.7 0-.4.3-.8.7-.8h18.6c.4 0 .8.3.8.7-.1.4-.4.8-.9.8.1 0 .1 0 0 0zm0 6.8H2.8c-.5 0-.8-.4-.8-.8s.3-.8.8-.8h18.5c.4 0 .8.3.8.8-.1.4-.4.8-.9.8zm0 3.3H2.8c-.4 0-.8-.3-.8-.8 0-.4.3-.8.8-.8h18.5c.4 0 .8.3.8.8-.1.5-.4.8-.9.8zm0-6.7H2.8c-.5 0-.8-.4-.8-.8s.3-.8.8-.8h18.5c.4 0 .8.3.8.8-.1.4-.4.8-.9.8zm0 10.1H2.8c-.4 0-.8-.3-.8-.7 0-.4.3-.8.7-.8h18.6c.4 0 .8.3.8.7-.1.4-.4.8-.9.8.1 0 .1 0 0 0z"/>'
  }
})
