<template>
  <div class="app-screens-wrapper">
    <LoadingScreen
      v-if="showLoadingOverlay"
      class="loading-screen"
      :loader-type-spinner="loaderTypeSpinner"
    />
    <FlashMessages v-if="!minimizedMode" class="flash-messages" />
    <SafariNotificationModal v-if="isSafari" />
    <transition name="fade">
      <AppScreen v-if="isAppReady" />
      <PreJoin v-else-if="showPreJoinScreen" />
      <EntranceScreen v-else-if="showEntranceScreen" />
    </transition>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import AppScreen from '@/views/AppScreen';
import LoadingScreen from '@/views/LoadingScreen.vue';
import EntranceScreen from '@/views/EntranceScreen.vue';
import PreJoin from '@/components/PreJoin';
import FlashMessages from '@/components/FlashMessages';
import SafariNotificationModal from '@/components/modals/SafariNotificationModal';

export default {
  name: 'Home',

  components: {
    PreJoin,
    AppScreen,
    LoadingScreen,
    EntranceScreen,
    FlashMessages,
    SafariNotificationModal
  },

  data() {
    return {
      isSafari: window.isSafari
    };
  },

  computed: {
    ...mapState([
      'showEntranceScreen',
      'hasSessionEverConnected',
      'showPreJoinScreen',
      'isBranded',
      'minimizedMode'
    ]),
    ...mapGetters([
      'isAppReady',
      'showLoadingOverlay',
      'isMeetingAlreadyInitialized',
      'isAppUserEnteringAppScreen',
      'isBeforeAppScreen'
    ]),

    loaderTypeSpinner() {
      const showMeetingLoader =
        !this.isBranded &&
        (this.isMeetingAlreadyInitialized ||
          this.isBeforeAppScreen ||
          this.isAppReady ||
          this.isAppUserEnteringAppScreen);

      return !showMeetingLoader;
    }
  },

  created() {
    if (!window.Cypress || this.$route.query.is_e2e) {
      this.initApp(this.$route.query);
    }
  },

  methods: {
    ...mapActions(['initApp'])
  }
};
</script>
<style scoped>
.app-screens-wrapper {
  position: static;
  height: 100%;
}

.loading-screen {
  position: absolute;
  z-index: 9999;
  height: 100%;
  width: 100%;
}

.fade-enter-active {
  transition: opacity 1s;
}

.fade-enter {
  opacity: 0;
}

.flash-messages >>> .Vlt-flash {
  left: 20px;
  width: 380px;
  margin-left: 0;
}
</style>
