/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video-whiteboard': {
    width: 30,
    height: 30,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M18.011 2.5H2.308C1.033 2.5 0 3.563 0 4.875v19c0 1.312 1.033 2.375 2.308 2.375h25.384c1.275 0 2.308-1.063 2.308-2.375V9.489l-1.625 1.625v12.761c0 .46-.349.75-.683.75H2.308c-.334 0-.683-.29-.683-.75v-19c0-.46.349-.75.683-.75h14.078L18.011 2.5zM8.438 28.125c-.563 0-.938.375-.938.938 0 .562.375.937.938.937h13.124c.563 0 .938-.375.938-.938 0-.562-.375-.937-.938-.937H8.438z" _fill="#fff"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M23.4.275a.937.937 0 011.325 0l5 5a.938.938 0 010 1.325l-11.25 11.25a.937.937 0 01-.479.257l-6.875 1.875a.937.937 0 01-1.103-1.103l1.875-6.875a.937.937 0 01.257-.48L23.4.275zm4.337 5.662l-3.674-3.674L13.675 12.65l-1.543 5.217 5.217-1.543L27.737 5.937z" _fill="#fff"/>'
  }
})
