/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-checkbox-unchecked': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.25 0A2.25 2.25 0 000 2.25v19.5A2.25 2.25 0 002.25 24h19.5A2.25 2.25 0 0024 21.75V2.25A2.25 2.25 0 0021.75 0H2.25zM1.5 2.25a.75.75 0 01.75-.75h19.5a.75.75 0 01.75.75v19.5a.75.75 0 01-.75.75H2.25a.75.75 0 01-.75-.75V2.25z"/>'
  }
})
