/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-php-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 6C5.345 6 0 8.727 0 12.11c0 3.38 5.345 6.108 12 6.108 6.654 0 12-2.727 12-6.109S18.654 6 12 6zm-3.164 7.31c-.327.217-.654.326-.981.544-.328.11-.764.11-1.31.11h-1.2l-.327 1.854H3.6l1.31-6.654h2.726c.873 0 1.419.218 1.855.654.436.437.545 1.091.327 1.855-.109.327-.218.654-.327.873-.218.218-.327.545-.655.763zm4.255.544l.545-2.836c.11-.327 0-.545-.109-.654-.109-.11-.327-.219-.763-.219h-1.091l-.764 3.71H9.6l1.31-6.437h1.417L12 9.164h1.31c.763 0 1.308.109 1.635.436.328.327.437.655.219 1.31l-.546 2.944h-1.527zm7.854-2.181c-.109.327-.218.654-.327.873-.109.218-.327.545-.545.763-.328.218-.655.327-.982.546-.327.109-.764.109-1.31.109h-1.2l-.326 1.854h-1.419l1.31-6.654h2.727c.872 0 1.418.218 1.854.654.328.437.437 1.091.218 1.855zm-2.727-1.527h-.982l-.545 2.727h.873c.545 0 .982-.11 1.309-.327.327-.219.436-.546.545-1.091.11-.437 0-.873-.109-1.091-.109-.11-.545-.219-1.09-.219zm-11.127 0h-.982l-.545 2.727h.872c.546 0 .982-.11 1.31-.327.327-.219.436-.546.545-1.091.109-.437 0-.873-.11-1.091-.217-.11-.545-.219-1.09-.219z" _fill="#6181B6"/>'
  }
})
