<template>
  <div
    ref="participant"
    class="participant unselectable"
    :class="{
      'main-participant': isMainParticipant
    }"
    :style="{ ...participantSize }"
    @click="onParticipantClick"
    @dblclick="onParticipantDoubleClick"
  >
    <div v-if="isParticipantRaisingHand">
      <div
        class="raise-hand-yellow-indication"
        :style="{
          transform: `translate(2px, 2px)`
        }"
      />
      <div
        class="raise-hand-yellow-indication"
        :style="{
          transform: `translate(-2px, 2px)`
        }"
      />
      <div
        class="raise-hand-yellow-indication"
        :style="{
          transform: `translate(2px, -2px)`
        }"
      />
      <div
        class="raise-hand-yellow-indication"
        :style="{
          transform: `translate(-2px, -2px)`
        }"
      />
    </div>
    <div v-else-if="showSpeakerIndication">
      <!-- TODO: this solution is a bit of overkill. rethink about it -->
      <div
        class="speaker-indication"
        :style="{
          transform: `translate(${speakerIndicationSize}px, ${speakerIndicationSize}px)`
        }"
      />
      <div
        class="speaker-indication"
        :style="{
          transform: `translate(-${speakerIndicationSize}px, ${speakerIndicationSize}px)`
        }"
      />
      <div
        class="speaker-indication"
        :style="{
          transform: `translate(${speakerIndicationSize}px, -${speakerIndicationSize}px)`
        }"
      />
      <div
        class="speaker-indication"
        :style="{
          transform: `translate(-${speakerIndicationSize}px, -${speakerIndicationSize}px)`
        }"
      />
    </div>

    <slot name="content"></slot>

    <template>
      <NoVideoParticipantTile
        v-if="!canShowContent"
        :is-loading="isLoading"
        :avatarSize="avatarSize"
        :participantType="participantType"
        :participantProfilePicture="participantProfilePicture"
        :displayName="displayName"
        :isBeRightBackOn="isBeRightBackOn"
      />
      <div
        v-if="showDisplayName"
        class="participant-name-wrapper text-ellipsis"
      >
        <vwc-icon-button-toggle
          v-if="showMobilePinButton"
          class="pin-container vvd-scheme-alternate"
          :class="{
            pinned: isPinned,
            'with-take-snapshot-button': showMobileTakeSnapshotButton
          }"
          onicon="pin-2-solid"
          officon="pin-2-line"
          layout="filled"
          connotation="primary"
          dense
          :on="isPinned"
          @click.stop="pinParticipant"
        />

        <vwc-icon-button
          v-if="showMobileTakeSnapshotButton"
          class="take-snapshot-button vvd-scheme-alternate"
          :class="{ pinned: isPinned }"
          icon="camera-line"
          layout="filled"
          dense
          @click="takeSnapshot(stream.streamId)"
        />

        <slot name="prepended-name-icons"></slot>
        <div
          class="vvd-scheme-alternate name-pill-container text-ellipsis"
          :class="{
            'name-with-icon': iconNextToDisplayName
          }"
        >
          <img
            v-if="isParticipantRaisingHand"
            v-tooltip.top="raisedHandTooltip"
            class="raise-hand"
            :class="{
              'removable-hand': isMyParticipantView || isSessionOwner,
              'with-mic': !!iconNextToDisplayName
            }"
            src="/raise_hand_img.svg"
            @click="toggleRaiseHand(participantId)"
          />
          <vwc-icon
            v-if="iconNextToDisplayName"
            :type="iconNextToDisplayName"
            class="mic-icon"
          />
          <span class="text-ellipsis displayName">{{ displayName }}</span>
        </div>
      </div>
    </template>

    <vwc-icon-button-toggle
      v-if="showPinButton"
      class="pin-container vvd-scheme-alternate"
      :class="{ pinned: isPinned }"
      data-cy="pin-container"
      onicon="pin-2-solid"
      officon="pin-2-line"
      layout="filled"
      connotation="primary"
      :on="isPinned"
      dense
      @click.stop="pinParticipant"
    />

    <vwc-icon-button
      v-if="showTakeSnapshotButton"
      class="take-snapshot-button vvd-scheme-alternate"
      data-cy="take-snapshot-button"
      icon="camera-line"
      layout="filled"
      dense
      @click="takeSnapshot(stream.streamId)"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { LAYOUT_MODE_TYPES } from '@/consts/global-consts';
import {
  convertStringToUint8Array,
  downloadFile,
  formatTime,
  isElectron
} from '@/helpers/global-helpers';
import NoVideoParticipantTile from './NoVideoParticipantTile';
import MeetingManager from '@/services/meeting-manager-service';

export default {
  name: 'ParticipantView',

  components: {
    NoVideoParticipantTile
  },

  props: {
    participantId: {
      type: String,
      default: ''
    },
    participantType: {
      type: String,
      default: ''
    },
    participantProfilePicture: {
      type: String,
      default: ''
    },
    stream: {
      type: Object,
      default: null
    },
    isMainParticipant: {
      type: Boolean,
      default: false
    },
    displayName: {
      type: String,
      default: ''
    },
    showDisplayName: {
      type: Boolean,
      default: true
    },
    preferred: {
      type: Object,
      required: true
    },
    canBePinned: {
      type: Boolean,
      default: false
    },
    canShowContent: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isPinned: {
      type: Boolean,
      default: false
    },
    showSpeakerIndication: {
      type: Boolean,
      default: false
    },
    speakerIndicationSize: {
      type: Number,
      default: 0
    },
    isBeRightBackOn: {
      type: Boolean,
      default: false
    },
    iconNextToDisplayName: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      participantSize: {}
    };
  },

  computed: {
    ...mapState(['contacts', 'minimizedMode', 'myParticipantId', 'isBranded']),
    ...mapState('layout', ['layoutMode']),
    ...mapGetters([
      'dominantSpeakerParticipantId',
      'isMobileWebMode',
      'isSessionOwner',
      'isVCPRoom',
      'roomDisplayName',
      'isMeetingAutoRecorded',
      'isRecordingOfOwnerOnly',
      'isRecordingFeatureEnabled'
    ]),
    ...mapGetters('recordings', ['isGuestMayBeRecorded']),
    ...mapState('reactions', ['reactions']),
    ...mapState('watchTogether/ongoing', ['isWatchTogetherActive']),
    ...mapState('whiteboard', ['isWhiteboardActive']),
    ...mapState('raiseHand', ['raisedHands']),

    raisedHandTooltip() {
      if (this.isMyParticipantView || this.isSessionOwner) {
        return {
          hideOnTargetClick: true,
          content: this.$t('raise_hand_button.tooltip')
        };
      }
      return null;
    },

    showPinButton() {
      return !this.isMobileWebMode && !this.minimizedMode && this.canBePinned;
    },

    showMobilePinButton() {
      return this.isMobileWebMode && this.canBePinned;
    },

    showTakeSnapshotButton() {
      return (
        !this.isMobileWebMode &&
        !this.minimizedMode &&
        this.isVCPRoom &&
        this.isSessionOwner &&
        !this.isMyParticipantView &&
        this.stream?.hasVideo &&
        this.isGuestMayBeRecorded
      );
    },

    showMobileTakeSnapshotButton() {
      return (
        this.isMobileWebMode &&
        this.isVCPRoom &&
        this.isSessionOwner &&
        !this.isMyParticipantView &&
        this.stream?.hasVideo &&
        this.isGuestMayBeRecorded
      );
    },

    avatarSize() {
      if (!this.isMobileWebMode) {
        return 'relative';
      }

      if (this.isMainParticipant) {
        return 'lg';
      }

      return 'sm';
    },

    isParticipantRaisingHand() {
      return this.participantId in this.raisedHands;
    },

    isMyParticipantView() {
      return this.myParticipantId === this.participantId;
    }
  },

  watch: {
    preferred() {
      this.adjustParticipantSize();
    }
  },

  mounted() {
    this.adjustParticipantSize();
  },

  methods: {
    ...mapActions('raiseHand', ['setIsRaisingHand']),

    adjustParticipantSize() {
      if (this.isMobileWebMode && this.isMainParticipant) {
        // Mobile main participants are sized and positioned via CSS
        this.participantSize = {};
        return;
      }

      if (!this.preferred) {
        return;
      }

      if (!this.preferred.preventSizeUpdate) {
        let participantSize = {};

        if (this.preferred.size.width) {
          participantSize.width = `${this.preferred.size.width}px`;
        }
        if (this.preferred.size.height) {
          participantSize.height = `${this.preferred.size.height}px`;
        }

        if (
          this.isMainParticipant &&
          this.layoutMode === LAYOUT_MODE_TYPES.SPEAKER
        ) {
          participantSize.left = `${this.preferred.left}px`;
        }
        this.participantSize = participantSize;
      }
    },

    pinParticipant() {
      this.$emit('pinToggled', !this.isPinned);
    },

    onParticipantClick(event) {
      if (this.isMobileWebMode && !this.isMainParticipant) {
        this.pinParticipant();
        event.stopPropagation();
      }
    },

    onParticipantDoubleClick() {
      if (this.minimizedMode || this.isMobileWebMode) {
        return;
      }

      if (
        isElectron() &&
        this.isMainParticipant &&
        this.layoutMode !== LAYOUT_MODE_TYPES.GRID &&
        window.Electron.toggleFullscreen
      ) {
        window.Electron.toggleFullscreen();
      } else {
        this.pinParticipant();
      }
    },

    toggleRaiseHand(participantId) {
      if (!this.isMyParticipantView && !this.isSessionOwner) {
        return;
      }

      this.setIsRaisingHand({
        isRaisingHand: false,
        participantId
      });
    },

    takeSnapshot(streamId) {
      const subscriber = MeetingManager.getSubscriberByStreamId(streamId);
      const subscriberImageData = subscriber.getImgData();
      const decodedImageData = atob(subscriberImageData);
      const bytes = new ArrayBuffer(decodedImageData.length);
      convertStringToUint8Array(decodedImageData, bytes);

      const fileType = 'application/octet-stream';
      const fileName = `${this.roomDisplayName}_${
        this.displayName
      }_${new Date().toLocaleDateString()}_${formatTime(new Date().getTime(), {
        hourCycle: 'h23',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      })}`;
      const extension = '.png';
      downloadFile(bytes, fileType, fileName, extension, true);
    }
  }
};
</script>

<style scoped>
.mic-icon {
  flex-shrink: 0;
  width: 12px;
  height: 12px;
}

.name-pill-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: 14px;
  color: white;
  padding: 2px 8px 2px 2px;
}

.name-pill-container.name-with-icon {
  padding: 2px 8px;
}

.displayName {
  margin-left: 6px;
}
.participant {
  display: flex;
  flex-direction: column;
  z-index: 1;
  margin: 4px;
  contain: size layout;
  position: relative;
}

.speaker-view:not(.minimized-mode):not(.mobile) .participant.main-participant {
  display: flex;
  justify-content: center;
  position: fixed;
  top: 37px;
  left: 0;
  margin-top: 8px;
}

.mobile.speaker-view .participant.main-participant {
  --stream-aspect-ratio: 4 / 3;
  --stream-aspect-ratio-inverted: 3 / 4;
  --main-participant-margin: 8px;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  margin: 0;
  contain: size;
  max-width: calc(100vw - var(--main-participant-margin) * 2);
  max-height: calc(var(--app-height) - var(--main-participant-margin) * 2);
}

.portrait.mobile.speaker-view .participant.main-participant {
  width: calc(100vw - var(--main-participant-margin) * 2);
  left: var(--main-participant-margin);
  --main-participant-height: calc(100vw * var(--stream-aspect-ratio-inverted));
  height: var(--main-participant-height);
  top: max(
    var(--main-participant-margin),
    calc(var(--app-height) / 2 - var(--main-participant-height) / 2)
  );
}

.landscape-left.mobile.speaker-view .participant.main-participant,
.landscape-right.mobile.speaker-view .participant.main-participant {
  --main-participant-width: calc(
    var(--app-height) * var(--stream-aspect-ratio)
  );
  width: var(--main-participant-width);
  left: max(
    var(--main-participant-margin),
    calc(50vw - var(--main-participant-width) / 2)
  );
  height: calc(var(--app-height) - var(--main-participant-margin) * 2);
  top: var(--main-participant-margin);
}

.speaker-view:not(.minimized-mode):not(.mobile) .participant {
  align-self: flex-start;
  margin: 4px 0 4px 8px;
}

.mobile.speaker-view .participant:not(.main-participant) {
  flex-shrink: 0;
  z-index: 2;
}

.minimized-mode .participant {
  left: 0 !important;
  margin: 4px 8px;
}

.minimized-mode .participant:last-child {
  margin-bottom: 0;
}

.dominant-view .participant:not(.main-participant) {
  display: none;
}

.participant .video-container {
  overflow: hidden;
  z-index: 9;
  border-radius: 8px;
}

.mobile.portrait .participant:not(.main-participant) {
  margin: 8px 0 0 8px;
}

.mobile.landscape-left .participant:not(.main-participant) {
  margin: 0 0 8px 8px;
}

.mobile.landscape-right .participant:not(.main-participant) {
  margin: 8px 8px 0 0;
}

.mobile .participant:not(.main-participant) .avatar-wrapper {
  border-radius: 8px;
}

.mobile .participant:not(.main-participant) .avatar-wrapper {
  padding-bottom: 23px;
}

.participant-name-wrapper {
  position: absolute;
  bottom: 4px;
  left: 4px;
  right: 4px;
  z-index: 20;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.1px;
  display: flex;
  align-items: center;
}

.speaker-view .participant:not(.main-participant) .participant-name-wrapper {
  font-size: 12px;
  padding-top: 1px;
}

.mobile .participant:not(.main-participant) .participant-name-wrapper {
  border-radius: 0 0 6px 6px;
}

.mobile .participant.main-participant .participant-name-wrapper {
  position: absolute;
  height: 38px;
  background: none;
  font-weight: 600;
  font-size: 12px;
  transition: transform 0.3s;
}

.mobile.portrait .participant.main-participant .participant-name-wrapper {
  bottom: 0;
  left: 0;
}

.mobile.landscape-left .participant.main-participant .participant-name-wrapper {
  bottom: 0;
  right: 0;
  left: unset;
  justify-content: flex-end;
}

.mobile.landscape-right
  .participant.main-participant
  .participant-name-wrapper {
  top: 0;
  left: 0;
}

.mobile.portrait
  .participant.main-participant:not(.tall)
  .participant-name-wrapper {
  transition: none;
  transform: translateY(100%);
  margin-top: 4px;
}

.mobile.portrait .participant.main-participant.tall .participant-name-wrapper,
.mobile.landscape-left .participant.main-participant .participant-name-wrapper,
.mobile.landscape-right
  .participant.main-participant
  .participant-name-wrapper {
  position: fixed;
  bottom: 8px;
}

.mobile.portrait .participant.main-participant.tall .participant-name-wrapper {
  left: 4px;
}

.low-connectivity-icon {
  margin-right: 4px;
}

.subscriber-reconnecting-icon {
  margin-right: 4px;
  outline: none;
  flex-shrink: 0;
}

vwc-icon.subscriber-reconnecting-icon {
  --vvd-color-primary: #ffc100;
}

.mobile .main-participant .muted-mic-icon {
  width: 16px;
  height: 16px;
  flex: 0 0 16px;
}

.mobile.portrait .main-participant.tall .muted-mic-icon,
.mobile.landscape-left .main-participant .muted-mic-icon,
.mobile.landscape-right .main-participant .muted-mic-icon {
  filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.45));
}

.speaker-indication {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: var(--speaker-indication);
  border-radius: 10px;
  will-change: transform;
  z-index: 0;
}

.branded .speaker-indication {
  background: linear-gradient(
    90deg,
    var(--gradient-dark),
    var(--gradient-light)
  );
}

.raise-hand-yellow-indication {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #fa9f00;
  border-radius: 10px;
  z-index: 0;
}

vwc-icon-button-toggle.pin-container {
  display: none;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 6px;
  top: 6px;
  z-index: 10;
}

.take-snapshot-button {
  display: none;
  position: absolute;
  left: 44px;
  top: 6px;
  z-index: 10;
}

.mobile vwc-icon-button-toggle.pin-container {
  opacity: 1;
  position: unset;
  flex-shrink: 0;
  margin-right: 8px;
}

.mobile vwc-icon-button-toggle.pin-container.with-take-snapshot-button {
  margin-right: 0;
}

.mobile .take-snapshot-button.pinned {
  display: flex;
  position: unset;
  margin-right: 8px;
}

vwc-icon-button-toggle.pin-container.pinned {
  opacity: 1;
  display: flex !important;
}

@media (pointer: fine) {
  .participant:hover vwc-icon-button-toggle.pin-container,
  .participant:hover .take-snapshot-button {
    display: flex !important;
  }
}

.reaction-img {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 20;
  max-height: 96px;
  max-width: 96px;
  width: 35%;
  pointer-events: none;
}

.reactions-leave-active {
  animation: bounce-in 10s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.3) rotate(-360deg);
  }
  8% {
    transform: scale(1) rotate(0deg);
  }
  95% {
    transform: scale(1) rotate(0deg);
  }
  100% {
    transform: scale(0) rotate(360deg);
    opecity: 0;
  }
}

.video-disabled-issue-message {
  top: 8px;
}

.branded .vvd-scheme-alternate {
  --vvd-color-cta: var(--btn-cta-color-white-bg);
  --vvd-color-on-cta: var(--btn-cta-color-white-bg-text-color);
}

.raise-hand {
  height: 14px;
  width: 14px;
  margin-left: 4px;
}

.raise-hand.with-mic {
  margin-left: 0;
  margin-right: 4px;
}

.removable-hand {
  cursor: pointer;
}
</style>
