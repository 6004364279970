/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-snooze': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6 .5c.1.1.2.3.2.5-.1.5-.2 1-.2 1.5C6 6.6 9.4 10 13.5 10c.5 0 1-.1 1.5-.2.2 0 .4 0 .5.2.1.1.2.3.1.5-1 3.2-4.1 5.5-7.6 5.5-4.4 0-8-3.6-8-8C0 4.5 2.3 1.4 5.5.4c.2-.1.4 0 .5.1zM5 1.7C2.7 2.8 1 5.2 1 8c0 3.9 3.1 7 7 7 2.8 0 5.2-1.7 6.3-4h-.8C8.8 11 5 7.2 5 2.5v-.8z"/><path pid="1" d="M10.5 1c-.3 0-.5-.2-.5-.5s.2-.5.5-.5h5c.2 0 .4.1.5.3.1.2 0 .4-.1.5L11.7 5h3.8c.3 0 .5.2.5.5s-.2.5-.5.5h-5c-.2 0-.4-.1-.5-.3-.1-.2 0-.4.1-.5L14.3 1h-3.8z"/>'
  }
})
