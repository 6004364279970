<template>
  <vwc-textfield
    ref="textField"
    v-focus="focus"
    tabindex="0"
    :connotation="connotation"
    :disabled="disabled"
    :outlined="outlined"
    :dense="dense"
    :value="value"
    :label="label"
    :required="required"
    :autoValidate="autoValidate"
    :pattern="pattern"
    :validationMessage="validationMessage"
    :maxLength="maxLength"
    :icon="icon"
    :readOnly="readOnly"
    :placeholder="placeholder"
    :helper="helper"
    :autocomplete="autocomplete"
    :appearance="appearance"
    @focus="$emit('focus')"
    @blur="$emit('blur')"
    @input="$emit('input', $event.target.value)"
    @keyup.enter="$emit('submit', $event.target.value)"
  >
    <vwc-icon-button
      v-if="actionSlotFunction"
      slot="action"
      :disabled="isActionDisabled"
      icon="message-sent-line"
      @click="actionSlotFunction"
    />
  </vwc-textfield>
</template>

<script>
export default {
  name: 'VTextfield',
  props: {
    value: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    connotation: {
      type: String,
      default: 'primary'
    },
    focus: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    pattern: {
      type: String,
      default: ''
    },
    autoValidate: {
      type: Boolean,
      default: false
    },
    validationMessage: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 30
    },
    minLength: {
      type: Number,
      default: 0
    },
    icon: {
      type: String,
      default: ''
    },
    dense: {
      type: Boolean,
      default: false
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    helper: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    appearance: {
      type: String,
      default: 'outlined'
    },
    actionSlotFunction: {
      type: Function,
      default: undefined
    },
    isActionDisabled: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    if (this.focus) {
      // Note: This is a hack, because our custom v-focus directive doesn't work in all cases
      setTimeout(() => {
        this.$refs.textField.focus();
      }, 50);
    }
  }
};
</script>
<style scoped>
vwc-textfield {
  width: 100%;
}
</style>
