import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import MeetingManager from '@/services/meeting-manager-service';
import StreamsStats from '@/services/streams-stats-service';

const SPLIT_AUDIO_DETECTOR_INTERVAL = 1000;
const SPLIT_AUDIO_TEST_COUNTER = 5;
const MAX_FIX_RETRIES_FOR_SUBSCRIBER = 2;

// set streamId with 0 to start checking the stream audio level
const subscribersAudioLevelTestsCounters = {};
// count number of split audio alerts for each stream id. (to avoid infinite resubscribe loop)
const subscribersWithSplitAudioCounter = {};
// extra data to log on the stream id when split-audio alert is triggered
const subscribersExtraData = {};

const splitAudioHandlers = [];
let publisherAudioLevelTestCounter = null;
let splitAudioDetectorInterval;
let firstTimeSplitAudio = true;

export function initSplitAudioDetector() {
  splitAudioDetectorInterval = setInterval(() => {
    _testSubscribersAudio();
    _testPublisherAudio();
  }, SPLIT_AUDIO_DETECTOR_INTERVAL);
}

export function stopSplitAudioDetector() {
  clearInterval(splitAudioDetectorInterval);
  splitAudioDetectorInterval = undefined;
}

export function isSplitAudioDetectorInitialized() {
  return splitAudioDetectorInterval !== undefined;
}

export function resetSubscriberAudioLevelTestCounter(streamId, extraData) {
  subscribersAudioLevelTestsCounters[streamId] = 0;
  if (extraData) {
    subscribersExtraData[streamId] = extraData;
  }
}

export function resetPublisherAudioLevelTestCounter() {
  publisherAudioLevelTestCounter = 0;
}

export function onSplitAudioAlert(callback) {
  splitAudioHandlers.push(callback);
}

export function isMaxRetriesReachedForStream(streamId) {
  return (
    subscribersWithSplitAudioCounter[streamId] >= MAX_FIX_RETRIES_FOR_SUBSCRIBER
  );
}

async function _testSubscribersAudio() {
  const streamIds = Object.keys(subscribersAudioLevelTestsCounters);
  streamIds.forEach(async (streamId) => {
    if (
      subscribersAudioLevelTestsCounters[streamId] >= SPLIT_AUDIO_TEST_COUNTER
    ) {
      delete subscribersAudioLevelTestsCounters[streamId];
      subscribersWithSplitAudioCounter[streamId] =
        subscribersWithSplitAudioCounter[streamId] || 0;
      if (!isMaxRetriesReachedForStream(streamId)) {
        if (subscribersWithSplitAudioCounter[streamId] === 0) {
          logger.warning('split-audio-warning', LOG_CATEGORIES.SPLIT_AUDIO, {
            streamId,
            extraData: subscribersExtraData[streamId]
          });
        }
        subscribersWithSplitAudioCounter[streamId]++;
        _executeHandlersOnSplitAudioStream(streamId);
      } else {
        logger.warning('split-audio-max-retries', LOG_CATEGORIES.SPLIT_AUDIO, {
          streamId
        });
        delete subscribersWithSplitAudioCounter[streamId];
      }
    } else {
      subscribersAudioLevelTestsCounters[streamId]++;
      const subscriber = MeetingManager.getSubscriberByStreamId(streamId);
      if (subscriber) {
        if (await _audioTestPassed(subscriber, false)) {
          delete subscribersAudioLevelTestsCounters[streamId];
          if (subscribersWithSplitAudioCounter[streamId] >= 1) {
            logger.log('split-audio-fixed', LOG_CATEGORIES.SPLIT_AUDIO, {
              streamId,
              retries: subscribersWithSplitAudioCounter[streamId]
            });
            delete subscribersWithSplitAudioCounter[streamId];
          }
        }
      } else if (
        subscribersAudioLevelTestsCounters[streamId] ===
        SPLIT_AUDIO_TEST_COUNTER
      ) {
        logger.error('subscriber-not-found', LOG_CATEGORIES.SPLIT_AUDIO, {
          streamId
        });
        // we want to resubscribe to a stream with no subscriber
        // delete subscribersAudioLevelTestsCounters[streamId];
      }
    }
  });
}

async function _testPublisherAudio() {
  if (publisherAudioLevelTestCounter !== null && MeetingManager.publisher) {
    if (publisherAudioLevelTestCounter >= SPLIT_AUDIO_TEST_COUNTER) {
      logger.warning('audio-publishing-warning', LOG_CATEGORIES.TOKBOX);
      publisherAudioLevelTestCounter = null;
    } else {
      publisherAudioLevelTestCounter++;
      if (await _audioTestPassed(MeetingManager.publisher, true)) {
        publisherAudioLevelTestCounter = null;
      }
    }
  }
}

async function _audioTestPassed(streamHolder, isPublisher) {
  if (streamHolder.stream.hasAudio) {
    try {
      let currentAudioLevel = 0;
      // TODO: check if we can always use streamsActiveVolumes and myCurrentAudioLevel

      const audioLevelArr = isPublisher
        ? StreamsStats.myCurrentAudioLevel
        : StreamsStats.streamsActiveVolumes[streamHolder.stream.streamId];
      currentAudioLevel = audioLevelArr[audioLevelArr.length - 1] || 0;

      return currentAudioLevel > 0;
    } catch {
      logger.error('get-rtc-stats-report', LOG_CATEGORIES.TOKBOX, {
        streamId: streamHolder.stream.streamId
      });

      return false;
    }
  }
  return true;
}

function _executeHandlersOnSplitAudioStream(streamId) {
  if (firstTimeSplitAudio) {
    logger.log('split-audio', LOG_CATEGORIES.SPLIT_AUDIO);
    firstTimeSplitAudio = false;
  }
  splitAudioHandlers.forEach((handler) => {
    handler(streamId);
  });
}

// add for non-alpha users the option to manually trigger the split audio fix
// if it wasn't detected automatically.
if (!window.isProduction) {
  window.addSplitAudioStream = (streamId) => {
    logger.log('add-split-audio-stream', LOG_CATEGORIES.SPLIT_AUDIO, {
      streamId
    });
    subscribersAudioLevelTestsCounters[streamId] = 5;
  };
}
