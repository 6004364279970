/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-9-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 3a3 3 0 116 0 3 3 0 01-6 0zm-9 7.5a3 3 0 100 6 3 3 0 000-6zM5.25 18A5.25 5.25 0 000 23.25a.75.75 0 00.75.75h10.5a.75.75 0 00.75-.75A5.25 5.25 0 006.75 18h-1.5zm12-10.5A5.25 5.25 0 0012 12.75c0 .414.336.75.75.75h10.5a.75.75 0 00.75-.75 5.25 5.25 0 00-5.25-5.25h-1.5z"/>'
  }
})
