var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "Vlt-form__element" }, [
    _c("div", { staticClass: "Vlt-composite" }, [
      _c(
        "div",
        { staticClass: "Vlt-composite__prepend Vlt-composite__prepend--icon" },
        [
          _c("v-icon", {
            staticClass: "input-icon",
            attrs: { iconName: "Vlt-icon-search" },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "Vlt-input" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.filterText,
              expression: "filterText",
            },
          ],
          attrs: { type: "text", placeholder: _vm.searchPlaceHolder },
          domProps: { value: _vm.filterText },
          on: {
            focus: _vm.focusUpdate,
            focusout: _vm.focusoutUpdate,
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.filterText = $event.target.value
            },
          },
        }),
      ]),
      _vm.filterText !== ""
        ? _c(
            "div",
            {
              staticClass: "Vlt-composite__append Vlt-composite__append--icon",
            },
            [
              _c("v-icon", {
                staticClass: "input-icon clear-icon",
                attrs: { iconName: "Vlt-icon-cross-circle" },
                on: {
                  click: function ($event) {
                    _vm.filterText = ""
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }