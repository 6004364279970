/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.9 5.1l-5-5c-.1 0-.3-.1-.4-.1h-8c-.3 0-.5.2-.5.5v15c0 .3.2.5.5.5h13c.3 0 .5-.2.5-.5v-10c0-.1-.1-.3-.1-.4zM10 1.7L13.3 5H10V1.7zM14 15H2V1h7v4.5c0 .3.2.5.5.5H14v9z"/>'
  }
})
