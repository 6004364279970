var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "placeholder",
    class: { round: _vm.round, loading: _vm.loading },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }