/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-add-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 12C0 5.4 5.4 0 12 0s12 5.4 12 12-5.4 12-12 12S0 18.6 0 12zm13.485 1.485h4.455v-2.97h-4.455V6.06h-2.97v4.455H6.06v2.97h4.455v4.455h2.97v-4.455z"/>'
  }
})
