var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "dialog",
      attrs: {
        "data-cy": "lock-meeting-modal",
        heading: _vm.$t("lock_meeting_modal.title"),
        "icon-name": "lock-line",
        close: _vm.hide,
        "min-width": _vm.minWidth,
        "primary-action": _vm.$t("lock_meeting_modal.ok_button"),
        open: _vm.isLockMeetingDialogVisible,
        isSmallModal: "",
      },
      on: {
        applied: _vm.lockMeetingAndHideModal,
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content", attrs: { slot: "content" }, slot: "content" },
        [
          _c("div", { staticClass: "text" }, [
            _vm._v(" " + _vm._s(_vm.$t("lock_meeting_modal.text")) + " "),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }