/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-remove': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1.5 8a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0zM8 .5a7.5 7.5 0 100 15 7.5 7.5 0 000-15zm-4 7a.5.5 0 000 1h8a.5.5 0 000-1H4z"/>'
  }
})
