/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-gallery': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1.5 1a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5h-13zM0 1.5A1.5 1.5 0 011.5 0h13A1.5 1.5 0 0116 1.5v13a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 14.5v-13zM9.514 5a.5.5 0 01.402.223l4 6A.5.5 0 0113.5 12h-11a.5.5 0 01-.447-.724l2-4a.5.5 0 01.8-.13L6.447 8.74 9.1 5.2a.5.5 0 01.414-.2zm-.038 1.365L6.9 9.8a.5.5 0 01-.754.054l-1.509-1.51L3.309 11h9.257l-3.09-4.635zM7 4a1 1 0 11-2 0 1 1 0 012 0z"/>'
  }
})
