var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vwc-3-dialog",
    {
      ref: "safariNotificationDialog",
      staticClass: "vvd-root vvd-theme-alternate",
    },
    [
      _c(
        "vwc-3-empty-state",
        {
          staticClass: "safari-notification-empty-state",
          attrs: { slot: "main" },
          slot: "main",
        },
        [
          _c("img", {
            attrs: {
              slot: "graphic",
              src: "/illustrations/safari-notification.svg",
            },
            slot: "graphic",
          }),
          _c("p", { staticClass: "heading4 tight" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("safari_notification_modal.title")) + " "
            ),
          ]),
          _c("p", {
            staticClass: "safari-notification-description",
            domProps: { innerHTML: _vm._s(_vm.text) },
          }),
          _c("vwc-3-button", {
            attrs: {
              slot: "action-items",
              label: _vm.$t("safari_notification_modal.close_button"),
              appearance: "filled",
            },
            on: {
              click: function ($event) {
                return _vm.closeDialog()
              },
            },
            slot: "action-items",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }