/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-click-to-call': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.72 1.72a.75.75 0 01.53-.22h19.5a.75.75 0 01.75.75v7.5a.75.75 0 001.5 0v-7.5A2.25 2.25 0 0021.75 0H2.25A2.25 2.25 0 000 2.25v10.5A2.25 2.25 0 002.25 15h3a.75.75 0 000-1.5h-3a.75.75 0 01-.75-.75V2.25a.75.75 0 01.22-.53zm6.712 7.302a.75.75 0 00-.91.91l3 12a.75.75 0 001.44.055l1.116-3.349 5.142 5.142a.75.75 0 001.06 0l3-3a.75.75 0 000-1.06l-5.142-5.142 3.35-1.117a.75.75 0 00-.056-1.439l-12-3zm2.921 10.048l-2.072-8.29 8.289 2.073-2.057.686a.75.75 0 00-.293 1.241l5.47 5.47-1.94 1.94-5.47-5.47a.75.75 0 00-1.241.293l-.686 2.056z"/>'
  }
})
