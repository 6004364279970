/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-user-sync-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.957 2.935a2.935 2.935 0 115.87 0 2.935 2.935 0 01-5.87 0zM.907 9.186c.77-.61 2.1-1.36 3.984-1.36s3.215.75 3.985 1.36c.585.464.907 1.17.907 1.904v1.138a.49.49 0 01-.49.49H.49a.49.49 0 01-.489-.49V11.09c0-.733.322-1.44.907-1.904zM12 4.5c2.7 0 5.1 1.5 6.45 3.75l2.7-1.5C19.2 3.45 15.75 1.5 12 1.5v3zM2.1 17.25l2.55-1.5c1.36 2.04 3.459 3.463 5.85 3.711v3.013c-3.456-.241-6.58-2.145-8.4-5.224zm17.009-6a2.935 2.935 0 100 5.87 2.935 2.935 0 000-5.87zm0 7.826c-1.884 0-3.215.75-3.985 1.36a2.422 2.422 0 00-.907 1.904v1.138c0 .27.22.49.49.49h8.804a.49.49 0 00.489-.49V22.34c0-.733-.322-1.44-.907-1.904-.77-.61-2.1-1.36-3.984-1.36z"/>'
  }
})
