/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-stackoverflow': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.965 17.016L6.49 14.826l.443-2.105L17.41 14.91l-.444 2.106zm.61-2.66l-9.7-4.518.887-1.967 9.7 4.545-.887 1.94zm1.22-2.44l-8.231-6.844 1.357-1.635 8.232 6.845-1.358 1.635zM14.166 1.276L15.885 0l6.374 8.591-1.718 1.275-6.375-8.591zm2.8 18.43H6.267V17.57h10.697v2.133zm2.161-4.269v6.43H4.134v-6.43H2V24h19.261v-8.564h-2.134z"/>'
  }
})
