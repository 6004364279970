/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-tool-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M19.8 7.8l-3.6-3.6L19.8.6c-.75-.45-1.65-.6-2.55-.6-3.75 0-6.75 3-6.75 6.75 0 .75.15 1.35.3 2.1L.9 16.2c-1.2.9-1.2 2.55-.3 3.6l3.45 3.6c1.05 1.05 2.7.9 3.6-.3L15 13.2c.75.15 1.5.3 2.25.3 3.75 0 6.75-3 6.75-6.75 0-.9-.15-1.8-.45-2.55L19.8 7.8z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
