/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-hubspot': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.055 11.454a6.299 6.299 0 00-2.291-2.181c-.655-.437-1.418-.655-2.291-.764V5.564c.873-.328 1.309-1.091 1.309-1.964 0-1.2-.982-2.182-2.182-2.182S14.31 2.4 14.31 3.6c0 .873.435 1.636 1.308 1.964v2.945c-.654.11-1.309.327-1.963.655-1.31-.982-5.455-4.146-7.964-5.891.109-.218.109-.437.109-.655C5.91 1.091 4.818 0 3.51 0 2.2 0 1 1.09 1 2.4c0 1.31 1.09 2.4 2.51 2.4.435 0 .872-.11 1.308-.327l.546.436 7.09 5.018c-.327.328-.763.764-.981 1.2-.546.873-.873 1.855-.873 2.946v.218c0 .764.11 1.418.436 2.073a6.95 6.95 0 00.873 1.527l-2.4 2.29c-.654-.217-1.527-.108-1.964.437A2.013 2.013 0 007 21.928c0 .545.218.981.545 1.308.219.546.655.764 1.2.764.546 0 .982-.218 1.31-.546.327-.327.545-.872.545-1.309 0-.218 0-.436-.11-.545l2.4-2.4c.328.218.655.436 1.092.546.764.327 1.636.545 2.509.545h.218c.982 0 1.964-.218 2.837-.764.981-.545 1.745-1.2 2.29-2.073.546-.872.873-1.854.873-2.945.11-1.2-.109-2.182-.654-3.055zm-2.946 5.019c-.655.763-1.418 1.2-2.29 1.2h-.11a3.36 3.36 0 01-1.418-.327c-.546-.219-.982-.655-1.31-1.091-.326-.437-.545-.982-.545-1.528v-.218c0-.545.11-1.09.328-1.527.327-.546.654-.982 1.2-1.31.545-.327.981-.436 1.636-.436h.11c.544 0 1.09.11 1.526.328.437.218.873.654 1.2 1.09.328.437.437.982.546 1.528v.327c-.11.655-.328 1.31-.873 1.964z"/>'
  }
})
