/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-message': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4.5 16h-.2c-.2-.1-.3-.3-.3-.5V12H1.5C.7 12 0 11.3 0 10.5v-9C0 .7.7 0 1.5 0h13c.8 0 1.5.7 1.5 1.5v9c0 .8-.7 1.5-1.5 1.5H9.4l-4.5 3.9c-.2.1-.3.1-.4.1zm-3-15c-.3 0-.5.2-.5.5v9c0 .3.2.5.5.5h3c.3 0 .5.2.5.5v2.9l3.8-3.3c.1-.1.2-.1.4-.1h5.3c.3 0 .5-.2.5-.5v-9c0-.3-.2-.5-.5-.5h-13z"/>'
  }
})
