/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-backspace': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M8.25 3c-.15 0-.45.15-.6.3l-7.5 8.25c-.15.3-.15.6 0 .9l7.5 8.25c.15.15.45.3.6.3h13.5c1.2 0 2.25-1.05 2.25-2.25V5.25C24 4.05 22.95 3 21.75 3H8.25zM1.8 12l6.75-7.5h13.2c.45 0 .75.3.75.75v13.5c0 .45-.3.75-.75.75H8.55L1.8 12zm16.73-3.905a.75.75 0 010 1.06L15.686 12l2.844 2.845a.75.75 0 11-1.06 1.06l-2.845-2.844-2.845 2.844a.75.75 0 11-1.06-1.06L13.564 12 10.72 9.155a.75.75 0 011.06-1.06l2.845 2.844 2.845-2.844a.75.75 0 011.06 0z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
