/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-thumbs-down-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" class="st0" d="M13.3 10H9v4c0 1.5-.8 2-2 2-.3 0-.6-.2-.6-.5C6.4 15.5 4.1 8 4 8V0h8.6c1.3 0 2.4 1 2.6 2.3l.8 4.6c.1.8-.1 1.6-.6 2.1-.5.7-1.3 1-2.1 1zM2 8H0V0h2v8z"/>'
  }
})
