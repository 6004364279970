/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-fax-add-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23 10.429h-9.429V1H10.43v9.429H1v3.142h9.429V23h3.142v-9.429H23V10.43z"/>'
  }
})
