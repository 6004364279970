/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-search': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.2 22.5l-5.3-5.4c1.5-1.8 2.4-4.1 2.4-6.6 0-5.6-4.5-10.2-10-10.2S.3 4.9.3 10.5s4.5 10.2 10 10.2c2.5 0 4.8-.9 6.6-2.5l5.3 5.4c.3.3.8.3 1.1 0 .2-.4.2-.8-.1-1.1zm-6.8-6s-.1.1 0 0c-.1.1-.1.1 0 0-1.6 1.6-3.7 2.6-6.1 2.6-4.7 0-8.5-3.9-8.5-8.7s3.8-8.7 8.5-8.7 8.5 3.9 8.5 8.7c0 2.4-.9 4.6-2.4 6.1z"/>'
  }
})
