/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-happy': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1 8a7 7 0 1114 0A7 7 0 011 8zm7-8a8 8 0 100 16A8 8 0 008 0zM4.1 8.89a.5.5 0 10-.976.22 5 5 0 009.751 0 .5.5 0 00-.975-.22 4.001 4.001 0 01-7.8 0zM5 5a1 1 0 00-1 1 .5.5 0 01-1 0 2 2 0 114 0 .5.5 0 01-1 0 1 1 0 00-1-1zm5.293.293A1 1 0 0112 6a.5.5 0 001 0 2 2 0 10-4 0 .5.5 0 001 0 1 1 0 01.293-.707z"/>'
  }
})
