/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-chevron-left': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10.9.8c.2-.2.1-.5-.1-.7-.2-.2-.5-.1-.7.1l-5 7.5c-.1.2-.1.4 0 .6l5 7.5c.2.2.5.3.7.1.2-.2.3-.5.1-.7L6.1 8 10.9.8z"/>'
  }
})
