export const COLOR_TYPES = Object.freeze({
  NORMAL: 'NORMAL', // good for white & black bg
  DARK: 'DARK', // good for white, need a small fix for black
  LIGHT: 'LIGHT', // good for black, need a small fix for white
  GRAY_NORMAL: 'GRAY_NORMAL', // gray but good for white & black bg
  GRAY_DARK: 'GRAY_DARK', // gray but good for white, need a small fix for black
  GRAY_LIGHT: 'GRAY_LIGHT', // gray but good for black, need a small fix for white
  BLACK_AND_WHITE: 'BLACK_AND_WHITE' // main color is black or white
});

export const CSS_VARS_KEYS = Object.freeze({
  ENTRANCE_SCREEN_LOGO_BG: '--entrance-screen-logo-bg',
  ENTRANCE_SCREEN_LOGO_COLOR: '--entrance-screen-logo-color',
  PRIMARY_WHITE_BG: '--btn-primary-color-white-bg',
  PRIMARY_WHITE_BG_TEXT_COLOR: '--btn-primary-color-white-bg-text-color',
  SWITCH_PRIMARY_CHECKED_COLOR: '--switch-primary-checked-color',
  CTA_BLACK_BG: '--btn-cta-color-black-bg',
  CTA_BLACK_BG_HOVER: '--btn-cta-color-black-bg-hover',
  CTA_BLACK_BG_CLICKED: '--btn-cta-color-black-bg-clicked',
  CTA_BLACK_BG_OUTLINED: '--btn-cta-color-black-bg-outlined',
  CTA_BLACK_BG_TEXT_COLOR: '--btn-cta-color-black-bg-text-color',
  CTA_WHITE_BG: '--btn-cta-color-white-bg',
  CTA_WHITE_BG_HOVER: '--btn-cta-color-white-bg-hover',
  CTA_WHITE_BG_CLICKED: '--btn-cta-color-white-bg-clicked',
  CTA_WHITE_BG_OUTLINED: '--btn-cta-color-white-bg-outlined',
  CTA_WHITE_BG_TEXT_COLOR: '--btn-cta-color-white-bg-text-color',
  AVATAR_CIRCLE_BG_COLOR_DARK: '--avatar-circle-bg-color-dark',
  AVATAR_ICON_FILL_DARK: '--avatar-icon-fill-dark',
  AVATAR_ICON_FILL_LIGHT: '--avatar-icon-fill-light',
  AVATAR_FILL_ON_DARK_BG: '--avatar-fill-on-dark-bg',
  EMPTY_STATE_GRADIENT: '--empty-state-gradient',
  SPEAKER_INDICATION: '--speaker-indication',
  MEETING_PIN_TEXT_COLOR: '--meeting-pin-text-color',
  ACTION_BUTTON_CTA_COLOR: '--action-button-cta-color',
  AVATAR_WRAPPER_BG_COLOR: '--avatar-wrapper-bg-color',
  AVATAR_CIRCLE_BG_COLOR_LIGHT: '--avatar-circle-bg-color-light',
  PARTICIPANT_ROW_ICON: '--participant-row-icon-color',
  PARTICIPANT_ROW_ICON_DISABLED: '--participant-row-icon-color-disabled',
  GRADIENT_DARK: '--gradient-dark',
  GRADIENT_LIGHT: '--gradient-light',
  LINK_COLOR_BLACK_BG: '--link-color-black-bg',
  LINK_COLOR_WHITE_BG: '--link-color-white-bg'
});
