var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preview-container" }, [
    _vm.isVideoIdValid
      ? _c("div", { staticClass: "thumbnail-container" }, [
          _c("img", {
            staticClass: "video-thumbnail",
            attrs: {
              alt: "Video Thumbnail",
              src: _vm.videoMetadata.thumbnailUrl,
            },
          }),
          _c(
            "div",
            { staticClass: "thumbnail-duration-overlay unselectable" },
            [_vm._v(" " + _vm._s(_vm.videoDuration) + " ")]
          ),
        ])
      : _c("div", {
          staticClass:
            "video-thumbnail video-thumbnail-placeholder placeholder",
          class: { "placeholder-loading": _vm.isLoadingVideoMetadata },
        }),
    _c("div", { staticClass: "text-container" }, [
      _vm.isVideoIdValid
        ? _c("span", { staticClass: "Vlt-black video-title" }, [
            _vm._v(_vm._s(_vm.videoMetadata.title)),
          ])
        : _c("div", { staticClass: "text-container" }, [
            _c("div", {
              staticClass:
                "video-title video-title-placeholder placeholder placeholder-round",
              class: { "placeholder-loading": _vm.isLoadingVideoMetadata },
            }),
            _c("div", {
              staticClass:
                "video-title video-title-placeholder placeholder placeholder-round",
              class: { "placeholder-loading": _vm.isLoadingVideoMetadata },
            }),
          ]),
      _vm.isVideoIdValid
        ? _c("span", { staticClass: "Vlt-grey-darker video-author" }, [
            _vm._v(
              _vm._s(
                _vm.$t("watch_together_video_preview.video_author_text", {
                  author: _vm.videoMetadata.author,
                })
              ) + " "
            ),
          ])
        : _c("div", {
            staticClass:
              "video-author video-author-placeholder placeholder placeholder-round",
            class: { "placeholder-loading": _vm.isLoadingVideoMetadata },
          }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }