import Autolinker from 'autolinker';
import '@/style/Linkify.scss';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';

const regexProtocolScheme = /^[A-Za-z]+:\/\//;

// Turns text with links to text with clickable links, safely
export default {
  functional: true,
  name: 'Linkify',
  render(createElement, { data, props, listeners, children }) {
    const content = props.content;
    children = children || [];
    if (!content) {
      return null;
    }

    const hightlightStyle = {
      'background-color': '#d1ffee',
      padding: 0
    };

    const elements = [];
    let from = 0;
    const splittedFilterText = props.filterText
      ? props.filterText.split(' ')
      : '';

    // Only try to linkify content if 'parseContent' flag is set
    if (props.parseContent && !props.emojiesOnly) {
      // If we don't want to find nothing, no need to run auto-linker
      if (props.urls || props.emails || props.phones) {
        // Electron should always open urls on a new browser tab
        const target = props.newPage || props.isElectron ? '_blank' : undefined;

        const linkifyItems = Autolinker.parse(content, {
          urls: props.urls,
          email: props.emails,
          phone: props.phones
        });

        for (let index = 0; index < linkifyItems.length; index++) {
          const linkItem = linkifyItems[index];
          const linkType = linkItem.getType();
          const unlinkifiedPart = content.substring(from, linkItem.offset);
          const attrs = {};
          let shouldLinkify = true;

          switch (linkType) {
            case 'url':
              attrs.target = target;

              if (regexProtocolScheme.test(linkItem.matchedText)) {
                attrs.href = linkItem.matchedText;
              } else {
                attrs.href = props.isElectron
                  ? `http://${linkItem.matchedText}`
                  : `//${linkItem.matchedText}`;
              }
              break;
            case 'email':
              // We have to set the target to _blank for electron won't try to navigate to the "mailto:" URI
              // but rather will try to open it externally so it will be propagated to the default email app
              if (props.isElectron) {
                attrs.target = '_blank';
              }

              attrs.href = `mailto:${linkItem.matchedText}`;
              break;
            case 'phone':
              attrs.href = `tel:${linkItem.matchedText}`;
              attrs['data-tel'] = linkItem.matchedText;
              break;
            default: {
              const warningMessage = `Linkify ${linkType} is not supported. ${linkItem.matchedText} will not be linkified`;
              logger.error('linkify-render', LOG_CATEGORIES.CLIENT_LOGIC, {
                warningMessage
              });
              shouldLinkify = false;
              break;
            }
          }

          const highlightMatchedText = props.filterText
            ? createElement(
                'text-highlight',
                {
                  props: {
                    queries: splittedFilterText,
                    highlightStyle: hightlightStyle
                  }
                },
                linkItem.matchedText
              )
            : linkItem.matchedText;
          const highlightUnlinkifiedPart = props.filterText
            ? createElement(
                'text-highlight',
                {
                  props: {
                    queries: splittedFilterText,
                    highlightStyle: hightlightStyle
                  }
                },
                unlinkifiedPart
              )
            : unlinkifiedPart;

          elements.push(
            highlightUnlinkifiedPart,
            shouldLinkify
              ? createElement(
                  'a',
                  {
                    attrs,
                    on: listeners.click
                      ? {
                          click: listeners.click
                        }
                      : undefined
                  },
                  [highlightMatchedText]
                )
              : highlightMatchedText
          );

          from = linkItem.offset + linkItem.matchedText.length;
        }
      }
    }

    const otherText = props.filterText
      ? createElement(
          'text-highlight',
          {
            props: {
              queries: splittedFilterText,
              highlightStyle: hightlightStyle
            }
          },
          content.substring(from, content.length)
        )
      : content.substring(from, content.length);

    elements.push(otherText);
    elements.push(...children);

    const allText = createElement(
      'span',
      {
        class: {
          'body-emojies-only': props.emojiesOnly,
          'linkify-body': !props.emojiesOnly
        },
        staticClass: `${props.halfOpacity ? 'linkify-half-opacity' : ''}`
      },
      elements
    );
    return createElement(
      'div',
      {
        staticClass: `linkify ${props.isRTL ? 'rtl' : 'ltr'} ${
          data.staticClass || ''
        }`,
        class: data.class
      },
      [allText]
    );
  },

  props: {
    content: {
      type: String
    },
    newPage: {
      type: Boolean,
      default: true
    },
    urls: {
      type: Boolean,
      default: true
    },
    emails: {
      type: Boolean,
      default: true
    },
    phones: {
      type: Boolean,
      default: true
    },
    parseContent: {
      type: Boolean,
      default: true
    },
    halfOpacity: {
      type: Boolean,
      default: false
    },
    isRTL: {
      type: Boolean,
      default: false
    },
    emojiesOnly: {
      type: Boolean,
      default: false
    },
    filterText: {
      type: String,
      default: ''
    },
    isElectron: {
      type: Boolean,
      default: false
    }
  }
};
