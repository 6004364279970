import { render, staticRenderFns } from "./AttachmentRow.vue?vue&type=template&id=fc521e06&scoped=true&"
import script from "./AttachmentRow.vue?vue&type=script&lang=js&"
export * from "./AttachmentRow.vue?vue&type=script&lang=js&"
import style0 from "./AttachmentRow.vue?vue&type=style&index=0&id=fc521e06&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc521e06",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1498271919/src/v-meetings/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fc521e06')) {
      api.createRecord('fc521e06', component.options)
    } else {
      api.reload('fc521e06', component.options)
    }
    module.hot.accept("./AttachmentRow.vue?vue&type=template&id=fc521e06&scoped=true&", function () {
      api.rerender('fc521e06', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/rows/message-rows/AttachmentRow.vue"
export default component.exports