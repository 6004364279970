/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-whisper-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 22h2c1.654 0 3-1.346 3-3v-1a1 1 0 00-.537-.887l-2.226-1.16 2.195-1.052A.998.998 0 005 14v-1.219l1.529-.382a1.978 1.978 0 001.443-1.406 1.98 1.98 0 00-.514-1.949L5 6.586V1H3v6c0 .266.105.52.293.707l2.751 2.752-2.286.571A.999.999 0 003 12v1.37l-1.627.778a1.993 1.993 0 00-1.137 1.771 1.989 1.989 0 001.076 1.808L3 18.606V19a1 1 0 01-1 1H0v2zM13 3h8c1.654 0 3 1.346 3 3v8c0 1.654-1.346 3-3 3H6l4-5.333V6c0-1.654 1.346-3 3-3zm1 10h6v-2h-6v2zm0-4h6V7h-6v2z"/>'
  }
})
