<template>
  <VDropdown
    class="layout-toggle-container"
    :items="layoutDropdownItems"
    closeOnMouseLeave
    defaultCursor
    minWidth="186"
    @change="
      (layout) =>
        setLayoutMode({
          layoutMode: layout.value,
          layoutToSave: lastSavedLayout
        })
    "
  >
    <div slot="toggle-button" app sm white class="action-button-container">
      <span v-if="layouts[layoutMode]" class="layout-name">{{
        layouts[layoutMode].label
      }}</span>
      <v-icon
        iconName="Vlt-icon-down-full"
        class="expand-dropdown-arrow-icon Vlt-black"
      />
    </div>
  </VDropdown>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import VDropdown from '@/components/volta/VDropdown.vue';
import { LAYOUT_MODE_TYPES } from '@/consts/global-consts';

export default {
  name: 'LayoutPicker',

  components: {
    VDropdown
  },

  computed: {
    ...mapState('layout', ['layoutMode', 'lastSavedLayout']),

    layouts() {
      return {
        [LAYOUT_MODE_TYPES.DOMINANT]: {
          label: this.$t('layouts_picker.dominant_label'),
          icon: 'dominant-icon',
          value: LAYOUT_MODE_TYPES.DOMINANT
        },
        [LAYOUT_MODE_TYPES.SPEAKER]: {
          label: this.$t('layouts_picker.speaker_label'),
          icon: 'audience-icon',
          value: LAYOUT_MODE_TYPES.SPEAKER
        }
      };
    },

    layoutDropdownItems() {
      return Object.values(this.layouts).map((layout) => ({
        ...layout,
        checked: this.layoutMode === layout.value
      }));
    }
  },

  methods: {
    ...mapActions('layout', ['setLayoutMode'])
  }
};
</script>

<style scoped>
.layout-toggle-container {
  -webkit-app-region: no-drag;
}

.action-button-container {
  padding: 6px;
  height: 32px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.expand-dropdown-arrow-icon {
  width: 14px;
  height: 14px;
  margin-left: 4px;
  transition: transform 0.2s;
}

.layout-name {
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: #131415;
}
</style>
