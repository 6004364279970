/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-volume-off-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 4.5C15.3 4.5 12 7.8 12 12s3.3 7.5 7.5 7.5v3c0 1.2-1.35 1.8-2.4 1.2L8.55 18H1.5C.6 18 0 17.4 0 16.5v-9C0 6.6.6 6 1.5 6h7.05L17.1.3a1.5 1.5 0 012.4 1.2v3zm0 5.379l2.121-2.122 2.122 2.122L21.62 12l2.122 2.121-2.122 2.122L19.5 14.12l-2.121 2.122-2.122-2.122L17.38 12l-2.122-2.121 2.122-2.122 2.12 2.122z"/>'
  }
})
