/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-notification-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.75 16.5h.75c.9 0 1.5.6 1.5 1.5s-.6 1.5-1.5 1.5h-21C.6 19.5 0 18.9 0 18s.6-1.5 1.5-1.5h.75C3.3 15.45 4.5 13.95 4.5 12V7.5C4.5 3.3 7.8 0 12 0s7.5 3.3 7.5 7.5V12c0 1.95 1.2 3.45 2.25 4.5zM12 24c-1.65 0-3-1.35-3-3h6c0 1.65-1.35 3-3 3z"/>'
  }
})
