/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-brain-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M18.434 18.09c.183-.057.374-.087.566-.09a1.995 1.995 0 11-1.91 1.434.98.98 0 01-.211-.141L15.586 18H13v.5a5.501 5.501 0 01-10.157 2.944 5.5 5.5 0 01-.597-4.553 3.956 3.956 0 01-.836-4.624 6.971 6.971 0 003.716 1.672 4 4 0 002.539 2.82 1 1 0 00.67-1.885A2 2 0 017 13a1.974 1.974 0 01.665-1.478 1 1 0 00-1.34-1.485 4.02 4.02 0 00-1.157 1.88A4.984 4.984 0 012.7 10.732 4.981 4.981 0 016 2a1.4 1.4 0 01.192.017c.046.006.093.013.14.016A3.494 3.494 0 0113 3.5V6h2.586l1.293-1.293a.978.978 0 01.212-.141 2.037 2.037 0 111.343 1.343.98.98 0 01-.141.212l-1.586 1.586A1 1 0 0116 8h-3v8h3a1 1 0 01.707.293l1.586 1.586a.98.98 0 01.14.212zm1.57-7.82a1.995 1.995 0 011.998-.006A2.001 2.001 0 1119.277 13H15v-2h4.277c.174-.303.425-.555.727-.73z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
