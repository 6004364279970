/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-fax-add': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 2.2v1.5h3v18h11.6l6.2-6.2.2-.2V3.8h3V2.2H0zm15.2 16.9v-3.4h3.4l-3.4 3.4zm4.2-4.9h-5.7l-.1 6H4.4V3.7h15v10.5z"/><path pid="1" d="M9.9 13.4h1.5v-3h3V8.9h-3v-3H9.9v3h-3v1.5h3z"/>'
  }
})
