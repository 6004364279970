/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-right': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10.864 2.47a.75.75 0 00-1.061 1.06l3.387 3.387H1a.75.75 0 000 1.5h12.189l-3.386 3.386a.75.75 0 001.06 1.06l4.652-4.651a.748.748 0 00.015-1.076L10.864 2.47z"/>'
  }
})
