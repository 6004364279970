/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-maximise': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.785.225A.748.748 0 0124 .75v9a.75.75 0 01-1.5 0V2.56l-6.22 6.22a.75.75 0 11-1.06-1.06l6.22-6.22h-7.19a.75.75 0 010-1.5h9c.205 0 .39.082.525.215l.01.01zM.75 13.5a.75.75 0 01.75.75v7.19l6.22-6.22a.75.75 0 011.06 1.06L2.56 22.5h7.19a.75.75 0 010 1.5h-9a.747.747 0 01-.525-.215l-.01-.01A.748.748 0 010 23.25v-9a.75.75 0 01.75-.75zM.463.057A.748.748 0 00.22.218L.22.22.218.22A.748.748 0 000 .75v9a.75.75 0 001.5 0V2.56l6.22 6.22a.75.75 0 001.06-1.06L2.56 1.5h7.19a.75.75 0 000-1.5h-9a.748.748 0 00-.287.057zM23.25 13.5a.75.75 0 01.75.75v9a.747.747 0 01-.213.524l-.013.013a.748.748 0 01-.524.213h-9a.75.75 0 010-1.5h7.19l-6.22-6.22a.75.75 0 111.06-1.06l6.22 6.22v-7.19a.75.75 0 01.75-.75z"/>'
  }
})
