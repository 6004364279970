<template>
  <div class="subscriber-stats-container">
    <div class="title">{{ $t('subscriber_stats.title') }}</div>
    {{ $t('subscriber_stats.stream_id', { mainStreamId }) }}<br />
    {{ $t('subscriber_stats.audio_bitrate', { bitRates: bitrates.audio })
    }}<br />
    {{ $t('subscriber_stats.video_bitrate', { bitRates: bitrates.video })
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['subscriberStats', 'mainStreamId']),
    bitrates() {
      const subscriberStats = this.subscriberStats[this.mainStreamId];
      const video = subscriberStats?.['videoBitrate']?.toFixed(2) || 0;
      const audio = subscriberStats?.['audioBitrate']?.toFixed(2) || 0;
      return { audio, video };
    }
  }
};
</script>

<style scoped>
.title {
  margin-bottom: 10px;
  font-weight: bold;
}

.subscriber-stats-container {
  position: absolute;
  height: 200px;
  width: 300px;
  top: 100px;
  left: 38px;
  z-index: 10;
  background: #2c2d30;
  border: 2px white solid;
  border-radius: 8px;
  padding: 8px;
  font-size: 16px;
}
</style>
