import { FEATURE_FLAGS } from '@/consts/global-consts';
import { isMinimalNativeVersion } from '../helpers/global-helpers';

export default {
  getFlag(flag) {
    window.Electron?.getFeatureFlag?.(flag);
  },

  setFlag(flag, value) {
    window.Electron?.updateFeatureFlag?.(flag, value);
  },

  initNativeFeatureFlags() {
    this.setFlag(FEATURE_FLAGS.PRESENTER_MODE, isMinimalNativeVersion('2.9.3'));
    this.setFlag(
      FEATURE_FLAGS.WAITING_ROOM_IN_PRESENTER_MODE,
      isMinimalNativeVersion('2.14.0')
    );
  }
};
