/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-doc-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.5 9h9v13.5c0 .9-.6 1.5-1.5 1.5H3c-.9 0-1.5-.6-1.5-1.5v-21C1.5.6 2.1 0 3 0h10.5v9zm1.15 7.909c0 .87-.213 1.53-.64 1.984-.426.452-1.051.678-1.874.678-.81 0-1.432-.228-1.865-.682-.432-.455-.648-1.117-.648-1.987 0-.86.215-1.518.645-1.971.43-.454 1.055-.681 1.875-.681.822 0 1.446.226 1.87.675.425.45.637 1.112.637 1.984zm-3.556 0c0 1 .346 1.498 1.042 1.498.354 0 .616-.12.787-.364.17-.242.256-.62.256-1.135s-.086-.897-.26-1.143c-.174-.247-.432-.37-.776-.37-.7.001-1.05.505-1.05 1.514zm-2.206-.102c0 .863-.238 1.527-.713 1.993-.475.468-1.143.7-2.002.7H4.5v-5.168h1.79c.83 0 1.469.211 1.92.636.452.425.678 1.037.678 1.838zm-1.45.049c0-.474-.094-.825-.281-1.054-.187-.229-.472-.343-.854-.343h-.407v2.895h.311c.425 0 .736-.124.934-.37.198-.246.297-.621.297-1.128zm9.67-1.045a.89.89 0 01.777-.408c.22 0 .424.03.615.092s.382.137.573.226l.427-1.1a3.676 3.676 0 00-1.602-.363c-.51 0-.955.109-1.334.326-.378.218-.667.53-.87.936-.201.406-.301.876-.301 1.411 0 .854.207 1.509.623 1.962.417.452 1.014.678 1.795.678.544 0 1.033-.095 1.463-.287v-1.18c-.216.09-.432.166-.646.23a2.315 2.315 0 01-.665.096c-.757 0-1.135-.498-1.135-1.493 0-.479.094-.854.28-1.126zM16.5 6V0l6 6h-6z"/>'
  }
})
