/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-dollar-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22.5 9h-9V0H3c-.9 0-1.5.6-1.5 1.5v21c0 .9.6 1.5 1.5 1.5h18c.9 0 1.5-.6 1.5-1.5V9zm-6-9v6h6l-6-6zm-4.667 17.661c.12-.238.176-.498.166-.76a1.49 1.49 0 00-.232-.825 2.162 2.162 0 00-.721-.652 9.84 9.84 0 00-1.659-.7v-1.588a7.055 7.055 0 011.912.42l.562-1.26a7.395 7.395 0 00-2.474-.495V11H8.57v.823a3.692 3.692 0 00-1.89.611c-.216.152-.39.347-.507.57a1.458 1.458 0 00-.171.713c-.015.448.161.884.493 1.219a4.58 4.58 0 001.675.912l.4.14v1.667c-.442-.03-.88-.1-1.306-.21-.43-.1-.85-.236-1.251-.408v1.42c.81.305 1.68.463 2.558.463V20h.819v-1.102a3.663 3.663 0 001.924-.62 1.74 1.74 0 00.52-.617zm-3.99-3.73a.467.467 0 01-.044-.213c0-.306.257-.492.771-.56v1.264a2.221 2.221 0 01-.588-.315.514.514 0 01-.138-.177zm2.306 2.84a.444.444 0 01.053.212c0 .34-.271.552-.813.635v-1.34c.218.083.423.191.609.323a.496.496 0 01.15.17z"/>'
  }
})
