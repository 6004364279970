/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-left': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4.886 13.614a.75.75 0 101.061-1.061L2.56 9.166h12.19a.75.75 0 000-1.5H2.561L5.947 4.28a.75.75 0 00-1.06-1.06L.234 7.87A.748.748 0 00.22 8.947l4.666 4.667z"/>'
  }
})
