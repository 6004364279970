var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vwc-card", { class: { uploading: _vm.question.isUploading } }, [
    _c(
      "div",
      { staticClass: "name-wrapper", attrs: { slot: "media" }, slot: "media" },
      [
        _c("Avatar", {
          staticClass: "avatar",
          attrs: {
            size: "sm",
            displayName: _vm.participantDisplayName,
            image: _vm.participantProfilePicture,
          },
        }),
        _c("div", { staticClass: "name-and-time-container" }, [
          _c("div", { staticClass: "name" }, [
            _vm._v(" " + _vm._s(_vm.participantDisplayName) + " "),
          ]),
          _c("div", { staticClass: "creationTime" }, [
            _vm._v(
              " " + _vm._s(_vm.formattedTime(_vm.question.createdAt)) + " "
            ),
          ]),
        ]),
        _vm.isQnaSessionOwner && !_vm.question.isResolved
          ? _c("vwc-icon-button", {
              staticClass: "check-icon",
              attrs: { icon: "check-solid", dense: "", connotation: "success" },
              on: {
                click: function ($event) {
                  return _vm.resolveQuestion(_vm.question.questionId)
                },
              },
            })
          : !_vm.isQnaSessionOwner && !_vm.question.isResolved
          ? _c(
              "div",
              { staticClass: "upvote-button-container" },
              [
                _c("vwc-icon-button-toggle", {
                  staticClass: "vote-button",
                  attrs: {
                    connotation: "cta",
                    onicon: "thumbs-up-solid",
                    officon: "thumbs-up-line",
                    dense: "",
                    on: _vm.isUpvotedByMe,
                  },
                  on: { click: _vm.upvote },
                }),
                _c("span", { staticClass: "number-of-likes" }, [
                  _vm._v(" " + _vm._s(_vm.questionNumberOfLikes) + " "),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm.isQnaSessionOwner || _vm.isMyQuestion
          ? _c("vwc-icon-button", {
              staticClass: "delete-icon",
              attrs: { connotation: "primary", icon: "delete-line", dense: "" },
              on: {
                click: function ($event) {
                  return _vm.deleteQuestion(_vm.question.questionId)
                },
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "card-main-slot-container",
        attrs: { slot: "main" },
        slot: "main",
      },
      [
        _c(
          "p",
          {
            staticClass: "question-text",
            class: {
              resolved: _vm.question.isResolved,
            },
          },
          [_vm._v(" " + _vm._s(_vm.question.questionText) + " ")]
        ),
        _c(
          "div",
          { staticClass: "badges-container" },
          [
            _vm.isQnaSessionOwner
              ? _c("vwc-badge", {
                  staticClass: "likes-badge",
                  attrs: {
                    connotation: "cta",
                    icon: "thumbs-up-solid",
                    shape: "pill",
                    text: _vm.questionNumberOfLikes,
                  },
                })
              : _vm._e(),
            _vm.question.isResolved
              ? _c("vwc-badge", {
                  staticClass: "answered-badge",
                  attrs: {
                    connotation: "success",
                    icon: "check-solid",
                    shape: "pill",
                    layout: "soft",
                    text: _vm.$t("qna_sidebar.answered_badge"),
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }