<template>
  <Dialog
    data-cy="report-modal"
    class="report-modal"
    :heading="$t('report_issue_modal.title')"
    icon-name="feedback-line"
    :close="hide"
    :min-width="445"
    :primary-action="$t('report_issue_modal.submit_button')"
    :secondary-action="$t('report_issue_modal.cancel_button')"
    :open="showReportIssueModal"
    primaryButtonIcon="message-sent-solid"
    :disablePrimaryButton="isSubmitDisabled"
    isSmallModal
    primaryButtonConnotation="primary"
    @applied="submit"
    @click.stop
  >
    <template slot="content">
      <div>
        <div class="text">{{ text }}</div>
        <VVDSelect
          class="issue-selector"
          :label="$t('report_issue_modal.select_label')"
          :selected-value="selectedIssueType"
          :options="issueTypes"
          dense
          shape="rounded"
          required
          @selectedOption="setSelectedOption"
        />

        <div data-cy="leave-feedback-text">
          <VVDTextarea
            ref="textArea"
            v-model="description"
            data-cy="leave-feedback-text"
            class="text-area"
            outlined
            required
            :rows="6"
            :label="$t('report_issue_modal.form_label')"
            dense
            resizable
            autoValidate
            :validationMessage="$t('report_issue_modal.textarea_error_message')"
          />
        </div>
      </div>
      <div v-if="isGuest">
        <label class="Vlt-label email-label">{{
          $t('report_issue_modal.email_label')
        }}</label>
        <VTextfield
          v-model="email"
          data-cy="email-text-field"
          outlined
          placeholder="myemail@example.com"
          dense
          autoValidate
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          :validationMessage="$t('report_issue_modal.email_error_message')"
        />
      </div>
    </template>
  </Dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import Dialog from '@/components/Dialog';
import VVDTextarea from '@/components/VVDTextarea.vue';
import {
  LEAVE_FEEDBACK_TAGS_OPTIONS,
  LEAVE_FEEDBACK_TYPES_OPTIONS,
  ANALYTICS
} from '@/consts/global-consts';
import VVDSelect from '@/components/VVDSelect';
import analytics from '@/services/analytics-service';

export default {
  name: 'ReportIssueModal',

  components: { VVDSelect, Dialog, VVDTextarea },

  data() {
    return {
      email: '',
      description: '',
      selectedIssueType: LEAVE_FEEDBACK_TYPES_OPTIONS.REPORT_AN_ISSUE,
      selectedIssueTag: LEAVE_FEEDBACK_TAGS_OPTIONS.ISSUE
    };
  },

  computed: {
    ...mapState(['isBranded', 'showReportIssueModal']),
    ...mapGetters(['isGuest']),

    isValidEmail() {
      if (
        this.email.length < 5 ||
        !this.email.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        return false;
      }
      return true;
    },

    isSubmitDisabled() {
      return (
        (this.email !== '' && !this.isValidEmail && this.isGuest) ||
        this.description === ''
      );
    },

    text() {
      return this.isBranded
        ? this.$t('report_issue_modal.text_branded')
        : this.$t('report_issue_modal.text');
    },

    issueTypes() {
      return [
        {
          label: this.$t('report_issue_modal.select_option_report_an_issue'),
          id: LEAVE_FEEDBACK_TYPES_OPTIONS.REPORT_AN_ISSUE,
          icon: 'wrench-tool-2-line',
          tag: LEAVE_FEEDBACK_TAGS_OPTIONS.ISSUE
        },
        {
          label: this.$t('report_issue_modal.select_option_suggest_an_idea'),
          id: LEAVE_FEEDBACK_TYPES_OPTIONS.SUGGEST_AN_IDEA,
          icon: 'light-bulb-line',
          tag: LEAVE_FEEDBACK_TAGS_OPTIONS.IDEA
        },
        {
          label: this.$t('report_issue_modal.select_option_send_a_comment'),
          id: LEAVE_FEEDBACK_TYPES_OPTIONS.SEND_A_COMMENT,
          icon: 'chat-2-line',
          tag: LEAVE_FEEDBACK_TAGS_OPTIONS.COMMENT
        }
      ];
    }
  },

  methods: {
    ...mapActions(['reportIssue', 'setShowReportIssueModal']),

    setSelectedOption(option) {
      this.selectedIssueType = option.id;
      this.selectedIssueTag = option.tag;
    },

    hide() {
      this.setShowReportIssueModal(false);
    },

    submit() {
      if (this.isSubmitDisabled) {
        return;
      }
      this.reportIssue({
        description: this.description,
        email: this.email,
        tags: [this.selectedIssueTag]
      });
      analytics.trackEvent(ANALYTICS.REPORT_AN_ISSUE, {
        category: this.selectedIssueTag
      });
      this.hide();
    }
  }
};
</script>

<style scoped>
.content {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.text {
  font-weight: 600;
  padding-bottom: 8px;
}

.text-area {
  width: 400px;
  margin-top: 20px;
}

.issue-selector {
  width: 400px;
  margin-top: 10px;
}
</style>
