<template>
  <div class="container vvd-scheme-alternate">
    <div class="dialog-container">
      <div class="header-container">
        <div class="heading-wrapper">
          <img src="/smile-with-glasses.svg" class="title-icon" />
          <div class="title-wrapper">
            <div class="title">{{ $t('reactions_counter_modal.title') }}</div>
          </div>
        </div>
        <div v-if="isSessionOwner" class="buttons">
          <vwc-icon
            class="reset-button"
            type="reset-solid"
            size="small"
            @click="resetCounter"
          />
          <vwc-icon
            class="dismiss-button"
            type="close-solid"
            size="small"
            @click="hide"
          />
        </div>
      </div>
      <div slot="content" class="content">
        <div class="reactions-container">
          <div class="total-count">
            <div class="calc-icon-container">
              <vwc-icon
                type="calculator-line"
                class="calc-icon"
                :class="{ branded: isBranded }"
                connotation="cta"
              />
            </div>
            <div class="total-number">{{ totalCount }}</div>
          </div>

          <div
            v-for="reaction in reactionsCounts"
            :key="reaction.id"
            class="reaction-icon-container"
            :class="{
              max: isMaxCounter(reaction),
              min: isMinCounter(reaction)
            }"
            @click="sendReaction(reaction.id)"
          >
            <img :src="reaction.path" class="icon" />
            <div class="reaction-count">
              {{ reaction.count }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'ReactionsCounterModal',

  computed: {
    ...mapState('reactionsCounter', [
      'isReactionsCounterModalOpen',
      'reactionsCounts',
      'totalCount'
    ]),
    ...mapState(['isBranded']),
    ...mapGetters(['isSessionOwner']),

    minCounterNumber() {
      const reactionCounts = this.reactionsCounts.map(
        (reaction) => reaction.count
      );
      return Math.min.apply(Math, reactionCounts);
    },

    maxCounterNumber() {
      const reactionCounts = this.reactionsCounts.map(
        (reaction) => reaction.count
      );
      return Math.max.apply(Math, reactionCounts);
    }
  },

  methods: {
    ...mapActions('reactionsCounter', [
      'setIsReactionsCounterModalOpen',
      'sendResetReactionsCounterSignal',
      'sendStopReactionsCounterSignal'
    ]),
    ...mapActions('reactions', ['sendReaction']),

    hide() {
      this.setIsReactionsCounterModalOpen(false);
      this.sendStopReactionsCounterSignal();
    },

    resetCounter() {
      this.sendResetReactionsCounterSignal();
    },

    isMaxCounter(reaction) {
      return reaction.count === this.maxCounterNumber && reaction.count !== 0;
    },

    isMinCounter(reaction) {
      return reaction.count === this.minCounterNumber;
    }
  }
};
</script>

<style scoped>
.reactions-container {
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.reaction-icon-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 48px;
  border-radius: 6px;
  margin-left: 12px;
  border: 1px solid #cccccc;
  justify-content: space-evenly;
  margin-top: 10px;
  cursor: pointer;
  user-select: none;
}

.icon {
  width: 24px;
  height: 24px;
}

.reaction-count {
  font-weight: 600;
  color: black;
  font-size: 14px;
}

.total-count {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 110px;
  height: 48px;
  border-radius: 6px;
  margin-top: 10px;
  border: 1px solid var(--btn-cta-color-white-bg-hover);
  font-weight: 500;
  background: var(--btn-cta-color-white-bg);
  user-select: none;
}

.total-number {
  font-size: 32px;
  color: white;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container {
  width: 480px;
  height: 125px;
  background: white;
  border-radius: 10px;
}

.dialog-container {
  overflow: hidden;
}

.header-container {
  padding: 10px 10px 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title-icon {
  margin-left: 12px;
  margin-right: 12px;
  align-self: flex-start;
  width: 28px;
  height: 28px;
  fill: black;
  color: #212121;
  user-select: none;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: black;
}

.heading-wrapper {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
  flex: 3;
}

.dismiss-button {
  margin-left: 16px;
  align-self: flex-start;
  cursor: pointer;
  color: black;
}

.reset-button {
  align-self: flex-start;
  cursor: pointer;
  color: black;
}

.dismiss-button:hover,
.reset-button:hover {
  color: #666666;
}
.buttons {
  display: flex;
  flex-direction: row;
  align-self: center;
  padding-right: 5px;
}

.reaction-icon-container.max {
  border: 1px solid rgb(21, 137, 21) !important;
  background: #e1f8e5;
}

.reaction-icon-container.min {
  border: 1px solid red !important;
  background: #ffeef2;
}

.calc-icon-container {
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background: white;
  display: flex;
  justify-content: center;
  align-items: center;
}

vwc-icon.calc-icon {
  width: 14px;
  height: 16px;
}

vwc-icon.branded.calc-icon {
  --vvd-color-cta: var(--btn-cta-color-white-bg);
}
</style>
