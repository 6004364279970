/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-broadcast-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M24 13.5h-3V12a9.01 9.01 0 00-9-9h-1.5V0H12a12.013 12.013 0 0112 12v1.5zm-12.44 1.06l2.121-2.12-2.12-2.122L9.44 12.44 3.075 6.075a10.5 10.5 0 1014.85 14.85L11.56 14.56zm4.94-1.06h3V12A7.51 7.51 0 0012 4.5h-1.5v3H12a4.5 4.5 0 014.5 4.5v1.5z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
