/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'whiteboard-icon': {
    width: 16,
    height: 17,
    viewBox: '0 0 16 17',
    data: '<path pid="0" d="M15.303 3.623l.556-.533a.433.433 0 000-.64L13.96.634A.48.48 0 0013.626.5a.492.492 0 00-.333.133L11.039 2.79h.007l-4.39 4.202a.374.374 0 00-.118.173l-.946 2.723a.445.445 0 00.3.573c.048.013.097.02.146.02a.412.412 0 00.153-.027l2.845-.905c.07-.02.132-.06.187-.107l6.08-5.82z" _fill="#B77EF9"/><path pid="1" d="M14.301 12.192h-13.3V2.79h8.737l1.002-.958H.668c-.369 0-.668.286-.668.639v10.04c0 .354.3.64.668.64h13.967c.369 0 .668-.286.668-.64V4.869l-1.002.959v6.365zM12.924 14.502L2.379 14.47a.995.995 0 00-.988.999.99.99 0 00.988.999l10.545.033a.994.994 0 00.988-.999.994.994 0 00-.988-.998z" _fill="#B77EF9"/>'
  }
})
