<template>
  <div class="preview">
    <div class="preview-content">
      <div class="participant">
        <div class="avatar">RB</div>
        <div class="names">
          <div class="participant-name">Randall Warren</div>
          <div class="participant-description">Host</div>
        </div>
        <v-icon
          md
          :iconName="isVideo ? 'Vlt-icon-video' : 'Vlt-icon-video-off'"
          class="video-icon"
          :class="isVideo ? 'icon-enabled' : 'icon-disabled'"
          @click="toggleVideo"
        />
        <v-icon
          md
          :iconName="
            isAudio ? 'Vlt-icon-microphone' : 'Vlt-icon-microphone-mute'
          "
          class="audio-icon"
          :class="isAudio ? 'icon-enabled' : 'icon-disabled'"
          @click="toggleAudio"
        />
      </div>
      <vwc-button
        class="enter-button"
        label="Join Meeting"
        layout="filled"
        connotation="cta"
      />
      <div class="settings">
        <div class="settings-row">
          <v-icon
            class="icon Vlt-white"
            icon-name="Vlt-icon-video-negative"
          ></v-icon>
          <p>Enable computer video</p>
          <vwc-switch
            connotation="cta"
            :checked="isVideo"
            @change="toggleVideo"
          />
        </div>
        <div class="settings-row">
          <v-icon
            class="icon Vlt-white"
            icon-name="Vlt-icon-microphone-full"
          ></v-icon>
          <p>Enable computer audio</p>
          <vwc-switch
            connotation="cta"
            :checked="isAudio"
            @change="toggleAudio"
          />
        </div>
      </div>
      <div class="bottom-bar">
        <v-button class="share-screen-button" lg secondary app>
          <v-icon
            class="action-button-icon"
            iconName="Vlt-icon-screen-share-full"
          />
          <span class="action-button-title">Screen Share</span>
        </v-button>
        <v-button class="apps-button" lg secondary app>
          <v-icon class="action-button-icon" iconName="Vlt-icon-rocket-full" />
          <span class="action-button-title">Apps</span>
        </v-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'WhitelabelingPreviewScreen',
  data() {
    return {
      isAudio: false,
      isVideo: true
    };
  },

  mounted() {
    const color = this.$route.query.color;
    if (color) {
      this.init(color);
    }
  },

  methods: {
    ...mapActions('whitelabel', ['init']),
    toggleVideo() {
      this.isVideo = !this.isVideo;
    },
    toggleAudio() {
      this.isAudio = !this.isAudio;
    }
  }
};
</script>
<style scoped>
.preview {
  background: white;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.preview-content {
  display: flex;
  width: 410px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 20px;
}

.participant {
  height: 76px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 2px 1px rgba(19, 20, 21, 0.05),
    0px 1px 2px rgba(19, 20, 21, 0.05), 0px 1px 4px rgba(19, 20, 21, 0.1);
  border-radius: 6px;
  padding: 0px 15px;
}

.avatar {
  width: 48px;
  height: 48px;
  background: var(--avatar-circle-bg-color-dark);
  color: var(--avatar-icon-fill-dark);
  border-radius: 24px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}

.names {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
  padding: 0px 13px;
}

.participant-name {
  font-size: 16px;
  font-weight: 600;
  color: black;
}

.participant-description {
  font-size: 14px;
  color: #616266;
}

.icon-enabled {
  fill: var(--participant-row-icon-color);
}

.icon-disabled {
  fill: var(--participant-row-icon-color-disabled);
}

.video-icon {
  margin-right: 16px;
}

vwc-button.enter-button {
  margin: 24px 0;
}

.settings {
  background: black;
  height: 91px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.settings-row {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 24px;
  padding: 0px 16px;
}

.settings-row .icon {
  height: 16px;
  width: 16px;
}

.settings-row p {
  color: white;
  margin: 0px;
  font-size: 14px;
  flex-grow: 1;
  padding: 0px 8px;
}

.bottom-bar {
  background: #1a1a1a;
  height: 80px;
  width: 100%;
  border-radius: 6px;
  margin: 24px 0px 27px 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bottom-bar button {
  width: 105px;
  height: 60px;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 13px;
  padding: 4px 0px 0px 0px;
  margin: 4px;
}

.bottom-bar .v-icon {
  margin-top: 0;
  margin-right: 0;
  width: 16px;
  height: 16px;
}

.share-screen-button {
  background: #333333 !important;
  color: var(--action-button-cta-color);
}

.share-screen-button .v-icon {
  fill: var(--action-button-cta-color);
}

.share-screen-button:hover {
  background: #666666 !important;
}

.apps-button {
  background: var(--btn-cta-color-black-bg);
  color: var(--btn-cta-color-black-bg-text-color);
}

.apps-button .v-icon {
  fill: var(--btn-cta-color-black-bg-text-color);
}

.apps-button:hover {
  background: var(--btn-cta-color-black-bg-hover);
}
</style>
