import { v4 as uuid } from 'uuid';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import * as busStationApi from '@/apis/bus-station-api';
import {
  MIN_RECONNECT_DELAY,
  MAX_RECONNECT_DELAY,
  WS_CLOSED_NORMAL,
  RECONNECTION_DELAY_GROW_FACTOR,
  NETWORK_ERROR_RECONNECTION_DELAY,
  PONG_TIMEOUT_SEC,
  ACTIVITY_TIMEOUT_SEC
} from '@/consts/bus';
import { convertJsonToUint8Array } from '@/helpers/global-helpers';
import * as vbcGw from '@/apis/vbc-gw';

const PING_PACKET = convertJsonToUint8Array({ type: 'PING' });
let busStation = busStationApi;
if (window.Cypress && window.BusStationMock) {
  busStation = window.BusStationMock;
}

class PushNotificationsService {
  constructor() {
    this.handlers = {};
    this.onReconnectHandlers = [];
    this._retryCountBusDetails = 0;
    this._retryCountWS = 0;
    this._isWSConnected = false;
    this._wasOpened = false;
    this._activityTimeout = 0;
    this._pongTimeout = 0;
    this._initTimeout = 0;
    window.addEventListener('online', () =>
      this._restartBusConnection(NETWORK_ERROR_RECONNECTION_DELAY)
    );
    window.addEventListener('offline', () =>
      this._restartBusConnection(NETWORK_ERROR_RECONNECTION_DELAY)
    );
  }

  async init(accountId, userId, domain) {
    this._checkArguments(accountId, userId);
    busStation.init(domain);
    try {
      // Clear any previous attempt to init the bus connection
      if (this._initTimeout) {
        clearTimeout(this._initTimeout);
        this._initTimeout = 0;
      }
      this._initFields(accountId, userId, uuid());
      this._retryCountBusDetails++;
      const busDetails = await busStation.getBusDetailsAndRegister(
        vbcGw.getCredentials().externalId,
        this.accountId,
        this.userId,
        this.deviceId
      );
      this.busConnectionUrl = busDetails.url;
      this._retryCountBusDetails = 0;
    } catch (error) {
      /* error can be unauthorized from api gw then we need to get new accessToken
      or general error (we couldn't get to the db/rabbit/frizzle).
      In both cases we will try to the the init command. */
      const delay =
        error.message === 'Network Error'
          ? NETWORK_ERROR_RECONNECTION_DELAY
          : this._getDelay(this._retryCountBusDetails);
      this._restartBusConnection(delay);
      return;
    }
    await this._wsConnectAndListen();
  }

  addHandler(serviceName, messageType, handler) {
    this.handlers[serviceName] = this.handlers[serviceName] || {};
    this.handlers[serviceName][messageType] = handler;
  }

  ping() {
    if (!this.webSocket) {
      return;
    }
    this.webSocket.send(PING_PACKET);
    this._pongTimeout = setTimeout(
      () => this._restartBusConnection(),
      PONG_TIMEOUT_SEC
    );
  }

  isConnected() {
    return this._isWSConnected;
  }

  onreconnect(handler) {
    this.onReconnectHandlers.push(handler);
  }

  unregisterBusStation() {
    busStation.unregisterBusStation(this.accountId, this.deviceId);
    this._cleanOldWebsocket();
    this._clearPongTimeout();
    this._clearActivityTimeout();
  }

  _clearPongTimeout() {
    if (this._pongTimeout) {
      clearTimeout(this._pongTimeout);
      this._pongTimeout = 0;
    }
  }

  _clearActivityTimeout() {
    if (this._activityTimeout) {
      clearTimeout(this._activityTimeout);
      this._activityTimeout = 0;
    }
  }

  _restartActivityTimeout() {
    this._clearActivityTimeout();
    this._activityTimeout = setTimeout(() => this.ping(), ACTIVITY_TIMEOUT_SEC);
  }

  _listen() {
    if (!this.webSocket) {
      const delay = this._getDelay(this._retryCountWS);
      this._restartBusConnection(delay);
      return;
    }

    this.webSocket.onmessage = (messageEvent) => {
      let payload;
      this._restartActivityTimeout();
      const decoder = new TextDecoder('utf-8');
      try {
        payload = JSON.parse(decoder.decode(new Uint8Array(messageEvent.data)));
      } catch (error) {
        logger.error('PushNotificationsService', LOG_CATEGORIES.BUS, {
          err: error.toString()
        });
      }

      const isMessageInvalid =
        !payload ||
        !payload.type ||
        (payload.type !== 'PONG' && !payload.service);

      if (isMessageInvalid) {
        const errorMessage = '[BUS STATION] - Message in wrong format';
        logger.error('PushNotificationsService', LOG_CATEGORIES.BUS, {
          message: errorMessage
        });
        return;
      }

      if (payload.type === 'PONG') {
        this._clearPongTimeout();
        return;
      }

      const messageType = payload.type;
      const serviceName = payload.service;
      if (
        this.handlers[serviceName] &&
        this.handlers[serviceName][messageType] &&
        payload.data
      ) {
        this.handlers[serviceName][messageType](payload.data);
      }
    };

    this.webSocket.onerror = () => {
      this.webSocket.close();
    };

    this.webSocket.onopen = () => {
      this._retryCountWS = 0;
      this._isWSConnected = true;
      this._restartActivityTimeout();
      if (this._wasOpened) {
        this.onReconnectHandlers.forEach((handler) => handler());
      }
      this._wasOpened = true;
    };

    this.webSocket.onclose = (closeEvent) => {
      if (closeEvent.code === WS_CLOSED_NORMAL) {
        this._isWSConnected = false;
        this._retryCountWS = 0;
      } else {
        if (this._activityTimeout) {
          return;
        }
        const delay = this._getDelay(this._retryCountWS);
        this._restartBusConnection(delay);
      }
    };
  }

  async _wsConnectAndListen() {
    try {
      this._retryCountWS++;
      this._cleanOldWebsocket();
      this.webSocket = await busStation.createWSConnection(
        this.busConnectionUrl
      );
    } catch (error) {
      const delay =
        error.message === 'Network Error'
          ? NETWORK_ERROR_RECONNECTION_DELAY
          : this._getDelay(this._retryCountWS);
      this._restartBusConnection(delay);
      return;
    }
    this._listen();
  }

  _getDelay(retryCount) {
    /* eslint-disable-next-line */
    let delay =
      MIN_RECONNECT_DELAY *
      Math.pow(RECONNECTION_DELAY_GROW_FACTOR, retryCount);
    if (delay > MAX_RECONNECT_DELAY) {
      delay = MAX_RECONNECT_DELAY;
    }
    return delay;
  }

  _restartBusConnection(delay) {
    if (this._initTimeout) {
      return;
    }
    this._clearActivityTimeout();
    this._clearPongTimeout();
    this._isWSConnected = false;
    this._initTimeout = setTimeout(
      () => this.init(),
      delay || MIN_RECONNECT_DELAY
    );
  }

  _cleanOldWebsocket() {
    if (this.webSocket) {
      delete this.webSocket.onerror;
      delete this.webSocket.onclose;
      delete this.webSocket.onopen;
      this.webSocket.close();
    }
  }

  _checkArguments(accountId, userId) {
    if (!accountId && !this.accountId) {
      throw new Error('PushNotificationsService - accountId is mandatory');
    }
    if (!userId && !this.userId) {
      throw new Error('PushNotificationsService - userId is mandatory');
    }
  }

  _initFields(accountId, userId, deviceId) {
    if (accountId) {
      this.accountId = accountId;
    }
    if (userId) {
      this.userId = userId;
    }
    if (deviceId) {
      this.deviceId = deviceId;
    }
  }
}

const pushNotificationsService = new PushNotificationsService();

export default pushNotificationsService;
