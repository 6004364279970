/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-android': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.9 8.8c-.3 0-.6.1-.8.3-.3.2-.4.5-.4.8v5c0 .3.1.6.3.8.2.2.5.3.8.3.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8v-5c0-.3-.1-.6-.3-.8-.1-.2-.4-.3-.7-.3zM14.8 3.9l.8-1.5c.1-.1 0-.2-.1-.2s-.2 0-.2.1l-.8 1.5c-.7-.4-1.5-.5-2.3-.5-.8 0-1.6.2-2.3.5L9 2.2c0-.1-.1-.1-.2-.1-.1.1-.1.2-.1.3l.8 1.5c-.8.4-1.5 1-2 1.8-.4.8-.7 1.6-.7 2.5h10.7c0-.9-.2-1.7-.7-2.5-.5-.8-1.1-1.4-2-1.8zm-4.7 2.2c-.1.1-.2.2-.3.2-.1 0-.2 0-.3-.1-.1-.2-.2-.3-.2-.4 0-.1 0-.2.1-.3.1-.1.2-.1.3-.1s.3 0 .4.1c.1.1.1.2.1.3 0 .1 0 .2-.1.3zm4.9 0c-.1.1-.2.1-.3.1-.1 0-.2 0-.3-.1-.1-.1-.1-.2-.1-.3 0-.1 0-.2.1-.3.1-.1.2-.1.3-.1.1 0 .2 0 .3.1.1.1.1.2.1.3 0 .1 0 .2-.1.3zM6.9 16.7c0 .4.1.7.4.9.2.2.5.4.9.4H9v2.6c0 .3.1.6.3.8.2.2.5.3.8.3.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8V18H13v2.6c0 .3.1.6.3.8.2.2.5.3.8.3.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8V18h.9c.3 0 .6-.1.9-.4.2-.2.4-.5.4-.9V9H6.9v7.7zM19.5 8.8c-.3 0-.6.1-.8.3-.2.2-.3.5-.3.8v5c0 .3.1.6.3.8s.5.3.8.3c.3 0 .6-.1.8-.3.2-.2.3-.5.3-.8v-5c0-.3-.1-.6-.3-.8-.2-.2-.4-.3-.8-.3z"/>'
  }
})
