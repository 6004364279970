/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-address-book': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.5 2.25c0-.45.3-.75.75-.75h16.5c.45 0 .75.3.75.75v19.5c0 .45-.3.75-.75.75H2.25c-.45 0-.75-.3-.75-.75V2.25zM2.25 0C1.05 0 0 1.05 0 2.25v19.5C0 22.95 1.05 24 2.25 24h16.5c1.2 0 2.25-1.05 2.25-2.25V2.25C21 1.05 19.95 0 18.75 0H2.25zM24 3.75c0-.45-.3-.75-.75-.75s-.75.3-.75.75v3c0 .45.3.75.75.75s.75-.3.75-.75v-3zM23.25 9c.45 0 .75.3.75.75v3c0 .45-.3.75-.75.75s-.75-.3-.75-.75v-3c0-.45.3-.75.75-.75zM9 7.5c0-.9.6-1.5 1.5-1.5s1.5.6 1.5 1.5S11.4 9 10.5 9 9 8.4 9 7.5zm1.5-3c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm3.45 10.05c-.75-.6-1.65-1.05-2.7-1.05h-1.5c-1.05 0-1.95.45-2.55 1.05-.6.6-.9 1.2-1.05 1.95H15c-.15-.75-.6-1.5-1.05-1.95zM9.75 12h1.5c1.35 0 2.7.6 3.75 1.5.75.75 1.35 1.65 1.5 2.7.15.45 0 .9-.3 1.2-.3.45-.75.6-1.2.6H6.15c-.45 0-.9-.15-1.35-.6-.3-.3-.45-.75-.3-1.2.3-1.05.75-1.95 1.5-2.7 1.05-.9 2.4-1.5 3.75-1.5z"/>'
  }
})
