export const LOG_CATEGORIES = Object.freeze({
  UI_INTERACTION: 'uiInteraction',
  API_CALL: 'apiCall',
  TOKBOX: 'tokbox',
  DEVICES: 'devices',
  BUS: 'bus',
  MESSAGING: 'messaging',
  SETTINGS: 'settings',
  CLIENT_LOGIC: 'clientLogic',
  LINK_FLOW: 'linkFlow',
  NATIVE_SOCKET: 'nativeSocket',
  SPLIT_AUDIO: 'splitAudio',
  MOBILE: 'mobile',
  WHITEBOARD: 'whiteboard',
  WAITING_ROOM: 'waiting-room',
  LOCALIZATION: 'localization'
});
