import S3 from 'aws-sdk/clients/s3';

export function uploadFileToS3({
  file,
  bucket,
  region = 'us-east-1',
  key,
  accessKeyId,
  secretAccessKey,
  sessionToken,
  callback,
  addUploadRequest
}) {
  const params = {
    Body: file,
    Bucket: bucket,
    Key: key,
    ContentType: file.type
  };

  const s3 = new S3({
    httpOptions: { timeout: 5 * 60 * 1000 },
    region,
    credentials: {
      accessKeyId,
      secretAccessKey,
      sessionToken
    }
  });
  const options = { queueSize: 2 };
  const upload = s3
    .upload(params, options)
    .on('httpUploadProgress', (event) => {
      callback(event, upload);
    });
  addUploadRequest(upload);
  return upload.promise();
}

export function getS3FileUrl(
  {
    bucket,
    key,
    accessKeyId,
    secretAccessKey,
    sessionToken,
    region = 'us-east-1'
  },
  saveAs,
  contentType
) {
  const params = {
    Bucket: bucket,
    Key: key
  };

  if (saveAs) {
    params.ResponseContentDisposition = `attachment; filename="${encodeURIComponent(
      saveAs
    )}"`;
  }
  if (contentType) {
    params.ResponseContentType = contentType;
  }

  const s3 = new S3({
    region,
    credentials: {
      accessKeyId,
      secretAccessKey,
      sessionToken
    },
    signatureVersion: 'v4'
  });

  return new Promise((resolve, reject) => {
    s3.getSignedUrl('getObject', params, (err, url) => {
      if (err) {
        reject(err);
      }
      resolve(url);
    });
  });
}
