var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "meeting-info-container" },
    [
      _c(
        "div",
        { staticClass: "meeting-id-container" },
        [
          _c("div", { staticClass: "meeting-id" }, [
            _c(
              "span",
              {
                staticClass: "option-title",
                attrs: { "data-cy": "meeting-id-title" },
              },
              [_vm._v(_vm._s(_vm.$t("meeting_info.meeting_id_title")))]
            ),
            _c(
              "span",
              {
                staticClass: "option-value",
                attrs: { "data-cy": "meeting-id" },
              },
              [_vm._v(_vm._s(_vm.roomPinCode))]
            ),
          ]),
          _c("vwc-icon-button", {
            attrs: {
              "data-cy": "copy-icon-pin",
              icon: "copy-2-line",
              onmouseup: "this.blur()",
            },
            on: { click: _vm.copyRoomPinToClipboard },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "link-container" },
        [
          _c("div", { staticClass: "meeting-link" }, [
            _c(
              "span",
              {
                staticClass: "option-title",
                attrs: { "data-cy": "meeting-link-title" },
              },
              [_vm._v(_vm._s(_vm.$t("meeting_info.meeting_link_title")))]
            ),
            _c(
              "span",
              {
                staticClass: "option-value",
                attrs: { "data-cy": "meeting-link" },
              },
              [_vm._v(_vm._s(_vm.roomUrl))]
            ),
          ]),
          _c("vwc-icon-button", {
            attrs: {
              "data-cy": "copy-icon-link",
              icon: "copy-2-line",
              onmouseup: "this.blur()",
            },
            on: { click: _vm.copyRoomUrlToClipboard },
          }),
        ],
        1
      ),
      _c("DialInInfo", { staticClass: "dial-in" }),
      _c("vwc-button", {
        staticClass: "copy-details-button",
        attrs: {
          "data-cy": "copy-meeting-details",
          connotation: "primary",
          layout: "filled",
          fullwidth: "",
          icon: "copy-2-solid",
          label: _vm.$t("meeting_info.copy_info_button"),
        },
        on: { click: _vm.copyMeetingInfoToClipboard },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }