/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-video-negative': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11 6V3c0-.6-.4-1-1-1H1c-.6 0-1 .4-1 1v10c0 .6.4 1 1 1h9c.6 0 1-.4 1-1v-3l5 2V4l-5 2z"/>'
  }
})
