var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "standup",
      class: { "focus-animation": _vm.isPlayingFocusAnimation },
      on: { animationend: _vm.stopFocusAnimation },
    },
    [
      _c("vwc-icon", {
        staticClass: "standup-icon",
        attrs: { type: "user-sync-line", connotation: "cta" },
      }),
      _c("div", { staticClass: "standup-text" }, [
        _c("span", { staticClass: "meeting-is-locked-text" }, [
          _vm._v(_vm._s(_vm.$t("standup.title"))),
        ]),
        _c("span", { staticClass: "current-speaker-text" }, [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("standup.current_speaker_text", {
                  currentParticipantDisplayName:
                    _vm.currentParticipantDisplayName,
                })
              ) +
              " "
          ),
        ]),
        _c(
          "span",
          { staticClass: "remaining-participants-text Vlt-grey-darker" },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("standup.remaining_participants_text", {
                    remainingParticipantsCount: _vm.remainingParticipantsCount,
                  })
                ) +
                " "
            ),
          ]
        ),
      ]),
      _c("vwc-button", {
        staticClass: "next-button",
        attrs: {
          label: _vm.buttonText,
          layout: "outlined",
          shape: "pill",
          dense: "",
          disabled: _vm.isNextButtonDisabled,
        },
        on: { click: _vm.nextButtonClicked },
      }),
      _c("v-icon", {
        staticClass: "close-standup",
        attrs: { iconName: "Vlt-icon-cross" },
        on: { click: _vm.stopStandup },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }