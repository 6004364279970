var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "layout",
      class: {
        "frameless-titlebar-layout": _vm.showFramelessTitlebar,
      },
    },
    [
      _c(
        "div",
        { staticClass: "main-container" },
        [
          _vm.showLocaleSwitcher && _vm.isLocaleSwitcherEnabled
            ? _c("LocaleSwitcher", { staticClass: "locale-switcher" })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass:
                "prejoin-template-container smooth-flex-grow-animation",
            },
            [
              _c(
                "transition",
                { attrs: { name: "title-fade", mode: "out-in" } },
                [
                  _c("div", { key: _vm.title, staticClass: "header" }, [
                    _c("h3", { staticClass: "subtitle Vlt-white" }, [
                      _vm._v(_vm._s(_vm.subtitle)),
                    ]),
                    _c(
                      "div",
                      { staticClass: "title-header" },
                      [
                        _c(
                          "h2",
                          {
                            staticClass: "title Vlt-white",
                            attrs: { "data-cy": "pre-join-title" },
                          },
                          [_vm._v(" " + _vm._s(_vm.title) + " ")]
                        ),
                        _vm.showTitleLoader
                          ? _c("vwc-circular-progress", {
                              staticClass: "circular-progress",
                              attrs: { indeterminate: "", density: "-4" },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "content-container",
                  class: {
                    "light-gray-background": _vm.useLightGrayBackground,
                  },
                },
                [_vm._t("main-content")],
                2
              ),
              _c(
                "div",
                { staticClass: "footer" },
                [_vm._t("footer-button")],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", [_vm._t("sidebar")], 2),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }