/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-history': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 4.5v4.3l3.3 1.6.4-.8L9 8.2V4.5z"/><path pid="1" d="M9 1.5C5.4 1.5 2.5 4.4 2.5 8v.8L.9 7.1l-.8.8L3 10.7l2.9-2.9-.8-.7-1.6 1.7V8C3.5 5 6 2.5 9 2.5S14.5 5 14.5 8 12 13.5 9 13.5c-1.3 0-2.6-.5-3.5-1.3l-.7.8c1.2.9 2.6 1.5 4.2 1.5 3.6 0 6.5-2.9 6.5-6.5S12.6 1.5 9 1.5z"/>'
  }
})
