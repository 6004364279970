/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-plus-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 0H3c-.9 0-1.5.6-1.5 1.5v21c0 .9.6 1.5 1.5 1.5h18c.9 0 1.5-.6 1.5-1.5v-21C22.5.6 21.9 0 21 0zm-4.5 13.5h-3v3h-3v-3h-3v-3h3v-3h3v3h3v3z"/>'
  }
})
