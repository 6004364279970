/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-wallet': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1.44 1.44A1.5 1.5 0 012.5 1H11v1.5a.5.5 0 001 0v-2a.5.5 0 00-.5-.5h-9A2.5 2.5 0 000 2.5v11A2.5 2.5 0 002.5 16h13a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5h-13a1.5 1.5 0 01-1.06-2.56zM1 13.5v-9c.43.323.956.5 1.5.5H15v10H2.5A1.5 1.5 0 011 13.5zm9-3.5a1 1 0 112 0 1 1 0 01-2 0zm1-2a2 2 0 100 4 2 2 0 000-4z"/>'
  }
})
