/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-shout': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.95 1.35l.6.45c1.95 1.8 3.15 4.5 3.3 7.2 0 2.7-1.2 5.4-3.3 7.2l-.6.45-1.05-1.05.6-.45c1.65-1.5 2.7-3.75 2.7-6.15 0-2.4-.9-4.65-2.7-6.15l-.6-.45 1.05-1.05zM12.75 9c0 2.7-2.25 4.8-4.8 4.8S3.3 11.55 3.3 9s1.95-4.8 4.65-4.8 4.8 2.1 4.8 4.8zm-1.5 0c0-1.8-1.5-3.3-3.3-3.3C6.15 5.7 4.8 7.2 4.8 9c0 1.8 1.35 3.3 3.15 3.3 1.8 0 3.3-1.5 3.3-3.3zM8.1 15.3c1.95 0 3.9.3 5.4.9 1.35.6 2.25 1.95 2.4 3.45v3H.45v-3c0-1.5.9-2.85 2.25-3.45 1.5-.6 3.3-.9 5.4-.9zm-6.3 4.5v1.5h12.3v-1.5c0-.9-.45-1.8-1.35-2.1-1.35-.6-3-.9-4.8-.9-1.8 0-3.45.3-4.8.9-.75.3-1.35 1.2-1.35 2.1zM17.1 5.25L15.9 6.3l.6.6c.45.6.75 1.35.75 2.1s-.3 1.5-.75 2.1l-.45.6 1.2 1.05.45-.6c.75-.9 1.2-1.95 1.2-3.15s-.45-2.25-1.2-3.15l-.6-.6z"/>'
  }
})
