import Vue from 'vue';
import * as messaging from '@/apis/messaging-api';
import * as s3Api from '@/apis/aws-s3-api';
import * as vbcGw from '@/apis/vbc-gw';
import Throat from 'throat';
import { isElectron } from '@/helpers/global-helpers';

// Library to manage a queue of promise requests. This way there will be at most 2 upload
// requests at a time.
const throat = Throat(2);

// Object to save uploads that are in the throat queue and haven't started yet.
const uploadsInQueue = {};

// Object to save ongoing upload requests so we can cancel them.
const uploadsRequests = {};

// When cancelling uploads of file of size less than S3 PartSize (default 5mb), the httpUploadProgress
// event keeps getting called for deprecated uploads so we need to ignore it.
const uploadsIdentifiers = {};

function getUploadIdentifier(messageId) {
  if (uploadsIdentifiers[messageId]) {
    uploadsIdentifiers[messageId]++;
  } else {
    uploadsIdentifiers[messageId] = 1;
  }
  return uploadsIdentifiers[messageId];
}

let s3 = s3Api;
if (window.Cypress && window.S3ApiMock) {
  s3 = window.S3ApiMock;
}

export default {
  namespaced: true,

  state: {
    // Helps tracking the upload progress of attachment files
    uploadsProgress: {},
    cancelledRequests: {},
    imageViewerMsgId: '',
    imageViewerDeleteMessage: '',
    videosUrl: {}
  },

  mutations: {
    // TODO change to oneliners
    UPDATE_UPLOAD_PROGRESS: (state, { messageId, percentsLoaded }) => {
      // Needed for when cancelling an upload doesn't cancel the httpUploadProgress event.
      // This happens with files with size less than the partSize (currently configured to s3 default)
      if (
        state.uploadsProgress[messageId] === undefined &&
        percentsLoaded > 0
      ) {
        return;
      }
      Vue.set(state.uploadsProgress, messageId, percentsLoaded);
    },

    REMOVE_UPLOAD_PROGRESS: (state, { messageId }) =>
      Vue.delete(state.uploadsProgress, messageId),

    SET_IMAGE_VIEWER_MESSAGE_ID: (state, { messageId }) =>
      (state.imageViewerMsgId = messageId),

    SET_IMAGE_VIEWER_DELETE_MESSAGE: (state, { deleteMessage }) =>
      (state.imageViewerDeleteMessage = deleteMessage),

    ADD_UPLOAD_REQUEST: (state, { messageId, upload }) =>
      (uploadsRequests[messageId] = upload),

    REMOVE_UPLOAD_REQUEST: (state, { messageId }) =>
      delete uploadsRequests[messageId],

    ADD_CANCELLED_REQUEST: (state, { messageId }) =>
      Vue.set(state.cancelledRequests, messageId, true),

    REMOVE_CANCELLED_REQUEST: (state, { messageId }) =>
      Vue.delete(state.cancelledRequests, messageId),

    SET_ATTACHMENTS_VIDEOS_URL: (state, { messageId, url }) =>
      (state.videosUrl[messageId] = url)
  },

  getters: {
    // Needed to know which attachment to work with in the ImageViewer
    imageViewerMsgId: (state) => state.imageViewerMsgId,

    imageViewerDeleteMessage: (state) => state.imageViewerDeleteMessage,

    cancelledRequests: (state) => state.cancelledRequests,

    uploadsProgressMap: (state) => state.uploadsProgress,

    videosUrl: (state) => state.videosUrl
  },

  actions: {
    uploadFileToS3: ({ commit }, { file, messageId }) => {
      // For cancelling uploads that haven't started yet but are in the throat queue.
      uploadsInQueue[messageId] = true;
      commit('REMOVE_CANCELLED_REQUEST', { messageId });

      const uploadIdentifier = getUploadIdentifier(messageId);

      // Throat is used to have at most 2 attachments upload at a time.
      return throat(() => {
        if (!uploadsInQueue[messageId]) {
          // The upload was cancelled before it has started
          throw new Error('UploadCancelled');
        }

        delete uploadsInQueue[messageId];
        return messaging
          .getS3TokenForUpload(vbcGw.getCredentials().externalId, file.name)
          .then(
            ({ bucket, key, sessionToken, secretAccessKey, accessKeyId }) => {
              return s3.uploadFileToS3({
                file,
                bucket,
                key,
                accessKeyId,
                secretAccessKey,
                sessionToken,
                callback(event) {
                  // The if filters calls of uploads that were cancelled but their progress event
                  // keeps getting called. (this happens with files with size smaller than partSize)
                  if (uploadsIdentifiers[messageId] === uploadIdentifier) {
                    // Callback will get called for updating the progress of the file upload.
                    const percentsLoaded = Math.floor(
                      (event.loaded / event.total) * 100
                    );
                    commit('UPDATE_UPLOAD_PROGRESS', {
                      messageId,
                      percentsLoaded
                    });
                  }
                },
                addUploadRequest(upload) {
                  // Add upload request so we can cancel it later.
                  commit('ADD_UPLOAD_REQUEST', { messageId, upload });
                }
              });
            }
          )
          .then((s3Details) => {
            delete uploadsIdentifiers[messageId];
            commit('REMOVE_UPLOAD_REQUEST', { messageId });
            return s3Details;
          });
      });
    },

    cancelUploadRequest: ({ commit }, { messageId }) => {
      if (uploadsInQueue[messageId]) {
        // If attachment is waiting for being uploaded.
        delete uploadsInQueue[messageId];
      } else {
        // Else meaning the upload has already started and we need to cancel an active upload.
        const upload = uploadsRequests[messageId];
        upload.abort();
        commit('REMOVE_UPLOAD_REQUEST', { messageId });
      }
      commit('ADD_CANCELLED_REQUEST', { messageId });
      commit('REMOVE_UPLOAD_PROGRESS', { messageId });
    },

    // We download the file to the disk using a URL so the browser does the loading work instead of
    // our application.
    downloadFileToDisk: async ({ dispatch }, { messageId }) => {
      const fileUrl = await dispatch('getFileUrl', { messageId });
      dispatch('downloadFileFromUrl', fileUrl);
    },

    downloadFileFromUrl: ({ rootGetters }, fileUrl) => {
      if (isElectron()) {
        if (window.Electron.downloadFile) {
          window.Electron.downloadFile(fileUrl);
        } else {
          window.Electron.ipc.sendMessage_downloadFile(fileUrl);
        }
      } else if (rootGetters.isMobileWebMode && window.isSafari) {
        // When opening a new window in Safari it keeps the state history of the opener window,
        // thus after the file is downloaded it pops the last state's url.
        // In our case, it loads meetings url and opens meetings entrance screen.
        // Therefore, we listen to 'load' event and we close the window.
        const downloadWindow = window.open(fileUrl, '_blank');
        downloadWindow.addEventListener('load', () => {
          downloadWindow.close();
        });
      } else {
        window.open(fileUrl, '_blank');
      }
    },

    // Returns a URL pointing to the location file on S3. It has a validity timeout. Currently
    // set to default which is 15 minutes.
    getFileUrl: ({ rootState }, { messageId }) => {
      const message = rootState.messaging.messages[messageId];
      const attachment = message.data[0].metadata;
      // To get download token for facebook we need to pass our facebook page id.
      return messaging
        .getS3TokenForDownload(
          vbcGw.getCredentials().externalId,
          messageId,
          attachment.fileName
        )
        .then((accessDetails) =>
          s3.getS3FileUrl(accessDetails, attachment.fileName)
        );
    },

    updateUploadProgress: ({ commit }, payload) =>
      commit('UPDATE_UPLOAD_PROGRESS', payload),

    removeUploadProgress: ({ commit }, payload) =>
      commit('REMOVE_UPLOAD_PROGRESS', payload)
  }
};
