import Vue from 'vue';
import { PUBLISHER_STATE, USER_MODES } from '@/consts/global-consts';

export default {
  SET_IS_APP_INITIALIZED: (state, isInitialized) =>
    (state.isAppInitialized = isInitialized),

  SET_INIT_USER_SETTINGS_COMPLETED: (state) =>
    (state.initUserSettingsCompleted = true),

  SET_IS_APP_RUNNING: (state, isAppRunning) =>
    (state.isAppRunning = isAppRunning),

  UPDATE_ROOM_DETAILS: (state, details) =>
    (state.roomDetails = { ...state.roomDetails, ...details }),

  SET_GUEST_USER_MODE: (state) => (state.userMode = USER_MODES.GUEST),

  SET_APP_USER_MODE: (state) => (state.userMode = USER_MODES.APP),

  SET_EXTENSION_LOCALE: (state, extensionLocale) =>
    (state.extensionLocale = extensionLocale),

  SET_VBC_USER_INFO: (state, userInfo) => (state.userInfo = userInfo),

  SET_SELECTED_MICROPHONE_ID: (state, microphoneId) =>
    (state.selectedMicrophoneId = microphoneId),

  SET_SELECTED_CAMERA_ID: (state, cameraId) =>
    (state.selectedCameraId = cameraId),

  SET_CURRENT_CAMERA_ID: (state, cameraId) =>
    (state.currentCameraId = cameraId),

  SET_SELECTED_SPEAKER_ID: (state, speakerId) =>
    (state.selectedSpeakerId = speakerId),

  SET_SPEAKER_DEVICES: (state, speakerDevices) =>
    (state.speakerDevices = speakerDevices),

  SET_MICROPHONE_DEVICES: (state, microphoneDevices) =>
    (state.microphoneDevices = microphoneDevices),

  SET_CAMERA_DEVICES: (state, cameraDevices) =>
    (state.cameraDevices = cameraDevices),

  SET_HAS_MICROPHONE_PERMISSIONS: (state, hasPermissions) =>
    (state.hasMicrophonePermissions = hasPermissions),

  SET_HAS_CAMERA_PERMISSIONS: (state, hasPermissions) =>
    (state.hasCameraPermissions = hasPermissions),

  SET_HAS_CAMERA_DEVICES: (state, hasDevices) =>
    (state.hasCameraDevices = hasDevices),

  SET_HAS_MICROPHONE_DEVICES: (state, hasDevices) =>
    (state.hasMicrophoneDevices = hasDevices),

  SET_ACTION_IN_PROGRESS: (state, action) =>
    Vue.set(state.actionsInProgress, action.name, action.inProgress),

  ADD_STREAM: (state, stream) => state.streams.push(stream),

  REMOVE_STREAM: (state, streamId) => {
    const index = state.streams.findIndex(
      (stream) => stream.streamId === streamId
    );
    if (index > -1) {
      state.streams.splice(index, 1);
    }
  },

  UPDATE_STREAM: (state, payload) => {
    Vue.set(state.streams, payload.streamIndex, {
      ...state.streams[payload.streamIndex],
      ...payload.streamData
    });
  },

  SET_LOCKED_STREAM_ID: (state, streamId) => (state.lockedStreamId = streamId),

  SET_PARTICIPANTS_VOLUME: (state, participantsVolumes) =>
    (state.participantsVolumes = participantsVolumes),

  SET_IS_FULL_SCREEN: (state, isFullScreen) =>
    (state.isFullScreen = isFullScreen),

  SET_IS_MAXIMIZED: (state, isMaximized) => (state.isMaximized = isMaximized),

  SET_MY_SCREEN_STREAM_ID: (state, streamId) =>
    (state.myScreenStreamId = streamId),

  SET_LOCK_MEETING: (state, isLocked) => (state.isLocked = isLocked),

  SET_SESSION_JOIN_APPROVAL_LEVEL: (state, sessionJoinApprovalLevel) =>
    (state.sessionJoinApprovalLevel = sessionJoinApprovalLevel),

  SET_SESSION_ACCOUNT_ID: (state, accountId) =>
    (state.sessionAccountId = accountId),

  SET_MIC_ENABLED: (state, isMicEnabled) => (state.isMicEnabled = isMicEnabled),

  SET_VIDEO_ENABLED: (state, isVideoEnabled) =>
    (state.isVideoEnabled = isVideoEnabled),

  SET_NO_AUDIO_MODE: (state, isNoAudioMode) =>
    (state.isNoAudioMode = isNoAudioMode),

  SET_MAIN_STREAM_ID: (state, streamId) => (state.mainStreamId = streamId),

  SET_CONTACTS: (state, contacts) => (state.contacts = contacts),

  RESET_CONTACTS: (state) => (state.contacts = []),

  UPDATE_CONTACTS_INVITE_STATE: (state, { invitees, inviteState }) => {
    invitees.forEach((id) => {
      const contactIndex = state.contacts.findIndex(
        (contact) => contact.id === id
      );
      if (contactIndex > -1) {
        Vue.set(state.contacts, contactIndex, {
          ...state.contacts[contactIndex],
          inviteState
        });
      }
    });
  },

  SET_MY_PARTICIPANT_ID: (state, participantId) =>
    (state.myParticipantId = participantId),

  UPDATE_PARTICIPANT: (state, payload) => {
    Vue.set(state.participants, payload.participantId, {
      ...state.participants[payload.participantId],
      ...payload
    });
  },

  SET_IS_PARTICIPANT_ABOUT_TO_GET_KICKED: (state, isAboutToGetKicked) =>
    (state.isAboutToGetKicked = isAboutToGetKicked),

  SET_SESSION_OWNER: (state, owner) => (state.owner = owner),

  SET_SESSION_ID: (state, sessionId) => (state.sessionId = sessionId),

  SET_IS_JOINING_A_SESSION: (state, isJoining) =>
    (state.isJoiningASession = isJoining),

  SET_IS_SESSION_INITIALIZED: (state, isInitialized) =>
    (state.isSessionInitialized = isInitialized),

  SET_INITIAL_INVITEES: (state, invitees) => (state.initialInvitees = invitees),

  SET_HAS_SESSION_EVER_CONNECTED: (state, hasSessionEverConnected) =>
    (state.hasSessionEverConnected = hasSessionEverConnected),

  SET_IS_SESSION_CONNECTED: (state, isSessionConnected) =>
    (state.isSessionConnected = isSessionConnected),

  SET_IS_TURN_ON_AUDIO_DIALOG_VISIBLE: (state, isVisible) =>
    (state.isTurnOnAudioDialogVisible = isVisible),

  SET_IS_END_MEETING_DIALOG_VISIBLE: (state, isVisible) =>
    (state.isEndMeetingDialogVisible = isVisible),

  SET_IS_INVITE_PARTICIPANTS_DIALOG_VISIBLE: (state, isVisible) =>
    (state.isInviteParticipantsDialogVisible = isVisible),

  SET_IS_LOCK_MEETING_DIALOG_VISIBLE: (state, isVisible) =>
    (state.isLockMeetingDialogVisible = isVisible),

  SET_PARTICIPANT_TO_REMOVE: (state, participantToRemove) =>
    (state.participantToRemove = participantToRemove),

  SET_IS_HIJACK_SCREENSHARE_DIALOG_VISIBLE: (state, isVisible) =>
    (state.isHijackScreenshareDialogVisible = isVisible),

  SET_IS_MUTE_ALL_PARTICIPANTS_DIALOG_VISIBLE: (state, isVisible) =>
    (state.isMuteAllParticipantsModalVisible = isVisible),

  SET_IS_SETTINGS_MODAL_VISIBLE: (state, isVisible) =>
    (state.isSettingsModalVisible = isVisible),

  ADD_FLASH_MESSAGE: (state, options) => {
    // Using unshift to push to the head of the list in order to make the flash pop at the top of the other messages
    state.flashMessages.unshift(options);
  },

  REMOVE_FLASH_MESSAGE: (state, id) => {
    const index = state.flashMessages.findIndex((msg) => msg.id === id);
    if (index >= 0) {
      state.flashMessages.splice(index, 1);
    }
  },

  SET_MEETING_INFO_OPENED: (state, isOpened) =>
    (state.meetingInfoOpened = isOpened),

  SET_SELECTED_DIAL_IN_NUMBERS: (state, selectedNumbers) =>
    (state.selectedDialInNumbers = selectedNumbers),

  SET_PUBLISHER_STATE: (state, publisherState) => {
    state.publisherState = publisherState;

    if (
      publisherState !== PUBLISHER_STATE.NOT_INITIALIZED &&
      publisherState !== PUBLISHER_STATE.INITIALIZING
    ) {
      state.isPublisherInitialized = true;
    }
  },

  SET_OPTIMIZE_PUBLISHERS: (state, optimize) =>
    (state.optimizePublishers = optimize),

  CLEAR_STATE_FOR_RECONNECTION: (state) =>
    (state = Object.assign(state, {
      streams: [],
      participantsVolumes: {},
      mainStreamId: '',
      myScreenStreamId: '',
      isNetworkIssues: false,
      hasSessionEverConnected: false,
      isSessionConnected: false,
      isEndMeetingDialogVisible: false,
      screenshareSources: [],
      flashMessages: []
    })),

  UPDATE_PUBLISHER_STATS: (state, stats) =>
    (state.publisherStats = { ...state.publisherStats, ...stats }),

  SET_PUBLISHER_ERROR: (state, error) => (state.publisherError = error),

  SET_STREAM_SUBSCRIBER_STATUS: (state, { streamId, status }) =>
    Vue.set(state.streamsSubscribersStatus, streamId, status),

  PIN_STREAM: (state, streamId) => (state.pinnedStreamId = streamId),

  SHOW_SUBSCRIBER_STATS: (state, shouldShow) =>
    (state.showSubscriberStats = shouldShow),

  RESET_SUBSCRIBER_STATS: (state) => (state.subscriberStats = {}),

  UPDATE_SUBSCRIBER_STATS: (state, update) => {
    Vue.set(state.subscriberStats, update.streamId, {
      ...state.subscriberStats[update.streamId],
      ...update
    });
  },

  SET_TOUR_STEPS: (state, steps) => (state.tourSteps = steps),

  SET_NUM_OF_TOUR_STEPS: (state, numOfTourSteps) =>
    (state.numOfTourSteps = numOfTourSteps),

  REMOVE_TOUR_STEP: (state) => {
    state.tourSteps.shift();
  },

  SET_IS_NETWORK_ISSUES: (state, isNetworkIssues) =>
    (state.isNetworkIssues = isNetworkIssues),

  SET_CONNECTION_STATUS: (state, status) => (state.connectionStatus = status),

  SET_DOCUMENT_VISIBILITY_STATE: (state, documentVisibilityState) => {
    state.documentVisibilityState = documentVisibilityState;
  },

  KEEP_ACTIONS_BAR_UP: (state, keep) => (state.keepActionsBarUp = keep),

  SET_IS_PTT_ON: (state, isPTTOn) => (state.isPTTOn = isPTTOn),

  KEEP_PARTICIPANT_OPTIONS_MENU_SHOWN: (state, keep) =>
    (state.keepParticipantOptionsMenuShown = keep),

  SET_ACTIVE_SIDEBAR: (state, sidebar) => (state.activeSidebar = sidebar),

  SET_CONFIRMATION_DIALOG: (state, confirmationDialog) =>
    (state.confirmationDialog = confirmationDialog),

  SET_IS_LOADING_PRE_ENTRANCE_DATA: (state, isLoadingPreEntranceData) =>
    (state.isLoadingPreEntranceData = isLoadingPreEntranceData),

  SET_SHOW_ENTRANCE_SCREEN: (state, showScreen) =>
    (state.showEntranceScreen = showScreen),

  SET_SHOW_PRE_JOIN_SCREEN: (state, showScreen) =>
    (state.showPreJoinScreen = showScreen),

  SET_SHOW_THANK_YOU_SCREEN: (state, showScreen) =>
    (state.showThankYouScreen = showScreen),

  SET_SHOW_REPORT_ISSUE_MODAL: (state, show) =>
    (state.showReportIssueModal = show),

  SET_SHOW_SELECT_LANGUAGE_MODAL: (state, show) =>
    (state.showSelectLanguageModal = show),

  SET_GLOBAL_MESSAGE: (state, message) => (state.globalMessage = message),

  SET_INFO_DIALOG: (state, info) => (state.infoDialog = info),

  SET_CURRENT_FORCE_MUTE_EVENT: (state, eventType) =>
    (state.currentForceMuteEvent = eventType),

  SET_ERROR_DIALOG: (state, error) => (state.errorDialog = error),

  SET_PAGE_VISIBILITY: (state, isPageVisible) =>
    (state.isPageVisible = isPageVisible),

  SET_MINIMIZE_MODE: (state, isMinimized) =>
    (state.minimizedMode = isMinimized),

  TOGGLE_UNSUBSCRIBED_VIDEO_STREAM: (state, streamId) => {
    if (state.unsubscribedVideoStreamIds[streamId]) {
      Vue.delete(state.unsubscribedVideoStreamIds, streamId);
    } else {
      Vue.set(state.unsubscribedVideoStreamIds, streamId, true);
    }
  },

  SET_CAPABILITIES: (state, capabilities) =>
    (state.capabilities = capabilities),

  SET_OFFNET_INVITE_DATA: (state, offnetInvite) =>
    (state.offnetInviteData = offnetInvite),

  SET_PARTICIPANT_TOKEN: (state, participantToken) =>
    (state.participantToken = participantToken),

  SET_IS_APP_PRE_INITALIZED: (state, isAppPreInitialized) =>
    (state.isAppPreInitialized = isAppPreInitialized),

  SET_HAS_VALID_ROOM_DETAILS: (state, hasValidRoomDetails) =>
    (state.hasValidRoomDetails = hasValidRoomDetails),

  SET_IS_GUEST_ROOM_DETAILS_FETCH_NEEDED: (
    state,
    isGuestRoomDetailsFetchNeeded
  ) => (state.isGuestRoomDetailsFetchNeeded = isGuestRoomDetailsFetchNeeded),

  SET_IS_LOADING_PRE_INIT_DATA: (state, isLoadingPreInitData) =>
    (state.isLoadingPreInitData = isLoadingPreInitData),

  SET_IS_BRANDED: (state, isBranded) => (state.isBranded = isBranded),

  SET_IS_EMBEDDED: (state, isEmbedded) => (state.isEmbedded = isEmbedded),

  SET_DID_SEND_HIJACK_SCREENSHARE: (state, didSendHijackScreenShare) =>
    (state.didSendHijackScreenShare = didSendHijackScreenShare),

  SET_SHOW_LONGER_THAN_USUAL_PUBLISHER_MESSAGE: (
    state,
    isLongPublisherInitialization
  ) => (state.isLongPublisherInitialization = isLongPublisherInitialization),

  UPDATE_INITIAL_JOIN_CONFIG: (state, joinConfig) => {
    state.initialJoinConfig = {
      ...state.initialJoinConfig,
      ...joinConfig
    };
  },

  SET_VIRTUAL_BACKGROUND: (state, virtualBackground) =>
    (state.virtualBackground = virtualBackground),

  SET_IS_VIRTUAL_BACKGROUND_MODAL_VISIBLE: (state, isVisible) =>
    (state.isVirtualBackgroundModalVisible = isVisible),

  SET_SHOULD_TOGGLE_BLUR_HAVE_FOCUS_ANIMATION: (
    state,
    shouldToggleBlurHaveFocusAnimation
  ) =>
    (state.shouldToggleBlurHaveFocusAnimation = shouldToggleBlurHaveFocusAnimation),

  SET_HIDE_MY_STREAM: (state, hideMyStream) =>
    (state.isHideMyStreamEnabled = hideMyStream),

  SET_IS_MICROPHONE_PAUSED_SAFARI: (state, isMicrophonePausedSafari) =>
    (state.isMicrophonePausedSafari = isMicrophonePausedSafari),

  SET_IS_VIDEO_PAUSED_SAFARI: (state, isVideoPausedSafari) =>
    (state.isVideoPausedSafari = isVideoPausedSafari),

  SET_IS_DEVICES_CHANGED_RECENTLY: (state, isDevicesChangedRecently) =>
    (state.isDevicesChangedRecently = isDevicesChangedRecently)
};
