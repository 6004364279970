/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-microphone-off': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M16.05 1.5C15 .6 13.5 0 12 0 8.7 0 6 2.7 6 6v3c0 .75.15 1.5.45 2.1l9.6-9.6zM22.5 9c-.75-.15-1.65.45-1.65 1.2-.75 4.5-4.5 7.8-9 7.8-1.05 0-2.25-.15-3.15-.6l2.55-2.55H12c3.3 0 6-2.7 6-6V8.1l5.55-5.55c.6-.6.6-1.5 0-2.1-.6-.6-1.5-.6-2.1 0l-21 21c-.6.6-.6 1.5 0 2.1.3.3.6.45 1.05.45.45 0 .75-.15 1.05-.45l3.9-3.9c1.2.6 2.55 1.05 3.9 1.2V24h3v-3.15c5.25-.6 9.6-4.8 10.35-10.2.15-.75-.45-1.5-1.2-1.65zM4.2 13.65c-.6-1.05-1.05-2.1-1.2-3.3-.15-.9-.9-1.5-1.8-1.35-.75.15-1.35.9-1.2 1.65.3 1.95 1.05 3.6 2.1 5.1l2.1-2.1z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
