/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-nexmo': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.764 10.164h-1.31l3.273 3.164L24 10.164h-1.636C21.709 5.037 17.236 1 11.782 1 7.309 1 3.6 3.618 1.964 7.328l3.272 1.309c1.091-2.4 3.6-4.146 6.546-4.146 3.49 0 6.327 2.4 6.982 5.673zM12 14.31c-1.31 0-2.4-1.09-2.4-2.4 0-1.309 1.09-2.4 2.4-2.4 1.31 0 2.4 1.091 2.4 2.4 0 1.31-1.09 2.4-2.4 2.4zm-6.764-.545c.655 3.163 3.491 5.564 6.982 5.564 2.837 0 5.346-1.746 6.546-4.255l3.381 1.309c-1.636 3.819-5.454 6.437-9.927 6.437-5.454 0-9.927-3.928-10.69-9.055H0l3.382-3.273 3.382 3.273H5.236z"/>'
  }
})
