/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-search-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 0h18a1 1 0 011 1v22a1 1 0 01-1 1H3a1 1 0 01-1-1V1a1 1 0 011-1zm10.886 14.3L17 17.414 18.414 16 15.3 12.886A4.457 4.457 0 0015.242 8a4.5 4.5 0 10-3.742 7 4.457 4.457 0 002.386-.7zM14 10.5a2.5 2.5 0 11-5 0 2.5 2.5 0 015 0z"/>'
  }
})
