var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "MobileSelect",
        {
          attrs: { showArrow: "", options: _vm.dialInNumberOptions },
          model: {
            value: _vm.selectedCountry,
            callback: function ($$v) {
              _vm.selectedCountry = $$v
            },
            expression: "selectedCountry",
          },
        },
        [
          _c("span", { staticClass: "Vlt-grey-light" }, [
            _vm._v(_vm._s(_vm.$t("mobile_dial_in_countries.text"))),
          ]),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "selected-country-info",
          attrs: { "data-cy": "selected-country-info-value" },
          on: { click: _vm.copySelectedCountryNumber },
        },
        [
          _c("span", { staticClass: "country-name" }, [
            _vm._v(_vm._s(_vm.selectedCountry.display_name)),
          ]),
          _c("span", [_vm._v(_vm._s(_vm.selectedCountryNumber))]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }