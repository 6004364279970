<template>
  <vwc-banner
    data-cy="no-connection-banner"
    class="banner"
    connotation="alert"
    :message="$t('connectivity_banner.message')"
    open="true"
    icon="wifi-solid"
  />
</template>
<script>
export default {
  name: 'NoConnectionBanner'
};
</script>
<style scoped>
.banner {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 100;
}
</style>
