/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-smile': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 12C1.5 6.201 6.201 1.5 12 1.5S22.5 6.201 22.5 12 17.799 22.5 12 22.5 1.5 17.799 1.5 12zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zM9 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm7.5 1.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-8.357 3.813a.75.75 0 00-1.286.773 6 6 0 0010.286 0 .75.75 0 00-1.286-.773 4.5 4.5 0 01-7.714 0z"/>'
  }
})
