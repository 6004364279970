/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-wifi-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 7.8C3.3 4.65 7.5 3 12 3s8.7 1.65 12 4.8l-2.1 2.25C19.2 7.35 15.75 6 12 6S4.8 7.35 2.1 9.9L0 7.8zM17.25 15c-1.35-1.5-3.3-2.25-5.25-2.25-1.95 0-3.9.75-5.25 2.25l-2.1-2.1C6.6 10.8 9.15 9.75 12 9.75c2.85 0 5.4 1.05 7.35 3.15l-2.1 2.1zM12 21a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"/>'
  }
})
