<template>
  <div :style="animationDurationOverrides" class="main-container">
    <div class="loader-wrapper">
      <div class="loader-wrapper-top-bar">
        <div class="buttons">
          <div class="close" />
          <div class="minimize" />
          <div class="maximize" />
        </div>
      </div>
      <div class="icons-container">
        <div class="item top-item top-left-item">
          <div class="icon-wrapper">
            <vwc-icon class="icon primary-icon" type="user-line"></vwc-icon>
            <vwc-icon class="icon alternate-icon" type="chat-line"></vwc-icon>
          </div>
        </div>
        <div class="item top-item top-right-item">
          <div class="icon-wrapper">
            <vwc-icon class="icon primary-icon" type="user-line"></vwc-icon>
            <v-icon iconName="video-whiteboard" class="icon alternate-icon" />
          </div>
        </div>
        <div class="item bottom-left-item">
          <div class="icon-wrapper">
            <vwc-icon class="icon primary-icon" type="user-line"></vwc-icon>
            <vwc-icon class="icon alternate-icon" type="emoji-line"></vwc-icon>
          </div>
        </div>
        <div class="item bottom-right-item">
          <div class="icon-wrapper">
            <vwc-icon class="icon primary-icon" type="user-line"></vwc-icon>
            <vwc-icon
              class="icon alternate-icon"
              type="screen-share-line"
            ></vwc-icon>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MeetingLoader',

  props: {
    speedInSec: {
      type: Number,
      default: 2
    }
  },
  computed: {
    animationDurationOverrides() {
      return {
        '--total-loader-animation-duration': `${this.speedInSec}s`,
        '--item-loader-animation-duration': `${this.speedInSec / 4}s`
      };
    }
  }
};
</script>

<style scoped>
.main-container {
  /* we'll override this eventually but it's good practice to write it anyway. */
  --total-loader-animation-duration: 2s;
  --item-loader-animation-duration: calc(
    var(--total-loader-animation-duration) / 4
  );
}

.main-container {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-content: center;
  justify-content: center;
}

.loader-wrapper {
  display: flex;
  width: fit-content;
  flex-direction: column;
  align-self: center;
  padding: 2px;
  background: white;
  border-radius: 6px;
}

.loader-wrapper-top-bar {
  display: flex;
  height: 18px;
  border-radius: 6px 6px 0 0;
  background: #0d0d0d;
  margin-bottom: 2px;
}

.buttons {
  display: flex;
  align-items: center;
  margin-left: 6px;
}

.buttons div {
  width: 6px;
  height: 6px;
  margin-left: 4px;
  border-radius: 50%;
}

.close {
  background: rgb(231, 69, 70);
}
.minimize {
  background: rgb(246, 180, 39);
}
.maximize {
  background: rgb(86, 202, 53);
}

.icons-container {
  display: grid;
  background: white;
  height: 100%;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-gap: 2px;
}

.item {
  background: #0d0d0d;
  display: flex;
  align-content: center;
  justify-content: center;
  place-items: center;
}

.item,
.primary-icon,
.alternate-icon {
  animation-duration: var(--total-loader-animation-duration);
  animation-iteration-count: infinite;
}

.icon-wrapper {
  position: relative;
  display: block;
  height: 30px;
  width: 30px;
  margin: 19px 23px 19px 29px;
  max-height: 100%;
  max-width: 100%;
}

.icon {
  position: absolute;
  max-height: 100%;
  max-width: 100%;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  color: white;
  fill: white;
}

.primary-icon {
  animation-name: primary-icon-opacity;
}

.alternate-icon {
  animation-name: alternate-icon-opacity;
  opacity: 0;
}

.top-item {
  border-radius: 0;
}

.top-left-item,
.top-left-item .icon-wrapper .primary-icon,
.top-left-item .icon-wrapper .alternate-icon {
  animation-delay: 0s;
}

.top-left-item {
  animation-name: first-item-animation;
}

.top-right-item,
.top-right-item .icon-wrapper .primary-icon,
.top-right-item .icon-wrapper .alternate-icon {
  animation-delay: var(--item-loader-animation-duration);
}

.top-right-item {
  animation-name: second-item-animation;
}

.bottom-left-item,
.bottom-left-item .icon-wrapper .primary-icon,
.bottom-left-item .icon-wrapper .alternate-icon {
  animation-delay: calc(var(--item-loader-animation-duration) * 3);
}

.bottom-left-item {
  border-radius: 0 0 0 6px;
  animation-name: fourth-item-animation;
}

.bottom-right-item,
.bottom-right-item .icon-wrapper .primary-icon,
.bottom-right-item .icon-wrapper .alternate-icon {
  animation-delay: calc(var(--item-loader-animation-duration) * 2);
}

.bottom-right-item {
  border-radius: 0 0 6px 0;
  animation-name: third-item-animation;
}

@keyframes primary-icon-opacity {
  0% {
    opacity: 1;
  }
  5% {
    opacity: 0;
  }
  25% {
    opacity: 0;
  }
  30% {
    opacity: 1;
  }
}
@keyframes alternate-icon-opacity {
  0% {
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  25% {
    opacity: 1;
  }
  30% {
    opacity: 0;
  }
}

@keyframes first-item-animation {
  0% {
    background-color: #0d0d0d;
  }
  25% {
    background-color: #9941ff;
  }
  50% {
    background-color: #0d0d0d;
  }
}

@keyframes second-item-animation {
  0% {
    background-color: #0d0d0d;
  }
  25% {
    background-color: #d6219c;
  }
  50% {
    background-color: #0d0d0d;
  }
}

@keyframes third-item-animation {
  0% {
    background-color: #0d0d0d;
  }
  25% {
    background-color: #2997f0;
  }
  50% {
    background-color: #0d0d0d;
  }
}

@keyframes fourth-item-animation {
  0% {
    background-color: #0d0d0d;
  }
  25% {
    background-color: #fa7454;
  }
  50% {
    background-color: #0d0d0d;
  }
}
</style>
