var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vwc-list-item",
    {
      attrs: {
        "data-cy": "contact-row",
        twoline: "",
        graphic: "icon",
        shape: "rounded",
        hasMeta: "",
      },
      on: {
        click: function ($event) {
          return _vm.$emit("click")
        },
      },
    },
    [
      _c("Avatar", {
        attrs: {
          slot: "graphic",
          size: "sm",
          displayName: _vm.contact.displayName,
          image: _vm.contact.profilePicture,
        },
        slot: "graphic",
      }),
      _vm._v(" " + _vm._s(_vm.contact.displayName) + " "),
      _c("span", { attrs: { slot: "secondary" }, slot: "secondary" }, [
        _vm._v(
          _vm._s(
            _vm.$t("contact_row.extension", {
              extensionNumber: _vm.contact.extensionNumber,
            })
          )
        ),
      ]),
      _c("vwc-checkbox", {
        attrs: {
          slot: "meta",
          checked: _vm.contact.inviteState === "selected",
        },
        slot: "meta",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }