var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "dialog",
      attrs: {
        "data-cy": "upload-attachments-modal",
        heading: _vm.$tc("upload_attachments_modal.title", _vm.files.length, {
          filesCount: _vm.files.length,
        }),
        "icon-name": "upload-line",
        close: _vm.hide,
        "min-width": _vm.minWidth,
        "primary-action": _vm.$t("upload_attachments_modal.send_button"),
        "secondary-action": _vm.$t("upload_attachments_modal.cancel_button"),
        disablePrimaryButton: _vm.hasError,
        primaryButtonIcon: "message-sent-solid",
      },
      on: {
        applied: _vm.sendAttachments,
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content", attrs: { slot: "content" }, slot: "content" },
        [
          _vm.errorMessage
            ? _c("div", { staticClass: "error-message" }, [
                _vm._v(" " + _vm._s(_vm.errorMessage) + " "),
              ])
            : _vm._e(),
          _c(
            "div",
            { staticClass: "attachment-rows" },
            _vm._l(_vm.attachments, function (attachment, index) {
              return _c(
                "div",
                { key: attachment.name, staticClass: "attachment-wrapper" },
                [
                  _c("AttachmentBox", {
                    attrs: {
                      attachment: attachment,
                      isUnsupported: attachment.isUnsupported,
                    },
                  }),
                  _c("v-icon", {
                    staticClass: "remove-button Vlt-red",
                    attrs: { iconName: "Vlt-icon-bin" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.removeAttachment(index)
                      },
                    },
                  }),
                ],
                1
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }