import {
  SHOW_CAPTIONS_TIMEOUT,
  MAX_SENTENCES_CAPTIONS,
  CAPTION_MESSAGE_TYPE,
  TRANSCRIPT_MESSAGE_STYLE
} from './consts';
import {
  shouldCreateNewCaptionsBlock,
  createCaptionBlock,
  formatTranscriptionTime
} from './captions-utils';
import debounce from 'lodash.debounce';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import i18n from '@/i18n';
import * as roomService from '@/apis/room-service-api';
import * as vbcGw from '@/apis/vbc-gw';
import MeetingManager from '@/services/meeting-manager-service';
import { SIDEBARS } from '@/consts/global-consts';

export default {
  // TODO: try to find a way to merge enableLiveTranscription and toggleCaptions
  enableLiveTranscription: async ({ state, commit, dispatch }) => {
    if (state.isLoadingLiveTranscription) {
      return;
    }
    commit('SET_IS_LOADING_LIVE_TRANSCRIPTION', true);
    const newLiveTranscriptionStatus = !state.isLiveTranscriptionEnabled;
    // After live transcription is enabled, it can't be disabled
    if (newLiveTranscriptionStatus) {
      try {
        // We want to disable or enable session captioning only if Captions is not enabled
        if (!state.isCaptionsEnabled) {
          await dispatch('updateSessionCaptioning', newLiveTranscriptionStatus);
        }
        commit('SET_IS_LIVE_TRANSCRIPTION_ENABLED', newLiveTranscriptionStatus);
        dispatch(
          'addFlashMessage',
          {
            type: 'good',
            text: newLiveTranscriptionStatus
              ? i18n.t(
                  'live_transcription_actions.live_transcription_enabled_flash_message_title'
                )
              : i18n.t(
                  'live_transcription_actions.live_transcription_disabled_flash_message_title'
                )
          },
          { root: true }
        );
      } catch (error) {
        dispatch(
          'addFlashMessage',
          {
            type: 'critical',
            text: newLiveTranscriptionStatus
              ? i18n.t(
                  'live_transcription_actions.enable_live_transcription_failed_flash_message_title'
                )
              : i18n.t(
                  'live_transcription_actions.disable_live_transcription_failed_flash_message_title'
                )
          },
          { root: true }
        );
      } finally {
        commit('SET_IS_LOADING_LIVE_TRANSCRIPTION', false);
      }
    }
    dispatch('openLiveTranscriptionSidebar');
  },

  toggleCaptions: async ({ state, commit, dispatch }) => {
    if (state.isLoadingCaptions) {
      return;
    }
    commit('SET_IS_LOADING_CAPTIONS', true);
    const newCaptionsStatus = !state.isCaptionsEnabled;
    try {
      // We want to disable or enable session captioning only if live transcription is not enabled
      if (!state.isLiveTranscriptionEnabled) {
        await dispatch('updateSessionCaptioning', newCaptionsStatus);
      }
      commit('SET_IS_CAPTIONS_ENABLED', newCaptionsStatus);
      // We want to enable Live Transcription when captions is enabled
      if (!state.isLiveTranscriptionEnabled && newCaptionsStatus) {
        commit('SET_IS_LIVE_TRANSCRIPTION_ENABLED', true);
        dispatch('openLiveTranscriptionSidebar');
      }
      dispatch(
        'addFlashMessage',
        {
          type: 'good',
          text: newCaptionsStatus
            ? i18n.t('captions_actions.captions_enabled_flash_message_title')
            : i18n.t('captions_actions.captions_disabled_flash_message_title')
        },
        { root: true }
      );
    } catch (error) {
      dispatch(
        'addFlashMessage',
        {
          type: 'critical',
          text: newCaptionsStatus
            ? i18n.t(
                'captions_actions.enable_captions_failed_flash_message_title'
              )
            : i18n.t(
                'captions_actions.disable_captions_failed_flash_message_title'
              )
        },
        { root: true }
      );
    } finally {
      commit('SET_IS_LOADING_CAPTIONS', false);
    }
  },

  updateSessionCaptioning: async (
    { getters, dispatch },
    enableSessionCaptioning
  ) => {
    if (enableSessionCaptioning) {
      if (!getters.isSessionCaptionsEnabled) {
        await dispatch('enableSessionCaptions');
      }

      // Can't be rejected because error is handled inside
      await dispatch('createSelfSubscriber');
    }
    // Can't be rejected because error is handled inside
    await Promise.all(
      MeetingManager.getSessionSubscribers().map((subscriber) =>
        dispatch('subscribeToCaptionsForSubscriber', {
          subscriber,
          shouldSubscribe: enableSessionCaptioning
        })
      )
    );
  },

  addParticipantCaptions: (
    { rootState, state, commit, dispatch },
    newCaption
  ) => {
    const participantInProgressCaptionIndex = state.currentCaptionsList.findIndex(
      (caption) => {
        return (
          caption.participantId === newCaption.participantId &&
          !caption.isComplete
        );
      }
    );
    const dateNow = Date.now();
    newCaption.updatedTime = dateNow;
    newCaption.saveToLiveTranscript =
      state.isLiveTranscriptionEnabled && !state.isLiveTranscriptionPaused;
    newCaption.messageType = CAPTION_MESSAGE_TYPE.CAPTION;
    if (participantInProgressCaptionIndex >= 0) {
      commit('UPDATE_PARTICIPANT_CAPTIONS_BY_INDEX', {
        updatedCaption: newCaption,
        index: participantInProgressCaptionIndex
      });
    } else {
      newCaption.creationTime = dateNow;
      newCaption.formattedTime = formatTranscriptionTime(
        newCaption.creationTime
      );
      newCaption.participantDisplayName =
        rootState.participants[newCaption.participantId]?.displayName;
      if (state.currentCaptionsList.length >= MAX_SENTENCES_CAPTIONS) {
        dispatch('moveLastCaptionToHistory');
      }
      commit('ADD_NEW_PARTICIPANT_CAPTIONS', newCaption);
    }
    dispatch('debouncedClearCaptions');
  },

  moveLastCaptionToHistory: ({ state, commit, dispatch }) => {
    if (
      state.currentCaptionsList.length > 0 &&
      state.currentCaptionsList[0].isComplete
    ) {
      dispatch('addCaptionMessageToHistory', state.currentCaptionsList[0]);
      commit('REMOVE_OLDEST_CAPTION');
    }
  },

  clearCaptionsAndMoveToHistory: ({ state, commit, dispatch }) => {
    state.currentCaptionsList.forEach((caption) => {
      // Move to history only completed captions
      if (caption.isComplete) {
        dispatch('addCaptionMessageToHistory', caption);
      }
    });
    commit('CLEAR_CAPTION');
  },

  debouncedClearCaptions: debounce(({ dispatch }) => {
    dispatch('clearCaptionsAndMoveToHistory');
  }, SHOW_CAPTIONS_TIMEOUT),

  setActiveCaptionsId: ({ state, commit, dispatch }, activeCaptionsId) => {
    // Show flash message for the first time enabling captions
    if (!state.activeCaptionsId && activeCaptionsId) {
      dispatch(
        'addFlashMessage',
        {
          type: 'tip',
          text: i18n.t(
            'live_transcription_actions.meeting_is_being_transcribed_flash_message_title'
          )
        },
        { root: true }
      );
    }
    commit('SET_ACTIVE_CAPTIONS_ID', activeCaptionsId);
  },

  subscribeToCaptionsForSubscriber: (_, { subscriber, shouldSubscribe }) => {
    return subscriber.subscribeToCaptions(shouldSubscribe).catch((error) => {
      logger.error('subscribeToCaptions failed', LOG_CATEGORIES.TOKBOX, {
        error,
        streamId: subscriber?.stream?.streamId
      });
    });
  },

  addCaptionsEventListener: async ({ dispatch, rootGetters }, subscriber) => {
    subscriber.on('captionReceived', (event) => {
      const streamParticipant = rootGetters.getParticipantByStreamId(
        event.streamId
      );
      dispatch('addParticipantCaptions', {
        participantId: streamParticipant?.participantId,
        text: event.caption,
        isComplete: event.isFinal
      });
    });
  },

  enableSessionCaptions: async ({ rootState, state, commit }) => {
    if (state.isUpdateIsCaptionsEnabledInProgress) {
      return;
    }
    try {
      commit('UPDATE_IS_UPDATE_IS_CAPTIONS_ENABLED_IN_PROGRESS', true);
      const { activeCaptionsId } = await roomService.updateIsCaptionsEnabled(
        vbcGw.getCredentials().externalId,
        rootState.sessionId,
        true
      );
      commit('SET_ACTIVE_CAPTIONS_ID', activeCaptionsId);
    } catch (error) {
      logger.error(
        'Failed to enable captions in session',
        LOG_CATEGORIES.TOKBOX,
        { error }
      );
      throw error;
    } finally {
      commit('UPDATE_IS_UPDATE_IS_CAPTIONS_ENABLED_IN_PROGRESS', false);
    }
  },

  addCaptionMessageToHistory: ({ state, commit }, caption) => {
    if (!caption.saveToLiveTranscript) {
      return;
    }
    const lastHistoricalCaptionIndex = state.captionsHistory.length - 1;
    const lastHistoricalCaption =
      state.captionsHistory[lastHistoricalCaptionIndex];
    if (shouldCreateNewCaptionsBlock(lastHistoricalCaption, caption)) {
      commit('ADD_CAPTIONS_HISTORY_BLOCK', createCaptionBlock(caption));
    } else {
      commit('UPDATE_LAST_HISTORICAL_CAPTION_BLOCK', caption);
    }
  },

  createSelfSubscriber: async ({ state, commit, dispatch }) => {
    let selfSubscriber;
    if (
      !MeetingManager.publisher ||
      !MeetingManager.publisher.stream ||
      MeetingManager.selfSubscriber ||
      state.isSelfSubscribingInProgress
    ) {
      return null;
    }
    try {
      commit('UPDATE_IS_SELF_SUBSCRIBING_IN_PROGRESS', true);
      selfSubscriber = await MeetingManager.createSelfSubscriber();
      dispatch('addCaptionsEventListener', selfSubscriber);
    } catch (error) {
      logger.error('Failed to create self subscriber', LOG_CATEGORIES.TOKBOX, {
        error,
        streamId: MeetingManager.publisher.stream?.streamId
      });
    } finally {
      commit('UPDATE_IS_SELF_SUBSCRIBING_IN_PROGRESS', false);
    }
    return selfSubscriber;
  },

  openLiveTranscriptionSidebar: ({ dispatch }) => {
    dispatch('layout/toggleSidebar', { shouldCollapse: false }, { root: true });
    return dispatch('setActiveSidebar', SIDEBARS.LIVE_TRANSCRIPTION, {
      root: true
    });
  },

  toggleIsLiveTranscriptionPaused: async ({ state, commit, dispatch }) => {
    const isTranscriptionPaused = !state.isLiveTranscriptionPaused;
    commit('SET_IS_LIVE_TRANSCRIPTION_PAUSED', isTranscriptionPaused);
    const dateNow = Date.now();
    const systemMessage = {
      creationTime: dateNow,
      updatedTime: dateNow,
      saveToLiveTranscript: true,
      messageType: CAPTION_MESSAGE_TYPE.SYSTEM,
      hideHeader: true,
      isComplete: true,
      textStyle: isTranscriptionPaused
        ? TRANSCRIPT_MESSAGE_STYLE.PAUSE_SYSTEM_MESSAGE
        : TRANSCRIPT_MESSAGE_STYLE.RESUME_SYSTEM_MESSAGE,
      text: isTranscriptionPaused
        ? i18n.t(
            'live_transcription_actions.live_transcription_paused_system_message',
            { time: formatTranscriptionTime(dateNow) }
          )
        : i18n.t(
            'live_transcription_actions.live_transcription_resumed_system_message',
            { time: formatTranscriptionTime(dateNow) }
          )
    };
    if (isTranscriptionPaused) {
      // We want to keep the current active captions block to history
      // before we pause live transcript
      dispatch('clearCaptionsAndMoveToHistory');
    }
    dispatch('addCaptionMessageToHistory', systemMessage);
    dispatch(
      'addFlashMessage',
      {
        type: 'good',
        text: isTranscriptionPaused
          ? i18n.t(
              'live_transcription_actions.live_transcription_paused_flash_message_title'
            )
          : i18n.t(
              'live_transcription_actions.live_transcription_resumed_flash_message_title'
            )
      },
      { root: true }
    );
  }
};
