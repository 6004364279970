<template>
  <vwc-dialog
    v-observe-keyboard.l2.enter="hide"
    data-cy="info-modal"
    class="vvd-scheme-alternate dialog"
    :open="infoDialog"
    :heading="infoDialog.title"
    :escapeKeyAction="hide"
    @closed="hide"
    @click.stop
  >
    <vwc-icon
      slot="icon"
      size="large"
      type="warning-line"
      class="dialog-icon"
    ></vwc-icon>

    <div class="content">
      <div class="text">{{ infoDialog.text }}</div>
    </div>

    <vwc-button
      slot="primaryAction"
      data-cy="ok-btn"
      :label="$t('info_modal.ok_button')"
      layout="filled"
      connotation="primary"
      @click="hide"
    />
  </vwc-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'InfoModal',

  computed: {
    ...mapState(['infoDialog'])
  },

  methods: {
    ...mapActions(['setInfoDialog']),

    hide() {
      this.setInfoDialog(null);
    }
  }
};
</script>

<style scoped>
vwc-dialog {
  --mdc-dialog-min-width: 350px;
  --mdc-dialog-max-width: 543px;
  --mdc-dialog-z-index: 100000;
}
.dialog-icon {
  color: var(--vvd-color-on-canvas);
  margin-bottom: 25px;
}
.text {
  white-space: pre-wrap;
}
</style>
