/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-xls': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.3 7.7L14.8.2c-.2-.1-.4-.2-.6-.2h-12c-.4 0-.7.3-.7.8V10H3V1.5h10.5v6.8c0 .4.3.8.8.8H21v13.5H3V21H1.5v2.2c0 .4.3.8.8.8h19.5c.4 0 .8-.3.8-.8v-15c-.1-.1-.2-.3-.3-.5zM15 7.5V2.6l4.9 4.9H15z"/><path pid="1" d="M14.5 17.7c-.8 0-1.4-.5-1.5-1.2l-1 .1c.1 1.3 1.2 2 2.6 2 1.4 0 2.2-.8 2.2-2 0-1.4-1.1-1.6-2.1-1.8-.8-.2-1.5-.3-1.5-1 0-.6.5-.8 1.1-.8.8 0 1.2.4 1.3 1.1l1.1-.2c-.1-1.2-1-1.9-2.4-1.9-1.3.1-2.3.7-2.3 2 0 1.4 1.1 1.6 2.1 1.8.8.2 1.5.3 1.5 1 0 .5-.4.9-1.1.9zM1.7 18.5L3 16.2l1.3 2.3h1.3l-1.9-3.2 1.8-3.1H4.2L3 14.3l-1.2-2.1H.5l1.8 3.1-1.9 3.2zM6.8 12.2v6.3h4.4v-1.1H7.9v-5.2z"/>'
  }
})
