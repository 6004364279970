import MeetingManager from '@/services/meeting-manager-service';
import analytics from '@/services/analytics-service';
import { ANALYTICS, PARTICIPANT_STATE_TYPES } from '@/consts/global-consts';
import { APP_SIGNALS } from '@/consts/global-consts';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import debounce from 'lodash.debounce';
import i18n from '@/i18n';
import { isElectron } from '@/helpers/global-helpers';

export default {
  init: ({ dispatch }) => {
    // Handle incoming raised hand info
    MeetingManager.onSignal(APP_SIGNALS.RAISE_HAND, (event) => {
      dispatch('receiveIsRaisingHandSignal', event);
    });
  },

  sendIsRaisingHandSignal: (
    _,
    { isRaisingHand, participantId, to, isSilentUpdate }
  ) => {
    analytics.trackEvent(ANALYTICS.RAISE_HAND, {
      Status: isRaisingHand ? 'On' : 'Off'
    });

    let raiseHandSignal = {
      type: APP_SIGNALS.RAISE_HAND,
      data: {
        isRaisingHand,
        participantId,
        isSilentUpdate
      }
    };

    if (to !== undefined) {
      raiseHandSignal.to = to;
    }

    return MeetingManager.sendSignal(raiseHandSignal);
  },

  receiveIsRaisingHandSignal: ({ state, dispatch, rootState }, event) => {
    const owner = rootState.participants[rootState.owner];
    const participantId = event.data.participantId;
    const currentParticipant = rootState.participants[participantId];
    const senderConnectionId = event.from.id;

    const isSignalVerified = [
      owner?.connectionId,
      currentParticipant?.connectionId
    ].includes(senderConnectionId);
    if (!isSignalVerified) {
      logger.warning(
        'Raise hand - Cannot process signal',
        LOG_CATEGORIES.CLIENT_LOGIC,
        {
          message: `Sender with connection id ${senderConnectionId} is not allowed to send signals`
        }
      );
      return;
    }

    if (event.data.isRaisingHand) {
      dispatch('addRaisedHand', participantId);
      const numberOfRaisedHandParticipants = Object.keys(state.raisedHands)
        .length;
      if (
        !event.data.isSilentUpdate &&
        participantId !== rootState.myParticipantId
      ) {
        let text = rootState.participants[participantId].displayName;
        if (numberOfRaisedHandParticipants > 1) {
          text += ' + ' + (numberOfRaisedHandParticipants - 1);
        }
        dispatch('showRaiseHandMessage', text);
      }
      // First participant raised hand
      if (numberOfRaisedHandParticipants === 1) {
        dispatch('notifyRaiseHandStatusUpdate', true);
      }
    } else {
      dispatch('removeRaisedHand', participantId);
      const numberOfRaisedHandParticipants = Object.keys(state.raisedHands)
        .length;
      // None of the participants are raising hands
      if (numberOfRaisedHandParticipants === 0) {
        dispatch('notifyRaiseHandStatusUpdate', false);
      }
    }
  },

  notifyRaiseHandStatusUpdate: (_, isAnyParticipantRaisingHand) => {
    if (isElectron() && window.Electron.notifyRaiseHandStatusUpdate) {
      window.Electron.notifyRaiseHandStatusUpdate(isAnyParticipantRaisingHand);
    }
  },

  showRaiseHandMessage: debounce(({ dispatch }, text) => {
    dispatch(
      'addFlashMessage',
      {
        type: 'blank',
        time: 5000,
        title: i18n.t('raise_hand_actions.raise_hand_flash_message_title'),
        text,
        customImg: '/raise_hand_img.svg'
      },
      { root: true }
    );
  }, 500),

  setIsRaisingHand: ({ dispatch }, { isRaisingHand, participantId }) => {
    dispatch('sendIsRaisingHandSignal', {
      isRaisingHand,
      participantId
    });
  },

  toggleMyHand: ({ dispatch, getters, rootState }) => {
    dispatch('sendIsRaisingHandSignal', {
      isRaisingHand: !getters.isMyParticipantRaisingHand,
      participantId: rootState.myParticipantId
    });
  },

  addRaisedHand: ({ state, commit }, participantId) => {
    if (!(participantId in state.raisedHands)) {
      commit('ADD_RAISED_HAND', participantId);
    }
  },

  removeRaisedHand: ({ state, commit }, participantId) => {
    if (participantId in state.raisedHands) {
      commit('REMOVE_RAISED_HAND', participantId);
    }
  },

  handleParticipantStateUpdate: ({ dispatch }, event) => {
    if (event.state === PARTICIPANT_STATE_TYPES.JOINED) {
      dispatch('_handleParticipantJoined', {
        joinedParticipantConnectionId: event.connectionId,
        joinedParticipantId: event.participantId
      });
    } else if (
      [PARTICIPANT_STATE_TYPES.LEFT, PARTICIPANT_STATE_TYPES.KICKED].includes(
        event.state
      )
    ) {
      dispatch('_handleParticipantLeft', event.participantId);
    }
  },

  _handleParticipantJoined: (
    { state, rootState, dispatch },
    { joinedParticipantConnectionId, joinedParticipantId }
  ) => {
    const myParticipantId = rootState.myParticipantId;
    if (
      !(myParticipantId in state.raisedHands) ||
      joinedParticipantId === myParticipantId
    ) {
      return;
    }

    const stream = rootState.streams?.find(
      (stream) => stream?.connectionId === joinedParticipantConnectionId
    );
    const connection = stream?.connection;

    dispatch('sendIsRaisingHandSignal', {
      isRaisingHand: true,
      participantId: myParticipantId,
      to: connection,
      isSilentUpdate: true
    });
  },

  _handleParticipantLeft: ({ state, dispatch }, leftParticipantId) => {
    if (!(leftParticipantId in state.raisedHands)) {
      return;
    }

    dispatch('sendIsRaisingHandSignal', {
      isRaisingHand: false,
      participantId: leftParticipantId
    });
  }
};
