/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-crop-fill': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.9 19.2h14.3V4.9H4.9v14.3zM6.2 8.9l2.7-2.7h1.5l-4.2 4.2V8.9zm0 3.4l6.1-6.1h1.5l-7.6 7.6v-1.5zm11.7 3.2l-2.4 2.4H14l3.9-3.9v1.5zm0-3.4l-5.7 5.7h-1.5l7.2-7.2v1.5zm0-3.3l-9.1 9.1H7.3L17.9 7.3v1.5zM6.2 17.1v-1.5l9.4-9.4h1.5L6.2 17.1zm11.1.8l.5-.5v.5h-.5zM7 6.2l-.8.9v-.9H7zM17.9 1h1.3v3h-1.3V1zM20 4.9h3v1.3h-3V4.9zM4.9 20h1.3v3H4.9v-3zM1 17.9h3v1.3H1v-1.3zm19 0h3v1.3h-3v-1.3zM17.9 20h1.3v3h-1.3v-3zM1 4.9h3v1.3H1V4.9zM4.9 1h1.3v3H4.9V1z"/>'
  }
})
