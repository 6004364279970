/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-eye-hide': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.2 11.7c-.1.2-.1.4 0 .6 0 0 1 1.7 3 4.2l1.1-1.1C3 13.8 2.3 13 1.7 12c0 0 .9-1.6 2.9-3.6S9 4.5 12 4.5c.9 0 1.8.2 2.7.5l1.2-1.2C14.7 3.3 13.4 3 12 3 8.3 3 3.7 5.3.2 11.7zM23.7 11.2c-.2-.3-1-1.4-1.2-1.7 0 0-1.1-1.4-1.8-2.2l-1.5-1.5L23 2c.3-.3.3-.8 0-1.1-.3-.3-.8-.3-1.1 0L1 22c-.3.3-.3.8 0 1.1.3.3.8.3 1.1 0l4-4c1.7 1.1 3.7 2 6 2 3.8 0 6.8-2.2 8.7-4.3 1-1.1 1.8-2.1 2.4-3 .6-1.1.8-1.4.8-1.4 0-.3-.1-.8-.3-1.2zm-9.1-.7c.3.4.4 1 .4 1.5 0 1.7-1.3 3-3 3-.6 0-1.1-.2-1.5-.4l4.1-4.1zm7.8 1.5c-.1.2-.5.7-.5.7s-.6 1-2.4 2.9-4.5 3.9-7.5 3.9c-1.7 0-3.4-.7-4.8-1.6l2.2-2.2c.7.5 1.6.8 2.6.8 2.5 0 4.5-2 4.5-4.5 0-1-.3-1.9-.8-2.6L18.1 7c.6.5 1 .8 1.6 1.4 1.5 1.6 2.7 3.6 2.7 3.6z"/><path pid="1" d="M7.5 12v.1l4.6-4.6H12c-2.5 0-4.5 2-4.5 4.5z"/>'
  }
})
