/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-telephone': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 17',
    data: '<path pid="0" d="M0 4c0-.8.7-1.5 1.5-1.5H2v1h-.5c-.3 0-.5.2-.5.5v11c0 .3.2.5.5.5h13c.3 0 .5-.2.5-.5V4c0-.3-.2-.5-.5-.5H7v-1h7.5c.8 0 1.5.7 1.5 1.5v11c0 .8-.7 1.5-1.5 1.5h-13C.7 16.5 0 15.8 0 15V4z"/><path pid="1" d="M2 2C2 1.2 2.7.5 3.5.5h1C5.3.5 6 1.2 6 2v11c0 .8-.7 1.5-1.5 1.5h-1c-.8 0-1.5-.7-1.5-1.5V2zm1.5-.5c-.3 0-.5.2-.5.5v11c0 .3.2.5.5.5h1c.3 0 .5-.2.5-.5V2c0-.3-.2-.5-.5-.5h-1zM7.2 9H9v1H7.2V9zM7.2 11.1H9v1H7.2v-1zM7.2 13.2H9v1H7.2v-1zM9.7 9h1.8v1H9.7V9zM9.7 11.1h1.8v1H9.7v-1zM9.7 13.2h1.8v1H9.7v-1zM12.2 9H14v1h-1.8V9zM12.2 11.1H14v1h-1.8v-1zM12.2 13.2H14v1h-1.8v-1zM13 4.5H8.2c-.6 0-1 .4-1 1V7c0 .6.4 1 1 1H13c.6 0 1-.4 1-1V5.5c0-.6-.4-1-1-1zM13 7H8.2V5.5H13V7z"/>'
  }
})
