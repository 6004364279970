/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-minimize': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 9.5A.5.5 0 01.5 9h6a.5.5 0 01.5.5v6a.5.5 0 01-1 0V10H.5a.5.5 0 01-.5-.5zM9.5 0a.5.5 0 01.5.5V6h5.5a.5.5 0 010 1h-6a.5.5 0 01-.5-.5v-6a.5.5 0 01.5-.5z"/><path pid="1" d="M6.854 9.146a.5.5 0 010 .708l-5 5a.5.5 0 01-.708-.708l5-5a.5.5 0 01.708 0zM14.854 1.146a.5.5 0 010 .708l-5 5a.5.5 0 11-.708-.708l5-5a.5.5 0 01.708 0zM9 9.5a.5.5 0 01.5-.5h6a.5.5 0 010 1H10v5.5a.5.5 0 01-1 0v-6z"/><path pid="2" d="M9.146 9.146a.5.5 0 01.708 0l5 5a.5.5 0 01-.708.708l-5-5a.5.5 0 010-.708zM6.5 0a.5.5 0 01.5.5v6a.5.5 0 01-.5.5h-6a.5.5 0 010-1H6V.5a.5.5 0 01.5-.5z"/><path pid="3" d="M1.146 1.146a.5.5 0 01.708 0l5 5a.5.5 0 11-.708.708l-5-5a.5.5 0 010-.708z"/>'
  }
})
