export default {
  SET_TOAST: (state, toast) => {
    state.toast = toast;
  },

  SET_MESSAGE_BANNER: (state, messageBanner) => {
    state.messageBanner = messageBanner;
  },

  SET_IS_MOBILE_CHAT_OPEN: (state, isChatOpen) => {
    state.isMobileChatOpen = isChatOpen;
  }
};
