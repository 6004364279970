/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-gear-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 0h18a1 1 0 011 1v22a1 1 0 01-1 1H3a1 1 0 01-1-1V1a1 1 0 011-1zm10.125 7.642V5.25h-2.25v2.392c-.413.106-.803.27-1.16.481L8.021 6.431 6.431 8.02l1.692 1.693a4.474 4.474 0 00-.481 1.161H5.25v2.25h2.392c.106.413.27.803.481 1.161l-1.692 1.692 1.591 1.591 1.692-1.692c.358.212.748.375 1.161.481v2.392h2.25v-2.392a4.47 4.47 0 001.16-.481l1.693 1.692 1.59-1.59-1.691-1.693c.212-.358.375-.748.481-1.161h2.392v-2.25h-2.392a4.471 4.471 0 00-.481-1.161l1.692-1.692-1.591-1.591-1.692 1.692a4.474 4.474 0 00-1.161-.481zm-2.686 2.737l-.06.06a2.242 2.242 0 00-.629 1.55v.021c.003.6.24 1.145.626 1.547l.067.067c.404.388.953.626 1.557.626.603 0 1.15-.237 1.555-.623l.072-.072c.382-.4.618-.941.623-1.537v-.036a2.242 2.242 0 00-.627-1.54l-.065-.065A2.242 2.242 0 0012 9.75c-.606 0-1.156.24-1.56.63z"/>'
  }
})
