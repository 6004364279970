<template>
  <vwc-dialog
    v-observe-keyboard.l2.enter="close"
    close-button="true"
    :heading="$t('disable_waiting_room_modal.heading')"
    connotation="cta"
    class="vvd-scheme-alternate"
    :open="true"
  >
    <div class="dialog-message">
      {{ $t('disable_waiting_room_modal.dialog_message') }}
    </div>

    <vwc-button
      slot="primaryAction"
      dialogaction="close"
      type="submit"
      data-cy="got-it-btn"
      :label="$t('disable_waiting_room_modal.got_it_button')"
      layout="filled"
      @click="close"
    />
  </vwc-dialog>
</template>

<script>
import analytics from '@/services/analytics-service';
import { mapActions } from 'vuex';
import { ANALYTICS } from '@/consts/global-consts';

export default {
  name: 'DisableWaitingRoomModal',

  methods: {
    ...mapActions('waitingRoom', ['setIsDisableWaitingRoomModalVisible']),

    mounted() {
      analytics.trackEvent(ANALYTICS.WAITING_ROOM_DISABLE_NOTIFICATION);
    },

    close() {
      this.setIsDisableWaitingRoomModalVisible(false);
    }
  }
};
</script>

<style scoped>
.dialog-message {
  width: 370px;
}
</style>
