/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-type-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.5 6c0-1.654 1.345-3 3-3H18V0h-1.5c-1.8 0-3.4.813-4.5 2.073C10.899.813 9.3 0 7.5 0H6v3h1.5c1.654 0 3 1.346 3 3v7.5H6v3h4.5V18c0 1.654-1.346 3-3 3H6v3h1.5c1.8 0 3.399-.813 4.5-2.073C13.1 23.187 14.7 24 16.5 24H18v-3h-1.5c-1.655 0-3-1.346-3-3v-1.5H18v-3h-4.5V6z"/>'
  }
})
