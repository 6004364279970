<template>
  <Dialog
    data-cy="mute-all-participants-modal"
    class="dialog"
    :heading="$t('mute_all_participants_modal.title')"
    icon-name="mic-mute-line"
    :close="hide"
    :min-width="410"
    :primary-action="$t('mute_all_participants_modal.ok_button_text')"
    :open="isMuteAllParticipantsModalVisible"
    isSmallModal
    @applied="forceMuteSessionAndHideModal"
    @click.stop
  >
    <div slot="content" class="content">
      <div class="text">
        {{ $t('mute_all_participants_modal.text') }}
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Dialog from '@/components/Dialog';

export default {
  name: 'MuteAllParticipantsModal',

  components: { Dialog },

  computed: {
    ...mapState(['isMuteAllParticipantsModalVisible'])
  },

  methods: {
    ...mapActions([
      'setIsMuteAllParticipantsDialogVisible',
      'forceMuteSession'
    ]),

    hide() {
      this.setIsMuteAllParticipantsDialogVisible(false);
    },

    forceMuteSessionAndHideModal() {
      this.forceMuteSession();
      this.hide();
    }
  }
};
</script>

<style scoped></style>
