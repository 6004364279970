<template>
  <div
    v-if="messageBanner"
    data-cy="message-banner-container"
    class="banner-wrapper"
  >
    <ParticipantAvatar
      negative
      avatarSize="sm"
      data-cy="message-banner-avatar"
      class="avatar"
      :participantType="sender.type"
      :participantProfilePicture="sender.profilePicture"
      :displayName="displayName"
    />
    <div class="content-wrapper">
      <div class="content-header">
        <div
          data-cy="message-banner-name"
          class="display-name"
          :style="{ color: color }"
        >
          {{ displayName }}
        </div>
        <div v-if="showTime" data-cy="message-time" class="message-time">
          {{ formattedTime }}
        </div>
      </div>
      <div data-cy="message-banner-content" class="content-body">
        <div v-if="isTextMessage">
          <v-icon
            v-if="isLink"
            class="attachment-icon"
            iconName="Vlt-icon-link-full"
          />
          <TextMessageRow :message="message" />
        </div>
        <div v-else-if="isImage">
          <v-icon
            data-cy="attachment-icon-image"
            class="attachment-icon"
            iconName="Vlt-icon-camera-full"
          />
          <span class="attachment">{{
            $t('mobile_message_banner.image_text')
          }}</span>
        </div>
        <div v-else-if="isVideo">
          <v-icon
            data-cy="attachment-icon-video"
            class="attachment-icon"
            iconName="Vlt-icon-file-video-full"
          />
          <span class="attachment">{{
            $t('mobile_message_banner.video_text')
          }}</span>
        </div>
        <div v-else>
          <v-icon
            data-cy="attachment-icon-file"
            class="attachment-icon"
            iconName="Vlt-icon-file-2-full"
          />
          <span class="attachment">{{
            $t('mobile_message_banner.attachment_text')
          }}</span>
        </div>
      </div>
    </div>
    <div
      data-cy="message-banner-close-btn"
      class="close-icon-container"
      @click.stop="clearMessageBanner"
    >
      <v-icon class="close-icon" iconName="Vlt-icon-cross-full" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { getColor, formatTime } from '@/helpers/global-helpers';
import { MESSAGE_TYPE, DARK_MODE_COLORS } from '@/consts/messaging';
import TextMessageRow from '@/components/rows/message-rows/TextMessageRow';
import Autolinker from 'autolinker';

export default {
  name: 'MobileMessageBanner',

  components: { TextMessageRow },

  props: {
    showTime: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['participants']),
    ...mapState('mobile', ['messageBanner']),

    formattedTime() {
      return formatTime(this.message.creationTime);
    },

    color() {
      return getColor(this.message.fromDisplayName, DARK_MODE_COLORS);
    },

    displayName() {
      return this.message.fromDisplayName;
    },

    isTextMessage() {
      return this.message.type === MESSAGE_TYPE.TEXT;
    },

    isImage() {
      return this.message.type === MESSAGE_TYPE.IMAGE;
    },

    isVideo() {
      return this.message.type === MESSAGE_TYPE.VIDEO;
    },

    isLink() {
      const urls = Autolinker.parse(this.message.body, { url: true });
      return !!urls[0]?.getUrl();
    },

    sender() {
      return this.message.participantId
        ? this.participants[this.message.participantId]
        : null;
    },

    message() {
      return this.messageBanner.message;
    }
  },

  watch: {
    messageBanner() {
      if (this.removeBannerTimeout) {
        clearTimeout(this.removeBannerTimeout);
      }

      if (this.messageBanner) {
        this.removeBannerTimeout = setTimeout(
          this.clearMessageBanner,
          this.messageBanner.time
        );
      }
    }
  },

  methods: {
    ...mapActions('mobile', ['clearMessageBanner'])
  }
};
</script>

<style scoped>
.banner-wrapper {
  width: 300px;
  height: 65px;
  background: #1a1a1a;
  padding: 12.5px 8px 12.5px 8px;
  border: 1px solid #757575;
  border-radius: 6px;
  box-shadow: 0px 4px 2px rgba(19, 20, 21, 0.05),
    0px 2px 4px rgba(19, 20, 21, 0.05), 0px 2px 8px rgba(19, 20, 21, 0.1);
}

.avatar {
  display: inline-block;
  vertical-align: top;
}

.content-wrapper {
  display: inline-block;
  margin-left: 8px;
  width: calc(100% - 44px);
}

.content-header {
  display: flex;
  align-items: center;
  font-weight: 600;
}

.display-name {
  font-size: 16px;
  font-weight: bold;
}

.message-time {
  margin-left: 4px;
  font-size: 13px;
  font-weight: 400;
  color: #919eab;
  margin-left: auto;
  right: 0px;
}

.content-body {
  display: flex;
  color: #2c2d30;
}

.content-body >>> .text-wrapper {
  display: inline-block;
  width: 200px;
}

.content-body >>> .linkify {
  max-width: 100%;
}

.content-body >>> .linkify-body,
.attachment {
  font-size: 16px;
  color: white;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.attachment {
  color: #b3b3b3;
}

.content-body >>> a {
  color: var(--link-color-black-bg);
}

.attachment-icon {
  display: inline-block;
  height: 12px;
  width: 12px;
  margin-right: 8px;
  fill: #b3b3b3;
}

.close-icon-container {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 24px;
  width: 24px;
  padding: 10px 14px 0px 0px;
}

.close-icon {
  fill: white;
  cursor: unset;
  height: 12px;
  width: 12px;
}
</style>
