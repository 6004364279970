var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "thank-you-screen",
      class: {
        mobile: _vm.isMobileWebMode,
        branded: _vm.isBranded,
        embedded: _vm.isEmbedded,
      },
    },
    [
      _vm.isLocaleSwitcherEnabled
        ? _c("LocaleSwitcher", { staticClass: "locale-switcher" })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "content Vlt-white",
          attrs: { "data-cy": "voange-logo" },
        },
        [
          _vm.isMobileWebMode
            ? _c("img", {
                staticClass: "image-top",
                attrs: {
                  src: _vm.isBranded || _vm.isEmbedded ? "" : "/img-clouds.svg",
                },
              })
            : _vm._e(),
          _vm.showThankYouContent
            ? _c(
                "div",
                { staticClass: "logo-container" },
                [
                  _vm.isBranded && _vm.brandImageWhite
                    ? _c("img", {
                        staticClass: "brand-logo",
                        attrs: { src: _vm.brandImageWhite },
                      })
                    : _vm.isBranded
                    ? _c("p", { staticClass: "brand-text" }, [
                        _vm._v(_vm._s(_vm.brandText)),
                      ])
                    : _c("v-icon", {
                        staticClass: "vonage-logo",
                        attrs: { iconName: "Brand-icon-vonage" },
                      }),
                ],
                1
              )
            : _vm._e(),
          _vm.showThankYouContent
            ? _c(
                "h2",
                {
                  staticClass: "title Vlt-white",
                  attrs: { "data-cy": "thank-you-screen-title" },
                },
                [_vm._v(" " + _vm._s(_vm.thankYouTitle) + " ")]
              )
            : _vm._e(),
          (_vm.isBranded || _vm.isEmbedded) && !_vm.isMobileWebMode
            ? _c(
                "p",
                {
                  staticClass: "message Vlt-white",
                  attrs: { "data-cy": "thank-you-screen-content" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("thank_you_screen.content1")) + " "
                  ),
                ]
              )
            : !_vm.isMobileWebMode
            ? _c(
                "p",
                {
                  staticClass: "message Vlt-white",
                  attrs: { "data-cy": "thank-you-screen-content" },
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t("thank_you_screen.content2")) + " "
                  ),
                  _c(
                    "a",
                    {
                      staticClass: "message Vlt-white",
                      attrs: { href: "mailto:vbcfeedback@vonage.com" },
                    },
                    [_vm._v(" vbcfeedback@vonage.com ")]
                  ),
                ]
              )
            : _vm._e(),
          _vm.isMobileWebMode && _vm.showThankYouContent && !_vm.isEmbedded
            ? _c("div", { staticClass: "separator" })
            : _vm._e(),
        ]
      ),
      !_vm.isEmbedded
        ? _c("RateThisMeeting", {
            staticClass: "rate-this-meeting",
            on: {
              rateMeetingClicked: function ($event) {
                _vm.rateMeetingClicked = true
              },
              finishRateMeeting: function ($event) {
                _vm.finishRateMeeting = true
              },
            },
          })
        : _vm._e(),
      _vm.showJoinNewMeeting
        ? _c("vwc-button", {
            staticClass: "join-new-meeting-button",
            attrs: {
              "data-cy": "join-new-meeting-button",
              label: _vm.$t("thank_you_screen.join_new_meeting_button"),
              connotation: _vm.finishRateMeeting ? "primary" : "cta",
              layout: _vm.finishRateMeeting ? "filled" : "text",
            },
            on: { click: _vm.onJoinNewMeetingClicked },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }