var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vwc-select",
    {
      style: _vm.selectStyle,
      attrs: {
        label: _vm.label,
        shape: _vm.shape,
        dense: _vm.dense,
        required: _vm.required,
        value: _vm.selectedValue,
        disabled: _vm.disabled,
      },
      on: {
        closed: function (event) {
          return event.stopPropagation()
        },
        selected: function ($event) {
          $event.preventDefault()
        },
      },
    },
    _vm._l(_vm.options, function (option) {
      return _c(
        "vwc-list-item",
        {
          key: option.id,
          attrs: {
            value: option.label,
            disabled: option.disabled,
            hasMeta: "",
            graphic: option.icon ? "icon" : "",
            role: "option",
          },
          on: {
            click: function ($event) {
              return _vm.selectedOption(option)
            },
          },
        },
        [
          _c("span", [_vm._v(_vm._s(option.label) + " ")]),
          option.icon
            ? _c("vwc-icon", {
                attrs: { slot: "graphic", type: option.icon, size: "small" },
                slot: "graphic",
              })
            : _vm._e(),
          option.label === _vm.selectedValue
            ? _c("vwc-icon", {
                staticClass: "meta-icon",
                attrs: { slot: "meta", type: "check-line", size: "small" },
                slot: "meta",
              })
            : _vm._e(),
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }