/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-virtual-voicemail-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 23 24',
    data: '<path pid="0" d="M9 11.25a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0zM18 11.25a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"/><path pid="1" d="M1.5 0A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24H21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0021 0H1.5zm9 11.25c0 .844-.279 1.623-.75 2.25h3a3.75 3.75 0 113 1.5h-9a3.75 3.75 0 113.75-3.75z"/>'
  }
})
