var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "screen-wrapper Vlt-white mobile speaker-view",
      class: Object.assign({}, _vm.screenWrapperOrientationClasses, {
        "card-partially-extended": _vm.isCardPartiallyExtended,
      }),
      on: { click: _vm.handleScreenTap },
    },
    [
      _c(
        "div",
        { staticClass: "toast-container" },
        [_c("transition", { attrs: { name: "fade" } }, [_c("MobileToast")], 1)],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide" } },
        [
          _vm.isMobileChatOpen
            ? _c("Chat", { staticClass: "slide-in-chat" })
            : _vm._e(),
        ],
        1
      ),
      !_vm.hasSessionEverConnected
        ? _c(
            "div",
            { staticClass: "loader-wrapper unselectable" },
            [
              _c("vwc-circular-progress", {
                attrs: { density: "8", indeterminate: "" },
              }),
              _c("span", { staticClass: "loading-text" }, [
                _vm._v(_vm._s(_vm.$t("mobile_app_screen.loading_text"))),
              ]),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "videos-wrapper" },
            [
              _c(
                "div",
                [
                  _c(
                    "div",
                    { staticClass: "toast-container" },
                    [
                      _c(
                        "transition",
                        { attrs: { name: "fade" } },
                        [_c("MobileToast")],
                        1
                      ),
                    ],
                    1
                  ),
                  !_vm.isOnline ? _c("NoConnectionBanner") : _vm._e(),
                  _vm.isEndMeetingDialogVisible
                    ? _c("EndMeetingModal", {
                        attrs: {
                          shouldCloseWindow: false,
                          showCancelButton: "",
                        },
                      })
                    : _vm._e(),
                  _vm.confirmationDialog
                    ? _c("ConfirmationDialogModal")
                    : _vm._e(),
                  _vm.errorDialog
                    ? _c("ErrorModal", { attrs: { showCancelButton: "" } })
                    : _vm._e(),
                  _vm.infoDialog ? _c("InfoModal") : _vm._e(),
                ],
                1
              ),
              _vm.isNetworkIssues
                ? _c("ReconnectingBanner", {
                    staticClass: "network-issues-banner-container",
                    class: { top: _vm.isOnline },
                  })
                : _vm._e(),
              _vm.isRecording
                ? _c("MobileRecordingIndication", {
                    staticClass: "recording-indication",
                  })
                : _vm._e(),
              _c("ParticipantsTileList", {
                attrs: {
                  canFullyStretchMainStream: "",
                  preferredFitMode: "cover",
                  smallStreamAspectRatio: 1,
                  smallStreamsDock: _vm.smallStreamsDock,
                  updateStreamSizesWrapper: _vm.updateStreamSizesWrapper,
                },
                on: { click: _vm.handleScreenTap },
              }),
              _c("MobileCard", {
                ref: "card",
                staticClass: "mobile-card unselectable",
                attrs: {
                  collapsed: !_vm.showActionsCard,
                  extendable:
                    !_vm.isEmbedded &&
                    _vm.orientation === _vm.MOBILE_SCREEN_ORIENTATION.PORTRAIT,
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.clearHideActionsCardTimer($event)
                  },
                  "visibility-state-changed": _vm.updateCardVisibility,
                },
              }),
            ],
            1
          ),
      _c(
        "div",
        {
          staticClass: "last-message-banner",
          on: { click: _vm.bannerClicked },
        },
        [
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [_c("MobileMessageBanner")],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }