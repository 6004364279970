/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-refresh-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M17.422 5.453A8.526 8.526 0 0012 3.498c-4.148 0-7.656 2.928-8.34 6.961L.323 9.906C1.283 4.256 6.193.156 12 .156c2.893 0 5.672 1.056 7.823 2.924l3.265-3.226L24 10.235l-10.506-.9 3.928-3.882zM6.579 18.256A8.526 8.526 0 0012 20.21c4.148 0 7.656-2.928 8.34-6.961l3.337.553c-.96 5.65-5.87 9.75-11.677 9.75a11.948 11.948 0 01-7.823-2.924L.912 23.855 0 13.474l10.506.9-3.927 3.882z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
