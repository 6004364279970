export function applyTextEllipsis(text, maxLength) {
  if (text.length <= maxLength) {
    return text;
  }

  return text.substring(0, Math.max(1, maxLength - 3)).trim() + '...';
}

export function extractFirstName(name) {
  return name.split(' ')[0];
}
