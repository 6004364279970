/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-share-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 15c-1.35 0-2.55.6-3.3 1.5l-4.5-2.85c.15-.45.3-1.05.3-1.65 0-.6-.15-1.2-.3-1.65l4.5-2.85c.75.9 2.1 1.5 3.3 1.5 2.55 0 4.5-1.95 4.5-4.5S20.55 0 18 0s-4.5 1.95-4.5 4.5c0 .6.15 1.2.3 1.65L9.3 9C8.55 8.1 7.35 7.5 6 7.5c-2.55 0-4.5 1.95-4.5 4.5s1.95 4.5 4.5 4.5c1.35 0 2.55-.6 3.3-1.5l4.5 2.85c-.15.45-.3 1.05-.3 1.65 0 2.55 1.95 4.5 4.5 4.5s4.5-1.95 4.5-4.5S20.55 15 18 15zm0-13.5c1.65 0 3 1.35 3 3s-1.35 3-3 3c-1.05 0-1.95-.6-2.55-1.35-.3-.6-.45-1.05-.45-1.65 0-1.65 1.35-3 3-3zm-9.45 12c0 .15 0 .15 0 0 0 .15 0 .15 0 0-.6.9-1.5 1.5-2.55 1.5-1.65 0-3-1.35-3-3s1.35-3 3-3c1.05 0 1.95.6 2.55 1.35.3.6.45 1.05.45 1.65 0 .6-.15 1.05-.45 1.5zm9.45 9c-1.65 0-3-1.35-3-3 0-.6.15-1.05.45-1.5.6-.75 1.5-1.35 2.55-1.35 1.65 0 3 1.35 3 3s-1.35 2.85-3 2.85z"/>'
  }
})
