/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-jobdiva-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M11.403 21.756c.45-1.35.85-2.601 1.3-3.951 1.901-.15 4.152.15 5.752-1.75 1.2-1.401 1.5-3.102 1.45-4.852-.05-4.051-1.8-5.602-7.352-6.502V1c3.451.05 6.702.35 9.053 3.3 2.8 3.552 3.2 9.203.9 13.054-1.35 2.251-3.25 3.751-6.002 4.002-1.75.2-3.45.3-5.1.4z" _fill="#42BAEB"/><path pid="1" d="M0 19.305c1-.95 2-1.85 3-2.8.601.5 1.051 1 1.651 1.35 1.4.9 2.45.35 2.5-1.3.05-4.752.05-9.503.05-14.254V1.1h3.952c.05.1.1.25.1.35 0 5.25 0 10.452-.1 15.704-.05 2.5-1.2 4.15-3.2 4.7-2.452.701-4.652.151-6.603-1.45-.4-.35-.85-.65-1.35-1.1z" _fill="#FAA91F"/></g><defs><clipPath id="clip0"><path pid="2" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
