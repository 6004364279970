/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-books-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 1.65l3.75 11.4-8.4 2.85-3.9-11.4c-.432-1.296.519-1.762.86-1.93a5.36 5.36 0 00.04-.02l5.7-1.8c.9-.3 1.65.15 1.95.9zm-.45 8.1l-1.95-5.7-2.85.9 1.95 5.7 2.85-.9zM9 3v12H0V3c0-.9.6-1.5 1.5-1.5h6C8.4 1.5 9 2.1 9 3zM6 4.5H3v6h3v-6zM0 21v-3h9v3c0 .9-.6 1.5-1.5 1.5h-6C.6 22.5 0 21.9 0 21zm22.8-5.1l-8.55 2.85.9 2.85c.15.6.75 1.2 1.95.9l5.7-1.95c.75-.3 1.2-1.05.9-1.95l-.9-2.7z"/>'
  }
})
