var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "control-bar-button",
      class: {
        transparent: _vm.transparent,
        disabled: _vm.disabled,
        destructive: _vm.destructive,
      },
      on: { click: _vm.onClick },
    },
    [
      _c("v-icon", {
        staticClass: "button-icon",
        attrs: { iconName: _vm.iconName },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }