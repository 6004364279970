<template>
  <transition name="slide">
    <div
      class="Vlt-callout flash-message"
      :class="[calloutClass, { 'off-screen': offScreen }]"
    >
      <div v-if="options.customIcon" class="Vlt-callout__icon">
        <v-icon :iconName="options.customIcon" />
      </div>
      <div v-else-if="options.customImg" class="custom-image">
        <img
          :src="options.customImg"
          :class="{ 'center-elements': areElementsCentered }"
        />
      </div>
      <i v-else />
      <div class="Vlt-callout__content">
        <h4 v-if="hasTitle">
          {{ options.title }}
        </h4>
        <p class="selectable" :class="{ 'flash-message-bold-text': !hasTitle }">
          <slot name="text" />
        </p>
        <button
          v-if="options.button && options.button.actions"
          class="Vlt-callout__cta"
          @click="dispatchButtonAction"
        >
          {{ buttonText }}
        </button>
      </div>
      <button
        v-if="options.dismiss"
        class="Vlt-callout__dismiss"
        :class="{ 'center-elements': areElementsCentered, blank: hasBlankType }"
        @click="removeSelf"
      ></button>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
export default {
  name: 'VFlashMessage',
  props: {
    options: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      offScreen: true,
      timeout: null
    };
  },
  computed: {
    calloutClass() {
      return `Vlt-callout--${this.options.type}`;
    },
    buttonText() {
      return (
        this.options?.button?.text ||
        this.$t('v_flash_message.default_button_text')
      );
    },
    hasTitle() {
      return !!this.options?.title;
    },
    // Elements of new messages with custom image should be centered
    areElementsCentered() {
      return this.hasTitle && !!this.options?.text && this.options.customImg;
    },
    hasBlankType() {
      return this.options.type === 'blank';
    }
  },
  mounted() {
    setTimeout(() => (this.offScreen = false), 10);
    this.timeout = setTimeout(() => this.removeSelf(), this.options.time);
  },
  methods: {
    ...mapActions(['removeFlashMessage']),
    removeSelf() {
      clearTimeout(this.timeout);
      this.$nextTick(() => this.removeFlashMessage(this.options.id));
    },
    dispatchButtonAction() {
      if (this.options?.button?.actions?.name === 'removeSelf') {
        return this.removeSelf();
      }
      if (this.options?.button?.actions) {
        this.options?.button?.actions.forEach((action) => {
          this.$store.dispatch(action.name, action.payload);
        });
      }
    }
  }
};
</script>
<style scoped>
.flash-message {
  transition: transform 0.2s;
}
.slide-leave-to {
  transform: translateX(-410px);
}
.off-screen {
  transform: translateX(-360px);
}
.flash-message-bold-text {
  font-weight: 600;
}
.custom-image {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.center-elements {
  margin-top: 8px;
}
/* Override Volta */
/* Volta doesn't have non color type flash messages, so added these properties for displaying x button if type is blank */
.Vlt-callout__dismiss.blank {
  background-image: url('data:image/svg+xml,%3Csvg%20version%3D%221.1%22%20id%3D%22Layer_1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20%0D%0A%09%20viewBox%3D%220%200%2024%2024%22%20style%3D%22enable-background%3Anew%200%200%2024%2024%3B%22%20xml%3Aspace%3D%22preserve%22%3E%0D%0A%3Cpath%20style%3D%22fill%3A%23131415%3B%22%20d%3D%22M12%2012.7223L18.2777%2019L19%2018.2777L12.7223%2012L19%205.72229L18.2777%205L12%2011.2777L5.72229%205L5%205.72229L11.2777%2012L5%2018.2777L5.72229%2019L12%2012.7223Z%22%2F%3E%0D%0A%3C%2Fsvg%3E');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}
</style>
