import * as roomService from '@/apis/room-service-api';
import analytics from '@/services/analytics-service';
import * as vbcGw from '@/apis/vbc-gw';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import { ANALYTICS } from '@/consts/global-consts';
import SoundsPlayer from '@/services/sounds-player';
import i18n from '@/i18n';

export default {
  updateSessionRecording: async ({ dispatch }, sessionId) => {
    const activeRecording = await roomService.getActiveRecordingBySessionId(
      vbcGw.getCredentials().externalId,
      sessionId
    );

    if (activeRecording && Object.keys(activeRecording).length !== 0) {
      return dispatch('setSessionRecording', {
        recordingId: activeRecording.recording_id,
        startTime: new Date(activeRecording.start_time).getTime(),
        recordingOwner: activeRecording.owner
      });
    } else {
      return dispatch('setSessionRecording', null);
    }
  },

  setSessionRecording: (
    { state, rootState, getters, commit, dispatch },
    recording
  ) => {
    const isUpdateRecordingOn = !!recording;
    const isCurrentRecordingOn = !!state.recording;
    // Show indications only if the recording state has changed
    if (isCurrentRecordingOn !== isUpdateRecordingOn) {
      dispatch(
        'addFlashMessage',
        {
          type: 'shoutout',
          text: isUpdateRecordingOn
            ? i18n.t('recordings_actions.recording_on_text')
            : i18n.t('recordings_actions.recording_stopped_text')
        },
        { root: true }
      );
      if (getters.isRecordingSoundIndicationEnabled) {
        SoundsPlayer.playMeetingRecordingIndicationSound(
          isUpdateRecordingOn,
          rootState.selectedSpeakerId
        );
      }
    }
    commit('SET_RECORDING', recording);
    commit('SET_RECORDING_OWNER', recording?.recordingOwner);
  },

  stopRecording: async ({ state, commit, dispatch, rootGetters }) => {
    commit('SET_IS_LOADING_RECORDING', true);
    try {
      await roomService.stopRecording(
        vbcGw.getCredentials().externalId,
        state.recording.recordingId
      );

      analytics.trackEvent(ANALYTICS.RECORDING_TOGGLED, {
        'New State': 'Off',
        'Num of Participants': rootGetters.activeParticipants.length,
        'is Owner': rootGetters.isSessionOwner
      });
    } catch (error) {
      dispatch('handleStopRecordingError', error);
    } finally {
      commit('SET_IS_LOADING_RECORDING', false);
    }
  },

  startRecording: async ({ commit, dispatch, rootState, rootGetters }) => {
    commit('SET_IS_LOADING_RECORDING', true);
    try {
      await roomService.startRecording(
        vbcGw.getCredentials().externalId,
        rootState.sessionId
      );

      analytics.trackEvent(ANALYTICS.RECORDING_TOGGLED, {
        'New State': 'On',
        'Num of Participants': rootGetters.activeParticipants.length,
        'is Owner': rootGetters.isSessionOwner
      });
    } catch (error) {
      dispatch('handleStartRecordingError', error);
    } finally {
      commit('SET_IS_LOADING_RECORDING', false);
    }
  },

  handleStartRecordingError: ({ dispatch, rootState }, error) => {
    logger.error('start-recording', LOG_CATEGORIES.CLIENT_LOGIC, error);
    if (
      error.response?.data?.name === 'BadRequestError' &&
      // Checking the limit exceeded text.
      // When RS changes the error we should change accordingly.
      error.response?.data?.message?.includes('Limit exceeded')
    ) {
      return dispatch(
        'setInfoDialog',
        {
          title: i18n.t('recordings_actions.out_of_storage_title'),
          text: i18n.t('recordings_actions.out_of_storage_text')
        },
        { root: true }
      );
    } else if (error.response?.status === 409) {
      // We want to update the session recording's state
      // due to an "already active recording in session" error
      dispatch('updateSessionRecording', rootState.sessionId);
    }
    dispatch(
      'addFlashMessage',
      {
        type: 'critical',
        text: i18n.t('recordings_actions.start_recording_error_text')
      },
      { root: true }
    );
  },

  handleStopRecordingError: (
    { state, getters, rootState, rootGetters, dispatch },
    error
  ) => {
    logger.error('stop-recording', LOG_CATEGORIES.CLIENT_LOGIC, error);
    if (error?.response?.status === 404) {
      let flashMessageText;
      if (state.recordingOwner === rootState.owner) {
        flashMessageText = i18n.t(
          'meeting_actions.only_owner_can_stop_recording',
          { ownerDisplayName: rootGetters.ownerDisplayName }
        );
      } else if (rootGetters.isOwnerInTheMeeting) {
        flashMessageText = i18n.t(
          'meeting_actions.only_owner_or_recording_owner_can_stop_recording',
          {
            ownerDisplayName: rootState.ownerDisplayName,
            recordingOwnerDisplayName: getters.recordingOwnerDisplayName
          }
        );
      } else {
        flashMessageText = i18n.t(
          'meeting_actions.only_owner_can_stop_recording',
          { ownerDisplayName: rootState.ownerDisplayName }
        );
      }
      dispatch(
        'addFlashMessage',
        {
          time: 7000,
          type: 'shoutout',
          title: i18n.t('meeting_actions.recording_already_active'),
          text: flashMessageText,
          button: {
            text: i18n.t('meeting_actions.got_it'),
            actions: {
              name: 'removeSelf'
            }
          }
        },
        { root: true }
      );
    } else {
      // Any other error than 404
      // For general error we show errorDialog to allow the user to try again
      dispatch(
        'setErrorDialog',
        {
          text: i18n.t('recordings_actions.stop_recording_error_text'),
          data: error,
          primaryButtonText: i18n.t('error_modal.stop_recording_btn'),
          primaryButtonIcon: 'rec-solid',
          buttonCallback: () => dispatch('stopRecording')
        },
        { root: true }
      );
    }
  }
};
