var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popper",
    {
      ref: "popper",
      attrs: {
        trigger: "clickToToggle",
        "visible-arrow": false,
        options: { placement: "top-end" },
      },
      on: { show: _vm.onPopperShown, hide: _vm.onPopperHidden },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasMenuItems,
              expression: "hasMenuItems",
            },
          ],
          staticClass: "popper menu-items-popper",
        },
        [
          _c(
            "div",
            { staticClass: "menu-items-container" },
            [_vm._t("default")],
            2
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "action-button-container",
          attrs: { slot: "reference" },
          slot: "reference",
        },
        [
          _c("vwc-button", {
            attrs: {
              "data-cy": "custom-action-button",
              label: _vm.title,
              layout: _vm.destructive ? "outlined" : "text",
              connotation: _vm.destructive ? "alert" : "primary",
              shape: "rounded",
              stacked: "",
              icon: _vm.iconName,
              onmouseup: "this.blur()",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("click")
              },
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }