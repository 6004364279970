/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-compose-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 15c0-.9.6-1.5 1.5-1.5s1.5.6 1.5 1.5v7.5c0 .9-.6 1.5-1.5 1.5h-21C.6 24 0 23.4 0 22.5v-21C0 .6.6 0 1.5 0H9c.9 0 1.5.6 1.5 1.5S9.9 3 9 3H3v18h18v-6zM19.05.45c.6-.6 1.5-.6 2.1 0l2.4 2.4c.6.6.6 1.5 0 2.1L13.5 15H9v-4.5L19.05.45z"/>'
  }
})
