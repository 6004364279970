export default class VideoMetadata {
  constructor(videoSiteType, videoId, title, duration, thumbnailUrl, author) {
    this.videoSiteType = videoSiteType;
    this.videoId = videoId;
    this.title = title;
    this.duration = duration;
    this.thumbnailUrl = thumbnailUrl;
    this.author = author;
  }
}
