/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-disabled': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.3 14.4l1.1-1.1c-1.8-2.2-3-4.5-3.4-6.8-.6-3.1 1.6-3.7 2.3-3.9h.1c.1-.1.1-.1.2-.1h.1c.5 0 .8.3.9.5.4.6 2.1 3 2.1 4s-.4 1.6-1.3 2.2c0 0 .1.8 1 2.1l1.1-1.1c-.1-.2-.2-.3-.3-.5.6-.6 1.2-1.5 1-2.8 0-1.3-1.3-3.5-2.4-5-.3-.5-1-1-2.1-1-.2 0-.5 0-.6.2-2.8.7-4.2 2.8-3.6 5.7.5 2.4 1.7 4.9 3.8 7.6zM23 1c-.3-.3-.8-.3-1.1 0L1 22c-.3.3-.3.8 0 1.1.3.3.8.3 1.1 0l5.8-5.8c3.1 3.1 7.3 5.4 10 5.4h.6c2.1-.3 3.5-2 3.9-3.6.3-1.2 0-2.2-.8-2.9-2.2-1.8-3.6-2.5-5.1-2.5h-.8c-.9.2-1.5.6-2 1-.1 0-.1-.1-.2-.1-.4-.3-1.1-.7-1.8-1.4L23 2c.3-.3.3-.7 0-1zm-8.4 15.5s.5-1.2 1.7-1.4h.5c1 0 2.1.6 4.2 2.2.8.6-.2 3.3-2.4 3.8H18c-2.3 0-6.1-2.1-9.1-5l1.9-1.9c2 1.9 3.8 2.3 3.8 2.3z"/>'
  }
})
