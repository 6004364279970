/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-youtube-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M23.498 6.185a3.016 3.016 0 00-2.122-2.135C19.506 3.545 12 3.545 12 3.545s-7.505 0-9.376.505A3.016 3.016 0 00.502 6.185C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 002.122 2.136c1.87.505 9.376.505 9.376.505s7.506 0 9.376-.505a3.016 3.016 0 002.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.815z" _fill="red"/><path pid="1" d="M9.545 15.568V8.431L15.818 12l-6.273 3.568z" _fill="#fff"/></g><defs><clipPath id="clip0"><path pid="2" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
