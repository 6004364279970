/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-wordpress-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M12 24.002c6.654 0 12-5.346 12-12C24 5.456 18.654.11 12.327.002 5.564-.108 0 5.347 0 11.892c0 6.764 5.345 12.11 12 12.11zM12 .547c6.327 0 11.454 5.127 11.454 11.564 0 6.109-5.236 11.236-11.454 11.236C5.673 23.347.545 18.22.545 11.892.545 5.675 5.782.547 12 .547zm-4.8 6.11c.11.326-.218.436-.545.436-.328 0-.655 0-.982.109 1.2 3.709 2.509 7.309 3.709 11.127.109-.11.109-.218.109-.218.655-2.073 1.418-4.146 2.073-6.219.109-.218 0-.436 0-.654-.328-.982-.764-2.073-1.091-3.055-.219-.763-.546-1.2-1.31-1.09-.108 0-.327-.328-.436-.437.11-.109.328-.327.546-.327H14.4c.218 0 .436.218.545.327-.218.11-.327.437-.545.437-.327.109-.655.109-1.09.109 1.2 3.709 2.508 7.309 3.708 11.127.546-1.855 1.091-3.6 1.636-5.346.437-1.527.219-2.945-.654-4.363-.218-.437-.546-.764-.654-1.2-.11-.546-.328-1.091-.219-1.637.11-.872.764-1.309 1.746-1.418C13.963-.107 6.327 1.53 3.49 6.438c1.09 0 2.182-.11 3.164-.11.218 0 .545.11.545.328zm1.964 14.945v.218c2.072.545 4.145.545 6.327-.218l-3.273-8.837c-1.09 3.055-2.072 6-3.054 8.837zm-1.637-.328L2.618 7.856c-2.182 4.255-.545 10.8 4.91 13.418zm14.727-8.618c.11-2.073-.545-4.8-1.2-5.236-.108.763 0 1.527-.218 2.29-.545 1.746-1.09 3.492-1.745 5.237-.655 1.855-1.31 3.818-1.964 5.673 3.164-1.746 4.91-4.364 5.128-7.964z" _fill="#3179A1"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
