/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-merge-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.85 2.25l-2.1-2.1-4.8 4.8L13.5 1.5v9h9l-3.45-3.45 4.8-4.8zm-18.9 14.7l-4.8 4.8 2.1 2.1 4.8-4.8 3.45 3.45v-9h-9l3.45 3.45zm1.843-8.743l9 9 1.414-1.414-9-9-1.414 1.414z"/>'
  }
})
