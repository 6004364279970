import { isBackCamera } from '@/helpers/mobile-utils';
import { mobileScreenOrientationTracker } from '@/services/mobile-screen-orientation-tracker';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import analytics from '@/services/analytics-service';
import router from '@/router';
import { ANALYTICS } from '@/consts/global-consts';

export default {
  init: () => {
    mobileScreenOrientationTracker.addOrientationChangeListener(() => {
      logger.log('orientation-change', LOG_CATEGORIES.MOBILE, {
        newOrientation: mobileScreenOrientationTracker.currentOrientation
      });

      if (window.isSafari) {
        // A scroll a day keeps the Safari UI bugs away - changing orientations can sometimes cause odd
        // UI bugs due to the changing of the visibility of Safari's URL bar. This hacky scrolling solution
        // is barely noticed
        setTimeout(() => {
          window.scrollTo(0, 1);
        }, 400);
      }
    });
  },

  toggleVideo: async ({ dispatch }) => {
    dispatch('toggleVideo', null, { root: true });
    dispatch('rebindPublisherVideo', null, { root: true });
  },

  flipCamera: async ({ getters, rootState, dispatch }) => {
    const flippedCamera = rootState.cameraDevices.find(
      (cameraDevice) =>
        isBackCamera(cameraDevice.label) !== getters.isBackCameraSelected
    );

    if (flippedCamera) {
      // If a camera that has an opposite facing-direction was found, use it
      dispatch('selectCameraDevice', flippedCamera.deviceId, { root: true });
    } else {
      // Otherwise just cycle to the next available camera
      dispatch('cycleCamera', null, { root: true });

      logger.warning('flip-camera', LOG_CATEGORIES.MOBILE, {
        message: 'Failed to flip camera. Cycling cameras instead'
      });
    }

    analytics.trackEvent(ANALYTICS.CAMERA_FLIPPED, {
      'Join Type': 'Guest'
    });
  },

  openMobileChat: ({ dispatch }) => {
    // To allow the user to go back to the meeting from the chat using the back button we add a chat hash to the url.
    // The changes in the hash trigger the hashChange event but in some cases the event is not triggered when the entire hash is removed.
    // To trigger the event when the chat hash is removed we add an empty hash to the url before the chat hash and remove it when the chat hash is removed.
    router.history.push(router.currentRoute.path + '#');
    router.history.push(router.currentRoute.path + '#chat');
    dispatch('setIsChatOpen', true);
  },

  displayToast: ({ commit }, { message, time, type }) => {
    commit('SET_TOAST', { message, time, type });

    logger.log('display-toast', LOG_CATEGORIES.MOBILE, {
      toastMessage: message,
      time: time
    });
  },

  clearToast: ({ commit }) => {
    commit('SET_TOAST', null);
  },

  displayMessageBanner: ({ commit }, { message, time }) => {
    commit('SET_MESSAGE_BANNER', { message, time });
  },

  clearMessageBanner: ({ commit }) => {
    commit('SET_MESSAGE_BANNER', null);
  },

  setIsChatOpen: ({ commit }, isOpen) => {
    commit('SET_IS_MOBILE_CHAT_OPEN', isOpen);
  }
};
