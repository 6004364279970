import {
  LAYOUT_MODE_TYPES,
  MUTE_FORCED_TYPE,
  PUBLISHER_STATE
} from '@/consts/global-consts';

export function createTestingInstance(store) {
  const testing = {
    setTestMode: (enabled) => (window.TEST_MODE = enabled),

    layoutTypes: LAYOUT_MODE_TYPES,

    publisherStates: PUBLISHER_STATE,

    isMicEnabled: () => {
      return store.state.isMicEnabled;
    },
    isVideoEnabled: () => {
      return store.state.isVideoEnabled;
    },
    isScreenshareEnabled: () => {
      return store.getters.isScreenShared;
    },
    currentLayout: () => {
      return store.state.layout.layoutMode;
    },
    publisherState: () => {
      return store.state.publisherState;
    },
    toggleMic: () => {
      return store.dispatch('toggleMic');
    },
    toggleVideo: () => {
      return store.dispatch('toggleVideo');
    },
    changeLayout: (layoutMode) => {
      return store.dispatch('layout/setLayoutMode', { layoutMode });
    },
    sendReaction: async (type, count) => {
      if (!count) {
        return store.dispatch('reactions/sendReaction', type);
      }
      for (let i = 0; i < count; i++) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        store.dispatch('reactions/sendReaction', type);
      }
    },
    // Test all the permutations of mute all/participant
    getSessionAndPublisherMute: () => {
      store.dispatch(
        'setCurrentForceMuteEventState',
        MUTE_FORCED_TYPE.SESSION_MUTE
      );
      store.dispatch(
        'setCurrentForceMuteEventState',
        MUTE_FORCED_TYPE.PUBLISHER_MUTE
      );
    },
    getPublisherAndSessionMute: () => {
      store.dispatch(
        'setCurrentForceMuteEventState',
        MUTE_FORCED_TYPE.PUBLISHER_MUTE
      );
      store.dispatch(
        'setCurrentForceMuteEventState',
        MUTE_FORCED_TYPE.SESSION_MUTE
      );
    },
    getSessionMute: () => {
      store.dispatch(
        'setCurrentForceMuteEventState',
        MUTE_FORCED_TYPE.SESSION_MUTE
      );
    },
    getPublisherMute: () => {
      store.dispatch(
        'setCurrentForceMuteEventState',
        MUTE_FORCED_TYPE.PUBLISHER_MUTE
      );
    }
  };
  return testing;
}
