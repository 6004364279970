/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mind-map': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 8.2c-1.8 0-3.3 1.3-3.7 3h-2.7c-.3-1.7-1.9-3-3.7-3-.6 0-1.1.1-1.6.4L6.8 6.5c.6-.7 1-1.5 1-2.5C7.8 1.9 6.1.2 4 .2S.2 1.9.2 4 1.9 7.8 4 7.8c.6 0 1.1-.1 1.6-.4l1.6 2.1c-.6.7-1 1.5-1 2.5s.4 1.8.9 2.5l-1.6 2.1c-.5-.2-1-.4-1.6-.4C1.8 16.2.1 17.9.1 20s1.7 3.8 3.8 3.8 3.8-1.7 3.8-3.8c0-1-.4-1.8-.9-2.5l1.6-2.1c.5.2 1 .4 1.6.4 1.8 0 3.3-1.3 3.7-3h2.7c.3 1.7 1.9 3 3.7 3 2.1 0 3.8-1.7 3.8-3.8-.1-2.1-1.8-3.8-3.9-3.8zm-16-2c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2-1 2.2-2.2 2.2zm0 16c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2-1 2.2-2.2 2.2zm6-8c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2-1 2.2-2.2 2.2zm10 0c-1.2 0-2.2-1-2.2-2.2s1-2.2 2.2-2.2 2.2 1 2.2 2.2-1 2.2-2.2 2.2z"/>'
  }
})
