/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-queue-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20 1a3 3 0 100 6 3 3 0 000-6zm-8 3a3 3 0 100 6 3 3 0 000-6zM4 7a3 3 0 100 6 3 3 0 000-6zm15 2a2.99 2.99 0 00-2.86 2.14C17.267 12.06 18 13.439 18 15v2h6v-5a3 3 0 00-3-3h-2zm-8 3a2.99 2.99 0 00-2.86 2.14C9.267 15.06 10 16.439 10 18v2h6v-5a3 3 0 00-3-3h-2zm-8 3a3 3 0 00-3 3v5h8v-5a3 3 0 00-3-3H3z"/>'
  }
})
