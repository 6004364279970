var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.shift.a",
          value: function () {
            return _vm.toggleMicViaKeyboard()
          },
          expression: "() => toggleMicViaKeyboard()",
          modifiers: { prevent: true, cmd: true, shift: true, a: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.shift.v",
          value: function () {
            return _vm.toggleVideoViaKeyboard()
          },
          expression: "() => toggleVideoViaKeyboard()",
          modifiers: { prevent: true, cmd: true, shift: true, v: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.shift.s",
          value: function () {
            return _vm.toggleShareScreenClickViaKeyboard()
          },
          expression: "\n    () => toggleShareScreenClickViaKeyboard()\n  ",
          modifiers: { prevent: true, cmd: true, shift: true, s: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.shift.r",
          value: function () {
            return _vm.toggleRecordingViaKeyboard()
          },
          expression: "() => toggleRecordingViaKeyboard()",
          modifiers: { prevent: true, cmd: true, shift: true, r: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.shift.m",
          value: function () {
            return _vm.muteAllViaKeyboard()
          },
          expression: "() => muteAllViaKeyboard()",
          modifiers: { prevent: true, cmd: true, shift: true, m: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.shift.e",
          value: function () {
            return _vm.copyMeetingDetailsViaKeyboard()
          },
          expression: "\n    () => copyMeetingDetailsViaKeyboard()\n  ",
          modifiers: { prevent: true, cmd: true, shift: true, e: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.shift.l",
          value: function () {
            return _vm.switchLayoutsViaKeyboard()
          },
          expression: "() => switchLayoutsViaKeyboard()",
          modifiers: { prevent: true, cmd: true, shift: true, l: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.shift.h",
          value: function () {
            return _vm.toggleHandViaKeyboard()
          },
          expression: "() => toggleHandViaKeyboard()",
          modifiers: { prevent: true, cmd: true, shift: true, h: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.b",
          value: function () {
            return _vm.toggleBeRightBackViaKeyboard()
          },
          expression: "() => toggleBeRightBackViaKeyboard()",
          modifiers: { prevent: true, cmd: true, b: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.i",
          value: function () {
            return _vm.toggleInfoSideBarViaKeyboard()
          },
          expression: "() => toggleInfoSideBarViaKeyboard()",
          modifiers: { prevent: true, cmd: true, i: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.o",
          value: function () {
            return _vm.toggleChatSideBarViaKeyboard()
          },
          expression: "() => toggleChatSideBarViaKeyboard()",
          modifiers: { prevent: true, cmd: true, o: true },
        },
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.prevent.cmd.u",
          value: function () {
            return _vm.toggleParticipantsSideBarViaKeyboard()
          },
          expression: "\n    () => toggleParticipantsSideBarViaKeyboard()\n  ",
          modifiers: { prevent: true, cmd: true, u: true },
        },
      ],
      staticClass: "meeting-buttons unselectable",
      class: {
        "floating-bottom-bar": _vm.floating,
        "hidden-bottom-bar": !_vm.show,
      },
    },
    [
      _c("div", { staticClass: "dock left-dock" }, [
        _c(
          "div",
          { staticClass: "mute-btn-container" },
          [
            _c(
              "ToggleAudioButtonPopovers",
              [
                _c(
                  "MeetingActionMenuButton",
                  {
                    staticClass: "mic-button",
                    class: {
                      "mic-button-enabled": _vm.micEnabled,
                      "big-button": _vm.isMicButtonBig || _vm.isNoAudioMode,
                    },
                    attrs: {
                      "data-cy": "mic-button",
                      label: _vm.isNoAudioMode
                        ? _vm.$t("meeting_actions.enable_audio")
                        : _vm.micEnabled
                        ? _vm.$t("meeting_actions.mute")
                        : _vm.$t("meeting_actions.unmute"),
                      icon: _vm.isNoAudioMode
                        ? "audio-mute-solid"
                        : _vm.micEnabled
                        ? "microphone-2-solid"
                        : "mic-mute-solid",
                      disabled: _vm.isInitializingPublisher,
                      "hover-tooltip": _vm.toggleMicBtnTooltip,
                      split: _vm.hasMicrophoneAccess,
                      "disable-split":
                        !_vm.hasMicrophoneAccess ||
                        _vm.isInitializingPublisher ||
                        _vm.isNoAudioMode,
                      "flip-menu": true,
                      lists: _vm.micMenu,
                    },
                    on: {
                      click: function () {
                        return _vm.isNoAudioMode
                          ? _vm.setIsTurnOnAudioDialogVisible(true)
                          : _vm.toggleMic(_vm.TOGGLE_MIC_SOURCES.BOTTOM_BAR)
                      },
                      speakerDeviceClicked: _vm.speakerDeviceClicked,
                      micDeviceClicked: _vm.micDeviceClicked,
                    },
                  },
                  [
                    !_vm.isInitializingPublisher && _vm.micEnabled
                      ? _c("MicVolumeIndication", {
                          staticClass: "volume-indication",
                          style: _vm.micIndicationStyle,
                          attrs: { volume: _vm.micVolume },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mute-video-button-container" },
          [
            _vm.showPreviewVideo
              ? _c(
                  "div",
                  {
                    staticClass: "video-preview-container",
                    style: _vm.hasCameraAccess
                      ? { "margin-right": "49px" }
                      : "",
                  },
                  [
                    _c("PreviewVideo", {
                      staticClass: "video-preview",
                      attrs: { delay: 500 },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("MeetingActionMenuButton", {
              staticClass: "camera-button",
              attrs: {
                "data-cy": "camera-button",
                label: _vm.videoEnabled
                  ? _vm.$t("meeting_actions.stop_video")
                  : _vm.$t("meeting_actions.start_video"),
                icon: _vm.videoEnabled ? "video-solid" : "video-off-solid",
                disabled: _vm.isInitializingPublisher,
                "hover-tooltip": _vm.toggleVideoBtnTooltip,
                split: _vm.hasCameraAccess,
                "disable-split":
                  _vm.isInitializingPublisher || !_vm.hasCameraAccess,
                "flip-menu": true,
                lists: _vm.cameraMenu,
              },
              on: {
                hover: function ($event) {
                  _vm.cameraButtonHovered = true
                },
                unhover: function ($event) {
                  _vm.cameraButtonHovered = false
                },
                click: function () {
                  return _vm.toggleVideo(_vm.TOGGLE_CAMERA_SOURCES.BOTTOM_BAR)
                },
                blur: _vm.blurBackgroundClicked,
                virtualBackground: _vm.virtualBackgroundClicked,
                cameraDeviceClicked: _vm.cameraDeviceClicked,
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "dock right-dock" },
        [
          _vm.showRecordingButton
            ? _c("MeetingActionMenuButton", {
                staticClass: "recording-button",
                attrs: {
                  "data-cy": "recording-button",
                  label: _vm.isUserRecording
                    ? _vm.$t("meeting_actions.stop_recording")
                    : _vm.$t("meeting_actions.record"),
                  icon: _vm.isUserRecording ? "stop-solid" : "rec-solid",
                  loading: _vm.isLoadingRecording,
                  "active-alert": _vm.isUserRecording,
                },
                on: { click: _vm.toggleRecording },
              })
            : _vm._e(),
          _vm.showCaptionsButton
            ? _c("MeetingActionMenuButton", {
                staticClass: "captions-button",
                attrs: {
                  "data-cy": "captions-button",
                  label: _vm.isCaptionsEnabled
                    ? _vm.$t("meeting_actions.hide_captions")
                    : _vm.$t("meeting_actions.show_captions"),
                  icon: "closed-captioning-solid",
                  loading: _vm.isLoadingCaptions,
                  "active-info": _vm.isCaptionsEnabled,
                },
                on: { click: _vm.toggleCaptions },
              })
            : _vm._e(),
          _vm.showShareScreenButton
            ? _c("MeetingActionMenuButton", {
                staticClass: "share-screen-button",
                attrs: {
                  "data-cy": "share-screen-button",
                  label: _vm.isScreenShared
                    ? _vm.$t("meeting_actions.stop_sharing")
                    : _vm.$t("meeting_actions.share_screen"),
                  icon: "screen-share-solid",
                  "active-info": _vm.isScreenShared,
                  disabled: !_vm.hasScreenshareAccess,
                  loading: _vm.isInitializingScreenshare,
                },
                on: { click: _vm.onScreenshareClick },
              })
            : _vm._e(),
          !_vm.$screen.sm && !_vm.$screen.xs
            ? _c(
                "MeetingActionCustomMenuButton",
                {
                  ref: "reactionsMenu",
                  staticClass: "reactions-menu",
                  attrs: {
                    "data-cy": "reactions-button",
                    title: _vm.$t("meeting_actions.reactions_title"),
                    iconName: "emoji-solid",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "reactions-container" },
                    _vm._l(_vm.reactions, function (reaction) {
                      return _c(
                        "div",
                        {
                          key: reaction.value,
                          staticClass: "reaction-icon",
                          on: {
                            click: function ($event) {
                              return _vm.sendReactionToAll(reaction)
                            },
                          },
                        },
                        [
                          _c("img", {
                            attrs: { src: reaction.path, alt: reaction.value },
                          }),
                        ]
                      )
                    }),
                    0
                  ),
                  _vm.exclusiveExperimentalMode && _vm.isSessionOwner
                    ? _c(
                        "div",
                        [
                          _c("div", { staticClass: "separator" }),
                          _c(
                            "vwc-list-item",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip",
                                  value: {
                                    content: _vm.reactionsCounterTooltip,
                                    placement: "top",
                                    classes: "reactions-counter-tooltip",
                                  },
                                  expression:
                                    "{\n            content: reactionsCounterTooltip,\n            placement: 'top',\n            classes: 'reactions-counter-tooltip'\n          }",
                                },
                              ],
                              attrs: { graphic: "icon", hasMeta: "" },
                              on: { click: _vm.toggleReactionsCounter },
                            },
                            [
                              _c("vwc-icon", {
                                attrs: {
                                  slot: "graphic",
                                  type: "happy-line",
                                  size: "small",
                                },
                                slot: "graphic",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t("meeting_actions.reactions_counter")
                                  ) +
                                  " "
                              ),
                              _vm.isReactionsCounterModalOpen
                                ? _c("vwc-icon", {
                                    attrs: {
                                      slot: "meta",
                                      "data-cy": "layout-selected-icon",
                                      type: "check-line",
                                      size: "small",
                                    },
                                    slot: "meta",
                                  })
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "separator" }),
                  _c("vwc-button", {
                    staticClass: "raise-hand-btn",
                    attrs: {
                      label: _vm.isMyParticipantRaisingHand
                        ? _vm.$t("meeting_actions.lower_hand")
                        : _vm.$t("meeting_actions.raise_hand"),
                      layout: "filled",
                      icon: _vm.isMyParticipantRaisingHand
                        ? "hand-off-line"
                        : "hand-line",
                    },
                    on: { click: _vm.toggleRaiseHand },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.showPrivacyButton
            ? _c("MeetingActionMenuButton", {
                attrs: {
                  id: "privacy-button",
                  "data-cy": "privacy-button",
                  label: _vm.$t("meeting_actions.privacy"),
                  icon: "shield-solid",
                  lists: [{ items: _vm.privacyMenuItems }],
                },
                on: {
                  lockMeeting: _vm.toggleMeetingLock,
                  toggleWaitingRoom: _vm.toggleWaitingRoom,
                },
              })
            : _vm._e(),
          !_vm.$screen.xs
            ? _c("MeetingActionMenuButton", {
                attrs: {
                  "data-cy": "more-button",
                  label: _vm.$t("meeting_actions.more"),
                  icon: "more-vertical-solid",
                  loading: _vm.isInitializingWhiteboard,
                  lists: _vm.moreMenuLists,
                },
                on: {
                  whiteboard: _vm.toggleWhiteboard,
                  watchTogether: _vm.watchTogetherClicked,
                  roundTable: _vm.roundTableClicked,
                  lockMeeting: _vm.toggleMeetingLock,
                  shareScreen: _vm.toggleScreenshare,
                  record: _vm.toggleRecording,
                  captions: _vm.toggleCaptions,
                  transcript: _vm.liveTranscriptionClicked,
                  settings: _vm.openSettings,
                  toggleWaitingRoom: _vm.toggleWaitingRoom,
                  reportIssue: _vm.reportIssue,
                  qna: _vm.toggleQna,
                },
              })
            : _vm._e(),
          _c("vwc-button", {
            attrs: {
              "data-cy": "end-meeting-button",
              label: _vm.endMeetingButtonText,
              layout: "filled",
              connotation: "alert",
            },
            on: { click: _vm.showEndMeetingDialog },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }