/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-wifi-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 5.2C2.2 3.1 5 2 8 2s5.8 1.1 8 3.2l-1.4 1.5C12.8 4.9 10.5 4 8 4s-4.8.9-6.6 2.6L0 5.2zM11.5 10C10.6 9 9.3 8.5 8 8.5c-1.3 0-2.6.5-3.5 1.5L3.1 8.6c1.3-1.4 3-2.1 4.9-2.1 1.9 0 3.6.7 4.9 2.1L11.5 10zM8 14a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" _fill="#E84545"/>'
  }
})
