<template>
  <div
    class="Vlt-callout Vlt-callout--generic attachment-box"
    :class="{ 'mobile-chat': isMobileWebChat }"
  >
    <div class="thumbnail">
      <v-icon
        :iconName="attachment.icon"
        class="icon"
        :class="attachment.icon"
      />
      <v-spinner
        v-if="isLoading"
        class="spinner"
        size="sm"
        :white="isMobileWebChat"
      />
    </div>
    <div class="Vlt-callout__content text-ellipsis">
      <!-- File info -->
      <div class="file-info">
        <div class="filename text-ellipsis">{{ attachment.name }}</div>
        <div v-if="isUnsupported" class="unsupported">
          {{ $t('attachment_box.unsupported_file_text') }}
        </div>
        <div v-else class="file-size">{{ attachment.size }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AttachmentBox',
  props: {
    attachment: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    isUnsupported: {
      type: Boolean,
      default: false
    },
    isMobileWebChat: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.attachment-box {
  cursor: pointer;
}
.attachment-box * {
  transition: 250ms ease;
}
.attachment-box.mobile-chat {
  background-color: #131415;
  border-radius: 8px;
}
/* file-info */
.file-info {
  flex: 1;
  overflow: hidden;
  text-align: left;
}
.filename {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: #2c2d30;
  text-align: left;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}
.attachment-box.mobile-chat .filename {
  color: white;
}
.file-size {
  font-size: 13px;
  line-height: 1.38;
  color: #637381;
  margin-top: 2px;
}
.attachment-box.mobile-chat .file-size {
  color: white;
}
.thumbnail {
  height: 40px;
  width: 40px;
  border-radius: 3px;
  overflow: hidden;
}
.thumbnail .icon {
  height: 40px;
  width: 40px;
}
.Vlt-icon-file-doc {
  fill: #3ae;
}
.Vlt-icon-file-pdf {
  fill: #f25a6b;
}
.Vlt-icon-file-ppt {
  fill: #ff8f02;
}
.Vlt-icon-file-xls {
  fill: #35be8b;
}
.Vlt-icon-file-video {
  fill: #7683d8;
}
.Vlt-icon-file {
  fill: #919eab;
}
.Vlt-icon-file-zip {
  fill: #ffc100;
}
.Vlt-icon-image {
  fill: #ba3737;
}
.spinner {
  position: absolute;
  bottom: 9px;
  left: 42px;
  height: 20px;
  width: 20px;
  margin: 0;
  background-color: #fff;
}
.attachment-box.mobile-chat .spinner {
  background-color: #131415;
}
.unsupported {
  color: #f25a6b;
  font-size: 13px;
  font-weight: bold;
  line-height: 1.38;
}
.Vlt-callout--generic:before {
  background-color: #131415;
}
.attachment-box.mobile-chat.Vlt-callout--generic:before {
  width: unset;
}
</style>
