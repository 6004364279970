<template>
  <div data-cy="message-row" class="message-row" :class="{ me: isMe }">
    <ParticipantAvatar
      v-if="isMobileWebMode"
      negative
      avatarSize="sm"
      class="avatar"
      :participantType="sender ? sender.type : undefined"
      :participantProfilePicture="sender ? sender.profilePicture : undefined"
      :displayName="displayName"
    />
    <div class="message-wrapper">
      <div class="message-header">
        <div
          v-if="!isMobileWebMode"
          class="dot"
          :style="{ 'background-color': isMe ? '' : color }"
        />
        <div
          class="display-name"
          :style="{ color: !isMobileWebMode ? '' : color }"
        >
          {{ displayName }}
        </div>
        <div data-cy="message-time" class="message-time">
          {{ formattedTime }}
        </div>
      </div>
      <div data-cy="message-content" class="message-content">
        <TextMessageRow
          v-if="isTextMessage"
          :message="message"
          @resend="$emit('resend')"
          @copyToClipboard="$emit('copyToClipboard')"
        />
        <ImageRow
          v-else-if="isImage"
          class="image-row"
          :message="message"
          @resend="$emit('resend')"
          @openImageView="$emit('openImageView')"
        />
        <AttachmentRow
          v-else-if="isDocument || (isVideo && !isMobileWebMode)"
          class="attachment"
          :message="message"
          @resend="$emit('resend')"
        />
        <div v-else class="attachment not-supported">
          {{ $t('message_row.attachment_not_supported') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getColor, formatTime } from '@/helpers/global-helpers';
import { MESSAGE_TYPE, COLORS, DARK_MODE_COLORS } from '@/consts/messaging';
import TextMessageRow from '@/components/rows/message-rows/TextMessageRow';
import AttachmentRow from '@/components/rows/message-rows/AttachmentRow';
import ImageRow from '@/components/rows/message-rows/ImageRow';

export default {
  name: 'MessageRow',

  components: {
    TextMessageRow,
    AttachmentRow,
    ImageRow
  },

  props: {
    message: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters(['isMobileWebMode', 'participants']),

    formattedTime() {
      return formatTime(this.message.creationTime);
    },

    isMe() {
      return this.message.direction === 'OUTBOUND';
    },

    color() {
      return getColor(
        this.message.fromDisplayName,
        this.isMobileWebMode ? DARK_MODE_COLORS : COLORS
      );
    },

    displayName() {
      return this.isMe
        ? this.$t('message_row.is_me_display_name_text')
        : this.message.fromDisplayName;
    },

    isTextMessage() {
      return this.message.type === MESSAGE_TYPE.TEXT;
    },

    isImage() {
      return this.message.type === MESSAGE_TYPE.IMAGE;
    },

    isDocument() {
      return this.message.type === MESSAGE_TYPE.DOCUMENT;
    },

    isVideo() {
      return this.message.type === MESSAGE_TYPE.VIDEO;
    },
    sender() {
      return this.message.participantId
        ? this.participants[this.message.participantId]
        : null;
    }
  }
};
</script>

<style scoped>
.message-row {
  margin: 10px 16px 0px 16px;
  word-break: break-word;
}

.mobile .message-row {
  position: relative;
}

.mobile .avatar {
  display: inline-block;
  vertical-align: top;
}

.mobile .message-wrapper {
  display: inline-block;
  margin-left: 8px;
  width: calc(100% - 44px);
}

.message-header {
  display: flex;
  align-items: center;
}

.mobile .message-header {
  font-weight: 600;
}

.display-name {
  font-size: 16px;
  font-weight: bold;
}
.me .dot {
  background-color: #33aaee;
}
.dot {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  margin-right: 5px;
}

.message-time {
  margin-left: 4px;
  font-size: 13px;
  color: #919eab;
}

.mobile .message-time {
  margin-left: auto;
  right: 0px;
  font-weight: 400;
}

.mobile .image-row {
  margin-top: 5px;
}

.mobile .message-content >>> .linkify-body {
  font-size: 16px;
  color: white;
}

.message-content >>> a {
  color: var(--link-color-white-bg) !important;
}

.mobile .message-content >>> a {
  color: var(--link-color-black-bg) !important;
}

.message-content {
  display: flex;
  color: #2c2d30;
}

.attachment {
  width: 100%;
}

.mobile .attachment {
  margin-top: 5px;
}

.mobile .not-supported {
  color: white;
}
</style>
