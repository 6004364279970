/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-puzzle': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7 0a3 3 0 00-3 3H1.5a.5.5 0 00-.5.5v3.05a.5.5 0 00.4.49 2 2 0 010 3.92.5.5 0 00-.4.49v3.05a.5.5 0 00.5.5h3.05a.5.5 0 00.49-.4 2 2 0 013.92 0 .5.5 0 00.49.4h3.05a.5.5 0 00.5-.5V12a3 3 0 100-6V3.5a.5.5 0 00-.5-.5H10a3 3 0 00-3-3zM5 3a2 2 0 113.96.403.5.5 0 00.49.597H12v2.55a.5.5 0 00.597.49 2 2 0 11.003 3.92.5.5 0 00-.6.49V14H9.83a3 3 0 00-5.66 0H2v-2.17a3 3 0 000-5.66V4h2.55a.5.5 0 00.49-.597A2.077 2.077 0 015 3z"/>'
  }
})
