var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "watch-together-container",
      class: { minimized: _vm.isWatchTogetherMinimized },
      style: _vm.sizedStyle,
    },
    [
      !_vm.isWatchTogetherMinimized && _vm.isWatchTogetherOwner
        ? _c(
            "div",
            { staticClass: "owner-exclamation Vlt-white" },
            [
              _c("div", { staticClass: "owner-exclamation-text" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("watch_together_view.owner_exclamation_text")
                    ) +
                    " "
                ),
              ]),
              _c("vwc-button", {
                staticClass: "stop-sharing-btn",
                attrs: {
                  "data-cy": "stop-sharing-btn",
                  label: _vm.$t("watch_together_view.stop_sharing_button"),
                  layout: "filled",
                  shape: "pill",
                  connotation: "alert",
                  dense: "",
                },
                on: { click: _vm.stopWatchTogether },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("WatchTogetherPlayer", {
        ref: "videoPlayer",
        staticClass: "video-player",
        attrs: { minimized: _vm.isWatchTogetherMinimized },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }