/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-collapse-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 4.5H1.5a1.5 1.5 0 010-3H12a1.5 1.5 0 010 3zm4.5 6h-15a1.5 1.5 0 000 3h15V18l7.5-6-7.5-6v4.5zm-15 12H12a1.5 1.5 0 000-3H1.5a1.5 1.5 0 000 3z"/>'
  }
})
