/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-inbound-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.137 8.926l6.756-6.756L21.723 0l-6.756 6.756V1.937H11.9v10.057h10.057V8.926h-4.819z"/><path pid="1" d="M3.856.853a1.89 1.89 0 011.231.09c.44.19.753.555.98.934.188.295.694 1.09 1.515 2.384.842 1.326 1.346 2.637 1.012 3.76-.218.787-.58 1.526-1.235 1.915-.01.051-.017.174.032.4l.004.02c.083.43.385 1.034 1.038 1.83.687.834 1.193 1.412 1.528 1.75.354.358.936.873 1.762 1.554.787.65 1.394.968 1.834 1.069.212.046.329.04.379.032.38-.652 1.107-1.032 1.89-1.273l.02-.007c.574-.16 1.186-.098 1.789.087.605.186 1.254.513 1.941.952 1.253.8 2.058 1.324 2.385 1.557.37.23.722.542.906.976.17.4.19.82.088 1.228l-.011.047-.442 1.195c-.376 1.016-1.147 1.763-2.194 2.267l-.011.005c-.98.455-2.13.472-3.367.18-1.208-.286-2.447-.77-3.716-1.442a28.542 28.542 0 01-3.667-2.325c-1.182-.879-2.2-1.745-3.047-2.6s-1.706-1.881-2.576-3.074a28.858 28.858 0 01-2.303-3.7C.955 9.386.476 8.138.193 6.92c-.289-1.246-.272-2.4.177-3.384l.005-.011c.497-1.053 1.237-1.832 2.249-2.213L3.809.865l.047-.012z"/>'
  }
})
