import { capabilities } from '@/consts/capabilities';

export default {
  isRecording: (state) => !!state.recording,

  recordingOwner: (state) => state.recordingOwner,

  isRecordingSoundIndicationEnabled: (state, getters, rootState) => {
    return rootState.capabilities.includes(
      capabilities.RECORDING_SOUND_INDICATION
    );
  },

  isUserAllowedToRecord: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters.isRecordingFeatureEnabled &&
      (rootGetters.isSessionOwner || getters.isNonOwnerAllowedToRecord)
    );
  },

  isNonOwnerAllowedToRecord: (state, getters, rootState, rootGetters) => {
    return (
      rootGetters.allowUsersToRecord &&
      !rootGetters.isSessionOwner &&
      !rootGetters.isGuest
    );
  },

  isUserRecording: (state, getters, rootState, rootGetters) => {
    return (
      getters.isRecording &&
      (rootState.myParticipantId === getters.recordingOwner ||
        rootGetters.isSessionOwner)
    );
  },

  recordingOwnerDisplayName: (state, getters, rootState) => {
    return rootState.participants[state.recordingOwner]?.displayName;
  },

  // Used for allowing the owner to take guest snapshot (need to confirm it due to privacy issues)
  isGuestMayBeRecorded: (state, getters, rootState, rootGetters) => {
    return (
      (rootGetters.isMeetingAutoRecorded ||
        rootGetters.isRecordingFeatureEnabled) &&
      !rootGetters.isRecordingOfOwnerOnly &&
      getters.isRecording // Currently decided to enable snapshot only when recording is active
    );
  }
};
