/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-attachment': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M12.747 1.467a4 4 0 00-5.658 0L2.67 5.887a5.751 5.751 0 008.133 8.133l4.065-4.066a.5.5 0 10-.708-.707l-4.065 4.066a4.75 4.75 0 01-6.717-6.718l3.558-3.56.28-.28.58-.58a3 3 0 114.244 4.242l-4.066 4.066a1.251 1.251 0 01-1.768-1.767l.192-.193 1.26-1.26L9.21 5.711a.5.5 0 10-.708-.708l-.971.972-.98.98-1.054 1.053a2.251 2.251 0 003.183 3.183l4.066-4.066a4 4 0 000-5.658z"/>'
  }
})
