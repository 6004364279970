<template>
  <!-- TODO: try to flatten the nested divs -->
  <div @drop.prevent @dragenter.prevent @dragleave.prevent @dragover.prevent>
    <div
      class="main-app"
      :class="[minimizedMode ? 'Vlt-bg-white' : 'black-background']"
    >
      <div
        class="main-screen Vlt-white"
        :class="{
          'grid-view': layoutMode === LAYOUT_MODE_TYPES.GRID,
          'speaker-view': layoutMode === LAYOUT_MODE_TYPES.SPEAKER,
          'dominant-view': layoutMode === LAYOUT_MODE_TYPES.DOMINANT,
          'minimized-mode': minimizedMode
        }"
      >
        <portal-target name="overlay-bg-placeholder" />
        <WatchTogetherModal v-if="isWatchTogetherModalShown" />
        <VirtualBackgroundModal v-if="isVirtualBackgroundModalVisible" />
        <div class="screen-container">
          <div class="screen-wrapper">
            <transition name="fade">
              <div v-if="hasSessionEverConnected" class="main-area-container">
                <div
                  class="videos-wrapper"
                  :class="{
                    'no-cursor': !showFullscreenElements,
                    'sidebar-collapsed': isSidebarCollapsed
                  }"
                  @mousemove="updateIsMouseIdle"
                >
                  <ReactionsCounterModal
                    v-if="isReactionsCounterModalOpen"
                    class="reactions-counter-modal"
                  />
                  <div
                    class="waiting-room-owner-modal-container"
                    :class="{
                      'waiting-room-empty': isWaitingRoomEmpty
                    }"
                  >
                    <transition name="waiting-room-modal-fade-and-fly">
                      <!--Both string formats here are passed without injecting the parameters first, it is handled inside the modal itself -->
                      <WaitingRoomOwnerModal
                        v-if="!minimizedMode && isWaitingRoomOwnerModalShown"
                        class="waiting-room-owner-modal"
                        :waitingParticipants="waitingParticipants"
                        :isApproveAllInProgress="isApproveAllInProgress"
                        :undoWindowDuration="UNDO_WINDOW_DURATION"
                        :viewRoomButtonLabel="
                          $t('app_screen.show_waiting_room_button')
                        "
                        :approveAllButtonLabel="
                          $t('app_screen.waiting_room_approve_all_button')
                        "
                        :singleWaitingStringFormat="
                          $t(
                            'app_screen.waiting_room_single_participant_waiting_msg_format'
                          )
                        "
                        :pluralWaitingStringFormat="
                          $t(
                            'app_screen.waiting_room_plural_participants_waiting_msg_format'
                          )
                        "
                        @viewRoomClicked="viewWaitingRoom"
                        @approveAllClicked="approveAllWaitingParticipants"
                        @updateParticipantClicked="updateWaitingParticipant"
                        @undoDenyClicked="undoDeny"
                      />
                    </transition>
                  </div>
                  <div v-if="!minimizedMode">
                    <NoConnectionBanner v-if="!isOnline" />
                    <OldAccountBanner v-if="isOldAccount" />
                    <SubscriberStats v-if="showSubscriberStatsWidget" />
                    <ReportIssueModal v-if="showReportIssueModal" />
                    <SelectLanguageModal v-if="showSelectLanguageModal" />
                    <EndMeetingModal
                      v-if="isEndMeetingDialogVisible"
                      shouldCloseWindow
                      showCancelButton
                    />
                    <TurnOnAudioModal v-if="isTurnOnAudioDialogVisible" />
                    <ConfirmationDialogModal v-if="confirmationDialog" />

                    <LockMeetingModal v-if="isLockMeetingDialogVisible" />
                    <RemoveParticipantModal v-if="participantToRemove" />

                    <HijackScreenshareModal
                      v-if="isHijackScreenshareDialogVisible"
                    />
                    <MuteAllParticipantsModal
                      v-if="isMuteAllParticipantsModalVisible"
                    />
                    <ScreenshareSourcesPickerModal
                      v-if="screenshareSources.length"
                    />
                    <ErrorModal v-if="errorDialog" showCancelButton />
                    <InfoModal v-if="infoDialog" />
                    <Tour v-if="!isGuest" />
                    <LargeWhiteboardSessionModal
                      v-if="isWhiteboardLargeSessionModalVisible"
                    />
                    <DisableWaitingRoomModal
                      v-if="isDisableWaitingRoomModalVisible"
                    />
                    <InviteParticipantsModal
                      v-if="isInviteParticipantsDialogVisible"
                    />
                    <SettingsModal v-if="isSettingsModalVisible" />
                    <div
                      v-if="
                        layoutMode !== LAYOUT_MODE_TYPES.GRID ||
                        !showVideoStreams
                      "
                      class="reactions"
                    >
                      <ScreenReactions />
                    </div>
                  </div>
                  <div v-else class="layout-picker Vlt-bg-white">
                    <LayoutPicker />
                  </div>
                  <ReconnectingBanner
                    v-if="isNetworkIssues && !minimizedMode"
                  />
                  <DisabledDeviceBanner
                    v-if="shouldShowDeviceDisabledBanner"
                    :isMicDisabled="isMicrophonePausedSafari"
                    :isVideoDisabled="isVideoPausedSafari"
                  />
                  <ParticipantsTileList
                    :showOnlyMainStream="!showFullscreenElements"
                    :updateStreamSizesWrapper="updateStreamSizesWrapper"
                  />
                  <transition name="captions-fade-and-fly">
                    <!-- this captions-wrapper is for the captions toggle animation
                    because CaptionsView has its own animation on the same property
                    when bottom bar is floating -->
                    <div v-if="showCaptions" class="captions-wrapper">
                      <CaptionsView
                        :class="{
                          'bottom-bar-transition': isFloatingBottomBar
                        }"
                        :offset-y="captionsOffsetY"
                      />
                    </div>
                  </transition>
                </div>
              </div>
            </transition>
            <Sidebar v-if="!minimizedMode" />
          </div>
          <MeetingActions
            v-if="!minimizedMode && hasSessionEverConnected"
            ref="meetingActions"
            :class="{ 'bottom-bar-transition': isFloatingBottomBar }"
            :floating="isFloatingBottomBar"
            :show="showBottomBar"
            @mouseenter.native="hoveringActionButtons = true"
            @mouseleave.native="hoveringActionButtons = false"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { isElectron } from '@/helpers/global-helpers';
import ReconnectingBanner from '@/components/banners/ReconnectingBanner.vue';
import DisabledDeviceBanner from '@/components/banners/DisabledDeviceBanner.vue';
import NoConnectionBanner from '@/components/banners/NoConnectionBanner.vue';
import OldAccountBanner from '@/components/banners/OldAccountBanner.vue';
import ReportIssueModal from '@/components/modals/ReportIssueModal.vue';
import EndMeetingModal from '@/components/modals/EndMeetingModal.vue';
import ScreenshareSourcesPickerModal from '@/components/modals/ScreenshareSourcesPickerModal.vue';
import MeetingActions from '@/components/MeetingActions.vue';
import ParticipantsTileList from '@/components/ParticipantsTileList.vue';
import Tour from '@/components/Tour.vue';
import ConfirmationDialogModal from '@/components/modals/ConfirmationDialogModal.vue';
import LockMeetingModal from '@/components/modals/LockMeetingModal.vue';
import RemoveParticipantModal from '@/components/modals/RemoveParticipantModal.vue';
import HijackScreenshareModal from '@/components/modals/HijackScreenshareModal.vue';
import MuteAllParticipantsModal from '@/components/modals/MuteAllParticipantsModal.vue';
import ErrorModal from '@/components/modals/ErrorModal.vue';
import InfoModal from '@/components/modals/InfoModal.vue';
import SubscriberStats from '@/components/SubscriberStats.vue';
import ScreenReactions from '@/components/ScreenReactions.vue';
import LayoutPicker from '@/components/LayoutPicker.vue';
import Sidebar from '@/components/Sidebar.vue';
import WatchTogetherModal from '@/components/watchTogether/modal/WatchTogetherModal.vue';
import LargeWhiteboardSessionModal from '@/components/whiteboard/LargeWhiteboardSessionModal.vue';
import debounce from 'lodash.debounce';
import { LAYOUT_MODE_TYPES } from '@/consts/global-consts';
import TurnOnAudioModal from '@/components/modals/TurnOnAudioModal';
import throttle from 'lodash.throttle';
import CaptionsView from '@/components/CaptionsView.vue';
import DisableWaitingRoomModal from '@/components/modals/DisableWaitingRoomModal';
import analytics from '@/services/analytics-service';
import { ANALYTICS } from '@/consts/global-consts';
import { UNDO_WINDOW_DURATION } from '@/store/waitingRoom/consts';
import SelectLanguageModal from '@/components/modals/SelectLanguageModal';
import VirtualBackgroundModal from '@/components/modals/VirtualBackgroundModal';
import ReactionsCounterModal from '@/components/modals/ReactionsCounterModal.vue';
import InviteParticipantsModal from '@/components/modals/InviteParticipantsModal';
import SettingsModal from '@/components/modals/SettingsModal';

export default {
  name: 'AppScreen',

  components: {
    SettingsModal,
    InviteParticipantsModal,
    TurnOnAudioModal,
    MeetingActions,
    ReportIssueModal,
    SelectLanguageModal,
    ParticipantsTileList,
    NoConnectionBanner,
    OldAccountBanner,
    EndMeetingModal,
    ScreenshareSourcesPickerModal,
    ReconnectingBanner,
    ErrorModal,
    InfoModal,
    SubscriberStats,
    Tour,
    ConfirmationDialogModal,
    LockMeetingModal,
    RemoveParticipantModal,
    HijackScreenshareModal,
    MuteAllParticipantsModal,
    ScreenReactions,
    LayoutPicker,
    Sidebar,
    WatchTogetherModal,
    LargeWhiteboardSessionModal,
    CaptionsView,
    DisableWaitingRoomModal,
    VirtualBackgroundModal,
    ReactionsCounterModal,
    DisabledDeviceBanner
  },

  data() {
    return {
      isMouseOnIdle: false,
      hoveringActionButtons: false,
      isSpaceBarDown: false,
      debouncedUpdateMouseIdle: debounce(
        () => (this.isMouseOnIdle = true),
        2000
      ),
      updateStreamSizesWrapper: (updateStreamSizes) =>
        throttle(updateStreamSizes, 200, { leading: false }),
      LAYOUT_MODE_TYPES,
      UNDO_WINDOW_DURATION
    };
  },

  computed: {
    ...mapState([
      'isFullScreen',
      'streams',
      'isVideoEnabled',
      'hasSessionEverConnected',
      'isTurnOnAudioDialogVisible',
      'isEndMeetingDialogVisible',
      'isInviteParticipantsDialogVisible',
      'isNetworkIssues',
      'showSubscriberStats',
      'keepActionsBarUp',
      'isPTTOn',
      'confirmationDialog',
      'isLockMeetingDialogVisible',
      'participantToRemove',
      'isHijackScreenshareDialogVisible',
      'isMuteAllParticipantsModalVisible',
      'mainStreamId',
      'errorDialog',
      'infoDialog',
      'minimizedMode',
      'showReportIssueModal',
      'showSelectLanguageModal',
      'isVirtualBackgroundModalVisible',
      'isSettingsModalVisible',
      'isMicrophonePausedSafari',
      'isVideoPausedSafari'
    ]),
    ...mapState('screenshare', ['screenshareSources']),
    ...mapState('layout', ['layoutMode', 'isSidebarCollapsed']),
    ...mapState('watchTogether/modal', ['isWatchTogetherModalShown']),
    ...mapState('watchTogether/ongoing', ['isWatchTogetherActive']),
    ...mapState('whiteboard', [
      'isWhiteboardLargeSessionModalVisible',
      'isWhiteboardActive'
    ]),
    ...mapState('waitingRoom', [
      'waitingParticipants',
      'isApproveAllInProgress',
      'isDisableWaitingRoomModalVisible'
    ]),
    ...mapState('reactionsCounter', ['isReactionsCounterModalOpen']),
    ...mapGetters('waitingRoom', [
      'isWaitingRoomOwnerModalShown',
      'isWaitingRoomEmpty'
    ]),
    ...mapGetters([
      'isGuest',
      'isPublisherInMainStream',
      'showToggleAudioButtonPopper',
      'isOnline',
      'isOldAccount',
      'activeParticipants',
      'shouldShowDeviceDisabledBanner'
    ]),
    ...mapGetters('captions', ['showCaptions']),

    inSessionParticipants() {
      return this.activeParticipants;
    },

    showBottomBar() {
      return (
        this.hasSessionEverConnected &&
        (!this.isMouseOnIdle ||
          this.hoveringActionButtons ||
          this.keepActionsBarUp ||
          this.showToggleAudioButtonPopper ||
          this.isPTTOn)
      );
    },

    isFloatingBottomBar() {
      return (
        (this.isFullScreen || this.isActiveWhiteboardInSmallScreen) &&
        !this.isWatchTogetherActive &&
        this.isSidebarCollapsed
      );
    },

    isActiveWhiteboardInSmallScreen() {
      return (this.$screen.sm || this.$screen.md) && this.isWhiteboardActive;
    },

    showFullscreenElements() {
      return !(this.isFullScreen && this.isMouseOnIdle);
    },

    showSubscriberStatsWidget() {
      return (
        this.showSubscriberStats &&
        this.mainStreamId &&
        !this.isPublisherInMainStream
      );
    },

    showVideoStreams() {
      return (
        this.streams.length > 1 ||
        (this.isVideoEnabled && this.streams?.[0]?.hasVideo)
      );
    },

    captionsOffsetY() {
      return this.showBottomBar && this.isFloatingBottomBar
        ? this.$refs.meetingActions.$el.offsetHeight * -1
        : 0;
    }
  },

  mounted() {
    if (isElectron()) {
      window.addEventListener('beforeunload', this.closeMeeting);
    }
    this.setIsAppRunning(true);
    this.adobeTrack({ pageName: 'Welcome Screen' });
  },

  beforeDestroy() {
    if (isElectron()) {
      window.removeEventListener('beforeunload', this.closeMeeting);
    }
    this.setIsAppRunning(false);
  },

  methods: {
    ...mapActions([
      'setIsEndMeetingDialogVisible',
      'setIsAppRunning',
      'adobeTrack'
    ]),
    ...mapActions('layout', ['toggleSidebar']),
    ...mapActions('waitingRoom', {
      _approveAllWaitingRoomParticipants: 'approveAllWaitingRoomParticipants',
      _updateWaitingRoomParticipantStatus: 'updateWaitingRoomParticipantStatus',
      _viewWaitingRoom: 'viewWaitingRoom',
      _undoDeny: 'undoDeny'
    }),

    closeMeeting(event) {
      if (!this.isEndMeetingDialogVisible) {
        event.preventDefault();
        event.returnValue = '';
        this.setIsEndMeetingDialogVisible(true);
      }
    },

    updateIsMouseIdle() {
      this.isMouseOnIdle = false;
      this.debouncedUpdateMouseIdle();
    },

    viewWaitingRoom() {
      this._viewWaitingRoom({ source: 'Owner modal' });
    },

    async approveAllWaitingParticipants() {
      await this._approveAllWaitingRoomParticipants();

      analytics.trackEvent(ANALYTICS.WAITING_ROOM_RESPONSES, {
        Status: 'Approve all',
        Source: 'Owner modal'
      });
    },

    async updateWaitingParticipant({ participant, isApproval }) {
      await this._updateWaitingRoomParticipantStatus({
        participantId: participant.participantId,
        isApproval,
        source: 'Owner modal',
        isUndoAllowed: true
      });
    },

    async undoDeny({ participant }) {
      await this._undoDeny({ participantId: participant.participantId });
    }
  }
};
</script>

<style scoped>
.main-app {
  display: flex;
  width: 100vw;
}

.black-background {
  background: #1a1a1a;
}

.loading-text {
  margin-left: 12px;
  margin-top: 10px;
}

.main-screen {
  flex: 1;
  height: 100vh;
  width: 100%;
  position: relative;
}

.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #e7ebee;
  font-size: 20px;
  font-weight: 600;
}

.main-screen:not(.minimized-mode) .screen-container {
  padding-top: 40px;
}

.screen-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.screen-wrapper {
  display: flex;
  min-height: 0;
  flex: 1;
  height: 100%;
  width: 100%;
}

.videos-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 0;
}

.speaker-view .screen-container .screen-wrapper .videos-wrapper {
  align-items: flex-end;
}

.no-cursor {
  cursor: none;
}

.disabled-speaker {
  position: relative;
  z-index: 1;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.speaker-view
  .screen-container
  .screen-wrapper
  .videos-wrapper.sidebar-collapsed {
  margin: 0 auto;
  position: relative;
}

.reactions {
  position: absolute;
  bottom: 0%;
  left: 0;
  height: 90%;
  width: 0;
  z-index: 2;
  overflow: visible;
}

.layout-picker {
  width: 100%;
  -webkit-app-region: drag;
}

.main-area-container {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.fade-enter-active {
  transition: opacity 1s;
}

.fade-enter {
  opacity: 0;
}

.waiting-room-owner-modal-container {
  z-index: 9003;
  align-self: center;
}

.waiting-room-owner-modal {
  align-self: center;
  box-shadow: 0px 6px 8px 2px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 12px 16px rgba(0, 0, 0, 0.25))
    drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.25));
  margin-top: 12px;
}

.waiting-room-modal-fade-and-fly-enter-active,
.waiting-room-modal-fade-and-fly-leave-active {
  transition: transform 0.4s, opacity 0.5s ease-in;
  z-index: 4;
}

.waiting-room-empty .waiting-room-modal-fade-and-fly-leave-active {
  transition-delay: 300ms;
}

.minimized-mode .waiting-room-modal-fade-and-fly-leave-active {
  transition: none;
}

.waiting-room-modal-fade-and-fly-leave-to,
.waiting-room-modal-fade-and-fly-enter {
  opacity: 0;
  transform: translateY(-100px);
}

.bottom-bar-transition {
  transition: transform 0.3s;
}

.captions-wrapper {
  position: relative;
  width: 100%;
  z-index: 1;
}

.captions-fade-and-fly-enter-active {
  transition: opacity 0.4s ease, transform 0.3s;
}

.captions-fade-and-fly-leave-active {
  transition: opacity 0.2s ease, transform 0.3s;
}

.captions-fade-and-fly-leave-to,
.captions-fade-and-fly-enter {
  opacity: 0;
  transform: translateY(100%);
}

.reactions-counter-modal {
  margin-top: 10px;
  z-index: 9002;
  align-self: center;
}
</style>
