/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-sidenav-only': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.75 1.5a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h22.5a.75.75 0 00.75-.75V2.25a.75.75 0 00-.75-.75H.75zM1.5 21V3h21v18h-21zM3.75 4.5a.75.75 0 00-.75.75v13.5c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75V5.25a.75.75 0 00-.75-.75h-4.5zM4.5 18V6h3v12h-3z"/>'
  }
})
