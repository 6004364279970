import getInitialState from './state';

export default {
  SET_SHOW_VIDEO_SYNC_OVERLAY: (state, isWatchTogetherModalShown) => {
    state.isWatchTogetherModalShown = isWatchTogetherModalShown;
  },

  SET_IS_VIDEO_LINK_VALID: (state, isValid) => {
    state.isVideoUrlValid = isValid;
  },

  SET_VALIDATION_ERROR_REASON: (state, errorReason) => {
    state.urlValidationErrorReason = errorReason;
  },

  SET_IS_LOADING_VIDEO_METADATA: (state, isLoading) => {
    state.isLoadingVideoMetadata = isLoading;
  },

  UPDATE_VIDEO_METADATA: (state, metadata) => {
    state.videoMetadata = metadata;
  },

  RESET_VIDEO_SYNC_STATE: (state) => {
    Object.assign(state, getInitialState());
  }
};
