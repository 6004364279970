/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-play-3-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0 2C5.373 24 0 18.627 0 12S5.373 0 12 0s12 5.373 12 12-5.373 12-12 12zm-3.5-8.49c0 1.242.98 1.856 2.011 1.261l6.206-3.574c1.044-.601 1.044-1.793 0-2.395L10.51 7.228C9.481 6.635 8.5 7.251 8.5 8.49v7.021z"/>'
  }
})
