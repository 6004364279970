export default {
  UPDATE_STANDUP: (state, data) => {
    state.isStandupOn = data.isStandupOn;
    state.currentSpeaker = data.currentSpeaker;
    state.pastSpeakers = data.pastSpeakers;
  },

  SET_WAS_NEXT_RECENTLY_CLICKED: (state, wasNextRecentlyClicked) => {
    state.wasNextRecentlyClicked = wasNextRecentlyClicked;
  },

  SET_IS_PLAYING_FOCUS_ANIMATION: (state, isPlayingFocusAnimation) => {
    state.isPlayingFocusAnimation = isPlayingFocusAnimation;
  }
};
