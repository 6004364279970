/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-profile-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm6.15 18.45c-.6-1.05-1.8-1.5-3.15-1.95-1.05-.3-1.35-.9-1.5-1.8 1.65-.6 3-2.25 3-4.2C16.5 7.95 14.55 6 12 6s-4.5 1.95-4.5 4.5c0 1.95 1.2 3.6 3 4.2-.15.9-.45 1.5-1.5 1.8-1.35.45-2.55.9-3.15 1.95C4.05 16.8 3 14.55 3 12c0-4.95 4.05-9 9-9s9 4.05 9 9c0 2.55-1.05 4.8-2.85 6.45z"/>'
  }
})
