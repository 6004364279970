/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-ai-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.25 8.25a3.71 3.71 0 00-1.363.265l-3.402-3.402a3.71 3.71 0 00.265-1.363 3.75 3.75 0 00-7.5 0c.002.467.092.93.265 1.364L5.113 8.514A3.71 3.71 0 003.75 8.25a3.75 3.75 0 100 7.5 3.71 3.71 0 001.364-.265l3.401 3.402a3.71 3.71 0 00-.265 1.363 3.75 3.75 0 007.5 0 3.71 3.71 0 00-.265-1.363l3.402-3.402a3.71 3.71 0 001.363.265 3.75 3.75 0 000-7.5zM12 16.5a3.71 3.71 0 00-1.364.265l-3.401-3.402a3.635 3.635 0 000-2.726l3.401-3.403a3.635 3.635 0 002.728 0l3.401 3.402a3.634 3.634 0 000 2.728l-3.402 3.401A3.71 3.71 0 0012 16.5z"/>'
  }
})
