/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-media-fullscreen': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.429 15.429H0V24h8.571v-3.429H3.43V15.43zM0 8.57h3.429V3.43H8.57V0H0v8.571zm20.571 12H15.43V24H24v-8.571h-3.429v5.142zM15.43 0v3.429h5.142V8.57H24V0h-8.571z"/>'
  }
})
