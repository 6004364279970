/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-camera-switch-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.75 5.25h3.75c.9 0 1.5.6 1.5 1.5v15c0 .9-.6 1.5-1.5 1.5h-21c-.9 0-1.5-.6-1.5-1.5v-15c0-.9.6-1.5 1.5-1.5h3.75l2.55-3.9c.3-.3.75-.6 1.2-.6h6c.45 0 .9.3 1.2.6l2.55 3.9zm-9.563 5.7h3.75L9.188 7.2v1.875A4.693 4.693 0 004.5 13.763a4.693 4.693 0 004.688 4.687v-1.875a2.816 2.816 0 01-2.813-2.812 2.816 2.816 0 012.813-2.813zm5.626-1.875v1.875a2.816 2.816 0 012.812 2.813 2.816 2.816 0 01-2.813 2.812h-3.75l3.75 3.75V18.45a4.693 4.693 0 004.688-4.687 4.693 4.693 0 00-4.688-4.688z"/>'
  }
})
