/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mailbox-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M21 8.25l-9 5.1-9-5.1V1.5C3 .6 3.6 0 4.5 0h15c.9 0 1.5.6 1.5 1.5v6.75zm-9.75 8.1c.3.15.45.15.75.15s.45 0 .75-.15L24 10.05V22.5c0 .9-.6 1.5-1.5 1.5h-21C.6 24 0 23.4 0 22.5V10.05l11.25 6.3z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
