var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "watch-together-modal",
      attrs: {
        "data-cy": "watch-together-modal",
        heading: _vm.$t("watch_together_modal.title"),
        "icon-name": "file-video-2-line",
        close: function (confirm) {
          return confirm ? _vm.start() : _vm.closeWatchTogetherModal()
        },
        primaryButtonConnotation: "cta",
        "min-width": 460,
        "primary-action": _vm.$t("watch_together_modal.start_button"),
        primaryButtonIcon: "play-solid",
        disablePrimaryButton: !_vm.isVideoUrlValid,
      },
      on: { applied: _vm.start },
    },
    [
      _c("div", { attrs: { slot: "content" }, slot: "content" }, [
        _c(
          "div",
          { staticClass: "form" },
          [
            _c("div", { staticClass: "description" }, [
              _c("div", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("watch_together_modal.description_part1")
                  ),
                },
              }),
              _c("div", [
                _vm._v(
                  _vm._s(_vm.$t("watch_together_modal.description_part2"))
                ),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "video-link-row" },
              [
                _c("div", { staticClass: "input-label" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("watch_together_modal.video_url_label")) +
                      " "
                  ),
                ]),
                _c("v-input", {
                  staticClass: "input-box video-link-input",
                  attrs: {
                    type: "text",
                    placeholder: "https://www.youtube.com/example",
                    big: "",
                    preservePlaceForError: "",
                    focus: "",
                    errorMessage: _vm.urlValidationError,
                  },
                  on: { textChanged: _vm.onVideoUrlChanged },
                  model: {
                    value: _vm.videoUrl,
                    callback: function ($$v) {
                      _vm.videoUrl = $$v
                    },
                    expression: "videoUrl",
                  },
                }),
              ],
              1
            ),
            _vm.videoMetadata
              ? _c(
                  "div",
                  { staticClass: "video-preview" },
                  [
                    _c("WatchTogetherVideoPreview", {
                      attrs: {
                        videoMetadata: _vm.videoMetadata,
                        isLoadingVideoMetadata: _vm.isLoadingVideoMetadata,
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "vwc-empty-state",
                  {
                    staticClass: "empty-state",
                    attrs: {
                      heading: _vm.$t("watch_together_modal.empty_state_title"),
                      body: _vm.$t("watch_together_modal.empty_state_subtitle"),
                    },
                  },
                  [
                    _c("img", {
                      attrs: { slot: "graphic", src: _vm.emptyStateImg },
                      slot: "graphic",
                    }),
                  ]
                ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }