/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vonage-lettermark': {
    width: 230,
    height: 200,
    viewBox: '0 0 230 200',
    data: '<path pid="0" _fill="#131415" d="M45.34 0H0l64.68 146.958c.495 1.123 2.092 1.112 2.57-.016l21.513-50.605L45.34 0zM183.45 0s-69.494 159.156-78.802 173.833c-10.819 17.063-17.989 23.576-31.257 25.663a.253.253 0 00-.214.25c0 .14.114.254.254.254h41.524c17.988 0 30.96-15.021 38.15-28.286C161.274 156.637 229.59 0 229.59 0h-46.14z"/>'
  }
})
