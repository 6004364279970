/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-drag': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 3a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zM7.5 0a3 3 0 110 6 3 3 0 010-6zm0 10.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm3 1.5a3 3 0 10-6 0 3 3 0 006 0zm-3 7.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm3 1.5a3 3 0 10-6 0 3 3 0 006 0zm5.25-10.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm3 1.5a3 3 0 10-6 0 3 3 0 006 0zm-1.5 9a1.5 1.5 0 10-3 0 1.5 1.5 0 003 0zm-1.5-3a3 3 0 110 6 3 3 0 010-6zm0-16.5a1.5 1.5 0 110 3 1.5 1.5 0 010-3zm3 1.5a3 3 0 10-6 0 3 3 0 006 0z"/>'
  }
})
