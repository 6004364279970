var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "subscriber-stats-container" }, [
    _c("div", { staticClass: "title" }, [
      _vm._v(_vm._s(_vm.$t("subscriber_stats.title"))),
    ]),
    _vm._v(
      " " +
        _vm._s(
          _vm.$t("subscriber_stats.stream_id", {
            mainStreamId: _vm.mainStreamId,
          })
        )
    ),
    _c("br"),
    _vm._v(
      " " +
        _vm._s(
          _vm.$t("subscriber_stats.audio_bitrate", {
            bitRates: _vm.bitrates.audio,
          })
        )
    ),
    _c("br"),
    _vm._v(
      " {{ $t('subscriber_stats.video_bitrate', { bitRates: bitrates.video }) "
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }