<template>
  <div class="switch" @click="onClick">
    <slot />
    <div
      class="Vlt-switch"
      :class="{
        'Vlt-switch--unchecked-red': uncheckedRed,
        'Vlt-switch--red': red,
        'Vlt-switch--green': green,
        'purple-switch': purple,
        'Vlt-switch--small': sm
      }"
    >
      <label>
        <input type="checkbox" :checked="checked" :disabled="disabled" />
        <!-- Without @click.stop, click on switch will trigger 2 click events, one for the input
        and one for span.Vlt-switch__slider -->
        <span
          class="Vlt-switch__slider"
          :class="{ disabled: disabled }"
          @click.stop
        ></span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VSwitch',

  props: {
    sm: {
      type: Boolean,
      default: false
    },
    red: {
      type: Boolean,
      default: false
    },
    uncheckedRed: {
      type: Boolean,
      default: false
    },
    green: {
      type: Boolean,
      default: false
    },
    checked: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      defualt: false
    },
    purple: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('update:checked', !this.checked);
      }
    }
  }
};
</script>

<style scoped>
.Vlt-switch.Vlt-switch--green input:checked ~ .Vlt-switch__slider {
  background-color: #35be8b;
}

.Vlt-switch.Vlt-switch--unchecked-red
  input:not(:checked)
  ~ .Vlt-switch__slider {
  background-color: #f25a6b;
}

.Vlt-switch.purple-switch input:checked ~ .Vlt-switch__slider {
  background-color: var(--switch-primary-checked-color);
}

.Vlt-switch input ~ .Vlt-switch__slider.disabled {
  cursor: default;
  opacity: 0.5;
  background-color: #678898;
}

.switch {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
</style>
