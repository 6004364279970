var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ParticipantView", {
    class: {
      screenshare: _vm.isScreenshare,
      "has-video": _vm.hasVideo,
      "has-raised-hand": _vm.isParticipantRaisingHand,
      mirrored: _vm.mirrored,
      tall: _vm.isStreamTall,
    },
    style: Object.assign({}, _vm.streamAspectRatioVariables),
    attrs: {
      participantId: _vm.streamParticipant.participantId,
      participantType: _vm.streamParticipant.type,
      participantProfilePicture: _vm.streamParticipant.profilePicture,
      stream: _vm.stream,
      preferred: _vm.preferred,
      canBePinned: _vm.canBePinned,
      canShowContent: _vm.canShowVideo,
      isLoading: _vm.isSubscriberReconnecting,
      displayName: _vm.displayName,
      isMainParticipant: _vm.isMainStream,
      isPinned: _vm.isPinned,
      showSpeakerIndication: _vm.showSpeakerIndication,
      speakerIndicationSize: _vm.speakerIndicationSize,
      isBeRightBackOn: _vm.isBeRightBackOn,
      iconNextToDisplayName: _vm.iconNextToDisplayName,
    },
    on: { pinToggled: _vm.setIsPinned },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("VideoStream", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.canShowVideo,
                  expression: "canShowVideo",
                },
              ],
              attrs: {
                stream: _vm.stream,
                preferred: _vm.preferred,
                subscriberStatus: _vm.subscriberStatus,
                isPageVisible: _vm.isPageVisible,
                preferredFitMode: _vm.preferredFitMode,
                canShowVideo: _vm.canShowVideo,
                isMainStream: _vm.isMainStream,
              },
            }),
            _vm.layoutMode !== _vm.LAYOUT_MODE_TYPES.GRID &&
            _vm.isMainStream &&
            _vm.hasBadQuality &&
            _vm.hasVideo &&
            !_vm.minimizedMode
              ? _c("VideoDisabledBanner", {
                  staticClass: "video-disabled-issue-message",
                })
              : _vm._e(),
            _c("transition", { attrs: { name: "reactions" } }, [
              _vm.showReactionOnVideoStream
                ? _c("img", {
                    staticClass: "reaction-img",
                    attrs: { src: _vm.activeReactionSrc },
                  })
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "prepended-name-icons",
        fn: function () {
          return [
            _vm.isSubscriberReconnecting
              ? _c(
                  "div",
                  { staticClass: "subscriber-reconnecting-icon" },
                  [
                    _c("vwc-icon", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.top",
                          value: _vm.subscriberReconnectingTooltip,
                          expression: "subscriberReconnectingTooltip",
                          modifiers: { top: true },
                        },
                      ],
                      staticClass: "icon",
                      attrs: { type: "warning-solid" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            !_vm.stream.isPublisher && _vm.hasBadQuality
              ? _c(
                  "div",
                  { staticClass: "low-connectivity-icon" },
                  [
                    _c("vwc-icon", {
                      staticClass: "icon",
                      attrs: { type: "wifi-solid" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }