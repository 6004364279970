/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-more-detailed-list-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 0H0v4.5h24V0zm0 9H0v4.5h24V9zM0 18h24v4.5H0V18z"/>'
  }
})
