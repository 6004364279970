/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-merge-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M13.06.44l7.06 7.06L18 9.62l-4.5-4.5v12l-6.44 6.44-2.12-2.12 5.56-5.561V5.12L6 9.62 3.879 7.5l7.06-7.06a1.498 1.498 0 012.121 0zm1.061 20.302l2.122-2.122 2.818 2.819-2.122 2.12-2.818-2.817z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
