/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-up': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.559 8.06a.75.75 0 01.882 0l7.333 5.333a.75.75 0 01-.882 1.213L12 9.594l-6.892 5.012a.75.75 0 01-.883-1.213L11.56 8.06z"/>'
  }
})
