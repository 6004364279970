/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-address-book-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 0h16.5c1.65 0 3 1.35 3 3v18c0 1.65-1.35 3-3 3H0V0zm4.5 18.15v-2.625S5.813 12.9 9.75 12.9c3.938 0 5.25 2.625 5.25 2.625v2.625H4.5zm5.25-7.35a2.625 2.625 0 100-5.25 2.625 2.625 0 000 5.25zM24 3h-3v4.5h3V3zm0 6h-3v4.5h3V9z"/>'
  }
})
