<template>
  <div>
    <vwc-icon-button
      v-tooltip.bottom-end="tooltip"
      data-cy="locale-switcher-button"
      icon="globe-solid"
      dense
      :class="showThankYouScreen ? 'vvd-scheme-alternate' : ''"
      @click="openSelectLanguageModal"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  name: 'LocaleSwitcher',

  computed: {
    ...mapState(['showThankYouScreen']),
    tooltip() {
      return {
        hideOnTargetClick: true,
        content: this.$t('locale_switcher.tooltip')
      };
    }
  },

  methods: {
    ...mapActions(['setShowSelectLanguageModal']),

    openSelectLanguageModal() {
      this.setShowSelectLanguageModal(true);
    }
  }
};
</script>
