var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-container", style: _vm.animationDurationOverrides },
    [
      _c("div", { staticClass: "loader-wrapper" }, [
        _vm._m(0),
        _c("div", { staticClass: "icons-container" }, [
          _c("div", { staticClass: "item top-item top-left-item" }, [
            _c(
              "div",
              { staticClass: "icon-wrapper" },
              [
                _c("vwc-icon", {
                  staticClass: "icon primary-icon",
                  attrs: { type: "user-line" },
                }),
                _c("vwc-icon", {
                  staticClass: "icon alternate-icon",
                  attrs: { type: "chat-line" },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "item top-item top-right-item" }, [
            _c(
              "div",
              { staticClass: "icon-wrapper" },
              [
                _c("vwc-icon", {
                  staticClass: "icon primary-icon",
                  attrs: { type: "user-line" },
                }),
                _c("v-icon", {
                  staticClass: "icon alternate-icon",
                  attrs: { iconName: "video-whiteboard" },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "item bottom-left-item" }, [
            _c(
              "div",
              { staticClass: "icon-wrapper" },
              [
                _c("vwc-icon", {
                  staticClass: "icon primary-icon",
                  attrs: { type: "user-line" },
                }),
                _c("vwc-icon", {
                  staticClass: "icon alternate-icon",
                  attrs: { type: "emoji-line" },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "item bottom-right-item" }, [
            _c(
              "div",
              { staticClass: "icon-wrapper" },
              [
                _c("vwc-icon", {
                  staticClass: "icon primary-icon",
                  attrs: { type: "user-line" },
                }),
                _c("vwc-icon", {
                  staticClass: "icon alternate-icon",
                  attrs: { type: "screen-share-line" },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "loader-wrapper-top-bar" }, [
      _c("div", { staticClass: "buttons" }, [
        _c("div", { staticClass: "close" }),
        _c("div", { staticClass: "minimize" }),
        _c("div", { staticClass: "maximize" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }