/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-pause-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.26.158c-.413-.179-.849-.2-1.273-.093L3.94.077 2.713.539C1.667.933.903 1.739.387 2.827l-.005.012c-.464 1.018-.481 2.21-.182 3.5.292 1.257.788 2.549 1.476 3.871a29.835 29.835 0 002.381 3.826c.9 1.233 1.788 2.294 2.665 3.178.876.885 1.928 1.78 3.15 2.69a29.499 29.499 0 003.793 2.403c1.311.695 2.593 1.196 3.841 1.49 1.28.303 2.468.286 3.482-.184l.012-.006c1.082-.521 1.88-1.293 2.268-2.344l.457-1.236.012-.048a1.983 1.983 0 00-.091-1.27c-.19-.449-.554-.772-.937-1.01-.338-.24-1.17-.783-2.466-1.61-.71-.453-1.382-.791-2.007-.984-.624-.192-1.257-.256-1.85-.09l-.021.007c-.81.25-1.56.643-1.954 1.317-.052.009-.173.014-.393-.034-.454-.104-1.082-.434-1.896-1.105-.854-.704-1.455-1.237-1.821-1.606-.347-.35-.87-.948-1.58-1.811-.676-.822-.988-1.447-1.074-1.891l-.004-.021c-.05-.234-.043-.361-.033-.414.677-.402 1.051-1.167 1.276-1.98.346-1.161-.175-2.517-1.046-3.889-.85-1.337-1.371-2.16-1.566-2.465C6.039.73 5.715.355 5.26.158z"/><path pid="1" d="M12.657.005h4.113v9.244h-4.113V.005zM19.767.005h4.112v9.244h-4.113V.005z"/>'
  }
})
