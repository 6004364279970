/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'gallery-icon': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<rect pid="0" width="9" height="9" rx="1" _fill="#131415"/><rect pid="1" y="11" width="9" height="9" rx="1" _fill="#131415"/><rect pid="2" x="11" y="11" width="9" height="9" rx="1" _fill="#131415"/><rect pid="3" x="11" width="9" height="9" rx="1" _fill="#131415"/>'
  }
})
