/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cross': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.813 2.752a.75.75 0 00-1.06 1.06L10.938 12l-8.187 8.187a.75.75 0 101.06 1.06L12 13.062l8.187 8.187a.75.75 0 101.06-1.06L13.062 12l8.187-8.187a.75.75 0 00-1.06-1.06L12 10.938 3.813 2.752z"/>'
  }
})
