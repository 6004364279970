var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.inSessionParticipants.length
    ? _c(
        "div",
        [
          _c("transition", { attrs: { name: "fade" } }, [
            _vm.waitingParticipantsCount > 0
              ? _c(
                  "div",
                  {
                    staticClass: "waiting-participants-wrapper",
                    attrs: { "data-cy": "waiting-participants-wrapper" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "waiting-participants-header" },
                      [
                        _c(
                          "vwc-text",
                          {
                            staticClass:
                              "waiting-participants-text Vlt-black unselectable",
                            attrs: {
                              "font-face": "body-2-bold",
                              "data-cy": "waiting-participants-text",
                            },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t(
                                    "participants_list.waiting_participants_text",
                                    {
                                      waitingParticipantsCount:
                                        _vm.waitingParticipantsCount,
                                    }
                                  )
                                ) +
                                " "
                            ),
                          ]
                        ),
                        _c(
                          "vwc-button",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.waitingParticipantsCount > 1,
                                expression: "waitingParticipantsCount > 1",
                              },
                            ],
                            staticClass: "approve-all-button",
                            attrs: {
                              "data-cy": "approve-all-button",
                              label: _vm.$t(
                                "participants_list.approve_all_button"
                              ),
                              layout: "filled",
                              connotation: "cta",
                              dense: "",
                              disabled: _vm.isApproveAllInProgress,
                            },
                            on: {
                              click: _vm.approveAllWaitingRoomParticipants,
                            },
                          },
                          [
                            _vm.isApproveAllInProgress
                              ? _c("vwc-circular-progress", {
                                  staticClass: "button-loading-spinner",
                                  attrs: { indeterminate: "", density: "-7" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "waiting-participants",
                        attrs: { "data-cy": "waiting-participants" },
                      },
                      [
                        _c(
                          "transition-group",
                          { attrs: { name: "waiting-room-list-transition" } },
                          _vm._l(_vm.waitingParticipants, function (
                            participant
                          ) {
                            return _c("WaitingRoomParticipantRow", {
                              key: participant.participantId,
                              staticClass:
                                "waiting-room-participant-row-container",
                              attrs: {
                                participant: participant,
                                disableButtons:
                                  _vm.isApproveAllInProgress ||
                                  participant.isApproveInProgress ||
                                  participant.isDenyInProgress,
                                maxDisplayNameLength: 20,
                                undoWindowDuration: _vm.UNDO_WINDOW_DURATION,
                                denyButtonLabel: _vm.$t(
                                  "participants_list.waiting_room_deny_button"
                                ),
                                approveButtonLabel: _vm.$t(
                                  "participants_list.waiting_room_approve_button"
                                ),
                                undoButtonLabel: _vm.$t(
                                  "participants_list.waiting_room_undo_button"
                                ),
                              },
                              on: {
                                updateParticipantStatus:
                                  _vm.updateWaitingRoomParticipantStatus,
                                undoDeny: _vm.undoDeny,
                              },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]),
          _c(
            "vwc-expansion-panel",
            {
              staticClass: "participants-accordion",
              attrs: {
                "data-cy": "active-participants-panel",
                header: _vm.$t(
                  "participants_list.active_participants_panel_header",
                  {
                    inSessionParticipantsCount:
                      "" + _vm.inSessionParticipants.length,
                  }
                ),
                open: "",
                leadingtoggle: "",
                heading: "",
                dense: "",
                icon: "",
                indicatoriconset: "chevron",
              },
            },
            [
              _c(
                "div",
                { staticClass: "active-participants" },
                _vm._l(_vm.inSessionParticipants, function (participant) {
                  return _c("ParticipantRow", {
                    key: participant.participantId,
                    class: {
                      "my-participant":
                        participant.participantId === _vm.myParticipantId,
                      "owner-participant":
                        !_vm.isSessionOwner &&
                        participant.participantId === _vm.owner,
                      "raised-hand-participant":
                        participant.participantId in _vm.raisedHands,
                    },
                    attrs: {
                      participant: participant,
                      micVolume:
                        _vm.participantsVolumes[participant.participantId],
                    },
                  })
                }),
                1
              ),
            ]
          ),
          _vm.offSessionParticipants.length
            ? [
                _c(
                  "vwc-expansion-panel",
                  {
                    staticClass: "participants-accordion",
                    attrs: {
                      "data-cy": "inactive-participants-panel",
                      header: _vm.$t(
                        "participants_list.inactive_participants_panel_header",
                        {
                          offSessionParticipantsCount:
                            "" + _vm.offSessionParticipants.length,
                        }
                      ),
                      leadingtoggle: "",
                      heading: "",
                      dense: "",
                      icon: "",
                      indicatoriconset: "chevron",
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "inactive-participants" },
                      _vm._l(_vm.offSessionParticipants, function (
                        participant
                      ) {
                        return _c("ParticipantRow", {
                          key: participant.participantId,
                          attrs: { participant: participant, micVolume: 0 },
                        })
                      }),
                      1
                    ),
                  ]
                ),
              ]
            : _vm._e(),
        ],
        2
      )
    : _c(
        "div",
        { staticClass: "spinner-container unselectable" },
        [
          _c("v-spinner"),
          _c("div", [
            _vm._v(
              _vm._s(_vm.$t("participants_list.loading_participants_text"))
            ),
          ]),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }