export default {
  isWhiteboardOwner: (state, getters, rootState) =>
    state.whiteboardOwnerParticipantId === rootState.myParticipantId,

  whiteboardOwnerParticipant: (state, getters, rootState) =>
    rootState.participants[state.whiteboardOwnerParticipantId],

  whiteboardOwnerName: (state, getters) => {
    const ownerParticipant = getters.whiteboardOwnerParticipant;
    if (!ownerParticipant) {
      return 'Another participant';
    }

    return ownerParticipant.displayName;
  },

  isWhiteboardPinned: (state, getters, rootState) =>
    rootState.pinnedStreamId === 'whiteboard'
};
