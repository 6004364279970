/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-xml-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.998 16.333l-.668-.653-.014-8.017.682-.658v9.328z" _fill="#803300"/><path pid="1" d="M23.33 7.663H.658v8.02H23.33v-8.02z" _fill="#F60"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M0 7l24 .003-.679.658-22.655.008L0 7z" _fill="#FCA"/><path pid="3" fill-rule="evenodd" clip-rule="evenodd" d="M.003 16.333L24 16.336l-.671-.658H.659l-.656.655z" _fill="#520"/><path pid="4" fill-rule="evenodd" clip-rule="evenodd" d="M.664 15.672L0 16.334V7l.66.66.004 8.013z" _fill="#F95"/><path pid="5" d="M3.211 9.578h1.95l1.015 1.762.984-1.762h1.93L7.31 12.352l1.949 2.953H7.27l-1.13-1.84-1.132 1.84H3.032l1.976-2.985-1.797-2.742zm6.594 0h2.328l.898 3.484.895-3.484h2.32v5.727h-1.445v-4.368l-1.121 4.368H12.37l-1.117-4.368v4.368H9.805V9.578zm7.566 0h1.77v4.316h2.762v1.41H17.37V9.579z" _fill="#803300"/><path pid="6" d="M2.828 8.96h1.95l1.015 1.761.984-1.762h1.93l-1.781 2.774 1.949 2.953H6.887l-1.13-1.84-1.132 1.84H2.65l1.976-2.984-1.797-2.743zm6.594 0h2.328l.898 3.484.895-3.485h2.32v5.727h-1.445v-4.367l-1.121 4.367h-1.309l-1.117-4.367v4.367H9.422V8.959zm7.566 0h1.77v4.316h2.762v1.41h-4.532V8.959z" _fill="#fff"/>'
  }
})
