/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-reload-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.25 21C6.75 21 3 17.25 3 12.75S6.75 4.5 11.25 4.5c1.8 0 3.3.6 4.8 1.5H12v3h9V0h-3v3.75c-1.95-1.5-4.2-2.25-6.75-2.25C5.1 1.5 0 6.6 0 12.75S5.1 24 11.25 24 22.5 18.9 22.5 12.75h-3c0 4.5-3.75 8.25-8.25 8.25z"/>'
  }
})
