/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-audio-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.002 0h18a1 1 0 011 1v22a1 1 0 01-1 1h-18a1 1 0 01-1-1V1a1 1 0 011-1zm8.695 13.377V5.25l5.03 2.516-.827 1.65-2.357-1.178v7.741a2.77 2.77 0 11-2.77-2.77c.315.002.628.06.924.168z"/>'
  }
})
