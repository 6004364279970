/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.89 10.188c-1.785 0-3.234-2.168-3.234-3.954a3.235 3.235 0 016.469 0c0 1.786-1.448 3.954-3.234 3.954zM7.548 11.905c2.183 0 3.953-2.488 3.953-4.672a3.953 3.953 0 10-7.906 0c0 2.184 1.77 4.672 3.953 4.672zm14.655.53c-.927-.37-2.428-.811-4.311-.811-1.388 0-2.566.24-3.462.514 2.08.95 3.102 2.777 3.102 4.65v.586h6.47v-2.273a2.85 2.85 0 00-1.799-2.666zm-14.655.908c2.51 0 4.397.662 5.538 1.218a3.607 3.607 0 012.009 3.227v2.742H0V17.79c0-1.365.788-2.633 2.009-3.227 1.14-.556 3.027-1.218 5.538-1.218z"/>'
  }
})
