/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-hoteling-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 0h-21C.6 0 0 .6 0 1.5v21c0 .9.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5v-21c0-.9-.6-1.5-1.5-1.5zm-3 19.5h-3v-6h-9v6h-3v-15h3v6h9v-6h3v15z"/>'
  }
})
