/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-laptop-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 13.5h3V6a3.01 3.01 0 00-3-3h-15a3.009 3.009 0 00-3 3v7.5h3V6h15v7.5zM3 21h18a3.01 3.01 0 003-3v-1.5H0V18a3.009 3.009 0 003 3z"/>'
  }
})
