/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-plug': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.733.268a.682.682 0 010 .964L21.15 3.817a5.932 5.932 0 01-.45 7.871l-1.456 1.532a.682.682 0 01-.977.012L10.805 5.77a.682.682 0 010-.965l1.5-1.5a5.932 5.932 0 017.88-.453L22.77.268a.682.682 0 01.964 0zm-4.016 3.99a4.568 4.568 0 00-6.448.012l-1.018 1.018 6.486 6.486.981-1.031.012-.013a4.569 4.569 0 00-.013-6.473zm-9.486 7.474a.682.682 0 00-.965-.964L7.5 12.536l-1.767-1.768a.682.682 0 00-.977.012L3.3 12.312a5.932 5.932 0 00-.45 7.872L.269 22.768a.682.682 0 00.964.964l2.583-2.584a5.932 5.932 0 007.88-.453l1.5-1.5a.682.682 0 000-.964l-1.73-1.731 1.767-1.768a.682.682 0 10-.964-.964L10.5 15.536 8.464 13.5l1.767-1.768zm-3.224 2.239l.011.011.012.012 2.977 2.977.011.011.012.012 1.719 1.719-1.018 1.018a4.568 4.568 0 01-6.46-6.461l.011-.012.98-1.031 1.745 1.744z"/>'
  }
})
