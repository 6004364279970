/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-devices': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.5 6H12V1.5c0-.4-.2-.8-.4-1.1-.3-.2-.7-.4-1.1-.4h-9C1.1 0 .7.2.4.4S0 1.1 0 1.5v12c0 .4.2.8.4 1.1.3.2.7.4 1.1.4h4c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-4c-.1 0-.3-.1-.4-.1 0-.1-.1-.3-.1-.4v-12c0-.1.1-.3.1-.4.1 0 .3-.1.4-.1h9c.1 0 .3.1.4.1 0 .1.1.3.1.4V6H9.5C8.7 6 8 6.7 8 7.5v7c0 .8.7 1.5 1.5 1.5h5c.8 0 1.5-.7 1.5-1.5v-7c0-.8-.7-1.5-1.5-1.5zm.5 8.5c0 .3-.2.5-.5.5h-5c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5v7z"/>'
  }
})
