/* eslint-disable */
require('./Brand-icon-android-color')
require('./Brand-icon-android')
require('./Brand-icon-angular-color')
require('./Brand-icon-angular')
require('./Brand-icon-apple-color')
require('./Brand-icon-apple')
require('./Brand-icon-blogger-color')
require('./Brand-icon-blogger')
require('./Brand-icon-bullhorn-color')
require('./Brand-icon-bullhorn')
require('./Brand-icon-chime-color')
require('./Brand-icon-chime')
require('./Brand-icon-clio-color')
require('./Brand-icon-clio')
require('./Brand-icon-curl-color')
require('./Brand-icon-curl')
require('./Brand-icon-dotnet-color')
require('./Brand-icon-dotnet')
require('./Brand-icon-drupal-color')
require('./Brand-icon-drupal')
require('./Brand-icon-dynamics-color')
require('./Brand-icon-dynamics')
require('./Brand-icon-express-color')
require('./Brand-icon-express')
require('./Brand-icon-facebook-color')
require('./Brand-icon-facebook')
require('./Brand-icon-github-color')
require('./Brand-icon-github')
require('./Brand-icon-google-color')
require('./Brand-icon-google')
require('./Brand-icon-gradle-color')
require('./Brand-icon-gradle')
require('./Brand-icon-groovy-color')
require('./Brand-icon-groovy')
require('./Brand-icon-hubspot-color')
require('./Brand-icon-hubspot')
require('./Brand-icon-java-color')
require('./Brand-icon-java')
require('./Brand-icon-javascript-color')
require('./Brand-icon-javascript')
require('./Brand-icon-jobdiva-color')
require('./Brand-icon-jobdiva')
require('./Brand-icon-kotlin-color')
require('./Brand-icon-kotlin')
require('./Brand-icon-laravel-color')
require('./Brand-icon-laravel')
require('./Brand-icon-linkedin-color')
require('./Brand-icon-linkedin')
require('./Brand-icon-netsuite-color')
require('./Brand-icon-netsuite')
require('./Brand-icon-nexmo-color')
require('./Brand-icon-nexmo')
require('./Brand-icon-node-color')
require('./Brand-icon-node')
require('./Brand-icon-office-color')
require('./Brand-icon-office')
require('./Brand-icon-php-color')
require('./Brand-icon-php')
require('./Brand-icon-python-color')
require('./Brand-icon-python')
require('./Brand-icon-rails-color')
require('./Brand-icon-rails')
require('./Brand-icon-react-color')
require('./Brand-icon-react')
require('./Brand-icon-rss-color')
require('./Brand-icon-rss')
require('./Brand-icon-ruby-color')
require('./Brand-icon-ruby')
require('./Brand-icon-salesforce-color')
require('./Brand-icon-salesforce')
require('./Brand-icon-slack-color')
require('./Brand-icon-slack')
require('./Brand-icon-stackoverflow-color')
require('./Brand-icon-stackoverflow')
require('./Brand-icon-sugarcrm-color')
require('./Brand-icon-sugarcrm')
require('./Brand-icon-symfony-color')
require('./Brand-icon-symfony')
require('./Brand-icon-twitch-color')
require('./Brand-icon-twitch')
require('./Brand-icon-twitter-color')
require('./Brand-icon-twitter')
require('./Brand-icon-viber-color')
require('./Brand-icon-viber')
require('./Brand-icon-vonage-2-color')
require('./Brand-icon-vonage-2')
require('./Brand-icon-vonage-color')
require('./Brand-icon-vonage')
require('./Brand-icon-vue-color')
require('./Brand-icon-vue')
require('./Brand-icon-whatsapp-color')
require('./Brand-icon-whatsapp')
require('./Brand-icon-wordpress-color')
require('./Brand-icon-wordpress')
require('./Brand-icon-xml-color')
require('./Brand-icon-xml')
require('./Brand-icon-youtube-color')
require('./Brand-icon-youtube')
require('./Brand-icon-zendesk-color')
require('./Brand-icon-zendesk')
require('./Brand-icon-zoho-color')
require('./Brand-icon-zoho')
