/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-checkbox-negative': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14 0H2C.9 0 0 .9 0 2v12c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2zM7 11.4L3.6 8 5 6.6l2 2 4-4L12.4 6 7 11.4z"/>'
  }
})
