/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-hospital-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm6 13c0 .6-.4 1-1 1h-3v3c0 .6-.4 1-1 1h-2c-.6 0-1-.4-1-1v-3H7c-.6 0-1-.4-1-1v-2c0-.6.4-1 1-1h3V7c0-.6.4-1 1-1h2c.6 0 1 .4 1 1v3h3c.6 0 1 .4 1 1v2z"/>'
  }
})
