/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-7-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 0a2.4 2.4 0 100 4.8A2.4 2.4 0 006 0zm6 0a2.4 2.4 0 100 4.8A2.4 2.4 0 0012 0zm6 0a2.4 2.4 0 100 4.8A2.4 2.4 0 0018 0zM3.6 6a2.4 2.4 0 00-2.4 2.4v8.4h1.2V24h2.4v-9.6H3.6v-6h1.322C5.104 7.508 5.5 6.7 6.03 6H3.6zm7.2 0a3.6 3.6 0 00-3.6 3.6v9.6h1.2V24h2.4v-4.8h2.4V24h2.4v-4.8h1.2V9.6A3.6 3.6 0 0013.2 6h-2.4zm7.17 0c.53.7.927 1.508 1.108 2.4H20.4v6h-1.2V24h2.4v-7.2h1.2V8.4A2.4 2.4 0 0020.4 6h-2.43z"/>'
  }
})
