/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-check-double': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M16.314 5.744a.75.75 0 10-1.128-.988L5.213 16.153 1.28 12.22a.75.75 0 00-1.06 1.06l4.5 4.5a.75.75 0 001.094-.036l10.5-12zm7.5 0a.75.75 0 10-1.128-.988l-9.973 11.397-.933-.933a.75.75 0 10-1.06 1.06l1.5 1.5a.75.75 0 001.094-.036l10.5-12z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
