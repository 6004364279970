/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-apple': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.3 15.9c-.3.7-.6 1.3-1 1.8-.5.8-1 1.3-1.3 1.6-.5.5-1.1.7-1.7.7-.4 0-1-.1-1.6-.4-.6-.2-1.2-.4-1.7-.4s-1.1.1-1.7.4c-.6.2-1.1.4-1.5.4-.6 0-1.2-.2-1.7-.8-.4-.3-.8-.9-1.4-1.6-.6-.8-1.1-1.8-1.5-2.9-.4-1.2-.6-2.3-.6-3.4 0-1.3.3-2.4.8-3.3.4-.7 1-1.3 1.7-1.8.8-.3 1.5-.5 2.4-.5.5 0 1.1.1 1.8.4.7.3 1.2.4 1.4.4.2 0 .7-.2 1.6-.5.9-.3 1.6-.4 2.2-.4 1.6.1 2.8.8 3.6 1.9-1.4.9-2.1 2.1-2.1 3.6 0 1.2.5 2.2 1.3 3 .4.4.8.7 1.3.9-.1.4-.2.6-.3.9zM15.6 1.5c0 1-.3 1.8-1 2.7-.8 1-1.8 1.5-2.9 1.5v-.4c0-.9.4-1.9 1.1-2.7.4-.4.8-.7 1.3-1 .5-.3 1.1-.4 1.5-.4v.3z"/>'
  }
})
