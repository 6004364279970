/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-pattern-grid': {
    width: 16,
    height: 16,
    viewBox: '0 0 47.2 27',
    data: '<path pid="0" d="M24.6 0h-2L0 12.9v1.2L22.6 27h2l22.6-12.9v-1.2L24.6 0zm-1 26.4L1 13.5 23.6.6l22.6 12.9-22.6 12.9z" _fill="#fff"/>'
  }
})
