/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-sand-clock': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5.75A.75.75 0 012.25 0h19.5a.75.75 0 010 1.5H19.5V2.25c0 3.944-1.19 7.684-3.334 9.75 2.145 2.066 3.334 5.805 3.334 9.75V22.5h2.25a.75.75 0 010 1.5H2.25a.75.75 0 010-1.5H4.5V21.75c0-3.945 1.19-7.684 3.334-9.75C5.69 9.934 4.5 6.194 4.5 2.25V1.5H2.25A.75.75 0 011.5.75zM6 22.5h12V21.75c0-4.158-1.405-7.68-3.438-9.14a.75.75 0 010-1.22C16.595 9.93 18 6.409 18 2.25V1.5H6V2.25c0 4.158 1.405 7.68 3.438 9.14a.75.75 0 010 1.22C7.405 14.07 6 17.591 6 21.75V22.5z"/>'
  }
})
