/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-squares': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6 .2H.7C.4.2.2.4.2.7V6c0 .3.2.5.5.5H6c.3 0 .5-.2.5-.5V.7c0-.3-.2-.5-.5-.5zm-.5 5.3H1.2V1.2h4.3v4.3zM15.3.2H10c-.3 0-.5.2-.5.5V6c0 .3.2.5.5.5h5.3c.3 0 .5-.2.5-.5V.7c0-.3-.2-.5-.5-.5zm-.5 5.3h-4.3V1.2h4.3v4.3zM6 9.5H.7c-.3 0-.5.2-.5.5v5.3c0 .3.2.5.5.5H6c.3 0 .5-.2.5-.5V10c0-.3-.2-.5-.5-.5zm-.5 5.3H1.2v-4.3h4.3v4.3zM15.3 9.5H10c-.3 0-.5.2-.5.5v5.3c0 .3.2.5.5.5h5.3c.3 0 .5-.2.5-.5V10c0-.3-.2-.5-.5-.5zm-.5 5.3h-4.3v-4.3h4.3v4.3z"/>'
  }
})
