/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-ai-3': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11.744.558A7 7 0 002 7v.349L.084 10.223a.5.5 0 00.192.724L2 11.81V14a2 2 0 002 2h3.5a3.5 3.5 0 003.5-3.5V8.95a2.5 2.5 0 10-1 0v3.55A2.5 2.5 0 017.5 15H4a1 1 0 01-1-1v-2.5a.5.5 0 00-.276-.447l-1.494-.747 1.686-2.529A.5.5 0 003 7.5V7a6 6 0 1110.154 4.327.5.5 0 00-.154.361V15.5a.5.5 0 001 0v-3.604A7 7 0 0011.744.559zM10.504 8h-.008a1.5 1.5 0 11.008 0z"/>'
  }
})
