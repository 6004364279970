/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-flash': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4.5 0c-.2 0-.4.2-.5.4l-2 8c0 .1 0 .3.1.4.1.1.2.2.4.2h4.4L6 15.4c0 .2.1.5.3.5.2.1.5 0 .6-.2l7-11c.1-.2.1-.3 0-.5 0-.1-.2-.2-.4-.2H9.1L10 .6c0-.1 0-.3-.1-.4-.1-.1-.2-.2-.4-.2h-5zM3.1 8l1.8-7h4L8 4.4c0 .1 0 .3.1.4.1.1.2.2.4.2h4.1l-5.3 8.3.7-4.7c0-.1 0-.3-.1-.4-.1-.1-.3-.2-.4-.2H3.1z"/>'
  }
})
