<template>
  <ParticipantView
    :displayName="$t('whiteboard.display_name')"
    class="whiteboard-participant-view"
    :participantType="'Guest'"
    :preferred="preferred"
    :canBePinned="!isWhiteboardPinned"
    :canShowContent="true"
    :isMainParticipant="isWhiteboardPinned"
    :isPinned="isWhiteboardPinned"
    :showDisplayName="showOverlay"
    @pinToggled="pinWhiteboard"
  >
    <template #content>
      <div class="whiteboard-iframe-container">
        <iframe
          id="whiteboard-iframe"
          ref="whiteboardIframe"
          data-cy="whiteboard-iframe"
          :title="$t('whiteboard.iframe_title')"
          class="whiteboard-iframe"
          :src="whiteboardUrl"
        ></iframe>
        <div v-if="showOverlay" class="pinned-overlay" />
      </div>
    </template>
  </ParticipantView>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ParticipantView from '@/components/ParticipantView';
import { LAYOUT_MODE_TYPES } from '@/consts/global-consts';

export default {
  name: 'Whiteboard',

  components: {
    ParticipantView
  },

  props: {
    sessionId: {
      type: String,
      required: true
    },
    preferred: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      whiteboardUrl: process.env.VUE_APP_EMBEDDABLE_WHITEBOARD_URL
    };
  },

  computed: {
    ...mapState(['pinnedStreamId', 'minimizedMode']),
    ...mapState('layout', ['layoutMode']),
    ...mapState('whiteboard', ['token']),
    ...mapGetters([
      'activeParticipants',
      'isMobileWebMode',
      'screenshareStreamId',
      'participantDisplayName'
    ]),
    ...mapGetters('whiteboard', ['whiteboardOwnerName', 'isWhiteboardPinned']),

    showOverlay() {
      return !this.isWhiteboardPinned || this.minimizedMode;
    }
  },

  watch: {
    layoutMode() {
      if (
        this.layoutMode === LAYOUT_MODE_TYPES.GRID &&
        this.isWhiteboardPinned
      ) {
        // Unpin the whiteboard when changing to grid
        this.unpinWhiteboard();
      } else if (!this.pinnedStreamId) {
        // Pin the whiteboard when changing to dominant/audience and there's no pinned stream
        this.pinWhiteboard();
      }
    },

    screenshareStreamId(newValue, oldValue) {
      // When screenshare stops, re-pin whiteboard
      if (oldValue && !newValue) {
        this.pinWhiteboard();
      }
    }
  },

  created() {
    // URL: embedBaseUrl/{sessionId}?queryparams=...
    this.whiteboardUrl = `${process.env.VUE_APP_EMBEDDABLE_WHITEBOARD_URL}/${
      this.sessionId
    }?display_name=${encodeURIComponent(
      this.participantDisplayName
    )}&owner_display_name=${encodeURIComponent(
      this.whiteboardOwnerName
    )}&is_locked=${encodeURIComponent(
      this.isMobileWebMode
    )}&number_of_participants=${encodeURIComponent(
      this.activeParticipants.length // should filter pstn participants ?
    )}&mobile_mode=${encodeURIComponent(this.isMobileWebMode)}`;

    if (this.token) {
      this.whiteboardUrl += `&token=${encodeURIComponent(this.token)}`;
    }
  },

  mounted() {
    this.registerClickedAnywhereListener(() => {
      this.$emit('click');
    });

    this.registerWhiteboardLoadedListener(() => {
      this.fixCursorOffsets();
    });
  },

  methods: {
    ...mapActions('whiteboard', [
      'pinWhiteboard',
      'unpinWhiteboard',
      'registerClickedAnywhereListener',
      'registerWhiteboardLoadedListener'
    ]),

    /**
     * A little hack that changes the iframe's width slightly for a split second,
     * thereby fixing an issue with wrong offsets with mouse events within the iframe
     */
    async fixCursorOffsets() {
      const iframe = this.$refs.whiteboardIframe;

      iframe.style.setProperty('width', `${iframe.offsetWidth + 1}px`);

      await this.$nextTick();
      iframe.style.removeProperty('width');
    }
  }
};
</script>

<style scoped>
.whiteboard-participant-view {
  order: -2;
}

.whiteboard-iframe-container {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mobile.speaker-view .participant:not(.main-participant) .whiteboard-iframe {
  border-radius: 6px;
}

.mobile.speaker-view .main-participant .whiteboard-iframe-container {
  --stream-aspect-ratio: 4 / 3;
  --stream-aspect-ratio-inverted: 3 / 4;
  --main-stream-margin: 8px;
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  margin: 0;
  contain: size;
  max-width: calc(100vw - var(--main-stream-margin) * 2);
  max-height: calc(var(--app-height) - var(--main-stream-margin) * 2);
}

.portrait.mobile.speaker-view .main-participant .whiteboard-iframe-container {
  width: calc(100vw - var(--main-stream-margin) * 2);
  left: var(--main-stream-margin);
  --main-stream-height: calc(100vw * var(--stream-aspect-ratio-inverted));
  height: var(--main-stream-height);
  top: max(
    var(--main-stream-margin),
    calc(var(--app-height) / 2 - var(--main-stream-height) / 2)
  );
}

.landscape-left.mobile.speaker-view
  .main-participant
  .whiteboard-iframe-container,
.landscape-right.mobile.speaker-view
  .main-participant
  .whiteboard-iframe-container {
  --main-stream-width: calc(var(--app-height) * var(--stream-aspect-ratio));
  width: var(--main-stream-width);
  left: max(
    var(--main-stream-margin),
    calc(50vw - var(--main-stream-width) / 2)
  );
  height: calc(var(--app-height) - var(--main-stream-margin) * 2);
  top: var(--main-stream-margin);
}

.whiteboard-iframe {
  width: 100%;
  height: 100%;
  user-select: none;
}

.pinned-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.15;
}
</style>
