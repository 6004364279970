/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-envelope-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 1.5h21c.9 0 1.5.6 1.5 1.5v2.25l-12 6.6L0 5.1V3c0-.9.6-1.5 1.5-1.5zM0 8.55l11.25 6.3c.42.21 1.08.21 1.5 0L24 8.55V21c0 .9-.6 1.5-1.5 1.5h-21C.6 22.5 0 21.9 0 21V8.55z"/>'
  }
})
