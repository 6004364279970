/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-increase': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.943 4.963a.748.748 0 00-.693-.463h-9a.75.75 0 000 1.5h7.19l-8.69 8.69-5.47-5.47a.75.75 0 00-1.06 0l-6 6a.75.75 0 101.06 1.06l5.47-5.47 5.47 5.47a.75.75 0 001.06 0l9.22-9.22v7.19a.75.75 0 001.5 0v-9a.747.747 0 00-.057-.287z"/>'
  }
})
