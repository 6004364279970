var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "watch-together-popper" }, [
    _c(
      "div",
      { staticClass: "popper-content-container" },
      [
        _c("p", { staticClass: "title" }, [
          _vm._v(_vm._s(_vm.$t("watch_together_mute_indication.title"))),
        ]),
        _c("p", [
          _vm._v(
            " " + _vm._s(_vm.$t("watch_together_mute_indication.text")) + " "
          ),
        ]),
        _c("vwc-button", {
          staticClass: "next-button",
          attrs: {
            label: _vm.$t("watch_together_mute_indication.got_it_button"),
            layout: "filled",
            connotation: "cta",
            dense: "",
          },
          on: { click: _vm.closeAnnouncement },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }