export const WAITING_ROOM_MESSAGE_ENUM = Object.freeze({
  TECHNICAL_DIFFICULTIES: 'TECHNICAL_DIFFICULTIES',
  MEETING_HAS_ENDED: 'MEETING_HAS_ENDED',
  CANCELED: 'CANCELED',
  DENIED: 'DENIED',
  KEEPALIVE_EXPIRED: 'KEEPALIVE_EXPIRED'
});

export const WAITING_ROOM_ILLUSTRATION_PATHS = Object.freeze({
  BYE_IMG: '/illustrations/waiting-room/bye-img.svg',
  WALK_OUT_THE_DOOR: '/illustrations/waiting-room/walk-out-the-door.svg',
  OFFICE_WORKSPACE: '/illustrations/waiting-room/office-workspace.svg',
  MEETING_ENDED: '/illustrations/waiting-room/meeting-ended.svg',
  WAIT_FOR_OWNER: '/illustrations/waiting-room/wait-for-owner.svg',
  PLEASE_WAIT: '/illustrations/waiting-room/please-wait.svg',
  SESSION_EXPIRED: '/illustrations/waiting-room/session-expired.svg'
});

export const UNDO_WINDOW_DURATION = 2 * 1000; // 2 seconds
