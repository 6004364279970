/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-video': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.8 4.1c-.2-.1-.3-.1-.5-.1L11 5.8V3.5c0-.8-.7-1.5-1.5-1.5h-8C.7 2 0 2.7 0 3.5v9c0 .8.7 1.5 1.5 1.5h8c.8 0 1.5-.7 1.5-1.5v-2.3l4.3 1.7c.2.1.3 0 .5-.1.1-.1.2-.2.2-.4v-7c0-.1-.1-.2-.2-.3zM10 6.5v6c0 .3-.2.5-.5.5h-8c-.3 0-.5-.2-.5-.5v-9c0-.3.2-.5.5-.5h8c.3 0 .5.2.5.5v3zm5 4.3l-4-1.6V6.8l4-1.6v5.6z"/>'
  }
})
