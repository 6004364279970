<template>
  <v-modal
    v-observe-keyboard.up.right.down.left="onArrowPressed"
    class="modal"
    :title="$t('screen_share_sources_picker_modal.title')"
    largeModal
    dismiss
    dismissEsc
    dismissClickOutside
    confirmEnter
    @close="(confirm) => (confirm ? share() : hide())"
  >
    <template slot="content">
      <resize-observer @notify="onModalSizeChangedDebounced" />

      <div
        v-for="(section, i) in sharingSections"
        :key="`section-${i}`"
        class="sections-container unselectable"
      >
        <h3 class="section-title">{{ section.title }}</h3>
        <div class="sources-container">
          <div
            v-for="(source, j) in section.sources"
            :key="source.id"
            class="source-container"
            :class="{ 'selected-source': source.id === selectedSourceId }"
            @click="selectSource(i, j)"
            @dblclick="share"
          >
            <p class="source-name">
              <img
                v-if="source.appIcon"
                class="source-icon"
                :src="source.appIcon"
              />
              <span class="text-ellipsis source-name-text">{{
                source.name
              }}</span>
            </p>
            <div class="thumbnail-container">
              <img class="source-thumbnail" :src="source.thumbnailUrl" />
            </div>
          </div>
        </div>
      </div>
    </template>

    <template slot="footerButtons">
      <div class="footer-buttons">
        <vwc-button
          class="cancel-button"
          :label="$t('screen_share_sources_picker_modal.cancel_button')"
          layout="outlined"
          @click="hide"
        />
        <vwc-button
          class="share-button"
          :label="
            isInitializingScreenshare || shareInProgress
              ? undefined
              : $t('screen_share_sources_picker_modal.share_button')
          "
          connotation="cta"
          layout="filled"
          :disabled="isShareButtonDisabled"
          @click="share"
        >
          <vwc-circular-progress
            v-if="isInitializingScreenshare || shareInProgress"
            indeterminate
            density="-7.5"
          />
        </vwc-button>
        <div v-if="showWindowsDisclaimer" class="windows-disclaimer">
          <v-icon iconName="Vlt-icon-error" class="icon" />
          <span>{{
            $t('screen_share_sources_picker_modal.windows_disclaimer_text')
          }}</span>
        </div>
      </div>
    </template>
  </v-modal>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import { KEYBOARD_CODES } from '@/directives/keycodes';
import debounce from 'lodash.debounce';
import { OS_TYPES } from '@/consts/global-consts';
import {
  isElectron,
  OS,
  isMinimalNativeVersion
} from '@/helpers/global-helpers';

let sectionsGrid;

export default {
  name: 'ScreenshareSourcesPickerModal',

  data() {
    return {
      sectionIndex: null,
      sourceIndex: null,
      sharingSections: [],
      shareInProgress: false,
      onModalSizeChangedDebounced: debounce(() => {
        sectionsGrid?.resetRowLengths();
      }, 300),
      showWindowsDisclaimer:
        isElectron() &&
        OS.name === OS_TYPES.WINDOWS &&
        !isMinimalNativeVersion('2.13.0')
    };
  },

  computed: {
    ...mapState('screenshare', [
      'screenshareSources',
      'hasScreensharePermissions'
    ]),
    ...mapGetters(['isInitializingScreenshare']),

    isShareButtonDisabled() {
      return (
        !this.selectedSourceId ||
        this.isInitializingScreenshare ||
        this.shareInProgress
      );
    },

    selectedSourceId() {
      return this.sharingSections[this.sectionIndex]?.sources[this.sourceIndex]
        ?.id;
    }
  },

  mounted() {
    this.sharingSections = [
      {
        type: 'Desktop',
        title: this.$t('screen_share_sources_picker_modal.screens_section'),
        sources: []
      },
      {
        type: 'App',
        title: this.$t(
          'screen_share_sources_picker_modal.applications_section'
        ),
        sources: []
      }
    ];

    this.screenshareSources.forEach((source) => {
      // In old electron versions we return the thumbnail as a native image and not as data url
      const thumbnailUrl = source.thumbnail.toDataURL?.() || source.thumbnail;

      if (thumbnailUrl.length > 500) {
        const sourceObj = {
          id: source.id,
          name: source.name,
          thumbnailUrl,
          appIcon: source.appIcon
        };
        if (source.id.startsWith('screen:')) {
          this.sharingSections[0].sources.push(sourceObj);
        } else {
          this.sharingSections[1].sources.push(sourceObj);
        }
      }
    });

    sectionsGrid = new SectionsGrid(this.sharingSections);
  },

  methods: {
    ...mapActions('screenshare', [
      'setScreenshareSources',
      'startScreenshare',
      'updateHasScreensharePermissions'
    ]),

    hide() {
      this.setScreenshareSources([]);
    },

    selectSource(sectionIndex, sourceIndex) {
      this.sectionIndex = sectionIndex;
      this.sourceIndex = sourceIndex;
    },

    async share() {
      if (this.isShareButtonDisabled) {
        return;
      }
      this.shareInProgress = true;
      try {
        await this.updateHasScreensharePermissions();
        if (this.hasScreensharePermissions) {
          const stream = await navigator.mediaDevices.getUserMedia({
            audio: false,
            video: {
              mandatory: {
                chromeMediaSource: 'desktop',
                chromeMediaSourceId: this.selectedSourceId
              }
            }
          });
          await this.startScreenshare({
            videoSource: stream.getVideoTracks()[0],
            sharingType: this.sharingSections[this.sectionIndex]?.type
          });
        }
      } catch (err) {
        logger.error('get-screen-stream', LOG_CATEGORIES.TOKBOX, {
          err: err.toString()
        });
      } finally {
        this.shareInProgress = false;
        this.hide();
      }
    },

    onArrowPressed(keyCode) {
      if (!this.selectedSourceId) {
        // select the first source
        this.selectSource(0, 0);
      } else {
        switch (keyCode) {
          case KEYBOARD_CODES.up:
            this.moveUp(this.sectionIndex, this.sourceIndex);
            break;

          case KEYBOARD_CODES.down:
            this.moveDown(this.sectionIndex, this.sourceIndex);
            break;

          case KEYBOARD_CODES.right:
            this.moveRight(this.sectionIndex, this.sourceIndex);
            break;

          case KEYBOARD_CODES.left: {
            this.moveLeft(this.sectionIndex, this.sourceIndex);
            break;
          }
        }
      }
    },

    moveUp(activeSectionIndex, activeIndex) {
      const numPerRow = sectionsGrid.getRowLength(activeSectionIndex);
      const isTopRow = activeIndex <= numPerRow - 1;
      if (!isTopRow) {
        // move up
        this.selectSource(activeSectionIndex, activeIndex - numPerRow);
      } else if (activeSectionIndex > 0) {
        // move to prev section
        const newSectionIndex = activeSectionIndex - 1;
        const newSectionSources = this.sharingSections[newSectionIndex]
          ?.sources;
        const newSectionNumPerRow = sectionsGrid.getRowLength(newSectionIndex);
        let newSourceIndex;
        if (
          sectionsGrid.numOfSourcesInLastRow(newSectionIndex) - 1 >=
          activeIndex
        ) {
          // move to the source at the same row-index of the prev section
          const isNewSectionMultiRow = sectionsGrid.hasMultipleRows(
            newSectionIndex
          );
          const sourcesInFirstRows = isNewSectionMultiRow
            ? newSectionSources.length -
              (newSectionSources.length % newSectionNumPerRow)
            : 0;
          newSourceIndex = sourcesInFirstRows + activeIndex;
        } else {
          // move to the last source of the prev section
          newSourceIndex = newSectionSources.length - 1;
        }
        this.selectSource(newSectionIndex, newSourceIndex);
      }
    },

    moveDown(activeSectionIndex, activeIndex) {
      const numPerRow = sectionsGrid.getRowLength(activeSectionIndex);
      const isBottomRow =
        activeIndex >=
        sectionsGrid.getSectionLength(activeSectionIndex) - numPerRow;
      if (!isBottomRow) {
        // move down
        this.selectSource(activeSectionIndex, activeIndex + numPerRow);
      } else if (
        sectionsGrid.hasMultipleRows(activeSectionIndex) &&
        !sectionsGrid.isLastRow(activeSectionIndex, activeIndex)
      ) {
        // move to last source in section (in case the last row is shorter than numPerRow)
        const sectionSources = this.sharingSections[activeSectionIndex].sources;
        const newSourceIndex = sectionSources[sectionSources.length - 1];
        this.selectSource(activeSectionIndex, newSourceIndex);
      } else if (activeSectionIndex < this.sharingSections.length - 1) {
        // move to next section
        const newSectionIndex = activeSectionIndex + 1;
        const newSectionSources = this.sharingSections[newSectionIndex]
          ?.sources;
        const newSourceIndex = Math.min(
          activeIndex % numPerRow,
          newSectionSources.length - 1
        );
        this.selectSource(newSectionIndex, newSourceIndex);
      }
    },

    moveRight(activeSectionIndex, activeIndex) {
      const isLastInSection =
        activeIndex === sectionsGrid.getSectionLength(activeSectionIndex) - 1;
      if (!isLastInSection) {
        // move one right
        this.selectSource(activeSectionIndex, activeIndex + 1);
      } else if (activeSectionIndex < this.sharingSections.length - 1) {
        // move to next section
        this.selectSource(activeSectionIndex + 1, 0);
      }
    },

    moveLeft(activeSectionIndex, activeIndex) {
      const isFirstInSection = activeIndex === 0;
      if (!isFirstInSection) {
        // move one left
        this.selectSource(activeSectionIndex, activeIndex - 1);
      } else if (activeSectionIndex > 0) {
        // move to prev section
        const newSectionIndex = activeSectionIndex - 1;
        const newSourceIndex =
          this.sharingSections[newSectionIndex].sources.length - 1;
        this.selectSource(newSectionIndex, newSourceIndex);
      }
    }
  }
};

class SectionsGrid {
  constructor(sections) {
    this.sections = sections;
    this.sectionsRowLengths = [];
  }

  hasMultipleRows(sectionIndex) {
    const rowLen = this.getRowLength(sectionIndex);
    return rowLen < this.sections[sectionIndex].sources.length;
  }

  isLastRow(sectionIndex, sourceIndex) {
    const activeSectionSources = this.sections[sectionIndex].sources;
    return (
      sourceIndex >
      activeSectionSources.length - 1 - this.numOfSourcesInLastRow(sectionIndex)
    );
  }

  numOfSourcesInLastRow(sectionIndex) {
    const rowLen = this.getRowLength(sectionIndex);
    return this.sections[sectionIndex].sources.length % rowLen || rowLen;
  }

  getSectionLength(sectionIndex) {
    return this.sections[sectionIndex]?.sources.length;
  }

  getRowLength(sectionIndex) {
    if (this.sectionsRowLengths[sectionIndex]) {
      return this.sectionsRowLengths[sectionIndex];
    }

    const sourceContainers = document.getElementsByClassName(
      'sources-container'
    );
    const grid = Array.from(sourceContainers[sectionIndex].children);
    const baseOffset = grid[0].offsetTop;
    const breakIndex = grid.findIndex((item) => item.offsetTop > baseOffset);
    const rowLength = breakIndex === -1 ? grid.length : breakIndex;
    this.sectionsRowLengths[sectionIndex] = rowLength;
    return rowLength;
  }

  resetRowLengths() {
    this.sectionsRowLengths = [];
  }
}
</script>

<style scoped>
.modal >>> .Vlt-modal__panel {
  width: 732px;
  max-height: 502px;
}

.modal >>> .Vlt-modal__content {
  padding-bottom: 0;
}

.sections-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.section-title {
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0;
  margin-bottom: 3px;
  line-height: normal;
}

.sources-container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 14px;
}

.source-container {
  display: flex;
  flex-direction: column;
  margin: 10px 15px 10px 0;
  cursor: pointer;
  width: 150px;
}

.source-name {
  display: flex;
  align-items: center;
  margin-bottom: 4px;
}

.source-container:hover:not(.selected-source) .source-name {
  font-weight: 600;
}

.selected-source .source-name {
  color: var(--btn-cta-color-white-bg);
  font-weight: 600;
}

.source-name-text {
  font-size: 12px;
}

.source-icon {
  margin-right: 4px;
  width: 16px;
  height: 16px;
}

.thumbnail-container {
  height: 97px;
  padding: 8px;
  border: 1px solid black;
  border-radius: 6px;
}

.source-container:hover:not(.selected-source) .thumbnail-container {
  padding: 7px;
  border: 2px solid black;
}

.selected-source .thumbnail-container {
  padding: 7px;
  border: 2px solid var(--btn-cta-color-white-bg);
}

.source-thumbnail {
  width: 100%;
  height: 100%;
}

.footer-buttons {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

vwc-button.share-button {
  margin-left: 8px;
}

.windows-disclaimer {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 6px;
}

.windows-disclaimer .icon {
  width: 23px;
  height: 23px;
  margin: 0 8px 0 12px;
}
</style>
