/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-rss-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 24h-4.629C19.371 13.371 10.63 4.629 0 4.629V0c13.2 0 24 10.8 24 24zM3.429 24a3.429 3.429 0 100-6.857 3.429 3.429 0 000 6.857zm8.057 0h4.457c0-8.743-7.2-15.943-15.943-15.943v4.457c6.343 0 11.486 5.143 11.486 11.486z" _fill="#131415"/>'
  }
})
