/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-umbrella-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 19.5c0 .9-.6 1.5-1.5 1.5s-1.5-.6-1.5-1.5V12H24c0-6.6-5.4-12-12-12S0 5.4 0 12h10.5v7.5c0 2.55 1.95 4.5 4.5 4.5s4.5-1.95 4.5-4.5V18h-3v1.5z"/>'
  }
})
