/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-bookmark-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 0C4.35 0 3 1.35 3 3v21l9-4.5 9 4.5V3c0-1.65-1.35-3-3-3H6z"/>'
  }
})
