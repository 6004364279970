/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-hash-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.8 8.7c.7 0 1.2-.5 1.2-1.1v-.4c0-.6-.5-1.1-1.2-1.1H19V1.2c0-.7-.5-1.2-1.1-1.2h-.4c-.6 0-1.1.5-1.1 1.2v4.9H7.6V1.2C7.6.5 7.1 0 6.5 0h-.4C5.5 0 5 .5 5 1.2v4.9H1.2C.5 6.1 0 6.6 0 7.2v.4c0 .6.5 1.1 1.2 1.1H5v6.5H1.2c-.7 0-1.2.5-1.2 1.1v.4c0 .6.5 1.1 1.2 1.1H5v4.9c0 .8.5 1.3 1.1 1.3h.4c.6 0 1.1-.5 1.1-1.2v-4.9h8.8v4.9c0 .7.5 1.2 1.1 1.2h.4c.6 0 1.1-.5 1.1-1.2v-4.9h3.8c.7 0 1.2-.5 1.2-1.1v-.4c0-.6-.5-1.1-1.2-1.1H19V8.7h3.8zm-6.4 6.6H7.6V8.7h8.8v6.6z"/>'
  }
})
