import MeetingManager from '@/services/meeting-manager-service';
import { APP_SIGNALS } from '@/consts/global-consts';
import { PARTICIPANT_STATE_TYPES } from '@/consts/global-consts';

export default {
  init: ({ dispatch }) => {
    MeetingManager.onSignal(APP_SIGNALS.REACTIONS_COUNTER.START, (event) => {
      dispatch('setIsReactionsCounterModalOpen', true);
      dispatch('setAllReactionsCounters', event.data.reactionsCounts);
      dispatch('setTotalCounter', event.data.totalCount);
    });

    MeetingManager.onSignal(APP_SIGNALS.REACTIONS_COUNTER.RESET, () => {
      dispatch('resetTotalCounter');
      dispatch('resetAllReactionsCounters');
    });

    MeetingManager.onSignal(APP_SIGNALS.REACTIONS_COUNTER.UPDATE, (event) => {
      dispatch('setTotalCounter', event.data.totalCount);
      dispatch('setAllReactionsCounters', event.data.reactionsCounts);
    });

    MeetingManager.onSignal(APP_SIGNALS.REACTIONS_COUNTER.STOP, () => {
      dispatch('setIsReactionsCounterModalOpen', false);
      dispatch('resetTotalCounter');
      dispatch('resetAllReactionsCounters');
    });
  },

  setIsReactionsCounterModalOpen: ({ commit }, isVisible) =>
    commit('SET_IS_REACTIONS_COUNTER_MODAL_OPEN', isVisible),

  setTotalCounter: ({ commit }, totalCounter) =>
    commit('SET_TOTAL_COUNT', totalCounter),

  setAllReactionsCounters: ({ commit }, reactionsCounts) =>
    commit('SET_ALL_REACTIONS_COUNTERS', reactionsCounts),

  resetTotalCounter: ({ dispatch }) => {
    dispatch('setTotalCounter', 0);
  },

  resetAllReactionsCounters: ({ commit }, reactionsCounters) => {
    commit('RESET_REACTIONS_COUNTERS', reactionsCounters);
  },

  increaseReaction: ({ commit }, { reactionId }) => {
    commit('INCREASE_REACTION_COUNTER', reactionId);
  },

  sendUpdateReactionsCounterSignal: ({ state, dispatch }, reactionId) => {
    dispatch('increaseReaction', reactionId);
    const totalCount = state.totalCount + 1;
    dispatch('setTotalCounter', totalCount);

    const reactionSignal = {
      type: APP_SIGNALS.REACTIONS_COUNTER.UPDATE,
      data: {
        reactionsCounts: state.reactionsCounts,
        totalCount: state.totalCount
      }
    };
    return MeetingManager.sendSignal(reactionSignal);
  },

  sendResetReactionsCounterSignal: ({ state, dispatch }) => {
    dispatch('resetTotalCounter');
    dispatch('resetAllReactionsCounters');

    const resetSignal = {
      type: APP_SIGNALS.REACTIONS_COUNTER.RESET,
      data: {
        reactionsCounts: state.reactionsCounts,
        totalCount: state.totalCount
      }
    };
    return MeetingManager.sendSignal(resetSignal);
  },

  sendStartReactionsCounterSignal: ({ state }, { to = undefined }) => {
    const reactionStartSignal = {
      type: APP_SIGNALS.REACTIONS_COUNTER.START,
      data: {
        reactionsCounts: state.reactionsCounts,
        totalCount: state.totalCount
      }
    };
    if (to !== undefined) {
      reactionStartSignal.to = to;
    }
    return MeetingManager.sendSignal(reactionStartSignal);
  },

  sendStopReactionsCounterSignal: ({ state }) => {
    const reactionStopSignal = {
      type: APP_SIGNALS.REACTIONS_COUNTER.STOP,
      data: {
        reactionsCounts: state.reactionsCounts,
        totalCount: state.totalCount
      }
    };
    return MeetingManager.sendSignal(reactionStopSignal);
  },

  handleParticipantStateUpdate: ({ dispatch, state }, event) => {
    if (!state.isReactionsCounterModalOpen) {
      return;
    }

    if (event.state === PARTICIPANT_STATE_TYPES.JOINED) {
      dispatch('handleParticipantJoined', {
        participantId: event.participantId,
        connectionId: event.connectionId
      });
    } else if (
      [PARTICIPANT_STATE_TYPES.LEFT, PARTICIPANT_STATE_TYPES.KICKED].includes(
        event.state
      )
    ) {
      dispatch('_handleParticipantLeft', {
        participantId: event.participantId
      });
    }
  },

  handleParticipantJoined: (
    { rootState, dispatch, state },
    { participantId, connectionId }
  ) => {
    if (
      !state.isReactionsCounterModalOpen ||
      participantId === rootState.myParticipantId
    ) {
      return;
    }

    const stream = rootState.streams?.find(
      (stream) => stream?.connectionId === connectionId
    );
    const connection = stream?.connection;

    dispatch('sendStartReactionsCounterSignal', { to: connection });
  },

  // When the owner of reactions counter leaves, close reactions counter for all
  _handleParticipantLeft: (
    { dispatch, state, rootState },
    { participantId }
  ) => {
    if (
      !state.isReactionsCounterModalOpen ||
      rootState.owner !== participantId
    ) {
      return;
    }

    dispatch('setIsReactionsCounterModalOpen', false);
    dispatch('resetTotalCounter');
    dispatch('resetAllReactionsCounters');
  }
};
