/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-light-bulb': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.2 3c-.5-.9-1.3-1.7-2.2-2.2C10 .2 8.9 0 7.9 0c-1.1 0-2.1.3-3 .9-.9.5-1.7 1.3-2.2 2.3-.5.9-.7 2-.7 3 0 1.1.4 2.1.9 3 .5.8 1.2 1.5 2.1 2v4.3c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-4.3c.9-.5 1.6-1.2 2.1-2.1.6-.9.9-2 .9-3.1s-.3-2.1-.8-3zM6 15v-1h4v1H6zm7-9c0 .9-.3 1.8-.7 2.6s-1.2 1.4-2 1.8c-.2.1-.3.3-.3.4V13H6v-2.1c0-.2-.1-.4-.3-.4-.8-.4-1.5-1-1.9-1.8S3 7.1 3 6.2c0-.9.2-1.8.6-2.6.4-.8 1-1.4 1.8-1.9C6.1 1.3 7 1 7.9 1c.9 0 1.8.2 2.5.6.8.4 1.4 1.1 1.9 1.8.5.8.7 1.7.7 2.6z"/><path pid="1" d="M8 3c-.8 0-1.6.3-2.1.9C5.3 4.4 5 5.2 5 6c0 .3.2.5.5.5S6 6.3 6 6c0-.5.2-1 .6-1.4C7 4.2 7.5 4 8 4c.3 0 .5-.2.5-.5S8.3 3 8 3z"/>'
  }
})
