/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'empty': {
    width: 16,
    height: 16,
    viewBox: '0 0 230 200',
    data: '<rect pid="0" opacity=".15" x="65" y="50" width="100" height="100" rx="8"/><path pid="1" d="M97.75 78.438c0-.794.644-1.438 1.438-1.438h31.624a1.438 1.438 0 110 2.875H99.187a1.438 1.438 0 01-1.437-1.438zm7.188 15.812c.793 0 1.437.644 1.437 1.438V100h17.25v-4.313a1.438 1.438 0 012.875 0v5.751c0 .793-.644 1.437-1.438 1.437h-20.124a1.438 1.438 0 01-1.438-1.437v-5.75c0-.794.644-1.438 1.438-1.438zm-11.5-8.625c-.794 0-1.438.644-1.438 1.438v31.625c0 .793.644 1.437 1.438 1.437h43.124c.794 0 1.438-.644 1.438-1.437V87.062c0-.793-.644-1.437-1.438-1.437H93.437zm1.437 31.625V88.5h40.25v28.75h-40.25z"/>'
  }
})
