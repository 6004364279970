var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "slider-container" },
    [
      _c(
        "v-popover",
        {
          ref: "hoverTimePopover",
          attrs: {
            trigger: "hover",
            placement: "bottom-start",
            popoverBaseClass: "hover-time-popover",
            offset: "16",
            boundariesElement: "window",
          },
        },
        [
          _c("WatchTogetherSlider", {
            ref: "slider",
            attrs: {
              primaryColor: _vm.sliderColor,
              value: _vm.timeInVideo,
              secondaryValue: _vm.bufferedSeconds,
              max: _vm.duration,
            },
            on: {
              mouseMoveOnBar: _vm.mouseMoveOnBarThrottled,
              dragstart: _vm.sliderDragStarted,
              dragging: _vm.sliderDragging,
              dragend: _vm.sliderDragEnded,
            },
          }),
          _c(
            "div",
            {
              ref: "hoverTimePopoverContent",
              style: _vm.hoverTimePopoverStyle,
              attrs: { slot: "popover" },
              on: { mouseenter: _vm.hideHoverTimePopover },
              slot: "popover",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "watch-together-popover unselectable hover-time-text",
                },
                [_vm._v(" " + _vm._s(_vm.hoverTime) + " ")]
              ),
            ]
          ),
        ],
        1
      ),
      _vm.ownerIndicationVisible
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.ownerIndicationTooltip,
                  expression: "ownerIndicationTooltip",
                },
              ],
              staticClass: "owner-indication",
              class: _vm.ownerIndicationBorderClasses,
              style: _vm.ownerIndicationStyle,
              on: { click: _vm.syncToOwner },
            },
            [
              _c("Avatar", {
                attrs: {
                  size: "xs",
                  displayName: _vm.ownerParticipant.displayName,
                  image: _vm.ownerParticipant.profilePicture,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }