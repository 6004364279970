<template>
  <div
    v-if="showOpenMeetingScreen"
    class="join-from-link-screen"
    data-cy="join-from-link-screen"
  >
    <div v-if="!isBranded && !domainsConfigurations.isCsp" class="vonage-logo">
      <img src="/vonage-logo-with-text.svg" />
    </div>

    <LocaleSwitcher v-if="isLocaleSwitcherEnabled" class="locale-switcher" />

    <div class="container">
      <div class="image-title-container">
        <div v-if="isBranded" class="branded-icon">
          <vwc-icon type="video-conference-line" class="icon" />
        </div>
        <img v-else class="image" :src="joinMeetingFromLinkScreenImgPath" />

        <div data-cy="title" class="title">
          {{ $t('join_meeting_from_link_screen.title') }}
        </div>
      </div>
      <div class="checkbox-buttons-container">
        <vwc-formfield :label="checkBoxLabel" class="checkbox-text">
          <vwc-checkbox
            :checked="rememberChoiceCheckbox"
            @change="rememberChoiceCheckboxChanged"
          />
        </vwc-formfield>

        <div class="buttons-container">
          <vwc-3-button
            class="continue-button vvd-root"
            :label="$t('join_meeting_from_link_screen.continue_button')"
            appearance="outlined"
            connotation="primary"
            :disabled="rememberChoiceCheckbox"
            @click="() => confirmOpenMeeting(false)"
          />
          <vwc-3-button
            class="confirm-button vvd-root"
            :label="$t('join_meeting_from_link_screen.confirmation_button')"
            appearance="filled"
            connotation="cta"
            :icon="isBranded ? '' : 'vonage-solid'"
            @click="() => confirmOpenMeeting(true)"
          />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    <LoadingScreen />
  </div>
</template>

<script>
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import analytics from '@/services/analytics-service';
import { isElectron, isNativeOSSupported } from '@/helpers/global-helpers';
import { mapActions, mapState, mapGetters } from 'vuex';
import desktopSocketService from '@/services/desktop-clients-sync/desktop-client-service';
import { ANALYTICS } from '@/consts/global-consts';
import LoadingScreen from '@/views/LoadingScreen';
import LocaleSwitcher from '@/components/LocaleSwitcher';

export default {
  name: 'JoinMeetingFromLinkScreen',

  components: { LoadingScreen, LocaleSwitcher },

  data() {
    return {
      roomToken: this.$route.params.roomToken,
      rememberChoiceCheckbox: false,
      showOpenMeetingScreen: false,
      joinMeetingFromLinkScreenImgPath: this.$store.state.domainsConfigurations
        .joinMeetingFromLinkScreenImgPath
    };
  },

  computed: {
    ...mapState(['isBranded']),
    ...mapGetters(['isLocaleSwitcherEnabled', 'domainsConfigurations']),

    checkBoxLabel() {
      const title = this.domainsConfigurations
        .joinMeetingFromLinkScreenCheckboxTitle;
      return this.isBranded
        ? this.$t('join_meeting_from_link_screen.checkbox_branded')
        : this.$t(`join_meeting_from_link_screen.${title}`);
    }
  },

  beforeDestroy() {
    desktopSocketService.closeConnection();
  },

  async mounted() {
    if (isElectron()) {
      logger.log(
        'Open meeting link in this running instance of desktop app',
        LOG_CATEGORIES.LINK_FLOW,
        {
          method: 'mounted',
          roomToken: this.roomToken
        }
      );
      return this.startApp();
    }

    if (!window.isEdge && isNativeOSSupported) {
      await desktopSocketService.init();
    }

    if (
      desktopSocketService.isConnected() &&
      (await desktopSocketService.syncIsDesktopAppLoggedIn()) &&
      !this.isBranded
    ) {
      const {
        alwaysOpenInDesktopApp
      } = await desktopSocketService.syncMeetingsSettings();
      if (alwaysOpenInDesktopApp) {
        this.openInSyncedDesktop();
      } else {
        this.showOpenMeetingScreen = true;
      }
    } else {
      this.openInWebApp(desktopSocketService.env);
    }
  },

  methods: {
    ...mapActions(['showOpenedInDesktopMessage', 'replaceRoute']),

    openInWebApp(env) {
      logger.log('Open meeting link in web', LOG_CATEGORIES.LINK_FLOW, {
        method: 'openInWebApp',
        roomToken: this.roomToken
      });
      this.startApp(env);

      this.sendAnalyticsEvent(true);
    },

    openInSyncedDesktop() {
      logger.log(
        'Open meeting in synced desktop app',
        LOG_CATEGORIES.LINK_FLOW,
        {
          method: 'openInSyncedDesktop',
          roomToken: this.roomToken
        }
      );

      desktopSocketService.syncOpenMeetingInDesktop(this.roomToken);
      this.showOpenedInDesktopMessage(this.domainsConfigurations.isCsp);

      this.sendAnalyticsEvent(false);
    },

    startApp(env) {
      // check if need to route to other env
      if (
        env &&
        (!isElectron() ||
          window.isProduction ||
          env.host !== window.location.host)
      ) {
        logger.log(
          `Meetings link flow - redirecting to connected env ${env.host}`,
          LOG_CATEGORIES.LINK_FLOW,
          {
            method: 'startApp',
            roomToken: this.roomToken
          }
        );

        window.location.assign(
          `https://${env.host}/?room_token=${this.roomToken}`
        );
      } else {
        this.replaceRoute({
          name: 'Home',
          query: { room_token: this.roomToken, ...this.$route.query }
        });
      }
    },

    async confirmOpenMeeting(shouldOpenInDesktopApp) {
      this.showOpenMeetingModal = false;

      if (this.rememberChoiceCheckbox && shouldOpenInDesktopApp) {
        await desktopSocketService.syncUpdateMeetingsSettings({
          alwaysOpenInDesktopApp: true
        });
      }

      if (shouldOpenInDesktopApp) {
        this.openInSyncedDesktop();
        analytics.trackEvent(ANALYTICS.ALWAYS_OPEN_IN_APP, {
          State: this.rememberChoiceCheckbox ? 'Enabled' : 'Disabled'
        });
      } else {
        this.openInWebApp(desktopSocketService.env);
      }
    },

    rememberChoiceCheckboxChanged() {
      this.rememberChoiceCheckbox = !this.rememberChoiceCheckbox;
    },

    sendAnalyticsEvent(isOpenInBrowser) {
      analytics.trackEvent(ANALYTICS.OPEN_MEETING_LINK, {
        Type: isOpenInBrowser ? 'Browser' : 'App'
      });
    }
  }
};
</script>

<style scoped>
.join-from-link-screen {
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #0d0d0d;
}

.vonage-logo {
  padding-top: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0d0d0d;
}

.locale-switcher {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 10;
}

.image-title-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0d0d0d;
  text-align: center;
}

.branded-icon {
  background-color: #333333;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.icon {
  width: 60px;
  height: 60px;
  color: white;
}

.image-title-container .title {
  font-weight: 500;
  font-size: 26px;
  line-height: 36px;
  margin-top: 48px;
  color: white;
}

.image-title-container .image {
  width: 260px;
  height: 180px;
}

.checkbox-buttons-container .checkbox-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  margin-top: 24px;
  color: white;
}

.buttons-container {
  display: flex;
  flex-direction: row;
  min-width: 510px;
  margin-top: 12px;
}

.continue-button {
  flex: 1;
}

.confirm-button {
  margin-left: 15px;
  flex: 1;
}

.checkbox-buttons-container {
  display: flex;
  flex-direction: column;
  align-self: center;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  flex: 1;
}
</style>
