/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-record': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 22.8c6 0 10.8-4.8 10.8-10.8S18 1.2 12 1.2 1.2 6 1.2 12 6 22.8 12 22.8zm0 1.2C5.4 24 0 18.6 0 12S5.4 0 12 0s12 5.4 12 12-5.4 12-12 12zM6.4 12.9h.5c.1 0 .1.1.1.2l.9 1.6c.3.2.5.3.7.3h1.2l-1.2-2c-.1-.1-.1-.2-.2-.2-.1-.1-.2-.1-.2-.2.2-.1.3-.2.5-.3s.2-.2.3-.3c.1-.1.2-.3.2-.5s.1-.3.1-.5 0-.5-.1-.7l-.5-.5c-.2-.1-.4-.2-.7-.3-.3-.1-.6-.2-1-.2H5.1V15h1.3v-2.1zm0-.9v-1.7H7c.3 0 .6.1.8.2.1.2.2.4.2.6 0 .1 0 .2-.1.3 0 .1-.1.2-.2.3-.1.1-.2.1-.3.2-.1.1-.2.1-.4.1h-.6zM14 9.3h-3.6V15H14v-1h-2.3v-1.3h1.8v-1h-1.8v-1.3H14V9.3zm4.6 4.2h-.1s-.1 0-.1.1c-.1.1-.2.1-.2.2-.1 0-.2.1-.2.1-.1 0-.2 0-.3.1h-.3c-.2 0-.4 0-.6-.1s-.3-.2-.5-.4c-.1-.2-.2-.3-.3-.6-.1-.2-.1-.5-.1-.8 0-.3 0-.5.1-.7l.3-.6c.1-.2.3-.3.5-.4s.4-.1.7-.1h.4c.1 0 .2 0 .3.1.1 0 .1.1.2.1s.1.1.2.1l.1.1H19l.1-.1.4-.6c-.1-.1-.2-.2-.4-.3-.1-.1-.3-.2-.5-.2-.2-.1-.3-.1-.5-.2-.2 0-.4-.1-.6-.1-.4 0-.8.1-1.2.2-.4.1-.7.3-.9.6-.3.3-.5.6-.6.9-.1.4-.2.7-.2 1.2 0 .4.1.8.2 1.2.1.4.3.7.5.9.2.3.5.5.9.6.3.1.7.2 1.2.2s.9-.1 1.2-.2c.3-.2.6-.4.9-.7l-.5-.5c-.2 0-.2 0-.4-.1.1 0 .1 0 0 0z"/>'
  }
})
