/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-switch': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11 3H5C2.2 3 0 5.2 0 8s2.2 5 5 5h6c2.8 0 5-2.2 5-5s-2.2-5-5-5zm-6 9c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4zm6 0H8c1.2-.9 2-2.4 2-4s-.8-3.1-2-4h3c2.2 0 4 1.8 4 4s-1.8 4-4 4z"/>'
  }
})
