<template>
  <Dialog
    class="dialog"
    :heading="$t('virtual_background_modal.title')"
    :subtitle="$t('virtual_background_modal.subtitle')"
    icon-name="texture-line"
    :min-width="minWidth"
    :primary-action="$t('virtual_background_modal.ok_button')"
    :secondary-action="$t('virtual_background_modal.cancel_button')"
    :disable-primary-button="isInitializingVirtualBackground || !isVideoLoaded"
    :loading-primary-button="isInitializingVirtualBackground || !isVideoLoaded"
    :close="closeModal"
    @applied="applyVirtualBackground"
  >
    <div slot="content" class="dialog-container">
      <div class="preview-container">
        <div ref="video" class="video"></div>
      </div>
      <VirtualBackgroundSelector
        class="select-background-container"
        :selected-background="selectedBackground"
        :disabled="isInitializingVirtualBackground || !isVideoLoaded"
        @selectBackground="selectBackground"
      />
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import MeetingManager from '@/services/meeting-manager-service';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import VirtualBackgroundSelector from '@/components/VirtualBackgroundSelector';
import Dialog from '@/components/Dialog';
import { ANALYTICS_SOURCE } from '@/consts/global-consts';

let previewPublisher = null;

export default {
  name: 'VirtualBackgroundModal',

  components: { VirtualBackgroundSelector, Dialog },

  data() {
    return {
      selectedBackground: this.$store.state.virtualBackground,
      isVideoLoaded: false
    };
  },

  computed: {
    ...mapState(['virtualBackground']),
    ...mapGetters(['isInitializingVirtualBackground']),

    minWidth() {
      if (this.$screen.xxl || this.$screen.xl || this.$screen.lg) {
        return 744;
      } else if (this.$screen.md || this.$screen.sm) {
        return 360;
      }
      return 0;
    }
  },

  created() {
    this.activateCamera();
  },

  destroyed() {
    this.stopVideoStream();
  },

  methods: {
    ...mapActions([
      'selectVirtualBackground',
      'saveVirtualBackground',
      'setIsVirtualBackgroundModalVisible',
      'createPublisher',
      'setActionInProgress'
    ]),

    async selectBackground(selectedBackground) {
      try {
        await this.selectVirtualBackground({
          publisher: previewPublisher,
          currentVirtualBackground: this.selectedBackground,
          newVirtualBackground: selectedBackground
        });
        this.selectedBackground = selectedBackground;
      } catch (error) {
        logger.error(
          'Virtual Background Modal - failed to select virtual background',
          LOG_CATEGORIES.TOKBOX,
          error
        );
      }
    },

    async applyVirtualBackground() {
      try {
        await this.selectVirtualBackground({
          publisher: MeetingManager.publisher,
          currentVirtualBackground: this.virtualBackground,
          newVirtualBackground: this.selectedBackground
        });
        this.saveVirtualBackground({
          source: ANALYTICS_SOURCE.VIRTUAL_BACKGROUND_MODAL,
          virtualBackground: this.selectedBackground
        });
      } catch (error) {
        logger.error(
          'Virtual Background Modal - failed to apply virtual background',
          LOG_CATEGORIES.CLIENT_LOGIC,
          error
        );
      } finally {
        this.closeModal();
      }
    },

    closeModal() {
      this.setIsVirtualBackgroundModalVisible(false);
    },

    async activateCamera() {
      await this.stopVideoStream();
      const element = this.$refs.video;
      const options = {
        insertMode: 'append',
        fitMode: 'contain',
        showControls: false,
        height: '100%',
        width: '100%',
        publishAudio: false,
        publishVideo: true
      };

      previewPublisher = await this.createPublisher({
        element,
        options
      });
      this.isVideoLoaded = true;
      // If the component has been destroyed before the publisher was created, we want to destroy the publisher
      if (this._isDestroyed) {
        await this.stopVideoStream();
      }
    },

    async stopVideoStream() {
      if (previewPublisher) {
        const publisherToDestroy = previewPublisher;
        previewPublisher = null;
        if (publisherToDestroy.getVideoFilter()) {
          await publisherToDestroy.clearVideoFilter();
        }
        if (this.isInitializingVirtualBackground) {
          this.setActionInProgress({
            name: 'virtualBackground',
            inProgress: false
          });
        }
        publisherToDestroy.destroy();
        this.isVideoLoaded = false;
      }
    }
  }
};
</script>

<style scoped>
.dialog-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;
  max-height: 357px;
}

.preview-container {
  align-content: center;
  height: 261px;
  width: 348px;
}

.preview-container >>> * {
  border-radius: 8px;
}

.preview-container .video {
  width: 100%;
  height: 100%;
}

.video >>> video {
  transform: scale(-1, 1);
  transform-origin: 50% 50%;
}

.select-background-container {
  display: flex;
  flex-direction: column;
  height: 287px;
  padding-right: 5px;
}

@media only screen and (max-width: 800px) {
  .dialog-container {
    flex-direction: column;
    max-height: 377px;
  }

  .select-background-container {
    height: 124px;
    margin-top: 12px;
  }

  .select-background-container >>> .grid {
    gap: 8px;
    margin-bottom: 8px;
  }

  .preview-container {
    height: 200px;
    width: 300px;
  }

  .dialog >>> .header-container {
    margin-bottom: 12px;
  }
}

@media only screen and (max-height: 500px) {
  .preview-container,
  .dialog >>> .subtitle {
    display: none;
  }

  .select-background-container {
    height: 145px;
    margin-top: unset;
  }
}
</style>
