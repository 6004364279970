/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-print-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 0h12v4.5H6V0zm0 7.5h12c3.3 0 6 2.7 6 6V18c0 .9-.6 1.5-1.5 1.5h-3v3c0 .9-.6 1.5-1.5 1.5H6c-.9 0-1.5-.6-1.5-1.5v-3h-3C.6 19.5 0 18.9 0 18v-4.5c0-3.3 2.7-6 6-6zM7.5 21h9v-6h-9v6z"/>'
  }
})
