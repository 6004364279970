/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-stream': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7 1.5a.5.5 0 00-1 0v12a.5.5 0 001 0v-12zm2 1a.5.5 0 00-1 0v12a.5.5 0 001 0v-12zM4.5 4a.5.5 0 01.5.5v7a.5.5 0 01-1 0v-7a.5.5 0 01.5-.5zM2.97 6.5a.5.5 0 00-1 0v4a.5.5 0 001 0v-4zM.5 7a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2A.5.5 0 01.5 7zM11 4.5a.5.5 0 00-1 0v7a.5.5 0 001 0v-7zM12.5 6a.5.5 0 01.5.5v4a.5.5 0 01-1 0v-4a.5.5 0 01.5-.5zM15 7.5a.5.5 0 00-1 0v2a.5.5 0 001 0v-2z"/>'
  }
})
