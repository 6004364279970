var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "PreJoinTemplate",
    {
      directives: [
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.l2.enter",
          value: _vm.enterMeeting,
          expression: "enterMeeting",
          modifiers: { l2: true, enter: true },
        },
      ],
      attrs: {
        subtitle: _vm.subtitle,
        title: _vm.title,
        useLightGrayBackground: true,
        showTitleLoader:
          _vm.isInWaitingRoom &&
          _vm.hasFinishedArtificalTransitionToWaitingRoom,
        showLocaleSwitcher: true,
      },
    },
    [
      _c("template", { slot: "main-content" }, [
        _c("div", { staticClass: "controls-container" }, [
          _c(
            "div",
            { staticClass: "video-mirror" },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.showVideo,
                      expression: "showVideo",
                    },
                  ],
                  staticClass: "video-container",
                },
                [_c("div", { ref: "video", staticClass: "video" })]
              ),
              _vm.confirmationDialog ? _c("ConfirmationDialogModal") : _vm._e(),
              _vm.infoDialog
                ? _c("InfoModal", { staticClass: "infoModal" })
                : _vm._e(),
              _vm.showPermissionModal && _vm.canUseMicrophone
                ? _c("PermissionsModal")
                : _vm._e(),
              _vm.isSettingsModalVisible ? _c("SettingsModal") : _vm._e(),
              _c("NoVideoParticipantTile", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.showVideo,
                    expression: "!showVideo",
                  },
                ],
                attrs: {
                  participantType: _vm.myParticipant.type,
                  participantProfilePicture: _vm.myParticipant.profilePicture,
                  displayName: _vm.myParticipant.displayName,
                  "avatar-size": "lg",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "controls" }, [
            _c(
              "div",
              { staticClass: "device-options-container" },
              [
                _c("MeetingActionMenuButton", {
                  staticClass: "toggle-device mic-button",
                  class: { "no-audio": !_vm.joinWithComputerAudio },
                  attrs: {
                    "data-cy": "toggle-mic",
                    label: _vm.microphoneToggleText,
                    icon: !_vm.joinWithComputerAudio
                      ? "audio-mute-solid"
                      : _vm.microphoneEnabled && _vm.canUseMicrophone
                      ? "microphone-2-solid"
                      : "mic-mute-solid",
                    disabled: _vm.isMicDisabled,
                    split: _vm.canUseMicrophone,
                    "disable-split":
                      _vm.isMicDisabled || !_vm.joinWithComputerAudio,
                    "flip-menu": true,
                    lists: _vm.micMenu,
                  },
                  on: {
                    click: function ($event) {
                      !_vm.joinWithComputerAudio
                        ? _vm.toggleJoinWithComputerAudio()
                        : _vm.toggleMicrophone()
                    },
                    speakerDeviceClicked: _vm.speakerDeviceClicked,
                    micDeviceClicked: _vm.micDeviceClicked,
                    noAudioMode: _vm.toggleJoinWithComputerAudio,
                  },
                }),
                _c("MeetingActionMenuButton", {
                  staticClass: "toggle-device camera-button",
                  attrs: {
                    "data-cy": "toggle-camera",
                    label: _vm.cameraToggleText,
                    icon:
                      _vm.canUseCamera && _vm.cameraEnabled
                        ? "video-solid"
                        : "video-off-solid",
                    disabled: _vm.isCameraDisabled,
                    split: _vm.canUseCamera,
                    "disable-split": _vm.isCameraDisabled,
                    "flip-menu": true,
                    lists: _vm.cameraMenu,
                  },
                  on: {
                    click: _vm.toggleCamera,
                    blur: _vm.blurBackgroundClicked,
                    virtualBackground: _vm.toggleVirtualBackground,
                    cameraDeviceClicked: _vm.cameraDeviceClicked,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "toggle-device" },
              [
                _c("vwc-button", {
                  attrs: {
                    "data-cy": "toggle-sidebar",
                    label: _vm.$t("pre_join.settings_button"),
                    layout: "text",
                    shape: "rounded",
                    stacked: "",
                    onmouseup: "this.blur()",
                    icon: "gear-solid",
                  },
                  on: { click: _vm.openSettings },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "template",
        { slot: "footer-button" },
        [
          _vm.isInInitialPrejoinState
            ? _c(
                "vwc-button",
                {
                  staticClass: "enter-button",
                  attrs: {
                    label: _vm.$t("pre_join.enter_button"),
                    "data-cy": "enter-button",
                    layout: "filled",
                    connotation: "cta",
                    disabled: _vm.isJoining,
                  },
                  on: { click: _vm.enterMeeting },
                },
                [
                  _vm.isJoining
                    ? _c("vwc-circular-progress", {
                        staticClass: "join-meeting-loading-spinner",
                        attrs: { indeterminate: "", density: "-7" },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "vwc-button",
                {
                  staticClass: "enter-button",
                  attrs: {
                    label: _vm.$t("pre_join.cancel_waiting_room_button"),
                    "data-cy": "cancel-waiting-room-participation-button",
                    layout: "outlined",
                    outlined: "",
                    connotation: "primary",
                    disabled: _vm.isCancellingWaitingRoomParticipation,
                  },
                  on: { click: _vm.cancelWaitingRoomParticipation },
                },
                [
                  _vm.isCancellingWaitingRoomParticipation
                    ? _c("vwc-circular-progress", {
                        staticClass: "join-meeting-loading-spinner",
                        attrs: { indeterminate: "", density: "-7" },
                      })
                    : _vm._e(),
                ],
                1
              ),
        ],
        1
      ),
      _c("template", { slot: "sidebar" }, [
        _c(
          "div",
          { staticClass: "sidebar-container" },
          [
            _c(
              "Sidebar",
              {
                staticClass: "sidebar-wrapper",
                attrs: {
                  icon: "texture-line",
                  title: _vm.$t("virtual_background_modal.title"),
                },
              },
              [
                _c("template", { slot: "sidebar-content" }, [
                  _c(
                    "div",
                    { staticClass: "virtual-background-container" },
                    [
                      _c("VirtualBackgroundSelector", {
                        attrs: {
                          "selected-background": _vm.virtualBackground,
                          disabled: _vm.isInitializingVirtualBackground,
                        },
                        on: { selectBackground: _vm.selectBackground },
                      }),
                    ],
                    1
                  ),
                ]),
              ],
              2
            ),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }