/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-github': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.345 0 0 5.441 0 12.297c0 5.441 3.49 10.012 8.182 11.644.545.11.873-.217.873-.544v-2.285c-3.382.653-4.037-1.524-4.037-1.524-.545-1.414-1.309-1.85-1.309-1.85-1.09-.761.11-.761.11-.761 1.2.108 1.854 1.305 1.854 1.305 1.09 1.85 2.836 1.306 3.49.98.11-.762.437-1.306.764-1.632-2.836-.218-5.563-1.306-5.563-5.986 0-1.306.436-2.394 1.2-3.264-.11-.327-.546-1.633.109-3.265 0 0 .982-.327 3.272 1.306.982-.327 1.964-.436 3.055-.436.982 0 2.073.11 3.055.436 2.29-1.633 3.272-1.306 3.272-1.306.655 1.74.218 2.938.11 3.264.763.871 1.2 1.96 1.2 3.265 0 4.68-2.837 5.768-5.455 6.094.436.436.763 1.089.763 2.286v3.373c0 .327.219.762.873.544C20.618 22.31 24 17.738 24 12.297 24 5.441 18.654 0 12 0z"/>'
  }
})
