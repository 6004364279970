/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-wallet-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.25 0H15v4.5H2.25C1.05 4.5 0 3.45 0 2.25S1.05 0 2.25 0zM0 7.5h22.5c.9 0 1.5.6 1.5 1.5v13.5c0 .9-.6 1.5-1.5 1.5H3c-1.65 0-3-1.35-3-3V7.5zm16.5 8.25c0 1.2 1.05 2.25 2.25 2.25S21 16.95 21 15.75s-1.05-2.25-2.25-2.25-2.25 1.05-2.25 2.25z"/>'
  }
})
