/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.06 9.559a.75.75 0 011.048-.165L12 14.405l6.892-5.013a.75.75 0 01.882 1.214l-7.333 5.333a.75.75 0 01-.882 0l-7.334-5.333a.75.75 0 01-.165-1.048z"/>'
  }
})
