/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-crop-fill-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 3v10.5H24V18H4.5V9H9v4.5h6v-6H0V3h4.5V0H9v3h10.5zM15 19.5h4.5V24H15v-4.5z"/>'
  }
})
