/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-vue-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.78 2h4.373L12 6.576 14.848 2h4.372L12 14.102 4.78 2z" _fill="#35495E"/><path pid="1" d="M0 2h4.78L12 14.102 19.22 2H24L12 21.932 0 2z" _fill="#41B883"/>'
  }
})
