/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-home-user-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M21.761 10.5l-9.76-6.65-9.762 6.65L.75 7.394 11.256.236a1.291 1.291 0 011.489 0L23.25 7.394 21.761 10.5zM6.001 21v3h12v-3s-1.5-3-6-3-6 3-6 3zm9-7.5a3 3 0 11-6 0 3 3 0 016 0z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
