/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-add-user-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.5 13.5c-3.3 0-6-2.7-6-6V6c0-3.3 2.7-6 6-6s6 2.7 6 6v1.5c0 3.3-2.7 6-6 6zM7.5 16.5C3.3 16.5 0 19.8 0 24h12v-7.5H7.5zM21 15h-3v3h-3v3h3v3h3v-3h3v-3h-3v-3z"/>'
  }
})
