import { REACTION_COUNTER_OPTIONS } from '@/consts/global-consts';
export default function getInitialState() {
  return {
    isReactionsCounterModalOpen: false,
    reactionsCounts: Object.values(REACTION_COUNTER_OPTIONS).map((option) => ({
      id: option.value,
      path: option.path,
      count: 0
    })),
    totalCount: 0
  };
}
