/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-edit': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4 3.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM4 6.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zM4 9.5a.5.5 0 01.5-.5H6a.5.5 0 010 1H4.5a.5.5 0 01-.5-.5z"/><path pid="1" d="M1 .5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v3.91h-1V1H2v14h2.6v1H1.5a.5.5 0 01-.5-.5V.5z"/><path pid="2" d="M12.667 5.96l2.707 2.707-5.785 5.784-3.61.903.903-3.61 5.785-5.785zm-4.882 6.295l-.431 1.724 1.724-.43 4.882-4.882-1.293-1.293-4.882 4.881z"/>'
  }
})
