import PushNotificationsService from '@/services/push-notifications-service';
import { showNotification } from '@/helpers/global-helpers';

// eslint-disable-next-line
import { SERVICES, EVENTS_TYPES } from '@/consts/bus';
import { RECORDING_STATE } from '@/store/recordings/consts';
import i18n from '@/i18n';

let _enabled = false;
let _eventsStack = [];

export default {
  // TODO: why to disable eslint and not remove the unused var?
  // eslint-disable-next-line no-unused-vars
  initBus: async ({ dispatch, state }, { domain }) => {
    if (PushNotificationsService.isConnected()) {
      return;
    }

    await dispatch('registerBus', { domain });

    // Register participants states updates events
    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      EVENTS_TYPES.BUS_SESSION_PARTICIPANT_STATE,
      (data) => {
        dispatch('_handleParticipantStateUpdate', data);
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      EVENTS_TYPES.BUS_SESSION_OWNER_CHANGED,
      (data) => {
        dispatch('_handleSessionOwnerChanged', data);
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      EVENTS_TYPES.BUS_SESSION_CLOSED,
      (data) => {
        dispatch('_handleSessionClosed', data);
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      EVENTS_TYPES.BUS_SESSION_IS_LOCKED,
      (data) => {
        dispatch('_handleSessionLocked', data);
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      EVENTS_TYPES.BUS_SESSION_JOIN_APPROVAL_LEVEL_CHANGED,
      (data) => {
        dispatch('_handleSessionJoinApprovalLevelChanged', data);
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      EVENTS_TYPES.BUS_SESSION_RECORDING_STATE,
      (data) => {
        dispatch('_handleSessionRecordingStateChange', data);
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MESSAGES_SERVICE,
      EVENTS_TYPES.BUS_MESSAGING_MESSAGE_NEW,
      (data) => {
        // TODO: when pim adds groupId to the data, filter msgs by relevant groupId
        dispatch('_handleNewMessageEvent', data);
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      EVENTS_TYPES.BUS_SESSION_WHITEBOARD_STATUS,
      (data) => {
        dispatch('_handleSessionWhiteboardStatusChange', data);
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      EVENTS_TYPES.BUS_SESSION_WHITEBOARD_TOKEN,
      (data) => {
        dispatch('_handleSessionWhiteboardToken', data);
      }
    );

    // Register for waiting room updates
    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      'waiting-room:participant:admitted',
      () => {
        dispatch('_handleWaitingRoomResponse', true);
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      'waiting-room:participant:denied',
      () => {
        dispatch('_handleWaitingRoomResponse', false);
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      'waiting-room:participant:joined',
      () => {
        dispatch('_handleWaitingRoomParticipantJoined');
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      'waiting-room:participant:left',
      () => {
        dispatch('_handleWaitingRoomParticipantListChanged');
      }
    );

    PushNotificationsService.addHandler(
      SERVICES.BUS_MEETINGS_SERVICE,
      EVENTS_TYPES.BUS_SESSION_ACTIVE_CAPTIONS_UPDATE,
      (data) => {
        dispatch('_handleSessionIsCaptionsEnabledUpdated', data);
      }
    );
  },

  enableBus: ({ dispatch }) => {
    _eventsStack.forEach((event) => dispatch(event.action, event.data));
    _eventsStack = [];
    _enabled = true;
  },

  unregisterBus: () => {
    PushNotificationsService.unregisterBusStation();
  },

  registerBus: ({ state }, { domain }) => {
    return PushNotificationsService.init(
      state.userInfo.accountId,
      state.userInfo.userId,
      domain
    );
  },

  _handleParticipantStateUpdate: ({ dispatch, state }, event) => {
    if (!_enabled) {
      _eventsStack.push({
        action: '_handleParticipantStateUpdate',
        data: event
      });
      return;
    }
    if (event.sessionId === state.sessionId) {
      dispatch('playParticipantStateSoundIndication', event);
      dispatch('updateParticipantData', event);
      dispatch('watchTogether/ongoing/handleParticipantStateUpdate', event);
      dispatch('whiteboard/handleParticipantStateUpdate', event);
      dispatch('beRightBack/handleParticipantStateUpdate', event);
      dispatch('raiseHand/handleParticipantStateUpdate', event);
      dispatch('qna/handleParticipantStateUpdate', event);
      dispatch('reactionsCounter/handleParticipantStateUpdate', event);
    }
  },

  _handleSessionOwnerChanged: ({ dispatch }, event) => {
    if (!_enabled) {
      _eventsStack.push({
        action: '_handleSessionOwnerChanged',
        data: event
      });
      return;
    }
    dispatch('setSessionOwner', event.owner);
  },

  _handleNewMessageEvent: ({ dispatch }, event) => {
    if (!_enabled) {
      _eventsStack.push({ action: '_handleNewMessageEvent', data: event });
      return;
    }
    dispatch('messaging/readMessages');
  },

  _handleSessionRecordingStateChange: ({ dispatch, state }, event) => {
    if (!_enabled) {
      _eventsStack.push({
        action: '_handleSessionRecordingStateChange',
        data: event
      });
      return;
    }
    if (event.sessionId === state.sessionId) {
      // only if the recording is on, we save the recording id.
      if (event.state === RECORDING_STATE.STARTED) {
        dispatch('recordings/updateSessionRecording', state.sessionId);
      } else if (event.state === RECORDING_STATE.FAILED) {
        dispatch('addFlashMessage', {
          type: 'critical',
          text: i18n.t('more_actions_bus.recording_error_text')
        });
      } else if (
        state.recordings.recording?.recordingId === event.recordingId &&
        (event.state === RECORDING_STATE.STOPPED ||
          event.state === RECORDING_STATE.UPLOADED)
      ) {
        dispatch('recordings/setSessionRecording', null);
      }
    }
  },

  _handleSessionClosed: ({ dispatch, state }, event) => {
    if (!_enabled) {
      _eventsStack.push({ action: '_handleSessionClosed', data: event });
      return;
    }
    if (event.sessionId === state.sessionId) {
      dispatch('leaveSession', { endForAll: false, closeWindow: false });
    }
  },

  _handleSessionLocked: ({ dispatch, state }, event) => {
    if (!_enabled) {
      _eventsStack.push({ action: '_handleSessionLocked', data: event });
      return;
    }
    if (event.sessionId === state.sessionId) {
      dispatch('setSessionIsLocked', event.isLocked);
    }
  },

  _handleSessionJoinApprovalLevelChanged: ({ dispatch, state }, event) => {
    if (!_enabled) {
      _eventsStack.push({
        action: '_handleSessionJoinApprovalLevelChanged',
        data: event
      });
      return;
    }
    if (event.sessionId === state.sessionId) {
      dispatch('setSessionJoinApprovalLevel', event.joinApprovalLevel);
    }
  },

  _handleSessionWhiteboardStatusChange: ({ state, dispatch }, event) => {
    if (!_enabled) {
      _eventsStack.push({
        action: '_handleSessionWhiteboardStatusChange',
        data: event
      });
      return;
    }

    if (event.sessionId === state.sessionId && event.status === 'ended') {
      dispatch('whiteboard/clearState', null, { root: true });
    }
  },

  _handleSessionWhiteboardToken: ({ state, dispatch }, event) => {
    if (!_enabled) {
      _eventsStack.push({
        action: '_handleSessionWhiteboardToken',
        data: event
      });
      return;
    }

    if (event.sessionId === state.sessionId) {
      dispatch('whiteboard/activateIncomingWhiteboard', {
        whiteboardId: event.whiteboardId,
        token: event.token
      });
    }
  },

  _handleWaitingRoomResponse: ({ dispatch }, isAdmitted) => {
    dispatch(
      isAdmitted ? 'waitingRoom/handleAdmitted' : 'waitingRoom/handleDenied'
    );
  },

  _handleWaitingRoomParticipantListChanged: ({ dispatch }) => {
    dispatch('waitingRoom/handleParticipantListChanged');
  },

  _handleWaitingRoomParticipantJoined: ({ state, getters, dispatch }) => {
    dispatch('waitingRoom/handleParticipantListChanged');
    if (getters.isSessionOwner && !document.hasFocus()) {
      if (state.settings.playSoundOnWaitingRoomJoin) {
        dispatch('playWaitingRoomSoundIndication');
      }

      const notificationIcon = state.isBranded
        ? state.whitelabel.brandedFavicon
        : undefined;
      showNotification({
        title: i18n.t(
          'waiting_room_consts.waiting_room_owner_notification_text'
        ),
        iconUrl: notificationIcon
      });
    }
  },

  _handleSessionIsCaptionsEnabledUpdated: ({ state, dispatch }, event) => {
    if (!_enabled) {
      _eventsStack.push({
        action: '_handleSessionIsCaptionsEnabledUpdated',
        data: event
      });
      return;
    }
    if (event.sessionId === state.sessionId) {
      dispatch('captions/setActiveCaptionsId', event.activeCaptionsId);
    }
  }
};
