/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-app-plus-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 19.5h9v3H1.5A1.5 1.5 0 010 21V3a1.5 1.5 0 011.5-1.5h21A1.5 1.5 0 0124 3v9h-3V7.5H3v12zM21 15v3h3v3h-3v3h-3v-3h-3v-3h3v-3h3z"/>'
  }
})
