/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-audio-mute-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.085 2.716a.5.5 0 01.827.378v17.812a.5.5 0 01-.827.378l-5.72-4.95H2.183C.977 16.333 0 15.338 0 14.11V9.889C0 8.66.977 7.666 2.183 7.666h2.182l5.72-4.95zm11.622 5.577a1 1 0 010 1.414L19.414 12l2.293 2.293a1 1 0 01-1.414 1.414L18 13.414l-2.293 2.293a1 1 0 01-1.414-1.414L16.586 12l-2.293-2.293a1 1 0 011.414-1.414L18 10.586l2.293-2.293a1 1 0 011.414 0z"/>'
  }
})
