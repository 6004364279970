var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "dialog-container unselectable vvd-root vvd-theme-alternate",
    },
    [
      _vm.imgSrc
        ? _c("img", {
            staticClass: "dialog-img",
            attrs: { src: _vm.imgSrc, alt: "dialog-img" },
          })
        : _vm._e(),
      _c("div", { staticClass: "dialog-message-container" }, [
        _c("span", { staticClass: "dialog-message-title" }, [
          _vm._v(" " + _vm._s(_vm.dialogTitle) + " "),
        ]),
        _vm.dialogSubtitle
          ? _c("span", { staticClass: "dialog-message-subtitle" }, [
              _vm._v(" " + _vm._s(_vm.dialogSubtitle) + " "),
            ])
          : _vm._e(),
      ]),
      _vm.actionButtonText
        ? _c("vwc-3-button", {
            staticClass: "dialog-action-button",
            attrs: { label: _vm.actionButtonText, appearance: "filled" },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.$emit("action-button-click")
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }