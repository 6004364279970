var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vwc-dialog",
    {
      directives: [
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.l2.enter",
          value: _vm.mainButtonAction,
          expression: "mainButtonAction",
          modifiers: { l2: true, enter: true },
        },
      ],
      staticClass: "vvd-scheme-alternate",
      class: _vm.dialogClassName,
      attrs: {
        "data-cy": "end-meeting-modal",
        "close-button": _vm.showCancelButton,
        connotation: "cta",
        open: _vm.isEndMeetingDialogVisible,
        heading: _vm.title,
        escapeKeyAction: _vm.hide,
      },
      on: {
        closed: _vm.hide,
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _vm.showRecordingCheckbox
        ? _c("vwc-icon", {
            staticClass: "dialog-icon",
            attrs: { slot: "icon", size: "large", type: "warning-line" },
            slot: "icon",
          })
        : _vm._e(),
      _c("div", { class: { content: _vm.isSessionOwner && _vm.isRecording } }, [
        _c("div", { staticClass: "text" }, [
          _vm._v(" " + _vm._s(_vm.text) + " "),
        ]),
        _vm.showRecordingCheckbox
          ? _c(
              "div",
              { class: _vm.checkboxClassName },
              [
                _c(
                  "vwc-formfield",
                  {
                    attrs: {
                      label: _vm.$t(
                        "end_meeting_modal.stop_recording_checkbox"
                      ),
                    },
                  },
                  [
                    _c("vwc-checkbox", {
                      attrs: { checked: _vm.stopRecordingFlag },
                      on: { change: _vm.toggleStopRecordingFlag },
                    }),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      !_vm.isSessionOwner
        ? _c("vwc-button", {
            attrs: {
              slot: "secondaryAction",
              "data-cy": "cancel-btn",
              label: _vm.$t("end_meeting_modal.cancel_button"),
              layout: "outlined",
              disabled: _vm.isLeaving,
            },
            on: { click: _vm.hide },
            slot: "secondaryAction",
          })
        : _vm._e(),
      _c("vwc-button", {
        attrs: {
          slot: _vm.leaveMeetingButtonSlot,
          "data-cy": "leave-meeting-btn",
          layout: _vm.leaveMeetingButtonLayout,
          label: _vm.$t("end_meeting_modal.leave_meeting_button"),
          disabled: _vm.isLeaving,
        },
        on: {
          click: function ($event) {
            return _vm.leaveMeeting(false)
          },
        },
        slot: _vm.leaveMeetingButtonSlot,
      }),
      _vm.isSessionOwner
        ? _c("vwc-button", {
            attrs: {
              slot: "primaryAction",
              "data-cy": "end-meeting-btn",
              label: _vm.$t("end_meeting_modal.end_meeting_button"),
              layout: "filled",
              connotation: "alert",
              disabled: _vm.isLeaving,
            },
            on: {
              click: function ($event) {
                return _vm.leaveMeeting(true)
              },
            },
            slot: "primaryAction",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }