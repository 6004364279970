import Vue from 'vue';
export default {
  ADD_RAISED_HAND: (state, participantId) => {
    Vue.set(state.raisedHands, participantId, {});
  },

  REMOVE_RAISED_HAND: (state, participantId) => {
    Vue.delete(state.raisedHands, participantId);
  }
};
