/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-duplicate': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 5.5A.5.5 0 01.5 5h2a.5.5 0 010 1H1v1.5a.5.5 0 01-1 0v-2zM.5 9a.5.5 0 01.5.5v2.031a.5.5 0 01-1 0V9.5A.5.5 0 01.5 9zM.5 13a.5.5 0 01.5.5V15h1.5a.5.5 0 010 1h-2a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5zM4 15.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zM10.5 13a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 010-1H10v-1.5a.5.5 0 01.5-.5zM15.5 8a.5.5 0 01.5.5v2a.5.5 0 01-.5.5h-2a.5.5 0 010-1H15V8.5a.5.5 0 01.5-.5zM15.5 3.969a.5.5 0 01.5.5V6.5a.5.5 0 01-1 0V4.469a.5.5 0 01.5-.5zM13 .5a.5.5 0 01.5-.5h2a.5.5 0 01.5.5v2a.5.5 0 01-1 0V1h-1.5a.5.5 0 01-.5-.5zM9 .5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2A.5.5 0 019 .5zM5 .5a.5.5 0 01.5-.5h2a.5.5 0 010 1H6v1.5a.5.5 0 01-1 0v-2zM5 5.5a.5.5 0 01.5-.5h5a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-5a.5.5 0 01-.5-.5v-5zM6 6v4h4V6H6z"/>'
  }
})
