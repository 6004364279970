var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.focusedElement
    ? _c(
        "popper",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hideTour,
              expression: "!hideTour",
            },
          ],
          key: _vm.tourSteps.length,
          staticClass: "tour-container",
          style: _vm.currentTourStep.style,
          attrs: {
            trigger: "focus",
            forceShow: _vm.showPopper,
            reference: _vm.focusedElement,
            options: _vm.popperOptions,
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "popper",
              class: {
                "popper-with-illustration": _vm.currentTourStep.illustration,
                "light-mode": _vm.currentTourStep.lightMode,
              },
            },
            [
              _vm.currentTourStep.illustration
                ? _c("div", { staticClass: "illustration-container" }, [
                    _c("img", {
                      attrs: {
                        src: "/" + _vm.currentTourStep.illustration + ".svg",
                      },
                    }),
                  ])
                : _vm._e(),
              _c(
                "div",
                { staticClass: "popper-content-container" },
                [
                  !_vm.hideStepCounter
                    ? _c("span", { staticClass: "step-counter" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.numOfTourSteps - _vm.tourSteps.length + 1
                            ) +
                            "/" +
                            _vm._s(_vm.numOfTourSteps) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                  _vm.currentTourStep.title
                    ? _c("h6", [_vm._v(_vm._s(_vm.currentTourStep.title))])
                    : _vm._e(),
                  _c("p", [_vm._v(_vm._s(_vm.currentTourStep.subtitle))]),
                  _c("vwc-button", {
                    staticClass: "next-button",
                    attrs: {
                      label: _vm.currentStepButton,
                      connotation: "cta",
                      layout: "filled",
                      fullwidth: "",
                      dense: "",
                    },
                    on: { click: _vm.nextStep },
                  }),
                ],
                1
              ),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }