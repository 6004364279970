/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-audio-mid': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.924 9.166H2.183a.703.703 0 00-.683.723v4.222c0 .425.33.722.683.722h2.741l4.489 3.885V5.282L4.924 9.166zm-.559-1.5H2.183C.977 7.666 0 8.661 0 9.89v4.222c0 1.228.977 2.222 2.183 2.222h2.182l5.72 4.95a.5.5 0 00.827-.377V3.094a.5.5 0 00-.827-.378l-5.72 4.95zm9.108.656a.75.75 0 011.06.01c1.983 2.018 1.983 5.32 0 7.338a.75.75 0 11-1.07-1.051c1.41-1.435 1.41-3.801 0-5.236a.75.75 0 01.01-1.06zm3.682-3.133a.75.75 0 10-1.07 1.051c3.114 3.171 3.114 8.35 0 11.519a.75.75 0 101.07 1.05c3.688-3.752 3.687-9.866 0-13.62z"/>'
  }
})
