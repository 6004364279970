/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-viber': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M22.278 5.3c-.408-1.456-1.215-2.605-2.397-3.418C18.39.852 16.673.49 15.191.267c-2.05-.305-3.906-.348-5.677-.132-1.66.204-2.908.528-4.048 1.052-2.233 1.027-3.574 2.688-3.984 4.936a30.697 30.697 0 00-.412 3.02c-.179 2.176-.016 4.102.498 5.888.5 1.74 1.375 2.983 2.673 3.8.33.209.755.36 1.164.504.232.081.459.161.644.249.171.08.171.093.17.236-.015 1.237 0 3.662 0 3.662l.003.518h.93l.15-.147c.1-.094 2.403-2.319 3.225-3.216l.112-.124c.14-.163.14-.163.285-.165a99.158 99.158 0 003.367-.127c1.363-.073 2.943-.205 4.43-.825 1.362-.569 2.356-1.471 2.954-2.682.623-1.263.992-2.63 1.13-4.182.245-2.727.072-5.093-.527-7.233zm-4.904 10.603c-.342.556-.851.944-1.453 1.194-.44.184-.888.145-1.322-.039-3.634-1.538-6.485-3.961-8.368-7.446-.39-.718-.659-1.5-.969-2.258-.063-.155-.059-.339-.087-.51C5.2 5.62 6.14 4.928 7.09 4.72c.362-.081.684.047.953.306a8.35 8.35 0 011.78 2.46c.193.406.106.764-.225 1.066-.069.063-.14.12-.216.177-.754.567-.864.997-.462 1.85.684 1.454 1.822 2.43 3.291 3.035.387.16.752.08 1.048-.234.04-.04.085-.082.114-.13.579-.966 1.42-.87 2.197-.32.51.362 1.005.745 1.508 1.114.768.565.762 1.095.295 1.859zm-4.82-9.642c-.17 0-.342.014-.511.043a.521.521 0 11-.171-1.03 4.103 4.103 0 014.724 4.724.522.522 0 01-1.03-.17 3.06 3.06 0 00-3.012-3.567zm2.534 3.13a.523.523 0 01-1.044 0c0-.862-.703-1.565-1.565-1.565a.523.523 0 010-1.043 2.611 2.611 0 012.609 2.609zm2.98 1.16a.522.522 0 01-1.02-.232A4.606 4.606 0 0011.54 4.81a.52.52 0 01-.623-.393.52.52 0 01.393-.624 5.65 5.65 0 016.758 6.758z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
