/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-gear-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.95 7.8l1.65-3.15-2.1-2.1-3.15 1.65c-.45-.3-1.05-.45-1.65-.6L13.5 0h-3L9.3 3.45c-.45.15-1.05.3-1.5.6L4.65 2.4 2.4 4.65 4.05 7.8c-.3.45-.45 1.05-.6 1.5L0 10.5v3l3.45 1.2c.15.6.45 1.05.6 1.65L2.4 19.5l2.1 2.1 3.15-1.65c.45.3 1.05.45 1.65.6L10.5 24h3l1.2-3.45c.6-.15 1.05-.45 1.65-.6l3.15 1.65 2.1-2.1-1.65-3.15c.3-.45.45-1.05.6-1.65L24 13.5v-3l-3.45-1.2c-.15-.45-.3-1.05-.6-1.5zM12 16.5c-2.55 0-4.5-1.95-4.5-4.5S9.45 7.5 12 7.5s4.5 1.95 4.5 4.5-1.95 4.5-4.5 4.5z"/>'
  }
})
