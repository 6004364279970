var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.showInviteDisabledIndication,
          expression: "showInviteDisabledIndication",
        },
      ],
      staticClass: "invites-disabled-indication",
      attrs: { "data-cy": "invites-disabled-indication" },
    },
    [
      _c("vwc-icon", { attrs: { type: "info-line", size: "small" } }),
      _c("div", { staticClass: "message-container" }, [
        _vm.isLocked && _vm.isSessionOwner
          ? _c("div", [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "invites_disabled_indication.locked_meeting_owner_start"
                    )
                  )
                ),
              ]),
              _c(
                "span",
                {
                  staticClass: "text-button",
                  attrs: { "data-cy": "unlock-text-button" },
                  on: { click: _vm.onUnlockMeeting },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "invites_disabled_indication.unlock_meeting_button"
                        )
                      ) +
                      " "
                  ),
                ]
              ),
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.$t(
                      "invites_disabled_indication.locked_meeting_owner_end"
                    )
                  )
                ),
              ]),
            ])
          : _c("div", [
              _c("span", [_vm._v(_vm._s(_vm.text))]),
              _c(
                "span",
                {
                  staticClass: "text-button",
                  attrs: { "data-cy": "text-button" },
                  on: {
                    click: function ($event) {
                      _vm.showInviteDisabledIndication = false
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("invites_disabled_indication.dismiss_button")
                      ) +
                      " "
                  ),
                ]
              ),
            ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }