/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-exit-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 0H15c.9 0 1.5.6 1.5 1.5V6h-3V3H3v18h10.5v-3h3v4.5c0 .9-.6 1.5-1.5 1.5H1.5C.6 24 0 23.4 0 22.5v-21C0 .6.6 0 1.5 0zM18 18l6-6-6-6v4.5H9v3h9V18z"/>'
  }
})
