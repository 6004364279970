/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vonage-page-logo': {
    width: 683,
    height: 768,
    viewBox: '0 0 683 768',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 0h170.628l163.407 369.934-80.954 194.323c-1.803 4.332-7.813 4.374-9.672.062L0 0zm393.815 667.519C428.843 611.159 690.365 0 690.365 0H864S606.912 601.486 576.166 659.382C549.112 710.319 500.296 768 432.603 768H276.339a.967.967 0 01-.956-.975c0-.48.342-.887.805-.96 49.931-8.014 76.913-33.024 117.627-98.546z" _fill="#131415"/>'
  }
})
