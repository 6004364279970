<template>
  <div
    v-if="toast"
    class="toast"
    :class="{
      critical: isCritical,
      warning: isWarning,
      good: isGood,
      info: isInfo
    }"
    data-cy="mobile-toast"
  >
    <v-icon v-if="iconName" :iconName="iconName" class="icon" />
    <div class="text">{{ toast.message }}</div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { TOAST_TYPE } from '@/consts/mobile-consts';
export default {
  computed: {
    ...mapState('mobile', ['toast']),

    isCritical() {
      return this.toast.type === TOAST_TYPE.CRITICAL;
    },

    isWarning() {
      return this.toast.type === TOAST_TYPE.WARNING;
    },

    isGood() {
      return this.toast.type === TOAST_TYPE.GOOD;
    },

    isInfo() {
      return this.toast.type === TOAST_TYPE.INFO;
    },

    iconName() {
      if (this.isCritical) {
        return 'Vlt-icon-error';
      } else if (this.isGood) {
        return 'Vlt-icon-check-circle-negative';
      }
      return undefined;
    }
  },

  watch: {
    toast() {
      if (this.removeToastTimeout) {
        clearTimeout(this.removeToastTimeout);
      }

      if (this.toast) {
        this.removeToastTimeout = setTimeout(this.clearToast, this.toast.time);
      }
    }
  },

  methods: {
    ...mapActions('mobile', ['clearToast'])
  }
};
</script>

<style scoped>
.toast {
  display: flex;
  align-items: center;
  background: white;
  border-radius: 6px;
  padding: 16px 0;
}

.toast.good {
  border-left: solid 8px #1c8731;
}

.toast.critical {
  border-left: solid 8px #e84545;
}

.toast.warning {
  border-left: solid 8px #ffc100;
}

.toast.info {
  border-left: solid 8px #0276d5;
}

.icon {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  fill: black;
}

.critical .icon {
  fill: #e84545;
}

.text {
  margin-left: 16px;
  color: black;
}
</style>
