/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-rails': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.836 2.4c-2.4-1.2-4.8-1.31-7.309-.436-2.29.763-4.145 2.29-5.454 4.254C5.782 9.6 4.145 13.2 3.163 17.128c-.436 2.072-.763 4.145-.654 6.218V24h11.673c0-.436-.11-.327-.11-.327-.654-1.527-1.09-3.273-1.417-4.91-.437-2.4-.764-4.8-.219-7.2.655-3.381 2.619-5.781 5.782-7.09 1.855-.764 3.6-.546 5.128.872h.108L24 4.582c-.982-.873-1.964-1.637-3.164-2.182zM.218 17.89C.11 18.655 0 19.419 0 20.074l2.182.218L2.618 18c-.873 0-1.636-.11-2.4-.11zm4.255-6.654L2.4 10.473l-.655 1.854 2.073.655c.218-.655.437-1.2.655-1.746zm9.6 10.364c.654 0 1.418.11 2.181.11-.327-.546-.545-1.092-.763-1.637 0-.11-.11-.11-.218-.11h-1.746c.11.546.219.982.437 1.419-.11.218 0 .218.109.218zM7.963 5.564l-1.527-.982L5.127 5.89l1.637 1.09 1.2-1.417zm4.91 10.363c0 .11 0 .11.109.218.545.219 1.09.437 1.745.764 0-.436 0-.873-.109-1.309 0-.11 0-.218-.109-.218-.545-.327-.982-.546-1.636-.873v1.418zm-.546-13.963l-1.09-1.2-1.419.654c.328.437.655.873 1.091 1.31l1.418-.764zm.655 9.49c0 .11 0 .219.109.328.436.327.873.764 1.309 1.2.11-.436.218-.873.327-1.31 0-.108 0-.218-.109-.218-.327-.327-.764-.545-1.2-.872-.109.218-.218.545-.436.872zM15.49 1.31h1.636c-.109-.436-.109-.654-.109-.982 0-.109-.109-.218-.218-.218C16.254.11 15.71 0 15.164 0c.109.436.109.764.218 1.09-.11.11 0 .22.109.22zm.654 6.873v-.218c-.327-.328-.545-.764-.872-1.2l-.873.872 1.31 1.419c.108-.328.217-.546.435-.873zm.982-2.837l.546.982c.873-.436.873-.545.654-1.2l-1.2.218zm4.037-3.163c.327.218.763.436 1.09.654l.219-.436c-.437-.327-.873-.655-1.418-1.09v.545c.109.109.109.327.109.327zm-.437 3.273c.437 0 .982.109 1.31.109v-.546c-.437-.109-.982-.218-1.31-.218v.655z"/>'
  }
})
