/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-pie-chart': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M9.5 0c-.1 0-.3.1-.4.1 0 .1-.1.3-.1.4v6c0 .3.2.5.5.5h6c.1 0 .3-.1.4-.1 0-.1.1-.3.1-.4 0-1.7-.7-3.4-1.9-4.6C12.9.7 11.2 0 9.5 0zm.5 6V1c1.3.1 2.5.7 3.4 1.6S14.9 4.7 15 6h-5zM6.5 3C5.2 3 4 3.4 2.9 4.1S1 5.8.5 7s-.6 2.5-.4 3.8c.3 1.2.9 2.4 1.8 3.3.9.9 2.1 1.5 3.3 1.8 1.3.2 2.6.1 3.8-.4 1.2-.5 2.2-1.3 2.9-2.4.7-1.1 1.1-2.3 1.1-3.6 0-.1-.1-.3-.1-.4-.1 0-.3-.1-.4-.1H7V3.5c0-.3-.2-.5-.5-.5zM3.4 4.9c.8-.5 1.7-.8 2.6-.9v5.5c0 .3.2.5.5.5H12c-.1.9-.4 1.8-.9 2.6-.6.9-1.5 1.6-2.5 2-1 .4-2.1.5-3.2.3s-2-.7-2.8-1.5c-.8-.8-1.3-1.7-1.5-2.8-.2-1.1-.1-2.2.3-3.2s1.1-1.9 2-2.5z"/>'
  }
})
