var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "captions",
      class: { floating: _vm.floating },
      style: _vm.captionsStyle,
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.currentCaptionsList.length > 0,
              expression: "currentCaptionsList.length > 0",
            },
          ],
          staticClass: "captions-container",
        },
        [
          _c(
            "div",
            { staticClass: "captions-content" },
            _vm._l(_vm.currentCaptionsList, function (caption, index) {
              return _c(
                "div",
                { key: "text-" + index, staticClass: "captions-text" },
                [
                  _c(
                    "span",
                    {
                      directives: [
                        {
                          name: "observe-visibility",
                          rawName: "v-observe-visibility",
                          value: {
                            callback: _vm.participantCaptionsVisibilityChanged,
                          },
                          expression:
                            "{\n            callback: participantCaptionsVisibilityChanged\n          }",
                        },
                      ],
                    },
                    [_vm._v(_vm._s(caption.text))]
                  ),
                ]
              )
            }),
            0
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }