/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-microphone-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 1.5a5.25 5.25 0 00-5.25 5.25v.75H9A.75.75 0 019 9H6.75v.75a5.25 5.25 0 0010.5 0V9H15a.75.75 0 010-1.5h2.25v-.75c0-2.9-2.35-5.25-5.25-5.25zm6.75 6.75v1.5a6.75 6.75 0 01-13.5 0v-3a6.75 6.75 0 0113.5 0v1.5zM1.5 9a.75.75 0 01.75.75c0 5.385 4.365 9.75 9.75 9.75s9.75-4.365 9.75-9.75a.75.75 0 011.5 0c0 5.961-4.637 10.84-10.5 11.225v2.275a.75.75 0 01-1.5 0v-2.275C5.386 20.59.75 15.711.75 9.75A.75.75 0 011.5 9z"/>'
  }
})
