/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-copy-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 18h15c.83 0 1.5-.67 1.5-1.5v-15A1.5 1.5 0 0016.5 0h-15A1.5 1.5 0 000 1.5v15c0 .83.67 1.5 1.5 1.5z"/><path pid="1" d="M6 24h16.5c.83 0 1.5-.67 1.5-1.5V6h-3v15H6v3z"/>'
  }
})
