var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vwc-dialog",
    {
      directives: [
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.l2.enter",
          value: _vm.close,
          expression: "close",
          modifiers: { l2: true, enter: true },
        },
      ],
      staticClass: "vvd-scheme-alternate",
      attrs: {
        "close-button": "true",
        heading: _vm.$t("disable_waiting_room_modal.heading"),
        connotation: "cta",
        open: true,
      },
    },
    [
      _c("div", { staticClass: "dialog-message" }, [
        _vm._v(
          " " +
            _vm._s(_vm.$t("disable_waiting_room_modal.dialog_message")) +
            " "
        ),
      ]),
      _c("vwc-button", {
        attrs: {
          slot: "primaryAction",
          dialogaction: "close",
          type: "submit",
          "data-cy": "got-it-btn",
          label: _vm.$t("disable_waiting_room_modal.got_it_button"),
          layout: "filled",
        },
        on: { click: _vm.close },
        slot: "primaryAction",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }