/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cloud-upload-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 .75c4.07 0 7.467 2.91 8.131 6.859 2.213.47 3.869 2.455 3.869 4.766 0 2.508-1.947 4.648-4.433 4.872a.663.663 0 01-.067.003h-6.375V10.5H16.5L12 6l-4.5 4.5h3.375v6.75H5.25C2.281 17.026 0 14.556 0 11.625a5.6 5.6 0 014.249-5.45A8.198 8.198 0 0112 .75zm-1.125 16.5v6h2.25v-6h-2.25z"/>'
  }
})
