/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-checkbox-unchecked-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21 3H3v18h18V3zM3 0a3 3 0 00-3 3v18a3 3 0 003 3h18a3 3 0 003-3V3a3 3 0 00-3-3H3z"/>'
  }
})
