export default class HostIFrameCommunication {
  constructor() {
    this.listeners = new Map();
    window.addEventListener(
      'message',
      (event) => {
        // Ignore our own events
        if (event.source === window) {
          return;
        }

        if (!this.listeners.has(event.data.name)) {
          return;
        }

        this.listeners
          .get(event.data.name)
          .forEach((listener) => listener(event.data));
      },
      false
    );
  }

  on(eventName, callback) {
    if (!this.listeners.has(eventName)) {
      this.listeners.set(eventName, []);
    }
    this.listeners.get(eventName).push(callback);
  }

  emit(eventName, data) {
    window.parent.postMessage({ name: eventName, data }, '*');
  }
}
