let detectLoudnessTimer = -1;
let turnMuteIndicationOffTimer = -1;
let indicationShownRecently = false;
const loudnessDetector = {};
const AUTO_HIDE_MUTE_INDICATION_POPUP_DELAY = 5 * 1000;
const INDICATION_INTERVAL_TIME = 30000; // Time to wait until the next indication will pop up
import { ANALYTICS } from '@/consts/global-consts';
import analytics from '@/services/analytics-service';

export default {
  turnMuteIndicationOff: ({ commit }) => {
    // User clicked the 'x' button, don't show mute indication anymore
    clearTimeout(detectLoudnessTimer);
    clearTimeout(turnMuteIndicationOffTimer);
    turnMuteIndicationOffTimer = -1;
    commit('SET_MUTE_INDICATION', false);
  },

  turnMuteIndicationOffClicked: ({ dispatch }) => {
    analytics.trackEvent(ANALYTICS.DISMISS_MUTE_INDICATION);

    dispatch('turnMuteIndicationOff');
    dispatch('turnLoudnessDetectorOff');
  },

  turnLoudnessDetectorOn: async (
    { state, getters, commit, dispatch },
    { selectedMicrophoneId, isMicEnabled }
  ) => {
    if (!getters.isLoudnessDetectorSupported) {
      return;
    }

    try {
      loudnessDetector.audioContext = new AudioContext();
      loudnessDetector.stream = await navigator.mediaDevices.getUserMedia({
        audio: {
          deviceId: selectedMicrophoneId
        }
      });

      loudnessDetector.source = loudnessDetector.audioContext.createMediaStreamSource(
        loudnessDetector.stream
      );
      loudnessDetector.analyser = loudnessDetector.audioContext.createAnalyser();

      loudnessDetector.source.connect(loudnessDetector.analyser);

      loudnessDetector.analyser.fftSize = 2048;
      const bufferLength = loudnessDetector.analyser.fftSize;
      const timeDomainData = new Uint8Array(bufferLength);

      const detectLoudness = () => {
        // Keep running only if audio is not enabled and we have a valid analyser
        if (isMicEnabled || !loudnessDetector.analyser) {
          return;
        }

        // Only process the audio if the tab is visible and the popup was not recently dismissed
        if (document.visibilityState === 'visible') {
          loudnessDetector.analyser.getByteTimeDomainData(timeDomainData);

          let max = 0;
          for (let index = timeDomainData.length - 1; index >= 0; index -= 1) {
            max = Math.max(max, Math.abs(timeDomainData[index] - 128));
          }
          const loudness = max / 128;
          // 0.2 is a magical number, it can't be calculated. it was achieved by trial and error.
          if (loudness > 0.3 && !indicationShownRecently) {
            // If we are just now turning on the mute indication, set a timer to turn it off
            // in AUTO_HIDE_MUTE_INDICATION_POPUP_DELAY seconds
            if (!state.muteIndication && turnMuteIndicationOffTimer === -1) {
              // Turn loudness detection off for DELAY_BETWEEN_MUTE_INDICATION_POPUP seconds
              dispatch('turnLoudnessDetectorOff');
              turnMuteIndicationOffTimer = setTimeout(() => {
                dispatch('turnMuteIndicationOff');

                // TODO: try to decouple from outer module
                dispatch('toggleLoudnessDetector', undefined, {
                  root: true
                });
              }, AUTO_HIDE_MUTE_INDICATION_POPUP_DELAY);
            }
            indicationShownRecently = true;
            setTimeout(() => {
              indicationShownRecently = false;
            }, INDICATION_INTERVAL_TIME);
            commit('SET_MUTE_INDICATION', true);
          }
        }

        detectLoudnessTimer = setTimeout(detectLoudness, 200);
      };

      detectLoudness();
    } catch (e) {
      // An error has occured, we don't want to bother the user, the only affect
      // is, the user won't see the mute indication
    }
  },

  turnLoudnessDetectorOff: ({ dispatch }) => {
    // Check if we have a valid loudnessDetector object before destroying it
    if (loudnessDetector.analyser) {
      loudnessDetector.analyser.disconnect();
      loudnessDetector.source.disconnect();
      loudnessDetector.stream.getTracks().forEach((track) => track.stop());
      loudnessDetector.audioContext.close();
      loudnessDetector.analyser = null;
      loudnessDetector.source = null;
      loudnessDetector.stream = null;
      loudnessDetector.audioContext = null;
      dispatch('turnMuteIndicationOff');
    }
  }
};
