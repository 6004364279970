/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-ai-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 6v3h-3v6h3v3h-3v1.5a1.5 1.5 0 01-1.5 1.5H18v3h-3v-3H9v3H6v-3H4.5A1.5 1.5 0 013 19.5V18H0v-3h3V9H0V6h3V4.5A1.5 1.5 0 014.5 3H6V0h3v3h6V0h3v3h1.5A1.5 1.5 0 0121 4.5V6h3zM6 18h12V6H6v12zm3-9h6v6H9V9z"/>'
  }
})
