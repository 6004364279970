/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-kotlin-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M12.05 0L0 12.672V24l12.032-12.054L24 0H12.05z" _fill="url(#paint0_linear)"/><path pid="1" d="M0 24l12.032-12.053L24 24H0z" _fill="url(#paint1_linear)"/><path pid="2" d="M0 0h12.05L0 12.672V0z" _fill="url(#paint2_linear)"/></g><defs><linearGradient id="paint0_linear" x1="-4.041" y1="22.066" x2="18.293" y2="-.268" gradientUnits="userSpaceOnUse"><stop offset=".108" stop-color="#C757BC"/><stop offset=".173" stop-color="#CD5CA9"/><stop offset=".492" stop-color="#E8744F"/><stop offset=".716" stop-color="#F88316"/><stop offset=".823" stop-color="#FF8900"/></linearGradient><linearGradient id="paint1_linear" x1="7.659" y1="28.33" x2="18.545" y2="17.445" gradientUnits="userSpaceOnUse"><stop offset=".296" stop-color="#00AFFF"/><stop offset=".694" stop-color="#5282FF"/><stop offset="1" stop-color="#945DFF"/></linearGradient><linearGradient id="paint2_linear" x1="-18.087" y1="437.19" x2="485.272" y2="-41.47" gradientUnits="userSpaceOnUse"><stop offset=".296" stop-color="#00AFFF"/><stop offset=".694" stop-color="#5282FF"/><stop offset="1" stop-color="#945DFF"/></linearGradient><clipPath id="clip0"><path pid="3" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
