/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-table-1': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.9 4.9c.7 0 1.1-.4 1.1-1.1V1.6c0-.7-.4-1.1-1.1-1.1H1.1C.4.5 0 .9 0 1.6v2.2c0 .7.4 1.1 1.1 1.1h6.3V9H0v1.3h7.4v5.2H0V17h7.4v5.1H0v1.4h24v-1.4h-7.5V17H24v-1.4h-7.5v-5.1H24V9h-7.5V4.9h6.4zm-14.1 0H15V9H8.8V4.9zm6.4 17.2H8.8V17h6.3v5.1zm0-6.7H8.8v-5.1h6.3v5.1z"/>'
  }
})
