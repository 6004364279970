/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-code-snippet-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 0h-21A1.5 1.5 0 000 1.5v21A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-21A1.5 1.5 0 0022.5 0zM3.44 16.94L8.378 12l-4.94-4.94 2.122-2.12L12.62 12l-7.06 7.06-2.122-2.12zM19.5 19.5H12v-3h7.5v3zm0-6H15v-3h4.5v3zm0-6H12v-3h7.5v3z"/>'
  }
})
