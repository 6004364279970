/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-heart-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.023 3.477a6.751 6.751 0 00-9.546 0c-.181.181-.321.388-.477.583-.156-.195-.296-.402-.477-.583a6.751 6.751 0 00-9.546 9.546L12 22.5l10.023-9.477a6.751 6.751 0 000-9.546z"/>'
  }
})
