/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-outbound-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.564 3.12h-4.901V0H23.89v10.229h-3.12V5.327L13.899 12.2l-2.206-2.207 6.871-6.871z"/><path pid="1" d="M3.922.458A1.923 1.923 0 015.174.55c.447.193.766.564.997.949l1.54 2.425c.857 1.349 1.37 2.683 1.03 3.825-.222.8-.59 1.551-1.256 1.947-.01.052-.018.177.032.407l.005.02c.083.437.39 1.052 1.055 1.86.698.85 1.213 1.438 1.554 1.782.36.363.952.887 1.792 1.58.8.66 1.418.984 1.865 1.087.216.047.335.041.386.033.387-.663 1.126-1.05 1.922-1.296l.02-.006c.584-.164 1.206-.1 1.82.088.615.19 1.276.522 1.974.968 1.274.814 2.093 1.347 2.426 1.584.377.233.734.551.921.992.173.407.193.834.09 1.25l-.011.047-.45 1.216c-.383 1.033-1.167 1.793-2.231 2.305l-.012.006c-.997.462-2.165.48-3.424.182-1.229-.29-2.489-.783-3.78-1.466a29.021 29.021 0 01-3.73-2.365c-1.201-.893-2.236-1.774-3.098-2.644-.862-.87-1.735-1.914-2.62-3.126a29.355 29.355 0 01-2.343-3.763C.971 9.136.484 7.866.197 6.63-.098 5.36-.08 4.188.376 3.187l.005-.012C.887 2.105 1.64 1.312 2.67.925L3.874.47l.048-.012z"/>'
  }
})
