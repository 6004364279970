<template>
  <vwc-3-dialog
    ref="safariNotificationDialog"
    class="vvd-root vvd-theme-alternate"
  >
    <vwc-3-empty-state slot="main" class="safari-notification-empty-state">
      <img slot="graphic" src="/illustrations/safari-notification.svg" />
      <p class="heading4 tight">
        {{ $t('safari_notification_modal.title') }}
      </p>
      <p class="safari-notification-description" v-html="text"></p>
      <vwc-3-button
        slot="action-items"
        :label="$t('safari_notification_modal.close_button')"
        appearance="filled"
        @click="closeDialog()"
      ></vwc-3-button>
    </vwc-3-empty-state>
  </vwc-3-dialog>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'SafariNotificationModal',

  data() {
    return {
      dialog: null
    };
  },

  computed: {
    ...mapGetters(['isVCPRoom']),

    text() {
      if (this.isVCPRoom) {
        return this.$t('safari_notification_modal.vcp_text');
      } else {
        return this.$t('safari_notification_modal.vbc_text');
      }
    }
  },

  mounted() {
    this.dialog = this.$refs.safariNotificationDialog;
    // Show the modal after the page is loaded
    setTimeout(() => {
      this.dialog.showModal();
    }, 700);
  },

  methods: {
    closeDialog() {
      this.dialog.close();
    }
  }
};
</script>
<style scoped>
vwc-3-dialog {
  --dialog-max-inline-size: 609px;
}
.safari-notification-empty-state {
  display: block;
  margin: 24px;
}
.safari-notification-description {
  margin-block: 0;
}
</style>
