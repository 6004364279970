/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-phone-garland-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.8 9.374s.745-3.11.592-3.374a1.133 1.133 0 00-.7-.531l-2.517-.644-.644-2.517a1.133 1.133 0 00-1.402-.81l-2.503.701L12.81.341a1.133 1.133 0 00-1.62 0L9.374 2.199l-2.502-.7a1.132 1.132 0 00-1.403.81l-.644 2.516-2.517.644a1.133 1.133 0 00-.81 1.403l.701 2.502L.341 11.19a1.133 1.133 0 000 1.62l1.858 1.816-.701 2.502a1.134 1.134 0 00.81 1.403l2.517.644.644 2.517a1.133 1.133 0 001.403.81l2.502-.701 1.816 1.858a1.134 1.134 0 001.62 0l1.816-1.858 2.502.701a1.133 1.133 0 001.403-.81l.644-2.517 2.517-.644a1.133 1.133 0 00.81-1.403l-.701-2.502 1.858-1.816a1.133 1.133 0 000-1.62l-1.858-1.816zM9.067 6.819a.864.864 0 00-.557-.04l-.021.004-.541.202c-.46.172-.798.524-1.026 1l-.002.006c-.205.446-.213.969-.08 1.533.128.55.347 1.116.651 1.695.301.573.652 1.13 1.051 1.673.398.54.79 1.004 1.176 1.39.387.387.851.78 1.39 1.177.543.4 1.101.75 1.675 1.05a7.19 7.19 0 001.694.653c.564.132 1.087.124 1.533-.08l.005-.003c.477-.228.829-.565 1-1.026l.203-.54.005-.022a.863.863 0 00-.04-.557.995.995 0 00-.414-.442c-.15-.105-.517-.342-1.088-.704-.602-.381-1.193-.61-1.698-.47l-.01.003c-.356.11-.687.281-.86.576a.487.487 0 01-.178-.014c-.202-.046-.48-.19-.84-.484a11.677 11.677 0 01-.804-.703 12.64 12.64 0 01-.697-.793c-.299-.36-.436-.633-.474-.826l-.002-.01a.487.487 0 01-.015-.178c.299-.176.464-.51.564-.866.079-.262.054-.54-.029-.816a3.622 3.622 0 00-.433-.888L9.513 7.24c-.104-.172-.247-.336-.447-.421z"/>'
  }
})
