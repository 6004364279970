/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-virtual-voicemail': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.75 22.5H1.5v-21h18V12c0 .45.3.75.75.75s.75-.3.75-.75V.75c0-.45-.3-.75-.75-.75H.75C.3 0 0 .3 0 .75v22.5c0 .45.3.75.75.75h3c.45 0 .75-.3.75-.75s-.3-.75-.75-.75zM16.5 5.25c0-.45-.3-.75-.75-.75H5.25c-.45 0-.75.3-.75.75s.3.75.75.75h10.5c.45 0 .75-.3.75-.75zm-12 4.5c0-.45.3-.75.75-.75h8.25c.45 0 .75.3.75.75s-.3.75-.75.75H5.25c-.45 0-.75-.3-.75-.75zm3.75 4.5c0-.45-.3-.75-.75-.75H5.25c-.45 0-.75.3-.75.75s.3.75.75.75H7.5c.45 0 .75-.3.75-.75zm8.25 6c0-2.1 1.65-3.75 3.75-3.75S24 18.15 24 20.25 22.35 24 20.25 24h-9c-2.1 0-3.75-1.65-3.75-3.75s1.65-3.75 3.75-3.75S15 18.15 15 20.25c0 .9-.3 1.65-.75 2.25h3c-.45-.6-.75-1.35-.75-2.25zm-7.5 0c0 1.2 1.05 2.25 2.25 2.25s2.25-1.05 2.25-2.25S12.45 18 11.25 18 9 19.05 9 20.25zm9 0c0 1.2 1.05 2.25 2.25 2.25s2.25-1.05 2.25-2.25S21.45 18 20.25 18 18 19.05 18 20.25z"/>'
  }
})
