/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-twitch': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5.786 0L1.5 4.286v15.428h5.143V24l4.286-4.286h3.428L22.071 12V0H5.786zm14.571 11.143L16.93 14.57H13.5l-3 3v-3H6.643V1.714h13.714v9.429zm-2.571-6.429H16.07v5.143h1.715V4.714zm-6.429 0h1.714v5.143h-1.714V4.714z"/>'
  }
})
