/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'spinner-mask-small': {
    width: 25,
    height: 24,
    viewBox: '0 0 25 24',
    data: '<path pid="0" d="M13 22.5a1.5 1.5 0 010-3 7.5 7.5 0 10-3.06-.65 1.5 1.5 0 11-1.224 2.739A10.5 10.5 0 012.5 12C2.5 6.201 7.201 1.5 13 1.5S23.5 6.201 23.5 12 18.799 22.5 13 22.5z" fill-rule="nonzero"/>'
  }
})
