<template>
  <div v-if="inSessionParticipants.length">
    <transition name="fade">
      <div
        v-if="waitingParticipantsCount > 0"
        data-cy="waiting-participants-wrapper"
        class="waiting-participants-wrapper"
      >
        <div class="waiting-participants-header">
          <vwc-text
            font-face="body-2-bold"
            data-cy="waiting-participants-text"
            class="waiting-participants-text Vlt-black unselectable"
          >
            {{
              $t('participants_list.waiting_participants_text', {
                waitingParticipantsCount
              })
            }}
          </vwc-text>

          <vwc-button
            v-show="waitingParticipantsCount > 1"
            class="approve-all-button"
            data-cy="approve-all-button"
            :label="$t('participants_list.approve_all_button')"
            layout="filled"
            connotation="cta"
            dense
            :disabled="isApproveAllInProgress"
            @click="approveAllWaitingRoomParticipants"
          >
            <vwc-circular-progress
              v-if="isApproveAllInProgress"
              class="button-loading-spinner"
              indeterminate
              density="-7"
            />
          </vwc-button>
        </div>
        <div class="waiting-participants" data-cy="waiting-participants">
          <transition-group name="waiting-room-list-transition">
            <WaitingRoomParticipantRow
              v-for="participant in waitingParticipants"
              :key="participant.participantId"
              class="waiting-room-participant-row-container"
              :participant="participant"
              :disableButtons="
                isApproveAllInProgress ||
                participant.isApproveInProgress ||
                participant.isDenyInProgress
              "
              :maxDisplayNameLength="20"
              :undoWindowDuration="UNDO_WINDOW_DURATION"
              :denyButtonLabel="
                $t('participants_list.waiting_room_deny_button')
              "
              :approveButtonLabel="
                $t('participants_list.waiting_room_approve_button')
              "
              :undoButtonLabel="
                $t('participants_list.waiting_room_undo_button')
              "
              @updateParticipantStatus="updateWaitingRoomParticipantStatus"
              @undoDeny="undoDeny"
            />
          </transition-group>
        </div>
      </div>
    </transition>

    <vwc-expansion-panel
      data-cy="active-participants-panel"
      :header="
        $t('participants_list.active_participants_panel_header', {
          inSessionParticipantsCount: `${inSessionParticipants.length}`
        })
      "
      class="participants-accordion"
      open
      leadingtoggle
      heading
      dense
      icon
      indicatoriconset="chevron"
    >
      <div class="active-participants">
        <ParticipantRow
          v-for="participant in inSessionParticipants"
          :key="participant.participantId"
          :class="{
            'my-participant': participant.participantId === myParticipantId,
            'owner-participant':
              !isSessionOwner && participant.participantId === owner,
            'raised-hand-participant': participant.participantId in raisedHands
          }"
          :participant="participant"
          :micVolume="participantsVolumes[participant.participantId]"
        />
      </div>
    </vwc-expansion-panel>

    <template v-if="offSessionParticipants.length">
      <vwc-expansion-panel
        data-cy="inactive-participants-panel"
        :header="
          $t('participants_list.inactive_participants_panel_header', {
            offSessionParticipantsCount: `${offSessionParticipants.length}`
          })
        "
        class="participants-accordion"
        leadingtoggle
        heading
        dense
        icon
        indicatoriconset="chevron"
      >
        <div class="inactive-participants">
          <ParticipantRow
            v-for="participant in offSessionParticipants"
            :key="participant.participantId"
            :participant="participant"
            :micVolume="0"
          />
        </div>
      </vwc-expansion-panel>
    </template>
  </div>

  <div v-else class="spinner-container unselectable">
    <v-spinner />
    <div>{{ $t('participants_list.loading_participants_text') }}</div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import ParticipantRow from '@/components/rows/ParticipantRow.vue';
import { PARTICIPANT_STATE_TYPES, ANALYTICS } from '@/consts/global-consts';
import analytics from '@/services/analytics-service';
import { UNDO_WINDOW_DURATION } from '@/store/waitingRoom/consts';

const compareDisplayName = (participantA, participantB) =>
  (participantA.displayName || '').localeCompare(
    participantB.displayName || ''
  );

export default {
  name: 'ParticipantsList',

  components: {
    ParticipantRow
  },

  data() {
    return {
      UNDO_WINDOW_DURATION
    };
  },

  computed: {
    ...mapState([
      'participantsVolumes',
      'contacts',
      'owner',
      'myParticipantId'
    ]),
    ...mapState('waitingRoom', [
      'waitingParticipants',
      'isApproveAllInProgress'
    ]),
    ...mapState('raiseHand', ['raisedHands']),
    ...mapGetters(['participants', 'isSessionOwner', 'activeParticipants']),

    inSessionParticipants() {
      return [...this.activeParticipants].sort(compareDisplayName);
    },

    offSessionParticipants() {
      return Object.values(this.participants)
        .filter((participant) => {
          if (participant.state === PARTICIPANT_STATE_TYPES.JOINED) {
            return false;
          }
          if (
            participant.participantId === this.owner &&
            participant.state === PARTICIPANT_STATE_TYPES.INVITED
          ) {
            return false;
          }

          // hide extensions that are invited and already joined with pstn
          if (participant.type === 'Application') {
            const contact = this.contacts.find(
              (contact) => contact.id === participant.participantId
            );
            if (!contact) {
              return true;
            }
            const extensionNumber = contact.extensionNumber;
            const pstnExtension = this.inSessionParticipants.find(
              (inSessionParticipants) =>
                inSessionParticipants.displayName === extensionNumber
            );
            return !pstnExtension;
          }
          if (participant.type === 'Pstn') {
            const contact = this.contacts.find(
              (contact) => contact.extensionNumber === participant.displayName
            );
            return !contact;
          }
          return true;
        })
        .sort(compareDisplayName);
    },

    waitingParticipantsCount() {
      return this.waitingParticipants.length;
    }
  },

  methods: {
    ...mapActions('waitingRoom', {
      _approveAllWaitingRoomParticipants: 'approveAllWaitingRoomParticipants',
      _updateWaitingRoomParticipantStatus: 'updateWaitingRoomParticipantStatus',
      _undoDeny: 'undoDeny'
    }),

    async updateWaitingRoomParticipantStatus({ participant, isApproval }) {
      await this._updateWaitingRoomParticipantStatus({
        participantId: participant.participantId,
        isApproval,
        source: 'Participants tab',
        isUndoAllowed: true
      });
    },

    async approveAllWaitingRoomParticipants() {
      await this._approveAllWaitingRoomParticipants();

      analytics.trackEvent(ANALYTICS.WAITING_ROOM_RESPONSES, {
        Status: 'Approve all',
        Source: 'Participants tab'
      });
    },

    async undoDeny({ participant }) {
      await this._undoDeny({ participantId: participant.participantId });
    }
  }
};
</script>

<style scoped>
.spinner-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.waiting-participants-header,
.active-participants-header {
  display: flex;
  align-items: center;
  padding: 0px 10px 2px 16px;
}

.waiting-participants-text {
  flex: 1;
}

.waiting-participants,
.active-participants {
  display: flex;
  flex-direction: column;
}

.waiting-participants-wrapper {
  padding: 16px 0;
}

.waiting-participants-wrapper,
.participants-accordion:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

.waiting-room-participant-row-container {
  padding: 10px 10px 10px 16px;
}

.my-participant {
  order: -3;
}

.owner-participant {
  order: -2;
}

.raised-hand-participant:not(.owner-participant):not(.my-participant) {
  order: -1;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.25s;
}
.fade-leave-active {
  transition-delay: 250ms;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.waiting-room-list-transition-enter-active,
.waiting-room-list-transition-leave-active {
  transition: opacity 0.3s;
}

.waiting-room-list-transition-leave-active {
  transition-delay: 700ms;
}

.waiting-room-list-transition-enter,
.waiting-room-list-transition-leave-to {
  opacity: 0;
}

.active-participants,
.inactive-participants {
  margin-left: -32px;
}

.button-loading-spinner {
  margin-left: 4px;
}
</style>
