/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-volume-on-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 2.25a.75.75 0 00-1.166-.624L8.023 7.5H.75a.75.75 0 00-.75.75v9c0 .414.336.75.75.75h7.273l8.811 5.874A.75.75 0 0018 23.25v-21zM8.666 8.874L16.5 3.651V21.85l-7.834-5.223a.75.75 0 00-.416-.126H1.5V9h6.75a.75.75 0 00.416-.126z"/>'
  }
})
