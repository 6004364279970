/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-phone-tick': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.3 3.45C6.15 3.3 5.85 3 5.4 3h-.3l-.104.032c-.659.2-3 .908-2.296 3.868.6 3.3 2.85 6.45 5.55 9.3 3 3.15 7.05 5.4 9.45 5.4h.45c2.25-.45 3.15-3.15 2.4-3.75-2.1-1.65-3.15-2.25-4.2-2.25h-.45c-1.2.15-1.65 1.35-1.65 1.35s-2.1-.45-4.35-2.85c-2.55-2.55-2.85-4.35-2.85-4.35.9-.6 1.35-1.2 1.35-2.25S6.75 4.05 6.3 3.45zM4.8 1.5h.6c.9 0 1.65.45 2.1 1.05C8.55 4.05 9.9 6 9.9 7.35c.15 1.35-.45 2.25-1.05 2.85.3.75.9 1.65 2.1 2.85.919 1.05 1.838 1.64 2.355 1.973l.195.127c.45-.45 1.05-.9 1.95-1.05h.75c1.5 0 2.85.75 5.1 2.55.75.6 1.05 1.65.75 2.85-.45 1.65-1.8 3.3-3.9 3.6h-.6c-2.85 0-7.35-2.55-10.5-5.85-3.3-3.6-5.25-6.9-5.85-10.05-.6-2.85.75-4.95 3.6-5.7zm11.754 10.544L23.55 4.51l-1.1-1.02-6.004 6.466-3.452-3.02-.988 1.129 4.548 3.979z"/>'
  }
})
