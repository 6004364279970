var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mic-indication", style: _vm.volumeStyle }, [
    _c(
      "svg",
      {
        staticClass: "volume-svg",
        style: { fill: _vm.color },
        attrs: { viewBox: "173.328 51.005 13 16" },
      },
      [
        _c("path", {
          attrs: {
            d:
              "M 5.5 9.6 C 5.5 13.134 8.41 16 12 16 C 15.59 16 18.5 13.134 18.5 9.6 L 18.5 6.4 C 18.5 2.866 15.59 0 12 0 C 8.41 0 5.5 2.866 5.5 6.4 L 5.5 9.6 Z",
            transform: "matrix(1, 0, 0, 1, 167.827957, 51.005409)",
          },
        }),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }