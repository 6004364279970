/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-history-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13 2.25c-5.384 0-9.75 4.366-9.75 9.75H0l4.214 4.214.076.152L8.667 12h-3.25A7.578 7.578 0 0113 4.417 7.578 7.578 0 0120.583 12 7.578 7.578 0 0113 19.583a7.522 7.522 0 01-5.352-2.231L6.11 18.89A9.7 9.7 0 0013 21.75c5.384 0 9.75-4.366 9.75-9.75S18.384 2.25 13 2.25zm-1.083 5.417v5.416l4.636 2.752.78-1.31-3.791-2.254V7.667h-1.625z"/>'
  }
})
