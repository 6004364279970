/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-eye-negative': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 14c3.6 0 6.4-3.1 7.6-4.9.5-.7.5-1.6 0-2.3C14.4 5.1 11.6 2 8 2 4.4 2 1.6 5.1.4 6.9c-.5.7-.5 1.6 0 2.2C1.6 10.9 4.4 14 8 14zm0-9c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3 1.3-3 3-3z"/>'
  }
})
