<template>
  <vwc-dialog
    v-observe-keyboard.l2.enter="onConfirm"
    :open="open"
    class="vvd-scheme-alternate"
    :style="dialogStyle"
    :topposition="topposition"
    :hideactions="hideActions"
    @closed="closeDialog"
  >
    <div class="dialog-container">
      <div
        class="header-container"
        :class="{ 'small-modal': isSmallModal, 'big-modal': !isSmallModal }"
      >
        <div class="heading-wrapper">
          <vwc-icon v-if="iconName" class="icon" :type="iconName" />
          <div class="title-wrapper">
            <div class="title" data-cy="dialog-title">{{ heading }}</div>
            <div v-if="subtitle" class="subtitle">
              {{ subtitle }}
            </div>
          </div>
        </div>
        <vwc-icon
          data-cy="dismiss-button"
          class="dismiss-button"
          type="close-solid"
          size="small"
          @click="closeDialog"
        />
      </div>
      <slot name="content">
        <div>{{ content }}</div>
      </slot>
    </div>
    <slot name="footer-buttons">
      <vwc-button
        v-if="primaryAction"
        slot="primaryAction"
        layout="filled"
        :dialogaction="primaryActionDialogAction"
        type="submit"
        data-cy="main-action-btn"
        :icon="primaryButtonIcon"
        :connotation="primaryButtonConnotation"
        :disabled="disablePrimaryButton"
        @click="onConfirm"
      >
        {{ primaryAction }}
        <vwc-circular-progress
          v-if="loadingPrimaryButton"
          class="primary-btn-loading-spinner"
          indeterminate
          density="-8"
        />
      </vwc-button>

      <vwc-button
        v-if="secondaryAction"
        slot="secondaryAction"
        layout="outlined"
        dialogaction="cancel"
        type="submit"
        data-cy="cancel-btn"
      >
        {{ secondaryAction }}
      </vwc-button>
    </slot>
  </vwc-dialog>
</template>

<script>
export default {
  name: 'Dialog',

  props: {
    heading: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: null
    },
    iconName: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    },
    closeButton: {
      type: Boolean,
      default: true
    },
    primaryButtonConnotation: {
      type: String,
      default: 'primary'
    },
    primaryAction: {
      type: String,
      default() {
        return this.$t('dialog.primary_action_button');
      }
    },
    secondaryAction: {
      type: String,
      default() {
        return this.$t('dialog.secondary_action_button');
      }
    },
    close: {
      type: Function,
      default: undefined
    },
    minWidth: {
      type: Number,
      default: undefined
    },
    topposition: {
      type: String,
      default: 'undefined'
    },
    primaryButtonIcon: {
      type: String,
      default: ''
    },
    disablePrimaryButton: {
      type: Boolean,
      default: false
    },
    isSmallModal: {
      type: Boolean,
      default: false
    },
    primaryActionDialogAction: {
      type: String,
      default: 'discard'
    },
    loadingPrimaryButton: {
      type: Boolean,
      default: false
    },
    hideActions: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      open: true
    };
  },

  computed: {
    dialogStyle() {
      if (!this.minWidth) {
        return;
      }
      return {
        '--mdc-dialog-min-width': `${this.minWidth}px`
      };
    }
  },

  methods: {
    closeDialog() {
      if (this.close) {
        this.close();
      }
      this.open = false;
    },

    onConfirm() {
      this.$emit('applied');
    }
  }
};
</script>

<style scoped>
.dialog-container {
  overflow: hidden;
}
.header-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.small-modal {
  margin-bottom: 16px;
}

.big-modal {
  margin-bottom: 24px;
}
.heading-wrapper {
  display: flex;
  flex-direction: row;
  align-self: flex-end;
}

.title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}
.subtitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.icon {
  margin-right: 16px;
  align-self: flex-start;
}
.dismiss-button {
  margin-left: 16px;
  align-self: flex-start;
  cursor: pointer;
}
.dismiss-button:hover {
  color: #666666;
}
.primary-btn-loading-spinner {
  margin-left: 8px;
  --vvd-color-primary: #929292;
}
vwc-dialog {
  --mdc-dialog-max-width: 100%;
  --mdc-dialog-z-index: 100000;
}
</style>
