import copyTextToClipboard from 'copy-text-to-clipboard';

class ClipboardService {
  copy(text) {
    // The `${text}` is used to make sure we pass a string to the copy-text-to-clipboard package, which
    // will ignore Number, Boolean, etc
    copyTextToClipboard(`${text}`);
  }
}

export const clipboardService = window.Cypress
  ? window.ClipboardServiceMock || new ClipboardService()
  : new ClipboardService();
