/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-3-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 9C9.45 9 7.5 7.05 7.5 4.5S9.45 0 12 0s4.5 1.95 4.5 4.5S14.55 9 12 9zM15 24H9v-6l-3-3v-4.5h12V15l-3 3v6zM3 7.5c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3zM4.5 15.6V9H0v4.5L1.5 15v6H6v-3.9l-1.5-1.5zM21 7.5c1.65 0 3-1.35 3-3s-1.35-3-3-3-3 1.35-3 3 1.35 3 3 3zM19.5 15.6V9H24v4.5L22.5 15v6H18v-3.9l1.5-1.5z"/>'
  }
})
