/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-gear': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7 0a.5.5 0 00-.493.418l-.311 1.865a5.957 5.957 0 00-.963.398L3.694 1.583a.5.5 0 00-.644.053L1.636 3.05a.5.5 0 00-.053.644l1.098 1.54c-.16.306-.293.627-.398.962l-1.865.31A.5.5 0 000 7v2a.5.5 0 00.418.493l1.865.311c.105.335.239.656.398.963l-1.098 1.539a.5.5 0 00.053.644l1.414 1.414a.5.5 0 00.644.053l1.54-1.098c.306.16.627.293.962.398l.31 1.865A.5.5 0 007 16h2a.5.5 0 00.493-.418l.311-1.865c.335-.105.656-.239.963-.398l1.538 1.099a.5.5 0 00.645-.053l1.414-1.414a.5.5 0 00.053-.644l-1.098-1.54c.16-.307.293-.628.398-.963l1.865-.31A.5.5 0 0016 9V7a.5.5 0 00-.418-.493l-1.865-.311a5.955 5.955 0 00-.398-.963l1.099-1.538a.5.5 0 00-.054-.645L12.95 1.636a.5.5 0 00-.643-.053l-1.54 1.098a5.958 5.958 0 00-.962-.398L9.494.418A.5.5 0 009 0H7zm.13 2.76L7.424 1h1.152l.294 1.76a.5.5 0 00.37.401c.465.12.903.302 1.305.54a.5.5 0 00.546-.022l1.451-1.036.816.815L12.32 4.91a.5.5 0 00-.023.546c.239.402.421.84.54 1.306a.5.5 0 00.403.37L15 7.423v1.152l-1.76.294a.5.5 0 00-.401.37c-.12.465-.302.903-.54 1.305a.5.5 0 00.022.545l1.036 1.453-.815.815-1.451-1.037a.5.5 0 00-.546-.023c-.402.239-.84.421-1.306.54a.5.5 0 00-.37.403L8.577 15H7.424l-.294-1.76a.5.5 0 00-.37-.401 4.964 4.964 0 01-1.305-.54.5.5 0 00-.545.022l-1.452 1.036-.815-.815 1.036-1.451a.5.5 0 00.023-.546 4.963 4.963 0 01-.54-1.306.5.5 0 00-.403-.37L1 8.577V7.424l1.76-.294a.5.5 0 00.401-.37c.12-.465.302-.903.54-1.305a.5.5 0 00-.022-.545L2.643 3.458l.815-.815L4.91 3.679a.5.5 0 00.545.023c.402-.239.84-.421 1.306-.54a.5.5 0 00.37-.403zM6 8a2 2 0 114 0 2 2 0 01-4 0zm2-3a3 3 0 100 6 3 3 0 000-6z"/>'
  }
})
