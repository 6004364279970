import store from '@/store';
import { sessionStorageService } from '@/services/storage-service';

export function beforeEnterRouteTo(
  to,
  from,
  next,
  routeTo = 'Home',
  replaceRoute = false
) {
  next({
    name: routeTo,
    params: to.params,
    query: to.query,
    replace: replaceRoute
  });
}

export function beforeEnterMessageScreen(
  to,
  from,
  next,
  fallbackRouteName = 'Home'
) {
  if (store.state.globalMessage.message) {
    next();
  } else {
    sessionStorageService.clear();
    beforeEnterRouteTo(to, from, next, fallbackRouteName);
  }
}

export function beforeEnterThankYouMessage(
  to,
  from,
  next,
  fallbackRouteName = 'Home'
) {
  if (store.state.showThankYouScreen) {
    next();
  } else {
    sessionStorageService.clear();
    beforeEnterRouteTo(to, from, next, fallbackRouteName);
  }
}
