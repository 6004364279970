/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calendar-add': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5.75a.75.75 0 00-1.5 0V3H2.25A2.25 2.25 0 000 5.25v16.5A2.25 2.25 0 002.25 24h7.5a.75.75 0 000-1.5h-7.5a.75.75 0 01-.75-.75V9h21.75a.75.75 0 00.75-.75v-3A2.25 2.25 0 0021.75 3H18V.75a.75.75 0 00-1.5 0V3h-9V.75zM6.75 4.5h15a.75.75 0 01.75.75V7.5h-21V5.25a.75.75 0 01.75-.75h4.5zM12 17.25a5.25 5.25 0 1110.5 0 5.25 5.25 0 01-10.5 0zm5.25-6.75a6.75 6.75 0 100 13.5 6.75 6.75 0 000-13.5zm0 3a.75.75 0 01.75.75v2.25h2.25a.75.75 0 010 1.5H18v2.25a.75.75 0 01-1.5 0V18h-2.25a.75.75 0 010-1.5h2.25v-2.25a.75.75 0 01.75-.75z"/>'
  }
})
