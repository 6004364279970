/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cross-bold': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M17.743 6.257a.829.829 0 00-1.2 0L12 10.8 7.457 6.257a.829.829 0 00-1.2 0 .829.829 0 000 1.2L10.8 12l-4.543 4.543a.829.829 0 000 1.2.778.778 0 00.6.257.778.778 0 00.6-.257L12 13.2l4.543 4.543a.926.926 0 00.6.257.926.926 0 00.6-.257.829.829 0 000-1.2L13.2 12l4.543-4.543a.829.829 0 000-1.2z"/>'
  }
})
