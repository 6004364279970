var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Vlt-checkbox", class: { inverted: _vm.inverted } },
    [
      _c(
        "label",
        {
          staticClass: "volta-label-override",
          class: { "align-center": _vm.multiLine },
        },
        [
          _c("span", { staticClass: "Vlt-checkbox__button" }, [
            _c("input", {
              attrs: { type: "checkbox", disabled: _vm.disabled },
              domProps: { checked: _vm.checked },
              on: {
                change: function ($event) {
                  return _vm.$emit("change", $event.target.checked)
                },
              },
            }),
            _c("span", { staticClass: "Vlt-checkbox__icon" }),
          ]),
          _c(
            "span",
            { class: { "text-ellipsis": !_vm.multiLine } },
            [_vm._t("default")],
            2
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }