<template>
  <div
    class="screen-wrapper Vlt-white mobile speaker-view"
    :class="{
      ...screenWrapperOrientationClasses,
      'card-partially-extended': isCardPartiallyExtended
    }"
    @click="handleScreenTap"
  >
    <div class="toast-container">
      <transition name="fade">
        <MobileToast />
      </transition>
    </div>
    <transition name="slide">
      <Chat v-if="isMobileChatOpen" class="slide-in-chat" />
    </transition>
    <div v-if="!hasSessionEverConnected" class="loader-wrapper unselectable">
      <vwc-circular-progress density="8" indeterminate></vwc-circular-progress>
      <span class="loading-text">{{
        $t('mobile_app_screen.loading_text')
      }}</span>
    </div>
    <div v-else class="videos-wrapper">
      <div>
        <div class="toast-container">
          <transition name="fade">
            <MobileToast />
          </transition>
        </div>
        <NoConnectionBanner v-if="!isOnline" />
        <EndMeetingModal
          v-if="isEndMeetingDialogVisible"
          :shouldCloseWindow="false"
          showCancelButton
        />
        <ConfirmationDialogModal v-if="confirmationDialog" />
        <ErrorModal v-if="errorDialog" showCancelButton />
        <InfoModal v-if="infoDialog" />
      </div>
      <ReconnectingBanner
        v-if="isNetworkIssues"
        class="network-issues-banner-container"
        :class="{ top: isOnline }"
      />
      <MobileRecordingIndication
        v-if="isRecording"
        class="recording-indication"
      />

      <ParticipantsTileList
        canFullyStretchMainStream
        preferredFitMode="cover"
        :smallStreamAspectRatio="1"
        :smallStreamsDock="smallStreamsDock"
        :updateStreamSizesWrapper="updateStreamSizesWrapper"
        @click="handleScreenTap"
      />

      <MobileCard
        ref="card"
        class="mobile-card unselectable"
        :collapsed="!showActionsCard"
        :extendable="
          !isEmbedded && orientation === MOBILE_SCREEN_ORIENTATION.PORTRAIT
        "
        @click.stop="clearHideActionsCardTimer"
        @visibility-state-changed="updateCardVisibility"
      />
    </div>
    <div class="last-message-banner" @click="bannerClicked">
      <transition name="fade">
        <MobileMessageBanner />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import ReconnectingBanner from '@/components/banners/ReconnectingBanner.vue';
import NoConnectionBanner from '@/components/banners/NoConnectionBanner.vue';
import EndMeetingModal from '@/components/modals/EndMeetingModal.vue';

import ParticipantsTileList from '@/components/ParticipantsTileList.vue';
import ConfirmationDialogModal from '@/components/modals/ConfirmationDialogModal.vue';
import ErrorModal from '@/components/modals/ErrorModal.vue';
import InfoModal from '@/components/modals/InfoModal.vue';
import MobileCard from './MobileCard';
import MobileToast from './MobileToast';
import MobileRecordingIndication from './MobileRecordingIndication';
import MeetingManager from '@/services/meeting-manager-service';
import {
  mobileScreenOrientationTracker,
  MOBILE_SCREEN_ORIENTATION
} from '@/services/mobile-screen-orientation-tracker';
import debounce from 'lodash.debounce';
import {
  CARD_VISIBILITY_STATE,
  MESSAGE_BANNER_DURATION,
  LONG_DURATION,
  TOAST_TYPE
} from '@/consts/mobile-consts';
import Chat from '@/components/Chat';
import MobileMessageBanner from './MobileMessageBanner';

const INITIAL_ACTIONS_CARD_SHOW_TIME = 5000;
let hideActionsCardTimer;

export default {
  components: {
    Chat,
    NoConnectionBanner,
    EndMeetingModal,
    ConfirmationDialogModal,
    ErrorModal,
    InfoModal,
    ReconnectingBanner,
    ParticipantsTileList,
    MobileCard,
    MobileToast,
    MobileRecordingIndication,
    MobileMessageBanner
  },

  data() {
    return {
      MOBILE_SCREEN_ORIENTATION,
      orientation: undefined,
      showActionsCard: true,
      cardVisibilityState: CARD_VISIBILITY_STATE.COLLAPSED,
      isCardPartiallyExtended: false,
      isShowHideToastDisplayed: false,
      isShowHideToastActive: false,
      updateStreamSizesWrapper: (updateStreamSizes) =>
        debounce(updateStreamSizes, 30)
    };
  },

  computed: {
    ...mapState([
      'hasSessionEverConnected',
      'isEndMeetingDialogVisible',
      'isNetworkIssues',
      'confirmationDialog',
      'errorDialog',
      'infoDialog',
      'isEmbedded',
      'isPublisherInitialized'
    ]),
    ...mapState('messaging', ['unreadMessagesCount']),
    ...mapGetters(['isOnline']),
    ...mapGetters('recordings', ['isRecording']),
    ...mapGetters('messaging', ['chatMessages']),
    ...mapState('mobile', ['banner', 'isMobileChatOpen']),

    smallStreamsDock() {
      switch (this.orientation) {
        case MOBILE_SCREEN_ORIENTATION.PORTRAIT:
          return 'top';
        case MOBILE_SCREEN_ORIENTATION.LANDSCAPE_LEFT:
          return 'left';
        case MOBILE_SCREEN_ORIENTATION.LANDSCAPE_RIGHT:
          return 'right';
        default:
          return 'top';
      }
    },

    screenWrapperOrientationClasses() {
      return {
        portrait: this.orientation === MOBILE_SCREEN_ORIENTATION.PORTRAIT,
        'landscape-left':
          this.orientation === MOBILE_SCREEN_ORIENTATION.LANDSCAPE_LEFT,
        'landscape-right':
          this.orientation === MOBILE_SCREEN_ORIENTATION.LANDSCAPE_RIGHT
      };
    },

    isAnyDialogDisplayed() {
      return this.confirmationDialog || this.infoDialog || this.errorDialog;
    }
  },

  watch: {
    unreadMessagesCount() {
      if (
        this.unreadMessagesCount > 0 &&
        this.cardVisibilityState === CARD_VISIBILITY_STATE.COLLAPSED
      ) {
        this.displayMessageBanner({
          message: this.chatMessages[this.chatMessages.length - 1],
          time: MESSAGE_BANNER_DURATION
        });
      }
    },

    isPublisherInitialized() {
      if (this.isPublisherInitialized && !this.isShowHideToastDisplayed) {
        this.setShowTimeForCardAndToast();
      }
    },

    isAnyDialogDisplayed() {
      this.setShowTimeForCardAndToast();
    }
  },

  created() {
    this.orientation = mobileScreenOrientationTracker.currentOrientation;
    mobileScreenOrientationTracker.addOrientationChangeListener(
      this.updateOrientation
    );
  },

  destroyed() {
    mobileScreenOrientationTracker.removeOrientationChangeListener(
      this.updateOrientation
    );
  },

  mounted() {
    MeetingManager.onSessionConnected(() => {
      // Show the card and the toast for a long time, until there are no dialogs on the screen, and then hide them after a few seconds
      this.displayToast({
        message: this.$t('mobile_card.show_or_hide_card_message'),
        time: LONG_DURATION,
        type: TOAST_TYPE.INFO
      });
      this.isShowHideToastActive = true;
      hideActionsCardTimer = setTimeout(() => {
        this.showActionsCard = false;
      }, LONG_DURATION);
    });
  },

  methods: {
    ...mapActions('mobile', [
      'displayMessageBanner',
      'clearMessageBanner',
      'openMobileChat',
      'clearToast',
      'displayToast'
    ]),

    handleScreenTap() {
      this.showActionsCard = !this.showActionsCard;
      this.clearHideActionsCardTimer();
      if (this.isShowHideToastActive) {
        this.clearToast();
        this.isShowHideToastActive = false;
      }
    },

    clearHideActionsCardTimer() {
      if (hideActionsCardTimer) {
        clearTimeout(hideActionsCardTimer);
      }
    },

    updateOrientation() {
      this.orientation = mobileScreenOrientationTracker.currentOrientation;
    },

    updateCardVisibility(visibilityState) {
      this.isCardPartiallyExtended =
        visibilityState === CARD_VISIBILITY_STATE.PARTIALLY_EXTENDED;

      // Don't auto-hide the card if the user chose to extend it
      if (visibilityState === CARD_VISIBILITY_STATE.FULLY_EXTENDED) {
        this.clearHideActionsCardTimer();
      }

      this.cardVisibilityState = visibilityState;
    },

    bannerClicked() {
      this.clearMessageBanner();
      this.openMobileChat();
    },

    setShowTimeForCardAndToast() {
      if (!this.isAnyDialogDisplayed && !this.isShowHideToastDisplayed) {
        this.displayToast({
          message: this.$t('mobile_card.show_or_hide_card_message'),
          time: INITIAL_ACTIONS_CARD_SHOW_TIME,
          type: TOAST_TYPE.INFO
        });
        hideActionsCardTimer = setTimeout(() => {
          this.showActionsCard = false;
          this.isShowHideToastActive = false;
        }, INITIAL_ACTIONS_CARD_SHOW_TIME);
        this.isShowHideToastDisplayed = true;
      }
    }
  }
};
</script>

<style scoped>
.screen-wrapper {
  display: flex;
  height: 100vh;
  height: var(--viewport-height);
}

.loader-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #e7ebee;
  font-size: 20px;
  font-weight: 600;
}

.loading-text {
  margin-top: 10px;
}

.videos-wrapper {
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.toast-container {
  position: fixed;
  top: 8px;
  left: 0;
  right: 0;
  margin: 0 8px;
  z-index: 99999;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}

.fade-enter-to,
.fade-leave {
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.recording-indication {
  position: fixed;
  z-index: 1000;
}

.portrait .recording-indication {
  top: 110px;
  left: 8px;
}

.landscape-left .recording-indication {
  bottom: 0;
  left: 110px;
}

.landscape-right .recording-indication {
  top: 0;
  right: 110px;
}

.mobile-card {
  z-index: 1738;
  position: fixed;
}

.portrait .mobile-card {
  width: 100%;
  bottom: 0;
}

.landscape-left .mobile-card,
.landscape-right .mobile-card {
  top: 0;
  bottom: 0;
}

.landscape-left .mobile-card {
  right: 0;
}

.landscape-right .mobile-card {
  left: 0;
}

.network-issues-banner-container.top {
  top: 8px !important;
}

.network-issues-banner-container:not(.top) {
  top: 74px !important;
}

.mobile >>> .Vlt-modal {
  height: var(--app-height);
}

.slide-in-chat {
  background-color: black;
  flex: none;
  z-index: 1739;
  position: fixed;
  height: 100%;
  width: 100%;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter,
.slide-leave-to {
  transform: translateX(100%);
}

.last-message-banner {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  bottom: 60px;
  z-index: 1737;
  transition: opacity 0.3s, transform 0.3s;
}

.portrait.card-partially-extended .last-message-banner {
  opacity: 0;
  transform: translate(-50%, -100px);
}

.landscape-left .last-message-banner,
.landscape-right .last-message-banner {
  height: 18px;
}
</style>

<!-- Global style to affect stream-name-wrapper, which is defined in the VideoStream component -->
<style>
.portrait.card-partially-extended
  .participant.main-participant.tall
  .participant-name-wrapper {
  transform: translateY(-140px);
}

.landscape-left.card-partially-extended
  .participant.main-participant
  .participant-name-wrapper {
  transform: translateX(-100px);
}

.landscape-right.card-partially-extended
  .participant.main-participant
  .participant-name-wrapper {
  transform: translateX(100px);
}
</style>
