/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-plus-bold': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 10.5h-9v-9C13.5.6 12.9 0 12 0s-1.5.6-1.5 1.5v9h-9c-.9 0-1.5.6-1.5 1.5s.6 1.5 1.5 1.5h9v9c0 .9.6 1.5 1.5 1.5s1.5-.6 1.5-1.5v-9h9c.9 0 1.5-.6 1.5-1.5s-.6-1.5-1.5-1.5z"/>'
  }
})
