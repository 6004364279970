/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-github': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.1 2.1C6.5 2.1 1.9 6.7 1.9 12.3c0 4.5 2.9 8.4 7 9.7.5.1.7-.2.7-.5v-1.9c-2.8.6-3.4-1.2-3.4-1.2C5.7 17.3 5 17 5 17c-.9-.6.1-.6.1-.6 1 .1 1.6 1.1 1.6 1.1.9 1.6 2.4 1.1 3 .8.1-.7.4-1.1.6-1.4-2.3-.3-4.7-1.1-4.7-5.1 0-1.1.4-2 1.1-2.7-.1-.3-.5-1.3.1-2.8 0 0 .9-.3 2.8 1 .8-.2 1.7-.3 2.6-.3.9 0 1.7.1 2.6.3 2-1.3 2.8-1 2.8-1 .6 1.4.2 2.5.1 2.7.7.7 1.1 1.6 1.1 2.7 0 3.9-2.4 4.8-4.7 5.1.4.3.7.9.7 1.9v2.8c0 .3.2.6.7.5 4.1-1.4 7-5.2 7-9.7-.1-5.6-4.7-10.2-10.4-10.2z"/>'
  }
})
