/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-bar-chart': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6 .5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v15a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5V.5zM7 1v14h2V1H7zm-7 9.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-5zm1 .5v4h2v-4H1zm11.5-6a.5.5 0 00-.5.5v10a.5.5 0 00.5.5h3a.5.5 0 00.5-.5v-10a.5.5 0 00-.5-.5h-3zM15 15h-2V6h2v9z"/>'
  }
})
