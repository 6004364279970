var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "dialog",
      attrs: {
        "data-cy": "mute-all-participants-modal",
        heading: _vm.$t("mute_all_participants_modal.title"),
        "icon-name": "mic-mute-line",
        close: _vm.hide,
        "min-width": 410,
        "primary-action": _vm.$t("mute_all_participants_modal.ok_button_text"),
        open: _vm.isMuteAllParticipantsModalVisible,
        isSmallModal: "",
      },
      on: {
        applied: _vm.forceMuteSessionAndHideModal,
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content", attrs: { slot: "content" }, slot: "content" },
        [
          _c("div", { staticClass: "text" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("mute_all_participants_modal.text")) + " "
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }