/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-id-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0c1.65 0 3 1.35 3 3v3H9V3c0-1.65 1.35-3 3-3zM0 10.5C0 9.6.6 9 1.5 9h21c.9 0 1.5.6 1.5 1.5v12c0 .9-.6 1.5-1.5 1.5h-21C.6 24 0 23.4 0 22.5v-12zm4.5 9h6v-6h-6v6zm9 0h6V18h-6v1.5zm0-4.5h6v-1.5h-6V15z"/>'
  }
})
