<template>
  <div class="scrollable-content-container">
    <div
      ref="scrollableContent"
      class="scrollable-content"
      :class="{ 'smooth-scroll': smoothScroll }"
      @scroll="throttledUpdateScrollToBottom"
    >
      <div ref="slotContainer">
        <slot />
      </div>
    </div>
    <transition name="fade">
      <vwc-fab
        v-if="!isScrolledToBottom"
        :extended="!!scrollButtonText"
        :label="scrollButtonText"
        mini
        class="scroll-bottom-button"
        icon="chevron-down-line"
        @click="scrollToBottom"
      ></vwc-fab>
    </transition>
  </div>
</template>

<script>
import throttle from 'lodash.throttle';

let resizeObserver;

export default {
  name: 'ScrollableContent',

  props: {
    scrollButtonText: {
      type: String,
      default: ''
    }
  },

  mounted() {
    this.scrollToBottom(false);
    this.throttledUpdateScrollToBottom = throttle(
      this.updateIsScrolledToBottom,
      150,
      { leading: false, trailing: true }
    );
    resizeObserver = new ResizeObserver(this.onSizeChange);
    resizeObserver.observe(this.$refs.slotContainer);
  },

  beforeUnmount() {
    resizeObserver.disconnect();
  },

  data() {
    return {
      isScrolledToBottom: true,
      throttledUpdateScrollToBottom: () => {},
      scrollableContentHeight: 0,
      smoothScroll: true
    };
  },

  methods: {
    updateIsScrolledToBottom() {
      const element = this.$refs.scrollableContent;
      // Sometimes scrollTop property can be a decimal number,
      // and sometimes scrollHeight - offsetHeight can be rounded up or down.
      this.isScrolledToBottom =
        element &&
        Math.floor(element.scrollTop + 1) >=
          element.scrollHeight - element.offsetHeight;
    },

    onSizeChange(entries) {
      if (entries && entries.length > 0) {
        if (
          this.scrollableContentHeight !== entries[0].contentRect.height &&
          this.isScrolledToBottom
        ) {
          this.scrollToBottom();
        }
        this.scrollableContentHeight = entries[0].contentRect.height;
      }
    },

    async scrollToBottom(smoothScroll = true) {
      this.smoothScroll = smoothScroll;
      const element = this.$refs.scrollableContent;
      if (element) {
        // if there is a lot of content to scroll,
        // we want to disable the smooth scroll animation
        if (element.scrollHeight - element.scrollTop > 1000) {
          this.smoothScroll = false;
        }
        await this.$nextTick();
        element.scrollTop = element.scrollHeight;
      }
      this.smoothScroll = true;
    }
  }
};
</script>

<style scoped>
.scrollable-content-container {
  position: relative;
  height: 100%;
  width: 100%;
}
.scrollable-content {
  position: relative;
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.scroll-bottom-button {
  position: absolute;
  right: 15px;
  bottom: 3px;
  transition: all 0.2s;
}

.fade-enter,
.fade-leave-to {
  transform: scale(0.8);
  opacity: 0;
}
</style>
