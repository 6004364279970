<template>
  <vwc-dialog
    v-observe-keyboard.l2.enter="mainButtonAction"
    data-cy="end-meeting-modal"
    :close-button="showCancelButton"
    connotation="cta"
    class="vvd-scheme-alternate"
    :class="dialogClassName"
    :open="isEndMeetingDialogVisible"
    :heading="title"
    :escapeKeyAction="hide"
    @closed="hide"
    @click.stop
  >
    <vwc-icon
      v-if="showRecordingCheckbox"
      slot="icon"
      size="large"
      type="warning-line"
      class="dialog-icon"
    ></vwc-icon>

    <div :class="{ content: isSessionOwner && isRecording }">
      <div class="text">
        {{ text }}
      </div>
      <div v-if="showRecordingCheckbox" :class="checkboxClassName">
        <vwc-formfield :label="$t('end_meeting_modal.stop_recording_checkbox')">
          <vwc-checkbox
            :checked="stopRecordingFlag"
            @change="toggleStopRecordingFlag"
          />
        </vwc-formfield>
      </div>
    </div>

    <vwc-button
      v-if="!isSessionOwner"
      slot="secondaryAction"
      data-cy="cancel-btn"
      :label="$t('end_meeting_modal.cancel_button')"
      layout="outlined"
      :disabled="isLeaving"
      @click="hide"
    />

    <vwc-button
      :slot="leaveMeetingButtonSlot"
      data-cy="leave-meeting-btn"
      :layout="leaveMeetingButtonLayout"
      :label="$t('end_meeting_modal.leave_meeting_button')"
      :disabled="isLeaving"
      @click="leaveMeeting(false)"
    />

    <vwc-button
      v-if="isSessionOwner"
      slot="primaryAction"
      data-cy="end-meeting-btn"
      :label="$t('end_meeting_modal.end_meeting_button')"
      layout="filled"
      connotation="alert"
      :disabled="isLeaving"
      @click="leaveMeeting(true)"
    />
  </vwc-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import { ANALYTICS } from '@/consts/global-consts';
import analytics from '@/services/analytics-service';

export default {
  name: 'EndMeetingModal',

  props: {
    shouldCloseWindow: { type: Boolean, default: true },
    showCancelButton: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      stopRecordingFlag: true,
      isLeaving: false
    };
  },

  computed: {
    ...mapGetters(['isSessionOwner', 'isMobileWebMode']),
    ...mapGetters('recordings', ['isRecording']),
    ...mapState([
      'roomDetails',
      'myParticipantId',
      'isEndMeetingDialogVisible'
    ]),
    ...mapState('recordings', ['recordingOwner']),

    title() {
      if (this.isSessionOwner) {
        if (this.isRecording) {
          return this.$t('end_meeting_modal.recording_on_title');
        } else {
          return this.$t('end_meeting_modal.title');
        }
      } else if (this.isRecording && this.isRecordingOwner) {
        return this.$t('end_meeting_modal.recording_owner_title');
      }
      return this.$t('end_meeting_modal.leave_meeting_title');
    },

    text() {
      if (this.isSessionOwner && this.isRecording) {
        return this.$t(
          'end_meeting_modal.session_owner_and_recording_on_content'
        );
      } else if (
        this.isRecordingOwner &&
        this.isRecording &&
        !this.isSessionOwner
      ) {
        return this.$t(
          'end_meeting_modal.recording_owner_and_recording_on_content'
        );
      } else if (this.isSessionOwner) {
        return this.$t('end_meeting_modal.session_owner_content');
      } else {
        return this.$t('end_meeting_modal.leave_meeting_content');
      }
    },

    leaveMeetingButtonSlot() {
      return this.isSessionOwner ? 'secondaryAction' : 'primaryAction';
    },

    leaveMeetingButtonLayout() {
      return this.isSessionOwner ? 'outlined' : 'filled';
    },

    showRecordingCheckbox() {
      return (
        (this.isSessionOwner || this.isRecordingOwner) &&
        this.isRecording &&
        !this.roomDetails.isAutoRecorded
      );
    },

    isRecordingOwner() {
      return this.recordingOwner === this.myParticipantId;
    },

    dialogClassName() {
      if (this.isMobileWebMode) {
        return 'xsmall';
      } else if (this.isSessionOwner && this.isRecording) {
        return 'xlarge';
      } else if (
        this.isRecordingOwner &&
        this.isRecording &&
        !this.isSessionOwner
      ) {
        return 'large';
      } else if (this.isSessionOwner && !this.isRecording) {
        return 'medium';
      } else if (!this.isSessionOwner) {
        return 'small';
      } else {
        return '';
      }
    },

    checkboxClassName() {
      if (this.isSessionOwner && this.isRecording) {
        return 'recording-checkbox-meeting-owner';
      } else if (
        this.isRecordingOwner &&
        this.isRecording &&
        !this.isSessionOwner
      ) {
        return 'recording-checkbox';
      } else {
        return '';
      }
    }
  },

  methods: {
    ...mapActions(['setIsEndMeetingDialogVisible', 'leaveSession']),
    ...mapActions('recordings', ['stopRecording']),
    close(confirmed) {
      if (confirmed) {
        this.leaveMeeting(false);
      } else {
        this.hide();
      }
    },

    mainButtonAction() {
      this.leaveMeeting(this.isSessionOwner);
    },

    hide() {
      this.modalAnalytic('Close modal');
      this.setIsEndMeetingDialogVisible(false);
    },

    modalAnalytic(selectedOption) {
      analytics.trackEvent(ANALYTICS.END_OR_LEAVE_MEETING_MODAL, {
        'Modal Type': this.isSessionOwner
          ? 'End meeting modal'
          : 'Leave meeting modal',
        'Selected option': selectedOption
      });
    },

    async leaveMeeting(endForAll) {
      this.isLeaving = true;
      if (
        (this.isRecordingOwner || this.isSessionOwner) &&
        this.isRecording &&
        this.stopRecordingFlag &&
        !this.roomDetails.isAutoRecorded
      ) {
        await this.stopRecording();
      }
      try {
        await this.leaveSession({
          endForAll,
          closeWindow: this.shouldCloseWindow
        });

        this.modalAnalytic(
          this.isSessionOwner ? 'End meeting' : 'Leave meeting'
        );
      } catch (e) {
        logger.log('failed to leave session', { e });
        this.isLeaving = false;
      }
      if (endForAll) {
        logger.log('end-for-all-button-clicked', LOG_CATEGORIES.UI_INTERACTION);
      } else {
        logger.log(
          'leave-meeting-button-clicked',
          LOG_CATEGORIES.UI_INTERACTION
        );
      }
    },

    toggleStopRecordingFlag() {
      this.stopRecordingFlag = !this.stopRecordingFlag;
    }
  }
};
</script>

<style scoped>
.recording-checkbox-meeting-owner {
  /* This is needed in vivid's modal so the checkbox won't be too close to the content */
  margin-top: -33px;
}

.recording-checkbox {
  margin-top: 10px;
}

.dialog-icon {
  color: var(--vvd-color-on-canvas);
  margin-bottom: 25px;
}

.xlarge {
  --mdc-dialog-min-width: 520px;
}

.large {
  --mdc-dialog-min-width: 500px;
}

.medium {
  --mdc-dialog-min-width: 480px;
}

.small {
  --mdc-dialog-min-width: 400px;
}

.xsmall {
  --mdc-dialog-max-width: 360px;
}

.content {
  white-space: pre-wrap;
}

vwc-dialog {
  --mdc-dialog-z-index: 100000;
}
</style>
