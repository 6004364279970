/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-voicemail-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M.586 1.586A2 2 0 012 1h12a2 2 0 012 2v8a2 2 0 01-2 2h-3.266l-2.35 2.82a.5.5 0 01-.768 0L5.266 13H2a2 2 0 01-2-2V3a2 2 0 01.586-1.414zM2 2a1 1 0 00-1 1v8a1 1 0 001 1h3.5a.5.5 0 01.384.18L8 14.719l2.116-2.54A.5.5 0 0110.5 12H14a1 1 0 001-1V3a1 1 0 00-1-1H2zm1.5 3a.5.5 0 01.5.5v3a.5.5 0 01-1 0v-3a.5.5 0 01.5-.5zM7 4.5a.5.5 0 00-1 0v5a.5.5 0 001 0v-5zM9.5 6a.5.5 0 01.5.5v1a.5.5 0 01-1 0v-1a.5.5 0 01.5-.5zm3.5-.5a.5.5 0 00-1 0v3a.5.5 0 001 0v-3z"/>'
  }
})
