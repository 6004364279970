/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-edit-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.15 5.25L.45 16.95c-.3.3-.45.6-.45 1.05v4.5c0 .9.6 1.5 1.5 1.5H6c.45 0 .75-.15 1.05-.45l11.7-11.7-6.6-6.6zM23.55 4.95l-4.5-4.5c-.6-.6-1.5-.6-2.1 0l-2.7 2.7 6.6 6.6 2.7-2.7c.6-.6.6-1.5 0-2.1z"/>'
  }
})
