/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-infinity': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.542 8.41a5.334 5.334 0 013.682-1.46c1.981 0 3.627 1.063 5.06 2.335l.529.469-.937 1.056-.528-.469C7.99 9.137 6.677 8.362 5.224 8.362A3.922 3.922 0 002.517 9.43a3.513 3.513 0 00-1.105 2.54c0 .947.393 1.862 1.105 2.541a3.922 3.922 0 002.707 1.068c1.113 0 2.145-.453 3.183-1.212 1.047-.766 2.038-1.796 3.084-2.885l.024-.025c1.018-1.061 2.089-2.177 3.245-3.022 1.174-.86 2.495-1.485 4.016-1.485 1.376 0 2.7.522 3.683 1.46A4.924 4.924 0 0124 11.97a4.924 4.924 0 01-1.541 3.562 5.334 5.334 0 01-3.683 1.46c-1.983 0-3.629-1.064-5.061-2.336l-.528-.47.938-1.054.527.468c1.355 1.204 2.669 1.98 4.124 1.98a3.922 3.922 0 002.707-1.069 3.513 3.513 0 001.105-2.54c0-.947-.393-1.861-1.105-2.54a3.922 3.922 0 00-2.707-1.069c-1.113 0-2.145.453-3.183 1.212-1.046.766-2.038 1.796-3.084 2.886l-.024.025c-1.018 1.06-2.089 2.176-3.245 3.022-1.174.859-2.495 1.484-4.016 1.484-1.376 0-2.7-.521-3.682-1.459A4.924 4.924 0 010 11.971c0-1.343.559-2.623 1.542-3.562z"/>'
  }
})
