var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "dialog",
      attrs: {
        "data-cy": "hijack-screenshare-modal",
        heading: _vm.$t("meeting_actions.share_screen"),
        "icon-name": "screen-share-line",
        close: _vm.hide,
        "min-width": 454,
        "primary-action": _vm.$t("meeting_actions.share_screen_ok_button"),
        open: _vm.isHijackScreenshareDialogVisible,
        primaryButtonConnotation: "cta",
        isSmallModal: "",
      },
      on: {
        applied: _vm.hijackScreenshareAndHideModal,
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content", attrs: { slot: "content" }, slot: "content" },
        [
          _c("div", { staticClass: "text" }, [
            _vm._v(" " + _vm._s(_vm.content) + " "),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }