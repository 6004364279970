/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mockup': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.5 0h-13C.7 0 0 .7 0 1.5v13c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-13c0-.8-.7-1.5-1.5-1.5zM5 15H1.5c-.3 0-.5-.2-.5-.5V6h4v9zm10-.5c0 .3-.2.5-.5.5H6V6h9v8.5zM15 5H1V1.5c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5V5z"/>'
  }
})
