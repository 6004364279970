/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-eye': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.9 7.8c0-.1 0-.1 0 0-.1-.1-.1-.2-.1-.3-.1-.2-.2-.4-.4-.6-.4-.5-.9-1.2-1.6-2C12.5 3.5 10.5 2 8 2S3.5 3.5 2.2 4.9c-.7.7-1.2 1.4-1.6 2-.2.2-.3.4-.4.6 0 .1-.1.1-.1.2v.1l.2.1-.2-.1c-.1.1-.1.3 0 .4l.2-.1-.2.1v.1s.1.1.1.2c.1.2.2.4.4.6.4.5.9 1.2 1.6 2C3.5 12.5 5.5 14 8 14s4.5-1.5 5.8-2.9c.7-.7 1.2-1.4 1.6-2 .2-.3.3-.5.4-.6 0-.1.1-.1.1-.2v-.1l-.4-.2.4-.2zm-1.4.8c-.3.5-.8 1.2-1.5 1.8-1.2 1.3-3 2.6-5 2.6s-3.8-1.3-5.1-2.6c-.6-.7-1.1-1.3-1.4-1.8-.2-.3-.3-.5-.4-.6.1-.1.2-.3.4-.6.3-.5.8-1.2 1.5-1.8C4.2 4.3 6 3 8 3s3.8 1.3 5.1 2.6c.6.7 1.1 1.3 1.5 1.8.2.2.3.4.4.6-.2.1-.3.3-.5.6z"/><path pid="1" d="M15.9 7.8l-.4.2.4.2c.1-.1.1-.3 0-.4zM8 5C6.3 5 5 6.3 5 8s1.3 3 3 3 3-1.3 3-3-1.3-3-3-3zm0 5c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"/>'
  }
})
