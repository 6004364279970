/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-circle-down-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm0 13.339l5.104-4.083a1.167 1.167 0 011.458 1.822l-5.833 4.666a1.167 1.167 0 01-1.458 0l-5.833-4.666a1.167 1.167 0 111.458-1.822L12 13.339z"/>'
  }
})
