/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cart-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.567.626l3.02 3.02-.707.708L7.567 2.04 5.254 4.354l-.707-.708 3.02-3.02z"/><path pid="1" d="M10.667.626l3.02 3.02-.707.708-2.313-2.314-1.313 1.314-.708-.708 2.02-2.02zM4.666 13.167a.833.833 0 100 1.666.833.833 0 000-1.666zM2.833 14A1.833 1.833 0 116.5 14a1.833 1.833 0 01-3.667 0zM12.666 13.167a.833.833 0 100 1.666.833.833 0 000-1.666zM10.833 14a1.833 1.833 0 113.667 0 1.833 1.833 0 01-3.667 0z"/><path pid="2" d="M.667.167h2.438l1.333 10h8.486L14.057 4.5H4.5v-1h10.777l-1.533 7.667H3.563l-1.334-10H.667v-1z"/>'
  }
})
