/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-fork': {
    width: 16,
    height: 16,
    viewBox: '0 0 25 24',
    data: '<path pid="0" d="M22.3 7c0-1.5-1.2-2.7-2.7-2.7S16.9 5.5 16.9 7c0 1.3.9 2.4 2.1 2.6v.4c0 3.1-1.6 4.2-5.1 4.4h-2.1c-1.5 0-2.5.3-3.3.9V5.6c1.2-.3 2.1-1.3 2.1-2.6C10.6 1.5 9.3.3 7.8.3S5.1 1.5 5.1 3c0 1.2.9 2.3 2.1 2.6v12.8c-1.1.3-1.9 1.4-1.9 2.6 0 1.5 1.2 2.7 2.7 2.7s2.7-1.2 2.7-2.7c0-1.3-1-2.4-2.2-2.6h.1v-.3c0-.2.1-.5.2-.8.4-1 1.3-1.6 3.1-1.6H14c4-.2 6.3-1.8 6.3-5.7v-.4c1.1-.3 2-1.3 2-2.6zM9.4 21c0 .8-.6 1.4-1.4 1.4s-1.4-.6-1.4-1.4.6-1.4 1.4-1.4 1.4.6 1.4 1.4zM7.9 4.3c-.8 0-1.5-.5-1.5-1.3s.8-1.4 1.5-1.4 1.4.5 1.4 1.3-.6 1.4-1.4 1.4zm11.7 4.1c-.8 0-1.4-.6-1.4-1.4s.6-1.4 1.4-1.4S21 6.2 21 7s-.6 1.4-1.4 1.4z"/>'
  }
})
