/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-id': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.293 1.293A1 1 0 019 2v3H7V2a1 1 0 01.293-.707zM8 0a2 2 0 00-2 2v3.5a.5.5 0 00.5.5h3a.5.5 0 00.5-.5V2a2 2 0 00-2-2zM1.5 3A1.5 1.5 0 000 4.5v9A1.5 1.5 0 001.5 15h13a1.5 1.5 0 001.5-1.5v-9A1.5 1.5 0 0014.5 3h-3a.5.5 0 000 1h3a.5.5 0 01.5.5v9a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5h3a.5.5 0 000-1h-3zM9 8.5a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zm0 3a.5.5 0 01.5-.5h3a.5.5 0 010 1h-3a.5.5 0 01-.5-.5zm-6-3a.5.5 0 01.5-.5h3a.5.5 0 01.5.5v3a.5.5 0 01-.5.5h-3a.5.5 0 01-.5-.5v-3zM4 9v2h2V9H4z"/>'
  }
})
