/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-hoteling': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22 10.5C22 5 17.5.5 12 .5S2 5 2 10.5c0 3.3 1.6 6.2 4 8l6 5 6-5c2.4-1.8 4-4.7 4-8zm-5.2 4.9c0 .5-.1.8-.3 1.1s-.5.4-.8.4c-.3 0-.6-.1-.8-.4s-.3-.6-.3-1.1v-3.9h-5v3.9c0 .5-.1.8-.3 1.1s-.5.4-.8.4c-.3 0-.6-.1-.8-.4s-.3-.6-.3-1.1v-9c0-.5.1-.8.3-1.1S8 5 8.3 5c.4 0 .7.1.9.3s.3.6.3 1.1v3.3h5V6.4c0-.5.1-.8.3-1.1s.5-.3.9-.3c.3 0 .6.1.8.3s.3.6.3 1.1v9z"/>'
  }
})
