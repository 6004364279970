/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'modal-close': {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 10.722L16.278 17l.722-.722L10.722 10 17 3.722 16.278 3 10 9.278 3.722 3 3 3.722 9.278 10 3 16.278l.722.722L10 10.722z"/>'
  }
})
