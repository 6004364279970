/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-jobdiva': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.053 19.78c-.212.65-.425 1.3-.65 1.976l.15-.01c1.604-.097 3.254-.197 4.951-.39 2.751-.25 4.652-1.751 6.002-4.002 2.3-3.85 1.9-9.502-.9-13.053C19.256 1.35 16.004 1.05 12.553 1v3.701c5.552.9 7.302 2.45 7.352 6.502.05 1.75-.25 3.45-1.45 4.851-1.325 1.573-3.095 1.639-4.743 1.7-.343.012-.681.025-1.008.05-.226.676-.438 1.326-.65 1.976zM1.5 17.905c-.5.462-1 .925-1.5 1.4.278.25.54.454.788.646.197.153.385.299.562.454 1.95 1.6 4.151 2.151 6.602 1.45 2-.55 3.151-2.2 3.201-4.7.1-5.252.1-10.453.1-15.705 0-.1-.05-.25-.1-.35H7.202v1.2c0 4.752 0 9.503-.05 14.254-.05 1.65-1.1 2.201-2.5 1.3-.408-.236-.745-.543-1.108-.871-.172-.156-.35-.317-.543-.479-.5.476-1 .938-1.5 1.4z"/>'
  }
})
