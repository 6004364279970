/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-ai-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 6c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 3c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"/><path pid="1" d="M14 6c-.4 0-.7.1-1 .3L9.7 3c.2-.3.3-.6.3-1 0-1.1-.9-2-2-2S6 .9 6 2c0 .4.1.7.3 1L3 6.3c-.3-.2-.6-.3-1-.3-1.1 0-2 .9-2 2s.9 2 2 2c.4 0 .7-.1 1-.3L6.3 13c-.2.3-.3.6-.3 1 0 1.1.9 2 2 2s2-.9 2-2c0-.4-.1-.7-.3-1L13 9.7c.3.2.6.3 1 .3 1.1 0 2-.9 2-2s-.9-2-2-2zm-6 6c-.4 0-.7.1-1 .3L3.7 9c.2-.3.3-.6.3-1s-.1-.7-.3-1L7 3.7c.3.2.6.3 1 .3s.7-.1 1-.3L12.3 7c-.2.3-.3.6-.3 1s.1.7.3 1L9 12.3c-.3-.2-.6-.3-1-.3zM8 1c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zM1 8c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1-1-.4-1-1zm7 7c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1zm6-6c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"/>'
  }
})
