var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "main-actions-bar-container unselectable" },
    [
      _vm.showSeparator ? _c("div", { staticClass: "separator" }) : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "on-clickaway",
              rawName: "v-on-clickaway",
              value: _vm.closeExperimentalIndicationPopup,
              expression: "closeExperimentalIndicationPopup",
            },
          ],
          staticClass: "indication-container",
        },
        [
          _c("vwc-icon-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.exclusiveExperimentalMode,
                expression: "exclusiveExperimentalMode",
              },
              {
                name: "tooltip",
                rawName: "v-tooltip.bottom",
                value: _vm.experimentalIndicationButtonTooltip,
                expression: "experimentalIndicationButtonTooltip",
                modifiers: { bottom: true },
              },
            ],
            staticClass: "experimental-indication-icon",
            class: { branded: _vm.isBranded },
            attrs: {
              id: "experimental-indication-button",
              "data-cy": "experimental-top-bar-indication",
              icon: "flash-2-solid",
              connotation: "cta",
              dense: "",
            },
            on: { click: _vm.toggleExperimentalIndicationPopup },
          }),
          _c(
            "vwc-popup",
            {
              attrs: {
                id: "experimental-indication-popup",
                "data-cy": "experimental-indication-popup",
                anchor: "experimental-indication-button",
                open: _vm.isExperimentalPopupOpen,
                arrow: "",
                corner: "bottom-start",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "popup-content experimental",
                  attrs: { "data-cy": "experimental-popup-content" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "popup-title",
                      attrs: { "data-cy": "experimental-popup-title" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("experimental_indication_popup.title")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "popup-text",
                      attrs: { "data-cy": "experimental-popup-text" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("experimental_indication_popup.subtitle")
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _vm.exclusiveExperimentalMode
                    ? _c("vwc-button", {
                        staticClass: "popup-button",
                        attrs: {
                          "data-cy": "experimental-popup-button",
                          label: _vm.$t(
                            "experimental_indication_popup.turn_off_button"
                          ),
                          connotation: "primary",
                          layout: "outlined",
                          fullwidth: "",
                          dense: "",
                        },
                        on: { click: _vm.turnOffExperimental },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          directives: [
            {
              name: "on-clickaway",
              rawName: "v-on-clickaway",
              value: _vm.closeLockedIndicationPopup,
              expression: "closeLockedIndicationPopup",
            },
          ],
          staticClass: "indication-container",
        },
        [
          _c("vwc-icon-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isLocked,
                expression: "isLocked",
              },
              {
                name: "tooltip",
                rawName: "v-tooltip.bottom",
                value: _vm.meetingLockIndicationButtonTooltip,
                expression: "meetingLockIndicationButtonTooltip",
                modifiers: { bottom: true },
              },
            ],
            staticClass: "lock-indication-icon",
            attrs: {
              id: "lock-indication-button",
              "data-cy": "lock-top-bar-indication",
              icon: _vm.isSessionOwner ? "lock-solid" : "lock-line",
              connotation: "primary",
              disabled: !_vm.isSessionOwner,
              dense: "",
            },
            on: { click: _vm.toggleLockedIndicationPopup },
          }),
          _c(
            "vwc-popup",
            {
              attrs: {
                id: "lock-indication-popup",
                anchor: "lock-indication-button",
                "data-cy": "lock-indication-popup",
                open: _vm.isLockedPopupOpen,
                arrow: "",
                corner: "bottom-start",
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "popup-content locked",
                  attrs: { "data-cy": "lock-popup-content" },
                },
                [
                  _c(
                    "p",
                    {
                      staticClass: "popup-title",
                      attrs: { "data-cy": "lock-popup-title" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("locked_indication_popup.title")) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "p",
                    {
                      staticClass: "popup-text",
                      attrs: { "data-cy": "lock-popup-text" },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("locked_indication_popup.subtitle")) +
                          " "
                      ),
                    ]
                  ),
                  _vm.isLocked
                    ? _c("vwc-button", {
                        staticClass: "popup-button",
                        attrs: {
                          "data-cy": "lock-popup-button",
                          label: _vm.$t(
                            "locked_indication_popup.unlock_button"
                          ),
                          connotation: "primary",
                          layout: "outlined",
                          icon: "unlock-solid",
                          fullwidth: "",
                          dense: "",
                        },
                        on: { click: _vm.unlockMeeting },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        {
          staticClass: "meeting-name",
          attrs: { "data-cy": "meeting-name" },
          on: {
            click: function ($event) {
              return _vm.toggleSidebar(_vm.SIDEBARS.INFO)
            },
          },
        },
        [_vm._v(" " + _vm._s(_vm.roomDisplayName))]
      ),
      _vm.isRecording
        ? _c("RecordingIndication", {
            staticClass: "recording-indication",
            attrs: {
              startTime: _vm.recording.startTime,
              showTimer: _vm.screenSizeMediumOrLarger,
            },
          })
        : _vm._e(),
      _vm.isSessionCaptionsEnabled
        ? _c("vwc-3-badge", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.bottom",
                value: _vm.transcribingBadgeTooltip,
                expression: "transcribingBadgeTooltip",
                modifiers: { bottom: true },
              },
            ],
            staticClass: "transcription-indication vvd-root",
            attrs: {
              "data-cy": "transcription-indication",
              connotation: "primary",
              appearance: "subtle",
              icon: "voicemail-transcript-solid",
              shape: "pill",
              text: _vm.transcriptionBadgeText,
            },
          })
        : _vm._e(),
      _c("span", { staticClass: "spacer" }),
      _c("TopBarLayoutsButton", { staticClass: "layout-toggle-container" }),
      _c("div", { staticClass: "separator" }),
      _vm.isLocaleSwitcherEnabled ? _c("LocaleSwitcher") : _vm._e(),
      _vm._l(_vm.availableSidebarButtons, function (sidebarButton) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.bottom-end",
                value: sidebarButton.tooltip,
                expression: "sidebarButton.tooltip",
                modifiers: { "bottom-end": true },
              },
            ],
            key: sidebarButton.id,
            staticClass: "top-bar-button toggle-sidebar-button",
            attrs: { "data-cy": sidebarButton.data_cy },
            on: {
              click: function ($event) {
                return _vm.toggleSidebar(sidebarButton.id)
              },
              dblclick: function ($event) {
                $event.stopPropagation()
              },
            },
          },
          [
            _c("vwc-icon-button", {
              attrs: {
                icon: sidebarButton.icon,
                dense: "",
                layout: sidebarButton.active ? "filled" : "ghost",
                onmouseup: "this.blur()",
              },
            }),
            !sidebarButton.active && sidebarButton.badgeCounter
              ? _c("CounterBadge", {
                  staticClass: "overlapping-counter-badge",
                  attrs: { count: sidebarButton.badgeCounter },
                })
              : sidebarButton.status
              ? _c("StatusBadge", {
                  staticClass: "overlapping-status-badge",
                  attrs: { status: sidebarButton.status },
                })
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }