/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-palette-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.85 5.7c-5.4-2.55-9.9 2.7-12 0-.75-1.05 1.05-3 0-4.35-.75-.9-2.55-.75-3.6 0C2.1 3.6 0 7.2 0 11.25c0 6.6 5.4 12 12 12 5.55 0 10.2-3.75 11.55-8.85.6-1.65 1.2-6.75-2.7-8.7zM4.5 12c-.9 0-1.5-.6-1.5-1.5S3.6 9 4.5 9 6 9.6 6 10.5 5.4 12 4.5 12zm3 6c-.9 0-1.5-.6-1.5-1.5S6.6 15 7.5 15s1.5.6 1.5 1.5S8.4 18 7.5 18zm6 3c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5 1.5.6 1.5 1.5-.6 1.5-1.5 1.5zm4.5-6c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"/>'
  }
})
