/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-node-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M21.285 5.342L12.607.244a1.864 1.864 0 00-1.844 0L1.976 5.342C1.434 5.668 1 6.21 1 6.861v10.088c0 .651.434 1.302.976 1.627l2.278 1.302c1.085.542 1.41.542 1.953.542 1.627 0 2.495-.976 2.495-2.712v-9.98c0-.108.108-.325 0-.325H7.617c-.217 0-.542.217-.542.326v9.98c0 .759-.76 1.518-2.061.867l-2.278-1.301c-.109 0-.109-.109-.109-.217V6.86c0-.108.109-.217.217-.217l8.787-5.098h.325l8.678 4.99c.108 0 .108.108.108.217V16.84c0 .108 0 .217-.108.217l-8.678 5.098h-.217l-2.495-1.193h-.217c-.65.325-.76.434-1.302.65-.108 0-.325.109.109.434l2.929 1.736c.325.108.65.217.867.217.326 0 .651-.108.868-.217l8.678-5.098c.543-.326.868-.976.868-1.627V6.86c.108-.65-.217-1.193-.76-1.519zM14.342 15.43c-2.278 0-2.82-.65-3.037-1.735 0-.109-.108-.325-.217-.325h-1.085c-.108 0-.217.216-.217.325 0 1.519.76 3.254 4.665 3.254 2.82 0 4.339-1.085 4.339-2.929 0-1.844-1.302-2.386-4.014-2.711-2.712-.326-3.037-.543-3.037-1.194 0-.542.217-1.193 2.278-1.193 1.844 0 2.495.434 2.712 1.627 0 .109.108.217.217.217h1.084c.109 0 .109 0 .217-.108 0 0 .109-.109.109-.217-.217-2.061-1.519-3.038-4.34-3.038-2.494 0-4.013 1.085-4.013 2.82 0 1.953 1.519 2.496 3.905 2.713 2.82.325 3.038.65 3.038 1.301 0 .76-.76 1.194-2.604 1.194z" _fill="#83CD29"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
