/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-chat': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1.5 0C.7 0 0 .7 0 1.5v6C0 8.3.7 9 1.5 9H3v2.5c0 .2.1.4.3.5s.4 0 .5-.1L6.7 9h3.8c.8 0 1.5-.7 1.5-1.5v-6c0-.8-.7-1.5-1.5-1.5h-9zM1 1.5c0-.3.2-.5.5-.5h9c.3 0 .5.2.5.5v6c0 .3-.2.5-.5.5h-4c-.1 0-.3.1-.4.1L4 10.3V8.5c0-.3-.2-.5-.5-.5h-2c-.3 0-.5-.2-.5-.5v-6zM13.5 4c-.3 0-.5.2-.5.5s.2.5.5.5h1c.3 0 .5.2.5.5v6c0 .3-.2.5-.5.5h-2c-.3 0-.5.2-.5.5v1.8l-2.1-2.1c-.1-.1-.3-.2-.4-.2h-3c-.3 0-.5.2-.5.5s.2.5.5.5h2.8l2.9 2.9c.1.1.4.2.5.1.2-.1.3-.3.3-.5V13h1.5c.8 0 1.5-.7 1.5-1.5v-6c0-.8-.7-1.5-1.5-1.5h-1z"/>'
  }
})
