/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-ambulance': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10 10.833h1.533v1H10v-1zM13.5 5.833H10v-1h4.5v2.858l1.333.667v3.475H13.8v-1h1.033V8.976L13.5 8.309V5.833z"/><path pid="1" d="M.167 2.167H10.5v9.666H6.667v-1H9.5V3.167H1.167v7.666h1.5v1h-2.5V2.167z"/><path pid="2" d="M4.667 9.833a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-2.5 1.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zM12.666 11.167a.833.833 0 100 1.666.833.833 0 000-1.666zM10.833 12a1.833 1.833 0 113.667 0 1.833 1.833 0 01-3.667 0zM5.833 4.167v3.666h-1V4.167h1z"/><path pid="3" d="M3.5 5.5h3.667v1H3.5v-1z"/>'
  }
})
