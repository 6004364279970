/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'audience-icon': {
    width: 20,
    height: 20,
    viewBox: '0 0 20 20',
    data: '<rect pid="0" width="12" height="20" rx="1" _fill="#131415"/><rect pid="1" x="14" width="6" height="6" rx="1" _fill="#131415"/><rect pid="2" x="14" y="7" width="6" height="6" rx="1" _fill="#131415"/><rect pid="3" x="14" y="14" width="6" height="6" rx="1" _fill="#131415"/>'
  }
})
