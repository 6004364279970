<template>
  <div class="chat-wrapper vvd-root vvd-theme-alternate">
    <MobileImageView
      v-if="isMobileWebMode && showImageView"
      class="mobile-image-view"
      :message="imageMessage"
      @closeImageView="$router.back()"
    />
    <v-chat
      v-show="!hasMessagingError"
      ref="vChat"
      enableAttachments
      group
      class="chat"
      :disabled="isChatDisabled"
      :locale="$i18n.locale"
      @userInputKeyPress.stop="userInputKeyPress($event)"
      @sendClick.stop="sendClicked($event)"
      @chatDragOver.prevent
      @downloadClick.stop="downloadFile($event.detail.message.uuid)"
      @cancelClick.stop="cancelUpload($event.detail.message)"
      @fileClicked.stop="fileClicked($event.detail.message.uuid)"
      @metaMessageActionClicked.stop="handleTryAgain($event.detail.message)"
      @userAttachments.stop="handleUserAttachmentsAdd($event)"
      @isLatchedToBottomChanged.stop="
        handleIsChatScrolledToBottomChanged($refs.vChat.isLatchedToBottom)
      "
    >
      <vwc-3-button
        v-if="!isMobileWebMode"
        slot="action-buttons"
        icon="emoji-line"
        size="condensed"
        shape="rounded"
        @click="openEmojiKeyboardClicker()"
      ></vwc-3-button>
      <em-emoji-picker
        v-if="!isMobileWebMode"
        v-show="showEmojiKeyboard"
        ref="emojiPicker"
        slot="input-action"
        class="emoji-picker"
        theme="light"
        dynamicWidth="true"
        previewPosition="none"
      ></em-emoji-picker>
      <div
        v-if="chatMessages.length === 0"
        slot="main"
        class="empty-state-container"
      >
        <vwc-empty-state
          :heading="$t('messages_list.empty_state_title')"
          :body="$t('messages_list.empty_state_description')"
        >
          <img slot="graphic" data-cy="empty-chat-img" :src="emptyStatImg" />
        </vwc-empty-state>
      </div>
    </v-chat>
    <div
      v-if="
        hasMessagingError ||
        showUnsupportedFileDialog ||
        showUnsupportedFolderDialog ||
        showMaxFilesSizeReachedDialog ||
        showMaxNumberOfFilesReachedDialog
      "
      class="dialog-container"
    >
      <ChatAlerts
        :show-messaging-error="hasMessagingError"
        :show-unsupported-file-dialog="showUnsupportedFileDialog"
        :show-unsupported-folder-dialog="showUnsupportedFolderDialog"
        :show-max-files-size-reached-dialog="showMaxFilesSizeReachedDialog"
        :show-max-number-of-files-reached-dialog="
          showMaxNumberOfFilesReachedDialog
        "
        @action-button-click="handleChatErrorDialogActionButtonClick()"
      ></ChatAlerts>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  getMediaType,
  isFileUnsupported,
  validateFiles
} from '@/helpers/attachments-utils';
import {
  COLORS,
  MEDIA_TYPE,
  MESSAGE_TYPE,
  UPLOAD_ERRORS_TYPES
} from '@/consts/messaging';
import { SHORT_TOAST_DURATION, TOAST_TYPE } from '@/consts/mobile-consts';
import '@vonage/v-chat-components';
import {
  MessageType,
  VFileModel,
  VFilePreviewModel,
  VMessageModel,
  FilePreviewActionButtonType
} from '@vonage/v-chat-components';
import { getColor } from '@/helpers/global-helpers';
import { init as initEmojis } from 'emoji-mart';
import MobileImageView from '@/views/mobile/MobileImageView';
import ChatAlerts from '@/components/chat/ChatDialog/ChatAlerts.vue';

export default {
  name: 'ChatComponent',

  components: { ChatAlerts, MobileImageView },
  data() {
    return {
      vChat: undefined,
      messageIdToVMessage: {},
      failedDownloads: {},
      showImageView: false,
      imageMessage: null,
      showEmojiKeyboard: false,
      showUnsupportedFileDialog: false,
      showUnsupportedFolderDialog: false,
      showMaxFilesSizeReachedDialog: false,
      showMaxNumberOfFilesReachedDialog: false
    };
  },

  computed: {
    ...mapState(['hasSessionEverConnected', 'isBranded']),
    ...mapState('messaging', [
      'messages',
      'groupData',
      'failedReadingMessages',
      'finishedReadingHistoricalMessages'
    ]),
    ...mapGetters(['isMobileWebMode']),
    ...mapGetters('messaging', ['chatMessages']),
    ...mapGetters('messaging/attachments', [
      'uploadsProgressMap',
      'cancelledRequests'
    ]),

    hasMessagingError() {
      return !this.groupData.groupId || this.failedReadingMessages;
    },

    isChatDisabled() {
      return (
        !this.groupData.groupId ||
        !this.finishedReadingHistoricalMessages ||
        !this.hasSessionEverConnected
      );
    },

    emptyStatImg() {
      return this.isBranded
        ? '/illustrations/empty-state/empty-chat-branded.svg'
        : '/illustrations/empty-state/empty-chat.svg';
    }
  },

  watch: {
    chatMessages: {
      // when the chatMessages array changes we'll convert the messages format
      handler(chatMessages) {
        if (this.vChat !== undefined) {
          this.vChat.messages = this.convertToVMessages(chatMessages);
        }
      }
    },

    uploadsProgressMap: {
      deep: true, // it's not a problem to use deep here because it should be a small data structure.
      handler(newUploadsProgressMap) {
        for (let messageId of Object.keys(newUploadsProgressMap)) {
          const progress = newUploadsProgressMap[messageId];
          let vMessage = this.messageIdToVMessage[messageId];
          if (vMessage?.filePreview !== undefined) {
            if (progress >= 0 && progress < 100) {
              vMessage.filePreview.showProgress = true;
              if (progress !== 0) {
                vMessage.filePreview.progressValue = progress;
              }
              vMessage.filePreview.actionButton =
                FilePreviewActionButtonType.cancel;
            } else {
              vMessage.filePreview.showProgress = false;
              vMessage.filePreview.actionButton =
                FilePreviewActionButtonType.download;
            }
          }
        }
      }
    },

    cancelledRequests: {
      deep: true, // it's not a problem to use deep here because it should be a small data structure.
      handler(newCancelUploadRequestMap, oldCancelUploadRequestMap) {
        for (let messageId of Object.keys(newCancelUploadRequestMap)) {
          this.setMetaDataForMessage(messageId);
        }
        for (let messageId of Object.keys(oldCancelUploadRequestMap)) {
          if (!(messageId in newCancelUploadRequestMap)) {
            this.setMetaDataForMessage(messageId);
          }
        }
      }
    },

    failedDownloads: {
      deep: true, // it's not a problem to use deep here because it should be a small data structure.
      handler(newFailedDownloads, oldFailedDownloads) {
        for (let messageId of Object.keys(newFailedDownloads)) {
          this.setMetaDataForMessage(messageId);
        }
        for (let messageId of Object.keys(oldFailedDownloads)) {
          if (!(messageId in newFailedDownloads)) {
            this.setMetaDataForMessage(messageId);
          }
        }
      }
    }
  },

  mounted() {
    window.addEventListener('hashchange', this.onHashChanged);
    if (!this.hasMessagingError) {
      this.vChat = this.$refs.vChat;
      this.vChat.messages = this.convertToVMessages(this.chatMessages);
      this.vChat.chatInput.maxTextLength = 1000;
    }
    this.fetchEmojisData().then((emojisData) => {
      initEmojis({ emojisData });
    });
  },

  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChanged);
  },

  methods: {
    ...mapActions(['addFlashMessage']),
    ...mapActions('messaging', [
      'sendMessage',
      'readMessages',
      'updateIsChatScrolledToBottom'
    ]),
    ...mapActions('mobile', ['displayToast', 'setIsChatOpen']),
    ...mapActions('messaging/attachments', [
      'downloadFileToDisk',
      'cancelUploadRequest'
    ]),

    userInputKeyPress(event) {
      if (event.detail.key === 'Enter') {
        event.preventDefault();
        const chat = event.target;
        if (event.detail.shiftKey) {
          chat.chatInput.insertTextInSelection('\n');
          return;
        }
        chat.chatInput.invokeSend();
      }
    },

    sendClicked(event) {
      const chat = event.target;
      const messages = event.detail.messagesModels;
      if (messages === undefined) return;
      for (let i = messages.length - 1; i >= 0; i--) {
        const message = messages[i];
        if (message.filePreview?.fileModel?.file !== undefined) {
          this.sendAttachmentMessage(
            [message.filePreview?.fileModel?.file],
            message.contentText
          );
        } else if (message.contentText !== undefined) {
          this.sendTextMessage(message.contentText);
        }
      }
      this.showEmojiKeyboard = false;
      chat.userInputValue = '';
      chat.chatInput.clearFilePreviews();
      requestAnimationFrame(() => {
        chat.chatInput.focusInputIfAvailable();
      });
    },

    sendTextMessage(messageText) {
      const messageTextToSend = messageText.trim();
      if (messageTextToSend) {
        this.sendMessage({ body: messageTextToSend });
      }
    },

    resendMessage(message) {
      let messageToResend = message;
      if (message.type !== MESSAGE_TYPE.TEXT) {
        messageToResend = { ...message };

        messageToResend.attachment = this.chatMessages.find(
          (m) => m.messageId === message.messageId
        ).originalAttachmentFile;
      }
      this.sendMessage(messageToResend);
    },

    retryReadMessages() {
      if (this.failedReadingMessages) {
        this.readMessages(true);
      }
    },

    sendAttachmentMessage(files, msg) {
      if (
        this.isMobileWebMode &&
        files.some(
          (file) =>
            !getMediaType(file) || getMediaType(file) === MEDIA_TYPE.VIDEO
        )
      ) {
        this.displayToast({
          message: this.$t('chat.unsupported_attachment_message'),
          time: SHORT_TOAST_DURATION,
          type: TOAST_TYPE.CRITICAL
        });
        return;
      }
      const result = validateFiles(files);
      if (result.error) {
        this.addFlashMessage({
          type: 'critical',
          text: this.$t('chat.attachment_critical_flash_message')
        });
      } else {
        result.attachments.forEach((attachment) => {
          this.sendMessage({
            attachment,
            body: msg
          });
        });
      }
    },

    fileClicked(messageId) {
      const message = this.chatMessages.find(
        (message) => message.messageId === messageId
      );
      if (message === undefined) return;
      if (message.type === MESSAGE_TYPE.IMAGE) {
        this.openImageView(message);
      }
    },

    async downloadFile(messageId) {
      const vMessage = this.messageIdToVMessage[messageId];
      vMessage.showProgress = true;
      try {
        await this.downloadFileToDisk({ messageId });
        vMessage.showProgress = false;
        if (messageId in this.failedDownloads) {
          delete this.failedDownloads[messageId];
        }
      } catch (error) {
        vMessage.showProgress = false;
        this.failedDownloads[messageId] = true;
      }
    },

    cancelUpload(vMessage) {
      if (vMessage === undefined) return;
      vMessage.filePreview.actionButton = FilePreviewActionButtonType.none;
      vMessage.filePreview.showProgress = false;
      this.cancelUploadRequest({ messageId: vMessage.uuid });
    },

    handleTryAgain(vMessage) {
      this.resendMessage(this.messages[vMessage.uuid]);
    },

    handleUserAttachmentsAdd(event) {
      const eventDetails = event.detail;
      const fileListArr = Array.from(eventDetails.files);
      if (
        (this.isMobileWebMode &&
          fileListArr.some(
            (file) =>
              !getMediaType(file) || getMediaType(file) === MEDIA_TYPE.VIDEO
          )) ||
        fileListArr.some((file) => isFileUnsupported(file))
      ) {
        event.preventDefault();
        this.showUnsupportedFileDialog = true;
        return;
      }
      const chatInput = this.vChat.chatInput;
      const inputCurrentFiles = chatInput.filesPreviews.map(
        (filePreviewModel) => filePreviewModel.fileModel.file
      );
      const validationResult = validateFiles([
        ...fileListArr,
        ...inputCurrentFiles
      ]);

      if (validationResult.error !== undefined) {
        if (
          validationResult.error.name ===
          UPLOAD_ERRORS_TYPES.MAX_NUMBER_OF_FILES_EXCEEDED
        ) {
          this.showMaxNumberOfFilesReachedDialog = true;
          event.preventDefault();
        }

        if (
          validationResult.error.name ===
          UPLOAD_ERRORS_TYPES.TOTAL_SIZE_EXCEEDED
        ) {
          this.showMaxFilesSizeReachedDialog = true;
          event.preventDefault();
        }
      }

      if (eventDetails.items !== undefined) {
        for (let index = 0; index < eventDetails.items.length; index++) {
          const item = eventDetails.items[index];
          if (item.webkitGetAsEntry().isDirectory) {
            event.preventDefault();
            this.showUnsupportedFolderDialog = true;
            return;
          }
        }
      }
    },

    openImageView(message) {
      if (!this.isMobileWebMode) {
        return;
      }

      this.showImageView = true;
      this.$router.history.push(this.$route.path + '#image-view');
      this.imageMessage = message;
    },

    onHashChanged(event) {
      if (!this.isMobileWebMode) {
        return;
      }

      if (event.oldURL.includes('#chat')) {
        if (event.newURL.includes('image-view')) {
          // Forward
          this.showImageView = true;
        } else {
          // Back
          this.setIsChatOpen(false);
          // Remove the empty hash from the url when the chat hash is removed
          this.$router.back();
        }
      } else if (event.oldURL.includes('#image-view')) {
        this.showImageView = false;
      }
    },

    convertToVMessages(messages) {
      const messageIdToVMessage = {};
      const vMessages = messages.map((message, index) => {
        const previousMessage = this.chatMessages[index - 1];
        const vMessage = this.convertMessageToVMessage(
          message,
          previousMessage
        );
        messageIdToVMessage[message.messageId] = vMessage;
        return vMessage;
      });
      this.messageIdToVMessage = messageIdToVMessage;
      return vMessages;
    },

    convertMessageToVMessage(message, previousMessage) {
      let vMessage;
      if (this.messageIdToVMessage[message.messageId] !== undefined) {
        vMessage = this.messageIdToVMessage[message.messageId];
      } else {
        vMessage = new VMessageModel(message.messageId);
      }

      vMessage.contentText = message.body;
      // if the previous message is not by the same sender we need to add display name again
      if (
        previousMessage === undefined ||
        previousMessage.participantId !== message.participantId
      ) {
        vMessage.senderName =
          message.direction === 'INBOUND' ? message.fromDisplayName : 'Me';
        // add color to display name
        if (message.direction === 'INBOUND') {
          vMessage.displayNameColor = getColor(message.participantId, COLORS);
        }
      }

      // adding a file if needed
      if (
        message.type !== MESSAGE_TYPE.TEXT &&
        vMessage.filePreview === undefined
      ) {
        const fileModel = new VFileModel(
          message.data[0].metadata.fileName,
          message.data[0].metadata.size
        );
        fileModel.fileType = message.data[0].metadata.mimeType;
        const filePreviewModel = new VFilePreviewModel(fileModel);

        const uploadProgress = this.uploadsProgressMap[message.messageId];
        if (uploadProgress === undefined) {
          filePreviewModel.showProgress = false;
          filePreviewModel.actionButton = FilePreviewActionButtonType.download;
        } else {
          filePreviewModel.showProgress = true;
          filePreviewModel.actionButton = FilePreviewActionButtonType.cancel;
        }
        if (message.type === MESSAGE_TYPE.IMAGE) {
          filePreviewModel.previewSrc = `data:${message.data[0].metadata.info.mime_type};base64, ${message.data[0].thumbnail}`;
          if (this.isMobileWebMode) {
            filePreviewModel.clickable = true;
          }
        }
        vMessage.filePreview = filePreviewModel;
      }

      this.setMetaDataForMessage(message.messageId, vMessage);
      vMessage.senderId = message.participantId;
      vMessage.timeStamp = new Date(message.creationTime);
      vMessage.messageType =
        message.direction === 'INBOUND'
          ? MessageType.Response
          : MessageType.User;
      return vMessage;
    },

    openEmojiKeyboardClicker() {
      this.showEmojiKeyboard = !this.showEmojiKeyboard;
      if (!this.showEmojiKeyboard) return;
      const emojiPickerProps = {
        onEmojiSelect: (e) => {
          this.handleEmojiSelect(e);
        }
      };

      this.$refs.emojiPicker.update(emojiPickerProps);
    },

    handleEmojiSelect(e) {
      const chat = this.vChat;
      chat.chatInput.insertTextInSelection(e.native);
    },

    setMetaDataForMessage(messageId, vMessage) {
      let message = this.messages[messageId];
      const isUploadCancelled = this.cancelledRequests[messageId] !== undefined;
      const isSendFailed = message.uiStatus.sentStatus === 'FAILED';
      const isDownloadFailed = this.failedDownloads[messageId] !== undefined;
      const uploadProgress = this.uploadsProgressMap[messageId];
      const isDownloadable =
        !isUploadCancelled && !isSendFailed && uploadProgress === undefined;
      if (vMessage === undefined) {
        vMessage = this.messageIdToVMessage[messageId];
      }
      if (vMessage === undefined) return;
      if (isSendFailed) {
        this.setSendFailed(vMessage);
      } else if (isUploadCancelled) {
        this.setUploadCancelled(vMessage);
      } else if (isDownloadFailed) {
        this.setDownloadFailed(vMessage);
      } else {
        this.removeVMessageMetaMessage(vMessage, isDownloadable);
      }
    },

    setSendFailed(vMessage) {
      vMessage.metaMessage = this.$t('message.send_failed');
      vMessage.metaMessageAction = this.$t('message.try_again');
      if (vMessage.filePreview !== undefined) {
        vMessage.filePreview.showProgress = false;
        vMessage.filePreview.actionButton = FilePreviewActionButtonType.none;
      }
    },

    setUploadCancelled(vMessage) {
      vMessage.metaMessage = this.$t('message.upload_cancelled_label');
      vMessage.metaMessageAction = this.$t('message.try_again');
      if (vMessage.filePreview !== undefined) {
        vMessage.filePreview.showProgress = false;
        vMessage.filePreview.actionButton = FilePreviewActionButtonType.none;
      }
    },

    setDownloadFailed(vMessage) {
      vMessage.metaMessage = this.$t('message.download_failed_label');
      vMessage.metaMessageAction = undefined;
    },

    removeVMessageMetaMessage(vMessage, isDownloadable) {
      vMessage.metaMessage = undefined;
      vMessage.metaMessageAction = undefined;
      if (vMessage.filePreview !== undefined && isDownloadable) {
        vMessage.filePreview.showProgress = false;
        vMessage.filePreview.actionButton =
          FilePreviewActionButtonType.download;
      }
    },

    handleIsChatScrolledToBottomChanged(IsChatScrolledToBottom) {
      this.updateIsChatScrolledToBottom(IsChatScrolledToBottom);
    },

    handleChatErrorDialogActionButtonClick() {
      if (this.hasMessagingError) {
        this.retryReadMessages();
      }
      this.showUnsupportedFileDialog = false;
      this.showUnsupportedFolderDialog = false;
      this.showMaxFilesSizeReachedDialog = false;
      this.showMaxNumberOfFilesReachedDialog = false;
    },

    async fetchEmojisData() {
      const response = await fetch(
        'https://cdn.jsdelivr.net/npm/@emoji-mart/data'
      );
      return response.json();
    }
  }
};
</script>

<style scoped>
.chat {
  --canvas: #fff;
  --canvas-text: #000;
  --accent-text: var(--btn-cta-color-white-bg-text-color);
  --accent: var(--btn-cta-color-white-bg);
  --accent-hover: var(--btn-cta-color-white-bg-hover);
  --accent-emphasized: var(--btn-cta-color-white-bg-outlined);
  --accent-clicked: var(--btn-cta-color-white-bg-clicked);
}

.mobile-image-view {
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 2000;
  flex: none;
}

.chat-wrapper {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.emoji-picker {
  width: 100%;
  height: 250px;
  --border-radius: 0px;
}

.empty-state-container {
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.dialog-container {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
</style>
