/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-note-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 0H7.5C6.6 0 6 .6 6 1.5v13.8c-.45-.15-.9-.3-1.5-.3C1.95 15 0 16.95 0 19.5S1.95 24 4.5 24 9 22.05 9 19.5v-12h10.5v4.8c-.45-.15-.9-.3-1.5-.3-2.55 0-4.5 1.95-4.5 4.5S15.45 21 18 21s4.5-1.95 4.5-4.5v-15C22.5.6 21.9 0 21 0z"/>'
  }
})
