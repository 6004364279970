import { ANALYTICS, PARTICIPANT_STATE_TYPES } from '@/consts/global-consts';
import MeetingManager from '@/services/meeting-manager-service';
import VideoOwnerTrackingService from '@/services/watch-together/video-owner-tracking-service';
import analytics from '@/services/analytics-service';
import { createState, getInitialState } from './state';
import { LAYOUT_MODE_TYPES } from '@/consts/global-consts';
import { APP_SIGNALS } from '@/consts/global-consts';

export default {
  sendData: ({ commit }, { data, to = undefined }) => {
    commit('UPDATE_VIDEO_STATE', data);
    let signal = {
      type: APP_SIGNALS.WATCH_TOGETHER,
      data: data
    };
    if (to !== undefined) {
      signal.to = to;
    }

    return MeetingManager.sendSignal(signal);
  },

  handleVideoStateChanged: (
    { dispatch, state, commit, getters },
    { isVideoPlaying, timeInVideo }
  ) => {
    dispatch('setIsVideoPlaying', isVideoPlaying);

    if (getters.isWatchTogetherOwner && timeInVideo >= 0) {
      const watchTogetherState = createState(
        true,
        isVideoPlaying,
        timeInVideo,
        state.watchTogetherOwnerParticipantId,
        state.videoMetadata
      );
      dispatch('sendData', { data: watchTogetherState });

      VideoOwnerTrackingService.handleStateUpdate({
        isVideoPlaying,
        timeInVideo
      });
    } else if (!getters.isWatchTogetherOwner) {
      const synchronizedTimeInVideo = VideoOwnerTrackingService.extrapolateTimeInVideo();
      // Check if the user lost sync with the owner. If they were already out-of-sync,
      // keep them in that state until they manually choose to sync. Otherwise, compare
      // the state of the video and the approximate time in video
      const isSyncedWithOwner =
        state.isSyncedWithOwner &&
        isVideoPlaying === !VideoOwnerTrackingService.isPaused &&
        Math.abs(synchronizedTimeInVideo - timeInVideo) < 2;

      commit('SET_IS_SYNCED_WITH_OWNER', isSyncedWithOwner);
    }
  },

  startWatchTogether: (
    { dispatch, rootState, rootGetters },
    { videoMetadata }
  ) => {
    // Prevent sharing a new video while another participant is already sharing a video
    if (rootGetters['watchTogether/modal/isAnotherParticipantAlreadySharing']) {
      dispatch(
        'watchTogether/modal/showAnotherParticipantAlreadySharingDialog',
        null,
        { root: true }
      );
      return;
    }

    analytics.trackEvent(ANALYTICS.WATCH_TOGETHER, {
      'Video Source': videoMetadata.videoSiteType,
      'Num of Participants': rootGetters.activeParticipants.length
    });

    const watchTogetherState = createState(
      true,
      false,
      0,
      rootState.myParticipantId,
      videoMetadata
    );

    // TODO: Remove this hack, and instead of it simply recognize that a new video has begun
    dispatch('stopWatchTogether');

    // TODO: Ew! This was done just to let 'stopWatchTogether' happen, but it is absolutely nothing more than an unreliable temporary workaround
    setTimeout(() => {
      dispatch('sendData', { data: watchTogetherState });
      dispatch('setOptimalLayout');
    }, 1000);
  },

  resetLocalWatchTogetherState: ({ commit }) => {
    commit('RESET_STATE');
    commit('SET_SHOW_WATCH_TOGETHER_MUTE_INDICATION', false);
    VideoOwnerTrackingService.reset();
  },

  stopWatchTogether: ({ dispatch }) => {
    const emptyState = getInitialState();
    VideoOwnerTrackingService.reset();
    dispatch('sendData', { data: emptyState });
  },

  init: ({ dispatch }) => {
    MeetingManager.onSignal(APP_SIGNALS.WATCH_TOGETHER, (event) => {
      dispatch('updateWatchTogether', event.data);
    });
  },

  updateWatchTogether: ({ commit, state, rootState, dispatch }, data) => {
    if (rootState.myParticipantId === data.watchTogetherOwnerParticipantId) {
      // The owner updates itself instantly and doesn't need the signals
      return;
    }

    if (data.isWatchTogetherActive) {
      VideoOwnerTrackingService.handleStateUpdate({
        isVideoPlaying: data.isVideoPlaying,
        timeInVideo: data.timeInVideo.seconds
      });
    } else {
      VideoOwnerTrackingService.reset();
    }

    if (data.isWatchTogetherActive && !state.isWatchTogetherActive) {
      dispatch('setOptimalLayout');
    }

    // When the user is not synced with the owner, omit the owner's playback state
    if (!state.isSyncedWithOwner) {
      data.isVideoPlaying = state.isVideoPlaying;
      data.timeInVideo = state.timeInVideo;
    }

    if (!data.isWatchTogetherActive) {
      dispatch('resetLocalWatchTogetherState');
    } else {
      commit('UPDATE_VIDEO_STATE', data);
    }
  },

  setOptimalLayout: ({ dispatch }) => {
    dispatch(
      'layout/setLayoutMode',
      {
        layoutMode: LAYOUT_MODE_TYPES.SPEAKER
      },
      { root: true }
    );

    // Make sure no stream is pinned
    dispatch('pinVideoStream', '', { root: true });
  },

  setIsVideoPlaying: ({ dispatch, commit, rootState }, isActivelyPlaying) => {
    const isMicEnabled = rootState.isMicEnabled;

    if (isActivelyPlaying) {
      dispatch('loudnessDetector/turnLoudnessDetectorOff', null, {
        root: true
      });

      // Turn the mic off when the video is playing to prevent echo
      // TODO: Consider improving UX by not muting users who have turned on their mic while the video was playing
      if (isMicEnabled) {
        dispatch('toggleMic', null, { root: true });
        commit('SET_SHOW_WATCH_TOGETHER_MUTE_INDICATION', true);
      }
    }
    commit('SET_IS_VIDEO_PLAYING', isActivelyPlaying);
  },

  setShowWatchTogetherMuteIndication: ({ state, commit }, shouldShow) => {
    // Prevent displaying WT mute indication more than once
    if (state.hasSeenMuteIndication && shouldShow) {
      return;
    }

    commit('SET_SHOW_WATCH_TOGETHER_MUTE_INDICATION', shouldShow);
    commit('SET_HAS_SEEN_MUTE_INDICATION', shouldShow);
  },

  handleParticipantStateUpdate: ({ dispatch, state }, event) => {
    if (!state.isWatchTogetherActive) {
      return;
    }

    if (event.state === PARTICIPANT_STATE_TYPES.JOINED) {
      dispatch('_handleParticipantJoined', {
        connectionId: event.connectionId,
        participantId: event.participantId
      });
    } else if (
      [PARTICIPANT_STATE_TYPES.LEFT, PARTICIPANT_STATE_TYPES.KICKED].includes(
        event.state
      )
    ) {
      dispatch('_handleParticipantLeft', {
        participantId: event.participantId
      });
    }
  },

  // Make the owner of the watch-together session send a signal to new participants
  _handleParticipantJoined: (
    { dispatch, getters, state, rootState },
    { connectionId, participantId }
  ) => {
    if (
      !getters.isWatchTogetherOwner ||
      participantId === rootState.myParticipantId
    ) {
      return;
    }

    const watchTogetherState = createState(
      true,
      state.isVideoPlaying,
      VideoOwnerTrackingService.extrapolateTimeInVideo(),
      state.watchTogetherOwnerParticipantId,
      state.videoMetadata
    );

    const stream = rootState.streams?.find(
      (stream) => stream?.connectionId === connectionId
    );
    const connection = stream?.connection;

    dispatch('sendData', {
      data: watchTogetherState,
      to: connection
    });
  },

  // When the owner of a watch together session leaves, reset its state
  _handleParticipantLeft: ({ dispatch, state }, { participantId }) => {
    if (
      !state.watchTogetherOwnerParticipantId ||
      participantId !== state.watchTogetherOwnerParticipantId
    ) {
      return;
    }

    dispatch('resetLocalWatchTogetherState');
  },

  syncToOwner: ({ commit }) => {
    const synchronizedTimeInVideo = VideoOwnerTrackingService.extrapolateTimeInVideo();
    commit('SET_IS_VIDEO_PLAYING', !VideoOwnerTrackingService.isPaused);
    commit('SET_TIME_IN_VIDEO', synchronizedTimeInVideo);
    commit('SET_IS_SYNCED_WITH_OWNER', true);
  }
};
