<template>
  <MobileAppScreen v-if="isAppReady" />
  <MobileEntranceScreen v-else-if="showEntranceScreen" />
  <LoadingScreen v-else />
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import MobileAppScreen from '@/views/mobile/MobileAppScreen';
import LoadingScreen from '@/views/LoadingScreen.vue';
import MobileEntranceScreen from '@/views/mobile/MobileEntranceScreen.vue';

export default {
  name: 'MobileHome',

  components: {
    MobileAppScreen,
    LoadingScreen,
    MobileEntranceScreen
  },

  computed: {
    ...mapState(['initAppModeDone', 'showEntranceScreen']),
    ...mapGetters(['isAppReady'])
  },

  created() {
    this.initApp(this.$route.query);
  },

  methods: {
    ...mapActions(['initApp'])
  }
};
</script>
