var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAppReady
    ? _c("MobileAppScreen")
    : _vm.showEntranceScreen
    ? _c("MobileEntranceScreen")
    : _c("LoadingScreen")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }