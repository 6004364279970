/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-brain': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.7 6.1v3.6h1.5v-2h2.6l1.4-1.4c.4.2.9.4 1.4.4 1.6 0 2.8-1.3 2.8-2.8 0-1.6-1.3-2.8-2.8-2.8-1.6 0-2.8 1.3-2.8 2.8 0 .5.1 1 .4 1.4l-.9.9h-3.6zm6.9-3.5c.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3-.3 0-.7-.1-.9-.4-.2-.2-.4-.5-.4-.9.1-.8.6-1.3 1.3-1.3z"/><path pid="1" d="M18.6 17.4c-.5 0-1 .1-1.4.4l-1.4-1.4h-4.1v1.8c0 .6-.1 1.1-.3 1.7-.2.5-.5 1-.9 1.4-.4.4-.9.7-1.4.9-.5.2-1.1.3-1.7.3-.6 0-1.1-.1-1.7-.3-.5-.2-1-.5-1.4-.9-.4-.4-.7-.9-.9-1.4-.2-.5-.3-1.1-.3-1.7 0-.5.1-.9.3-1.4l.2-.6-.6-.4c-.3-.2-.5-.5-.7-.8-.2-.3-.3-.7-.3-1 0-.4.1-.8.3-1.1.1-.2.2-.3.3-.5.9.7 1.9 1.1 3 1.3.1.5.3 1 .6 1.5.5.6 1.1 1.1 1.9 1.4l.7.2.5-1.5-.7-.2c-.4-.1-.8-.4-1.1-.8-.3-.4-.4-.8-.4-1.3v-.2c0-.3.1-.6.2-.8.1-.3.3-.5.6-.7l.6-.5-1-1.2-.6.5c-.4.4-.7.8-1 1.3-.1.2-.2.5-.2.7-.9-.1-1.8-.6-2.5-1.3C2.5 10.1 2 9 2 7.9c0-.6.1-1.1.3-1.7.2-.5.5-1 .9-1.4.4-.4.9-.7 1.4-.9.5-.2 1.1-.3 1.7-.3h.8l.2-.6c.1-.4.4-.8.8-1.1.4-.2.9-.4 1.3-.4.3 0 .6 0 .9.2s.5.3.7.5.4.5.5.7.2.6.2.9v.8h1.5v-.8c0-.5-.1-1-.3-1.5-.2-.5-.5-.9-.8-1.3-.4-.4-.8-.6-1.3-.8-.4-.1-.9-.2-1.4-.2-.8 0-1.5.2-2.2.7-.5.3-.9.8-1.1 1.3-.7 0-1.3.2-1.9.4-.8.4-1.4.8-2 1.3-.5.5-1 1.2-1.3 1.9-.3.8-.4 1.5-.4 2.3 0 1.2.4 2.5 1.1 3.5-.2.2-.5.5-.6.8-.3.6-.5 1.2-.5 1.8 0 .6.1 1.2.4 1.8.2.4.5.7.8 1-.1.4-.2.9-.2 1.3 0 .8.1 1.5.4 2.3.3.7.7 1.4 1.3 1.9.5.5 1.2 1 1.9 1.3.8.3 1.5.4 2.3.4.8 0 1.5-.1 2.3-.4.7-.3 1.4-.7 1.9-1.3.5-.5 1-1.2 1.3-1.9.3-.7.4-1.5.4-2.3v-.3h2l.9.9c-.2.4-.4.9-.4 1.4 0 1.6 1.3 2.8 2.8 2.8 1.6 0 2.8-1.3 2.8-2.8 0-1.5-1.2-2.7-2.8-2.7zm0 4c-.7 0-1.3-.6-1.3-1.3 0-.3.1-.6.3-.9l.1-.1c.2-.2.5-.3.9-.3.7 0 1.3.6 1.3 1.3 0 .8-.6 1.3-1.3 1.3z"/><path pid="2" d="M20.7 9.2c-1.3 0-2.4.9-2.7 2h-6.2v3.6h1.5v-2H18c.3 1.2 1.4 2 2.7 2 1.6 0 2.8-1.3 2.8-2.8 0-1.6-1.3-2.8-2.8-2.8zm0 4.1c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3-.1.7-.6 1.3-1.3 1.3z"/>'
  }
})
