/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-stream-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 24a1.5 1.5 0 01-1.5-1.5v-21a1.5 1.5 0 113 0v21A1.5 1.5 0 0112 24zm-4.5-4.5A1.5 1.5 0 016 18V6a1.5 1.5 0 013 0v12a1.5 1.5 0 01-1.5 1.5zm-5.56-4.94A1.5 1.5 0 004.5 13.5v-3a1.5 1.5 0 00-3 0v3c0 .398.158.78.44 1.06zM16.5 19.5A1.5 1.5 0 0115 18V6a1.5 1.5 0 113 0v12a1.5 1.5 0 01-1.5 1.5zm3.44-4.94a1.5 1.5 0 002.56-1.06v-3a1.5 1.5 0 10-3 0v3c0 .398.158.78.44 1.06z"/>'
  }
})
