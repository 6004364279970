/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-notification-off': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 0a5.478 5.478 0 00-3.3 1.1.5.5 0 10.6.8 4.502 4.502 0 017.2 3.6v4A2.5 2.5 0 0015 12a.5.5 0 000-1 1.5 1.5 0 01-1.5-1.5v-4A5.5 5.5 0 008 0zM1.354.646a.5.5 0 10-.708.708l.437.436 1.77 1.77L2.5 9.5A1.5 1.5 0 011 11H.5a.5.5 0 000 1h10.793l3.353 3.354a.5.5 0 00.708-.707L3.804 3.096a.497.497 0 00-.017-.017L1.354.646zm-.27 1.144l.46.462L2.853 3.56 1.083 1.79zm2.563 2.564L10.293 11H3a2.5 2.5 0 00.5-1.5v-4c0-.387.05-.773.147-1.146z"/><path pid="1" d="M6.5 13a.5.5 0 00-1 0c0 .663.263 1.401.732 1.87.47.469 1.105.63 1.768.63s1.299-.161 1.768-.63c.469-.469.732-1.207.732-1.87a.5.5 0 00-1 0 1.499 1.499 0 01-2.56 1.06A1.499 1.499 0 016.5 13z"/>'
  }
})
