/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-files': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4 .5a.5.5 0 01.5-.5h11a.5.5 0 01.5.5v13a.5.5 0 01-1 0V1H4.5A.5.5 0 014 .5zm-2 3a.5.5 0 01.5-.5h10a.5.5 0 01.5.5v12a.5.5 0 01-.5.5h-10a.5.5 0 01-.5-.5v-12zM3 4v11h9V4H3zm2.5 2a.5.5 0 000 1h4a.5.5 0 000-1h-4zM5 9.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zm.5 2.5a.5.5 0 000 1h4a.5.5 0 000-1h-4z"/>'
  }
})
