/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-user-lock-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.5 7.5c0 3.3 2.7 6 6 6s6-2.7 6-6V6c0-3.3-2.7-6-6-6s-6 2.7-6 6v1.5zM0 24c0-4.2 3.3-7.5 7.5-7.5h6V24H0zm15-6.5a1 1 0 011-1 3.5 3.5 0 117 0 1 1 0 011 1V23a1 1 0 01-1 1h-7a1 1 0 01-1-1v-5.5zm7-1a2.5 2.5 0 00-5 0h5z"/>'
  }
})
