var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "tooltip",
          rawName: "v-tooltip.top-start",
          value: {
            content: _vm.hoverTooltip,
            classes: "meeting-action-menu-btn-tooltip",
          },
          expression:
            "{\n    content: hoverTooltip,\n    classes: 'meeting-action-menu-btn-tooltip'\n  }",
          modifiers: { "top-start": true },
        },
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.closeMenu,
          expression: "closeMenu",
        },
      ],
      staticClass: "meeting-action-container",
      class: {
        "button-with-focused-element": _vm.hasFocusedElement,
      },
    },
    [
      _c(
        "vwc-action-group",
        { attrs: { shape: "rounded", layout: "ghost", tight: "" } },
        [
          _c(
            "vwc-button",
            {
              class: {
                clicked: !_vm.split && _vm.isMenuOpen,
                privacy: _vm.label === "Privacy",
              },
              attrs: {
                id: !_vm.split ? _vm.label : "",
                "data-cy": "meeting-action-button",
                label: _vm.label,
                layout: !_vm.split && _vm.isMenuOpen ? "filled" : "text",
                shape: "rounded",
                stacked: "",
                disabled: _vm.disabled,
                onmouseup: "this.blur()",
              },
              on: {
                mouseover: function ($event) {
                  return _vm.$emit("hover")
                },
                mouseleave: function ($event) {
                  return _vm.$emit("unhover")
                },
                click: function ($event) {
                  !_vm.split && _vm.hasMenu
                    ? _vm.toggleMenu()
                    : _vm.$emit("click")
                },
              },
            },
            [
              _vm.loading
                ? _c("vwc-circular-progress", {
                    staticClass: "loading",
                    attrs: { slot: "icon", indeterminate: "", density: "-6" },
                    slot: "icon",
                  })
                : _c("vwc-icon", {
                    staticClass: "icon",
                    class: { disabled: _vm.disabled },
                    attrs: {
                      slot: "icon",
                      "data-cy": "action-button-icon",
                      connotation: _vm.iconConnotation,
                      type: _vm.icon,
                    },
                    slot: "icon",
                  }),
            ],
            1
          ),
          _c("span", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.split,
                expression: "split",
              },
            ],
            staticClass: "separator",
            attrs: { role: "separator" },
          }),
          _c("vwc-icon-button", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.split,
                expression: "split",
              },
            ],
            staticClass: "split",
            class: { clicked: _vm.isMenuOpen },
            attrs: {
              id: _vm.split ? _vm.label : "",
              icon: "chevron-up-solid",
              layout: _vm.isMenuOpen ? "filled" : "text",
              shape: "rounded",
              dense: "",
              disabled: _vm.disableSplit,
              onmouseup: "this.blur()",
            },
            on: {
              click: function ($event) {
                return _vm.toggleMenu()
              },
            },
          }),
        ],
        1
      ),
      _c(
        "vwc-popup",
        {
          staticClass: "menu",
          attrs: {
            anchor: _vm.label,
            corner: _vm.flipMenu ? "top-start" : "top-end",
            open: _vm.isMenuOpen,
          },
          on: { closed: _vm.closeMenu },
        },
        [
          _c(
            "div",
            { staticClass: "popup-container" },
            _vm._l(_vm.lists, function (list, index) {
              return _c(
                "vwc-list",
                { key: index },
                [
                  list.label
                    ? _c("div", { staticClass: "list-label" }, [
                        _vm._v(_vm._s(list.label)),
                      ])
                    : _vm._e(),
                  _vm._l(list.items, function (item) {
                    return _c(
                      "vwc-list-item",
                      {
                        key: item.id,
                        staticClass: "list-item",
                        class: {
                          interactive: item.type === "interactive",
                          "focus-animation": item.shouldHaveFocusAnimation,
                          "glow-button": _vm.hasFocusedElement,
                        },
                        attrs: {
                          graphic: item.iconName ? "icon" : "",
                          hasMeta: "",
                          twoline: item.subtitle,
                          disabled: item.disabled,
                        },
                        on: {
                          animationend: function ($event) {
                            return _vm.focusAnimationEnded(item)
                          },
                          animationcancel: function ($event) {
                            return _vm.focusAnimationEnded(item)
                          },
                          click: function ($event) {
                            return _vm.menuItemClicked(item, list.defaultAction)
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "item-content" }, [
                          _vm._v(_vm._s(item.label)),
                        ]),
                        item.subtitle
                          ? _c(
                              "span",
                              {
                                attrs: { slot: "secondary" },
                                slot: "secondary",
                              },
                              [_vm._v(_vm._s(item.subtitle))]
                            )
                          : _vm._e(),
                        item.iconName
                          ? _c("vwc-icon", {
                              attrs: {
                                slot: "graphic",
                                type: item.iconName,
                                size: "small",
                              },
                              slot: "graphic",
                            })
                          : _vm._e(),
                        item.type === "interactive"
                          ? _c("vwc-switch", {
                              attrs: {
                                slot: "meta",
                                connotation: "cta",
                                disabled: item.disabled,
                                checked: item.active,
                              },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                },
                              },
                              slot: "meta",
                            })
                          : item.active
                          ? _c("vwc-icon", {
                              staticClass: "meta-icon",
                              attrs: {
                                slot: "meta",
                                type: "check-line",
                                size: "small",
                              },
                              slot: "meta",
                            })
                          : _vm._e(),
                        _vm.isFocusAnimationInProgress(item) ||
                        _vm.hasFocusedElement
                          ? _c(
                              "portal",
                              { attrs: { to: "overlay-bg-placeholder" } },
                              [
                                _c(
                                  "transition",
                                  { attrs: { name: "overlay-bg-animation" } },
                                  [
                                    _c("div", {
                                      staticClass: "overlay-bg",
                                      on: { click: _vm.stopFocusAnimation },
                                    }),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  index !== _vm.lists.length - 1
                    ? _c("li", {
                        staticClass: "lists-divider",
                        attrs: { role: "divider" },
                      })
                    : _vm._e(),
                ],
                2
              )
            }),
            1
          ),
        ]
      ),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }