/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-alert-negative': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.805 20.794L13.443 1.366c-.615-1.155-2.27-1.155-2.888 0L.195 20.794a1.637 1.637 0 001.444 2.407h20.724a1.637 1.637 0 001.442-2.407zM12 19.93a1.637 1.637 0 11.001-3.274A1.637 1.637 0 0112 19.93zm1.636-4.908h-3.272V8.476h3.272v6.544z"/>'
  }
})
