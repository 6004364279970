<template>
  <div class="captions" :class="{ floating }" :style="captionsStyle">
    <div v-show="currentCaptionsList.length > 0" class="captions-container">
      <div class="captions-content">
        <div
          v-for="(caption, index) in currentCaptionsList"
          :key="`text-${index}`"
          class="captions-text"
        >
          <span
            v-observe-visibility="{
              callback: participantCaptionsVisibilityChanged
            }"
            >{{ caption.text }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'CaptionsView',

  props: {
    floating: {
      type: Boolean,
      default: false
    },

    offsetY: {
      type: Number,
      default: 0
    }
  },

  computed: {
    ...mapState('captions', ['currentCaptionsList']),

    captionsStyle() {
      const style = {};
      if (this.offsetY) {
        style['transform'] = `translateY(${this.offsetY}px)`;
      }
      return style;
    }
  },

  methods: {
    ...mapActions('captions', ['moveLastCaptionToHistory']),

    participantCaptionsVisibilityChanged(isVisible) {
      if (!isVisible) {
        this.moveLastCaptionToHistory();
      }
    }
  }
};
</script>

<style scoped>
.captions {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 80px;
  margin: 12px 0px;
  z-index: 1;
  pointer-events: none;
}

.captions-container {
  background: #0d0d0d;
  max-width: 600px;
  width: 100%;
  height: 80px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 12px;
  margin: 0 10px;
}

.captions-content {
  position: relative;
  width: 100%;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

.captions-text {
  font-weight: bold;
  line-height: 28px;
  font-size: 20px;
  color: #fff;
}

.floating {
  position: absolute;
  bottom: 0;
}
</style>
