<template>
  <div class="content">
    <div v-if="isBranded" class="content-div">
      <vwc-icon class="icon" type="puzzled-line" size="large" />
      <h2 class="title">{{ $t('unsupported_browser_screen.title') }}</h2>
      <h4 class="description">
        {{ $t('unsupported_browser_screen.formatted_description') }}
      </h4>
    </div>
    <div v-else class="content-div">
      <div class="form">
        <vwc-icon type="video-conference-full" />
        <div class="text">
          <h2 class="title">{{ $t('unsupported_browser_screen.title') }}</h2>
          <div class="description">
            {{ $t('unsupported_browser_screen.description') }}
          </div>
          <div>
            {{ $t('unsupported_browser_screen.description2') }}
          </div>
          <vwc-button
            class="download-btn"
            :label="$t('unsupported_browser_screen.download_button')"
            layout="filled"
            connotation="cta"
            @click="downloadVBC"
          />
          <div class="open-vbc-description">
            {{ $t('unsupported_browser_screen.open_vbc_description') }}
            <span class="open-vbc-link Vlt-purple-dark" @click="openVBCApp">{{
              $t('unsupported_browser_screen.here_text')
            }}</span>
            {{ $t('unsupported_browser_screen.join_the_meeting_text') }}
          </div>
          <iframe
            ref="preAlphaMeetingsIframe"
            class="hidden-frames"
            :src="vbcAppPreAlphaMeetingLink"
          ></iframe>
          <iframe
            ref="alphaMeetingsIframe"
            class="hidden-frames"
            :src="vbcAppAlphaMeetingLink"
          ></iframe>
          <iframe
            ref="meetingsIframe"
            class="hidden-frames"
            :src="vbcAppMeetingLink"
          ></iframe>
        </div>
      </div>
    </div>
    <v-icon
      v-if="!isBranded"
      iconName="vonage-page-logo"
      class="vonage-banner"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  isAndroidMobileDevice,
  isAppleMobileDevice,
  OS
} from '@/helpers/global-helpers';
import { ANALYTICS, OS_TYPES } from '@/consts/global-consts';
import analytics from '@/services/analytics-service';
import {
  VONAGE_APP_GOOGLE_PLAY_STORE_URL,
  VONAGE_APP_APP_STORE_URL
} from '@/consts/global-consts';

export default {
  name: 'UnsupportedBrowserScreen',

  data() {
    return {
      roomToken: this.$route.query.room_token,
      VONAGE_APP_GOOGLE_PLAY_STORE_URL,
      VONAGE_APP_APP_STORE_URL
    };
  },

  computed: {
    ...mapState(['isBranded']),

    ...mapGetters(['domainsConfigurations']),

    linkParams() {
      return this.roomToken ? `?room_token=${this.roomToken}` : '';
    },

    vbcAppPreAlphaMeetingLink() {
      const domain = this.domainsConfigurations.deeplinkProtocolPrefix;
      return `${domain}-pre-alpha://meeting/${this.linkParams}`;
    },
    vbcAppAlphaMeetingLink() {
      const domain = this.domainsConfigurations.deeplinkProtocolPrefix;
      return `${domain}-alpha://meeting/${this.linkParams}`;
    },
    vbcAppMeetingLink() {
      const domain = this.domainsConfigurations.deeplinkProtocolPrefix;
      return `${domain}://meeting/${this.linkParams}`;
    }
  },

  mounted() {
    const timeBeforeChangeToIdlePage = 60 * 1000;
    setTimeout(() => {
      window.close();
    }, timeBeforeChangeToIdlePage);
  },

  methods: {
    openVBCApp() {
      if (isAndroidMobileDevice || isAppleMobileDevice) {
        window.location.assign(process.env.VUE_APP_MEETINGS_ROOM_URL);
      } else {
        this.$refs.preAlphaMeetingsIframe.contentWindow.location.assign(
          this.vbcAppPreAlphaMeetingLink
        );
        this.$refs.alphaMeetingsIframe.contentWindow.location.assign(
          this.vbcAppAlphaMeetingLink
        );
        this.$refs.meetingsIframe.contentWindow.location.assign(
          this.vbcAppMeetingLink
        );
      }
    },

    downloadVBC() {
      analytics.trackEvent(ANALYTICS.APP_DOWNLOAD, {
        Source: 'Unsupported Browser Page',
        OS: OS.name
      });

      if (isAndroidMobileDevice) {
        window.location.assign(VONAGE_APP_GOOGLE_PLAY_STORE_URL);
      } else if (isAppleMobileDevice) {
        window.location.assign(VONAGE_APP_APP_STORE_URL);
      } else if (OS.name === OS_TYPES.MAC) {
        window.location.assign('https://m.vona.ge/web_getMAC');
      } else {
        window.location.assign('https://m.vona.ge/getWindows');
      }
    }
  }
};
</script>

<style scoped>
@media only screen and (min-width: 642px) {
  .vonage-banner {
    height: 100%;
  }

  .content-div {
    margin: 20vh 200px auto 200px;
    flex: 1;
  }

  .branded .content-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .download-btn {
    width: 205px;
    margin-top: 25px;
    margin-bottom: 8px;
  }

  .content-div .form {
    width: 420px;
  }
}

@media only screen and (max-width: 641px) {
  .content {
    overflow: hidden;
  }

  .vonage-banner {
    display: none;
  }

  .content-div {
    margin: auto 16px;
  }

  .download-btn {
    margin-top: 40px;
  }

  .content-div .form .text {
    display: flex;
    flex-direction: column;
  }

  .branded .content-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.content {
  display: flex;
  width: 100vw;
  height: 100vh;
  background: #fff;
}

.content-div .form vwc-icon {
  width: 47px;
  height: 47px;
}

.content-div .form .text {
  margin-top: 20px;
  font-size: 16px;
  line-height: 26px;
}

.description {
  margin-bottom: 25px;
}

.open-vbc-description {
  margin-top: 12px;
}

.open-vbc-link {
  cursor: pointer;
  text-decoration: underline;
}

.hidden-frames {
  display: none;
}

/***IDLE PAGE CONTENT***/

.idle-page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.vonage-logo {
  width: 200px;
  height: 44px;
  margin-top: 70px;
}

.illustration {
  margin-top: 100px;
  width: 315px;
  height: 214px;
}

.idle-page-content .text {
  margin-top: 35px;
  text-align: center;
}

.idle-page-content .text h3 {
  font-size: 29px;
  line-height: 34px;
}

.idle-page-content .text p {
  font-size: 16px;
  line-height: 26px;
}

.branded .content-div .icon {
  --vvd-icon-size: 64px;
  margin-bottom: 32px;
}

.branded .content-div .title {
  text-align: center;
  font-size: 32px;
  line-height: 44px;
}

.branded .content-div .description {
  margin: unset;
  text-align: center;
  font-size: 20px;
  line-height: 28px;
}
</style>
