/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-chat-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 8.25C0 3.693 4.365 0 9.75 0s9.75 3.693 9.75 8.25-4.365 8.25-9.75 8.25c-1.506 0-2.928-.297-4.202-.813L1.5 18v-5.367C.555 11.361 0 9.863 0 8.25zm22.28 2.041C23.355 11.404 24 12.77 24 14.25c0 1.387-.56 2.677-1.518 3.75h.018v6l-5.54-3.165C16.33 20.94 15.673 21 15 21c-2.166 0-4.154-.575-5.708-1.532.07.002.14.009.209.016.082.008.165.016.249.016 6.24 0 11.436-3.978 12.53-9.209z"/>'
  }
})
