/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-voicemail-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M22.5 0h-21A1.418 1.418 0 000 1.5V18a1.418 1.418 0 001.5 1.5h6L12 24l4.5-4.5h6A1.417 1.417 0 0024 18V1.5A1.417 1.417 0 0022.5 0zm-15 13.5h-3V6h3v7.5zm6 1.5h-3V4.5h3V15zm6-3h-3V7.5h3V12z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
