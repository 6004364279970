var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "preview" }, [
    _c(
      "div",
      { staticClass: "preview-content" },
      [
        _c(
          "div",
          { staticClass: "participant" },
          [
            _c("div", { staticClass: "avatar" }, [_vm._v("RB")]),
            _vm._m(0),
            _c("v-icon", {
              staticClass: "video-icon",
              class: _vm.isVideo ? "icon-enabled" : "icon-disabled",
              attrs: {
                md: "",
                iconName: _vm.isVideo ? "Vlt-icon-video" : "Vlt-icon-video-off",
              },
              on: { click: _vm.toggleVideo },
            }),
            _c("v-icon", {
              staticClass: "audio-icon",
              class: _vm.isAudio ? "icon-enabled" : "icon-disabled",
              attrs: {
                md: "",
                iconName: _vm.isAudio
                  ? "Vlt-icon-microphone"
                  : "Vlt-icon-microphone-mute",
              },
              on: { click: _vm.toggleAudio },
            }),
          ],
          1
        ),
        _c("vwc-button", {
          staticClass: "enter-button",
          attrs: {
            label: "Join Meeting",
            layout: "filled",
            connotation: "cta",
          },
        }),
        _c("div", { staticClass: "settings" }, [
          _c(
            "div",
            { staticClass: "settings-row" },
            [
              _c("v-icon", {
                staticClass: "icon Vlt-white",
                attrs: { "icon-name": "Vlt-icon-video-negative" },
              }),
              _c("p", [_vm._v("Enable computer video")]),
              _c("vwc-switch", {
                attrs: { connotation: "cta", checked: _vm.isVideo },
                on: { change: _vm.toggleVideo },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "settings-row" },
            [
              _c("v-icon", {
                staticClass: "icon Vlt-white",
                attrs: { "icon-name": "Vlt-icon-microphone-full" },
              }),
              _c("p", [_vm._v("Enable computer audio")]),
              _c("vwc-switch", {
                attrs: { connotation: "cta", checked: _vm.isAudio },
                on: { change: _vm.toggleAudio },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "bottom-bar" },
          [
            _c(
              "v-button",
              {
                staticClass: "share-screen-button",
                attrs: { lg: "", secondary: "", app: "" },
              },
              [
                _c("v-icon", {
                  staticClass: "action-button-icon",
                  attrs: { iconName: "Vlt-icon-screen-share-full" },
                }),
                _c("span", { staticClass: "action-button-title" }, [
                  _vm._v("Screen Share"),
                ]),
              ],
              1
            ),
            _c(
              "v-button",
              {
                staticClass: "apps-button",
                attrs: { lg: "", secondary: "", app: "" },
              },
              [
                _c("v-icon", {
                  staticClass: "action-button-icon",
                  attrs: { iconName: "Vlt-icon-rocket-full" },
                }),
                _c("span", { staticClass: "action-button-title" }, [
                  _vm._v("Apps"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "names" }, [
      _c("div", { staticClass: "participant-name" }, [
        _vm._v("Randall Warren"),
      ]),
      _c("div", { staticClass: "participant-description" }, [_vm._v("Host")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }