var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "dropdown-container" },
    [
      _c("div", { ref: "anchor" }, [_vm._t("toggleDropdown")], 2),
      _c(
        "vwc-dropdown",
        {
          ref: "dropdown",
          attrs: {
            corner: "BOTTOM_START",
            open: _vm.open,
            fullwidth: _vm.fullwidth,
            stayOpenOnBodyClick: _vm.stayOpenOnBodyClick,
          },
        },
        [
          _c("VTextfield", {
            staticClass: "search",
            attrs: {
              slot: "header",
              placeholder: _vm.$t("dropdown.search_placeholder"),
              icon: "search-line",
              dense: "",
              outlined: "",
            },
            on: { input: _vm.filter },
            slot: "header",
            model: {
              value: _vm.filterText,
              callback: function ($$v) {
                _vm.filterText = $$v
              },
              expression: "filterText",
            },
          }),
          _c(
            "vwc-list",
            { ref: "dropdownContent", staticClass: "list" },
            _vm._l(_vm.filteredOptions, function (option, index) {
              return _c(
                "vwc-list-item",
                {
                  key: index,
                  attrs: { graphic: "icon", shape: "rounded" },
                  on: {
                    click: function ($event) {
                      return _vm.onSelection(option)
                    },
                  },
                },
                [
                  _c("vwc-checkbox", {
                    attrs: { slot: "graphic", checked: option.checked },
                    slot: "graphic",
                  }),
                  _c("span", [_vm._v(" " + _vm._s(option.label))]),
                ],
                1
              )
            }),
            1
          ),
          _c("vwc-button", {
            staticClass: "action-button",
            attrs: {
              slot: "actions",
              type: "submit",
              label: _vm.$t("dropdown.cancel_button"),
              layout: "outlined",
              dense: "",
            },
            on: { click: _vm.hide },
            slot: "actions",
          }),
          _c("vwc-button", {
            staticClass: "action-button",
            attrs: {
              slot: "actions",
              "data-cy": "apply-button",
              layout: "filled",
              type: "submit",
              label: _vm.$t("dropdown.apply_button"),
              dense: "",
              disabled: !_vm.hasChanges,
            },
            on: { click: _vm.onApply },
            slot: "actions",
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }