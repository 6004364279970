/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-hearing': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.25 9c0-3.246 1.315-6.185 3.44-8.31l1.06 1.06a10.215 10.215 0 00-3 7.25c0 2.834 1.145 5.395 3 7.25l-1.06 1.06A11.715 11.715 0 012.25 9zm4 0c0-4.344 3.406-7.75 7.75-7.75 4.344 0 7.75 3.406 7.75 7.75h-1.5c0-3.516-2.734-6.25-6.25-6.25S7.75 5.484 7.75 9c0 1.118.34 2.384.977 3.538.832 1.508 1.797 2.26 2.65 2.918l.026.02c.795.608 1.545 1.181 1.94 2.39.553 1.68 1.215 2.514 2.345 3.11.406.18.854.274 1.312.274A3.249 3.249 0 0020.25 18h1.5a4.749 4.749 0 01-6.698 4.334l-.02-.009-.02-.01c-1.577-.824-2.451-2.03-3.094-3.98l-.001-.002c-.24-.737-.639-1.064-1.453-1.687l-.002-.002c-.887-.683-2.06-1.59-3.049-3.381C6.67 11.915 6.25 10.402 6.25 9zm4.5 0a3.25 3.25 0 016.5 0 3.25 3.25 0 01-6.5 0zM14 7.25a1.75 1.75 0 000 3.5 1.75 1.75 0 000-3.5z"/>'
  }
})
