/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-play-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.4 10.8L7.4.3A1.5 1.5 0 005 1.5v21a1.5 1.5 0 002.4 1.2l15-10.5c.75-.6.75-1.8 0-2.4z"/>'
  }
})
