<template>
  <vwc-banner
    data-cy="old-account-banner"
    class="banner"
    connotation="warning"
    :message="$t('old_account_banner.message')"
    open="true"
    icon="warning-solid"
    dismissible
  >
    <a
      slot="actionItems"
      class="link"
      href="https://docs.google.com/document/d/1SurdHFTk8LFQ4oNy4hWzWkxk2TqqRh83t0aGVXkXtVs/edit"
      target="_blank"
      >{{ $t('old_account_banner.learn_how_button') }}
    </a>
  </vwc-banner>
</template>

<script>
export default {
  name: 'OldAccountBanner'
};
</script>

<style scoped>
.banner {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 100;
}

.link {
  color: black;
  text-decoration-line: underline;
}
</style>
