var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "messages-container" },
    [
      _vm.chatMessages.length === 0
        ? _c(
            "div",
            { staticClass: "empty-state-container" },
            [
              _c(
                "vwc-empty-state",
                {
                  attrs: {
                    heading: _vm.$t("messages_list.empty_state_title"),
                    body: _vm.$t("messages_list.empty_state_description"),
                  },
                },
                [
                  _c("img", {
                    attrs: {
                      slot: "graphic",
                      "data-cy": "empty-chat-img",
                      src: _vm.emptyStatImg,
                    },
                    slot: "graphic",
                  }),
                ]
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              ref: "messagesRows",
              staticClass: "messages-rows",
              class: { "smooth-scroll": _vm.smoothScroll },
              on: { scroll: _vm.debouncedScrollHandler },
            },
            [
              _vm._l(_vm.chatMessages, function (message) {
                return _c("MessageRow", {
                  key: message.messageId,
                  attrs: { message: message },
                  on: {
                    resend: function ($event) {
                      return _vm.$emit("resend", message)
                    },
                    copyToClipboard: function ($event) {
                      return _vm.$emit("copyToClipboard", message.body)
                    },
                    openImageView: function ($event) {
                      return _vm.$emit("openImageView", message)
                    },
                  },
                })
              }),
              _c("div", { staticClass: "bottom-div-container" }, [
                _c("div", {
                  directives: [
                    {
                      name: "observe-visibility",
                      rawName: "v-observe-visibility",
                      value: {
                        callback: _vm.updateIsChatScrolledToBottom,
                        throttle: 200,
                        throttleOptions: {
                          leading: "visible",
                        },
                      },
                      expression:
                        "{\n          callback: updateIsChatScrolledToBottom,\n          throttle: 200,\n          throttleOptions: {\n            leading: 'visible'\n          }\n        }",
                    },
                  ],
                  staticClass: "bottom-div",
                }),
              ]),
            ],
            2
          ),
      _c("transition", { attrs: { name: "fade" } }, [
        !_vm.isChatScrolledToBottom && _vm.chatMessages.length > 0
          ? _c(
              "div",
              {
                staticClass: "scroll-bottom-button",
                on: { click: _vm.scrollToBottom },
              },
              [
                _c("v-icon", {
                  staticClass: "Vlt-black scroll-bottom-icon",
                  attrs: { iconName: "Vlt-icon-down-full" },
                }),
                _c("ChatNotification", {
                  staticClass: "overlaping-notification",
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }