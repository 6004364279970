/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-duplicate-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 0H7.5v7.5H0V24h16.5v-7.5H24V0zM13.5 21H3V10.5h4.5v6h6V21zm7.5-7.5h-4.5v-6h-6V3H21v10.5z"/>'
  }
})
