/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-block-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm0 3c1.95 0 3.75.6 5.25 1.65l-12.6 12.6A9.093 9.093 0 013 12c0-4.95 4.05-9 9-9zm0 18c-1.95 0-3.75-.6-5.25-1.65l12.6-12.6C20.4 8.25 21 10.05 21 12c0 4.95-4.05 9-9 9z"/>'
  }
})
