/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-transfer': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.181.241a.824.824 0 011.165 0l4.237 4.237a.824.824 0 01-.007 1.172l-4.23 4.23a.824.824 0 01-1.165-1.165l2.83-2.83H15.94a2 2 0 00-2 2v2.825a.824.824 0 11-1.648 0V7.885a3.648 3.648 0 013.648-3.648h5.073l-2.83-2.83a.824.824 0 010-1.166zM5.756 2.416c-.165-.164-.495-.494-.989-.494h-.33a4.76 4.76 0 01-.114.036c-.723.219-3.296.997-2.521 4.248.659 3.625 3.13 7.085 6.096 10.215 3.295 3.46 7.743 5.931 10.38 5.931h.494c2.471-.494 3.46-3.46 2.636-4.119-2.307-1.812-3.46-2.471-4.613-2.471H16.3c-1.318.165-1.812 1.483-1.812 1.483s-2.307-.494-4.778-3.13c-2.8-2.802-3.13-4.779-3.13-4.779.988-.659 1.482-1.318 1.482-2.471S6.25 3.075 5.756 2.416zM4.108.275h.66c.988 0 1.812.494 2.306 1.153C8.227 3.076 9.71 5.218 9.71 6.7c.165 1.483-.494 2.472-1.153 3.13.33.825.988 1.813 2.306 3.131 1.01 1.153 2.019 1.802 2.586 2.167.081.052.153.098.215.14.495-.495 1.154-.989 2.142-1.154h.824c1.648 0 3.13.824 5.602 2.801.823.66 1.153 1.813.823 3.13-.494 1.813-1.977 3.625-4.283 3.955h-.66c-3.13 0-8.072-2.8-11.532-6.426C2.955 13.62.813 9.995.154 6.535c-.66-3.13.824-5.437 3.954-6.26z"/>'
  }
})
