/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-user-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M18 6A6 6 0 116 6a6 6 0 0112 0zM2 20.666C2 18.212 5.25 14 12 14s10 4.212 10 6.666V22a2 2 0 01-2 2H4a2 2 0 01-2-2v-1.334z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
