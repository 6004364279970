/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-play': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 3.457C5 1.689 6.944.612 8.443 1.549l13.67 8.543c1.41.882 1.41 2.935 0 3.816l-13.67 8.544c-1.5.937-3.443-.14-3.443-1.908V3.457zm2.647-.636a.75.75 0 00-1.147.636v17.087a.75.75 0 001.147.636l13.67-8.544a.75.75 0 000-1.272L7.647 2.821z"/>'
  }
})
