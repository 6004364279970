export const SHOW_CAPTIONS_TIMEOUT = 5 * 1000;

export const MAX_SENTENCES_CAPTIONS = 2;

export const MIN_SECONDS_BEFORE_CREATING_NEW_BLOCK = 2 * 1000;

export const CAPTION_MESSAGE_TYPE = Object.freeze({
  SYSTEM: 'system',
  CAPTION: 'caption'
});

export const TRANSCRIPT_MESSAGE_STYLE = Object.freeze({
  PAUSE_SYSTEM_MESSAGE: {
    padding: '2px 8px',
    border: '1px solid #B3B3B3',
    'border-radius': '10px',
    color: '#666666'
  },
  RESUME_SYSTEM_MESSAGE: {
    padding: '2px 8px',
    border: '1px solid #53CA6A',
    'border-radius': '10px',
    color: '#167629'
  }
});
