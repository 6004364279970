var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "issue-message",
      attrs: { "data-cy": "video-disabled-banner" },
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("vwc-icon", {
            staticClass: "icon vvd-scheme-alternate",
            attrs: { type: "error-solid", connotation: "alert" },
          }),
          _c("div", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.$t("video_disabled_banner.text"))),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }