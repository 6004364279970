/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-phone-number-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.153 0c-.28 0-.535.08-.752.245-.225.17-.363.41-.42.698l-.506 2.667h-.794c-.332 0-.627.056-.834.215-.2.154-.318.38-.374.645l-.223 1.21h1.903l-.364 1.967h-1.693l-.15.907v.004c-.049.33-.04.64.113.856.157.22.407.303.679.303h.73l-.676 3.628h1.27c.279 0 .536-.076.766-.227.26-.17.406-.443.462-.774l.487-2.627h1.318l-.46 2.427a.985.985 0 00-.003.46c.033.136.088.261.167.372.081.113.184.204.306.27.124.065.258.099.4.099h1.215l.676-3.628h1.933l.15-.897v-.005c.05-.335.04-.652-.12-.868-.158-.217-.408-.3-.68-.3h-.962l.364-1.967h.687c.332 0 .626-.056.833-.215.2-.154.318-.38.374-.645l.211-1.21h-1.782l.487-2.556.001-.007a.856.856 0 00-.214-.752.951.951 0 00-.72-.295h-1.18l-.684 3.61h-1.328L17.451 0h-1.298zm-.064 7.648l.364-1.968h1.328l-.364 1.968H16.09z"/><path pid="1" d="M4.67 1.97a1.8 1.8 0 011.172.086c.419.18.716.527.932.888.18.281.66 1.038 1.442 2.269.801 1.262 1.281 2.51.963 3.58-.207.747-.552 1.451-1.175 1.822-.009.048-.016.165.03.38l.005.02c.078.408.366.984.987 1.74.654.795 1.135 1.345 1.455 1.667.337.34.89.83 1.676 1.478.75.618 1.327.922 1.746 1.018.202.044.313.039.36.03.363-.62 1.055-.982 1.799-1.211l.02-.007c.546-.153 1.128-.094 1.702.083.576.177 1.194.488 1.848.906 1.192.761 1.958 1.26 2.27 1.482.352.218.686.516.862.929.161.38.18.78.084 1.169l-.01.044-.422 1.137c-.358.968-1.091 1.679-2.087 2.158l-.011.005c-.934.433-2.027.45-3.205.17-1.15-.27-2.329-.732-3.536-1.371a27.164 27.164 0 01-3.49-2.213c-1.126-.836-2.094-1.66-2.9-2.474-.807-.814-1.624-1.791-2.453-2.926a27.459 27.459 0 01-2.191-3.52c-.634-1.219-1.09-2.407-1.359-3.565-.275-1.186-.259-2.284.168-3.221l.005-.01c.473-1.002 1.177-1.744 2.14-2.107l1.129-.425.044-.011z"/>'
  }
})
