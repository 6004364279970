<template>
  <div class="attachments-section">
    <div
      class="attachment-wrapper"
      @click.stop="downloadFile"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
      @focusout="fileClicked = false"
    >
      <AttachmentBox
        :attachment="attachment"
        :isLoading="isUploading"
        :isMobileWebChat="isMobileWebMode"
        :class="{
          clickable: downloadable,
          uploading: isUploading,
          'Vlt-btn-on-hover': !uploadCancelled
        }"
      />
      <div v-if="showDownloadButton" class="download-button">
        <vwc-icon-button
          v-if="!isMobileWebMode"
          icon="download-line"
          layout="outlined"
          @click.stop="downloadFile"
        >
          <vwc-circular-progress
            v-if="isDownloading"
            indeterminate
            density="-6"
          />
        </vwc-icon-button>

        <vwc-icon-button
          v-else-if="fileClicked"
          icon="open-line"
          layout="filled"
          :disabled="isDownloading"
          dense
          @click.stop="openFile"
        >
          <vwc-circular-progress
            v-if="isDownloading"
            indeterminate
            density="-6.5"
          />
        </vwc-icon-button>
      </div>
    </div>

    <!-- Upload info -->
    <div v-if="isUploading" class="upload-info">
      <div>{{ $t('attachment_row.uploading_text', { percentsUploaded }) }}</div>
      <vwc-button
        class="cancel-btn"
        :label="$t('attachment_row.cancel_button')"
        layout="filled"
        dense
        @click.stop="cancelUpload"
      />
    </div>

    <!-- Errors -->
    <div
      v-if="(sendFailed && !isUploading) || downloadFailed || uploadCancelled"
      class="action-failed"
    >
      <v-icon iconName="Vlt-icon-unhappy" />
      <div class="action-failed-text">{{ errorMessage }}</div>
      <div class="action-failed-buttons">
        <vwc-button
          :label="
            uploadCancelled
              ? $t('attachment_row.upload_cancelled_button')
              : $t('attachment_row.try_again_button')
          "
          layout="filled"
          dense
          @click.stop="tryAgain"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import numeral from 'numeraljs';
import AttachmentBox from '@/components/rows/message-rows/AttachmentBox';
import { getFileIcon } from '@/helpers/attachments-utils';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';

export default {
  name: 'AttachmentRow',

  components: {
    AttachmentBox
  },

  props: {
    message: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isDownloading: false,
      downloadFailed: false,
      hover: false,
      fileClicked: false,
      fileUrl: ''
    };
  },

  computed: {
    ...mapGetters('messaging/attachments', [
      'uploadsProgressMap',
      'cancelledRequests'
    ]),
    ...mapGetters(['isMobileWebMode']),

    uploadCancelled() {
      return !!this.cancelledRequests[this.message.messageId];
    },
    attachment() {
      return {
        name: this.name,
        size: numeral(this.size).format('0.0 b'),
        icon: getFileIcon({
          name: this.name,
          type: this.document.metadata.mimeType
        })
      };
    },
    isUploading() {
      return this.percentsUploaded !== undefined && this.percentsUploaded < 100;
    },
    percentsUploaded() {
      return this.uploadsProgressMap[this.message.messageId];
    },
    errorMessage() {
      if (this.uploadCancelled) {
        return this.$t('attachment_row.upload_cancel_error_message');
      } else if (this.sendFailed) {
        return this.$t('attachment_row.send_failed_error_message');
      } else if (this.downloadFailed) {
        return this.$t('attachment_row.download_failed_error_message');
      }
      return '';
    },
    document() {
      if (this.message.data?.[0]) {
        return this.message.data[0];
      }
      return null;
    },
    sendFailed() {
      return this.message.sentStatus === 'FAILED';
    },
    size() {
      return this.document?.metadata.size || 0;
    },
    name() {
      return this.document?.metadata.fileName || '';
    },
    downloadable() {
      return !this.isUploading && !this.sendFailed && !this.uploadCancelled;
    },
    showDownloadButton() {
      return this.downloadable && this.hover && !this.downloadFailed;
    }
  },

  methods: {
    ...mapActions('messaging/attachments', [
      'downloadFileToDisk',
      'cancelUploadRequest',
      'getFileUrl',
      'downloadFileFromUrl'
    ]),
    cancelUpload() {
      this.cancelUploadRequest({ messageId: this.message.messageId });
    },
    async downloadFile() {
      this.fileClicked = true;
      if (this.downloadable) {
        this.isDownloading = true;
        try {
          if (this.isMobileWebMode) {
            this.fileUrl = await this.getFileUrl({
              messageId: this.message.messageId
            });
          } else {
            await this.downloadFileToDisk({
              messageId: this.message.messageId
            });
          }
          this.isDownloading = false;
          this.downloadFailed = false;
        } catch (error) {
          this.isDownloading = false;
          this.downloadFailed = true;
        }
      }
    },
    tryAgain() {
      if (this.uploadCancelled || this.sendFailed) {
        // Upload Failed
        this.$emit('resend');
      } else {
        // Download Failed
        this.downloadFailed = false;
        this.downloadFile();
      }
    },
    openFile() {
      if (!this.fileUrl) {
        logger.error(
          'Attachment Row - failed to open attachment',
          LOG_CATEGORIES.MOBILE
        );
        return;
      }
      this.downloadFileFromUrl(this.fileUrl);
    }
  }
};
</script>

<style scoped>
.attachment-wrapper {
  position: relative;
  border-radius: 4px;
}
/* Uploading */
.uploading .filename,
.uploading .file-size {
  color: #ffffff;
}
.upload-info {
  display: flex;
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: #2c2d30;
  margin-top: -8px;
}
.mobile .attachments-section .upload-info {
  color: white;
}
.upload-info > div {
  flex: 1;
  margin-top: 8px;
}
.upload-info .Vlt-btn {
  font-size: 12px;
  padding: 0 14px 0 14px;
}
/* Downloading */
.download {
  height: 30px;
  width: 30px;
  text-align: center;
}
.download .circle {
  height: 18px;
  width: 18px;
  position: relative;
  top: calc(50% - 9px);
  margin: auto;
}
.spin >>> .svg-icon {
  width: 20px;
  height: 20px;
  animation: spin 1s infinite linear;
}
/* file-info */
.file-info {
  flex: 1;
  overflow: hidden;
}
.filename {
  font-size: 16px;
  font-weight: bold;
  line-height: 1.25;
  color: #2c2d30;
  text-align: left;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
}
.file-size {
  font-size: 13px;
  line-height: 1.38;
  color: #637381;
  margin-top: 2px;
}
.download-button {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.thumbnail {
  max-height: 40px;
  max-width: 40px;
  border-radius: 3px;
  overflow: hidden;
}
.thumbnail img {
  height: 100%;
  width: 100%;
  border-radius: 3px;
}
.thumbnail .circle {
  height: 40px;
  width: 40px;
  margin: auto;
}
.thumbnail >>> .svg-icon {
  cursor: default;
}
.clickable .thumbnail >>> .svg-icon {
  cursor: pointer;
}
/* Actions */
.attachment-box >>> .Vlt-btn-group--hover {
  bottom: 0px;
  right: 8px;
  top: initial !important;
}
.attachment-box.clickable {
  cursor: pointer;
}
.action-failed {
  display: flex;
  align-items: center;
  margin-top: -8px;
}
.action-failed svg {
  fill: #f25a6b;
  margin-top: 6px;
  margin-right: 8px;
  cursor: inherit;
  width: 18px;
  height: 18px;
}
.action-failed-text {
  flex: 1;
  margin-top: 8px;
  font-weight: bold;
  line-height: 1.25;
  color: #2c2d30;
}
.mobile .attachments-section .action-failed-text {
  color: white;
}
.action-failed-buttons,
.cancel-btn {
  margin-top: 8px;
}
/* Icon colors */
.icon {
  width: 100%;
  height: 100%;
}
.Vlt-icon-file-doc {
  fill: #3ae;
}
.Vlt-icon-file-pdf {
  fill: #f25a6b;
}
.Vlt-icon-file-ppt {
  fill: #ff8f02;
}
.Vlt-icon-file-xls {
  fill: #35be8b;
}
.Vlt-icon-file-video {
  fill: #7683d8;
}
.Vlt-icon-file {
  fill: #919eab;
}
.Vlt-icon-file-zip {
  fill: #ffc100;
}
</style>
