/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-star': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.4.6c0-.1-.2-.2-.4-.2s-.4.1-.4.3L5.3 5.1l-4.9.7c-.2 0-.3.2-.4.4 0 .1 0 .3.2.5l3.6 3.5-.9 4.8c0 .2 0 .4.2.5.2.1.4.1.5 0L8 13.3l4.4 2.3c.2.1.4.1.5 0 .2-.1.2-.3.2-.5l-.8-4.9 3.6-3.5c.1-.2.1-.4.1-.5-.1-.2-.2-.3-.4-.3l-4.9-.7L8.4.6zM6.1 5.8L8 2l1.9 3.8c.1.1.2.2.4.3l4.2.6-3 2.9c-.1.1-.2.3-.1.4l.7 4.2-3.7-2c-.1-.1-.3-.1-.5 0l-3.7 2 .7-4.2c0-.2 0-.3-.1-.4l-3-2.9L6 6.1c-.1-.1.1-.2.1-.3z"/>'
  }
})
