/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calls-disabled-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 26 26',
    data: '<path pid="0" d="M22.94.94l-22 22 2.12 2.12 5.82-5.818c.628.55 1.322 1.104 2.081 1.664a29.797 29.797 0 003.825 2.402c1.324.695 2.615 1.195 3.874 1.49 1.29.302 2.484.285 3.504-.184l.011-.006c1.09-.52 1.894-1.29 2.287-2.343l.462-1.236.012-.049a1.974 1.974 0 00-.093-1.275c-.192-.45-.559-.772-.945-1.009-.34-.24-1.18-.783-2.487-1.61-1.376-.87-2.726-1.393-3.88-1.072l-.022.006c-.815.25-1.572.642-1.97 1.317-.051.01-.176.017-.405-.033-.462-.105-1.097-.436-1.918-1.107-.258-.21-.493-.406-.705-.586L25.06 3.06 22.939.939zM8.7 10.89c.005.025.01.05.017.076l-3.813 3.813a29.66 29.66 0 01-2.213-3.565C1.997 9.891 1.497 8.6 1.202 7.341.9 6.05.918 4.856 1.386 3.836l.006-.011c.52-1.09 1.291-1.894 2.343-2.287l1.237-.462.048-.012c.424-.106.86-.084 1.275.093.456.196.782.57 1.02.963l1.58 2.465c.46.718.8 1.397.99 2.031.19.63.245 1.267.066 1.864-.227.815-.606 1.58-1.288 1.98-.01.053-.017.178.034.41l.004.02z"/>'
  }
})
