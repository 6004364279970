var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "app",
      staticClass: "app",
      class: [
        _vm.osClass,
        {
          branded: _vm.isBranded,
          "vvd-scheme-alternate": _vm.whiteBgMode,
        },
      ],
      attrs: { id: "app" },
    },
    [
      _c("MeetingTitlebar"),
      _vm.showSelectLanguageModal ? _c("SelectLanguageModal") : _vm._e(),
      _vm.isLoadingPreInitData && !_vm.isMobileDevice
        ? _c("LoadingScreen", { staticClass: "loading-screen" })
        : _vm._e(),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }