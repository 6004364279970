/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-flash-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10 .7c.1-.2 0-.5-.2-.6s-.5-.1-.7 0l-9 9c-.1.2-.1.4-.1.6.1.2.3.3.5.3h6.4L6 15.4c0 .2.1.4.3.5.2.1.4.1.6 0l9-9c.1-.2.1-.4.1-.6-.1-.2-.3-.3-.5-.3H8.2L10 .7zM7.9 9.2c-.1-.1-.3-.2-.4-.2H1.7l6.6-6.6L7 6.3c0 .2 0 .4.1.5.1.1.2.2.4.2h6.8l-7 7L8 9.6c0-.2 0-.3-.1-.4z"/>'
  }
})
