var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "avatar-wrapper" },
    [
      _vm.isLoading
        ? _c("v-spinner", { attrs: { white: "" } })
        : _c("ParticipantAvatar", {
            attrs: {
              displayName: _vm.displayName,
              participantType: _vm.participantType,
              participantProfilePicture: _vm.participantProfilePicture,
              avatarSize: _vm.avatarSize,
              isBeRightBackOn: _vm.isBeRightBackOn,
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }