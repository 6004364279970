/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'form-element-checkbox-check': {
    width: 16,
    height: 16,
    viewBox: '0 0 18 18',
    data: '<path pid="0" d="M7 14L2.6 9.6 4 8.2l3 3 7.5-7.5 1.4 1.4L7 14z"/>'
  }
})
