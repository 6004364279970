/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-volume-off-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.604 1.589A.75.75 0 0118 2.25v.879l-1.5 1.5V3.65L8.666 8.874A.75.75 0 018.25 9H1.5v7.5h3.129l-1.5 1.5H.75a.75.75 0 01-.75-.75v-9a.75.75 0 01.75-.75h7.273l8.811-5.874a.75.75 0 01.77-.037zM18 9.31l5.78-5.78a.75.75 0 00-1.06-1.061L2.47 22.72a.75.75 0 101.06 1.06l5.266-5.265 8.038 5.359A.75.75 0 0018 23.25V9.31zm-1.5 1.5l-6.623 6.622L16.5 21.85V10.81z"/>'
  }
})
