/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-tag-search': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.5.2c.3-.3.7-.3 1 0l8.2 7.5c.3.3.3.8.1 1.1-.3.3-.8.3-1.1.1l-7.7-7-7.5 6.7v13.2c0 .4.3.8.8.8h7.5c.4 0 .8.3.8.8s-.4.6-.8.6H4.2C3 24 2 23 2 21.8V8.2c0-.2.1-.4.2-.5L10.5.2zM21 18.6c.5-.7.8-1.6.8-2.6 0-2.6-2.1-4.8-4.8-4.8s-4.8 2.1-4.8 4.8 2.1 4.8 4.8 4.8c.9 0 1.8-.3 2.6-.8l2.7 2.7c.4.4 1 .4 1.4 0 .4-.4.4-1 0-1.4L21 18.6zm-1.8-.2c-.6.5-1.4.9-2.2.9-1.8 0-3.2-1.5-3.2-3.2s1.5-3.2 3.2-3.2 3.2 1.5 3.2 3.2c0 .9-.3 1.6-.9 2.2 0-.1 0 0-.1.1.1-.1 0-.1 0 0z"/>'
  }
})
