import i18n from '@/i18n';
import after from 'lodash.after';

export function getDefaultDevice(devices) {
  let deviceId = '';
  if (devices.length > 0) {
    deviceId = devices.find((device) => device.deviceId === 'default')
      ? 'default'
      : devices[0].deviceId;
  }
  return deviceId;
}

export function getAvailableDevices(devices, selectedDevice) {
  const defaultLabel = devices.find((device) => device.deviceId === 'default')
    ?.label;
  return devices.map((device) => {
    let text;
    if (device.deviceId === 'default') {
      text = i18n.t('meeting_helpers.devices_system_default_text');
    } else {
      const prefix = defaultLabel?.includes(device.label) ? '(Default) ' : '';
      text = prefix + device.label;
    }
    return {
      id: device.deviceId,
      label: text,
      active: device.id === selectedDevice,
      disabled: devices.length === 1
    };
  });
}

export function didDefaultDeviceChanged(newDevices, oldDevices) {
  const newDefaultDeviceLabel = newDevices.find((device) =>
    isDefaultDeviceId(device.deviceId)
  )?.label;
  const oldDefaultDeviceLabel = oldDevices.find((device) =>
    isDefaultDeviceId(device.deviceId)
  )?.label;

  return newDefaultDeviceLabel !== oldDefaultDeviceLabel;
}

// will be invoked only on the second run on - to make sure we don't run the first time when the devices get initialized
export const getChangedSelectedDevicesTypes = after(1, (devicesData) => {
  const changedDevicesNames = [];

  devicesData.forEach((deviceData) => {
    const { newDevices, oldDevices, selectedDeviceId, deviceName } = deviceData;
    if (
      (isDefaultDeviceId(selectedDeviceId) &&
        didDefaultDeviceChanged(newDevices, oldDevices)) ||
      isSelectedDeviceRemoved(selectedDeviceId, newDevices)
    ) {
      changedDevicesNames.push(deviceName);
    }
  });

  return changedDevicesNames;
});

export function isDefaultDeviceId(deviceId) {
  return deviceId === 'default' || deviceId === 'communications';
}

export function isSelectedDeviceRemoved(selectedDeviceId, newDevices) {
  return (
    !!selectedDeviceId &&
    !newDevices.some((device) => device.deviceId === selectedDeviceId)
  );
}
