/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-reorder': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6 2a1 1 0 10-2 0 1 1 0 002 0zM5 0a2 2 0 110 4 2 2 0 010-4zm0 7a1 1 0 110 2 1 1 0 010-2zm2 1a2 2 0 10-4 0 2 2 0 004 0zm-2 5a1 1 0 110 2 1 1 0 010-2zm2 1a2 2 0 10-4 0 2 2 0 004 0zm3.5-7a1 1 0 110 2 1 1 0 010-2zm2 1a2 2 0 10-4 0 2 2 0 004 0zm-1 6a1 1 0 10-2 0 1 1 0 002 0zm-1-2a2 2 0 110 4 2 2 0 010-4zm0-11a1 1 0 110 2 1 1 0 010-2zm2 1a2 2 0 10-4 0 2 2 0 004 0z"/>'
  }
})
