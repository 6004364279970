<template>
  <div class="empty-screen-main-content" data-cy="empty-screen-container">
    <div v-if="roomPinCode" class="box-wrapper Vlt-gradient--skittles2">
      <div class="box box-container" :class="{ guest: isGuest }">
        <vwc-icon v-if="isBranded" class="logo" type="waving-solid" />
        <vwc-icon v-else class="logo" type="vonage-solid" />
        <div class="box-title">
          <div data-cy="empty-state-title" class="box-title--text unselectable">
            {{ $t('empty_video_screen.title') }}
          </div>
        </div>
        <div v-if="!isGuest" class="sub-title" data-cy="sub-title">
          {{ $t('empty_video_screen.subtitle') }}
        </div>
        <div v-else class="sub-title" data-cy="sub-title">
          {{ $t('empty_video_screen.subtitle_guest') }}
        </div>
        <div
          class="buttons-container"
          :class="{ 'buttons-container-only-one-button': isGuest }"
        >
          <vwc-button
            :label="$t('empty_video_screen.copy_meeting_button')"
            layout="filled"
            icon="copy-2-solid"
            type="submit"
            :class="{
              'copy-button': !isGuest
            }"
            data-cy="copy-button"
            dense
            @click="copyMeetingDetails"
          />
          <vwc-button
            v-if="!isGuest"
            :label="$t('empty_video_screen.invite_button')"
            layout="outlined"
            icon="add-user-solid"
            type="submit"
            class="invite-button"
            data-cy="invite-button"
            :disabled="areInvitesDisabled"
            dense
            @click="openInviteModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import analytics from '@/services/analytics-service';
import { clipboardService } from '@/services/clipboard-service';
import { ANALYTICS } from '@/consts/global-consts';
import i18n from '@/i18n';

export default {
  name: 'EmptyVideoScreen',

  data() {
    return {
      showCopyButton: false,
      selectedCountry: {}
    };
  },

  computed: {
    ...mapState(['isBranded', 'isLocked']),
    ...mapGetters([
      'roomPinCode',
      'detailsStringToCopy',
      'activeParticipants',
      'isGuest',
      'isSessionOwner',
      'isWaitingRoomEnabled'
    ]),

    areInvitesDisabled() {
      return (
        this.isLocked || (this.isWaitingRoomEnabled && !this.isSessionOwner)
      );
    }
  },

  methods: {
    ...mapActions(['addFlashMessage', 'setIsInviteParticipantsDialogVisible']),

    onInfoCopy({ infoType }) {
      analytics.trackEvent(ANALYTICS.INFO_COPIED, {
        Source: 'Welcome Screen',
        'Num of Participants': this.activeParticipants.length,
        'Info copied': infoType
      });
      this.addFlashMessage({
        time: 4000,
        type: 'good',
        text: i18n.t(
          'empty_video_screen.meeting_info_has_been_copied_flash_message'
        )
      });
    },

    copyMeetingDetails() {
      this.copyToClipboard(this.detailsStringToCopy);
      this.onInfoCopy({ infoType: 'Details' });
    },

    copyToClipboard(text) {
      clipboardService.copy(text);
    },

    openInviteModal() {
      this.setIsInviteParticipantsDialogVisible(true);
    }
  }
};
</script>

<style scoped>
.empty-screen-main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.buttons-container {
  margin-top: 28px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.buttons-container-only-one-button {
  justify-content: flex-end;
}

.invite-button {
  margin-left: 15px;
  flex: 1;
}

.copy-button {
  flex: 1;
}

.grid-view .main-content {
  /* 110px - 45px from the grid view */
  top: 65px;
}

.box-wrapper {
  padding-left: 6px;
  padding-top: 6px;
  border-radius: 45px;
  background: #fff;
}

.box {
  padding: 40px;
  height: 240px;
  border-radius: 38px;
}

.box.box-container:not(.guest) {
  width: 470px;
}

.box .guest {
  width: 420px;
}
.box-title {
  display: flex;
  margin-top: 18px;
}

.box-title--text {
  font-size: 20px;
  font-weight: bold;
}

.sub-title {
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  margin-top: 8px;
}

.logo {
  width: 35px;
  height: 35px;
  color: white;
}

.box-container {
  background-color: #1a1a1a;
}

.Vlt-gradient--skittles2 {
  background: var(--btn-cta-color-black-bg) !important;
}

@media only screen and (max-width: 850px) {
  .box.box-container {
    width: unset !important;
  }
}

@media only screen and (max-width: 600px) {
  .box-wrapper {
    display: none;
  }
}
</style>
