var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "virtual-background-selector",
      class: { disabled: _vm.disabled },
    },
    [
      _c("div", { staticClass: "grid" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.noneTooltip,
                expression: "noneTooltip",
              },
            ],
            staticClass: "card none",
            class: {
              selected:
                _vm.selectedBackground.type ===
                _vm.VIRTUAL_BACKGROUND_TYPE.NONE,
            },
            on: {
              click: function ($event) {
                return _vm.selectBackground({
                  type: _vm.VIRTUAL_BACKGROUND_TYPE.NONE,
                  background: null,
                })
              },
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.selectedBackground.type ===
                      _vm.VIRTUAL_BACKGROUND_TYPE.NONE,
                    expression:
                      "selectedBackground.type === VIRTUAL_BACKGROUND_TYPE.NONE",
                  },
                ],
                staticClass: "icon-wrapper",
              },
              [
                _c("vwc-icon", {
                  staticClass: "selected-icon",
                  attrs: {
                    connotation: "cta",
                    type: "check-solid",
                    size: "small",
                  },
                }),
              ],
              1
            ),
            _vm.isEnglishLocale
              ? _c("div", { staticClass: "text" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("virtual_background_modal.none")) + " "
                  ),
                ])
              : _vm._e(),
            _vm.selectedBackground.type === _vm.VIRTUAL_BACKGROUND_TYPE.NONE
              ? _c("div", { staticClass: "selected-mask" })
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.blurTooltip,
                expression: "blurTooltip",
              },
            ],
            staticClass: "card",
            on: {
              click: function ($event) {
                return _vm.selectBackground({
                  type: _vm.VIRTUAL_BACKGROUND_TYPE.BLUR,
                  background: null,
                })
              },
            },
          },
          [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value:
                      _vm.selectedBackground.type ===
                      _vm.VIRTUAL_BACKGROUND_TYPE.BLUR,
                    expression:
                      "selectedBackground.type === VIRTUAL_BACKGROUND_TYPE.BLUR",
                  },
                ],
                staticClass: "icon-wrapper",
              },
              [
                _c("vwc-icon", {
                  staticClass: "selected-icon",
                  attrs: {
                    connotation: "cta",
                    type: "check-solid",
                    size: "small",
                  },
                }),
              ],
              1
            ),
            _vm.isEnglishLocale
              ? _c("div", { staticClass: "text" }, [
                  _vm._v(
                    " " + _vm._s(_vm.$t("virtual_background_modal.blur")) + " "
                  ),
                ])
              : _vm._e(),
            _c("img", {
              staticClass: "image",
              class: {
                selected:
                  _vm.selectedBackground.type ===
                  _vm.VIRTUAL_BACKGROUND_TYPE.BLUR,
              },
              attrs: { src: "/virtual-backgrounds/thumbnails/Blur.jpg" },
            }),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "grid" },
        _vm._l(_vm.virtualBackgrounds, function (background) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.disabled
                    ? null
                    : {
                        content: background.tooltip,
                        placement: "top",
                        classes: "virtual-background-card-tooltip",
                      },
                  expression:
                    "\n        disabled\n          ? null\n          : {\n              content: background.tooltip,\n              placement: 'top',\n              classes: 'virtual-background-card-tooltip'\n            }\n      ",
                },
              ],
              key: background.id,
              staticClass: "card",
              on: {
                click: function ($event) {
                  return _vm.selectBackground({
                    type: _vm.VIRTUAL_BACKGROUND_TYPE.IMAGE,
                    background: background.id,
                  })
                },
              },
            },
            [
              _vm.selectedBackground.background === background.id
                ? _c(
                    "div",
                    { staticClass: "icon-wrapper" },
                    [
                      _c("vwc-icon", {
                        staticClass: "selected-icon",
                        attrs: {
                          connotation: "cta",
                          type: "check-solid",
                          size: "small",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c("img", {
                staticClass: "image",
                class: {
                  selected: _vm.selectedBackground.background === background.id,
                },
                attrs: {
                  src:
                    "/virtual-backgrounds/thumbnails/" + background.id + ".jpg",
                },
              }),
            ]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }