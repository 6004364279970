/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-3': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4 3.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM4.5 6a.5.5 0 000 1h7a.5.5 0 000-1h-7zM4 9.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM4.5 12a.5.5 0 000 1h3a.5.5 0 000-1h-3z"/><path pid="1" d="M1 .5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v15a.5.5 0 01-.5.5h-13a.5.5 0 01-.5-.5V.5zM2 1v14h12V1H2z"/>'
  }
})
