var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "dialog",
      attrs: {
        "data-cy": "turn-on-audio-modal",
        heading: _vm.$t("turn_on_audio_modal.title"),
        "icon-name": "audio-max-line",
        close: _vm.close,
        "min-width": 430,
        "primary-action": _vm.$t("turn_on_audio_modal.turn_on_button"),
        "secondary-action": _vm.$t("turn_on_audio_modal.close_button"),
        open: _vm.isTurnOnAudioDialogVisible,
        isSmallModal: "",
      },
      on: {
        applied: _vm.turnOnAudioMode,
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content", attrs: { slot: "content" }, slot: "content" },
        [
          _c("div", { staticClass: "text" }, [
            _vm._v(
              " " + _vm._s(_vm.$t("turn_on_audio_modal.content_text")) + " "
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }