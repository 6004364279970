/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vonage-logo': {
    width: 16,
    height: 16,
    viewBox: '0 0 109 24',
    data: '<path pid="0" d="M93.046 13.312v-1.756h5.553v6.18h-1.935l.007-1.39c-.517.826-1.983 1.61-3.436 1.61-3.447 0-5.664-2.236-5.664-5.956s2.217-6 5.664-6c2.595 0 4.908 1.422 5.276 3.946h-2.098c-.266-1.441-1.532-2.067-3.055-2.067-2.432 0-3.766 1.27-3.766 4.121 0 2.64 1.441 4.104 3.643 4.104 1.755 0 3.412-1.01 3.428-2.792h-3.617zM109 8.184h-6.187v2.696h5.704v1.92h-5.704v3.016H109v1.92h-8.21V6.264H109v1.92zM5.416 0l5.186 11.56-2.57 6.073a.167.167 0 01-.306.002L0 0h5.416zM21.91 0h5.511s-8.16 18.796-9.135 20.606C17.428 22.197 15.879 24 13.73 24H8.77a.03.03 0 01-.004-.06c1.585-.25 2.441-1.032 3.733-3.08C13.611 19.099 21.911 0 21.911 0zm21.691 15.317l4.118-9.052h2.313s-4.244 9.605-4.668 10.323c-.414.702-.915 1.28-1.792 1.28s-1.379-.578-1.793-1.28c-.424-.718-4.667-10.323-4.667-10.323h2.313l4.117 9.052a.032.032 0 00.059 0zm12.557.787c2.23 0 3.703-1.464 3.703-4.104s-1.473-4.104-3.703-4.104c-2.237 0-3.703 1.464-3.703 4.104s1.466 4.104 3.703 4.104zM56.16 6c3.496 0 5.757 2.28 5.757 6s-2.261 6-5.757 6c-3.503 0-5.757-2.28-5.757-6s2.254-6 5.757-6zm17.593.264v11.472h-2.371l-5.528-8.387v8.387h-2.022V6.264h2.379l5.528 8.443V6.264h2.014zm5.42 6.768h4.044l-1.992-4.685a.032.032 0 00-.06 0l-1.991 4.685zm2.022-6.9c.833 0 1.342.636 1.678 1.27.344.651 4.457 10.334 4.457 10.334h-2.113l-1.181-2.778h-5.68l-1.182 2.778H75.06s4.113-9.683 4.457-10.333c.335-.635.844-1.27 1.677-1.27z" _fill="#131414"/>'
  }
})
