import publicApiGw from '@/apis/vbc-public-gw';

const vue_app_rs_env_route = process.env.VUE_APP_RS_ENV_ROUTE;

export function getGuestRoomDetails(roomToken, includeSessionData = false) {
  let queryParams = ['room_theme', 'ui_settings'];
  if (includeSessionData) {
    queryParams = [
      ...queryParams,
      'is_active_session_locked',
      'is_active_session_full'
    ];
  }
  const includeQuery = queryParams.join(',');
  return publicApiGw.get(
    `/${vue_app_rs_env_route}/guests/${roomToken}/rooms?includes=${includeQuery}`
  );
}

export function joinAsGuest(roomToken, displayName, participantToken) {
  return publicApiGw.post(`/${vue_app_rs_env_route}/guests/${roomToken}`, {
    displayName,
    participantToken
  });
}

export function getGuestSessionToken(guestId, roomToken) {
  return publicApiGw.post(`/${vue_app_rs_env_route}/guests/sessionToken`, {
    roomToken,
    guestId
  });
}

export function getThemeByUrl(themeUrl) {
  return publicApiGw.get(`/${vue_app_rs_env_route}/themes/${themeUrl}`);
}

export function joinWaitingRoom(roomToken, displayName) {
  return publicApiGw.post(
    `/${vue_app_rs_env_route}/guests/${roomToken}/rooms/waiting-room`,
    {
      displayName
    }
  );
}
