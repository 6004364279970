/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-van-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.05 7.8l3.9 1.35c.6.15 1.05.75 1.05 1.35v9c0 .9-.6 1.5-1.5 1.5h-6c-.075 0-.15-.038-.225-.075a.533.533 0 00-.225-.075c-.9 1.95-2.7 3.15-4.8 3.15-2.1 0-3.9-1.2-4.8-3H1.5C.6 21 0 20.4 0 19.5v-18C0 .6.6 0 1.5 0H15c.6 0 1.2.3 1.35.9l2.7 6.9zM16.5 18H21v-6.45l-3.6-1.05c-.45-.15-.75-.45-.9-.9L13.95 3H3v15h3.15c.3-2.55 2.4-4.5 5.1-4.5 2.7 0 4.95 1.95 5.25 4.5zm-9-13.5h3v3h3v3H9c-.9 0-1.5-.6-1.5-1.5V4.5z"/>'
  }
})
