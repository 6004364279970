/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-app-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 0h21A1.5 1.5 0 0124 1.5v21a1.5 1.5 0 01-1.5 1.5h-21A1.5 1.5 0 010 22.5v-21A1.5 1.5 0 011.5 0zM3 10h18V3H3v7zm6-5H5v3h4V5z"/>'
  }
})
