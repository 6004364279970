/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-video-active': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.25 6.75a3 3 0 100-6 3 3 0 000 6zM2.25 3H9v1.5H2.25a.75.75 0 00-.75.75v13.5c0 .414.336.75.75.75h12a.75.75 0 00.75-.75v-4.483-4.5a.66.66 0 010-.034v-.508l1.5-.6v.017l6.471-2.588A.75.75 0 0124 6.75v10.5a.75.75 0 01-1.029.696L16.5 15.358v3.392A2.25 2.25 0 0114.25 21h-12A2.25 2.25 0 010 18.75V5.25A2.25 2.25 0 012.25 3zM16.5 13.742l6 2.4V7.858l-6 2.4v3.484z"/>'
  }
})
