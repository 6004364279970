/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mockup-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 0h-21C.6 0 0 .6 0 1.5v3C0 5.4.6 6 1.5 6h21c.9 0 1.5-.6 1.5-1.5v-3c0-.9-.6-1.5-1.5-1.5zm-18 9h-3C.6 9 0 9.6 0 10.5v12c0 .9.6 1.5 1.5 1.5h3c.9 0 1.5-.6 1.5-1.5v-12C6 9.6 5.4 9 4.5 9zm6 0h12c.9 0 1.5.6 1.5 1.5v12c0 .9-.6 1.5-1.5 1.5h-12c-.9 0-1.5-.6-1.5-1.5v-12c0-.9.6-1.5 1.5-1.5z"/>'
  }
})
