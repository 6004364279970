/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-blogger-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.346 9.286c-.505-.217-2.67.024-3.271-.522-.425-.392-.452-1.104-.618-2.052-.277-1.59-.393-1.949-.681-2.574C17.726 1.908 15.208 0 12.922 0h-5.3C3.452 0 .044 3.416.044 7.586v8.844c0 4.162 3.408 7.57 7.578 7.57h8.708c4.17 0 7.554-3.408 7.578-7.57l.048-6.126s0-.757-.61-1.018zM7.5 6h4a1.5 1.5 0 110 3h-4a1.5 1.5 0 110-3zm9 12h-9a1.5 1.5 0 010-3h9a1.5 1.5 0 110 3z" _fill="#F60"/>'
  }
})
