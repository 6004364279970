import { render, staticRenderFns } from "./NoConnectionBanner.vue?vue&type=template&id=533ed483&scoped=true&"
import script from "./NoConnectionBanner.vue?vue&type=script&lang=js&"
export * from "./NoConnectionBanner.vue?vue&type=script&lang=js&"
import style0 from "./NoConnectionBanner.vue?vue&type=style&index=0&id=533ed483&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "533ed483",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1498271919/src/v-meetings/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('533ed483')) {
      api.createRecord('533ed483', component.options)
    } else {
      api.reload('533ed483', component.options)
    }
    module.hot.accept("./NoConnectionBanner.vue?vue&type=template&id=533ed483&scoped=true&", function () {
      api.rerender('533ed483', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/banners/NoConnectionBanner.vue"
export default component.exports