/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-grid-two': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.5 0h-13C.7 0 0 .7 0 1.5v13c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-13c0-.8-.7-1.5-1.5-1.5zm-7 15h-6c-.3 0-.5-.2-.5-.5v-13c0-.3.2-.5.5-.5h6v14zm7.5-.5c0 .3-.2.5-.5.5h-6V1h6c.3 0 .5.2.5.5v13z"/>'
  }
})
