var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "live-transcription-container" }, [
    _c("div", { staticClass: "action-buttons" }, [
      _c(
        "div",
        { staticClass: "left-side-buttons" },
        [
          _c("vwc-button", {
            attrs: {
              "data-cy": "pause-button",
              dense: "",
              icon: _vm.toggleTranscriptionButton.icon,
              label: _vm.toggleTranscriptionButton.label,
              connotation: _vm.toggleTranscriptionButton.connotation,
              layout: _vm.toggleTranscriptionButton.layout,
            },
            on: { click: _vm.toggleIsLiveTranscriptionPaused },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "right-side-buttons" },
        [
          _c("vwc-icon-button", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.bottom-end",
                value: _vm.$t(
                  "live_transcription_sidebar.download_button_tooltip"
                ),
                expression:
                  "\n          $t('live_transcription_sidebar.download_button_tooltip')\n        ",
                modifiers: { "bottom-end": true },
              },
            ],
            attrs: {
              icon: "download-solid",
              disabled: _vm.isLiveTranscriptionEmpty,
            },
            on: { click: _vm.downloadTranscription },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "separator" }),
    _c(
      "div",
      {
        ref: "transcriptionContent",
        staticClass: "live-transcription-content",
        class: { "align-center": _vm.isLiveTranscriptionEmpty },
      },
      [
        _vm.isLiveTranscriptionEmpty
          ? _c(
              "vwc-empty-state",
              {
                attrs: {
                  heading: _vm.$t(
                    "live_transcription_sidebar.empty_state_title"
                  ),
                  body: _vm.$t(
                    "live_transcription_sidebar.empty_state_message"
                  ),
                },
              },
              [
                _c("img", {
                  attrs: {
                    slot: "graphic",
                    alt: "empty transcript",
                    "data-cy": "empty-live-transcription-img",
                    src: "/illustrations/empty-state/empty-transcript.svg",
                  },
                  slot: "graphic",
                }),
              ]
            )
          : _c(
              "ScrollableContent",
              [
                _vm._l(_vm.transcriptionBlocks, function (
                  transcriptBlock,
                  index
                ) {
                  return _c("TranscriptionBlockRow", {
                    key: "transcription-block-" + index,
                    staticClass: "transcription-block",
                    attrs: { transcript: transcriptBlock },
                  })
                }),
                _vm._l(_vm.dynamicTranscriptionBlocks, function (
                  transcriptBlock,
                  index
                ) {
                  return _c("TranscriptionBlockRow", {
                    key: "dynamic-block-" + index,
                    staticClass: "transcription-block",
                    attrs: { transcript: transcriptBlock },
                  })
                }),
              ],
              2
            ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }