<template>
  <span v-if="counter" class="counter-badge" :style="{ width }">
    {{ counter }}
  </span>
</template>

<script>
export default {
  name: 'UnreadMessagesBadge',

  props: {
    count: {
      type: Number,
      default: 0
    }
  },

  computed: {
    counter() {
      return Math.min(this.count, 99);
    },

    width() {
      if (this.counter < 10) {
        return '16px';
      } else {
        return '20px';
      }
    }
  }
};
</script>

<style scoped>
.counter-badge {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  height: 16px;
  border-radius: 50%;
  background: #e84545;
  color: white;
  text-align: center;
  font-weight: 600;
  font-size: 11px;
}
</style>
