/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-increase-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.5 9h5.4l-5.4 5.4-6-6-7.05 7.05 2.1 2.1L7.5 12.6l6 6 7.5-7.5v5.4h3V6H13.5v3z"/>'
  }
})
