export default {
  UPDATE_SETTINGS: (state, settings) => {
    Object.keys(settings).forEach((setting) => {
      if (typeof state[setting] === 'object') {
        state[setting] = {
          ...state[setting],
          ...JSON.parse(JSON.stringify(settings[setting]))
        };
      } else {
        state[setting] = settings[setting];
      }
    });
  },

  SET_EXCLUSIVE_EXPERIMENTAL_MODE: (state, exclusiveExperimentalMode) => {
    state.exclusiveExperimentalMode = exclusiveExperimentalMode;
  }
};
