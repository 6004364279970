<template>
  <div class="virtual-background-selector" :class="{ disabled }">
    <div class="grid">
      <div
        v-tooltip="noneTooltip"
        class="card none"
        :class="{
          selected: selectedBackground.type === VIRTUAL_BACKGROUND_TYPE.NONE
        }"
        @click="
          selectBackground({
            type: VIRTUAL_BACKGROUND_TYPE.NONE,
            background: null
          })
        "
      >
        <div
          v-show="selectedBackground.type === VIRTUAL_BACKGROUND_TYPE.NONE"
          class="icon-wrapper"
        >
          <vwc-icon
            class="selected-icon"
            connotation="cta"
            type="check-solid"
            size="small"
          />
        </div>
        <div v-if="isEnglishLocale" class="text">
          {{ $t('virtual_background_modal.none') }}
        </div>
        <div
          v-if="selectedBackground.type === VIRTUAL_BACKGROUND_TYPE.NONE"
          class="selected-mask"
        ></div>
      </div>
      <div
        v-tooltip="blurTooltip"
        class="card"
        @click="
          selectBackground({
            type: VIRTUAL_BACKGROUND_TYPE.BLUR,
            background: null
          })
        "
      >
        <div
          v-show="selectedBackground.type === VIRTUAL_BACKGROUND_TYPE.BLUR"
          class="icon-wrapper"
        >
          <vwc-icon
            class="selected-icon"
            connotation="cta"
            type="check-solid"
            size="small"
          />
        </div>
        <div v-if="isEnglishLocale" class="text">
          {{ $t('virtual_background_modal.blur') }}
        </div>
        <img
          :class="{
            selected: selectedBackground.type === VIRTUAL_BACKGROUND_TYPE.BLUR
          }"
          class="image"
          src="/virtual-backgrounds/thumbnails/Blur.jpg"
        />
      </div>
    </div>
    <div class="grid">
      <div
        v-for="background in virtualBackgrounds"
        :key="background.id"
        v-tooltip="
          disabled
            ? null
            : {
                content: background.tooltip,
                placement: 'top',
                classes: 'virtual-background-card-tooltip'
              }
        "
        class="card"
        @click="
          selectBackground({
            type: VIRTUAL_BACKGROUND_TYPE.IMAGE,
            background: background.id
          })
        "
      >
        <div
          v-if="selectedBackground.background === background.id"
          class="icon-wrapper"
        >
          <vwc-icon
            class="selected-icon"
            connotation="cta"
            type="check-solid"
            size="small"
          />
        </div>
        <img
          :class="{
            selected: selectedBackground.background === background.id
          }"
          class="image"
          :src="`/virtual-backgrounds/thumbnails/${background.id}.jpg`"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {
  SUPPORTED_LOCALES,
  VIRTUAL_BACKGROUND_TYPE
} from '@/consts/global-consts';

export default {
  name: 'VirtualBackgroundSelector',

  props: {
    selectedBackground: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      VIRTUAL_BACKGROUND_TYPE
    };
  },

  computed: {
    isEnglishLocale() {
      return this.$i18n.locale === SUPPORTED_LOCALES.ENGLISH;
    },

    noneTooltip() {
      if (this.disabled) {
        return null;
      }
      return {
        content: this.$t('virtual_background_modal.none_tooltip'),
        placement: 'bottom',
        classes: 'virtual-background-top-card-tooltip'
      };
    },

    blurTooltip() {
      if (this.disabled) {
        return null;
      }
      return {
        content: this.$t('virtual_background_modal.blur_tooltip'),
        placement: 'bottom',
        classes: 'virtual-background-top-card-tooltip'
      };
    },

    virtualBackgrounds() {
      return {
        India: {
          id: 'India',
          tooltip: this.$t('virtual_background_modal.india-image-tooltip')
        },
        Thailand: {
          id: 'Thailand',
          tooltip: this.$t('virtual_background_modal.thailand-image-tooltip')
        },
        NewYork: {
          id: 'NewYork',
          tooltip: this.$t('virtual_background_modal.new-york-image-tooltip')
        },
        Dubai: {
          id: 'Dubai',
          tooltip: this.$t('virtual_background_modal.dubai-image-tooltip')
        },
        Beach: {
          id: 'Beach',
          tooltip: this.$t('virtual_background_modal.beach-image-tooltip')
        },
        NightWaterfall: {
          id: 'NightWaterfall',
          tooltip: this.$t(
            'virtual_background_modal.night-waterfall-image-tooltip'
          )
        },
        CherryBlossom: {
          id: 'CherryBlossom',
          tooltip: this.$t(
            'virtual_background_modal.cherry-blossom-image-tooltip'
          )
        },
        Valley: {
          id: 'Valley',
          tooltip: this.$t('virtual_background_modal.valley-image-tooltip')
        },
        Lounge: {
          id: 'Lounge',
          tooltip: this.$t('virtual_background_modal.lounge-image-tooltip')
        },
        HomeOffice: {
          id: 'HomeOffice',
          tooltip: this.$t('virtual_background_modal.home-office-image-tooltip')
        },
        Library: {
          id: 'Library',
          tooltip: this.$t('virtual_background_modal.library-image-tooltip')
        },
        Desk: {
          id: 'Desk',
          tooltip: this.$t('virtual_background_modal.desk-image-tooltip')
        },
        Confetti: {
          id: 'Confetti',
          tooltip: this.$t('virtual_background_modal.confetti-image-tooltip')
        },
        Texture: {
          id: 'Texture',
          tooltip: this.$t('virtual_background_modal.texture-image-tooltip')
        },
        Waves: {
          id: 'Waves',
          tooltip: this.$t('virtual_background_modal.waves-image-tooltip')
        },
        Bubbles: {
          id: 'Bubbles',
          tooltip: this.$t('virtual_background_modal.bubbles-image-tooltip')
        }
      };
    }
  },

  methods: {
    selectBackground(selectedBackground) {
      if (this.disabled) {
        return;
      }
      this.$emit('selectBackground', selectedBackground);
    }
  }
};
</script>

<!--Using global style to override the v-tooltip css-->
<style>
.tooltip.virtual-background-card-tooltip {
  top: -4px !important;
  z-index: 100001;
}

.tooltip.virtual-background-top-card-tooltip {
  top: 4px !important;
  z-index: 100001;
}

.tooltip.virtual-background-card-tooltip .tooltip-inner,
.tooltip.virtual-background-top-card-tooltip .tooltip-inner {
  position: relative;
  background: #0d0d0d;
  padding: 8px 12px;
  width: auto;
  color: white;
  border-radius: 6px;
  font-weight: 600;
  font-size: 14px;
  text-align: center;
  z-index: 100002;
}
</style>

<style scoped>
.virtual-background-selector {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.card {
  position: relative;
  height: 56px;
  width: 69px;
  border-radius: 6px;
}

.card .text {
  color: white;
  font-weight: 600;
  font-size: 14px;
  z-index: 100;
  position: absolute;
  top: 18px;
  left: 18px;
}

.card.none .text {
  color: black;
}

.card.none {
  margin: auto;
  background: white;
}

.card.none.selected .selected-mask {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  background: var(--btn-cta-color-white-bg);
  opacity: 0.12;
}

.card.none:not(.selected) {
  outline: 1px solid #929292;
  outline-offset: -1px;
  border-radius: 6px;
}

.virtual-background-selector:not(.disabled) .card.none:hover:not(.selected) {
  background: rgba(0, 0, 0, 0.15);
  cursor: pointer;
}

.card .image {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  opacity: 1;
}

.selected {
  outline: 2px solid var(--btn-cta-color-white-bg);
  outline-offset: -2px;
  border-radius: 6px;
}

.virtual-background-selector:not(.disabled) .card:hover:not(.selected) {
  background: #000000;
}

.virtual-background-selector:not(.disabled) .card:hover .image:not(.selected) {
  opacity: 0.85;
  cursor: pointer;
}

.card:hover .selected {
  opacity: 1;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 14px;
  width: 14px;
  z-index: 100;
  position: absolute;
  margin-left: 49px;
  margin-top: 6px;
  background-color: white;
  outline: 1px solid var(--btn-cta-color-white-bg);
  border-radius: 50%;
}

.selected-icon {
  width: 9px;
  height: 8px;
}

.grid {
  display: grid;
  gap: 12px;
  margin-bottom: 12px;
  grid-template-columns: repeat(4, 69px);
}
</style>
