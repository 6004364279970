/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-tooltip': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1.5 0A1.5 1.5 0 000 1.5v10A1.5 1.5 0 001.5 13h3.766l2.35 2.82a.5.5 0 00.768 0l2.35-2.82H14.5a1.5 1.5 0 001.5-1.5v-10A1.5 1.5 0 0014.5 0h-13zM1 1.5a.5.5 0 01.5-.5h13a.5.5 0 01.5.5v10a.5.5 0 01-.5.5h-4a.5.5 0 00-.384.18L8 14.719l-2.116-2.54A.5.5 0 005.5 12h-4a.5.5 0 01-.5-.5v-10z"/>'
  }
})
