/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-sms-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 0H12a1.5 1.5 0 00-1.5 1.5V12L15 9h7.5A1.5 1.5 0 0024 7.5v-6A1.5 1.5 0 0022.5 0zM15 19.5v-9h3V21a3 3 0 01-3 3H4.5a3 3 0 01-3-3V4.5a3 3 0 013-3H9V6H4.5v13.5H15z"/>'
  }
})
