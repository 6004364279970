/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-bottom-tabs': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.5 0h-13C.7 0 0 .7 0 1.5v13c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-13c0-.8-.7-1.5-1.5-1.5zM5 15H1.5c-.3 0-.5-.2-.5-.5v-3h4V15zm5 0H6v-3.5h4V15zm5-.5c0 .3-.2.5-.5.5H11v-3.5h4v3zm0-4H1v-9c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5v9z"/>'
  }
})
