<template>
  <div class="contacts">
    <vwc-list>
      <recycle-scroller ref="list" page-mode :item-size="64" :items="contacts">
        <template #default="{ item }">
          <ContactRow
            :key="item.id"
            :contact="item"
            @click="onContactClick(item)"
          />
        </template>
      </recycle-scroller>
    </vwc-list>
  </div>
</template>

<script>
import ContactRow from '@/components/rows/ContactRow.vue';

export default {
  name: 'ContactsList',

  components: {
    ContactRow
  },

  props: {
    contacts: {
      type: Array,
      default: () => []
    }
  },

  methods: {
    onContactClick(contact) {
      this.$emit('contactClick', contact);
    }
  }
};
</script>

<style scoped>
.contacts {
  margin-top: 16px;
}
vwc-list {
  --mdc-list-vertical-padding: 0;
}
</style>
