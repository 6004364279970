/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-store': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.5 8c-.3 0-.5.2-.5.5V15h-3v-3.5c0-.3-.2-.5-.5-.5h-3c-.3 0-.5.2-.5.5V15H3V8.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v7c0 .3.2.5.5.5h11c.3 0 .5-.2.5-.5v-7c0-.3-.2-.5-.5-.5zM9 15H7v-3h2v3zM15.9 6.3L14 2.9V.5c0-.3-.2-.5-.5-.5h-11c-.3 0-.5.2-.5.5v2.4L.1 6.3c-.1.1-.1.3 0 .5.1.1.2.2.4.2h15c.2 0 .3-.1.4-.2.1-.2.1-.4 0-.5zM1.4 6L3 3.2V1h10v2c0 .1 0 .2.1.2L14.6 6H1.4z"/>'
  }
})
