/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-emoji-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zM9 7.5c.9 0 1.5.6 1.5 1.5s-.6 1.5-1.5 1.5S7.5 9.9 7.5 9 8.1 7.5 9 7.5zM12 18c-2.55 0-4.5-1.95-4.5-4.5h9c0 2.55-1.95 4.5-4.5 4.5zm3-7.5c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5 1.5.6 1.5 1.5-.6 1.5-1.5 1.5z"/>'
  }
})
