/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-left': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.441 4.06a.75.75 0 01.165 1.048L9.594 12l5.012 6.892a.75.75 0 01-1.213.882L8.06 12.441a.75.75 0 010-.882l5.333-7.334a.75.75 0 011.048-.165z"/>'
  }
})
