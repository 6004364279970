/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-error-star': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="st0" d="M24 11.9l-2.9-3 .6-4.1-4-.7-2-3.6L12 2.3 8.3.5l-2 3.6-4 .7.6 4.1-2.9 3 2.9 3-.6 4.1 4 .7 2 3.6 3.7-1.8 3.7 1.8 2-3.6 4-.7-.6-4.1 2.9-3zm-12 6.3c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5 1.5.6 1.5 1.5-.6 1.5-1.5 1.5zm1.5-5.2h-3V6.2h3V13z"/>'
  }
})
