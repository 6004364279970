var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "advanced-settings-container" }, [
    _vm.exclusiveExperimentalMode
      ? _c(
          "div",
          { staticClass: "section-container" },
          [
            _c("div", { staticClass: "title-container" }, [
              _c("div", { staticClass: "section-title" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("advanced_settings.hide_my_stream_title")) +
                    " "
                ),
              ]),
              _c("div", { staticClass: "section-subtitle" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("advanced_settings.hide_my_stream_subtitle")
                    ) +
                    " "
                ),
              ]),
            ]),
            _c("vwc-switch", {
              attrs: { checked: _vm.isHideMyStreamEnabled },
              on: { change: _vm.toggleHideMyStream },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.isOpenWebRTCInternalsFunctionalityAvailable
      ? _c(
          "div",
          { staticClass: "section-container" },
          [
            _c("div", { staticClass: "title-container" }, [
              _c("div", { staticClass: "section-title" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("advanced_settings.debug_title")) + " "
                ),
              ]),
              _c("div", { staticClass: "section-subtitle" }, [
                _vm._v(
                  " " + _vm._s(_vm.$t("advanced_settings.debug_subtitle")) + " "
                ),
              ]),
            ]),
            _c("vwc-button", {
              staticClass: "webrtc-internals-button",
              attrs: {
                label: _vm.$t("advanced_settings.webrtc_internals_button"),
                layout: "outlined",
                shape: "rounded",
                dense: "",
              },
              on: { click: _vm.openWebRTCInternals },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }