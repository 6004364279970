/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-right-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.672 3.563a1.5 1.5 0 10-2.343 1.875L13.579 12l-5.25 6.563a1.5 1.5 0 002.343 1.875l6-7.5a1.5 1.5 0 000-1.875l-6-7.5z"/>'
  }
})
