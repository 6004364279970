/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-umbrella': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.75 0C6.537 0 1.5 5.037 1.5 11.25c0 .414.336.75.75.75h21a.75.75 0 00.75-.75C24 5.037 18.963 0 12.75 0zm0 1.5c5.132 0 9.339 3.966 9.722 9H3.028c.383-5.034 4.59-9 9.722-9zm.75 12.75a.75.75 0 00-1.5 0v6a3.75 3.75 0 107.5 0v-1.5a.75.75 0 00-1.5 0v1.5a2.25 2.25 0 11-4.5 0v-6z"/>'
  }
})
