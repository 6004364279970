var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.toast
    ? _c(
        "div",
        {
          staticClass: "toast",
          class: {
            critical: _vm.isCritical,
            warning: _vm.isWarning,
            good: _vm.isGood,
            info: _vm.isInfo,
          },
          attrs: { "data-cy": "mobile-toast" },
        },
        [
          _vm.iconName
            ? _c("v-icon", {
                staticClass: "icon",
                attrs: { iconName: _vm.iconName },
              })
            : _vm._e(),
          _c("div", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.toast.message)),
          ]),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }