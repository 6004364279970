/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-emoji': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10.5 9h-5c-.2 0-.4.2-.4.4-.1.2-.1.4-.1.6 0 .8.3 1.6.9 2.1.5.6 1.3.9 2.1.9s1.6-.3 2.1-.9c.6-.6.9-1.3.9-2.1 0-.2 0-.4-.1-.6 0-.2-.2-.4-.4-.4zm-.5 1c0 .5-.2 1-.6 1.4-.4.4-.9.6-1.4.6s-1-.2-1.4-.6C6.2 11 6 10.5 6 10h4-4 4z"/><circle pid="1" cx="5" cy="6" r="1"/><path pid="2" d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zm0 15c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7z"/><circle pid="3" cx="11" cy="6" r="1"/>'
  }
})
