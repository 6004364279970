/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-receptionist-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M12 0v7.471c1.569.368 2.77 1.854 2.77 3.606 0 1.107-.47 2.03-1.299 2.77l3.317 2.768h.202c.74 0 1.385.646 1.385 1.385 0 .74-.559 1.385-1.298 1.385-.74 0-1.385-.56-1.385-1.299l-3.98-3.317h-.635a3.704 3.704 0 01-3.692-3.692c0-1.752 1.2-3.144 2.769-3.606V.087C4.984.548.924 4.893.924 10.154c0 2.401.843 4.536 2.134 6.288.923 1.201 1.557 2.676 1.557 4.154v.462c0 .461.289.829.75.923L14.596 24c.556.184 1.096-.278 1.096-.923v-1.385l1.558.087c2.124.184 3.98-1.479 3.98-3.692V15.23l1.933-1.01c.462-.184.653-.75.375-1.211l-2.394-4.068C20.59 4.233 16.8.462 12 0zm-.923 9.23a1.845 1.845 0 100 3.693 1.845 1.845 0 100-3.692z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
