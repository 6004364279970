/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-whatsapp': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.997.595a11.235 11.235 0 017.997 3.315 11.234 11.234 0 013.308 7.996c-.002 6.232-5.074 11.302-11.305 11.302h-.005c-1.892 0-3.752-.475-5.403-1.376L.596 23.404 2.2 17.547a11.279 11.279 0 01-1.51-5.65C.693 5.665 5.764.595 11.997.595zm0-.595C5.437 0 .097 5.337.095 11.897c0 2.005.505 3.98 1.465 5.73l-1.539 5.62a.594.594 0 00.726.733l5.768-1.512a11.925 11.925 0 005.477 1.336c6.564 0 11.903-5.337 11.906-11.897a11.824 11.824 0 00-3.482-8.418A11.831 11.831 0 0011.996 0zm6.646 5.26A9.333 9.333 0 0012 2.504c-5.183 0-9.399 4.213-9.401 9.393 0 1.775.496 3.503 1.437 5l.224.355-.95 3.466 3.557-.933.343.204a9.379 9.379 0 004.783 1.31h.004c5.18 0 9.395-4.215 9.396-9.395 0-2.51-.975-4.87-2.75-6.646zM8.535 6.683c.201.008.424.018.636.488.147.329.398.947.593 1.43.138.337.248.61.277.668.07.14.117.306.023.494a9.4 9.4 0 00-.04.081c-.07.144-.122.25-.242.39l-.148.178c-.096.117-.192.234-.276.317-.142.141-.289.294-.124.577.165.283.732 1.207 1.572 1.956a7.303 7.303 0 002.083 1.317c.079.034.142.061.188.085.283.141.448.118.613-.071.165-.188.706-.825.894-1.107.189-.283.377-.236.636-.142s1.648.777 1.93.919l.158.077c.195.094.327.158.384.253.07.118.07.683-.165 1.343-.235.66-1.389 1.295-1.907 1.342-.05.005-.1.01-.15.017-.479.056-1.083.127-3.24-.723-2.654-1.047-4.404-3.64-4.765-4.176a3.005 3.005 0 00-.06-.089l-.003-.002c-.148-.198-1.151-1.535-1.151-2.92 0-1.3.639-1.981.933-2.295l.056-.06c.259-.283.565-.353.753-.353h.04c.175 0 .348 0 .502.006z"/>'
  }
})
