/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-thumbs-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3.5 0h-3C.2 0 0 .2 0 .5v8c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5v-8c0-.3-.2-.5-.5-.5zM3 8H1V1h2v7zM16 6.9l-1.1-5c-.1-.6-.4-1-1-1.4-.4-.3-.9-.5-1.5-.5H5.5c-.3 0-.5.2-.5.5s.2.5.5.5h6.9c.4 0 .7.2 1 .3.3.2.4.5.5.8L15 7.2c.1.2.1.4 0 .6-.1.2-.2.5-.3.6-.2.2-.4.4-.6.5-.1.1-.3.1-.6.1h-4c-.3 0-.5.2-.5.5v4c0 .4-.2.8-.5 1-.2.2-.4.3-.7.4L6 9.3c-.1-.2-.4-.4-.7-.3-.2.1-.4.4-.3.7l2 6c.1.2.3.3.5.3.6 0 1.3-.3 1.8-.7.5-.5.7-1.1.7-1.8V10h3.5c.3 0 .8 0 1.2-.3.3-.2.6-.5.8-.7.3-.3.4-.7.5-1 .1-.3.1-.8 0-1.1z"/>'
  }
})
