/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-exit': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M9.5 11c-.2 0-.5.2-.5.5v3c0 .3-.2.5-.5.5h-7c-.2 0-.5-.2-.5-.5v-12c0-.3.3-.5.5-.5h7c.3 0 .5.2.5.5v3c0 .3.3.5.5.5.3 0 .5-.2.5-.5v-3C10 1.7 9.4 1 8.5 1h-7C.7 1 0 1.7 0 2.5v12c0 .8.7 1.5 1.5 1.5h7c.8 0 1.5-.7 1.5-1.5v-3c0-.3-.2-.5-.5-.5z"/><path pid="1" d="M15.9 8.1l-3-3c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7L14.3 8H4.5c-.2 0-.5.2-.5.5s.3.5.5.5h9.8l-2.1 2.1c-.2.2-.2.5 0 .7.2.2.5.2.7 0l3-3c.2-.3 0-.7 0-.7z"/>'
  }
})
