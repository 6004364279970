var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { ref: "wtPlayer", staticClass: "wt-player" }, [
    _c(
      "div",
      { staticClass: "player-container" },
      [
        _vm.isYoutube
          ? _c("YoutubePlayerWrapper", {
              ref: "playerWrapper",
              staticStyle: { width: "100%", height: "100%" },
              attrs: { "video-id": _vm.videoMetadata.videoId },
              on: {
                videoPlayerStateChanged:
                  _vm.handleWrappedPlayerVideoStateChanged,
                ready: _vm.handleWrappedPlayerReady,
                volumeChanged: _vm.handleWrappedPlayerVolumeChanged,
                seek: _vm.handleWrappedPlayerSeek,
                fullscreenchange: _vm.handleWrappedPlayerFullscreenChanged,
              },
            })
          : _vm._e(),
        _vm.isDraggingSeekBar
          ? _c("div", { staticClass: "invisible-overlay" })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        ref: "playbackContainer",
        staticClass: "playback-container",
        class: { minimized: _vm.minimized },
      },
      [
        _c("WatchTogetherSeekBar", {
          attrs: {
            timeInVideo: _vm.preciseTimeInVideo,
            duration: _vm.videoMetadata.duration,
            bufferedFraction: _vm.bufferedFraction,
            ownerIndicationVisible:
              !_vm.isWatchTogetherOwner &&
              !_vm.isSyncedWithOwner &&
              !_vm.minimized,
            ownerParticipant: _vm.watchTogetherOwnerParticipant,
            ownerTimeInVideo: _vm.preciseOwnerTimeInVideo,
            ownerPaused: _vm.isOwnerPaused,
          },
          on: { dragstart: _vm.timeBarDragStarted, seek: _vm.seek },
        }),
        _c("div", { staticClass: "playback-controls unselectable" }, [
          _c(
            "div",
            { staticClass: "dock left-dock" },
            [
              _vm.isPaused
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.bottom",
                          value: _vm.playButtonTooltip,
                          expression: "playButtonTooltip",
                          modifiers: { bottom: true },
                        },
                      ],
                      staticClass: "playback-control",
                      on: { click: _vm.play },
                    },
                    [_c("v-icon", { attrs: { iconName: "Vlt-icon-play-2" } })],
                    1
                  )
                : _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip.bottom",
                          value: _vm.pauseButtonTooltip,
                          expression: "pauseButtonTooltip",
                          modifiers: { bottom: true },
                        },
                      ],
                      staticClass: "playback-control",
                      on: { click: _vm.pause },
                    },
                    [_c("v-icon", { attrs: { iconName: "Vlt-icon-pause-2" } })],
                    1
                  ),
              _c("WatchTogetherVolumeSlider", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip.bottom",
                    value: _vm.volumeTooltip,
                    expression: "volumeTooltip",
                    modifiers: { bottom: true },
                  },
                ],
                staticClass: "volume-slider",
                attrs: { volume: _vm.volume },
                on: {
                  toggleMute: _vm.toggleMute,
                  volumeChanged: _vm.setVolume,
                },
              }),
              !_vm.minimized
                ? _c("div", { staticClass: "dock" }, [
                    _c("div", { staticClass: "duration-text" }, [
                      _c("span", [_vm._v(_vm._s(_vm.currentTime))]),
                      _vm._v(" / "),
                      _c("span", [_vm._v(_vm._s(_vm.duration))]),
                    ]),
                    _c("div", { staticClass: "separator" }),
                    _c(
                      "div",
                      { staticClass: "owner-identity-exclamation" },
                      [
                        _c("Avatar", {
                          staticClass: "owner-avatar",
                          attrs: {
                            size: "xs",
                            displayName:
                              _vm.watchTogetherOwnerParticipant.displayName,
                            image:
                              _vm.watchTogetherOwnerParticipant.profilePicture,
                            negative: true,
                          },
                        }),
                        _vm.isWatchTogetherOwner
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("watch_together_player.owner_text")
                                )
                              ),
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("watch_together_player.watcher_text", {
                                    watchTogetherOwnerFirstName:
                                      _vm.watchTogetherOwnerFirstName,
                                  })
                                )
                              ),
                            ]),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "dock" }, [
            !_vm.minimized
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip",
                        value: _vm.fullscreenTooltip,
                        expression: "fullscreenTooltip",
                      },
                    ],
                    staticClass: "playback-control",
                    on: {
                      click: _vm.toggleFullscreen,
                      mouseleave: function ($event) {
                        _vm.showFullscreenHint = false
                      },
                    },
                  },
                  [
                    _c("v-icon", {
                      attrs: { iconName: _vm.fullscreenButtonIcon },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.bottom",
                        value: _vm.expandTooltip,
                        expression: "expandTooltip",
                        modifiers: { bottom: true },
                      },
                    ],
                    staticClass: "playback-control",
                    on: { click: _vm.expand },
                  },
                  [
                    _c("v-icon", {
                      attrs: { iconName: "Vlt-icon-expand-full" },
                    }),
                  ],
                  1
                ),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }