import * as tokbox from '@/apis/opentok-api';
import {
  OT_ERROR,
  OS_TYPES,
  STREAM_TYPE,
  DEVICES_TYPES,
  MEDIA_ACCESS_STATUS,
  ANALYTICS
} from '@/consts/global-consts';
import {
  OS,
  isElectron,
  shouldEnableAudioSource
} from '@/helpers/global-helpers';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import analytics from '@/services/analytics-service';
import MeetingManager from '@/services/meeting-manager-service';
import i18n from '@/i18n';

let publisherScreenStatsInterval = 0;
const SCREEN_SHARE_FPS = 15;
const electronWebapp = isElectron() ? window.Electron.webApp : {};

export default {
  setScreenshareCapability: async ({ commit }) => {
    const isScreenshareSupported = await tokbox.isScreenshareSupported();
    commit('SET_IS_SCREENSHARE_SUPPORTED', isScreenshareSupported);
  },

  handleScreenshareError: ({ dispatch }, error) => {
    // Handle different errors:
    if (error.name === OT_ERROR.USER_MEDIA_ACCESS_DENIED) {
      if (
        error.originalMessage === 'Permission denied by system' &&
        OS.name === OS_TYPES.MAC
      ) {
        // If the user click cancel when choosing source, we get the same error,
        // but the originalMessage is just "Permission denied"
        // Missing permissions - Web (Catalina)
        dispatch(
          'setInfoDialog',
          {
            title: i18n.t('share_screen_actions.missing_permissions_title'),
            text: i18n.t('share_screen_actions.missing_permissions_text')
          },
          { root: true }
        );
      } else if (!window.isChrome) {
        // In chrome if the user click cancel when choosing source,
        // we get the same error.
        dispatch(
          'addFlashMessage',
          {
            type: 'warning',
            text: i18n.t('share_screen_actions.no_permissions_text')
          },
          { root: true }
        );
      }
    } else if (
      error.name === OT_ERROR.NO_DEVICES_FOUND &&
      OS.name === OS_TYPES.MAC &&
      window.isFirefox
    ) {
      // In Firefox on Mac with Catalina, we get this weird error for missing permissions.
      dispatch(
        'setInfoDialog',
        {
          title: i18n.t('share_screen_actions.missing_permissions_title'),
          text: i18n.t('share_screen_actions.missing_permissions_text')
        },
        { root: true }
      );
    } else if (error.name === OT_ERROR.SCREEN_SHARING_NOT_SUPPORTED) {
      // Share screen is not supported in this browser
      dispatch(
        'addFlashMessage',
        {
          type: 'warning',
          text: i18n.t('share_screen_actions.not_supported_text')
        },
        { root: true }
      );
    } else {
      dispatch(
        'addFlashMessage',
        {
          type: 'critical',
          text: i18n.t('share_screen_actions.general_error_text')
        },
        { root: true }
      );
    }
  },

  setScreenshareSources: ({ commit, dispatch }, sources) => {
    commit('SET_SCREENSHARE_SOURCES', sources);
    dispatch(
      'setActionInProgress',
      { name: 'getScreenshareSources', inProgress: false },
      {
        root: true
      }
    );
  },

  toggleScreenshare: async ({ getters, rootGetters, dispatch }) => {
    if (getters.hasScreenshareAccess) {
      if (rootGetters.isScreenShared) {
        return dispatch('stopScreenshare');
      } else {
        if (!isElectron()) {
          return dispatch('startScreenshare');
        }
        dispatch(
          'setActionInProgress',
          { name: 'getScreenshareSources', inProgress: true },
          {
            root: true
          }
        );

        const sources = await dispatch('getScreenshareSources');
        dispatch('setScreenshareSources', sources || []);
      }
    }
  },

  toggleScreenshareIndicator: async (
    { state, rootGetters },
    { isShared, sharingType }
  ) => {
    // TODO: move to a separate electron service
    if (isElectron()) {
      if (window.Electron.notifyScreenshareStatusUpdate) {
        window.Electron.enableMinimizeMode(isShared);
        window.Electron.notifyScreenshareStatusUpdate(isShared, sharingType);
      } else {
        if (isShared) {
          electronWebapp.sendMessage_screenshareStarted(
            state.roomDetails.displayName
          );
        } else {
          electronWebapp.sendMessage_screenshareStopped();
        }
      }
    }
    logger.log('screenshare-toggled', LOG_CATEGORIES.CLIENT_LOGIC, {
      isShared,
      isOwner: rootGetters.isSessionOwner
    });
    analytics.trackEvent(ANALYTICS.SCREEN_SHARED, {
      'New State': isShared ? 'On' : 'Off',
      Owner: rootGetters.isSessionOwner,
      'Num of Participants': rootGetters.activeParticipants.length,
      'Shared Screen': sharingType,
      'Join Type': rootGetters.isGuest ? 'Guest' : 'Application'
    });
  },

  getScreenshareSources: () => {
    if (window.Electron?.getScreenshareSources) {
      return window.Electron.getScreenshareSources({
        types: ['window', 'screen'],
        thumbnailSize: { width: 235, height: 145 },
        fetchWindowIcons: true
      });
    } else if (window.Electron?.screenshare) {
      // TODO: can we delete it?
      return window.Electron.screenshare.getSources({
        types: ['window', 'screen'],
        thumbnailSize: { width: 235, height: 145 }
      });
    }
  },

  stopScreenshare: ({ dispatch }) => {
    dispatch(
      'setActionInProgress',
      { name: 'screenShare', inProgress: true },
      {
        root: true
      }
    );
    return new Promise((resolve, reject) => {
      clearInterval(publisherScreenStatsInterval);
      publisherScreenStatsInterval = 0;

      if (MeetingManager.screenPublisher) {
        MeetingManager.stopScreensharePublishing();
        dispatch('setMyScreenStreamId', '', { root: true });
        resolve();
      } else {
        const errMessage = 'ScreenPublisher is not defined';
        logger.error(
          'stop-screenshare',
          LOG_CATEGORIES.CLIENT_LOGIC,
          errMessage
        );
        reject(new Error(errMessage));
      }
      dispatch(
        'setActionInProgress',
        { name: 'screenShare', inProgress: false },
        {
          root: true
        }
      );
    });
  },

  startScreenshare: async (
    { dispatch, rootGetters },
    { videoSource = STREAM_TYPE.WEB_SCREENSHARE, sharingType } = {}
  ) => {
    try {
      if (rootGetters.isInitializingScreenshare || rootGetters.isScreenShared) {
        throw new Error('Share screen is already in progress');
      }
      dispatch(
        'setActionInProgress',
        { name: 'screenShare', inProgress: true },
        {
          root: true
        }
      );
      // When the videoSource is a mediaStreamTrack we need to set the default screenshare properties manually.
      const screenPublisherElement = document.createElement('div');

      try {
        await MeetingManager.initScreensharePublisher(screenPublisherElement, {
          videoSource,
          fitMode: 'contain',
          audioSource: shouldEnableAudioSource(),
          audioFallbackEnabled: false,
          maxResolution: { width: 1920, height: 1080 },
          mirror: false,
          scaleMode: 'fit',
          frameRate: SCREEN_SHARE_FPS,
          // Content hint should help the browser better handle the stream. For more info:
          // https://jira.vonage.com/browse/MEET-1794
          videoContentHint: 'text'
        });
      } catch (error) {
        dispatch('handleScreenshareError', error);
        logger.error(
          'init-screenshare-publisher',
          LOG_CATEGORIES.CLIENT_LOGIC,
          error
        );
        dispatch('setMyScreenStreamId', '', { root: true });
        throw new Error(error);
      }

      logger.log('tb_screenshare-publish_try', LOG_CATEGORIES.TOKBOX, {
        tb_call: 'session.publish()'
      });

      try {
        await MeetingManager.startScreensharePublishing();
      } catch (error) {
        logger.error('tb_screenshare-publish_error', LOG_CATEGORIES.TOKBOX, {
          tb_call: 'session.publish()',
          error
        });

        dispatch('handleScreenshareError', error);
        dispatch('setMyScreenStreamId', '', { root: true });
        throw new Error(error);
      }

      const streamId = MeetingManager.screenPublisherStreamId;
      logger.log('tb_screenshare-publish_success', LOG_CATEGORIES.TOKBOX, {
        tb_call: 'session.publish()',
        streamId
      });

      dispatch('setMyScreenStreamId', streamId, { root: true });
      dispatch('toggleScreenshareIndicator', { isShared: true, sharingType });

      MeetingManager.onScreenPublisherEvent('destroyed', () => {
        dispatch('toggleScreenshareIndicator', { isShared: false });
        dispatch('setMyScreenStreamId', '', { root: true });
      });
    } finally {
      dispatch(
        'setActionInProgress',
        { name: 'screenShare', inProgress: false },
        {
          root: true
        }
      );
    }
  },

  updateHasScreensharePermissions: async ({ state, commit, dispatch }) => {
    if (isElectron() && OS.name === OS_TYPES.MAC) {
      const accessStatus = await dispatch(
        'getMediaAccessStatus',
        DEVICES_TYPES.SCREEN,
        {
          root: true
        }
      );
      logger.log('mac-screenshare-permissions', LOG_CATEGORIES.DEVICES, {
        accessStatus
      });
      commit(
        'SET_HAS_SCREENSHARE_PERMISSIONS',
        accessStatus === MEDIA_ACCESS_STATUS.GRANTED
      );
      if (!state.hasScreensharePermissions) {
        dispatch(
          'setInfoDialog',
          {
            title: i18n.t('share_screen_actions.missing_permissions_title'),
            text: i18n.t('share_screen_actions.vbc_missing_permissions_text')
          },
          {
            root: true
          }
        );
      }
    } else {
      commit('SET_HAS_SCREENSHARE_PERMISSIONS', true);
    }
  },

  showMissingScreenshareAccess: ({ state, dispatch }) => {
    if (!state.isScreenshareSupported) {
      dispatch('addFlashMessage', {
        type: 'warning',
        text: i18n.t('share_screen_actions.not_supported_text'),
        time: 5000
      });
    } else if (
      isElectron() &&
      OS.name === OS_TYPES.MAC &&
      !state.hasScreensharePermissions
    ) {
      dispatch('openSystemSettings', 'screen');
    }
  }
};
