<template>
  <div class="participants-container" data-cy="participants-sidebar">
    <div v-if="inviteVisible || muteAllVisible" class="header-container">
      <div class="header-buttons">
        <vwc-button
          v-if="inviteVisible"
          data-cy="invite-button"
          layout="outlined"
          shape="rounded"
          fullwidth
          dense
          connotation="primary"
          icon="add-user-solid"
          :label="$t('participants.invite_participants_button')"
          :disabled="areInvitesDisabled"
          @click="showInviteParticipantsModal"
        />
        <vwc-button
          v-else-if="muteAllVisible"
          layout="outlined"
          shape="rounded"
          fullwidth
          dense
          connotation="primary"
          icon="mic-mute-line"
          :label="$t('participants.mute_all_label')"
          @click="muteAll"
        />
        <div
          v-if="inviteVisible && muteAllVisible"
          class="owner-menu"
          data-cy="owner-menu"
        >
          <MoreOptionsMenu
            :more-options-lists="ownerMoreOptionsList"
            outlined
            @optionSelected="moreOptionSelected"
          />
        </div>
      </div>
      <InvitesDisabledIndication v-if="areInvitesDisabled" />
    </div>
    <Standup v-if="isStandupOn" />
    <div data-cy="participants-list" class="participants-list">
      <ParticipantsList />
    </div>
  </div>
</template>

<script>
import ParticipantsList from '@/components/lists/ParticipantsList.vue';
import Standup from '@/components/Standup.vue';
import { mapState, mapGetters, mapActions } from 'vuex';
import analytics from '@/services/analytics-service';
import { ANALYTICS } from '@/consts/global-consts';
import MoreOptionsMenu from '@/components/MoreOptionsMenu';
import InvitesDisabledIndication from '@/components/InvitesDisabledIndication';

export default {
  name: 'Participants',

  components: {
    MoreOptionsMenu,
    ParticipantsList,
    Standup,
    InvitesDisabledIndication
  },

  computed: {
    ...mapState('standup', ['isStandupOn']),
    ...mapState(['isLocked']),
    ...mapGetters([
      'isGuest',
      'isSessionOwner',
      'isMuteAllEnabled',
      'activeParticipants',
      'isWaitingRoomEnabled'
    ]),

    areInvitesDisabled() {
      return (
        this.isLocked || (this.isWaitingRoomEnabled && !this.isSessionOwner)
      );
    },

    inviteVisible() {
      return !this.isGuest;
    },

    ownerMoreOptionsList() {
      const moreList = [];
      if (this.muteAllVisible) {
        moreList.push({
          id: 'mute-all',
          label: this.$t('participants.mute_all_label'),
          iconName: 'mic-mute-line'
        });
      }
      return [moreList];
    },

    muteAllVisible() {
      return (
        this.isMuteAllEnabled &&
        this.isSessionOwner &&
        this.activeParticipants.length > 1
      );
    }
  },

  methods: {
    ...mapActions([
      'setIsMuteAllParticipantsDialogVisible',
      'setIsInviteParticipantsDialogVisible'
    ]),

    moreOptionSelected(selected) {
      if (selected.id === 'mute-all') {
        this.muteAll();
      }
    },

    muteAll() {
      this.setIsMuteAllParticipantsDialogVisible(true);
      analytics.trackEvent(ANALYTICS.OWNER_MUTE, {
        Type: 'All'
      });
    },

    showInviteParticipantsModal() {
      this.setIsInviteParticipantsDialogVisible(true);
    }
  }
};
</script>

<style scoped>
.participants-container {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.header-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 20px 20px 20px;
  border-bottom: 1px solid #cccccc;
}

.header-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.owner-menu {
  margin-left: 12px;
}
.participants-list {
  overflow-y: auto;
  flex: 1;
}
</style>
