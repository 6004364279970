/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-flip': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.8 7.5H15v5.2c0 .3.2.6.4.7.3.1.6.1.8-.1l7.5-6c.2-.1.3-.3.3-.5s-.1-.4-.3-.6l-7.5-6c-.2-.2-.5-.3-.8-.1-.2.1-.4.4-.4.7V6H.8c-.5 0-.8.3-.8.8s.3.7.8.7zm15.7-.7V2.3L22 6.8l-5.5 4.4V6.8z"/><path pid="1" d="M23.2 16.5H9v-5.2c0-.3-.2-.6-.4-.7-.3-.1-.6-.1-.8.1l-7.5 6c-.2.1-.3.3-.3.5s.1.4.3.6l7.5 6c.2.2.5.3.8.1.2-.1.4-.4.4-.7V18h14.2c.4 0 .8-.3.8-.8s-.3-.7-.8-.7zm-15.7.7v4.4L2 17.2l5.5-4.4v4.4z"/>'
  }
})
