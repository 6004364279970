var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "more-menu-container" },
    [
      _c("vwc-icon-button", {
        ref: "moreOptionsMenuButton",
        attrs: {
          "data-cy": "more-options-menu-toggle",
          icon: "more-vertical-solid",
          connotation: "primary",
          dense: "",
          layout: _vm.outlined ? "outlined" : "ghost",
        },
        on: { click: _vm.toggleMoreOptionsMenuVisibility },
      }),
      _c(
        "vwc-menu",
        {
          ref: "moreOptionsMenu",
          attrs: {
            menuCorner: "START",
            corner: "BOTTOM_LEFT",
            open: _vm.isMoreOptionsMenuOpen,
          },
          on: {
            closed: function ($event) {
              _vm.isMoreOptionsMenuOpen = false
            },
          },
        },
        _vm._l(_vm.moreOptionsLists, function (list, index) {
          return _c(
            "div",
            { key: index },
            [
              _vm._l(list, function (item) {
                return _c(
                  "vwc-list-item",
                  {
                    key: item.id,
                    attrs: { graphic: "icon" },
                    on: {
                      click: function ($event) {
                        return _vm.optionSelected(item)
                      },
                    },
                  },
                  [
                    _c(
                      "vwc-text",
                      {
                        attrs: {
                          "data-cy": "menu-option-label",
                          "font-face": "body-2",
                          connotation: item.isCritical ? "alert" : "primary",
                        },
                      },
                      [_vm._v(_vm._s(item.label))]
                    ),
                    _c("vwc-icon", {
                      attrs: {
                        slot: "graphic",
                        size: "small",
                        connotation: item.isCritical ? "alert" : "primary",
                        type: item.iconName,
                      },
                      slot: "graphic",
                    }),
                  ],
                  1
                )
              }),
              index < _vm.moreOptionsLists.length - 1
                ? _c("li", {
                    staticClass: "lists-divider",
                    attrs: { role: "separator" },
                  })
                : _vm._e(),
            ],
            2
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }