/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-queue': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M20 2.3a1.7 1.7 0 100 3.4 1.7 1.7 0 000-3.4zm-8 3a1.7 1.7 0 100 3.4 1.7 1.7 0 000-3.4zm-8 3a1.7 1.7 0 100 3.4 1.7 1.7 0 000-3.4zm-1 8A1.7 1.7 0 001.3 18v3.7h5.4V18A1.7 1.7 0 005 16.3H3zM8 18a3 3 0 00-3-3H3a3 3 0 00-3 3v5h8v-5zm9.879-16.121A3 3 0 1122.12 6.12 3 3 0 0117.88 1.88zm-8 3a3 3 0 114.242 4.243A3 3 0 019.88 4.879zm-8 3A3 3 0 116.12 12.12 3 3 0 011.88 7.88zm6.138 6.406a3.266 3.266 0 013.125-2.336h1.75a3.275 3.275 0 013.275 3.275v5.025h-5.9v-1.3h4.6v-3.725c0-1.09-.884-1.975-1.975-1.975h-1.75c-.886 0-1.634.592-1.88 1.41l-1.245-.374zM18.952 8.95a3.266 3.266 0 00-3.125 2.336l1.245.374a1.966 1.966 0 011.88-1.41h1.75c1.09 0 1.975.884 1.975 1.975v3.725h-4.6v1.3h5.9v-5.025a3.275 3.275 0 00-3.275-3.275h-1.75z"/>'
  }
})
