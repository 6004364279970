import io from 'socket.io-client';
import { config } from '@/services/desktop-clients-sync/config';

export async function getRunningEnvSocket() {
  const isProdEnvs = window.isAlpha || window.isProduction;
  const configEnvsType = isProdEnvs ? 'prod' : 'dev';

  for (const env of config.environments[configEnvsType]) {
    const socket = openDesktopWebSocket(env.desktop_app_port);

    if (await isSocketConnected(socket)) {
      return { socket, env };
    }
  }

  return null;
}

function isSocketConnected(socket) {
  return new Promise((resolve) => {
    socket.on('connect', () => resolve(true));
    socket.on('connect_error', () => resolve(false));
    socket.on('connect_timeout', () => resolve(false));
  });
}

function openDesktopWebSocket(port) {
  return io(`http://127.0.0.1:${port}`, {
    transports: ['polling'],
    upgrade: false
  });
}
