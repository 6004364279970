/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-thin-up': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.646.146a.5.5 0 01.708 0l5 5a.5.5 0 01-.708.708L8.5 1.707V15.5a.5.5 0 01-1 0V1.707L3.354 5.854a.5.5 0 11-.708-.708l5-5z"/>'
  }
})
