/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-drag-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5 6a3 3 0 110-6 3 3 0 010 6zm0 9a3 3 0 110-6 3 3 0 010 6zm-3 6a3 3 0 106 0 3 3 0 00-6 0zm12-6a3 3 0 110-6 3 3 0 010 6zm-3-12a3 3 0 106 0 3 3 0 00-6 0zm3 21a3 3 0 110-6 3 3 0 010 6z"/>'
  }
})
