<template>
  <Dialog
    data-cy="hijack-screenshare-modal"
    class="dialog"
    :heading="$t('meeting_actions.share_screen')"
    icon-name="screen-share-line"
    :close="hide"
    :min-width="454"
    :primary-action="$t('meeting_actions.share_screen_ok_button')"
    :open="isHijackScreenshareDialogVisible"
    primaryButtonConnotation="cta"
    isSmallModal
    @applied="hijackScreenshareAndHideModal"
    @click.stop
  >
    <div slot="content" class="content">
      <div class="text">
        {{ content }}
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import Dialog from '@/components/Dialog';

export default {
  name: 'HijackScreenshareModal',

  components: { Dialog },

  computed: {
    ...mapState(['isHijackScreenshareDialogVisible', 'streams']),
    ...mapGetters(['displayNameOfParticipantSharingScreen']),

    content() {
      return this.$t('meeting_actions.other_participant_sharing_screen', {
        participantDisplayName: this.displayNameOfParticipantSharingScreen
      });
    }
  },

  methods: {
    ...mapActions(['setIsHijackScreenshareDialogVisible', 'hijackScreenshare']),

    hide() {
      this.setIsHijackScreenshareDialogVisible(false);
    },

    hijackScreenshareAndHideModal() {
      this.hijackScreenshare();
      this.hide();
    }
  }
};
</script>

<style scoped>
vwc-dialog {
  --mdc-dialog-max-width: 454px;
}
</style>
