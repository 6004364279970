/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-app-plus': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11.5 7C9 7 7 9 7 11.5S9 16 11.5 16s4.5-2 4.5-4.5S14 7 11.5 7zm0 8C9.6 15 8 13.4 8 11.5S9.6 8 11.5 8 15 9.6 15 11.5 13.4 15 11.5 15z"/><path pid="1" d="M13.5 11H12V9.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5V11H9.5c-.3 0-.5.2-.5.5s.2.5.5.5H11v1.5c0 .3.2.5.5.5s.5-.2.5-.5V12h1.5c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"/><path pid="2" d="M14.5 0h-13C.7 0 0 .7 0 1.5v10c0 .8.7 1.5 1.5 1.5h3c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-3c-.3 0-.5-.2-.5-.5V5h14v1.5c0 .3.2.5.5.5s.5-.2.5-.5v-5c0-.8-.7-1.5-1.5-1.5zm.5 4H1V1.5c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5V4z"/>'
  }
})
