/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-camera-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 5.25h-3.75l-2.55-3.9c-.3-.3-.75-.6-1.2-.6H9c-.45 0-.9.3-1.2.6l-2.55 3.9H1.5c-.9 0-1.5.6-1.5 1.5v15c0 .9.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5v-15c0-.9-.6-1.5-1.5-1.5zm-6 8.75a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm1.5 0a6 6 0 11-12 0 6 6 0 0112 0z"/>'
  }
})
