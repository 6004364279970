/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-park': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5 21.3V3.7c0-.4.3-.7.7-.7h6.2C16.1 3 19 5.1 19 8.8v.1c0 4-3.5 6-7.4 6H6.4v6.5c0 .4-.3.7-.7.7-.4-.1-.7-.4-.7-.8zm1.4-7.7h5.2c3.5 0 5.9-1.8 5.9-4.6v-.1c0-3-2.3-4.6-5.8-4.6H6.4v9.3z"/>'
  }
})
