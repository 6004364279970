/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-inbox-3': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2 .5a.5.5 0 01.5-.5h11a.5.5 0 010 1h-11A.5.5 0 012 .5zM4.5 6a.5.5 0 01.5.5V8h6V6.5a.5.5 0 011 0v2a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-2a.5.5 0 01.5-.5zm-4-3a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h15a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5H.5zM1 14V4h14v10H1z"/>'
  }
})
