import * as tokbox from '@/apis/opentok-api';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';

class MeetingManagerService {
  constructor() {
    this.session = null;
    this.publisher = null;
    this.selfSubscriber = null;
    this.screenPublisher = null;
    this.streams = {};
    this.subscribers = {};
    this._preInitSessionEvents = [];
  }

  get connectionId() {
    return this.session?.connection?.connectionId;
  }

  get publisherStreamId() {
    return this.publisher?.stream?.streamId;
  }

  get screenPublisherStreamId() {
    return this.screenPublisher?.stream?.streamId;
  }

  get publisherConnectionId() {
    return this.publisher?.stream?.connection?.connectionId;
  }

  get offSessionEvent() {
    return this.session.off;
  }

  get onPublisherEvent() {
    return this.publisher.on;
  }

  get onScreenPublisherEvent() {
    return this.screenPublisher.on;
  }

  onSessionEvent(eventName, callback) {
    if (!this.session) {
      this._preInitSessionEvents.push({ eventName, callback });
      return;
    }
    return this.session.on(eventName, callback);
  }

  initSession(apiKey, sessionId) {
    this.session = tokbox.initSession(apiKey, sessionId);
    this._registerPreInitSessionEvents();
  }

  setMainPublisher(publisher) {
    this.stopPublishing(); // Destroy current publisher if exists
    this.publisher = publisher;
  }

  async initPublisher(element, options) {
    return tokbox.initPublisher(element, options);
  }

  async initScreensharePublisher(element, options) {
    try {
      this.screenPublisher = await tokbox.initPublisher(element, options);
    } catch (error) {
      // TODO: is it needed?
      this.screenPublisher = null;
      throw error;
    }
  }

  async startMainPublishing() {
    await this._startPublishing(this.publisher);
    this.streams[this.publisherStreamId] = this.publisher.stream;
  }

  async startScreensharePublishing() {
    try {
      await this._startPublishing(this.screenPublisher);
    } catch (error) {
      // TODO: is it needed?
      this.screenPublisher = null;
      throw error;
    }
  }

  _startPublishing(publisher) {
    return new Promise((resolve, reject) => {
      this.session.publish(publisher, (error) => {
        if (error) {
          reject(error);
        } else {
          resolve();
        }
      });
    });
  }

  stopPublishing() {
    if (this.publisher) {
      if (this.publisherStreamId) {
        this.removeStream(this.publisherStreamId);
      }
      this.publisher.destroy();
      this.publisher = null;
    }
  }

  stopScreensharePublishing() {
    this.session.unpublish(this.screenPublisher);
    this.screenPublisher = null;
  }

  addStream(stream) {
    this.streams[stream.streamId] = stream;
  }

  removeStream(streamId) {
    delete this.streams[streamId];
  }

  startSubscribing(
    stream,
    element,
    options,
    videoElementCreatedCallback = undefined
  ) {
    return new Promise((resolve, reject) => {
      const subscriber = this.session.subscribe(
        stream,
        element,
        options,
        (error) => {
          if (error) {
            reject(error);
          } else {
            this.subscribers[stream.streamId] = { element, options };
            resolve(subscriber);
          }
        }
      );

      // This is a hack to remove the default background that tokbox display on the stream until the video is enabled
      // The reason that we see this default background is that it takes time for the video to be enabled on share-screen
      // tokbok allows us to change the style only if showControls is true, so we set it to true only for share-screen
      if (options.showControls) {
        subscriber.setStyle('backgroundImageURI', '');
      }

      if (videoElementCreatedCallback) {
        subscriber.on('videoElementCreated', (event) => {
          videoElementCreatedCallback(event.element);
        });
      }
    });
  }

  subscribeToAudio() {
    Object.keys(this.subscribers).forEach((streamId) => {
      const subscriber = this.getSubscriberByStreamId(streamId);
      if (subscriber) {
        subscriber.subscribeToAudio(true);
      }
    });
  }

  unsubscribeStream(streamId) {
    const streamSubscribers = this.getSubscribersByStreamId(streamId);
    streamSubscribers.forEach((subscriber) => {
      this.session.unsubscribe(subscriber);
    });
  }

  removeSubscriber(streamId) {
    delete this.subscribers[streamId];
    this.removeStream(streamId);
  }

  getSubscriberOptions(streamId) {
    return this.subscribers[streamId];
  }

  isSubscriberStream(streamId) {
    const stream = this.streams[streamId];
    return stream && !stream.publisher;
  }

  onSessionConnected(callback) {
    if (this.session && this.session.isConnected()) {
      callback();
    } else {
      this.onSessionEvent('sessionConnected', callback);
    }
  }

  disconnectFromSession() {
    return new Promise((resolve, reject) => {
      let timeout;
      const sessionDisconnectedHandler = (event) => {
        clearTimeout(timeout);
        this.offSessionEvent(sessionDisconnectedHandler);
        resolve(event);
      };
      this.onSessionEvent('sessionDisconnected', sessionDisconnectedHandler);
      // TODO: the session.disconnets() call causes many errors when the meeting ends. investigate the reasons (i.e invalid transitions because TB calls connectivityState.disconnect() twice for some reason)
      this.session.disconnect();
      timeout = setTimeout(() => {
        this.offSessionEvent(sessionDisconnectedHandler);
        reject();
      }, 1000);
    });
  }

  getStreamById(streamId) {
    return this.streams[streamId];
  }

  sendSignal(signal) {
    return new Promise((resolve, reject) => {
      if (this.session.isConnected()) {
        if (signal.data) {
          signal.data = JSON.stringify(signal.data);
        }
        this.session.signal(signal, (err) => {
          if (err) {
            return reject(err);
          }
          return resolve();
        });
      } else {
        return reject(new Error('Session is not connected'));
      }
    });
  }

  onSignal(signalType, callback) {
    if (this.session) {
      this.session.on(`signal:${signalType}`, (event) => {
        if (event.data) {
          try {
            event.data = JSON.parse(event.data);
          } catch {
            logger.warning(
              'parse-signal-to-json-failure',
              LOG_CATEGORIES.CLIENT_LOGIC,
              { signal: event.data }
            );
          }
        }
        return callback(event);
      });
    }
  }

  getSubscribersByStreamId(streamId) {
    const stream = this.getStreamById(streamId);
    if (stream) {
      const subscribers = this.session.getSubscribersForStream(stream);
      if (subscribers.length > 0) {
        if (subscribers.length > 1) {
          const subscribersInfo = subscribers.map((subscriber) => {
            return {
              hasStream: !!subscriber?.stream,
              hasAudio: !!subscriber?.stream?.hasAudio,
              hasVideo: !!subscriber?.stream?.hasVideo,
              isSubscribing: subscriber?.isSubscribing()
            };
          });
          logger.error('get-subscriber', LOG_CATEGORIES.TOKBOX, {
            message: 'Found more than one subscriber for the same stream',
            streamId,
            subscribersInfo
          });
        }
        return subscribers;
      }
    }
    return [];
  }

  getSubscriberByStreamId(streamId) {
    const subscribers = this.getSubscribersByStreamId(streamId);
    return subscribers[0];
  }

  async createSelfSubscriber(options = { audioVolume: 0, testNetwork: true }) {
    if (!this.publisher) {
      logger.error('create-self-subscriber', LOG_CATEGORIES.TOKBOX, {
        message: 'Publisher not found'
      });
      throw new Error('Publisher not found');
    }
    if (this.selfSubscriber) {
      logger.error('create-self-subscriber', LOG_CATEGORIES.TOKBOX, {
        message: 'Self subscriber already exist'
      });
      throw new Error('Self subscriber already exist');
    }
    this.selfSubscriber = await this.startSubscribing(
      this.publisher.stream,
      document.createElement('div'),
      { ...options, subscribeToAudio: this.publisher.stream.hasAudio }
    );
    return this.selfSubscriber;
  }

  getSessionSubscribers() {
    return tokbox.getSubscribers();
  }

  _registerPreInitSessionEvents() {
    if (this.session) {
      this._preInitSessionEvents.forEach((ev) => {
        this.onSessionEvent(ev.eventName, ev.callback);
      });
    }
  }
}

export default new MeetingManagerService();
