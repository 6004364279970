var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "rate-this-meeting",
      class: {
        mobile: _vm.isMobileWebMode,
        "vvd-scheme-alternate": !_vm.isMobileWebMode,
      },
      attrs: { "data-cy": "rate-this-meeting" },
    },
    [
      !_vm.showFinalThankYouMessage && !_vm.showNegativeDetailsSection
        ? _c("div", { staticClass: "card main-card" }, [
            _c(
              "h2",
              {
                staticClass: "rate-title",
                attrs: { "data-cy": "rate-this-meeting-title" },
              },
              [
                _vm._v(
                  " " + _vm._s(_vm.$t("rate_this_meeting.rate_title")) + " "
                ),
              ]
            ),
            !_vm.isMobileWebMode
              ? _c(
                  "p",
                  {
                    staticClass: "rate-description",
                    attrs: { "data-cy": "rate-this-meeting-description" },
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("rate_this_meeting.rate_description")) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "thumbs-section" },
              [
                _c("vwc-icon-button", {
                  staticClass: "thumb-btn",
                  attrs: {
                    "data-cy": "thumbs-up",
                    icon: "thumbs-up-solid",
                    layout: _vm.isMobileWebMode ? "filled" : "outlined",
                    enlarged: !_vm.isMobileWebMode,
                  },
                  on: { click: _vm.setPositiveRating },
                }),
                _c("vwc-icon-button", {
                  staticClass: "thumb-btn",
                  attrs: {
                    "data-cy": "thumbs-down",
                    icon: "thumbs-down-solid",
                    layout: _vm.isMobileWebMode ? "filled" : "outlined",
                    enlarged: !_vm.isMobileWebMode,
                  },
                  on: { click: _vm.setNegativeRating },
                }),
              ],
              1
            ),
          ])
        : _vm.showFinalThankYouMessage
        ? _c(
            "div",
            {
              staticClass: "card main-card thank-you",
              attrs: { "data-cy": "rate-this-meeting-thank-you" },
            },
            [
              _vm.isBranded
                ? _c("vwc-icon", {
                    staticClass: "thank-you-icon",
                    attrs: {
                      type: "waving-solid",
                      connotation: "cta",
                      size: "large",
                    },
                  })
                : _c("img", {
                    staticClass: "thank-you-icon",
                    attrs: {
                      src: _vm.isMobileWebMode
                        ? "/img-celebration-mobile.svg"
                        : "/img-celebration.svg",
                    },
                  }),
              _c(
                "h2",
                {
                  staticClass: "thank-you-title",
                  attrs: { "data-cy": "rate-this-meeting-thank-you-title" },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("rate_this_meeting.thank_you_title")) +
                      " "
                  ),
                ]
              ),
              _c(
                "h1",
                {
                  staticClass: "thank-you-description",
                  attrs: {
                    "data-cy": "rate-this-meeting-thank-you-description",
                  },
                },
                [_vm._v(" " + _vm._s(_vm.thankYouDescription) + " ")]
              ),
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "card choices-step",
              attrs: { "data-cy": "rate-this-meeting-choices-window" },
            },
            [
              !_vm.isMobileWebMode
                ? _c(
                    "div",
                    { staticClass: "choices-icons" },
                    [
                      _c("v-icon", {
                        staticClass: "choices-icon",
                        attrs: { iconName: "Vlt-icon-device-cloud" },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "h4",
                {
                  staticClass: "negative-title",
                  attrs: { "data-cy": "rate-this-meeting-neg-title" },
                },
                [_vm._v(" " + _vm._s(_vm.negativeTitle) + " ")]
              ),
              _vm.showDetailsSection
                ? _c("div", { staticClass: "negative-description" }, [
                    _c(
                      "label",
                      {
                        staticClass: "Vlt-label",
                        attrs: {
                          "data-cy": "rate-this-meeting-neg-description",
                        },
                      },
                      [_vm._v(" " + _vm._s(_vm.negativeDescription) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "Vlt-textarea",
                        attrs: { "data-cy": "rate-this-meeting-textarea" },
                      },
                      [
                        _c("textarea", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.descriptionIssue,
                              expression: "descriptionIssue",
                            },
                          ],
                          staticClass: "details-textarea",
                          attrs: { rows: "7", maxlength: "10000" },
                          domProps: { value: _vm.descriptionIssue },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) {
                                return
                              }
                              _vm.descriptionIssue = $event.target.value
                            },
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._l(_vm.options, function (option, index) {
                    return _c(
                      "v-checkbox",
                      {
                        key: index,
                        staticClass: "reason",
                        attrs: {
                          inverted: _vm.isMobileWebMode,
                          "data-cy": "rate-this-meeting-option-" + index,
                        },
                        model: {
                          value: option.checked,
                          callback: function ($$v) {
                            _vm.$set(option, "checked", $$v)
                          },
                          expression: "option.checked",
                        },
                      },
                      [_vm._v(" " + _vm._s(option.label) + " ")]
                    )
                  }),
              _c(
                "div",
                {
                  staticClass: "rate-buttons",
                  attrs: { "data-cy": "rate-buttons" },
                },
                [
                  _vm.showDetailsSection
                    ? _c("vwc-button", {
                        staticClass: "rate-button",
                        attrs: {
                          "data-cy": "back-button",
                          label: _vm.$t("rate_this_meeting.back_button"),
                          layout: "outlined",
                        },
                        on: { click: _vm.back },
                      })
                    : _c("vwc-button", {
                        staticClass: "rate-button",
                        attrs: {
                          "data-cy": "cancel-button",
                          label: _vm.$t("rate_this_meeting.cancel_button"),
                          layout: "outlined",
                        },
                        on: { click: _vm.cancelRating },
                      }),
                  _c("vwc-button", {
                    staticClass: "rate-button submit-button",
                    attrs: {
                      "data-cy": "submit-button",
                      label: _vm.$t("rate_this_meeting.submit_button"),
                      connotation: _vm.isMobileWebMode ? "primary" : "cta",
                      layout: "filled",
                      disabled: !_vm.isAnyOptionSelected && _vm.options.length,
                    },
                    on: { click: _vm.submit },
                  }),
                ],
                1
              ),
            ],
            2
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }