<template>
  <ParticipantView
    :class="{
      screenshare: isScreenshare,
      'has-video': hasVideo,
      'has-raised-hand': isParticipantRaisingHand,
      mirrored,
      tall: isStreamTall
    }"
    :style="{ ...streamAspectRatioVariables }"
    :participantId="streamParticipant.participantId"
    :participantType="streamParticipant.type"
    :participantProfilePicture="streamParticipant.profilePicture"
    :stream="stream"
    :preferred="preferred"
    :canBePinned="canBePinned"
    :canShowContent="canShowVideo"
    :isLoading="isSubscriberReconnecting"
    :displayName="displayName"
    :isMainParticipant="isMainStream"
    :isPinned="isPinned"
    :showSpeakerIndication="showSpeakerIndication"
    :speakerIndicationSize="speakerIndicationSize"
    :isBeRightBackOn="isBeRightBackOn"
    :iconNextToDisplayName="iconNextToDisplayName"
    @pinToggled="setIsPinned"
  >
    <template #content>
      <VideoStream
        v-show="canShowVideo"
        :stream="stream"
        :preferred="preferred"
        :subscriberStatus="subscriberStatus"
        :isPageVisible="isPageVisible"
        :preferredFitMode="preferredFitMode"
        :canShowVideo="canShowVideo"
        :isMainStream="isMainStream"
      />

      <VideoDisabledBanner
        v-if="
          layoutMode !== LAYOUT_MODE_TYPES.GRID &&
          isMainStream &&
          hasBadQuality &&
          hasVideo &&
          !minimizedMode
        "
        class="video-disabled-issue-message"
      />

      <transition name="reactions">
        <img
          v-if="showReactionOnVideoStream"
          class="reaction-img"
          :src="activeReactionSrc"
        />
      </transition>
    </template>
    <template #prepended-name-icons>
      <div v-if="isSubscriberReconnecting" class="subscriber-reconnecting-icon">
        <vwc-icon
          v-tooltip.top="subscriberReconnectingTooltip"
          type="warning-solid"
          class="icon"
        />
      </div>

      <div
        v-if="!stream.isPublisher && hasBadQuality"
        class="low-connectivity-icon"
      >
        <vwc-icon type="wifi-solid" class="icon" />
      </div>
    </template>
  </ParticipantView>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import ParticipantView from './ParticipantView';
import VideoStream from './VideoStream';
import { isScreenshareStream } from '@/helpers/meeting-helpers';
import {
  LAYOUT_MODE_TYPES,
  SUBSCRIBER_STATUS,
  ANALYTICS
} from '@/consts/global-consts';
import analytics from '@/services/analytics-service';
import { formatPhoneDisplayInternational } from '@/helpers/global-helpers';
import VideoDisabledBanner from '@/components/banners/VideoDisabledBanner.vue';
import { reactionOptions } from '@/helpers/meeting-helpers';

export default {
  components: {
    ParticipantView,
    VideoStream,
    VideoDisabledBanner
  },

  props: {
    stream: {
      type: Object,
      required: true
    },
    subscriberStatus: {
      type: String,
      default: ''
    },
    isPageVisible: {
      type: Boolean,
      default: true
    },
    preferred: {
      type: Object,
      required: true
    },
    preferredFitMode: {
      type: String,
      default: 'contain'
    }
  },

  data() {
    return {
      LAYOUT_MODE_TYPES
    };
  },

  computed: {
    ...mapState([
      'contacts',
      'minimizedMode',
      'isVideoEnabled',
      'unsubscribedVideoStreamIds',
      'pinnedStreamId',
      'participantsVolumes',
      'mainStreamId'
    ]),
    ...mapState('layout', ['layoutMode']),
    ...mapState('reactions', ['reactions']),
    ...mapState('raiseHand', ['raisedHands']),
    ...mapState('beRightBack', ['beRightBackStatuses']),
    ...mapState('watchTogether/ongoing', ['isWatchTogetherActive']),
    ...mapGetters([
      'isMobileWebMode',
      'participants',
      'activeParticipants',
      'isInitializingPublisher',
      'dominantSpeakerStreamId'
    ]),
    ...mapGetters('mobile', ['isBackCameraSelected']),

    iconNextToDisplayName() {
      if (!this.isScreenshare && !this.stream.hasAudio) {
        return 'mic-mute-solid';
      }
      return '';
    },

    subscriberReconnectingTooltip() {
      return {
        hideOnTargetClick: false,
        content: this.$t('ot_participant_view.subscriber_reconnecting_tooltip'),
        'open-group': 'a'
      };
    },

    showSpeakerIndication() {
      let allowIndicationInLayout;

      switch (this.layoutMode) {
        case LAYOUT_MODE_TYPES.SPEAKER:
          allowIndicationInLayout =
            this.mainStreamId === this.stream.streamId &&
            (this.mainStreamId !== this.dominantSpeakerStreamId ||
              (!this.stream.isPublisher && this.minimizedMode)) &&
            !this.isMobileWebMode;
          break;
        case LAYOUT_MODE_TYPES.GRID:
          allowIndicationInLayout = this.mainStreamId === this.stream.streamId;
          break;
        case LAYOUT_MODE_TYPES.DOMINANT:
          allowIndicationInLayout = false;
          break;
      }

      return (
        this.stream.hasAudio &&
        this.activeParticipants.length > 2 &&
        allowIndicationInLayout
      );
    },

    speakerIndicationSize() {
      const vol = this.participantsVolumes[this.stream.participantId];
      if (vol < 1) {
        return 0;
      }
      return Math.floor(vol / 2 + 2);
    },

    formattedPSTNNumber() {
      if (!this.streamParticipant) {
        return '';
      }
      const contact = this.contacts.find(
        (contact) =>
          contact.extensionNumber === this.streamParticipant.displayName
      );
      return (
        contact?.displayName ||
        formatPhoneDisplayInternational(this.streamParticipant.displayName)
      );
    },

    displayName() {
      if (this.streamParticipant.type === 'Pstn') {
        return this.formattedPSTNNumber;
      } else if (this.isScreenshare) {
        return this.$t('ot_participant_view.share_screen_display_name', {
          displayName: this.streamParticipant.displayName
        });
      }
      return this.streamParticipant.displayName;
    },

    isScreenshare() {
      return isScreenshareStream(this.stream);
    },

    canBePinned() {
      return (
        !this.isScreenshare ||
        this.layoutMode === LAYOUT_MODE_TYPES.GRID ||
        (this.layoutMode === LAYOUT_MODE_TYPES.SPEAKER && !this.isMainStream)
      );
    },

    isPinned() {
      return this.stream.streamId === this.pinnedStreamId;
    },

    isMainStream() {
      // When a watch-together video is shown, only pinned streams may be main streams
      // As for whiteboard, pinning streams is not yet supported
      if (this.isWatchTogetherActive && !this.isPinned) {
        return false;
      }

      return this.stream.streamId === this.dominantSpeakerStreamId;
    },

    canShowVideo() {
      return (
        !this.isUnsubscribedToVideo &&
        ((this.hasVideo &&
          !this.hasBadQuality &&
          !this.isSubscriberReconnecting) ||
          (this.stream.isPublisher &&
            this.isInitializingPublisher &&
            this.isVideoEnabled))
      );
    },

    streamParticipant() {
      return this.stream ? this.participants[this.stream.participantId] : null;
    },

    streamAspectRatioVariables() {
      if (
        !this.isMobileWebMode ||
        !this.hasVideo ||
        !this.stream.videoDimensions
      ) {
        return {};
      }

      return {
        '--stream-aspect-ratio': this.streamAspectRatio,
        '--stream-aspect-ratio-inverted': 1 / this.streamAspectRatio
      };
    },

    streamAspectRatio() {
      if (!this.stream || !this.stream.videoDimensions) {
        return 4 / 3;
      }

      const streamWidth = this.stream.videoDimensions.width;
      const streamHeight = this.stream.videoDimensions.height;
      return streamWidth / streamHeight;
    },

    mirrored() {
      return (
        this.stream.isPublisher &&
        !(this.isMobileWebMode && this.isBackCameraSelected)
      );
    },

    hasVideo() {
      return (
        this.stream &&
        this.stream.hasVideo &&
        (!this.stream.isPublisher || this.isVideoEnabled)
      );
    },

    /**
     * Whether the stream's height is greater than its width
     */
    isStreamTall() {
      return this.streamAspectRatio < 1;
    },

    isSubscriberReconnecting() {
      return (
        this.subscriberStatus === SUBSCRIBER_STATUS.RETRYING ||
        this.subscriberStatus === SUBSCRIBER_STATUS.FAILED
      );
    },

    activeReaction() {
      return this.reactions.find(
        (reaction) => reaction.from === this.stream.connectionId
      );
    },

    isParticipantRaisingHand() {
      return (
        this.stream.participantId in this.raisedHands && !this.isScreenshare
      );
    },

    activeReactionSrc() {
      return this.activeReaction
        ? reactionOptions()[this.activeReaction.type].path
        : '';
    },

    showReactionOnVideoStream() {
      if (!this.activeReaction || this.isScreenshare) {
        return false;
      }

      const allowFloatingAnimation = reactionOptions()[this.activeReaction.type]
        .allowFloatingAnimation;
      return (
        this.layoutMode === LAYOUT_MODE_TYPES.GRID ||
        this.minimizedMode ||
        !allowFloatingAnimation
      );
    },

    hasBadQuality() {
      return this.stream?.badQuality;
    },

    isUnsubscribedToVideo() {
      return this.unsubscribedVideoStreamIds[this.stream?.streamId];
    },

    isBeRightBackOn() {
      const connectionId = this.stream.connectionId;
      return connectionId in this.beRightBackStatuses;
    }
  },

  methods: {
    ...mapActions(['pinVideoStream']),

    setIsPinned(isPinned) {
      this.pinVideoStream(isPinned ? this.stream.streamId : '');
      analytics.trackEvent(ANALYTICS.PINNED_PARTICIPANT);
    }
  }
};
</script>

<style scoped>
.has-video {
  order: -1;
}

.has-raised-hand:not(.screenshare):not(.my-stream) {
  order: -2;
}

.has-raised-hand.has-video {
  order: -3 !important;
}

.screenshare {
  order: -4;
}

.my-stream {
  order: -5;
}

.video-disabled-issue-message {
  top: 24px;
}

.mirrored >>> video {
  transform: scale(-1, 1);
  transform-origin: 50% 50%;
}

.reaction-img {
  position: absolute;
  top: 5%;
  right: 5%;
  z-index: 20;
  max-height: 96px;
  max-width: 96px;
  width: 35%;
  pointer-events: none;
}

.reactions-leave-active {
  animation: bounce-in 10s;
}

@keyframes bounce-in {
  0% {
    transform: scale(0.3) rotate(-360deg);
  }
  8% {
    transform: scale(1) rotate(0deg);
  }
  95% {
    transform: scale(1) rotate(0deg);
  }
  100% {
    transform: scale(0) rotate(360deg);
    opecity: 0;
  }
}
.low-connectivity-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--vvd-color-alert);
  border-radius: 50%;
  aspect-ratio: 1;
  color: var(--vvd-color-on-alert);
  height: 20px;
  width: 20px;
}
.subscriber-reconnecting-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--vvd-color-warning);
  border-radius: 50%;
  aspect-ratio: 1;
  color: var(--vvd-color-on-warning);
  height: 20px;
  width: 20px;
}

vwc-icon.icon {
  --vvd-icon-size: 12px;
}
</style>
