<template>
  <div class="more-menu-container">
    <vwc-icon-button
      ref="moreOptionsMenuButton"
      data-cy="more-options-menu-toggle"
      icon="more-vertical-solid"
      connotation="primary"
      dense
      :layout="outlined ? 'outlined' : 'ghost'"
      @click="toggleMoreOptionsMenuVisibility"
    />
    <vwc-menu
      ref="moreOptionsMenu"
      menuCorner="START"
      corner="BOTTOM_LEFT"
      :open="isMoreOptionsMenuOpen"
      @closed="isMoreOptionsMenuOpen = false"
    >
      <div v-for="(list, index) in moreOptionsLists" :key="index">
        <vwc-list-item
          v-for="item in list"
          :key="item.id"
          graphic="icon"
          @click="optionSelected(item)"
        >
          <vwc-text
            data-cy="menu-option-label"
            font-face="body-2"
            :connotation="item.isCritical ? 'alert' : 'primary'"
            >{{ item.label }}</vwc-text
          >

          <vwc-icon
            slot="graphic"
            size="small"
            :connotation="item.isCritical ? 'alert' : 'primary'"
            :type="item.iconName"
          />
        </vwc-list-item>

        <li
          v-if="index < moreOptionsLists.length - 1"
          class="lists-divider"
          role="separator"
        />
      </div>
    </vwc-menu>
  </div>
</template>

<script>
export default {
  name: 'MoreOptionsMenu',

  props: {
    moreOptionsLists: {
      type: Array,
      default: () => []
    },
    outlined: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isMoreOptionsMenuOpen: false
    };
  },

  mounted() {
    this.$refs.moreOptionsMenu.anchor = this.$refs.moreOptionsMenuButton;
  },

  methods: {
    toggleMoreOptionsMenuVisibility() {
      this.isMoreOptionsMenuOpen = !this.isMoreOptionsMenuOpen;
    },

    optionSelected(item) {
      this.isMoreOptionsMenuOpen = false;
      this.$emit('optionSelected', item);
    }
  }
};
</script>

<style scoped>
.more-menu-container {
  position: relative;
}
</style>
