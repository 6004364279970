var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.minimizedMode && !_vm.isMobileWebMode
    ? _c(
        "div",
        {
          directives: [
            {
              name: "observe-keyboard",
              rawName: "v-observe-keyboard.prevent.esc",
              value: _vm.closeOnEsc,
              expression: "closeOnEsc",
              modifiers: { prevent: true, esc: true },
            },
            {
              name: "observe-keyboard",
              rawName: "v-observe-keyboard.f11",
              value: _vm.toggleFullscreen,
              expression: "toggleFullscreen",
              modifiers: { f11: true },
            },
            {
              name: "observe-keyboard",
              rawName: "v-observe-keyboard.prevent.cmd.shift.f",
              value: _vm.toggleFullscreen,
              expression: "toggleFullscreen",
              modifiers: { prevent: true, cmd: true, shift: true, f: true },
            },
          ],
          staticClass: "meeting-titlebar",
          class: {
            "mac-titlebar": _vm.showMacTitlebar,
            "black-bg": _vm.blackBgMode,
          },
          on: { dblclick: _vm.onMaximize },
        },
        [
          _c("div", { staticClass: "action-bar-drag-region" }),
          _c(
            "transition",
            { attrs: { name: "fade" } },
            [
              _vm.isAppRunning
                ? _c("MainScreenActionsBar", {
                    staticClass: "action-bar Vlt-white",
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm.showWindowsTitlebar
            ? _c("FramelessTitlebar", {
                staticClass: "title-bar",
                attrs: {
                  black: _vm.blackButtonMode,
                  isMaximized: _vm.isWindows && _vm.isMaximized,
                },
                on: {
                  close: _vm.onClose,
                  minimize: _vm.onMinimize,
                  maximize: _vm.onMaximize,
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }