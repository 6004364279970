<template>
  <div class="mic-indication" :style="volumeStyle">
    <svg
      class="volume-svg"
      viewBox="173.328 51.005 13 16"
      :style="{ fill: color }"
    >
      <path
        d="M 5.5 9.6 C 5.5 13.134 8.41 16 12 16 C 15.59 16 18.5 13.134 18.5 9.6 L 18.5 6.4 C 18.5 2.866 15.59 0 12 0 C 8.41 0 5.5 2.866 5.5 6.4 L 5.5 9.6 Z"
        transform="matrix(1, 0, 0, 1, 167.827957, 51.005409)"
      ></path>
    </svg>
  </div>
</template>

<script>
import throttle from 'lodash.throttle';

const UPDATE_VOLUME_STYLE_THROTTLE_INTERVAL = 120;

export default {
  name: 'MicVolumeIndication',

  props: {
    maxHeight: {
      type: Number,
      default: 14
    },

    volume: {
      type: Number,
      default: 0
    },

    color: {
      type: String,
      default: '#30a849'
    }
  },

  data() {
    return {
      volumeStyle: {
        height: '0px'
      },
      throttledUpdateVolumeStyle: throttle(
        this.updateVolumeStyle,
        UPDATE_VOLUME_STYLE_THROTTLE_INTERVAL
      )
    };
  },

  watch: {
    volume() {
      requestAnimationFrame(this.throttledUpdateVolumeStyle);
    }
  },

  methods: {
    updateVolumeStyle() {
      if (!this.volume || this.volume < 0.25) {
        this.volumeStyle = {
          height: '0px'
        };
      }
      let volumeHeight = this.maxHeight;
      if (this.volume <= 6) {
        // 0 < volumeHeight < maxHeight
        volumeHeight = this.volume * (this.maxHeight / 6);
      }

      this.volumeStyle = {
        height: `${volumeHeight}px`
      };
    }
  }
};
</script>

<style scoped>
.mic-indication {
  position: absolute;
  height: 0px;
  z-index: 2;
  overflow: hidden;
  pointer-events: none;
}

.volume-svg {
  position: absolute;
  bottom: 0;
}
</style>
