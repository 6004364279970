import Vue from 'vue';

export default {
  SET_IS_CAPTIONS_ENABLED: (state, isEnabled) =>
    (state.isCaptionsEnabled = isEnabled),

  SET_IS_LIVE_TRANSCRIPTION_ENABLED: (state, isEnabled) =>
    (state.isLiveTranscriptionEnabled = isEnabled),

  SET_IS_LIVE_TRANSCRIPTION_PAUSED: (state, isPaused) =>
    (state.isLiveTranscriptionPaused = isPaused),

  SET_ACTIVE_CAPTIONS_ID: (state, activeCaptionsId) =>
    (state.activeCaptionsId = activeCaptionsId),

  SET_IS_LOADING_CAPTIONS: (state, isLoading) =>
    (state.isLoadingCaptions = isLoading),

  SET_IS_LOADING_LIVE_TRANSCRIPTION: (state, isLoading) =>
    (state.isLoadingLiveTranscription = isLoading),

  UPDATE_PARTICIPANT_CAPTIONS_BY_INDEX: (state, { updatedCaption, index }) => {
    Vue.set(state.currentCaptionsList, index, {
      ...state.currentCaptionsList[index],
      ...updatedCaption
    });
  },

  ADD_NEW_PARTICIPANT_CAPTIONS: (state, captions) => {
    state.currentCaptionsList.push(captions);
  },

  CLEAR_CAPTION: (state) => {
    state.currentCaptionsList = [];
  },

  REMOVE_OLDEST_CAPTION: (state) => {
    state.currentCaptionsList.shift();
  },

  ADD_CAPTIONS_HISTORY_BLOCK: (state, captionBlock) => {
    state.captionsHistory.push(captionBlock);
  },

  UPDATE_LAST_HISTORICAL_CAPTION_BLOCK: (state, caption) => {
    const lastCaptionToUpdate =
      state.captionsHistory[state.captionsHistory.length - 1];
    lastCaptionToUpdate.updatedTime = caption.updatedTime;
    lastCaptionToUpdate.texts.push({
      text: caption.text,
      isComplete: caption.isComplete
    });
  },

  UPDATE_IS_UPDATE_IS_CAPTIONS_ENABLED_IN_PROGRESS: (state, isInProgress) => {
    state.isUpdateIsCaptionsEnabledInProgress = isInProgress;
  },

  UPDATE_IS_SELF_SUBSCRIBING_IN_PROGRESS: (state, isInProgress) => {
    state.isSelfSubscribingInProgress = isInProgress;
  }
};
