/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-maximise-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.2 6.3L0 10.5V0h10.5L6.3 4.2l3.75 3.75-2.1 2.1L4.2 6.3zm13.5-2.1L13.5 0H24v10.5l-4.2-4.2-3.75 3.75-2.1-2.1L17.7 4.2zm-7.65 11.85l-2.1-2.1L4.2 17.7 0 13.5V24h10.5l-4.2-4.2 3.75-3.75zm3.9 0l2.1-2.1 3.75 3.75 4.2-4.2V24H13.5l4.2-4.2-3.75-3.75z"/>'
  }
})
