/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-vonage-2-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1 1.25a.75.75 0 00-.687 1.052l6.559 14.915a.892.892 0 001.637-.01l2.181-5.136a.75.75 0 00-.006-.601L6.28 1.691a.75.75 0 00-.684-.442H1zm6.678 14.075L2.15 2.75h2.963l4.07 9.036-1.504 3.54zM18.75 1.25H23a.75.75 0 01.692 1.04l-.283.676a2038.63 2038.63 0 01-3.092 7.346c-1.726 4.08-3.577 8.417-3.987 9.205-.372.716-.907 1.508-1.61 2.128-.707.625-1.618 1.105-2.714 1.105H8.024a.775.775 0 01-.124-1.54c.515-.085.871-.248 1.214-.547.37-.323.752-.83 1.263-1.67.19-.313.75-1.522 1.512-3.248a553.604 553.604 0 002.553-5.898c.888-2.08 1.748-4.114 2.387-5.629.179-.424.407-.933.606-1.375.155-.346.293-.65.373-.843.192-.382.403-.75.942-.75zm.014 2.216v.001c-.168.409-.365.888-.553 1.334-.639 1.516-1.5 3.552-2.39 5.635a555.209 555.209 0 01-2.56 5.915c-.738 1.671-1.346 3.002-1.602 3.422-.352.579-.683 1.07-1.028 1.477h1.376c.63 0 1.202-.271 1.72-.729.524-.462.955-1.085 1.272-1.695.374-.72 2.19-4.97 3.937-9.098a2372.647 2372.647 0 002.937-6.978H19.06l-.296.716z" _fill="#131415"/>'
  }
})
