/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-stack': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.257 1.071a.5.5 0 00-.514 0l-7.5 4.5a.5.5 0 000 .858l7.5 4.5a.5.5 0 00.514 0l7.5-4.5a.5.5 0 000-.858l-7.5-4.5zM8 9.917L1.472 6 8 2.083 14.528 6 8 9.917zm-5.743-.388a.5.5 0 10-.514-.858l-1.5.9a.5.5 0 000 .858l7.5 4.5a.5.5 0 00.514 0l7.5-4.5a.5.5 0 000-.858l-1.5-.9a.5.5 0 10-.514.858l.785.471L8 13.917 1.472 10l.785-.471z"/>'
  }
})
