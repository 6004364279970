/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-dollar-star': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.696.5L12 2.327 8.304.5 6.336 4.153l-4.032.721.576 4.086L0 11.94l2.88 2.981-.576 4.086 4.032.721 1.968 3.653L12 21.555l3.696 1.826 1.968-3.653 4.032-.721-.576-4.086 2.88-2.98-2.88-2.98.576-4.087-4.032-.72L15.696.5zm-3.52 10.8c1.438.257 3.078.55 3.078 2.489 0 1.585-1.093 2.678-2.807 2.833v1.345h-1.196v-1.39c-1.572-.26-2.75-1.307-2.93-2.788l1.571-.272c.136 1.106.97 1.74 2.141 1.74 1.003 0 1.636-.466 1.636-1.242 0-.973-1.014-1.17-2.148-1.388l-.012-.002-.03-.006c-1.435-.277-3.061-.59-3.061-2.53 0-1.617 1.144-2.568 2.833-2.71V6.047h1.196V7.41c1.552.227 2.47 1.197 2.665 2.568l-1.559.265c-.116-.918-.718-1.507-1.92-1.507-.978 0-1.624.395-1.624 1.184 0 .977 1.002 1.17 2.121 1.371l.046.008z"/>'
  }
})
