/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-pentool': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.9 8.1l-2-2-4-4-2-2c-.2-.1-.6-.1-.8 0-.1.2-.1.6 0 .8l1.4 1.4L2.4 4c-.2.1-.4.2-.4.4l-2 11v.1c0 .1 0 .3.1.4.2.1.3.1.5.1l11-2c.2 0 .3-.2.4-.4l1.8-6.2 1.4 1.4c.2.2.5.2.7 0 .1-.1.1-.5 0-.7zm-4.8 5L2 14.7l4-4c.3.2.6.3 1 .3 1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2c0 .4.1.7.3 1l-4 4L3 4.9l6.4-1.8L13 6.7l-1.9 6.4zM6 9c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1c-.3 0-.5-.1-.7-.3-.2-.2-.3-.4-.3-.7z"/>'
  }
})
