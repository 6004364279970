var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vwc-textfield",
    {
      directives: [
        {
          name: "focus",
          rawName: "v-focus",
          value: _vm.focus,
          expression: "focus",
        },
      ],
      ref: "textField",
      attrs: {
        tabindex: "0",
        connotation: _vm.connotation,
        disabled: _vm.disabled,
        outlined: _vm.outlined,
        dense: _vm.dense,
        value: _vm.value,
        label: _vm.label,
        required: _vm.required,
        autoValidate: _vm.autoValidate,
        pattern: _vm.pattern,
        validationMessage: _vm.validationMessage,
        maxLength: _vm.maxLength,
        icon: _vm.icon,
        readOnly: _vm.readOnly,
        placeholder: _vm.placeholder,
        helper: _vm.helper,
        autocomplete: _vm.autocomplete,
        appearance: _vm.appearance,
      },
      on: {
        focus: function ($event) {
          return _vm.$emit("focus")
        },
        blur: function ($event) {
          return _vm.$emit("blur")
        },
        input: function ($event) {
          return _vm.$emit("input", $event.target.value)
        },
        keyup: function ($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
          ) {
            return null
          }
          return _vm.$emit("submit", $event.target.value)
        },
      },
    },
    [
      _vm.actionSlotFunction
        ? _c("vwc-icon-button", {
            attrs: {
              slot: "action",
              disabled: _vm.isActionDisabled,
              icon: "message-sent-line",
            },
            on: { click: _vm.actionSlotFunction },
            slot: "action",
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }