/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-rocket': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1.1 12.1C.3 12.9 0 16 0 16s3.1-.3 3.9-1.1.8-2 0-2.8-2-.8-2.8 0z"/><circle pid="1" cx="9" cy="7" r="1"/><path pid="2" d="M16 .5c0-.1 0-.3-.1-.4-.1-.1-.3-.1-.4-.1-2.9.1-5.2 1-7 2.2-2.2-.8-4.7-.4-6.4 1.3-.4.4-.7.8-.9 1.2-.1.2-.1.5.1.6l2.1 2.1-.2.2c-.3.4-.4.8-.5 1-.1.1-.1.2-.2.3V9l.5.2-.5-.2c-.1.2 0 .4.1.5l3.9 3.9c.1.1.4.2.5.1l-.2-.5.2.5h.1c.1 0 .2-.1.3-.1l1-.5c.1 0 .2-.1.3-.2l2.1 2.1c.2.2.4.2.6.1.4-.2.8-.5 1.2-.9 1.7-1.7 2.2-4.2 1.4-6.4 1-1.9 1.8-4.2 2-7.1zM15 1c-.1 1.5-.5 2.8-1 4l-3-3c1.1-.5 2.5-.8 4-1zM2.3 4.9c.2-.2.3-.5.6-.7 1.2-1.2 3-1.7 4.6-1.3-.9.6-1.5 1.2-2.1 1.8-.6.6-1.1 1.3-1.5 1.8L2.3 4.9zm9.5 8.3c-.2.2-.4.4-.7.6l-1.6-1.6c.6-.4 1.2-.9 1.8-1.5.6-.6 1.2-1.2 1.8-2 .4 1.5-.1 3.2-1.3 4.5zm-1.2-3.3c-.8.7-1.5 1.3-2.2 1.7-.2.1-.4.3-.6.4-.4.2-.7.4-.9.5L3.5 9.1c.2-.3.3-.6.5-.9.4-.8 1.1-1.8 2.1-2.8 1-1 2.3-2.1 3.9-2.9L13.5 6c-.8 1.6-1.8 2.9-2.9 3.9z"/>'
  }
})
