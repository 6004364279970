/**
 * Helps keep track of the estimated timeInVideo of the owner of the video.
 *
 * The owner of the video updates an exact value of timeInVideo when they play/pause,
 * and this service simply calculates the estimated live time by compensating for the
 * time diff between the last update and now.
 */
class VideoOwnerTrackingService {
  constructor() {
    this.reset();
    this.stateChangedEventCallback = null;
  }

  reset() {
    this.lastKnownTimeInVideo = 0;
    this.lastUpdateTime = null;
    this.isPaused = true;
  }

  handleStateUpdate({ isVideoPlaying, timeInVideo }) {
    this.lastKnownTimeInVideo = timeInVideo;
    this.lastUpdateTime = Date.now();
    const wasPaused = this.isPaused;
    this.isPaused = !isVideoPlaying;

    if (this.isPaused !== wasPaused) {
      this.emitStateChangedEvent();
    }
  }

  extrapolateTimeInVideo() {
    if (this.isPaused) {
      return this.lastKnownTimeInVideo;
    }

    const timeSinceLastUpdate = this.lastUpdateTime
      ? (Date.now() - this.lastUpdateTime) / 1000
      : 0;
    return this.lastKnownTimeInVideo + timeSinceLastUpdate;
  }

  emitStateChangedEvent() {
    if (this.stateChangedEventCallback) {
      this.stateChangedEventCallback();
    }
  }

  onStateChanged(callback) {
    this.stateChangedEventCallback = callback;
  }

  clearStateChangedListener() {
    this.stateChangedEventCallback = null;
  }
}

export default new VideoOwnerTrackingService();
