var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popper",
    {
      ref: "popper",
      staticClass: "toggle-button-popper",
      attrs: {
        trigger: "clickToOpen",
        "visible-arrow": false,
        forceShow: _vm.flashing || _vm.isMouseOver,
        options: { placement: "top" },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value:
                (_vm.hoverTooltip && _vm.isMouseOver) ||
                (_vm.popperText && _vm.flashing),
              expression:
                "(hoverTooltip && isMouseOver) || (popperText && flashing)",
            },
          ],
          staticClass: "popper",
        },
        [_vm._v(" " + _vm._s(_vm.popperText) + " ")]
      ),
      _c("ControlBarButton", {
        staticClass: "toggle-button",
        class: {
          active: _vm.active,
          flashing: _vm.flashing,
          inactive: !_vm.active,
        },
        attrs: {
          slot: "reference",
          iconName: _vm.iconName,
          disabled: _vm.disabled,
          transparent: _vm.transparent,
        },
        on: { click: _vm.onClick },
        nativeOn: {
          animationend: function ($event) {
            $event.stopPropagation()
            return _vm.onAnimationEnd($event)
          },
          mouseenter: function ($event) {
            return _vm.onMouseOver($event)
          },
          mouseleave: function ($event) {
            return _vm.onMouseLeave($event)
          },
        },
        slot: "reference",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }