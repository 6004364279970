import { vbcApiGw } from '@/apis/vbc-gw';

export function getUserSettings(modules = []) {
  return vbcApiGw.get('/customization/users/me', {
    'axios-retry': { retries: 3 },
    params: {
      modules: `[${modules.join(',')}]`
    }
  });
}

export function updateUserSettings(settings) {
  return vbcApiGw.patch(`/customization/users/me`, settings);
}
