import Vue from 'vue';
import throttle from 'lodash.throttle';
import MeetingManager from '@/services/meeting-manager-service';
import analytics from '@/services/analytics-service';
import { REACTION_OPTIONS, ANALYTICS } from '@/consts/global-consts';
import { APP_SIGNALS } from '@/consts/global-consts';
import debounce from 'lodash.debounce';
import soundsPlayer from '@/services/sounds-player';
import { reactionOptions } from '@/helpers/meeting-helpers';
import { isValidReactionForReactionsCounter } from '@/helpers/global-helpers';

const REACTION_THROTTLE_WAIT_TIME = 1000;
let reactionsCounter = 0;

/** TEMP - clap sounds */
let clapsCounter = 0;
let invalidateClapTimers = [];
let lastClapPlayingTime;
const MIN_CLAPS_FOR_SOUNDS = 5;
const MIN_CLAPS_FOR_LONG_SOUNDS = 9;
const CLAP_VALIDITY_MILLISECONDS = 2500;
const MIN_MILLISECONDS_BETWEEN_CLAP_SOUNDS = 15 * 1000; // 15 sec

function isReactionValid(reaction) {
  return (
    !!reaction.from && !!reaction.type && !!reactionOptions()[reaction.type]
  );
}

export default {
  init: ({ dispatch }) => {
    MeetingManager.onSignal(APP_SIGNALS.REACTION, (event) => {
      dispatch('addReaction', {
        id: reactionsCounter,
        from: event.from.id,
        type: event.data
      });
      reactionsCounter++;
    });
  },

  addReaction: ({ getters, commit, dispatch }, reaction) => {
    // Ignore reactions when the tab is not focused, otherwise the animations are optimized by the browser
    // and appear buggy
    if (document.hidden) {
      return;
    }

    if (!isReactionValid(reaction)) {
      return;
    }

    if (
      !getters.allowExperimentalReactions &&
      reactionOptions()[reaction.type].experimental
    ) {
      return;
    }

    commit('ADD_REACTION', reaction);
    // Add the reaction and then immediately remove it so we can animate only the "leave" element state
    Vue.nextTick(() => commit('REMOVE_REACTION'));
    dispatch('playReactionSound', reaction);
  },

  sendReaction: throttle(
    ({ dispatch, rootState }, reactionType) => {
      analytics.trackEvent(ANALYTICS.REACTION_SENT, { type: reactionType });
      if (
        rootState.reactionsCounter.isReactionsCounterModalOpen &&
        isValidReactionForReactionsCounter(reactionType)
      ) {
        dispatch(
          'reactionsCounter/sendUpdateReactionsCounterSignal',
          {
            reactionId: reactionType
          },
          { root: true }
        );
      }

      const reactionSignal = {
        type: APP_SIGNALS.REACTION,
        data: reactionType
      };
      return MeetingManager.sendSignal(reactionSignal);
    },
    REACTION_THROTTLE_WAIT_TIME,
    { trailing: false }
  ),

  playReactionSound: ({ dispatch, rootGetters }, reaction) => {
    const pinCode = rootGetters.roomPinCode;
    const pinCodesWhitelist = [
      '838227947',
      '262822303',
      '631592576',
      '475547107'
    ];
    const participantDisplayName = rootGetters.myParticipant.displayName;

    if (
      // Reaction sounds are not enabled in production
      window.isProduction ||
      // In fact, they should only be played in specific rooms
      !pinCodesWhitelist.includes(pinCode.toString()) ||
      // And never will they play for an Ayelet
      participantDisplayName.toLowerCase().includes('ayelet')
    ) {
      return;
    }

    if (reaction.type === REACTION_OPTIONS.CLAP.value) {
      clapsCounter++;
      // Remove this clap from the counter after some time
      invalidateClapTimers.push(
        setTimeout(() => {
          clapsCounter--;
        }, CLAP_VALIDITY_MILLISECONDS)
      );
      dispatch('playClapsSound');
    }
  },

  // Debounce playing the clap sounds to allow the counter to accumulate and go beyond the long-sound threshold
  playClapsSound: debounce(({ rootState }) => {
    // Clap sounds will only play if there have been multiple clap reactions recently
    if (
      clapsCounter < MIN_CLAPS_FOR_SOUNDS ||
      Date.now() - lastClapPlayingTime < MIN_MILLISECONDS_BETWEEN_CLAP_SOUNDS
    ) {
      return;
    }

    // Long clap sounds will only play if there have been many clap reactions recently
    const shouldPlayLongSound = clapsCounter >= MIN_CLAPS_FOR_LONG_SOUNDS;
    console.log(
      shouldPlayLongSound
        ? `Playing long clapping sound for ${clapsCounter} claps`
        : `Playing short clapping sound for ${clapsCounter} claps`
    );
    const random = Math.random();

    if (shouldPlayLongSound) {
      if (random >= 0.5) {
        soundsPlayer.playLongNormalClapping(rootState.selectedSpeakerId);
      } else {
        soundsPlayer.playLongRythmicClapping(rootState.selectedSpeakerId);
      }
    } else {
      if (random >= 0.85) {
        soundsPlayer.playShortExcitedClappingScreaming(
          rootState.selectedSpeakerId
        );
      } else if (random >= 0.5) {
        soundsPlayer.playShortExcitedClapping();
      } else {
        soundsPlayer.playShortNormalClapping();
      }
    }

    lastClapPlayingTime = Date.now();
    invalidateClapTimers.forEach((timer) => {
      clearTimeout(timer);
    });
    invalidateClapTimers = [];
    clapsCounter = 0;
  }, 800)
};
