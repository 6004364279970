/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cloud-upload': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.5 6v8.667h-1V6h1z"/><path pid="1" d="M8 5.293L10.707 8 10 8.707l-2-2-2 2L5.293 8 8 5.293z"/><path pid="2" d="M2.207 5.716A5.496 5.496 0 017.667.833a5.496 5.496 0 015.435 4.698 3.165 3.165 0 01-.436 6.302h-2.5v-1h2.5a2.167 2.167 0 10-.013-4.331l-.471.003-.031-.47a4.496 4.496 0 00-4.484-4.202 4.497 4.497 0 00-4.49 4.282l-.017.37-.359.09a2.161 2.161 0 00.532 4.258h2.5v1h-2.5A3.167 3.167 0 01.167 8.667c0-1.354.851-2.498 2.04-2.95z"/>'
  }
})
