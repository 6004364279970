var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "popper",
    {
      ref: "popper",
      staticClass: "audio-button-popper",
      attrs: {
        trigger: "focus",
        "visible-arrow": false,
        disabled: !_vm.showToggleAudioButtonPopper,
        forceShow: _vm.showToggleAudioButtonPopper,
        options: { placement: "top-start" },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showToggleAudioButtonPopper,
              expression: "showToggleAudioButtonPopper",
            },
          ],
          staticClass: "popper",
          class: _vm.popperClass,
        },
        [
          _vm.showWatchTogetherMuteIndication
            ? _c("WatchTogetherMuteIndication", {
                on: { close: _vm.closeWatchTogetherMuteIndication },
              })
            : _vm.muteIndication
            ? _c("MuteIndication", { on: { close: _vm.closePopper } })
            : _vm._e(),
        ],
        1
      ),
      _vm._t("default", null, { slot: "reference" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }