/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calendar-add-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 3h3c.9 0 1.5.6 1.5 1.5v18c0 .9-.6 1.5-1.5 1.5h-21C.6 24 0 23.4 0 22.5v-18C0 3.6.6 3 1.5 3h3V1.5C4.5.6 5.1 0 6 0h1.5C8.4 0 9 .6 9 1.5V3h6V1.5c0-.9.6-1.5 1.5-1.5H18c.9 0 1.5.6 1.5 1.5V3zM3 21h18V9H3v12zm10.5-4.5h3v-3h-3v-3h-3v3h-3v3h3v3h3v-3z"/>'
  }
})
