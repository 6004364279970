/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-slack-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.018 15.164c0 1.418-1.09 2.509-2.509 2.509-1.418 0-2.509-1.091-2.509-2.51 0-1.418 1.09-2.508 2.51-2.508h2.508v2.509zM6.327 15.164c0-1.419 1.091-2.51 2.51-2.51 1.418 0 2.508 1.091 2.508 2.51v6.327c0 1.418-1.09 2.509-2.509 2.509-1.418 0-2.509-1.09-2.509-2.51v-6.326z" _fill="#E01E5A"/><path pid="1" d="M8.836 5.018c-1.418 0-2.509-1.09-2.509-2.509C6.327 1.091 7.418 0 8.837 0c1.418 0 2.508 1.09 2.508 2.51v2.508H8.836zM8.836 6.327c1.418 0 2.51 1.091 2.51 2.51 0 1.418-1.091 2.509-2.51 2.509H2.51C1.091 11.346 0 10.255 0 8.836c0-1.418 1.09-2.509 2.51-2.509h6.326z" _fill="#36C5F0"/><path pid="2" d="M18.982 8.836c0-1.418 1.09-2.509 2.509-2.509 1.418 0 2.509 1.091 2.509 2.51 0 1.418-1.09 2.509-2.51 2.509h-2.508v-2.51zM17.673 8.836c0 1.418-1.091 2.51-2.51 2.51-1.418 0-2.508-1.091-2.508-2.51V2.51c0-1.418 1.09-2.509 2.509-2.509 1.418 0 2.509 1.09 2.509 2.51v6.326z" _fill="#2EB67D"/><path pid="3" d="M15.164 18.982c1.418 0 2.509 1.09 2.509 2.509 0 1.418-1.091 2.509-2.51 2.509-1.418 0-2.508-1.091-2.508-2.51v-2.508h2.509zM15.164 17.673c-1.419 0-2.51-1.091-2.51-2.51 0-1.418 1.091-2.508 2.51-2.508h6.327c1.418 0 2.509 1.09 2.509 2.509 0 1.418-1.091 2.509-2.51 2.509h-6.326z" _fill="#ECB22E"/>'
  }
})
