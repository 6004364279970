/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-home': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.3.1c-.2-.1-.4-.1-.6 0L4 3.1V1.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v2.4L.2 6.1c-.2.2-.3.5-.1.7.2.2.5.3.7.1l3-2.4L8 1.1l7.2 5.8c.2.2.5.1.7-.1.2-.2.1-.5-.1-.7L8.3.1zM3 7.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v8c0 .3.2.5.5.5h4c.3 0 .5-.2.5-.5V12h2v3.5c0 .3.2.5.5.5h4c.3 0 .5-.2.5-.5v-8c0-.3-.2-.5-.5-.5s-.5.2-.5.5V15h-3v-3.5c0-.3-.2-.5-.5-.5h-3c-.3 0-.5.2-.5.5V15H3V7.5zm3-2c0-.3.2-.5.5-.5h3c.3 0 .5.2.5.5v3c0 .3-.2.5-.5.5h-3c-.3 0-.5-.2-.5-.5v-3zM9 6H7v2h2V6z"/>'
  }
})
