/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-6': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.5 15h-3c-.3 0-.5-.2-.5-.5s.2-.5.5-.5H15v-2.7c0-.1 0-.2-.1-.3-.1-.1-.2-.2-.3-.2l-3.2-.8c-.2 0-.3-.2-.4-.4l-.3-1.8c0-.2.1-.4.3-.5.4-.2.8-.5 1.1-.9.3-.4.4-.9.4-1.4v-.9c0-.6-.2-1.2-.6-1.7-.4-.5-.9-.8-1.5-.9-.4-.1-.9 0-1.3.1-.3.1-.5 0-.6-.3-.1-.3 0-.5.3-.6.6-.2 1.2-.3 1.8-.1.8.2 1.5.6 2.1 1.3.5.6.8 1.5.8 2.3V5c0 .7-.2 1.3-.6 1.9-.3.5-.7.8-1.2 1.1l.2 1.1 2.9.7c.3.1.6.3.8.5.2.3.3.6.3.9v3.2c.1.4-.1.6-.4.6zM10.5 15H.5c-.3 0-.5-.2-.5-.5v-1.7c0-.3.1-.6.3-.9.2-.3.5-.4.8-.5l2.5-.7.1-.8c-.3 0-.7 0-1-.1-.5-.1-1-.4-1.5-.7-.2-.4-.3-.7-.1-.9.7-.9 1-2 .9-3.2 0-1 .4-1.9 1-2.5 1.3-1.3 3.6-1.3 4.9 0C8.6 3.2 9 4.1 9 5c-.1 1.2.2 2.3.9 3.2.2.2.1.5-.1.7-.4.3-.9.6-1.5.7-.3.1-.6.2-1 .2l.1.8 2.5.7c.3.1.6.3.8.5.2.3.3.6.3.9v1.7c0 .4-.2.6-.5.6zM1 14h9v-1.2c0-.1 0-.2-.1-.3-.1-.1-.2-.2-.3-.2l-2.8-.8c-.2-.1-.3-.2-.4-.4l-.1-1.8c0-.2 0-.3.1-.4.1-.1.3-.2.4-.1.4.1.8 0 1.2-.1.3-.1.5-.2.7-.3C8.2 7.3 7.9 6.2 8 5c0-.6-.3-1.3-.7-1.7-.9-.9-2.6-.9-3.5 0-.5.4-.8 1-.8 1.7.1 1.2-.2 2.3-.8 3.4.2.1.5.2.7.3.4.1.8.1 1.2.1.2 0 .3 0 .4.1.2.1.3.2.2.4l-.3 1.8c0 .2-.2.4-.4.4l-2.8.8c-.1 0-.2.1-.3.2.1 0 .1.1.1.3V14z"/>'
  }
})
