/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-voicemail': {
    width: 16,
    height: 16,
    viewBox: '0 0 17 17',
    data: '<path pid="0" d="M13 4.8c-2.1 0-3.8 1.7-3.8 3.8 0 1.1.5 2.1 1.2 2.8H6.5c.7-.7 1.2-1.7 1.2-2.8 0-2.1-1.7-3.8-3.8-3.8S.2 6.4.2 8.5s1.7 3.8 3.8 3.8h9c2.1 0 3.8-1.7 3.8-3.8S15.1 4.8 13 4.8zm-9 6.5c-1.5 0-2.8-1.2-2.8-2.8S2.5 5.9 4 5.9s2.8 1.2 2.8 2.7-1.3 2.7-2.8 2.7zm9 0c-1.5 0-2.8-1.2-2.8-2.8s1.2-2.8 2.8-2.8 2.8 1.2 2.8 2.8-1.3 2.8-2.8 2.8z"/>'
  }
})
