var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "sidebarContainer",
      staticClass: "sidebar-container Vlt-black vvd-scheme-alternate",
      class: {
        "sidebar-collapsed": _vm.isSidebarCollapsed,
        "sidebar-completely-collapsed": _vm.isSidebarCompletelyCollapsed,
        "round-corners": _vm.roundCorners,
      },
    },
    [
      _c(
        "div",
        { staticClass: "sidebar Vlt-bg-white" },
        [
          _vm._t("sidebar-header", [
            _c(
              "div",
              { staticClass: "header" },
              [
                _c(
                  "div",
                  { staticClass: "title-container" },
                  [
                    _c("vwc-icon", {
                      attrs: {
                        type: _vm.icon
                          ? _vm.icon
                          : _vm.sidebars[_vm.activeSidebar].icon,
                      },
                    }),
                    _c("span", { attrs: { "data-cy": "sidebar-title" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.title
                            ? _vm.title
                            : _vm.sidebars[_vm.activeSidebar].title
                        )
                      ),
                    ]),
                  ],
                  1
                ),
                _c("vwc-icon", {
                  staticClass: "dismiss-button",
                  attrs: { type: "close-solid", size: "small" },
                  on: { click: _vm.toggleSidebar },
                }),
              ],
              1
            ),
          ]),
          _vm._t("sidebar-content", [
            _vm.isParticipantsSidebar
              ? _c("Participants")
              : _vm.isMeetingInfoSidebar
              ? _c("MeetingInfo")
              : _vm.isQnA
              ? _c("QuestionsAndAnswers")
              : _vm.isLiveTranscriptionSidebar
              ? _c("LiveTranscription")
              : _vm.isChatSidebar
              ? _c("ChatComponent")
              : _vm._e(),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }