/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-nexmo-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 14.309c-1.31 0-2.4-1.09-2.4-2.4 0-1.31 1.09-2.4 2.4-2.4 1.31 0 2.4 1.09 2.4 2.4 0 1.31-1.09 2.4-2.4 2.4z" _fill="#1875BB"/><path pid="1" d="M18.764 10.164h-1.31l3.273 3.163L24 10.164h-1.636C21.709 5.036 17.236 1 11.782 1 7.309 1 3.6 3.618 1.964 7.327l3.272 1.31c1.091-2.4 3.6-4.146 6.546-4.146 3.49 0 6.327 2.4 6.982 5.673zM12.218 19.327c-3.49 0-6.327-2.4-6.982-5.563h1.528L3.382 10.49 0 13.764h1.527c.764 5.127 5.237 9.054 10.691 9.054 4.473 0 8.291-2.618 9.927-6.436l-3.381-1.31c-1.2 2.51-3.71 4.255-6.546 4.255z" _fill="#4C4D4F"/>'
  }
})
