import * as PhoneNumber from 'awesome-phonenumber';
import { COLORS } from '@/consts/messaging';
import { OS_TYPES, REACTION_COUNTER_OPTIONS } from '@/consts/global-consts';
import * as semver from 'semver';

const colorCache = {};
const timestampCache = {};
const phoneCache = {};

function djb2Code(str) {
  let hash = 5381;

  for (let i = 0; i < str.length; i++) {
    hash = (hash << 5) + hash + str.charCodeAt(i); /* Hash * 33 + c */
  }

  // In the original code, it's without abs
  return Math.abs(hash);
}

export function isElectron() {
  // Renderer process
  return !!window.Electron;
}

function detectBrowserName() {
  const browsers = ['Chrome', 'Firefox', 'Edge', 'Safari', 'Opera', 'IE'];
  return browsers.find((browserName) => window[`is${browserName}`]);
}

export const browserName = detectBrowserName();

export function detectBrowserLanguage(defaultLanguage = 'en-US') {
  return navigator?.language || navigator?.browserLanguage || defaultLanguage;
}

export const userLanguage = detectBrowserLanguage();

export function getColor(str, colorsPalette) {
  const colors = colorsPalette || COLORS;
  if (!str) {
    return colors[0];
  }
  if (!colorCache[str]) {
    colorCache[str] = colors[djb2Code(str) % colors.length];
  }
  return colorCache[str];
}

export function formatTime(
  timestamp,
  format = {
    hour: '2-digit',
    minute: '2-digit'
  }
) {
  if (!timestampCache[timestamp]) {
    const date = new Date(timestamp);
    const formatted = date.toLocaleDateString(userLanguage, format);
    timestampCache[timestamp] = formatted
      .substring(formatted.indexOf(' ') + 1)
      .toLowerCase();
  }

  return timestampCache[timestamp];
}

export function formatDuration(totalSeconds) {
  const hours = Math.floor(totalSeconds / 3600);
  let minutes = Math.floor((totalSeconds - hours * 3600) / 60);
  let seconds = Math.floor(totalSeconds - hours * 3600 - minutes * 60);

  seconds = seconds < 10 ? `0${seconds}` : seconds;
  if (hours > 0) {
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes}:${seconds}`;
  } else if (minutes > 0) {
    return `${minutes}:${seconds}`;
  }
  return `0:${seconds}`;
}

export function getRegionCodeFromLocale(locale) {
  if (locale.indexOf('-') >= 0) {
    return locale.split('-')[1];
  }

  if (locale.indexOf('_') >= 0) {
    return locale.split('_')[1];
  }

  return locale;
}

export function formatPhone(phone, locale, extensionLocale = null) {
  if (!locale) {
    locale = window.locale;
  }

  if (!phoneCache[locale]) {
    phoneCache[locale] = {};
  }

  if (phoneCache[locale][phone]) {
    return phoneCache[locale][phone];
  }

  phoneCache[locale][phone] = { display: '', e164: '', isValid: false };

  const currentRegionCode = locale.substr(locale.length - 2);
  if (!phone || !currentRegionCode) {
    return phoneCache[locale][phone];
  }

  let formatted;
  formatted = PhoneNumber(phone, currentRegionCode);
  if (formatted.isValid()) {
    // If the input number cotains international prefix, the region code can be a lie so we validate it again.
    const numberRegionCode = PhoneNumber(
      formatted.getNumber('international')
    ).getRegionCode();
    phoneCache[locale][phone] = {
      display:
        currentRegionCode === numberRegionCode
          ? formatted.getNumber('national')
          : formatted.getNumber('international'),
      e164: formatted.getNumber('e164'),
      isValid: true
    };
    return phoneCache[locale][phone];
  }

  formatted = PhoneNumber(phone);
  // The ** test handles dynamic caller id numbers
  if (!formatted.isValid() && phone.indexOf('**') === -1) {
    formatted = PhoneNumber(`+${phone}`);
  }

  if (formatted.isValid()) {
    const display =
      extensionLocale &&
      formatted.getRegionCode() === getRegionCodeFromLocale(extensionLocale)
        ? formatted.getNumber('national')
        : formatted.getNumber('international');

    phoneCache[locale][phone] = {
      display,
      e164: formatted.getNumber('e164'),
      isValid: true
    };
  } else {
    phoneCache[locale][phone] = { display: phone, e164: phone, isValid: false };
  }

  return phoneCache[locale][phone];
}

export function formatPhoneDisplayInternational(phone, extensionLocale = null) {
  // There is no such locale as international.
  return formatPhone(phone, 'international', extensionLocale).display;
}

export function getBrowserLocale() {
  return (
    navigator.languages?.[0] ||
    navigator.userLanguage ||
    navigator.language ||
    navigator.browserLanguage ||
    'en-US'
  );
}

export function isRTLLanguage(s) {
  const ltrChars =
    'A-Za-z\u00C0-\u00D6\u00D8-\u00F6\u00F8-\u02B8\u0300-\u0590\u0800-\u1FFF\u2C00-\uFB1C\uFDFE-\uFE6F\uFEFD-\uFFFF';
  const rtlChars = '\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC';
  // eslint-disable-next-line no-misleading-character-class
  const rtlDirCheck = new RegExp(`^[^${ltrChars}]*[${rtlChars}]`);

  return rtlDirCheck.test(s.trim());
}

export function wait(timeout) {
  return new Promise((resolve) => setTimeout(resolve, timeout));
}

export const isIPad = (() =>
  (navigator.userAgent.indexOf('iPad') > -1 ||
    navigator.userAgent.indexOf('Macintosh') > -1) &&
  'ontouchend' in window)();

export const isAndroidMobileDevice = (() => {
  return /Android/i.test(navigator.userAgent);
})();

export const isAppleMobileDevice = (() => {
  return isIPad || /iPhone|iPad|iPod/i.test(navigator.userAgent);
})();

export const isOthersMobileDevice = (() => {
  return /Windows Phone|IEMobile|webOS|Mobile|Opera Mini|Blackberry/i.test(
    navigator.userAgent
  );
})();

export const isMobileDevice = (() => {
  return isAndroidMobileDevice || isAppleMobileDevice || isOthersMobileDevice;
})();

export function compareVersions(version1, version2) {
  version1 = version1 || '0.0.0';
  version2 = version2 || '0.0.0';
  // major * 10^6 + minor * 10^3 + patch
  // example: 2.30.1 => 2030001
  const getVersionValue = (version) =>
    version
      .split(/\.|-/)
      .slice(0, 3)
      .reduce(
        (acc, part, i) => acc + Math.pow(10, (2 - i) * 3) * parseInt(part),
        0
      );

  return getVersionValue(version1) - getVersionValue(version2);
}

function buildUint8Array(str, ret) {
  for (let i = 0; i < str.length; i++) {
    ret[i] = str.charCodeAt(i);
  }
  return ret;
}

export function convertJsonToUint8Array(json) {
  const str = JSON.stringify(json, null, 0);
  const ret = new Uint8Array(str.length);
  return buildUint8Array(str, ret);
}

export function convertStringToUint8Array(str, buffer) {
  const ret = new Uint8Array(buffer);
  return buildUint8Array(str, ret);
}

export function isMinimalNativeVersion(minVersion) {
  if (isElectron()) {
    const electronVersion = window.Electron.version || '0.0.0';
    return !semver.gt(minVersion, electronVersion);
  }
  return false;
}

export function copyPropsExistingOnOriginalObject(originalObj, newObj = {}) {
  const result = {};

  for (const [itemKey, itemValue] of Object.entries(originalObj)) {
    if (typeof itemValue === 'object') {
      result[itemKey] = copyPropsExistingOnOriginalObject(
        originalObj[itemKey],
        newObj[itemKey]
      );
    } else {
      if (newObj[itemKey] !== undefined) {
        result[itemKey] = newObj[itemKey];
      } else {
        result[itemKey] = originalObj[itemKey];
      }
    }
  }

  return result;
}

function detectOS() {
  let name = OS_TYPES.UNKNOWN;
  let arch = 32;

  if (isMobileDevice) {
    if (isAppleMobileDevice) {
      name = isIPad ? OS_TYPES.IPADOS : OS_TYPES.IOS;
    } else if (isAndroidMobileDevice) {
      name = OS_TYPES.ANDROID;
    } else {
      name = OS_TYPES.MOBILE_UNKNOWN;
    }
  } else if (navigator) {
    const appVersion = navigator.appVersion;
    if (appVersion) {
      if (appVersion.indexOf('Win') !== -1) {
        name = OS_TYPES.WINDOWS;
        if (
          navigator.userAgent.indexOf('WOW64') !== -1 ||
          navigator.userAgent.indexOf('Win64') !== -1
        ) {
          arch = 64;
        }
      } else if (appVersion.indexOf('Mac') !== -1) {
        name = OS_TYPES.MAC;
        arch = 64;
      } else if (appVersion.indexOf('X11') !== -1) {
        name = OS_TYPES.UNIX;
      } else if (appVersion.indexOf('Linux') !== -1) {
        name = OS_TYPES.LINUX;
      }
    }
  }

  return { name, arch };
}

export const OS = detectOS();

export const isNativeOSSupported =
  (OS.name === 'MacOS' || OS.name === 'Windows') && OS.arch === 64;

export function getUserAgentDetails() {
  return navigator.userAgent || '';
}

export function focusWindow() {
  if (isElectron() && window.Electron.focusMeetingsWindow) {
    window.Electron.focusMeetingsWindow();
  } else {
    window.focus();
  }
}

export function showNotification({
  title,
  body,
  callback,
  iconUrl,
  requireInteraction = false
}) {
  // window.focus isn't working on Windows
  // We don't want to show notification if focus is not supported
  if (
    isElectron() &&
    OS.name === 'Windows' &&
    !window.Electron.focusMeetingsWindow
  ) {
    return;
  }

  const defaultIconUrl = '/favicon.ico';
  const options = {
    body,
    icon: iconUrl || defaultIconUrl,
    requireInteraction: requireInteraction || false,
    silent: isElectron()
  };
  const notification = new Notification(title, options);
  notification.onclick = callback || focusWindow;
  return notification;
}

export function isValidReactionForReactionsCounter(reactionType) {
  return (
    Object.values(REACTION_COUNTER_OPTIONS).findIndex(
      (element) => element.value === reactionType
    ) !== -1
  );
}

export async function downloadFile(
  content,
  fileType,
  fileName,
  extension,
  openDialogBox
) {
  // Create a new Blob object with the content
  const fileBlob = new Blob([content], { type: fileType });
  const fileFullName = `${fileName}${extension}`;

  // Not all the browsers support the File System API
  // It's used for opening save file dialog box
  if (!isElectron() && window.showSaveFilePicker && openDialogBox) {
    const fileOptions = {
      suggestedName: fileFullName,
      startIn: 'downloads'
    };
    // eslint-disable-next-line no-undef
    const handle = await showSaveFilePicker(fileOptions);

    const writable = await handle.createWritable();

    await writable.write(fileBlob);

    writable.close();
  } else {
    // Create a new URL for the blob object
    const fileUrl = URL.createObjectURL(fileBlob);

    // Create a new anchor element
    const downloadLink = document.createElement('a');

    // Set the download link's href attribute to the URL
    downloadLink.href = fileUrl;

    // Set the download link's download attribute to the element's tag name
    downloadLink.download = fileFullName;

    // Click the download link to trigger the download
    downloadLink.click();
  }
}

export function isHijackScreenShareSupported() {
  return !window.isSafari;
}

export function isContentEditable() {
  const { nodeName, isContentEditable } = document.activeElement;
  if (isContentEditable) return true;

  switch (nodeName) {
    case 'INPUT':
    case 'TEXTAREA':
    case 'SELECT':
    case 'V-CHAT':
      return true;
    case 'VWC-TEXTAREA':
      return true;
  }

  return false;
}

export function setTitleBarData(title, favicon) {
  if (favicon) {
    document.getElementById('favicon').href = favicon;
  }
  document.title = title;
}

export function shouldEnableAudioSource() {
  // The audio source is enabled only for Chrome
  return !isElectron() && window.isChrome;
}
