/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-symfony': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.345 0 0 5.345 0 12c0 6.654 5.345 12 12 12 6.654 0 12-5.346 12-12 0-6.655-5.346-12-12-12zm6.436 6.982c-.545 0-.982-.327-.982-.764 0-.218 0-.327.219-.545.109-.218.109-.218.109-.328 0-.327-.436-.327-.655-.327-1.854.11-2.29 2.51-2.618 4.473l-.218 1.09c.982.11 1.745 0 2.182-.326.545-.328-.11-.764-.11-1.2.11-.437.437-.655.764-.655.437 0 .764.436.764.873 0 .763-.982 1.854-3.055 1.745h-.654l-.437 2.073c-.327 1.636-.763 3.818-2.4 5.673-1.418 1.636-2.836 1.963-3.49 1.963-1.2 0-1.964-.654-1.964-1.418 0-.764.654-1.309 1.2-1.309.654 0 1.09.436 1.09.982 0 .436-.217.545-.326.654-.11 0-.219.11-.219.328 0 .109.11.218.328.218.545 0 .872-.218 1.09-.436 1.2-.982 1.637-2.619 2.182-5.673l.11-.764c.218-.982.436-2.073.763-3.273-.873-.545-1.309-1.309-2.4-1.636-.764-.218-1.2 0-1.527.327-.437.437-.218 1.091.109 1.527l.545.655c.764.873 1.2 1.527.982 2.4-.218 1.418-1.963 2.51-4.036 1.855-1.746-.546-2.073-1.746-1.855-2.4.218-.655.655-.764 1.091-.546.546.11.655.764.546 1.2 0 .11 0 .11-.11.218.11.219 0 .328 0 .546-.109.327.437.654.764.763.764.219 1.527-.218 1.746-.763.109-.655-.328-1.091-.437-1.2l-.763-.764C6.436 11.891 5.673 10.8 6 9.6c.218-.545.545-.982.982-1.31a3.168 3.168 0 012.727-.545c1.2.328 1.745 1.2 2.51 1.746.436-1.2.981-2.4 1.854-3.491.763-.982 1.854-1.636 3.054-1.636 1.2 0 2.182.545 2.182 1.418.11.436-.109 1.09-.873 1.2z"/>'
  }
})
