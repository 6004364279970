import { formatPhoneDisplayInternational } from '@/helpers/global-helpers';

export default {
  currentParticipant: (state, getters, rootState) =>
    rootState.participants[state.currentSpeaker],

  currentParticipantDisplayName: (state, getters, rootState) => {
    const currentParticipant = getters.currentParticipant;
    if (!currentParticipant) {
      return '';
    }
    if (currentParticipant.type === 'Pstn') {
      // search phone number in contacts
      const participantContact = rootState.contacts.find(
        (contact) => contact.extensionNumber === currentParticipant.displayName
      );
      if (participantContact) {
        return participantContact.displayName;
      }

      // return the formatted number
      return formatPhoneDisplayInternational(currentParticipant.displayName);
    }

    return currentParticipant.displayName;
  },

  remainingParticipantsCount: (state, getters, rootState, rootGetters) => {
    // Count the amount of past/current speakers who are still part of the meeting
    const activePastSpeakersCount = rootGetters.activeParticipants.reduce(
      (total, participant) =>
        // Match past speakers
        state.pastSpeakers.indexOf(participant.participantId) >= 0 ||
        // Match current speaker
        state.currentSpeaker === participant.participantId
          ? total + 1
          : total,
      0
    );

    return rootGetters.activeParticipants.length - activePastSpeakersCount;
  },

  pastSpeakersCount: (state) => state.pastSpeakers.length,

  // Gets whether the app user is the current participant in the standup session
  isSelfCurrentParticipant: (state, getters, rootState) => {
    const currentParticipant = getters.currentParticipant;
    if (!currentParticipant) {
      return false;
    }

    return currentParticipant.participantId === rootState.myParticipantId;
  }
};
