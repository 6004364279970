/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-express': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 18.6c-.9.2-1.4 0-1.9-.7-1.1-1.6-2.3-3.2-3.4-4.8-.1-.2-.3-.4-.5-.7-1.4 1.9-2.7 3.6-4 5.5-.5.7-.9.9-1.8.7l5.2-7-4.8-6.3c.8-.2 1.4-.1 1.9.7 1.1 1.6 2.3 3.2 3.6 4.9 1.2-1.6 2.5-3.2 3.6-4.8.5-.7 1-.9 1.8-.7-.6.8-1.2 1.7-1.9 2.5-.8 1.1-1.7 2.2-2.5 3.3-.3.4-.3.6 0 1 1.5 2.1 3.1 4.2 4.7 6.4zM0 11.6c.1-.7.2-1.4.4-2.1 1.1-4.1 5.9-5.8 9.1-3.3 1.9 1.5 2.4 3.6 2.3 6H1.1C.9 16.5 4 19 7.9 17.7c1.4-.5 2.2-1.5 2.6-2.9.2-.7.5-.8 1.2-.6-.3 1.7-1.1 3.1-2.6 4-2.3 1.3-5.6.9-7.3-.9-1-1.1-1.5-2.4-1.6-3.9-.1-.2-.2-.4-.2-.7v-1.1zm1.1-.3h9.7C10.7 8.2 8.8 6 6.2 6c-2.9 0-4.9 2.1-5.1 5.3z"/>'
  }
})
