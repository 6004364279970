/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-key': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M12.5 0a.5.5 0 00-.354.146L6.142 6.151A4.675 4.675 0 005 6a5 5 0 105 5c0-.404-.061-.785-.15-1.142l2.004-2.004A.5.5 0 0012 7.5V5h2.5a.5.5 0 00.354-.146l1-1A.5.5 0 0016 3.5v-3a.5.5 0 00-.5-.5h-3zM6.644 7.064L12.707 1H15v2.293L14.293 4H11.5a.5.5 0 00-.5.5v2.793L8.936 9.356a.5.5 0 00-.125.497c.114.383.189.76.189 1.147a4 4 0 11-4-4c.387 0 .764.075 1.147.189a.5.5 0 00.497-.125zM4 11a1 1 0 112 0 1 1 0 01-2 0zm1-2a2 2 0 100 4 2 2 0 000-4z"/>'
  }
})
