/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-javascript': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0v24h24V0H0zm12.655 20.618c-.437.764-1.091 1.2-1.855 1.418-1.2.218-2.29.11-3.164-.436-.545-.327-.981-.873-1.309-1.636l1.855-1.091s0 .109.109.218c.327.436.436.654.873.873.327.109 1.2.218 1.527-.437.218-.327.109-1.527.109-2.727v-5.782h2.29v6.218c0 1.31.11 2.4-.435 3.382zm9.272-.436c-.763 2.618-5.127 2.727-6.872.982-.328-.437-.546-.655-.764-1.091.982-.546.982-.546 1.854-1.091.437.764.982 1.09 1.746 1.309 1.09.109 2.182-.218 1.964-1.418-.219-.873-2.291-1.2-3.6-2.182-1.31-.982-1.637-3.164-.546-4.473.436-.436 1.091-.764 1.745-.982l.655-.109c1.418-.109 2.182.327 2.836.982.11.218.328.327.546.764-.764.436-.764.436-1.745 1.09-.219-.436-.546-.763-.982-.872-.655-.218-1.418 0-1.528.655-.109.108-.109.327 0 .654.218.545.982.764 1.746 1.09 2.182.874 2.836 1.746 3.054 2.837.218.873 0 1.527-.109 1.855z"/>'
  }
})
