<template>
  <div
    class="control-bar-button"
    :class="{ transparent, disabled, destructive }"
    @click="onClick"
  >
    <v-icon class="button-icon" :iconName="iconName" />
  </div>
</template>

<script>
export default {
  name: 'ControlBarButton',

  props: {
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: ''
    },
    transparent: {
      type: Boolean,
      default: false
    },
    destructive: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    onClick(event) {
      if (this.disabled || !('click' in this.$listeners)) {
        return;
      }

      const clickListener = this.$listeners['click'];
      clickListener(event);
    }
  }
};
</script>

<style scoped>
.control-bar-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 54px;
  height: 54px;
  border-radius: 50%;
  padding: 12px;
  position: relative;
  border: 1px solid #4d4d4d;
  cursor: pointer;
  background: #0d0d0d;
}

.mobile .control-bar-button {
  border-color: #ccc;
  cursor: unset;
}

.control-bar-button.destructive {
  border: none;
  background: #f75959;
}

.control-bar-button.disabled {
  opacity: 0.5;
  cursor: default;
}

.control-bar-button.transparent {
  opacity: 0.5;
}

.control-bar-button .button-icon {
  margin-top: 0;
  width: 16px;
  height: 16px;
  fill: white;
}

.mobile .control-bar-button .button-icon {
  width: 24px;
  height: 24px;
}
</style>
