/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-volume-off-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M1.5 16.5c0 .358.095.669.266.913L17.972 1.207A1.5 1.5 0 0015.6.3L7.05 6H3c-.9 0-1.5.6-1.5 1.5v9zM18 9.621l5.56-5.56a1.5 1.5 0 00-2.12-2.122l-19.5 19.5a1.5 1.5 0 002.12 2.122l4.533-4.532L15.6 23.7c1.05.6 2.4 0 2.4-1.2V9.621z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
