export const capabilities = Object.freeze({
  NEW_BOTTOM_BAR: 'new-bottom-bar-design',
  MUTE_ALL: 'force-mute',
  RECORDING_SOUND_INDICATION: 'recording-sound-indication',
  ADOBE_ANALYTICS_DISABLED: 'adobe-analytics-disabled'
});

export const ALPHA_PREFIX = 'alpha-';

export function getDefaultCapabilities() {
  return [];
}
