var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-screens-wrapper" },
    [
      _vm.showLoadingOverlay
        ? _c("LoadingScreen", {
            staticClass: "loading-screen",
            attrs: { "loader-type-spinner": _vm.loaderTypeSpinner },
          })
        : _vm._e(),
      !_vm.minimizedMode
        ? _c("FlashMessages", { staticClass: "flash-messages" })
        : _vm._e(),
      _vm.isSafari ? _c("SafariNotificationModal") : _vm._e(),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          _vm.isAppReady
            ? _c("AppScreen")
            : _vm.showPreJoinScreen
            ? _c("PreJoin")
            : _vm.showEntranceScreen
            ? _c("EntranceScreen")
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }