import chroma from 'chroma-js';
import { COLOR_TYPES, CSS_VARS_KEYS } from '@/store/whitelabel/consts';
import { setTitleBarData } from '@/helpers/global-helpers';
import DomainConfigTypeEnum from '@/consts/domains-configuration-enum';

/**
 *
 * @param {*} color chroma object color
 * @returns the color type of the color (if it's too dark / light / gray)
 */
const getColorType = (color) => {
  const isWayTooBlack = color.luminance() < 0.015; //1.3:1
  const isWayTooWhite = color.luminance() > 0.75; //1.3:1
  if (isWayTooBlack || isWayTooWhite) return COLOR_TYPES.BLACK_AND_WHITE;

  const chromaticity = color.get('lch.c');
  const isGray = chromaticity < 10;

  const isTooBlack = color.luminance() < 0.1; //3:1
  const isTooWhite = color.luminance() > 0.28; //3:1

  if (isTooBlack) return isGray ? COLOR_TYPES.GRAY_DARK : COLOR_TYPES.DARK;
  if (isTooWhite) return isGray ? COLOR_TYPES.GRAY_LIGHT : COLOR_TYPES.LIGHT;
  return isGray ? COLOR_TYPES.GRAY_NORMAL : COLOR_TYPES.NORMAL;
};

const _getCssVariablesForColor = (color) => {
  const colorType = getColorType(color);

  const darkerVersion = chroma(color.hex()).luminance(0.28);
  const lighterVersion = chroma(color.hex()).luminance(0.1);
  const lightestVersion =
    color.luminance() > 0.5 ? color : chroma(color.hex()).luminance(0.5);
  const preferredTextColor =
    color.luminance() < 0.179 ? chroma('White') : chroma('Black');

  const white = chroma('White');
  const black = chroma('black');

  const cssVars = {};

  switch (colorType) {
    case COLOR_TYPES.NORMAL:
    case COLOR_TYPES.GRAY_NORMAL:
      cssVars[CSS_VARS_KEYS.ENTRANCE_SCREEN_LOGO_BG] = color;
      cssVars[CSS_VARS_KEYS.ENTRANCE_SCREEN_LOGO_COLOR] = white;
      cssVars[CSS_VARS_KEYS.PRIMARY_WHITE_BG] = color;
      cssVars[CSS_VARS_KEYS.PRIMARY_WHITE_BG_TEXT_COLOR] = white;
      cssVars[CSS_VARS_KEYS.SWITCH_PRIMARY_CHECKED_COLOR] = color;
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG] = color;
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_HOVER] = color.darken();
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_CLICKED] = color.darken().darken();
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_TEXT_COLOR] = preferredTextColor;
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_OUTLINED] = color.darken();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG] = color;
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_HOVER] = color.darken();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_CLICKED] = color.darken().darken();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_OUTLINED] = color.darken();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_TEXT_COLOR] = white;
      cssVars[CSS_VARS_KEYS.AVATAR_CIRCLE_BG_COLOR_DARK] = color;
      cssVars[CSS_VARS_KEYS.AVATAR_ICON_FILL_DARK] = white;
      cssVars[CSS_VARS_KEYS.AVATAR_ICON_FILL_LIGHT] = color;
      cssVars[CSS_VARS_KEYS.AVATAR_FILL_ON_DARK_BG] = color;
      cssVars[CSS_VARS_KEYS.EMPTY_STATE_GRADIENT] = color;
      cssVars[CSS_VARS_KEYS.SPEAKER_INDICATION] = color;
      cssVars[CSS_VARS_KEYS.MEETING_PIN_TEXT_COLOR] = color;
      cssVars[CSS_VARS_KEYS.ACTION_BUTTON_CTA_COLOR] = lightestVersion;
      break;
    case COLOR_TYPES.DARK:
    case COLOR_TYPES.GRAY_DARK:
      cssVars[CSS_VARS_KEYS.ENTRANCE_SCREEN_LOGO_BG] = color;
      cssVars[CSS_VARS_KEYS.ENTRANCE_SCREEN_LOGO_COLOR] = white;
      cssVars[CSS_VARS_KEYS.PRIMARY_WHITE_BG] = color;
      cssVars[CSS_VARS_KEYS.PRIMARY_WHITE_BG_TEXT_COLOR] = white;
      cssVars[CSS_VARS_KEYS.SWITCH_PRIMARY_CHECKED_COLOR] = lighterVersion;
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG] = lighterVersion;
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_HOVER] = lighterVersion.darken();
      cssVars[
        CSS_VARS_KEYS.CTA_BLACK_BG_CLICKED
      ] = lighterVersion.darken().darken();
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_OUTLINED] = lighterVersion.darken();
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_TEXT_COLOR] = preferredTextColor;
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG] = color;
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_HOVER] = color.darken();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_CLICKED] = color.darken().darken();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_OUTLINED] = color.darken();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_TEXT_COLOR] = white;
      cssVars[CSS_VARS_KEYS.AVATAR_CIRCLE_BG_COLOR_DARK] = lighterVersion;
      cssVars[CSS_VARS_KEYS.AVATAR_ICON_FILL_DARK] = white;
      cssVars[CSS_VARS_KEYS.AVATAR_ICON_FILL_LIGHT] = color;
      cssVars[CSS_VARS_KEYS.AVATAR_FILL_ON_DARK_BG] = lighterVersion;
      cssVars[CSS_VARS_KEYS.EMPTY_STATE_GRADIENT] = lighterVersion;
      cssVars[CSS_VARS_KEYS.SPEAKER_INDICATION] = lighterVersion;
      cssVars[CSS_VARS_KEYS.MEETING_PIN_TEXT_COLOR] = color;
      cssVars[CSS_VARS_KEYS.ACTION_BUTTON_CTA_COLOR] = lightestVersion;
      break;
    case COLOR_TYPES.LIGHT:
    case COLOR_TYPES.GRAY_LIGHT:
      cssVars[CSS_VARS_KEYS.ENTRANCE_SCREEN_LOGO_BG] = color;
      cssVars[CSS_VARS_KEYS.ENTRANCE_SCREEN_LOGO_COLOR] = white;
      cssVars[CSS_VARS_KEYS.PRIMARY_WHITE_BG] = darkerVersion;
      cssVars[CSS_VARS_KEYS.PRIMARY_WHITE_BG_TEXT_COLOR] = white;
      cssVars[CSS_VARS_KEYS.SWITCH_PRIMARY_CHECKED_COLOR] = darkerVersion;
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG] = color;
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_HOVER] = color.darken();
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_CLICKED] = color.darken().darken();
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_OUTLINED] = color.darken();
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_TEXT_COLOR] = preferredTextColor;
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG] = darkerVersion;
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_HOVER] = darkerVersion.darken();
      cssVars[
        CSS_VARS_KEYS.CTA_WHITE_BG_CLICKED
      ] = darkerVersion.darken().darken();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_OUTLINED] = darkerVersion.darken();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_TEXT_COLOR] = white;
      cssVars[CSS_VARS_KEYS.AVATAR_CIRCLE_BG_COLOR_DARK] = color;
      cssVars[CSS_VARS_KEYS.AVATAR_ICON_FILL_DARK] = white;
      cssVars[CSS_VARS_KEYS.AVATAR_ICON_FILL_LIGHT] = darkerVersion;
      cssVars[CSS_VARS_KEYS.AVATAR_FILL_ON_DARK_BG] = color;
      cssVars[CSS_VARS_KEYS.EMPTY_STATE_GRADIENT] = color;
      cssVars[CSS_VARS_KEYS.SPEAKER_INDICATION] = color;
      cssVars[CSS_VARS_KEYS.MEETING_PIN_TEXT_COLOR] = darkerVersion;
      cssVars[CSS_VARS_KEYS.ACTION_BUTTON_CTA_COLOR] = lightestVersion;
      break;
    case COLOR_TYPES.BLACK_AND_WHITE:
      cssVars[CSS_VARS_KEYS.ENTRANCE_SCREEN_LOGO_BG] = black;
      cssVars[CSS_VARS_KEYS.ENTRANCE_SCREEN_LOGO_COLOR] = white;
      cssVars[CSS_VARS_KEYS.PRIMARY_WHITE_BG] = black;
      cssVars[CSS_VARS_KEYS.PRIMARY_WHITE_BG_TEXT_COLOR] = white;
      cssVars[CSS_VARS_KEYS.SWITCH_PRIMARY_CHECKED_COLOR] = chroma('#06ba77');
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG] = white;
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_HOVER] = white.darken();
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_CLICKED] = white.darken().darken();
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_OUTLINED] = white.darken();
      cssVars[CSS_VARS_KEYS.CTA_BLACK_BG_TEXT_COLOR] = black;
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG] = black;
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_HOVER] = black.brighten();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_CLICKED] = black.brighten().brighten();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_OUTLINED] = black.brighten();
      cssVars[CSS_VARS_KEYS.CTA_WHITE_BG_TEXT_COLOR] = white;
      cssVars[CSS_VARS_KEYS.AVATAR_CIRCLE_BG_COLOR_DARK] = black;
      cssVars[CSS_VARS_KEYS.AVATAR_ICON_FILL_DARK] = white;
      cssVars[CSS_VARS_KEYS.AVATAR_ICON_FILL_LIGHT] = black;
      cssVars[CSS_VARS_KEYS.AVATAR_FILL_ON_DARK_BG] = white;
      cssVars[CSS_VARS_KEYS.EMPTY_STATE_GRADIENT] = white;
      cssVars[CSS_VARS_KEYS.SPEAKER_INDICATION] = white;
      cssVars[CSS_VARS_KEYS.MEETING_PIN_TEXT_COLOR] = black;
      cssVars[CSS_VARS_KEYS.ACTION_BUTTON_CTA_COLOR] = white;
      break;
  }
  cssVars[CSS_VARS_KEYS.AVATAR_CIRCLE_BG_COLOR_LIGHT] = white;
  cssVars[CSS_VARS_KEYS.PARTICIPANT_ROW_ICON] = black;
  cssVars[CSS_VARS_KEYS.PARTICIPANT_ROW_ICON_DISABLED] = chroma('#B3B3B3');
  cssVars[CSS_VARS_KEYS.GRADIENT_DARK] = darkerVersion;
  cssVars[CSS_VARS_KEYS.GRADIENT_LIGHT] = lighterVersion;
  cssVars[CSS_VARS_KEYS.LINK_COLOR_BLACK_BG] = chroma('#85ccf5');
  cssVars[CSS_VARS_KEYS.LINK_COLOR_WHITE_BG] = chroma('#1a0dab');

  Object.keys(cssVars).forEach((key) => {
    cssVars[key] = cssVars[key].hex();
  });
  return cssVars;
};

const originalCssVars = {};

const _saveOriginalCssVariables = () => {
  Object.values(CSS_VARS_KEYS).forEach((key) => {
    originalCssVars[key] = document.documentElement.style.getPropertyValue(key);
  });
};

export default {
  init: () => {
    _saveOriginalCssVariables();
  },

  setTheme: ({ commit, dispatch }, theme) => {
    dispatch('setMainColor', theme.main_color);
    commit('SET_BRAND_TEXT', theme.brand_text);
    commit('SET_SHORT_COMPANY_URL', theme.short_company_url);
    commit('SET_BRAND_IMAGE_COLORED', theme.brand_image_colored);
    commit('SET_BRAND_IMAGE_WHITE', theme.brand_image_white);
    commit('SET_BRANDED_FAVICON', theme.branded_favicon);
    commit('SET_HIDE_POWERED_BY_VONAGE', theme.white_label);
    setTitleBarData('Meetings', theme.branded_favicon);
  },

  resetTheme: ({ commit, dispatch }) => {
    commit('SET_MAIN_COLOR', null);
    commit('SET_CSS_VARIABLES', originalCssVars);
    commit('SET_BRAND_TEXT', null);
    commit('SET_SHORT_COMPANY_URL', null);
    commit('SET_BRAND_IMAGE_COLORED', null);
    commit('SET_BRAND_IMAGE_WHITE', null);
    commit('SET_BRANDED_FAVICON', null);
    commit('SET_HIDE_POWERED_BY_VONAGE', false);
    dispatch('setThemeUrlInLocationBar');
    if (process.env.VUE_APP_DOMAIN_CONFIG === DomainConfigTypeEnum.VONAGE) {
      setTitleBarData('Vonage Meetings', '/favicon.ico');
    } else if (process.env.VUE_APP_DOMAIN_CONFIG === DomainConfigTypeEnum.CSP) {
      setTitleBarData('CommsSetup', '/favicon_csp.ico');
    }
  },

  setColor: ({ dispatch }, colorHex) => {
    if (colorHex === 'random') {
      dispatch('newRandomColor');
      setInterval(() => {
        dispatch('newRandomColor');
      }, 4000);
    } else {
      const color = chroma(colorHex);
      dispatch('setMainColor', color);
    }
  },

  newRandomColor: ({ dispatch }) => {
    const colorHex = '#000000'.replace(/0/g, () =>
      (~~(Math.random() * 16)).toString(16)
    );

    // const color = chroma(colorHex);
    // color.set('lch.l', 0.6); // for checking very light colors
    dispatch('setMainColor', colorHex);
  },

  setMainColor: ({ commit }, mainColor) => {
    commit('SET_MAIN_COLOR', mainColor);
    const color = chroma(mainColor);
    commit('SET_CSS_VARIABLES', _getCssVariablesForColor(color));
  },

  setThemeUrlInLocationBar: ({ state }) => {
    if (!state.shortCompanyUrl) {
      history.replaceState(null, '', location.origin);
    } else {
      const newRoute = `${location.origin}/${state.shortCompanyUrl}`;
      history.replaceState(null, '', newRoute);
    }
  }
};
