var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "report-modal",
      attrs: {
        "data-cy": "report-modal",
        heading: _vm.$t("report_issue_modal.title"),
        "icon-name": "feedback-line",
        close: _vm.hide,
        "min-width": 445,
        "primary-action": _vm.$t("report_issue_modal.submit_button"),
        "secondary-action": _vm.$t("report_issue_modal.cancel_button"),
        open: _vm.showReportIssueModal,
        primaryButtonIcon: "message-sent-solid",
        disablePrimaryButton: _vm.isSubmitDisabled,
        isSmallModal: "",
        primaryButtonConnotation: "primary",
      },
      on: {
        applied: _vm.submit,
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("template", { slot: "content" }, [
        _c(
          "div",
          [
            _c("div", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
            _c("VVDSelect", {
              staticClass: "issue-selector",
              attrs: {
                label: _vm.$t("report_issue_modal.select_label"),
                "selected-value": _vm.selectedIssueType,
                options: _vm.issueTypes,
                dense: "",
                shape: "rounded",
                required: "",
              },
              on: { selectedOption: _vm.setSelectedOption },
            }),
            _c(
              "div",
              { attrs: { "data-cy": "leave-feedback-text" } },
              [
                _c("VVDTextarea", {
                  ref: "textArea",
                  staticClass: "text-area",
                  attrs: {
                    "data-cy": "leave-feedback-text",
                    outlined: "",
                    required: "",
                    rows: 6,
                    label: _vm.$t("report_issue_modal.form_label"),
                    dense: "",
                    resizable: "",
                    autoValidate: "",
                    validationMessage: _vm.$t(
                      "report_issue_modal.textarea_error_message"
                    ),
                  },
                  model: {
                    value: _vm.description,
                    callback: function ($$v) {
                      _vm.description = $$v
                    },
                    expression: "description",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm.isGuest
          ? _c(
              "div",
              [
                _c("label", { staticClass: "Vlt-label email-label" }, [
                  _vm._v(_vm._s(_vm.$t("report_issue_modal.email_label"))),
                ]),
                _c("VTextfield", {
                  attrs: {
                    "data-cy": "email-text-field",
                    outlined: "",
                    placeholder: "myemail@example.com",
                    dense: "",
                    autoValidate: "",
                    pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
                    validationMessage: _vm.$t(
                      "report_issue_modal.email_error_message"
                    ),
                  },
                  model: {
                    value: _vm.email,
                    callback: function ($$v) {
                      _vm.email = $$v
                    },
                    expression: "email",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }