/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-code-snippet': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1.5 1a.5.5 0 00-.5.5v13a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-13a.5.5 0 00-.5-.5h-13zM0 1.5A1.5 1.5 0 011.5 0h13A1.5 1.5 0 0116 1.5v13a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 14.5v-13zm3.146 2.646a.5.5 0 01.708 0l3.5 3.5a.5.5 0 010 .708l-3.5 3.5a.5.5 0 01-.708-.708L6.293 8 3.146 4.854a.5.5 0 010-.708zM7.5 4a.5.5 0 000 1h5a.5.5 0 000-1h-5zm0 7h5a.5.5 0 010 1h-5a.5.5 0 010-1zm2-3.5a.5.5 0 000 1h3a.5.5 0 000-1h-3z"/>'
  }
})
