var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "audio-settings" },
    [
      _vm.isNoAudioMode
        ? _c(
            "vwc-3-note",
            {
              staticClass: "vvd-root vvd-theme-alternate note",
              attrs: {
                connotation: "information",
                icon: "info-solid",
                headline: _vm.$t("audio_settings.no-audio-note-title"),
              },
            },
            [
              _c(
                "div",
                { staticClass: "note-content" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("audio_settings.no-audio-note-content")) +
                      " "
                  ),
                  _c("vwc-button", {
                    staticClass: "enable-audio-btn",
                    attrs: {
                      layout: "outlined",
                      shape: "pill",
                      dense: "",
                      connotation: "primary",
                      label: _vm.$t("audio_settings.no-audio-note-button"),
                    },
                    on: { click: _vm.onEnableAudio },
                  }),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("VVDSelect", {
        attrs: {
          "data-cy": "mic-select",
          label: _vm.$t("audio_settings.select_microphone_label"),
          "selected-value": _vm.currentMicrophone,
          options: _vm.microphoneDevicesItems,
          disabled: _vm.isNoAudioMode || !_vm.hasMicrophoneAccess,
          "max-height": 20,
          dense: "",
          shape: "rounded",
        },
        on: { selectedOption: _vm.onSelectMicrophone },
      }),
      _c("VVDSelect", {
        staticClass: "speaker-select",
        attrs: {
          "data-cy": "speaker-select",
          label: _vm.$t("audio_settings.select_speaker_label"),
          "selected-value": _vm.currentSpeaker,
          options: _vm.speakerDevicesItems,
          disabled: _vm.isNoAudioMode || !_vm.speakerDevicesItems.length,
          "max-height": 20,
          dense: "",
          shape: "rounded",
        },
        on: { selectedOption: _vm.onSelectSpeaker },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }