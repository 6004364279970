/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-8-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M12 6a3 3 0 100-6 3 3 0 000 6zM3 9.75a3 3 0 100-6 3 3 0 000 6zm21-3a3 3 0 11-6 0 3 3 0 016 0zm-12 10.5a4.5 4.5 0 100-9 4.5 4.5 0 000 9zm-5.006 1.8c3.162-.398 6.85-.398 10.012 0 1.862.235 3.244 1.843 3.244 3.719v.483a.748.748 0 01-.75.748h-15c-.414 0-.75-.334-.75-.936v-.295c0-1.876 1.382-3.484 3.244-3.719z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
