<template>
  <div ref="inputTag" data-cy="input-line" class="input-line">
    <vwc-tag
      v-for="(tag, index) in tags"
      :key="index"
      class="tag"
      removable
      removeEventOnly
      shape="rounded"
      :text="tag"
      @remove-tag="removeTag(index)"
    />
    <slot name="input-text">
      <VTextfield
        v-model="newTag"
        class="text-input"
        appearance="ghost"
        dense
        focus
        :placeholder="placeholder"
        @input="filter"
      />
    </slot>
  </div>
</template>

<script>
export default {
  name: 'InputTag',

  props: {
    tags: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      newTag: ''
    };
  },

  watch: {
    tags() {
      this.$nextTick(
        () => (this.$refs.inputTag.scrollTop = this.$refs.inputTag.scrollHeight)
      );
    },

    newTag() {
      return this.filter(this.newTag);
    }
  },

  methods: {
    removeTag(index) {
      this.$emit('remove', index);
    },

    filter(value) {
      this.$emit('filter', value);
    },

    clearText() {
      this.newTag = '';
    }
  }
};
</script>

<style scoped>
.input-line {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-height: 100px;
  border: 1px solid #929292;
  border-radius: 6px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
  flex: 1;
}

.tag {
  padding: 5px;
  cursor: pointer;
}

.text-input {
  padding: 5px 0;
  --vvd-textfield-block-size: 20px;
  cursor: text;
}
</style>
