<template>
  <div class="reactions-container">
    <transition-group name="screenReactions">
      <img
        v-for="reaction in screenReactions"
        :key="reaction.id"
        class="reaction"
        :style="reaction.style"
        :src="iconsTypes[reaction.type].path"
      />
    </transition-group>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { reactionOptions } from '@/helpers/meeting-helpers';

export default {
  name: 'ScreenReactions',

  data() {
    return {
      iconsTypes: reactionOptions()
    };
  },

  computed: {
    ...mapState('reactions', ['reactions']),
    ...mapState('layout', ['isSidebarCollapsed']),

    screenReactions() {
      const screenWidth =
        window.outerWidth - (this.isSidebarCollapsed ? 0 : 360);
      return this.reactions
        .map((reaction) => {
          const percent = Math.random() * 0.35 + 0.05; // Position between 5% - 35% of screen width
          const randomPos = Math.floor(screenWidth * percent);
          return {
            ...reaction,
            style: {
              left: `${randomPos}px`
            }
          };
        })
        .filter(
          (reaction) => reactionOptions()[reaction.type].allowFloatingAnimation
        );
    }
  }
};
</script>

<style scoped>
.reactions-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: visible;
}

.screenReactions-leave-active {
  animation: reaction 7s;
}

.reaction {
  position: absolute;
  min-width: 96px;
  min-height: 96px;
  pointer-events: none;
}

@keyframes reaction {
  0% {
    transform: translateX(0) rotate(0deg);
    opacity: 1;
    bottom: -10%;
  }
  20% {
    transform: translateX(-24px) rotate(-8deg);
  }
  50% {
    transform: translateX(36px) rotate(16deg) scale(1);
    opacity: 1;
  }
  75% {
    transform: translateX(-36px) rotate(-20deg) scale(0.3);
  }
  100% {
    transform: translateX(0px) rotate(0deg) scale(0.1);
    opacity: 0;
    bottom: 100%;
  }
}
</style>
