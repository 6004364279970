<template>
  <div class="main-actions-bar-container unselectable">
    <div v-if="showSeparator" class="separator"></div>
    <!-- left -->
    <div
      v-on-clickaway="closeExperimentalIndicationPopup"
      class="indication-container"
    >
      <vwc-icon-button
        v-show="exclusiveExperimentalMode"
        id="experimental-indication-button"
        v-tooltip.bottom="experimentalIndicationButtonTooltip"
        data-cy="experimental-top-bar-indication"
        class="experimental-indication-icon"
        :class="{ branded: isBranded }"
        icon="flash-2-solid"
        connotation="cta"
        dense
        @click="toggleExperimentalIndicationPopup"
      />
      <vwc-popup
        id="experimental-indication-popup"
        data-cy="experimental-indication-popup"
        anchor="experimental-indication-button"
        :open="isExperimentalPopupOpen"
        arrow
        corner="bottom-start"
      >
        <div
          class="popup-content experimental"
          data-cy="experimental-popup-content"
        >
          <p class="popup-title" data-cy="experimental-popup-title">
            {{ $t('experimental_indication_popup.title') }}
          </p>
          <p class="popup-text" data-cy="experimental-popup-text">
            {{ $t('experimental_indication_popup.subtitle') }}
          </p>
          <vwc-button
            v-if="exclusiveExperimentalMode"
            data-cy="experimental-popup-button"
            class="popup-button"
            :label="$t('experimental_indication_popup.turn_off_button')"
            connotation="primary"
            layout="outlined"
            fullwidth
            dense
            @click="turnOffExperimental"
          />
        </div>
      </vwc-popup>
    </div>

    <div
      v-on-clickaway="closeLockedIndicationPopup"
      class="indication-container"
    >
      <vwc-icon-button
        v-show="isLocked"
        id="lock-indication-button"
        v-tooltip.bottom="meetingLockIndicationButtonTooltip"
        data-cy="lock-top-bar-indication"
        class="lock-indication-icon"
        :icon="isSessionOwner ? 'lock-solid' : 'lock-line'"
        connotation="primary"
        :disabled="!isSessionOwner"
        dense
        @click="toggleLockedIndicationPopup"
      />
      <vwc-popup
        id="lock-indication-popup"
        anchor="lock-indication-button"
        data-cy="lock-indication-popup"
        :open="isLockedPopupOpen"
        arrow
        corner="bottom-start"
      >
        <div class="popup-content locked" data-cy="lock-popup-content">
          <p class="popup-title" data-cy="lock-popup-title">
            {{ $t('locked_indication_popup.title') }}
          </p>
          <p class="popup-text" data-cy="lock-popup-text">
            {{ $t('locked_indication_popup.subtitle') }}
          </p>
          <vwc-button
            v-if="isLocked"
            class="popup-button"
            data-cy="lock-popup-button"
            :label="$t('locked_indication_popup.unlock_button')"
            connotation="primary"
            layout="outlined"
            icon="unlock-solid"
            fullwidth
            dense
            @click="unlockMeeting"
          />
        </div>
      </vwc-popup>
    </div>

    <span
      class="meeting-name"
      data-cy="meeting-name"
      @click="toggleSidebar(SIDEBARS.INFO)"
    >
      {{ roomDisplayName }}</span
    >
    <RecordingIndication
      v-if="isRecording"
      :startTime="recording.startTime"
      :showTimer="screenSizeMediumOrLarger"
      class="recording-indication"
    />
    <vwc-3-badge
      v-if="isSessionCaptionsEnabled"
      class="transcription-indication vvd-root"
      data-cy="transcription-indication"
      connotation="primary"
      appearance="subtle"
      icon="voicemail-transcript-solid"
      shape="pill"
      v-tooltip.bottom="transcribingBadgeTooltip"
      :text="transcriptionBadgeText"
    >
    </vwc-3-badge>
    <span class="spacer"></span>

    <!-- right -->
    <TopBarLayoutsButton class="layout-toggle-container" />
    <div class="separator"></div>
    <LocaleSwitcher v-if="isLocaleSwitcherEnabled" />
    <div
      v-for="sidebarButton in availableSidebarButtons"
      :key="sidebarButton.id"
      v-tooltip.bottom-end="sidebarButton.tooltip"
      class="top-bar-button toggle-sidebar-button"
      :data-cy="sidebarButton.data_cy"
      @click="toggleSidebar(sidebarButton.id)"
      @dblclick.stop
    >
      <vwc-icon-button
        :icon="sidebarButton.icon"
        dense
        :layout="sidebarButton.active ? 'filled' : 'ghost'"
        onmouseup="this.blur()"
      />

      <CounterBadge
        v-if="!sidebarButton.active && sidebarButton.badgeCounter"
        :count="sidebarButton.badgeCounter"
        class="overlapping-counter-badge"
      />
      <StatusBadge
        v-else-if="sidebarButton.status"
        :status="sidebarButton.status"
        class="overlapping-status-badge"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { OS_TYPES, SIDEBARS } from '@/consts/global-consts';
import RecordingIndication from '@/components/RecordingIndication.vue';
import CounterBadge from '@/components/CounterBadge.vue';
import StatusBadge from '@/components/StatusBadge.vue';
import { isElectron, OS } from '@/helpers/global-helpers';
import TopBarLayoutsButton from '@/components/TopBarLayoutsButton.vue';
import LocaleSwitcher from '@/components/LocaleSwitcher';
import analytics from '@/services/analytics-service';
import { ANALYTICS, ANALYTICS_SOURCE } from '@/consts/global-consts';
import { mixin as clickaway } from 'vue-clickaway';

export default {
  name: 'MainScreenActionsBar',

  components: {
    LocaleSwitcher,
    RecordingIndication,
    TopBarLayoutsButton,
    CounterBadge,
    StatusBadge
  },

  mixins: [clickaway],

  data() {
    return {
      isWindows: OS.name === OS_TYPES.WINDOWS,
      SIDEBARS,
      isExperimentalPopupOpen: false,
      isLockedPopupOpen: false
    };
  },

  computed: {
    ...mapState(['isFullScreen', 'isLocked', 'activeSidebar', 'isBranded']),
    ...mapState('layout', ['layoutMode', 'isSidebarCollapsed']),
    ...mapState('recordings', ['recording']),
    ...mapState('qna', ['isQnaSessionActive']),
    ...mapState('messaging', ['unreadMessagesCount']),
    ...mapState('captions', [
      'isLiveTranscriptionEnabled',
      'isLiveTranscriptionPaused'
    ]),
    ...mapGetters([
      'roomDisplayName',
      'isLocaleSwitcherEnabled',
      'isChatOpen',
      'isChatFeatureEnabled',
      'isSessionOwner'
    ]),
    ...mapGetters('recordings', ['isRecording']),
    ...mapGetters('settings', ['exclusiveExperimentalMode']),
    ...mapGetters('qna', ['unseenQuestions']),
    ...mapGetters('captions', ['isSessionCaptionsEnabled']),

    availableSidebarButtons() {
      const sidebarButtons = [
        {
          id: SIDEBARS.LIVE_TRANSCRIPTION,
          icon: 'voicemail-transcript-solid',
          data_cy: 'toggle-live-transcription-btn',
          active: this.isLiveTranscriptionSidebarOpen,
          tooltip: this.liveTranscriptionTooltip,
          available:
            this.isLiveTranscriptionEnabled && this.exclusiveExperimentalMode,
          status: this.isLiveTranscriptionPaused ? 'paused' : 'active'
        },
        {
          id: SIDEBARS.QUESTIONS_AND_ANSWERS,
          icon: 'group-8-solid',
          data_cy: 'toggle-qna-btn',
          active: this.isQnASidebarOpen,
          tooltip: this.qnaButtonTooltip,
          available: this.isQnaSessionActive,
          badgeCounter: this.unseenQuestions.length
        },
        {
          id: SIDEBARS.INFO,
          icon: 'info-solid',
          data_cy: 'toggle-info-btn',
          active: this.isInfoSidebarOpen,
          tooltip: this.meetingInfoButtonTooltip,
          available: true
        },
        {
          id: SIDEBARS.CHAT,
          icon: 'chat-solid',
          data_cy: 'toggle-chat-btn',
          active: this.isChatOpen,
          tooltip: this.chatButtonTooltip,
          available: this.isChatFeatureEnabled,
          badgeCounter: this.unreadMessagesCount
        },
        {
          id: SIDEBARS.PARTICIPANTS,
          icon: 'group-solid',
          data_cy: 'toggle-participants-btn',
          active: this.isParticipantsSidebarOpen,
          tooltip: this.participantsButtonTooltip,
          available: true
        }
      ];

      return sidebarButtons.filter((sidebarButton) => sidebarButton.available);
    },

    isInfoSidebarOpen() {
      return !this.isSidebarCollapsed && this.activeSidebar === SIDEBARS.INFO;
    },

    isParticipantsSidebarOpen() {
      return (
        !this.isSidebarCollapsed && this.activeSidebar === SIDEBARS.PARTICIPANTS
      );
    },

    isQnASidebarOpen() {
      return (
        !this.isSidebarCollapsed &&
        this.activeSidebar === SIDEBARS.QUESTIONS_AND_ANSWERS
      );
    },

    isLiveTranscriptionSidebarOpen() {
      return (
        !this.isSidebarCollapsed &&
        this.activeSidebar === SIDEBARS.LIVE_TRANSCRIPTION
      );
    },

    meetingLockIndicationButtonTooltip() {
      return {
        hideOnTargetClick: true,
        content: this.$t('main_actions_bar.locked_tooltip'),
        'open-group': 'a'
      };
    },

    meetingInfoButtonTooltip() {
      return {
        hideOnTargetClick: false,
        content: this.$t('main_actions_bar.meeting_info_tooltip'),
        'open-group': 'a'
      };
    },

    experimentalIndicationButtonTooltip() {
      return {
        hideOnTargetClick: true,
        content: this.$t('main_actions_bar.experimental_tooltip'),
        'open-group': 'a'
      };
    },

    participantsButtonTooltip() {
      return {
        hideOnTargetClick: true,
        content: this.$t('main_actions_bar.participants_tooltip'),
        'open-group': 'a'
      };
    },

    qnaButtonTooltip() {
      return {
        hideOnTargetClick: true,
        content: this.$t('main_actions_bar.qna_tooltip'),
        'open-group': 'a'
      };
    },

    liveTranscriptionTooltip() {
      return {
        hideOnTargetClick: true,
        content: this.$t('main_actions_bar.live_transcription_tooltip'),
        'open-group': 'a'
      };
    },

    chatButtonTooltip() {
      return {
        hideOnTargetClick: true,
        content: this.$t('main_actions_bar.chat_tooltip'),
        'open-group': 'a'
      };
    },

    transcribingBadgeTooltip() {
      return {
        content: this.$t('main_actions_bar.transcribing_badge_tooltip'),
        'open-group': 'a'
      };
    },

    showSeparator() {
      return (
        isElectron() &&
        window.Electron.minimizeWindow &&
        !this.isWindows &&
        !this.isFullScreen
      );
    },

    transcriptionBadgeText() {
      return this.screenSizeMediumOrLarger
        ? this.$t('live_transcription.transcription_indication')
        : '';
    },

    screenSizeMediumOrLarger() {
      return !this.$screen.xs && !this.$screen.sm;
    }
  },

  methods: {
    ...mapActions([
      'setIsMeetingInfoVisible',
      'setActiveSidebar',
      'setLockMeeting'
    ]),
    ...mapActions('layout', { _toggleSidebar: 'toggleSidebar' }),
    ...mapActions('settings', ['toggleExclusiveExperimentalMode']),

    toggleSidebar(sidebar) {
      if (this.isSidebarCollapsed || this.activeSidebar === sidebar) {
        this._toggleSidebar();
      }
      this.setActiveSidebar(sidebar);
    },

    toggleExperimentalIndicationPopup() {
      this.isExperimentalPopupOpen = !this.isExperimentalPopupOpen;
    },

    closeExperimentalIndicationPopup() {
      this.isExperimentalPopupOpen = false;
    },

    toggleLockedIndicationPopup() {
      this.isLockedPopupOpen = !this.isLockedPopupOpen;
    },

    closeLockedIndicationPopup() {
      this.isLockedPopupOpen = false;
    },

    turnOffExperimental() {
      this.toggleExperimentalIndicationPopup();
      this.toggleExclusiveExperimentalMode();
      analytics.trackEvent(ANALYTICS.EXCLUSIVE_EXPERIMENTAL_MODE, {
        Source: ANALYTICS_SOURCE.EXPERIMENTAL_INDICATION_POPUP,
        State: this.exclusiveExperimentalMode ? 'On' : 'Off'
      });
    },

    unlockMeeting() {
      this.toggleLockedIndicationPopup();
      this.setLockMeeting(false);
    }
  }
};
</script>

<style scoped>
.main-actions-bar-container {
  position: relative;
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 16px;
  z-index: 5;
  background: #0d0d0d;
}

.meeting-name {
  font-weight: 600;
  margin-left: 12px;
  color: white;
  max-height: 100%;
  overflow: hidden;
}

.meeting-name:hover {
  text-decoration: underline;
}

.indication-container {
  position: relative;
}

.top-bar-button {
  margin-left: 8px;
}

.spacer {
  flex: 1;
}

.layout-toggle-container,
.toggle-sidebar-button,
.indication-container {
  -webkit-app-region: no-drag;
}

.layout-toggle-container {
  margin-right: 8px;
}

.main-actions-bar-container .separator {
  width: 0px;
  height: 24px;
  border-left: 1px solid #4d4d4d;
  margin-right: 16px;
}

.recording-indication,
.transcription-indication {
  margin-left: 8px;
}

.overlapping-counter-badge {
  position: absolute;
  transform: translate(18px, -33px);
}

.overlapping-status-badge {
  position: absolute;
  transform: translate(18px, -30px);
}

.popup-content {
  text-align: left;
  padding: 12px 12px 12px 12px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.popup-content.locked {
  min-width: 220px;
}

.popup-content.experimental {
  min-width: 260px;
}

.popup-content .popup-title {
  color: white;
  font-weight: 600;
  height: 16px;
}

.popup-content .popup-text {
  color: white;
  height: 16px;
}

.popup-content .popup-button {
  margin-top: 8px;
}

.lock-indication-icon {
  margin-left: 4px;
}

vwc-icon-button.branded {
  --vvd-color-cta: var(--btn-cta-color-black-bg);
}
</style>
