import MeetingManager from '@/services/meeting-manager-service';
import { PARTICIPANT_STATE_TYPES } from '@/consts/global-consts';
import { APP_SIGNALS } from '@/consts/global-consts';
import store from '@/store';

export default {
  init: ({ dispatch, state }) => {
    store.watch(
      (storeState) => storeState.isMicEnabled || storeState.isVideoEnabled,
      (newValue) => {
        // If user has BRB status on and he turns on camera/mic we will turn off his BRB status
        if (newValue && state.isBeRightBackOn) {
          dispatch('toggleBeRightBackStatus');
        }
      }
    );
    // Handle incoming be right back status
    MeetingManager.onSignal(APP_SIGNALS.BE_RIGHT_BACK, (event) => {
      dispatch('receiveIsBeRightBackOnSignal', event);
    });
  },

  sendIsBeRightBackStatusSignal: (_, { isBeRightBackOn, to = undefined }) => {
    let beRightBackStatusSignal = {
      type: APP_SIGNALS.BE_RIGHT_BACK,
      data: { isBeRightBackOn }
    };

    if (to !== undefined) {
      beRightBackStatusSignal.to = to;
    }

    return MeetingManager.sendSignal(beRightBackStatusSignal);
  },

  receiveIsBeRightBackOnSignal: ({ dispatch }, event) => {
    if (event.data.isBeRightBackOn) {
      dispatch('addBeRightBackStatus', event.from.id);
    } else {
      dispatch('removeBeRightBackStatus', event.from.id);
    }
  },

  toggleBeRightBackStatus: ({ state, commit, dispatch }) => {
    commit('SET_IS_BE_RIGHT_BACK_ON', !state.isBeRightBackOn);
    dispatch('sendIsBeRightBackStatusSignal', {
      isBeRightBackOn: state.isBeRightBackOn
    });
  },

  addBeRightBackStatus: ({ commit }, connectionId) => {
    commit('ADD_BE_RIGHT_BACK_STATUS', connectionId);
  },

  removeBeRightBackStatus: ({ state, commit }, connectionId) => {
    if (connectionId in state.beRightBackStatuses) {
      commit('REMOVE_BE_RIGHT_BACK_STATUS', connectionId);
    }
  },

  handleParticipantStateUpdate: ({ dispatch }, event) => {
    if (event.state === PARTICIPANT_STATE_TYPES.JOINED) {
      dispatch('_handleParticipantJoined', {
        participantId: event.participantId,
        connectionId: event.connectionId
      });
    }
  },

  _handleParticipantJoined: (
    { rootState, dispatch, state },
    { participantId, connectionId }
  ) => {
    if (!state.isBeRightBackOn || participantId === rootState.myParticipantId) {
      return;
    }

    const stream = rootState.streams?.find(
      (stream) => stream?.connectionId === connectionId
    );
    const connection = stream?.connection;

    dispatch('sendIsBeRightBackStatusSignal', {
      isBeRightBackOn: state.isBeRightBackOn,
      to: connection
    });
  }
};
