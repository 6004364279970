/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-youtube': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.376 4.05a3.016 3.016 0 012.122 2.135C24 8.07 24 12 24 12s0 3.93-.502 5.814a3.016 3.016 0 01-2.122 2.136c-1.87.505-9.376.505-9.376.505s-7.505 0-9.376-.505a3.016 3.016 0 01-2.122-2.136C0 15.93 0 12 0 12s0-3.93.502-5.815A3.016 3.016 0 012.624 4.05C4.494 3.545 12 3.545 12 3.545s7.506 0 9.376.505zM15.818 12L9.545 8.43v7.137L15.818 12z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
