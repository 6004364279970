/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-apple': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.36 12.76c0 3.6 3.163 4.8 3.272 4.909 0 .109-.545 1.745-1.636 3.381-.981 1.418-2.072 2.945-3.708 2.945-1.636 0-2.181-.982-3.927-.982-1.854 0-2.4.873-3.926.982-1.636.109-2.836-1.527-3.817-3.054C2.436 17.996.909 12.543 2.982 8.834a6.121 6.121 0 015.017-3.053c1.527 0 3.054 1.09 3.926 1.09.982 0 2.727-1.309 4.581-1.09.763 0 2.945.327 4.363 2.4 0 .108-2.509 1.526-2.509 4.58zm-2.945-8.943c.764-.981 1.31-2.4 1.2-3.817-1.2 0-2.727.763-3.6 1.854-.763.873-1.417 2.29-1.308 3.708 1.418.11 2.836-.763 3.708-1.745z"/>'
  }
})
