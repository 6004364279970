/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-microphone-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 12.7c2.016 0 3.5-1.58 3.5-3.28V6.28C15.5 4.58 14.016 3 12 3S8.5 4.58 8.5 6.28v3.14c0 1.7 1.484 3.28 3.5 3.28zm6.5-3.28c0 3.454-2.925 6.28-6.5 6.28s-6.5-2.826-6.5-6.28V6.28C5.5 2.826 8.425 0 12 0s6.5 2.826 6.5 6.28v3.14z"/><path pid="1" d="M23.85 10.65c.15-.75-.45-1.5-1.2-1.65-.75-.15-1.65.45-1.65 1.2-.75 4.5-4.5 7.8-9 7.8s-8.25-3.3-8.85-7.65c-.15-.9-.9-1.5-1.8-1.35-.75.15-1.35.9-1.2 1.65.75 5.4 5.1 9.45 10.35 10.2V24h3v-3.15c5.25-.6 9.6-4.8 10.35-10.2z"/>'
  }
})
