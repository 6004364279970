/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.9 9.7c-.6-.3-1.7-.6-3-.6s-2.4.3-3 .6c-.2.1-.4.2-.6.4-.8-.3-1.7-.5-2.9-.5-1.7 0-3.1.5-3.9.8-.8.4-1.4 1.3-1.4 2.2v1.9h10.5v-1.9c0-.9-.5-1.7-1.3-2.1.6-.2 1.4-.4 2.5-.4s2.1.3 2.6.5c.4.2.6.5.6.9v.6h-3v1h4v-1.6c.1-.8-.4-1.5-1.1-1.8zm-5.3 3.9H1.1v-.9c0-.6.3-1.1.8-1.3.7-.3 1.9-.7 3.4-.7 1.6 0 2.7.4 3.4.7.5.2.8.8.8 1.3v.9zM5.2 8.5C7 8.5 8.5 7 8.5 5.2S7 2 5.2 2 2 3.5 2 5.2s1.5 3.3 3.2 3.3zm0-5.5c1.2 0 2.2 1 2.2 2.2s-1 2.2-2.2 2.2S3 6.5 3 5.2 4 3 5.2 3zM12 8c1.4 0 2.5-1.1 2.5-2.5S13.4 3 12 3 9.5 4.1 9.5 5.5 10.6 8 12 8zm0-4c.8 0 1.5.7 1.5 1.5S12.8 7 12 7s-1.5-.7-1.5-1.5S11.2 4 12 4z"/>'
  }
})
