/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-compose': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.4.1c-.2-.2-.5-.2-.7 0l-8 8c-.1.1-.2.2-.2.3l-1 3.5c0 .2 0 .4.1.5.1.1.3.2.5.1l3.5-1c.1 0 .2-.1.2-.1l8-8c.2-.2.2-.5 0-.7L13.4.1zM4.7 11.3l.7-2.5L13 1.2 14.8 3l-7.6 7.6-2.5.7zM1 3.5c0-.3.2-.5.5-.5h5c.3 0 .5-.2.5-.5S6.8 2 6.5 2h-5C.7 2 0 2.7 0 3.5v11c0 .8.7 1.5 1.5 1.5h11c.8 0 1.5-.7 1.5-1.5v-5c0-.3-.2-.5-.5-.5s-.5.2-.5.5v5c0 .3-.2.5-.5.5h-11c-.3 0-.5-.2-.5-.5v-11z"/>'
  }
})
