/**
 * Wraps window.postMessage and window.onmessage events to communicate with an embedded iframe
 */
export default class ChildIFrameCommunication {
  constructor(iframeId, origin) {
    this.iframeId = iframeId;
    this.origin = origin;
  }

  sendMessage(message) {
    const iframe = document.getElementById(this.iframeId);
    if (!iframe) {
      return;
    }

    iframe.contentWindow.postMessage(message, '*');
  }

  onMessage(callback) {
    window.addEventListener(
      'message',
      (event) => {
        if (this.origin.includes(event.origin)) {
          callback(event);
        }
      },
      false
    );
  }
}
