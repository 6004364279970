/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-zoho': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M20.157 20.578c-.198-.297-.496-.545-.793-.743a3.285 3.285 0 00-1.34-.397c-.594-.1-1.19-.149-1.784-.1l-7.785.05c.148-.545.595-1.24 1.24-2.082.743-.942 1.784-1.984 3.123-3.223l1.042-.992c.148-.149.396-.397.743-.645 2.083-1.934 3.422-3.52 4.066-4.86.447-.941.744-1.933.943-2.974.099-.595.148-1.14.099-1.736 0-.545-.05-.992-.199-1.339-.148-.446-.347-.744-.595-.942-.297-.248-.694-.397-1.289-.496C17.182.05 16.587 0 15.893 0L6.917.05c-1.09 0-1.934.198-2.479.545a2.057 2.057 0 00-.843 1.29c0 .148-.05.297-.05.446 0 .743.298 1.338.893 1.686.347.198.793.347 1.29.446.346.05.743.099 1.19.099h7.536c-.049.595-.198 1.14-.495 1.636-.298.546-.992 1.29-2.033 2.232-.298.248-.744.644-1.34 1.19-2.33 1.983-4.065 3.719-5.206 5.256-.793 1.041-1.388 2.083-1.785 3.124a8.008 8.008 0 00-.496 1.785c-.05.446-.099.843-.099 1.24 0 .595.1 1.09.198 1.487.15.397.347.695.645.942.248.199.694.298 1.24.397.743.1 1.735.149 3.024.149l8.182-.05c1.438 0 2.48-.198 3.124-.545.496-.248.843-.744.893-1.339 0-.149.05-.297.05-.446.098-.397 0-.744-.199-1.042z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
