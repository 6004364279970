/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-preferences': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" class="st0" d="M14.2 6h9c.5 0 .8-.3.8-.8s-.3-.8-.8-.8h-9c-.4 0-.8.3-.8.8s.4.8.8.8zM9.8 18h-9c-.5 0-.8.3-.8.8s.3.8.8.8h9c.4 0 .8-.3.8-.8s-.4-.8-.8-.8zM23.2 18h-3.8v-3.8c0-.4-.3-.8-.8-.8h-4.5c-.4 0-.8.3-.8.8v9c0 .4.3.8.8.8h4.5c.4 0 .8-.3.8-.8v-3.8h3.8c.4 0 .8-.3.8-.8s-.3-.6-.8-.6zm-5.2.8v3.8h-3V15h3v3.8zM9.8 0H5.2c-.4 0-.7.3-.7.8v3.8H.8c-.5-.1-.8.2-.8.6s.3.8.8.8h3.8v3.8c0 .4.3.8.8.8h4.5c.4 0 .8-.3.8-.8v-9c-.2-.5-.5-.8-.9-.8zM9 9H6V1.5h3V9z"/>'
  }
})
