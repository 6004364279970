import { vbcApiGw } from '@/apis/vbc-gw';
import { USER_TYPE } from '@/consts/user-type';

let busTypeUrlPart;

export function init(domain = USER_TYPE.VBC) {
  busTypeUrlPart = domain === USER_TYPE.VBC ? 'bus' : 'bus-vcp';
}

export function createWSConnection(url) {
  const webSocket = new WebSocket(url);
  webSocket.binaryType = 'arraybuffer';
  return webSocket;
}

export function getBusDetailsAndRegister(
  externalId,
  accountId,
  userId,
  deviceId
) {
  return vbcApiGw.post(
    `/${busTypeUrlPart}/bus/v2/register`,
    {
      accountId,
      userId,
      deviceId,
      extensionsIds: [externalId],
      type: 'ws'
    },
    {
      'axios-retry': { retries: 3 }
    }
  );
}

export function unregisterBusStation(accountId, deviceId) {
  return vbcApiGw.post(`/${busTypeUrlPart}/bus/v2/unregister`, {
    accountId,
    deviceId
  });
}
