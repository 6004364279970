/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-inbox-3-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 3h-15C3.6 3 3 2.4 3 1.5S3.6 0 4.5 0h15c.9 0 1.5.6 1.5 1.5S20.4 3 19.5 3zM1.5 6h21c.9 0 1.5.6 1.5 1.5v15c0 .9-.6 1.5-1.5 1.5h-21C.6 24 0 23.4 0 22.5v-15C0 6.6.6 6 1.5 6zM6 16.5h12v-6h-3v3H9v-3H6v6z"/>'
  }
})
