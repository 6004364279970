/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-ambulance-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.4 12.1l-1.4-.7V8c0-.6-.4-1-1-1h-5V4c0-.6-.4-1-1-1H1c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1h1.1c.4 1.7 2 3 3.9 3s3.4-1.3 3.9-3h4.2c.2 1.7 1.7 3 3.4 3s3.2-1.3 3.4-3H23c.6 0 1-.4 1-1v-4c0-.4-.2-.7-.6-.9zM6 19c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm5-9H9v2H7v-2H5V8h2V6h2v2h2v2zm6.5 9c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5zm2.5-8.5c0 .3-.2.5-.5.5h-2c-.3 0-.5-.2-.5-.5v-1c0-.3.2-.5.5-.5h2c.3 0 .5.2.5.5v1z"/>'
  }
})
