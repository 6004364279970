var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrollable-content-container" },
    [
      _c(
        "div",
        {
          ref: "scrollableContent",
          staticClass: "scrollable-content",
          class: { "smooth-scroll": _vm.smoothScroll },
          on: { scroll: _vm.throttledUpdateScrollToBottom },
        },
        [_c("div", { ref: "slotContainer" }, [_vm._t("default")], 2)]
      ),
      _c(
        "transition",
        { attrs: { name: "fade" } },
        [
          !_vm.isScrolledToBottom
            ? _c("vwc-fab", {
                staticClass: "scroll-bottom-button",
                attrs: {
                  extended: !!_vm.scrollButtonText,
                  label: _vm.scrollButtonText,
                  mini: "",
                  icon: "chevron-down-line",
                },
                on: { click: _vm.scrollToBottom },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }