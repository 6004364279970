<template>
  <div class="contacts-panel">
    <div class="input-container">
      <InputTag
        ref="contactsInputTag"
        :tags="tags"
        focus
        :placeholder="$t('contacts.input_tag_placeholder')"
        @remove="removeTag"
        @filter="filter"
      />
      <vwc-icon-button
        icon="refresh-solid"
        class="refresh-contacts-icon"
        @click="reloadContacts"
      />
    </div>

    <ContactsList
      v-if="filteredContacts && filteredContacts.length > 0"
      class="contacts-list"
      :contacts="filteredContacts"
      @contactClick="toggleContactToInvite"
    />
    <vwc-circular-progress
      v-else-if="reloadInProgress"
      connotation="primary"
      indeterminate
      class="loading"
    />
    <div v-else class="empty-contacts-wrapper">
      <vwc-empty-state :heading="$t('contacts.empty_contacts_text')">
        <img slot="graphic" src="/illustrations/empty-state/empty-search.svg" />
      </vwc-empty-state>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ContactsList from '@/components/lists/ContactsList.vue';
import InputTag from '@/components/InputTag';

export default {
  name: 'Contacts',

  components: {
    InputTag,
    ContactsList
  },

  model: {
    prop: 'selectedContacts',
    event: 'selectedContactsChanged'
  },

  props: {
    selectedContacts: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      searchTerm: '',
      maxContacts: 10,
      reloadInProgress: false
    };
  },

  computed: {
    ...mapState(['contacts']),

    filteredContacts() {
      const contacts = this.contacts.filter(
        (contact) =>
          contact.displayName
            .toLowerCase()
            .indexOf(this.searchTerm.toLowerCase()) >= 0 || // filter by display name
          contact.extensionNumber
            ?.toLowerCase()
            .indexOf(this.searchTerm.toLowerCase()) >= 0 // filter by extension
      );
      return contacts;
    },

    tags() {
      return this.selectedContacts.map(
        (selectedContact) => selectedContact.displayName
      );
    }
  },

  methods: {
    ...mapActions([
      'selectContactToInvite',
      'clearContacts',
      'readContacts',
      'addFlashMessage'
    ]),

    toggleContactToInvite(contact) {
      const index = this.selectedContacts.findIndex((c) => c.id === contact.id);
      if (index === -1) {
        // select
        this.addTag(contact);
      } else {
        // deselect
        this.removeTag(index);
      }
      this.$refs.contactsInputTag.clearText();
    },

    filter(value) {
      this.searchTerm = value;
    },

    addTag(contact) {
      if (this.selectedContacts.length >= this.maxContacts) {
        this.addFlashMessage({
          type: 'warning',
          text: this.$t('contacts.max_invites_flash_message')
        });
        return;
      }

      this.$emit('selectedContactsChanged', [
        ...this.selectedContacts,
        contact
      ]);
      this.selectContactToInvite({ id: contact.id, selected: true });
    },

    removeTag(index) {
      this.$emit('selectedContactsChanged', [
        ...this.selectedContacts.slice(0, index),
        ...this.selectedContacts.slice(index + 1)
      ]);
      this.selectContactToInvite({
        id: this.selectedContacts[index].id,
        selected: false
      });
    },

    async reloadContacts() {
      if (!this.reloadInProgress) {
        this.reloadInProgress = true;

        try {
          await this.clearContacts();
          await this.readContacts();
        } finally {
          this.reloadInProgress = false;
        }
      }
    }
  }
};
</script>

<style scoped>
.contacts-panel {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 338px;
}

.contacts-list {
  height: 280px;
  overflow-y: scroll;
}

.input-container {
  display: flex;
  width: 387px;
  flex-direction: row;
  align-items: center;
}

.empty-contacts-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  overflow: hidden;
  margin-top: 16px;
  min-height: 280px;
}

.loading {
  margin: auto;
  justify-content: center;
  align-items: center;
  min-height: 280px;
}

.refresh-contacts-icon {
  margin-left: 8px;
  cursor: pointer;
  --vvd-icon-size: 20px;
}
</style>
