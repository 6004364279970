/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-whatsapp-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.997.595a11.235 11.235 0 017.997 3.315 11.234 11.234 0 013.308 7.996c-.002 6.232-5.074 11.302-11.305 11.302h-.005c-1.892 0-3.752-.475-5.403-1.376L.596 23.404 2.2 17.547a11.279 11.279 0 01-1.51-5.65C.693 5.665 5.764.595 11.997.595zm0-.595C5.437 0 .097 5.337.095 11.897c0 2.005.505 3.98 1.465 5.73l-1.539 5.62a.594.594 0 00.726.733l5.768-1.512a11.925 11.925 0 005.477 1.336c6.564 0 11.903-5.337 11.906-11.897a11.824 11.824 0 00-3.482-8.418A11.831 11.831 0 0011.996 0z" _fill="#CFD8DC"/><path pid="1" d="M18.643 5.26A9.333 9.333 0 0012 2.504c-5.183 0-9.399 4.213-9.401 9.393 0 1.775.496 3.503 1.437 5l.224.355-.95 3.466 3.557-.933.343.204a9.379 9.379 0 004.783 1.31h.004c5.18 0 9.395-4.215 9.396-9.395 0-2.51-.975-4.87-2.75-6.646z" _fill="#40C351"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M9.17 7.172c-.21-.47-.434-.48-.635-.488-.165-.007-.354-.006-.542-.006-.188 0-.494.07-.753.353s-.99.966-.99 2.356 1.013 2.733 1.154 2.921c.141.188 1.955 3.132 4.826 4.265 2.386.94 2.872.753 3.39.706.518-.047 1.672-.683 1.907-1.342.235-.66.235-1.225.165-1.343-.071-.118-.26-.188-.541-.33-.283-.142-1.672-.825-1.931-.919-.26-.094-.447-.141-.636.142-.188.282-.73.919-.894 1.107-.165.189-.33.212-.613.07-.282-.141-1.192-.439-2.271-1.401-.84-.749-1.407-1.673-1.572-1.956-.165-.283-.018-.436.124-.577.127-.127.282-.33.424-.495.141-.165.188-.282.282-.47.094-.19.047-.354-.024-.495-.07-.142-.618-1.54-.87-2.098z" _fill="#fff"/>'
  }
})
