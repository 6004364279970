/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-list': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1 2a1 1 0 112 0 1 1 0 01-2 0zm1-2a2 2 0 100 4 2 2 0 000-4zM1 8a1 1 0 112 0 1 1 0 01-2 0zm1-2a2 2 0 100 4 2 2 0 000-4zm0 7a1 1 0 100 2 1 1 0 000-2zm-2 1a2 2 0 114 0 2 2 0 01-4 0zM6.5 2a.5.5 0 000 1h9a.5.5 0 000-1h-9zM6 8.5a.5.5 0 01.5-.5h9a.5.5 0 010 1h-9a.5.5 0 01-.5-.5zm.5 5.5a.5.5 0 000 1h9a.5.5 0 000-1h-9z"/>'
  }
})
