export default function getInitialState() {
  return {
    joinedWaitingRoomTimestamp: 0,
    isInWaitingRoom: false,
    isOwnerInMeeting: false,
    waitingParticipants: [],
    keepAliveErrorCounter: 0,
    isApproveAllInProgress: false,
    isDisableWaitingRoomModalVisible: false,
    hasFinishedArtificalTransitionToWaitingRoom: false,
    lastKeepaliveResponseTimestamp: Date.now(),
    lastKeepaliveExpiresIn: 2 * 60 * 1000 // default to 2 minutes
  };
}
