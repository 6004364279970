/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-wifi': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1.636 5.637a9 9 0 0112.726 0 .5.5 0 10.707-.708 10 10 0 00-14.14 0 .5.5 0 10.707.708zm6.363 1.365a5 5 0 00-3.534 1.463.5.5 0 11-.707-.708 6 6 0 018.482 0 .5.5 0 11-.707.708 5 5 0 00-3.534-1.463zM8 11a1 1 0 100 2 1 1 0 000-2zm-2 1a2 2 0 114 0 2 2 0 01-4 0z"/>'
  }
})
