<template>
  <div ref="wtPlayer" class="wt-player">
    <div class="player-container">
      <YoutubePlayerWrapper
        v-if="isYoutube"
        ref="playerWrapper"
        style="width: 100%; height: 100%"
        :video-id="videoMetadata.videoId"
        @videoPlayerStateChanged="handleWrappedPlayerVideoStateChanged"
        @ready="handleWrappedPlayerReady"
        @volumeChanged="handleWrappedPlayerVolumeChanged"
        @seek="handleWrappedPlayerSeek"
        @fullscreenchange="handleWrappedPlayerFullscreenChanged"
      />

      <!-- An invisible layer on top of the player to allow users to continue dragging slider thumbs
           when their cursor enters the territory of an iframe -->
      <div v-if="isDraggingSeekBar" class="invisible-overlay" />
    </div>

    <div
      ref="playbackContainer"
      class="playback-container"
      :class="{ minimized }"
    >
      <WatchTogetherSeekBar
        :timeInVideo="preciseTimeInVideo"
        :duration="videoMetadata.duration"
        :bufferedFraction="bufferedFraction"
        :ownerIndicationVisible="
          !isWatchTogetherOwner && !isSyncedWithOwner && !minimized
        "
        :ownerParticipant="watchTogetherOwnerParticipant"
        :ownerTimeInVideo="preciseOwnerTimeInVideo"
        :ownerPaused="isOwnerPaused"
        @dragstart="timeBarDragStarted"
        @seek="seek"
      />

      <div class="playback-controls unselectable">
        <div class="dock left-dock">
          <div
            v-if="isPaused"
            v-tooltip.bottom="playButtonTooltip"
            class="playback-control"
            @click="play"
          >
            <v-icon iconName="Vlt-icon-play-2" />
          </div>
          <div
            v-else
            v-tooltip.bottom="pauseButtonTooltip"
            class="playback-control"
            @click="pause"
          >
            <v-icon iconName="Vlt-icon-pause-2" />
          </div>

          <WatchTogetherVolumeSlider
            v-tooltip.bottom="volumeTooltip"
            class="volume-slider"
            :volume="volume"
            @toggleMute="toggleMute"
            @volumeChanged="setVolume"
          />

          <div v-if="!minimized" class="dock">
            <div class="duration-text">
              <span>{{ currentTime }}</span>
              /
              <span>{{ duration }}</span>
            </div>

            <div class="separator" />

            <div class="owner-identity-exclamation">
              <Avatar
                size="xs"
                class="owner-avatar"
                :displayName="watchTogetherOwnerParticipant.displayName"
                :image="watchTogetherOwnerParticipant.profilePicture"
                :negative="true"
              />
              <span v-if="isWatchTogetherOwner">{{
                $t('watch_together_player.owner_text')
              }}</span>
              <span v-else>{{
                $t('watch_together_player.watcher_text', {
                  watchTogetherOwnerFirstName
                })
              }}</span>
            </div>
          </div>
        </div>

        <div class="dock">
          <div
            v-if="!minimized"
            v-tooltip="fullscreenTooltip"
            class="playback-control"
            @click="toggleFullscreen"
            @mouseleave="showFullscreenHint = false"
          >
            <v-icon :iconName="fullscreenButtonIcon" />
          </div>
          <div
            v-else
            v-tooltip.bottom="expandTooltip"
            class="playback-control"
            @click="expand"
          >
            <v-icon iconName="Vlt-icon-expand-full" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import VideoSiteTypeEnum from '@/services/watch-together/video-site-type-enum';
import YoutubePlayerWrapper from './videoProviderWrappers/YoutubePlayerWrapper';
import WatchTogetherVolumeSlider from './WatchTogetherVolumeSlider';
import WatchTogetherSeekBar from './WatchTogetherSeekBar';
import VideoOwnerTrackingService from '@/services/watch-together/video-owner-tracking-service';
import { durationToString } from '@/helpers/time-utils';

const UPDATE_CONTROL_BAR_INTERVAL = 1000;
const createPlaybackControlTooltip = (text) => {
  return {
    hideOnTargetClick: true,
    content: text,
    'open-group': 'a',
    boundariesElement: 'window',
    classes: ['watch-together-tooltip']
  };
};
let refreshControlBarInterval;

export default {
  name: 'WatchTogetherPlayer',

  components: {
    YoutubePlayerWrapper,
    WatchTogetherVolumeSlider,
    WatchTogetherSeekBar
  },

  props: {
    minimized: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      isPaused: true,
      isOwnerPaused: true,
      lastVideoStateUpdate: { timeInVideo: 0, timeOfUpdate: null },
      bufferedFraction: 0,
      preciseTimeInVideo: 0,
      preciseOwnerTimeInVideo: 0,
      volume: 0,
      isDraggingSeekBar: false,
      isFullscreen: false,
      showFullscreenHint: false,
      showNonDefaultSpeakerHint: false,
      playButtonTooltip: createPlaybackControlTooltip(
        this.$t('watch_together_player.play_button_tooltip')
      ),
      pauseButtonTooltip: createPlaybackControlTooltip(
        this.$t('watch_together_player.pause_button_tooltip')
      ),
      expandTooltip: createPlaybackControlTooltip(
        this.$t('watch_together_player.expand_button_tooltip')
      ),
      debouncedHandleVideoStateChanged: debounce(
        this.handleVideoStateChanged,
        500
      )
    };
  },

  computed: {
    ...mapState(['isNoAudioMode']),
    ...mapState('watchTogether/ongoing', [
      'videoMetadata',
      'isVideoPlaying',
      'timeInVideo',
      'isSyncedWithOwner'
    ]),
    ...mapGetters(['isDefaultSpeakerSelected', 'defaultSpeakerLabel']),
    ...mapGetters('watchTogether/ongoing', [
      'isWatchTogetherOwner',
      'watchTogetherOwnerParticipant',
      'watchTogetherOwnerName'
    ]),

    volumeTooltip() {
      const content = this.showNonDefaultSpeakerHint
        ? this.$t('watch_together_player.non_default_speaker_volume_tooltip', {
            defaultSpeakerLabel: this.defaultSpeakerLabel
          })
        : this.$t('watch_together_player.volume_tooltip');
      let classes = ['watch-together-tooltip'];
      if (this.showNonDefaultSpeakerHint) {
        classes.push('arrow');
      }

      return {
        trigger: 'hover',
        show: this.showNonDefaultSpeakerHint,
        content,
        classes,
        hideOnTargetClick: true,
        'open-group': 'a',
        boundariesElement: 'window',
        placement: 'bottom-start'
      };
    },

    fullscreenTooltip() {
      let content = this.isFullscreen
        ? this.$t('watch_together_player.exit_full_screen_tooltip')
        : this.$t('watch_together_player.full_screen_tooltip');
      let classes = ['watch-together-tooltip'];
      if (this.showFullscreenHint) {
        content = this.$t('watch_together_player.full_screen_hint_tooltip');
        classes.push('arrow');
      }

      return {
        trigger: 'hover',
        show: this.showFullscreenHint,
        content,
        classes,
        hideOnTargetClick: true,
        'open-group': 'a',
        boundariesElement: 'window',
        placement: 'bottom-end'
      };
    },

    isYoutube() {
      return this.videoMetadata.videoSiteType === VideoSiteTypeEnum.YouTube;
    },

    currentTime() {
      return durationToString(
        Math.round(this.lastVideoStateUpdate.timeInVideo)
      );
    },

    duration() {
      return durationToString(this.videoMetadata.duration);
    },

    watchTogetherOwnerFirstName() {
      return this.watchTogetherOwnerName.split(' ')[0];
    },

    fullscreenButtonIcon() {
      return this.isFullscreen
        ? 'Vlt-icon-media-exit-fullscreen'
        : 'Vlt-icon-media-fullscreen';
    }
  },

  watch: {
    isVideoPlaying() {
      if (this.isWatchTogetherOwner) {
        return;
      }

      if (this.isVideoPlaying) {
        this.$refs.playerWrapper.playVideo();
      } else {
        this.$refs.playerWrapper.pauseVideo();
      }
    },

    /**
     * Seek to the time defined in the state.
     * timeInVideo is an object, which allows to re-seek to the same point in time.
     * Note: timeInVideo affects the player, but the player does not affect timeInVideo.
     */
    timeInVideo() {
      if (this.isWatchTogetherOwner) {
        return;
      }

      this.$refs.playerWrapper.seekTo(this.timeInVideo.seconds);
    }
  },

  created() {
    // Frequently refresh control bar properties based on the player to keep track of changes that
    // cannot always be reported by the events emitted by player wrappers
    refreshControlBarInterval = setInterval(
      this.refreshControlBar,
      UPDATE_CONTROL_BAR_INTERVAL
    );

    document.addEventListener(
      'fullscreenchange',
      this.documentFullscreenChange
    );

    window.addEventListener('keydown', this.windowKeyDown);

    VideoOwnerTrackingService.onStateChanged(this.ownerVideoStateChanged);

    this.preciseOwnerTimeInVideo = VideoOwnerTrackingService.extrapolateTimeInVideo();
    this.isOwnerPaused = VideoOwnerTrackingService.isPaused;
  },

  beforeDestroy() {
    this.$refs.playerWrapper.destroy();
  },

  destroyed() {
    clearInterval(refreshControlBarInterval);

    document.removeEventListener(
      'fullscreenchange',
      this.documentFullscreenChange
    );

    window.removeEventListener('keydown', this.windowKeyDown);

    VideoOwnerTrackingService.clearStateChangedListener();
  },

  mounted() {
    this.animateOwnerTime();
  },

  methods: {
    ...mapActions('watchTogether/ongoing', [
      'handleVideoStateChanged',
      'setOptimalLayout'
    ]),

    documentFullscreenChange() {
      if (!document.fullscreenElement) {
        this.isFullscreen = false;
      }
    },

    windowKeyDown(e) {
      if (!this.isFullscreen) {
        return;
      }

      // space == keycode 32
      if (e.keyCode === 32) {
        this.$refs.playerWrapper.togglePlayingState();
      }
    },

    /* SECTION: Events emitted by the wrapped player */

    handleWrappedPlayerVideoStateChanged({ isVideoPlaying, timeInVideo }) {
      this.isPaused = !isVideoPlaying;
      this.lastVideoStateUpdate = { timeInVideo, timeOfUpdate: Date.now() };

      if (!this.isDraggingSeekBar) {
        this.debouncedHandleVideoStateChanged({ isVideoPlaying, timeInVideo });
      }

      if (isVideoPlaying) {
        this.animateTime();
      }
    },

    handleWrappedPlayerSeek(timeInVideo) {
      this.lastVideoStateUpdate = { timeInVideo, timeOfUpdate: Date.now() };
      this.preciseTimeInVideo = timeInVideo;

      if (!this.isDraggingSeekBar) {
        this.debouncedHandleVideoStateChanged({
          isVideoPlaying: this.isVideoPlaying,
          timeInVideo
        });
      }
    },

    handleWrappedPlayerFullscreenChanged(isFullscreen) {
      // Prevent the iframe itself from going into fullscreen (e.g. when it is double clicked)
      if (isFullscreen) {
        document.exitFullscreen().then(() => {
          this.showFullscreenHint = true;

          setTimeout(() => {
            this.showFullscreenHint = false;
          }, 5000);
        });
      }
    },

    handleWrappedPlayerVolumeChanged({ volume }) {
      this.volume = volume;
    },

    /* SECTION: Refresh properties that the wrapped player does not reliably emit events for */

    refreshControlBar() {
      this.refreshTime();
      this.refreshVolume();
      this.refreshBufferedFraction();
    },

    async refreshTime() {
      if (!this.$refs.playerWrapper) {
        return;
      }

      if (this.isDraggingSeekBar) {
        return;
      }

      const timeInVideo = await this.$refs.playerWrapper.getCurrentTime();

      if (
        this.isPaused &&
        Math.abs(this.lastVideoStateUpdate.timeInVideo - timeInVideo) >= 0.5
      ) {
        this.preciseTimeInVideo = timeInVideo;
      }

      this.lastVideoStateUpdate = { timeInVideo, timeOfUpdate: Date.now() };
    },

    async refreshVolume() {
      if (!this.$refs.playerWrapper) {
        return;
      }

      const isMuted = await this.$refs.playerWrapper.isMuted();
      if (isMuted) {
        this.volume = 0;
      } else {
        this.volume = await this.$refs.playerWrapper.getVolume();
      }
    },

    async refreshBufferedFraction() {
      if (!this.$refs.playerWrapper) {
        return;
      }

      const fraction = await this.$refs.playerWrapper.getLoadedFraction();
      this.bufferedFraction = fraction;
    },

    /* SECTION: State transitions for time-in-video properties */

    ownerVideoStateChanged() {
      // Animate the value of owner-time-in-video when the owner is playing the video
      if (!VideoOwnerTrackingService.isPaused) {
        this.animateOwnerTime();
      }

      this.isOwnerPaused = VideoOwnerTrackingService.isPaused;
    },

    animateTime() {
      if (this.isPaused) {
        return;
      }

      this.preciseTimeInVideo =
        this.lastVideoStateUpdate.timeInVideo +
        (Date.now() - this.lastVideoStateUpdate.timeOfUpdate) / 1000;
      requestAnimationFrame(this.animateTime);
    },

    animateOwnerTime() {
      if (VideoOwnerTrackingService.isPaused) {
        return;
      }

      this.preciseOwnerTimeInVideo = VideoOwnerTrackingService.extrapolateTimeInVideo();
      requestAnimationFrame(this.animateOwnerTime);
    },

    /* SECTION: Control bar actions */

    async play() {
      await this.$refs.playerWrapper.playVideo();
    },

    async pause() {
      await this.$refs.playerWrapper.pauseVideo();
    },

    async setVolume(volume) {
      this.showNonDefaultSpeakerHint = false;
      await this.$refs.playerWrapper.setVolume(volume);
    },

    async toggleMute() {
      this.showNonDefaultSpeakerHint = false;
      await this.$refs.playerWrapper.toggleMute();
    },

    timeBarDragStarted() {
      this.isDraggingSeekBar = true;
    },

    async seek({ final, timeInVideo }) {
      if (final) {
        this.isDraggingSeekBar = false;
      }

      await this.$refs.playerWrapper.seekTo(timeInVideo, {
        allowSeekAhead: final
      });

      if (final && this.isVideoPlaying) {
        this.$refs.playerWrapper.playVideo();
      }
    },

    handleWrappedPlayerReady() {
      this.seekToSynchronizedTime();

      if (this.isNoAudioMode || !this.isDefaultSpeakerSelected) {
        this.$refs.playerWrapper.mute();

        if (!this.isDefaultSpeakerSelected) {
          this.showNonDefaultSpeakerHint = true;

          setTimeout(() => {
            this.showNonDefaultSpeakerHint = false;
          }, 15000);
        }
      }
    },

    async seekToSynchronizedTime() {
      if (this.isWatchTogetherOwner) {
        return;
      }

      if (this.isVideoPlaying) {
        const synchronizedTimeInVideo = VideoOwnerTrackingService.extrapolateTimeInVideo();
        await this.$refs.playerWrapper.seekTo(synchronizedTimeInVideo);
        this.$refs.playerWrapper.playVideo();
      }
    },

    toggleFullscreen() {
      if (!document.fullscreenElement) {
        const target = this.$refs.wtPlayer;
        if (target) {
          target.requestFullscreen();
          this.isFullscreen = true;
        }
      } else {
        document.exitFullscreen();
      }
    },

    /**
     * Brings the player out of its minimized mode by changing to the default layout of Watch Together
     */
    expand() {
      this.setOptimalLayout();
    }
  }
};
</script>

<style scoped>
.wt-player {
  display: flex;
  flex-direction: column;
}

.player-container {
  flex: 1;
  position: relative;
}

.invisible-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1738;
}

.playback-container {
  display: flex;
  flex-direction: column;
}

.playback-controls {
  flex-shrink: 0;
  display: flex;
  height: 46px;
  background: black;
}

.volume-slider >>> .playback-control,
.playback-control {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 32px;
  height: 100%;
  text-align: center;
}

.volume-slider >>> .playback-control .v-icon,
.playback-control .v-icon {
  width: 16px;
  height: 16px;
  fill: #d1d1d1;
  transition: fill 0.1s, transform 0.1s;
}

.volume-slider:hover >>> .playback-control .v-icon,
.playback-control:hover .v-icon {
  fill: #fff;
  transform: scale(1.15);
}

.dock {
  display: flex;
  align-items: center;
}

.left-dock {
  flex: 1;
}

.playback-container .duration-text {
  margin-left: 10px;
  min-width: 75px;
}

.separator {
  height: 28px;
  width: 1px;
  background: white;
  margin: 0 12px;
}

.owner-identity-exclamation {
  display: flex;
  align-items: center;
  font-size: 12px;
  text-transform: uppercase;
}

.owner-identity-exclamation > *:not(:first-child) {
  margin-left: 4px;
}

.owner-identity-exclamation .owner-avatar {
  width: 24px;
  height: 24px;
}
</style>

<style>
.watch-together-popover,
.tooltip.watch-together-tooltip .tooltip-inner {
  background: rgb(24, 25, 27);
  border: 1px solid #43474f;
  border-radius: 6px;
  padding: 3px 6px;
  width: auto;
  z-index: 100;
  text-align: left;
  box-shadow: none;
  color: white;
}

.tooltip.watch-together-tooltip .tooltip-arrow {
  display: none;
}

.tooltip.watch-together-tooltip.arrow .tooltip-arrow {
  display: block;
  border-color: #43474f;
}
</style>
