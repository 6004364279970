/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-up-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.438 13.33l-7.5-6a1.5 1.5 0 00-1.875 0l-7.5 6a1.5 1.5 0 101.875 2.342L12 10.422l6.563 5.25a1.501 1.501 0 001.875-2.343z"/>'
  }
})
