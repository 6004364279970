import {
  CONNECTION_STATUS,
  INSPECTOR_URL_COMPONENTS,
  JOIN_APPROVAL_LEVEL,
  KIBANA_RS_URL_COMPONENTS,
  LOGGLY_URL_COMPONENTS,
  MAC_BUILT_IN_CAMERA_NAME,
  PARTICIPANT_STATE_TYPES,
  PUBLISHER_ERROR,
  PUBLISHER_STATE,
  SIDEBARS,
  USER_MODES,
  VIRTUAL_BACKGROUND_TYPE
} from '@/consts/global-consts';
import {
  formatPhoneDisplayInternational,
  getBrowserLocale,
  isElectron,
  isMinimalNativeVersion,
  isMobileDevice
} from '@/helpers/global-helpers';
import { capabilities } from '@/consts/capabilities';
import * as vbcGw from '@/apis/vbc-gw';
import * as tokbox from '@/apis/opentok-api';
import { browserFullName, osFullName } from '@/helpers/detectrtc';
import MeetingManager from '@/services/meeting-manager-service';
import { isScreenshareStream } from '@/helpers/meeting-helpers';
import { sessionStorageService } from '@/services/storage-service';
import i18n from '@/i18n';
import { getDefaultDevice } from '@/helpers/devices-utils';

export default {
  userDisplayName: (state) =>
    `${state.userInfo.firstName} ${state.userInfo.lastName}`,

  participantDisplayName: (state, getters) => {
    return getters.isGuest
      ? getters.myParticipant.displayName
      : getters.userDisplayName;
  },

  roomToken: (state) => state.roomDetails.roomToken,

  roomPinCode: (state) => state.roomDetails.pinCode,

  allowUsersToRecord: (state) => state.roomDetails.allowUsersToRecord,

  dialInNumbers: (state) => {
    return (
      state.roomDetails.dialInNumbers?.sort((a, b) =>
        a.display_name > b.display_name ? 1 : -1
      ) || []
    );
  },

  userLocale: (state) => state.extensionLocale || getBrowserLocale(),

  roomDisplayName: (state) => state.roomDetails.displayName,

  showContacts: (state) => state.contacts.length,

  roomUrl: (state, getters) => {
    const url = process.env.VUE_APP_MEETINGS_ROOM_URL;
    return url ? `${url}/${getters.roomToken}` : '';
  },

  isGuest: (state) => state.userMode === USER_MODES.GUEST,

  isAppUserEnteringAppScreen: (state, getters) =>
    state.isLoadingPreEntranceData &&
    !getters.isGuest &&
    (getters.roomToken || state.sessionId) &&
    !state.settings.shouldShowPreJoinScreen,

  isSessionOwner: (state) => state.myParticipantId === state.owner,

  ownerDisplayName: (state) => state.participants[state.owner].displayName,

  isPublisherInMainStream: (state) =>
    state.streams.find(
      (stream) => stream.streamId === state.mainStreamId && stream.isPublisher
    ),

  isBeforeAppScreen: (state, getters) =>
    (state.isJoiningASession && !state.isSessionInitialized) || // when we're joining a session but the session is not yet initialized
    (state.isSessionInitialized && !state.hasSessionEverConnected) || // between the session initialization and the session connection
    (getters.isMeetingAlreadyInitialized && !getters.isAppReady), // when we'll start the meeting immediately (probably after refresh)

  showLoadingOverlay: (state, getters) =>
    state.isLoadingPreEntranceData || // if we need to load info before the entrance screen
    getters.isBeforeAppScreen ||
    (state.isAppRunning &&
      !getters.isAppReady &&
      !state.showPreJoinScreen &&
      !state.showEntranceScreen), // in the case we don't have any of the 3 screens presented but the app is running

  loadingOverlayType: (state) => state.showPreJoinScreen,

  isAppReady: (state) =>
    state.isSessionInitialized &&
    Object.keys(state.participants).length > 0 &&
    state.initUserSettingsCompleted,

  streamsMap: (state) => {
    return state.streams.reduce((map, stream) => {
      map[stream.streamId] = stream;
      return map;
    }, {});
  },

  streamsWithVideoCount: (state) => {
    return state.streams.filter((stream) => stream.hasVideo).length;
  },

  participants: (state) => {
    const participants = {};
    Object.values(state.participants).forEach((participant) => {
      const contact = state.contacts.find(
        (contact) => contact.id === participant.participantId
      );
      participant.displayName = contact?.displayName || participant.displayName;
      participant.profilePicture = contact?.profilePicture || '';
      participants[participant.participantId] = participant;
    });
    return participants;
  },

  activeParticipants: (state) => {
    return Object.values(state.participants).filter(
      (participant) => participant.state === PARTICIPANT_STATE_TYPES.JOINED
    );
  },

  isOwnerInTheMeeting: (state) => {
    const ownerParticipant = state.participants[state.owner];
    if (ownerParticipant?.state === PARTICIPANT_STATE_TYPES.JOINED) {
      return true;
    } else {
      return false;
    }
  },

  myParticipant: (state) => state.participants[state.myParticipantId],

  myParticipantContactInfo: (state) => {
    return state.contacts.find(
      (contact) => contact.id === state.myParticipantId
    );
  },

  myStream: (state) => {
    return state.streams.find(
      (stream) => stream.participantId === state.myParticipantId
    );
  },

  publisherStats: (state) => state.publisherStats,

  detailsStringToCopy: (state, getters) => {
    // add room info
    let details = state.isBranded
      ? i18n.t('store_getters.branded_details_string', {
          roomUrl: getters.roomUrl,
          roomPinCode: getters.roomPinCode
        })
      : i18n.t('store_getters.details_string', {
          roomUrl: getters.roomUrl,
          roomPinCode: getters.roomPinCode
        });

    // add all other countries
    details = state.selectedDialInNumbers.reduce((currentValue, dialIn) => {
      const formattedNumber = formatPhoneDisplayInternational(
        dialIn.value,
        getters.userLocale
      );
      const clickToDial = `${formattedNumber},,${getters.roomPinCode}#`;
      return `${currentValue}\n\n${dialIn.label}\n${i18n.t(
        'store_getters.dial_in_text'
      )} ${formattedNumber}\n${i18n.t(
        'store_getters.click_to_dial_text'
      )} ${clickToDial}`;
    }, details);
    return details;
  },

  hasMicrophoneAccess(state, getters) {
    return (
      state.hasMicrophoneDevices &&
      state.hasMicrophonePermissions &&
      !getters.hasSystemPermissionsError
    );
  },

  hasCameraAccess(state, getters) {
    return (
      state.hasCameraDevices &&
      state.hasCameraPermissions &&
      !getters.hasSystemPermissionsError
    );
  },

  numberOfVideoStreamsInSession(state) {
    return state.streams.reduce((count, stream) => {
      if (stream.hasVideo) {
        count++;
      }
      return count;
    }, 0);
  },

  numberOfPublishersInSession(state) {
    return state.streams.length;
  },

  isInitializingPublisher(state) {
    return state.publisherState === PUBLISHER_STATE.INITIALIZING;
  },

  hasChromeMicError(state) {
    return (
      state.publisherError &&
      state.publisherError.name === PUBLISHER_ERROR.CHROME_MICROPHONE_ERROR
    );
  },

  hasSystemPermissionsError(state) {
    return (
      state.publisherError &&
      state.publisherError.name === PUBLISHER_ERROR.SYSTEM_PERMISSIONS
    );
  },

  screenshareStreamId: (state) => {
    // Searches for any screen share stream
    for (let index = 0; index < state.streams.length; index++) {
      if (isScreenshareStream(state.streams[index])) {
        return state.streams[index].streamId;
      }
    }

    return null;
  },

  isInitializingScreenshare: (state) => {
    return (
      state.actionsInProgress.screenShare ||
      state.actionsInProgress.getScreenshareSources
    );
  },

  isScreenShared: (state) => {
    return !!state.myScreenStreamId;
  },

  isInitializingVirtualBackground: (state) => {
    return state.actionsInProgress.virtualBackground;
  },

  isVirtualBackgroundSupported() {
    return (
      !(isElectron() && !isMinimalNativeVersion('2.13.0')) &&
      tokbox.hasVirtualBackgroundSupport()
    );
  },

  isBlurBackgroundEnabled(state) {
    return state.virtualBackground.type === VIRTUAL_BACKGROUND_TYPE.BLUR;
  },

  // pinned > screenshare > dominant > last to join
  dominantSpeakerStreamId: (state, getters) =>
    state.pinnedStreamId ||
    getters.screenshareStreamId ||
    state.mainStreamId ||
    state.streams[state.streams.length - 1]?.streamId,

  isVCPRoom: (state) => {
    return state.roomDetails.domain === 'VCP';
  },

  allowMyParticipantAsDominantSpeaker: (state) => {
    return state.minimizedMode;
  },

  showToggleAudioButtonPopper: (state) => {
    return (
      !state.isNoAudioMode &&
      (state.loudnessDetector.muteIndication ||
        state.watchTogether.ongoing.showWatchTogetherMuteIndication)
    );
  },

  isOnline: (state) => state.connectionStatus === CONNECTION_STATUS.ONLINE,

  getParticipantByStreamId: (state, getters) => (streamId) => {
    return state.participants?.[getters.streamsMap[streamId]?.participantId];
  },

  // indicates if we are before or after refresh of the page
  isMeetingAlreadyInitialized: (state, getters) =>
    !state.waitingRoom?.isInWaitingRoom &&
    ((!getters.isGuest && sessionStorageService.getItem('session_id')) ||
      (getters.isGuest && sessionStorageService.getItem('guest_init_done'))),

  isMuteAllEnabled: (state) =>
    state.capabilities.includes(capabilities.MUTE_ALL),

  hasRoomDetails: (state) => !!state.roomDetails.displayName,

  isWaitingRoomEnabled: (state) =>
    state.sessionJoinApprovalLevel === JOIN_APPROVAL_LEVEL.EXPLICIT_APPROVAL,

  isMobileWebMode: (state) =>
    isMobileDevice && state.roomDetails.isMobileWebSupported,

  showFramelessTitlebar: (state) =>
    isElectron() && window.Electron.minimizeWindow && !state.isFullScreen,

  defaultSpeakerLabel: (state) => {
    return state.speakerDevices.find(
      (device) => device.deviceId === getDefaultDevice(state.speakerDevices)
    )?.label;
  },

  isDefaultSpeakerSelected: (state, getters) => {
    const defaultSpeakerId = getDefaultDevice(state.speakerDevices);
    if (state.selectedSpeakerId === defaultSpeakerId) {
      return true;
    }

    // Check if the selected speaker has the label of the default speaker, which is what
    // happens when users select speakers that begin with (Default) in the settings menu
    const selectedSpeakerLabel = state.speakerDevices.find(
      (device) => device.deviceId === state.selectedSpeakerId
    )?.label;

    return getters.defaultSpeakerLabel?.includes(selectedSpeakerLabel);
  },

  currentCameraLabel: (state) => {
    return state.cameraDevices.find(
      (cameraDevice) => cameraDevice.deviceId === state.currentCameraId
    )?.label;
  },

  feedbackProperties: (state, getters) => {
    const formatDevices = (devices) =>
      devices.map((device) => device.label).join(', ');

    return {
      user_name: state.userInfo.loginName || '',
      full_name: !getters.isGuest
        ? getters.userDisplayName
        : state.userInfo.loginName,
      account_id: state.userInfo.accountId,
      email: state.userInfo.email,
      is_native: isElectron(),
      app_version: `${process.env.VUE_APP_VERSION} - ${process.env.VUE_APP_GIT_REVISION}`,
      extension: vbcGw.getCredentials().extension,
      is_guest: getters.isGuest,
      session_id: state.sessionId,
      room_name: state.roomDetails.displayName,
      is_owner: getters.isSessionOwner,
      num_of_participants: Object.keys(state.participants).length,
      num_of_active_participants: getters.activeParticipants.length,
      browser: browserFullName,
      operation_system: osFullName,
      participant_id: state.myParticipantId,
      connection_id: MeetingManager.connectionId || '',
      loggly_url: getters.logglyUrl,
      inspector_url: getters.inspectorUrl,
      room_service_kibana_url: getters.rsKibanaUrl,
      microphone_devices: formatDevices(state.microphoneDevices),
      camera_devices: formatDevices(state.cameraDevices),
      speaker_devices: formatDevices(state.speakerDevices),
      domain: state.roomDetails.domain
    };
  },

  logglyUrl: (state) =>
    LOGGLY_URL_COMPONENTS.BASE_URL +
    '#terms=' +
    state.sessionId +
    '&' +
    LOGGLY_URL_COMPONENTS.SOURCE_GROUP,

  inspectorUrl: (state) =>
    INSPECTOR_URL_COMPONENTS.BASE_URL +
    '/account/' +
    INSPECTOR_URL_COMPONENTS.ACCOUNT +
    '/project/' +
    INSPECTOR_URL_COMPONENTS.PROJECT +
    '/session/' +
    state.sessionId,

  rsKibanaUrl: (state) =>
    KIBANA_RS_URL_COMPONENTS.BASE_URL +
    KIBANA_RS_URL_COMPONENTS.FILTERS +
    KIBANA_RS_URL_COMPONENTS.QUERY_START +
    state.sessionId +
    KIBANA_RS_URL_COMPONENTS.QUERY_END,

  forceShowVideoStreams: (state, getters) =>
    state.minimizedMode ||
    getters.isMobileWebMode ||
    state.watchTogether.ongoing.isWatchTogetherActive ||
    state.whiteboard.isWhiteboardActive,

  /**
   * Number of participant tiles visible (including "fake participants" such as the whiteboard app)
   */
  numberOfVisibleParticipants: (state) => {
    let numberOfVisibleParticipants = state.streams.length;

    if (state.whiteboard.isWhiteboardActive) {
      numberOfVisibleParticipants++;
    }

    return numberOfVisibleParticipants;
  },

  isAdobeAnalyticsDisabled: (state) => {
    const isProdEnvs = window.isAlpha || window.isProduction;
    if (!isProdEnvs) {
      return true;
    }
    return state.capabilities.includes(capabilities.ADOBE_ANALYTICS_DISABLED);
  },

  isMeetingAutoRecorded: (state) => {
    return state.roomDetails.autoRecord;
  },

  isRecordingOfOwnerOnly: (state) => {
    return state.roomDetails.recordOnlyOwner;
  },

  isRecordingFeatureEnabled: (state) => {
    return state.roomDetails.availableFeatures.isRecordingAvailable;
  },

  isChatFeatureEnabled: (state) => {
    return state.roomDetails.availableFeatures.isChatAvailable;
  },

  isWhiteboardFeatureEnabled: (state) => {
    return state.roomDetails.availableFeatures.isWhiteboardAvailable;
  },

  isCaptionsFeatureAvailable: (state) => {
    return state.roomDetails.availableFeatures.isCaptionsAvailable;
  },

  isInForeground: (state) => state.documentVisibilityState === 'visible',

  isLocaleSwitcherEnabled: (state, getters) => {
    return (
      (state.roomDetails.availableFeatures.isLocaleSwitcherAvailable &&
        getters.isVCPRoom) ||
      getters['settings/exclusiveExperimentalMode']
    );
  },

  isChatOpen: (state) => {
    return (
      !state.layout.isSidebarCollapsed && state.activeSidebar === SIDEBARS.CHAT
    );
  },

  isOldAccount: (state) => {
    const oldAccounts = ['143131', '255931'];
    return oldAccounts.includes(state.sessionAccountId);
  },

  hasMacBuiltInCam: (state) => {
    return state.cameraDevices.some((cam) =>
      cam.label.includes(MAC_BUILT_IN_CAMERA_NAME)
    );
  },

  shouldShowDeviceDisabledBanner: (state) => {
    return (
      window.isSafari &&
      (state.isMicrophonePausedSafari || state.isVideoPausedSafari)
    );
  },

  displayNameOfParticipantSharingScreen: (state, getters) => {
    let participantDisplayName = '';
    if (!getters.isScreenShared) {
      const screenStream = state.streams.find((stream) =>
        isScreenshareStream(stream)
      );
      if (screenStream) {
        participantDisplayName =
          getters.participants[screenStream.participantId].displayName;
      }
    }
    return participantDisplayName;
  },

  isFlashMessagesDisabled: (state, getters) => {
    return isElectron() && state.minimizedMode && getters.isScreenShared;
  },

  domainsConfigurations: (state) => state.domainsConfigurations
};
