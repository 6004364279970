<template>
  <div
    id="app"
    ref="app"
    class="app"
    :class="[
      osClass,
      {
        branded: isBranded,
        'vvd-scheme-alternate': whiteBgMode
      }
    ]"
  >
    <!-- Frameless window title bar -->
    <MeetingTitlebar />
    <SelectLanguageModal v-if="showSelectLanguageModal" />
    <LoadingScreen
      v-if="isLoadingPreInitData && !isMobileDevice"
      class="loading-screen"
    />
    <router-view />
  </div>
</template>

<script>
import MeetingTitlebar from '@/components/MeetingTitlebar.vue';
import LoadingScreen from '@/views/LoadingScreen.vue';
import { isMobileDevice, OS } from '@/helpers/global-helpers';
import { mapState } from 'vuex';
import SelectLanguageModal from '@/components/modals/SelectLanguageModal';
import { MESSAGE_SCREEN_ILLUSTRATIONS, OS_TYPES } from '@/consts/global-consts';

const WhiteBackgroundScreens = [
  'UnsupportedBrowserScreen',
  'MobileJoinMeetingFromLinkScreen',
  'WhitelabelingPreviewScreen'
];

export default {
  components: {
    SelectLanguageModal,
    MeetingTitlebar,
    LoadingScreen
  },

  data() {
    return {
      isMobileDevice
    };
  },

  computed: {
    ...mapState('whitelabel', ['cssVariables']),
    ...mapState([
      'isBranded',
      'showEntranceScreen',
      'isLoadingPreInitData',
      'showSelectLanguageModal'
    ]),

    whiteBgMode() {
      // if one of the white background screens is shown
      return (
        WhiteBackgroundScreens.includes(this.$route.name) ||
        ((this.$route.name === 'Home' ||
          this.$route.name === 'HomeWithThemeUrl') &&
          this.showEntranceScreen &&
          this.isBranded) ||
        ((this.$route.name === 'MobileHome' ||
          this.$route.name === 'MobileHomeWithThemeUrl') &&
          this.showEntranceScreen)
      );
    },

    osClass() {
      return OS.name === OS_TYPES.WINDOWS ? 'windows-os' : 'mac-os';
    }
  },

  watch: {
    cssVariables: function (cssVariables) {
      Object.keys(cssVariables).forEach((key) => {
        document.documentElement.style.setProperty(key, cssVariables[key]);
      });
    }
  },

  mounted() {
    // Mobile browsers often have unexpected behavior when using "vh" units,
    // so we keep track of the window's inner height to use it instead of vh
    if (isMobileDevice) {
      const refreshAppHeight = () => {
        document.documentElement.style.setProperty(
          '--viewport-height',
          `${window.innerHeight}px`
        );

        // The document's clientHeight does not change when zooming in/out, so we use it
        // for elements that can be zoomed into such as video streams
        const appHeight = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight
        );
        document.documentElement.style.setProperty(
          '--app-height',
          `${appHeight}px`
        );
      };
      window.addEventListener('resize', refreshAppHeight);
      refreshAppHeight();
    }

    // Preload images that need to be displayed offline
    new Image().src = MESSAGE_SCREEN_ILLUSTRATIONS.NETWORK_ISSUES;
    new Image().src = MESSAGE_SCREEN_ILLUSTRATIONS.TECHNICAL_DIFFICULTIES;
  }
};
</script>

<style lang="sass">
$Vlt-font-url: "~@vonagevolta/volta2/fonts/"
@import "~@vonagevolta/volta2/scss/volta"
@import "./style/styles.scss"
@import "./style/whitelabel.scss"
@import "~@vonage/vivid/styles/core/all"
@import "~@vonage/vivid/styles/tokens/theme-dark.css"
@import "~@vonage/vivid/styles/fonts/spezia-variable.css"
</style>

<style>
/* Global Styles */
:root {
  --viewport-height: 100vh;
  --app-height: 100vh;
}

body {
  overflow: hidden;
  background: #000;
}

.app {
  height: 100vh;
  height: var(--viewport-height);
}

.unselectable {
  user-select: none;
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background: #8d8d8d;
  border-radius: 20px;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.branded {
  --vvd-color-cta: var(--btn-cta-color-black-bg);
  --vvd-color-on-cta: var(--btn-cta-color-black-bg-text-color);
}

.vvd-scheme-alternate.branded {
  --vvd-color-cta: var(--btn-cta-color-white-bg);
  --vvd-color-on-cta: var(--btn-cta-color-white-bg-text-color);
}

.loading-screen {
  position: absolute;
  z-index: 9999;
  height: 100%;
  width: 100%;
}

.appcues iframe {
  /* Vivid's sets the color-scheme property and we do not want it to affect appcues */
  color-scheme: unset;
}
</style>
