/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-minimize-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.55 2.55l-2.1-2.1L17.7 4.2 13.5 0v10.5H24l-4.2-4.2 3.75-3.75zM4.2 17.7L0 13.5h10.5V24l-4.2-4.2-3.75 3.75-2.1-2.1L4.2 17.7zm13.5 2.1L13.5 24V13.5H24l-4.2 4.2 3.75 3.75-2.1 2.1-3.75-3.75zM.45 2.55l2.1-2.1L6.3 4.2 10.5 0v10.5H0l4.2-4.2L.45 2.55z"/>'
  }
})
