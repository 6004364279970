/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-volume-off': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3.1 11H1V6h4.5c.1 0 .2 0 .3-.1L11 2.4v.7l1-1v-.6c0-.2-.1-.4-.3-.4-.2-.1-.4-.1-.5 0L5.3 5H.5c-.3 0-.5.2-.5.5v6c0 .3.2.5.5.5h1.6l1-1zM15.9 1.6c-.2-.2-.5-.2-.7 0L1.6 15.1c-.2.2-.2.5 0 .7.2.2.5.2.7 0l3.5-3.5 5.4 3.6c.2.1.4.1.5 0s.3-.3.3-.4V6.2l3.9-3.9c.1-.1.1-.5 0-.7zm-4.9 13l-4.4-2.9L11 7.2v7.4z"/>'
  }
})
