/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-flow-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 0H9v6h6V0zm0 18H9v6h6v-6zm3 6v-6h6v6h-6zM6 18H0v6h6v-6zm4.5-4.5h-6v3h-3V12c0-.9.6-1.5 1.5-1.5h7.5v-3h3v3H21c.9 0 1.5.6 1.5 1.5v4.5h-3v-3h-6v3h-3v-3z"/>'
  }
})
