/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-video-conference': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.846 3.3h20.308c.267 0 .546.233.546.6v15.2c0 .367-.28.6-.546.6H1.846c-.267 0-.546-.233-.546-.6V3.9c0-.367.28-.6.546-.6zM22.154 2H1.846C.826 2 0 2.85 0 3.9v15.2C0 20.15.826 21 1.846 21h20.308c1.02 0 1.846-.85 1.846-1.9V3.9c0-1.05-.826-1.9-1.846-1.9zM10.5 8a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zM12 5a3 3 0 100 6 3 3 0 000-6zm-.75 9a3.75 3.75 0 00-3.674 3h8.848a3.75 3.75 0 00-3.674-3h-1.5zm-3.712.038A5.25 5.25 0 0111.25 12.5h1.5A5.25 5.25 0 0118 17.75a.75.75 0 01-.75.75H6.75a.75.75 0 01-.75-.75 5.25 5.25 0 011.538-3.712zM6 23.25c0-.45.3-.75.75-.75h10.5c.45 0 .75.3.75.75s-.3.75-.75.75H6.75c-.45 0-.75-.3-.75-.75z"/>'
  }
})
