/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calculator-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 0H3c-.9 0-1.5.6-1.5 1.5v21c0 .9.6 1.5 1.5 1.5h18c.9 0 1.5-.6 1.5-1.5v-21C22.5.6 21.9 0 21 0zM7.5 19.5h-3v-3h3v3zm0-6h-3v-3h3v3zm6 6h-3v-3h3v3zm0-6h-3v-3h3v3zm6 6h-3v-9h3v9zm0-12h-15V3h15v4.5z"/>'
  }
})
