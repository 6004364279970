/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'raise-hand': {
    width: 20,
    height: 19,
    viewBox: '0 0 20 19',
    data: '<path pid="0" d="M1.77 2.816a1.25 1.25 0 011.25 1.25V8.65h.833V2.4a1.25 1.25 0 012.5 0v6.25h.833V1.566a1.25 1.25 0 012.5 0V8.65h.834V2.4a1.25 1.25 0 112.5 0v8.235a.833.833 0 001.205.745l2.878-1.44a1.417 1.417 0 011.802.466 1.483 1.483 0 01-.166 1.809l-3.484 3.916a7.501 7.501 0 01-5.604 2.519H8.02a7.5 7.5 0 01-7.5-7.5V4.066a1.25 1.25 0 011.25-1.25z" _fill="#fff"/>'
  }
})
