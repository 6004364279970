var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "attachments-section" }, [
    _c(
      "div",
      {
        staticClass: "attachment-wrapper",
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.downloadFile($event)
          },
          mouseenter: function ($event) {
            _vm.hover = true
          },
          mouseleave: function ($event) {
            _vm.hover = false
          },
          focusout: function ($event) {
            _vm.fileClicked = false
          },
        },
      },
      [
        _c("AttachmentBox", {
          class: {
            clickable: _vm.downloadable,
            uploading: _vm.isUploading,
            "Vlt-btn-on-hover": !_vm.uploadCancelled,
          },
          attrs: {
            attachment: _vm.attachment,
            isLoading: _vm.isUploading,
            isMobileWebChat: _vm.isMobileWebMode,
          },
        }),
        _vm.showDownloadButton
          ? _c(
              "div",
              { staticClass: "download-button" },
              [
                !_vm.isMobileWebMode
                  ? _c(
                      "vwc-icon-button",
                      {
                        attrs: { icon: "download-line", layout: "outlined" },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.downloadFile($event)
                          },
                        },
                      },
                      [
                        _vm.isDownloading
                          ? _c("vwc-circular-progress", {
                              attrs: { indeterminate: "", density: "-6" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm.fileClicked
                  ? _c(
                      "vwc-icon-button",
                      {
                        attrs: {
                          icon: "open-line",
                          layout: "filled",
                          disabled: _vm.isDownloading,
                          dense: "",
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.openFile($event)
                          },
                        },
                      },
                      [
                        _vm.isDownloading
                          ? _c("vwc-circular-progress", {
                              attrs: { indeterminate: "", density: "-6.5" },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _vm.isUploading
      ? _c(
          "div",
          { staticClass: "upload-info" },
          [
            _c("div", [
              _vm._v(
                _vm._s(
                  _vm.$t("attachment_row.uploading_text", {
                    percentsUploaded: _vm.percentsUploaded,
                  })
                )
              ),
            ]),
            _c("vwc-button", {
              staticClass: "cancel-btn",
              attrs: {
                label: _vm.$t("attachment_row.cancel_button"),
                layout: "filled",
                dense: "",
              },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.cancelUpload($event)
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
    (_vm.sendFailed && !_vm.isUploading) ||
    _vm.downloadFailed ||
    _vm.uploadCancelled
      ? _c(
          "div",
          { staticClass: "action-failed" },
          [
            _c("v-icon", { attrs: { iconName: "Vlt-icon-unhappy" } }),
            _c("div", { staticClass: "action-failed-text" }, [
              _vm._v(_vm._s(_vm.errorMessage)),
            ]),
            _c(
              "div",
              { staticClass: "action-failed-buttons" },
              [
                _c("vwc-button", {
                  attrs: {
                    label: _vm.uploadCancelled
                      ? _vm.$t("attachment_row.upload_cancelled_button")
                      : _vm.$t("attachment_row.try_again_button"),
                    layout: "filled",
                    dense: "",
                  },
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.tryAgain($event)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }