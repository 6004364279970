/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-refresh': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3.2 4.4c.8-1 1.9-1.8 3.1-2.2 1.2-.4 2.6-.3 3.8.1.9.4 1.7 1 2.4 1.7h-3c-.3 0-.5.2-.5.5s.2.5.5.5h4.1c.2 0 .4-.2.4-.5v-4c0-.3-.2-.5-.5-.5s-.5.2-.5.5v2.6c-.7-.7-1.6-1.3-2.5-1.7C9 .9 7.5.9 6 1.3c-1.4.4-2.7 1.3-3.6 2.5C1.5 5 1 6.5 1 8c0 .3.2.5.5.5S2 8.3 2 8c0-1.3.4-2.6 1.2-3.6zM14.5 7.5c-.3 0-.5.2-.5.5 0 1.3-.4 2.6-1.2 3.6-.8 1-1.9 1.8-3.1 2.2-1.2.4-2.6.3-3.8-.1-.9-.4-1.7-1-2.4-1.7h3c.3 0 .5-.2.5-.5s-.2-.5-.5-.5h-4-.1c-.2 0-.4.2-.4.5v4c0 .3.2.5.5.5s.5-.2.5-.5v-2.6c.7.7 1.6 1.3 2.5 1.7 1.4.5 3 .6 4.4.2 1.5-.4 2.7-1.3 3.6-2.5C14.5 11 15 9.5 15 8c0-.3-.2-.5-.5-.5z"/>'
  }
})
