/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-expand-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.8.2c.2.2.2.6 0 .8l-5.7 5.7c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8L15 .2c.2-.3.6-.3.8 0zM6.7 9.3c.2.2.2.6 0 .8L1 15.8c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8l5.7-5.7c.2-.2.6-.2.8 0z"/><path pid="1" d="M8 .6c0-.3.3-.6.6-.6h6.9c.2 0 .5.3.5.6v6.9c0 .2-.3.5-.6.5s-.6-.3-.6-.6V1.1H8.6c-.3 0-.6-.2-.6-.5zM.6 8c.3 0 .6.3.6.6v6.3h6.3c.3 0 .6.3.6.6-.1.2-.4.5-.7.5H.6c-.3 0-.6-.3-.6-.6V8.6c0-.3.3-.6.6-.6z"/>'
  }
})
