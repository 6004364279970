/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-packet-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.521.223a1.39 1.39 0 00-1.094 0L1.702 4.391A1.388 1.388 0 00.86 5.668v12.504c0 .555.331 1.058.842 1.276l9.7 4.157a1.381 1.381 0 001.149-.001l9.695-4.155a1.39 1.39 0 00.842-1.277V5.712a1.385 1.385 0 00-.861-1.329L12.521.223zm5.651 5.445l-6.198-2.656-6.198 2.656 6.199 2.656 6.197-2.656zM3.638 7.775v9.48l6.947 2.978v-9.481L3.638 7.775zm9.726 12.458l6.946-2.977V7.775l-2.019.865c.03.115.047.236.047.36v3a1.39 1.39 0 11-2.778 0V9.81l-2.197.942v9.48z"/>'
  }
})
