/**
 * @param {number} totalSeconds Duration expressed in total amount of seconds
 * @returns A string that represents hh:mm:SS (or just mm:SS if duration is less than 1 hour)
 */
export function durationToString(totalSeconds) {
  const seconds = Math.round(totalSeconds) % 60;
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const hours = Math.floor(totalSeconds / 3600);
  const paddedSeconds = seconds >= 10 ? seconds.toString() : `0${seconds}`;

  if (hours > 0) {
    const paddedMinutes = minutes >= 10 ? minutes.toString() : `0${minutes}`;
    // e.g. 1:00:00, 1:05:38, 200:00:00
    return `${hours}:${paddedMinutes}:${paddedSeconds}`;
  }

  // e.g. 3:05, 4:12, 12:50
  return `${minutes}:${paddedSeconds}`;
}

/**
 * Runs the given async function and then waits, if necessary, to make sure at least minimumDelay ms have passed.
 * @param {*} fn An async function
 * @param {*} minimumDelay The minimum delay in milliseconds
 * @note If the given function throws an error, the delay will not be applied
 * @note This is useful for emphasizing the loading animations of critical user actions
 */
export async function runWithMinimumDelay(fn, minimumDelay) {
  const timeBeforeRunning = Date.now();
  await fn();
  const elapsed = Date.now() - timeBeforeRunning;

  if (elapsed < minimumDelay) {
    await sleepAsync(minimumDelay - elapsed);
  }
}

export async function sleepAsync(milliseconds) {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
}
