<template>
  <div
    class="thank-you-screen"
    :class="{
      mobile: isMobileWebMode,
      branded: isBranded,
      embedded: isEmbedded
    }"
  >
    <LocaleSwitcher v-if="isLocaleSwitcherEnabled" class="locale-switcher" />
    <div data-cy="voange-logo" class="content Vlt-white">
      <img
        v-if="isMobileWebMode"
        :src="isBranded || isEmbedded ? '' : '/img-clouds.svg'"
        class="image-top"
      />
      <div v-if="showThankYouContent" class="logo-container">
        <img
          v-if="isBranded && brandImageWhite"
          class="brand-logo"
          :src="brandImageWhite"
        />
        <p v-else-if="isBranded" class="brand-text">{{ brandText }}</p>
        <v-icon v-else class="vonage-logo" iconName="Brand-icon-vonage" />
      </div>

      <h2
        v-if="showThankYouContent"
        data-cy="thank-you-screen-title"
        class="title Vlt-white"
      >
        {{ thankYouTitle }}
      </h2>
      <p
        v-if="(isBranded || isEmbedded) && !isMobileWebMode"
        data-cy="thank-you-screen-content"
        class="message Vlt-white"
      >
        {{ $t('thank_you_screen.content1') }}
      </p>
      <p
        v-else-if="!isMobileWebMode"
        data-cy="thank-you-screen-content"
        class="message Vlt-white"
      >
        {{ $t('thank_you_screen.content2') }}
        <a class="message Vlt-white" href="mailto:vbcfeedback@vonage.com">
          vbcfeedback@vonage.com
        </a>
      </p>
      <div
        v-if="isMobileWebMode && showThankYouContent && !isEmbedded"
        class="separator"
      />
    </div>
    <RateThisMeeting
      v-if="!isEmbedded"
      class="rate-this-meeting"
      @rateMeetingClicked="rateMeetingClicked = true"
      @finishRateMeeting="finishRateMeeting = true"
    />
    <vwc-button
      v-if="showJoinNewMeeting"
      class="join-new-meeting-button"
      data-cy="join-new-meeting-button"
      :label="$t('thank_you_screen.join_new_meeting_button')"
      :connotation="finishRateMeeting ? 'primary' : 'cta'"
      :layout="finishRateMeeting ? 'filled' : 'text'"
      @click="onJoinNewMeetingClicked"
    />
  </div>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import RateThisMeeting from '@/components/RateThisMeeting';
import { sessionStorageService } from '@/services/storage-service';
import LocaleSwitcher from '@/components/LocaleSwitcher';

export default {
  name: 'ThankYouScreen',
  components: { RateThisMeeting, LocaleSwitcher },
  data() {
    return {
      rateMeetingClicked: false,
      finishRateMeeting: false
    };
  },

  computed: {
    ...mapState('whitelabel', ['brandImageWhite', 'brandText']),
    ...mapState(['isBranded', 'isEmbedded']),
    ...mapGetters(['isMobileWebMode', 'isLocaleSwitcherEnabled']),

    showThankYouContent() {
      return !(this.isMobileWebMode && this.rateMeetingClicked);
    },

    showJoinNewMeeting() {
      return (
        this.isMobileWebMode &&
        !this.isEmbedded &&
        (!this.rateMeetingClicked || this.finishRateMeeting)
      );
    },

    thankYouTitle() {
      return this.isBranded
        ? this.$t('thank_you_screen.branded_thank_you_title')
        : this.$t('thank_you_screen.thank_you_title');
    }
  },

  mounted() {
    this.adobeTrack({ pageName: 'Thank you screen' });
  },

  methods: {
    ...mapActions(['adobeTrack']),

    onJoinNewMeetingClicked() {
      sessionStorageService.clear();
      window.location.assign(`${location.origin}/`);
    }
  }
};
</script>

<style scoped>
.thank-you-screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100vw;
  height: 100vh;
  background-size: 100% 100%;
}

.thank-you-screen.mobile {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  padding: 35px 40px;
  background-size: 100% 100%;
  height: var(--app-height);
  overflow: auto;
}

.thank-you-screen.mobile.embedded {
  justify-content: center;
}

.thank-you-screen.mobile .rate-this-meeting {
  width: unset;
  height: unset;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 60vw;
  height: 100vh;
  padding: 35px 40px;
  background-color: black;
  background-size: 100% 60%;
  background-position: left;
  text-align: center;
}

.thank-you-screen.mobile .content {
  justify-content: flex-start;
  height: unset;
  padding: unset;
  background-image: none;
}

.separator {
  display: block;
  background: #666666;
  content: '';
  height: 1px;
  width: 124px;
  overflow: hidden;
  margin-bottom: 24px;
}

.thank-you-screen.mobile vwc-button.join-new-meeting-button {
  min-width: 110px;
  margin-top: auto;
  margin-bottom: 50px;
}

.content .title {
  margin-top: 20px;
  margin-bottom: 16px;
  font-size: 32px;
  letter-spacing: unset;
  line-height: 44px;
  white-space: pre-line;
}

.thank-you-screen.mobile .content .title {
  margin: 24px 0;
  font-size: 20px;
  font-weight: bold;
  line-height: 28px;
}

.content .message {
  font-size: 16px;
  line-height: 23px;
}

.content .image-top {
  margin-bottom: 24px;
  margin-top: 5px;
}

.logo-container {
  margin-bottom: 5px;
}

.vonage-logo {
  width: 88px;
  height: 75px;
}

.brand-logo {
  fill: var(--entrance-screen-logo-color);
}

.brand-text {
  font-size: 52px;
  font-weight: 600;
  line-height: initial;
  color: var(--entrance-screen-logo-color);
}

.thank-you-screen.mobile .vonage-logo {
  width: 71px;
  height: 60px;
  object-fit: fill;
}

.branded {
  --vvd-color-cta: var(--btn-cta-color-black-bg);
  --vvd-color-cta-70: var(--btn-cta-color-black-bg);
  --vvd-color-on-cta: var(--btn-cta-color-black-bg-text-color);
}

.locale-switcher {
  position: absolute;
  top: 24px;
  z-index: 10;
}

.windows-os .locale-switcher {
  left: 24px;
}

.mac-os .locale-switcher {
  right: 24px;
}

@media only screen and (max-width: 600px) {
  .content {
    width: unset;
  }
}
</style>
