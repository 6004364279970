/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-media-exit-fullscreen': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 18.857h5.143V24H8.57v-8.571H0v3.428zM5.143 5.143H0V8.57h8.571V0H5.143v5.143zM15.429 24h3.428v-5.143H24V15.43h-8.571V24zm3.428-18.857V0H15.43v8.571H24V5.143h-5.143z"/>'
  }
})
