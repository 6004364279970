<template>
  <div class="unselectable">
    <div v-if="loaderTypeSpinner" class="preload-spinner-wrapper">
      <vwc-circular-progress
        density="8"
        indeterminate
        connotation="primary"
      ></vwc-circular-progress>
      <div class="loading-text">{{ loaderText }}</div>
    </div>
    <div v-else class="meetings-loader-wrapper">
      <MeetingLoader></MeetingLoader>
      <div class="meetings-loader-text unselectable">{{ loaderText }}</div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import MeetingLoader from '@/components/MeetingLoader';

export default {
  name: 'LoadingScreen',
  components: {
    MeetingLoader
  },
  props: {
    loaderTypeSpinner: {
      type: Boolean,
      default: true
    }
  },
  ...mapState(['roomDetails', 'isBranded']),

  computed: {
    meetingsLoaderTexts() {
      return [this.$t('loading_screen.meetings_loader_text1')];
    },

    loaderText() {
      if (this.loaderTypeSpinner) {
        return this.$t('loading_screen.loader_default_text');
      }
      return this.meetingsLoaderTexts[
        Math.floor(Math.random() * this.meetingsLoaderTexts.length)
      ];
    }
  }
};
</script>

<style scoped>
.preload-spinner-wrapper,
.meetings-loader-wrapper {
  height: var(--app-height);
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #e7ebee;
  font-size: 20px;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0);
  --vvd-color-primary: #ffffff; /* overloading vivid color to enable the spinner before the vivid color is loaded */
}

.loading-text {
  margin-left: 12px;
  margin-top: 10px;
}

.meetings-loader-text {
  color: white;
  padding-top: 32px;
  font-size: 20px;
  font-weight: bold;
}
</style>
