/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-receptionist': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.154 0C5.572 0 1 4.572 1 10.154c0 1.997.775 3.67 1.471 5.163.696 1.493 1.298 2.802 1.298 4.154V24h1.846v-4.529c0-1.878-.782-3.45-1.47-4.932-.69-1.482-1.299-2.874-1.299-4.385 0-4.259 3.249-7.781 7.385-8.25v6.548C8.644 8.863 7.46 10.29 7.46 12a3.704 3.704 0 003.693 3.692c.721 0 1.392-.212 1.961-.577l1.731 1.183c.069.7.667 1.24 1.385 1.24a1.385 1.385 0 10-.289-2.74l-1.557-1.067c.28-.52.461-1.1.461-1.731 0-1.71-1.182-3.137-2.77-3.548V1.875c2.922.238 4.598 1.594 5.626 2.942 1.136 1.486 1.413 2.943 1.413 2.943l.03.115.057.115 2.452 4.933a.649.649 0 01.058.317h-.058c-.494.166-1.5.462-1.5.462l-.692.173v2.74c0 .617-.347 1.327-.895 1.875-.548.549-1.258.895-1.875.895h-1.846V24h1.846v-2.77c1.23 0 2.366-.576 3.202-1.413.837-.836 1.414-1.972 1.414-3.202V15.26c.313-.09.523-.12.865-.231.029-.007.058-.022.087-.029v-.029c.595-.187 1.092-.681 1.24-1.211.159-.563.058-1.082-.144-1.558v-.029l-.03-.029-2.451-4.932-.029-.058c-.072-.325-.44-1.81-1.702-3.462C17.742 1.853 15.21 0 11.154 0zm0 10.154c1.031 0 1.846.815 1.846 1.846a1.831 1.831 0 01-1.846 1.846A1.831 1.831 0 019.308 12c0-1.031.815-1.846 1.846-1.846z"/>'
  }
})
