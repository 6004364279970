<template>
  <div class="dialog-container unselectable vvd-root vvd-theme-alternate">
    <img v-if="imgSrc" class="dialog-img" :src="imgSrc" alt="dialog-img" />
    <div class="dialog-message-container">
      <span class="dialog-message-title">
        {{ dialogTitle }}
      </span>
      <span v-if="dialogSubtitle" class="dialog-message-subtitle">
        {{ dialogSubtitle }}
      </span>
    </div>
    <vwc-3-button
      v-if="actionButtonText"
      class="dialog-action-button"
      :label="actionButtonText"
      appearance="filled"
      @click.stop="$emit('action-button-click')"
    ></vwc-3-button>
  </div>
</template>

<script>
export default {
  name: 'ChatDialog',
  props: {
    imgSrc: {
      type: String,
      default: undefined
    },
    dialogTitle: {
      type: String,
      default: ''
    },
    dialogSubtitle: {
      type: String,
      default: undefined
    },
    actionButtonText: {
      type: String,
      default: undefined
    }
  }
};
</script>

<style scoped>
.dialog-container {
  width: 100%;
  height: 100%;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

.dialog-img {
  max-width: 100%;
  object-fit: contain;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.dialog-message-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-inline: 39px;
  text-align: center;
  gap: 8px;
}

.dialog-message-title {
  white-space: pre-line;
  font-weight: 500;
  font-size: 20px;
}

.dialog-message-subtitle {
  font-weight: 400;
  font-size: 14px;
}
</style>
