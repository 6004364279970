/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-5-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M4.5 6a3 3 0 100-6 3 3 0 000 6zM13.5 10.5a1.5 1.5 0 100-3H3a3 3 0 00-3 3v12a1.5 1.5 0 003 0v-6h1.5v6a1.5 1.5 0 003 0v-12h6z"/><path pid="1" d="M22.5 1.5h-12v3H21V15H10.5v3h5.073l2.584 5.17a1.5 1.5 0 102.686-1.34L18.927 18H22.5a1.5 1.5 0 001.5-1.5V3a1.5 1.5 0 00-1.5-1.5z"/></g><defs><clipPath id="clip0"><path pid="2" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
