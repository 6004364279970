/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-share-1': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.1.1c.2-.1.6-.1.8 0l3 3c.2.2.2.5 0 .7-.2.2-.5.2-.7 0L8.5 1.2 5.9 3.9c-.2.1-.6.1-.8 0-.1-.2-.1-.6 0-.8l3-3z"/><path pid="1" d="M8.5 0c.3 0 .5.2.5.5v10c0 .3-.2.5-.5.5s-.5-.2-.5-.5V.5c0-.3.2-.5.5-.5z"/><path pid="2" d="M2 6.4c0-.2.2-.4.5-.4h3c.3 0 .5.2.5.5s-.2.5-.5.5H2.9L2 15h12.9L14 7h-2.6c-.2 0-.4-.2-.4-.5s.2-.5.5-.5h3c.3 0 .5.2.5.4l1 9c0 .1 0 .3-.1.4-.1.1-.2.2-.4.2h-14c-.1 0-.3-.1-.4-.2-.1-.1-.1-.2-.1-.4l1-9z"/>'
  }
})
