/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-credit-card-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 4.5v3H0v-3C0 3.6.6 3 1.5 3h21c.9 0 1.5.6 1.5 1.5zm-24 15v-9h24v9c0 .9-.6 1.5-1.5 1.5h-21C.6 21 0 20.4 0 19.5zm3-3h6V15H3v1.5zm15 0h3V15h-3v1.5z"/>'
  }
})
