/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-star-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 26 24',
    data: '<path pid="0" d="M23.716 8.159l-6.476-.942-2.89-5.867a1.562 1.562 0 00-2.7 0L8.76 7.218l-6.476.94a1.5 1.5 0 00-.834 2.56l4.684 4.567-1.105 6.45a1.5 1.5 0 002.176 1.581L13 20.27l5.791 3.043a1.5 1.5 0 002.177-1.581l-1.106-6.45 4.688-4.565a1.5 1.5 0 00-.831-2.557l-.003-.001z"/>'
  }
})
