/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-search': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 .5A.5.5 0 01.5 0h13a.5.5 0 01.5.5v5a.5.5 0 01-1 0V1H1v14h5.5a.5.5 0 010 1h-6a.5.5 0 01-.5-.5V.5z"/><path pid="1" d="M3 3.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM3 6.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zM3 9.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zM3 12.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zM11.05 8.5a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5zM7.3 11.25a3.75 3.75 0 117.5 0 3.75 3.75 0 01-7.5 0z"/><path pid="2" d="M13.146 13.646a.5.5 0 01.708 0l1.5 1.5a.5.5 0 01-.708.708l-1.5-1.5a.5.5 0 010-.708z"/>'
  }
})
