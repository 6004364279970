/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-video-conference-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 1.846C0 .826.826 0 1.846 0h20.308C23.174 0 24 .826 24 1.846v14.77c0 1.02-.826 1.846-1.846 1.846H1.846A1.846 1.846 0 010 16.614V1.846zM19.5 22.5c0-.9-.6-1.5-1.5-1.5H6c-.9 0-1.5.6-1.5 1.5S5.1 24 6 24h12c.9 0 1.5-.6 1.5-1.5zM9.444 5.556a2.556 2.556 0 115.112 0 2.556 2.556 0 01-5.112 0zM8.2 11.31A4.472 4.472 0 0111.36 10h1.278a4.472 4.472 0 014.472 4.472.639.639 0 01-.639.64H7.528a.639.639 0 01-.639-.64c0-1.186.471-2.323 1.31-3.162z"/>'
  }
})
