/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-stopwatch-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M8 0h8v2H8V0zM6.444 5.685a10 10 0 1111.112 16.63A10 10 0 016.444 5.685zM11 15h6v-2h-4V9h-2v6z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
