/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mobile-arrow-right': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.5 6.2c-.3-.3-.7-.3-1 0-.3.3-.3.7 0 1l2.3 2.3H7.5c-.4 0-.7.3-.7.7 0 .4.3.7.7.7h7.4l-2.3 2.3c-.3.3-.3.7 0 1 .3.3.7.3 1 0l3.6-3.6c.1-.1.1-.1.1-.2s.1-.2.1-.3c0-.2-.1-.4-.2-.5l-3.7-3.4z"/><path pid="1" d="M17.2 0H6.8C4.7 0 3 1.7 3 3.8v16.5c0 2 1.7 3.7 3.8 3.7h10.5c2.1 0 3.8-1.7 3.8-3.8V3.7C21 1.7 19.3 0 17.2 0zm2.3 20.2c0 1.2-1 2.2-2.2 2.2H6.8c-1.2 0-2.2-1-2.2-2.2v-.8h15v.8zm0-2.2h-15V3.8c0-1.2 1-2.2 2.2-2.2h10.5c1.2 0 2.2 1 2.2 2.2V18z"/>'
  }
})
