/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-pie-chart-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.5 3C4.65 3 0 7.65 0 13.5S4.65 24 10.5 24 21 19.35 21 13.5H10.5V3z"/><path pid="1" d="M13.5 0v10.5H24C24 4.65 19.35 0 13.5 0z"/>'
  }
})
