/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-user-small': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 8a4 4 0 100-8 4 4 0 000 8zM8 9.333c-4.5 0-6.667 2.808-6.667 4.444v.89A1.333 1.333 0 002.666 16h10.667a1.333 1.333 0 001.333-1.333v-.89c0-1.636-2.166-4.444-6.666-4.444z"/>'
  }
})
