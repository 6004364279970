var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-modal",
    {
      directives: [
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.up.right.down.left",
          value: _vm.onArrowPressed,
          expression: "onArrowPressed",
          modifiers: { up: true, right: true, down: true, left: true },
        },
      ],
      staticClass: "modal",
      attrs: {
        title: _vm.$t("screen_share_sources_picker_modal.title"),
        largeModal: "",
        dismiss: "",
        dismissEsc: "",
        dismissClickOutside: "",
        confirmEnter: "",
      },
      on: {
        close: function (confirm) {
          return confirm ? _vm.share() : _vm.hide()
        },
      },
    },
    [
      _c(
        "template",
        { slot: "content" },
        [
          _c("resize-observer", {
            on: { notify: _vm.onModalSizeChangedDebounced },
          }),
          _vm._l(_vm.sharingSections, function (section, i) {
            return _c(
              "div",
              {
                key: "section-" + i,
                staticClass: "sections-container unselectable",
              },
              [
                _c("h3", { staticClass: "section-title" }, [
                  _vm._v(_vm._s(section.title)),
                ]),
                _c(
                  "div",
                  { staticClass: "sources-container" },
                  _vm._l(section.sources, function (source, j) {
                    return _c(
                      "div",
                      {
                        key: source.id,
                        staticClass: "source-container",
                        class: {
                          "selected-source": source.id === _vm.selectedSourceId,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.selectSource(i, j)
                          },
                          dblclick: _vm.share,
                        },
                      },
                      [
                        _c("p", { staticClass: "source-name" }, [
                          source.appIcon
                            ? _c("img", {
                                staticClass: "source-icon",
                                attrs: { src: source.appIcon },
                              })
                            : _vm._e(),
                          _c(
                            "span",
                            { staticClass: "text-ellipsis source-name-text" },
                            [_vm._v(_vm._s(source.name))]
                          ),
                        ]),
                        _c("div", { staticClass: "thumbnail-container" }, [
                          _c("img", {
                            staticClass: "source-thumbnail",
                            attrs: { src: source.thumbnailUrl },
                          }),
                        ]),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          }),
        ],
        2
      ),
      _c("template", { slot: "footerButtons" }, [
        _c(
          "div",
          { staticClass: "footer-buttons" },
          [
            _c("vwc-button", {
              staticClass: "cancel-button",
              attrs: {
                label: _vm.$t(
                  "screen_share_sources_picker_modal.cancel_button"
                ),
                layout: "outlined",
              },
              on: { click: _vm.hide },
            }),
            _c(
              "vwc-button",
              {
                staticClass: "share-button",
                attrs: {
                  label:
                    _vm.isInitializingScreenshare || _vm.shareInProgress
                      ? undefined
                      : _vm.$t(
                          "screen_share_sources_picker_modal.share_button"
                        ),
                  connotation: "cta",
                  layout: "filled",
                  disabled: _vm.isShareButtonDisabled,
                },
                on: { click: _vm.share },
              },
              [
                _vm.isInitializingScreenshare || _vm.shareInProgress
                  ? _c("vwc-circular-progress", {
                      attrs: { indeterminate: "", density: "-7.5" },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.showWindowsDisclaimer
              ? _c(
                  "div",
                  { staticClass: "windows-disclaimer" },
                  [
                    _c("v-icon", {
                      staticClass: "icon",
                      attrs: { iconName: "Vlt-icon-error" },
                    }),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            "screen_share_sources_picker_modal.windows_disclaimer_text"
                          )
                        )
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }