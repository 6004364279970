<template>
  <div class="container">
    <select v-model="wrappedValue" class="native-select">
      <option v-for="option in options" :key="option.key" :value="option.value">
        {{ option.label }}
      </option>
    </select>
    <div class="custom-select">
      <slot />
      <v-icon
        v-show="showArrow"
        class="arrow Vlt-grey"
        iconName="Vlt-icon-right-full"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * Array of { key, value, label } objects
     */
    options: {
      type: Array,
      default: () => [],
      validator(options) {
        return options.every((option) =>
          ['key', 'value', 'label'].every((key) => key in option)
        );
      }
    },
    /**
     * Affects the selected value
     * Should correspond to one of the values from the options prop
     */
    value: {
      type: [Object, String, Number, Boolean],
      default: () => undefined
    },
    showArrow: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    wrappedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    }
  }
};
</script>

<style scoped>
.container {
  position: relative;
}

.native-select {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
}

.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #666;
  color: white;
  border-radius: 6px;
  padding: 9px 12px;
  font-weight: bold;
}

.arrow {
  width: 10px;
  height: 10px;
}
</style>
