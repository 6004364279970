export default {
  ADD_QUESTION: (state, question) => {
    state.questions.push(question);
  },

  REMOVE_QUESTION: (state, questionId) => {
    state.questions = state.questions.filter(
      (q) => q.questionId !== questionId
    );
  },

  SET_QUESTION: (state, question) => {
    const questionIndex = state.questions.findIndex(
      (q) => q.questionId === question.questionId
    );
    state.questions.splice(questionIndex, 1, question);
  },

  SET_QUESTIONS: (state, questions) => {
    state.questions = questions.sort((a, b) => {
      // resolved questions appear last, regardless of upvotes or age
      if (a.isResolved && !b.isResolved) {
        return 1;
      } else if (!a.isResolved && b.isResolved) {
        return -1;
      }

      if (a.upvotes.length === b.upvotes.length) {
        // make older questions appear first
        return a.createdAt - b.createdAt;
      }

      // sort by upvotes descending
      return b.upvotes.length - a.upvotes.length;
    });
  },

  SET_IS_QNA_SESSION_ACTIVE: (state, isQnaSessionActive) => {
    state.isQnaSessionActive = isQnaSessionActive;
  },

  SET_QNA_SESSION_OWNER_ID: (state, qnaSessionOwnerId) => {
    state.qnaSessionOwnerId = qnaSessionOwnerId;
  },

  SET_QNA_SESSION_ID: (state, qnaSessionId) => {
    state.qnaSessionId = qnaSessionId;
  },

  SET_IS_LOADING_SESSION: (state, isLoadingSession) => {
    state.isLoadingSession = isLoadingSession;
  },

  MARK_QUESTION_ID_AS_SEEN: (state, questionId) => {
    state.seenQuestionIds.add(questionId);
  }
};
