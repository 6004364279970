/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-ai-3-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M13.5 0c-2.7 0-5.25 1.05-7.2 2.85-1.95 1.95-3.15 4.5-3.3 7.2L.3 14.1c-.15.3-.3.45-.3.6v.6c0 .15.15.45.3.6l.45.45L3 17.4v2.85c0 1.05.45 1.95 1.05 2.7.75.6 1.65 1.05 2.7 1.05h3.75c1.2 0 2.4-.45 3.15-1.35.9-.75 1.35-1.95 1.35-3.15v-6.45c-.6-.3-1.05-.9-1.2-1.5-.3-.6-.3-1.2-.15-1.8.15-.75.45-1.2 1.05-1.65.45-.45 1.2-.6 1.8-.6.6 0 1.35.15 1.8.6.45.45.9.9 1.05 1.65.15.6.15 1.2-.15 1.95-.3.6-.75 1.05-1.2 1.5v6.3c0 1.65-.6 3.15-1.5 4.5h3c.45 0 .75-.15 1.05-.45.3-.3.45-.6.45-1.05v-4.65c1.5-1.5 2.4-3.3 2.85-5.4.45-2.1.15-4.2-.6-6-.9-1.95-2.25-3.6-3.9-4.65C17.55.6 15.6 0 13.5 0z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
