/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-alarm': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1.1 4.9C1 4.6 1 4.3 1 4c0-1.7 1.3-3 3-3 .5 0 .9.1 1.3.3.2.1.5 0 .7-.2s0-.5-.2-.7C5.2.1 4.6 0 4 0 1.8 0 0 1.8 0 4c0 .4.1.8.2 1.1.1.3.4.4.6.3.3 0 .4-.3.3-.5zM11.5 9H8.7L5.9 6.1c-.2-.1-.6-.1-.8 0-.1.2-.1.6 0 .8l3 3c.1 0 .3.1.4.1h3c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"/><path pid="1" d="M13.3 13.6C14.4 12.4 15 10.8 15 9c0-3.9-3.1-7-7-7S1 5.1 1 9c0 1.8.6 3.4 1.7 4.6l-1.6 1.6c-.2.2-.2.5 0 .7.2.2.5.2.7 0l1.6-1.6C4.6 15.4 6.2 16 8 16c1.8 0 3.4-.6 4.6-1.7l1.6 1.6c.2.2.5.2.7 0 .2-.2.2-.5 0-.7l-1.6-1.6zm-1-.4c-.1 0-.1 0 0 0-.1 0-.1 0 0 0C11.1 14.3 9.7 15 8 15c-1.7 0-3.1-.7-4.2-1.7C2.7 12.1 2 10.7 2 9c0-3.3 2.7-6 6-6s6 2.7 6 6c0 1.7-.7 3.1-1.7 4.2z"/><path pid="2" d="M12 0c-.6 0-1.2.1-1.7.4-.3.1-.4.4-.2.6.1.2.4.4.7.2.3-.1.7-.2 1.2-.2 1.7 0 3 1.3 3 3 0 .3 0 .6-.1.9-.1.3.1.5.3.6.3.1.5-.1.6-.3.1-.4.2-.8.2-1.2 0-2.2-1.8-4-4-4z"/>'
  }
})
