/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-remove-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm6 13.5H6v-3h12v3z"/>'
  }
})
