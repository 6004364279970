/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-card': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.659 3.659A2.25 2.25 0 012.25 3h3a.75.75 0 01.75.75v.75a1.5 1.5 0 003 0v-.75A.75.75 0 019.75 3h4.5a.75.75 0 01.75.75v.75a1.5 1.5 0 003 0v-.75a.75.75 0 01.75-.75h3A2.25 2.25 0 0124 5.25v13.5A2.25 2.25 0 0121.75 21H2.25A2.25 2.25 0 010 18.75V5.25c0-.597.237-1.169.659-1.591zM2.25 4.5a.75.75 0 00-.75.75v13.5a.75.75 0 00.75.75h19.5a.75.75 0 00.75-.75V5.25a.75.75 0 00-.75-.75H19.5a3 3 0 11-6 0h-3a3 3 0 01-6 0H2.25zm12 6a.75.75 0 000 1.5h4.5a.75.75 0 000-1.5h-4.5zm0 4.5a.75.75 0 000 1.5h4.5a.75.75 0 000-1.5h-4.5zm-9-4.5a.75.75 0 00-.75.75v4.5c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-4.5a.75.75 0 00-.75-.75h-4.5zM6 15v-3h3v3H6z"/>'
  }
})
