/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-volume-on-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 1.5c0-1.2-1.35-1.95-2.4-1.2L5.55 6H1.5C.6 6 0 6.6 0 7.5v9c0 .9.6 1.5 1.5 1.5h4.05l8.55 5.7c1.05.6 2.4 0 2.4-1.2v-21z"/>'
  }
})
