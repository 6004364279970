/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calendar': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M14.5 2H12V.5c0-.3-.2-.5-.5-.5s-.5.2-.5.5V2H5V.5c0-.3-.2-.5-.5-.5S4 .2 4 .5V2H1.5C.7 2 0 2.7 0 3.5v11c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-11c0-.8-.7-1.5-1.5-1.5zm.5 12.5c0 .3-.2.5-.5.5h-13c-.3 0-.5-.2-.5-.5V6h14v8.5zM15 5H1V3.5c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5V5z"/>'
  }
})
