/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-flow': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6 .5a.5.5 0 01.5-.5h4a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-4a.5.5 0 01-.5-.5v-4zM7 1v3h3V1H7zm2 5.5a.5.5 0 00-1 0V9H2.5a.5.5 0 00-.5.5v2a.5.5 0 001 0V10h5v1.5a.5.5 0 001 0V10h5v1.5a.5.5 0 001 0v-2a.5.5 0 00-.5-.5H9V6.5zm7 8a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM8.5 16a1.5 1.5 0 100-3 1.5 1.5 0 000 3zM4 14.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"/>'
  }
})
