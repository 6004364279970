/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-sort': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.625 2.219l5 4a1 1 0 01-1.25 1.562L12 4.28l-4.375 3.5a1 1 0 01-1.25-1.562l5-4a1 1 0 011.25 0zM12 19.719l4.375-3.5a1 1 0 011.25 1.562l-5 4a1 1 0 01-1.25 0l-5-4a1 1 0 111.25-1.562l4.375 3.5z"/>'
  }
})
