/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-magic-wand': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M9.9 4.1C9.8 4 9.6 4 9.5 4s-.2 0-.3.1l-2 2-7 7c-.2.2-.2.5 0 .7l2 2c0 .1.2.2.3.2.1 0 .3-.1.4-.1L9.8 9s.1 0 .1-.1l.1-.1 1.9-1.9c.2-.2.2-.5 0-.7l-2-2.1zM2.5 14.8l-1.3-1.3 6.3-6.3 1.3 1.3-6.3 6.3zm7-7L8.2 6.5l1.3-1.3 1.3 1.3-1.3 1.3zM3.1 3.9c.2.1.6.1.8 0L5 2.7l1.1 1.1c.2.2.6.2.8.1.1-.2.1-.6 0-.8L5.7 2 6.8.9c.2-.2.2-.6.1-.8-.2-.1-.6-.1-.8 0L5 1.3 3.9.1c-.2-.1-.6-.1-.8 0-.1.2-.1.6 0 .8L4.3 2 3.1 3.1c-.1.2-.1.6 0 .8zM14.7 2L15.8.9c.2-.2.2-.5 0-.7-.2-.2-.5-.2-.7 0L14 1.3 12.9.2c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7L13.3 2l-1.1 1.1c-.2.2-.2.5 0 .7.2.2.5.2.7 0L14 2.7l1.1 1.1c.2.2.5.2.7 0 .2-.2.2-.5 0-.7L14.7 2zM15.9 10.1c-.2-.2-.5-.2-.7 0L14 11.3l-1.1-1.1c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l1.1 1.1-1.1 1.1c-.2.2-.2.5 0 .7s.5.2.7 0l1.1-1.1 1.1 1.1c.2.2.5.2.7 0s.2-.5 0-.7L14.7 12l1.1-1.1c.2-.2.2-.6.1-.8z"/>'
  }
})
