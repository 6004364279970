var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message-screen",
      class: {
        "message-screen-with-logo": _vm.shouldShowVonageLogo,
      },
      attrs: { "data-cy": "message-screen" },
    },
    [
      _vm.shouldShowVonageLogo
        ? _c("div", { staticClass: "vonage-logo" }, [
            _c("img", { attrs: { src: "/vonage-logo-with-text.svg" } }),
          ])
        : _vm._e(),
      _vm.isLocaleSwitcherEnabled
        ? _c("LocaleSwitcher", { staticClass: "locale-switcher" })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "content",
          class: {
            mobile: _vm.isMobileWebMode,
            "content-with-logo": _vm.shouldShowVonageLogo,
          },
        },
        [
          _vm.isIconWithSuccessConnotation && _vm.isBranded
            ? _c(
                "div",
                { staticClass: "branded-icon" },
                [
                  _c("vwc-icon", {
                    staticClass: "confirm-icon",
                    attrs: {
                      type: "check-circle-line",
                      connotation: "success",
                    },
                  }),
                ],
                1
              )
            : !_vm.isBranded && _vm.globalMessage.image
            ? _c("img", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.imageLoaded,
                    expression: "imageLoaded",
                  },
                ],
                staticClass: "image",
                attrs: { src: _vm.globalMessage.image },
                on: {
                  load: function ($event) {
                    _vm.imageLoaded = true
                  },
                },
              })
            : _c(
                "div",
                { staticClass: "branded-icon" },
                [
                  _c("vwc-icon", {
                    staticClass: "icon",
                    attrs: { type: _vm.globalMessage.brandedIcon },
                  }),
                ],
                1
              ),
          _c(
            "h1",
            {
              staticClass: "title",
              attrs: { "data-cy": "message-screen-title" },
            },
            [_vm._v(" " + _vm._s(_vm.title) + " ")]
          ),
          _c("p", {
            staticClass: "message",
            attrs: { "data-cy": "message-screen-content" },
            domProps: { innerHTML: _vm._s(_vm.message) },
          }),
          _vm.globalMessage.buttonText
            ? _c(
                "vwc-button",
                {
                  staticClass: "reconnect-button",
                  attrs: {
                    "data-cy": "reconnect-button",
                    label: !_vm.callbackInProgress ? _vm.buttonText : "",
                    disabled: _vm.callbackInProgress,
                    layout: "filled",
                  },
                  on: { click: _vm.onClick },
                },
                [
                  _vm.callbackInProgress
                    ? _c("vwc-circular-progress", {
                        attrs: { indeterminate: "", density: "-7.5" },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm.buttonErrorText
            ? _c("p", { staticClass: "message error" }, [
                _vm._v(_vm._s(_vm.buttonErrorText)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }