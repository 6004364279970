<template>
  <div class="dial-in-info" data-cy="dial-in-info">
    <span class="select-countries-label">{{
      $t('dial_in_info.select_countries_label')
    }}</span>
    <Dropdown
      :open="isDropdownExpanded"
      :options="dialInOptions"
      @close="closeDropdown"
      @apply="onApplyDropdown"
    >
      <InputTag
        slot="toggleDropdown"
        :tags="tags"
        @remove="removeSelectedNumber"
      >
        <div
          slot="input-text"
          class="input-text"
          data-cy="input-text"
          @click="toggleDropdown"
        >
          {{ $t('dial_in_info.input_text') }}
        </div>
      </InputTag>
    </Dropdown>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import InputTag from '@/components/InputTag';
import Dropdown from '@/components/Dropdown';
import analytics from '@/services/analytics-service';
import { ANALYTICS } from '@/consts/global-consts';

export default {
  name: 'DialInInfo',

  components: { Dropdown, InputTag },

  data() {
    return {
      isDropdownExpanded: false
    };
  },

  computed: {
    ...mapState(['selectedDialInNumbers']),
    ...mapGetters(['dialInNumbers']),

    dialInOptions() {
      return this.dialInNumbers.map((dialIn) => {
        return {
          label: dialIn.display_name,
          checked: this.isSelected(dialIn),
          value: dialIn.number,
          locale: dialIn.locale
        };
      });
    },

    tags() {
      return this.selectedDialInNumbers.map(
        (dialInNumber) => dialInNumber.label
      );
    }
  },

  methods: {
    ...mapActions(['setSelectedDialInNumbers', 'setDefaultSelectedCountries']),

    toggleDropdown() {
      this.isDropdownExpanded = !this.isDropdownExpanded;
    },

    closeDropdown() {
      this.isDropdownExpanded = false;
    },

    onApplyDropdown(items) {
      const selectedNumbers = items.filter((number) => number.checked);
      if (selectedNumbers.length > 0) {
        this.setSelectedDialInNumbers(selectedNumbers);
        analytics.trackEvent(ANALYTICS.SELECT_COUNTRIES, {
          Source: 'Info Modal'
        });
      } else {
        this.setDefaultSelectedCountries();
      }
    },

    removeSelectedNumber(index) {
      const selected = [
        ...this.selectedDialInNumbers.slice(0, index),
        ...this.selectedDialInNumbers.slice(index + 1)
      ];
      this.setSelectedDialInNumbers(selected);
    },

    isSelected(dialIn) {
      return !!this.selectedDialInNumbers.find(
        (selected) => selected.value === dialIn.number
      );
    }
  }
};
</script>

<style scoped>
.dial-in-info {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.select-countries-label {
  margin-bottom: 4px;
}

.input-text {
  padding: 5px;
  user-select: none;
  cursor: pointer;
}
</style>
