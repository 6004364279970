/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-plug-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M20.793 11.446l-1.82 1.905-8.28-8.282 1.862-1.867a5.81 5.81 0 017.023-.9L21.439.44a1.5 1.5 0 112.115 2.121l-1.861 1.862a5.812 5.812 0 01-.9 7.023zm-8.088 1.26l1.59 1.59-2.813 2.812 1.824 1.83-1.861 1.862a5.811 5.811 0 01-7.023.9L2.56 23.56a1.5 1.5 0 11-2.12-2.12l1.867-1.862a5.811 5.811 0 01.9-7.023l1.82-1.905L6.9 12.518l2.805-2.812 1.59 1.59-2.805 2.812 1.41 1.41 2.805-2.812z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
