import { VBCAuthVault } from '@vonage/auth-client-sdk';
import Cookies from 'js-cookie';

class AuthClientService {
  init() {
    let VBCAuthClient = VBCAuthVault;
    if (window.Cypress && window.AuthClient) {
      VBCAuthClient = window.AuthClient;
    }
    this._authVault = VBCAuthClient.getInstance(
      process.env.VUE_APP_AUTH_CLIENT_ENV
    );
  }

  async store(cookieName, cookieValue) {
    if (!this._authVault) {
      console.warn('VBCAuthClient is not initialized');
      return;
    }
    await this._authVault.store(
      `${process.env.VUE_APP_CREDENTIALS_MODE}_vbc_${cookieName}`,
      cookieValue
    );
  }

  async retrieve(cookieName) {
    if (!this._authVault) {
      console.warn('VBCAuthClient is not initialized');
      return Cookies.get(
        `${process.env.VUE_APP_CREDENTIALS_MODE}_vbc_${cookieName}`
      );
    }
    const cookie = await this._authVault.retrieve(
      `${process.env.VUE_APP_CREDENTIALS_MODE}_vbc_${cookieName}`
    );
    return (
      cookie?.value ||
      Cookies.get(`${process.env.VUE_APP_CREDENTIALS_MODE}_vbc_${cookieName}`)
    );
  }
}

export default new AuthClientService();
