var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-modal",
    {
      staticClass: "modal",
      attrs: {
        noFooter: "",
        dismiss: "",
        dismissEsc: "",
        dismissClickOutside: "",
        confirmEnter: "",
      },
      on: { close: _vm.dismiss },
    },
    [
      _c("template", { slot: "content" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c("div", { staticClass: "illustration" }, [
            _c("img", { attrs: { src: "/whiteboard-large-session.png" } }),
          ]),
          _c("div", { staticClass: "text-container" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("large_whiteboard_session_modal.title")) +
                  " "
              ),
            ]),
            _c("div", { staticClass: "text" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("large_whiteboard_session_modal.text")) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "buttons-container" },
              [
                _c("vwc-button", {
                  attrs: {
                    label: _vm.$t("large_whiteboard_session_modal.ok_button"),
                    layout: "filled",
                  },
                  on: { click: _vm.dismiss },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }