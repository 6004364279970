/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-megaphone': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.8.1c-.2-.1-.4-.1-.5-.1L8.9 3H3.5C1.6 3 0 4.6 0 6.5 0 8.3 1.4 9.8 3.2 10l2.5 4.9c.5 1 1.7 1.4 2.7.9 1-.5 1.4-1.7.9-2.7l-.9-1.8c-.1-.2-.4-.3-.7-.2-.2.1-.3.4-.2.7l.9 1.8c.3.4.1 1-.4 1.3-.5.3-1.1.1-1.3-.4L4.3 10h4.6l6.4 3c.2.1.3.1.5 0 .1-.1.2-.3.2-.4V.6c0-.3-.1-.4-.2-.5zM15 11.7L9.2 9H3.5C2.1 9 1 7.9 1 6.5S2.1 4 3.5 4h5.7L15 1.3v10.4z"/>'
  }
})
