<template>
  <div class="avatar-wrapper">
    <v-spinner v-if="isLoading" white />
    <ParticipantAvatar
      v-else
      :displayName="displayName"
      :participantType="participantType"
      :participantProfilePicture="participantProfilePicture"
      :avatarSize="avatarSize"
      :isBeRightBackOn="isBeRightBackOn"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'NoVideoParticipantTile',

  props: {
    displayName: {
      type: String,
      default: ''
    },
    participantType: {
      type: String,
      default: ''
    },
    participantProfilePicture: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    avatarSize: {
      type: String,
      default: 'relative'
    },
    isBeRightBackOn: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(['isBranded'])
  }
};
</script>

<style scoped>
.avatar-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  background: var(--avatar-wrapper-bg-color);
  z-index: 1;
  border: 1px solid #333333;
  border-radius: 8px;
}
</style>
