/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-tooltip-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 0h-21C.6 0 0 .6 0 1.5V18c0 .9.6 1.5 1.5 1.5h5.4l4.05 4.05c.3.3.6.45 1.05.45.45 0 .75-.15 1.05-.45l4.05-4.05h5.4c.9 0 1.5-.6 1.5-1.5V1.5c0-.9-.6-1.5-1.5-1.5z"/>'
  }
})
