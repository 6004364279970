var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vwc-dialog",
    {
      directives: [
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.l2.enter",
          value: _vm.confirmAction,
          expression: "confirmAction",
          modifiers: { l2: true, enter: true },
        },
      ],
      staticClass: "vvd-scheme-alternate dialog",
      attrs: {
        "data-cy": "confirmation-dialog",
        open: _vm.confirmationDialog,
        heading: _vm.confirmationDialog.title
          ? _vm.confirmationDialog.title
          : "",
        escapeKeyAction: _vm.hide,
      },
      on: {
        close: function (confirm) {
          return confirm ? _vm.confirmAction() : _vm.hide()
        },
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.confirmationDialog.text)),
        ]),
      ]),
      _c("vwc-button", {
        attrs: {
          slot: "primaryAction",
          "data-cy": "confirm-btn",
          label: _vm.confirmationDialog.okButtonText,
          layout: "filled",
          connotation: "primary",
        },
        on: { click: _vm.confirmAction },
        slot: "primaryAction",
      }),
      _c("vwc-button", {
        attrs: {
          slot: "secondaryAction",
          "data-cy": "cancel-btn",
          label: _vm.$t("confirmation_dialog_modal.cancel_button"),
          layout: "outlined",
        },
        on: { click: _vm.hide },
        slot: "secondaryAction",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }