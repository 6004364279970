<template>
  <popper
    ref="popper"
    trigger="clickToOpen"
    class="toggle-button-popper"
    :visible-arrow="false"
    :forceShow="flashing || isMouseOver"
    :options="{ placement: 'top' }"
  >
    <div
      v-show="(hoverTooltip && isMouseOver) || (popperText && flashing)"
      class="popper"
    >
      {{ popperText }}
    </div>

    <ControlBarButton
      slot="reference"
      class="toggle-button"
      :class="{ active, flashing, inactive: !active }"
      :iconName="iconName"
      :disabled="disabled"
      :transparent="transparent"
      @click="onClick"
      @animationend.native.stop="onAnimationEnd"
      @mouseenter.native="onMouseOver"
      @mouseleave.native="onMouseLeave"
    />
  </popper>
</template>

<script>
import ControlBarButton from './ControlBarButton';

export default {
  name: 'ControlBarToggleButton',

  components: {
    ControlBarButton
  },

  props: {
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: ''
    },
    transparent: {
      type: Boolean,
      default: false
    },
    hoverTooltip: {
      type: String,
      default: null
    },
    activationTooltip: {
      type: String,
      default: null
    },
    deactivationTooltip: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      flashing: false,
      isMouseOver: false
    };
  },

  computed: {
    popperText() {
      if (this.flashing) {
        if (this.active && this.activationTooltip) {
          return this.activationTooltip;
        } else if (!this.active && this.deactivationTooltip) {
          return this.deactivationTooltip;
        }
      }

      return this.hoverTooltip;
    }
  },

  watch: {
    popperText() {
      // Refresh the popper when text changes, otherwise the placement will be off
      this.$refs.popper.updatePopper();
    }
  },

  methods: {
    onClick(event) {
      if (!('click' in this.$listeners)) {
        return;
      }

      this.flashing = true;
      const clickListener = this.$listeners['click'];
      clickListener(event);
    },

    onAnimationEnd() {
      this.flashing = false;
    },

    onMouseOver() {
      this.isMouseOver = true;
    },

    onMouseLeave() {
      this.isMouseOver = false;
    }
  }
};
</script>

<style scoped>
.popper {
  background: #0d0d0d;
  border: 1px solid #4d4d4d;
  border-radius: 6px;
  padding: 6px 16px;
  white-space: nowrap;
  width: auto;
  z-index: 100;
  text-align: center;
  box-shadow: none;
  color: white;
  font-weight: 600;
}

.toggle-button-popper {
  position: relative;
  display: block;
}

.toggle-button-popper >>> .popper__arrow {
  border-color: #0c0d0e transparent transparent transparent;
  bottom: -6px !important;
}

.toggle-button.inactive >>> .button-icon {
  fill: #f75959;
}

.toggle-button.active >>> .button-icon {
  fill: #30a849;
}

.toggle-button.disabled >>> .button-icon {
  fill: #757575;
}

.toggle-button.flashing.active {
  animation: highlight-green 1.1s ease-out forwards;
}

.toggle-button.flashing.inactive {
  animation: highlight-red 1.1s ease-out forwards;
}

@keyframes highlight-green {
  25%,
  70% {
    background-color: #30a849;
  }

  100% {
    background-color: #0d0d0d;
  }
}

@keyframes highlight-red {
  25%,
  70% {
    background-color: #f75959;
  }

  100% {
    background-color: transparent;
  }
}

.toggle-button.flashing >>> .button-icon {
  fill: white;
  --expanded-scale: 1.5;
}

.mobile .toggle-button.flashing >>> .button-icon {
  --expanded-scale: 1.2;
}

.toggle-button.flashing.active >>> .button-icon {
  animation: shrink 1.1s ease-out forwards,
    turn-green 300ms ease-out 800ms forwards;
}

.toggle-button.flashing.inactive >>> .button-icon {
  animation: shrink 1.1s linear forwards, turn-red 300ms ease-out 800ms forwards;
}

@keyframes shrink {
  25%,
  70% {
    transform: scale(var(--expanded-scale));
  }

  100% {
    transform: scale(1);
  }
}

@keyframes turn-green {
  100% {
    fill: #32af4c;
  }
}

@keyframes turn-red {
  100% {
    fill: #f75959;
  }
}

.mobile .control-bar-button {
  background: transparent;
}
</style>
