<template>
  <popper
    ref="popper"
    trigger="clickToToggle"
    :visible-arrow="false"
    :options="{ placement: 'top-end' }"
    @show="onPopperShown"
    @hide="onPopperHidden"
  >
    <div v-show="hasMenuItems" class="popper menu-items-popper">
      <div class="menu-items-container">
        <slot />
      </div>
    </div>

    <div slot="reference" class="action-button-container">
      <vwc-button
        data-cy="custom-action-button"
        :label="title"
        :layout="destructive ? 'outlined' : 'text'"
        :connotation="destructive ? 'alert' : 'primary'"
        shape="rounded"
        stacked
        :icon="iconName"
        onmouseup="this.blur()"
        @click="$emit('click')"
      />
    </div>
  </popper>
</template>

<script>
export default {
  name: 'MeetingActionCustomMenuButton',

  props: {
    title: {
      type: String,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: ''
    },
    destructive: {
      type: Boolean,
      default: false
    },
    transparent: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      popperShown: false
    };
  },

  computed: {
    hasMenuItems() {
      return this.$slots.default;
    }
  },

  methods: {
    onPopperShown() {
      this.popperShown = this.hasMenuItems;
    },

    onPopperHidden() {
      this.popperShown = false;
    },

    close() {
      this.$refs.popper.doClose();
    }
  }
};
</script>

<style scoped>
@import '../style/bottom-bar-styles.scss';

.action-button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 0;
}

.menu-items-popper {
  background-color: #2a2a2a;
  border: unset;
  margin: unset;
  left: -70px;
}

.menu-items-container {
  padding: 10px;
  background-color: #2a2a2a;
}
</style>
