/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-paragraph-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 0H3c-.9 0-1.5.6-1.5 1.5v21c0 .9.6 1.5 1.5 1.5h18c.9 0 1.5-.6 1.5-1.5v-21C22.5.6 21.9 0 21 0zM4.5 21V3h15v18h-15zM12 4.5H6v6h6v-6zM13.5 6H18v1.5h-4.5V6zM18 9h-4.5v1.5H18V9zM6 13.5V12h12v1.5H6zM6 15v1.5h12V15H6zm0 3h7.5v1.5H6V18z"/>'
  }
})
