<template>
  <div class="audio-settings">
    <vwc-3-note
      v-if="isNoAudioMode"
      class="vvd-root vvd-theme-alternate note"
      connotation="information"
      icon="info-solid"
      :headline="$t('audio_settings.no-audio-note-title')"
    >
      <div class="note-content">
        {{ $t('audio_settings.no-audio-note-content') }}
        <vwc-button
          class="enable-audio-btn"
          layout="outlined"
          shape="pill"
          dense
          connotation="primary"
          :label="$t('audio_settings.no-audio-note-button')"
          @click="onEnableAudio"
        />
      </div>
    </vwc-3-note>
    <VVDSelect
      data-cy="mic-select"
      :label="$t('audio_settings.select_microphone_label')"
      :selected-value="currentMicrophone"
      :options="microphoneDevicesItems"
      :disabled="isNoAudioMode || !hasMicrophoneAccess"
      :max-height="20"
      dense
      shape="rounded"
      @selectedOption="onSelectMicrophone"
    />
    <VVDSelect
      data-cy="speaker-select"
      class="speaker-select"
      :label="$t('audio_settings.select_speaker_label')"
      :selected-value="currentSpeaker"
      :options="speakerDevicesItems"
      :disabled="isNoAudioMode || !speakerDevicesItems.length"
      :max-height="20"
      dense
      shape="rounded"
      @selectedOption="onSelectSpeaker"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import VVDSelect from '@/components/VVDSelect';
import {
  ANALYTICS_SOURCE,
  SETTING_ANALYTIC_TYPE
} from '@/consts/global-consts';
import { getAvailableDevices } from '@/helpers/devices-utils';

export default {
  name: 'AudioSettings',

  components: { VVDSelect },

  data() {
    return {
      currentMicrophone: '',
      currentSpeaker: ''
    };
  },

  computed: {
    ...mapState([
      'isNoAudioMode',
      'microphoneDevices',
      'speakerDevices',
      'selectedMicrophoneId',
      'selectedSpeakerId'
    ]),
    ...mapGetters(['hasMicrophoneAccess']),

    microphoneDevicesItems() {
      return getAvailableDevices(
        this.microphoneDevices,
        this.selectedMicrophoneId
      );
    },

    speakerDevicesItems() {
      return getAvailableDevices(this.speakerDevices, this.selectedSpeakerId);
    }
  },

  mounted() {
    // Set current microphone
    if (this.selectedMicrophoneId) {
      this.currentMicrophone = this.microphoneDevicesItems.find(
        (device) => device.id === this.selectedMicrophoneId
      ).label;
    } else {
      this.currentMicrophone = this.microphoneDevicesItems[0]?.label || '';
    }

    // Set current speaker
    if (this.selectedSpeakerId) {
      this.currentSpeaker = this.speakerDevicesItems.find(
        (device) => device.id === this.selectedSpeakerId
      ).label;
    } else {
      this.currentSpeaker = this.speakerDevicesItems[0]?.label || '';
    }
  },

  methods: {
    ...mapActions([
      'selectMicrophoneDevice',
      'selectSpeakerDevice',
      'setNoAudioMode'
    ]),
    ...mapActions('settings', ['sendSettingsAnalytics']),

    onSelectMicrophone(option) {
      this.currentMicrophone = option.label;
      this.selectMicrophoneDevice(option.id);
      this.sendSettingsAnalytics({
        setting: SETTING_ANALYTIC_TYPE.MICROPHONE,
        source: ANALYTICS_SOURCE.SETTINGS_MODAL
      });
    },

    onSelectSpeaker(option) {
      this.currentSpeaker = option.label;
      this.selectSpeakerDevice(option.id);
      this.sendSettingsAnalytics({
        setting: SETTING_ANALYTIC_TYPE.SPEAKER,
        source: ANALYTICS_SOURCE.SETTINGS_MODAL
      });
    },

    onEnableAudio() {
      this.setNoAudioMode(false);
    }
  }
};
</script>

<style scoped>
.note {
  margin-bottom: 16px;
}

.note-content {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.enable-audio-btn {
  align-self: flex-end;
}

.speaker-select {
  margin-top: 16px;
}
</style>
