<template>
  <div class="content">
    <div v-if="isBranded" class="brand-logo-container">
      <img
        v-if="brandImageColored"
        class="brand-logo"
        :src="brandImageColored"
      />
      <div v-else class="brand-text" data-cy="brand-text">{{ brandText }}</div>
    </div>
    <v-icon v-else iconName="Brand-icon-vonage" class="vonage-icon" />
    <h3 class="title">{{ title }}</h3>
    <span class="text">{{ $t('mobile_entrance_screen.text') }}</span>

    <VTextfield
      v-model="roomToken"
      data-cy="meetingIdInput"
      :placeholder="$t('mobile_entrance_screen.meeting_id_text')"
      class="input-box"
      :maxLength="roomTokenMaxLength"
      :disabled="inProgress || isPreEnteredRoomTokenMode"
      pattern="[0-9]+"
      :validationMessage="roomTokenErrorMessage"
      :helper="roomTokenErrorMessage"
      outlined
      focus
      autoValidate
      @input="updateRoomTokenErrorMessage"
      @submit="onJoin"
    />

    <VTextfield
      v-if="isGuest && isPreEnteredRoomTokenMode"
      v-model="displayName"
      data-cy="nameInput"
      :placeholder="$t('mobile_entrance_screen.name_text')"
      class="input-box"
      :maxLength="displayNameMaxLength"
      :disabled="inProgress"
      :validationMessage="displayNameErrorMessage"
      :focus="!!roomDetails.roomToken"
      outlined
      @submit="onJoin"
    />

    <vwc-button
      class="action-button"
      :label="$t('mobile_entrance_screen.join_meeting_button')"
      layout="filled"
      data-cy="join-meeting-button"
      connotation="cta"
      fullwidth
      :disabled="disableJoin"
      @click="onJoin"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import analytics from '@/services/analytics-service';
import {
  ROOM_SERVICE_ERROR_MESSAGES,
  DISPLAY_GUEST_NAME_MAX_LENGTH,
  ROOM_TOKEN_MAX_LENGTH,
  ANALYTICS
} from '@/consts/global-consts';
import * as utils from '@/helpers/meeting-helpers';
import { preventMobileLock } from '@/helpers/mobile-utils';
import { localStorageService } from '@/services/storage-service';

export default {
  name: 'MobileEntranceScreen',

  data() {
    return {
      roomToken: '',
      roomTokenErrorMessage: '',
      roomTokenMaxLength: ROOM_TOKEN_MAX_LENGTH,
      displayName: '',
      displayNameErrorMessage: '',
      displayNameMaxLength: DISPLAY_GUEST_NAME_MAX_LENGTH,
      preEnteredRoomToken: this.$store.state.roomDetails.roomToken,
      inProgress: false
    };
  },

  computed: {
    ...mapState('whitelabel', ['brandImageColored', 'brandText']),
    ...mapState(['roomDetails', 'isOriginallyAppUser', 'isBranded']),
    ...mapGetters(['isGuest', 'roomDisplayName', 'userDisplayName']),

    title() {
      return this.isBranded
        ? this.$t('mobile_entrance_screen.branded_title')
        : this.$t('mobile_entrance_screen.title');
    },

    disableJoin() {
      if (this.isPreEnteredRoomTokenMode && !this.isGuestNameValid) {
        return true;
      }

      return !this.isRoomTokenValid || this.inProgress;
    },

    isRoomTokenValid() {
      return (
        utils.validateRoomToken(this.roomToken) || this.roomDetails.roomToken
      );
    },

    isGuestNameValid() {
      // at least one non-space char
      return (
        (this.displayName.length && !/^\s+$/.test(this.displayName)) ||
        !this.isGuest
      );
    },

    isPreEnteredRoomTokenMode() {
      return !!this.preEnteredRoomToken.length;
    }
  },

  mounted() {
    const displayName = localStorageService.getItem('guestDisplayName');
    if (displayName) {
      this.displayName = displayName;
    }

    if (this.$route.query.invalid_room_token) {
      this.roomToken = this.$route.query.invalid_room_token;
      this.showError(ROOM_SERVICE_ERROR_MESSAGES.INVALID_TOKEN);
      this.preEnteredRoomToken = '';
    } else {
      this.roomToken = this.preEnteredRoomToken;
    }
  },

  methods: {
    ...mapActions([
      'setRoomToken',
      'getRoomDetails',
      'showPreJoinScreen',
      'initGuestMode',
      'handleRoomServiceError',
      'replaceRoute',
      'setGuestUserInfo',
      'resetGlobalMessage'
    ]),

    async onJoin() {
      if (this.disableJoin) {
        return;
      }
      this.inProgress = true;

      try {
        await this.setRoomToken(this.roomToken);
        if (this.isGuest && this.isPreEnteredRoomTokenMode) {
          this.setGuestUserInfo({ displayName: this.displayName.trim() });

          // Prevent mobile lock must be called in a flow that involves a user interaction, and that is why it must be used here
          preventMobileLock();

          await this.getRoomDetails();
          await this.resetGlobalMessage();
          this.showPreJoinScreen();

          analytics.trackEvent(ANALYTICS.JOINED, {
            Source: 'Entrance Screen'
          });
        } else {
          this.initGuestMode();
          this.replaceRoute({
            name: 'MobileJoinMeetingFromLinkScreen',
            params: { roomToken: this.roomToken }
          });
        }
      } catch (error) {
        this.handleRoomServiceError({
          meetingError: error.message,
          retryCallback: this.onJoin,
          defaultCallback: this.errorDefaultCallback
        });
        if (
          error.message === ROOM_SERVICE_ERROR_MESSAGES.DIFFERENT_ACCOUNT_ROOM
        ) {
          this.preEnteredRoomToken = this.roomToken;
          this.displayName = this.userDisplayName;
        } else if (
          error.message === ROOM_SERVICE_ERROR_MESSAGES.INVALID_TOKEN
        ) {
          this.errorDefaultCallback(error.message);
        }

        this.inProgress = false;
        // TODO: focus problematic field
      }
    },

    showError(roomServiceError) {
      switch (roomServiceError) {
        case ROOM_SERVICE_ERROR_MESSAGES.INVALID_TOKEN:
          this.roomTokenErrorMessage = this.$t(
            'mobile_entrance_screen.invalid_room_token_error'
          );
          break;
        default:
          this.roomTokenErrorMessage = this.$t(
            'mobile_entrance_screen.general_error'
          );
      }
    },

    updateRoomTokenErrorMessage() {
      this.roomTokenErrorMessage =
        this.isRoomTokenValid || !this.roomToken.length
          ? ''
          : this.$t('mobile_entrance_screen.invalid_meeting_id_error');
    },

    async errorDefaultCallback(errorMessage) {
      this.preEnteredRoomToken = '';
      await this.setRoomToken('');
      this.showError(errorMessage);
    }
  }
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: var(--viewport-height);
  overflow-y: auto;
  padding: 16px;
  background: #fff;
}

.content .input-box {
  width: 100%;
  margin-bottom: 4px;
}

.content .action-button {
  margin-top: 8px;
}

.vonage-icon {
  width: 120px;
  height: 100px;
  margin: 8px 0;
}

.brand-logo-container {
  margin: 25px 0;
}

.brand-text {
  font-size: 52px;
  font-weight: 600;
  line-height: initial;
  color: var(--entrance-screen-logo-bg);
}

.title {
  margin: 10px 0;
  font-size: 20px;
}

.text {
  font-size: 16px;
  margin-bottom: 24px;
}
</style>
