/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-click-to-call-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 15H6v-3H3V3h18v9h3V1.5A1.5 1.5 0 0022.5 0h-21A1.5 1.5 0 000 1.5v12c0 .828.67 1.5 1.5 1.5zm11.69 1.81l5.689 5.69L21 20.379l-5.69-5.69L19.5 12l-12-3 3 12 2.69-4.19z"/>'
  }
})
