var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "dialog",
      attrs: {
        "data-cy": "remove-participant-modal",
        heading: _vm.$t("remove_participant_modal.title"),
        "icon-name": "remove-user-line",
        close: _vm.hide,
        "min-width": 424,
        "primary-action": _vm.$t("remove_participant_modal.ok_button_text"),
        primaryButtonConnotation: "alert",
        open: _vm.participantToRemove,
        isSmallModal: "",
      },
      on: {
        applied: _vm.removeParticipantAndHideModal,
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content", attrs: { slot: "content" }, slot: "content" },
        [
          _c("div", { staticClass: "text" }, [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("remove_participant_modal.text", {
                    participantDisplayName:
                      _vm.participantToRemove.participantDisplayName,
                  })
                ) +
                " "
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }