/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-puzzled': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.4 12C1.4 6.146 6.146 1.4 12 1.4c5.854 0 10.6 4.746 10.6 10.6 0 5.854-4.746 10.6-10.6 10.6-5.854 0-10.6-4.746-10.6-10.6zM12 .1C5.428.1.1 5.428.1 12S5.428 23.9 12 23.9 23.9 18.572 23.9 12 18.572.1 12 .1zM9 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm7.5 1.5a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-.509 4.354a.65.65 0 10-.482-1.207l-7.5 3a.65.65 0 10.482 1.207l7.5-3z"/>'
  }
})
