var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("youtube", {
    ref: "youtube",
    attrs: { "video-id": _vm.videoId, "player-vars": _vm.playerVars },
    on: {
      playing: _vm.onPlaying,
      paused: _vm.onPaused,
      ended: _vm.onPaused,
      ready: _vm.onReady,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }