import { LARGE_MEETING_SIZE } from '@/consts/global-consts';
import { isElectron } from '@/helpers/global-helpers';

export default {
  allowExclusiveExperimentalMode: () => {
    return !window.isProduction;
  },

  exclusiveExperimentalMode: (state, getters) => {
    return (
      getters.allowExclusiveExperimentalMode && state.exclusiveExperimentalMode
    );
  },

  disableJoinOrLeaveSound: (state, getters, rootState, rootGetters) =>
    rootGetters.activeParticipants.length >= LARGE_MEETING_SIZE,

  disableWaitingRoomSoundNotification: (
    state,
    getters,
    rootState,
    rootGetters
  ) => {
    return !rootGetters.isWaitingRoomEnabled;
  },

  isOpenWebRTCInternalsFunctionalityAvailable: () => {
    return !window.isProduction && isElectron();
  }
};
