/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-toggle': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3.854 9.146a.5.5 0 010 .708L1.207 12.5l2.647 2.646a.5.5 0 01-.708.708l-3-3a.5.5 0 010-.708l3-3a.5.5 0 01.708 0z"/><path pid="1" d="M15.5 8a.5.5 0 01.5.5v4a.5.5 0 01-.5.5H.5a.5.5 0 010-1H15V8.5a.5.5 0 01.5-.5zM12.146.146a.5.5 0 01.708 0l3 3a.5.5 0 010 .708l-3 3a.5.5 0 01-.708-.708L14.793 3.5 12.146.854a.5.5 0 010-.708z"/><path pid="2" d="M0 3.5A.5.5 0 01.5 3h15a.5.5 0 010 1H1v3.5a.5.5 0 01-1 0v-4z"/>'
  }
})
