<template>
  <Dialog
    data-cy="turn-on-audio-modal"
    class="dialog"
    :heading="$t('turn_on_audio_modal.title')"
    icon-name="audio-max-line"
    :close="close"
    :min-width="430"
    :primary-action="$t('turn_on_audio_modal.turn_on_button')"
    :secondary-action="$t('turn_on_audio_modal.close_button')"
    :open="isTurnOnAudioDialogVisible"
    isSmallModal
    @applied="turnOnAudioMode"
    @click.stop
  >
    <div slot="content" class="content">
      <div class="text">
        {{ $t('turn_on_audio_modal.content_text') }}
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Dialog from '@/components/Dialog';

export default {
  name: 'TurnOnAudioModal',

  components: { Dialog },

  computed: {
    ...mapState(['isTurnOnAudioDialogVisible'])
  },

  methods: {
    ...mapActions(['setIsTurnOnAudioDialogVisible', 'setNoAudioMode']),

    turnOnAudioMode() {
      this.setNoAudioMode(false);
      this.setIsTurnOnAudioDialogVisible(false);
    },

    close() {
      this.setNoAudioMode(true);
      this.setIsTurnOnAudioDialogVisible(false);
    }
  }
};
</script>

<style scoped></style>
