/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-thumbs-up-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" class="st0" d="M13.3 6H9V2c0-1.5-.8-2-2-2-.3 0-.6.2-.6.5C6.4.5 4.1 8 4 8v8h8.6c1.3 0 2.4-1 2.6-2.3l.8-4.6c.1-.8-.1-1.6-.6-2.1-.5-.7-1.3-1-2.1-1zM2 8H0v8h2V8z"/>'
  }
})
