/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-lighthouse': {
    width: 16,
    height: 16,
    viewBox: '0 0 30 30',
    data: '<path pid="0" d="M25.2 20.3c-.8 0-1.5.2-2.1.6-.1-2.3-2-4.1-4.3-4.1-1.3 0-2.5.6-3.3 1.5V16h-.6v-4.7h-.6V6.7h-.6V4.6c0-1.7-1.4-3.1-3.1-3.1S7.5 2.9 7.5 4.6v2.1h-.6v4.7h-.6v4.7h-.6v2.2c-.1 0-.3.1-.4.1-.6-1.6-2.2-2.7-4-2.7-.4 0-.8.4-.8.8s.4.8.8.8c1.4 0 2.6 1.1 2.7 2.5 0 .7.9 1 1.4.5.1-.1.3-.2.4-.3v1.3h-.6v6.1h.2v1.4h10.5v-1.4h.2v-6.1h-.6v-1c.3.1.7-.1.9-.4.5-.9 1.4-1.5 2.4-1.5 1.5 0 2.7 1.2 2.7 2.7 0 .2 0 .4-.1.6-.1.3 0 .6.3.8l.3.3c.3.4.9.4 1.2 0 .5-.6 1.2-.9 2-.9 1.5 0 2.7 1.2 2.7 2.7 0 .4.4.8.8.8s.8-.4.8-.8c0-2.4-1.9-4.3-4.3-4.3zM9.1 4.6c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5v2.1h-3V4.6zm-.6 3.8h4.2v3H8.5v-3zM7.9 13h5.4v3H7.9v-3zm-.5 4.7h6.5v3.6H7.4v-3.6zm7.1 9.4H6.8v-4.2h7.7v4.2zM16.7 4.8l8.2-2c.4-.1.7-.5.6-1-.1-.4-.5-.7-1-.6l-8.2 2c-.4.1-.7.5-.6 1 .1.5.5.7 1 .6M24 5.4h-5.2c-.4 0-.8.4-.8.8s.4.8.8.8H24c.4 0 .8-.4.8-.8s-.3-.8-.8-.8"/><path pid="1" d="M16.3 9.3l8.2 2c.4.1.9-.2 1-.6.1-.4-.2-.9-.6-1l-8.2-2c-.4-.1-.9.2-1 .6-.1.5.1.9.6 1"/>'
  }
})
