import axios from 'axios';
import VideoMetadata from '@/services/watch-together/video-metadata';
import VideoSiteTypeEnum from '@/services/watch-together/video-site-type-enum';
import {
  VideoUrlValidationError,
  VIDEO_URL_ERROR_REASON
} from '@/services/watch-together/video-url-validation-error';

export function getDailyMotionIdByUrl(url) {
  var matches = url.match(
    /^.+dailymotion.com\/(video|hub)\/([^_]+)[^#]*(#video=([^_&]+))?/
  );
  if (matches !== null) {
    if (matches[4] !== undefined) {
      return matches[4];
    }
    return matches[2];
  }
  return null;
}

export async function getDailymotionVideoMetadata(videoUrl) {
  const videoId = getDailyMotionIdByUrl(videoUrl);
  const videoMetadataResponse = await axios.get(
    `https://api.dailymotion.com/video/${videoId}?fields=title,allow_embed,duration,owner.screenname,thumbnail_480_url`
  );
  const videoMetadata = videoMetadataResponse.data;
  const thumbnailUrl = videoMetadata.thumbnail_480_url;
  const author = videoMetadata['owner.screenname'];

  if (!videoMetadata.allow_embed) {
    throw new VideoUrlValidationError(
      videoUrl,
      VIDEO_URL_ERROR_REASON.VIDEO_NOT_EMBEDDABLE
    );
  }

  if (videoMetadata.onair) {
    throw new VideoUrlValidationError(
      videoUrl,
      VIDEO_URL_ERROR_REASON.LIVE_VIDEO
    );
  }

  return new VideoMetadata(
    VideoSiteTypeEnum.Dailymotion,
    videoId,
    videoMetadata.title,
    videoMetadata.duration,
    thumbnailUrl,
    author
  );
}
