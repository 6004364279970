/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11.5 7.5c0 .3.2.5.5.5s.5-.2.5-.5c0-2.2-1.8-4-4-4-.3 0-.5.2-.5.5s.2.5.5.5c1.7 0 3 1.3 3 3z"/><path pid="1" d="M8.5.5C8.2.5 8 .7 8 1s.2.5.5.5c3.3 0 6 2.7 6 6 0 .3.2.5.5.5s.5-.2.5-.5c0-3.9-3.1-7-7-7zM3.6 2c.3 0 .5.2.6.3.3.4 1.4 2 1.4 2.7 0 .7-.3 1.1-.9 1.5 0 0 .2 1.2 1.9 2.9 1.5 1.6 2.9 1.9 2.9 1.9s.3-.8 1.1-.9h.3c.7 0 1.4.4 2.8 1.5.5.4-.1 2.2-1.6 2.5h-.3c-1.6 0-4.3-1.5-6.3-3.6-1.8-1.9-3.3-4-3.7-6.2C1.3 2.5 3.1 2.1 3.4 2h.2m0-1h-.4C1.3 1.5.4 2.9.8 4.8c.4 2.1 1.7 4.3 3.9 6.7 2.1 2.2 5.1 3.9 7 3.9h.4c1.4-.2 2.3-1.3 2.6-2.4.2-.8 0-1.5-.5-1.9-1.5-1.2-2.4-1.7-3.4-1.7h-.5c-.6.1-1 .4-1.3.7-.3-.2-1-.6-1.7-1.4-.8-.8-1.2-1.4-1.4-1.9.4-.4.8-1 .7-1.9 0-.9-.9-2.2-1.6-3.2-.3-.4-.8-.7-1.4-.7z"/>'
  }
})
