import {
  UPLOAD_ERRORS_TYPES,
  MAX_NUMBER_OF_FILES_TO_UPLOAD,
  MAX_SIZE_OF_FILES_TO_UPLOAD,
  UNSUPPORTED_ATTACHMENTS_FILES,
  SUPPORTED_IMAGES_TYPES,
  ATTACHMENT_TYPE,
  MEDIA_TYPE
} from '@/consts/messaging';

export function isFileUnsupported(file) {
  return (
    file.isDirectory ||
    UNSUPPORTED_ATTACHMENTS_FILES.findIndex(
      (type) => type === file.name.split('.').pop().toLowerCase().trim()
    ) > -1
  );
}

export function getFilesFromDragAndDropEvent(event) {
  // Convert FileList object to Array. It's easier to work with
  const files = [];
  for (let index = 0; index < event.dataTransfer.files.length; index++) {
    const file = event.dataTransfer.files[index];
    const item = event.dataTransfer.items[index];
    file.isDirectory = item.webkitGetAsEntry().isDirectory;
    files.push(file);
  }
  return files;
}

export function validateFiles(files) {
  const result = { attachments: files };

  if (files.length === 0) {
    return;
  }
  const totalSize = files.reduce(
    (total, attachment) => total + attachment.size,
    0
  );
  const unsupportedFiles = files.filter(isFileUnsupported);

  if (unsupportedFiles.length === files.length) {
    result.error = {
      name: UPLOAD_ERRORS_TYPES.TYPE_NOT_SUPPORTED,
      data: {
        unsupportedFiles
      }
    };
  } else if (files.length > MAX_NUMBER_OF_FILES_TO_UPLOAD) {
    result.error = {
      name: UPLOAD_ERRORS_TYPES.MAX_NUMBER_OF_FILES_EXCEEDED,
      data: {
        count: files.length
      }
    };
  } else if (totalSize > MAX_SIZE_OF_FILES_TO_UPLOAD) {
    result.error = {
      name: UPLOAD_ERRORS_TYPES.TOTAL_SIZE_EXCEEDED,
      data: {
        size: totalSize
      }
    };
  }
  return result;
}

export function getFilesFromClipboardPasteEvent(clipboardPasteEvent) {
  const clipboardData =
    clipboardPasteEvent.clipboardData ||
    clipboardPasteEvent.originalEvent.clipboardData;
  if (!clipboardData) {
    return;
  }
  const filesToSend = [];

  // Interate over all the items in the clipboard data and send only the files.
  for (
    let clipboardItemIndex = 0;
    clipboardItemIndex < clipboardData.items.length;
    clipboardItemIndex++
  ) {
    const item = clipboardData.items[clipboardItemIndex];
    if (item.kind === 'file') {
      const clipboardFile = item.getAsFile();
      if (clipboardFile) {
        filesToSend.push(clipboardFile);
      }
    }
  }

  return filesToSend;
}

export function getFileIcon(file) {
  if (file.name.endsWith('.doc') || file.name.endsWith('.docx')) {
    return 'Vlt-icon-file-doc';
  } else if (file.name.endsWith('.pdf')) {
    return 'Vlt-icon-file-pdf';
  } else if (file.name.endsWith('.ppt') || file.name.endsWith('.pptx')) {
    return 'Vlt-icon-file-ppt';
  } else if (file.name.endsWith('.xls') || file.name.endsWith('.xlsx')) {
    return 'Vlt-icon-file-xls';
  } else if (file.name.endsWith('.zip')) {
    return 'Vlt-icon-file-zip';
  } else if (
    file.type.toLowerCase().includes('video') ||
    file.name.endsWith('.mp4')
  ) {
    return 'Vlt-icon-file-video';
  } else if (file.type.toLowerCase().includes('image')) {
    return 'Vlt-icon-image';
  }
  return 'Vlt-icon-file';
}

/** Resize existing image according to a maximum while keeping the ratio (unless the image is
 * too small).
 * params:
 * maxSize - maximum size for width and height.
 * minHeight - minimum height for the image
 * minWidth - minimum width for the image
 * return: canvas object
 */
function getResizedCanvasFromImage(attachment, maxSize, minHeight, minWidth) {
  const canvas = document.createElement('canvas');
  const img = new Image();
  img.src = URL.createObjectURL(attachment);
  return new Promise((resolve) => {
    img.onload = () => {
      let scale = 1;
      if (img.width > maxSize || img.height > maxSize) {
        scale = maxSize / Math.max(img.height, img.width);
      }
      let width = img.width * scale;
      let height = img.height * scale;

      // We need a minimum height and width for displaying the thumbnail correctly
      // in the chats.
      if ((minHeight && minHeight > height) || (minWidth && minWidth > width)) {
        const widthScale = minWidth / width;
        const heightScale = minHeight / height;
        height = Math.min(height * Math.max(heightScale, widthScale), maxSize);
        width = Math.min(width * Math.max(heightScale, widthScale), maxSize);
      }
      canvas.height = height;
      canvas.width = width;
      const ctx = canvas.getContext('2d');

      // Needed for png and maybe other image types
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, width, height);
      ctx.drawImage(img, 0, 0, width, height);
      URL.revokeObjectURL(img.src);
      resolve(canvas);
    };
  });
}

/** Get a resized image for performance purposes.
 * param: attachment file.
 * return: new image file
 */
async function getSmallerImage(attachment) {
  const newName = `${attachment.name.split('.').slice(0, -1).join('.')}.jpeg`;
  const canvas = await getResizedCanvasFromImage(attachment, 2048);
  return new Promise((resolve) => {
    canvas.toBlob(
      (blob) => {
        // Edge doesn't support the File API, therefore we use the blob directly.
        if (window.isEdge) {
          const imgFile = blob;
          imgFile.name = newName;
          resolve(imgFile);
        } else {
          const imgFile_1 = new File([blob], newName, {
            type: 'image/jpeg',
            lastModified: Date.now()
          });
          resolve(imgFile_1);
        }
      },
      'image/jpeg',
      0.92
    );
  });
}

/** Get thumbnail from image file
 * param: attachment - Image File
 * return: Data URL object
 */
async function getImageThumbnail(attachment) {
  const canvas = await getResizedCanvasFromImage(attachment, 240, 80, 120);
  const thumbnail = canvas.toDataURL('image/jpeg', 0.92).split(',').pop();
  return thumbnail;
}

function getAttachmentType(file) {
  const extension = file.name.trim().split('.').pop().toLowerCase();

  if (file.type.indexOf('application') > -1) {
    return ATTACHMENT_TYPE.DOCUMENT;
  } else if (SUPPORTED_IMAGES_TYPES.includes(extension)) {
    return ATTACHMENT_TYPE.IMAGE;
  } else if (extension === 'exe') {
    return ATTACHMENT_TYPE.NOT_SUPPORTED;
  }
  return ATTACHMENT_TYPE.DOCUMENT;
}

export async function getAttachmentMessageData(attachment) {
  const result = {};
  const attachmentType = getAttachmentType(attachment);

  result.data = [
    {
      metadata: {
        fileName: attachment.name,
        size: attachment.size,
        attachmentType,
        mimeType: attachment.type
      }
    }
  ];
  result.attachmentsCount = 1;
  result.originalAttachmentFile = attachment;

  if (attachmentType === ATTACHMENT_TYPE.IMAGE) {
    const [thumbnail, resizedImage] = await Promise.all([
      getImageThumbnail(attachment),
      getSmallerImage(attachment)
    ]);
    if (resizedImage) {
      // Update attachment with resized image and new fields details
      result.data[0].thumbnail = thumbnail;
      result.originalAttachmentFile = resizedImage;
      result.data[0].metadata.info = { mime_type: resizedImage.type };
      result.data[0].metadata.fileName = resizedImage.name;
    }
  }
  return result;
}

export function buildAttachmentFieldsForSending(
  attachment,
  s3Details,
  thumbnail
) {
  // Construct attachment fields for back-end
  const data = JSON.stringify([
    {
      metadata: JSON.stringify({
        attachmentType: getAttachmentType(attachment),
        info: {
          data_size: attachment.size,
          name: attachment.name,
          mime_type: attachment.type
        }
      }),
      thumbnail
    }
  ]);

  const attachments = [
    {
      key: s3Details.Key,
      fileName: attachment.name,
      mimeType: attachment.type,
      size: attachment.size,
      bucket: s3Details.Bucket,
      attachmentCreationTime: 0
    }
  ];

  return { attachments, data };
}

export function getMediaType(attachment) {
  if (!attachment) {
    return undefined;
  }
  const attachmentType = getAttachmentType(attachment);
  if (attachmentType === ATTACHMENT_TYPE.IMAGE) {
    return MEDIA_TYPE.IMAGE;
  } else if (attachmentType === ATTACHMENT_TYPE.DOCUMENT) {
    if (
      attachment.type.toLowerCase().includes('video') ||
      attachment.name.endsWith('.mp4')
    ) {
      return MEDIA_TYPE.VIDEO;
    } else {
      return MEDIA_TYPE.FILE;
    }
  }
  return undefined;
}

export function formatBytes(bytes, decimals = 0) {
  if (!+bytes) return '0B';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))}${sizes[i]}`;
}
