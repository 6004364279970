/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-snooze-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M13.5 1.5A1.5 1.5 0 0015 3h3.879l-4.94 4.94A1.5 1.5 0 0015 10.5h7.5a1.5 1.5 0 100-3h-3.879l4.94-4.94A1.5 1.5 0 0022.5 0H15a1.5 1.5 0 00-1.5 1.5zM0 13.35C0 8.4 3.3 4.2 7.8 3 5.7 10.95 13.05 18.3 21 16.2c-1.35 4.5-5.4 7.8-10.35 7.8C4.8 24 0 19.2 0 13.35z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
