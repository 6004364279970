/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-5': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 25',
    data: '<path pid="0" d="M3 3c0-.9.6-1.5 1.5-1.5S6 2.1 6 3s-.6 1.5-1.5 1.5S3 3.9 3 3zm1.5-3c-1.65 0-3 1.35-3 3s1.35 3 3 3 3-1.35 3-3-1.35-3-3-3zm9.75 9H7.5v14.25c0 .45-.3.75-.75.75S6 23.7 6 23.25V9H2.25c-.075 0-.188.037-.3.075a1.16 1.16 0 01-.3.075c0 .075-.037.188-.075.3-.037.113-.075.225-.075.3v13.5c0 .45-.3.75-.75.75S0 23.7 0 23.25V9.75c0-.6.3-1.2.6-1.65.45-.3 1.05-.6 1.65-.6h12c.45 0 .75.3.75.75s-.3.75-.75.75zm-3-6c-.45 0-.75.3-.75.75s.3.75.75.75H22.5v12H11.25c-.45 0-.75.3-.75.75s.3.75.75.75h4.05l2.85 5.7c.3.3.75.45 1.05.3.3-.3.45-.75.3-1.05L17.025 18h6.225c.45 0 .75-.3.75-.75V3.75c0-.45-.3-.75-.75-.75H15V.75c0-.45-.3-.75-.75-.75s-.75.3-.75.75V3h-2.25z"/>'
  }
})
