/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-tag-add-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M1.5 24h9a1.5 1.5 0 100-3H3V9.621l6-6 4.94 4.94a1.498 1.498 0 002.12 0 1.498 1.498 0 000-2.121l-6-6a1.498 1.498 0 00-2.12 0l-7.5 7.5C.156 8.22 0 8.6 0 9v13.5c0 .83.672 1.5 1.5 1.5zm18-7.5H24v3h-4.5V24h-3v-4.5H12v-3h4.5V12h3v4.5z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
