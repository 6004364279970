var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.counter
    ? _c(
        "span",
        { staticClass: "counter-badge", style: { width: _vm.width } },
        [_vm._v(" " + _vm._s(_vm.counter) + " ")]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }