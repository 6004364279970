<template>
  <div
    class="frameless-titlebar unselectable"
    :class="[
      titlebarPlatform === OS_TYPES.WINDOWS ? 'windows' : 'mac',
      black ? 'black' : 'white'
    ]"
    :style="titlebarStyle"
  >
    <div v-if="titlebarPlatform === OS_TYPES.WINDOWS" class="buttons-container">
      <button
        v-if="showMinimizeButton"
        class="titlebar-btn minimize-btn"
        @click="$emit('minimize')"
      >
        <div class="icon"></div>
      </button>
      <button
        v-if="showMaximizeButton"
        class="titlebar-btn"
        :class="{ 'restore-btn': isMaximized, 'maximize-btn': !isMaximized }"
        @click="$emit('maximize')"
      >
        <div class="icon"></div>
      </button>
      <button
        v-if="showCloseButton"
        class="titlebar-btn close-btn"
        @click="$emit('close')"
      >
        <div class="icon right"></div>
        <div class="icon left"></div>
      </button>
    </div>
    <div
      v-else
      class="buttons-container"
      @mouseenter="showIcons = true"
      @mouseleave="showIcons = false"
    >
      <div
        v-if="showMaximizeButton"
        class="titlebar-btn maximize-btn"
        @click="$emit('maximize')"
      >
        <v-icon v-if="showIcons" class="icon" iconName="fullscreen-mac-icon" />
      </div>
      <div
        v-if="showMinimizeButton"
        class="titlebar-btn minimize-btn"
        @click="$emit('minimize')"
      >
        <v-icon v-if="showIcons" class="icon" iconName="Vlt-icon-minus-bold" />
      </div>
      <div
        v-if="showCloseButton"
        class="titlebar-btn close-btn"
        @click="$emit('close')"
      >
        <v-icon v-if="showIcons" class="icon" iconName="Vlt-icon-cross-full" />
      </div>
    </div>
  </div>
</template>

<script>
import { OS_TYPES } from '@/consts/global-consts';
import { OS } from '@/helpers/global-helpers';
export default {
  name: 'FramelessTitlebar',

  props: {
    platform: {
      type: String,
      default: OS.name
    },
    height: {
      type: Number,
      default: 40
    },
    showCloseButton: {
      type: Boolean,
      default: true
    },
    showMinimizeButton: {
      type: Boolean,
      default: true
    },
    showMaximizeButton: {
      type: Boolean,
      default: true
    },
    // Windows buttons only
    black: {
      type: Boolean,
      default: false
    },
    // Windows only
    isMaximized: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      OS_TYPES
    };
  },

  computed: {
    titlebarPlatform() {
      return this.platform === OS_TYPES.WINDOWS
        ? OS_TYPES.WINDOWS
        : OS_TYPES.MAC;
    },

    titlebarStyle() {
      return {
        height: `${this.height}px`
      };
    }
  }
};
</script>

<style scoped>
.frameless-titlebar {
  display: flex;
  align-items: center;
}

.frameless-titlebar.windows {
  direction: ltr;
}

.frameless-titlebar.mac {
  direction: rtl;
}

.frameless-titlebar .buttons-container {
  display: flex;
  flex-direction: row;
}

.frameless-titlebar .buttons-container .titlebar-btn {
  background: transparent;
  border: 0;
  outline: none;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-app-region: no-drag;
}

.frameless-titlebar.mac .buttons-container .titlebar-btn {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin: 4px;
}

.frameless-titlebar.mac .buttons-container .titlebar-btn.close-btn {
  background: #ed6a5e;
}

.frameless-titlebar.mac .buttons-container .titlebar-btn.minimize-btn {
  background: #f6be4f;
}

.frameless-titlebar.mac .buttons-container .titlebar-btn.maximize-btn {
  background: #62c554;
}

.frameless-titlebar.mac .buttons-container .titlebar-btn .icon {
  width: 6px;
}

.frameless-titlebar.windows .buttons-container .titlebar-btn {
  width: 40px;
  height: 40px;
  margin-left: 8px;
}

.frameless-titlebar.windows
  .buttons-container
  .titlebar-btn.minimize-btn
  .icon {
  width: 14px;
  height: 1px;
}

.frameless-titlebar.windows .buttons-container .titlebar-btn.close-btn .icon {
  width: 15px;
  height: 1px;
  position: absolute;
}

.frameless-titlebar.windows
  .buttons-container
  .titlebar-btn.close-btn
  .icon.right {
  transform: rotate(-45deg);
}

.frameless-titlebar.windows
  .buttons-container
  .titlebar-btn.close-btn
  .icon.left {
  transform: rotate(45deg);
}

.frameless-titlebar.windows .buttons-container .maximize-btn .icon,
.frameless-titlebar.windows .buttons-container .restore-btn .icon {
  width: 14px;
  height: 14px;
}

.frameless-titlebar.windows.white .buttons-container .maximize-btn .icon,
.frameless-titlebar.windows.white .buttons-container .restore-btn .icon::before,
.frameless-titlebar.windows.white .buttons-container .restore-btn .icon::after {
  border: 1px solid #ffffff;
}

.frameless-titlebar.windows .buttons-container .restore-btn .icon {
  position: relative;
}

.frameless-titlebar.windows .buttons-container .restore-btn .icon::before,
.frameless-titlebar.windows .buttons-container .restore-btn .icon::after {
  content: '';
  display: block;
  position: absolute;
  width: 10px;
  height: 10px;
}

.frameless-titlebar.windows .buttons-container .restore-btn .icon::before {
  top: 1px;
  left: 2px;
}

.frameless-titlebar.windows .buttons-container .restore-btn .icon::after {
  top: 3px;
  left: 0px;
}

.frameless-titlebar.windows.white .buttons-container .restore-btn .icon::after {
  background: #000;
}

.frameless-titlebar.windows.white .buttons-container .minimize-btn .icon,
.frameless-titlebar.windows.white .buttons-container .close-btn .icon {
  background: #fff;
}

.frameless-titlebar.windows.black .buttons-container .restore-btn .icon::after {
  background: #fff;
}

.frameless-titlebar.windows.black .buttons-container .maximize-btn .icon,
.frameless-titlebar.windows.black .buttons-container .restore-btn .icon::before,
.frameless-titlebar.windows.black .buttons-container .restore-btn .icon::after {
  border: 1px solid #000;
}

.frameless-titlebar.windows.black .buttons-container .minimize-btn .icon,
.frameless-titlebar.windows.black .buttons-container .close-btn .icon {
  background: #000;
}

.frameless-titlebar.windows.white
  .buttons-container
  .titlebar-btn:not(.close-btn):hover {
  background-color: #1a1a1a;
}

.frameless-titlebar.windows.white
  .buttons-container
  .titlebar-btn.minimize-btn:active,
.frameless-titlebar.windows.white
  .buttons-container
  .titlebar-btn.maximize-btn:active {
  background-color: #323232;
}

.frameless-titlebar.windows .buttons-container .titlebar-btn.close-btn:hover {
  background-color: #e91024;
}

.frameless-titlebar.windows.black
  .buttons-container
  .titlebar-btn:not(.close-btn):hover {
  background-color: #e5e5e5;
}

.frameless-titlebar.windows.black
  .buttons-container
  .titlebar-btn.minimize-btn:active,
.frameless-titlebar.windows.black
  .buttons-container
  .titlebar-btn.maximize-btn:active {
  background-color: #cacaca;
}

.frameless-titlebar.windows.black
  .buttons-container
  .titlebar-btn.close-btn:hover
  .icon {
  background-color: #fff;
}
</style>
