/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cursor-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 7.468a1.501 1.501 0 00-1.09-1.41l-20.998-6A1.5 1.5 0 00.058 1.912l6 20.999a1.504 1.504 0 001.41 1.087c.685.035 1.249-.402 1.456-1.026l2.233-6.697 7.282 7.284a1.496 1.496 0 002.121 0l3-3a1.498 1.498 0 000-2.121l-7.282-7.284 6.697-2.231a1.5 1.5 0 001.024-1.455z"/>'
  }
})
