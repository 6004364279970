/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-share-1-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M13.5 16.5V6H18l-6-6-6 6h4.5v10.5h3zM1.5 24h21a1.497 1.497 0 001.488-1.686l-1.5-12A1.499 1.499 0 0021 9h-4.5v3h3.175l1.125 9H3.2l1.124-9H7.5V9H3c-.756 0-1.395.563-1.49 1.314l-1.5 12A1.502 1.502 0 001.5 24z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
