import o9n from 'o9n';

export const MOBILE_SCREEN_ORIENTATION = Object.freeze({
  PORTRAIT: 'portrait',
  LANDSCAPE_RIGHT: 'landscape-right', // the screen was tilted rightward
  LANDSCAPE_LEFT: 'landscape-left' // the screen was tilted leftward
});

/**
 * Wrapper for tracking the orientation of mobile devices.
 * Currently implemented with the o9n package, but could be changed in the future if mobile browsers
 * add official screen orientation API support
 */
class MobileScreenOrientationTracker {
  constructor() {
    this.orientationChangeListeners = [];
    this.currentOrientation = this.getCurrentOrientation();
    o9n.orientation.addEventListener('change', () =>
      this.handleOrientationChanged()
    );
  }

  handleOrientationChanged() {
    this.currentOrientation = this.getCurrentOrientation();
    this.orientationChangeListeners.forEach((listener) => listener());
  }

  getCurrentOrientation() {
    switch (o9n.orientation.type) {
      case 'portrait-primary':
      case 'portrait-secondary': {
        return MOBILE_SCREEN_ORIENTATION.PORTRAIT;
      }
      case 'landscape-primary': {
        return MOBILE_SCREEN_ORIENTATION.LANDSCAPE_LEFT;
      }
      case 'landscape-secondary': {
        return MOBILE_SCREEN_ORIENTATION.LANDSCAPE_RIGHT;
      }
    }
  }

  addOrientationChangeListener(callback) {
    this.orientationChangeListeners.push(callback);
  }

  removeOrientationChangeListener(callback) {
    this.orientationChangeListeners.splice(
      this.orientationChangeListeners.indexOf(callback),
      1
    );
  }
}

export const mobileScreenOrientationTracker = window.Cypress
  ? window.OrientationTrackerMock || new MobileScreenOrientationTracker()
  : new MobileScreenOrientationTracker();
