/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-notes-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5 0h9v6h-9V0zM18 1.5h4.5c.9 0 1.5.6 1.5 1.5v19.5c0 .9-.6 1.5-1.5 1.5h-21C.6 24 0 23.4 0 22.5V3c0-.9.6-1.5 1.5-1.5H6v3H3V21h18V4.5h-3v-3zM18 9H6v3h12V9zM6 15h12v3H6v-3z"/>'
  }
})
