/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cross-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 1.067a6.933 6.933 0 100 13.866A6.933 6.933 0 008 1.067zM0 8a8 8 0 1116 0A8 8 0 010 8zm11.577-3.577a.533.533 0 010 .754L8.754 8l2.823 2.823a.533.533 0 11-.754.754L8 8.754l-2.823 2.823a.533.533 0 11-.754-.754L7.246 8 4.423 5.177a.533.533 0 11.754-.754L8 7.246l2.823-2.823a.533.533 0 01.754 0z"/>'
  }
})
