/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-unlock': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 9c-1.1 0-2 .9-2 2s.9 2 2 2 2-.9 2-2-.9-2-2-2zm0 3c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1z"/><path pid="1" d="M13.5 6H5V4c0-1.7 1.3-3 3-3 1.4 0 2.5.9 2.9 2.1.1.3.4.4.6.3.3-.1.4-.4.3-.6C11.3 1.2 9.8 0 8 0 5.8 0 4 1.8 4 4v2H2.5C1.7 6 1 6.7 1 7.5v7c0 .8.7 1.5 1.5 1.5h11c.8 0 1.5-.7 1.5-1.5v-7c0-.8-.7-1.5-1.5-1.5zm.5 8.5c0 .3-.2.5-.5.5h-11c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5h11c.3 0 .5.2.5.5v7z"/>'
  }
})
