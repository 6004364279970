/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-reply': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.709 1.046A.5.5 0 018 1.5v3.541c1.8.062 3.712.475 5.223 1.642C14.901 7.978 16 10.133 16 13.5a.5.5 0 01-.925.263C13.338 10.95 10.729 10.097 8 10.008V13.5a.5.5 0 01-.825.38l-7-6a.5.5 0 010-.76l7-6a.5.5 0 01.534-.074zM1.269 7.5L7 12.413V9.5a.5.5 0 01.5-.5c2.623 0 5.35.607 7.396 2.862-.29-2.158-1.166-3.524-2.284-4.387C11.21 6.392 9.34 6.033 7.5 6.033a.5.5 0 01-.5-.5V2.587L1.268 7.5z"/>'
  }
})
