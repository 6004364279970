/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-stopwatch': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.25 0a.75.75 0 000 1.5H12v.75a.75.75 0 001.5 0V1.5h3.75a.75.75 0 000-1.5h-9zm4.5 9a.75.75 0 01.75.75v4.5a.75.75 0 01-1.5 0v-4.5a.75.75 0 01.75-.75zM4.5 14.25a8.25 8.25 0 1116.5 0 8.25 8.25 0 01-16.5 0zm8.25-9.75C7.365 4.5 3 8.865 3 14.25S7.365 24 12.75 24s9.75-4.365 9.75-9.75-4.365-9.75-9.75-9.75z"/>'
  }
})
