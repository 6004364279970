import { getInitialState } from './state';

export default {
  RESET_STATE: (state) => {
    Object.assign(state, getInitialState());
  },

  UPDATE_VIDEO_STATE: (state, data) => {
    state.isVideoPlaying = data.isVideoPlaying;
    state.timeInVideo = data.timeInVideo;
    state.watchTogetherOwnerParticipantId =
      data.watchTogetherOwnerParticipantId;
    state.videoMetadata = data.videoMetadata;
    state.isWatchTogetherActive = data.isWatchTogetherActive;
  },

  SET_SHOW_WATCH_TOGETHER_MUTE_INDICATION: (state, shouldShow) => {
    state.showWatchTogetherMuteIndication = shouldShow;
  },

  SET_HAS_SEEN_MUTE_INDICATION: (state, hasSeen) => {
    state.hasSeenMuteIndication = hasSeen;
  },

  SET_TIME_IN_VIDEO: (state, timeInVideo) => {
    state.timeInVideo = { seconds: timeInVideo };
  },

  SET_IS_VIDEO_PLAYING: (state, isVideoPlaying) => {
    state.isVideoPlaying = isVideoPlaying;
  },

  SET_IS_SYNCED_WITH_OWNER: (state, isSynced) => {
    state.isSyncedWithOwner = isSynced;
  }
};
