/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-chat-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6.5 0C2.999 0 0 2.381 0 5.5c0 1.085.372 2.09 1 2.932V12a.5.5 0 00.8.4l2.36-1.77c.726.238 1.515.37 2.34.37 3.501 0 6.5-2.381 6.5-5.5S10.001 0 6.5 0zM1 5.5C1 3.097 3.373 1 6.5 1S12 3.097 12 5.5 9.627 10 6.5 10a6.48 6.48 0 01-2.253-.399.5.5 0 00-.474.069L2 11V8.262a.5.5 0 00-.109-.312C1.323 7.237 1 6.396 1 5.5zm14.112.72a.5.5 0 10-.822.57A3.86 3.86 0 0115 9c0 .897-.323 1.737-.891 2.45a.5.5 0 00-.109.312v2.804l-2.008-1.338a.5.5 0 00-.434-.059c-.637.211-1.33.331-2.058.331-1.564 0-2.964-.535-3.958-1.377a.5.5 0 10-.646.762C6.08 13.89 7.712 14.5 9.5 14.5c.75 0 1.467-.11 2.136-.308l2.587 1.724A.5.5 0 0015 15.5v-3.568c.628-.843 1-1.846 1-2.932a4.86 4.86 0 00-.888-2.78z"/>'
  }
})
