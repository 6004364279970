/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-chime': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M15.833 11.574V8.868l-1.002.552 1.002 2.204v-.05zM18.338 9.72l4.159-4.158-6.664 3.306 2.505.852zM15.833 12.125l-.852 2.305 1.904-1.904 1.203 2.305H24l-8.167-2.706z"/><path pid="1" d="M15.833 8.868v2.706H24L18.338 9.72l-2.505-.852zM15.833 11.574v.551L24 14.831v-3.257h-8.167zM12.376 15.833h2.705l-.801 2.455 4.158 4.209-3.858-7.716-2.204 1.052zM14.981 14.43l5.762 5.762-2.655-5.361-1.203-2.305-1.904 1.904z"/><path pid="2" d="M14.981 14.43l-.4.35 3.857 7.717 2.305-2.305-5.762-5.762zM9.57 14.981l1.904 1.904-2.355 1.153v5.912l2.706-8.117-2.255-.852zM12.376 15.833v8.117l1.904-5.662.801-2.455h-2.705z"/><path pid="3" d="M12.376 15.833h-.551L9.119 23.95h3.257v-8.117zM5.662 14.28l-4.159 4.158 6.664-3.356-2.505-.802zM8.167 12.376v2.705l1.002-.5-1.002-2.205zM9.57 14.981l-5.762 5.762 5.311-2.705 2.355-1.153-1.904-1.904z"/><path pid="4" d="M9.57 14.981l-.4-.4-1.003.5-6.664 3.357 2.305 2.305 5.762-5.762zM9.019 9.57l-1.904 1.904-1.203-2.355H0l8.167 2.706.852-2.255zM8.167 15.081v-2.705H0l5.662 1.904 2.505.801z"/><path pid="5" d="M8.167 12.376v-.551L0 9.119v3.257h8.167zM11.574 8.167H8.92l.801-2.505-4.158-4.159L9.42 9.17l2.154-1.002zM9.019 9.57L3.257 3.808l2.655 5.31 1.203 2.356L9.019 9.57z"/><path pid="6" d="M9.019 9.57l.4-.4-3.857-7.667-2.305 2.305L9.019 9.57zM14.43 9.019l-1.904-1.904 2.355-1.203V0l-2.706 8.167 2.255.852zM11.574 8.167h.05V0L9.72 5.662 8.92 8.167h2.655z"/><path pid="7" d="M11.624 8.167h.551L14.881 0h-3.257v8.167zM14.43 9.019l5.762-5.762-5.311 2.655-2.355 1.203 1.904 1.904z"/><path pid="8" d="M14.43 9.019l.4.4 1.003-.55 6.664-3.307-2.305-2.305-5.762 5.762z"/></g><defs><clipPath id="clip0"><path pid="9" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
