export default function getInitialState() {
  return {
    isCaptionsEnabled: false,
    isLiveTranscriptionEnabled: false,
    isLiveTranscriptionPaused: false,
    activeCaptionsId: null,
    currentCaptionsList: [],
    captionsHistory: [],
    isLoadingCaptions: false,
    isLoadingLiveTranscription: false,

    isUpdateIsCaptionsEnabledInProgress: false,
    isSelfSubscribingInProgress: false
  };
}
