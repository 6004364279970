var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "message-row",
      class: { me: _vm.isMe },
      attrs: { "data-cy": "message-row" },
    },
    [
      _vm.isMobileWebMode
        ? _c("ParticipantAvatar", {
            staticClass: "avatar",
            attrs: {
              negative: "",
              avatarSize: "sm",
              participantType: _vm.sender ? _vm.sender.type : undefined,
              participantProfilePicture: _vm.sender
                ? _vm.sender.profilePicture
                : undefined,
              displayName: _vm.displayName,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "message-wrapper" }, [
        _c("div", { staticClass: "message-header" }, [
          !_vm.isMobileWebMode
            ? _c("div", {
                staticClass: "dot",
                style: { "background-color": _vm.isMe ? "" : _vm.color },
              })
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "display-name",
              style: { color: !_vm.isMobileWebMode ? "" : _vm.color },
            },
            [_vm._v(" " + _vm._s(_vm.displayName) + " ")]
          ),
          _c(
            "div",
            {
              staticClass: "message-time",
              attrs: { "data-cy": "message-time" },
            },
            [_vm._v(" " + _vm._s(_vm.formattedTime) + " ")]
          ),
        ]),
        _c(
          "div",
          {
            staticClass: "message-content",
            attrs: { "data-cy": "message-content" },
          },
          [
            _vm.isTextMessage
              ? _c("TextMessageRow", {
                  attrs: { message: _vm.message },
                  on: {
                    resend: function ($event) {
                      return _vm.$emit("resend")
                    },
                    copyToClipboard: function ($event) {
                      return _vm.$emit("copyToClipboard")
                    },
                  },
                })
              : _vm.isImage
              ? _c("ImageRow", {
                  staticClass: "image-row",
                  attrs: { message: _vm.message },
                  on: {
                    resend: function ($event) {
                      return _vm.$emit("resend")
                    },
                    openImageView: function ($event) {
                      return _vm.$emit("openImageView")
                    },
                  },
                })
              : _vm.isDocument || (_vm.isVideo && !_vm.isMobileWebMode)
              ? _c("AttachmentRow", {
                  staticClass: "attachment",
                  attrs: { message: _vm.message },
                  on: {
                    resend: function ($event) {
                      return _vm.$emit("resend")
                    },
                  },
                })
              : _c("div", { staticClass: "attachment not-supported" }, [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("message_row.attachment_not_supported")) +
                      " "
                  ),
                ]),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }