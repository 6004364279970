/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-shout-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1 19c0-2.66 5.33-4 8-4s8 1.34 8 4v2H1v-2zM15.08 7.05l1.68-1.69c2.02 2.2 2.02 5.25 0 7.27l-1.68-1.69c.84-1.18.84-2.71 0-3.89zm3.36-3.42L20.07 2c3.91 4.05 3.9 10.11 0 14l-1.63-1.63c2.77-3.18 2.77-7.72 0-10.74zM13 9a4 4 0 11-8 0 4 4 0 018 0z"/>'
  }
})
