import axios from 'axios';
import VideoMetadata from '@/services/watch-together/video-metadata';
import VideoSiteTypeEnum from '@/services/watch-together/video-site-type-enum';
import {
  VideoUrlValidationError,
  VIDEO_URL_ERROR_REASON
} from '@/services/watch-together/video-url-validation-error';

// TODO: move to backend to prevent leaking the API key
const API_AUTHENTICATION_TOKEN = 'a9e446039eea0f5e60ab4a719de102fa';

export function getVimeoIdByUrl(url) {
  var matches = url.match(/^.+vimeo.com\/(.*\/)?([^#?]*)/);
  return matches ? matches[2] || matches[1] : null;
}

export async function getVimeoVideoMetadata(url) {
  const videoId = getVimeoIdByUrl(url);
  const videoMetadataResponse = await axios.get(
    `https://api.vimeo.com/videos/${videoId}`,
    { headers: { Authorization: `Bearer ${API_AUTHENTICATION_TOKEN}` } }
  );
  const videoMetadata = videoMetadataResponse.data;

  let thumbnailUrl = null;
  if (videoMetadata.pictures.sizes.length === 1) {
    thumbnailUrl = videoMetadata.pictures.sizes[0].link;
  }

  if (videoMetadata.pictures.sizes.length > 1) {
    const thumbnailCandidates = videoMetadata.pictures.sizes.filter(
      (picture) => picture.width >= 250 && picture.width <= 1000
    );
    if (thumbnailCandidates.length > 0) {
      thumbnailUrl = thumbnailCandidates[0].link;
    }
  }

  if (videoMetadata.privacy.embed !== 'public') {
    throw new VideoUrlValidationError(
      url,
      VIDEO_URL_ERROR_REASON.VIDEO_NOT_EMBEDDABLE
    );
  }

  if (videoMetadata.type === 'live') {
    throw new VideoUrlValidationError(url, VIDEO_URL_ERROR_REASON.LIVE_VIDEO);
  }

  return new VideoMetadata(
    VideoSiteTypeEnum.Vimeo,
    videoId,
    videoMetadata.name,
    videoMetadata.duration,
    thumbnailUrl,
    videoMetadata.user.name
  );
}
