/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-refresh-dollar': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 .5a11.5 11.5 0 018.477 19.27l1.934 1.933-5.5.786.785-5.5 2.073 2.073A10.5 10.5 0 0012 1.5v-1zM6.302 7.01l.786-5.5-5.5.786L3.522 4.23A11.5 11.5 0 0012 23.5v-1A10.5 10.5 0 014.23 4.938L6.303 7.01zm9.68 7.204a2.554 2.554 0 01-.887 2 4.509 4.509 0 01-2.561.971V19h-1.112v-1.766a8.63 8.63 0 01-3.406-.634v-1.767a8.573 8.573 0 001.682.59c.563.148 1.141.23 1.724.247v-3.13l-.7-.26a4.955 4.955 0 01-2.005-1.259 2.675 2.675 0 01-.634-1.811 2.357 2.357 0 01.9-1.9 4.455 4.455 0 012.44-.9V5h1.112v1.381a9.225 9.225 0 013.222.686L15.146 8.6a9 9 0 00-2.611-.619v3.046l.636.243a5.8 5.8 0 012.2 1.289c.408.453.627 1.045.612 1.655zM10.05 9.193c-.009.294.1.579.3.794.31.282.677.496 1.076.628v-2.6a1.956 1.956 0 00-1.022.41.985.985 0 00-.354.768zm3.958 5.146a1.04 1.04 0 00-.34-.8 3.466 3.466 0 00-1.133-.607V15.6c.982-.149 1.473-.569 1.473-1.261z"/>'
  }
})
