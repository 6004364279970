/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'spin-the-bottle-full': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 11.965c0 4.449-2.594 8.312-6.385 10.27a5.992 5.992 0 00-.313-1.423l-.031-.121c3.094-1.755 5.177-5.004 5.177-8.716 0-.414.344-.757.781-.757a.75.75 0 01.771.747zM6.958 17.513c.01-.07 0-.141-.01-.212 0-.03-.01-.07-.01-.1a.78.78 0 00-.625-.545L2.02 16a.795.795 0 00-.583.141.723.723 0 00-.303.505l-.583 4.176a.75.75 0 00.667.848c.031 0 .073.01.104.01a.767.767 0 00.77-.656l.292-2.119a11.526 11.526 0 004 3.36c.053-.495.146-.989.323-1.463.01-.03.01-.06.021-.09-1.302-.737-2.437-1.736-3.292-2.967l2.636.404a.786.786 0 00.885-.636zm-5.406-5.548c0-4.44 2.969-8.212 7.083-9.574V.817C3.656 2.23 0 6.69 0 11.965c0 .413.344.756.781.756a.76.76 0 00.771-.756zM21.98 7.929c.042.01.084.01.125.01.167 0 .334-.05.469-.15a.723.723 0 00.302-.505l.583-4.177a.75.75 0 00-.666-.847.76.76 0 00-.875.645l-.292 2.119c-1.542-2.058-3.73-3.53-6.26-4.227v1.604c2.114.686 3.947 1.987 5.208 3.793l-2.636-.403c-.437-.06-.822.212-.885.625a.75.75 0 00.646.858l4.281.655zm-6.156 13.388a7.981 7.981 0 01-.458-2.502c.01-.767.083-1.504.197-2.22.23-1.533.344-3.107.334-4.66 0-2.28-.813-4.55-1.396-6.205a10.435 10.435 0 01-.708-2.492l.01-.11V.392A.4.4 0 0013.396 0h-2.781a.394.394 0 00-.407.393V3.238A11.208 11.208 0 019.48 5.75c-.573 1.614-1.385 3.884-1.385 6.164-.01 1.564.104 3.138.344 4.681.114.716.187 1.453.197 2.19a7.89 7.89 0 01-.447 2.521c-.01.02-.01.04-.021.061-.146.403-.23.817-.25 1.23 0 .112.01.213.03.313.126.596.657 1.06 1.313 1.09h5.51a1.381 1.381 0 001.293-1.12c.02-.09.03-.191.02-.292a4.176 4.176 0 00-.25-1.231c0-.01 0-.03-.01-.04z" _fill="#212121"/>'
  }
})
