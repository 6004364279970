/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-chart-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.5 0h-3C9.6 0 9 .6 9 1.5v21c0 .9.6 1.5 1.5 1.5h3c.9 0 1.5-.6 1.5-1.5v-21c0-.9-.6-1.5-1.5-1.5zM4.5 15h-3c-.9 0-1.5.6-1.5 1.5v6c0 .9.6 1.5 1.5 1.5h3c.9 0 1.5-.6 1.5-1.5v-6c0-.9-.6-1.5-1.5-1.5zM22.5 7.5h-3c-.9 0-1.5.6-1.5 1.5v13.5c0 .9.6 1.5 1.5 1.5h3c.9 0 1.5-.6 1.5-1.5V9c0-.9-.6-1.5-1.5-1.5z"/>'
  }
})
