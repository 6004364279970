<template>
  <div class="dropdown-container">
    <div ref="anchor">
      <slot name="toggleDropdown"></slot>
    </div>
    <vwc-dropdown
      ref="dropdown"
      corner="BOTTOM_START"
      :open="open"
      :fullwidth="fullwidth"
      :stayOpenOnBodyClick="stayOpenOnBodyClick"
    >
      <VTextfield
        slot="header"
        v-model="filterText"
        class="search"
        :placeholder="$t('dropdown.search_placeholder')"
        icon="search-line"
        dense
        outlined
        @input="filter"
      />
      <vwc-list ref="dropdownContent" class="list">
        <vwc-list-item
          v-for="(option, index) in filteredOptions"
          :key="index"
          graphic="icon"
          shape="rounded"
          @click="onSelection(option)"
        >
          <vwc-checkbox slot="graphic" :checked="option.checked" />
          <span> {{ option.label }}</span>
        </vwc-list-item>
      </vwc-list>
      <vwc-button
        slot="actions"
        class="action-button"
        type="submit"
        :label="$t('dropdown.cancel_button')"
        layout="outlined"
        dense
        @click="hide"
      />
      <vwc-button
        slot="actions"
        data-cy="apply-button"
        class="action-button"
        layout="filled"
        type="submit"
        :label="$t('dropdown.apply_button')"
        dense
        :disabled="!hasChanges"
        @click="onApply"
      />
    </vwc-dropdown>
  </div>
</template>

<script>
export default {
  name: 'Dropdown',

  props: {
    open: {
      type: Boolean,
      required: true
    },
    options: {
      type: Array,
      default: () => []
    },
    fullwidth: {
      type: Boolean,
      default: true
    },
    stayOpenOnBodyClick: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      filterText: '',
      localOptions: []
    };
  },

  computed: {
    filteredOptions() {
      const filter = this.filterText.toLowerCase();
      return this.localOptions.filter((option) =>
        option.label.toLowerCase().includes(filter)
      );
    },

    hasChanges() {
      return (
        this.localOptions.filter(
          (option, index) => this.options[index].checked !== option.checked
        ).length > 0
      );
    }
  },

  watch: {
    options() {
      this.updateLocalOptions();
    }
  },

  mounted() {
    this.$refs.dropdown.anchor = this.$refs.anchor;
    this.updateLocalOptions();
  },

  methods: {
    updateLocalOptions() {
      this.localOptions = JSON.parse(JSON.stringify(this.options));
    },

    hide() {
      this.$emit('close');
      this.filterText = '';
      this.$refs.dropdownContent.scrollTop = 0;
    },

    filter(value) {
      this.filterText = value;
    },

    onSelection(option) {
      option.checked = !option.checked;
    },

    onApply() {
      this.$emit('apply', this.localOptions);
      this.hide();
    }
  }
};
</script>

<style scoped>
.dropdown-container {
  position: relative;
  width: 100%;
}
.search {
  margin-top: 8px;
}

.list {
  max-height: 160px;
  overflow-y: scroll;
  margin: 8px 5px;
  --mdc-list-vertical-padding: 0;
}

.action-button {
  margin-bottom: 8px;
}
</style>
