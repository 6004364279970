var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative-position" }, [
    _c(
      "div",
      {
        staticClass: "Vlt-composite text-area-wrapper",
        class: {
          "Vlt-form__element--big": _vm.label,
          "Vlt-form__element--error": !_vm.state,
        },
      },
      [
        _vm.prependIcon
          ? _c(
              "div",
              { staticClass: "Vlt-composite__prepend" },
              [
                _c("v-button", {
                  staticClass: "Vlt-btn--icon prepend-icon",
                  attrs: {
                    id: "prepend-btn",
                    iconName: _vm.prependIcon,
                    disabled: _vm.disabled,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("prependIconClick")
                    },
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "Vlt-textarea Vlt-input v-text-area" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.inputText,
                expression: "inputText",
              },
            ],
            ref: "textarea",
            staticClass: "textarea",
            class: {
              "no-left-border-radius": _vm.prependIcon,
              "no-right-border-radius": _vm.submitButton,
            },
            attrs: {
              rows: "1",
              cols: "50",
              maxlength: _vm.maxLength,
              disabled: _vm.disabled,
              placeholder: _vm.placeholder,
            },
            domProps: { value: _vm.inputText },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.inputText = $event.target.value
                },
                _vm.updateText,
              ],
              keydown: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                ) {
                  return null
                }
                $event.preventDefault()
              },
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                if (
                  $event.ctrlKey ||
                  $event.shiftKey ||
                  $event.altKey ||
                  $event.metaKey
                ) {
                  return null
                }
                return _vm.submitText($event)
              },
            },
          }),
        ]),
        _vm.submitButton
          ? _c("div", { staticClass: "Vlt-composite__append" }, [
              _c(
                "button",
                {
                  staticClass: "Vlt-btn Vlt-btn--icon submit-button",
                  on: { click: _vm.submitText },
                },
                [
                  _c("v-icon", {
                    staticClass: "submit-icon",
                    class: {
                      "Vlt-teal":
                        _vm.inputText.length > 0 && !_vm.isMobileWebMode,
                      submittable: _vm.inputText.length > 0,
                    },
                    attrs: {
                      iconName:
                        _vm.inputText.length > 0
                          ? "Vlt-icon-send-negative"
                          : "Vlt-icon-send",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _c("v-icon", {
          staticClass: "icon-happy icon-happy-send-message",
          attrs: { iconName: "Vlt-icon-emoji" },
          on: { click: _vm.showPicker },
        }),
      ],
      1
    ),
    _vm.showEmojisPicker
      ? _c(
          "div",
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: _vm.hidePicker,
                expression: "hidePicker",
              },
              {
                name: "observe-keyboard",
                rawName: "v-observe-keyboard.prevent.l2.esc",
                value: _vm.hidePicker,
                expression: "hidePicker",
                modifiers: { prevent: true, l2: true, esc: true },
              },
            ],
          },
          [_vm._m(0)],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("VPicker", {
      staticClass: "emojis-picker",
      on: { emojiClick: _vm.onEmojiClick },
    })
  },
]
render._withStripped = true

export { render, staticRenderFns }