<template>
  <vwc-list-item
    data-cy="contact-row"
    twoline
    graphic="icon"
    shape="rounded"
    hasMeta
    @click="$emit('click')"
  >
    <Avatar
      slot="graphic"
      size="sm"
      :displayName="contact.displayName"
      :image="contact.profilePicture"
    />
    {{ contact.displayName }}
    <span slot="secondary">{{
      $t('contact_row.extension', {
        extensionNumber: contact.extensionNumber
      })
    }}</span>
    <vwc-checkbox slot="meta" :checked="contact.inviteState === 'selected'" />
  </vwc-list-item>
</template>

<script>
export default {
  name: 'ContactRow',

  props: {
    contact: {
      type: Object,
      required: true
    }
  }
};
</script>

<style scoped>
vwc-list-item {
  --mdc-list-item-graphic-size: 32px;
  --mdc-list-item-graphic-margin: 12px;
  --mdc-list-side-padding: 8px;
}

vwc-list-item:after {
  border-bottom: unset;
}
</style>
