export default {
  SET_LAYOUT_MODE: (state, layoutMode) => (state.layoutMode = layoutMode),

  SET_USER_PREFERRED_LAYOUT: (state, userPreferredLayout) =>
    (state.userPreferredLayout = userPreferredLayout),

  SET_LAST_SAVED_LAYOUT: (state, layout) => (state.lastSavedLayout = layout),

  TOGGLE_SIDEBAR: (state) =>
    (state.isSidebarCollapsed = !state.isSidebarCollapsed),

  SET_LAYOUT_CHANGED_IN_LARGE_MEETING: (state, didLayoutChange) =>
    (state.layoutChangedInLargeMeeting = didLayoutChange)
};
