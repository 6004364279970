var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "image-view-container unselectable" },
    [
      _c(
        "div",
        {
          staticClass: "image-view-header",
          attrs: { "data-cy": "image-view-header" },
        },
        [
          _c("v-icon", {
            staticClass: "exit-icon sm",
            attrs: {
              "data-cy": "image-view-back-btn",
              "icon-name": "Vlt-icon-arrow-thin-left",
            },
            on: {
              click: function ($event) {
                return _vm.$emit("closeImageView")
              },
            },
          }),
          _c("div", { staticClass: "image-view-header-text" }, [
            _c("h5", { staticClass: "image-header-display-name" }, [
              _vm._v(_vm._s(_vm.displayName)),
            ]),
            _c("p", { staticClass: "image-header-time" }, [
              _vm._v(_vm._s(_vm.formattedTime)),
            ]),
          ]),
          !_vm.isUploading && !_vm.isSafari
            ? _c("v-icon", {
                staticClass: "download-button",
                attrs: {
                  "data-cy": "image-view-download-btn",
                  sm: "",
                  iconName: "Vlt-icon-download",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                    return _vm.downloadImage($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.isUploading
        ? _c("v-spinner", { staticClass: "spinner", attrs: { white: "" } })
        : _c("div", { staticClass: "image-container" }, [
            _vm.showDownloadMessage
              ? _c(
                  "div",
                  { staticClass: "download-message-container unselectable" },
                  [
                    _c("p", { staticClass: "download-message-text" }, [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("mobile_image_view.download_message_text")
                          ) +
                          " "
                      ),
                    ]),
                    _c("v-icon", {
                      staticClass: "download-message-exit-icn",
                      attrs: { "icon-name": "Vlt-icon-cross", xs: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDownloadMessage = false
                        },
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("img", {
              attrs: { src: _vm.imageUrl },
              on: { load: _vm.imageLoaded },
            }),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }