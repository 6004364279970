var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container vvd-scheme-alternate" }, [
    _c("div", { staticClass: "dialog-container" }, [
      _c("div", { staticClass: "header-container" }, [
        _c("div", { staticClass: "heading-wrapper" }, [
          _c("img", {
            staticClass: "title-icon",
            attrs: { src: "/smile-with-glasses.svg" },
          }),
          _c("div", { staticClass: "title-wrapper" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(_vm.$t("reactions_counter_modal.title"))),
            ]),
          ]),
        ]),
        _vm.isSessionOwner
          ? _c(
              "div",
              { staticClass: "buttons" },
              [
                _c("vwc-icon", {
                  staticClass: "reset-button",
                  attrs: { type: "reset-solid", size: "small" },
                  on: { click: _vm.resetCounter },
                }),
                _c("vwc-icon", {
                  staticClass: "dismiss-button",
                  attrs: { type: "close-solid", size: "small" },
                  on: { click: _vm.hide },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c(
        "div",
        { staticClass: "content", attrs: { slot: "content" }, slot: "content" },
        [
          _c(
            "div",
            { staticClass: "reactions-container" },
            [
              _c("div", { staticClass: "total-count" }, [
                _c(
                  "div",
                  { staticClass: "calc-icon-container" },
                  [
                    _c("vwc-icon", {
                      staticClass: "calc-icon",
                      class: { branded: _vm.isBranded },
                      attrs: { type: "calculator-line", connotation: "cta" },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "total-number" }, [
                  _vm._v(_vm._s(_vm.totalCount)),
                ]),
              ]),
              _vm._l(_vm.reactionsCounts, function (reaction) {
                return _c(
                  "div",
                  {
                    key: reaction.id,
                    staticClass: "reaction-icon-container",
                    class: {
                      max: _vm.isMaxCounter(reaction),
                      min: _vm.isMinCounter(reaction),
                    },
                    on: {
                      click: function ($event) {
                        return _vm.sendReaction(reaction.id)
                      },
                    },
                  },
                  [
                    _c("img", {
                      staticClass: "icon",
                      attrs: { src: reaction.path },
                    }),
                    _c("div", { staticClass: "reaction-count" }, [
                      _vm._v(" " + _vm._s(reaction.count) + " "),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }