/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-stack-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M12 1.5L0 9l12 7.5L24 9 12 1.5zM3.15 14.55L12 20.1l8.85-5.55L24 16.5 12 24 0 16.5l3.15-1.95z"/></g><defs><clipPath id="clip0"><rect/></clipPath></defs>'
  }
})
