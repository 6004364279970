/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calendar-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 3h-3V0H15v3H9V0H4.5v3h-3A1.5 1.5 0 000 4.5v18A1.5 1.5 0 001.5 24h21a1.5 1.5 0 001.5-1.5v-18A1.5 1.5 0 0022.5 3zM21 21H3V10.5h18V21z"/>'
  }
})
