/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calendar-3': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.75 0a.75.75 0 01.75.75V3h9V.75a.75.75 0 011.5 0V3h3.75A2.25 2.25 0 0124 5.25v3a.75.75 0 01-.75.75H1.5v12.75a.75.75 0 00.75.75h7.5a.75.75 0 010 1.5h-7.5A2.25 2.25 0 010 21.75V5.25A2.25 2.25 0 012.25 3H6V.75A.75.75 0 016.75 0zm10.5 4.5h-15a.75.75 0 00-.75.75V7.5h21V5.25a.75.75 0 00-.75-.75h-4.5zm0 7.5a5.25 5.25 0 100 10.5 5.25 5.25 0 000-10.5zm-6.75 5.25a6.75 6.75 0 1113.5 0 6.75 6.75 0 01-13.5 0zm7.5-3a.75.75 0 00-1.5 0v3c0 .414.336.75.75.75h3a.75.75 0 000-1.5H18v-2.25z"/>'
  }
})
