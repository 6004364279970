/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-car-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.55 12.45l-1.2-1.2-1.5-10.05c0-.6-.6-1.2-1.35-1.2h-15C3.75 0 3.15.6 3 1.35L1.5 11.4.45 12.45c-.3.3-.45.6-.45 1.05v6c0 .9.6 1.5 1.5 1.5v1.5c0 .9.6 1.5 1.5 1.5h3c.9 0 1.5-.6 1.5-1.5V21h9v1.5c0 .9.6 1.5 1.5 1.5h3c.9 0 1.5-.6 1.5-1.5V21c.9 0 1.5-.6 1.5-1.5v-6c0-.45-.15-.75-.45-1.05zM4.5 18c-.9 0-1.5-.6-1.5-1.5S3.6 15 4.5 15s1.5.6 1.5 1.5S5.4 18 4.5 18zm.3-7.5L5.85 3H18.3l1.05 7.5H4.8zM19.5 18c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5 1.5.6 1.5 1.5-.6 1.5-1.5 1.5z"/>'
  }
})
