export default function getInitialState() {
  return {
    qnaSessionId: null,
    isLoadingSession: false,
    isQnaSessionActive: false,
    qnaSessionOwnerId: '',
    questions: [],
    seenQuestionIds: new Set()
  };
}
