import Vue from 'vue';
import { v4 as uuid } from 'uuid';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import * as messagingApi from '@/apis/messaging-api';
import * as utils from '@/helpers/messaging-utils';
import {
  buildAttachmentFieldsForSending,
  getMediaType
} from '@/helpers/attachments-utils';
import {
  MESSAGES_POLLING_INTERVAL_IN_MILLISECONDS,
  MESSAGES_PAGE_SIZE,
  MESSAGE_TYPE,
  ATTACHMENT_TYPE
} from '@/consts/messaging';
import { ANALYTICS, SIDEBARS } from '@/consts/global-consts';
import PushNotificationsService from '@/services/push-notifications-service';
import attachments from './attachments/attachments';
import analytics from '@/services/analytics-service';
import * as vbcGw from '@/apis/vbc-gw';
import store from '@/store';
import { isElectron } from '@/helpers/global-helpers';

let messagesPollingInterval;

export default {
  namespaced: true,

  modules: {
    attachments
  },

  state: {
    messages: {},
    sortedMessagesIds: [],
    newestMessageTimestamp: 0,
    failedReadingMessages: false,
    finishedReadingHistoricalMessages: false,

    unreadMessagesCount: 0,
    isChatScrolledToBottom: true,
    lastChatScrollPosition: undefined,

    groupData: {
      groupId: '',
      startTime: 0
    }
  },

  actions: {
    init: ({ dispatch, commit }, { groupId, startTime, domain }) => {
      commit('SET_GROUP_DATA', { groupId, startTime });
      commit('SET_NEWEST_MESSAGE_TIMESTAMP', startTime);
      messagingApi.init(domain);
      dispatch('initUnreadMessagesCounter');
      dispatch('readMessages', true);
      dispatch('initMessagesPolling');
    },

    initUnreadMessagesCounter: ({ state, getters, commit }) => {
      // update unread messages count on new messages
      store.watch(
        (storeState, storeGetters) => storeGetters['messaging/chatMessages'],
        (newChatMessages, oldChatMessages) => {
          if (
            !getters.isChatVisibleAndScrolledToBottom &&
            !getters.isLastMessageSentByMe
          ) {
            const numOfNewMessages =
              state.unreadMessagesCount +
              newChatMessages.length -
              oldChatMessages.length;
            commit('SET_UNREAD_MESSAGES_COUNT', numOfNewMessages);
          }
        }
      );

      // reset chat notifications if all read
      store.watch(
        (storeState, storeGetters) =>
          storeGetters['messaging/isChatVisibleAndScrolledToBottom'],
        (resetUnreadMessagesCount) => {
          if (resetUnreadMessagesCount) {
            commit('SET_UNREAD_MESSAGES_COUNT', 0);
          }
        }
      );

      if (isElectron() && window.Electron.notifyUnreadMessagesCountUpdate) {
        store.watch(
          (storeState) => storeState.messaging.unreadMessagesCount,
          (unreadMessagesCount) =>
            window.Electron.notifyUnreadMessagesCountUpdate(
              unreadMessagesCount
            ),
          { immediate: true }
        );
      }
    },

    initMessagesPolling: ({ dispatch }) => {
      if (!messagesPollingInterval) {
        messagesPollingInterval = setInterval(() => {
          if (PushNotificationsService.isConnected()) {
            return;
          }
          dispatch('readMessages');
        }, MESSAGES_POLLING_INTERVAL_IN_MILLISECONDS);
      }
    },

    stopMessagesPolling: () => {
      if (messagesPollingInterval) {
        clearInterval(messagesPollingInterval);
        messagesPollingInterval = null;
      }
    },

    sendMessage: async (
      { state, commit, dispatch, rootGetters },
      { body = '', messageId, attachment }
    ) => {
      const credentials = vbcGw.getCredentials();
      const isResend = !!messageId;
      messageId = messageId || uuid();
      const conversationId = state.groupData.groupId;
      const extension = credentials.extension;
      let tempBody = body;
      if (attachment) {
        tempBody = ''; // if there's an attachment with body content and attachment we'll send the body in a separate message
        // This allows to display progress to the user immediately even if the upload to s3 hasn't started yet
        dispatch('attachments/updateUploadProgress', {
          messageId,
          percentsLoaded: 0
        });
      }
      const realMessage = utils.createNewRealMessage({
        to: conversationId,
        messageId,
        from: extension,
        body: tempBody,
        participantId: credentials.externalId,
        attachment
      });
      // TODO: log if fails, secure body
      const fakeMessage = await utils
        .createNewFakeMessage(
          conversationId,
          messageId,
          extension,
          tempBody,
          credentials.externalId,
          attachment
        )
        .catch((err) => {
          logger.error(
            'sendMessage/createNewFakeMessage',
            LOG_CATEGORIES.MESSAGING,
            {
              err,
              hasAttachments: !!attachment,
              isResend,
              conversationId,
              messageId,
              senderExtension: extension
            }
          );
        });

      if (!isResend) {
        commit('SET_MESSAGES', { [messageId]: fakeMessage });
        commit('ADD_NEW_MESSAGES', [messageId]);
        if (body !== '' && attachment !== undefined) {
          dispatch('sendMessage', { body }); // if we've sent an attachment - now it'll be a good time to send the body content
        }
      } else {
        commit('SET_MESSAGE_SENT_STATUS', {
          messageId,
          sentStatus: 'RESENDING'
        });
      }

      try {
        if (attachment) {
          const s3Details = await dispatch('attachments/uploadFileToS3', {
            file: fakeMessage.originalAttachmentFile,
            messageId
          });
          if (attachment) {
            const { data, attachments } = buildAttachmentFieldsForSending(
              attachment,
              s3Details,
              fakeMessage.data[0].thumbnail
            );
            realMessage.data = data;
            realMessage.attachments = attachments;
            realMessage.attachmentsCount = 1;
          }
        }

        let response = await messagingApi.sendMessage(
          credentials.externalId,
          realMessage
        );
        logger.log('sendMessage', LOG_CATEGORIES.MESSAGING, {
          hasAttachments: !!attachment,
          isResend,
          conversationId,
          messageId,
          senderExtension: extension
        });

        const mediaType = getMediaType(attachment);
        analytics.trackEvent(ANALYTICS.MESSAGE_SENT, {
          'Num of Participants': rootGetters.activeParticipants.length,
          'Media Type': mediaType
        });

        if (isResend) {
          commit('REMOVE_OLD_MESSAGES', [messageId]);
          commit('ADD_NEW_MESSAGES', [messageId]);
        }
        if (attachment) {
          dispatch('attachments/removeUploadProgress', { messageId });
          response.originalAttachmentFile = undefined;
        }
        await dispatch('updateMessageId', { trackId: messageId, response });
      } catch (err) {
        if (attachment) {
          dispatch('attachments/removeUploadProgress', { messageId });
          if (
            err.code === 'RequestAbortedError' ||
            err.message === 'UploadCancelled'
          ) {
            return;
          }
        }
        commit('SET_MESSAGE_SENT_STATUS', { messageId, sentStatus: 'FAILED' });

        logger.error('sendMessage', LOG_CATEGORIES.MESSAGING, {
          err,
          hasAttachments: !!attachment,
          isResend,
          conversationId,
          messageId,
          senderExtension: extension
        });
      }
    },

    sendOffNetMessage: async (
      _,
      { to, from, body, isBusinessInbox = false }
    ) => {
      const messageId = uuid();
      const messageData = {
        to,
        messageId,
        from,
        body,
        isOffNet: true
      };
      const credentials = vbcGw.getCredentials();
      if (isBusinessInbox) {
        messageData.fromDelegate = credentials.extension;
      }
      const realMessage = utils.createNewRealMessage(messageData);

      try {
        await messagingApi.sendMessage(credentials.externalId, realMessage, 2);
        logger.log('sendOffNetMessage', LOG_CATEGORIES.MESSAGING, {
          to,
          messageId,
          from
        });
      } catch (err) {
        logger.error('sendOffNetMessage', LOG_CATEGORIES.MESSAGING, {
          err,
          to,
          messageId,
          from
        });
        throw err;
      }
    },

    updateMessageId: ({ state, commit }, { trackId, response }) => {
      const stateMessage = state.messages[trackId];
      if (!stateMessage) {
        // We might try to update a fake message that was already deleted...
        return;
      }
      const newMessage = {
        ...stateMessage,
        messageId: response.messageId,
        creationTime: response.creationTime
      };
      newMessage.uiStatus.sentStatus = 'SENT';
      commit('REMOVE_MESSAGES', [trackId]);
      commit('SET_MESSAGES', { [newMessage.messageId]: newMessage });
      commit('REPLACE_MESSAGE_ID', {
        oldMessageId: trackId,
        newMessageId: newMessage.messageId
      });
      return newMessage;
    },

    readMessages: async (
      { state, commit, dispatch, rootGetters },
      isHistorical
    ) => {
      if (!state.finishedReadingHistoricalMessages && !isHistorical) {
        return;
      }
      if (state.failedReadingMessages) {
        commit('SET_FAILED_TO_READ_MESSAGES', false);
      }

      let messages = [];
      if (!isHistorical || !rootGetters.isGuest) {
        try {
          messages = await messagingApi.readMessages(
            vbcGw.getCredentials().externalId,
            state.newestMessageTimestamp,
            MESSAGES_PAGE_SIZE
          );
        } catch (err) {
          commit('SET_FAILED_TO_READ_MESSAGES', true);
          return;
        }
      }

      if (messages?.length) {
        await dispatch('addMessages', messages);
      }

      // request another page
      if (messages.length === MESSAGES_PAGE_SIZE) {
        setTimeout(() => dispatch('readMessages', isHistorical), 1000);
      } else if (isHistorical) {
        commit('SET_FINISHED_READING_HISTORICAL_MESSAGES');
      }
    },

    addMessages: ({ state, commit }, messages) => {
      const processedMessages = [];
      if (
        messages[messages.length - 1].creationTime >
        state.newestMessageTimestamp
      ) {
        commit(
          'SET_NEWEST_MESSAGE_TIMESTAMP',
          messages[messages.length - 1].creationTime + 1
        );
      }

      messages.forEach((message) => {
        if (message.toDisplayName === state.groupData.groupId) {
          if (message.data) {
            message.data = JSON.parse(message.data);
            if (
              message.data instanceof String ||
              typeof message.data === 'string'
            ) {
              message.data = JSON.parse(message.data);
            }
            if (message.messageType === 'GRP_MMS') {
              message.data.forEach((data, index) => {
                // In some case we can messages with data the is already an object we we don't need to do the following if MD is not string.
                if (typeof data.metadata === 'string') {
                  const metadata = JSON.parse(data.metadata);
                  data.metadata = {
                    ...metadata,
                    ...message.attachments[index]
                  };
                }
              });
            }
          }
          message.uiStatus = { sentStatus: 'SENT' };
          processedMessages.push(message);
        }
      });

      const messagesIds = processedMessages
        .filter(
          (msg) =>
            !state.messages[msg.messageId] && !state.messages[msg.trackId]
        )
        .map((msg) => msg.messageId);

      commit('ADD_NEW_MESSAGES', messagesIds);
      commit('SET_MESSAGES', processedMessages);
    },

    updateIsChatScrolledToBottom: ({ commit }, isChatScrolledToBottom) => {
      commit('SET_IS_CHAT_SCROLLED_TO_BOTTOM', isChatScrolledToBottom);
    },

    updateLastChatScrollPosition: ({ commit }, lastChatScroll) => {
      commit('SET_LAST_CHAT_SCROLL', lastChatScroll);
    }
  },

  mutations: {
    SET_MESSAGES: (state, messages) => {
      Object.values(messages).forEach((message) => {
        if (message.messageId) {
          Vue.set(state.messages, message.messageId, message);
        }
      });
    },

    ADD_NEW_MESSAGES: (state, messagesIds) => {
      state.sortedMessagesIds.push(...messagesIds);
    },

    REMOVE_OLD_MESSAGES: (state, messagesIds) => {
      messagesIds.forEach((messageId) => {
        const index = state.sortedMessagesIds.findIndex(
          (id) => id === messageId
        );
        state.sortedMessagesIds.splice(index, 1);
      });
    },

    SET_MESSAGE_SENT_STATUS: (state, { messageId, sentStatus }) => {
      if (!state.messages[messageId] || !sentStatus) {
        return;
      }
      state.messages[messageId].uiStatus.sentStatus = sentStatus;
    },

    REMOVE_MESSAGES: (state, messagesIds) => {
      messagesIds.forEach((messageId) => Vue.delete(state.messages, messageId));
    },

    REPLACE_MESSAGE_ID: (state, { oldMessageId, newMessageId }) => {
      const oldMessageIndex = state.sortedMessagesIds.indexOf(oldMessageId);
      if (oldMessageIndex > -1) {
        state.sortedMessagesIds.splice(oldMessageIndex, 1, newMessageId);
      }
    },

    SET_NEWEST_MESSAGE_TIMESTAMP: (state, timestamp) =>
      (state.newestMessageTimestamp = timestamp),

    SET_UNREAD_MESSAGES_COUNT: (state, newCount) =>
      (state.unreadMessagesCount = newCount),

    SET_GROUP_DATA: (state, groupData) => (state.groupData = groupData),

    SET_FAILED_TO_READ_MESSAGES: (state, failed) =>
      (state.failedReadingMessages = failed),

    SET_FINISHED_READING_HISTORICAL_MESSAGES: (state) =>
      (state.finishedReadingHistoricalMessages = true),

    SET_IS_CHAT_SCROLLED_TO_BOTTOM: (state, isChatScrolledToBottom) =>
      (state.isChatScrolledToBottom = isChatScrolledToBottom),

    SET_LAST_CHAT_SCROLL: (state, lastChatScrollPosition) =>
      (state.lastChatScrollPosition = lastChatScrollPosition)
  },

  getters: {
    chatMessages: (state, getters, rootState) => {
      return state.sortedMessagesIds.map((messageId) => {
        const msg = state.messages[messageId];
        const participant = rootState.participants[msg.from];
        const displayName = participant?.displayName || msg.fromDisplayName;
        const participantId =
          msg?.participantId || participant?.participantId || '';
        const message = {
          messageId: msg.messageId,
          creationTime: msg.creationTime,
          fromDisplayName: displayName,
          body: msg.body,
          sentStatus: msg.uiStatus.sentStatus,
          direction: msg.direction,
          participantId: participantId,
          originalAttachmentFile: msg.originalAttachmentFile
        };
        if (
          msg.attachmentsCount > 0 &&
          msg.data[0].metadata.attachmentType === ATTACHMENT_TYPE.IMAGE
        ) {
          message.data = msg.data;
          message.type = MESSAGE_TYPE.IMAGE;
        } else if (
          msg.attachmentsCount > 0 &&
          msg.data[0].metadata.attachmentType === ATTACHMENT_TYPE.DOCUMENT
        ) {
          message.data = msg.data;
          if (
            msg.data[0].metadata.mimeType.toLowerCase().includes('video') ||
            msg.data[0].metadata.fileName.endsWith('.mp4')
          ) {
            message.type = MESSAGE_TYPE.VIDEO;
          } else {
            message.type = MESSAGE_TYPE.DOCUMENT;
          }
        } else {
          message.type = MESSAGE_TYPE.TEXT;
        }

        return message;
      });
    },

    isLastMessageSentByMe: (state, getters) =>
      getters.chatMessages.length &&
      getters.chatMessages[getters.chatMessages.length - 1].direction ===
        'OUTBOUND',

    isChatVisibleAndScrolledToBottom: (
      state,
      getters,
      rootState,
      rootGetters
    ) => {
      if (rootGetters.isMobileWebMode) {
        return (
          rootState.mobile.isMobileChatOpen && state.isChatScrolledToBottom
        );
      } else {
        return (
          !rootState.isSidebarCollapsed &&
          rootState.activeSidebar === SIDEBARS.CHAT &&
          rootGetters.isChatOpen &&
          state.isChatScrolledToBottom &&
          !rootState.minimizedMode
        );
      }
    }
  }
};
