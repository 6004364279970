class TelemetriesHandlerService {
  constructor() {}

  getProperties(stats, showStats, isPublisher) {
    let properties = {};
    let binaryFound = 0;
    let timestamp = -1;
    if (isPublisher) {
      // extract all needed properties from the stats and mark each in the binaryFound flag
      for (const [, stat] of stats[0].rtcStatsReport.entries()) {
        if (stat.kind === 'audio') {
          if (stat.type === 'media-source' && stat.audioLevel !== undefined) {
            properties.audioLevel = stat.audioLevel;
            binaryFound |= 1;
            if (!showStats) {
              break;
            }
          }
          if (stat.type === 'outbound-rtp' && stat.packetsSent !== undefined) {
            properties.totalAudioPacketsSent = stat.packetsSent;
            binaryFound |= 2;
          }
          if (stat.type === 'inbound-rtp' && stat.packetsLost !== undefined) {
            properties.totalAudioPacketsSent = stat.packetsLost;
            binaryFound |= 4;
          }
          if (timestamp === -1 && stat.timestamp !== undefined) {
            properties.timestamp = stat.timestamp;
          }
          if (binaryFound === 7) {
            break;
          }
        }
      }
    } else {
      // extract all needed properties from the stats and mark each in the binaryFound flag
      for (const [, stat] of stats.entries()) {
        if (
          stat.kind === 'audio' &&
          stat.type === 'track' &&
          stat.audioLevel !== undefined
        ) {
          properties.audioLevel = stat.audioLevel;
          binaryFound |= 1;
          if (!showStats) {
            break;
          }
        }
        if (
          stat.kind === 'audio' &&
          stat.type === 'inbound-rtp' &&
          stat.bytesReceived !== undefined
        ) {
          properties.audioBytesReceived = stat.bytesReceived;
          binaryFound |= 2;
        }
        if (
          stat.kind === 'video' &&
          stat.type === 'inbound-rtp' &&
          stat.bytesReceived !== undefined
        ) {
          properties.videoBytesReceived = stat.bytesReceived;
          binaryFound |= 4;
        }
        if (timestamp === -1 && stat.timestamp !== undefined) {
          properties.timestamp = stat.timestamp;
        }
        // if all needed properties were found, break
        if (binaryFound === 7) {
          break;
        }
      }
    }
    return properties;
  }
}

const telemetriesHandler = new TelemetriesHandlerService();
export default telemetriesHandler;
