/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-netsuite': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.878 1v12.706L9.122 6.32H0V1h14.878zm-4.08 12.287c1.295 1.7 2.607 3.42 3.971 5.089 3.041 0 6.082 0 9.231-.109v5.43H9.122V11.1c.558.721 1.115 1.452 1.675 2.188zm5.057-11.201v4.235h2.39v11.186h4.669V2.086h-7.059zM1.086 7.299h4.67v11.077h2.28v4.343h-6.95V7.3z"/>'
  }
})
