<template>
  <div
    v-observe-keyboard.prevent.l2.esc="onClose"
    v-observe-keyboard.l2.enter="onConfirm"
    data-cy="v-modal"
    class="Vlt-modal Vlt-modal_visible Vlt-black vvd-scheme-alternate"
    :class="{
      'Vlt-modal--large': largeModal
    }"
    @click="dismissClickOutside && $emit('close', false)"
  >
    <div class="Vlt-modal__panel" v-on="{ click: stopPropagation }">
      <div v-if="title" class="Vlt-modal__header unselectable">
        <v-icon
          v-if="titleIcon"
          :iconName="titleIcon"
          class="title-icon"
          data-cy="modal-title-icon"
        />
        <h2 data-cy="modal-title" class="title">
          {{ title }}
        </h2>
        <div v-if="dismiss">
          <div class="Vlt-modal__dismiss" @click="$emit('close', false)"></div>
        </div>
      </div>

      <div data-cy="modal-content" class="Vlt-modal__content">
        <slot name="content" />
      </div>
      <div v-if="$slots.footerButtons" class="Vlt-modal__footer">
        <slot name="footerButtons" />
      </div>

      <div
        v-else-if="
          !noFooter && !footerButtons && (ok || cancel || $slots.extraButtons)
        "
        class="Vlt-modal__footer"
      >
        <v-button
          v-if="cancel"
          app
          sm
          secondary
          outline
          class="Vlt-modal__cancel modal-button"
          @click="$emit('close', false)"
          >{{ cancel }}</v-button
        >
        <v-button
          v-if="ok"
          :disabled="disableOk"
          app
          sm
          secondary
          class="Vlt-modal__confirm modal-button ok-button"
          @click="$emit('close', true)"
          >{{ ok }}</v-button
        >
        <slot name="extraButtons" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VModal',

  props: {
    title: {
      type: String,
      default: ''
    },
    titleIcon: {
      type: String,
      default: ''
    },
    dismiss: {
      type: Boolean,
      default: false
    },
    dismissEsc: {
      type: Boolean,
      default: false
    },
    dismissClickOutside: {
      type: Boolean,
      default: false
    },
    confirmEnter: {
      type: Boolean,
      default: false
    },
    cancel: {
      type: String,
      default() {
        return this.$t('v_modal.cancel_button');
      }
    },
    ok: {
      type: String,
      default() {
        return this.$t('v_modal.ok_button');
      }
    },
    largeModal: {
      type: Boolean,
      default: false
    },
    footerButtons: {
      type: Boolean,
      default: false
    },
    disableOk: {
      type: Boolean,
      default: false
    },
    noFooter: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    // If the modal was reached from a button click, we want to unfocus (blur) it to prevent ENTER key from opening it again
    // In some browsers (IE11), the blur method doesn't exist, this 'if' will prevent us from
    // calling it and getting an exception.
    if (document.activeElement?.blur) {
      document.activeElement.blur();
    }
  },

  methods: {
    onConfirm() {
      if (this.confirmEnter && !this.disableOk) {
        this.$emit('close', true);
      }
    },

    onClose() {
      if (this.dismissEsc) {
        this.$emit('close', false);
      }
    },

    stopPropagation() {
      event.preventDefault();
      event.stopPropagation();
    }
  }
};
</script>

<style scoped>
.modal-button,
.modal-button >>> .Vlt-btn {
  font-size: 14px;
}

.title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
}

.title-icon {
  width: 40px;
  height: 40px;
  margin-bottom: 26px;
}

.branded .title-icon {
  fill: var(--btn-cta-color-white-bg);
}

.keypad-icon {
  height: 24px;
  width: 24px;
  position: absolute;
  left: 22px;
  top: 18px;
}

.branded .vvd-scheme-alternate {
  --vvd-color-cta: var(--btn-cta-color-white-bg);
  --vvd-color-on-cta: var(--btn-cta-color-white-bg-text-color);
}
</style>
