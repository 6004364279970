/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-image-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M21 21v-5.379l-3.626-3.625-4.953 6.191-1.047 1.309-1.185-1.185-3.439-3.44L3 18.621V21h18zm3.31-4.19c-.094.096-.199.175-.31.24v5.35a1.6 1.6 0 01-1.6 1.6H1.6A1.6 1.6 0 010 22.4v-2.35a1.503 1.503 0 01-.31-2.36l.31-.311V1.6A1.6 1.6 0 011.6 0h20.8A1.6 1.6 0 0124 1.6v12.779l.31.31a1.5 1.5 0 010 2.122zM3 3v11.379l2.69-2.69 1.06-1.06 1.06 1.06 3.316 3.315 4.953-6.191 1.047-1.309L18.31 8.69 21 11.38V3H3zm9 3.75a2.25 2.25 0 11-4.5 0 2.25 2.25 0 014.5 0z"/></g><defs><clipPath id="clip0"><rect/></clipPath></defs>'
  }
})
