/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-forward-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 2h7v7h2V0h-9v2zm-1.2 9.8L18 7.6V13h2V4h-9v2h5.4l-4.2 4.2 1.6 1.6zM5.137.718A1.91 1.91 0 003.894.627l-.047.012-1.198.45C1.628 1.476.881 2.263.38 3.325l-.006.012c-.453.994-.47 2.158-.178 3.417.285 1.228.77 2.49 1.442 3.781a29.143 29.143 0 002.325 3.736c.88 1.204 1.746 2.24 2.602 3.104.855.863 1.883 1.738 3.076 2.625 1.2.893 2.435 1.675 3.704 2.347 1.28.679 2.532 1.168 3.751 1.456 1.25.296 2.41.279 3.4-.18l.012-.006c1.057-.509 1.835-1.263 2.215-2.29l.446-1.206.012-.047a1.936 1.936 0 00-.09-1.24c-.186-.438-.54-.754-.915-.986-.33-.235-1.143-.765-2.407-1.572-.694-.443-1.35-.773-1.96-.961-.61-.188-1.227-.25-1.807-.088l-.02.007c-.79.243-1.524.627-1.909 1.286-.05.008-.168.014-.383-.033-.444-.102-1.057-.424-1.852-1.08-.834-.687-1.42-1.207-1.778-1.568-.34-.342-.85-.925-1.544-1.769-.659-.802-.964-1.413-1.047-1.846l-.005-.02c-.05-.229-.042-.353-.032-.404.66-.394 1.027-1.14 1.247-1.934.337-1.134-.172-2.458-1.022-3.798L6.126 1.66c-.229-.383-.545-.75-.99-.942z"/>'
  }
})
