import { render, staticRenderFns } from "./MobileCard.vue?vue&type=template&id=5ef4c455&scoped=true&"
import script from "./MobileCard.vue?vue&type=script&lang=js&"
export * from "./MobileCard.vue?vue&type=script&lang=js&"
import style0 from "./MobileCard.vue?vue&type=style&index=0&id=5ef4c455&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5ef4c455",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1498271919/src/v-meetings/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5ef4c455')) {
      api.createRecord('5ef4c455', component.options)
    } else {
      api.reload('5ef4c455', component.options)
    }
    module.hot.accept("./MobileCard.vue?vue&type=template&id=5ef4c455&scoped=true&", function () {
      api.rerender('5ef4c455', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/mobile/MobileCard.vue"
export default component.exports