/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-reload': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.5 2A6.5 6.5 0 1014 8.5a.5.5 0 011 0A7.5 7.5 0 117.5 1c2.862 0 5.209 1.784 6.448 4.278a.5.5 0 01-.896.444C11.947 3.498 9.91 2 7.5 2z"/><path pid="1" d="M13.5 0a.5.5 0 01.5.5v5a.5.5 0 01-.5.5h-5a.5.5 0 010-1H13V.5a.5.5 0 01.5-.5z"/>'
  }
})
