var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vwc-banner", {
    staticClass: "banner",
    attrs: {
      "data-cy": "no-connection-banner",
      connotation: "alert",
      message: _vm.$t("connectivity_banner.message"),
      open: "true",
      icon: "wifi-solid",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }