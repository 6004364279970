export default function getInitialState() {
  return {
    cssVariables: {},
    mainColor: null,
    brandText: null,
    shortCompanyUrl: null,
    brandImageColored: null,
    brandImageWhite: null,
    brandedFavicon: null,
    debuggingColorFromQueryString: null,
    hidePoweredByVonage: false
  };
}
