/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-bounce-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.69 12.31L1.5 16.5V6H12l-4.19 4.19 5.69 5.689 7.94-7.94 2.12 2.122L13.5 20.12l-7.81-7.81z"/>'
  }
})
