/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-pdf-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.5 9h9v13.5c0 .9-.6 1.5-1.5 1.5H3c-.9 0-1.5-.6-1.5-1.5v-21C1.5.6 2.1 0 3 0h10.5v9zm-4.645 6.408c0 .67-.198 1.188-.593 1.554-.395.365-.957.547-1.685.547h-.456V19.5H4.5v-6h2.077c.758 0 1.327.165 1.707.497.38.332.57.8.57 1.41zm-2.734.78h.295c.244 0 .437-.068.581-.204.144-.137.216-.326.216-.567 0-.405-.224-.607-.674-.607h-.418v1.379zm8.782.184c0 1.002-.275 1.774-.827 2.315-.552.54-1.326.813-2.325.813H9.81v-6h2.077c.963 0 1.707.246 2.23.739.523.492.786 1.204.786 2.133zm-1.682.058c0-.55-.11-.957-.327-1.223-.217-.266-.547-.398-.99-.398h-.472v3.36h.361c.493 0 .853-.142 1.083-.428.23-.285.345-.723.345-1.31zm2.757 3.07h1.597v-2.253h1.797v-1.301h-1.797V14.8h1.949V13.5h-3.546v6zM16.5 6V0l6 6h-6z"/>'
  }
})
