/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-device-cloud': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<circle pid="0" cx="13.6" cy="16.3" r="1.1"/><circle pid="1" cx="13.6" cy="13.2" r="1.1"/><circle pid="2" cx="10.5" cy="13.2" r="1.1"/><circle pid="3" cx="10.5" cy="16.3" r="1.1"/><path pid="4" d="M8.5 20.6h7.1c.9 0 1.6-.7 1.6-1.7V2.4c0-.9-.7-1.7-1.6-1.7H8.5c-.9 0-1.6.7-1.6 1.7v16.5c0 .9.7 1.7 1.6 1.7zM8.2 2.4c0-.2.2-.4.3-.4h7.1c.2 0 .3.2.3.4v16.5c0 .2-.2.4-.3.4H8.5c-.2 0-.3-.2-.3-.4V2.4z"/><path pid="5" d="M14.9 4H9.3v6.4h5.6V4zm-1.3 5.1h-3V5.3h3v3.8z"/><path pid="6" d="M20.1 11.4c-.2-.4-.5-.9-.8-1.3-.3-.3-.7-.4-1-.1-.3.2-.4.6-.1.9.3.4.6.9.8 1.3l.1.3.3.1c2 .7 3.3 2.5 3.3 4.6 0 2.7-2.2 4.8-4.9 4.8H5.1c-2.1 0-3.8-1.7-3.8-3.8 0-1.7 1.1-3.1 2.7-3.6l.4-.1.1-.5c.2-1.2.8-2.4 1.5-3.3.2-.3.2-.7-.1-.9-.3-.3-.7-.2-.9.1-.8 1-1.4 2.2-1.7 3.5-2 .7-3.3 2.6-3.3 4.7 0 2.8 2.3 5.1 5.2 5.1h12.6c3.4 0 6.2-2.7 6.2-6.1 0-2.6-1.6-4.8-3.9-5.7z"/>'
  }
})
