import Loggly from './loggly-service';
import {
  isElectron,
  isMobileDevice,
  OS,
  browserName,
  getUserAgentDetails
} from '@/helpers/global-helpers';
import router from '@/router';

export default {
  init() {
    this.contextData = {
      appVersion: process.env.VUE_APP_VERSION,
      gitRevision: process.env.VUE_APP_GIT_REVISION,
      appName: 'VBC-Meetings',
      appEnv: process.env.NODE_ENV,
      platform: isElectron() ? 'Native' : 'Web',
      isMobile: isMobileDevice,
      URL: location.hostname,
      browser: browserName,
      operationSystem: OS.name,
      userAgent: getUserAgentDetails()
    };

    Loggly.init(process.env.VUE_APP_LOGGLY_TAG);
  },

  updateContext(contextData) {
    this.contextData = {
      ...this.contextData,
      ...contextData
    };
  },

  log(name, category, data = {}) {
    Loggly.push({
      contextData: this.contextData,
      name,
      category,
      currentRoute: router.currentRoute.name,
      data,
      type: 'info'
    });
  },

  error(name, category, data = {}) {
    Loggly.push({
      contextData: this.contextData,
      name,
      category,
      currentRoute: router.currentRoute.name,
      data,
      type: 'error'
    });
  },

  warning(name, category, data = {}) {
    Loggly.push({
      contextData: this.contextData,
      name,
      category,
      currentRoute: router.currentRoute.name,
      data,
      type: 'warning'
    });
  }
};
