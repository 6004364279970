var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "transcription-row",
      class: { "system-message": _vm.isSystemMessageBlock },
      attrs: { "data-cy": "transcription-row" },
    },
    [
      !_vm.transcript.hideHeader
        ? _c("div", { staticClass: "transcript-header" }, [
            _c("span", { staticClass: "participant-name" }, [
              _vm._v(_vm._s(_vm.transcript.participantDisplayName)),
            ]),
            _c("span", { staticClass: "transcript-time" }, [
              _vm._v(_vm._s(_vm.transcript.formattedTime)),
            ]),
          ])
        : _vm._e(),
      _c(
        "div",
        { staticClass: "transcript-text", style: _vm.transcript.textStyle },
        _vm._l(_vm.transcript.texts, function (sentence, index) {
          return _c(
            "span",
            { key: index, class: { "in-progress": !sentence.isComplete } },
            [_vm._v(" " + _vm._s(sentence.text) + " ")]
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }