<template>
  <vwc-dialog
    v-observe-keyboard.l2.enter="primaryAction"
    data-cy="error-modal"
    :close-button="showCancelButton"
    class="vvd-scheme-alternate dialog"
    :open="errorDialog"
    :heading="$t('error_modal.title')"
    @closed="hide"
    @click.stop
  >
    <vwc-icon
      slot="icon"
      size="large"
      type="warning-line"
      class="dialog-icon"
    ></vwc-icon>

    <div class="content">
      <div class="text">{{ errorDialog.text }}</div>
    </div>

    <vwc-button
      slot="secondaryAction"
      data-cy="cancel-btn"
      :label="$t('error_modal.cancel_btn')"
      layout="outlined"
      type="submit"
      @click="hide"
    />

    <vwc-button
      slot="primaryAction"
      data-cy="primary-btn"
      :label="errorDialog.primaryButtonText"
      layout="filled"
      connotation="primary"
      :icon="errorDialog.primaryButtonIcon"
      type="submit"
      @click="primaryAction"
    />
  </vwc-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  name: 'ErrorModal',

  props: {
    showCancelButton: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState(['errorDialog'])
  },

  methods: {
    ...mapActions([
      'setErrorDialog',
      'forceMuteSession',
      'kickOutParticipant',
      'forceMuteParticipant'
    ]),

    async primaryAction() {
      if (this.errorDialog.buttonCallback) {
        await this.errorDialog.buttonCallback();
      }
      this.hide();
    },

    hide() {
      this.setErrorDialog(null);
    }
  }
};
</script>

<style scoped>
vwc-dialog {
  --mdc-dialog-min-width: 390px;
  --mdc-dialog-max-width: 500px;
  --mdc-dialog-z-index: 100000;
}
.dialog-icon {
  color: var(--vvd-color-on-canvas);
  margin-bottom: 25px;
}
</style>
