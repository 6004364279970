var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "chat-wrapper vvd-root vvd-theme-alternate" },
    [
      _vm.isMobileWebMode && _vm.showImageView
        ? _c("MobileImageView", {
            staticClass: "mobile-image-view",
            attrs: { message: _vm.imageMessage },
            on: {
              closeImageView: function ($event) {
                return _vm.$router.back()
              },
            },
          })
        : _vm._e(),
      _c(
        "v-chat",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.hasMessagingError,
              expression: "!hasMessagingError",
            },
          ],
          ref: "vChat",
          staticClass: "chat",
          attrs: {
            enableAttachments: "",
            group: "",
            disabled: _vm.isChatDisabled,
            locale: _vm.$i18n.locale,
          },
          on: {
            userInputKeyPress: function ($event) {
              $event.stopPropagation()
              return _vm.userInputKeyPress($event)
            },
            sendClick: function ($event) {
              $event.stopPropagation()
              return _vm.sendClicked($event)
            },
            chatDragOver: function ($event) {
              $event.preventDefault()
            },
            downloadClick: function ($event) {
              $event.stopPropagation()
              return _vm.downloadFile($event.detail.message.uuid)
            },
            cancelClick: function ($event) {
              $event.stopPropagation()
              return _vm.cancelUpload($event.detail.message)
            },
            fileClicked: function ($event) {
              $event.stopPropagation()
              return _vm.fileClicked($event.detail.message.uuid)
            },
            metaMessageActionClicked: function ($event) {
              $event.stopPropagation()
              return _vm.handleTryAgain($event.detail.message)
            },
            userAttachments: function ($event) {
              $event.stopPropagation()
              return _vm.handleUserAttachmentsAdd($event)
            },
            isLatchedToBottomChanged: function ($event) {
              $event.stopPropagation()
              return _vm.handleIsChatScrolledToBottomChanged(
                _vm.$refs.vChat.isLatchedToBottom
              )
            },
          },
        },
        [
          !_vm.isMobileWebMode
            ? _c("vwc-3-button", {
                attrs: {
                  slot: "action-buttons",
                  icon: "emoji-line",
                  size: "condensed",
                  shape: "rounded",
                },
                on: {
                  click: function ($event) {
                    return _vm.openEmojiKeyboardClicker()
                  },
                },
                slot: "action-buttons",
              })
            : _vm._e(),
          !_vm.isMobileWebMode
            ? _c("em-emoji-picker", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showEmojiKeyboard,
                    expression: "showEmojiKeyboard",
                  },
                ],
                ref: "emojiPicker",
                staticClass: "emoji-picker",
                attrs: {
                  slot: "input-action",
                  theme: "light",
                  dynamicWidth: "true",
                  previewPosition: "none",
                },
                slot: "input-action",
              })
            : _vm._e(),
          _vm.chatMessages.length === 0
            ? _c(
                "div",
                {
                  staticClass: "empty-state-container",
                  attrs: { slot: "main" },
                  slot: "main",
                },
                [
                  _c(
                    "vwc-empty-state",
                    {
                      attrs: {
                        heading: _vm.$t("messages_list.empty_state_title"),
                        body: _vm.$t("messages_list.empty_state_description"),
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          slot: "graphic",
                          "data-cy": "empty-chat-img",
                          src: _vm.emptyStatImg,
                        },
                        slot: "graphic",
                      }),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _vm.hasMessagingError ||
      _vm.showUnsupportedFileDialog ||
      _vm.showUnsupportedFolderDialog ||
      _vm.showMaxFilesSizeReachedDialog ||
      _vm.showMaxNumberOfFilesReachedDialog
        ? _c(
            "div",
            { staticClass: "dialog-container" },
            [
              _c("ChatAlerts", {
                attrs: {
                  "show-messaging-error": _vm.hasMessagingError,
                  "show-unsupported-file-dialog": _vm.showUnsupportedFileDialog,
                  "show-unsupported-folder-dialog":
                    _vm.showUnsupportedFolderDialog,
                  "show-max-files-size-reached-dialog":
                    _vm.showMaxFilesSizeReachedDialog,
                  "show-max-number-of-files-reached-dialog":
                    _vm.showMaxNumberOfFilesReachedDialog,
                },
                on: {
                  "action-button-click": function ($event) {
                    return _vm.handleChatErrorDialogActionButtonClick()
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }