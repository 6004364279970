<template>
  <div class="issue-message" data-cy="video-disabled-banner">
    <div class="container">
      <vwc-icon
        class="icon vvd-scheme-alternate"
        type="error-solid"
        connotation="alert"
      />
      <div class="text">{{ $t('video_disabled_banner.text') }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoDisabledBanner'
};
</script>

<style scoped>
.container {
  display: flex;
  align-items: center;
  min-width: 360px;
  min-height: 36px;
  background-color: white;
  border-radius: 6px;
}

.issue-message {
  align-self: center;
  position: absolute;
  z-index: 100;
}

.text {
  color: black;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-left: 10px;
}

.icon {
  margin-left: 12px;
}
</style>
