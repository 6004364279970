export default {
  SET_HAS_SCREENSHARE_PERMISSIONS: (state, hasPermissions) =>
    (state.hasScreensharePermissions = hasPermissions),

  SET_SCREENSHARE_SOURCES: (state, sources) =>
    (state.screenshareSources = sources),

  SET_IS_SCREENSHARE_SUPPORTED: (state, isSupported) =>
    (state.isScreenshareSupported = isSupported)
};
