<template>
  <div class="image-view-container unselectable">
    <div data-cy="image-view-header" class="image-view-header">
      <v-icon
        data-cy="image-view-back-btn"
        :icon-name="'Vlt-icon-arrow-thin-left'"
        class="exit-icon sm"
        @click="$emit('closeImageView')"
      />
      <div class="image-view-header-text">
        <h5 class="image-header-display-name">{{ displayName }}</h5>
        <p class="image-header-time">{{ formattedTime }}</p>
      </div>
      <v-icon
        v-if="!isUploading && !isSafari"
        data-cy="image-view-download-btn"
        class="download-button"
        sm
        :iconName="'Vlt-icon-download'"
        @click.stop="downloadImage"
      />
    </div>
    <v-spinner v-if="isUploading" class="spinner" white />
    <div v-else class="image-container">
      <div
        v-if="showDownloadMessage"
        class="download-message-container unselectable"
      >
        <p class="download-message-text">
          {{ $t('mobile_image_view.download_message_text') }}
        </p>
        <v-icon
          class="download-message-exit-icn"
          :icon-name="'Vlt-icon-cross'"
          xs
          @click="showDownloadMessage = false"
        />
      </div>
      <img :src="imageUrl" @load="imageLoaded" />
    </div>
  </div>
</template>

<script>
import { formatTime } from '@/helpers/global-helpers';
import { mapActions } from 'vuex';

export default {
  name: 'MobileImageView',

  props: {
    message: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isUploading: true,
      isDownloading: false,
      imageUrl: '',
      showDownloadMessage: false
    };
  },

  computed: {
    isMe() {
      return this.message.direction === 'OUTBOUND';
    },

    displayName() {
      return this.isMe
        ? this.$t('mobile_image_view.is_me_display_name_text')
        : this.message.fromDisplayName;
    },

    formattedTime() {
      return formatTime(this.message.creationTime);
    },

    isSafari() {
      return window.isSafari;
    }
  },

  async mounted() {
    this.imageUrl = await this.getFileUrl({
      messageId: this.message.messageId
    });
    this.isUploading = false;
  },

  methods: {
    ...mapActions('messaging/attachments', [
      'downloadFileFromUrl',
      'getFileUrl'
    ]),

    imageLoaded() {
      this.showDownloadMessage = this.isSafari;
    },

    downloadImage() {
      location.replace(this.imageUrl);
    }
  }
};
</script>

<style scoped>
.image-view-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: unset;
}

.image-view-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px 8px 16px;
  background-color: #1a1a1a;
  height: 48px;
}

.image-view-header-text {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.image-header-display-name {
  color: white;
  font-size: 16px;
  margin: unset;
  text-align: center;
}

.image-header-time {
  color: white;
  font-size: 12px;
  margin: unset;
  text-align: center;
}

.image-container {
  align-self: center;
  justify-content: center;
  margin: auto;
  overflow: auto;
}

.download-message-container {
  width: 250px;
  height: 32px;
  background-color: rgba(13, 13, 13, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: absolute;
  z-index: 3000;
  left: 0;
  right: 0;
  margin: 12px auto 0;
  text-align: center;
  padding: 8px;
}

.download-message-text {
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-weight: 600;
  margin: unset;
}

.download-message-exit-icn {
  color: rgba(255, 255, 255, 1);
  align-self: center;
}

.spinner {
  align-self: center;
  justify-content: center;
  margin: auto;
}
</style>
