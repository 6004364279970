import i18n from '@/i18n';
import { SUPPORTED_LOCALES } from '@/consts/global-consts';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import { sessionStorageService } from '@/services/storage-service';

export default {
  setDefaultLocale: (_, { locale }) => {
    if (
      locale &&
      typeof locale === 'string' &&
      locale.length > 0 &&
      locale !== 'default'
    ) {
      if (!Object.values(SUPPORTED_LOCALES).includes(locale)) {
        logger.warning('cant-set-default-locale', LOG_CATEGORIES.LOCALIZATION, {
          message: `failed to set default locale: ${locale} since it's not one of the supported: ${Object.values(
            SUPPORTED_LOCALES
          )}`
        });
        return;
      }
      i18n.locale = locale;
      sessionStorageService.setItem('locale', locale);
    }
  }
};
