/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-phone-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.02.064c.424-.106.86-.085 1.275.093.456.196.782.57 1.02.963l1.58 2.465c.46.718.8 1.397.99 2.031.19.63.245 1.267.066 1.864-.228.815-.607 1.58-1.288 1.98-.01.053-.017.178.034.41l.004.02c.086.442.4 1.067 1.082 1.889.716.864 1.245 1.461 1.595 1.811.37.37.976.902 1.838 1.607.821.671 1.456 1.002 1.918 1.107.23.05.354.042.406.033.397-.675 1.154-1.068 1.969-1.317l.021-.006c1.155-.321 2.505.202 3.88 1.072 1.308.827 2.148 1.37 2.488 1.61.386.237.752.56.945 1.01.178.413.199.85.093 1.274l-.012.049-.462 1.236c-.393 1.052-1.197 1.823-2.287 2.343l-.011.006c-1.02.468-2.214.486-3.504.184-1.259-.295-2.55-.795-3.874-1.49a29.804 29.804 0 01-3.825-2.402c-1.233-.909-2.294-1.805-3.178-2.689-.884-.883-1.78-1.945-2.688-3.178a29.804 29.804 0 01-2.403-3.825C.997 8.891.497 7.599.202 6.341-.1 5.05-.082 3.856.386 2.836l.006-.012C.912 1.735 1.683.931 2.735.538L3.971.076 4.02.064z"/>'
  }
})
