/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-wordpress': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M12 24c6.653 0 11.998-5.345 11.998-12 0-6.544-5.345-11.89-11.672-11.998C5.563-.108 0 5.347 0 11.892 0 18.655 5.345 24 12 24zM12 .547c6.326 0 11.453 5.127 11.453 11.563 0 6.109-5.236 11.236-11.454 11.236-6.327 0-11.454-5.127-11.454-11.454C.545 5.674 5.781.547 12 .547zM7.2 6.656c.109.327-.219.436-.546.436-.327 0-.654 0-.982.11 1.2 3.708 2.51 7.308 3.71 11.126.108-.11.108-.218.108-.218.655-2.073 1.418-4.146 2.073-6.218.109-.218 0-.437 0-.655-.327-.982-.764-2.072-1.091-3.054-.218-.764-.545-1.2-1.309-1.091-.11 0-.327-.327-.436-.436.109-.11.327-.328.545-.328H14.4c.218 0 .436.219.546.328-.219.109-.328.436-.546.436-.327.11-.654.11-1.09.11 1.2 3.708 2.508 7.308 3.708 11.126.546-1.855 1.09-3.6 1.636-5.345.437-1.528.219-2.946-.654-4.364-.218-.436-.546-.763-.655-1.2-.109-.545-.327-1.09-.218-1.636.11-.873.764-1.309 1.745-1.418C13.963-.107 6.328 1.529 3.492 6.438c1.09 0 2.181-.11 3.163-.11.218 0 .546.11.546.328zM9.163 21.6v.218c2.073.546 4.145.546 6.327-.218l-3.273-8.836c-1.09 3.055-2.072 6-3.054 8.836zm-1.636-.327L2.618 7.856C.436 12.11 2.073 18.655 7.527 21.273zm14.726-8.618c.11-2.072-.545-4.8-1.2-5.236-.109.764 0 1.527-.218 2.291-.545 1.745-1.09 3.49-1.745 5.236-.655 1.854-1.31 3.818-1.964 5.672 3.164-1.745 4.909-4.363 5.127-7.963z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
