/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-smile-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm3 7.5c.9 0 1.5.6 1.5 1.5s-.6 1.5-1.5 1.5-1.5-.6-1.5-1.5.6-1.5 1.5-1.5zm-6 0c.9 0 1.5.6 1.5 1.5s-.6 1.5-1.5 1.5S7.5 9.9 7.5 9 8.1 7.5 9 7.5zm8.7 7.95c-.9 2.4-3.15 4.05-5.7 4.05-2.55 0-4.8-1.65-5.7-4.05-.3-.75.15-1.65.9-1.8.75-.3 1.65.15 1.95.9.45 1.2 1.65 1.95 2.85 1.95 1.2 0 2.4-.75 2.85-1.95.3-.75 1.2-1.2 1.95-.9.75.15 1.2 1.05.9 1.8z"/>'
  }
})
