/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-plus': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.75 2.25a.75.75 0 00-1.5 0v9h-9a.75.75 0 000 1.5h9v9a.75.75 0 001.5 0v-9h9a.75.75 0 000-1.5h-9v-9z"/>'
  }
})
