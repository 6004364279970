/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cursor': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.943.025a.75.75 0 00-.918.918l6 22.5a.75.75 0 001.396.142l4.035-8.069 6.764 6.764a2.872 2.872 0 004.06-4.06l-6.764-6.764 8.07-4.035a.75.75 0 00-.143-1.396l-22.5-6zm6.022 21.119L1.808 1.808l19.336 5.157-7.23 3.614a.75.75 0 00-.194 1.201l7.5 7.5a1.375 1.375 0 01.297 1.495 1.373 1.373 0 01-2.237.445l-7.5-7.5a.75.75 0 00-1.2.195l-3.615 7.229z"/>'
  }
})
