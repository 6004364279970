<template>
  <div
    class="layout"
    :class="{
      'frameless-titlebar-layout': showFramelessTitlebar
    }"
  >
    <div class="main-container">
      <LocaleSwitcher
        v-if="showLocaleSwitcher && isLocaleSwitcherEnabled"
        class="locale-switcher"
      />
      <div class="prejoin-template-container smooth-flex-grow-animation">
        <transition name="title-fade" mode="out-in">
          <div :key="title" class="header">
            <h3 class="subtitle Vlt-white">{{ subtitle }}</h3>
            <div class="title-header">
              <h2 class="title Vlt-white" data-cy="pre-join-title">
                {{ title }}
              </h2>
              <vwc-circular-progress
                v-if="showTitleLoader"
                class="circular-progress"
                indeterminate
                density="-4"
              />
            </div>
          </div>
        </transition>

        <div
          class="content-container"
          :class="{ 'light-gray-background': useLightGrayBackground }"
        >
          <slot name="main-content"></slot>
        </div>

        <div class="footer">
          <slot name="footer-button"></slot>
        </div>
      </div>
    </div>

    <div>
      <slot name="sidebar"></slot>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import LocaleSwitcher from '@/components/LocaleSwitcher';

export default {
  components: {
    LocaleSwitcher
  },

  props: {
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    useLightGrayBackground: {
      type: Boolean,
      default: false
    },
    showTitleLoader: {
      type: Boolean,
      default: false
    },
    showLocaleSwitcher: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters(['showFramelessTitlebar', 'isLocaleSwitcherEnabled'])
  }
};
</script>

<style scoped>
.prejoin-template-container .header.title-fade-leave,
.prejoin-template-container .header.title-fade-enter-to {
  opacity: 1;
}

.prejoin-template-container .header.title-fade-leave-to,
.prejoin-template-container .header.title-fade-enter {
  opacity: 0;
}

.prejoin-template-container .header.title-fade-enter-active,
.prejoin-template-container .header.title-fade-leave-active {
  transition: opacity 0.35s ease-out;
}

.layout {
  display: flex;
  height: 100vh;
  width: 100vw;
  background: #0d0d0d;
}

.layout.frameless-titlebar-layout {
  padding-top: 40px;
}

.main-container {
  flex: 1;
  height: 100%;
  display: flex;
  overflow: auto;
  padding: 16px 0;
  position: relative;
}

.prejoin-template-container {
  flex: 1;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  place-items: center;
  place-content: center;
  overflow: hidden;
  user-select: none;
}

.prejoin-template-container .header,
.prejoin-template-container .footer {
  display: flex;
  flex-direction: column;
  place-items: center;
  transition: transform 0.2s ease-out;
}

.header {
  justify-content: flex-end;
  transition: margin-bottom 0.25s ease-out;
  margin-bottom: 32px;
  padding: 0 16px;
}

.footer {
  transition: margin-top 0.25s ease-out;
  margin-top: 40px;
}

@media only screen and (max-height: 660px) {
  .header {
    margin-bottom: 8px;
    transform: scale(0.8);
  }

  .footer {
    margin-top: 16px;
    transform: scale(0.9);
  }
}

.header {
  justify-content: center;
}

.header .subtitle,
.header .title {
  margin: 0;
}

.header .subtitle {
  font-size: 20px;
  line-height: 28px;
}

.header .title {
  font-size: 26px;
  line-height: 36px;
}

.footer {
  justify-content: flex-start;
}

.content-container {
  display: flex;
  /* This means the minimum height is 438px, but on tall screens we can use 60% height */
  --content-container-height: max(60vh, 438px);
  height: var(--content-container-height);
  /* With this calc, we have a 4 / 3 aspect ratio without using the too-fresh "aspect-ratio" feature */
  width: calc((4 / 3) * var(--content-container-height));
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 16px;
}

.content-container.light-gray-background {
  background: #1a1a1a;
}

@media only screen and (max-width: 660px) {
  .content-container {
    aspect-ratio: unset;
    width: calc(100% - 32px);
  }
}

.footer >>> vwc-button {
  min-width: 185px;
}

.smooth-flex-grow-animation {
  -webkit-animation: flexGrow 500ms ease forwards;
  -o-animation: flexGrow 500ms ease forwards;
  animation: flexGrow 500ms ease forwards;
}

@keyframes flexGrow {
  to {
    flex: 1;
  }
}

.circular-progress {
  margin-left: 16px;
}

.title-header {
  display: flex;
  flex-direction: row;
  place-items: center;
  place-content: center;
}

.locale-switcher {
  position: absolute;
  top: 24px;
  z-index: 10;
}

.windows-os .locale-switcher {
  left: 24px;
}

.mac-os .locale-switcher {
  right: 24px;
}
</style>
