/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-audio-off': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1.433 21.509L21.834 1.107h-.001l.237-.238a.75.75 0 111.06 1.06l-1.923 1.924c3.833 5.494 3.333 13.178-1.5 18.099a.75.75 0 11-1.07-1.052c4.255-4.332 4.752-11.072 1.49-15.967l-1.763 1.763c2.42 3.737 2.018 8.83-1.209 12.114a.75.75 0 11-1.07-1.051c2.648-2.695 3.044-6.843 1.19-9.973l-1.823 1.822c.992 1.954.686 4.428-.918 6.061a.75.75 0 11-1.07-1.05c1.018-1.038 1.3-2.562.846-3.87l-3.398 3.399v6.757a.5.5 0 01-.827.378l-3.381-2.926L2.53 22.53a.75.75 0 01-1.097-1.021zm2.348-5.176H2.183C.977 16.333 0 15.338 0 14.11V9.888C0 8.66.977 7.666 2.183 7.666h2.182l5.72-4.95a.5.5 0 01.827.377v6.108l-1.5 1.5V5.28L4.925 9.166H2.183a.703.703 0 00-.683.722v4.222c0 .425.33.723.683.723h2.741l.191.165-1.334 1.335zm3.986.96l1.646 1.424v-3.07l-1.646 1.646z"/>'
  }
})
