<template>
  <div
    class="message-screen"
    :class="{
      'message-screen-with-logo': shouldShowVonageLogo
    }"
    data-cy="message-screen"
  >
    <div v-if="shouldShowVonageLogo" class="vonage-logo">
      <img src="/vonage-logo-with-text.svg" />
    </div>

    <LocaleSwitcher v-if="isLocaleSwitcherEnabled" class="locale-switcher" />

    <div
      class="content"
      :class="{
        mobile: isMobileWebMode,
        'content-with-logo': shouldShowVonageLogo
      }"
    >
      <div
        v-if="isIconWithSuccessConnotation && isBranded"
        class="branded-icon"
      >
        <vwc-icon
          type="check-circle-line"
          class="confirm-icon"
          connotation="success"
        />
      </div>
      <img
        v-else-if="!isBranded && globalMessage.image"
        v-show="imageLoaded"
        class="image"
        :src="globalMessage.image"
        @load="imageLoaded = true"
      />
      <div v-else class="branded-icon">
        <vwc-icon :type="globalMessage.brandedIcon" class="icon" />
      </div>

      <h1 data-cy="message-screen-title" class="title">
        {{ title }}
      </h1>
      <!-- eslint-disable-next-line vue/no-v-html -->
      <p data-cy="message-screen-content" class="message" v-html="message"></p>
      <vwc-button
        v-if="globalMessage.buttonText"
        class="reconnect-button"
        data-cy="reconnect-button"
        :label="!callbackInProgress ? buttonText : ''"
        :disabled="callbackInProgress"
        layout="filled"
        @click="onClick"
      >
        <vwc-circular-progress
          v-if="callbackInProgress"
          indeterminate
          density="-7.5"
        />
      </vwc-button>
      <p v-if="buttonErrorText" class="message error">{{ buttonErrorText }}</p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { wait } from '@/helpers/global-helpers';
import LocaleSwitcher from '@/components/LocaleSwitcher';

export default {
  name: 'MessageScreen',

  components: { LocaleSwitcher },

  data() {
    return {
      callbackInProgress: false,
      buttonErrorText: null,
      imageLoaded: false
    };
  },

  computed: {
    ...mapState(['globalMessage', 'isBranded']),
    ...mapGetters(['isMobileWebMode', 'isLocaleSwitcherEnabled']),

    title() {
      return this.$t(
        this.globalMessage.title.i18nKey,
        this.globalMessage.title.params
      );
    },

    message() {
      return this.$t(
        this.globalMessage.message.i18nKey,
        this.globalMessage.message.params
      );
    },

    buttonText() {
      return this.$t(
        this.globalMessage.buttonText.i18nKey,
        this.globalMessage.buttonText.params
      );
    },

    isIconWithSuccessConnotation() {
      return !!this.globalMessage.showVonageLogo;
    },

    shouldShowVonageLogo() {
      return !!this.globalMessage.showVonageLogo && !this.isBranded;
    }
  },

  methods: {
    async onClick() {
      if (this.globalMessage.buttonCallback) {
        this.callbackInProgress = true;
        const startTime = performance.now();
        try {
          await this.globalMessage.buttonCallback();
          this.buttonErrorText = null;
        } catch (error) {
          this.buttonErrorText = this.$t(
            this.globalMessage.errorText.i18nKey,
            this.globalMessage.errorText.params
          );
        } finally {
          await this._applyMinimumLoadingTime(startTime);
          this.callbackInProgress = false;
        }
      }
    },

    async _applyMinimumLoadingTime(startTime) {
      if (!this.globalMessage.minimumLoadingTime) {
        return;
      }

      const endTime = performance.now();
      const duration = endTime - startTime;
      const remainingLoadingTime =
        this.globalMessage.minimumLoadingTime - duration;

      if (remainingLoadingTime <= 0) {
        return;
      }

      await wait(remainingLoadingTime);
    }
  }
};
</script>

<style scoped>
.message-screen {
  display: flex;
  flex-direction: column;
  align-content: center;
  overflow-y: auto;
  width: 100%;
  height: 100%;
  background-color: #0d0d0d;
}

.message-screen-with-logo {
  padding: 80px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #0d0d0d;
  text-align: center;
  flex: 1;
}

.content .reconnect-button {
  margin-top: 24px;
}

.image {
  width: 240px;
  height: 240px;
}

.content.mobile {
  justify-content: flex-start;
  padding: 120px 10px 10px 10px;
}

.content .title {
  white-space: pre-line;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0px;
  margin-bottom: 8px;
  margin-top: 24px;
  color: white;
}

.mobile .title {
  font-size: 16px;
}
.content .message {
  white-space: pre-line;
  font-size: 16px;
  line-height: 20px;
  color: white;
}

.mobile .message {
  font-size: 14px;
}

.content .message.error {
  color: #f25a6b;
}

.branded-icon {
  background-color: #333333;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-bottom: 20px;
}

.icon {
  width: 60px;
  height: 60px;
  color: white;
}

.confirm-icon {
  width: 60px;
  height: 60px;
}

.vonage-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #0d0d0d;
}

.locale-switcher {
  position: absolute;
  top: 24px;
  z-index: 10;
}

.windows-os .locale-switcher {
  left: 24px;
}

.mac-os .locale-switcher {
  right: 24px;
}
</style>
