/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-sidebar-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 22.5h21A1.5 1.5 0 0024 21V3a1.5 1.5 0 00-1.5-1.5h-21A1.5 1.5 0 000 3v18c0 .83.672 1.5 1.5 1.5zm19.5-3H3v-15h18v15zM4.5 6H9v12H4.5V6z"/>'
  }
})
