/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-park': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.826 5.973a5.973 5.973 0 11-11.946 0 5.973 5.973 0 0111.946 0zm-8.145-3.475v7.058h1.164V7.125h1.639c1.433 0 2.24-1.103 2.24-2.403 0-1.23-.733-2.224-2.098-2.224h-2.945zm1.164 1.18h1.593c.683 0 1.109.42 1.109 1.099 0 .718-.472 1.176-1.187 1.176h-1.515V3.68zM5.69 2.662c-.163-.163-.489-.49-.978-.49h-.326l-.113.036c-.715.216-3.258.986-2.493 4.2.652 3.584 3.095 7.005 6.027 10.1 3.258 3.42 7.656 5.864 10.263 5.864h.488c2.444-.489 3.421-3.42 2.607-4.072-2.28-1.792-3.421-2.444-4.561-2.444h-.489c-1.303.163-1.792 1.466-1.792 1.466s-2.28-.488-4.724-3.095c-2.77-2.769-3.095-4.724-3.095-4.724.978-.651 1.466-1.303 1.466-2.443 0-1.14-1.792-3.747-2.28-4.398zM4.06.543h.652a2.85 2.85 0 012.28 1.14C8.135 3.313 9.6 5.43 9.6 6.896c.163 1.466-.489 2.443-1.14 3.095.326.814.977 1.792 2.28 3.095.998 1.14 1.996 1.782 2.557 2.142l.213.139c.488-.49 1.14-.978 2.117-1.14h.815c1.629 0 3.095.814 5.538 2.768.815.652 1.14 1.792.815 3.095-.489 1.792-1.955 3.584-4.236 3.91h-.651c-3.095 0-7.982-2.77-11.403-6.353C2.921 13.737.804 10.154.152 6.733c-.651-3.095.815-5.376 3.91-6.19z"/>'
  }
})
