var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "camera-settings" },
    [
      !_vm.hasCameraAccess
        ? _c(
            "vwc-3-note",
            {
              staticClass: "vvd-root vvd-theme-alternate note",
              attrs: {
                connotation: "alert",
                icon: "error-solid",
                headline: _vm.$t("camera_settings.no-camera-access-note-title"),
              },
            },
            [
              _c("div", { staticClass: "note-content" }, [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("camera_settings.no-camera-access-note-content")
                    ) +
                    " "
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c("VVDSelect", {
        attrs: {
          "data-cy": "camera-select",
          label: _vm.$t("camera_settings.select_camera_label"),
          "selected-value": _vm.currentCamera,
          options: _vm.cameraDevicesItems,
          disabled: !_vm.hasCameraAccess,
          "max-height": 20,
          dense: "",
          shape: "rounded",
        },
        on: { selectedOption: _vm.onSelectCamera },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }