/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-volume-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.1.3a1.5 1.5 0 012.4 1.2v21c0 1.2-1.35 1.8-2.4 1.2L5.55 18H1.5C.6 18 0 17.4 0 16.5v-9C0 6.6.6 6 1.5 6h4.05L14.1.3zm5.1 15.9c-.6.6-.6 1.5 0 2.1.6.6 1.65.6 2.1 0 3.45-3.6 3.45-9.3 0-12.75-.6-.6-1.5-.6-2.1 0-.6.6-.6 1.5 0 2.1 2.4 2.4 2.4 6.15 0 8.55z"/>'
  }
})
