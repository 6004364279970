/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-telephone-3-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M9.073 6.928c.055.565-.112 1.161-.458 1.815-.495.88-1.5 1.412-2.976 1.737-1.151.254-1.871.406-2.198.462-.474.11-.922.102-1.34-.067a2.033 2.033 0 01-.986-.85l-.062-.118-.403-.875C.246 8.152.237 7.179.596 6.154c.323-.881.997-1.617 1.976-2.222.862-.532 1.856-.97 2.98-1.315a22.915 22.915 0 013.403-.763 21.562 21.562 0 013.213-.253c.967.004 2.038.099 3.214.282 1.18.184 2.316.448 3.41.793 1.128.356 2.126.803 2.992 1.342.985.614 1.666 1.356 1.997 2.24.367 1.028.368 2.002-.03 2.878l-.394.872-.061.117c-.23.385-.564.677-.978.841-.43.17-.894.168-1.352.052l-2.175-.486c-1.495-.334-2.515-.886-2.99-1.774-.36-.633-.541-1.23-.49-1.805-.234-.149-.68-.275-1.33-.339-.816-.08-1.412-.12-1.774-.121-.383-.002-.984.036-1.79.113-.638.061-1.09.18-1.344.322zm-3.952 5.95A3 3 0 017.243 12h9.514a3 3 0 012.122.879l4.242 4.243a3 3 0 01.879 2.12V22.5c0 .9-.6 1.5-1.5 1.5h-21C.6 24 0 23.4 0 22.5v-3.257a3 3 0 01.879-2.121l4.242-4.243zM9 18c0 1.65 1.35 3 3 3s3-1.35 3-3-1.35-3-3-3-3 1.35-3 3z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
