/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-small-image': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15 0H1C.4 0 0 .4 0 1v14c0 .6.4 1 1 1h14c.6 0 1-.4 1-1V1c0-.6-.4-1-1-1zM6 4c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1zm-3 8l2-4 2 2 3-4 3 6H3z"/>'
  }
})
