/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-expand-full-1': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 4.5h10.5a1.5 1.5 0 000-3H12a1.5 1.5 0 000 3zm-4.5 6h15a1.5 1.5 0 010 3h-15V18L0 12l7.5-6v4.5zm15 12H12a1.5 1.5 0 010-3h10.5a1.5 1.5 0 010 3z"/>'
  }
})
