/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-toggle-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.9 7.5H3v3H0V6c0-.9.6-1.5 1.5-1.5h17.4l-1.95-1.95c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l4.5 4.5c.6.6.6 1.5 0 2.1l-4.5 4.5c-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45-.6-.6-.6-1.5 0-2.1L18.9 7.5zm-13.8 9H21v-3h3V18c0 .9-.6 1.5-1.5 1.5H5.1l1.95 1.95c.6.6.6 1.5 0 2.1-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45l-4.5-4.5c-.6-.6-.6-1.5 0-2.1l4.5-4.5c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1L5.1 16.5z"/>'
  }
})
