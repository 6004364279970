/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-ai-4': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2.5 1a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM0 2.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zM2.5 12a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM0 13.5a2.5 2.5 0 115 0 2.5 2.5 0 01-5 0zM13 6a2 2 0 100 4 2 2 0 000-4zm-3 2a3 3 0 116 0 3 3 0 01-6 0zM2.5 6a.5.5 0 01.5.5v3a.5.5 0 01-1 0v-3a.5.5 0 01.5-.5zM5.477 4.059a.5.5 0 01.675-.21l3.234 1.693a.5.5 0 01-.464.886L5.688 4.734a.5.5 0 01-.21-.675zM9.597 9.783a.5.5 0 01-.211.675l-3.234 1.694a.5.5 0 01-.464-.886l3.234-1.694a.5.5 0 01.675.211z"/>'
  }
})
