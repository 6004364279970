<template>
  <div class="camera-settings">
    <vwc-3-note
      v-if="!hasCameraAccess"
      class="vvd-root vvd-theme-alternate note"
      connotation="alert"
      icon="error-solid"
      :headline="$t('camera_settings.no-camera-access-note-title')"
    >
      <div class="note-content">
        {{ $t('camera_settings.no-camera-access-note-content') }}
      </div>
    </vwc-3-note>
    <VVDSelect
      data-cy="camera-select"
      :label="$t('camera_settings.select_camera_label')"
      :selected-value="currentCamera"
      :options="cameraDevicesItems"
      :disabled="!hasCameraAccess"
      :max-height="20"
      dense
      shape="rounded"
      @selectedOption="onSelectCamera"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import VVDSelect from '@/components/VVDSelect';
import {
  ANALYTICS_SOURCE,
  SETTING_ANALYTIC_TYPE
} from '@/consts/global-consts';
import { getAvailableDevices } from '@/helpers/devices-utils';

export default {
  name: 'CameraSettings',

  components: { VVDSelect },

  data() {
    return {
      currentCamera: ''
    };
  },

  computed: {
    ...mapState(['cameraDevices', 'selectedCameraId']),
    ...mapGetters(['hasCameraAccess']),

    cameraDevicesItems() {
      return getAvailableDevices(this.cameraDevices, this.selectedCameraId);
    }
  },

  mounted() {
    // Set current camera
    if (this.selectedCameraId) {
      this.currentCamera = this.cameraDevicesItems.find(
        (device) => device.id === this.selectedCameraId
      ).label;
    } else {
      this.currentCamera = this.cameraDevicesItems[0]?.label || '';
    }
  },

  methods: {
    ...mapActions(['selectCameraDevice']),
    ...mapActions('settings', ['sendSettingsAnalytics']),

    onSelectCamera(option) {
      this.currentCamera = option.label;
      this.selectCameraDevice(option.id);
      this.sendSettingsAnalytics({
        setting: SETTING_ANALYTIC_TYPE.CAMERA,
        source: ANALYTICS_SOURCE.SETTINGS_MODAL
      });
    }
  }
};
</script>

<style scoped>
.note {
  margin-bottom: 16px;
}

.note-content {
  white-space: pre-wrap;
}
</style>
