<template>
  <div>
    <MobileSelect
      v-model="selectedCountry"
      showArrow
      :options="dialInNumberOptions"
    >
      <span class="Vlt-grey-light">{{
        $t('mobile_dial_in_countries.text')
      }}</span>
    </MobileSelect>

    <div
      class="selected-country-info"
      data-cy="selected-country-info-value"
      @click="copySelectedCountryNumber"
    >
      <span class="country-name">{{ selectedCountry.display_name }}</span>
      <span>{{ selectedCountryNumber }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { formatPhoneDisplayInternational } from '@/helpers/global-helpers';
import MobileSelect from './MobileSelect';
import { clipboardService } from '@/services/clipboard-service';
import { SHORT_TOAST_DURATION, TOAST_TYPE } from '@/consts/mobile-consts';

export default {
  components: {
    MobileSelect
  },

  data() {
    return { selectedCountry: undefined };
  },

  computed: {
    ...mapGetters(['dialInNumbers', 'roomPinCode', 'userLocale']),

    selectedCountryNumber() {
      return formatPhoneDisplayInternational(
        this.selectedCountry.number,
        this.userLocale
      );
    },

    dialInNumberOptions() {
      return this.dialInNumbers.map((dialInNumber) => {
        return {
          key: dialInNumber.number,
          value: dialInNumber,
          label: dialInNumber.display_name
        };
      });
    }
  },

  watch: {
    selectedCountry() {
      const selectedDialInNumber = {
        label: this.selectedCountry.display_name,
        checked: true,
        value: this.selectedCountry.number,
        locale: this.selectedCountry.locale
      };
      this.setSelectedDialInNumbers([selectedDialInNumber]);
    }
  },

  created() {
    if (this.dialInNumbers.length > 0) {
      const defaultNumber = this.dialInNumbers.find(
        (number) => number.locale === 'en-US'
      );

      if (defaultNumber) {
        this.selectedCountry = defaultNumber;
      } else {
        this.selectedCountry = this.dialInNumbers[0];
      }
    }
  },

  methods: {
    ...mapActions(['setSelectedDialInNumbers']),
    ...mapActions('mobile', ['displayToast']),

    copySelectedCountryNumber() {
      const clickToDial = `${this.selectedCountryNumber},,${this.roomPinCode}#`;
      clipboardService.copy(clickToDial);
      this.displayToast({
        message: this.$t('mobile_dial_in_countries.toast_message'),
        time: SHORT_TOAST_DURATION,
        type: TOAST_TYPE.GOOD
      });
    }
  }
};
</script>

<style scoped>
.selected-country-info {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  font-size: 16px;
}

.selected-country-info .country-name {
  font-weight: 600;
}
</style>
