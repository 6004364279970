/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calendar-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5.75a.75.75 0 00-1.5 0V3H2.25A2.25 2.25 0 000 5.25v16.5A2.25 2.25 0 002.25 24h7.5a.75.75 0 000-1.5h-7.5a.75.75 0 01-.75-.75V9h21v3.75a.75.75 0 001.5 0v-7.5A2.25 2.25 0 0021.75 3H18V.75a.75.75 0 00-1.5 0V3h-9V.75zM6.75 4.5h15a.75.75 0 01.75.75V7.5h-21V5.25a.75.75 0 01.75-.75h4.5zm17.03 13.28a.75.75 0 10-1.06-1.06l-5.47 5.47-2.47-2.47a.75.75 0 10-1.06 1.06l3 3a.75.75 0 001.06 0l6-6z"/>'
  }
})
