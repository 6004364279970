/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-user-sync-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6.46 5.82a4.481 4.481 0 018.96 0v1.03c0 1.255-.515 2.658-1.366 3.743-.852 1.089-1.964 1.767-3.114 1.767s-2.262-.678-3.115-1.767C6.975 9.508 6.46 8.105 6.46 6.85V5.82zm4.48 7.88c1.694 0 3.157-.99 4.168-2.28 1.014-1.294 1.651-2.982 1.651-4.57V5.82a5.82 5.82 0 00-11.639 0v1.03c0 1.588.638 3.276 1.651 4.57.336.427.72.822 1.148 1.16a24.705 24.705 0 00-4.378 1.044A3.751 3.751 0 001 17.183v4.757h9.94V20.6H2.339v-3.417c0-1.039.655-1.96 1.63-2.29 1.653-.557 4.157-1.193 6.968-1.194h.003zm7.21 1.03a3.453 3.453 0 00-3.342 2.587l-1.297-.334a4.792 4.792 0 018.222-1.981l1.794-1.303-.441 4.482-4.398-.967 1.954-1.42a3.44 3.44 0 00-2.492-1.065zm0 7.931c1.607 0 2.959-1.1 3.342-2.588l1.297.334a4.791 4.791 0 01-8.221 1.982l-1.795 1.303.441-4.482 4.398.968-1.954 1.419a3.44 3.44 0 002.492 1.064z"/>'
  }
})
