<template>
  <div
    class="Vlt-form__element Vlt-form__element--big"
    :class="{ 'Vlt-form__element--error': errorMessage }"
  >
    <div class="Vlt-select">
      <select
        :value="value"
        class="select"
        :disabled="disabled"
        :class="{ 'with-label': !!label }"
        @input="emitValue"
      >
        <slot>
          <option
            v-for="(item, index) in items"
            :key="index"
            :value="item.value"
          >
            {{ item.label || item.value }}
          </option>
        </slot>
      </select>
      <label class="label">{{ label }}</label>
    </div>
    <div v-if="errorMessage" class="error-container">
      <small
        v-if="errorMessage && errorMessage !== ' '"
        class="Vlt-form__element__error error-small-message"
        >{{ errorMessage }}
      </small>
    </div>
  </div>
</template>
<script>
export default {
  name: 'VSelect',

  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    errorMessage: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    emitValue({ target: { value } }) {
      this.$emit('input', value);
    }
  }
};
</script>
<style scoped>
/* Override rem */
.Vlt-select .select {
  height: 48px;
  min-width: 95px;
  font-size: 14px;
}

.Vlt-select > .label {
  font-size: 11px;
}

.Vlt-btn {
  font-size: 16px;
}

.label {
  font-size: 11px;
}
/*****/

.Vlt-btn >>> .Vlt-icon {
  fill: #33aaee;
}

.Vlt-form__element--big .Vlt-select .select:not(.with-label) {
  padding: 10px;
}
</style>
