/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-less-detailed-list': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.75.75A.75.75 0 000 1.5v9c0 .414.336.75.75.75h22.5a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75H.75zm.75 9v-7.5h21v7.5h-21zm-.75 3a.75.75 0 00-.75.75v9c0 .414.336.75.75.75h22.5a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75H.75zm.75 9v-7.5h21v7.5h-21z"/>'
  }
})
