/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-twitter-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.527 21.653c9.055 0 13.964-7.528 13.964-14.073v-.655c.982-.763 1.855-1.636 2.509-2.618-.873.437-1.855.655-2.836.764.981-.655 1.745-1.637 2.181-2.727-.981.545-2.072.981-3.163 1.2-1.855-1.964-5.018-2.073-6.982-.219-1.2 1.2-1.745 3.055-1.418 4.8A14.178 14.178 0 011.636 2.998C.327 5.18.982 8.125 3.164 9.544a3.933 3.933 0 01-2.182-.655v.11c0 2.4 1.636 4.363 3.927 4.908a5 5 0 01-2.182.11c.655 2.072 2.51 3.381 4.582 3.49-1.745 1.2-3.927 1.964-6.109 1.964H0c2.29 1.418 4.91 2.182 7.527 2.182z" _fill="#1DA1F2"/>'
  }
})
