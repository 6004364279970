/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-phone-plus-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.589 4.303V0h-3.443v4.303h-4.303v3.443h4.303v4.303h3.443V7.746h4.303V4.303h-4.303z"/><path pid="1" d="M3.894.627a1.91 1.91 0 011.243.091c.444.192.76.56.99.942l1.529 2.408c.85 1.339 1.36 2.663 1.022 3.798-.22.793-.586 1.54-1.247 1.933-.01.051-.017.175.032.404l.005.02c.083.434.388 1.044 1.047 1.846.694.844 1.205 1.427 1.544 1.77.357.36.944.88 1.778 1.568.795.655 1.408.977 1.852 1.079.215.047.333.041.383.033.385-.658 1.118-1.043 1.908-1.286l.021-.007c.58-.162 1.197-.1 1.806.088.611.188 1.267.518 1.96.96 1.265.808 2.078 1.338 2.408 1.573.375.232.73.548.915.986.172.403.192.828.09 1.24l-.012.047-.446 1.207c-.38 1.026-1.158 1.78-2.215 2.29l-.012.005c-.99.459-2.15.476-3.4.18-1.22-.288-2.47-.777-3.751-1.456A28.814 28.814 0 019.64 20c-1.193-.887-2.22-1.762-3.076-2.625-.856-.864-1.723-1.9-2.602-3.104a29.144 29.144 0 01-2.325-3.736C.964 9.242.48 7.982.195 6.754-.097 5.493-.08 4.33.373 3.335l.005-.012c.503-1.062 1.25-1.85 2.271-2.234L3.847.639l.047-.012z"/>'
  }
})
