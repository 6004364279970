/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-android-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3.505 7.745C2.753 7.745 2 8.4 2 9.273v6.218c0 .873.645 1.527 1.505 1.527.86 0 1.506-.654 1.506-1.527V9.273c-.108-.873-.753-1.528-1.506-1.528zm11.72-5.563l.969-1.855c.107-.109 0-.218-.108-.327-.107 0-.215 0-.323.11l-1.075 1.854c-.86-.437-1.828-.655-2.903-.655s-2.043.218-2.903.655L7.806.109C8.021 0 7.914 0 7.806 0c-.107.11-.107.218 0 .327l.968 1.855C6.84 3.272 5.441 5.236 5.441 7.527h13.118c0-2.29-1.29-4.254-3.333-5.345zM8.99 5.127c-.322 0-.537-.218-.537-.545 0-.327.215-.546.537-.546.323 0 .538.219.538.546 0 .327-.215.545-.538.545zm6.022 0c-.323 0-.538-.218-.538-.545 0-.327.215-.546.538-.546.322 0 .537.219.537.546 0 .327-.215.545-.537.545zM5.44 8.073v9.6c0 .873.645 1.527 1.505 1.527h1.075v3.273c0 .763.646 1.527 1.506 1.527s1.505-.654 1.505-1.527V19.2h1.936v3.273c0 .763.645 1.527 1.505 1.527.86 0 1.505-.654 1.505-1.527V19.2h1.076c.86 0 1.505-.654 1.505-1.527v-9.6H5.441zM22 9.273c0-.873-.645-1.528-1.505-1.528-.753 0-1.506.655-1.506 1.528v6.218c0 .873.645 1.527 1.506 1.527.752 0 1.505-.654 1.505-1.527V9.273z" _fill="#A4C439"/>'
  }
})
