/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-copy-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.75 10.684l-6-5.85V.75h6.833v6.5h6.667v11.5h-7.5v-8.066z"/><path pid="1" d="M22.156 5.75l-4.073-3.971V5.75h4.073zM.75 5.25v18h13.5v-11.5H7.583v-6.5H.75z"/><path pid="2" d="M9.083 6.279l4.073 3.971H9.083V6.279z"/>'
  }
})
