var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "flash-messages-container",
      attrs: { "data-cy": "flash-messages" },
    },
    _vm._l(_vm.flashMessages, function (message) {
      return _c(
        "VFlashMessage",
        { key: message.id, attrs: { options: message } },
        [
          _c("template", { slot: "text" }, [
            _vm._v(" " + _vm._s(message.text) + " "),
          ]),
        ],
        2
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }