/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-download': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6 1.5a.5.5 0 01.5-.5h3a.5.5 0 01.5.5V7h1.5a.5.5 0 01.376.83l-3.5 4a.5.5 0 01-.752 0l-3.5-4A.5.5 0 014.5 7H6V1.5zM7 2v5.5a.5.5 0 01-.5.5h-.898L8 10.74 10.398 8H9.5a.5.5 0 01-.5-.5V2H7z"/><path pid="1" d="M1.5 6a.5.5 0 00-.5.5v7a.5.5 0 00.5.5h13a.5.5 0 00.5-.5v-7a.5.5 0 00-.5-.5h-2a.5.5 0 010-1h2A1.5 1.5 0 0116 6.5v7a1.5 1.5 0 01-1.5 1.5h-13A1.5 1.5 0 010 13.5v-7A1.5 1.5 0 011.5 5h2a.5.5 0 010 1h-2z"/>'
  }
})
