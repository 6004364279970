import * as customizationApi from '@/apis/customization-api';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import debounce from 'lodash.debounce';
import getInitialState from './state';
import { copyPropsExistingOnOriginalObject } from '@/helpers/global-helpers';
import analytics from '@/services/analytics-service';
import { ANALYTICS } from '@/consts/global-consts';
import i18n from '@/i18n';
import { localStorageService } from '@/services/storage-service';
/**
 * We use the customization service as the main source to define the settings, and localStorage as a fallback (and for guests)
 */

const SAVE_SETTINGS_DEBOUNCE_DURATION = 3000;

export default {
  readSettings: async ({ getters, rootGetters, commit, dispatch }) => {
    if (rootGetters.isGuest) {
      dispatch('readLocalSettings');
    } else {
      await dispatch('readRemoteSettings');
    }

    commit('SET_INIT_USER_SETTINGS_COMPLETED', undefined, { root: true });

    // Persist selected locales in experimental mode
    if (
      getters.exclusiveExperimentalMode &&
      localStorageService.getItem('lastSelectedLocale')
    ) {
      const lastSelectedLocale = localStorageService.getItem(
        'lastSelectedLocale'
      );
      i18n.locale = lastSelectedLocale;
    }
  },

  saveSettings: async ({ commit, dispatch }, settings) => {
    commit('UPDATE_SETTINGS', settings);
    dispatch('saveLocalSettings');
    dispatch('debouncedSaveRemoteSettings');
  },

  readRemoteSettings: async ({ commit, dispatch }) => {
    try {
      const userSettings =
        (await customizationApi.getUserSettings(['meetings'])) || {};
      const meetingsSettings = _castRemoteSettingsTypes(userSettings.meetings);
      commit('UPDATE_SETTINGS', meetingsSettings);
      dispatch('saveLocalSettings');
    } catch (err) {
      await dispatch('readLocalSettings');
    }
  },

  // use debounce in order not to update the customization service too often
  debouncedSaveRemoteSettings: debounce(async ({ rootGetters, state }) => {
    if (rootGetters.isGuest) {
      return;
    }

    try {
      const settingsPatch = Object.keys(state).reduce((acc, settingName) => {
        acc.push({
          op: 'add',
          path: `/meetings/${settingName}`,
          value: JSON.stringify(state[settingName])
        });
        return acc;
      }, []);
      await customizationApi.updateUserSettings(settingsPatch);
    } catch (err) {
      return;
    }

    logger.log('save-settings', LOG_CATEGORIES.SETTINGS, {
      settings: state
    });
  }, SAVE_SETTINGS_DEBOUNCE_DURATION),

  readLocalSettings: ({ commit, dispatch }) => {
    const initialSettings = getInitialState();

    // TODO: remove handling deprecated settings - few releases after this code is released (PreJoin)
    // Right now the settings properties are scattered inside the localStorage, and we want to make sure we don't reset them to the users.
    const deprecatedSettings = _readLocalStorageDeprecatedSettings();

    let localStorageSettings = { ...deprecatedSettings };

    if (localStorageService.getItem('settings')) {
      localStorageSettings = {
        ...localStorageSettings,
        ...JSON.parse(localStorageService.getItem('settings'))
      };
      localStorageSettings = copyPropsExistingOnOriginalObject(
        initialSettings,
        localStorageSettings
      );
    }

    commit('UPDATE_SETTINGS', localStorageSettings);

    // if we have deprecated settings, we want to to save them in the the new structure
    if (Object.keys(deprecatedSettings).length) {
      dispatch('saveLocalSettings');
    }
  },

  saveLocalSettings: async ({ state }) => {
    localStorageService.setItem('settings', JSON.stringify(state));
  },

  toggleExclusiveExperimentalMode: ({ commit, state, dispatch }) => {
    commit('SET_EXCLUSIVE_EXPERIMENTAL_MODE', !state.exclusiveExperimentalMode);
    dispatch('saveSettings', {
      exclusiveExperimentalMode: state.exclusiveExperimentalMode
    });
    const title = state.exclusiveExperimentalMode
      ? i18n.t(
          'experimental_indication_popup.experimental_mode_turn_on_flash_message_title'
        )
      : i18n.t(
          'experimental_indication_popup.experimental_mode_turn_off_flash_message_title'
        );
    const text = state.exclusiveExperimentalMode
      ? ''
      : i18n.t(
          'experimental_indication_popup.experimental_mode_turn_off_flash_message_text'
        );
    dispatch(
      'addFlashMessage',
      {
        time: 5000,
        type: 'good',
        title,
        text
      },
      { root: true }
    );
  },

  sendSettingsAnalytics: ({ rootGetters }, { setting, source }) => {
    analytics.trackEvent(ANALYTICS.SETTINGS_UPDATED, {
      Source: source,
      Setting: setting,
      'Num of Participants': rootGetters.activeParticipants.length
    });
  }
};

// use the default state values types to decide the target type of each setting
function _castRemoteSettingsTypes(settings = {}) {
  try {
    const initialSettings = getInitialState();

    const conversionMap = {
      boolean: (v) => v == 'true',
      number: Number,
      string: String,
      object: (obj, propName) => {
        const parsedObj = JSON.parse(obj);
        return copyPropsExistingOnOriginalObject(
          initialSettings[propName],
          parsedObj
        );
      }
    };

    return Object.keys(settings)
      .filter((settingName) => settingName in initialSettings)
      .reduce((acc, settingName) => {
        const conversionType = typeof initialSettings[settingName];
        acc[settingName] = conversionMap[conversionType](
          settings[settingName],
          settingName
        );

        return acc;
      }, {});
  } catch (err) {
    logger.error('_castRemoteSettingsTypes', LOG_CATEGORIES.SETTINGS, {
      err: err.toString(),
      settings
    });
    throw err;
  }
}

// TODO: remove this func - few releases after this code is released (PreJoin)
// Right now the settings properties are scattered inside the localStorage, and we want to make sure we don't reset them to the users.
function _readLocalStorageDeprecatedSettings() {
  const deprecatedSettings = {};

  const playSoundOnJoinOrLeave = localStorageService.getItem(
    'playSoundOnJoinOrLeave'
  );
  if (playSoundOnJoinOrLeave) {
    deprecatedSettings.playSoundOnJoinOrLeave =
      playSoundOnJoinOrLeave === 'true';
  }

  const exclusiveExperimentalMode = localStorageService.getItem(
    'exclusiveExperimentalMode'
  );
  if (exclusiveExperimentalMode) {
    deprecatedSettings.exclusiveExperimentalMode =
      exclusiveExperimentalMode === 'true';
  }

  return deprecatedSettings;
}
