/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-zendesk-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M0 20.987L11.1 7.728v13.26H0zM11.05 3c0 3.032-2.468 5.499-5.551 5.499A5.472 5.472 0 010 3h11.05zm1.85 17.987c0-3.032 2.466-5.499 5.55-5.499 3.083 0 5.55 2.467 5.55 5.5H12.9zm0-4.728V3H24L12.9 16.26z" _fill="#03363D"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
