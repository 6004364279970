import Vue from 'vue';
import Vuex from 'vuex';
import actions from './actions';
import mutations from './mutations';
import getters from './getters';
import getInitialState from './state';
import eventHandlersActions from './more-actions/event-handlers';
import busActions from './more-actions/bus';
import messaging from './messaging/messaging';
import layout from './layout';
import recordings from './recordings';
import loudnessDetector from './loudness-detector';
import settings from './settings';
import reactions from './reactions';
import raiseHand from './raise-hand';
import beRightBack from './be-right-back';
import standup from './standup';
import watchTogether from './watchTogether';
import whiteboard from './whiteboard';
import mobile from './mobile';
import screenshare from './screenshare';
import whitelabel from './whitelabel';
import waitingRoom from './waitingRoom';
import reactionsCounter from './reactions-counter';
import embedded from './embedded';
import captions from './captions';
import { isMobileDevice } from '@/helpers/global-helpers';
import localization from './localization';
import qna from './qna';

Vue.use(Vuex);

const modules = {
  messaging,
  layout,
  recordings,
  loudnessDetector,
  settings,
  reactions,
  standup,
  watchTogether,
  whiteboard,
  whitelabel,
  raiseHand,
  screenshare,
  waitingRoom,
  beRightBack,
  embedded,
  captions,
  localization,
  qna,
  reactionsCounter
};
if (isMobileDevice) {
  modules['mobile'] = mobile;
}

// TODO: make some modules dynamic modules with store.registerModule() to improve startup time
export default new Vuex.Store({
  namespaced: true,
  state: getInitialState(),
  getters,
  mutations,
  actions: {
    ...actions,
    ...eventHandlersActions,
    ...busActions
  },
  modules
});
