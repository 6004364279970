/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-hash': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.7 7.5L24 6h-5.4l.9-6H18l-1 6H8.8l.9-6H8.2l-.9 6H1.8l-.3 1.5h5.6l-1.4 9H.3L0 18h5.4l-.9 6H6l.9-6h8.2l-.9 6h1.5l.9-6h5.5l.3-1.5h-5.6l1.4-9h5.5zm-8.3 9H7.2l1.4-9h8.2l-1.4 9z"/>'
  }
})
