import { vbcApiGw } from '@/apis/vbc-gw';
import retry from 'async-retry';

const vueAppRsEnvRoute = process.env.VUE_APP_RS_ENV_ROUTE;

export function getSessionById(sessionId, timeout, includes = undefined) {
  return vbcApiGw.get(`/${vueAppRsEnvRoute}/sessions/${sessionId}`, {
    'axios-retry': { retries: 3 },
    params: {
      includes,
      isActive: true
    },
    timeout
  });
}

export function getOrCreateSessionByRoomToken(externalId, roomToken) {
  return vbcApiGw.get(`/${vueAppRsEnvRoute}/sessions`, {
    'axios-retry': { retries: 3 },
    params: {
      roomToken,
      isActive: true,
      externalId
    }
  });
}

export function getSessionToken(externalId, sessionId) {
  return vbcApiGw.get(`/${vueAppRsEnvRoute}/sessions/${sessionId}/token`, {
    'axios-retry': { retries: 3 },
    params: {
      externalId
    }
  });
}

export function getRoomDetailsBySessionId(sessionId) {
  return vbcApiGw.get(`/${vueAppRsEnvRoute}/rooms`, {
    params: {
      session_id: sessionId,
      includes: 'is_active_session_locked,is_active_session_full'
    }
  });
}

export function getRoomDetailsByRoomToken(roomToken) {
  return vbcApiGw.get(`/${vueAppRsEnvRoute}/rooms`, {
    'axios-retry': { retries: 3 },
    params: {
      token: roomToken,
      includes: 'is_active_session_locked,is_active_session_full'
    }
  });
}

export function inviteContacts(externalId, sessionId, invitees) {
  return vbcApiGw.post(
    `/${vueAppRsEnvRoute}/sessions/${sessionId}/participants`,
    {
      invitees,
      externalId
    }
  );
}

// TODO: public api?
export function getDialInNumbers(domain) {
  return vbcApiGw.get(`/${vueAppRsEnvRoute}/phones`, {
    params: {
      domain: domain
    }
  });
}

export function endSession(externalId, sessionId) {
  return vbcApiGw.post(`/${vueAppRsEnvRoute}/sessions/${sessionId}/close`, {
    externalId
  });
}

export function lockSession(externalId, sessionId, locked) {
  return vbcApiGw.put(`/${vueAppRsEnvRoute}/sessions/${sessionId}`, {
    externalId,
    locked
  });
}

export function setSessionJoinApprovalLevel(
  externalId,
  sessionId,
  joinApprovalLevel
) {
  return vbcApiGw.put(`/${vueAppRsEnvRoute}/sessions/${sessionId}`, {
    externalId,
    joinApprovalLevel
  });
}

export function startRecording(externalId, sessionId) {
  return vbcApiGw.post(`/${vueAppRsEnvRoute}/recordings`, {
    sessionId,
    externalId
  });
}

export function stopRecording(externalId, recordingId) {
  return vbcApiGw.post(`/${vueAppRsEnvRoute}/recordings/${recordingId}/stop`, {
    externalId
  });
}

export function getActiveRecordingBySessionId(externalId, sessionId) {
  return vbcApiGw.get(`/${vueAppRsEnvRoute}/recordings/active-recording`, {
    'axios-retry': { retries: 3 },
    params: {
      sessionId,
      externalId
    }
  });
}

export function kickOutParticipant(externalId, sessionId, participantId) {
  return vbcApiGw.post(`/${vueAppRsEnvRoute}/sessions/${sessionId}/kick-out`, {
    externalId,
    participantId
  });
}

export function forceMuteParticipant(sessionId, streamId) {
  return vbcApiGw.post(
    `/${vueAppRsEnvRoute}/sessions/${sessionId}/force-mute`,
    {
      streamId
    }
  );
}

export function forceMuteSession(sessionId, excludedStreamIds) {
  return vbcApiGw.post(
    `/${vueAppRsEnvRoute}/sessions/${sessionId}/force-mute`,
    {
      excludedStreamIds
    }
  );
}

export function reportAnIssue(reportData) {
  return vbcApiGw.post(`/${vueAppRsEnvRoute}/reports/issue`, reportData);
}

export async function createWhiteboardSession(
  externalId,
  sessionId,
  { boardName, isLocked = false }
) {
  return vbcApiGw.post(`/${vueAppRsEnvRoute}/whiteboards`, {
    externalId,
    sessionId,
    boardData: { boardName, isLocked }
  });
}

export async function obtainWhiteboardToken(
  whiteboardId,
  externalId = undefined
) {
  // Note: Could not use axios-retry here for some reason (it returned an empty response when the request finally succeeded)
  return await retry(
    async () => {
      const responseData = await vbcApiGw.post(
        `/${vueAppRsEnvRoute}/whiteboards/${whiteboardId}/join`,
        {
          externalId,
          whiteboardId
        }
      );
      return responseData.token;
    },
    {
      retries: 5,
      minTimeout: 1000,
      maxTimeout: 2000
    }
  );
}

export function joinWaitingRoom(roomName, externalId) {
  return vbcApiGw.post(`/${vueAppRsEnvRoute}/rooms/${roomName}/waiting-room`, {
    externalId
  });
}

export function getWaitingRoomParticipants(roomName, externalId) {
  return vbcApiGw.get(`/${vueAppRsEnvRoute}/rooms/${roomName}/waiting-room`, {
    params: {
      externalId
    }
  });
}

export function updateWaitingRoomParticipant(
  roomName,
  participantId,
  isApproval,
  externalId
) {
  return updateWaitingRoomParticipants(
    roomName,
    [participantId],
    isApproval,
    externalId
  );
}

export function updateWaitingRoomParticipants(
  roomName,
  waitingParticipantIds,
  isApproval,
  externalId
) {
  return vbcApiGw.put(
    `/${vueAppRsEnvRoute}/rooms/${roomName}/waiting-room/participants`,
    {
      externalId,
      participantIds: waitingParticipantIds,
      status: isApproval ? 'admitted' : 'denied'
    }
  );
}

export function sendWaitingRoomKeepAlive(
  roomName,
  externalId,
  { isExtended } = { isExtended: false }
) {
  return vbcApiGw.post(
    `/${vueAppRsEnvRoute}/rooms/${roomName}/waiting-room/keepalive`,
    {
      externalId,
      isExtended
    }
  );
}

export function cancelWaitingRoomParticipation(roomName, externalId) {
  return vbcApiGw.post(
    `/${vueAppRsEnvRoute}/rooms/${roomName}/waiting-room/cancel`,
    {
      externalId
    }
  );
}

export function updateRoom(
  roomName,
  externalId,
  { joinApprovalLevel = undefined }
) {
  return vbcApiGw.put(`/${vueAppRsEnvRoute}/rooms/${roomName}`, {
    externalId,
    updateDetails: {
      join_approval_level: joinApprovalLevel
    }
  });
}

export function updateIsCaptionsEnabled(
  externalId,
  sessionId,
  isCaptionsEnabled
) {
  return vbcApiGw.post(`/${vueAppRsEnvRoute}/sessions/${sessionId}/captions`, {
    externalId,
    isCaptionsEnabled
  });
}
