import modal from './modal';
import ongoing from './ongoing';

export default {
  namespaced: true,
  modules: {
    modal,
    ongoing
  }
};
