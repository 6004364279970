var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "questions-container" }, [
    !_vm.isQnaSessionActive && _vm.isLoadingSession
      ? _c(
          "div",
          { staticClass: "loading-container" },
          [_c("vwc-circular-progress", { attrs: { indeterminate: "" } })],
          1
        )
      : !_vm.isQnaSessionActive && !_vm.isLoadingSession
      ? _c(
          "div",
          { staticClass: "empty-state-container" },
          [
            _c(
              "vwc-empty-state",
              {
                attrs: {
                  heading: _vm.$t("qna_sidebar.empty_state_title"),
                  body: _vm.$t("qna_sidebar.empty_state_message"),
                },
              },
              [
                _c("img", {
                  attrs: {
                    slot: "graphic",
                    "data-cy": "empty-qna-img",
                    src: "/illustrations/empty-state/empty-qna.svg",
                  },
                  slot: "graphic",
                }),
              ]
            ),
          ],
          1
        )
      : _c("div", { staticClass: "qna-container" }, [
          _c(
            "div",
            { staticClass: "header-container" },
            [
              _c("div", { staticClass: "header-title" }, [
                _vm._v(_vm._s(_vm.$t("qna_sidebar.title"))),
              ]),
              _c("vwc-badge", {
                staticClass: "num-of-questions-badge",
                attrs: {
                  connotation: "primary",
                  shape: "pill",
                  layout: "soft",
                  text: _vm.questions.length,
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "started-by" }, [
            _vm._v(" " + _vm._s(_vm.startedBy) + " "),
          ]),
          _c(
            "div",
            {
              staticClass: "questions-cards-container",
              class: {
                empty: _vm.isQnaSessionEmpty,
              },
            },
            [
              _vm.isQnaSessionEmpty
                ? _c(
                    "div",
                    [
                      _c(
                        "vwc-empty-state",
                        {
                          attrs: {
                            heading: _vm.$t("qna_sidebar.empty_state_title"),
                            body: _vm.$t("qna_sidebar.empty_state_message"),
                          },
                        },
                        [
                          _c("img", {
                            attrs: {
                              slot: "graphic",
                              "data-cy": "empty-qna-img",
                              src: "/illustrations/empty-state/empty-qna.svg",
                            },
                            slot: "graphic",
                          }),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._l(_vm.questions, function (question) {
                    return _c(
                      "div",
                      {
                        key: question.questionId,
                        staticClass: "question-card",
                      },
                      [
                        _c("Question", {
                          attrs: {
                            question: question,
                            participant: _vm.participantObj(question.userId),
                          },
                        }),
                      ],
                      1
                    )
                  }),
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "text-field" },
            [
              _c("VTextfield", {
                attrs: {
                  dense: "",
                  placeholder: _vm.$t(
                    "qna_sidebar.question_textfield_placeholder"
                  ),
                  maxLength: 512,
                  actionSlotFunction: _vm.sendQuestion,
                  isActionDisabled: _vm.isSendButtonDisabled,
                },
                on: { submit: _vm.sendQuestion },
                model: {
                  value: _vm.newQuestionText,
                  callback: function ($$v) {
                    _vm.newQuestionText = $$v
                  },
                  expression: "newQuestionText",
                },
              }),
            ],
            1
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }