/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-document': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6.75 0A.75.75 0 006 .75V12H.75a.75.75 0 00-.75.75v7.5A3.75 3.75 0 003.75 24h16.5A3.75 3.75 0 0024 20.25V.75a.75.75 0 00-.75-.75H6.75zm0 22.5h13.5a2.25 2.25 0 002.25-2.25V1.5h-15v18.75c0 .844-.279 1.623-.75 2.25zM1.5 20.25V13.5H6v6.75a2.25 2.25 0 11-4.5 0zm9-15a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM11.25 9a.75.75 0 000 1.5h7.5a.75.75 0 000-1.5h-7.5zm-.75 5.25a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zm.75 3.75a.75.75 0 000 1.5h3a.75.75 0 000-1.5h-3z"/>'
  }
})
