/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 0v6h6l-6-6z"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M13.5 9h9v13.5c0 .9-.6 1.5-1.5 1.5H3c-.9 0-1.5-.6-1.5-1.5v-21C1.5.6 2.1 0 3 0h10.5v9zM4 11h8v2H4v-2zm3-4H4v2h3V7z"/>'
  }
})
