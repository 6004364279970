/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-thin-left-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M24 13.5H10.5V21L0 12l10.5-9v7.5H24v3z"/>'
  }
})
