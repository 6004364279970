/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-zip-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M22.5 9h-9V0H3c-.9 0-1.5.6-1.5 1.5v21c0 .9.6 1.5 1.5 1.5h18c.9 0 1.5-.6 1.5-1.5V9zm-6-9v6h6l-6-6zm-6.418 19.374H5.5v-1.066l2.664-3.73H5.5V13.3h4.582v.959l-2.664 3.73h2.664v1.385zm2.664 0H11.04V13.3h1.705v6.074zm5.115-2.558c-.427.32-.96.533-1.705.533h-.426v2.025H14.13V13.3h2.131c.746 0 1.386.213 1.705.533.32.32.533.852.533 1.385 0 .64-.213 1.172-.64 1.598zm-1.705-2.237h-.426v1.385h.32c.212 0 .425-.107.639-.213.213-.107.213-.32.213-.533-.107-.426-.32-.64-.746-.64z"/>'
  }
})
