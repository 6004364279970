/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-linkedin-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.255 0H1.744C.764 0 0 .764 0 1.745v20.51C0 23.236.764 24 1.745 24h20.4c.982 0 1.746-.764 1.746-1.745V1.744C24 .764 23.236 0 22.255 0zM7.09 20.4H3.6V8.945h3.6V20.4h-.11zM5.345 7.418c-1.2 0-2.072-.873-2.072-2.073s.872-2.072 2.072-2.072c1.091 0 2.073.872 2.073 2.072 0 1.2-.982 2.073-2.073 2.073zM20.4 20.4h-3.6v-5.564c0-1.309 0-3.054-1.855-3.054-1.854 0-2.181 1.418-2.181 2.945V20.4H9.382V8.945h3.382v1.528c.545-.764 1.636-1.746 3.381-1.746 3.6 0 4.255 2.4 4.255 5.455V20.4z" _fill="#007BB6"/>'
  }
})
