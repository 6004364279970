<template>
  <youtube
    ref="youtube"
    :video-id="videoId"
    :player-vars="playerVars"
    @playing="onPlaying"
    @paused="onPaused"
    @ended="onPaused"
    @ready="onReady"
  />
</template>

<script>
// The enum values correspond to the numeric values returned by YouTube's player.getPlayerState() API
const YOUTUBE_PLAYER_STATE_ENUM = Object.freeze({
  UNSTARTED: -1,
  ENDED: 0,
  PLAYING: 1,
  PAUSED: 2,
  BUFFERING: 3,
  VIDEO_CUED: 5
});

export default {
  name: 'YoutubePlayerWrapper',

  props: {
    videoId: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      playerVars: {
        controls: 0,
        rel: 0,
        modestbranding: 1
      },
      volumeBeforeMute: 0,
      isFullscreen: false,
      origin: window.location.origin
    };
  },

  computed: {
    player() {
      return this.$refs.youtube.player;
    }
  },

  created() {
    document.addEventListener(
      'fullscreenchange',
      this.documentFullscreenChange
    );
  },

  destroyed() {
    document.removeEventListener(
      'fullscreenchange',
      this.documentFullscreenChange
    );
  },

  methods: {
    /* SECTION 1: EVENT EMITTERS */

    /**
     * Emit events when the iframe goes in/out of full screen
     */
    async documentFullscreenChange() {
      const playerIframe = await this.player.getIframe();

      if (document.fullscreenElement === playerIframe) {
        this.isFullscreen = true;
        this.$emit('fullscreenchange', true);
      } else {
        // Detect leaving fullscreen
        if (this.isFullscreen) {
          this.isFullscreen = false;
          this.$emit('fullscreenchange', this.isFullscreen);
        }
      }
    },

    async onPlaying() {
      let timeInVideo = await this.player.getCurrentTime();
      if (timeInVideo < 0.5) {
        timeInVideo = 0;
      }
      this.$emit('videoPlayerStateChanged', {
        isVideoPlaying: true,
        timeInVideo
      });
    },

    async onPaused() {
      const timeInVideo = await this.player.getCurrentTime();
      this.$emit('videoPlayerStateChanged', {
        isVideoPlaying: false,
        timeInVideo
      });
    },

    onReady() {
      this.$emit('ready');
    },

    /* SECTION 2: EXPOSED FUNCTIONALITY */

    playVideo() {
      this.player.playVideo();
    },

    pauseVideo() {
      this.player.pauseVideo();
    },

    async togglePlayingState() {
      if (
        (await this.player.getPlayerState()) ===
        YOUTUBE_PLAYER_STATE_ENUM.PLAYING
      ) {
        this.pauseVideo();
      } else {
        this.playVideo();
      }
    },

    async seekTo(timeInVideo, { allowSeekAhead } = { allowSeekAhead: true }) {
      await this.player.seekTo(timeInVideo, allowSeekAhead);
      this.$emit('seek', timeInVideo);
    },

    async getCurrentTime() {
      const state = await this.player.getPlayerState();
      if (state === YOUTUBE_PLAYER_STATE_ENUM.ENDED) {
        return await this.player.getDuration();
      }

      return await this.player.getCurrentTime();
    },

    async getLoadedFraction() {
      return await this.player.getVideoLoadedFraction();
    },

    async getVolume() {
      return await this.player.getVolume();
    },

    async setVolume(volume) {
      if (volume > 0 && (await this.isMuted())) {
        await this.player.unMute();
      }

      this.$emit('volumeChanged', { volume });
      return await this.player.setVolume(volume);
    },

    async mute() {
      this.volumeBeforeMute = await this.getVolume();
      await this.player.mute();
      this.$emit('volumeChanged', {
        volume: 0
      });
    },

    async unmute() {
      await this.player.unMute();
      this.$emit('volumeChanged', {
        volume: this.volumeBeforeMute
      });
    },

    async toggleMute() {
      if (await this.isMuted()) {
        await this.unmute();
      } else {
        await this.mute();
      }
    },

    async isMuted() {
      return await this.player.isMuted();
    },

    async destroy() {
      return await this.player.destroy();
    }
  }
};
</script>
