import axios from 'axios';
import axiosRetry from 'axios-retry';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import { MAX_RETRY_REFRESH_TOKEN_COUNT } from '@/consts/global-consts';
import AuthClient from '@/services/auth-client-service';

export const vbcApiGw = axios.create({
  baseURL: process.env.VUE_APP_VBC_API_SERVER,
  timeout: 90000
});

// Setup auto retry interceptor - only for 5XX errors or network errors. For now we will enable this per API call.
axiosRetry(vbcApiGw, { retries: 0, retryDelay: axiosRetry.exponentialDelay });
initInterceptors();

let credentials = {};
export function getCredentials() {
  if (window.Cypress && window.CredentialsMock) {
    return window.CredentialsMock;
  }
  return credentials;
}

let _refreshAccessTokenPromise;

export function setDefaultErrorHandler(fn) {
  vbcApiGw.interceptors.response.use(null, fn);
}

export function setDefaultSuccessHandler(fn) {
  vbcApiGw.interceptors.response.use(fn);
}

export function setRequestStartTime() {
  vbcApiGw.interceptors.request.use((request) => {
    // to avoid overwriting if another interceptor already defined meta.
    // We will use the 'requestStartedAt' header we defined in meta in the response
    request.meta = request.meta || {};
    request.meta.requestStartedAt = new Date().getTime();
    return request;
  });
}

export async function refreshAccessToken(refreshToken) {
  const response = await vbcApiGw.post(
    `/oauth/token?grant_type=refresh_token&refresh_token=${refreshToken}`,
    {
      refresh_token: refreshToken,
      grant_type: 'refresh_token'
    },
    {
      headers: {
        Authorization: 'Basic bW9iaWxlOg=='
      }
    }
  );
  return response;
}

export function buildAuthenticationHeaders(accessToken) {
  return {
    headers: {
      Authorization: `bearer ${accessToken}`
    }
  };
}

export function init({ accessToken, refreshToken, externalId, extension }) {
  setAccessToken(accessToken);
  setRefreshToken(refreshToken);
  setExternalId(externalId);
  setExtension(extension);
}

export function reset() {
  setAccessToken();
  setRefreshToken();
  setExternalId();
  setExtension();
}

function initInterceptors() {
  _initAPIGatewayTryToRefreshTokenHandler();
  _initAPIGatewayLogErrorsHandler();
  _initRedirectsAuthorizationHandler();
  setRequestStartTime();
  setDefaultSuccessHandler((response) => sendApiRequestLogs(response));
  setDefaultSuccessHandler((response) => response.data);
}

function _initAPIGatewayLogErrorsHandler() {
  setDefaultErrorHandler(async (err) => {
    const { message, name, error } = err.response?.data || {};
    const { url, params, method, data } = err.response?.config || {};
    const status = err.response?.status;
    const formattedError = {
      name,
      status,
      message,
      url,
      params,
      method,
      data,
      error_name: error
    };

    logger.error('api-error', LOG_CATEGORIES.API_CALL, formattedError);
    throw err;
  });
}

function _initAPIGatewayTryToRefreshTokenHandler() {
  setDefaultErrorHandler(async (error) => {
    // We want to try to refresh the access token if we got a 401 from the Vonage API Gateway.
    if (
      getCredentials().refreshToken &&
      error?.config?.baseURL?.indexOf(process.env.VUE_APP_VBC_API_SERVER) ===
        0 &&
      error?.response?.status === 401
    ) {
      const errorDescription = error?.response?.data?.error_description;

      if (
        errorDescription?.indexOf('Access token expired') === 0 ||
        errorDescription?.indexOf('Cannot convert access token to JSON') === 0
      ) {
        if (!_refreshAccessTokenPromise) {
          _refreshAccessTokenPromise = _refreshVBCAccessToken();
        }

        await _refreshAccessTokenPromise;
        _refreshAccessTokenPromise = null;

        // Retry the failed request with the new credentials
        return _retry(error.config, getCredentials().accessToken);
      }
    }
    throw error;
  });
}

/**
 * Refer to ADR /doc/architecture/decisions/0002-ios-safari-handling-redirects-with-authorization-header.md
 */
function _initRedirectsAuthorizationHandler() {
  setDefaultErrorHandler(async (error) => {
    const originalUrl =
      error?.config?.baseURL?.replace(/\/+$/, '') +
      vbcApiGw.getUri(error.config);
    const responseUrl = error?.request?.responseURL;
    const status = error?.response?.status;
    const authorizationHeader = error?.config?.headers['Authorization'];

    if (
      status !== 401 ||
      !responseUrl ||
      originalUrl === responseUrl || // if there was no redirect
      !authorizationHeader
    ) {
      throw error;
    }

    // Retry the failed request with the redirected URL
    const options = {
      ...error.config,
      url: responseUrl
    };
    return _retry(options);
  });
}

function setAccessToken(accessToken) {
  credentials.accessToken = accessToken;
  vbcApiGw.defaults.headers.common['Authorization'] = `bearer ${accessToken}`;
}

function setRefreshToken(refreshToken) {
  credentials.refreshToken = refreshToken;
}

function setExternalId(externalId) {
  credentials.externalId = externalId;
}

function setExtension(extension) {
  credentials.extension = extension;
}

function _retry(options, accessToken = null) {
  if (accessToken) {
    options.headers.Authorization = buildAuthenticationHeaders(
      accessToken
    ).headers.Authorization;
  }

  return axios.request(options);
}

function _refreshVBCAccessToken() {
  return new Promise((resolve, reject) => {
    const maxRetryCount = MAX_RETRY_REFRESH_TOKEN_COUNT;
    let retryCount = 0;

    async function tryAccessTokenRefresh() {
      retryCount++;
      try {
        logger.log('refreshVBCToken', LOG_CATEGORIES.CLIENT_LOGIC);

        const newCredentials = await refreshAccessToken(
          getCredentials().refreshToken
        );
        setAccessToken(newCredentials.access_token);
        await AuthClient.store('accessToken', getCredentials().accessToken);

        resolve(newCredentials.access_token);
      } catch (error) {
        logger.error('refreshVBCToken', LOG_CATEGORIES.CLIENT_LOGIC, error);

        if (retryCount < maxRetryCount) {
          if (error?.response?.status === 401) {
            reject(error);
          } else {
            setTimeout(tryAccessTokenRefresh, 5000);
          }
          // If there are attempts left, try again after 5 seconds.
        } else {
          reject(error);
        }
      }
    }
    tryAccessTokenRefresh();
  });
}

function sendApiRequestLogs(response) {
  const endTime = new Date().getTime();
  let startTime;
  if (response.config['axios-retry']) {
    startTime = response.config['axios-retry'].lastRequestTime;
  } else {
    startTime = response.config.meta.requestStartedAt;
  }
  const duration = endTime - startTime;
  const method = response.config.method;
  const url = response.config.url;
  const status = response.status;
  const transactionId = response.headers['x-transaction-id'] || '';

  logger.log('api-call', LOG_CATEGORIES.API_CALL, {
    method,
    url,
    duration,
    status,
    transactionId
  });

  return response;
}
