/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-clock': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.6 12C1.6 6.256 6.256 1.6 12 1.6c5.744 0 10.4 4.656 10.4 10.4 0 5.744-4.656 10.4-10.4 10.4-5.744 0-10.4-4.656-10.4-10.4zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm.8 5.6a.8.8 0 00-1.6 0V12a.8.8 0 00.8.8h6.4a.8.8 0 000-1.6h-5.6V5.6z"/>'
  }
})
