<template>
  <vwc-select
    :label="label"
    :shape="shape"
    :dense="dense"
    :required="required"
    :value="selectedValue"
    :disabled="disabled"
    :style="selectStyle"
    @closed="(event) => event.stopPropagation()"
    @selected.prevent
  >
    <vwc-list-item
      v-for="option in options"
      :key="option.id"
      :value="option.label"
      :disabled="option.disabled"
      hasMeta
      :graphic="option.icon ? 'icon' : ''"
      role="option"
      @click="selectedOption(option)"
    >
      <span>{{ option.label }} </span>
      <vwc-icon
        v-if="option.icon"
        slot="graphic"
        :type="option.icon"
        size="small"
      />
      <vwc-icon
        v-if="option.label === selectedValue"
        slot="meta"
        class="meta-icon"
        type="check-line"
        size="small"
      />
    </vwc-list-item>
  </vwc-select>
</template>

<script>
export default {
  name: 'VVDSelect',

  props: {
    options: {
      type: Array,
      required: true
    },
    label: {
      type: String,
      default: ''
    },
    selectedValue: {
      type: String,
      required: true
    },
    shape: {
      type: String,
      default: 'rounded'
    },
    dense: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    maxHeight: {
      type: Number,
      default: undefined
    }
  },

  computed: {
    selectStyle() {
      if (!this.maxHeight) {
        return;
      }
      return {
        '--mdc-menu-max-height': `${this.maxHeight}vh`
      };
    }
  },

  methods: {
    selectedOption(option) {
      if (!option) {
        return;
      }
      this.$emit('selectedOption', option);
    }
  }
};
</script>

<style scoped></style>
