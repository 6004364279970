var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("svgicon", {
    staticClass: "v-icon",
    class: {
      md: _vm.md,
      sm: _vm.sm,
      xs: _vm.xs,
      pointer: _vm.$listeners && _vm.$listeners.click && !_vm.preventClick,
      spin: _vm.spin,
    },
    attrs: { name: _vm.iconName },
    nativeOn: {
      click: function ($event) {
        return (function (event) {
          return !_vm.preventClick && _vm.$emit("click", event)
        })($event)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }