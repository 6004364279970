/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-play-3': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 12C0 5.4 5.4 0 12 0s12 5.4 12 12-5.4 12-12 12S0 18.6 0 12zm1.5 0c0 5.85 4.65 10.5 10.5 10.5S22.5 17.85 22.5 12 17.85 1.5 12 1.5 1.5 6.15 1.5 12zm9-4.8l6.15 3.6c1.05.6 1.05 1.8 0 2.25l-6.15 3.6c-.9.6-1.95 0-1.95-1.2V8.4c0-1.2.9-1.8 1.95-1.2zm5.25 4.8l-5.7-3.3v6.6l5.7-3.3z"/>'
  }
})
