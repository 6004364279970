<template>
  <Dialog
    class="invite-participants-container"
    data-cy="invite-participants-dialog"
    :heading="$t('invite_participants_modal.title')"
    :min-width="435"
    icon-name="add-user-line"
    :close="closeModal"
    :applied="invite"
  >
    <div slot="content" class="invite-participants-content">
      <Contacts v-model="selectedContacts" class="contacts" />
    </div>
    <template slot="footer-buttons">
      <vwc-button
        slot="secondaryAction"
        layout="outlined"
        icon="link-solid"
        :label="$t('invite_participants_modal.copy_link_button')"
        @click="copyLinkToClipboard"
      />
      <vwc-button
        slot="primaryAction"
        data-cy="invite-button"
        connotation="cta"
        layout="filled"
        :disabled="!selectedContacts.length"
        icon="message-sent-solid"
        :label="$t('invite_participants_modal.invite_button')"
        @click="invite"
      />
    </template>
  </Dialog>
</template>

<script>
import Contacts from '@/components/Contacts.vue';
import { mapGetters, mapActions } from 'vuex';
import analytics from '@/services/analytics-service';
import { ANALYTICS, ANALYTICS_SOURCE } from '@/consts/global-consts';
import Dialog from '@/components/Dialog';
import { clipboardService } from '@/services/clipboard-service';

export default {
  name: 'InviteParticipantsModal',

  components: {
    Dialog,
    Contacts
  },

  data() {
    return {
      selectedContacts: []
    };
  },

  computed: {
    ...mapGetters(['isSessionOwner', 'activeParticipants', 'roomUrl'])
  },

  beforeDestroy() {
    this.resetSelectedContacts();
  },

  methods: {
    ...mapActions([
      'selectContactToInvite',
      'inviteContacts',
      'setIsInviteParticipantsDialogVisible',
      'addFlashMessage'
    ]),

    invite() {
      if (!this.selectedContacts.length) {
        return;
      }
      const invitees = this.selectedContacts.map((contact) => contact.id);
      analytics.trackEvent(ANALYTICS.PARTICIPANT_INVITED, {
        Source: ANALYTICS_SOURCE.INVITE_PARTICIPANT_MODAL,
        'Number of Invitations': invitees.length,
        Owner: this.isSessionOwner,
        'Num of Participants': this.activeParticipants.length
      });

      this.inviteContacts(invitees);
      this.resetSelectedContacts();
      this.closeModal();
    },

    resetSelectedContacts() {
      this.selectedContacts.forEach((contact) =>
        this.selectContactToInvite({ id: contact.id, selected: false })
      );
      this.selectedContacts = [];
    },

    copyLinkToClipboard() {
      clipboardService.copy(this.roomUrl);

      this.addFlashMessage({
        type: 'good',
        text: this.$t('invite_participants_modal.link_copied_flash_message')
      });
      analytics.trackEvent(ANALYTICS.INFO_COPIED, {
        Source: ANALYTICS_SOURCE.INVITE_PARTICIPANT_MODAL
      });
    },

    closeModal() {
      this.setIsInviteParticipantsDialogVisible(false);
    }
  }
};
</script>

<style scoped>
.invite-participants-container {
  overflow: hidden;
  max-width: 435px;
}
</style>
