import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/views/Home';
import MobileHome from '@/views/mobile/MobileHome';
import JoinMeetingFromLinkScreen from '@/views/JoinMeetingFromLinkScreen';
import UnsupportedBrowserScreen from '@/views/UnsupportedBrowserScreen';
import MessageScreen from '@/views/MessageScreen.vue';
import ThankYouScreen from '@/views/ThankYouScreen';
import MobileJoinMeetingFromLinkScreen from '@/views/mobile/MobileJoinMeetingFromLinkScreen';
import WhitelabelingPreviewScreen from '@/views/WhitelabelingPreviewScreen.vue';
import MobileWaitingRoomJoinerScreen from '@/views/mobile/MobileWaitingRoomJoinerScreen';
import { isMobileDevice } from '@/helpers/global-helpers';
import { MESSAGE_SCREEN_REASON } from '@/consts/global-consts';
import store from '@/store';
import { sessionStorageService } from '@/services/storage-service';
import {
  beforeEnterRouteTo,
  beforeEnterMessageScreen,
  beforeEnterThankYouMessage
} from './routes-handlers';
import { WAITING_ROOM_MESSAGE_ENUM } from '@/store/waitingRoom/consts';

Vue.use(Router);

export async function preInitApp(query, params) {
  const themeUrl = params?.themeUrl;
  const roomToken =
    query?.room_token ||
    params?.roomToken ||
    sessionStorageService.getItem('room_token');
  await store.dispatch('preInitApp', {
    room_token: roomToken,
    theme_url: themeUrl
  });
}

const router = new Router({
  mode: 'history', // TODO: change to 'abstract'
  routes: [
    {
      path: '/meetings/unsupported-browser',
      name: 'UnsupportedBrowserScreen',
      component: UnsupportedBrowserScreen
    },
    {
      path: '/meetings/preview',
      name: 'WhitelabelingPreviewScreen',
      component: WhitelabelingPreviewScreen
    },
    {
      path: '/meetings/message',
      name: 'MessageScreen',
      component: MessageScreen,
      beforeEnter: beforeEnterMessageScreen
    },
    {
      path: '/:themeUrl/meetings/message',
      name: 'MessageScreenWithThemeUrl',
      component: MessageScreen,
      beforeEnter: (to, from, next) => {
        beforeEnterMessageScreen(to, from, next, 'HomeWithThemeUrl', true);
      }
    },
    {
      path: '/meetings/network-issue',
      name: 'NetworkIssueScreen',
      component: MessageScreen,
      beforeEnter: beforeEnterMessageScreen
    },
    {
      path: '/:themeUrl/meetings/network-issue',
      name: 'NetworkIssueScreenWithThemeUrl',
      component: MessageScreen,
      beforeEnter: (to, from, next) => {
        beforeEnterMessageScreen(to, from, next, 'HomeWithThemeUrl', true);
      }
    },
    {
      path: '/meetings/thank-you',
      name: 'ThankYouScreen',
      component: ThankYouScreen,
      beforeEnter: beforeEnterThankYouMessage
    },
    {
      path: '/:themeUrl/meetings/thank-you',
      name: 'ThankYouScreenWithThemeUrl',
      component: ThankYouScreen,
      beforeEnter: (to, from, next) => {
        beforeEnterThankYouMessage(to, from, next, 'HomeWithThemeUrl', true);
      }
    },
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: async (to, from, next) => {
        if (isMobileDevice) {
          beforeEnterRouteTo(to, from, next, 'MobileHome', true);
        } else if (!window.isBrowserSupported) {
          beforeEnterRouteTo(to, from, next, 'UnsupportedBrowserScreen', true);
        } else {
          await preInitApp(to.query, to.params);
          next();
        }
      }
    },
    {
      path: '/mobile',
      name: 'MobileHome',
      component: MobileHome,
      beforeEnter: async (to, from, next) => {
        if (!isMobileDevice) {
          beforeEnterRouteTo(to, from, next, 'Home', true);
        } else {
          await preInitApp(to.query, to.params);
          next();
        }
      }
    },
    {
      path: '/:roomToken(\\d+)',
      name: 'JoinMeetingFromLinkScreen',
      component: JoinMeetingFromLinkScreen,
      beforeEnter: async (to, from, next) => {
        await preInitApp(to.query, to.params);

        if (isMobileDevice) {
          beforeEnterRouteTo(
            to,
            from,
            next,
            'MobileJoinMeetingFromLinkScreen',
            true
          );
        } else {
          next();
        }
      }
    },
    {
      path: '/:themeUrl',
      name: 'HomeWithThemeUrl',
      component: Home,
      beforeEnter: async (to, from, next) => {
        await preInitApp(to.query, to.params);

        if (isMobileDevice) {
          beforeEnterRouteTo(to, from, next, 'MobileHomeWithThemeUrl', true);
        } else if (!window.isBrowserSupported) {
          beforeEnterRouteTo(to, from, next, 'UnsupportedBrowserScreen', true);
        } else {
          next();
        }
      }
    },
    {
      path: '/mobile/:roomToken(\\d+)',
      name: 'MobileJoinMeetingFromLinkScreen',
      component: MobileJoinMeetingFromLinkScreen,
      beforeEnter: async (to, from, next) => {
        await preInitApp(to.query, to.params);
        next();
      }
    },
    {
      path: '/mobile/:themeUrl/waiting-room',
      name: 'MobileWaitingRoomJoinerScreenWithThemeUrl',
      component: MobileWaitingRoomJoinerScreen,
      beforeEnter: async (to, from, next) => {
        await preInitApp(to.query, to.params);
        next();
      }
    },
    {
      path: '/mobile/waiting-room',
      name: 'MobileWaitingRoomJoinerScreen',
      component: MobileWaitingRoomJoinerScreen,
      beforeEnter: async (to, from, next) => {
        const waitingRoomEnumValues = Object.values(WAITING_ROOM_MESSAGE_ENUM);
        const globalMessageType = store.state.globalMessage?.reason;
        if (
          waitingRoomEnumValues.includes(globalMessageType) ||
          store.state.waitingRoom.isInWaitingRoom
        ) {
          await preInitApp(to.query, to.params);
          next();
        } else {
          sessionStorageService.clear();
          beforeEnterRouteTo(to, from, next);
        }
      }
    },
    {
      path: '/mobile/:themeUrl',
      name: 'MobileHomeWithThemeUrl',
      component: MobileHome,
      beforeEnter: async (to, from, next) => {
        await preInitApp(to.query, to.params);
        next();
      }
    },
    {
      path: '/:themeUrl/:roomToken',
      name: 'JoinMeetingFromLinkScreenWithThemeUrl',
      component: JoinMeetingFromLinkScreen,
      beforeEnter: async (to, from, next) => {
        if (isMobileDevice) {
          beforeEnterRouteTo(
            to,
            from,
            next,
            'MobileJoinMeetingFromLinkScreenWithThemeUrl',
            true
          );
        }
        await preInitApp(to.query, to.params);
        next();
      }
    },
    {
      path: '/mobile/:themeUrl/:roomToken',
      name: 'MobileJoinMeetingFromLinkScreenWithThemeUrl',
      component: MobileJoinMeetingFromLinkScreen,
      beforeEnter: async (to, from, next) => {
        if (!isMobileDevice) {
          beforeEnterRouteTo(
            to,
            from,
            next,
            'JoinMeetingFromLinkScreenWithThemeUrl',
            true
          );
        }
        await preInitApp(to.query, to.params);
        next();
      }
    },
    {
      path: '*',
      redirect: () => ({
        name: store.getters.isMobileDevice ? 'MobileHome' : 'Home'
      })
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (
    store.getters.isMobileWebMode &&
    (from.fullPath.includes('/meetings/thank-you') ||
      (from.fullPath.includes('/meetings/message') &&
        from.params.reason !== MESSAGE_SCREEN_REASON.CANNOT_JOIN_BEFORE_HOST))
  ) {
    // Disable back from thankYou screen and message screen in mobile web mode
    return router.push(from.fullPath);
  }
  return next();
});

router.afterEach(() => {
  window.Appcues?.page();
});

if (isMobileDevice) {
  window.addEventListener('pageshow', (e) => {
    // Invalidate persisted back/forward cache by reloading the page
    if (e.persisted) {
      document.location.reload();
    }
  });
}

export default router;
