<template>
  <div class="advanced-settings-container">
    <div v-if="exclusiveExperimentalMode" class="section-container">
      <div class="title-container">
        <div class="section-title">
          {{ $t('advanced_settings.hide_my_stream_title') }}
        </div>
        <div class="section-subtitle">
          {{ $t('advanced_settings.hide_my_stream_subtitle') }}
        </div>
      </div>
      <vwc-switch
        :checked="isHideMyStreamEnabled"
        @change="toggleHideMyStream"
      />
    </div>

    <div
      v-if="isOpenWebRTCInternalsFunctionalityAvailable"
      class="section-container"
    >
      <div class="title-container">
        <div class="section-title">
          {{ $t('advanced_settings.debug_title') }}
        </div>
        <div class="section-subtitle">
          {{ $t('advanced_settings.debug_subtitle') }}
        </div>
      </div>
      <vwc-button
        class="webrtc-internals-button"
        :label="$t('advanced_settings.webrtc_internals_button')"
        layout="outlined"
        shape="rounded"
        dense
        @click="openWebRTCInternals"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';

export default {
  name: 'AdvancedSettings',

  computed: {
    ...mapState(['isHideMyStreamEnabled']),
    ...mapGetters('settings', [
      'exclusiveExperimentalMode',
      'isOpenWebRTCInternalsFunctionalityAvailable'
    ])
  },

  methods: {
    ...mapActions(['openWebRTCInternals', 'toggleHideMyStream'])
  }
};
</script>

<style scoped>
.advanced-settings-container {
  row-gap: 16px;
}

.section-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.title-container {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

.section-title {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  text-align: left;
}

.section-subtitle {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: #666666;
}
</style>
