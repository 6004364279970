<template>
  <div class="image-container" @click="imageClicked">
    <div
      class="image-wrapper Vlt-btn-on-hover"
      :class="{
        'image-uploading': isUploading,
        'image-wrapper-not-sent': sendFailed
      }"
      @mouseenter="hover = true"
      @mouseleave="hover = false"
    >
      <transition name="fade">
        <div
          v-if="isUploading && !sendFailed"
          class="thumbnail-text text-ellipsis"
        >
          {{ $t('image_row.uploading_image_text', { percentsUploaded }) }}
        </div>
        <div
          v-if="sendFailed || downloadFailed"
          class="thumbnail-text text-ellipsis"
        >
          {{ errorMessage }}
        </div>
      </transition>
      <v-spinner v-if="isUploading" class="spinner" :white="isMobileWebMode" />
      <div v-if="showActionFailedButtons" class="action-failed-buttons">
        <vwc-button
          :label="
            uploadCancelled
              ? $t('image_row.upload_again_label')
              : $t('image_row.try_again_label')
          "
          layout="filled"
          dense
          @click.stop="tryAgain"
        />
      </div>
      <div v-if="isUploading" class="uploading-action-buttons">
        <vwc-button
          :label="$t('image_row.cancel_button')"
          layout="filled"
          dense
          @click.stop="cancelUpload"
        />
      </div>
      <img class="image-thumbnail" :src="image.src" />
      <vwc-icon-button
        v-if="showDownloadButton"
        class="download-button vvd-scheme-main"
        icon="download-line"
        layout="filled"
        :disabled="isDownloading"
        @click.stop="downloadFile()"
      >
        <vwc-circular-progress
          v-if="isDownloading"
          indeterminate
          density="-6"
        />
      </vwc-icon-button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import { ATTACHMENT_TYPE } from '@/consts/messaging';
export default {
  name: 'ImageRow',

  props: {
    message: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isDownloading: false,
      downloadFailed: false,
      hover: false
    };
  },

  computed: {
    ...mapGetters('messaging/attachments', [
      'uploadsProgressMap',
      'cancelledRequests'
    ]),
    ...mapGetters(['isMobileWebMode']),

    uploadCancelled() {
      return !!this.cancelledRequests[this.message.messageId];
    },

    percentsUploaded() {
      return this.uploadsProgressMap[this.message.messageId];
    },

    errorMessage() {
      if (this.downloadFailed) {
        return this.$t('image_row.download_failed_error');
      } else if (this.sendFailed) {
        return this.$t('image_row.send_failed_error');
      }
      return '';
    },

    isUploading() {
      return (
        this.percentsUploaded !== undefined && this.percentsUploaded <= 100
      );
    },

    actions() {
      return {
        showMaximise: true,
        showTrash: true,
        showDownloading: this.isDownloading,
        showDownload: !this.isDownloading
      };
    },

    image() {
      if (
        this.message.data &&
        this.message.data[0] &&
        this.message.data[0].metadata.attachmentType ===
          ATTACHMENT_TYPE.IMAGE &&
        this.message.data[0].thumbnail
      ) {
        return {
          key: this.message.data[0].metadata.key,
          src: `data:${this.message.data[0].metadata.info.mime_type};base64, ${this.message.data[0].thumbnail}`,
          fileName: this.message.data[0].metadata.fileName,
          size: this.message.data[0].metadata.size
        };
      }
      return null;
    },

    sendFailed() {
      return this.message.sentStatus === 'FAILED';
    },

    downloadable() {
      return !this.isUploading && !this.sendFailed;
    },

    showDownloadButton() {
      return (
        this.downloadable &&
        this.hover &&
        !this.downloadFailed &&
        !this.uploadCancelled &&
        !this.isMobileWebMode
      );
    },

    showActionFailedButtons() {
      return (
        (this.sendFailed || this.downloadFailed || this.uploadCancelled) &&
        !this.isUploading
      );
    }
  },

  methods: {
    ...mapActions('messaging/attachments', [
      'downloadFileToDisk',
      'cancelUploadRequest'
    ]),

    cancelUpload() {
      this.cancelUploadRequest({ messageId: this.message.messageId });
    },

    async downloadFile() {
      if (this.downloadable) {
        this.isDownloading = true;
        try {
          await this.downloadFileToDisk({ messageId: this.message.messageId });
          this.isDownloading = false;
          this.downloadFailed = false;
        } catch (error) {
          this.isDownloading = false;
          this.downloadFailed = true;
        }
      }
    },

    tryAgain() {
      if (this.uploadCancelled || this.sendFailed) {
        // Upload Failed
        this.$emit('resend');
      } else {
        // Download Failed
        this.downloadFile();
      }
    },

    imageClicked() {
      if (this.downloadable) {
        this.$emit('openImageView');
      }
    }
  }
};
</script>
<style scoped>
.image-container {
  position: relative;
}
/* Image Wrapper */
.image-wrapper {
  border-radius: 5px;
  position: relative;
  float: left;
  background: #000;
}
/* Using selector on native elements is bad practice so we use a class on img and
   select according to this class insted */
.image-wrapper .image-thumbnail {
  border-radius: 4px;
  display: block;
  transition: opacity 300ms;
}
.image-wrapper:hover .image-thumbnail {
  opacity: 0.8;
  transition: opacity 300ms;
}
.image-wrapper >>> .Vlt-btn-group--hover {
  bottom: 4px;
  right: 16px;
  top: initial !important;
}
/* Text */
.thumbnail-text {
  z-index: 1;
  position: absolute;
  top: 8px;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.33;
  color: #ffffff;
  width: 100%;
  padding: 0 10px;
}
/* Upload */
.image-uploading.image-wrapper .image-thumbnail {
  opacity: 0.5;
  transition: opacity 300ms;
  background-color: #000;
  cursor: default;
}
.spinner {
  z-index: 1;
  position: absolute;
  top: calc(50% - 36px);
  left: calc(50% - 36px);
}
/* Send Failure */
.image-wrapper.image-wrapper-not-sent .image-thumbnail {
  opacity: 0.5;
  transition: opacity 300ms;
  background-color: #000;
  cursor: default;
}
/* Buttons for failures */
.action-failed-buttons,
.uploading-action-buttons {
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 16px;
  bottom: 8px;
  z-index: 1;
}
.action-failed-buttons vwc-button,
.uploading-action-buttons vwc-button {
  margin-bottom: 8px;
}
vwc-icon-button.download-button {
  position: absolute;
  right: 8px;
  bottom: 8px;
  cursor: pointer;
}
/* Animations */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
/* Override Volta */
.image-wrapper >>> #hover-for-image {
  bottom: 4px;
  right: 16px;
}
/***/
</style>
