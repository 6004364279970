/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-flip-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5 0v4.5h12a1.5 1.5 0 010 3h-12V12L0 6l7.5-6zm-3 16.5h12V12l7.5 6-7.5 6v-4.5h-12a1.5 1.5 0 010-3z"/>'
  }
})
