const ASPECT_RATIO = 4 / 3;
const MIN_STREAM_WIDTH = 100;

const MINIMIZED_MODE_PARTICIPANT_PADDING = 8 * 2;
const PARTICIPANT_PADDING = 4 * 2;
const GALLERY_VIEW_PADDING = 8 * 2;
const GALLERY_VIEW_FRAMELESS_PADDING = 20;

const SCROLLBAR_WIDTH = 6;
const MAX_SPEAKER_MODE_COLUMNS = 2;
const MAX_SCREENSHARE_MODE_COLUMNS = 1;
const MIN_STREAMS_IN_HEIGHT = 4;

/***
 * ALGORITHM:
 * 1. calc min amount of cols possible (available height / min stream height) - skip in screenshare (always max 1 col)
 * 2. calc min small strip width (amount of cols * min stream width) - the remaining width would be the max main stream width
 * 3. calc max size for main stream in the max main stream width (in screenshare mode, take the full height, and all the width from the remaining width of step 2)
 * 4. take the width that remains after assigning the main stream (it must be at least as the width from step 2), and find the max size of streams that fits in this width in the amount of cols that was found in step 1
 * 5. if min amount of cols is larger than the max amount of columns allowed, add scroll
 */
export function searchOptimalSpeakerViewSize(
  areaWidth,
  areaHeight,
  numOfParticipants,
  {
    stretchMainStream = false,
    participantPadding = PARTICIPANT_PADDING,
    smallStreamAspectRatio = ASPECT_RATIO
  } = {}
) {
  const minStreamHeight = MIN_STREAM_WIDTH / smallStreamAspectRatio;
  const minStreamWidthPadded = MIN_STREAM_WIDTH + participantPadding;
  const minStreamHeightPadded = minStreamHeight + participantPadding;
  const areaWidthPadded = areaWidth - participantPadding;
  const areaHeightPadded = areaHeight - participantPadding;
  const numOfParticipantsToUse = numOfParticipants - 1;
  const maxMainStripWidthCalculator = (cols) =>
    areaWidthPadded -
    cols * minStreamWidthPadded -
    participantPadding -
    SCROLLBAR_WIDTH;

  let mainStreamSize;
  let smallStreamSize;
  let smallStripSize;
  let extraWidthSpace = 0;

  if (stretchMainStream) {
    // step 2
    const maxMainStripWidth = maxMainStripWidthCalculator(
      MAX_SCREENSHARE_MODE_COLUMNS
    );

    // step 3
    // main takes max height and width
    let mainStripWidth = areaWidthPadded;
    let mainStripHeight = areaHeightPadded;
    mainStripWidth = maxMainStripWidth - participantPadding;

    mainStreamSize = {
      width: mainStripWidth,
      height: mainStripHeight
    };

    smallStreamSize = {
      width: minStreamWidthPadded,
      height: minStreamHeightPadded
    };

    smallStripSize = {
      smallStreamsStripWidth:
        MAX_SCREENSHARE_MODE_COLUMNS * smallStreamSize.width +
        2 * participantPadding
    };
  } else {
    // step 1
    const maxParticipantsInCol = Math.floor(
      areaHeightPadded / minStreamHeightPadded
    );
    const expectedNumOfCols = Math.ceil(
      numOfParticipantsToUse / maxParticipantsInCol
    );
    const numOfColumns = Math.min(expectedNumOfCols, MAX_SPEAKER_MODE_COLUMNS);

    // step 2
    const maxMainStripWidth = maxMainStripWidthCalculator(numOfColumns);

    // step 3
    mainStreamSize = searchOptimalDominantViewSize(
      maxMainStripWidth + 3 * participantPadding,
      areaHeight
    );

    // step 4
    const smallStreamStripMaxWidth =
      areaWidthPadded - mainStreamSize.width - participantPadding;
    smallStreamSize = searchOptimalSpeakerViewSmallStreamsSize(
      smallStreamStripMaxWidth - participantPadding,
      areaHeightPadded,
      numOfParticipantsToUse,
      numOfColumns,
      { smallStreamAspectRatio }
    );

    smallStripSize = {
      smallStreamsStripWidth: smallStreamSize.outerWidth + participantPadding,
      smallStreamsStripHeight: smallStreamSize.outerHeight || areaHeight // step 5
    };

    // used to center the streams in the availavle width
    extraWidthSpace = Math.max(
      smallStreamStripMaxWidth - smallStreamSize.outerWidth,
      0
    );
  }

  return {
    mainStreamSize,
    smallStreamSize,
    smallStripSize,
    extraWidthSpace
  };
}

function searchOptimalSpeakerViewSmallStreamsSize(
  areaWidth,
  areaHeight,
  numOfParticipants,
  numOfColumns,
  {
    participantPadding = PARTICIPANT_PADDING,
    smallStreamAspectRatio = ASPECT_RATIO
  } = {}
) {
  let finalWidth;
  let finalHeight;
  const numOfExpectedRows = Math.ceil(numOfParticipants / numOfColumns);
  const numOfRows = Math.max(numOfExpectedRows, MIN_STREAMS_IN_HEIGHT); // at least 4 rows

  // max width
  const width1 =
    (areaWidth - (numOfColumns - 1) * participantPadding) / numOfColumns;
  const height1 = width1 / ASPECT_RATIO;

  // max height
  const height2 =
    (areaHeight - (numOfRows - 1) * participantPadding) / numOfRows;
  const width2 = height2 * ASPECT_RATIO;

  // take the smaller of the 2 options - the bigger won't fit in the screen
  if (width1 < width2) {
    finalWidth = width1;
    finalHeight = height1;
  } else if (width2 >= MIN_STREAM_WIDTH) {
    finalWidth = width2;
    finalHeight = height2;
  } else {
    finalWidth = MIN_STREAM_WIDTH;
    finalHeight = MIN_STREAM_WIDTH / smallStreamAspectRatio;
  }

  return {
    width: finalWidth,
    height: finalHeight,
    outerWidth: numOfColumns * (finalWidth + participantPadding),
    outerHeight: (finalHeight + participantPadding) * numOfExpectedRows
  };
}

export function searchOptimalDominantViewSize(
  areaWidth,
  areaHeight,
  { stretchStream = false } = {}
) {
  let mainStreamSize;
  const areaWidthPadded = areaWidth - 2 * PARTICIPANT_PADDING;
  const areaHeightPadded = areaHeight - PARTICIPANT_PADDING;

  // check if max available main stream width is possible
  if (stretchStream) {
    // stream takes max width and height
    mainStreamSize = {
      width: areaWidthPadded,
      height: areaHeightPadded
    };
  } else if (areaWidthPadded / areaHeightPadded >= ASPECT_RATIO) {
    // stream takes max height
    mainStreamSize = {
      width: areaHeightPadded * ASPECT_RATIO,
      height: areaHeightPadded
    };
  } else {
    // stream takes max width
    mainStreamSize = {
      width: areaWidthPadded,
      height: areaWidthPadded / ASPECT_RATIO
    };
  }
  return mainStreamSize;
}

// Given areaWidth * areaHeight container, search for the maxiumum sized numOfParticipants that have ASPECT_RATIO
export function searchOptimalGridViewSize(
  areaWidth,
  areaHeight,
  numOfParticipants
) {
  const areaWidthPadded = areaWidth - PARTICIPANT_PADDING;
  const areaHeightPadded =
    areaHeight - PARTICIPANT_PADDING - GALLERY_VIEW_FRAMELESS_PADDING;
  let cols = 0;
  let rows = 0;
  let rectHeight = 0;
  let rectWidth = 0;

  do {
    rows++;
    cols = Math.ceil(numOfParticipants / rows);

    rectHeight = (areaHeightPadded - (rows - 1) * GALLERY_VIEW_PADDING) / rows;
    rectWidth = rectHeight * ASPECT_RATIO;
  } while (
    cols * rectWidth + (cols - 1) * GALLERY_VIEW_PADDING >
    areaWidthPadded
  );

  const potentialSize1 = {
    width: rectWidth,
    height: rectHeight
  };

  cols = 0;
  do {
    cols++;
    rows = Math.ceil(numOfParticipants / cols);

    rectWidth = (areaWidthPadded - (cols - 1) * GALLERY_VIEW_PADDING) / cols;
    rectHeight = rectWidth / ASPECT_RATIO;
  } while (
    rows * rectHeight + (rows - 1) * GALLERY_VIEW_PADDING >
    areaHeightPadded
  );

  const potentialSize2 = {
    width: rectWidth,
    height: rectHeight
  };

  return {
    width: Math.floor(Math.max(potentialSize1.width, potentialSize2.width)),
    height: Math.floor(Math.max(potentialSize1.height, potentialSize2.height))
  };
}

export function getOptimalMinimizedViewSize(areaWidth) {
  const width = areaWidth - MINIMIZED_MODE_PARTICIPANT_PADDING;
  const size = {
    width,
    height: width / ASPECT_RATIO,
    left: 0
  };
  return size;
}
