/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-rows': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 2c0-.3.2-.5.5-.5h15c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5H.5C.2 4.5 0 4.3 0 4V2zm1 .5v1h14v-1H1zM0 7c0-.3.2-.5.5-.5h15c.3 0 .5.2.5.5v2c0 .3-.2.5-.5.5H.5C.2 9.5 0 9.3 0 9V7zm1 .5v1h14v-1H1zm-.5 4c-.3 0-.5.2-.5.5v2c0 .3.2.5.5.5h15c.3 0 .5-.2.5-.5v-2c0-.3-.2-.5-.5-.5H.5zm.5 2v-1h14v1H1z"/>'
  }
})
