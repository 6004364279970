/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-block-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M23.896 6.647a6.647 6.647 0 11-13.293 0 6.647 6.647 0 0113.293 0zm-6.647 3.821a3.822 3.822 0 003.51-5.335l-5.023 5.024c.464.2.976.311 1.513.311zm1.513-7.332L13.74 8.16a3.822 3.822 0 015.024-5.024z"/><path pid="1" d="M4.959 1.524c-.39-.168-.8-.189-1.2-.088l-.046.012-1.155.435C1.57 2.255.85 3.014.365 4.04l-.005.011c-.437.96-.454 2.084-.172 3.3C.464 8.535.931 9.752 1.58 11a28.131 28.131 0 002.245 3.606c.849 1.162 1.685 2.163 2.512 2.996.826.834 1.817 1.678 2.97 2.535a27.822 27.822 0 003.575 2.266c1.236.655 2.444 1.127 3.621 1.405 1.207.286 2.327.27 3.283-.174l.011-.005c1.02-.492 1.772-1.22 2.138-2.21l.431-1.166.011-.045a1.87 1.87 0 00-.086-1.197c-.18-.423-.522-.728-.883-.952-.319-.226-1.104-.738-2.325-1.518-.67-.427-1.302-.746-1.892-.927-.588-.181-1.184-.242-1.744-.085l-.02.006c-.763.236-1.47.606-1.842 1.242a1.042 1.042 0 01-.37-.032c-.428-.098-1.02-.409-1.787-1.042-.806-.664-1.372-1.166-1.718-1.514-.327-.33-.82-.893-1.49-1.707-.636-.775-.93-1.365-1.01-1.783l-.005-.02c-.048-.22-.04-.34-.031-.39.638-.38.991-1.1 1.203-1.866.326-1.095-.165-2.373-.986-3.666-.8-1.261-1.293-2.036-1.477-2.324-.221-.37-.526-.724-.955-.91z"/>'
  }
})
