<template>
  <transition name="fade">
    <div
      v-show="showPreviewVideo"
      class="video-preview"
      data-cy="preview-video-window"
    >
      <div class="video-container">
        <div class="preview-text">
          <div class="title">
            {{ $t('preview_video.title') }} -
            <span class="sub-title">{{ $t('preview_video.subtitle') }}</span>
          </div>
        </div>
        <div v-show="isVideoLoaded" ref="video" class="video"></div>
        <div v-if="!isVideoLoaded" class="loading-video">
          <vwc-circular-progress indeterminate density="2.5" />
          <div class="loading-text">{{ $t('preview_video.loading_text') }}</div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions } from 'vuex';
import analytics from '@/services/analytics-service';
import { ANALYTICS } from '@/consts/global-consts';

let previewPublisher = null;

export default {
  name: 'PreviewVideo',

  props: {
    delay: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      isVideoLoaded: false,
      showPreviewVideo: false,
      delayedActivationTimer: null
    };
  },

  created() {
    this.delay > 0 ? this.activateCameraWithDelay() : this.activateCamera();
  },

  destroyed() {
    this.stopVideoStream();
    clearTimeout(this.delayedActivationTimer);
  },

  methods: {
    ...mapActions(['createPublisher']),

    activateCameraWithDelay() {
      this.delayedActivationTimer = setTimeout(() => {
        this.activateCamera();
      }, this.delay);
    },

    async activateCamera() {
      this.stopVideoStream();
      const options = {
        insertMode: 'append',
        fitMode: 'contain',
        showControls: false,
        height: '100%',
        width: '100%',
        publishAudio: false,
        publishVideo: true
      };
      const element = this.$refs.video;

      this.showPreviewVideo = true;
      previewPublisher = await this.createPublisher({ element, options });
      // If the component has been destroyed before the publisher was created, we want to destroy the publisher
      if (this._isDestroyed) {
        this.stopVideoStream();
      }
      this.isVideoLoaded = true;
      analytics.trackEvent(ANALYTICS.PREVIEW_VIDEO, {
        Status: 'On'
      });
    },

    async stopVideoStream() {
      if (previewPublisher) {
        const publisherToDestroy = previewPublisher;
        previewPublisher = null;
        if (publisherToDestroy.getVideoFilter()) {
          await publisherToDestroy.clearVideoFilter();
        }
        publisherToDestroy.destroy();
      }
    }
  }
};
</script>

<style scoped>
.video >>> video {
  transform: scale(-1, 1);
  transform-origin: 50% 50%;
}

.video {
  transform: translateZ(0);
}

.video-container {
  background: #252525;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 12px 16px 16px 16px;
  border-radius: 8px;
}

.video,
.loading-video {
  background: #4d4d4d;
  height: 100%;
  width: 100%;
  margin-top: 12px;
  border-radius: 3px;
  overflow: hidden;
}

.loading-video {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-text {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  margin-top: 12px;
}

.preview-text {
  color: white;
}

.preview-text .title {
  font-size: 14px;
  line-height: 20px;
  font-weight: bold;
}

.preview-text .title .sub-title {
  font-weight: normal;
}

.video-preview {
  border-radius: 6px;
}

.fade-enter-active {
  transition: opacity 0.3s;
}

.fade-enter {
  opacity: 0;
}
</style>
