/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-fax-scheduled': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 2.2v1.5h3v18h11.6l6.2-6.2.2-.2V3.8h3V2.2H0zm15 17v-3.4h3.4L15 19.2zm4.5-5h-6v6h-9V3.8h15v10.4z"/><path pid="1" d="M10.2 10.5h1.5c.4 0 .8-.3.8-.8s-.4-.7-.8-.7h-.8v-.8c0-.4-.3-.8-.8-.8-.4 0-.8.3-.8.8v1.5c.1.5.5.8.9.8z"/><path pid="2" d="M10.5 13.9c2.5 0 4.5-2 4.5-4.5s-2-4.5-4.5-4.5S6 7 6 9.4s2 4.5 4.5 4.5zm0-7.5c1.7 0 3 1.3 3 3s-1.3 3-3 3-3-1.3-3-3c0-1.6 1.3-3 3-3z"/>'
  }
})
