/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-sticker-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 12a9 9 0 0116.061-5.582c-.219-.012-.44-.018-.661-.018-6.627 0-12 5.373-12 12 0 .222.006.442.018.66A8.986 8.986 0 013 12zm4.339 11.061C3.027 21.241 0 16.974 0 12 0 5.373 5.373 0 12 0c4.974 0 9.242 3.027 11.061 7.34h.002v.001c0 .002 0 .004.002.006L7.344 23.064l-.004-.002-.001.002-.001-.003z"/>'
  }
})
