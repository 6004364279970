/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-list-numbers': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 25',
    data: '<path pid="0" d="M3.107 5.828h1.468V.478H3.281l-1.84 1.5.75.923.6-.501c.121-.106.233-.222.337-.345-.014.286-.021.601-.021.945v2.828zM1.35 14.866h3.884l.004-1.189H3.213v-.037c.593-.533.98-.894 1.163-1.084.163-.166.31-.348.438-.544.104-.163.186-.34.243-.525.054-.19.08-.386.079-.584a1.35 1.35 0 00-.225-.758 1.457 1.457 0 00-.633-.522 2.25 2.25 0 00-.93-.185 3 3 0 00-.75.086c-.214.053-.42.135-.612.243-.25.152-.483.33-.696.529l.795.93c.18-.16.375-.304.584-.426.154-.094.33-.146.511-.15a.554.554 0 01.381.12.422.422 0 01.135.33.802.802 0 01-.066.324 1.779 1.779 0 01-.24.367c-.115.143-.358.41-.732.8L1.35 13.914v.953zm3.705 4.868c.008.313-.099.617-.3.857-.235.256-.54.436-.879.517v.021c.894.112 1.341.535 1.341 1.27a1.373 1.373 0 01-.592 1.174A2.759 2.759 0 012.978 24a4.898 4.898 0 01-.8-.061 4.105 4.105 0 01-.796-.227v-1.2a3.205 3.205 0 001.387.351c.24.017.479-.03.695-.133a.45.45 0 00.217-.419.472.472 0 00-.114-.34.75.75 0 00-.366-.179 2.901 2.901 0 00-.658-.061h-.33v-1.086h.337c.734 0 1.103-.188 1.103-.564a.346.346 0 00-.164-.315.826.826 0 00-.438-.104c-.38.012-.748.132-1.062.345l-.6-.967a2.91 2.91 0 01.87-.417c.333-.088.676-.13 1.02-.125a2.25 2.25 0 011.304.33 1.05 1.05 0 01.472.906zM9.75 3a.75.75 0 000 1.5h13.5a.75.75 0 100-1.5H9.75zM9 12.75a.75.75 0 01.75-.75h13.5a.75.75 0 110 1.5H9.75a.75.75 0 01-.75-.75zM9.75 21a.75.75 0 000 1.5h13.5a.75.75 0 100-1.5H9.75z"/>'
  }
})
