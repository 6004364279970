/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-lock-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C8.7 0 6 2.7 6 6v1.5H3c-.9 0-1.5.6-1.5 1.5v13.5c0 .9.6 1.5 1.5 1.5h18c.9 0 1.5-.6 1.5-1.5V9c0-.9-.6-1.5-1.5-1.5h-3V6c0-3.3-2.7-6-6-6zm1.5 17.55v1.95h-3v-1.95C9.6 17.1 9 16.05 9 15c0-1.65 1.35-3 3-3s3 1.35 3 3c0 1.05-.6 2.1-1.5 2.55zM15 7.5H9V6c0-1.65 1.35-3 3-3s3 1.35 3 3v1.5z"/>'
  }
})
