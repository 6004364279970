/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-filter': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M.5 0a.5.5 0 00-.5.5v3a.5.5 0 00.223.416L6 7.768V15.5a.5.5 0 00.777.416l3-2A.5.5 0 0010 13.5V7.768l5.777-3.852A.5.5 0 0016 3.5v-3a.5.5 0 00-.5-.5H.5zm6.277 7.084L1 3.232V1h14v2.232L9.223 7.084A.5.5 0 009 7.5v5.732l-2 1.334V7.5a.5.5 0 00-.223-.416z"/>'
  }
})
