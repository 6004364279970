var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vwc-banner",
    {
      staticClass: "banner",
      attrs: {
        "data-cy": "old-account-banner",
        connotation: "warning",
        message: _vm.$t("old_account_banner.message"),
        open: "true",
        icon: "warning-solid",
        dismissible: "",
      },
    },
    [
      _c(
        "a",
        {
          staticClass: "link",
          attrs: {
            slot: "actionItems",
            href:
              "https://docs.google.com/document/d/1SurdHFTk8LFQ4oNy4hWzWkxk2TqqRh83t0aGVXkXtVs/edit",
            target: "_blank",
          },
          slot: "actionItems",
        },
        [_vm._v(_vm._s(_vm.$t("old_account_banner.learn_how_button")) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }