/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-zip': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.4 16.3c1.3 0 2.1-.9 2.1-2.1 0-1.2-.7-2-2-2h-2.7v6.3h1.1v-2.2h1.5zm-1.5-3h1.4c.6 0 1 .4 1 1s-.4 1.1-1.1 1.1h-1.3v-2.1zM5.2 17.4H1.9l3.3-4.2v-1H.5v1.1h3.3L.5 17.5v1h4.7z"/><path pid="1" d="M22.3 7.7L14.8.2c-.2-.1-.4-.2-.6-.2h-12c-.4 0-.7.3-.7.8V10H3V1.5h10.5v6.8c0 .4.3.8.8.8H21v13.5H3V21H1.5v2.2c0 .4.3.8.8.8h19.5c.4 0 .8-.3.8-.8v-15c-.1-.1-.2-.3-.3-.5zM15 7.5V2.6l4.9 4.9H15z"/><path pid="2" d="M6 17.4v1.1h3.7v-1.1H8.4v-4.1h1.3v-1.1H6v1.1h1.3v4.1z"/>'
  }
})
