/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-voicemail-transcript-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M22.5.001h-21a1.418 1.418 0 00-1.5 1.5v16.5a1.417 1.417 0 001.5 1.5h6l4.5 4.5 4.5-4.5h6a1.417 1.417 0 001.5-1.5v-16.5a1.417 1.417 0 00-1.5-1.5zm-15 13.5h-3v-7.5h3v7.5zm6 1.5h-3v-10.5h3v10.5zm6-3h-3v-4.5h3v4.5z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
