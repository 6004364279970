/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-key-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.05 0l-9.3 9.45C9 9.15 8.25 9 7.5 9 3.3 9 0 12.3 0 16.5S3.3 24 7.5 24s7.5-3.3 7.5-7.5c0-.75-.15-1.65-.45-2.4L16.5 12V9h3V6h3L24 4.5V0h-4.95zM6.75 18c-1.2 0-2.25-1.05-2.25-2.25s1.05-2.25 2.25-2.25S9 14.55 9 15.75 7.95 18 6.75 18z"/>'
  }
})
