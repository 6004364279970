/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-audio-off-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.924 9.166H2.183a.703.703 0 00-.683.723v4.222c0 .425.33.722.683.722h2.741l4.489 3.885V5.282L4.924 9.166zm-.559-1.5H2.183C.977 7.666 0 8.661 0 9.89v4.222c0 1.228.977 2.222 2.183 2.222h2.182l5.72 4.95a.5.5 0 00.827-.377V3.094a.5.5 0 00-.827-.378l-5.72 4.95z"/>'
  }
})
