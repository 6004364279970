/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-sms': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.146 1.146A.5.5 0 017.5 1h7a.5.5 0 01.5.5v4a.5.5 0 01-.5.5h-5a.5.5 0 00-.277.084L7 7.566V1.5a.5.5 0 01.146-.354zM7.5 0A1.5 1.5 0 006 1.5v7a.5.5 0 00.777.416L9.651 7H14.5A1.5 1.5 0 0016 5.5v-4A1.5 1.5 0 0014.5 0h-7zM2.146 2.146A.5.5 0 012.5 2h2a.5.5 0 000-1h-2A1.5 1.5 0 001 2.5v12A1.5 1.5 0 002.5 16h8a1.5 1.5 0 001.5-1.5v-6a.5.5 0 00-1 0v6a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-12a.5.5 0 01.146-.354z"/>'
  }
})
