import {
  isMobileDevice,
  isIPad,
  isAppleMobileDevice
} from '@/helpers/global-helpers';

export function initBrowserDetection() {
  // The variable is now available on window.isIE everywhere in the app
  window.isIE = (function () {
    // Since it seems that neither of the two stackoverflow solutions is enough on its own, we use
    // both of them to increase the chances of detecting when IE is used.
    // According to Mario's answer https://stackoverflow.com/questions/19999388/check-if-user-is-using-ie-with-jquery
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE ');
    const trident = ua.indexOf('Trident/');
    const uaCheck = msie > 0 || trident > 0;
    // According to https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
    return !!document.documentMode || uaCheck;
  })();

  window.isEdge = (function () {
    return ['Edge', 'EdgiOS', 'Edg'].some(
      (edgeIdentifier) =>
        window.navigator.userAgent.indexOf(edgeIdentifier) >= 0
    );
  })();

  window.isFirefox = (function () {
    return ['firefox', 'fxios'].some(
      (edgeIdentifier) =>
        window.navigator.userAgent.toLowerCase().indexOf(edgeIdentifier) >= 0
    );
  })();

  window.isOpera = (function () {
    return (
      navigator.opr ||
      navigator.opera ||
      /Opera|OPR\//.test(navigator.userAgent)
    );
  })();

  window.isSamsungBrowser = (function () {
    return !!navigator.userAgent.match(/SamsungBrowser/i);
  })();

  // The variable is now available on window.isSafari everywhere in the app
  window.isSafari = (function () {
    // Avoid false positives by detecting other iOS browsers, which are all based on Safari
    if (
      navigator.userAgent.match('CriOS') ||
      window.isEdge ||
      window.isFirefox
    ) {
      return false;
    }

    // According to https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
    return (
      /^((?!chrome|android).)*safari/i.test(navigator.userAgent) ||
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
      })(
        !window['safari'] ||
          // eslint-disable-next-line no-undef
          (typeof safari !== 'undefined' && safari.pushNotification)
      )
    );
  })();

  window.isChrome = (function () {
    return !!(
      window.chrome &&
      navigator.vendor === 'Google Inc.' &&
      !window.isOpera &&
      !window.isEdge &&
      !window.isSafari &&
      !window.isIE &&
      !window.isFirefox &&
      !window.isSamsungBrowser
    );
  })();

  window.isAndroidWebView = (function () {
    return !!navigator.userAgent.match('wv');
  })();

  // This solution was taken from here: https://stackoverflow.com/questions/4460205/detect-ipad-iphone-webview-via-javascript
  window.isIosWebView = (function () {
    if (!isAppleMobileDevice) {
      return false;
    }
    const htmlEl = document.getElementsByTagName('html')[0];
    const bodyEl = document.getElementsByTagName('body')[0];

    const oldHtmlHeight = htmlEl.style.height;
    const oldBodyHeight = bodyEl.style.height;

    htmlEl.style.height = '100vh';
    bodyEl.style.height = '100%';

    const webViewMode =
      document.documentElement.clientHeight ===
      document.documentElement.scrollHeight;

    // restore height
    htmlEl.style.height = oldHtmlHeight;
    bodyEl.style.height = oldBodyHeight;

    return webViewMode;
  })();

  function getSamsungBrowserMajorVersion() {
    if (!window.isSamsungBrowser) {
      return 0;
    }

    const match = navigator.userAgent.match(
      /SamsungBrowser\/(\d+)\.(\d+)\.?(\d+)?/
    );
    return match ? parseInt(match[1], 10) : 0;
  }

  function getiPadOSMajorVersion() {
    const match = navigator.appVersion.match(
      /Version\/(\d+)\.(\d+)\.?(\d+)?\s+Safari/
    );
    return match ? parseInt(match[1], 10) : 0;
  }

  function getIOSMajorVersion() {
    if (!isAppleMobileDevice) {
      return 0;
    }

    if (isIPad) {
      const iPadOSMajorVersion = getiPadOSMajorVersion();
      if (iPadOSMajorVersion) {
        return getiPadOSMajorVersion();
      }
    }

    const match = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return match ? parseInt(match[1], 10) : 0;
  }

  function getAndroidMajorVersion() {
    const userAgent = navigator.userAgent.toLowerCase();
    var match = userAgent.match(/android\s([0-9.]*)/i);
    return match ? parseInt(match[1]) : 0;
  }

  // Mobile web is supported on:
  // iOS 14+ using Safari, Edge or Firefox
  const isMobileWebSupportedIOS =
    getIOSMajorVersion() >= 14 &&
    (window.isSafari || window.isFirefox || window.isIosWebView);
  // Android 10+ using Chrome or Samsung Browser v14+
  const isMobileWebSupportedAndroid =
    getAndroidMajorVersion() >= 10 &&
    (window.isChrome ||
      (window.isSamsungBrowser && getSamsungBrowserMajorVersion() >= 14) ||
      window.isAndroidWebView);

  window.isMobileWebSupported = !!(
    isMobileDevice &&
    (isMobileWebSupportedIOS || isMobileWebSupportedAndroid)
  );

  window.isBrowserSupported =
    !isMobileDevice && !window.isIE && !window.isOpera;
}
