/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-surprised': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1 8a7 7 0 1114 0A7 7 0 011 8zm7-8a8 8 0 100 16A8 8 0 008 0zM6 6a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2zm-5 4a2 2 0 114 0 2 2 0 01-4 0zm2-3a3 3 0 100 6 3 3 0 000-6z"/>'
  }
})
