<template>
  <div class="live-transcription-container">
    <div class="action-buttons">
      <div class="left-side-buttons">
        <vwc-button
          data-cy="pause-button"
          dense
          :icon="toggleTranscriptionButton.icon"
          :label="toggleTranscriptionButton.label"
          :connotation="toggleTranscriptionButton.connotation"
          :layout="toggleTranscriptionButton.layout"
          @click="toggleIsLiveTranscriptionPaused"
        />
      </div>
      <div class="right-side-buttons">
        <vwc-icon-button
          v-tooltip.bottom-end="
            $t('live_transcription_sidebar.download_button_tooltip')
          "
          icon="download-solid"
          :disabled="isLiveTranscriptionEmpty"
          @click="downloadTranscription"
        />
      </div>
    </div>
    <div class="separator"></div>
    <div
      ref="transcriptionContent"
      class="live-transcription-content"
      :class="{ 'align-center': isLiveTranscriptionEmpty }"
    >
      <vwc-empty-state
        v-if="isLiveTranscriptionEmpty"
        :heading="$t('live_transcription_sidebar.empty_state_title')"
        :body="$t('live_transcription_sidebar.empty_state_message')"
      >
        <img
          slot="graphic"
          alt="empty transcript"
          data-cy="empty-live-transcription-img"
          src="/illustrations/empty-state/empty-transcript.svg"
        />
      </vwc-empty-state>

      <ScrollableContent v-else>
        <TranscriptionBlockRow
          v-for="(transcriptBlock, index) in transcriptionBlocks"
          :key="`transcription-block-${index}`"
          class="transcription-block"
          :transcript="transcriptBlock"
        />
        <TranscriptionBlockRow
          v-for="(transcriptBlock, index) in dynamicTranscriptionBlocks"
          :key="`dynamic-block-${index}`"
          class="transcription-block"
          :transcript="transcriptBlock"
        />
      </ScrollableContent>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { downloadFile } from '@/helpers/global-helpers';
import TranscriptionBlockRow from '@/components/rows/TranscriptionBlockRow.vue';
import ScrollableContent from '@/components/ScrollableContent.vue';
import {
  shouldCreateNewCaptionsBlock,
  createCaptionBlock,
  formatTranscriptionBlocksText
} from '@/store/captions/captions-utils';

export default {
  name: 'LiveTranscription',

  components: {
    TranscriptionBlockRow,
    ScrollableContent
  },

  computed: {
    ...mapState(['participants']),
    ...mapState('captions', [
      'captionsHistory',
      'currentCaptionsList',
      'isLiveTranscriptionPaused'
    ]),
    ...mapGetters(['roomDisplayName']),

    transcriptionBlocks() {
      return this.captionsHistory.slice(0, this.captionsHistory.length - 1);
    },

    dynamicTranscriptionBlocks() {
      const dynamicTranscriptionBlocks = [];
      const lastHistoricalBlock = this.captionsHistory[
        this.captionsHistory.length - 1
      ];
      if (lastHistoricalBlock) {
        dynamicTranscriptionBlocks.push({
          ...lastHistoricalBlock,
          texts: [...lastHistoricalBlock.texts]
        });
      }
      this.currentCaptionsList.forEach((caption) => {
        if (!caption.saveToLiveTranscript) {
          return;
        }
        const lastBlock =
          dynamicTranscriptionBlocks[dynamicTranscriptionBlocks.length - 1];
        if (shouldCreateNewCaptionsBlock(lastBlock, caption)) {
          dynamicTranscriptionBlocks.push(createCaptionBlock(caption));
        } else {
          lastBlock.updatedTime = caption.updatedTime;
          lastBlock.texts.push({
            text: caption.text,
            isComplete: caption.isComplete
          });
        }
      });
      return dynamicTranscriptionBlocks;
    },

    isLiveTranscriptionEmpty() {
      return (
        this.transcriptionBlocks.length === 0 &&
        this.dynamicTranscriptionBlocks.length === 0
      );
    },

    toggleTranscriptionButton() {
      return this.isLiveTranscriptionPaused
        ? {
            label: this.$t('live_transcription_sidebar.resume_button'),
            icon: 'play-solid',
            connotation: 'success',
            layout: 'filled'
          }
        : {
            label: this.$t('live_transcription_sidebar.pause_button'),
            icon: 'pause-solid',
            connotation: 'alert',
            layout: 'outlined'
          };
    }
  },

  methods: {
    ...mapActions('captions', ['toggleIsLiveTranscriptionPaused']),

    downloadTranscription() {
      const transcription = formatTranscriptionBlocksText([
        ...this.transcriptionBlocks,
        ...this.dynamicTranscriptionBlocks
      ]);

      const fileType = 'text/plain';
      const fileName = `${this.roomDisplayName} Transcript`;
      const extension = '.txt';
      downloadFile(transcription, fileType, fileName, extension, false);
    }
  }
};
</script>

<style scoped>
.live-transcription-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 20px 20px 20px;
  overflow-y: auto;
}

.separator {
  border-top: 1px solid #bbbbbb;
  margin-top: 12px;
}

.live-transcription-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
}

.align-center {
  justify-content: center;
}

.transcription-block {
  margin-bottom: 16px;
}

.action-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
