var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "text-wrapper",
      on: {
        click: function ($event) {
          return _vm.$emit("copyToClipboard")
        },
      },
    },
    [
      _c("Linkify", {
        attrs: {
          halfOpacity: _vm.message.sentStatus === "PENDING",
          content: _vm.message.body,
          isRTL: _vm.isRTL,
          isElectron: _vm.isElectron,
        },
      }),
      _vm.isNotSent
        ? _c(
            "div",
            {
              staticClass: "message-not-sent-div",
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.$emit("resend")
                },
              },
            },
            [
              _c("v-icon", {
                staticClass: "retry-icon",
                class: {
                  "spinning-icon": _vm.message.sentStatus === "RESENDING",
                },
                attrs: { iconName: "Vlt-icon-refresh" },
              }),
              _c("span", { staticClass: "message-not-sent" }, [
                _vm._v(
                  _vm._s(_vm.$t("text_message_row.message_not_sent_text")) + " "
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }