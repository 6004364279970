/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-receptionist-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.96 0C6.14 0 1.4 4.74 1.4 10.56v3.84c0 1.06.86 1.92 1.92 1.92h1.92v-4.8c0-1.041-.83-1.884-1.864-1.914A8.621 8.621 0 0111.96 1.92c4.46 0 8.11 3.355 8.584 7.686a1.917 1.917 0 00-1.864 1.914v4.8h1.92c1.06 0 1.92-.86 1.92-1.92v-3.84C22.52 4.74 17.78 0 11.96 0zm6.72 18.24v.96c0 1.072-.848 1.92-1.92 1.92h-3.14a1.92 1.92 0 00-3.018-.398 1.92 1.92 0 103.02 2.318h3.138c2.11 0 3.84-1.73 3.84-3.84v-.96h-1.92z"/>'
  }
})
