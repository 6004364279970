/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-puzzle-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M20.571 10.286V3.429h-6.857A3.439 3.439 0 0010.286 0a3.439 3.439 0 00-3.429 3.429H0v6.857a3.439 3.439 0 013.429 3.428A3.439 3.439 0 010 17.143V24h6.857a3.439 3.439 0 013.429-3.429A3.439 3.439 0 0113.714 24h6.857v-6.857A3.439 3.439 0 0024 13.714a3.439 3.439 0 00-3.429-3.428z"/>'
  }
})
