/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-switch-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 4.5h-9C3.3 4.5 0 7.8 0 12s3.3 7.5 7.5 7.5h9c4.2 0 7.5-3.3 7.5-7.5s-3.3-7.5-7.5-7.5zm-9 12C4.95 16.5 3 14.55 3 12s1.95-4.5 4.5-4.5S12 9.45 12 12s-1.95 4.5-4.5 4.5z"/>'
  }
})
