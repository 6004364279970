var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "messages",
      class: {
        "vvd-scheme-alternate": !_vm.isMobileWebMode,
      },
      on: {
        paste: _vm.onFilePaste,
        dragenter: function ($event) {
          $event.stopPropagation()
          return _vm.enterDragZone($event)
        },
        dragleave: function ($event) {
          $event.stopPropagation()
          return _vm.leaveDragZone($event)
        },
        dragover: function ($event) {
          $event.preventDefault()
        },
        drop: function ($event) {
          $event.preventDefault()
          return _vm.getFilesFromDragAndDrop($event)
        },
      },
    },
    [
      _vm.isMobileWebMode && _vm.showImageView
        ? _c("MobileImageView", {
            staticClass: "mobile-image-view",
            attrs: { message: _vm.imageMessage },
            on: {
              closeImageView: function ($event) {
                return _vm.$router.back()
              },
            },
          })
        : _c(
            "div",
            { staticClass: "chat" },
            [
              _vm.isMobileWebMode
                ? _c(
                    "div",
                    {
                      staticClass: "mobile-messages-header unselectable",
                      class: { mobile: _vm.isMobileWebMode },
                      attrs: { "data-cy": "chat-header" },
                    },
                    [
                      _c("v-icon", {
                        staticClass:
                          "mobile-messages-header-icon sm unselectable",
                        attrs: {
                          "data-cy": "mobile-chat-back-btn",
                          "icon-name": "Vlt-icon-arrow-thin-left",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.$router.back()
                          },
                        },
                      }),
                      _c(
                        "div",
                        { staticClass: "mobile-messages-header-text" },
                        [
                          _c(
                            "span",
                            { staticClass: "mobile-messages-header-title" },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("chat.messages_header_title"))
                              ),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.isInDropZone
                ? _c(
                    "div",
                    {
                      staticClass: "drop-zone",
                      attrs: { "data-cy": "drop-zone" },
                    },
                    [
                      _c("img", {
                        staticClass: "drop-icon",
                        attrs: { src: "/img-drop-to-upload.svg" },
                      }),
                    ]
                  )
                : _vm._e(),
              _c("MessagesList", {
                staticClass: "messages-list",
                on: {
                  resend: _vm.resendMessage,
                  copyToClipboard: _vm.copyMessageToClipboard,
                  openImageView: _vm.openImageView,
                },
              }),
              _vm.hasMessagingError
                ? _c(
                    "div",
                    {
                      staticClass:
                        "messaging-unavailable-container unselectable",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "messaging-unavailable-row-container",
                          class: { clickable: _vm.failedReadingMessages },
                          on: { click: _vm.retryReadMessages },
                        },
                        [
                          _vm.failedReadingMessages
                            ? _c("v-icon", {
                                staticClass: "retry-icon",
                                attrs: { iconName: "Vlt-icon-refresh" },
                              })
                            : _vm._e(),
                          _c("span", { staticClass: "messaging-unavailable" }, [
                            _vm._v(
                              _vm._s(_vm.$t("chat.messaging_unavailable_text"))
                            ),
                            _vm.failedReadingMessages
                              ? _c("span", [
                                  _vm._v(
                                    ". " + _vm._s(_vm.$t("chat.retry_text"))
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "message-input",
                  attrs: { "data-cy": "message-input" },
                },
                [
                  _c("VTextArea", {
                    attrs: {
                      submitButton: _vm.isMobileWebMode,
                      disabled: _vm.isChatDisabled,
                      prependIcon: _vm.prependIcon,
                      placeholder: _vm.$t("chat.message_input_placeholder"),
                    },
                    on: {
                      submit: _vm.sendTextMessage,
                      prependIconClick: _vm.openFilesPicker,
                    },
                    model: {
                      value: _vm.messageText,
                      callback: function ($$v) {
                        _vm.messageText = $$v
                      },
                      expression: "messageText",
                    },
                  }),
                  _c("input", {
                    ref: "attachments",
                    staticClass: "hidden",
                    attrs: {
                      "data-cy": "input-message-attachment",
                      type: "file",
                      accept: "*",
                      multiple: "",
                    },
                    on: {
                      click: _vm.inputFileClicked,
                      change: _vm.sendFilesFromInput,
                    },
                  }),
                ],
                1
              ),
              _vm.attachments.length > 0
                ? _c("UploadAttachmentsModal", {
                    attrs: { files: _vm.attachments },
                    on: {
                      removeFile: _vm.removeAttachment,
                      hide: _vm.clearAttachments,
                      send: _vm.sendAttachments,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }