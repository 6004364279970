/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-checkbox-checked-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 0H3C1.35 0 0 1.35 0 3v18c0 1.65 1.35 3 3 3h18c1.65 0 3-1.35 3-3V3c0-1.65-1.35-3-3-3zM10.5 17.1L5.4 12l2.1-2.1 3 3 6-6L18.6 9l-8.1 8.1z"/>'
  }
})
