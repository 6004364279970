import DomainConfigTypeEnum from '@/consts/domains-configuration-enum';

let domainConfiguration;
const envDomainConfig = process.env.VUE_APP_DOMAIN_CONFIG;
const domainConfigMode = getDomainEnumValueFromEnvVar(envDomainConfig);
const domainConfigs = {
  [DomainConfigTypeEnum.VONAGE]: require('/domains/vonage.json'),
  [DomainConfigTypeEnum.CSP]: require('/domains/csp.json')
};
const defaultDomainConfig = {
  entranceScreenTitle: 'title',
  entranceScreenLogoPath: '/entrance-screen/logo.png',
  entranceScreenImageSrc: '/entrance-screen/img-entrance-1.jpg',
  joinMeetingFromLinkScreenCheckboxTitle: 'checkbox',
  joinMeetingFromLinkScreenImgPath: '/img-join-from-link-screen.svg',
  isCsp: false,
  deeplinkProtocolPrefix: 'vbc-meetings'
};

domainConfiguration = domainConfigs[domainConfigMode] ?? defaultDomainConfig;

function getDomainEnumValueFromEnvVar(envVar) {
  return (
    Object.values(DomainConfigTypeEnum).find((element) => element === envVar) ??
    DomainConfigTypeEnum.VONAGE
  );
}

export default domainConfiguration;
