/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-9': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM15 3a3 3 0 116 0 3 3 0 01-6 0zM4.5 13.5a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm1.5-3a3 3 0 100 6 3 3 0 000-6zM2.598 20.598A3.75 3.75 0 015.25 19.5h1.5a3.75 3.75 0 013.674 3H1.576a3.75 3.75 0 011.022-1.902zM5.25 18A5.25 5.25 0 000 23.25a.75.75 0 00.75.75h10.5a.75.75 0 00.75-.75A5.25 5.25 0 006.75 18h-1.5zm9.348-7.902A3.75 3.75 0 0117.25 9h1.5a3.75 3.75 0 013.674 3h-8.848a3.75 3.75 0 011.022-1.902zM17.25 7.5A5.25 5.25 0 0012 12.75c0 .414.336.75.75.75h10.5a.75.75 0 00.75-.75 5.25 5.25 0 00-5.25-5.25h-1.5z"/>'
  }
})
