/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-python': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.747 0c.982 0 2.072.109 3.053.218 1.526.218 2.835 1.417 2.835 2.944v5.45c0 1.636-1.2 2.945-2.835 2.945H9.022c-1.963 0-3.598 1.635-3.598 3.488v2.508H3.462c-1.636 0-2.617-1.09-3.053-2.835-.545-2.29-.545-3.598 0-5.778.436-1.962 1.962-2.944 3.598-2.944h7.958v-.763H6.296V3.162c0-1.636.327-2.508 2.726-2.944C9.894.109 10.766 0 11.747 0zM7.604 2.835c0 .654.546 1.09 1.09 1.09.546 0 1.09-.436 1.09-1.09 0-.655-.435-1.09-1.09-1.09-.653 0-1.09.435-1.09 1.09zm12.974 3.27c1.636 0 2.399 1.309 2.835 2.944.545 2.29.545 3.925-.109 5.778-.545 1.745-1.2 2.835-2.835 2.835h-8.613v.763h5.67v2.18c0 1.636-1.418 2.508-2.835 2.944-2.18.654-3.816.546-5.67 0-1.526-.436-2.834-1.417-2.834-2.943v-5.452c0-1.635 1.308-3.052 2.835-3.052h5.887c1.853 0 3.598-1.527 3.598-3.489V6.105h2.071zm-4.36 14.937c0-.654-.546-1.09-1.09-1.09-.546 0-1.091.436-1.091 1.09 0 .654.545 1.09 1.09 1.09.654 0 1.09-.436 1.09-1.09z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
