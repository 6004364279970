/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-4': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3 2c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zM1 3c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2zM2.5 7c-.2 0-.4 0-.6.1-.2.1-.3.2-.5.3-.1.2-.2.3-.3.5-.1.2-.1.4-.1.6V14h3v-3.5c0-.3.2-.5.5-.5s.5.2.5.5v4c0 .3-.2.5-.5.5h-4c-.3 0-.5-.2-.5-.5v-6c0-.3.1-.7.2-1 .1-.3.3-.5.5-.8.3-.2.5-.4.8-.5.3-.1.7-.2 1-.2 1.1 0 1.8.5 2.4 1.2.2.2.3.4.5.5.5.6 1 1.2 1.9 1.8.2.2.3.5.1.7-.2.2-.5.3-.7.1-.9-.7-1.5-1.4-2-2-.2-.1-.4-.3-.5-.4-.6-.6-1-.9-1.7-.9zM13.5 6c.3 0 .7.1 1 .2.3.1.6.3.8.5.2.2.4.5.5.8.1.3.2.6.2 1v6c0 .3-.2.5-.5.5h-4c-.3 0-.5-.2-.5-.5v-4c0-.3.2-.5.5-.5s.5.2.5.5V14h3V8.5c0-.2 0-.4-.1-.6-.1-.2-.2-.3-.3-.5-.1-.1-.3-.2-.5-.3-.2-.1-.4-.1-.6-.1-.7 0-1.1.3-1.7.9-.1.1-.3.3-.4.5-.5.6-1.1 1.3-2 2-.2.2-.5.1-.7-.1-.2-.2-.1-.5.1-.7.8-.7 1.4-1.3 1.9-1.8.2-.2.3-.4.5-.5.5-.8 1.2-1.3 2.3-1.3zM13 2c-.6 0-1 .4-1 1s.4 1 1 1 1-.4 1-1-.4-1-1-1zm-2 1c0-1.1.9-2 2-2s2 .9 2 2-.9 2-2 2-2-.9-2-2z"/>'
  }
})
