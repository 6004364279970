/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-transfer': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.291 1.046A.5.5 0 008 1.5v3.541c-1.8.062-3.712.475-5.223 1.642C1.099 7.978 0 10.133 0 13.5a.5.5 0 00.925.263C2.662 10.95 5.271 10.097 8 10.008V13.5a.5.5 0 00.825.38l7-6a.5.5 0 000-.76l-7-6a.5.5 0 00-.534-.074zm6.44 6.454L9 12.413V9.5a.5.5 0 00-.5-.5c-2.623 0-5.35.607-7.396 2.862.29-2.158 1.166-3.524 2.284-4.387C4.79 6.392 6.66 6.033 8.5 6.033a.5.5 0 00.5-.5V2.587L14.732 7.5z"/>'
  }
})
