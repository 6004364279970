/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cloud-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.65 19.5c2.4 0 4.35-1.95 4.35-4.35 0-2.4-1.95-4.35-4.35-4.35-.3-4.35-3.9-7.8-8.25-7.8s-7.95 3.45-8.1 7.8c-1.95.6-3.3 2.25-3.3 4.35 0 2.4 1.95 4.35 4.35 4.35h15.3z"/>'
  }
})
