/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-inbound': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.833.975a.571.571 0 10-.809-.808L1.143 14.05v-6.62a.571.571 0 00-1.143 0v8A.57.57 0 00.571 16h8.001a.571.571 0 100-1.143H1.95L15.833.975z"/>'
  }
})
