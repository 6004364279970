/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-preferences-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8 12H4V8H0V4h4V0h4v12zm2-8h14v4H10V4zm10 8h-4v12h4v-4h4v-4h-4v-4zM0 16h14v4H0v-4z"/>'
  }
})
