var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "participant-row",
      class: { "my-participant-row": _vm.isMyParticipantRow },
      attrs: { "data-cy": "participant-row" },
      on: {
        mouseleave: _vm.hideVolumeBarSlider,
        mouseenter: _vm.showVolumeBarSlider,
      },
    },
    [
      _c(
        "div",
        { staticClass: "participant-info" },
        [
          _vm.participant.type === "Pstn"
            ? _c("Avatar", {
                key: "1",
                attrs: { size: "sm", iconName: "call-solid" },
              })
            : _vm.participant.type === "Guest"
            ? _c("Avatar", {
                key: "2",
                attrs: {
                  size: "sm",
                  isBeRightBackOn: _vm.isBeRightBackOnStatus,
                  iconName: "user-solid",
                },
              })
            : _c("Avatar", {
                key: "3",
                attrs: {
                  size: "sm",
                  displayName: _vm.participant.displayName,
                  image: _vm.participant.profilePicture,
                  isBeRightBackOn: _vm.isBeRightBackOnStatus,
                },
              }),
          _c("div", { staticClass: "participant-data" }, [
            _c("div", { staticClass: "participant-name-row" }, [
              _c("div", { staticClass: "participant-name text-ellipsis" }, [
                _vm._v(" " + _vm._s(_vm.participantDisplayName) + " "),
              ]),
            ]),
            !_vm.isActiveParticipant
              ? _c(
                  "div",
                  {
                    staticClass: "participant-state",
                    class: {
                      "Vlt-green":
                        _vm.participant.state ===
                        _vm.PARTICIPANT_STATE_TYPES.INVITED,
                      "Vlt-grey-dark":
                        _vm.participant.state ===
                          _vm.PARTICIPANT_STATE_TYPES.LEFT ||
                        _vm.participant.state ===
                          _vm.PARTICIPANT_STATE_TYPES.KICKED,
                      "Vlt-red":
                        _vm.participant.state ===
                        _vm.PARTICIPANT_STATE_TYPES.DECLINED,
                    },
                  },
                  [_vm._v(" " + _vm._s(_vm.participantStateText) + " ")]
                )
              : _vm._e(),
            _vm.isBeRightBackOnStatus &&
            _vm.owner !== _vm.participant.participantId &&
            !_vm.isMyParticipantRow
              ? _c(
                  "div",
                  { staticClass: "participant-state Vlt-grey-darker" },
                  [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("participant_row.be_right_back_text")) +
                        " "
                    ),
                  ]
                )
              : _vm._e(),
            _c("div", { staticClass: "participant-state Vlt-grey-darker" }, [
              _vm.owner === _vm.participant.participantId
                ? _c(
                    "span",
                    { staticClass: "participant-state Vlt-grey-darker" },
                    [
                      _vm.isMyParticipantRow
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.$t("participant_row.is_me_text")) +
                                ", "
                            ),
                          ])
                        : _vm._e(),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("participant_row.is_owner_text")) + " "
                        ),
                      ]),
                      _vm.isBeRightBackOnStatus
                        ? _c("span", { staticClass: "Vlt-grey-darker" }, [
                            _vm._v(
                              " · " +
                                _vm._s(
                                  _vm.$t("participant_row.be_right_back_text")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm.isMyParticipantRow
                ? _c(
                    "span",
                    { staticClass: "participant-state Vlt-grey-darker" },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("participant_row.is_me_text")) + " "
                      ),
                      _vm.isBeRightBackOnStatus
                        ? _c("span", { staticClass: "Vlt-grey-darker" }, [
                            _vm._v(
                              " · " +
                                _vm._s(
                                  _vm.$t("participant_row.be_right_back_text")
                                ) +
                                " "
                            ),
                          ])
                        : _vm._e(),
                    ]
                  )
                : _vm._e(),
              _vm.isRecordingOwner
                ? _c("span", { staticClass: "Vlt-red recording" }, [
                    _vm._v(
                      _vm._s(_vm.$t("participant_row.is_recording_owner_text"))
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.isActiveParticipant
            ? _c(
                "div",
                { staticClass: "info-icons" },
                [
                  _vm.participantVideoStream &&
                  _vm.participantVideoStream.badQuality
                    ? _c("vwc-icon", {
                        attrs: {
                          type: "wifi-line",
                          connotation: "alert",
                          size: "small",
                        },
                      })
                    : _vm._e(),
                  _vm.isParticipantSharingScreen
                    ? _c("vwc-icon", {
                        staticClass: "participant-row-icon share-screen",
                        attrs: {
                          type: "screen-share-line",
                          connotation: "primary",
                          size: "small",
                        },
                      })
                    : _vm._e(),
                  _vm.isParticipantRaisingHand
                    ? _c(
                        "div",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top",
                              value: _vm.raisedHandTooltip,
                              expression: "raisedHandTooltip",
                              modifiers: { top: true },
                            },
                          ],
                          class: {
                            "removable-hand":
                              _vm.isMyParticipantRow || _vm.isSessionOwner,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleRaiseHand(
                                _vm.participant.participantId
                              )
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "raise-hand",
                            attrs: { src: "/raise_hand_img.svg" },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _vm.participant.type === "Pstn"
                    ? _c("vwc-icon", {
                        staticClass: "participant-row-icon",
                        attrs: {
                          type: "call-line",
                          connotation: "primary",
                          size: "small",
                        },
                      })
                    : _vm.isMyParticipantRow
                    ? _c("vwc-icon-button", {
                        staticClass: "participant-row-icon camera-btn",
                        attrs: {
                          icon: _vm.isParticipantMutedVideo
                            ? "video-off-solid"
                            : "video-solid",
                          connotation: "primary",
                          layout: "ghost",
                          dense: "",
                          disabled: _vm.isInitializingPublisher,
                        },
                        on: {
                          click: function () {
                            return _vm.toggleVideo(
                              _vm.TOGGLE_CAMERA_SOURCES.PARTICIPANTS_TAB
                            )
                          },
                        },
                      })
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "observe-visibility",
                          rawName: "v-observe-visibility",
                          value: {
                            callback: _vm.microphoneVisibilityChanged,
                            throttle: 1000,
                            throttleOptions: {
                              leading: "visible",
                            },
                          },
                          expression:
                            "{\n          callback: microphoneVisibilityChanged,\n          throttle: 1000,\n          throttleOptions: {\n            leading: 'visible'\n          }\n        }",
                        },
                      ],
                      staticClass: "mic-wrapper",
                    },
                    [
                      !_vm.isParticipantMutedAudio && _vm.isMicrophoneVisible
                        ? _c("MicVolumeIndication", {
                            staticClass: "volume-indication",
                            attrs: {
                              volume: _vm.micVolume,
                              maxHeight: 10,
                              color: _vm.micIndicationFillColor,
                            },
                          })
                        : _vm._e(),
                      !_vm.isMyParticipantRow
                        ? _c("vwc-icon", {
                            staticClass: "mic-icon",
                            attrs: {
                              type: _vm.isParticipantMutedAudio
                                ? "mic-mute-line"
                                : "microphone-line",
                              connotation: "primary",
                              size: "small",
                            },
                          })
                        : _c("vwc-icon-button", {
                            staticClass: "participant-row-icon mic-btn",
                            attrs: {
                              icon: _vm.isParticipantMutedAudio
                                ? "mic-mute-solid"
                                : "microphone-2-solid",
                              connotation: "primary",
                              layout: "ghost",
                              dense: "",
                              disabled:
                                _vm.isInitializingPublisher ||
                                _vm.isNoAudioMode,
                            },
                            on: {
                              click: function () {
                                return _vm.toggleMic(
                                  _vm.TOGGLE_MIC_SOURCES.PARTICIPANTS_TAB
                                )
                              },
                            },
                          }),
                    ],
                    1
                  ),
                  _c("MoreOptionsMenu", {
                    attrs: { "more-options-lists": _vm.moreOptionsMenuLists },
                    on: { optionSelected: _vm.dropdownOptionSelected },
                  }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      false &&
      _vm.exclusiveExperimentalMode &&
      !_vm.isMyParticipantRow &&
      _vm.isActiveParticipant &&
      !_vm.isParticipantMutedAudio
        ? _c(
            "div",
            { class: _vm.volumeBarClass },
            [
              _c("VueSlider", {
                staticClass: "volume-bar",
                attrs: {
                  tooltip: "hover",
                  dotSize: "12",
                  "use-keyboard": false,
                  marks: { 0: "0", 50: "50", 100: "100" },
                },
                on: { change: _vm.setParticipantSubscriberAudioVolume },
                model: {
                  value: _vm.participantSubscriberAudioVolume,
                  callback: function ($$v) {
                    _vm.participantSubscriberAudioVolume = $$v
                  },
                  expression: "participantSubscriberAudioVolume",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }