// *************** FULLSCREEN API ****************/
export function initPolyfills() {
  // Use JavaScript strict mode
  'use strict';

  // eslint-disable-next-line no-redeclare
  /* global Element, Promise */

  var pollute = true,
    api,
    vendor,
    apis = {
      // http://dvcs.w3.org/hg/fullscreen/raw-file/tip/Overview.html
      w3: {
        enabled: 'fullscreenEnabled',
        element: 'fullscreenElement',
        request: 'requestFullscreen',
        exit: 'exitFullscreen',
        events: {
          change: 'fullscreenchange',
          error: 'fullscreenerror'
        }
      },
      webkit: {
        enabled: 'webkitFullscreenEnabled',
        element: 'webkitCurrentFullScreenElement',
        request: 'webkitRequestFullscreen',
        exit: 'webkitExitFullscreen',
        events: {
          change: 'webkitfullscreenchange',
          error: 'webkitfullscreenerror'
        }
      },
      moz: {
        enabled: 'mozFullScreenEnabled',
        element: 'mozFullScreenElement',
        request: 'mozRequestFullScreen',
        exit: 'mozCancelFullScreen',
        events: {
          change: 'mozfullscreenchange',
          error: 'mozfullscreenerror'
        }
      },
      ms: {
        enabled: 'msFullscreenEnabled',
        element: 'msFullscreenElement',
        request: 'msRequestFullscreen',
        exit: 'msExitFullscreen',
        events: {
          change: 'MSFullscreenChange',
          error: 'MSFullscreenError'
        }
      }
    },
    w3 = apis.w3;

  // Loop through each vendor's specific API
  for (vendor in apis) {
    // Check if document has the "enabled" property
    if (apis[vendor].enabled in document) {
      // It seems this browser support the fullscreen API
      api = apis[vendor];
      break;
    }
  }

  function dispatch(type, target) {
    var event = document.createEvent('Event');

    event.initEvent(type, true, false);
    target.dispatchEvent(event);
  } // end of dispatch()

  function handleChange(e) {
    e.stopPropagation();
    e.stopImmediatePropagation();

    // Recopy the enabled and element values
    document[w3.enabled] = document[api.enabled];
    document[w3.element] = document[api.element];

    dispatch(w3.events.change, e.target);
  } // end of handleChange()

  function handleError(e) {
    dispatch(w3.events.error, e.target);
  } // end of handleError()

  // Prepare a resolver to use for the requestFullscreen and exitFullscreen's promises
  // Use a closure since we need to check which method was used
  function createResolver(method) {
    return function resolver(resolve, reject) {
      // Reject the promise if asked to exitFullscreen and there is no element currently in fullscreen
      if (method === w3.exit && !document[api.element]) {
        setTimeout(function () {
          reject(new TypeError());
        }, 1);
        return;
      }

      // When receiving an internal fullscreenchange event, fulfill the promise
      function change() {
        resolve();
        document.removeEventListener(api.events.change, change, false);
      }

      // When receiving an internal fullscreenerror event, reject the promise
      function error() {
        reject(new TypeError());
        document.removeEventListener(api.events.error, error, false);
      }

      document.addEventListener(api.events.change, change, false);
      document.addEventListener(api.events.error, error, false);
    };
  }

  // Pollute only if the API doesn't already exists
  if (pollute && !(w3.enabled in document) && api) {
    // Add listeners for fullscreen events
    document.addEventListener(api.events.change, handleChange, false);
    document.addEventListener(api.events.error, handleError, false);

    // Copy the default value
    document[w3.enabled] = document[api.enabled];
    document[w3.element] = document[api.element];

    // Match the reference for exitFullscreen
    document[w3.exit] = function () {
      var result = document[api.exit]();
      return !result && window.Promise
        ? new Promise(createResolver(w3.exit))
        : result;
    };

    // Add the request method to the Element's prototype
    Element.prototype[w3.request] = function () {
      var result = this[api.request].apply(this, arguments);
      return !result && window.Promise
        ? new Promise(createResolver(w3.request))
        : result;
    };
  }

  // Return the API found (or undefined if the Fullscreen API is unavailable)
  return api;
}
