/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-pin': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4.464 2.464A5 5 0 0113 6c0 2.075-1.283 4.336-2.648 6.135A25.36 25.36 0 018 14.808a25.352 25.352 0 01-2.352-2.673C4.283 10.335 3 8.075 3 6a5 5 0 011.464-3.536zm3.2 13.406L8 15.5l-.336.37a.5.5 0 00.672 0L8 15.5l.337.37.001-.002.004-.003.013-.012.049-.045.18-.172a26.37 26.37 0 002.564-2.896C12.533 10.914 14 8.425 14 6A6 6 0 102 6c0 2.425 1.467 4.914 2.852 6.74a26.37 26.37 0 002.744 3.068l.05.045.012.012.004.003.001.001zM6 6a2 2 0 114 0 2 2 0 01-4 0zm2-3a3 3 0 100 6 3 3 0 000-6z"/>'
  }
})
