/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-outbound': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.957.353A.568.568 0 0015.429 0h-8a.571.571 0 000 1.143h6.62L.167 15.024a.571.571 0 10.808.809L14.857 1.95v6.62a.571.571 0 001.143 0V.572.567a.57.57 0 00-.043-.215z"/>'
  }
})
