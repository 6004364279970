/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-screen-share': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M11.5 15h-7c-.3 0-.5.2-.5.5s.2.5.5.5h7c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM6.3 9.9c0 .1.1.1.2.1s.2 0 .3-.1l5-3c.1-.1.2-.2.2-.4s-.1-.3-.2-.4l-5-3c-.2-.1-.4-.1-.5 0-.2.1-.3.2-.3.4v6c0 .2.1.3.3.4zM7 4.4l3.5 2.1L7 8.6V4.4z"/><path pid="1" d="M15.5 0H.5C.2 0 0 .2 0 .5v12c0 .3.2.5.5.5h15c.3 0 .5-.2.5-.5V.5c0-.3-.2-.5-.5-.5zM15 12H1V1h14v11z"/>'
  }
})
