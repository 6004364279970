var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "frameless-titlebar unselectable",
      class: [
        _vm.titlebarPlatform === _vm.OS_TYPES.WINDOWS ? "windows" : "mac",
        _vm.black ? "black" : "white",
      ],
      style: _vm.titlebarStyle,
    },
    [
      _vm.titlebarPlatform === _vm.OS_TYPES.WINDOWS
        ? _c("div", { staticClass: "buttons-container" }, [
            _vm.showMinimizeButton
              ? _c(
                  "button",
                  {
                    staticClass: "titlebar-btn minimize-btn",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("minimize")
                      },
                    },
                  },
                  [_c("div", { staticClass: "icon" })]
                )
              : _vm._e(),
            _vm.showMaximizeButton
              ? _c(
                  "button",
                  {
                    staticClass: "titlebar-btn",
                    class: {
                      "restore-btn": _vm.isMaximized,
                      "maximize-btn": !_vm.isMaximized,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("maximize")
                      },
                    },
                  },
                  [_c("div", { staticClass: "icon" })]
                )
              : _vm._e(),
            _vm.showCloseButton
              ? _c(
                  "button",
                  {
                    staticClass: "titlebar-btn close-btn",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("close")
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "icon right" }),
                    _c("div", { staticClass: "icon left" }),
                  ]
                )
              : _vm._e(),
          ])
        : _c(
            "div",
            {
              staticClass: "buttons-container",
              on: {
                mouseenter: function ($event) {
                  _vm.showIcons = true
                },
                mouseleave: function ($event) {
                  _vm.showIcons = false
                },
              },
            },
            [
              _vm.showMaximizeButton
                ? _c(
                    "div",
                    {
                      staticClass: "titlebar-btn maximize-btn",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("maximize")
                        },
                      },
                    },
                    [
                      _vm.showIcons
                        ? _c("v-icon", {
                            staticClass: "icon",
                            attrs: { iconName: "fullscreen-mac-icon" },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showMinimizeButton
                ? _c(
                    "div",
                    {
                      staticClass: "titlebar-btn minimize-btn",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("minimize")
                        },
                      },
                    },
                    [
                      _vm.showIcons
                        ? _c("v-icon", {
                            staticClass: "icon",
                            attrs: { iconName: "Vlt-icon-minus-bold" },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showCloseButton
                ? _c(
                    "div",
                    {
                      staticClass: "titlebar-btn close-btn",
                      on: {
                        click: function ($event) {
                          return _vm.$emit("close")
                        },
                      },
                    },
                    [
                      _vm.showIcons
                        ? _c("v-icon", {
                            staticClass: "icon",
                            attrs: { iconName: "Vlt-icon-cross-full" },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }