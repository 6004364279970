const VIDEO_URL_ERROR_REASON = Object.freeze({
  UNKNOWN: 0,
  UNSUPPORTED_SITE: 1,
  VIDEO_NOT_EMBEDDABLE: 2,
  LIVE_VIDEO: 3
});

class VideoUrlValidationError extends Error {
  constructor(url, errorReason) {
    super(`Error parsing video URL: ${url}, reason ID: ${errorReason}`);
    this.url = url;
    this.errorReason = errorReason;
  }
}

export { VideoUrlValidationError, VIDEO_URL_ERROR_REASON };
