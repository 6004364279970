var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Dialog",
    {
      staticClass: "invite-participants-container",
      attrs: {
        "data-cy": "invite-participants-dialog",
        heading: _vm.$t("invite_participants_modal.title"),
        "min-width": 435,
        "icon-name": "add-user-line",
        close: _vm.closeModal,
        applied: _vm.invite,
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "invite-participants-content",
          attrs: { slot: "content" },
          slot: "content",
        },
        [
          _c("Contacts", {
            staticClass: "contacts",
            model: {
              value: _vm.selectedContacts,
              callback: function ($$v) {
                _vm.selectedContacts = $$v
              },
              expression: "selectedContacts",
            },
          }),
        ],
        1
      ),
      _c(
        "template",
        { slot: "footer-buttons" },
        [
          _c("vwc-button", {
            attrs: {
              slot: "secondaryAction",
              layout: "outlined",
              icon: "link-solid",
              label: _vm.$t("invite_participants_modal.copy_link_button"),
            },
            on: { click: _vm.copyLinkToClipboard },
            slot: "secondaryAction",
          }),
          _c("vwc-button", {
            attrs: {
              slot: "primaryAction",
              "data-cy": "invite-button",
              connotation: "cta",
              layout: "filled",
              disabled: !_vm.selectedContacts.length,
              icon: "message-sent-solid",
              label: _vm.$t("invite_participants_modal.invite_button"),
            },
            on: { click: _vm.invite },
            slot: "primaryAction",
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }