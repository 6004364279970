/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-keypad-solid': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8 12.667c-.733 0-1.333.6-1.333 1.333s.6 1.333 1.333 1.333 1.333-.6 1.333-1.333-.6-1.333-1.333-1.333zm-4-12c-.733 0-1.333.6-1.333 1.333S3.267 3.333 4 3.333 5.333 2.733 5.333 2 4.733.667 4 .667zm0 4c-.733 0-1.333.6-1.333 1.333S3.267 7.333 4 7.333 5.333 6.733 5.333 6 4.733 4.667 4 4.667zm0 4c-.733 0-1.333.6-1.333 1.333s.6 1.333 1.333 1.333 1.333-.6 1.333-1.333S4.733 8.667 4 8.667zm8-5.334c.733 0 1.333-.6 1.333-1.333S12.733.667 12 .667s-1.333.6-1.333 1.333.6 1.333 1.333 1.333zM8 8.667c-.733 0-1.333.6-1.333 1.333s.6 1.333 1.333 1.333 1.333-.6 1.333-1.333S8.733 8.667 8 8.667zm4 0c-.733 0-1.333.6-1.333 1.333s.6 1.333 1.333 1.333 1.333-.6 1.333-1.333-.6-1.333-1.333-1.333zm0-4c-.733 0-1.333.6-1.333 1.333s.6 1.333 1.333 1.333 1.333-.6 1.333-1.333-.6-1.333-1.333-1.333zm-4 0c-.733 0-1.333.6-1.333 1.333S7.267 7.333 8 7.333 9.333 6.733 9.333 6 8.733 4.667 8 4.667zm0-4c-.733 0-1.333.6-1.333 1.333S7.267 3.333 8 3.333 9.333 2.733 9.333 2 8.733.667 8 .667z"/>'
  }
})
