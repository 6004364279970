/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-broadcast': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12.8 0c-.5 0-.8.3-.8.8 0 .4.3.8.8.8 2.6 0 5.1 1 6.9 2.9 1.8 1.8 2.9 4.3 2.9 6.9 0 .4.3.8.8.8s.8-.3.8-.8c0-3-1.2-5.8-3.3-8C18.6 1.2 15.7 0 12.8 0zm0 4.5c-.4 0-.8.3-.8.8s.3.7.8.7c1.4 0 2.7.6 3.7 1.5 1 1 1.5 2.3 1.5 3.7 0 .4.3.8.8.8s.8-.3.8-.8c0-1.8-.7-3.5-2-4.8-1.3-1.2-3.1-1.9-4.8-1.9zm.5 6.2c.3.3.3.8 0 1.1l-1 1 6.9 6.9c.3.3.3.8 0 1.1-2.1 2.1-5 3.3-8 3.3s-5.8-1.2-8-3.3c-2.1-2.1-3.3-5-3.3-8s1.2-5.8 3.3-8c.1-.1.3-.2.5-.2s.4.1.5.2l6.9 6.9 1-1c.4-.3.9-.3 1.2 0zm-2.6 2.6L3.8 6.4c-1.5 1.8-2.3 4-2.3 6.3 0 2.6 1 5.1 2.9 6.9s4.3 2.9 6.9 2.9c2.3 0 4.6-.8 6.3-2.3l-6.9-6.9z"/>'
  }
})
