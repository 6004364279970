/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-code': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M10.7 1c.3.1.4.4.3.6l-5 13c-.1.3-.4.4-.6.3-.3-.1-.4-.4-.3-.6l5-13c0-.2.3-.4.6-.3zM3.9 4.1c.1.2.1.6 0 .8L1.2 7.5l2.6 2.6c.2.2.2.5 0 .7-.2.2-.5.2-.7 0l-3-3c-.1-.1-.1-.5 0-.7l3-3c.2-.1.6-.1.8 0zm9 0c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l2.6 2.6-2.6 2.6c-.2.2-.2.5 0 .7.2.2.5.2.7 0l3-3c.2-.2.2-.5 0-.7l-3-2.9z"/>'
  }
})
