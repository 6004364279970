/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-minimize-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.1 8.6H.5c-.3-.1-.5.2-.5.5s.2.5.5.5h5.1L1.2 14c-.2.2-.2.5 0 .8.2.2.6.2.8 0l4.4-4.4v5.1c0 .3.2.5.5.5s.5-.2.5-.5V9.1v-.2c0-.2-.1-.3-.3-.3zM15.5 6.4h-5.1L14.8 2c.2-.2.2-.5 0-.8-.2-.2-.6-.2-.8 0L9.6 5.6V.5c0-.3-.2-.5-.5-.5s-.6.2-.6.5V7.1l.3.3H15.4c.3 0 .5-.2.5-.5s-.1-.5-.4-.5z"/>'
  }
})
