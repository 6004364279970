import { vbcApiGw, setDefaultErrorHandler } from '@/apis/vbc-gw';
import { USER_TYPE } from '@/consts/user-type';

let pimTypeUrlPart;

setDefaultErrorHandler((error) => {
  // Remove message content from API errors so it does not get logged.
  if (
    error?.config?.method === 'post' &&
    error?.config?.url?.indexOf('/messages') !== -1
  ) {
    try {
      const data = JSON.parse(error?.config?.data);
      data.body = '***';
      error.config.data = JSON.stringify(data);
    } catch (e) {
      // Do Nothing
    }
  }

  return Promise.reject(error);
});

export function init(domain = USER_TYPE.VBC) {
  pimTypeUrlPart = domain === USER_TYPE.VBC ? 'messages' : 'messages-vcp';
}

export function readMessages(externalId, timestamp, pageSize) {
  return vbcApiGw.get(
    `/${pimTypeUrlPart}/8/users/${externalId}/messages?type=GRP_SMS&type=GRP_MMS`,
    {
      params: {
        pageSize,
        newerTime: timestamp, // 'newerTime'/'olderTime' to select the direction of the requested page
        pageOrder: 'OLDEST_FIRST', // if there is more than one pages, defines the order of the pages requested
        sortOrder: 'OLDEST_FIRST' // if there is more than one message, defines the order of the messages in the page
      }
    }
  );
}

export async function sendMessage(externalId, payload, retries = 0) {
  const response = await vbcApiGw.post(
    `/${pimTypeUrlPart}/7/users/${externalId}/messages`,
    payload,
    {
      'axios-retry': { retries }
    }
  );

  // Sometimes, the API GW returns 200 OK with empty body, without passing the request to PiMessaging,
  // If we take this empty body and treat it as a message, it causes many different issues.
  // This piece of code protectes us from this scenario by causing the ui to show "please retry".
  if (!response) {
    throw new Error('APIGW returns empty body');
  }

  return response;
}

export function getS3TokenForUpload(externalId, fileName) {
  return vbcApiGw.get(
    `/${pimTypeUrlPart}/8/users/${externalId}/messages/_/attachments`,
    {
      params: {
        fileName,
        token: false
      }
    }
  );
}

export function getS3TokenForDownload(externalId, messageId, fileName) {
  const url = `/${pimTypeUrlPart}/8/users/${externalId}/messages/${messageId}/attachments`;
  return vbcApiGw.get(url, {
    params: {
      fileName,
      token: false
    }
  });
}
