/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-books': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M5.5 1h-5c-.3 0-.5.2-.5.5v13c0 .3.2.5.5.5h5c.3 0 .5-.2.5-.5v-13c0-.3-.2-.5-.5-.5zM5 14H1v-2h4v2zm0-3H1V2h4v9zM16 13.4L12.4.9c-.1-.3-.4-.4-.6-.3L7 2c-.3 0-.5.3-.4.6l3.6 12.5c.1.3.4.4.6.3l4.8-1.4c.3 0 .5-.3.4-.6zm-5.8-2L7.7 2.8l3.8-1.1 2.5 8.6-3.8 1.1zm.8 2.9l-.6-1.9 3.8-1.1.6 1.9-3.8 1.1z"/>'
  }
})
