/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-add-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.5 13.5h-3V9H6V6h4.5V1.5h3V6H18v3h-4.5v4.5zm7.5 6v-6h3V21a1.5 1.5 0 01-1.5 1.5h-21A1.5 1.5 0 010 21v-7.5h3v6h18z"/>'
  }
})
