import MeetingManager from '@/services/meeting-manager-service';
import SoundsPlayer from '@/services/sounds-player';
import analytics from '@/services/analytics-service';
import { ANALYTICS, SIDEBARS } from '@/consts/global-consts';
import { APP_SIGNALS } from '@/consts/global-consts';

const _randomParticipant = (pastSpeakers, allParticipants) => {
  const availableParticipants = allParticipants.filter(
    (participant) => !pastSpeakers.includes(participant.participantId)
  );

  if (availableParticipants.length == 0) {
    return '';
  }

  return availableParticipants[
    Math.floor(Math.random() * availableParticipants.length)
  ].participantId;
};

const _moveToNextSpeaker = (pastSpeakers, currentSpeaker, allParticipants) => {
  const updatedPastSpeakers = pastSpeakers?.slice() || [];
  if (currentSpeaker) {
    updatedPastSpeakers.push(currentSpeaker);
  }

  const nextSpeaker = _randomParticipant(updatedPastSpeakers, allParticipants);

  const data = {
    isStandupOn: true,
    pastSpeakers: updatedPastSpeakers,
    currentSpeaker: nextSpeaker
  };

  return data;
};

export default {
  init: ({ dispatch }) => {
    MeetingManager.onSignal(APP_SIGNALS.STANDUP, (event) => {
      dispatch('updateStandup', event.data);
    });
  },

  updateStandup: ({ state, rootState, commit, getters, dispatch }, data) => {
    if (!state.isStandupOn) {
      dispatch('focusStandup');
    }

    commit('UPDATE_STANDUP', data);

    // Play a sound when the user's turn is reached only if isNoAudioMode is false
    if (getters.isSelfCurrentParticipant && !rootState.isNoAudioMode) {
      SoundsPlayer.playStandupTurnReachedSound(rootState.selectedSpeakerId);
    }

    // Temporarily disable the next button to prevent multiple next clicks
    commit('SET_WAS_NEXT_RECENTLY_CLICKED', true);

    // After the throttling time is over, let the button go back to its enabled state
    setTimeout(() => {
      commit('SET_WAS_NEXT_RECENTLY_CLICKED', false);
    }, 1000);
  },

  startStandup: ({ rootGetters, dispatch }) => {
    analytics.trackEvent(ANALYTICS.ROUND_TABLE, {
      'Num of Participants': rootGetters.activeParticipants.length
    });

    const data = _moveToNextSpeaker([], '', rootGetters.activeParticipants);
    return dispatch('sendData', data);
  },

  stopStandup: ({ dispatch }) => {
    const data = {
      isStandupOn: false,
      pastSpeakers: [],
      currentSpeaker: ''
    };
    return dispatch('sendData', data);
  },

  focusStandup: ({ dispatch, commit }) => {
    dispatch('layout/toggleSidebar', { shouldCollapse: false }, { root: true });
    dispatch('setActiveSidebar', SIDEBARS.PARTICIPANTS, { root: true });
    commit('SET_IS_PLAYING_FOCUS_ANIMATION', true);
  },

  stopFocusAnimation: ({ commit }) => {
    commit('SET_IS_PLAYING_FOCUS_ANIMATION', false);
  },

  moveToNextSpeaker: ({ state, getters, rootGetters, dispatch }) => {
    if (getters.remainingParticipantsCount === 0) {
      return dispatch('stopStandup');
    }

    const data = _moveToNextSpeaker(
      state.pastSpeakers,
      state.currentSpeaker,
      rootGetters.activeParticipants
    );
    return dispatch('sendData', data);
  },

  sendData: (_, data) => {
    const signal = {
      type: APP_SIGNALS.STANDUP,
      data: data
    };
    return MeetingManager.sendSignal(signal);
  }
};
