/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-enter-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 24h13.5c.9 0 1.5-.6 1.5-1.5v-21c0-.9-.6-1.5-1.5-1.5H9c-.9 0-1.5.6-1.5 1.5V6h3V3H21v18H10.5v-3h-3v4.5c0 .9.6 1.5 1.5 1.5zm9-12l-6 6v-4.5H0v-3h12V6l6 6z"/>'
  }
})
