/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'empty-search': {
    width: 16,
    height: 16,
    viewBox: '0 0 230 200',
    data: '<rect pid="0" opacity=".15" x="65" y="50" width="100" height="100" rx="8"/><path pid="1" d="M94.875 95.688c0-8.626 7.187-15.813 15.812-15.813S126.5 87.063 126.5 95.688c0 8.624-7.188 15.812-15.813 15.812s-15.812-7.188-15.812-15.813zM110.687 77A18.65 18.65 0 0092 95.688a18.65 18.65 0 0018.687 18.687c3.163 0 6.038-.863 8.625-2.3l10.351 10.35c.575.575 1.437.575 2.012 0l5.75-5.75c.575-.575.575-1.437 0-2.012l-10.35-10.351c1.437-2.587 2.3-5.462 2.3-8.624A18.65 18.65 0 00110.687 77z"/>'
  }
})
