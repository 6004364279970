/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-7': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.912 1.532a1.276 1.276 0 100 2.552 1.276 1.276 0 000-2.552zM9.104 2.809a2.808 2.808 0 115.616-.002 2.808 2.808 0 01-5.616.002zm-5.53.51a1.276 1.276 0 112.552 0 1.276 1.276 0 01-2.552 0zM4.851.511a2.808 2.808 0 10-.001 5.616 2.808 2.808 0 000-5.616zm13.021 2.808a1.276 1.276 0 112.552 0 1.276 1.276 0 01-2.552 0zM19.15.511a2.808 2.808 0 10-.001 5.616 2.808 2.808 0 000-5.616zM3.59 7.149A2.81 2.81 0 00.803 9.61l-.654 5.233a1.788 1.788 0 001.44 1.979l.455 5.456a.766.766 0 00.764.702h4.085a.766.766 0 100-1.532h-3.38l-.453-5.425a.766.766 0 00-.763-.702h-.375a.256.256 0 01-.253-.287L2.323 9.8c.08-.64.623-1.119 1.267-1.119h2.282a.766.766 0 100-1.532H3.59zm16.82 0a2.809 2.809 0 012.787 2.46l.653 5.233a1.787 1.787 0 01-1.44 1.978l-.455 5.456a.766.766 0 01-.763.703h-4.086a.766.766 0 010-1.532h3.38l.453-5.425a.766.766 0 01.763-.703h.375a.255.255 0 00.253-.286l-.654-5.234a1.277 1.277 0 00-1.267-1.118h-2.282s-.714.02-.714-.766c0-.787.714-.766.714-.766h2.282zm-9.714-.235c-.606 0-1.263.103-1.81.451-.58.37-.953.962-1.045 1.746l-.676 4.539a1.928 1.928 0 00.706 1.765L7.163 19a.766.766 0 00.752.914h1.548l.767 3.484a.766.766 0 00.748.601h2.043c.36 0 .67-.25.748-.601l.767-3.484h1.549a.766.766 0 00.751-.914l-.708-3.586a1.929 1.929 0 00.706-1.765l-.675-4.539c-.093-.784-.465-1.376-1.046-1.746-.547-.348-1.204-.45-1.81-.45h-2.607zM9.36 9.3c.042-.368.183-.536.348-.641.202-.128.527-.212.987-.212h2.608c.46 0 .785.084.986.212.165.105.307.273.349.64a.77.77 0 00.003.027l.675 4.535c.021.226-.117.372-.245.406a.766.766 0 00-.557.89l.638 3.227H13.92c-.36 0-.67.25-.748.601l-.768 3.484h-.81l-.768-3.484a.766.766 0 00-.748-.601H8.847l.637-3.228a.766.766 0 00-.557-.89c-.127-.033-.266-.18-.244-.405l.675-4.535a.77.77 0 00.003-.026z"/>'
  }
})
