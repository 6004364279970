/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mobile-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.5 2.25a.75.75 0 01.75-.75h13.5a.75.75 0 01.75.75v19.5a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75V2.25zM5.25 0A2.25 2.25 0 003 2.25v19.5A2.25 2.25 0 005.25 24h13.5A2.25 2.25 0 0021 21.75V2.25A2.25 2.25 0 0018.75 0H5.25zm1.5 18a.75.75 0 000 1.5h10.5a.75.75 0 000-1.5H6.75z"/>'
  }
})
