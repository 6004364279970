/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-outbound-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.25 0H8.241v3.502h9.78L0 21.524 2.476 24 20.498 5.978v9.78H24V1.751C24 .784 23.216 0 22.25 0z"/>'
  }
})
