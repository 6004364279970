/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-heart': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.7 2.3C5.9.5 3.1.5 1.3 2.3c-1.8 1.8-1.8 4.6 0 6.4l6.3 6.2c.2.2.5.2.7 0l6.3-6.2c1.8-1.8 1.8-4.6 0-6.4C12.8.5 10 .5 8.2 2.3c0 .1-.1.3-.2.4-.1-.1-.2-.3-.3-.4zM2 3c1.4-1.3 3.6-1.3 5 0 .2.2.4.5.6.8 0 .2.2.3.4.3s.4-.1.4-.3c.2-.3.4-.5.6-.8 1.4-1.4 3.6-1.4 4.9 0 1.4 1.4 1.4 3.6 0 4.9l-6 5.8L2 8C.7 6.6.7 4.4 2 3z"/>'
  }
})
