/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-keypad-line-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 0H0v6h6V0zm9 0H9v6h6V0zm3 0h6v6h-6V0zM6 9H0v6h6V9zm3 0h6v6H9V9zm15 0h-6v6h6V9zM0 18h6v6H0v-6zm15 0H9v6h6v-6zm3 0h6v6h-6v-6z"/>'
  }
})
