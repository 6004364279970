/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-edit-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 0H3a1 1 0 00-1 1v22a1 1 0 001 1h18a1 1 0 001-1V1a1 1 0 00-1-1zM10 17l-4 1 1-4 8-8 3 3-8 8z"/>'
  }
})
