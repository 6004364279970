<template>
  <div class="slider-container">
    <v-popover
      ref="hoverTimePopover"
      trigger="hover"
      placement="bottom-start"
      popoverBaseClass="hover-time-popover"
      offset="16"
      boundariesElement="window"
    >
      <WatchTogetherSlider
        ref="slider"
        :primaryColor="sliderColor"
        :value="timeInVideo"
        :secondaryValue="bufferedSeconds"
        :max="duration"
        @mouseMoveOnBar="mouseMoveOnBarThrottled"
        @dragstart="sliderDragStarted"
        @dragging="sliderDragging"
        @dragend="sliderDragEnded"
      />
      <div
        slot="popover"
        ref="hoverTimePopoverContent"
        :style="hoverTimePopoverStyle"
        @mouseenter="hideHoverTimePopover"
      >
        <div class="watch-together-popover unselectable hover-time-text">
          {{ hoverTime }}
        </div>
      </div>
    </v-popover>

    <div
      v-if="ownerIndicationVisible"
      v-tooltip="ownerIndicationTooltip"
      class="owner-indication"
      :class="ownerIndicationBorderClasses"
      :style="ownerIndicationStyle"
      @click="syncToOwner"
    >
      <Avatar
        size="xs"
        :displayName="ownerParticipant.displayName"
        :image="ownerParticipant.profilePicture"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import WatchTogetherSlider from './WatchTogetherSlider';
import { durationToString } from '@/helpers/time-utils';
import throttle from 'lodash.throttle';

/**
 * Overlays the WatchTogetherSlider with an indication of the owner's time in the video.
 * Hovering over the seek bar shows a tooltip that shows the time at that point of the video.
 */
export default {
  name: 'WatchTogetherSeekBar',

  components: {
    WatchTogetherSlider
  },

  props: {
    timeInVideo: {
      type: Number,
      default: 0
    },
    bufferedFraction: {
      type: Number,
      default: 0
    },
    duration: {
      type: Number,
      default: 1
    },
    ownerIndicationVisible: {
      type: Boolean,
      default: true
    },
    ownerParticipant: {
      type: Object,
      default: null
    },
    ownerTimeInVideo: {
      type: Number,
      default: 0
    },
    ownerPaused: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      hoverTimeSeconds: 0, // represents the point in time at which the cursor is pointing
      mouseMoveOnBarThrottled: throttle(this.mouseMoveOnBar, 40)
    };
  },

  computed: {
    ...mapState('whitelabel', ['cssVariables']),
    ...mapState(['isBranded']),

    hoverTimePopoverStyle() {
      const hoverTimeFraction = this.hoverTimeSeconds / this.duration;
      const slider = this.$refs.slider;
      const hoverTimePopoverContent = this.$refs.hoverTimePopoverContent;
      if (!slider || !hoverTimePopoverContent) {
        return {};
      }

      const mouseX = hoverTimeFraction * slider.$el.offsetWidth;
      const popoverX = mouseX - hoverTimePopoverContent.offsetWidth / 2;
      return { transform: `translateX(${popoverX}px)` };
    },

    hoverTime() {
      return durationToString(this.hoverTimeSeconds);
    },

    bufferedSeconds() {
      return this.duration * this.bufferedFraction;
    },

    ownerIndicationStyle() {
      const ownerTimeFraction = this.ownerTimeInVideo / this.duration;
      const slider = this.$refs.slider;
      if (!slider) {
        return {};
      }

      const indicationX = ownerTimeFraction * slider.$el.offsetWidth;
      return { transform: `translateX(${indicationX}px)` };
    },

    ownerIndicationBorderClasses() {
      const diff = this.timeInVideo - this.ownerTimeInVideo;

      // This makes the owner indication look more natural when it goes past the user's
      // time, from the purple area to the gray area
      return {
        'left-border': diff >= -1,
        'bottom-border': diff >= 0,
        'top-border': diff >= 0,
        'right-border': diff >= 1
      };
    },

    ownerIndicationTooltip() {
      return {
        hideOnTargetClick: true,
        content: this.ownerParticipant.displayName,
        'open-group': 'a',
        placement: 'bottom',
        boundariesElement: 'window',
        classes: [
          'watch-together-tooltip',
          'owner-indication-tooltip',
          this.ownerPaused ? 'paused' : 'playing'
        ]
      };
    },

    sliderColor() {
      return this.isBranded
        ? this.cssVariables['--btn-cta-color-black-bg']
        : '#871eff';
    }
  },

  methods: {
    ...mapActions('watchTogether/ongoing', ['syncToOwner']),

    mouseMoveOnBar(event) {
      this.hoverTimeSeconds = this.$refs.slider.getValueByEvent(event);
    },

    sliderDragStarted() {
      this.dragging = true;
      this.$emit('dragstart');
    },

    sliderDragging(value) {
      this.$emit('seek', { final: false, timeInVideo: value });
    },

    sliderDragEnded(value) {
      this.$emit('seek', { final: true, timeInVideo: value });
    },

    hideHoverTimePopover() {
      this.$refs.hoverTimePopover.hide();
    }
  }
};
</script>

<style scoped>
.v-popover >>> .trigger {
  display: block !important;
}

.hover-time-popover .popover-arrow {
  display: none;
}

.hover-time-text {
  font-size: 12px;
}

.slider-container {
  position: relative;
}

.owner-indication {
  position: absolute;
  top: -11.5px;
  left: -12px;
  border-width: 3px;
  border-style: solid;
  border-radius: 50%;
  border-color: #808080;
  cursor: pointer;
}

.owner-indication.left-border {
  border-left-color: var(--btn-cta-color-black-bg);
}

.owner-indication.top-border {
  border-top-color: var(--btn-cta-color-black-bg);
}

.owner-indication.bottom-border {
  border-bottom-color: var(--btn-cta-color-black-bg);
}

.owner-indication.right-border {
  border-right-color: var(--btn-cta-color-black-bg);
}
</style>

<!-- Global style to affect the tooltip, which is injected to the body -->
<style>
.tooltip.owner-indication-tooltip .tooltip-inner {
  display: flex;
  align-items: center;
}

.tooltip.owner-indication-tooltip.paused .tooltip-inner::before,
.tooltip.owner-indication-tooltip.playing .tooltip-inner::before {
  content: '';
  width: 10px;
  height: 10px;
  margin-right: 8px;
}

.tooltip.tooltip.owner-indication-tooltip.playing .tooltip-inner::before {
  display: inline-block;
  box-sizing: border-box;
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 10px solid white;
}

.tooltip.tooltip.owner-indication-tooltip.paused .tooltip-inner::before {
  border-style: double;
  border-width: 0 0 0 10px;
  border-color: white;
}

.tooltip.owner-indication-tooltip .tooltip-arrow {
  display: none;
}
</style>
