/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-meeting': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M9.6 3.6c.2-.2.5-.2.7 0L15 8.3c.3.3.5.8.5 1.2s-.2.9-.5 1.2c-.3.3-.8.5-1.2.5s-.9-.2-1.2-.5c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0 .1.1.3.2.5.2s.4-.1.5-.2c.1-.1.2-.3.2-.5s-.1-.4-.2-.5L9.6 4.4c-.1-.2-.1-.6 0-.8zM0 1.9c0-.3.2-.9.5-.9h5.6c.3 0 .5.3.4.6 0 .3-.3.5-.6.4h-.3m-4.4-.1L1 6.5c0 .3-.2.5-.5.5S0 6.8 0 6.5V1.9m1.2 0l4.4.1-4.4-.1z"/><path pid="1" d="M11.1 8.6c.2-.2.5-.2.7 0l1.5 1.5c.3.3.5.8.5 1.2 0 .5-.2.9-.5 1.2-.3.3-.8.5-1.2.5-.5 0-.9-.2-1.2-.5L9.4 11c-.2-.2-.2-.5 0-.7.2-.2.5-.2.7 0l1.5 1.5c.1.1.3.2.5.2s.4-.1.5-.2c.1-.1.2-.3.2-.5s-.1-.4-.2-.5l-1.5-1.5c-.1-.1-.1-.5 0-.7z"/><path pid="2" d="M10.5 11.5c.2-.2.5-.2.7 0 .3.3.5.8.5 1.2s-.2.9-.5 1.2l-.7-.7c.1-.1.2-.3.2-.5s-.1-.4-.2-.5c-.2-.2-.2-.5 0-.7zM10.1 1h5.4c.3 0 .5.2.5.5v5c0 .3-.2.5-.5.5s-.5-.2-.5-.5V2h-4.9c-.6 0-1.2.2-1.6.6l-3 2.7c-.1 0-.2.1-.2.2v.3c0 .1.1.2.1.3l.2.2c.1.1.2.1.3.1.1 0 .2-.1.3-.1l3.4-2.6c.2-.2.5-.1.7.1.2.2.1.5-.1.7L6.9 7c-.3.2-.6.3-.9.3-.3 0-.6 0-.9-.2-.2-.1-.4-.2-.5-.4-.2-.3-.3-.5-.3-.7 0-.3 0-.5.1-.8 0-.2.2-.5.3-.6l3.1-2.7c.7-.6 1.5-.9 2.3-.9zM2 8.7c-.3.3-.3.8 0 1.1.3.3.8.3 1 0l.3-.3c.3-.3.3-.8 0-1-.3-.3-.8-.3-1 0l-.3.2zm-.7 1.8C.6 9.8.6 8.7 1.3 8l.3-.3c.7-.7 1.8-.7 2.5 0s.7 1.8 0 2.5l-.3.3c-.7.7-1.8.7-2.5 0z"/><path pid="3" d="M3.7 10.5c-.3.3-.3.8 0 1 .3.3.8.3 1 0l.4-.4c.3-.3.3-.8 0-1-.3-.3-.8-.3-1 0l-.4.4zM3 12.3c-.7-.7-.7-1.8 0-2.5l.4-.4c.7-.7 1.8-.7 2.5 0s.7 1.8 0 2.5l-.4.4c-.8.7-1.9.7-2.5 0z"/><path pid="4" d="M5.4 12.3c-.3.3-.3.8 0 1 .3.3.8.3 1 0l.3-.3c.3-.3.3-.8 0-1-.3-.3-.8-.3-1 0l-.3.3zM4.7 14c-.7-.7-.7-1.8 0-2.5l.3-.2c.7-.7 1.8-.7 2.5 0s.7 1.8 0 2.5l-.3.2c-.7.7-1.8.7-2.5 0z"/><path pid="5" d="M7.2 14c-.2.2-.2.6 0 .8.2.2.5.2.8 0l.3-.3c.2-.2.2-.6 0-.8-.2-.2-.6-.2-.8 0l-.3.3zm-.7 1.5c-.6-.6-.6-1.6 0-2.2l.3-.3c.6-.6 1.6-.6 2.2 0 .6.6.6 1.6 0 2.2l-.3.3c-.6.6-1.6.6-2.2 0z"/>'
  }
})
