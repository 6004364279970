/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-merge-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.9 15.1l-3-3c-.2-.2-.5-.2-.7 0s-.2.5 0 .7l3 3c.2.2.5.2.7 0 .1-.1.1-.5 0-.7zM12.1 4.9c.2.2.5.2.7 0 .2-.2.2-.5 0-.7l-4-4S8.7.1 8.6.1c0-.1-.2-.1-.3-.1-.1 0-.1.1-.2.1l-4 4c-.1.2-.1.6 0 .8.2.1.6.1.8 0L8 1.7v8.6l-4.9 4.9c-.2.2-.2.5 0 .7.2.2.5.2.7 0l5-5c.1-.1.2-.3.2-.4V1.7l3.1 3.2z"/>'
  }
})
