var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("transition", { attrs: { name: "slide" } }, [
    _c(
      "div",
      {
        staticClass: "Vlt-callout flash-message",
        class: [_vm.calloutClass, { "off-screen": _vm.offScreen }],
      },
      [
        _vm.options.customIcon
          ? _c(
              "div",
              { staticClass: "Vlt-callout__icon" },
              [_c("v-icon", { attrs: { iconName: _vm.options.customIcon } })],
              1
            )
          : _vm.options.customImg
          ? _c("div", { staticClass: "custom-image" }, [
              _c("img", {
                class: { "center-elements": _vm.areElementsCentered },
                attrs: { src: _vm.options.customImg },
              }),
            ])
          : _c("i"),
        _c("div", { staticClass: "Vlt-callout__content" }, [
          _vm.hasTitle
            ? _c("h4", [_vm._v(" " + _vm._s(_vm.options.title) + " ")])
            : _vm._e(),
          _c(
            "p",
            {
              staticClass: "selectable",
              class: { "flash-message-bold-text": !_vm.hasTitle },
            },
            [_vm._t("text")],
            2
          ),
          _vm.options.button && _vm.options.button.actions
            ? _c(
                "button",
                {
                  staticClass: "Vlt-callout__cta",
                  on: { click: _vm.dispatchButtonAction },
                },
                [_vm._v(" " + _vm._s(_vm.buttonText) + " ")]
              )
            : _vm._e(),
        ]),
        _vm.options.dismiss
          ? _c("button", {
              staticClass: "Vlt-callout__dismiss",
              class: {
                "center-elements": _vm.areElementsCentered,
                blank: _vm.hasBlankType,
              },
              on: { click: _vm.removeSelf },
            })
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }