/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4.5 6h2c.3 0 .5-.2.5-.5S6.8 5 6.5 5h-2c-.3 0-.5.2-.5.5s.2.5.5.5zM11.5 8h-7c-.3 0-.5.2-.5.5s.2.5.5.5h7c.3 0 .5-.2.5-.5s-.2-.5-.5-.5zM11.5 11h-7c-.3 0-.5.2-.5.5s.2.5.5.5h7c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"/><path pid="1" d="M15 5.3c0-.1-.1-.1-.1-.2l-5-5S9.8 0 9.7 0H1.5c-.3 0-.5.2-.5.5v15c0 .3.2.5.5.5h13c.3 0 .5-.2.5-.5v-10-.2zm-5-3.6L13.3 5H10V1.7zM2 15V1h7v4.5c0 .3.2.5.5.5H14v9H2z"/>'
  }
})
