/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'callout-good--close': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 12.722L18.278 19l.722-.722L12.722 12 19 5.722 18.278 5 12 11.278 5.722 5 5 5.722 11.278 12 5 18.278l.722.722L12 12.722z"/>'
  }
})
