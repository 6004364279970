/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-js': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 5.2c-1.1.1-1.9.5-2.6 1.1-.7.7-1 1.5-1 2.5 0 1.6.6 2.6 2.1 3.5.4.2.9.5 1.7.8 1.2.5 1.6.7 1.9 1 .3.4.4.9.2 1.4 0 .1-.2.2-.2.3-.3.3-.8.5-1.5.5-1.1 0-1.9-.4-2.6-1.4l-.2-.2s-2.2 1.2-2.2 1.3c0 0 0 .1.1.3.8 1.4 2.2 2.3 4 2.5.5.1 1.3.1 1.8 0 1.1-.2 2-.5 2.6-1.2.7-.7 1-1.5 1-2.6 0-.7-.1-1.2-.4-1.8-.2-.4-.4-.6-.7-.9-.6-.6-1.4-1.1-2.9-1.7-1.2-.5-1.5-.7-1.8-1-.2-.3-.3-.6-.3-.9 0-.3.1-.5.3-.7.3-.3.6-.4 1-.4.5 0 .9.1 1.2.5l.4.4.2.2c.1 0 2.1-1.3 2.1-1.4l-.2-.4c-.3-.4-.8-.9-1.1-1.1-.6-.4-1.3-.6-2.1-.7-.3 0-.4 0-.8.1zM7.8 10.1V15l-.1.2c-.1.3-.2.5-.3.7-.2.2-.5.3-1 .3-.7 0-1.1-.3-1.6-1.2l-.2-.2s-.5.3-1.1.7l-1.1.7.1.2c.5 1 1.4 1.8 2.4 2.1 1.1.4 2.5.3 3.6-.1 1.2-.5 2-1.5 2.2-3.1V5.1H7.8v5z"/>'
  }
})
