import amplitude from 'amplitude-js';
import { isElectron } from '@/helpers/global-helpers';
import Bowser from 'bowser';

const appKey = process.env.VUE_APP_AMPLITUDE_KEY;
let isInitialized = false;
let isAppcuesEnabled = false;
let userIdentify = null;
let sessionId;
let meetingName;
let domainType;

const getParsedUAProps = () => {
  let props = {};
  try {
    const ua = window.navigator.userAgent;
    if (ua) {
      const browser = Bowser.getParser(ua);

      if (browser) {
        if (browser.getOS()) {
          props['PC OS Version'] = `${browser.getOS().name} ${
            browser.getOS().versionName
          }`;
        }
        if (!isElectron() && browser.getBrowser()) {
          props['Web Version'] = `${browser.getBrowser().name} ${
            browser.getBrowser().version
          }`;
        }
      }
    }
  } catch (err) {
    // ignored
  }

  return props;
};

const UAprops = getParsedUAProps();

export default {
  init(userId, userProperties = [], groupProperties = []) {
    if (isInitialized) {
      return;
    }
    const instance = amplitude.getInstance();
    instance.init(appKey, null);
    instance.clearUserProperties();
    instance.setUserId(userId);
    instance.regenerateDeviceId();
    userIdentify = new amplitude.Identify();
    userProperties.forEach((prop) => userIdentify.set(prop.key, prop.value));
    instance.identify(userIdentify);
    groupProperties.forEach((prop) => instance.setGroup(prop.key, prop.value));
    isInitialized = true;
  },

  enableAppcues() {
    isAppcuesEnabled = true;
  },

  setAccountId(value) {
    try {
      const instance = amplitude.getInstance();
      instance.setGroup('Account ID', value);
    } catch (e) {
      // do nothing
    }
  },

  setUserId(userId) {
    const instance = amplitude.getInstance();
    instance.setUserId(userId);
  },

  updateSessionProps(meetingId, roomName, domain) {
    sessionId = meetingId;
    meetingName = roomName;
    domainType = domain;
  },

  trackEvent(eventType, eventProps = {}) {
    try {
      const instance = amplitude.getInstance();

      const environment = window.isMobileWebSupported ? 'Mobile Web' : 'Web';

      let props = Object.assign(eventProps, UAprops, {
        Application: 'Meetings',
        'Meetings Version': process.env.VUE_APP_VERSION,
        'Meeting Name': meetingName,
        'Meeting ID': sessionId,
        Domain: domainType,
        'OS Platform': isElectron() ? 'Native' : 'Web',
        'Meetings Environment': environment
      });

      if (isElectron() && window.Electron.version) {
        props['Native Version'] = window.Electron.version;
      }

      instance.logEvent(eventType, props);
      if (isAppcuesEnabled) {
        window.Appcues?.track(eventType, props);
      }
    } catch (err) {
      // do nothing
    }
  }
};
