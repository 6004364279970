<template>
  <CounterBadge class="chat-notification" :count="unreadMessagesCount" />
</template>

<script>
import { mapState } from 'vuex';
import CounterBadge from '@/components/CounterBadge.vue';

export default {
  name: 'ChatNotification',

  components: {
    CounterBadge
  },

  computed: {
    ...mapState('messaging', ['unreadMessagesCount'])
  }
};
</script>

<style scoped></style>
