import { ANALYTICS, LAYOUT_MODE_TYPES } from '@/consts/global-consts';
import analytics from '@/services/analytics-service';

export default {
  setLayoutMode: ({ state, commit }, { layoutMode, layoutToSave }) => {
    if (layoutToSave) {
      commit('SET_LAST_SAVED_LAYOUT', layoutToSave);
    } else if (state.lastSavedLayout) {
      commit('SET_LAST_SAVED_LAYOUT', '');
    }
    commit('SET_LAYOUT_MODE', layoutMode);
  },

  setUserPreferredLayout: ({ commit }, { layoutMode }) => {
    commit('SET_USER_PREFERRED_LAYOUT', layoutMode);
  },

  changeLayoutOnPin: ({ state, dispatch }) => {
    if (state.layoutMode === LAYOUT_MODE_TYPES.GRID) {
      dispatch('setLayoutMode', {
        layoutMode: LAYOUT_MODE_TYPES.SPEAKER
      });
    }
  },

  changeLayoutOnScreenshareStarted: ({ state, dispatch }) => {
    if (state.layoutMode === LAYOUT_MODE_TYPES.GRID) {
      dispatch('setLayoutMode', {
        layoutMode: LAYOUT_MODE_TYPES.SPEAKER,
        layoutToSave: state.layoutMode
      });
    }
  },

  changeLayoutOnScreenshareEnded: ({ state, dispatch }) => {
    if (state.lastSavedLayout === LAYOUT_MODE_TYPES.GRID) {
      dispatch('setLayoutMode', {
        layoutMode: LAYOUT_MODE_TYPES.GRID
      });
    }
  },

  changeLayoutOnMinimizeModeChange: ({ state, dispatch, rootState }) => {
    // Gallery layout is not supported in minimized mode
    if (
      rootState.minimizedMode &&
      state.layoutMode === LAYOUT_MODE_TYPES.GRID
    ) {
      dispatch('setLayoutMode', {
        layoutMode: LAYOUT_MODE_TYPES.SPEAKER,
        layoutToSave: state.layoutMode
      });
    } else if (
      !rootState.minimizedMode &&
      state.lastSavedLayout &&
      state.lastSavedLayout !== state.layoutMode
    ) {
      dispatch('setLayoutMode', { layoutMode: state.lastSavedLayout });
    }
  },

  toggleSidebar: (
    { state, commit, dispatch, rootGetters },
    { skipAnimation = false, shouldCollapse } = {}
  ) => {
    const isShouldOpenDefined = shouldCollapse !== undefined;

    if (!isShouldOpenDefined || shouldCollapse !== state.isSidebarCollapsed) {
      if (!skipAnimation) {
        dispatch('toggleSidebarAnimation', true, { root: true });
        analytics.trackEvent(ANALYTICS.SIDEBAR_TOGGLED, {
          'New State': state.isSidebarCollapsed ? 'On' : 'Off',
          'Num of Participants': rootGetters.activeParticipants.length
        });
      }

      commit('TOGGLE_SIDEBAR');
    }
  },

  changeLayoutInLargeMeeting: ({ dispatch, commit }) => {
    commit('SET_LAYOUT_CHANGED_IN_LARGE_MEETING', true);
    dispatch('setLayoutMode', {
      layoutMode: LAYOUT_MODE_TYPES.SPEAKER,
      layoutToSave: LAYOUT_MODE_TYPES.SPEAKER
    });
  }
};
