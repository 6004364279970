/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-audio-max-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.885 1.872a1 1 0 00-1.427 1.401c4.723 4.809 4.723 12.645 0 17.453a1 1 0 101.427 1.401c5.487-5.586 5.487-14.669 0-20.255zm-9.8.844a.5.5 0 01.827.378v17.812a.5.5 0 01-.827.378l-5.72-4.95H2.183C.977 16.333 0 15.338 0 14.11V9.89C0 8.66.977 7.667 2.183 7.667h2.182l5.72-4.951zm4.627 5.44a1 1 0 10-1.427 1.402c1.314 1.338 1.314 3.548 0 4.886a1 1 0 101.427 1.401c2.078-2.116 2.078-5.573 0-7.688zm1.207-3.155a1 1 0 011.415.013c3.782 3.851 3.782 10.12 0 13.971a1 1 0 11-1.427-1.401c3.018-3.073 3.018-8.095 0-11.168A1 1 0 0115.919 5z"/>'
  }
})
