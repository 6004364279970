/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-audio-off-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.912 8.84V3.094a.5.5 0 00-.827-.378l-5.72 4.95H2.183C.977 7.666 0 8.66 0 9.889v4.222c0 1.228.977 2.223 2.183 2.223H3.42l7.492-7.492zm-5.537 8.367l.002.001-4.084 4.084a1 1 0 101.414 1.415l4.186-4.186 3.192 2.762a.5.5 0 00.827-.378v-6.404l3.274-3.274c.249 1.123-.052 2.352-.9 3.216a1 1 0 101.426 1.402c1.637-1.667 1.984-4.166 1.043-6.186l1.464-1.464c1.613 2.999 1.176 6.856-1.312 9.388a1 1 0 101.426 1.402c3.262-3.32 3.711-8.438 1.35-12.253l1.403-1.404c3.012 4.776 2.47 11.226-1.628 15.397a1 1 0 101.427 1.402C24.752 17.17 25.301 9.46 21.53 3.883l1.776-1.776A1 1 0 0021.893.692l-1.606 1.606-.002-.002-1.416 1.416.002.002-1.42 1.42-.002-.001-1.415 1.415.002.002-1.478 1.478-.002-.002-1.388 1.388.001.002-2.257 2.257v-.004l-5.537 5.538z"/>'
  }
})
