import {
  OS_TYPES,
  OT_ERROR,
  PUBLISHER_ERROR,
  REACTION_OPTIONS,
  ROOM_SERVICE_ERROR_MESSAGES,
  STREAM_TYPE,
  SYSTEM_PRIVACY_PATH,
  VIRTUAL_BACKGROUND_TYPE
} from '@/consts/global-consts';
import {
  isAppleMobileDevice,
  isElectron,
  isMobileDevice,
  OS
} from '@/helpers/global-helpers';
import i18n from '@/i18n';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';

export function extractParticipantData(connection) {
  const data = new URLSearchParams(connection.data);
  const participantId = data.get('participantId');
  let participantData = {
    participantId,
    connectionId: connection.connectionId
  };
  return participantData;
}

export function extractStreamData(stream, isPublisher = false) {
  const data = new URLSearchParams(stream.connection.data);
  const participantId = data.get('participantId');
  return {
    streamId: stream.streamId,
    hasVideo: stream.hasVideo,
    hasAudio: stream.hasAudio,
    connectionId: stream.connection.connectionId,
    participantId,
    type: stream.videoType ? stream.videoType : 'audio',
    badQuality: false,
    isPublisher,
    videoDimensions: stream.videoDimensions,
    connection: stream.connection
  };
}

export function createAvatarStream(
  participantId,
  connectionId,
  isPublisher = false
) {
  return {
    streamId: connectionId,
    hasVideo: false,
    hasAudio: false,
    connectionId,
    participantId,
    type: STREAM_TYPE.AVATAR,
    badQuality: false,
    isPublisher
  };
}

export function getPublisherError(error, unavailableDevices, isBranded) {
  const unavailableDevicesString = unavailableDevices.join('/');
  const err = {
    title: i18n.t('meeting_helpers.publisher_error_title'),
    message: i18n.t('meeting_helpers.publisher_error_message'),
    name: PUBLISHER_ERROR.OTHER
  };
  if (error.name === OT_ERROR.USER_MEDIA_ACCESS_DENIED) {
    if (error.originalMessage === 'Permission denied by system') {
      // Browser permissions denied by system - MAC
      err.title = i18n.t('meeting_helpers.publisher_access_denied_error_title');
      err.message = i18n.t(
        'meeting_helpers.publisher_access_denied_error_message',
        {
          unavailableDevicesString,
          osPrivacyPath: SYSTEM_PRIVACY_PATH[OS.name]
        }
      );
      err.name = PUBLISHER_ERROR.SYSTEM_PERMISSIONS;
    } else {
      // Browser permissions denied by browser
      err.title = i18n.t(
        'meeting_helpers.publisher_browser_permissions_error_title'
      );

      if (isAppleMobileDevice && window.isSafari) {
        // TODO: in this scenario the buttons should be "Cancel" and "Refresh" rather than "Got It"
        err.message = i18n.t(
          'meeting_helpers.publisher_browser_permissions_error_apple_message',
          { unavailableDevicesString }
        );
      } else if (isMobileDevice) {
        err.message = i18n.t(
          'meeting_helpers.publisher_browser_permissions_error_mobile_message',
          { unavailableDevicesString }
        );
      } else if (window.isSafari) {
        err.message = i18n.t(
          isBranded
            ? 'meeting_helpers.publisher_browser_permissions_error_safari_message_branded'
            : 'meeting_helpers.publisher_browser_permissions_error_safari_message',
          { unavailableDevicesString }
        );
      } else {
        err.message = i18n.t(
          isBranded
            ? 'meeting_helpers.publisher_browser_permissions_error_branded_message'
            : 'meeting_helpers.publisher_browser_permissions_error_vbc_message',
          { unavailableDevicesString }
        );
      }
      err.name = PUBLISHER_ERROR.BROWSER_PERMISSIONS;
    }
  } else if (error.name === OT_ERROR.UNABLE_TO_CAPTURE_MEDIA) {
    // The device is being used by another process
    err.title = i18n.t('meeting_helpers.publisher_device_access_denied_title');
    err.message = i18n.t(
      isBranded
        ? 'meeting_helpers.publisher_device_access_denied_message_branded'
        : 'meeting_helpers.publisher_device_access_denied_message',
      { unavailableDevicesString }
    );
    err.name = PUBLISHER_ERROR.USED_BY_ANOTHER_PROCESS;
  } else if (error.name === OT_ERROR.HARDWARE_UNAVAILABLE) {
    if (OS.name === OS_TYPES.WINDOWS) {
      // Desktop app permissions denied by system or its being used by another process - WINDOWS
      err.title = i18n.t(
        'meeting_helpers.publisher_device_access_denied_title'
      );
      err.message = i18n.t(
        isBranded
          ? 'meeting_helpers.publisher_device_access_denied_message_branded'
          : 'meeting_helpers.publisher_device_access_denied_windows_message',
        { unavailableDevicesString }
      );
      err.name = PUBLISHER_ERROR.SYSTEM_PERMISSIONS;
    } else if (isElectron()) {
      // Desktop app permissions denied by system - MAC
      err.title = i18n.t('meeting_helpers.publisher_access_denied_error_title');
      err.message = i18n.t(
        'meeting_helpers.publisher_access_denied_electron_error_message',
        {
          unavailableDevicesString,
          osPrivacyPath: SYSTEM_PRIVACY_PATH[OS.name]
        }
      );
      err.name = PUBLISHER_ERROR.SYSTEM_PERMISSIONS;
    }
  } else if (error.name === OT_ERROR.CHROME_MICROPHONE_ACQUISITION_ERROR) {
    // Chrome error
    err.title = i18n.t(
      'meeting_helpers.publisher_chrome_microphone_error_title'
    );
    err.message = isElectron()
      ? i18n.t('meeting_helpers.publisher_chrome_microphone_error_vbc_message')
      : i18n.t('meeting_helpers.publisher_chrome_microphone_error_message');
    err.name = PUBLISHER_ERROR.CHROME_MICROPHONE_ERROR;
  } else if (error.name === OT_ERROR.NO_DEVICES_FOUND && window.isFirefox) {
    // Missing System permissions for firefox MAC :\
    err.title = i18n.t(
      'meeting_helpers.publisher_firefox_permissions_error_title'
    );
    err.message = i18n.t(
      'meeting_helpers.publisher_access_denied_error_message',
      {
        unavailableDevicesString,
        osPrivacyPath: SYSTEM_PRIVACY_PATH[OS.name]
      }
    );
    err.name = PUBLISHER_ERROR.SYSTEM_PERMISSIONS;
  } else if (error.name === OT_ERROR.TIMEOUT) {
    // Failed to publish in a reasonable amount of time
    err.title = i18n.t('meeting_helpers.publisher_timeout_error_title');
    err.message = isElectron()
      ? i18n.t('meeting_helpers.publisher_timeout_error_electron_message')
      : i18n.t('meeting_helpers.publisher_timeout_error_message');
    err.name = PUBLISHER_ERROR.TIMEOUT;
  }
  return err;
}

export function isScreenshareStream(stream) {
  return (
    stream.videoType === STREAM_TYPE.WEB_SCREENSHARE ||
    stream.videoType === STREAM_TYPE.NATIVE_SCREENSHARE ||
    stream.type === STREAM_TYPE.WEB_SCREENSHARE ||
    stream.type === STREAM_TYPE.NATIVE_SCREENSHARE
  );
}

export function getSessionErrorType(error) {
  const status = error.response?.status;
  const errMessage = error.response?.data?.message;
  if (
    status === 423 ||
    (status === 403 && errMessage === 'Session is locked')
  ) {
    return ROOM_SERVICE_ERROR_MESSAGES.SESSION_IS_LOCKED;
  } else if (status === 403 && errMessage === 'Session is full') {
    return ROOM_SERVICE_ERROR_MESSAGES.SESSION_IS_FULL;
  } else if (
    status === 403 &&
    errMessage === 'Only the owner of this room can open a new session'
  ) {
    return ROOM_SERVICE_ERROR_MESSAGES.CANNOT_JOIN_BEFORE_HOST;
  } else if (
    status === 403 &&
    errMessage ===
      'This participant must be explicitly admitted into the room by the owner before joining a session'
  ) {
    return ROOM_SERVICE_ERROR_MESSAGES.WAITING_ROOM_APPROVAL_REQUIRED;
  } else if (status === 404) {
    return ROOM_SERVICE_ERROR_MESSAGES.INVALID_TOKEN;
  } else if (
    status === 400 &&
    errMessage ===
      "Only vbc users from the same account as the room's can get or create the session"
  ) {
    return ROOM_SERVICE_ERROR_MESSAGES.DIFFERENT_ACCOUNT_ROOM;
  }
  return ROOM_SERVICE_ERROR_MESSAGES.OTHER;
}

export function getRoomDetailsErrorType(error) {
  if (error.response?.status === 403 || error.response?.status === 404) {
    return ROOM_SERVICE_ERROR_MESSAGES.INVALID_TOKEN;
  }
  return ROOM_SERVICE_ERROR_MESSAGES.OTHER;
}

export function getRoomStatusError(roomDetails, isGuest, accountId) {
  let roomStatus;

  if (
    !isGuest &&
    // TODO: RoomService now returns account_id as string. The toString is meant to support the old RS version and should be deleted when the new RS is released.
    accountId?.toString() !== roomDetails?.account_id?.toString()
  ) {
    roomStatus = ROOM_SERVICE_ERROR_MESSAGES.DIFFERENT_ACCOUNT_ROOM;
  }

  return roomStatus;
}

export function validateRoomToken(token) {
  return /^([0-9 _-]+)$/gi.test(token);
}

/**
 * Rebinds the video element, which can sometimes solve audio and video issues.
 * @param {*} otEntity OT subscriber or publisher
 */
export function rebindVideoElement(otEntity) {
  if (otEntity && otEntity.videoElement()) {
    otEntity.videoElement().srcObject = otEntity.videoElement().srcObject;
  }
}

// Change this according to the special date you have
export function isSpecialDate() {
  return isReferralProgramDate();
}

export function isReferralProgramDate() {
  const from = new Date(2023, 5, 17); // months are from 0 to 11
  const to = new Date(2023, 6, 19);
  const now = new Date();
  return now > from && now < to;
}

export function reactionOptions() {
  return REACTION_OPTIONS;
}

export function getVideoFilter(virtualBackground) {
  if (!virtualBackground || !virtualBackground.type) {
    return null;
  }
  if (virtualBackground.type === VIRTUAL_BACKGROUND_TYPE.NONE) {
    return null;
  } else if (virtualBackground.type === VIRTUAL_BACKGROUND_TYPE.BLUR) {
    return {
      type: 'backgroundBlur'
    };
  } else if (virtualBackground.type === VIRTUAL_BACKGROUND_TYPE.IMAGE) {
    return {
      type: 'backgroundReplacement',
      backgroundImgUrl: `/virtual-backgrounds/backgrounds/${virtualBackground.background}.jpeg`
    };
  }
  logger.warning(
    'invalid-virtual-background-type',
    LOG_CATEGORIES.CLIENT_LOGIC,
    { virtualBackground }
  );
  return null;
}

export async function shouldShowReferralProgram(isGuest, isVCPRoom, isCsp) {
  return isGuest && !isVCPRoom && !isCsp && isSpecialDate();
}
