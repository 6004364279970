<template>
  <!--   messaging error   -->
  <ChatDialog
    v-if="showMessagingError"
    :img-src="
      isBranded
        ? '/illustrations/dialog/img-sad-face.svg'
        : '/illustrations/dialog/img-sad-square.svg'
    "
    :dialog-title="$t('chat_dialog.general_error_title')"
    :dialog-subtitle="$t('chat_dialog.general_error_subtitle')"
    :action-button-text="$t('chat_dialog.general_error_retry_button')"
    @action-button-click="$emit('action-button-click')"
  ></ChatDialog>
  <!--   unsupported file error   -->
  <ChatDialog
    v-else-if="showUnsupportedFileDialog"
    :img-src="
      isBranded
        ? '/illustrations/dialog/img-question-mark.svg'
        : '/illustrations/dialog/img-figure-holding-unavailable-sign.svg'
    "
    :dialog-title="$t('chat_dialog.type_not_supported_error_title')"
    :dialog-subtitle="$t('chat_dialog.type_not_supported_error_subtitle')"
    :action-button-text="
      $t('chat_dialog.type_not_supported_error_got_it_button')
    "
    @action-button-click="$emit('action-button-click')"
  ></ChatDialog>
  <!--   unsupported folder error   -->
  <ChatDialog
    v-else-if="showUnsupportedFolderDialog"
    :img-src="
      isBranded
        ? '/illustrations/dialog/img-folder-icon.svg'
        : '/illustrations/dialog/img-file-types.svg'
    "
    :dialog-title="$t('chat_dialog.cant_upload_folders_title')"
    :dialog-subtitle="$t('chat_dialog.cant_upload_folders_subtitle')"
    :action-button-text="$t('chat_dialog.cant_upload_folders_got_it_button')"
    @action-button-click="$emit('action-button-click')"
  ></ChatDialog>
  <!--   max files reached error   -->
  <ChatDialog
    v-else-if="showMaxNumberOfFilesReachedDialog"
    :img-src="
      isBranded
        ? '/illustrations/dialog/img-sad-face.svg'
        : '/illustrations/dialog/img-sad-square.svg'
    "
    :dialog-title="$t('chat_dialog.max_number_of_files_error_title')"
    :dialog-subtitle="
      $t('chat_dialog.max_number_of_files_error_subtitle', {
        maxNumberOfFiles: maxNumberOfFilesToUpload()
      })
    "
    :action-button-text="
      $t('chat_dialog.max_number_of_files_error_got_it_button')
    "
    @action-button-click="$emit('action-button-click')"
  ></ChatDialog>
  <!--   max files size reached error   -->
  <ChatDialog
    v-else-if="showMaxFilesSizeReachedDialog"
    :img-src="
      isBranded
        ? '/illustrations/dialog/img-sad-face.svg'
        : '/illustrations/dialog/img-sad-square.svg'
    "
    :dialog-title="$t('chat_dialog.total_files_size_exceeded_error_title')"
    :dialog-subtitle="
      $t('chat_dialog.total_files_size_exceeded_error_subtitle', {
        maxUploadSize: maxSizeForErrorMessage()
      })
    "
    :action-button-text="
      $t('chat_dialog.total_files_size_exceeded_error_got_it_button')
    "
    @action-button-click="$emit('action-button-click')"
  ></ChatDialog>
</template>

<script>
import { mapState } from 'vuex';
import { formatBytes } from '@/helpers/attachments-utils';
import {
  MAX_NUMBER_OF_FILES_TO_UPLOAD,
  MAX_SIZE_OF_FILES_TO_UPLOAD
} from '@/consts/messaging';
import ChatDialog from '@/components/chat/ChatDialog/ChatDialog.vue';

export default {
  name: 'ChatAlerts',
  components: { ChatDialog },
  props: {
    showMessagingError: {
      type: Boolean,
      default: false
    },
    showUnsupportedFileDialog: {
      type: Boolean,
      default: false
    },
    showUnsupportedFolderDialog: {
      type: Boolean,
      default: false
    },
    showMaxFilesSizeReachedDialog: {
      type: Boolean,
      default: false
    },
    showMaxNumberOfFilesReachedDialog: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(['hasSessionEverConnected', 'isBranded'])
  },
  methods: {
    maxSizeForErrorMessage() {
      return formatBytes(MAX_SIZE_OF_FILES_TO_UPLOAD);
    },
    maxNumberOfFilesToUpload() {
      return MAX_NUMBER_OF_FILES_TO_UPLOAD;
    }
  }
};
</script>

<style scoped></style>
