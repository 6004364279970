var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "participant",
      staticClass: "participant unselectable",
      class: {
        "main-participant": _vm.isMainParticipant,
      },
      style: Object.assign({}, _vm.participantSize),
      on: {
        click: _vm.onParticipantClick,
        dblclick: _vm.onParticipantDoubleClick,
      },
    },
    [
      _vm.isParticipantRaisingHand
        ? _c("div", [
            _c("div", {
              staticClass: "raise-hand-yellow-indication",
              style: {
                transform: "translate(2px, 2px)",
              },
            }),
            _c("div", {
              staticClass: "raise-hand-yellow-indication",
              style: {
                transform: "translate(-2px, 2px)",
              },
            }),
            _c("div", {
              staticClass: "raise-hand-yellow-indication",
              style: {
                transform: "translate(2px, -2px)",
              },
            }),
            _c("div", {
              staticClass: "raise-hand-yellow-indication",
              style: {
                transform: "translate(-2px, -2px)",
              },
            }),
          ])
        : _vm.showSpeakerIndication
        ? _c("div", [
            _c("div", {
              staticClass: "speaker-indication",
              style: {
                transform:
                  "translate(" +
                  _vm.speakerIndicationSize +
                  "px, " +
                  _vm.speakerIndicationSize +
                  "px)",
              },
            }),
            _c("div", {
              staticClass: "speaker-indication",
              style: {
                transform:
                  "translate(-" +
                  _vm.speakerIndicationSize +
                  "px, " +
                  _vm.speakerIndicationSize +
                  "px)",
              },
            }),
            _c("div", {
              staticClass: "speaker-indication",
              style: {
                transform:
                  "translate(" +
                  _vm.speakerIndicationSize +
                  "px, -" +
                  _vm.speakerIndicationSize +
                  "px)",
              },
            }),
            _c("div", {
              staticClass: "speaker-indication",
              style: {
                transform:
                  "translate(-" +
                  _vm.speakerIndicationSize +
                  "px, -" +
                  _vm.speakerIndicationSize +
                  "px)",
              },
            }),
          ])
        : _vm._e(),
      _vm._t("content"),
      [
        !_vm.canShowContent
          ? _c("NoVideoParticipantTile", {
              attrs: {
                "is-loading": _vm.isLoading,
                avatarSize: _vm.avatarSize,
                participantType: _vm.participantType,
                participantProfilePicture: _vm.participantProfilePicture,
                displayName: _vm.displayName,
                isBeRightBackOn: _vm.isBeRightBackOn,
              },
            })
          : _vm._e(),
        _vm.showDisplayName
          ? _c(
              "div",
              { staticClass: "participant-name-wrapper text-ellipsis" },
              [
                _vm.showMobilePinButton
                  ? _c("vwc-icon-button-toggle", {
                      staticClass: "pin-container vvd-scheme-alternate",
                      class: {
                        pinned: _vm.isPinned,
                        "with-take-snapshot-button":
                          _vm.showMobileTakeSnapshotButton,
                      },
                      attrs: {
                        onicon: "pin-2-solid",
                        officon: "pin-2-line",
                        layout: "filled",
                        connotation: "primary",
                        dense: "",
                        on: _vm.isPinned,
                      },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.pinParticipant($event)
                        },
                      },
                    })
                  : _vm._e(),
                _vm.showMobileTakeSnapshotButton
                  ? _c("vwc-icon-button", {
                      staticClass: "take-snapshot-button vvd-scheme-alternate",
                      class: { pinned: _vm.isPinned },
                      attrs: {
                        icon: "camera-line",
                        layout: "filled",
                        dense: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.takeSnapshot(_vm.stream.streamId)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._t("prepended-name-icons"),
                _c(
                  "div",
                  {
                    staticClass:
                      "vvd-scheme-alternate name-pill-container text-ellipsis",
                    class: {
                      "name-with-icon": _vm.iconNextToDisplayName,
                    },
                  },
                  [
                    _vm.isParticipantRaisingHand
                      ? _c("img", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip.top",
                              value: _vm.raisedHandTooltip,
                              expression: "raisedHandTooltip",
                              modifiers: { top: true },
                            },
                          ],
                          staticClass: "raise-hand",
                          class: {
                            "removable-hand":
                              _vm.isMyParticipantView || _vm.isSessionOwner,
                            "with-mic": !!_vm.iconNextToDisplayName,
                          },
                          attrs: { src: "/raise_hand_img.svg" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleRaiseHand(_vm.participantId)
                            },
                          },
                        })
                      : _vm._e(),
                    _vm.iconNextToDisplayName
                      ? _c("vwc-icon", {
                          staticClass: "mic-icon",
                          attrs: { type: _vm.iconNextToDisplayName },
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "text-ellipsis displayName" }, [
                      _vm._v(_vm._s(_vm.displayName)),
                    ]),
                  ],
                  1
                ),
              ],
              2
            )
          : _vm._e(),
      ],
      _vm.showPinButton
        ? _c("vwc-icon-button-toggle", {
            staticClass: "pin-container vvd-scheme-alternate",
            class: { pinned: _vm.isPinned },
            attrs: {
              "data-cy": "pin-container",
              onicon: "pin-2-solid",
              officon: "pin-2-line",
              layout: "filled",
              connotation: "primary",
              on: _vm.isPinned,
              dense: "",
            },
            on: {
              click: function ($event) {
                $event.stopPropagation()
                return _vm.pinParticipant($event)
              },
            },
          })
        : _vm._e(),
      _vm.showTakeSnapshotButton
        ? _c("vwc-icon-button", {
            staticClass: "take-snapshot-button vvd-scheme-alternate",
            attrs: {
              "data-cy": "take-snapshot-button",
              icon: "camera-line",
              layout: "filled",
              dense: "",
            },
            on: {
              click: function ($event) {
                return _vm.takeSnapshot(_vm.stream.streamId)
              },
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }