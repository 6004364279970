<template>
  <div class="messages-container">
    <div v-if="chatMessages.length === 0" class="empty-state-container">
      <vwc-empty-state
        :heading="$t('messages_list.empty_state_title')"
        :body="$t('messages_list.empty_state_description')"
      >
        <img slot="graphic" data-cy="empty-chat-img" :src="emptyStatImg" />
      </vwc-empty-state>
    </div>
    <div
      v-else
      ref="messagesRows"
      class="messages-rows"
      :class="{ 'smooth-scroll': smoothScroll }"
      @scroll="debouncedScrollHandler"
    >
      <MessageRow
        v-for="message in chatMessages"
        :key="message.messageId"
        :message="message"
        @resend="$emit('resend', message)"
        @copyToClipboard="$emit('copyToClipboard', message.body)"
        @openImageView="$emit('openImageView', message)"
      />
      <div class="bottom-div-container">
        <div
          v-observe-visibility="{
            callback: updateIsChatScrolledToBottom,
            throttle: 200,
            throttleOptions: {
              leading: 'visible'
            }
          }"
          class="bottom-div"
        />
      </div>
    </div>
    <transition name="fade">
      <div
        v-if="!isChatScrolledToBottom && chatMessages.length > 0"
        class="scroll-bottom-button"
        @click="scrollToBottom"
      >
        <v-icon
          iconName="Vlt-icon-down-full"
          class="Vlt-black scroll-bottom-icon"
        />
        <ChatNotification class="overlaping-notification" />
      </div>
    </transition>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import debounce from 'lodash.debounce';
import MessageRow from '@/components/rows/MessageRow.vue';
import ChatNotification from '@/components/ChatNotification.vue';

export default {
  name: 'MessagesList',

  components: {
    MessageRow,
    ChatNotification
  },

  data() {
    return {
      smoothScroll: true,
      debouncedScrollHandler: () => {},
      scrollPosition: null,
      lastScreenHeight: null
    };
  },

  computed: {
    ...mapState(['isBranded']),
    ...mapState('messaging', [
      'isChatScrolledToBottom',
      'lastChatScrollPosition'
    ]),
    ...mapGetters('messaging', ['chatMessages', 'isLastMessageSentByMe']),
    ...mapGetters(['isMobileWebMode']),

    emptyStatImg() {
      return this.isBranded
        ? '/illustrations/empty-state/empty-chat-branded.svg'
        : '/illustrations/empty-state/empty-chat.svg';
    }
  },

  watch: {
    chatMessages() {
      if (this.isLastMessageSentByMe || this.isChatScrolledToBottom) {
        this.$nextTick(this.scrollToBottom);
      }
    }
  },

  mounted() {
    this.lastScreenHeight = window.innerHeight;
    this.executeWithoutSmoothScroll(this.scrollToInitialPosition);
    if (this.isMobileWebMode) {
      window.addEventListener('resize', this.screenResized);
    }

    this.debouncedScrollHandler = debounce(() => {
      const messagesRows = this.$refs.messagesRows;
      if (messagesRows) {
        this.scrollPosition = messagesRows.scrollTop;
      }
    }, 100);
  },

  destroyed() {
    // write the scroll position to the state just when destroyed to reduce store actions
    this.updateLastChatScrollPosition(this.scrollPosition);
    this.updateIsChatScrolledToBottom(false);
    window.removeEventListener('resize', this.screenResized);
  },

  methods: {
    ...mapActions('messaging', [
      'updateIsChatScrolledToBottom',
      'updateLastChatScrollPosition'
    ]),

    scrollToBottom() {
      const element = this.$refs.messagesRows;
      if (element) {
        element.scrollTop = element.scrollHeight;
      }
    },

    scrollToInitialPosition() {
      if (this.lastChatScrollPosition === undefined) {
        this.scrollToBottom();
      } else {
        const messagesRows = this.$refs.messagesRows;
        if (messagesRows) {
          messagesRows.scrollTop = this.lastChatScrollPosition;
        }
      }
    },

    executeWithoutSmoothScroll(action) {
      this.smoothScroll = false;
      this.$nextTick(() => {
        action();
        this.$nextTick(() => {
          this.smoothScroll = true;
        });
      });
    },

    screenResized() {
      if (!this.isMobileWebMode) {
        return;
      }
      const windowHeightDiff = this.lastScreenHeight - window.innerHeight;

      this.executeWithoutSmoothScroll(() => {
        const messagesRows = this.$refs.messagesRows;
        if (messagesRows) {
          //When messageRows scroll position has different value than the last one saved (happens when closing virtual keyboard while being at screen's lower part)
          //messageRows scroll top should be last scroll position added with windows height difference
          messagesRows.scrollTop =
            this.scrollPosition === messagesRows.scrollTop
              ? messagesRows.scrollTop + windowHeightDiff
              : this.scrollPosition + windowHeightDiff;
          this.scrollPosition = messagesRows.scrollTop;
        }
        this.lastScreenHeight = window.innerHeight;
      });
    }
  }
};
</script>

<style scoped>
.messages-container {
  display: flex;
  position: relative;
  flex: 1;
  height: 100%;
  overflow-y: hidden;
}

.empty-state-container {
  display: flex;
  flex: 2;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.messages-rows {
  position: relative;
  flex: 1;
  height: 100%;
  overflow-y: auto;
}

.smooth-scroll {
  scroll-behavior: smooth;
}

.scroll-bottom-button {
  position: absolute;
  right: 15px;
  bottom: 3px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #ffffff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  opacity: 1;
  transform: scale(1);
  transition: all 0.2s;
}

.mobile .scroll-bottom-button {
  cursor: unset;
}

.scroll-bottom-icon {
  width: 14px;
  height: 14px;
}

.overlaping-notification {
  position: absolute;
  transform: translate(12px, -12px);
}

/* we use this trick to make the bottom-div appear at the bottom of the messages without occupying any height-space */
.bottom-div-container {
  position: relative;
  height: 0;
  width: 100%;
}

.bottom-div {
  position: absolute;
  bottom: 0;
  height: 20px;
  width: 1px;
}

.fade-enter,
.fade-leave-to {
  transform: scale(0.8);
  opacity: 0;
}
</style>
