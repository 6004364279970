/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-pause-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M5 1H2c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h3c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1zM14 1h-3c-.6 0-1 .4-1 1v12c0 .6.4 1 1 1h3c.6 0 1-.4 1-1V2c0-.6-.4-1-1-1z"/>'
  }
})
