export default {
  SET_CSS_VARIABLES: (state, cssVariables) =>
    (state.cssVariables = cssVariables),

  SET_MAIN_COLOR: (state, color) => {
    state.mainColor = color;
  },

  SET_BRAND_TEXT: (state, brandText) => {
    state.brandText = brandText;
  },

  SET_SHORT_COMPANY_URL: (state, shortCompanyUrl) => {
    state.shortCompanyUrl = shortCompanyUrl;
  },

  SET_BRAND_IMAGE_COLORED: (state, brandImageColored) => {
    state.brandImageColored = brandImageColored;
  },

  SET_BRAND_IMAGE_WHITE: (state, brandImageWhite) => {
    state.brandImageWhite = brandImageWhite;
  },

  SET_BRANDED_FAVICON: (state, brandedFavicon) => {
    state.brandedFavicon = brandedFavicon;
  },

  SET_DEBUGGING_COLOR_FROM_QUERY_STRING: (
    state,
    debuggingColorFromQueryString
  ) => {
    state.debuggingColorFromQueryString = debuggingColorFromQueryString;
  },

  SET_HIDE_POWERED_BY_VONAGE: (state, hidePoweredByVonage) => {
    state.hidePoweredByVonage = hidePoweredByVonage;
  }
};
