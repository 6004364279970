/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-rss': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 22.5h-4.05c0-9.3-7.65-16.95-16.95-16.95V1.5c11.55 0 21 9.45 21 21zm-18 0a3 3 0 100-6 3 3 0 000 6zm7.05 0h3.9c0-7.65-6.3-13.95-13.95-13.95v3.9c5.55 0 10.05 4.5 10.05 10.05z"/>'
  }
})
