<template>
  <div class="device-is-disabled-banner" data-cy="device-is-disabled-banner">
    <div class="container">
      <vwc-icon type="warning-solid" class="icon vvd-scheme-alternate" />
      <div class="text-container">
        <div class="title">{{ title }}</div>
        <div class="text">{{ text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DisabledDeviceBanner',
  props: {
    isMicDisabled: {
      type: Boolean,
      default: false
    },
    isVideoDisabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    text() {
      if (this.isMicDisabled && this.isVideoDisabled) {
        return this.$t(
          'devices_are_disabled_banner.mic_and_camera_are_disabled_text'
        );
      } else if (this.isMicDisabled) {
        return this.$t('devices_are_disabled_banner.mic_is_disabled_text');
      } else if (this.isVideoDisabled) {
        return this.$t('devices_are_disabled_banner.mic_is_disabled_text');
      }
      return '';
    },
    title() {
      if (this.isMicDisabled && this.isVideoDisabled) {
        return this.$t(
          'devices_are_disabled_banner.mic_and_camera_are_disabled_title'
        );
      } else if (this.isMicDisabled) {
        return this.$t('devices_are_disabled_banner.mic_is_disabled_title');
      } else if (this.isVideoDisabled) {
        return this.$t('devices_are_disabled_banner.video_is_disabled_title');
      }
      return '';
    }
  }
};
</script>

<style scoped>
.device-is-disabled-banner {
  display: flex;
  align-self: center;
  align-items: center;
  position: absolute;
  top: 8px;
  z-index: 100;
}
.text-container {
  align-content: center;
  justify-content: center;
  margin-left: 10px;
  display: flex;
  flex-direction: column;
  padding-right: 12px;
  line-height: 20px;
  color: black;
}
.text {
  font-size: 12px;
  font-weight: 400;
}
.title {
  font-size: 14px;
  font-weight: 600;
}
.icon {
  margin-left: 12px;
  color: #e07902;
}
.container {
  display: flex;
  align-items: center;
  min-width: 250px;
  min-height: 56px;
  background-color: white;
  border-radius: 6px;
}
</style>
