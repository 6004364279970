/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-envelope': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1 2.5c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5v2.2L8 8.4 1 4.7V2.5zM0 5v8.5c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-11c0-.8-.7-1.5-1.5-1.5h-13C.7 1 0 1.7 0 2.5V5zm15 .8v7.7c0 .3-.2.5-.5.5h-13c-.3 0-.5-.2-.5-.5V5.8l6.8 3.6c.1.1.3.1.5 0L15 5.8z"/>'
  }
})
