/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'callout-shoutout': {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M19.75.125C19.5 0 19.25 0 19.125 0l-8 3.75h-6.75C2 3.75 0 5.75 0 8.125c0 2.25 1.75 4.125 4 4.375l3.125 6.125A2.505 2.505 0 0010.5 19.75a2.505 2.505 0 001.125-3.375l-1.125-2.25c-.125-.25-.5-.375-.875-.25-.25.125-.375.5-.25.875L10.5 17c.375.5.125 1.25-.5 1.625s-1.375.125-1.625-.5l-3-5.625h5.75l8 3.75c.25.125.375.125.625 0 .125-.125.25-.375.25-.5v-15c0-.375-.125-.5-.25-.625zm-1 14.5L11.5 11.25H4.375A3.095 3.095 0 011.25 8.125 3.095 3.095 0 014.375 5H11.5l7.25-3.375v13z"/></g><defs><clipPath id="clip0"><path pid="1" _fill="#fff" d="M0 0h20v20H0z"/></clipPath></defs>'
  }
})
