import Vue from 'vue';

export default {
  SET_WAITING_PARTICIPANTS: (state, waitingParticipants) =>
    (state.waitingParticipants = waitingParticipants),

  UPDATE_PARTICIPANT: (state, payload) => {
    const participantIndex = state.waitingParticipants.findIndex(
      (participant) => participant.participantId === payload.participantId
    );
    Vue.set(state.waitingParticipants, participantIndex, {
      ...state.waitingParticipants[participantIndex],
      ...payload
    });
  },

  SET_IS_IN_WAITING_ROOM: (state, isInWaitingRoom) =>
    (state.isInWaitingRoom = isInWaitingRoom),

  SET_IS_OWNER_IN_MEETING: (state, isOwnerInMeeting) =>
    (state.isOwnerInMeeting = isOwnerInMeeting),

  SET_KEEPALIVE_ERROR_COUNTER: (state, keepAliveErrorCounter) =>
    (state.keepAliveErrorCounter = keepAliveErrorCounter),

  SET_IS_APPROVE_ALL_IN_PROGRESS: (state, isApproveAllInProgress) =>
    (state.isApproveAllInProgress = isApproveAllInProgress),

  SET_JOINED_WAITING_ROOM_TIMESTAMP: (state, joinedWaitingRoomTimestamp) =>
    (state.joinedWaitingRoomTimestamp = joinedWaitingRoomTimestamp),

  SET_HAS_FINISHED_ARTIFICIAL_TRANSITION_TO_WAITING_ROOM: (
    state,
    hasFinishedArtificalTransitionToWaitingRoom
  ) =>
    (state.hasFinishedArtificalTransitionToWaitingRoom = hasFinishedArtificalTransitionToWaitingRoom),

  SET_IS_DISABLE_WAITING_ROOM_MODAL_VISIBLE: (
    state,
    isDisableWaitingRoomModalVisible
  ) =>
    (state.isDisableWaitingRoomModalVisible = isDisableWaitingRoomModalVisible),

  SET_LAST_KEEPALIVE_RESPONSE_TIMESTAMP: (
    state,
    lastKeepaliveResponseTimestamp
  ) => (state.lastKeepaliveResponseTimestamp = lastKeepaliveResponseTimestamp),

  SET_LAST_KEEPALIVE_EXPIRES_IN: (state, lastKeepaliveExpiresIn) =>
    (state.lastKeepaliveExpiresIn = lastKeepaliveExpiresIn)
};
