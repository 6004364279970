var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", {
    staticClass: "Vlt-spinner",
    class: {
      "Vlt-spinner--white": _vm.white,
      "Vlt-spinner--small": _vm.size === "sm",
      "Vlt-spinner--smaller": _vm.size === "xs",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }