/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-audio-min': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.183 9.166h2.741l4.489-3.884v13.436l-4.489-3.885H2.183a.703.703 0 01-.683-.722V9.889c0-.425.33-.723.683-.723zm0-1.5h2.182l5.72-4.95a.5.5 0 01.827.378v17.812a.5.5 0 01-.827.378l-5.72-4.95H2.183C.977 16.333 0 15.338 0 14.11V9.889C0 8.66.977 7.666 2.183 7.666zm12.35.666a.75.75 0 00-1.07 1.05c1.41 1.436 1.41 3.802 0 5.237a.75.75 0 101.07 1.05c1.983-2.018 1.983-5.319 0-7.337z"/>'
  }
})
