/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-ppt-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.5 9h9v13.5c0 .9-.6 1.5-1.5 1.5H3c-.9 0-1.5-.6-1.5-1.5v-21C1.5.6 2.1 0 3 0h10.5v9zM9 15.429c0 .642-.214 1.178-.643 1.607-.428.428-.964.535-1.714.535h-.536v2.036H4.5V13.5h2.143c.75 0 1.393.214 1.714.536.322.321.643.75.643 1.393zm-2.893.75h.322c.214 0 .428-.108.642-.215.215-.107.215-.321.215-.535 0-.429-.215-.643-.643-.643h-.536v1.393zm8.25-.75c0 .642-.214 1.178-.643 1.607-.428.428-.964.535-1.714.535h-.428v2.036H9.963V13.5h2.143c.75 0 1.393.214 1.715.536.32.321.535.75.535 1.393zm-2.678.75h.214c.214 0 .428-.108.643-.215.214-.107.214-.321.214-.535 0-.429-.214-.643-.643-.643h-.428v1.393zm4.714 3.428H18v-4.714h1.5V13.5h-4.607v1.393h1.5v4.714zM16.5 6V0l6 6h-6z"/>'
  }
})
