/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cart-full-glyph': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.217 4.5h-1.45l-3.178-3-2.832 3H6.75L6 1.2C5.85.45 5.25 0 4.5 0H0v3h3.3L6 15.3c.15.75.75 1.2 1.5 1.2h12c.6 0 1.2-.45 1.35-1.05l3-9c.3-.75-.15-1.95-1.35-1.95h-1.28l-3.08-3-2.923 3zM7.5 24a3 3 0 100-6 3 3 0 000 6zm15-3a3 3 0 11-6 0 3 3 0 016 0z"/>'
  }
})
