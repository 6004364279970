import logger from '@/services/logging/logger';

// This service allows us to wrap the local storage and session storage, so we can disable them in embedded.
class StorageService {
  isStorageEnabled = true;
  storage = null;

  constructor(storage) {
    this.storage = storage;
  }

  enable() {
    this.isStorageEnabled = true;
  }

  disable() {
    this.isStorageEnabled = false;
    try {
      this.clear();
    } catch (error) {
      logger.log(`failed to disable storage service`, error);
    }
  }

  setItem(key, value) {
    if (this.isStorageEnabled) {
      this.storage.setItem(key, value);
    }
  }

  getItem(key) {
    if (this.isStorageEnabled) {
      return this.storage.getItem(key);
    }
    return null;
  }

  removeItem(key) {
    if (this.isStorageEnabled) {
      return this.storage.removeItem(key);
    }
  }

  clear() {
    if (this.isStorageEnabled) {
      this.storage.clear();
    }
  }
}

export const localStorageService = new StorageService(localStorage);
export const sessionStorageService = new StorageService(sessionStorage);
