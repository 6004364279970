/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-dollar': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.85.15l7.5 7.5c0 .075.037.188.075.3.037.112.075.225.075.3v15c0 .45-.3.75-.75.75H2.25c-.45 0-.75-.3-.75-.75V.75c0-.45.3-.75.75-.75h12c.075 0 .188.037.3.075.112.038.225.075.3.075zm5.1 7.35L15 2.55V7.5h4.95zM3 22.5h18V9h-6.75c-.45 0-.75-.3-.75-.75V1.5H3v21zm8.81-5.765c.116-.26.172-.54.165-.824a1.788 1.788 0 00-.46-1.242 4.35 4.35 0 00-1.65-.966l-.476-.183v-2.284a6.75 6.75 0 011.958.464l.459-1.15a6.918 6.918 0 00-2.417-.514V9h-.834v1.057c-.663.038-1.3.274-1.83.675a1.768 1.768 0 00-.675 1.426c-.015.495.155.98.476 1.358.417.431.933.755 1.504.944l.525.195v2.348a5.711 5.711 0 01-1.293-.186A6.428 6.428 0 016 16.375V17.7c.81.33 1.68.491 2.555.476V19.5h.834v-1.361a3.382 3.382 0 001.92-.729c.215-.185.387-.415.502-.675zM7.75 12.74a.84.84 0 01-.225-.595.739.739 0 01.265-.576c.221-.174.487-.28.767-.308v1.95c-.299-.099-.574-.26-.807-.47zm2.682 2.937a.779.779 0 01.061.327c0 .52-.368.834-1.104.946v-2.001c.309.097.597.252.85.455a.78.78 0 01.193.273z"/>'
  }
})
