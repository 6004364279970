var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vwc-textarea", {
    directives: [
      {
        name: "focus",
        rawName: "v-focus",
        value: _vm.focus,
        expression: "focus",
      },
    ],
    ref: "textarea",
    attrs: {
      tabindex: "0",
      disabled: _vm.disabled,
      outlined: _vm.outlined ? _vm.outlined : null,
      label: _vm.label,
      required: _vm.required,
      maxLength: _vm.maxLength,
      resizable: _vm.resizable,
      autoValidate: _vm.autoValidate,
      validationMessage: _vm.validationMessage,
      rows: _vm.rows,
    },
    domProps: { value: _vm.value },
    on: {
      input: function ($event) {
        return _vm.$emit("input", $event.target.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }