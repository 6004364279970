var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "empty-screen-main-content",
      attrs: { "data-cy": "empty-screen-container" },
    },
    [
      _vm.roomPinCode
        ? _c("div", { staticClass: "box-wrapper Vlt-gradient--skittles2" }, [
            _c(
              "div",
              {
                staticClass: "box box-container",
                class: { guest: _vm.isGuest },
              },
              [
                _vm.isBranded
                  ? _c("vwc-icon", {
                      staticClass: "logo",
                      attrs: { type: "waving-solid" },
                    })
                  : _c("vwc-icon", {
                      staticClass: "logo",
                      attrs: { type: "vonage-solid" },
                    }),
                _c("div", { staticClass: "box-title" }, [
                  _c(
                    "div",
                    {
                      staticClass: "box-title--text unselectable",
                      attrs: { "data-cy": "empty-state-title" },
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("empty_video_screen.title")) + " "
                      ),
                    ]
                  ),
                ]),
                !_vm.isGuest
                  ? _c(
                      "div",
                      {
                        staticClass: "sub-title",
                        attrs: { "data-cy": "sub-title" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.$t("empty_video_screen.subtitle")) +
                            " "
                        ),
                      ]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "sub-title",
                        attrs: { "data-cy": "sub-title" },
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("empty_video_screen.subtitle_guest")
                            ) +
                            " "
                        ),
                      ]
                    ),
                _c(
                  "div",
                  {
                    staticClass: "buttons-container",
                    class: { "buttons-container-only-one-button": _vm.isGuest },
                  },
                  [
                    _c("vwc-button", {
                      class: {
                        "copy-button": !_vm.isGuest,
                      },
                      attrs: {
                        label: _vm.$t("empty_video_screen.copy_meeting_button"),
                        layout: "filled",
                        icon: "copy-2-solid",
                        type: "submit",
                        "data-cy": "copy-button",
                        dense: "",
                      },
                      on: { click: _vm.copyMeetingDetails },
                    }),
                    !_vm.isGuest
                      ? _c("vwc-button", {
                          staticClass: "invite-button",
                          attrs: {
                            label: _vm.$t("empty_video_screen.invite_button"),
                            layout: "outlined",
                            icon: "add-user-solid",
                            type: "submit",
                            "data-cy": "invite-button",
                            disabled: _vm.areInvitesDisabled,
                            dense: "",
                          },
                          on: { click: _vm.openInviteModal },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }