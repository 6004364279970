/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-screen-share-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 0h21c.9 0 1.5.6 1.5 1.5v15c0 .9-.6 1.5-1.5 1.5h-21C.6 18 0 17.4 0 16.5v-15C0 .6.6 0 1.5 0zM15 9L9 4.5v9L15 9zM6 21h12c.9 0 1.5.6 1.5 1.5S18.9 24 18 24H6c-.9 0-1.5-.6-1.5-1.5S5.1 21 6 21z"/>'
  }
})
