/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-shield-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.29 8.47l.02.115C1.048 12.869 3.546 20.618 12 24 24 19.2 24 5.6 24 5.6A21.068 21.068 0 0112 0 21.068 21.068 0 010 5.6s0 1.137.29 2.87zm19.401 3.477c-1.203 3.208-3.437 6.687-7.691 8.773-4.254-2.086-6.488-5.565-7.691-8.773a21.506 21.506 0 01-1.047-3.892A24.069 24.069 0 0012 3.918a24.069 24.069 0 008.738 4.137 21.507 21.507 0 01-1.047 3.892z"/>'
  }
})
