var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Vlt-callout Vlt-callout--generic attachment-box",
      class: { "mobile-chat": _vm.isMobileWebChat },
    },
    [
      _c(
        "div",
        { staticClass: "thumbnail" },
        [
          _c("v-icon", {
            staticClass: "icon",
            class: _vm.attachment.icon,
            attrs: { iconName: _vm.attachment.icon },
          }),
          _vm.isLoading
            ? _c("v-spinner", {
                staticClass: "spinner",
                attrs: { size: "sm", white: _vm.isMobileWebChat },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "Vlt-callout__content text-ellipsis" }, [
        _c("div", { staticClass: "file-info" }, [
          _c("div", { staticClass: "filename text-ellipsis" }, [
            _vm._v(_vm._s(_vm.attachment.name)),
          ]),
          _vm.isUnsupported
            ? _c("div", { staticClass: "unsupported" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$t("attachment_box.unsupported_file_text")) +
                    " "
                ),
              ])
            : _c("div", { staticClass: "file-size" }, [
                _vm._v(_vm._s(_vm.attachment.size)),
              ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }