/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-plus': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.35 7.65l-7.5-7.5a1.16 1.16 0 01-.3-.075C14.437.038 14.325 0 14.25 0h-12C1.8 0 1.5.3 1.5.75v22.5c0 .45.3.75.75.75h19.5c.45 0 .75-.3.75-.75v-15c0-.075-.038-.188-.075-.3a1.16 1.16 0 01-.075-.3zM15 2.55l4.95 4.95H15V2.55zm6 19.95H3v-21h10.5v6.75c0 .45.3.75.75.75H21v13.5zM8.75 12a.75.75 0 01.75.75V15h2.25a.75.75 0 010 1.5H9.5v2.25a.75.75 0 01-1.5 0V16.5H5.75a.75.75 0 010-1.5H8v-2.25a.75.75 0 01.75-.75z"/>'
  }
})
