<template>
  <emoji-picker ref="picker" class="light" />
</template>

<script>
import * as english from '../i18n/locales/en.json';
import * as hebrew from '../i18n/locales/he.json';
import * as spanish from '../i18n/locales/es.json';
import * as portuguese from '../i18n/locales/pt-BR.json';
import * as italian from '../i18n/locales/it.json';
import * as catalan from '../i18n/locales/ca.json';
import * as french from '../i18n/locales/fr.json';
import * as german from '../i18n/locales/de.json';
import * as arabic from '../i18n/locales/ar.json';
import * as chinese_mainland from '../i18n/locales/zh-cn.json';
import * as chinese_taiwan from '../i18n/locales/zh-tw.json';

import { SUPPORTED_LOCALES } from '@/consts/global-consts';
import logger from '@/services/logging/logger';

//These translations are compatible with version 1.13.1 of the 'emoji-picker-element' package.
//Please notice to update the translations in case of upgrading the package!
const translations = {
  [SUPPORTED_LOCALES.ENGLISH]: english['emoji-picker'],
  [SUPPORTED_LOCALES.HEBREW]: hebrew['emoji-picker'],
  [SUPPORTED_LOCALES.SPANISH]: spanish['emoji-picker'],
  [SUPPORTED_LOCALES.PORTUGUESE]: portuguese['emoji-picker'],
  [SUPPORTED_LOCALES.ITALIAN]: italian['emoji-picker'],
  [SUPPORTED_LOCALES.CATALAN]: catalan['emoji-picker'],
  [SUPPORTED_LOCALES.FRENCH]: french['emoji-picker'],
  [SUPPORTED_LOCALES.GERMAN]: german['emoji-picker'],
  [SUPPORTED_LOCALES.ARABIC]: arabic['emoji-picker'],
  [SUPPORTED_LOCALES.CHINESE_MAINLAND]: chinese_mainland['emoji-picker'],
  [SUPPORTED_LOCALES.CHINESE_TAIWAN]: chinese_taiwan['emoji-picker']
};

export default {
  name: 'VPicker',

  watch: {
    '$i18n.locale': function () {
      this.setEmojiPickerLocale(this.$i18n.locale);
    }
  },

  mounted() {
    this.setEmojiPickerLocale(this.$i18n.locale);

    this.$refs.picker.addEventListener('emoji-click', this.emitEmoji);

    // Style the inner component
    const style = document.createElement('style');
    style.innerHTML =
      '.emoji { user-select: none; } .search { transition: border-color .5s; } .search:focus { outline: none; border-color: #000; } .search:hover { outline: none; border-color: #000; } .search-wrapper { margin: 0 2px; }';

    this.$refs.picker.shadowRoot.appendChild(style);
  },

  beforeDestroy() {
    this.$refs.picker.removeEventListener('emoji-click', this.emitEmoji);
  },

  methods: {
    emitEmoji(event) {
      this.$emit('emojiClick', event.detail.unicode);
    },

    setEmojiPickerLocale(locale) {
      const translation = translations[locale];
      if (translation) {
        this.$refs.picker.i18n = translation;
      } else {
        this.$refs.picker.i18n = translations[SUPPORTED_LOCALES.ENGLISH];
        logger.warning(
          `Set the emoji-picker with the fallback locale 'en' instead of the current locale '${locale}'`
        );
      }
    }
  }
};
</script>

<style>
/* The emoji picker is a web-componenet, this is how we style it */
emoji-picker {
  --num-columns: 9;
  --emoji-size: 24px;
  width: 356px;
  height: 420px;
  z-index: 99999;
}
</style>
