/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-tabs': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.6 8h-19C1.6 8 1 8.4 1 9v6.2c0 .6.6 1 1.6 1h19c1 0 1.7-.4 1.7-1V9c-.1-.6-.7-1-1.7-1zm-6.7 1.3v5.6H9.2V9.3h5.7zM2.2 14.9V9.3h5.7v5.6H2.2zm19.7 0h-5.6V9.3h5.6v5.6z"/>'
  }
})
