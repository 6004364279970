/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-asterisk': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.7 13.4H1.3c-.7 0-1.3-.6-1.3-1.3s.6-1.3 1.3-1.3h8.4L5.5 3.6c-.2-.3-.2-.7-.1-1s.3-.6.6-.8c.6-.4 1.5-.1 1.8.5L12 9.5l4.2-7.2c.4-.6 1.2-.9 1.8-.5.3.2.5.5.6.8s0 .7-.1 1l-4.2 7.2h8.4c.7 0 1.3.6 1.3 1.3s-.6 1.3-1.3 1.3h-8.4l4.2 7.2c.2.3.2.7.1 1s-.3.6-.6.8c-.6.4-1.5.1-1.8-.5L12 14.7l-4.2 7.2c-.4.6-1.2.9-1.8.5-.3-.2-.5-.5-.6-.8s0-.7.1-1l4.2-7.2z"/>'
  }
})
