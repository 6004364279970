/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-8': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zM9 3a3 3 0 116 0 3 3 0 01-6 0zM3 5.25a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm-3 1.5a3 3 0 116 0 3 3 0 01-6 0zm19.5 0a1.5 1.5 0 113 0 1.5 1.5 0 01-3 0zm1.5-3a3 3 0 100 6 3 3 0 000-6zm-9 5.625a3 3 0 100 6 3 3 0 000-6zm-4.5 3a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zm-.27 7.407A38.748 38.748 0 0112 19.5c1.945 0 3.558.131 4.77.282a2.251 2.251 0 011.98 2.235v.483H5.25v-.483c0-1.138.846-2.094 1.98-2.235zM12 18a40.26 40.26 0 00-4.955.294 3.751 3.751 0 00-3.295 3.723V24h16.5v-1.983c0-1.9-1.414-3.49-3.295-3.723A40.258 40.258 0 0012 18z"/>'
  }
})
