/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-info': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.3 12C1.3 6.118 6.118 1.3 12 1.3S22.7 6.118 22.7 12 17.882 22.7 12 22.7 1.3 17.882 1.3 12zM12 0C5.4 0 0 5.4 0 12s5.4 12 12 12 12-5.4 12-12S18.6 0 12 0zm-1.5 10.5V18h3v-7.5h-3zm0-3c0 .9.6 1.5 1.5 1.5s1.5-.6 1.5-1.5S12.9 6 12 6s-1.5.6-1.5 1.5z"/>'
  }
})
