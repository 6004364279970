var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "streamsContainer",
      staticClass: "participants-tile-list",
      class: _vm.streamsContainerClasses,
    },
    [
      _c("resize-observer", { on: { notify: _vm.updateStreamSizesThrottled } }),
      !_vm.showVideoStreams ? _c("EmptyVideoScreen") : _vm._e(),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showVideoStreams,
              expression: "showVideoStreams",
            },
          ],
          staticClass: "streams",
          style: _vm.sizeStyle,
        },
        [
          _vm.isWatchTogetherActive
            ? _c("WatchTogetherView", {
                attrs: { preferred: _vm.streamsStyle.main },
              })
            : _vm._e(),
          _vm.isWhiteboardActive
            ? _c("Whiteboard", {
                staticClass: "video-stream",
                attrs: {
                  sessionId: _vm.whiteboardId,
                  preferred:
                    _vm.dominantSpeakerStreamId === "whiteboard" &&
                    !_vm.isWatchTogetherMaximized
                      ? _vm.streamsStyle.main
                      : _vm.streamsStyle.small,
                },
                on: {
                  click: function ($event) {
                    return _vm.$emit("click")
                  },
                },
              })
            : _vm._e(),
          _vm._l(_vm.streams, function (stream) {
            return _c("OTParticipantView", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.shouldHideStream(stream),
                  expression: "!shouldHideStream(stream)",
                },
              ],
              key: stream.isPublisher ? "MyStream" : stream.streamId,
              staticClass: "video-stream",
              class: stream.isPublisher ? "my-stream" : "",
              attrs: {
                stream: stream,
                subscriberStatus: _vm.streamsSubscribersStatus[stream.streamId],
                preferred:
                  stream.streamId === _vm.dominantSpeakerStreamId &&
                  !_vm.isWatchTogetherMaximized
                    ? _vm.streamsStyle.main
                    : _vm.streamsStyle.small,
                isPageVisible: _vm.isPageVisibleThrottled,
                preferredFitMode: _vm.preferredFitMode,
              },
            })
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }