/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'callout-good': {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M6.25 17.124l-5.5-5.375a.604.604 0 010-.875c.25-.25.625-.25.875 0l4.625 4.5 12-12c.25-.25.625-.25.875 0s.25.625 0 .875L6.25 17.124z"/>'
  }
})
