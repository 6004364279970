/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-add-group': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3.8 6.5c-.8 0-1.5.7-1.5 1.5S3 9.5 3.8 9.5 5.3 8.8 5.3 8c-.1-.8-.7-1.5-1.5-1.5zM1.3 8c0-1.4 1.1-2.5 2.5-2.5S6.2 6.6 6.2 8s-1.1 2.5-2.5 2.5S1.3 9.3 1.3 8zm10.9-1.5c-.8 0-1.5.7-1.5 1.5s.7 1.5 1.5 1.5 1.5-.7 1.5-1.5-.7-1.5-1.5-1.5zM9.8 8c0-1.4 1.1-2.5 2.5-2.5s2.5 1.1 2.5 2.5-1.1 2.5-2.5 2.5S9.8 9.3 9.8 8zM12.2 12.7H12.5c1 0 1.6.4 2 .8.3.2.4.5.4.9v.6H9.6v-.6c0-.3.1-.7.4-.9.4-.3 1.2-.7 2.2-.8m0-1c-1.5.1-2.5.7-2.9 1-.5.4-.8 1-.8 1.7v.6c0 .6.4 1 1 1H15c.6 0 1-.4 1-1v-.6c0-.7-.3-1.3-.8-1.7-.6-.4-1.3-.9-2.6-1h-.4zM3.7 12.7H4c1 0 1.6.4 2 .8.3.2.4.5.4.9v.6H1v-.6c0-.3.1-.7.4-.9.4-.3 1.2-.7 2.3-.8m0-1c-.1 0-.1 0 0 0-1.5.1-2.5.7-2.9 1-.5.4-.8 1-.8 1.7v.6c0 .6.4 1 1 1h5.4c.6 0 1-.4 1-1v-.6c0-.7-.3-1.3-.8-1.7-.6-.4-1.3-.9-2.6-1H3.7zM8 0c.3 0 .5.2.5.5v5c0 .3-.2.5-.5.5s-.5-.2-.5-.5v-5c0-.3.2-.5.5-.5z"/><path pid="1" d="M5 3c0-.3.2-.5.5-.5h5c.3 0 .5.2.5.5s-.2.5-.5.5h-5c-.3 0-.5-.2-.5-.5z"/>'
  }
})
