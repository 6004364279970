/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-cloud': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M7.5 2a5.5 5.5 0 00-5.49 5.169A3 3 0 003 13h10a3 3 0 100-6h-.022A5.501 5.501 0 007.5 2zM3.001 7.481v-.008a4.5 4.5 0 018.999 0l-.001.01a.5.5 0 00.596.557A2 2 0 1113 12H2.999a2 2 0 01-.396-3.959A.5.5 0 003 7.481z"/>'
  }
})
