/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-pause': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.866 2.167c-.168-.167-.504-.5-1.008-.5h-.335l-.118.036C3.668 1.924 1.047 2.71 1.836 6c.672 3.667 3.19 7.167 6.213 10.333 3.358 3.5 7.892 6 10.578 6h.504c2.518-.5 3.526-3.5 2.686-4.166-2.35-1.834-3.526-2.5-4.701-2.5h-.504c-1.343.166-1.847 1.5-1.847 1.5s-2.35-.5-4.87-3.167c-2.854-2.833-3.19-4.833-3.19-4.833 1.008-.667 1.512-1.334 1.512-2.5 0-1.167-1.847-3.834-2.351-4.5zM4.186 0h.672C5.866 0 6.705.5 7.21 1.167 8.385 2.833 9.896 5 9.896 6.5 10.064 8 9.392 9 8.72 9.667c.336.833 1.008 1.833 2.351 3.166 1.029 1.167 2.057 1.823 2.636 2.192l.219.142c.503-.5 1.175-1 2.182-1.167h.84c1.68 0 3.19.833 5.709 2.833.84.667 1.175 1.834.84 3.167-.504 1.833-2.015 3.667-4.366 4h-.672c-3.19 0-8.227-2.833-11.754-6.5C3.011 13.5.83 9.833.157 6.333-.515 3.167.997.833 4.187 0zm12.426 0h-3.694c-.504 0-.84.333-.84.667v8.5c0 .333.336.666.672.666h3.694c.336 0 .672-.333.672-.666v-8.5C17.284.333 16.948 0 16.612 0zm-1.007 8.167h-1.848v-6.5h1.848v6.5zM23.328 0h-3.694c-.335 0-.671.333-.671.667v8.5c0 .333.335.666.671.666h3.694c.336 0 .672-.333.672-.666v-8.5C24 .333 23.664 0 23.328 0zm-1.007 8.167h-1.68v-6.5h1.68v6.5z"/>'
  }
})
