<template>
  <div
    :style="sizedStyle"
    class="watch-together-container"
    :class="{ minimized: isWatchTogetherMinimized }"
  >
    <div
      v-if="!isWatchTogetherMinimized && isWatchTogetherOwner"
      class="owner-exclamation Vlt-white"
    >
      <div class="owner-exclamation-text">
        {{ $t('watch_together_view.owner_exclamation_text') }}
      </div>

      <vwc-button
        class="stop-sharing-btn"
        data-cy="stop-sharing-btn"
        :label="$t('watch_together_view.stop_sharing_button')"
        layout="filled"
        shape="pill"
        connotation="alert"
        dense
        @click="stopWatchTogether"
      />
    </div>

    <WatchTogetherPlayer
      ref="videoPlayer"
      class="video-player"
      :minimized="isWatchTogetherMinimized"
    />
  </div>
</template>

<script>
import WatchTogetherPlayer from '@/components/watchTogether/ongoing/WatchTogetherPlayer';
import { mapActions, mapGetters } from 'vuex';

export default {
  components: {
    WatchTogetherPlayer
  },

  props: {
    preferred: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapGetters('watchTogether/ongoing', [
      'isWatchTogetherOwner',
      'isWatchTogetherMinimized'
    ]),

    sizedStyle() {
      if (this.isWatchTogetherMinimized) {
        return {};
      }

      return {
        width: `${this.preferred.size.width}px`,
        height: `${this.preferred.size.height}px`,
        left: `${this.preferred.left}px`
      };
    }
  },

  methods: {
    ...mapActions('watchTogether/ongoing', ['stopWatchTogether'])
  }
};
</script>

<style scoped>
.watch-together-container {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 40px;
  left: 0;
}

.watch-together-container.minimized {
  top: unset;
  left: 0;
  bottom: 80px;
  z-index: 1738;
  width: 270px;
  height: 232px;
}

.owner-exclamation {
  font-size: 16px;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 8px;
}

.owner-exclamation-text {
  margin-right: 1rem;
}

.video-player {
  flex: 1;
}

vwc-button.stop-sharing-btn {
  --vvd-color-on-alert: var(--btn-primary-color-white-bg-text-color);
}
</style>
