/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-add': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 .5A.5.5 0 01.5 0h13a.5.5 0 01.5.5v5a.5.5 0 01-1 0V1H1v14h5.5a.5.5 0 010 1h-6a.5.5 0 01-.5-.5V.5z"/><path pid="1" d="M3 3.5a.5.5 0 01.5-.5h7a.5.5 0 010 1h-7a.5.5 0 01-.5-.5zM3 6.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5zM3 9.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zM3 12.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zM11.5 8a3.5 3.5 0 100 7 3.5 3.5 0 000-7zM7 11.5a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0z"/><path pid="2" d="M11.5 9a.5.5 0 01.5.5v4a.5.5 0 01-1 0v-4a.5.5 0 01.5-.5z"/><path pid="3" d="M9 11.5a.5.5 0 01.5-.5h4a.5.5 0 010 1h-4a.5.5 0 01-.5-.5z"/>'
  }
})
