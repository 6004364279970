import MeetingManager from '@/services/meeting-manager-service';
import { APP_SIGNALS } from '@/consts/global-consts';
import {
  PARTICIPANT_STATE_TYPES,
  SIDEBARS,
  ANALYTICS
} from '@/consts/global-consts';
import * as pollsSdk from '@vonage/polls-api-client-sdk';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import analytics from '@/services/analytics-service';
import store from '@/store';

export default {
  init: ({ dispatch }) => {
    MeetingManager.onSignal(APP_SIGNALS.QNA, (event) => {
      dispatch('receiveQnaStateSignal', event);
    });

    MeetingManager.onSignal(APP_SIGNALS.QNA_REFRESH, (event) => {
      dispatch('receiveRefreshQnaSignal', event);
    });

    store.watch(
      (storeState, storeGetters) => storeGetters['qna/isQnaSidebarOpen'],
      (isOpen) => {
        if (isOpen) {
          dispatch('markAllQuestionsAsSeen');
        }
      }
    );
  },

  upvoteQuestion: async (
    { state, rootState, commit, dispatch },
    questionId
  ) => {
    const question = state.questions.find((q) => q.questionId === questionId);
    commit('SET_QUESTION', {
      ...question,
      upvotes: [
        ...question.upvotes,
        { voteId: 'temp', userId: rootState.myParticipantId }
      ]
    });
    await pollsSdk.upvoteQuestion(state.qnaSessionId, questionId);

    dispatch('sendRefreshQnaSignal', { qnaSessionId: state.qnaSessionId });
  },

  cancelUpvoteQuestion: async (
    { state, rootState, commit, dispatch },
    questionId
  ) => {
    const question = state.questions.find((q) => q.questionId === questionId);
    commit('SET_QUESTION', {
      ...question,
      upvotes: question.upvotes.filter(
        (upvote) => upvote.userId !== rootState.myParticipantId
      )
    });
    await pollsSdk.cancelUpvoteQuestion(state.qnaSessionId, questionId);

    dispatch('sendRefreshQnaSignal', { qnaSessionId: state.qnaSessionId });
  },

  toggleQna: async ({ dispatch, state }) => {
    analytics.trackEvent(ANALYTICS.QUESTIONS_AND_ANSWERS, {
      Action: state.isQnaSessionActive ? 'Stop' : 'Start'
    });

    if (state.isQnaSessionActive) {
      dispatch('stopQnaSession');
    } else {
      dispatch('startQnaSession');
    }
  },

  stopQnaSession: async ({ dispatch, commit }) => {
    commit('SET_QNA_SESSION_OWNER_ID', '');
    commit('SET_IS_QNA_SESSION_ACTIVE', false);
    commit('SET_QNA_SESSION_ID', null);

    dispatch('sendQnaStateSignal', {
      isQnaSessionActive: false
    });
  },

  startQnaSession: async ({ dispatch, commit, rootState }) => {
    pollsSdk.init(rootState.myParticipantId);
    const sessionId = await pollsSdk.createSession();

    dispatch('joinQnaSession', sessionId);

    commit('SET_IS_QNA_SESSION_ACTIVE', true);
    commit('SET_QNA_SESSION_OWNER_ID', rootState.myParticipantId);
    commit('SET_QNA_SESSION_ID', sessionId);

    dispatch('sendQnaStateSignal', {
      isQnaSessionActive: true,
      qnaSessionId: sessionId,
      qnaSessionOwnerId: rootState.myParticipantId
    });
  },

  joinQnaSession: async ({ dispatch, commit, rootState }, sessionId) => {
    pollsSdk.init(rootState.myParticipantId);

    commit('SET_QNA_SESSION_ID', sessionId);

    commit('SET_IS_LOADING_SESSION', true);
    dispatch('fetchSession').then(() => {
      commit('SET_IS_LOADING_SESSION', false);
    });

    pollsSdk.registerToSessionUpdates(sessionId, () => {
      dispatch('fetchSession');
    });

    dispatch('layout/toggleSidebar', { shouldCollapse: false }, { root: true });
    dispatch('setActiveSidebar', SIDEBARS.QUESTIONS_AND_ANSWERS, {
      root: true
    });
  },

  fetchSession: async ({ state, getters, commit, dispatch }) => {
    const session = await pollsSdk.getSession(state.qnaSessionId);
    commit('SET_QUESTIONS', session.questions);

    if (getters.isQnaSidebarOpen) {
      dispatch('markAllQuestionsAsSeen');
    }
  },

  resolveQuestion: async ({ state, commit, dispatch }, questionId) => {
    const question = state.questions.find((q) => q.questionId === questionId);
    commit('SET_QUESTION', { ...question, isResolved: true });
    await pollsSdk.markQuestionAsResolved(state.qnaSessionId, questionId);

    dispatch('sendRefreshQnaSignal', { qnaSessionId: state.qnaSessionId });
  },

  sendQuestion: async (
    { rootState, state, commit, dispatch },
    questionText
  ) => {
    commit('ADD_QUESTION', {
      questionId: 'temp-id',
      questionText: questionText,
      upvotes: [],
      isUploading: true,
      userId: rootState.myParticipantId,
      isResolved: false,
      createdAt: Date.now()
    });

    await pollsSdk.createQuestion(state.qnaSessionId, questionText);

    dispatch('sendRefreshQnaSignal', { qnaSessionId: state.qnaSessionId });
  },

  deleteQuestion: async ({ state, commit, dispatch }, questionId) => {
    commit('REMOVE_QUESTION', questionId);
    await pollsSdk.deleteQuestion(state.qnaSessionId, questionId);

    dispatch('sendRefreshQnaSignal', { qnaSessionId: state.qnaSessionId });
  },

  sendQnaStateSignal: (
    _,
    { isQnaSessionActive, qnaSessionId, qnaSessionOwnerId, to = undefined }
  ) => {
    let signal = {
      type: APP_SIGNALS.QNA,
      data: { isQnaSessionActive, qnaSessionId, qnaSessionOwnerId }
    };
    if (to !== undefined) {
      signal.to = to;
    }
    return MeetingManager.sendSignal(signal);
  },

  receiveQnaStateSignal: ({ state, rootState, commit, dispatch }, event) => {
    const signalSenderParticipantId = rootState.streams.find(
      (stream) => stream.connectionId === event.from.connectionId
    )?.participantId;

    if (
      state.qnaSessionOwnerId &&
      signalSenderParticipantId !== state.qnaSessionOwnerId
    ) {
      logger.warning(
        'qna-handle-participant-state-update',
        LOG_CATEGORIES.CLIENT_LOGIC,
        `Received a signal from participant ID ${signalSenderParticipantId} but the owner is ${state.qnaSessionOwnerId}. Ignoring.`
      );
      return;
    }

    commit('SET_IS_QNA_SESSION_ACTIVE', event.data.isQnaSessionActive);
    commit('SET_QNA_SESSION_OWNER_ID', event.data.qnaSessionOwnerId);

    if (
      event.data.isQnaSessionActive &&
      event.data.qnaSessionId &&
      event.data.qnaSessionId !== state.qnaSessionId
    ) {
      dispatch('joinQnaSession', event.data.qnaSessionId);
    } else if (!event.data.isQnaSessionActive) {
      dispatch('cleanupQnaSession');
    }
  },

  sendRefreshQnaSignal: (_, { qnaSessionId }) => {
    const signal = {
      type: APP_SIGNALS.QNA_REFRESH,
      data: { qnaSessionId }
    };
    return MeetingManager.sendSignal(signal);
  },

  receiveRefreshQnaSignal: () => {
    pollsSdk.forceRefresh();
  },

  cleanupQnaSession: ({ state, getters, commit, dispatch }) => {
    pollsSdk.unregisterFromSessionUpdates(state.qnaSessionId);
    commit('SET_QNA_SESSION_ID', null);
    commit('SET_IS_QNA_SESSION_ACTIVE', false);
    commit('SET_QNA_SESSION_OWNER_ID', '');
    commit('SET_QUESTIONS', []);

    if (getters.isQnaSidebarOpen) {
      dispatch(
        'layout/toggleSidebar',
        {
          skipAnimation: false,
          shouldCollapse: true
        },
        { root: true }
      );
    }
  },

  handleParticipantStateUpdate: ({ dispatch, state }, event) => {
    if (!state.isQnaSessionActive) {
      return;
    }

    if (event.state === PARTICIPANT_STATE_TYPES.JOINED) {
      dispatch('_handleParticipantJoined', {
        connectionId: event.connectionId,
        participantId: event.participantId
      });
    } else if (
      [PARTICIPANT_STATE_TYPES.LEFT, PARTICIPANT_STATE_TYPES.KICKED].includes(
        event.state
      )
    ) {
      dispatch('_handleParticipantLeft', {
        participantId: event.participantId
      });
    }
  },

  _handleParticipantJoined: (
    { dispatch, getters, state, rootState },
    { connectionId, participantId }
  ) => {
    if (
      !getters.isQnaSessionOwner ||
      participantId === rootState.myParticipantId
    ) {
      return;
    }

    const stream = rootState.streams?.find(
      (stream) => stream?.connectionId === connectionId
    );
    const connection = stream?.connection;

    dispatch('sendQnaStateSignal', {
      isQnaSessionActive: state.isQnaSessionActive,
      qnaSessionId: state.qnaSessionId,
      qnaSessionOwnerId: state.qnaSessionOwnerId,
      to: connection
    });
  },

  // When the owner of a Q&A leaves, close it for all
  _handleParticipantLeft: ({ dispatch, state }, { participantId }) => {
    if (
      !state.isQnaSessionActive ||
      state.qnaSessionOwnerId !== participantId
    ) {
      return;
    }

    dispatch('cleanupQnaSession');
  },

  markAllQuestionsAsSeen: ({ state, commit }) => {
    state.questions.forEach((question) => {
      commit('MARK_QUESTION_ID_AS_SEEN', question.questionId);
    });
  }
};
