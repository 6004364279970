/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-user-sync-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4 6c0 2.761 2.239 6 5 6s5-3.239 5-6V5A5 5 0 004 5v1zm7.625 8.182A7.948 7.948 0 0010.587 22H0v-3.968a2.99 2.99 0 012.026-2.839C3.671 14.638 6.176 14 9 14c.917 0 1.794.072 2.625.182zM18 15c.808 0 1.573.331 2.131.893l-1.608 1.168 4.269.939.428-4.351-1.466 1.065A5.01 5.01 0 0018 13a5 5 0 00-4.843 3.75l1.938.499A2.997 2.997 0 0118 15zm-2.131 6.107a2.997 2.997 0 005.036-1.356l1.938.499A5 5 0 0118 24a5.01 5.01 0 01-3.754-1.714l-1.466 1.065.428-4.351 4.269.939-1.608 1.168z"/>'
  }
})
