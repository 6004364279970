var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("vwc-3-badge", {
    directives: [
      {
        name: "tooltip",
        rawName: "v-tooltip.bottom",
        value: _vm.indicationTooltip,
        expression: "indicationTooltip",
        modifiers: { bottom: true },
      },
    ],
    staticClass: "vvd-root",
    attrs: {
      "data-cy": "recording-indication",
      connotation: "alert",
      icon: "rec-solid",
      shape: "pill",
      appearance: "subtle",
      text: _vm.formattedDurationBadgeText,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }