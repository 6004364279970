/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-left-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.438 3.33a1.5 1.5 0 00-2.109.233l-6 7.5a1.5 1.5 0 000 1.875l6 7.5a1.5 1.5 0 102.343-1.875l-5.25-6.562 5.25-6.563a1.501 1.501 0 00-.234-2.109z"/>'
  }
})
