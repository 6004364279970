/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-van': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 .5A.5.5 0 01.5 0H10a.5.5 0 01.47.33l1.913 5.26 3.254.93A.5.5 0 0116 7v6.5a.5.5 0 01-.5.5h-4a.5.5 0 010-1H15V7.377l-3.137-.896a.5.5 0 01-.333-.31L9.65 1H1v12h1.5a.5.5 0 010 1h-2a.5.5 0 01-.5-.5V.5zM7 11a2 2 0 100 4 2 2 0 000-4zm-3 2a3 3 0 116 0 3 3 0 01-6 0zM6 2.5a.5.5 0 00-1 0v4a.5.5 0 00.5.5h4a.5.5 0 000-1H6V2.5z"/>'
  }
})
