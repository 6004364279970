var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "volume-container",
      on: {
        mouseenter: function ($event) {
          _vm.isMouseOver = true
        },
        mouseleave: function ($event) {
          _vm.isMouseOver = false
        },
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "playback-control",
          on: {
            click: function ($event) {
              return _vm.$emit("toggleMute")
            },
          },
        },
        [_c("v-icon", { attrs: { iconName: _vm.volumeIcon } })],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "expand" } },
        [
          _c("WatchTogetherSlider", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isMouseOver || _vm.dragging,
                expression: "isMouseOver || dragging",
              },
            ],
            staticClass: "slider",
            attrs: {
              primaryColor: "white",
              collapsible: "",
              expandedWidth: 50,
              thumbPadding: 3,
              thumbSize: 12,
              barHeight: 3,
              max: 100,
              value: _vm.volume,
            },
            on: {
              dragstart: _vm.sliderDragStarted,
              dragging: _vm.volumeSliderChangedThrottled,
              dragend: _vm.sliderDragEnded,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }