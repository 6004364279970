<template>
  <div class="Vlt-checkbox" :class="{ inverted: inverted }">
    <label class="volta-label-override" :class="{ 'align-center': multiLine }">
      <span class="Vlt-checkbox__button">
        <input
          type="checkbox"
          :checked="checked"
          :disabled="disabled"
          @change="$emit('change', $event.target.checked)"
        />
        <span class="Vlt-checkbox__icon"></span>
      </span>
      <span :class="{ 'text-ellipsis': !multiLine }">
        <slot />
      </span>
    </label>
  </div>
</template>

<script>
export default {
  name: 'VCheckbox',

  model: {
    prop: 'checked',
    event: 'change'
  },

  props: {
    checked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    inverted: {
      type: Boolean,
      default: false
    },
    multiLine: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.volta-label-override {
  cursor: pointer;
  display: flex;
}

.align-center {
  align-items: center;
}

.inverted .Vlt-checkbox__button input:checked ~ .Vlt-checkbox__icon {
  background-color: white;
  border-color: white;
  border-radius: 2px;
}

.inverted .Vlt-checkbox__icon {
  background-color: unset;
  border-color: #757575 !important;
  border-radius: 2px;
}

.inverted .Vlt-checkbox__button > .Vlt-checkbox__icon:after {
  filter: invert(1);
}
</style>
