import { SIDEBARS } from '@/consts/global-consts';

export default {
  isQnaSessionOwner: (state, getters, rootState) =>
    state.qnaSessionOwnerId === rootState.myParticipantId,

  isQnaSidebarOpen: (state, getters, rootState) =>
    !rootState.layout.isSidebarCollapsed &&
    rootState.activeSidebar === SIDEBARS.QUESTIONS_AND_ANSWERS,

  unseenQuestions: (state, getters) =>
    getters.isQnaSidebarOpen
      ? []
      : state.questions.filter((question) => {
          if (question.questionId === 'temp-id') {
            return false;
          }

          return !state.seenQuestionIds.has(question.questionId);
        })
};
