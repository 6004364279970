/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-ppt': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.9 16.3h1.5c1.3 0 2.1-.9 2.1-2.1 0-1.2-.7-2-2-2H.8v6.3h1.1v-2.2zm0-3h1.4c.6 0 1 .4 1 1s-.4 1.1-1.1 1.1H1.9v-2.1zM15.1 18.5v-5.2h2.2v-1.1h-5.4v1.1H14v5.2z"/><path pid="1" d="M22.3 7.7L14.8.2c-.2-.1-.4-.2-.6-.2h-12c-.4 0-.7.3-.7.8V10H3V1.5h10.5v6.8c0 .4.3.8.8.8H21v13.5H3V21H1.5v2.2c0 .4.3.8.8.8h19.5c.4 0 .8-.3.8-.8v-15c-.1-.1-.2-.3-.3-.5zM15 7.5V2.6l4.9 4.9H15z"/><path pid="2" d="M9.3 16.3c1.3 0 2.1-.9 2.1-2.1 0-1.2-.7-2-2-2H6.7v6.3h1.1v-2.2h1.5zm-1.5-3h1.4c.6 0 1 .4 1 1s-.4 1.1-1.1 1.1H7.8v-2.1z"/>'
  }
})
