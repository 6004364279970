/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'steps-arrow': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M9.559 4.06a.75.75 0 011.048.165l5.333 7.333a.75.75 0 010 .883l-5.333 7.333a.75.75 0 01-1.213-.882l5.012-6.893-5.013-6.892A.75.75 0 019.56 4.06z"/></g><defs><clipPath id="clip0"><path pid="1" d="M4 4h16v16H4z"/></clipPath></defs>'
  }
})
