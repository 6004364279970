/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-upload': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4.5 5H6v5.5c0 .3.2.5.5.5h3c.3 0 .5-.2.5-.5V5h1.5c.2 0 .4-.1.5-.3s.1-.4-.1-.5L8.4.2c-.2-.2-.6-.2-.8 0l-3.5 4c-.1.1-.1.3-.1.5.1.2.3.3.5.3zM8 1.3L10.4 4h-.9c-.3 0-.5.2-.5.5V10H7V4.5c0-.3-.2-.5-.5-.5h-.9L8 1.3z"/><path pid="1" d="M14.5 6h-2c-.3 0-.5.2-.5.5s.2.5.5.5h2c.3 0 .5.2.5.5v7c0 .3-.2.5-.5.5h-13c-.3 0-.5-.2-.5-.5v-7c0-.3.2-.5.5-.5h2c.3 0 .5-.2.5-.5S3.8 6 3.5 6h-2C.7 6 0 6.7 0 7.5v7c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-7c0-.8-.7-1.5-1.5-1.5z"/>'
  }
})
