/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-flash-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M6.022.568A.75.75 0 016.75 0h7.5a.75.75 0 01.728.932L13.71 6h6.539a.75.75 0 01.633 1.153l-10.5 16.5a.75.75 0 01-1.375-.51l1.377-9.643H3.75a.75.75 0 01-.728-.932l3-12z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
