var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "participants-container",
      attrs: { "data-cy": "participants-sidebar" },
    },
    [
      _vm.inviteVisible || _vm.muteAllVisible
        ? _c(
            "div",
            { staticClass: "header-container" },
            [
              _c(
                "div",
                { staticClass: "header-buttons" },
                [
                  _vm.inviteVisible
                    ? _c("vwc-button", {
                        attrs: {
                          "data-cy": "invite-button",
                          layout: "outlined",
                          shape: "rounded",
                          fullwidth: "",
                          dense: "",
                          connotation: "primary",
                          icon: "add-user-solid",
                          label: _vm.$t(
                            "participants.invite_participants_button"
                          ),
                          disabled: _vm.areInvitesDisabled,
                        },
                        on: { click: _vm.showInviteParticipantsModal },
                      })
                    : _vm.muteAllVisible
                    ? _c("vwc-button", {
                        attrs: {
                          layout: "outlined",
                          shape: "rounded",
                          fullwidth: "",
                          dense: "",
                          connotation: "primary",
                          icon: "mic-mute-line",
                          label: _vm.$t("participants.mute_all_label"),
                        },
                        on: { click: _vm.muteAll },
                      })
                    : _vm._e(),
                  _vm.inviteVisible && _vm.muteAllVisible
                    ? _c(
                        "div",
                        {
                          staticClass: "owner-menu",
                          attrs: { "data-cy": "owner-menu" },
                        },
                        [
                          _c("MoreOptionsMenu", {
                            attrs: {
                              "more-options-lists": _vm.ownerMoreOptionsList,
                              outlined: "",
                            },
                            on: { optionSelected: _vm.moreOptionSelected },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm.areInvitesDisabled
                ? _c("InvitesDisabledIndication")
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.isStandupOn ? _c("Standup") : _vm._e(),
      _c(
        "div",
        {
          staticClass: "participants-list",
          attrs: { "data-cy": "participants-list" },
        },
        [_c("ParticipantsList")],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }