import DetectRTC from 'detectrtc';

function detectRTC() {
  if (window.Cypress && window.DetectRTCMock) {
    return window.DetectRTCMock;
  }
  return DetectRTC;
}

async function loadDevices() {
  return new Promise((resolve) => {
    detectRTC().load(resolve);
  });
}

export async function getDevices({ updateDevices = false }) {
  if (updateDevices) {
    await loadDevices();
  }
  return {
    microphoneDevices: detectRTC().audioInputDevices,
    cameraDevices: detectRTC().videoInputDevices,
    speakerDevices: detectRTC().audioOutputDevices
  };
}

export async function getPermissions({ updateDevices = false }) {
  if (updateDevices) {
    await loadDevices();
  }
  return {
    hasMicrophonePermissions: detectRTC().isWebsiteHasMicrophonePermissions,
    hasCameraPermissions: detectRTC().isWebsiteHasWebcamPermissions
  };
}

export function hasDevicesNames() {
  return detectRTC()?.MediaDevices?.[0]?.isCustomLabel === false;
}

export async function osFullName() {
  return `${detectRTC().osName} ${detectRTC().osVersion}`;
}

export async function browserFullName() {
  return `${detectRTC().browser.name} ${detectRTC().browser.version}`;
}
