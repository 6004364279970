/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-home-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M16.5 24h3c.9 0 1.5-.6 1.5-1.5V12h1.5c.45 0 .9-.15 1.2-.45.6-.6.45-1.5-.15-2.1l-10.5-9C12.45 0 11.7 0 11.1.45l-10.5 9c-.6.6-.75 1.5-.15 2.1.15.3.6.45 1.05.45H3v10.5c0 .9.6 1.5 1.5 1.5h3c.9 0 1.5-.6 1.5-1.5V18h6v4.5c0 .9.6 1.5 1.5 1.5z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
