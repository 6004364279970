/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-fax': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M3 .5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v3a.5.5 0 01-1 0V1H4v2.5a.5.5 0 01-1 0v-3zM0 5.5A.5.5 0 01.5 5h15a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-3a.5.5 0 010-1H15V6H1v6h2.5a.5.5 0 010 1h-3a.5.5 0 01-.5-.5v-7z"/><path pid="1" d="M3 9.5a.5.5 0 01.5-.5h9a.5.5 0 01.5.5v6a.5.5 0 01-.5.5h-9a.5.5 0 01-.5-.5v-6zm1 .5v5h8v-5H4z"/>'
  }
})
