/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-notes': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M7.5.75A.75.75 0 018.25 0h7.5a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-7.5a.75.75 0 01-.75-.75v-3zM9 3h6V1.5H9V3zM3.75 3a.75.75 0 00-.75.75v18a.75.75 0 00.75.75h16.5a.75.75 0 00.75-.75v-18a.75.75 0 00-.75-.75h-1.5a.75.75 0 010-1.5h1.5a2.25 2.25 0 012.25 2.25v18A2.25 2.25 0 0120.25 24H3.75a2.25 2.25 0 01-2.25-2.25v-18A2.25 2.25 0 013.75 1.5h1.5a.75.75 0 010 1.5h-1.5zm3 6a.75.75 0 000 1.5h10.5a.75.75 0 000-1.5H6.75zM6 14.25a.75.75 0 01.75-.75h10.5a.75.75 0 010 1.5H6.75a.75.75 0 01-.75-.75zM6.75 18a.75.75 0 000 1.5h10.5a.75.75 0 000-1.5H6.75z"/>'
  }
})
