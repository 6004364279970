/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-video-active-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15.75 6.75a3 3 0 100-6 3 3 0 000 6zM12 7.5C13.5 9 15.75 9 16.5 9L24 6v12l-7.5-3v4.5c0 .9-.6 1.5-1.5 1.5H1.5C.6 21 0 20.4 0 19.5v-15C0 3.6.6 3 1.5 3h9c0 1.5 0 3 1.5 4.5z"/>'
  }
})
