import {
  getYoutubeIdByUrl,
  getYoutubeVideoMetadata
} from './supported-video-sites/youtube';
import {
  getDailyMotionIdByUrl,
  getDailymotionVideoMetadata
} from './supported-video-sites/dailymotion';
import {
  getVimeoIdByUrl,
  getVimeoVideoMetadata
} from './supported-video-sites/vimeo';
import VideoSiteTypeEnum from './video-site-type-enum';
import {
  VideoUrlValidationError,
  VIDEO_URL_ERROR_REASON
} from './video-url-validation-error';

class VideoMetadataService {
  async getVideoMetadata(videoUrl) {
    const videoSiteType = this.getVideoSiteTypeByUrl(videoUrl);

    switch (videoSiteType) {
      case VideoSiteTypeEnum.YouTube:
        return await getYoutubeVideoMetadata(videoUrl);
      case VideoSiteTypeEnum.Vimeo:
        return await getVimeoVideoMetadata(videoUrl);
      case VideoSiteTypeEnum.Dailymotion:
        return await getDailymotionVideoMetadata(videoUrl);
      default:
        if (this.approximateUrlSupport(videoUrl)) {
          throw new VideoUrlValidationError(
            videoUrl,
            VIDEO_URL_ERROR_REASON.UNKNOWN
          );
        } else {
          throw new VideoUrlValidationError(
            videoUrl,
            VIDEO_URL_ERROR_REASON.UNSUPPORTED_SITE
          );
        }
    }
  }

  getVideoSiteTypeByUrl(videoUrl) {
    if (getYoutubeIdByUrl(videoUrl)) {
      return VideoSiteTypeEnum.YouTube;
    } else if (getVimeoIdByUrl(videoUrl)) {
      return VideoSiteTypeEnum.Vimeo;
    } else if (getDailyMotionIdByUrl(videoUrl)) {
      return VideoSiteTypeEnum.Dailymotion;
    }

    return null;
  }

  /**
   * Checks if the given URL *probably* belongs to a supported website.
   * This function only validates the domain name, i.e. does not check if the URL has a video ID.
   * The result is not reliable, but sufficient for telling the difference between a URL that
   * belongs to a supported site but is missing the video ID, and a URL that belongs to a completely
   * unsupported site.
   * @param {String} videoUrl Url to be checked
   */
  approximateUrlSupport(videoUrl) {
    const lowerVideoUrl = videoUrl.toLowerCase();
    const supportedDomains = [
      'youtube.com',
      'youtu.be',
      'vimeo.com',
      'dailymotion.com'
    ];

    return supportedDomains.some((supportedDomain) =>
      lowerVideoUrl.includes(supportedDomain)
    );
  }
}

export default new VideoMetadataService();
