/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-audio-min-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.085 2.716a.5.5 0 01.827.378v17.812a.5.5 0 01-.827.378l-5.72-4.95H2.183C.977 16.333 0 15.338 0 14.11V9.889C0 8.66.977 7.666 2.183 7.666h2.182l5.72-4.95zm4.627 5.44a1 1 0 10-1.427 1.402c1.314 1.337 1.314 3.548 0 4.885a1 1 0 101.427 1.402c2.078-2.116 2.078-5.573 0-7.689z"/>'
  }
})
