/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-netsuite-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.878 13.706V1H0v5.321h9.122l5.756 7.385zM14.77 18.376c-1.955-2.39-3.802-4.887-5.648-7.276v12.597H24v-5.43c-3.15.109-6.19.109-9.23.109z" _fill="#00467F"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M15.855 2.086V6.32h2.39v11.186h4.669V2.086h-7.059zM5.756 7.299h-4.67v15.42h6.95v-4.344h-2.28V7.3z" _fill="#B9C7D4"/>'
  }
})
