/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-minimize-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 .9L23.1 3l-4.05 4.05L24 12H12V0l4.95 4.95L21 .9zM12 12H0l4.95 4.95L.9 21 3 23.1l4.05-4.05L12 24V12z"/>'
  }
})
