/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-stackoverflow-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M19.127 21.866v-6.43h2.134V24H2v-8.563h2.134v6.43h14.993z" _fill="#BCBBBB"/><path pid="1" d="M6.49 14.827l10.475 2.19.444-2.107-10.476-2.19-.443 2.107zm1.385-4.989l9.7 4.518.887-1.94-9.7-4.545-.887 1.967zm2.689-4.766l8.23 6.845 1.358-1.635-8.23-6.845-1.358 1.635zM15.884 0l-1.718 1.275 6.375 8.591 1.718-1.275L15.885 0zM6.269 19.704h10.697v-2.133H6.268v2.133z" _fill="#F48023"/></g><defs><clipPath id="clip0"><path pid="2" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
