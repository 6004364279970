/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-dynamics': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M18.968 13.055s2.212-1.77 4.645-4.424l.387.166v14.544l-.498.221s-11.28-4.424-23.28-.055H0v-1.825S9.07 12.945 11.336 1l.553.11v17.53l-.331.166s-5.586.056-11.282 4.37c0 0 5.31-3.872 11.78-3.927l.276-.277v-5.308s3.262-3.042 5.64-8.848l.498.22V20.41l-.83.221S8.517 18.31.499 23.064c0 0 7.963-4.313 17.861-1.935l.553-.11c.056.055-.055-8.074.056-7.964z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
