/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-add-group-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.217 11.036c-1.728 0-3.13 1.34-3.13 2.992s1.401 2.991 3.13 2.991c1.73 0 3.13-1.34 3.13-2.991 0-1.653-1.4-2.992-3.13-2.992zM5.217 19.014c-2.009 0-3.428.763-4.25 1.386A2.43 2.43 0 000 22.34v1.161A.51.51 0 00.522 24h9.391a.51.51 0 00.522-.499v-1.16c0-.747-.343-1.468-.968-1.94-.82-.623-2.24-1.387-4.25-1.387zM18.783 11.036c-1.73 0-3.13 1.34-3.13 2.992s1.4 2.991 3.13 2.991c1.729 0 3.13-1.34 3.13-2.991 0-1.653-1.401-2.992-3.13-2.992zM18.783 19.014c-2.01 0-3.43.763-4.25 1.386a2.43 2.43 0 00-.968 1.94v1.161a.51.51 0 00.522.499h9.391a.51.51 0 00.522-.499v-1.16c0-.747-.343-1.468-.967-1.94-.821-.623-2.24-1.387-4.25-1.387zM13.6 0h-3.2v3.058H7.2v3.058h3.2v3.059h3.2V6.116h3.2V3.058h-3.2V0z"/>'
  }
})
