var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container", attrs: { "data-cy": "recording-indication" } },
    [
      _c("div", { staticClass: "shadow" }),
      _c("div", { staticClass: "recording-icon" }),
      _c("span", { staticClass: "text" }, [
        _vm._v(_vm._s(_vm.$t("mobile_recording_indication.text"))),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }