/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-world-hand': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.2 16.5c.9-.6 2-.6 2.7.1.5.5.7 1.5-.2 2.5-.8.9-3.6 3.9-7.3 3.9h-.3c-1.5-.1-2.8-.4-3.9-.8-1.8-.5-3.2-.9-5.2 0-.3.1-.6 0-.8-.3-.1-.3 0-.6.3-.8 2.4-1.2 4.1-.6 6-.1 1.1.3 2.2.6 3.6.7 3.3.1 6-2.6 6.7-3.5.2-.2.5-.6.2-.9-.2-.2-.7-.3-1.1 0-.8.6-3.4 2.1-4.9 2.2-1.1.1-4.5-.8-5.3-2-.2-.4-.3-.8-.1-1.2.6-1.1 1.8-.7 2.9-.3.7.2 1.4.5 2.1.5.6 0 1-.1 1-.4.1-.2-.1-.5-.4-.7-2.4-1.1-4.6-1.4-6.6-1-3.1.6-6.2 3.5-6.2 3.5-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8.1-.1 3.4-3.2 6.8-3.8 2.3-.4 4.7-.1 7.3 1.1.8.4 1.2 1.2 1 2-.2.7-.8 1.4-2.2 1.3-.9-.1-1.7-.4-2.4-.6-1-.3-1.4-.5-1.6-.2.2.5 3.2 1.6 4.3 1.5 1.1 0 3.5-1.3 4.4-1.9zm-15-3c-.3.1-.6-.1-.7-.4-.4-1-.5-2.1-.5-3.1C2 4.5 6.4 0 12 0c5.5 0 10 4.5 10 9.9 0 1.5-.3 3-1 4.4-.1.3-.5.4-.8.3-.3-.1-.4-.5-.3-.8.5-1.1.8-2.2.9-3.4h-3.4c0 1-.1 2-.3 3-.1.3-.4.5-.7.5-.3-.1-.5-.4-.4-.7.2-.9.3-1.8.3-2.8h-3.8v1.1c0 .3-.3.6-.6.6s-.6-.3-.6-.6v-1.1H7.6v.8c0 .3-.2.6-.5.6s-.6-.2-.6-.5v-.9H3.1c0 .8.2 1.6.5 2.4.1.3-.1.6-.4.7zM6 3.5a8.55 8.55 0 00-2.9 5.7h3.3c.1-1.8.4-3.4.9-4.8-.4-.3-.9-.6-1.3-.9zm14.8 5.7C20.6 7 19.6 5 18 3.5c-.4.3-.9.6-1.4.9.5 1.4.8 3 .9 4.8h3.3zm-8.2-3.7v3.7h3.8c-.1-1.6-.4-3.1-.8-4.4-1 .4-2 .6-3 .7zM7.8 3.3c.3-.6.6-1.1 1-1.6-.7.3-1.3.6-1.9 1.1.3.2.6.3.9.5zm3.6-2c-1 .3-1.8 1.2-2.5 2.5.8.3 1.6.5 2.5.5v-3zm3.7.5c.4.4.7 1 1 1.6.3-.3.6-.4.9-.6-.5-.5-1.2-.8-1.9-1zm-2.5-.5v3c.9-.1 1.7-.2 2.5-.5-.7-1.4-1.6-2.3-2.5-2.5zm-5 7.9h3.8V5.5c-1-.1-2-.3-3-.6-.4 1.2-.7 2.7-.8 4.3z"/>'
  }
})
