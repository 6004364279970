/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-ai': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M5.5 0a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2a.5.5 0 01.5-.5zM10.5 0a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2a.5.5 0 01.5-.5zM13 10.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zM13 5.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zM0 10.5a.5.5 0 01.5-.5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zM0 5.5A.5.5 0 01.5 5h2a.5.5 0 010 1h-2a.5.5 0 01-.5-.5zM5.5 13a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2a.5.5 0 01.5-.5zM10.5 13a.5.5 0 01.5.5v2a.5.5 0 01-1 0v-2a.5.5 0 01.5-.5z"/><path pid="1" d="M3.6 3a.6.6 0 00-.6.6v8.8a.6.6 0 00.6.6h8.8a.6.6 0 00.6-.6V3.6a.6.6 0 00-.6-.6H3.6zM2 3.6A1.6 1.6 0 013.6 2h8.8A1.6 1.6 0 0114 3.6v8.8a1.6 1.6 0 01-1.6 1.6H3.6A1.6 1.6 0 012 12.4V3.6z"/><path pid="2" d="M4 4.5a.5.5 0 01.5-.5h7a.5.5 0 01.5.5v7a.5.5 0 01-.5.5h-7a.5.5 0 01-.5-.5v-7zM5 5v6h6V5H5z"/>'
  }
})
