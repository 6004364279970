<template>
  <div
    ref="streamsContainer"
    class="participants-tile-list"
    :class="streamsContainerClasses"
  >
    <resize-observer @notify="updateStreamSizesThrottled" />
    <EmptyVideoScreen v-if="!showVideoStreams" />
    <div v-show="showVideoStreams" class="streams" :style="sizeStyle">
      <!-- TODO: Separate WatchTogether from ParticipantsTileList as much as possible:
                 1) A slot can be used to let the AppScreen be the one to inject views of apps
                 2) The :preferred condition can be generalized with a "forceAllSmallStreams" getter
       -->
      <WatchTogetherView
        v-if="isWatchTogetherActive"
        :preferred="streamsStyle.main"
      />
      <Whiteboard
        v-if="isWhiteboardActive"
        :sessionId="whiteboardId"
        class="video-stream"
        :preferred="
          dominantSpeakerStreamId === 'whiteboard' && !isWatchTogetherMaximized
            ? streamsStyle.main
            : streamsStyle.small
        "
        @click="$emit('click')"
      />
      <OTParticipantView
        v-for="stream in streams"
        v-show="!shouldHideStream(stream)"
        :key="stream.isPublisher ? 'MyStream' : stream.streamId"
        class="video-stream"
        :class="stream.isPublisher ? 'my-stream' : ''"
        :stream="stream"
        :subscriberStatus="streamsSubscribersStatus[stream.streamId]"
        :preferred="
          stream.streamId === dominantSpeakerStreamId &&
          !isWatchTogetherMaximized
            ? streamsStyle.main
            : streamsStyle.small
        "
        :isPageVisible="isPageVisibleThrottled"
        :preferredFitMode="preferredFitMode"
      />
    </div>
  </div>
</template>

<script>
import OTParticipantView from '@/components/OTParticipantView.vue';
import EmptyVideoScreen from '@/components/EmptyVideoScreen.vue';
import { mapState, mapGetters } from 'vuex';
import {
  searchOptimalGridViewSize,
  searchOptimalSpeakerViewSize,
  searchOptimalDominantViewSize,
  getOptimalMinimizedViewSize
} from '@/helpers/layout-manager.js';
import { LAYOUT_MODE_TYPES } from '@/consts/global-consts';
import WatchTogetherView from '@/components/watchTogether/ongoing/WatchTogetherView';
import Whiteboard from '@/components/Whiteboard';

function calcFrameRate(totalParticipants) {
  if (totalParticipants <= 2) {
    return 30;
  } else if (totalParticipants > 2 && totalParticipants <= 15) {
    return 15;
  } else {
    return 7;
  }
}

export default {
  name: 'ParticipantsTileList',

  components: {
    OTParticipantView,
    EmptyVideoScreen,
    WatchTogetherView,
    Whiteboard
  },

  props: {
    /**
     * Main stream will stretch on the entire view and small streams will appear on top of it
     */
    canFullyStretchMainStream: {
      type: Boolean,
      default: false
    },
    preferredFitMode: {
      type: String,
      default: 'contain',
      validator: (fitMode) => ['cover', 'contain'].includes(fitMode)
    },
    smallStreamAspectRatio: {
      type: Number,
      default: 4 / 3
    },
    smallStreamsDock: {
      type: String,
      default: undefined,
      validator: (dock) => [undefined, 'top', 'left', 'right'].includes(dock)
    },
    updateStreamSizesWrapper: {
      type: Function,
      required: true
    }
  },

  data() {
    return {
      numberOfMaxStreamsToShow: 5,
      firstStreamIndexToShow: 0,
      updateStreamSizesThrottled: undefined,
      // This will hold the calculated width and height
      sizeStyle: null,
      streamsStyle: {
        main: { size: {} },
        small: { size: {} }
      },
      pageVisiblityTimer: 0,
      isPageVisibleThrottled: true
    };
  },

  computed: {
    ...mapState([
      'streams',
      'isVideoEnabled',
      'streamsSubscribersStatus',
      'isPageVisible',
      'minimizedMode',
      'pinnedStreamId',
      'isHideMyStreamEnabled'
    ]),
    ...mapState('layout', ['layoutMode', 'isSidebarCollapsed']),
    ...mapState('watchTogether/ongoing', ['isWatchTogetherActive']),
    ...mapState('whiteboard', ['isWhiteboardActive', 'whiteboardId']),
    ...mapGetters(['forceShowVideoStreams']),
    ...mapGetters('layout', ['toggleSidebarInProgress']),
    ...mapGetters(['dominantSpeakerStreamId', 'screenshareStreamId']),
    ...mapGetters('watchTogether/ongoing', ['isWatchTogetherMaximized']),
    ...mapGetters('whiteboard', ['isWhiteboardPinned']),

    mainStreamIndex() {
      return this.streams.findIndex(
        (stream) => stream.streamId === this.dominantSpeakerStreamId
      );
    },

    showVideoStreams() {
      return (
        this.forceShowVideoStreams ||
        this.streams.length > 1 ||
        (this.isVideoEnabled && this.streams[0]?.hasVideo)
      );
    },

    smallStreamsDockClass() {
      return {
        'dock-top': this.smallStreamsDock === 'top',
        'dock-left': this.smallStreamsDock === 'left',
        'dock-right': this.smallStreamsDock === 'right'
      };
    },

    streamsContainerClasses() {
      return {
        'big-meeting': this.streams.length > 20,
        ...this.smallStreamsDockClass
      };
    }
  },

  watch: {
    'streams.length': function () {
      // Recalculate sizes if a stream is added or removed
      this.updateStreamSizes();
    },

    layoutMode() {
      // Recalculate sizes if the layout mode changes
      this.updateStreamSizes();
    },

    minimizedMode() {
      // Recalculate sizes if the window changes to minimized mode
      this.updateStreamSizes();
    },

    isWatchTogetherMaximized() {
      // Recalculate sizes if "Watch Together" is maximized/minimized
      this.updateStreamSizes();
    },

    isWhiteboardPinned() {
      // Recalculate sizes if the Whiteboard is pinned/unpinned
      this.updateStreamSizes();
    },

    toggleSidebarInProgress() {
      // next tick is used to ensure the sidebar animation completely ended before updating the streams
      this.$nextTick(() => {
        this.updateStreamSizes();
      });
    },

    isPageVisible() {
      if (this.isPageVisible) {
        clearTimeout(this.pageVisiblityTimer);
        this.isPageVisibleThrottled = true;
      } else {
        this.pageVisiblityTimer = setTimeout(
          () => (this.isPageVisibleThrottled = this.isPageVisible),
          15 * 1000
        );
      }
    },

    isSidebarCollapsed(newVal) {
      if (!newVal) {
        this.updateStreamSizes();
      }
    }
  },

  created() {
    this.updateStreamSizesThrottled = this.updateStreamSizesWrapper(
      this.updateStreamSizes
    );
  },

  mounted() {
    this.updateStreamSizes();
  },

  methods: {
    shouldHideStream(stream) {
      return stream.isPublisher && this.isHideMyStreamEnabled;
    },

    streamIndexToShow(index) {
      const m =
        this.mainStreamIndex > -1 && index > this.mainStreamIndex ? 1 : 0;
      return index - m - this.firstStreamIndexToShow;
    },

    updateGridViewSize(availableSize, totalParticipants) {
      const size = searchOptimalGridViewSize(
        availableSize.width,
        availableSize.height,
        totalParticipants
      );

      const frameRate = calcFrameRate(totalParticipants);

      const preferred = {
        size,
        frameRate
      };

      this.streamsStyle = {
        main: preferred,
        small: preferred
      };

      // Set the style to available size AFTER we updates the streams, this is done to prevent
      // flickering when we resize the window (because flex-wrap moves the streams around)
      this.sizeStyle = {
        width: `${availableSize.width}px`
      };
    },

    updateSpeakerViewSize(availableSize, totalParticipants) {
      const speakerViewOptions = {
        smallStreamAspectRatio: this.smallStreamAspectRatio,
        stretchMainStream: false
      };

      if (this.canFullyStretchMainStream) {
        speakerViewOptions.stretchMainStream = true;
        speakerViewOptions.participantPadding = 0;
      } else if (
        this.screenshareStreamId === this.dominantSpeakerStreamId ||
        this.isWatchTogetherMaximized ||
        this.isWhiteboardPinned
      ) {
        speakerViewOptions.stretchMainStream = true;
      }

      const optimalSize = searchOptimalSpeakerViewSize(
        availableSize.width,
        availableSize.height,
        totalParticipants,
        speakerViewOptions
      );
      const frameRate = calcFrameRate(totalParticipants);

      // Set small streams strip width and height
      const sizeStyle = {};
      if (this.canFullyStretchMainStream) {
        sizeStyle.width = `${availableSize.width}px`;
      } else {
        sizeStyle.width = `${optimalSize.smallStripSize.smallStreamsStripWidth}px`;
        sizeStyle.left = `${
          optimalSize.extraWidthSpace / 2 + optimalSize.mainStreamSize.width + 8
        }px`;

        if (optimalSize.smallStripSize.smallStreamsStripHeight) {
          sizeStyle.height = `${optimalSize.smallStripSize.smallStreamsStripHeight}px`;
        }
      }

      this.sizeStyle = sizeStyle;

      const preferredMainStream = {
        size: optimalSize.mainStreamSize,
        left: optimalSize.extraWidthSpace / 2,
        frameRate: 30
      };

      const preferredSmallStream = {
        size: optimalSize.smallStreamSize,
        frameRate
      };

      this.streamsStyle = {
        main: preferredMainStream,
        small: preferredSmallStream
      };
    },

    updateDominantViewSize(availableSize) {
      this.sizeStyle = { width: '100%', height: '100%' };
      this.streamsStyle = {
        main: {
          size: searchOptimalDominantViewSize(
            availableSize.width,
            availableSize.height,
            {
              stretchStream:
                this.screenshareStreamId === this.dominantSpeakerStreamId ||
                this.isWatchTogetherMaximized ||
                this.isWhiteboardPinned
            }
          )
        },
        small: { size: {} }
      };
    },

    updateMinimizedModeViewSize(availableSize) {
      this.sizeStyle = null;
      const size = getOptimalMinimizedViewSize(
        availableSize.width,
        availableSize.height
      );
      this.streamsStyle = {
        main: { size },
        small: { size }
      };
    },

    updateStreamSizes() {
      const containerElement = this.$refs.streamsContainer;
      if (!containerElement) {
        return;
      }
      const availableSize = {
        width: containerElement.offsetWidth,
        height: containerElement.offsetHeight
      };
      let totalParticipants = this.streams.length;
      if (this.isWhiteboardActive) {
        totalParticipants++;
      }

      if (this.minimizedMode) {
        this.updateMinimizedModeViewSize(availableSize);
      } else if (this.layoutMode === LAYOUT_MODE_TYPES.GRID) {
        this.updateGridViewSize(availableSize, totalParticipants);
      } else if (
        this.layoutMode === LAYOUT_MODE_TYPES.SPEAKER ||
        this.canFullyStretchMainStream
      ) {
        this.updateSpeakerViewSize(availableSize, totalParticipants);
      } else if (this.layoutMode === LAYOUT_MODE_TYPES.DOMINANT) {
        this.updateDominantViewSize(availableSize);
      }
    }
  }
};
</script>

<style scoped>
.participants-tile-list {
  display: flex;
  flex: 1;
  position: relative;
  margin-bottom: 8px;
  overflow: hidden;
  width: 100%;
  min-width: 50px;
  min-height: 100px;
}

.minimized-mode .participants-tile-list,
.mobile .participants-tile-list {
  min-width: 0px;
}

.mobile .participants-tile-list {
  margin: 0;
}

.streams {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.streams-wrapper {
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-direction: row;
  padding: 6px;
  position: relative;
}

.grid-view .streams,
.dominant-view .streams,
.minimized-mode .streams {
  margin: 0;
  position: absolute; /* this allows resize-observer detect window resizes */
}

.dominant-view .streams,
.minimized-mode .streams {
  width: 100%;
}

.grid-view .participants-tile-list {
  justify-content: center;
  align-items: center;
}

.speaker-view .participants-tile-list {
  overflow-y: auto;
}

.mobile.portrait.speaker-view .participants-tile-list {
  overflow-y: hidden;
}

.mobile.landscape-left.speaker-view .participants-tile-list,
.mobile.landscape-right.speaker-view .participants-tile-list {
  overflow-x: hidden;
}

.speaker-view:not(.minimized-mode) .streams {
  /* height: 100%; */
  flex-direction: column;
  position: absolute;
  overflow-x: auto;
  justify-content: start;
  align-content: flex-start;
}

.mobile.speaker-view .streams {
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  top: 0;
}

.mobile.speaker-view .dock-top .streams {
  flex-direction: row;
  overflow-x: auto;
  overflow-y: hidden;
  /* Set the container height to be greater than the viewport height in order for
     horizontal scrollbars to exist outside of the view  */
  height: calc(var(--viewport-height) + 60px);
}

.mobile.speaker-view .dock-left .streams,
.mobile.speaker-view .dock-right .streams {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}

.mobile.speaker-view .dock-left .streams {
  flex-direction: column-reverse;
}

.mobile.speaker-view .dock-right {
  flex-direction: column;
  direction: rtl; /* Move the scrollbar to the left, otherwise it will intefere with the docked streams */
}

.mobile.speaker-view .dock-right .streams {
  align-items: flex-end;
  direction: ltr;
}
</style>
