/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-group-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 25',
    data: '<path pid="0" d="M19.95 8.8l2.7 1.8c.9.6 1.35 1.5 1.35 2.55v1.2c0 .9-.6 1.5-1.5 1.5h-5.1c0-.15-1.8-1.5-1.8-1.5.6-1.05.9-2.4.9-3.75V9.1c0-2.4-1.05-4.5-2.7-6 .75-1.35 2.25-2.4 4.05-2.1 1.8.3 3.15 2.1 3.15 3.9v1.35c0 1.05-.45 1.95-1.05 2.55zm-8.7 6.3l4.05 3c.75.6 1.2 1.5 1.2 2.4v3c0 .9-.6 1.5-1.5 1.5H1.5C.6 25 0 24.4 0 23.5v-2.85c0-.9.45-1.8 1.2-2.4l4.05-3.15C3.9 14.05 3 12.55 3 10.75v-1.5c0-3 2.55-5.4 5.55-5.25 2.85.15 4.95 2.7 4.95 5.55v1.2c0 1.8-.9 3.3-2.25 4.35z"/>'
  }
})
