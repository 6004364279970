/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-dotnet': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M15.368 14.539l.328-.655c.437-1.312.874-2.732 1.311-4.043.219-.547.328-.984.656-1.53.546-.655 1.202-1.092 2.076-1.202.328 0 .655-.109.983-.109H24c0 .11 0 .11-.11.11-.327.655-.764 1.31-1.092 1.966a58.643 58.643 0 01-2.076 3.496c-.437.765-.874 1.42-1.42 2.076-.437.437-.874.984-1.42 1.311-.328.219-.766.328-1.203.328h-4.807c-.437 0-.874-.218-1.202-.546-.437-.437-.765-.983-.983-1.639-.437-1.093-.656-2.295-.984-3.496-.109-.547-.327-1.093-.437-1.64v-.109l-.218.219c-.546.656-1.093 1.311-1.64 2.076-.108.219-.218.437-.218.655-.218.875-.546 1.749-.764 2.623-.328.765-.765 1.42-1.53 1.857a3.365 3.365 0 01-1.42.328H.18c-.218 0-.218 0-.11-.218.11-1.202.547-2.404 1.093-3.497.547-1.202 1.202-2.294 1.967-3.387.546-.656 1.093-1.311 1.858-1.857.327-.219.764-.437 1.201-.437h5.136c.874 0 1.53.327 1.966 1.092.328.437.547.874.656 1.42.328.984.546 1.967.874 3.06.11.546.328 1.202.437 1.748h.11z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
