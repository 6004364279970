<template>
  <div class="watch-together-popper">
    <div class="popper-content-container">
      <p class="title">{{ $t('watch_together_mute_indication.title') }}</p>
      <p>
        {{ $t('watch_together_mute_indication.text') }}
      </p>
      <vwc-button
        class="next-button"
        :label="$t('watch_together_mute_indication.got_it_button')"
        layout="filled"
        connotation="cta"
        dense
        @click="closeAnnouncement"
      />
    </div>
  </div>
</template>

<script>
const WATCH_TOGETHER_MUTE_INDICATION_MAX_SECONDS = 10;
let closeTimer;

export default {
  name: 'WatchTogetherMuteIndication',

  mounted() {
    closeTimer = setTimeout(() => {
      this.closeAnnouncement();
    }, WATCH_TOGETHER_MUTE_INDICATION_MAX_SECONDS * 1000);
  },

  beforeDestroy() {
    this.closeAnnouncement();
  },

  methods: {
    closeAnnouncement() {
      clearTimeout(closeTimer);
      this.$emit('close');
    }
  }
};
</script>

<style scoped>
.title {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: bold;
}

.watch-together-popper {
  width: 290px;
  white-space: normal;
  text-align: center;
}

.watch-together-popper .popper-content-container {
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.watch-together-popper p {
  color: #ffffff;
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
}

.watch-together-popper .next-button {
  width: 100%;
  border-radius: 6px;
  margin-bottom: 0;
  margin-top: 8px;
}

.video-icon {
  width: 16px;
  height: 16px;
}
</style>
