/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-sugarcrm-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M5.235 22.067C2.175 20.832.51 20.081.295 19.812c-.322-.376-.322-.805-.268-7.41C.08 6.39.134 5.37.349 5.209c.322-.16 9.879 3.597 10.416 4.188l.376.376-.054 7.033v7.088l-.59.053c-.43 0-2.095-.59-5.262-1.879z" _fill="#343433"/><path pid="1" d="M12.322 23.839c-.054-.054-.107-3.221-.107-7.034 0-5.852.053-6.98.268-7.302.322-.43 9.718-4.241 10.309-4.188l.376.054.053 7.034c.054 6.496 0 7.033-.268 7.41-.322.375-9.61 4.187-10.201 4.187-.215-.054-.376-.054-.43-.161z" _fill="#CDCCCB"/><path pid="2" d="M5.986 6.443C2.066 4.886 1.1 4.403 1.1 4.188 1.1 3.705 10.39 0 11.624 0c1.235 0 10.523 3.705 10.523 4.188 0 .268-.912.698-4.885 2.309-3.437 1.396-5.101 1.933-5.584 1.933-.537-.054-2.363-.698-5.692-1.987z" _fill="#E61718"/></g><defs><clipPath id="clip0"><path pid="3" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
