/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table-sorting--descending': {
    width: 16,
    height: 16,
    viewBox: '0 0 6 10',
    data: '<path pid="0" d="M3 9.5l-3-4h6l-3 4z"/>'
  }
})
