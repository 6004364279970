/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-atoms-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 6c-1.8 0-3.3.75-4.35 1.95l-3.3-1.65c.15-.3.15-.75.15-1.05C10.5 2.4 8.1 0 5.25 0S0 2.4 0 5.25c0 2.1 1.2 3.9 3 4.65v4.05c-1.8.9-3 2.7-3 4.65 0 3 2.4 5.4 5.25 5.4s5.25-2.4 5.25-5.25c0-.3 0-.75-.15-1.05l3.15-1.65C14.7 17.25 16.2 18 18 18c3.3 0 6-2.7 6-6s-2.7-6-6-6zM5.25 3C6.45 3 7.5 4.05 7.5 5.25S6.45 7.5 5.25 7.5 3 6.45 3 5.25 4.05 3 5.25 3zm0 18C4.05 21 3 19.95 3 18.75s1.05-2.25 2.25-2.25 2.25 1.05 2.25 2.25S6.45 21 5.25 21zM9 15c-.75-.75-1.8-1.35-3-1.5v-3.15c1.2-.15 2.25-.75 3-1.5l3.15 1.65c-.15 1.05-.15 1.95 0 2.85L9 15zm9 0c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"/>'
  }
})
