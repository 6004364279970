/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-kotlin': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M0 12.672V0h24L12.032 11.946 0 24V12.672zM0 24l12.032-12.053L24 24H0z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
