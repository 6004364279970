/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-microphone-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 15c3.3 0 6-2.7 6-6V6c0-3.3-2.7-6-6-6S6 2.7 6 6v3c0 3.3 2.7 6 6 6z"/><path pid="1" d="M23.85 10.65c.15-.75-.45-1.5-1.2-1.65-.75-.15-1.65.45-1.65 1.2-.75 4.5-4.5 7.8-9 7.8s-8.25-3.3-8.85-7.65c-.15-.9-.9-1.5-1.8-1.35-.75.15-1.35.9-1.2 1.65.75 5.4 5.1 9.45 10.35 10.2V24h3v-3.15c5.25-.6 9.6-4.8 10.35-10.2z"/>'
  }
})
