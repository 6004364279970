var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vwc-dialog",
    {
      directives: [
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.l2.enter",
          value: _vm.hide,
          expression: "hide",
          modifiers: { l2: true, enter: true },
        },
      ],
      staticClass: "vvd-scheme-alternate dialog",
      attrs: {
        "data-cy": "info-modal",
        open: _vm.infoDialog,
        heading: _vm.infoDialog.title,
        escapeKeyAction: _vm.hide,
      },
      on: {
        closed: _vm.hide,
        click: function ($event) {
          $event.stopPropagation()
        },
      },
    },
    [
      _c("vwc-icon", {
        staticClass: "dialog-icon",
        attrs: { slot: "icon", size: "large", type: "warning-line" },
        slot: "icon",
      }),
      _c("div", { staticClass: "content" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v(_vm._s(_vm.infoDialog.text)),
        ]),
      ]),
      _c("vwc-button", {
        attrs: {
          slot: "primaryAction",
          "data-cy": "ok-btn",
          label: _vm.$t("info_modal.ok_button"),
          layout: "filled",
          connotation: "primary",
        },
        on: { click: _vm.hide },
        slot: "primaryAction",
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }