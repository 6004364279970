/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-alert': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 1a1.5 1.5 0 011.32.789l10.5 19.5A1.5 1.5 0 0122.5 23.5h-21a1.5 1.5 0 01-1.32-2.211l10.5-19.5A1.5 1.5 0 0112 1zm0 1.5l-.852 1.582L2.308 20.5 1.5 22h21l-.808-1.5-8.84-16.418L12 2.5zm-1.55 15.867a1.562 1.562 0 103.124 0 1.562 1.562 0 00-3.124 0zm0-3.122h3.123V9H10.45v6.245z"/>'
  }
})
