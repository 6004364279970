/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-less-detailed-list-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M.75 1.5h22.5v9H.75v-9zm0 12h22.5v9H.75v-9z"/>'
  }
})
