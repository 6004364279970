export const MIN_RECONNECT_DELAY = 2000;
export const MAX_RECONNECT_DELAY = 300000;
export const NETWORK_ERROR_RECONNECTION_DELAY = 10000;
export const RECONNECTION_DELAY_GROW_FACTOR = 1.3;
export const ACTIVITY_TIMEOUT_SEC = 20 * 1000;
export const PONG_TIMEOUT_SEC = 10 * 1000;
export const WS_CLOSED_NORMAL = 1000;

export const SERVICES = {
  BUS_MEETINGS_SERVICE: 'MEETINGS',
  BUS_MESSAGES_SERVICE: 'MESSAGES'
};

export const EVENTS_TYPES = {
  BUS_SESSION_PARTICIPANT_STATE: 'session:participant:state',
  BUS_SESSION_OWNER_CHANGED: 'session:owner',
  BUS_SESSION_CLOSED: 'session:closed',
  BUS_SESSION_RECORDING_STATE: 'session:recording:state',
  BUS_MESSAGING_MESSAGE_NEW: 'message:new',
  BUS_MESSAGING_MESSAGE_UPDATE: 'message:update',
  BUS_SESSION_IS_LOCKED: 'session:locked',
  BUS_SESSION_JOIN_APPROVAL_LEVEL_CHANGED: 'session:join-approval-level',
  BUS_SESSION_WHITEBOARD_STATUS: 'session:whiteboard:status',
  BUS_SESSION_WHITEBOARD_TOKEN: 'session:whiteboard:token',
  BUS_SESSION_ACTIVE_CAPTIONS_UPDATE: 'session:active-captions:update'
};
