/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-angular': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9.91 12h4.29L12 7.09 9.91 12zM12 0L1 3.927l1.76 14.837L12 24l9.24-5.346L23 3.927 12 0zM9.03 14.073l-1.32 3.49H5.18L12 2.074l7.04 15.49-2.42-.108-1.54-3.491h-3.19"/>'
  }
})
