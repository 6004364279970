import { LAYOUT_MODE_TYPES } from '@/consts/global-consts';

export default {
  isWatchTogetherOwner: (state, getters, rootState) =>
    state.watchTogetherOwnerParticipantId === rootState.myParticipantId,

  watchTogetherOwnerParticipant: (state, getters, rootState) =>
    rootState.participants[state.watchTogetherOwnerParticipantId],

  watchTogetherOwnerName: (state, getters) =>
    getters.watchTogetherOwnerParticipant.displayName,

  isWatchTogetherMinimized: (state, getters, rootState) =>
    !!rootState.pinnedStreamId ||
    rootState.layout.layoutMode === LAYOUT_MODE_TYPES.GRID,

  isWatchTogetherMaximized: (state, getters) =>
    state.isWatchTogetherActive && !getters.isWatchTogetherMinimized
};
