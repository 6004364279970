/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-clipboard-add-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M16.5 0h-9v6h9V0z"/><path pid="1" d="M22.5 1.5H18v3h3V21H3V4.5h3v-3H1.5C.6 1.5 0 2.1 0 3v19.5c0 .9.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5V3c0-.9-.6-1.5-1.5-1.5z"/><path pid="2" d="M13.5 12h3v3h-3v3h-3v-3h-3v-3h3V9h3v3z"/>'
  }
})
