/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-voicemail': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2.5 15H1V1h12v7c0 .3.2.5.5.5s.5-.2.5-.5V.5c0-.3-.2-.5-.5-.5H.5C.2 0 0 .2 0 .5v15c0 .3.2.5.5.5h2c.3 0 .5-.2.5-.5s-.2-.5-.5-.5z"/><path pid="1" d="M11 3.5c0-.3-.2-.5-.5-.5h-7c-.3 0-.5.2-.5.5s.2.5.5.5h7c.3 0 .5-.2.5-.5zM3.5 6c-.3 0-.5.2-.5.5s.2.5.5.5H9c.3 0 .5-.2.5-.5S9.3 6 9 6H3.5zM5.5 9.5c0-.3-.2-.5-.5-.5H3.5c-.3 0-.5.2-.5.5s.2.5.5.5H5c.3 0 .5-.2.5-.5zM13.5 11c-1.4 0-2.5 1.1-2.5 2.5 0 .6.2 1.1.5 1.5h-2c.3-.4.5-.9.5-1.5 0-1.4-1.1-2.5-2.5-2.5S5 12.1 5 13.5 6.1 16 7.5 16h6c1.4 0 2.5-1.1 2.5-2.5S14.9 11 13.5 11zm-6 4c-.8 0-1.5-.7-1.5-1.5S6.7 12 7.5 12s1.5.7 1.5 1.5S8.3 15 7.5 15zm6 0c-.8 0-1.5-.7-1.5-1.5s.7-1.5 1.5-1.5 1.5.7 1.5 1.5-.7 1.5-1.5 1.5z"/>'
  }
})
