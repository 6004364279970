/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-whisper': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2.667.833a.5.5 0 01.5.5v3.121L4.9 6.126l.017.02c.268.321.36.738.257 1.117-.104.39-.407.71-.85.821l-1.157.302v1.27l-1.733.787a.189.189 0 00.011.321l1.722.94v.963c0 1.01-.824 1.833-1.834 1.833H.667a.5.5 0 110-1h.666a.837.837 0 00.834-.833v-.37l-1.215-.663a1.19 1.19 0 010-2.068l.02-.012 1.195-.543V7.614l1.912-.5a.197.197 0 00.094-.047.138.138 0 00.036-.064.24.24 0 00-.047-.2L2.167 4.88V1.333a.5.5 0 01.5-.5zM8.667 3.167A.837.837 0 007.833 4v4.167l-1.5 2H14a.837.837 0 00.833-.834V4A.837.837 0 0014 3.167H8.667zM6.833 4c0-1.01.824-1.833 1.834-1.833H14c1.01 0 1.833.824 1.833 1.833v5.333c0 1.01-.824 1.834-1.833 1.834H4.333l2.5-3.334V4z"/><path pid="1" d="M9.5 4.833h3.667v1H9.5v-1zM9.5 7.5h2.333v1H9.5v-1z"/>'
  }
})
