/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-user-lock': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.5 0c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm0 10.5C7.95 10.5 6 8.55 6 6s1.95-4.5 4.5-4.5S15 3.45 15 6s-1.95 4.5-4.5 4.5zm3.45 3.45c-.9-.3-1.8-.45-2.7-.45h-1.5c-2.55 0-5.1 1.05-6.9 2.85C1.05 18.15 0 20.7 0 23.25c0 .075.037.188.075.3.038.113.075.225.075.3.075 0 .188.037.3.075.112.037.225.075.3.075h12c.45 0 .75-.3.75-.75s-.3-.75-.75-.75H1.5c.15-1.95 1.05-3.75 2.4-5.1C5.4 15.9 7.5 15 9.75 15h1.5c.75 0 1.5.15 2.25.3.45.15.75-.15.9-.45.15-.3 0-.9-.45-.9zm9.3 4.05v.3c.45.15.75.6.75 1.2v3c0 .9-.6 1.5-1.5 1.5h-6c-.9 0-1.5-.6-1.5-1.5v-3c0-.6.3-1.05.75-1.2V18c0-2.1 1.65-3.75 3.75-3.75s3.75 1.65 3.75 3.75zm-1.5 0c0-1.2-1.05-2.25-2.25-2.25S17.25 16.8 17.25 18h4.5zm-5.25 1.5v3h6v-3h-6z"/>'
  }
})
