/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-telephone-3': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.531 6.71l-.004-.023-.004-.024c-.065-.32 0-.84.318-1.461a4.385 4.385 0 011.314-1.535C4.591 2.759 7.333 1.5 12.151 1.5c4.75 0 7.575 1.3 8.608 2.104l.071.056.078.046c.491.295.95.793 1.251 1.382.304.594.393 1.166.314 1.6l-.002.012-.002.01c-.242 1.454-.771 1.737-.855 1.78a2.271 2.271 0 01-.276.126.316.316 0 01-.112.017c-.377 0-1.073-.134-1.882-.373-.782-.231-1.486-.504-1.833-.678-.38-.19-.625-.497-.787-.843a2.414 2.414 0 01-.165-.484 1.612 1.612 0 01-.034-.205V4.817l-1.026-.342c-.676-.225-1.769-.379-3.348-.379s-2.672.154-3.348.38l-1.025.341v1.177l-.01.035-.003.015a7.265 7.265 0 01-.066.245 2.338 2.338 0 01-.178.451c-.138.258-.382.567-.938.799l-.134.056-.12.08c-.248.165-.851.417-1.643.633-.773.21-1.497.325-1.912.325a.316.316 0 01-.112-.017 2.266 2.266 0 01-.276-.127c-.083-.042-.612-.325-.855-1.778zM12.151 0C7.01 0 3.984 1.361 2.321 2.42.807 3.479-.252 5.445.05 6.957c.303 1.815 1.06 2.571 1.664 2.874.303.151.605.302 1.06.302 1.21 0 3.478-.605 4.385-1.21 1.583-.66 1.9-1.894 2.056-2.5.023-.089.042-.164.062-.222v-.302c.453-.152 1.36-.303 2.873-.303 1.513 0 2.42.151 2.874.303v.15c0 .454.302 2.118 1.815 2.874.907.454 3.176 1.21 4.386 1.21.454 0 .756-.15 1.059-.302.605-.303 1.36-1.059 1.663-2.874.303-1.663-.756-3.63-2.268-4.537C20.318 1.36 17.142 0 12.15 0zM5.743 12.879l-3.364 3.364a3 3 0 00-.879 2.121V21h21v-2.636a3 3 0 00-.879-2.121l-3.364-3.364A3 3 0 0016.136 12H7.864a3 3 0 00-2.121.879zm-1.061-1.061A4.5 4.5 0 017.864 10.5h8.272a4.5 4.5 0 013.182 1.318l3.364 3.364A4.5 4.5 0 0124 18.364V21c0 .9-.6 1.5-1.5 1.5h-21C.6 22.5 0 21.9 0 21v-2.636a4.5 4.5 0 011.318-3.182l3.364-3.364zM10.5 16.5c0 .822.678 1.5 1.5 1.5s1.5-.678 1.5-1.5S12.822 15 12 15s-1.5.678-1.5 1.5zm1.5 3c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3z"/>'
  }
})
