<template>
  <div class="placeholder" :class="{ round, loading }"></div>
</template>

<script>
export default {
  props: {
    round: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style scoped>
.placeholder {
  background: #ebebeb;
  position: relative;
  overflow: hidden;
}

.placeholder.round {
  border-radius: 8px;
}

.placeholder.loading::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -100%;
  bottom: 0;
  right: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    #d8d8d8 50%,
    transparent 100%
  );
  animation: load 1.5s cubic-bezier(0.4, 0, 0.2, 1);
  animation-iteration-count: infinite;
}

@keyframes load {
  from {
    margin-left: -100%;
    right: 100%;
  }
  to {
    margin-left: 100%;
    right: -100%;
  }
}
</style>
