class StreamsStatsService {
  constructor() {
    // We use participants active volumes to store the intrinsic volumes so we'd update the UI and calculate the volume level only when we need to
    this.streamsActiveVolumes = {};
    this.myCurrentAudioLevel = [];
  }

  setMyCurrentAudioLevel(audioLevelArray) {
    this.myCurrentAudioLevel = audioLevelArray;
  }

  setStreamAudioLevel(streamId, audioLevelArray) {
    this.streamsActiveVolumes[streamId] = audioLevelArray;
  }
}

export default new StreamsStatsService();
