/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-packet-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.295.06a.75.75 0 00-.59 0l-10.5 4.5a.75.75 0 00-.455.688V18.75c0 .3.179.571.455.69l10.484 4.493a.747.747 0 00.622 0l10.484-4.494a.75.75 0 00.455-.689V5.274a.748.748 0 00-.458-.715L12.295.061zm8.301 5.19L12 1.566 3.404 5.25 12 8.934l8.596-3.684zM2.25 6.387v11.869l9 3.857V10.245l-9-3.858zm10.5 15.726l9-3.857V6.388L17.961 8.01A.748.748 0 0118 8.25v4.5a.75.75 0 01-1.5 0V8.638l-3.75 1.607v11.868z"/>'
  }
})
