/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-file-audio': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M13.5.5h-11c-.6 0-1 .4-1 1v13c0 .6.4 1 1 1h11c.6 0 1-.4 1-1v-13c0-.6-.4-1-1-1zm0 14h-11v-13h11v13z"/><path pid="1" d="M7 13c1.1 0 2-.9 2-2V4.4l2.2 1.5c.2.2.5.1.7-.1.2-.2.1-.5-.1-.7l-3-2c-.2-.1-.4-.1-.5 0-.2 0-.3.2-.3.4v5.8c-.3-.2-.6-.3-1-.3-1.1 0-2 .9-2 2s.9 2 2 2zm0-3c.6 0 1 .4 1 1s-.4 1-1 1-1-.4-1-1 .4-1 1-1z"/>'
  }
})
