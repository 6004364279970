/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-expand': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M0 2.5A.5.5 0 01.5 2h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5zm11.646 2.146a.5.5 0 01.708 0l3.5 3.5a.5.5 0 010 .708l-3.5 3.5a.5.5 0 01-.708-.708L14.293 9H.5a.5.5 0 010-1h13.793l-2.647-2.646a.5.5 0 010-.708zM0 14.5a.5.5 0 01.5-.5h8a.5.5 0 010 1h-8a.5.5 0 01-.5-.5z"/>'
  }
})
