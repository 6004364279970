var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.showMessagingError
    ? _c("ChatDialog", {
        attrs: {
          "img-src": _vm.isBranded
            ? "/illustrations/dialog/img-sad-face.svg"
            : "/illustrations/dialog/img-sad-square.svg",
          "dialog-title": _vm.$t("chat_dialog.general_error_title"),
          "dialog-subtitle": _vm.$t("chat_dialog.general_error_subtitle"),
          "action-button-text": _vm.$t(
            "chat_dialog.general_error_retry_button"
          ),
        },
        on: {
          "action-button-click": function ($event) {
            return _vm.$emit("action-button-click")
          },
        },
      })
    : _vm.showUnsupportedFileDialog
    ? _c("ChatDialog", {
        attrs: {
          "img-src": _vm.isBranded
            ? "/illustrations/dialog/img-question-mark.svg"
            : "/illustrations/dialog/img-figure-holding-unavailable-sign.svg",
          "dialog-title": _vm.$t("chat_dialog.type_not_supported_error_title"),
          "dialog-subtitle": _vm.$t(
            "chat_dialog.type_not_supported_error_subtitle"
          ),
          "action-button-text": _vm.$t(
            "chat_dialog.type_not_supported_error_got_it_button"
          ),
        },
        on: {
          "action-button-click": function ($event) {
            return _vm.$emit("action-button-click")
          },
        },
      })
    : _vm.showUnsupportedFolderDialog
    ? _c("ChatDialog", {
        attrs: {
          "img-src": _vm.isBranded
            ? "/illustrations/dialog/img-folder-icon.svg"
            : "/illustrations/dialog/img-file-types.svg",
          "dialog-title": _vm.$t("chat_dialog.cant_upload_folders_title"),
          "dialog-subtitle": _vm.$t("chat_dialog.cant_upload_folders_subtitle"),
          "action-button-text": _vm.$t(
            "chat_dialog.cant_upload_folders_got_it_button"
          ),
        },
        on: {
          "action-button-click": function ($event) {
            return _vm.$emit("action-button-click")
          },
        },
      })
    : _vm.showMaxNumberOfFilesReachedDialog
    ? _c("ChatDialog", {
        attrs: {
          "img-src": _vm.isBranded
            ? "/illustrations/dialog/img-sad-face.svg"
            : "/illustrations/dialog/img-sad-square.svg",
          "dialog-title": _vm.$t("chat_dialog.max_number_of_files_error_title"),
          "dialog-subtitle": _vm.$t(
            "chat_dialog.max_number_of_files_error_subtitle",
            {
              maxNumberOfFiles: _vm.maxNumberOfFilesToUpload(),
            }
          ),
          "action-button-text": _vm.$t(
            "chat_dialog.max_number_of_files_error_got_it_button"
          ),
        },
        on: {
          "action-button-click": function ($event) {
            return _vm.$emit("action-button-click")
          },
        },
      })
    : _vm.showMaxFilesSizeReachedDialog
    ? _c("ChatDialog", {
        attrs: {
          "img-src": _vm.isBranded
            ? "/illustrations/dialog/img-sad-face.svg"
            : "/illustrations/dialog/img-sad-square.svg",
          "dialog-title": _vm.$t(
            "chat_dialog.total_files_size_exceeded_error_title"
          ),
          "dialog-subtitle": _vm.$t(
            "chat_dialog.total_files_size_exceeded_error_subtitle",
            {
              maxUploadSize: _vm.maxSizeForErrorMessage(),
            }
          ),
          "action-button-text": _vm.$t(
            "chat_dialog.total_files_size_exceeded_error_got_it_button"
          ),
        },
        on: {
          "action-button-click": function ($event) {
            return _vm.$emit("action-button-click")
          },
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }