<template>
  <div class="meeting-info-container">
    <div class="meeting-id-container">
      <div class="meeting-id">
        <span class="option-title" data-cy="meeting-id-title">{{
          $t('meeting_info.meeting_id_title')
        }}</span>
        <span class="option-value" data-cy="meeting-id">{{ roomPinCode }}</span>
      </div>
      <vwc-icon-button
        data-cy="copy-icon-pin"
        icon="copy-2-line"
        onmouseup="this.blur()"
        @click="copyRoomPinToClipboard"
      />
    </div>
    <div class="link-container">
      <div class="meeting-link">
        <span class="option-title" data-cy="meeting-link-title">{{
          $t('meeting_info.meeting_link_title')
        }}</span>
        <span class="option-value" data-cy="meeting-link">{{ roomUrl }}</span>
      </div>
      <vwc-icon-button
        data-cy="copy-icon-link"
        icon="copy-2-line"
        onmouseup="this.blur()"
        @click="copyRoomUrlToClipboard"
      />
    </div>
    <DialInInfo class="dial-in" />

    <vwc-button
      class="copy-details-button"
      data-cy="copy-meeting-details"
      connotation="primary"
      layout="filled"
      fullwidth
      icon="copy-2-solid"
      :label="$t('meeting_info.copy_info_button')"
      @click="copyMeetingInfoToClipboard"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { clipboardService } from '@/services/clipboard-service';
import analytics from '@/services/analytics-service';
import { ANALYTICS } from '@/consts/global-consts';
import DialInInfo from '@/components/DialInInfo';

export default {
  name: 'MeetingInfo',

  components: {
    DialInInfo
  },

  computed: {
    ...mapGetters([
      'roomPinCode',
      'roomUrl',
      'detailsStringToCopy',
      'activeParticipants'
    ])
  },

  methods: {
    ...mapActions(['addFlashMessage']),

    copyToClipboard(value, type, text) {
      clipboardService.copy(value);
      this.addFlashMessage({
        type: 'good',
        text: text
      });
      analytics.trackEvent(ANALYTICS.INFO_COPIED, {
        Source: 'Meeting Info',
        'Num of Participants': this.activeParticipants.length,
        'Info copied': type
      });
    },

    copyRoomUrlToClipboard() {
      this.copyToClipboard(
        this.roomUrl,
        'Link',
        this.$t('meeting_info.link_copied_flash_message')
      );
    },

    copyRoomPinToClipboard() {
      this.copyToClipboard(
        this.roomPinCode,
        'PIN',
        this.$t('meeting_info.pin_copied_flash_message')
      );
    },

    copyMeetingInfoToClipboard() {
      this.copyToClipboard(
        this.detailsStringToCopy,
        'Details',
        this.$t('meeting_info.info_copied_flash_message')
      );
    }
  }
};
</script>

<style scoped>
.meeting-info-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 0 20px 20px 20px;
  overflow-y: auto;
}

.meeting-id-container,
.link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.meeting-id,
.meeting-link {
  display: flex;
  flex-direction: row;
  max-width: 265px;
}

.option-title {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-right: 4px;
  flex-shrink: 0;
}

.option-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.dial-in {
  margin-top: 28px;
}

.copy-details-button {
  margin-top: 32px;
}
</style>
