/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-bar-chart-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2.5 13c-.3 0-.5-.2-.5-.5s.2-.5.5-.5c3.9 0 4.9-2.4 6-5.2 1.1-2.7 2.4-5.8 7-5.8.3 0 .5.2.5.5s-.2.5-.5.5c-3.9 0-4.9 2.4-6 5.2-1.2 2.7-2.4 5.8-7 5.8z"/><path pid="1" d="M15.5 15H.5c-.3 0-.5-.2-.5-.5v-13c0-.3.2-.5.5-.5s.5.2.5.5V14h14.5c.3 0 .5.2.5.5s-.2.5-.5.5z"/>'
  }
})
