<template>
  <div class="preview-container">
    <div v-if="isVideoIdValid" class="thumbnail-container">
      <img
        class="video-thumbnail"
        alt="Video Thumbnail"
        :src="videoMetadata.thumbnailUrl"
      />
      <div class="thumbnail-duration-overlay unselectable">
        {{ videoDuration }}
      </div>
    </div>
    <div
      v-else
      class="video-thumbnail video-thumbnail-placeholder placeholder"
      :class="{ 'placeholder-loading': isLoadingVideoMetadata }"
    />

    <div class="text-container">
      <span v-if="isVideoIdValid" class="Vlt-black video-title">{{
        videoMetadata.title
      }}</span>
      <div v-else class="text-container">
        <div
          class="video-title video-title-placeholder placeholder placeholder-round"
          :class="{ 'placeholder-loading': isLoadingVideoMetadata }"
        />
        <div
          class="video-title video-title-placeholder placeholder placeholder-round"
          :class="{ 'placeholder-loading': isLoadingVideoMetadata }"
        />
      </div>

      <span v-if="isVideoIdValid" class="Vlt-grey-darker video-author"
        >{{
          $t('watch_together_video_preview.video_author_text', {
            author: videoMetadata.author
          })
        }}
      </span>
      <div
        v-else
        class="video-author video-author-placeholder placeholder placeholder-round"
        :class="{ 'placeholder-loading': isLoadingVideoMetadata }"
      ></div>
    </div>
  </div>
</template>

<script>
import VideoMetadata from '@/services/watch-together/video-metadata';
import { durationToString } from '@/helpers/time-utils';

export default {
  props: {
    videoMetadata: {
      type: VideoMetadata,
      default: null
    },
    isLoadingVideoMetadata: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isVideoIdValid() {
      return this.videoMetadata != null;
    },

    videoDuration() {
      return durationToString(this.videoMetadata.duration);
    }
  }
};
</script>

<style scoped>
.preview-container {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  transition: height 0.25s linear;
}

.preview-container > *:not(:first-child) {
  margin-left: 2rem;
}

.text-container {
  display: flex;
  flex-direction: column;
}

.text-container > *:not(:first-child) {
  margin-top: 0.5rem;
}

.thumbnail-container {
  flex-shrink: 0;
  width: 170px;
  height: 96px;
  display: grid;
  justify-items: right;
  justify-content: right;
  align-items: end;
  align-content: end;
}

.video-thumbnail {
  max-height: 128px;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.thumbnail-duration-overlay {
  background: rgba(0, 0, 0, 0.9);
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 4px;
  padding: 1px 4px;
  grid-row: 1 / 1;
  grid-column: 1 / 1;
}

.video-title {
  font-weight: bold;
}

.video-author {
  font-size: 12px;
}

.video-thumbnail-placeholder {
  width: 170px;
  height: 96px;
  background: #ebebeb;
}

.video-title-placeholder {
  width: 140px;
  height: 20px;
}

.placeholder {
  background: #ebebeb;
  position: relative;
  overflow: hidden;
}

.placeholder-round {
  border-radius: 8px;
}

.video-author-placeholder {
  margin-top: 0.5rem;
  width: 100px;
  height: 15px;
  border-radius: 8px;
  background: #ebebeb;
}

.placeholder-loading::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  margin-left: -100%;
  bottom: 0;
  right: 100%;
  background: linear-gradient(
    to right,
    transparent 0%,
    #d8d8d8 50%,
    transparent 100%
  );
  animation: load 1.5s cubic-bezier(0.4, 0, 0.2, 1);
  animation-iteration-count: infinite;
}

@keyframes load {
  from {
    margin-left: -100%;
    right: 100%;
  }
  to {
    margin-left: 100%;
    right: -100%;
  }
}
</style>
