/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-my-apps': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.05.3H9c.45 0 .75.3.75.75V9c0 .45-.3.75-.75.75H1.05C.6 9.75.3 9.45.3 9V1.05C.3.6.6.3 1.05.3zm.75 7.95h6.45V1.8H1.8v6.45zM15 .3h7.95c.45 0 .75.3.75.75V9c0 .45-.3.75-.75.75H15c-.45 0-.75-.3-.75-.75V1.05c0-.45.3-.75.75-.75zm.75 7.95h6.45V1.8h-6.45v6.45zm-6.75 6H1.05c-.45 0-.75.3-.75.75v7.95c0 .45.3.75.75.75H9c.45 0 .75-.3.75-.75V15c0-.45-.3-.75-.75-.75zm-.75 7.95H1.8v-6.45h6.45v6.45zM15 14.25h7.95c.45 0 .75.3.75.75v7.95c0 .45-.3.75-.75.75H15c-.45 0-.75-.3-.75-.75V15c0-.45.3-.75.75-.75zm.75 7.95h6.45v-6.45h-6.45v6.45z"/>'
  }
})
