/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-note': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19 4.9v11.3c-.2.9-1.1 1.8-2.3 2.3-1.7.6-3.4.2-3.8-.9s.6-2.6 2.3-3.2c.9-.3 1.8-.4 2.5-.2V6.9L9.3 9.3v10.2c-.2.9-1.1 1.8-2.3 2.3-1.7.6-3.4.2-3.8-.9s.6-2.6 2.3-3.2c.8-.3 1.6-.4 2.3-.2V5.7c0-.4.3-.8.7-1L17.7 2c.5-.2 1.1.2 1.2.7V3l.1 1.9z"/>'
  }
})
