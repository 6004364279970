var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "vwc-dialog",
    {
      directives: [
        {
          name: "observe-keyboard",
          rawName: "v-observe-keyboard.l2.enter",
          value: _vm.onConfirm,
          expression: "onConfirm",
          modifiers: { l2: true, enter: true },
        },
      ],
      staticClass: "vvd-scheme-alternate",
      style: _vm.dialogStyle,
      attrs: {
        open: _vm.open,
        topposition: _vm.topposition,
        hideactions: _vm.hideActions,
      },
      on: { closed: _vm.closeDialog },
    },
    [
      _c(
        "div",
        { staticClass: "dialog-container" },
        [
          _c(
            "div",
            {
              staticClass: "header-container",
              class: {
                "small-modal": _vm.isSmallModal,
                "big-modal": !_vm.isSmallModal,
              },
            },
            [
              _c(
                "div",
                { staticClass: "heading-wrapper" },
                [
                  _vm.iconName
                    ? _c("vwc-icon", {
                        staticClass: "icon",
                        attrs: { type: _vm.iconName },
                      })
                    : _vm._e(),
                  _c("div", { staticClass: "title-wrapper" }, [
                    _c(
                      "div",
                      {
                        staticClass: "title",
                        attrs: { "data-cy": "dialog-title" },
                      },
                      [_vm._v(_vm._s(_vm.heading))]
                    ),
                    _vm.subtitle
                      ? _c("div", { staticClass: "subtitle" }, [
                          _vm._v(" " + _vm._s(_vm.subtitle) + " "),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              ),
              _c("vwc-icon", {
                staticClass: "dismiss-button",
                attrs: {
                  "data-cy": "dismiss-button",
                  type: "close-solid",
                  size: "small",
                },
                on: { click: _vm.closeDialog },
              }),
            ],
            1
          ),
          _vm._t("content", [_c("div", [_vm._v(_vm._s(_vm.content))])]),
        ],
        2
      ),
      _vm._t("footer-buttons", [
        _vm.primaryAction
          ? _c(
              "vwc-button",
              {
                attrs: {
                  slot: "primaryAction",
                  layout: "filled",
                  dialogaction: _vm.primaryActionDialogAction,
                  type: "submit",
                  "data-cy": "main-action-btn",
                  icon: _vm.primaryButtonIcon,
                  connotation: _vm.primaryButtonConnotation,
                  disabled: _vm.disablePrimaryButton,
                },
                on: { click: _vm.onConfirm },
                slot: "primaryAction",
              },
              [
                _vm._v(" " + _vm._s(_vm.primaryAction) + " "),
                _vm.loadingPrimaryButton
                  ? _c("vwc-circular-progress", {
                      staticClass: "primary-btn-loading-spinner",
                      attrs: { indeterminate: "", density: "-8" },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.secondaryAction
          ? _c(
              "vwc-button",
              {
                attrs: {
                  slot: "secondaryAction",
                  layout: "outlined",
                  dialogaction: "cancel",
                  type: "submit",
                  "data-cy": "cancel-btn",
                },
                slot: "secondaryAction",
              },
              [_vm._v(" " + _vm._s(_vm.secondaryAction) + " ")]
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }