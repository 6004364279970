export default {
  showCaptions: (state, getters, rootState, rootGetters) => {
    return (
      getters.isSessionCaptionsEnabled &&
      state.isCaptionsEnabled &&
      !rootGetters.isMobileWebMode &&
      !rootState.minimizedMode
    );
  },

  isSessionCaptionsEnabled: (state) => {
    return state.activeCaptionsId !== null;
  },

  isCaptionsOrLiveTranscriptionEnabled: (state) => {
    return state.isCaptionsEnabled || state.isLiveTranscriptionEnabled;
  }
};
