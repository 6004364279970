/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-pin-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M23.55 8.55l-8.1-8.1c-.6-.6-1.5-.6-2.1 0-.6.6-.6 1.5 0 2.1l.9.9L4.05 9l-1.5-1.5c-.6-.6-1.5-.6-2.1 0-.6.6-.6 1.5 0 2.1l2.25 2.25 3.6 3.6L.9 21 3 23.1l5.55-5.55 6 6c.75.75 1.8.3 2.1 0 .6-.6.6-1.5 0-2.1l-1.5-1.5L20.7 9.9l.9.9c.9.9 1.8.3 2.1 0 .45-.6.45-1.65-.15-2.25zm-10.8 9.15L6.3 11.25 16.35 5.7l1.95 1.95-5.55 10.05z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
