/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-merge': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6.7 9.3c.2.2.2.6 0 .8L1 15.8c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8l5.7-5.7c.2-.2.6-.2.8 0zM15.8.2c.2.2.2.6 0 .8l-5.7 5.7c-.2.2-.6.2-.8 0-.2-.2-.2-.6 0-.8L15 .2c.2-.3.6-.3.8 0z"/><path pid="1" d="M1.1 9.7c0-.3.3-.6.6-.6h4.6c.3 0 .6.3.6.6v4.6c0 .3-.3.6-.6.6s-.6-.3-.6-.6v-4h-4c-.3 0-.6-.3-.6-.6zM9.7 1.1c.3 0 .6.3.6.6v4h4c.3 0 .6.3.6.6s-.3.6-.6.6H9.7c-.3 0-.6-.3-.6-.6V1.7c0-.3.3-.6.6-.6zM4.2 4.2c.2-.2.6-.2.8 0l6.9 6.9c.2.2.2.6 0 .8-.2.2-.6.2-.8 0L4.2 5c-.3-.2-.3-.6 0-.8z"/>'
  }
})
