/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-help': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12 6.201 22.5 12 22.5zm0 1.5c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12zm-1.246-6.797c0 .787.525 1.312 1.312 1.312.786 0 1.311-.525 1.311-1.312s-.525-1.311-1.311-1.311c-.787 0-1.312.524-1.312 1.311zm2.623-3.934c0-.262 0-.394.656-.787.068-.055.143-.112.223-.173.69-.527 1.744-1.33 1.744-2.975 0-2.23-1.705-3.934-3.934-3.934-1.443 0-2.755.787-3.41 1.967L8 8.547l2.23 1.312.655-1.18c.263-.394.656-.656 1.18-.656.787 0 1.312.524 1.312 1.311 0 .394-.131.525-.787.918-.787.525-1.836 1.312-1.836 3.017v1.311h2.623V13.27z"/>'
  }
})
