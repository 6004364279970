/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-more-detailed-list': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 3c0-.45.3-.75.75-.75h22.5c.45 0 .75.3.75.75v3c0 .45-.3.75-.75.75H.75C.3 6.75 0 6.45 0 6V3zm1.5.75v1.5h21v-1.5h-21zM0 10.5c0-.45.3-.75.75-.75h22.5c.45 0 .75.3.75.75v3c0 .45-.3.75-.75.75H.75c-.45 0-.75-.3-.75-.75v-3zm1.5.75v1.5h21v-1.5h-21zm-.75 6c-.45 0-.75.3-.75.75v3c0 .45.3.75.75.75h22.5c.45 0 .75-.3.75-.75v-3c0-.45-.3-.75-.75-.75H.75zm.75 3v-1.5h21v1.5h-21z"/>'
  }
})
