/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-volume-decrease': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 2.25a.75.75 0 00-1.166-.624L11.023 7.5H.75a.75.75 0 00-.75.75v9c0 .414.336.75.75.75h10.273l8.811 5.874A.75.75 0 0021 23.25v-4.5a.75.75 0 00-1.5 0v3.099l-7.834-5.223a.75.75 0 00-.416-.126H1.5V9h9.75a.75.75 0 00.416-.126L19.5 3.651V6.75a.75.75 0 001.5 0v-4.5zM17.25 12a.75.75 0 000 1.5h6a.75.75 0 000-1.5h-6z"/>'
  }
})
