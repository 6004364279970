var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "contacts" },
    [
      _c(
        "vwc-list",
        [
          _c("recycle-scroller", {
            ref: "list",
            attrs: { "page-mode": "", "item-size": 64, items: _vm.contacts },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var item = ref.item
                  return [
                    _c("ContactRow", {
                      key: item.id,
                      attrs: { contact: item },
                      on: {
                        click: function ($event) {
                          return _vm.onContactClick(item)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }