/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-share-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 9c2.481 0 4.5-2.019 4.5-4.5S20.481 0 18 0a4.505 4.505 0 00-4.5 4.5c0 .335.043.659.113.973l-4.83 3.019A4.454 4.454 0 006 7.5 4.505 4.505 0 001.5 12c0 2.481 2.019 4.5 4.5 4.5a4.454 4.454 0 002.783-.992l4.83 3.018a4.536 4.536 0 00-.113.974c0 2.481 2.019 4.5 4.5 4.5s4.5-2.019 4.5-4.5S20.481 15 18 15a4.454 4.454 0 00-2.783.992l-4.83-3.019c.07-.315.113-.639.113-.973 0-.335-.043-.659-.113-.973l4.83-3.018C15.986 8.618 16.944 9 18 9z"/>'
  }
})
