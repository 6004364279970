var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "notifications" }, [
    _c("div", { staticClass: "notifications-title unselectable" }, [
      _vm._v(" " + _vm._s(_vm.$t("notifications_settings.title")) + " "),
    ]),
    _c(
      "div",
      {
        staticClass: "notification-row",
        attrs: { "data-cy": "play-sound-when-join-leave" },
      },
      [
        _vm._v(
          " " +
            _vm._s(
              _vm.$t("notifications_settings.participants_join_or_leave_text")
            ) +
            " "
        ),
        _c("vwc-switch", {
          directives: [
            {
              name: "tooltip",
              rawName: "v-tooltip",
              value: _vm.joinOrLeaveNotificationTooltip,
              expression: "joinOrLeaveNotificationTooltip",
            },
          ],
          attrs: {
            connotation: "cta",
            disabled: _vm.disableJoinOrLeaveSound,
            checked: _vm.isJoinOrLeaveSoundNotificationsEnabled,
          },
          on: {
            change: function ($event) {
              _vm.isJoinOrLeaveSoundNotificationsEnabled = !_vm.isJoinOrLeaveSoundNotificationsEnabled
            },
          },
        }),
      ],
      1
    ),
    _vm.isSessionOwner
      ? _c(
          "div",
          {
            staticClass: "notification-row",
            attrs: {
              "data-cy": "play-sound-when-you-have-guests-in-the-waiting-room",
            },
          },
          [
            _vm._v(
              " " +
                _vm._s(
                  _vm.$t("notifications_settings.guests_in_waiting_room_text")
                ) +
                " "
            ),
            _c("vwc-switch", {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip",
                  value: _vm.joinWaitingRoomNotificationTooltip,
                  expression: "joinWaitingRoomNotificationTooltip",
                },
              ],
              attrs: {
                connotation: "cta",
                disabled: _vm.disableWaitingRoomSoundNotification,
                checked: _vm.isWaitingRoomSoundNotificationsEnabled,
              },
              on: {
                change: function ($event) {
                  _vm.isWaitingRoomSoundNotificationsEnabled = !_vm.isWaitingRoomSoundNotificationsEnabled
                },
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }