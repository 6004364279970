/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-calculator': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M4.5 3.75A.75.75 0 015.25 3h13.5a.75.75 0 01.75.75v4.5a.75.75 0 01-.75.75H5.25a.75.75 0 01-.75-.75v-4.5zM6 4.5v3h12v-3H6zM5.25 10.5a.75.75 0 00-.75.75v3c0 .414.336.75.75.75h6a.75.75 0 00.75-.75v-3a.75.75 0 00-.75-.75h-6zm.75 3V12h4.5v1.5H6zM4.5 17.25a.75.75 0 01.75-.75h6a.75.75 0 01.75.75v3a.75.75 0 01-.75.75h-6a.75.75 0 01-.75-.75v-3zM6 18v1.5h4.5V18H6zM14.25 10.5a.75.75 0 00-.75.75v9c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-9a.75.75 0 00-.75-.75h-4.5zm.75 9V12h3v7.5h-3z"/><path pid="1" d="M1.5 2.25A2.25 2.25 0 013.75 0h16.5a2.25 2.25 0 012.25 2.25v19.5A2.25 2.25 0 0120.25 24H3.75a2.25 2.25 0 01-2.25-2.25V2.25zm2.25-.75a.75.75 0 00-.75.75v19.5c0 .414.336.75.75.75h16.5a.75.75 0 00.75-.75V2.25a.75.75 0 00-.75-.75H3.75z"/>'
  }
})
