/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-quit': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M9 .5a.5.5 0 00-1 0v7a.5.5 0 001 0v-7zM4.48 3.393a.5.5 0 10-.62-.786 7.5 7.5 0 109.28 0 .5.5 0 00-.62.786 6.5 6.5 0 11-8.04 0z"/>'
  }
})
