var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ParticipantView", {
    staticClass: "whiteboard-participant-view",
    attrs: {
      displayName: _vm.$t("whiteboard.display_name"),
      participantType: "Guest",
      preferred: _vm.preferred,
      canBePinned: !_vm.isWhiteboardPinned,
      canShowContent: true,
      isMainParticipant: _vm.isWhiteboardPinned,
      isPinned: _vm.isWhiteboardPinned,
      showDisplayName: _vm.showOverlay,
    },
    on: { pinToggled: _vm.pinWhiteboard },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("div", { staticClass: "whiteboard-iframe-container" }, [
              _c("iframe", {
                ref: "whiteboardIframe",
                staticClass: "whiteboard-iframe",
                attrs: {
                  id: "whiteboard-iframe",
                  "data-cy": "whiteboard-iframe",
                  title: _vm.$t("whiteboard.iframe_title"),
                  src: _vm.whiteboardUrl,
                },
              }),
              _vm.showOverlay
                ? _c("div", { staticClass: "pinned-overlay" })
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }