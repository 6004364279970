<template functional>
  <button
    :id="data.attrs.id"
    v-tooltip.bottom="props.tooltip"
    :data-cy="data.attrs.id"
    class="Vlt-btn Vlt-btn--no-focus unselectable"
    :class="[
      {
        'Vlt-btn--icon': !slots().default,
        'Vlt-btn--disabled': props.disabled,
        'Vlt-btn--app': props.app,
        'Vlt-btn--primary': props.primary,
        'Vlt-btn--secondary': props.secondary,
        'Vlt-btn--destructive': props.destructive,
        'Vlt-btn--tertiary': !(
          props.secondary ||
          props.quaternary ||
          props.primary ||
          props.destructive
        ),
        'Vlt-btn--small': props.sm,
        'Vlt-btn--large': props.lg,
        'Vlt-btn--quaternary': props.quaternary,
        'Vlt-btn--outline': props.outline,
        'Vlt-btn--white': props.white,
        'Vlt-btn--link': props.link
      },
      data.staticClass,
      data.class
    ]"
    v-on="listeners"
  >
    <v-icon
      v-if="props.iconName"
      :spin="props.spin"
      :iconName="props.iconName"
    />
    <slot />
  </button>
</template>

<script>
export default {
  name: 'VButton',

  props: {
    iconName: {
      type: String,
      default: ''
    },
    tooltip: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    sm: {
      type: Boolean,
      default: false
    },
    lg: {
      type: Boolean,
      default: false
    },
    destructive: {
      type: Boolean,
      default: false
    },
    app: {
      type: Boolean,
      default: false
    },
    primary: {
      type: Boolean,
      default: false
    },
    secondary: {
      type: Boolean,
      default: false
    },
    quaternary: {
      type: Boolean,
      default: false
    },
    spin: {
      type: Boolean,
      default: false
    },
    outline: {
      type: Boolean,
      default: false
    },
    white: {
      type: Boolean,
      default: false
    },
    link: {
      type: Boolean,
      default: false
    }
  }
};
</script>
