/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-app': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M4.5 2h-2c-.3 0-.5.2-.5.5s.2.5.5.5h2c.3 0 .5-.2.5-.5S4.8 2 4.5 2z"/><path pid="1" d="M14.5 0h-13C.7 0 0 .7 0 1.5v13c0 .8.7 1.5 1.5 1.5h13c.8 0 1.5-.7 1.5-1.5v-13c0-.8-.7-1.5-1.5-1.5zm.5 14.5c0 .3-.2.5-.5.5h-13c-.3 0-.5-.2-.5-.5V5h14v9.5zM15 4H1V1.5c0-.3.2-.5.5-.5h13c.3 0 .5.2.5.5V4z"/>'
  }
})
