/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mind-map-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M19.5 15c-.9 0-1.65.3-2.4.75l-3.6-3V8.7c1.8-.6 3-2.25 3-4.2C16.5 1.95 14.55 0 12 0S7.5 1.95 7.5 4.5c0 1.95 1.2 3.6 3 4.2v4.05L6.9 15.6c-.75-.3-1.5-.6-2.4-.6C1.95 15 0 16.95 0 19.5S1.95 24 4.5 24 9 22.05 9 19.5c0-.45-.15-1.05-.3-1.5l3.3-2.55L15.3 18c-.15.45-.3.9-.3 1.5 0 2.55 1.95 4.5 4.5 4.5s4.5-1.95 4.5-4.5-1.95-4.5-4.5-4.5zm-15 6c-.9 0-1.5-.6-1.5-1.5S3.6 18 4.5 18s1.5.6 1.5 1.5S5.4 21 4.5 21zM12 6c-.9 0-1.5-.6-1.5-1.5S11.1 3 12 3s1.5.6 1.5 1.5S12.9 6 12 6zm7.5 15c-.9 0-1.5-.6-1.5-1.5s.6-1.5 1.5-1.5 1.5.6 1.5 1.5-.6 1.5-1.5 1.5z"/>'
  }
})
