<template>
  <div
    ref="bar"
    class="bar"
    :style="{ height: `${barHeight}px` }"
    @mousemove="(event) => $emit('mouseMoveOnBar', event)"
    @click="barClicked"
  >
    <resize-observer @notify="onSliderSizeChangedThrottled" />

    <div class="extra-padding" />
    <div class="secondary-progress" :style="secondaryProgressBarStyle" />
    <div class="progress" :style="progressBarStyle" />
    <div
      class="thumb-container"
      :style="thumbContainerStyle"
      @mousedown="dragStart"
    >
      <div class="thumb" :style="thumbStyle" />
    </div>
  </div>
</template>

<script>
import throttle from 'lodash.throttle';

export default {
  name: 'WatchTogetherSlider',

  props: {
    value: {
      type: Number,
      default: 0
    },
    /**
     * A secondary value takes up space on the slider's bar, but cannot by changed by the user.
     * For example, it can be used to represent the buffered part of a video.
     */
    secondaryValue: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 1
    },
    primaryColor: {
      type: String,
      required: true
    },
    thumbSize: {
      type: Number,
      default: 14
    },
    thumbPadding: {
      type: Number,
      default: 6
    },
    barHeight: {
      type: Number,
      default: 5
    },
    /**
     * Collapsible sliders support size animations because they do not apply CSS transforms.
     * Sliders are not collapsible by default, because transforms are rendered more smoothly.
     */
    collapsible: {
      type: Boolean,
      default: false
    },
    /**
     * Width of the slider when it is not collapsed.
     * Only relevant for collapsible sliders.
     */
    expandedWidth: {
      type: Number,
      default: NaN
    }
  },

  data() {
    return {
      dragging: false,
      currentValue: 0, // represents the exact value that appears in the UI
      hoverTime: 0,
      sliderWidth: 0,
      onSliderSizeChangedThrottled: throttle(this.onSliderSizeChanged, 200, {
        leading: false
      })
    };
  },

  computed: {
    progressBarStyle() {
      const progressPart = this.currentValue / this.max;
      const customizableProperties = {
        backgroundColor: this.primaryColor,
        height: `${this.barHeight}px`
      };

      // Collapsible sliders are animated by their width, because scaling them will interfere with
      // their collapsing animation
      if (this.collapsible && !isNaN(this.expandedWidth)) {
        customizableProperties.width = `${progressPart * this.expandedWidth}px`;
      } else {
        customizableProperties.transform = `scaleX(${progressPart})`;
      }

      return customizableProperties;
    },

    secondaryProgressBarStyle() {
      const progressPart = this.secondaryValue / this.max;
      const height = `${this.barHeight}px`;

      // Collapsible sliders are animated by their width, because scaling them will interfere with
      // their collapsing animation
      if (this.collapsible) {
        return {
          height,
          width: `${progressPart * this.expandedWidth}px`
        };
      }

      return {
        height,
        transform: `scaleX(${progressPart})`
      };
    },

    thumbContainerStyle() {
      const progressPart = this.currentValue / this.max;
      const customizableProperties = {
        width: `${this.thumbSize + this.thumbPadding * 2}px`,
        height: `${this.thumbSize + this.thumbPadding * 2}px`,
        left: `-${this.thumbSize / 2 + this.thumbPadding}px`,
        top: `-${
          Math.abs(this.thumbSize - this.barHeight) / 2 + this.thumbPadding
        }px`
      };

      if (this.collapsible) {
        customizableProperties.left = `${
          progressPart * this.expandedWidth -
          this.thumbSize / 2 -
          this.thumbPadding
        }px`;
      } else {
        customizableProperties.transform = `translateX(${
          progressPart * this.sliderWidth
        }px)`;
      }

      return customizableProperties;
    },

    thumbStyle() {
      return {
        backgroundColor: this.primaryColor,
        width: `${this.thumbSize}px`,
        height: `${this.thumbSize}px`,
        left: `${this.thumbPadding}px`,
        top: `${this.thumbPadding}px`
      };
    }
  },

  watch: {
    value() {
      if (this.dragging) {
        return;
      }

      // Track the value prop
      this.currentValue = this.value;
    }
  },

  mounted() {
    this.bindEvent();

    this.sliderWidth = this.$refs.bar.offsetWidth;
  },

  beforeDestroy() {
    this.unbindEvent();
  },

  methods: {
    bindEvent() {
      document.addEventListener('mousemove', this.dragMove);
      document.addEventListener('mouseup', this.dragEnd);
      document.addEventListener('mouseleave', this.dragEnd);
    },

    unbindEvent() {
      document.removeEventListener('mousemove', this.dragMove);
      document.removeEventListener('mouseup', this.dragEnd);
      document.removeEventListener('mouseleave', this.dragEnd);
    },

    dragStart() {
      this.dragging = true;
      this.$emit('dragstart');
    },

    dragMove(event) {
      if (!this.dragging) {
        return;
      }

      event.preventDefault();

      const value = this.getValueByEvent(event);
      this.currentValue = value;
      this.$emit('dragging', value);
    },

    dragEnd(event) {
      if (!this.dragging) {
        return;
      }

      event.preventDefault();
      this.dragging = false;

      const value = this.getValueByEvent(event);
      this.currentValue = value;
      this.$emit('dragend', value);
    },

    barClicked(event) {
      if (this.dragging) {
        return;
      }

      const value = this.getValueByEvent(event);
      this.currentValue = value;
      this.$emit('dragend', value);
    },

    getValueByEvent(event) {
      const clientLeft =
        document.documentElement.clientLeft || document.body.clientLeft || 0;
      const pageXOffset =
        window.pageXOffset || document.documentElement.scrollLeft;
      const barBoundingRectangle = this.$refs.bar.getBoundingClientRect();

      const offsetX = barBoundingRectangle.left + pageXOffset - clientLeft;
      const relativeX = event.pageX - offsetX;

      if (relativeX <= 0) {
        return 0;
      } else {
        const newValue = (relativeX / this.sliderWidth) * this.max;
        return newValue > this.max ? this.max : newValue;
      }
    },

    onSliderSizeChanged() {
      const bar = this.$refs.bar;
      if (bar) {
        this.sliderWidth = bar.offsetWidth;
      }
    }
  }
};
</script>

<style scoped>
.bar {
  position: relative;
  background: #333;
  cursor: pointer;
}

.progress,
.secondary-progress,
.thumb-container,
.thumb {
  position: absolute;
  transform-origin: 0 0;
}

.secondary-progress {
  background: #808080;
}

.progress,
.secondary-progress {
  width: 100%;
}

.thumb {
  border-radius: 50%;
}

/* Extra padding is what makes the slider require less precision in cursor placement,
   so even if the slider is thin, it has some invisible extra space for interaction
*/
.extra-padding {
  position: absolute;
  bottom: -6px;
  height: 18px;
  width: 100%;
}
</style>
