<template>
  <Dialog
    data-cy="remove-participant-modal"
    class="dialog"
    :heading="$t('remove_participant_modal.title')"
    icon-name="remove-user-line"
    :close="hide"
    :min-width="424"
    :primary-action="$t('remove_participant_modal.ok_button_text')"
    primaryButtonConnotation="alert"
    :open="participantToRemove"
    isSmallModal
    @applied="removeParticipantAndHideModal"
    @click.stop
  >
    <div slot="content" class="content">
      <div class="text">
        {{
          $t('remove_participant_modal.text', {
            participantDisplayName: participantToRemove.participantDisplayName
          })
        }}
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import Dialog from '@/components/Dialog';

export default {
  name: 'RemoveParticipantModal',

  components: { Dialog },

  computed: {
    ...mapState(['participantToRemove'])
  },

  methods: {
    ...mapActions(['setParticipantToRemove', 'kickOutParticipant']),

    hide() {
      this.setParticipantToRemove(null);
    },

    async removeParticipant() {
      await this.kickOutParticipant({
        participantId: this.participantToRemove.participantId,
        participantDisplayName: this.participantToRemove.participantDisplayName
      });
    },

    removeParticipantAndHideModal() {
      this.removeParticipant();
      this.hide();
    }
  }
};
</script>

<style scoped>
vwc-dialog {
  --mdc-dialog-max-width: 424px;
}
</style>
