/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-javascript-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 0h24v24H0V0z" _fill="#F0DB4F"/><path pid="1" d="M22.036 18.327c-.218-1.09-.872-1.963-3.054-2.836-.764-.327-1.527-.545-1.746-1.09-.109-.328-.109-.546 0-.655.11-.655.873-.873 1.528-.655.436.11.763.436.982.873.981-.655.981-.655 1.745-1.091-.218-.436-.436-.546-.546-.764-.654-.654-1.418-1.09-2.836-.982l-.655.11c-.654.218-1.309.545-1.745.981-1.09 1.31-.764 3.491.546 4.473 1.309.982 3.381 1.31 3.6 2.182.218 1.2-.873 1.527-1.964 1.418-.764-.218-1.31-.545-1.745-1.31-.873.546-.873.546-1.855 1.092.218.436.436.654.764 1.09 1.745 1.746 6.109 1.637 6.872-.981.11-.327.328-.982.11-1.855zm-8.945-7.309H10.8V16.8c0 1.2.11 2.4-.11 2.727-.326.655-1.2.546-1.526.437-.437-.218-.546-.437-.873-.873-.11-.11-.11-.218-.11-.218l-1.854 1.09c.328.764.764 1.31 1.31 1.637.872.546 1.963.655 3.163.436.764-.218 1.418-.654 1.854-1.418.546-.981.437-2.072.437-3.381v-6.219z" _fill="#323330"/>'
  }
})
