/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-bounce-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M11.216 1H1.96v8.472h2.824V5.507l7.27 5.816 8.735-7.861-1.889-2.1-6.954 6.259L7.2 3.824h4.016V1zM12.005 13.276h.014c.996 0 2.095.095 3.296.282 1.215.188 2.386.463 3.513.825 1.14.365 2.154.826 3.034 1.39.906.58 1.577 1.294 1.89 2.171l.004.01c.322.942.34 1.866-.035 2.716l-.438.988-.022.038c-.183.314-.45.57-.805.716a1.585 1.585 0 01-.61.115 2.35 2.35 0 01-.567-.076l-.379-.085-1.892-.425c-1.272-.287-2.365-.777-2.836-1.684-.329-.604-.548-1.27-.396-1.907a.95.95 0 00-.214-.18l-.016-.01c-.278-.194-.786-.37-1.623-.45-.877-.084-1.5-.123-1.89-.123h-.013c-.409 0-1.044.043-1.922.135-.83.088-1.356.262-1.657.458a.939.939 0 00-.215.181c.15.63-.059 1.301-.377 1.917l-.009.017c-.483.882-1.567 1.372-2.837 1.669-1.19.277-1.97.451-2.304.511a2.307 2.307 0 01-.553.073c-.197 0-.404-.03-.606-.115a1.656 1.656 0 01-.806-.717l-.022-.038-.435-.987c-.376-.851-.353-1.774-.03-2.714l.003-.01c.315-.878.986-1.592 1.894-2.173.88-.565 1.894-1.029 3.035-1.397a23.18 23.18 0 013.514-.833c1.207-.19 2.313-.287 3.312-.288z"/>'
  }
})
