<template>
  <popper
    v-if="focusedElement"
    v-show="!hideTour"
    :key="tourSteps.length"
    class="tour-container"
    trigger="focus"
    :forceShow="showPopper"
    :reference="focusedElement"
    :options="popperOptions"
    :style="currentTourStep.style"
  >
    <div
      class="popper"
      :class="{
        'popper-with-illustration': currentTourStep.illustration,
        'light-mode': currentTourStep.lightMode
      }"
    >
      <div v-if="currentTourStep.illustration" class="illustration-container">
        <img :src="`/${currentTourStep.illustration}.svg`" />
      </div>
      <div class="popper-content-container">
        <span v-if="!hideStepCounter" class="step-counter">
          {{ numOfTourSteps - tourSteps.length + 1 }}/{{ numOfTourSteps }}
        </span>
        <h6 v-if="currentTourStep.title">{{ currentTourStep.title }}</h6>
        <p>{{ currentTourStep.subtitle }}</p>
        <vwc-button
          class="next-button"
          :label="currentStepButton"
          connotation="cta"
          layout="filled"
          fullwidth
          dense
          @click="nextStep"
        />
      </div>
    </div>
  </popper>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';

const popperOptions = {
  modifiers: {
    preventOverflow: {
      boundariesElement: 'scrollParent',
      enabled: true,
      padding: 3
    }
  }
};

export default {
  name: 'Tour',

  data() {
    return {
      popperOptions,
      focusedElement: null,
      showPopper: true,
      interval: null
    };
  },

  computed: {
    ...mapState(['tourSteps', 'numOfTourSteps', 'isFullScreen']),
    ...mapState('layout', ['isSidebarCollapsed']),
    ...mapGetters(['isChatOpen']),

    currentTourStep() {
      return this.tourSteps[0];
    },

    isLastStep() {
      return this.tourSteps.length === 1;
    },

    currentStepButton() {
      if (this.currentTourStep.buttonText) {
        return this.currentTourStep.buttonText;
      }
      return this.isLastStep ? 'Done' : 'Next';
    },

    hideTour() {
      return (
        (this.currentTourStep?.options?.hideOnCollapsedSidebar &&
          this.isSidebarCollapsed) ||
        (this.currentTourStep?.options?.hideOnCollapsedChat &&
          !this.isChatOpen) ||
        (this.currentTourStep?.options?.hideOnFullscreen && this.isFullScreen)
      );
    },

    hideStepCounter() {
      return this.currentTourStep?.options?.hideStepCounter;
    }
  },

  watch: {
    async currentTourStep(newStep, oldStep) {
      await this.teardownTourStepAction(oldStep);

      // stop if no more steps
      if (!newStep || !newStep.selector) {
        return;
      }

      if (newStep?.trigger?.selector) {
        const triggerSelectorInterval = setInterval(async () => {
          const triggerElement = this.getElementBySelector(
            newStep.trigger.selector,
            newStep.trigger.selectorOccurrence
          );

          if (triggerElement) {
            const isStepSuccessfullyCreated = await this.createTourStep(
              newStep
            );

            isStepSuccessfullyCreated
              ? clearInterval(triggerSelectorInterval)
              : null;
          }
        }, 2000);
      } else {
        this.createTourStep(newStep);
      }
    }
  },

  mounted() {
    this.initTour();
  },

  methods: {
    ...mapActions(['initTour', 'removeTourStep']),

    nextStep(timeToNextStep = 0) {
      if (!this.currentTourStep) {
        // happens in case two nextStep calls were made for the same step
        return;
      }

      clearInterval(this.interval);
      this.interval = null;

      this.removeFocusedElement();

      setTimeout(() => {
        this.removeTourStep();
      }, timeToNextStep);
    },

    nextStepDelayed() {
      this.nextStep(500);
    },

    refreshPopper() {
      setTimeout(() => {
        // show popper only if it is visible (no "display: none")
        if (!this.currentTourStep) {
          return;
        }

        this.updateFocusedElement(this.currentTourStep);
      }, 0);
    },

    updatePopperOptions(newStep) {
      const newPopperOptions = popperOptions;
      if (newStep.placement) {
        newPopperOptions.placement = newStep.placement;
      }
      if (newStep.boundariesElement) {
        newPopperOptions.modifiers.preventOverflow.boundariesElement =
          newStep.boundariesElement;
      }
      this.popperOptions = newPopperOptions;
    },

    async createTourStep(newStep) {
      let isStepSuccessfullyCreated = false;
      await this.setupTourStepAction(newStep);
      const newFocusedElement = this.getElementBySelector(newStep.selector);

      if (newFocusedElement) {
        this.updatePopperOptions(newStep);

        this.interval = setInterval(this.refreshPopper, 500);

        isStepSuccessfullyCreated = true;
      } else {
        await this.teardownTourStepAction(newStep);
      }

      return isStepSuccessfullyCreated;
    },

    getElementBySelector(selector, occurrence = 1) {
      if (occurrence > 1) {
        return document.querySelectorAll(selector)[occurrence - 1];
      }
      return document.querySelector(selector);
    },

    async setupTourStepAction(step) {
      const setupAction = step?.setup?.actionName;
      if (setupAction) {
        await this.$store.dispatch(`${setupAction}`, step.setup.param);
      }
    },

    async teardownTourStepAction(step) {
      const teardownAction = step?.teardown?.actionName;
      if (teardownAction) {
        await this.$store.dispatch(`${teardownAction}`, step.teardown.param);
      }
    },

    updateFocusedElement(newStep) {
      this.focusedElement = newStep
        ? this.getElementBySelector(newStep.selector)
        : null;

      if (this.focusedElement) {
        if (newStep?.options?.nextOnClick) {
          this.focusedElement.addEventListener('click', this.nextStepDelayed);
        }
        this.showPopper = true;
      } else {
        this.showPopper = false;
      }
    },

    removeFocusedElement() {
      this.showPopper = false;
      this.focusedElement.removeEventListener('click', this.nextStepDelayed);
      this.focusedElement = null;
    }
  }
};
</script>

<style scoped>
.tour-container {
  position: absolute;
  top: 0;
  left: 0;
}

.tour-container >>> .popper {
  background: rgba(24, 25, 27, 0.65);
  border: 1px solid #43474f;
  padding: 0;
  box-sizing: border-box;
  backdrop-filter: blur(40px);
  border-radius: 6px;
  min-width: 240px;
  z-index: 100;
  text-align: center;
  box-shadow: none;
}

.tour-container >>> .popper.light-mode {
  background: #ffffff;
  border-color: #9b9da3;
}

.tour-container >>> .popper .popper-content-container {
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.tour-container >>> .popper.light-mode .step-counter,
.tour-container >>> .popper.light-mode h6,
.tour-container >>> .popper.light-mode p {
  color: #131415;
}

.tour-container >>> .popper .illustration-container {
  padding: 11px 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: #d6219c;
  backdrop-filter: blur(40px);
}

.tour-container >>> .popper .step-counter {
  color: #ffffff;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 4px;
}

.tour-container >>> .popper h6 {
  color: #ffffff;
  font-weight: bold;
  font-size: 20px;
  letter-spacing: -1px;
  line-height: 25px;
  margin-bottom: 8px;
}

.tour-container >>> .popper p {
  color: #ffffff;
  margin: 0;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 4px;
}

.tour-container >>> .popper vwc-button.next-button {
  margin-top: 8px;
}

.tour-container >>> .popper[x-placement^='top'] .popper__arrow {
  border-color: #43474f transparent transparent transparent;
}

.tour-container >>> .popper[x-placement^='right'] .popper__arrow {
  border-color: transparent #43474f transparent transparent;
}

.tour-container >>> .popper[x-placement^='bottom'] .popper__arrow {
  border-color: transparent transparent #43474f transparent;
}

.tour-container >>> .popper[x-placement^='left'] .popper__arrow {
  border-color: transparent transparent transparent #43474f;
}

.tour-container >>> .popper.light-mode[x-placement^='top'] .popper__arrow {
  border-color: #9b9da3 transparent transparent transparent;
}

.tour-container >>> .popper.light-mode[x-placement^='right'] .popper__arrow {
  border-color: transparent #9b9da3 transparent transparent;
}

.tour-container >>> .popper.light-mode[x-placement^='bottom'] .popper__arrow {
  border-color: transparent transparent #9b9da3 transparent;
}

.tour-container >>> .popper.light-mode[x-placement^='left'] .popper__arrow {
  border-color: transparent transparent transparent #9b9da3;
}

.tour-container >>> .popper__arrow::after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 3px;
}

.tour-container >>> .popper[x-placement^='top'] .popper__arrow::after {
  left: -3px;
  top: -5px;
  border-color: #18191b transparent transparent transparent;
}

.tour-container >>> .popper[x-placement^='right'] .popper__arrow::after {
  left: -1px;
  top: -3px;
  border-color: transparent #18191b transparent transparent;
}

.tour-container >>> .popper[x-placement^='bottom'] .popper__arrow::after {
  left: -3px;
  top: -1px;
  border-color: transparent transparent #18191b transparent;
}

.tour-container >>> .popper[x-placement^='left'] .popper__arrow::after {
  left: -5px;
  top: -3px;
  border-color: transparent transparent transparent #18191b;
}

.tour-container
  >>> .popper.light-mode[x-placement^='top']
  .popper__arrow::after {
  left: -3px;
  top: -5px;
  border-color: #ffffff transparent transparent transparent;
}

.tour-container
  >>> .popper.light-mode[x-placement^='right']
  .popper__arrow::after {
  left: -1px;
  top: -3px;
  border-color: transparent #ffffff transparent transparent;
}

.tour-container
  >>> .popper.light-mode[x-placement^='bottom']
  .popper__arrow::after {
  left: -3px;
  top: -1px;
  border-color: transparent transparent #ffffff transparent;
}

.tour-container
  >>> .popper.light-mode[x-placement^='left']
  .popper__arrow::after {
  left: -5px;
  top: -3px;
  border-color: transparent transparent transparent #ffffff;
}

.tour-container
  >>> .popper.popper-with-illustration[x-placement^='bottom']
  .popper__arrow::after {
  border-color: transparent transparent #c366c4 transparent;
}
</style>
