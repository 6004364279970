/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-headset-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C5.383 0 0 5.383 0 12v7.5C0 21.981 2.019 24 4.5 24H6a1.5 1.5 0 001.5-1.5V15A1.5 1.5 0 006 13.5H3V12c0-4.963 4.037-9 9-9s9 4.037 9 9v1.5h-3a1.5 1.5 0 00-1.5 1.5v7.5c0 .828.67 1.5 1.5 1.5h1.5c2.481 0 4.5-2.019 4.5-4.5V12c0-6.617-5.384-12-12-12z"/>'
  }
})
