/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-send-negative': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.9 8c0-.4-.2-.7-.6-.9L1.9.7C1.5.6 1.1.6.8.9.5 1.2.4 1.6.5 2l1.6 4.9L9.3 8 2.1 9.2.5 14c-.1.4 0 .8.2 1 .3.3.7.3 1.1.2l13.4-6.4c.5-.1.7-.4.7-.8z"/>'
  }
})
