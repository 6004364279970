/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-enlarge-screen-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2.25 1.5a.75.75 0 00-.75.75v4.5a.75.75 0 01-1.5 0v-4.5A2.25 2.25 0 012.25 0h4.5a.75.75 0 010 1.5h-4.5zM16.5.75a.75.75 0 01.75-.75h4.5A2.25 2.25 0 0124 2.25v4.5a.75.75 0 01-1.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 01-.75-.75zM24 17.25a.75.75 0 00-1.5 0v4.5a.75.75 0 01-.75.75h-4.5a.75.75 0 000 1.5h4.5A2.25 2.25 0 0024 21.75v-4.5zM.75 16.5a.75.75 0 01.75.75v4.5c0 .414.336.75.75.75h4.5a.75.75 0 010 1.5h-4.5A2.25 2.25 0 010 21.75v-4.5a.75.75 0 01.75-.75zM6.75 6a.75.75 0 00-.75.75v10.5c0 .414.336.75.75.75h10.5a.75.75 0 00.75-.75V6.75a.75.75 0 00-.75-.75H6.75zm.75 10.5v-9h9v9h-9z"/>'
  }
})
