var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { ref: "layoutsButtonContainer", staticClass: "layouts-button-container" },
    [
      _c(
        "vwc-button",
        {
          staticClass: "top-bar-button",
          attrs: {
            "data-cy": "layout-picker",
            label: _vm.layouts[_vm.layoutMode].label,
            layout: "ghost",
            icon: _vm.layouts[_vm.layoutMode].selectedIcon,
            dense: "",
          },
          on: { click: _vm.toggleLayoutMenu },
        },
        [
          _c("vwc-icon", {
            attrs: {
              slot: "trailingIcon",
              type: _vm.isLayoutMenuOpen
                ? "chevron-up-solid"
                : "chevron-down-solid",
            },
            slot: "trailingIcon",
          }),
        ],
        1
      ),
      _c(
        "vwc-menu",
        {
          ref: "layoutsMenu",
          staticClass: "menu",
          attrs: {
            menuCorner: "START",
            corner: "BOTTOM_LEFT",
            open: _vm.isLayoutMenuOpen,
          },
          on: {
            closed: function ($event) {
              _vm.isLayoutMenuOpen = false
            },
          },
        },
        _vm._l(_vm.layoutOptions, function (layout) {
          return _c(
            "vwc-list-item",
            {
              key: layout.value,
              staticClass: "list-item",
              attrs: {
                graphic: "icon",
                hasMeta: "",
                "data-cy": "layout-option",
              },
              on: {
                click: function ($event) {
                  return _vm.setLayoutMode({ layoutMode: layout.value })
                },
              },
            },
            [
              _c("span", [_vm._v(" " + _vm._s(layout.label))]),
              _c("vwc-icon", {
                attrs: { slot: "graphic", type: layout.icon, size: "small" },
                slot: "graphic",
              }),
              layout.checked
                ? _c("vwc-icon", {
                    attrs: {
                      slot: "meta",
                      "data-cy": "layout-selected-icon",
                      type: "check-line",
                      size: "small",
                    },
                    slot: "meta",
                  })
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }