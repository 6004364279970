/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mobile-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 24H6a3 3 0 01-3-3V3a3 3 0 013-3h12a3 3 0 013 3v18a3 3 0 01-3 3zm0-19.5H6v15h12v-15zM7.5 15h9v3h-9v-3z"/>'
  }
})
