<template>
  <vwc-textarea
    ref="textarea"
    v-focus="focus"
    tabindex="0"
    :disabled="disabled"
    :outlined="outlined ? outlined : null"
    :value.prop="value"
    :label="label"
    :required="required"
    :maxLength="maxLength"
    :resizable="resizable"
    :autoValidate="autoValidate"
    :validationMessage="validationMessage"
    :rows="rows"
    @input="$emit('input', $event.target.value)"
  ></vwc-textarea>
</template>

<script>
export default {
  name: 'VVDTextarea',
  props: {
    value: {
      type: String,
      default: ''
    },
    outlined: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    maxLength: {
      type: Number,
      default: 1000
    },
    rows: {
      type: Number,
      default: 4
    },
    required: {
      type: Boolean,
      default: false
    },
    resizable: {
      type: Boolean,
      default: false
    },
    autoValidate: {
      type: Boolean,
      default: false
    },
    validationMessage: {
      type: String,
      default: ''
    }
  }
};
</script>

<style scoped></style>
