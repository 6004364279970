/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-receptionist-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M12 0C6.478 0 2 4.396 2 9.818v7.636c0 1.811 1.489 3.273 3.333 3.273h3.334V12H4.222V9.818C4.222 5.596 7.7 2.182 12 2.182c4.3 0 7.778 3.414 7.778 7.636V12h-4.445v8.727h4.445v1.091H12V24h6.667C20.51 24 22 22.538 22 20.727V9.818C22 4.396 17.522 0 12 0z"/>'
  }
})
