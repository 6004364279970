/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-upload-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M10.5 16.5V6H6.9L12 0l5.1 6h-3.6v10.5h-3zm-9 7.5h21c.9 0 1.5-.6 1.5-1.5V9c0-.9-.6-1.5-1.5-1.5H18v3h3V21H3V10.5h3v-3H1.5C.6 7.5 0 8.1 0 9v13.5c0 .9.6 1.5 1.5 1.5z"/>'
  }
})
