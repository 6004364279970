/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'table-sorting--ascending': {
    width: 16,
    height: 16,
    viewBox: '0 0 6 4',
    data: '<path pid="0" d="M3 0l3 4H0l3-4z"/>'
  }
})
