/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-sand-clock-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 3c.9 0 1.5-.6 1.5-1.5S23.4 0 22.5 0h-21C.6 0 0 .6 0 1.5S.6 3 1.5 3H3c.15 3.6 1.2 6.75 3 9-1.8 2.1-3 5.4-3 9H1.5c-.9 0-1.5.6-1.5 1.5S.6 24 1.5 24h21c.9 0 1.5-.6 1.5-1.5s-.6-1.5-1.5-1.5H21c-.15-3.75-1.2-6.9-3-9 1.95-2.25 3-5.4 3-9h1.5zm-7.65 7.95l-1.35 1.2L15 13.2c1.8 1.35 3 4.35 3.15 7.8H6c.15-3.45 1.35-6.45 3.15-7.8l1.5-1.05-1.5-1.2C7.2 9.15 6.15 6.3 6 3h12c-.15 3.3-1.2 6.15-3.15 7.95z"/>'
  }
})
