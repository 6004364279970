/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-menu-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 1.5h-21C.6 1.5 0 2.1 0 3s.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5s-.6-1.5-1.5-1.5zm0 9h-21c-.9 0-1.5.6-1.5 1.5s.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5s-.6-1.5-1.5-1.5zm-21 9h21c.9 0 1.5.6 1.5 1.5s-.6 1.5-1.5 1.5h-21C.6 22.5 0 21.9 0 21s.6-1.5 1.5-1.5z"/>'
  }
})
