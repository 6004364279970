var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "bar",
      staticClass: "bar",
      style: { height: _vm.barHeight + "px" },
      on: {
        mousemove: function (event) {
          return _vm.$emit("mouseMoveOnBar", event)
        },
        click: _vm.barClicked,
      },
    },
    [
      _c("resize-observer", {
        on: { notify: _vm.onSliderSizeChangedThrottled },
      }),
      _c("div", { staticClass: "extra-padding" }),
      _c("div", {
        staticClass: "secondary-progress",
        style: _vm.secondaryProgressBarStyle,
      }),
      _c("div", { staticClass: "progress", style: _vm.progressBarStyle }),
      _c(
        "div",
        {
          staticClass: "thumb-container",
          style: _vm.thumbContainerStyle,
          on: { mousedown: _vm.dragStart },
        },
        [_c("div", { staticClass: "thumb", style: _vm.thumbStyle })]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }