/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-user-admin': {
    width: 16,
    height: 16,
    viewBox: '0 0 25 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.5 6c0 3.3-2.7 6-6 6s-6-2.7-6-6 2.7-6 6-6 6 2.7 6 6zM15 6c0-2.55-1.95-4.5-4.5-4.5S6 3.45 6 6s1.95 4.5 4.5 4.5S15 8.55 15 6zm-1.8 7.65c.45.15.75.45.6.9 0 .45-.45.75-.9.6-.45-.15-1.05-.15-1.65-.15h-1.5c-2.25 0-4.35.9-5.85 2.4-1.35 1.35-2.25 3.15-2.4 5.1H12c.45 0 .75.3.75.75s-.3.75-.75.75H.75c-.075 0-.188-.038-.3-.075a1.16 1.16 0 00-.3-.075c0-.075-.037-.188-.075-.3-.037-.113-.075-.225-.075-.3 0-2.55 1.05-5.1 2.85-6.9 1.8-1.8 4.35-2.85 6.9-2.85h1.5c.75 0 1.35 0 1.95.15zm10.5 4.8l-1.65-2.55c-.3-.6-1.05-.9-1.65-.9h-3.3c-.75 0-1.35.3-1.65.9l-1.65 2.55c-.45.6-.45 1.35 0 2.1l1.65 2.55c.3.6 1.05.9 1.65.9h3.3c.75 0 1.35-.3 1.65-.9l1.65-2.55c.45-.6.45-1.5 0-2.1zm-1.2 1.2l-1.65 2.55c-.15.15-.3.15-.45.15h-3.3c-.075 0-.15-.038-.225-.075-.075-.037-.15-.075-.225-.075L15 19.65c-.15-.15-.15-.3 0-.45l1.65-2.55c.15-.15.3-.15.45-.15h3.3c.075 0 .15.038.225.075.075.038.15.075.225.075l1.65 2.55v.45zm-2.55-.15a1.2 1.2 0 11-2.4 0 1.2 1.2 0 012.4 0z"/>'
  }
})
