/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-design-tools-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M3.3 8.4l5.1-5.1L5.55.45c-.6-.6-1.5-.6-2.1 0l-3 3c-.6.6-.6 1.5 0 2.1L3.3 8.4zm17.4 7.2l-5.1 5.1 2.85 2.85c.3.3.6.45 1.05.45H24v-4.5c0-.45-.15-.75-.45-1.05L20.7 15.6zM17.55.45l6 6c.6.6.6 1.5 0 2.1l-15 15c-.6.6-1.5.6-2.1 0l-6-6c-.6-.6-.6-1.5 0-2.1L2.4 13.5l2.55 2.55 2.1-2.1L4.5 11.4 6.9 9l2.55 2.55 2.1-2.1L9 6.9l2.4-2.4 2.55 2.55 2.1-2.1L13.5 2.4 15.45.45c.6-.6 1.5-.6 2.1 0z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
