/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-grid': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M2 11.4h9.3V2.6H2.5c-.3 0-.5.2-.5.4v8.4zm0 1.2V21c0 .2.2.3.4.3h8.9v-8.6L2 12.6zm19.8 1.6V9.6h-9.1v4.6h9.1zm0 1.4h-9.1v5.7h8.8c.2 0 .3-.2.3-.3v-5.4zm0-7.3V3c0-.2-.2-.4-.3-.4h-8.8v5.8l9.1-.1zm-.3 14.3h-19c-.9 0-1.6-.7-1.6-1.7V3c0-.9.7-1.6 1.6-1.6h19c.9 0 1.7.7 1.7 1.6v18c-.1.9-.8 1.6-1.7 1.6z"/>'
  }
})
