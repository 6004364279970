/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-tag': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M.5 0a.5.5 0 00-.5.5V7a.5.5 0 00.146.354l8.5 8.5a.5.5 0 00.708 0l6.5-6.5a.5.5 0 000-.708l-8.5-8.5A.5.5 0 007 0H.5zM1 6.793V1h5.793l8 8L9 14.793l-8-8zM5 4a1 1 0 11-2 0 1 1 0 012 0z"/>'
  }
})
