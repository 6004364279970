/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-pin-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.8 9.2l-6.5-6.5L14.9.3c-.3-.3-.8-.3-1.1 0-.3.3-.3.8 0 1.1L15.5 3 3.3 9.8l-2-2.1C1 7.4.5 7.4.2 7.7c-.3.3-.3.8 0 1.1l12.5 12.5 2.5 2.5c.3.3.8.3 1.1 0 .3-.3.3-.8 0-1.1l-2.1-2.1L21 8.5l1.8 1.8c.3.3.8.3 1.1 0 .2-.3.2-.8-.1-1.1zM13.1 19.5l-8.7-8.7 12.1-6.7 3.3 3.3-6.7 12.1zM5.5 17.5L.3 22.7c-.3.3-.3.8 0 1.1.3.3.8.3 1.1 0l5.2-5.2c.3-.3.3-.8 0-1.1-.4-.3-.8-.3-1.1 0z"/>'
  }
})
