export default {
  init(tag) {
    window._LTracker = window._LTracker || [];
    window._LTracker.push({
      logglyKey: 'fbb36e16-d9e4-4643-8e34-829498d40994',
      sendConsoleErrors: false,
      tag
    });
  },

  push(log) {
    if (typeof log !== 'object') {
      throw new Error('Log must be an object');
    }
    window._LTracker.push(log);
  }
};
