/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-send': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M15.4 8.4c.2-.1.3-.3.3-.4 0-.2-.1-.4-.3-.4L1.7.8c-.2-.1-.4 0-.6.1-.1.1-.2.4-.1.6l1.7 4.6c.1.3.4.4.6.3.3-.1.4-.4.3-.7L2.3 2.3 14.1 8 2.3 13.7l1.9-5.2h3.9c.3 0 .5-.2.5-.5s-.2-.5-.5-.5H3.9c-.2 0-.4.1-.5.3L1 14.5c-.1.2 0 .4.1.5.2.1.4.2.6.1l13.7-6.7z"/>'
  }
})
