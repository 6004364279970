var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "issue-message",
      attrs: { "data-cy": "reconnecting-banner" },
    },
    [
      _c(
        "div",
        { staticClass: "container" },
        [
          _c("vwc-circular-progress", {
            staticClass: "loading-spinner vvd-scheme-alternate",
            attrs: { indeterminate: "", density: "-8" },
          }),
          _c("div", { staticClass: "text" }, [
            _vm._v(_vm._s(_vm.$t("app_screen.network_issue_message"))),
          ]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }