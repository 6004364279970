/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-circle-down': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.5 12C1.5 6.201 6.201 1.5 12 1.5S22.5 6.201 22.5 12 17.799 22.5 12 22.5 1.5 17.799 1.5 12zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zM7.28 9.22a.75.75 0 00-1.06 1.06l5.25 5.25a.75.75 0 001.06 0l5.25-5.25a.75.75 0 10-1.06-1.06L12 13.94 7.28 9.22z"/>'
  }
})
