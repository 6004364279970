/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-call-inbound': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M5.822 2.167c-.166-.167-.5-.5-1-.5H4.49l-.116.036C3.64 1.924 1.039 2.71 1.823 6c.666 3.667 3.166 7.167 6.166 10.333 3.333 3.5 7.833 6 10.5 6h.5c2.5-.5 3.5-3.5 2.667-4.166-2.334-1.834-3.5-2.5-4.667-2.5h-.5c-1.333.166-1.833 1.5-1.833 1.5s-2.334-.5-4.834-3.167C6.99 11.167 6.656 9.167 6.656 9.167c1-.667 1.5-1.334 1.5-2.5 0-1.167-1.834-3.834-2.334-4.5zM4.156 0h.666c1 0 1.834.5 2.334 1.167C8.322 2.833 9.822 5 9.822 6.5 9.99 8 9.322 9 8.656 9.667c.333.833 1 1.833 2.333 3.166 1.02 1.167 2.042 1.823 2.616 2.192.082.053.155.1.217.142.5-.5 1.167-1 2.167-1.167h.833c1.667 0 3.167.833 5.667 2.833.833.667 1.167 1.834.833 3.167-.5 1.833-2 3.667-4.333 4h-.667c-3.166 0-8.166-2.833-11.666-6.5-3.667-4-5.834-7.667-6.5-11.167-.667-3.166.833-5.5 4-6.333zm18.666 2.334l-6.833 6.833h4.5v1.666h-7.5v-7.5h1.666v4.5L21.49 1l1.333 1.334z"/>'
  }
})
