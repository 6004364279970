/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-tag-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.55 12.45l-12-12c-.3-.3-.6-.45-1.05-.45h-9C.6 0 0 .6 0 1.5v9c0 .45.15.75.45 1.05l12 12c.3.3.6.45 1.05.45.45 0 .75-.15 1.05-.45l9-9c.6-.6.6-1.5 0-2.1zM6 7.5c-.9 0-1.5-.6-1.5-1.5S5.1 4.5 6 4.5s1.5.6 1.5 1.5S6.9 7.5 6 7.5z"/>'
  }
})
