/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-right-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 7.5h10.5v-6L24 12 10.5 22.5v-6H0v-9z"/>'
  }
})
