/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-video-off-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M23.55.45c.6.6.6 1.5 0 2.1l-6 6L24 6v12l-7.5-3v4.5c0 .9-.6 1.5-1.5 1.5H5.1l-2.55 2.55c-.3.3-.6.45-1.05.45-.45 0-.75-.15-1.05-.45-.6-.6-.6-1.5 0-2.1l21-21c.6-.6 1.5-.6 2.1 0zM0 4.5C0 3.6.6 3 1.5 3H15L0 18V4.5z"/>'
  }
})
