import { initPolyfills } from './helpers/polyfills.js';
import VTooltip from 'v-tooltip';
import VSpinner from './components/volta/VSpinner.vue';
import VIcon from './components/volta/VIcon.vue';
import VButton from './components/volta/VButton.vue';
import VSelect from './components/volta/VSelect';
import VModal from './components/volta/VModal.vue';
import VInput from './components/volta/VInput.vue';
import VSwitch from './components/volta/VSwitch.vue';
import VSearch from './components/volta/VSearch.vue';
import VCheckbox from './components/volta/VCheckbox.vue';
import VTextfield from './components/VTextfield.vue';
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css';
import VueVirtualScroller from 'vue-virtual-scroller';
import 'vue-resize/dist/vue-resize.css';
import VueResize from 'vue-resize';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
import CustomDirectives from './directives';
import VueObserveVisibility from 'vue-observe-visibility';
import { createTestingInstance } from './services/testing-service';
import { initBrowserDetection } from './helpers/browsers-detection';
import logger from '@/services/logging/logger';
import VueYoutube from 'vue-youtube';
import VueScreen from 'vue-screen';
import '@vonage/vwc-circular-progress/vwc-circular-progress';
import '@vonage/vwc-button/vwc-button';
import '@vonage/vwc-switch/vwc-switch';
import '@vonage/vwc-icon-button/vwc-icon-button';
import '@vonage/vwc-icon-button-toggle/vwc-icon-button-toggle';
import '@vonage/vwc-badge/vwc-badge';
import '@vonage/vwc-icon/vwc-icon';
import '@vonage/vwc-banner/vwc-banner';
import '@vonage/vwc-menu';
import '@vonage/vwc-popup';
import '@vonage/vwc-list';
import '@vonage/vwc-list/vwc-list-item';
import '@vonage/vwc-list/vwc-radio-list-item';
import '@vonage/vwc-checkbox/vwc-checkbox';
import '@vonage/vwc-formfield/vwc-formfield';
import '@vonage/vwc-dialog';
import '@vonage/vwc-radio';
import '@vonage/vwc-text';
import '@vonage/vwc-action-group';
import '@vonage/vwc-expansion-panel';
import '@vonage/vwc-empty-state';
import '@vonage/vwc-textfield';
import '@vonage/vwc-tags/vwc-tag';
import '@vonage/vwc-dropdown';
import '@vonage/vwc-linear-progress/vwc-linear-progress';
import '@vonage/vwc-textarea';
import '@vonage/vwc-select';
import '@vonage/vwc-card';
import '@vonage/vwc-fab';
import {
  registerNote,
  registerButton,
  registerBadge,
  registerDialog,
  registerEmptyState
} from '@vonage/vivid';

registerNote('vwc-3');
registerButton('vwc-3');
registerBadge('vwc-3');
registerDialog('vwc-3');
registerEmptyState('vwc-3');
import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import meetingManagerService from '@/services/meeting-manager-service';
import PortalVue from 'portal-vue';
import MeetingsCommonVueComponents from '@vonage/meetings-common-vue-components';
import i18n from './i18n';
import { setTitleBarData } from '@/helpers/global-helpers';
import DomainConfigTypeEnum from '@/consts/domains-configuration-enum';

initPolyfills();
initBrowserDetection();
logger.init();

Vue.config.productionTip = false;

Vue.component('VSpinner', VSpinner);
Vue.component('VIcon', VIcon);
Vue.component('VButton', VButton);
Vue.component('VSelect', VSelect);
Vue.component('VModal', VModal);
Vue.component('VInput', VInput);
Vue.component('VSwitch', VSwitch);
Vue.component('VSearch', VSearch);
Vue.component('VCheckbox', VCheckbox);
Vue.component('Popper', Popper);
Vue.component('VTextfield', VTextfield);
Vue.use(VTooltip);
Vue.use(VueVirtualScroller);
Vue.use(VueResize);
Vue.use(VueObserveVisibility);
Vue.use(VueYoutube);
Vue.use(VueScreen, {
  // Not supported screen.width < 355 - No mic/camera/endMeeting buttons
  xs: (screen) => screen.width <= 500,
  sm: (screen) => screen.width > 500 && screen.width <= 600,
  md: (screen) => screen.width > 600 && screen.width <= 850,
  lg: (screen) => screen.width > 850 && screen.width <= 950,
  xl: (screen) => screen.width > 950 && screen.width <= 1100,
  xxl: (screen) => screen.width > 1100
});
Vue.use(PortalVue);
Vue.use(MeetingsCommonVueComponents);
Vue.directive('observe-keyboard', CustomDirectives.ObserveKeyboard);
Vue.directive('focus', CustomDirectives.Focus);

// Lazy load the emoji picker
import(/* webpackChunkName: "emoji-picker-element" */ 'emoji-picker-element');

window.SHOW_SUBSCRIBER_STATS = (showStats) => {
  store.dispatch('showSubscriberStats', showStats);
};
window.TEST_MODE = new URLSearchParams(location.search).has('test_mode');

window.isProduction =
  location.hostname === process.env.VUE_APP_PRODUCTION_HOSTNAME;
window.isAlpha = location.hostname === process.env.VUE_APP_ALPHA_HOSTNAME;

if (window.TEST_MODE) {
  window.testing = createTestingInstance(store);
}

window.appVersion = process.env.VUE_APP_VERSION;
window.gitRevision = process.env.VUE_APP_GIT_REVISION;
if (process.env.VUE_APP_DOMAIN_CONFIG === DomainConfigTypeEnum.VONAGE) {
  setTitleBarData('Vonage Meetings', '/favicon.ico');
}
if (process.env.VUE_APP_DOMAIN_CONFIG === DomainConfigTypeEnum.CSP) {
  setTitleBarData('CommsSetup', '/favicon_csp.ico');
}

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App)
}).$mount('#app');

if (window.Cypress) {
  window.appStore = store;
  window.appRouter = router;
  window.meetingManagerService = meetingManagerService;
}
