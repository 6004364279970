/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-check-circle': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M12.354 5.354a.5.5 0 00-.708-.708L7 9.293 4.854 7.146a.5.5 0 10-.708.708l2.5 2.5a.5.5 0 00.708 0l5-5z"/><path pid="1" d="M8 0a8 8 0 100 16A8 8 0 008 0zM1 8a7 7 0 1114 0A7 7 0 011 8z"/>'
  }
})
