/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-home-user': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M8.5 11h-1c-.9 0-1.8.4-2.5 1-.7.7-1 1.5-1 2.5 0 .3.2.5.5.5h7c.3 0 .5-.2.5-.5 0-.9-.4-1.8-1-2.5-.7-.6-1.6-1-2.5-1zm-3.4 3c.1-.5.3-.9.7-1.3.4-.4 1-.7 1.7-.7h1c.7 0 1.3.3 1.8.7.3.3.6.8.7 1.3H5.1zM10 8c0-1.1-.9-2-2-2s-2 .9-2 2 .9 2 2 2 2-.9 2-2zM7 8c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1-1-.4-1-1z"/><path pid="1" d="M15.8 6.1l-7.5-5c-.2-.1-.4-.1-.6 0l-7.5 5c-.2.1-.3.4-.1.7.1.2.4.3.7.1L8 2.1l7.2 4.8c.2.2.5.1.7-.1s.1-.6-.1-.7z"/>'
  }
})
