/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-unhappy': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1 8a7 7 0 1114 0A7 7 0 011 8zm7-8a8 8 0 100 16A8 8 0 008 0zM6 6a1 1 0 11-2 0 1 1 0 012 0zm5 1a1 1 0 100-2 1 1 0 000 2zm-4.477 3.392a3 3 0 014.048 1.066.5.5 0 00.858-.515 4 4 0 00-6.858 0 .5.5 0 10.858.515 3 3 0 011.094-1.066z"/>'
  }
})
