import { LAYOUT_MODE_TYPES } from '@/consts/global-consts';

export default function getInitialState() {
  return {
    layoutMode: LAYOUT_MODE_TYPES.GRID,
    lastSavedLayout: '',
    isSidebarCollapsed: true,
    layoutChangedInLargeMeeting: false,
    userPreferredLayout: undefined
  };
}
