/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-telephone-2': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21.2 3.5H10.6v1.4h10.6c.4 0 .7.3.7.7v15.5c0 .4-.3.7-.7.7H2.9c-.4 0-.7-.3-.7-.7V5.6c0-.4.3-.7.7-.7h.7V3.5h-.7C1.8 3.5.8 4.5.8 5.6v15.5c0 1.1 1 2.1 2.1 2.1h18.4c1.1 0 2.1-1 2.1-2.1V5.6c-.1-1.1-1.1-2.1-2.2-2.1z"/><path pid="1" d="M13.7 12.7h2.5v1.4h-2.5zM5.7 20.5h1.4c1.1 0 2.1-1 2.1-2.1V2.8c0-1.1-1-2.1-2.1-2.1H5.7c-1.1 0-2.1 1-2.1 2.1v15.5c0 1.2 1 2.2 2.1 2.2zM5 2.8c0-.4.3-.7.7-.7h1.4c.4 0 .7.3.7.7v15.5c0 .4-.3.7-.7.7H5.7c-.4 0-.7-.3-.7-.7V2.8zM13.7 9.9h4v1.4h-4zM14.4 18.6h2.5V20h-2.5zM18 18.6h2.5V20H18zM10.9 18.6h2.5V20h-2.5z"/><path pid="2" d="M10.9 16.9h9.6V7.1h-9.6v9.8zm1.4-8.4h6.8v7.1h-6.8V8.5z"/>'
  }
})
