<template>
  <vwc-3-badge
    class="vvd-root"
    data-cy="recording-indication"
    connotation="alert"
    icon="rec-solid"
    shape="pill"
    appearance="subtle"
    v-tooltip.bottom="indicationTooltip"
    :text="formattedDurationBadgeText"
  >
  </vwc-3-badge>
</template>

<script>
import { formatDuration } from '@/helpers/global-helpers';

export default {
  name: 'RecordingIndication',

  props: {
    startTime: {
      type: Number,
      default: Date.now()
    },

    showTimer: {
      type: Boolean,
      deafult: true
    }
  },

  data() {
    return {
      duration: 0,
      intervalId: ''
    };
  },

  computed: {
    formattedDurationBadgeText() {
      return this.showTimer ? formatDuration(this.duration) : undefined;
    },

    indicationTooltip() {
      return {
        content: this.$t('main_actions_bar.recording_badge_tooltip')
      };
    }
  },

  mounted() {
    this.duration = (Date.now() - this.startTime) / 1000;
    this.intervalId = setInterval(() => this.duration++, 1000);
  },

  beforeDestroy() {
    clearInterval(this.intervalId);
  }
};
</script>

<style scoped></style>
