/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-java': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M15 16.5c-2.8.5-4.4.5-6.4.3-1.5-.1-.5-.9-.5-.9-4.1 1.4 2.3 2.9 7.9 1.2-.6-.2-1-.6-1-.6zM8.7 18.6c-3.7 1 2.2 3.1 7 1.1-.7-.3-1.3-.6-1.3-.6-2.1.4-3.1.4-5 .2-1.6-.2-.7-.7-.7-.7zM17.2 4.9S9 7 12.9 11.5c1.2 1.3-.3 2.5-.3 2.5s2.9-1.5 1.6-3.4c-1.3-1.8-2.2-2.7 3-5.7zM16.9 17.6v.1c6-1.6 3.8-5.6.9-4.6-.3.1-.4.3-.4.3s.1 0 .5-.1c1.4-.3 3.5 2-1 4.3z"/><path pid="1" d="M19.2 20.5s.7.6-.7 1c-2.7.8-11.3 1.1-13.7 0-.9-.4.7-.9 1.3-1 .5-.1.8-.1.8-.1-1-.7-6.2 1.3-2.6 1.9 9.5 1.6 17.3-.7 14.9-1.8zM15.9 14.6c.5-.3 1.1-.6 1.1-.6s-1.8.3-3.6.5c-2.2.2-4.6.3-5.8.1-2.9-.4 1.5-1.4 1.5-1.4s-1.7-.1-3.7.8c-2.5 1.2 6.1 1.8 10.5.6zM11.8 13.1c-.9-2-3.9-3.7 0-6.8C16.7 2.5 14.2 0 14.2 0c1 4-3.5 5.2-5.2 7.6-1.1 1.7.6 3.5 2.8 5.5z"/><path pid="2" d="M17.1 23.1c-3.7.7-8.2.6-10.9.2 0 0 .5.4 3.4.6 4.3.3 11-.2 11.1-2.2 0 0-.3.8-3.6 1.4z"/>'
  }
})
