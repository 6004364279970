/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'callout-critical': {
    width: 16,
    height: 16,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M10 0C4.5 0 0 4.5 0 10s4.5 10 10 10 10-4.5 10-10S15.5 0 10 0zM1.25 10A8.696 8.696 0 0110 1.25c2.25 0 4.25.75 5.75 2.125L3.375 15.75C2 14.25 1.25 12.25 1.25 10zM10 18.75c-2.25 0-4.25-.75-5.75-2.125L16.625 4.25C18 5.75 18.75 7.75 18.75 10A8.696 8.696 0 0110 18.75z"/>'
  }
})
