/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-blocks': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M.5 0a.5.5 0 00-.5.5v11a.5.5 0 00.5.5h11a.5.5 0 00.5-.5V.5a.5.5 0 00-.5-.5H.5zM1 11V1h10v10H1zm12.5-7a.5.5 0 000 1H15v10H5v-1.5a.5.5 0 00-1 0v2a.5.5 0 00.5.5h11a.5.5 0 00.5-.5v-11a.5.5 0 00-.5-.5h-2z"/>'
  }
})
