/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-arrow-thin-down-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M13.5 0v13.5H21L12 24 3 13.5h7.5V0h3z"/>'
  }
})
