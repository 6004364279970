import { SIDEBARS } from '@/consts/global-consts';

export default {
  isWaitingRoomOwnerModalShown: (state, getters, rootState, rootGetters) => {
    return (
      (rootState.layout.isSidebarCollapsed ||
        rootState.activeSidebar !== SIDEBARS.PARTICIPANTS) &&
      rootGetters.isSessionOwner &&
      !getters.isWaitingRoomEmpty
    );
  },

  isWaitingRoomEmpty: (state) => state.waitingParticipants.length === 0,

  didKeepaliveExpire: (state) => {
    return (
      Date.now() - state.lastKeepaliveResponseTimestamp >
      state.lastKeepaliveExpiresIn
    );
  }
};
