import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import { isElectron } from '@/helpers/global-helpers';
import { getRunningEnvSocket } from '../../apis/desktop-socket-api';

const SOCKET_MESSAGE_TIMEOUT = 5000;

class DesktopSocketService {
  constructor() {
    this.connectedSocket = null;
    this.env = null;
  }

  async init() {
    if (isElectron()) {
      logger.error('DesktopSocketApi/init', LOG_CATEGORIES.NATIVE_SOCKET, {
        err:
          'You are not allowed to initialize this class while running inside electron renderer process'
      });

      return null;
    }

    if (!this.connectedSocket) {
      const runningEnv = await getRunningEnvSocket();

      if (runningEnv) {
        logger.log(
          `[DESKTOP-WEB-CLIENT] - Web socket initialized for ${runningEnv.env.host}`,
          LOG_CATEGORIES.NATIVE_SOCKET
        );

        this.connectedSocket = runningEnv.socket;
        this.env = runningEnv.env;
      }
    }

    return this.connectedSocket;
  }

  isConnected() {
    return !!this.connectedSocket?.connected;
  }

  closeConnection() {
    if (this.connectedSocket?.connected) {
      this.connectedSocket.close();
    }
    this.connectedSocket = null;
    this.env = null;
  }

  async syncMeetingsSettings() {
    logger.log(
      '[WEB-SYNC] - send web:meetingsSettings',
      LOG_CATEGORIES.NATIVE_SOCKET
    );

    if (this.isConnected()) {
      return new Promise((resolve) => {
        this.connectedSocket.emit('web:meetingsSettings', resolve);
      });
    }
    return {};
  }

  async syncUpdateMeetingsSettings(newSettings) {
    logger.log(
      '[WEB-SYNC] - send web:updateMeetingsSettings',
      LOG_CATEGORIES.NATIVE_SOCKET
    );

    if (this.isConnected()) {
      return new Promise((resolve) => {
        this.connectedSocket.emit(
          'web:updateMeetingsSettings',
          newSettings,
          resolve
        );
      });
    }
    return true;
  }

  syncIsDesktopAppLoggedIn() {
    return new Promise((resolve) => {
      if (this.isConnected()) {
        let connectionTimeout;
        const webSocket = this.connectedSocket;
        webSocket.once('desktop:isDesktopAppLoggedIn', (flag) => {
          logger.log(
            '[WEB-SYNC] - received desktop:isDesktopAppLoggedIn',
            LOG_CATEGORIES.NATIVE_SOCKET
          );
          clearTimeout(connectionTimeout);
          connectionTimeout = undefined;
          resolve(flag);
        });

        logger.log('[WEB-SYNC] - send web:isDesktopAppLoggedIn');
        webSocket.emit('web:isDesktopAppLoggedIn', () => {});

        connectionTimeout = setTimeout(() => {
          logger.error('DesktopSocketApi/init', LOG_CATEGORIES.NATIVE_SOCKET, {
            err: 'timeout in waiting for desktop:isDesktopAppLoggedIn'
          });
          resolve(false);
        }, SOCKET_MESSAGE_TIMEOUT);
      } else {
        resolve(false);
      }
    });
  }

  syncOpenMeetingInDesktop(roomToken) {
    logger.log(
      `[WEB-SYNC] - send web:joinMeeting with roomToken: ${roomToken}`,
      LOG_CATEGORIES.NATIVE_SOCKET
    );

    if (this.isConnected()) {
      return new Promise((resolve) => {
        this.connectedSocket.emit('web:joinMeeting', roomToken, resolve);
      });
    }
  }
}

export default new DesktopSocketService();
