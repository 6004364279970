/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-xml': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M24 7H0v10h24V7zM4.777 8.96H2.828l1.797 2.742-1.976 2.984h1.976l1.133-1.84 1.129 1.84h1.988l-1.95-2.953 1.782-2.774h-1.93l-.984 1.762L4.777 8.96zm6.973 0H9.422v5.726h1.449v-4.367l1.117 4.367h1.309l1.12-4.367v4.367h1.446V8.959h-2.32l-.895 3.485-.898-3.485zm7.008 0h-1.77v5.726h4.531v-1.41h-2.761V8.959z"/>'
  }
})
