/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-enlarge-screen-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M0 9V1.5C0 .6.6 0 1.5 0H9v3H3v6H0zm24-7.5V9h-3V3h-6V0h7.5c.9 0 1.5.6 1.5 1.5zM15 24h7.5c.9 0 1.5-.6 1.5-1.5V15h-3v6h-6v3zm-6 0H1.5C.6 24 0 23.4 0 22.5V15h3v6h6v3zm-3-6h12V6H6v12zm9-3H9V9h6v6z"/>'
  }
})
