/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-video-negative-active': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6 2H1.5A1.5 1.5 0 000 3.5v9A1.5 1.5 0 001.5 14h8a1.5 1.5 0 001.5-1.5v-2.261l4.314 1.725A.5.5 0 0016 11.5v-7a.5.5 0 00-.686-.464L11 5.76V5.75l-1 .4v.339a.49.49 0 000 .023v2.976a.49.49 0 000 .023V12.5a.5.5 0 01-.5.5h-8a.5.5 0 01-.5-.5v-9a.5.5 0 01.5-.5H6V2zm9 8.761l-4-1.6V6.84l4-1.6v5.522z"/><path pid="1" d="M11.5 2.5a2 2 0 11-4 0 2 2 0 014 0z"/>'
  }
})
