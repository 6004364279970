/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mobile-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18 0H6C4.346 0 3 1.345 3 3v18c0 1.654 1.346 3 3 3h12c1.654 0 3-1.346 3-3V3c0-1.655-1.346-3-3-3zm-6 21a1.5 1.5 0 11.001-3.001A1.5 1.5 0 0112 21zm6-6H6V3h12v12z"/>'
  }
})
