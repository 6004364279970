<template>
  <div
    v-if="isLoadingRoomDetails"
    class="mobile-guest-screen-container Vlt-bg-white"
  >
    <div class="header">
      <div v-if="isBranded" class="brand-logo-container">
        <img
          v-if="brandImageColored"
          class="brand-logo"
          :src="brandImageColored"
        />
        <div v-else class="brand-text">{{ brandText }}</div>
      </div>
      <v-icon v-else iconName="vonage-logo" class="logo" />
    </div>
    <!-- Room display name -->
    <h2 class="main-title-container">
      <PlaceholderLoadingSkeleton loading class="main-title-skeleton" />
    </h2>

    <!-- "Join via App" header -->
    <h3 v-if="isVBCRoom" class="join-via-app-header">
      <PlaceholderLoadingSkeleton
        loading
        class="join-via-app-header-skeleton"
      />
    </h3>

    <!-- Two lines of description text -->
    <div v-if="isVBCRoom" class="join-via-app-text Vlt-black">
      <div>
        <PlaceholderLoadingSkeleton
          loading
          class="join-via-app-text-skeleton"
        />
        <PlaceholderLoadingSkeleton
          loading
          class="join-via-app-text-skeleton"
        />
      </div>
    </div>

    <!-- "Download App" button -->
    <PlaceholderLoadingSkeleton
      v-if="isVBCRoom"
      loading
      class="action-button-skeleton"
    />

    <div v-if="isVBCRoom" class="separator">
      <hr class="hr" />
      <span class="separator-span">{{
        $t('mobile_join_meeting_from_link_screen.or_separator')
      }}</span>
    </div>

    <!-- Dial-in or Continue in Browser -->
    <div class="other-option-skeleton-container">
      <PlaceholderLoadingSkeleton loading class="other-option-title-skeleton" />
      <PlaceholderLoadingSkeleton
        loading
        class="other-option-description-skeleton"
      />
    </div>
  </div>

  <div v-else class="mobile-guest-screen-container Vlt-bg-white">
    <div class="header">
      <!-- Branded logo -->
      <div v-if="isBranded" class="brand-logo-container">
        <img
          v-if="brandImageColored"
          class="brand-logo"
          :src="brandImageColored"
        />
        <div v-else class="brand-text">{{ brandText }}</div>
      </div>
      <!-- Vonage logo -->
      <v-icon v-else iconName="vonage-logo" class="logo" />
    </div>

    <!-- Room display name -->
    <h2 class="main-title-container Vlt-black">
      {{ roomDisplayName }}
    </h2>

    <div v-if="shouldShowBrowserNotSupportedSection">
      <!-- "Unsupported Browser" header -->
      <h3 class="browser-not-supported-header Vlt-black">
        {{
          $t(
            'mobile_join_meeting_from_link_screen.browser_not_supported_header'
          )
        }}
      </h3>

      <!-- Two lines of description text -->
      <p class="browser-not-supported-text Vlt-black">
        {{
          $t('mobile_join_meeting_from_link_screen.browser_not_supported_text')
        }}
      </p>

      <!-- OR separator -->
      <div class="separator">
        <hr class="hr" />
        <span class="separator-span">{{
          $t('mobile_join_meeting_from_link_screen.or_separator')
        }}</span>
      </div>
    </div>

    <div v-if="isVBCRoom">
      <!-- "Join via App" header -->
      <h3
        class="join-via-app-header Vlt-black"
        :class="{ 'border-top': !shouldShowBrowserNotSupportedSection }"
      >
        {{ $t('mobile_join_meeting_from_link_screen.join_via_app_header') }}
      </h3>
      <!-- Two lines of description text -->
      <p class="join-via-app-text Vlt-black">
        {{ $t('mobile_join_meeting_from_link_screen.join_via_app_text') }}
      </p>
      <!-- "Download App" button -->
      <vwc-button
        class="action-button"
        :label="$t('mobile_join_meeting_from_link_screen.download_app_button')"
        layout="filled"
        connotation="cta"
        fullwidth
        enlarged
        @click="downloadApp"
      />
    </div>

    <!-- separator -->
    <div
      v-if="!shouldShowBrowserNotSupportedSection || isVBCRoom"
      class="separator"
    >
      <hr class="hr" />
      <span v-if="isVBCRoom" class="separator-span">{{
        $t('mobile_join_meeting_from_link_screen.or_separator')
      }}</span>
    </div>

    <div class="other-option-container">
      <div v-if="!isMobileWebSupported" class="other-option-container">
        <!-- "Join via Dial In" header -->
        <h3 class="join-via-dial-header Vlt-black">
          {{ $t('mobile_join_meeting_from_link_screen.join_via_dial_header') }}
        </h3>
        <!-- Two lines of description text -->
        <p class="join-via-dial-text Vlt-black">
          {{ $t('mobile_join_meeting_from_link_screen.join_via_dial_text') }}
        </p>
        <!-- Country selector -->
        <VSelect
          v-model="selectedCountryNumber"
          class="join-via-dial-select"
          :label="
            $t('mobile_join_meeting_from_link_screen.join_via_dial_select')
          "
        >
          <template slot>
            <option
              v-for="(entry, index) in dialInNumbers"
              :key="index"
              :value="entry.number"
              :selected="entry.number === selectedCountryNumber"
            >
              {{ entry.display_name }} -
              {{ formatNumber(entry.number) }}
            </option>
          </template>
        </VSelect>
        <vwc-button
          class="action-button"
          :label="
            $t('mobile_join_meeting_from_link_screen.dial_button', {
              selectedCountryNumber: formatNumber(selectedCountryNumber)
            })
          "
          layout="outlined"
          fullwidth
          enlarged
          @click="dial"
        />
      </div>
      <div v-else class="other-option-container">
        <vwc-button
          class="action-button"
          :label="$t('mobile_join_meeting_from_link_screen.continue_button')"
          layout="filled"
          fullwidth
          enlarged
          @click="continueInBrowser"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  formatPhoneDisplayInternational,
  userLanguage,
  isAndroidMobileDevice
} from '@/helpers/global-helpers';
import * as roomServicePublic from '@/apis/room-service-public-api';
import PlaceholderLoadingSkeleton from '@/components/PlaceholderLoadingSkeleton';

export default {
  name: 'MobileJoinMeetingFromLinkScreen',

  components: {
    PlaceholderLoadingSkeleton
  },

  data() {
    return {
      dialInNumbers: '',
      roomPinCode: '',
      roomToken: '',
      roomDisplayName: '',
      roomDomain: '',
      selectedCountryNumber: '',
      isMobileWebSupported: false,
      isBrowserSupported: false,
      isRoomSupportingMobileWeb: false,
      isLoadingRoomDetails: true
    };
  },

  computed: {
    ...mapState('whitelabel', ['brandImageColored', 'brandText']),
    ...mapState(['isBranded']),
    ...mapGetters(['userLocale']),

    isVBCRoom() {
      return this.roomDomain === 'VBC';
    },

    shouldShowBrowserNotSupportedSection() {
      // We show browser not supported only if the browser is not supported but the room is supporting mobile web
      return !this.isBrowserSupported && this.isRoomSupportingMobileWeb;
    }
  },

  watch: {
    dialInNumbers: {
      handler(numbers) {
        if (numbers?.length) {
          let defaultCountry = userLanguage
            ? numbers.find((number) => number.locale === userLanguage)
            : undefined; // search by locale

          defaultCountry =
            defaultCountry ||
            numbers.find((number) => number.locale === 'en-US'); // default to US

          defaultCountry = defaultCountry || numbers[0]; // last resort - just take the first one
          this.selectedCountryNumber = defaultCountry.number;
        }
      },
      immediate: true
    }
  },

  async mounted() {
    this.roomToken = this.$route.params?.roomToken;

    this.isLoadingRoomDetails = true;

    try {
      const roomDetails = await roomServicePublic.getGuestRoomDetails(
        this.roomToken
      );
      this.roomDisplayName = roomDetails.display_name;
      this.roomPinCode = roomDetails.pin_code;
      this.roomDomain = roomDetails.domain;
      this.dialInNumbers = roomDetails.dial_in_numbers.sort(function (a, b) {
        return a.display_name < b.display_name ? -1 : 1;
      });
      this.isBrowserSupported = window.isMobileWebSupported;
      this.isRoomSupportingMobileWeb = roomDetails.is_mobile_web_supported;
      this.isMobileWebSupported =
        window.isMobileWebSupported && roomDetails.is_mobile_web_supported;
      this.setDefaultLocale({
        locale: roomDetails.additional_attributes?.ui_settings?.language
      });
      if (
        this.isMobileWebSupported &&
        (this.isBranded || this.roomDomain === 'VCP')
      ) {
        this.continueInBrowser();
      }
    } catch (error) {
      if (error.response.status === 403 || error.response.status === 404) {
        this.replaceRoute({
          name: 'MobileHome',
          query: { invalid_room_token: this.roomToken }
        });
        return;
      }
    } finally {
      this.isLoadingRoomDetails = false;
    }
  },

  methods: {
    ...mapActions(['replaceRoute']),
    ...mapActions('localization', ['setDefaultLocale']),

    dial() {
      window.open(`tel:+${this.selectedCountryNumber},,${this.roomPinCode}#`);
    },

    continueInBrowser() {
      this.replaceRoute({
        name: 'MobileHome',
        query: { room_token: this.roomToken, ...this.$route.query }
      });
    },

    downloadApp() {
      if (isAndroidMobileDevice) {
        window.location =
          'https://play.google.com/store/apps/details?id=com.vocalocity.Administration&hl=en';
      } else {
        window.location =
          'https://apps.apple.com/us/app/vonage-business-communications/id456697646';
      }
    },

    formatNumber(number) {
      return formatPhoneDisplayInternational(number, this.userLocale);
    }
  }
};
</script>

<style scoped>
.mobile-guest-screen-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 16px 16px;
  overflow: auto;
}

.header {
  margin-bottom: 30px;
  margin-top: 14px;
}

.branded .header {
  margin-top: 25px;
}

.logo {
  width: 151px;
  height: 33px;
}

.brand-text {
  font-size: 52px;
  font-weight: 600;
  line-height: initial;
  color: var(--entrance-screen-logo-bg);
}

.main-title-container {
  display: flex;
  justify-content: center;
  width: 100%;
  min-height: 57px;
  border-top: 1px solid #b3b3b3;
  padding-top: 25px;
  font-size: 20px;
  margin-bottom: 29px;
}

.branded .main-title-container {
  margin-bottom: 15px;
}

.browser-not-supported-header {
  display: flex;
  width: 100%;
  border-top: 1px solid #b3b3b3;
  padding-top: 15px;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 8px;
}
.join-via-app-header {
  display: flex;
  width: 100%;
  padding-top: 15px;
  justify-content: center;
  font-size: 16px;
  margin-bottom: 8px;
}

.border-top {
  border-top: 1px solid #b3b3b3;
}

.join-via-app-header-skeleton {
  width: 120px;
  height: 16px;
}

.browser-not-supported-text {
  width: 100%;
  line-height: 23px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}
.join-via-app-text {
  width: 100%;
  line-height: 23px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}

.join-via-app-text-skeleton {
  width: 100%;
  height: 16px;
  margin: 1rem 0;
}

.action-button-skeleton {
  width: 100%;
  height: 40px;
  margin: 8px 0 16px;
}

vwc-button.action-button {
  margin: 8px 0;
}

.branded vwc-button.action-button {
  margin: 40px 0;
}

.separator {
  position: relative;
  width: 100%;
  height: 12px;
  margin-top: 4px;
  text-align: center;
}

.hr {
  width: 100%;
  border-top: 1px solid #b3b3b3;
  margin: 5px 0 0 0;
}

.separator-span {
  position: relative;
  top: -11px;
  background: white;
  padding: 0 24px;
  font-size: 14px;
}

.other-option-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 7px;
}

.join-via-dial-header {
  text-align: center;
  margin-bottom: 8px;
}

.join-via-dial-text {
  width: 100%;
  line-height: 23px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 16px;
}

.join-via-dial-select {
  width: 100%;
}

.join-via-dial-select select {
  height: 48px;
  margin-bottom: 8px;
}

.main-title-skeleton {
  width: 220px;
  height: 25px;
}

.other-option-skeleton-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 28px;
  width: 100%;
}

.other-option-title-skeleton {
  width: 120px;
  height: 25px;
}

.other-option-description-skeleton {
  width: 250px;
  height: 15px;
  margin: 10px;
}
</style>
