/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-chevron-right': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M5.9.2c-.1-.2-.4-.3-.7-.1-.2.1-.3.4-.1.7L9.9 8l-4.8 7.2c-.2.2-.1.5.1.7.2.2.5.1.7-.1l5-7.5c.1-.2.1-.4 0-.6L5.9.2z"/>'
  }
})
