/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-fax-inbound-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.75 24h14.008v-3.502h-9.78L24 2.476 21.524 0 3.502 18.022v-9.78H0v14.007C0 23.216.784 24 1.75 24z"/>'
  }
})
