export default function getInitialState() {
  return {
    videoUrl: '',
    isWatchTogetherModalShown: false,
    isVideoUrlValid: false,
    videoMetadata: null,
    isLoadingVideoMetadata: false,
    urlValidationErrorReason: null
  };
}
