<template>
  <div
    class="messages"
    :class="{
      'vvd-scheme-alternate': !isMobileWebMode
    }"
    @paste="onFilePaste"
    @dragenter.stop="enterDragZone"
    @dragleave.stop="leaveDragZone"
    @dragover.prevent
    @drop.prevent="getFilesFromDragAndDrop"
  >
    <MobileImageView
      v-if="isMobileWebMode && showImageView"
      class="mobile-image-view"
      :message="imageMessage"
      @closeImageView="$router.back()"
    />

    <div v-else class="chat">
      <div
        v-if="isMobileWebMode"
        data-cy="chat-header"
        class="mobile-messages-header unselectable"
        :class="{ mobile: isMobileWebMode }"
      >
        <v-icon
          data-cy="mobile-chat-back-btn"
          class="mobile-messages-header-icon sm unselectable"
          :icon-name="'Vlt-icon-arrow-thin-left'"
          @click="$router.back()"
        />
        <div class="mobile-messages-header-text">
          <span class="mobile-messages-header-title">{{
            $t('chat.messages_header_title')
          }}</span>
        </div>
      </div>

      <div v-if="isInDropZone" data-cy="drop-zone" class="drop-zone">
        <img class="drop-icon" src="/img-drop-to-upload.svg" />
      </div>

      <MessagesList
        class="messages-list"
        @resend="resendMessage"
        @copyToClipboard="copyMessageToClipboard"
        @openImageView="openImageView"
      />
      <div
        v-if="hasMessagingError"
        class="messaging-unavailable-container unselectable"
      >
        <div
          class="messaging-unavailable-row-container"
          :class="{ clickable: failedReadingMessages }"
          @click="retryReadMessages"
        >
          <v-icon
            v-if="failedReadingMessages"
            iconName="Vlt-icon-refresh"
            class="retry-icon"
          />
          <span class="messaging-unavailable"
            >{{ $t('chat.messaging_unavailable_text')
            }}<span v-if="failedReadingMessages"
              >. {{ $t('chat.retry_text') }}</span
            >
          </span>
        </div>
      </div>

      <div data-cy="message-input" class="message-input">
        <VTextArea
          v-model="messageText"
          :submitButton="isMobileWebMode"
          :disabled="isChatDisabled"
          :prependIcon="prependIcon"
          :placeholder="$t('chat.message_input_placeholder')"
          @submit="sendTextMessage"
          @prependIconClick="openFilesPicker"
        />
        <input
          ref="attachments"
          data-cy="input-message-attachment"
          type="file"
          class="hidden"
          accept="*"
          multiple
          @click="inputFileClicked"
          @change="sendFilesFromInput"
        />
      </div>
      <UploadAttachmentsModal
        v-if="attachments.length > 0"
        :files="attachments"
        @removeFile="removeAttachment"
        @hide="clearAttachments"
        @send="sendAttachments"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import VTextArea from '@/components/volta/VTextArea.vue';
import MessagesList from '@/components/lists/MessagesList.vue';
import UploadAttachmentsModal from '@/components/modals/UploadAttachmentsModal.vue';
import {
  getFilesFromClipboardPasteEvent,
  validateFiles,
  getFilesFromDragAndDropEvent,
  getMediaType
} from '@/helpers/attachments-utils';
import { MEDIA_TYPE, MESSAGE_TYPE } from '@/consts/messaging';
import copy from 'copy-text-to-clipboard';
import { SHORT_TOAST_DURATION, TOAST_TYPE } from '@/consts/mobile-consts';
import MobileImageView from '@/views/mobile/MobileImageView';

export default {
  name: 'Chat',

  components: {
    MobileImageView,
    MessagesList,
    VTextArea,
    UploadAttachmentsModal
  },

  data() {
    return {
      messageText: '',
      attachments: [],
      dropZoneCounter: 0,
      showImageView: false,
      imageMessage: null
    };
  },

  computed: {
    ...mapState(['hasSessionEverConnected']),
    ...mapState('messaging', [
      'groupData',
      'failedReadingMessages',
      'finishedReadingHistoricalMessages'
    ]),
    ...mapGetters(['isMobileWebMode']),
    ...mapGetters('messaging', ['chatMessages']),

    isInDropZone() {
      return this.dropZoneCounter > 0;
    },

    hasMessagingError() {
      return !this.groupData.groupId || this.failedReadingMessages;
    },

    isChatDisabled() {
      return (
        !this.groupData.groupId ||
        !this.finishedReadingHistoricalMessages ||
        !this.hasSessionEverConnected
      );
    },

    prependIcon() {
      return this.isMobileWebMode ? 'Vlt-icon-add-full' : 'Vlt-icon-plus';
    }
  },

  mounted() {
    window.addEventListener('hashchange', this.onHashChanged);
  },

  beforeDestroy() {
    window.removeEventListener('hashchange', this.onHashChanged);
  },

  methods: {
    ...mapActions(['addFlashMessage']),
    ...mapActions('messaging', ['sendMessage', 'readMessages']),
    ...mapActions('mobile', ['displayToast', 'setIsChatOpen']),

    sendTextMessage() {
      const messageText = this.messageText.trim();
      if (messageText) {
        this.sendMessage({ body: messageText });
        this.messageText = '';
      }
    },

    resendMessage(message) {
      let messageToResend = message;
      if (message.type !== MESSAGE_TYPE.TEXT) {
        messageToResend = { ...message };

        messageToResend.attachment = this.chatMessages.find(
          (m) => m.messageId === message.messageId
        ).originalAttachmentFile;
      }
      this.sendMessage(messageToResend);
    },

    retryReadMessages() {
      if (this.failedReadingMessages) {
        this.readMessages(true);
      }
    },

    onFilePaste(clipboardPasteEvent) {
      const filesToSend = getFilesFromClipboardPasteEvent(clipboardPasteEvent);
      if (filesToSend.length > 0) {
        this.attachments = filesToSend;
      }
    },

    sendAttachments(files) {
      if (
        this.isMobileWebMode &&
        files.some(
          (file) =>
            !getMediaType(file) || getMediaType(file) === MEDIA_TYPE.VIDEO
        )
      ) {
        this.displayToast({
          message: this.$t('chat.unsupported_attachment_message'),
          time: SHORT_TOAST_DURATION,
          type: TOAST_TYPE.CRITICAL
        });
        this.clearAttachments();
        return;
      }
      const result = validateFiles(files);
      if (result.error) {
        this.addFlashMessage({
          type: 'critical',
          text: this.$t('chat.attachment_critical_flash_message')
        });
      } else {
        result.attachments.forEach((attachment) => {
          this.sendMessage({ attachment });
        });
        this.clearAttachments();
        this.messageText = '';
      }
    },

    enterDragZone() {
      this.dropZoneCounter++;
      return false;
    },

    leaveDragZone() {
      this.dropZoneCounter--;
      return false;
    },

    getFilesFromDragAndDrop(event) {
      this.dropZoneCounter = 0;
      const files = getFilesFromDragAndDropEvent(event);
      this.attachments = files;
    },

    inputFileClicked() {
      // Without this, the @change event won't be triggered if the user uploads the same file twice
      event.target.value = null;
    },

    sendFilesFromInput(event) {
      // Convert FileList object to Array. It's easier to work with
      this.attachments = [...event.target.files];
    },

    openFilesPicker() {
      this.$refs.attachments.click();
    },

    clearAttachments() {
      this.attachments = [];
    },

    removeAttachment(index) {
      this.attachments.splice(index, 1);
    },

    copyMessageToClipboard(message) {
      if (this.isMobileWebMode) {
        copy(`${message}`);

        this.displayToast({
          message: this.$t('chat.copied_message'),
          time: SHORT_TOAST_DURATION,
          type: TOAST_TYPE.GOOD
        });
      }
    },

    openImageView(message) {
      if (!this.isMobileWebMode) {
        return;
      }

      this.showImageView = true;
      this.$router.history.push(this.$route.path + '#image-view');
      this.imageMessage = message;
    },

    onHashChanged(event) {
      if (!this.isMobileWebMode) {
        return;
      }

      if (event.oldURL.includes('#chat')) {
        if (event.newURL.includes('image-view')) {
          // Forward
          this.showImageView = true;
        } else {
          // Back
          this.setIsChatOpen(false);
          // Remove the empty hash from the url when the chat hash is removed
          this.$router.back();
        }
      } else if (event.oldURL.includes('#image-view')) {
        this.showImageView = false;
      }
    }
  }
};
</script>

<style scoped>
.messages,
.chat {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
}

.mobile .messages {
  width: 100%;
  border: none;
  height: var(--app-height);
}

.messages-list {
  padding: 0 10px;
  overflow-y: scroll;
}

.mobile .messages-list {
  padding: unset;
}

.message-input {
  margin: 16px;
  max-height: 40px;
}

.mobile .message-input {
  margin: 5px 0 0 0;
  position: relative;
  height: 44px;
  max-height: unset;
  border-top: 1px solid #333333;
}

.mobile-messages-header {
  display: flex;
  align-items: center;
  padding: 8px 16px 8px 16px;
  height: 55px;
  background: #1a1a1a;
}

.mobile-messages-header .mobile-messages-header-text {
  display: flex;
  align-items: center;
  margin: auto;
}

.mobile-messages-header
  .mobile-messages-header-text
  .mobile-messages-header-title {
  font: 600 ultra-condensed 16px / 20px SpeziaWebVariable;
  margin-right: 4px;
}

.drop-zone {
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(16, 27, 42);
  opacity: 0.7;
  height: 100%;
  width: 100%;
  z-index: 5;
  cursor: pointer;
}

.drop-icon {
  position: absolute;
  top: 22%;
  left: 26%;
  text-align: center;
  width: 50%;
  height: 50%;
  border-radius: 5px;
}

.hidden {
  display: none;
}

.messaging-unavailable-container {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.messaging-unavailable-container .messaging-unavailable-row-container {
  display: flex;
  align-items: center;
  margin: 0 auto 0 15px;
}

.messaging-unavailable-container
  .messaging-unavailable-row-container.clickable {
  cursor: pointer;
}

.messaging-unavailable-container .messaging-unavailable {
  font-size: 12px;
  font-weight: bold;
  color: #f25a6b;
  margin-left: 4px;
}

.messaging-unavailable-container .retry-icon {
  width: 17px;
  height: 17px;
  fill: #f25a6b;
}

.mobile-image-view {
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 2000;
  flex: none;
}
</style>
