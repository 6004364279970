/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-chart': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M9 .75A.75.75 0 019.75 0h4.5a.75.75 0 01.75.75v22.5a.75.75 0 01-.75.75h-4.5a.75.75 0 01-.75-.75V.75zm1.5.75v21h3v-21h-3zM0 15.75A.75.75 0 01.75 15h4.5a.75.75 0 01.75.75v7.5a.75.75 0 01-.75.75H.75a.75.75 0 01-.75-.75v-7.5zm1.5.75v6h3v-6h-3zm17.25-9a.75.75 0 00-.75.75v15c0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75v-15a.75.75 0 00-.75-.75h-4.5zm3.75 15h-3V9h3v13.5z"/>'
  }
})
