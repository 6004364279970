/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Brand-icon-google-color': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M8.285.655c-6.42 2.183-9.795 9.06-7.51 15.28 1.307 3.712 4.463 6.659 8.38 7.64 2.068.547 4.245.547 6.204.11a12.07 12.07 0 005.006-2.51c1.524-1.31 2.503-3.056 3.047-5.021.544-2.074.653-4.257.327-6.33H12.203v4.693h6.639c-.218 1.528-1.198 2.838-2.395 3.71-.762.546-1.741.874-2.72 1.092a6.677 6.677 0 01-2.939 0c-.98-.327-1.85-.764-2.72-1.31-1.307-.873-2.286-2.183-2.83-3.71a6.951 6.951 0 010-4.694c.326-1.091.98-2.074 1.741-2.838 1.85-1.855 4.68-2.62 7.183-1.855.98.327 1.85.764 2.612 1.528.761-.764 1.523-1.42 2.285-2.183l1.197-1.2C19.168 1.964 17.753 1.2 16.338.654c-2.503-.873-5.441-.873-8.053 0z" _fill="#fff"/><path pid="1" d="M8.285.655c2.72-.873 5.55-.873 8.271.109 1.415.546 2.83 1.31 3.918 2.401l-1.197 1.2c-.762.765-1.524 1.42-2.286 2.184-.761-.655-1.632-1.2-2.611-1.528-2.612-.764-5.333 0-7.183 1.855a6.146 6.146 0 00-1.85 2.838L1.32 6.658A11.868 11.868 0 018.285.655z" _fill="#E33629"/><path pid="2" d="M.232 9.605C.449 8.513.776 7.531 1.32 6.549l4.027 3.056a6.95 6.95 0 000 4.693c-1.306.983-2.721 2.074-4.027 3.057-1.197-2.402-1.633-5.13-1.088-7.75z" _fill="#F8BD00"/><path pid="3" d="M12.203 9.714h11.536a12.527 12.527 0 01-.327 6.33c-.544 1.856-1.632 3.603-3.047 5.021l-3.918-2.947c1.306-.873 2.177-2.182 2.395-3.71h-6.64V9.713z" _fill="#587DBD"/><path pid="4" d="M1.32 17.355c1.306-.983 2.72-2.074 4.027-3.056.544 1.528 1.523 2.728 2.83 3.71a7.68 7.68 0 002.72 1.201c.98.218 1.959.218 2.938 0 .98-.109 1.85-.546 2.721-1.091l3.918 2.947a9.941 9.941 0 01-5.006 2.51c-2.068.437-4.245.437-6.204-.109a13.096 13.096 0 01-4.462-2.183c-1.523-.982-2.72-2.292-3.482-3.93z" _fill="#319F43"/></g><defs><clipPath id="clip0"><path pid="5" d="M0 0h24v24H0V0z" _fill="#fff"/></clipPath></defs>'
  }
})
