<template>
  <div
    v-if="!minimizedMode && !isMobileWebMode"
    v-observe-keyboard.prevent.esc="closeOnEsc"
    v-observe-keyboard.f11="toggleFullscreen"
    v-observe-keyboard.prevent.cmd.shift.f="toggleFullscreen"
    class="meeting-titlebar"
    :class="{
      'mac-titlebar': showMacTitlebar,
      'black-bg': blackBgMode
    }"
    @dblclick="onMaximize"
  >
    <!-- We need this action-bar-drag-region div because of a resizing bug in windows -->
    <div class="action-bar-drag-region"></div>
    <transition name="fade">
      <MainScreenActionsBar v-if="isAppRunning" class="action-bar Vlt-white" />
    </transition>

    <!-- We are using the FramelessTitlebar component only for windows.
    For MacOS we are using the electron hidden titlebar alternative. -->
    <FramelessTitlebar
      v-if="showWindowsTitlebar"
      class="title-bar"
      :black="blackButtonMode"
      :isMaximized="isWindows && isMaximized"
      @close="onClose"
      @minimize="onMinimize"
      @maximize="onMaximize"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import FramelessTitlebar from '@/components/FramelessTitlebar.vue';
import MainScreenActionsBar from '@/components/MainScreenActionsBar.vue';
import { isElectron, OS } from '@/helpers/global-helpers';
import {
  OS_TYPES,
  ANALYTICS,
  KEYBOARD_SHORTCUTS
} from '@/consts/global-consts';
import analytics from '@/services/analytics-service';

export default {
  name: 'MeetingTitlebar',

  components: {
    FramelessTitlebar,
    MainScreenActionsBar
  },

  data() {
    return {
      isWindows: OS.name === OS_TYPES.WINDOWS
    };
  },

  computed: {
    ...mapState([
      'minimizedMode',
      'showEntranceScreen',
      'isFullScreen',
      'isMaximized',
      'isAppRunning',
      'isBranded',
      'sessionId',
      'showThankYouScreen',
      'showPreJoinScreen',
      'isEndMeetingDialogVisible'
    ]),
    ...mapGetters(['showFramelessTitlebar', 'isMobileWebMode']),

    blackButtonMode() {
      return this.$route.name === 'ThankYouScreen';
    },

    showMacTitlebar() {
      return !this.isWindows && this.showFramelessTitlebar;
    },

    showWindowsTitlebar() {
      return this.isWindows && this.showFramelessTitlebar;
    },

    blackBgMode() {
      return (
        this.sessionId && !this.showThankYouScreen && !this.showPreJoinScreen
      );
    }
  },

  mounted() {
    if (isElectron() && window.Electron.onMaximizedStateChanged) {
      window.Electron.onMaximizedStateChanged((event, isMaximized) => {
        this.setIsMaximized(isMaximized);
      });
    }
  },

  methods: {
    ...mapActions(['setIsMaximized', 'setIsEndMeetingDialogVisible']),

    onClose() {
      if (isElectron()) {
        window.close();
      }
    },

    onMinimize() {
      if (isElectron() && window.Electron.minimizeWindow) {
        window.Electron.minimizeWindow();
      }
    },

    onMaximize() {
      if (isElectron() && window.Electron.maximizeWindow) {
        window.Electron.maximizeWindow();
      }
    },

    toggleFullscreen() {
      if (isElectron() && window.Electron.toggleFullscreen) {
        analytics.trackEvent(ANALYTICS.WINDOW_SIZE_TOGGLED, {
          Fullscreen: !this.isFullScreen ? 'Yes' : 'No'
        });

        analytics.trackEvent(ANALYTICS.KEYBOARD_SHORTCUTS, {
          Type: KEYBOARD_SHORTCUTS.TOGGLE_FULL_SCREEN_MODE
        });

        window.Electron.toggleFullscreen();
      }
    },

    closeOnEsc() {
      if (isElectron()) {
        if (this.isFullScreen) {
          this.toggleFullscreen();
        } else {
          window.close();
        }
      } else {
        this.setIsEndMeetingDialogVisible(!this.isEndMeetingDialogVisible);
        analytics.trackEvent(ANALYTICS.KEYBOARD_SHORTCUTS, {
          Type: KEYBOARD_SHORTCUTS.TOGGLE_LEAVE_MEETING_MODAL
        });
      }
    }
  }
};
</script>

<style scoped>
.meeting-titlebar {
  position: absolute;
  display: flex;
  justify-content: flex-end;
  z-index: 10;
  height: 40px;
  width: 100%;
}

.fade-enter-active {
  transition: opacity 1s;
}

.fade-enter {
  opacity: 0;
}

.meeting-titlebar.mac-titlebar {
  padding-left: 70px;
}

.action-bar {
  flex: 1;
}

.title-bar {
  justify-self: flex-end;
}

.action-bar-drag-region {
  position: absolute;
  left: 6px;
  top: 6px;
  display: block;
  width: calc(100% - 12px);
  height: calc(100% - 6px);
  z-index: -1;
  -webkit-app-region: drag;
}

.black-bg {
  background-color: #0d0d0d;
}
</style>
