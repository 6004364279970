<template>
  <div
    class="standup"
    :class="{ 'focus-animation': isPlayingFocusAnimation }"
    @animationend="stopFocusAnimation"
  >
    <vwc-icon type="user-sync-line" connotation="cta" class="standup-icon" />
    <div class="standup-text">
      <span class="meeting-is-locked-text">{{ $t('standup.title') }}</span>
      <span class="current-speaker-text">
        {{
          $t('standup.current_speaker_text', { currentParticipantDisplayName })
        }}
      </span>
      <span class="remaining-participants-text Vlt-grey-darker">
        {{
          $t('standup.remaining_participants_text', {
            remainingParticipantsCount
          })
        }}
      </span>
    </div>
    <vwc-button
      class="next-button"
      :label="buttonText"
      layout="outlined"
      shape="pill"
      dense
      :disabled="isNextButtonDisabled"
      @click="nextButtonClicked"
    />
    <v-icon
      iconName="Vlt-icon-cross"
      class="close-standup"
      @click="stopStandup"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
  name: 'Standup',

  computed: {
    ...mapState('standup', [
      'wasNextRecentlyClicked',
      'isPlayingFocusAnimation'
    ]),
    ...mapGetters('standup', [
      'currentParticipantDisplayName',
      'remainingParticipantsCount',
      'pastSpeakersCount'
    ]),

    buttonText() {
      if (this.remainingParticipantsCount === 0) {
        return this.$t('standup.close_button');
      }

      return this.$t('standup.next_button');
    },

    isNextButtonDisabled() {
      // Prevent multiple consecutive clicks on the next button
      if (this.wasNextRecentlyClicked) {
        return true;
      }

      return false;
    }
  },

  methods: {
    ...mapActions('standup', [
      'moveToNextSpeaker',
      'moveToPreviousSpeaker',
      'stopStandup',
      'stopFocusAnimation'
    ]),

    nextButtonClicked: function () {
      this.moveToNextSpeaker();
    }
  }
};
</script>

<style scoped>
.standup {
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
  color: #ffffff;
  min-height: 72px;
  margin: 10px 16px 10px 16px;
  padding: 10px;
  border: 1px solid #c4c4c5;
  border-radius: 6px;
}

.standup:before {
  bottom: 0;
  content: '';
  left: 0;
  position: absolute;
  top: 0;
  width: 5px;
  background-color: var(--btn-cta-color-white-bg);
}

.standup-icon {
  width: 24px;
  height: 24px;
  margin: 0 12px;
}

.standup-text {
  font-size: 14px;
  color: #131415;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.standup-text .current-speaker-text {
  line-break: auto;
}

.remaining-participants-text {
  font-size: 12px;
}

.meeting-is-locked-text {
  font-weight: bold;
}

.close-standup {
  width: 20px;
  height: 20px;
  fill: #131415;
  margin-left: 8px;
  padding: 3px;
  transition: background-color 0.3s;
}

.focus-animation {
  animation: highlight-background 0.8s linear forwards;
}

@keyframes highlight-background {
  30%,
  60% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

.next-button {
  margin-left: 8px;
}
</style>
