<template>
  <Dialog
    data-cy="watch-together-modal"
    :heading="$t('watch_together_modal.title')"
    class="watch-together-modal"
    icon-name="file-video-2-line"
    :close="(confirm) => (confirm ? start() : closeWatchTogetherModal())"
    primaryButtonConnotation="cta"
    :min-width="460"
    :primary-action="$t('watch_together_modal.start_button')"
    primaryButtonIcon="play-solid"
    :disablePrimaryButton="!isVideoUrlValid"
    @applied="start"
  >
    <div slot="content">
      <div class="form">
        <div class="description">
          <div v-html="$t('watch_together_modal.description_part1')"></div>
          <div>{{ $t('watch_together_modal.description_part2') }}</div>
        </div>

        <div class="video-link-row">
          <div class="input-label">
            {{ $t('watch_together_modal.video_url_label') }}
          </div>
          <v-input
            v-model="videoUrl"
            class="input-box video-link-input"
            type="text"
            placeholder="https://www.youtube.com/example"
            big
            preservePlaceForError
            focus
            :errorMessage="urlValidationError"
            @textChanged="onVideoUrlChanged"
          />
        </div>

        <div v-if="videoMetadata" class="video-preview">
          <WatchTogetherVideoPreview
            :videoMetadata="videoMetadata"
            :isLoadingVideoMetadata="isLoadingVideoMetadata"
          />
        </div>

        <vwc-empty-state
          v-else
          class="empty-state"
          :heading="$t('watch_together_modal.empty_state_title')"
          :body="$t('watch_together_modal.empty_state_subtitle')"
        >
          <img slot="graphic" :src="emptyStateImg" />
        </vwc-empty-state>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import WatchTogetherVideoPreview from './WatchTogetherVideoPreview';
import { VIDEO_URL_ERROR_REASON } from '@/services/watch-together/video-url-validation-error';
import Dialog from '@/components/Dialog';

export default {
  name: 'WatchTogetherModal',

  components: { WatchTogetherVideoPreview, Dialog },

  data() {
    return {
      videoUrl: ''
    };
  },

  computed: {
    ...mapState(['isBranded']),
    ...mapState('watchTogether/modal', [
      'isVideoUrlValid',
      'videoMetadata',
      'isLoadingVideoMetadata',
      'urlValidationErrorReason'
    ]),

    emptyStateImg() {
      return this.isBranded
        ? '/illustrations/empty-state/empty-link-branded.svg'
        : '/illustrations/empty-state/empty-link.svg';
    },

    urlValidationError() {
      switch (this.urlValidationErrorReason) {
        case VIDEO_URL_ERROR_REASON.UNKNOWN: {
          return this.$t('watch_together_modal.url_error_unknown');
        }
        case VIDEO_URL_ERROR_REASON.UNSUPPORTED_SITE: {
          return this.$t('watch_together_modal.url_error_unsupported_site');
        }
        case VIDEO_URL_ERROR_REASON.VIDEO_NOT_EMBEDDABLE: {
          return this.$t('watch_together_modal.url_error_video_not_embedded');
        }
        case VIDEO_URL_ERROR_REASON.LIVE_VIDEO: {
          return this.$t('watch_together_modal.url_error_live_video');
        }
      }

      return null;
    }
  },

  methods: {
    ...mapActions('watchTogether/modal', [
      'updateVideoUrl',
      'closeWatchTogetherModal'
    ]),
    ...mapActions('watchTogether/ongoing', ['startWatchTogether']),

    start() {
      if (!this.isVideoUrlValid) {
        return;
      }

      this.startWatchTogether({ videoMetadata: this.videoMetadata });
      this.closeWatchTogetherModal();
    },

    onVideoUrlChanged() {
      this.updateVideoUrl(this.videoUrl);
    }
  }
};
</script>

<style scoped>
.watch-together-modal {
  width: 460px;
}

.form {
  display: flex;
  flex-direction: column;
}

.input-label {
  margin-top: 8px;
}
.video-link-input {
  margin-top: 5px;
  font-size: 2rem;
  max-width: 355px;
  min-height: 40px;
  margin-right: 0.5rem;
  flex: 1;
}

.empty-state {
  padding-top: 10px;
  padding-bottom: 35px;
}

.video-preview {
  margin-top: -10px;
  max-width: 410px;
  padding-top: 10px;
}

.video-link-row {
  display: flex;
  flex-direction: column;
}

.watch-together-modal >>> .title-icon {
  margin-bottom: 8px;
}

.description {
  margin-bottom: 1rem;
}

vwc-button.modal-button {
  margin-left: 8px;
}
</style>
