<template>
  <vwc-dialog
    hideActions
    connotation="cta"
    class="vvd-scheme-alternate modal"
    :open="true"
  >
    <div class="modal-content">
      <div class="title pre-formatted" v-html="title"></div>
      <div class="pre-formatted" v-html="content"></div>
      <vwc-linear-progress
        class="linear-progress"
        indeterminate
        :connotation="linearProgressConnotation"
        :progress="0.5"
        :buffer="1"
      />
    </div>
  </vwc-dialog>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'PermissionsModal',

  computed: {
    ...mapState(['isBranded']),

    title() {
      return this.isBranded
        ? this.$t('permissions_modal.branded_title')
        : this.$t('permissions_modal.title');
    },

    linearProgressConnotation() {
      return this.isBranded ? 'cta' : 'pacific';
    },

    content() {
      return this.isBranded
        ? this.$t('permissions_modal.branded_content')
        : this.$t('permissions_modal.content');
    }
  }
};
</script>

<style scoped>
.pre-formatted {
  white-space: pre-wrap;
}

.modal {
  --mdc-dialog-max-width: 460px;
}

.linear-progress {
  margin-top: 30px;
}

.mdc-dialog__title {
  white-space: pre-wrap;
}

.title {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 10px;
}

.branded .vvd-scheme-alternate {
  --vvd-color-cta: var(--btn-cta-color-white-bg);
  --vvd-color-on-cta: var(--btn-cta-color-white-bg-text-color);
}
</style>
