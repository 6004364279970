/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-design-tools': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M2.6 6.4c.2.2.5.2.7 0 .2-.2.2-.5 0-.7L1.2 3.5l2.3-2.3 2.1 2.1c.2.2.5.2.7 0 .2-.2.2-.5 0-.7L3.9.1c-.2-.1-.6-.1-.8 0l-3 3c-.1.2-.1.6 0 .8l2.5 2.5zM14.9 11.1l-1.5-1.5c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l1.4 1.4.8 3.1-3.1-.8-1.4-1.4c-.2-.2-.5-.2-.7 0-.2.2-.2.5 0 .7l1.5 1.5c.1.1.1.1.2.1l4 1c.2 0 .4 0 .5-.1.1-.1.2-.3.1-.5l-1-4s-.1-.1-.1-.2zM15.9 4.1l-4-4c-.2-.2-.5-.2-.7 0L8.7 2.6l-2 2-2 2-2 2-2.5 2.5c-.2.2-.2.5 0 .7l4 4c.2.2.5.2.7 0l11-11c.1-.1.1-.5 0-.7zM4.5 14.8l-3.3-3.3L3 9.7l1.1 1.1c.2.2.5.2.7 0 .2-.2.2-.5 0-.7L3.7 9 5 7.7l1.1 1.1c.2.2.6.2.8.1.1-.2.1-.6 0-.8L5.7 7 7 5.7l1.1 1.1c.2.2.6.2.8.1.1-.2.1-.6 0-.8L7.7 5 9 3.7l1.1 1.1c.2.2.5.2.7 0 .2-.2.2-.5 0-.7L9.7 3l1.8-1.8 3.3 3.3L4.5 14.8z"/>'
  }
})
