export function getInitialState() {
  return createState(false, false, 0, '', null);
}

export function createState(
  isWatchTogetherActive,
  isVideoPlaying,
  timeInVideoInSeconds,
  watchTogetherOwnerParticipantId,
  videoMetadata
) {
  return {
    isWatchTogetherActive,
    isVideoPlaying,
    timeInVideo: { seconds: timeInVideoInSeconds },
    watchTogetherOwnerParticipantId,
    videoMetadata,
    showWatchTogetherMuteIndication: false,
    hasSeenMuteIndication: false,
    isSyncedWithOwner: true
  };
}
