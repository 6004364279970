import { get, set, del } from 'idb-keyval';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import { localStorageService } from '@/services/storage-service';

let myExternalId;

// externalId=undefined for GUEST
export async function init(externalId) {
  myExternalId = externalId;
  await _resetContactsIfNeeded();
}

export function resetUser() {
  myExternalId = undefined;
}

export async function clearContactsCache() {
  await _resetContacts();
}

export async function getContacts() {
  try {
    return localStorageService.getItem('lastModifiedContacts')
      ? await get('contacts')
      : [];
  } catch (err) {
    logger.error(
      'caching-service/getContacts',
      LOG_CATEGORIES.CLIENT_LOGIC,
      err
    );
  }
}

export async function setContacts(contacts, lastModified = 0) {
  if (!myExternalId) {
    return;
  }

  try {
    await set('contacts', contacts);
    localStorageService.setItem('lastModifiedContacts', lastModified);
    localStorageService.setItem('cachedContactsUserExternalId', myExternalId);
  } catch (err) {
    logger.error(
      'caching-service/setContacts',
      LOG_CATEGORIES.CLIENT_LOGIC,
      err
    );
  }
}

export async function getContactsLastModified() {
  await _resetContactsIfNeeded();
  return localStorageService.getItem('lastModifiedContacts') || 0;
}

async function _resetContactsIfNeeded() {
  if (
    myExternalId !== localStorageService.getItem('cachedContactsUserExternalId')
  ) {
    await _resetContacts();
  }
}

async function _resetContacts() {
  localStorageService.removeItem('lastModifiedContacts');
  localStorageService.removeItem('cachedContactsUserExternalId');
  try {
    await del('contacts');
  } catch (err) {
    logger.error(
      'caching-service/_resetContacts',
      LOG_CATEGORIES.CLIENT_LOGIC,
      err
    );
  }
}
