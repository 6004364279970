/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-mobile-arrow-right-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M6 0h12a3 3 0 013 3v18a3 3 0 01-3 3H6a3 3 0 01-3-3v-4.5h3v3h12v-15H6v3H3V3a3 3 0 013-3zm3 7.5l6 4.5-6 4.5v-3H0v-3h9v-3z"/>'
  }
})
