import store from '@/store';
import logger from '@/services/logging/logger';
import { LOG_CATEGORIES } from '@/services/logging/log-categories';
import MeetingManager from '@/services/meeting-manager-service';
import {
  MUTE_FORCED_TYPE,
  SUBSCRIBER_STATUS,
  LAYOUT_MODE_TYPES,
  CHECK_NETWORK_INTERVAL,
  NUMBER_OF_PARTICIPANTS_TO_CHANGE_TO_AUDIENCE,
  CONNECTION_STATUS,
  TIME_TO_WAIT_FOR_DEVICES_CHANGED_RECENTLY_TO_BE_SET,
  ANALYTICS,
  KEYBOARD_SHORTCUTS,
  TOGGLE_MIC_SOURCES,
  TOGGLE_CAMERA_SOURCES
} from '@/consts/global-consts';
import { HOST_OUTBOUND_EVENTS } from '@/store/embedded/consts';
import * as utils from '@/helpers/meeting-helpers';
import { isElectron } from '@/helpers/global-helpers';
import * as roomService from '@/apis/room-service-api';
import * as SplitAudioDetector from '@/services/split-audio-detector';
import router from '@/router';
import {
  isAppleMobileDevice,
  isContentEditable
} from '@/helpers/global-helpers';
import debounce from 'lodash.debounce';
import { setDefaultSuccessHandler } from '@/apis/vbc-gw';
import analytics from '@/services/analytics-service';
import StreamsStats from '@/services/streams-stats-service';

let recordingEventTimer = null;
let validateIfOnlineInterval = null;
const clearNetworkInterval = () => {
  if (validateIfOnlineInterval) {
    clearInterval(validateIfOnlineInterval);
    validateIfOnlineInterval = null;
  }
};

export default {
  // session events
  subscribeSessionEvents: ({
    state,
    commit,
    dispatch,
    getters,
    rootState,
    rootGetters
  }) => {
    // Participant published new stream.
    MeetingManager.onSessionEvent('streamCreated', (event) => {
      const stream = event.stream;
      const streamId = stream.streamId;
      const isShareScreen = utils.isScreenshareStream(stream);
      MeetingManager.addStream(stream);
      logger.log('tb_stream-created', LOG_CATEGORIES.TOKBOX, {
        tb_event: 'session.on.streamCreated',
        streamId,
        isShareScreen
      });
      dispatch('setStreamSubscriberStatus', {
        streamId: stream.streamId,
        status: SUBSCRIBER_STATUS.NOT_SUBSCRIBED
      });
      if (isShareScreen) {
        dispatch('addStream', { stream, isPublisher: false });
      } else {
        const streamData = utils.extractStreamData(stream);
        dispatch('updateStream', {
          streamId: streamData.connectionId,
          streamData
        });
        // TODO: should set participant.badQuality to false in case the participant refreshed
      }
      if (!state.mainStreamId) {
        commit('SET_MAIN_STREAM_ID', event.stream.streamId);
      }

      if (isShareScreen) {
        dispatch('layout/changeLayoutOnScreenshareStarted');
        dispatch('pinVideoStream', '');
      }
    });

    // Participant closed stream.
    MeetingManager.onSessionEvent('streamDestroyed', (event) => {
      const streamId = event.stream.streamId;
      const isShareScreen = utils.isScreenshareStream(event.stream);
      MeetingManager.removeSubscriber(streamId);

      logger.log('tb_stream-destroyed', LOG_CATEGORIES.TOKBOX, {
        tb_event: 'session.on.streamDestroyed',
        streamId,
        isShareScreen
      });

      if (isShareScreen) {
        dispatch('removeStream', streamId);
        dispatch('layout/changeLayoutOnScreenshareEnded');
      } else {
        const connection = event.stream.connection;
        const stream = getters.streamsMap[streamId];
        if (stream) {
          dispatch('updateStream', {
            streamId,
            streamData: utils.createAvatarStream(
              stream.participantId,
              connection.connectionId
            )
          });
        }
      }
    });

    // session disconnected or participant was kicked out
    MeetingManager.onSessionEvent('sessionDisconnected', (event) => {
      logger.log('tb_session-disconnected', LOG_CATEGORIES.TOKBOX, {
        tb_event: 'session.on.sessionDisconnected',
        reason: event.reason
      });
      commit('SET_IS_SESSION_CONNECTED', false);

      dispatch('pinVideoStream', '');
      if (getters.isScreenShared) {
        dispatch('screenshare/toggleScreenshareIndicator', { isShared: false });
      }

      let leaveReason;
      if (state.isAboutToGetKicked) {
        leaveReason = 'kicked';
        dispatch('showParticipantIsKickedMessage');
      } else if (event.reason === 'networkDisconnected') {
        leaveReason = 'network';
        dispatch('handleNetworkDisconnectedEvent');
      } else if (event.reason === 'forceDisconnected') {
        leaveReason = 'meeting-ended';
        dispatch('showLeftMeetingMessage');
      } else {
        leaveReason = 'user-left';
        dispatch('showLeftMeetingMessage');
      }
      dispatch('embedded/emitEventToHost', {
        eventName: HOST_OUTBOUND_EVENTS.LEFT_MEETING,
        eventData: { reason: leaveReason }
      });
    });

    MeetingManager.onSessionEvent('sessionReconnected', () => {
      logger.log('tb_session-reconnected', LOG_CATEGORIES.TOKBOX, {
        tb_event: 'session.on.sessionReconnected'
      });

      dispatch('setIsNetworkIssues', false);
    });

    MeetingManager.onSessionEvent('sessionReconnecting', () => {
      logger.log('tb_session-reconnecting', LOG_CATEGORIES.TOKBOX, {
        tb_event: 'session.on.sessionReconnecting'
      });

      dispatch('setIsNetworkIssues', true);
    });

    // New participant joined.
    MeetingManager.onSessionEvent('connectionCreated', (event) => {
      const data = new URLSearchParams(event.connection.data);
      const participantId = data.get('participantId');
      const type = data.get('type');
      // Every subscription to captions create a new connection without participantId
      if (type === 'Captions' || !participantId) {
        return;
      }
      const connectionId = event.connection.connectionId;
      let isPublisher = false;

      if (MeetingManager.connectionId === connectionId) {
        commit('SET_MY_PARTICIPANT_ID', participantId);
        isPublisher = true;
        logger.log('tb_my-connection-created', LOG_CATEGORIES.TOKBOX, {
          tb_event: 'session.on.connectionCreated',
          connection: event.connection
        });
      } else {
        logger.log('tb_connection-created', LOG_CATEGORIES.TOKBOX, {
          tb_event: 'session.on.connectionCreated',
          connection: event.connection
        });
      }
      const participantData = utils.extractParticipantData(event.connection);
      commit('UPDATE_PARTICIPANT', participantData);

      if (!getters.streamsMap[connectionId]) {
        commit(
          'ADD_STREAM',
          utils.createAvatarStream(participantId, connectionId, isPublisher)
        );
      } else {
        logger.error('connection-created-error', LOG_CATEGORIES.TOKBOX, {
          message: 'Stream already exists for participant',
          connection: event.connection
        });
      }

      if (rootState.layout.userPreferredLayout !== undefined) {
        // user entered the meeting with layout param - give it priority over 'big meeting' default layout (audience)
        return;
      }

      if (
        !state.layout.layoutChangedInLargeMeeting &&
        (state.layout.layoutMode === LAYOUT_MODE_TYPES.GRID ||
          rootGetters.screenshareStreamId) &&
        rootGetters.activeParticipants.length >=
          NUMBER_OF_PARTICIPANTS_TO_CHANGE_TO_AUDIENCE
      ) {
        dispatch('layout/changeLayoutInLargeMeeting');
      }
    });

    // Participant left
    MeetingManager.onSessionEvent('connectionDestroyed', (event) => {
      // If my connection was destroyed, show the ended meeting screen.\
      const connectionId = event.connection.connectionId;
      if (MeetingManager.connectionId === connectionId) {
        logger.log('tb_my-connection-destroyed', LOG_CATEGORIES.TOKBOX, {
          tb_event: 'session.on.connectionDestroyed',
          connectionId
        });
      } else {
        logger.log('tb_connection-destroyed', LOG_CATEGORIES.TOKBOX, {
          tb_event: 'session.on.connectionDestroyed',
          connectionId
        });
        dispatch('removeStream', connectionId);
      }
    });

    MeetingManager.onSessionEvent('streamPropertyChanged', (event) => {
      dispatch('updateStream', {
        streamId: event.stream.streamId,
        streamData: { [event.changedProperty]: event.newValue },
        videoDimensions: event.stream.videoDimensions
      });
      // Add the stream to the split audio test list in case the stream should have audio
      if (event.changedProperty === 'hasAudio' && event.newValue) {
        const isPublisher =
          event.stream.streamId === MeetingManager.publisherStreamId;
        isPublisher
          ? SplitAudioDetector.resetPublisherAudioLevelTestCounter()
          : SplitAudioDetector.resetSubscriberAudioLevelTestCounter(
              event.stream.streamId
            );
      }

      dispatch('detectMyStreamPropertiesChanged', { event });
    });

    MeetingManager.onSessionEvent('muteForced', (event) => {
      logger.log('sessionMuteForced', LOG_CATEGORIES.TOKBOX, {
        event
      });
      // active = false means mute on entry false and it's not interesting for us
      if (event.active) {
        dispatch(
          'setCurrentForceMuteEventState',
          MUTE_FORCED_TYPE.SESSION_MUTE
        );
      }
    });

    MeetingManager.onSessionEvent('archiveStarted', () => {
      recordingEventTimer = setTimeout(() => {
        if (!getters['recordings/isRecording']) {
          logger.warning(
            'missing-recording-indication',
            LOG_CATEGORIES.CLIENT_LOGIC,
            {
              message: `Received a recording event from TokBox but the state didn't change after 35 seconds`
            }
          );
        }
      }, 35000);
    });

    MeetingManager.onSessionEvent('archiveStopped', () => {
      clearTimeout(recordingEventTimer);
    });
  },

  // subscriber events
  subscribeSubscriberEvents: ({ dispatch, getters }, subscriber) => {
    const stream = getters.streamsMap[subscriber.stream.streamId];
    if (!stream) {
      logger.error('subscribe-subscriber-events', LOG_CATEGORIES.TOKBOX, {
        message: 'Could not find stream',
        subscriber
      });
      return;
    }
    const participantId = stream.participantId;

    subscriber.on('videoDisabled', (event) => {
      if (event.reason === 'quality') {
        dispatch('updateStream', {
          streamId: subscriber.stream.streamId,
          streamData: { badQuality: true }
        });
      }
      logger.log('videoDisabled', LOG_CATEGORIES.TOKBOX, {
        participantId,
        streamId: subscriber.stream.streamId,
        reason: event.reason
      });
    });

    subscriber.on('videoEnabled', (event) => {
      if (event.reason === 'quality') {
        dispatch('updateStream', {
          streamId: subscriber.stream.streamId,
          streamData: { badQuality: false },
          videoDimensions: subscriber.stream.videoDimensions
        });
      }
      logger.log('videoEnabled', LOG_CATEGORIES.TOKBOX, {
        participantId,
        streamId: subscriber.stream.streamId,
        reason: event.reason
      });
    });

    subscriber.on('disconnected', () => {
      const streamId = subscriber.stream.streamId;
      const isShareScreen = utils.isScreenshareStream(subscriber.stream);
      dispatch('setStreamSubscriberStatus', {
        streamId,
        status: SUBSCRIBER_STATUS.RETRYING
      });
      logger.log('tb_subscriber-reconnecting', LOG_CATEGORIES.TOKBOX, {
        tb_event: 'subscriber.on.disconnected',
        streamId,
        isShareScreen
      });
    });

    subscriber.on('connected', () => {
      const streamId = subscriber.stream.streamId;
      const isShareScreen = utils.isScreenshareStream(subscriber.stream);
      dispatch('setStreamSubscriberStatus', {
        streamId: streamId,
        status: SUBSCRIBER_STATUS.SUBSCRIBED
      });
      logger.log('tb_subscriber-connected', LOG_CATEGORIES.TOKBOX, {
        tb_event: 'subscriber.on.connected',
        streamId,
        isShareScreen
      });

      // Test - trying to detect split audio
      SplitAudioDetector.resetSubscriberAudioLevelTestCounter(streamId);
    });

    subscriber.on('destroyed', () => {
      const { streamId } = stream;
      const isShareScreen = utils.isScreenshareStream(stream);
      logger.log('tb_subscriber-destroyed', LOG_CATEGORIES.TOKBOX, {
        tb_event: 'subscriber.on.destroyed',
        streamId,
        isShareScreen
      });
      if (MeetingManager.isSubscriberStream(streamId)) {
        const subscriber = MeetingManager.getSubscriberByStreamId(streamId);
        // This means we have a stream object without a subscriber. Let's subscribe it again.
        if (!subscriber) {
          if (router.currentRoute.name === 'Home') {
            logger.error(
              'tb_subscriber-destroyed-resubscribe',
              LOG_CATEGORIES.TOKBOX,
              {
                tb_event: 'subscriber.on.destroyed',
                streamId,
                isShareScreen
              }
            );
            dispatch('resubscribeStream', streamId);
          } else {
            MeetingManager.removeSubscriber(streamId);
          }
        }
      }
    });

    subscriber.on('audioLevelUpdated', (event) => {
      const streamId = subscriber.stream.streamId;
      const audioLevel = [event.audioLevel];

      StreamsStats.setStreamAudioLevel(streamId, audioLevel);
    });

    // Events for non-screenshare subscribers
    if (!utils.isScreenshareStream(stream)) {
      subscriber.on('audioBlocked', () => {
        logger.log('audioBlocked', LOG_CATEGORIES.TOKBOX, {
          participantId,
          streamId: subscriber.stream.streamId
        });
      });

      subscriber.on('audioUnblocked', () => {
        logger.log('audioUnblocked', LOG_CATEGORIES.TOKBOX, {
          participantId,
          streamId: subscriber.stream.streamId
        });
      });

      if (subscriber.isAudioBlocked()) {
        logger.log('audioBlocked', LOG_CATEGORIES.TOKBOX, {
          participantId,
          streamId: subscriber.stream.streamId
        });
      }

      // Register captionReceived event for every non-screenshare subscriber
      // if captions feature is available
      if (getters.isCaptionsFeatureAvailable) {
        dispatch('captions/addCaptionsEventListener', subscriber);

        if (getters['captions/isCaptionsOrLiveTranscriptionEnabled']) {
          dispatch('captions/subscribeToCaptionsForSubscriber', {
            subscriber,
            shouldSubscribe: true
          });
        }
      }
    }
  },

  // publisher events
  subscribePublisherAudioLevelUpdated: () => {
    if (MeetingManager.publisher) {
      MeetingManager.onPublisherEvent('audioLevelUpdated', (event) => {
        const audioLevel = [event.audioLevel];
        StreamsStats.setMyCurrentAudioLevel(audioLevel);
      });
      // We call this because there is a bug in tokbox sdk, that never stop emitting audioLevelUpdated,
      // unless we read the 'loudness' getter once.
      // eslint-disable-next-line no-unused-expressions
      MeetingManager.publisher.loudness;
    }
  },

  subscribePublisherEvents: ({ state, dispatch }) => {
    if (MeetingManager.publisher) {
      dispatch('subscribePublisherAudioLevelUpdated');
      MeetingManager.onPublisherEvent('mediaStopped', (event) => {
        logger.warning('media-stopped', LOG_CATEGORIES.TOKBOX, {
          participantId: state.myParticipantId,
          type: event?.kind || 'All'
        });
      });

      MeetingManager.onPublisherEvent('audioAcquisitionProblem', (event) => {
        logger.warning('audioAcquisitionProblem', LOG_CATEGORIES.TOKBOX, {
          event
        });
      });

      MeetingManager.onPublisherEvent('muteForced', (event) => {
        logger.log('publisherMuteForced', LOG_CATEGORIES.TOKBOX, {
          event
        });
        dispatch(
          'setCurrentForceMuteEventState',
          MUTE_FORCED_TYPE.PUBLISHER_MUTE
        );

        dispatch('setMicEnabled', false);
      });
    }
  },

  // window events
  registerGlobalHandlers: async ({ state, commit, dispatch }) => {
    if (window.navigator.onLine) {
      commit('SET_CONNECTION_STATUS', CONNECTION_STATUS.ONLINE);
    } else {
      commit('SET_CONNECTION_STATUS', 'offline');
    }

    // Monitor network connection status
    window.addEventListener(CONNECTION_STATUS.ONLINE, () =>
      dispatch('setNetworkStatusToOnline')
    );
    window.addEventListener(CONNECTION_STATUS.OFFLINE, () =>
      dispatch('setNetworkStatusToOffline')
    );

    // Set network status back to online if an http request succeeded
    setDefaultSuccessHandler((res) => {
      if (state.connectionStatus === CONNECTION_STATUS.OFFLINE) {
        dispatch('setNetworkStatusToOnline');
      }
      return res;
    });

    window.addEventListener('keydown', (event) => {
      if (isContentEditable()) {
        return;
      }

      // PTT
      // TODO: Turn off listeners when feature flag is off
      // space == keycode 32
      if (event.keyCode == 32) {
        dispatch('togglePushToTalkOn');
        commit('SET_IS_PTT_ON', true);
        event.preventDefault();
        analytics.trackEvent(ANALYTICS.KEYBOARD_SHORTCUTS, {
          Type: KEYBOARD_SHORTCUTS.PUSH_TO_TALK
        });
      }
    });

    window.addEventListener('keyup', (event) => {
      // PTT
      // space == keycode 32
      if (event.keyCode == 32 && state.isPTTOn) {
        dispatch('togglePushToTalkOff');
        commit('SET_IS_PTT_ON', false);
        event.preventDefault();
      }
    });

    document.addEventListener('visibilitychange', () => {
      commit('SET_PAGE_VISIBILITY', document.visibilityState === 'visible');
    });
  },

  // electron events
  registerMainProcessEventHandlers: ({ state, getters, dispatch, commit }) => {
    if (isElectron()) {
      const electronIpc = window.Electron.ipc;
      const electronWebapp = window.Electron.webApp;

      if (window.Electron.onStopScreenshare) {
        window.Electron.onStopScreenshare(() =>
          dispatch('screenshare/stopScreenshare')
        );
        window.Electron.onToggleAudio(() =>
          dispatch('toggleMic', TOGGLE_MIC_SOURCES.PRESENTER_MODE)
        );
        window.Electron.onToggleVideo(() =>
          dispatch('toggleVideo', TOGGLE_CAMERA_SOURCES.PRESENTER_MODE)
        );
        window.Electron.onMinimizeMode((event, isEnabled) => {
          commit('SET_MINIMIZE_MODE', isEnabled);

          // We don't want Appcues to be visible on presenter mode
          dispatch('setShouldAppcuesBeVisible', !isEnabled);
        });
        window.Electron.onFullscreen((event, isEnabled) => {
          commit('SET_IS_FULL_SCREEN', isEnabled);
        });
      } else {
        electronIpc.onMessage_stopScreenshare(() =>
          dispatch('screenshare/stopScreenshare')
        );
      }

      if (window.Electron.onWaitingRoomParticipantUpdated) {
        window.Electron.onWaitingRoomParticipantUpdated(
          (event, participantId, isApproval) => {
            const isUndoSupported = !!window.Electron.onUndoDeny;

            dispatch('waitingRoom/updateWaitingRoomParticipantStatus', {
              participantId,
              isApproval,
              source: 'Owner modal presenter',
              isUndoAllowed: isUndoSupported
            });
          }
        );
      }
      if (window.Electron.onViewWaitingRoom) {
        window.Electron.onViewWaitingRoom(() => {
          dispatch('waitingRoom/viewWaitingRoom', {
            source: 'Owner modal presenter'
          });
        });
      }
      if (window.Electron.onApproveAllWaitingRoomParticipants) {
        window.Electron.onApproveAllWaitingRoomParticipants(() => {
          dispatch('waitingRoom/approveAllWaitingRoomParticipants');
        });
      }
      if (window.Electron.onUndoDeny) {
        window.Electron.onUndoDeny((event, participantId) => {
          dispatch('waitingRoom/undoDeny', { participantId });
        });
      }

      // enable/disable touchbar when meeting is ready/unready
      store.watch(
        (storeState) =>
          storeState.isAppRunning &&
          getters['settings/exclusiveExperimentalMode'],
        (enableTouchbar) => {
          if (window.Electron.enableTouchbar) {
            window.Electron.enableTouchbar(enableTouchbar);
          } else {
            electronWebapp.sendMessage_meetingReady(enableTouchbar);
          }
        },
        { immediate: true }
      );

      if (window.Electron.notifyNumberOfStreamsUpdate) {
        store.watch(
          (_, storeGetters) => storeGetters.numberOfVisibleParticipants,
          // Debounce the watcher because the IPC is async and cannot be awaited
          debounce((numberOfVisibleParticipants) => {
            const streamsCount =
              state.layout.layoutMode === LAYOUT_MODE_TYPES.DOMINANT
                ? 1
                : numberOfVisibleParticipants;
            window.Electron.notifyNumberOfStreamsUpdate(streamsCount);
          }, 100)
        );

        store.watch(
          (storeState) => storeState.layout.layoutMode,
          (layout) => {
            const numberOfStreams =
              layout === LAYOUT_MODE_TYPES.DOMINANT ? 1 : state.streams.length;
            window.Electron.notifyNumberOfStreamsUpdate(numberOfStreams);
          }
        );

        store.watch(
          (storeState) => storeState.minimizedMode,
          () => dispatch('layout/changeLayoutOnMinimizeModeChange')
        );
      }

      // init touchbar
      dispatch('registerTouchbarEventHandlers');
    }
  },

  registerTouchbarEventHandlers: ({ dispatch }) => {
    const electronIpc = window.Electron.ipc;
    const leaveMeeting = () => {
      dispatch('setIsEndMeetingDialogVisible', true);
    };

    if (window.Electron.onLeaveMeeting) {
      window.Electron.onLeaveMeeting(leaveMeeting);
    } else if (electronIpc.touchBar) {
      electronIpc.touchBar.onTouchBar_toggleMic(() =>
        dispatch('toggleMic', TOGGLE_MIC_SOURCES.TOUCHE_BAR)
      );

      electronIpc.touchBar.onTouchBar_toggleVideo(() =>
        dispatch('toggleVideo', TOGGLE_CAMERA_SOURCES.TOUCHE_BAR)
      );

      electronIpc.touchBar.onTouchBar_leaveMeeting(leaveMeeting);
    }
  },

  setNetworkStatusToOnline: async ({ state, dispatch, commit }) => {
    try {
      const session = await roomService.getSessionById(state.sessionId);
      clearNetworkInterval();
      commit('SET_CONNECTION_STATUS', CONNECTION_STATUS.ONLINE);
      dispatch('showConnectionRestoredFlashMessage');
      dispatch('updateSession', session);
      dispatch('messaging/readMessages');
    } catch (err) {
      // When it's a network error, there is no response
      if (err.response) {
        clearNetworkInterval();
      }
    }
  },

  setNetworkStatusToOffline: async ({ dispatch, commit }) => {
    commit('SET_CONNECTION_STATUS', 'offline');
    if (!validateIfOnlineInterval) {
      validateIfOnlineInterval = setInterval(() => {
        dispatch('setNetworkStatusToOnline');
      }, CHECK_NETWORK_INTERVAL);
    }
  },

  // others
  setIsNetworkIssues: ({ commit }, isNetworkIssues) => {
    commit('SET_IS_NETWORK_ISSUES', isNetworkIssues);
  },

  initAppReadyWatcher: ({ dispatch }) => {
    store.watch(
      (storeState) => storeState.isAppRunning,
      (isRunning) => {
        if (!isRunning) {
          dispatch('unregisterSessionData');
        }
      }
    );
  },

  detectMyStreamPropertiesChanged: ({ dispatch, getters }, { event }) => {
    if (event.stream.streamId !== getters.myStream?.streamId) {
      return;
    }

    if (['hasAudio', 'hasVideo'].includes(event.changedProperty)) {
      // Synchronize with iPhone's external toggle mic/cam buttons
      if (isAppleMobileDevice && event.oldValue !== event.newValue) {
        if (event.changedProperty === 'hasAudio') {
          dispatch('setMicEnabled', event.newValue, { root: true });
        } else if (event.changedProperty === 'hasVideo') {
          dispatch('setVideoEnabled', event.newValue, { root: true });
        }
      }
    }

    dispatch('handleDisabledDevices', event);
  },

  handleDisabledDevices: ({ commit, state }, event) => {
    // handle this situation only if this is safari browser
    if (!window.isSafari) {
      return;
    }

    // oldValue = true --> neValue = false
    if (event.oldValue && !event.newValue) {
      // the value for these might change after the timeout so we save the value they had
      // once the event was received
      const isVideoEnabled = state.isVideoEnabled;
      const isMicEnabled = state.isMicEnabled;
      setTimeout(() => {
        if (!state.isDevicesChangedRecently) {
          if (event.changedProperty === 'hasAudio' && isMicEnabled) {
            commit('SET_IS_MICROPHONE_PAUSED_SAFARI', true);
          } else if (event.changedProperty === 'hasVideo' && isVideoEnabled) {
            commit('SET_IS_VIDEO_PAUSED_SAFARI', true);
          }
        }
      }, TIME_TO_WAIT_FOR_DEVICES_CHANGED_RECENTLY_TO_BE_SET);
    }
    // oldValue = false --> neValue = true
    if (!event.oldValue && event.newValue) {
      if (event.changedProperty === 'hasAudio') {
        commit('SET_IS_MICROPHONE_PAUSED_SAFARI', false);
      } else if (event.changedProperty === 'hasVideo') {
        commit('SET_IS_VIDEO_PAUSED_SAFARI', false);
      }
    }
  }
};
