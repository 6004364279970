/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-pentool-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M23.55 7.95l-7.5-7.5c-.3-.45-.9-.6-1.5-.45L4.05 3c-.45.15-.9.75-1.05 1.2L.15 21.75l9-9c-.3-1.05 0-2.1.75-2.85 1.2-1.2 3-1.2 4.2 0 1.2 1.2 1.2 3 0 4.2-.75.75-1.95 1.05-2.85.75l-9 9L19.8 21c.6-.15 1.05-.45 1.2-1.05l3-10.5c.15-.6 0-1.2-.45-1.5z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
