/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-menu': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M1 2.5a.5.5 0 01.5-.5h14a.5.5 0 010 1h-14a.5.5 0 01-.5-.5zm0 6a.5.5 0 01.5-.5h14a.5.5 0 010 1h-14a.5.5 0 01-.5-.5zm.5 5.5a.5.5 0 000 1h14a.5.5 0 000-1h-14z"/>'
  }
})
