/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-palette': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" d="M6.4 1.1C6 .6 5.4.5 4.9.5s-1 .2-1.5.5C2 2 1 3.4.4 5s-.6 3.4 0 5c.5 1.6 1.5 3 2.9 4s3 1.5 4.8 1.5c1.7 0 3.4-.6 4.8-1.6s2.4-2.5 2.9-4.2c.2-.6.3-1.7.1-2.9-.2-1.2-.8-2.4-2.2-3.1-1.9-.9-3.6-.4-4.9 0-.1 0-.2 0-.2.1-.6.2-1 .3-1.4.4-.4 0-.6 0-.8-.3v-.2c0-.1.1-.3.1-.5v-.1c.2-.6.4-1.3-.1-2zm-5 4.2C1.8 3.9 2.8 2.7 4 1.8c.3-.2.6-.3 1-.3.3 0 .5.1.6.2.2.2.1.4 0 1v.1c-.1.2-.2.4-.2.7 0 .3 0 .6.2 1 .5.5 1.1.6 1.7.6.5 0 1.1-.2 1.6-.4.1 0 .2 0 .2-.1 1.3-.4 2.7-.8 4.2 0 1 .5 1.5 1.4 1.6 2.4.2 1 0 2-.1 2.5-.4 1.5-1.3 2.7-2.5 3.7-1.2.8-2.7 1.3-4.3 1.3-1.5 0-2.9-.4-4.2-1.3s-2.1-2.1-2.6-3.5c-.3-1.5-.3-3 .2-4.4zM9 8.5c0-.8.7-1.5 1.5-1.5s1.5.7 1.5 1.5-.7 1.5-1.5 1.5S9 9.3 9 8.5zM10.5 6C9.1 6 8 7.1 8 8.5S9.1 11 10.5 11 13 9.9 13 8.5 11.9 6 10.5 6zM4 9c.6 0 1-.4 1-1s-.4-1-1-1-1 .4-1 1 .4 1 1 1zm3 2c0 .6-.4 1-1 1s-1-.4-1-1 .4-1 1-1 1 .4 1 1z"/>'
  }
})
