var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container" }, [
    _c(
      "select",
      {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.wrappedValue,
            expression: "wrappedValue",
          },
        ],
        staticClass: "native-select",
        on: {
          change: function ($event) {
            var $$selectedVal = Array.prototype.filter
              .call($event.target.options, function (o) {
                return o.selected
              })
              .map(function (o) {
                var val = "_value" in o ? o._value : o.value
                return val
              })
            _vm.wrappedValue = $event.target.multiple
              ? $$selectedVal
              : $$selectedVal[0]
          },
        },
      },
      _vm._l(_vm.options, function (option) {
        return _c(
          "option",
          { key: option.key, domProps: { value: option.value } },
          [_vm._v(" " + _vm._s(option.label) + " ")]
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "custom-select" },
      [
        _vm._t("default"),
        _c("v-icon", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showArrow,
              expression: "showArrow",
            },
          ],
          staticClass: "arrow Vlt-grey",
          attrs: { iconName: "Vlt-icon-right-full" },
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }