/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-flash-2-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<g clip-path="url(#clip0)"><path pid="0" d="M14.644.112a.75.75 0 01.317.875L12.291 9H23.25a.75.75 0 01.53 1.28l-13.5 13.5a.75.75 0 01-1.27-.653L10.365 15H.75a.75.75 0 01-.53-1.28L13.72.22a.75.75 0 01.924-.108z"/></g><defs><clipPath id="clip0"><path pid="1" d="M0 0h24v24H0V0z"/></clipPath></defs>'
  }
})
