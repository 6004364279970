var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.messageBanner
    ? _c(
        "div",
        {
          staticClass: "banner-wrapper",
          attrs: { "data-cy": "message-banner-container" },
        },
        [
          _c("ParticipantAvatar", {
            staticClass: "avatar",
            attrs: {
              negative: "",
              avatarSize: "sm",
              "data-cy": "message-banner-avatar",
              participantType: _vm.sender.type,
              participantProfilePicture: _vm.sender.profilePicture,
              displayName: _vm.displayName,
            },
          }),
          _c("div", { staticClass: "content-wrapper" }, [
            _c("div", { staticClass: "content-header" }, [
              _c(
                "div",
                {
                  staticClass: "display-name",
                  style: { color: _vm.color },
                  attrs: { "data-cy": "message-banner-name" },
                },
                [_vm._v(" " + _vm._s(_vm.displayName) + " ")]
              ),
              _vm.showTime
                ? _c(
                    "div",
                    {
                      staticClass: "message-time",
                      attrs: { "data-cy": "message-time" },
                    },
                    [_vm._v(" " + _vm._s(_vm.formattedTime) + " ")]
                  )
                : _vm._e(),
            ]),
            _c(
              "div",
              {
                staticClass: "content-body",
                attrs: { "data-cy": "message-banner-content" },
              },
              [
                _vm.isTextMessage
                  ? _c(
                      "div",
                      [
                        _vm.isLink
                          ? _c("v-icon", {
                              staticClass: "attachment-icon",
                              attrs: { iconName: "Vlt-icon-link-full" },
                            })
                          : _vm._e(),
                        _c("TextMessageRow", {
                          attrs: { message: _vm.message },
                        }),
                      ],
                      1
                    )
                  : _vm.isImage
                  ? _c(
                      "div",
                      [
                        _c("v-icon", {
                          staticClass: "attachment-icon",
                          attrs: {
                            "data-cy": "attachment-icon-image",
                            iconName: "Vlt-icon-camera-full",
                          },
                        }),
                        _c("span", { staticClass: "attachment" }, [
                          _vm._v(
                            _vm._s(_vm.$t("mobile_message_banner.image_text"))
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm.isVideo
                  ? _c(
                      "div",
                      [
                        _c("v-icon", {
                          staticClass: "attachment-icon",
                          attrs: {
                            "data-cy": "attachment-icon-video",
                            iconName: "Vlt-icon-file-video-full",
                          },
                        }),
                        _c("span", { staticClass: "attachment" }, [
                          _vm._v(
                            _vm._s(_vm.$t("mobile_message_banner.video_text"))
                          ),
                        ]),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("v-icon", {
                          staticClass: "attachment-icon",
                          attrs: {
                            "data-cy": "attachment-icon-file",
                            iconName: "Vlt-icon-file-2-full",
                          },
                        }),
                        _c("span", { staticClass: "attachment" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("mobile_message_banner.attachment_text")
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
              ]
            ),
          ]),
          _c(
            "div",
            {
              staticClass: "close-icon-container",
              attrs: { "data-cy": "message-banner-close-btn" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.clearMessageBanner($event)
                },
              },
            },
            [
              _c("v-icon", {
                staticClass: "close-icon",
                attrs: { iconName: "Vlt-icon-cross-full" },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }