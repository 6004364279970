/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-report-full': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M22.5 0h-21C.6 0 0 .6 0 1.5v21c0 .9.6 1.5 1.5 1.5h21c.9 0 1.5-.6 1.5-1.5v-21c0-.9-.6-1.5-1.5-1.5zm-7.35 15.45L9 10.8l-4.95 3.3-1.65-2.55L9 7.2l5.85 4.35L19.5 6.9 21.6 9l-6.45 6.45z"/>'
  }
})
