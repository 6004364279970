/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Vlt-icon-hospital': {
    width: 16,
    height: 16,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M1.532 12C1.532 6.219 6.219 1.532 12 1.532S22.468 6.219 22.468 12 17.781 22.468 12 22.468 1.532 17.781 1.532 12zM12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12S18.627 0 12 0zm2.809 9.191V5.106H9.19v4.085H5.106v5.617h4.085v4.086h5.617v-4.085h4.086V9.19h-4.085zm-4.086 1.532V6.638h2.554v4.085h4.085v2.554h-4.085v4.085h-2.554v-4.085H6.638v-2.554h4.085z"/>'
  }
})
