var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "inputTag",
      staticClass: "input-line",
      attrs: { "data-cy": "input-line" },
    },
    [
      _vm._l(_vm.tags, function (tag, index) {
        return _c("vwc-tag", {
          key: index,
          staticClass: "tag",
          attrs: {
            removable: "",
            removeEventOnly: "",
            shape: "rounded",
            text: tag,
          },
          on: {
            "remove-tag": function ($event) {
              return _vm.removeTag(index)
            },
          },
        })
      }),
      _vm._t("input-text", [
        _c("VTextfield", {
          staticClass: "text-input",
          attrs: {
            appearance: "ghost",
            dense: "",
            focus: "",
            placeholder: _vm.placeholder,
          },
          on: { input: _vm.filter },
          model: {
            value: _vm.newTag,
            callback: function ($$v) {
              _vm.newTag = $$v
            },
            expression: "newTag",
          },
        }),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }